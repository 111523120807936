import ability from '@/libs/acl/ability'
import { pr } from './config'

// * Create entity file, and can functions
// * Import and add them to allCanFunctions object down below
// * Add defaults into the permissions array down below
// * Implement v-permission directives on neccessary buttons
// Implement permissions on entities sdk functions
// * Implement permissions on navigation and route - Everyone has access to view media

export const OrganizationUsers = Object.freeze({
  VIEW: 'Organization.User.View',
  MANAGE: 'Organization.User.Manage',
})

export const OrganizationUsersToolTips = {
  [OrganizationUsers.VIEW]: 'Allows user to view the list of all sites of the organization in ‘view only’ mode',
  [OrganizationUsers.MANAGE]: 'Allows users to view, remove and add users from a specific organization or site.',
}

/**
 * Can VIEW Users
 * */

export function canViewOrganizationUsers(_options = {}) {
  const { entity, ...options } = _options
  return pr(
    ability.can(OrganizationUsers.VIEW) || ability.can(OrganizationUsers.MANAGE),
    'You have no permission to view users',
    options,
  )
}

/**
 * Can VIEW status of invetations
 * */

// export function canViewInviteStatus(_options = {}) {
//   const { entity, ...options } = _options
//   return pr(
//     ability.can(OrganizationUsers.VIEW) || ability.can(OrganizationUsers.MANAGE),
//     'You have no permission to view invitation status',
//     options,
//   )
// }

/**
 * Can ADD site to users
 * */

export function canAddSiteToUsers(_options = {}) {
  const { entity, ...options } = _options
  return pr(
    ability.can(OrganizationUsers.MANAGE),
    'You don’t have permission to add a new Site to the user',
    options,
  )
}

/**
 * Can REMOVE site from user
 * */

export function canRemoveSiteFromUser(_options = {}) {
  const { entity, ...options } = _options
  return pr(
    ability.can(OrganizationUsers.MANAGE),
    'You don’t have permission to remove site from user',
    options,
  )
}

/**
 * Can VIEW list of invitations
 * */

export function canViewListOfInvitations(_options = {}) {
  const { entity, ...options } = _options
  return pr(
    ability.can(OrganizationUsers.MANAGE),
    'You have no permission to view list of invitations',
    options,
  )
}

/**
 * Can ADD users in organization
 * */

export function canAddUsersInOrganization(_options = {}) {
  const { entity, ...options } = _options
  return pr(
    ability.can(OrganizationUsers.MANAGE),
    'You have no permission to add users in organization',
    options,
  )
}

/**
 * Can REMOVE users in organization
 * */

export function canRemoveUsersFromOrganization(_options = {}) {
  const { entity, ...options } = _options
  return pr(
    ability.can(OrganizationUsers.MANAGE),
    'You have no permission to remove users from organization',
    options,
  )
}

/**
 * Can DELETE users
 * */

export function canDeleteUsers(_options = {}) {
  const { entity, ...options } = _options
  return pr(
    ability.can(OrganizationUsers.MANAGE),
    'You have no permission to delete users',
    options,
  )
}
