import { VIEW_TYPE } from '@/codex-sdk/entries-views'
import state from './modulesSitesState'
import mutations from './modulesSitesMutations'
import actions from './modulesSitesActions'
import getters from './modulesSitesGetters'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}

export function generateViewNavigationItem(entity, type = 'VIEW', folder = null) {
  const items = []

  if (type === 'VIEW') {
    let lastPath = null
    if (entity.path) {
      lastPath = entity.path
      generateViewNavigationItem({ ...entity, path: null }, 'FOLDER', lastPath).forEach(i => items.push(i))
    }
    items.push({
      staticName: `site-entries-${entity.id}`,
      name: `entries-${entity.siteId}-${entity.id}`,
      title: entity.name,
      siteId: entity.siteId,
      view: true,
      group: 'views',
      parent: lastPath,
      icon: entity.type === VIEW_TYPE.PRIVATE ? 'Lock' : 'AccountProfileUsersGroup',
      route: {
        name: 'entries',
        params: {
          siteName: entity.siteId,
          viewId: entity.id,
        },
      },
      insertAt: {
        parent: lastPath ? `entries-folder-${lastPath}-${entity.siteId}` : `entries-${entity.siteId}-all`,
        index: 999,
      },
      order: 2,
    })
  } else {
    items.push({
      staticName: `site-entries-folder-${folder}`,
      name: `entries-folder-${folder}-${entity.siteId}`,
      title: folder,
      folder: true,
      group: 'views',
      siteId: entity.siteId,
      icon: 'Folder',
      route: { name: 'entries', params: { siteName: entity.siteId, name: folder } },
      insertAt: {
        parent: `entries-${entity.siteId}-all`,
        index: 999,
      },
      order: 2,
      children: [],
    })
  }

  return items
}

export function generateSiteNavigationItems(site, hasViews = false) {
  const children = [
    {
      staticName: 'site-entries',
      name: `entries-${site.id}-all`,
      title: 'general.navbar.not-organization.content-options.entries',
      siteId: site.id,
      route: { name: 'entries', params: { siteName: site.id, viewId: 'all' } },
      icon: 'Article',
      isOpen: true,
      children: [
        {
          staticName: 'site-entries-all',
          name: `entries-${site.id}-all`,
          title: 'general.navbar.not-organization.content-options.all-entries',
          icon: 'Modeling',
          siteId: site.id,
          route: { name: 'entries', params: { siteName: site.id, viewId: 'all' } },
        },
        {
          staticName: 'site-entries-mine',
          name: `entries-${site.id}-mine`,
          title: 'general.navbar.not-organization.content-options.my-entries',
          icon: 'Modeling',
          siteId: site.id,
          route: { name: 'entries', params: { siteName: site.id, viewId: 'mine' } },
        },
        ...(hasViews ? [{
          name: 'divider', divider: true,
        }, {
          name: 'header', header: 'general.navbar.not-organization.content-options.all-views-title', icon: false, classes: 'all-views-title',
        }] : []),
      ],
    },
    {
      staticName: 'site-sections',
      name: `sections-${site.id}`,
      title: 'general.navbar.not-organization.content-options.sections',
      siteId: site.id,
      route: { name: 'sections', params: { siteName: site.id } },
      icon: 'MainArticle',
      permission: 'canViewSections',
    },
    {
      staticName: 'site-lists',
      name: `lists-${site.id}`,
      title: 'general.navbar.not-organization.content-options.lists',
      siteId: site.id,
      route: { name: 'lists-static', params: { siteName: site.id } },
      icon: 'ListAlt',
      permission: 'canViewLists',
      children: [
        {
          staticName: 'site-lists-static',
          name: `lists-static-${site.id}`,
          title: 'general.navbar.not-organization.content-options.lists-static',
          icon: 'IconparkTreeList',
          siteId: site.id,
          route: { name: 'lists-static', params: { siteName: site.id } },
        },
        {
          staticName: 'site-lists-dynamic',
          name: `lists-dynamic-${site.id}`,
          title: 'general.navbar.not-organization.content-options.lists-dynamic',
          icon: 'IconparkAutoFocus',
          siteId: site.id,
          route: { name: 'lists-dynamic', params: { siteName: site.id } },
        },
      ],
    },
    {
      staticName: 'site-urls',
      name: `urls-${site.id}`,
      title: 'general.navbar.not-organization.content-options.urls',
      siteId: site.id,
      route: { name: 'urls', params: { siteName: site.id } },
      icon: 'Hyperlink',
      permission: 'canViewUrl',
    },
    {
      staticName: 'site-tags',
      name: `tags-${site.id}`,
      title: 'general.navbar.not-organization.content-options.tags',
      siteId: site.id,
      route: { name: 'tags', params: { siteName: site.id } },
      icon: 'Tag',
      permission: 'canViewTags',
    },
  ]

  return {
    staticName: 'site-module',
    name: `site-${site.alias}`,
    title: site.name,
    image: site.logo,
    siteId: site.id,
    route: { name: 'entries', params: { siteName: site.id, viewId: 'all' } },
    insertBefore: 'builder-module',
    order: 1,
    group: 'sites',
    children,
  }
}
