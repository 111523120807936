<template>
  <div class="no-items">
    <div class="no-items__inner">
      <div class="no-items__icon">
        <slot name="icon">
          <GjIcon
            :name="icon"
            :size="`${iconSize}`"
          />
        </slot>
      </div>
      <div
        v-if="title"
        class="no-items__title"
      >
        {{ title }}
      </div>
      <div
        v-if="text"
        class="no-items__text"
      >
        {{ text }}
      </div>
      <slot name="content" />
    </div>
  </div>
</template>

<script>

export default {
  props: {
    title: {
      type: String,
      default: null,
    },
    text: {
      type: String,
      default: null,
    },
    icon: {
      type: String,
      default: null,
    },
    iconSize: {
      type: Number,
      default: 32,
    },
  },
}
</script>
