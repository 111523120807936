import { apiAxios as axios } from '@/libs/axios'
import i18n from '@/libs/i18n'
import { enumMapping } from '@/utils/helpers'
import { tc } from '@/codex-sdk/config'

// TEXT
/**
 *
 * @param {String} entryId Entry ID
 * @param {String} fieldAlias Target Field Alias
 * @param {String} prompt Prompt for the AI
 *
 * @response
 * [string]
 */
export function generateText({ entryId = '', fieldAlias = '', prompt = '' }, messages = {}) {
  return tc(axios.post(`/{organization}/ai/generate/suggestions/${entryId}`, {}, {
    params: {
      fieldAlias,
      prompt,
    },
  }), {
    successTitle: 'sdk.success-title',
    successMessage: 'sdk.ai.text-success',
    failTitle: 'sdk.ai.text-failed',
    catchError: false,
    ...messages,
  })
}

// RICH CONTENT

export const RICH_CONTENT_AI_OPERATIONS = Object.freeze({
  IMPROVE_WRITING: 'IMPROVE_WRITING',
  FIX_SPELLING_AND_GRAMMAR: 'FIX_SPELLING_AND_GRAMMAR',
  MAKE_SHORTER: 'MAKE_SHORTER',
  MAKE_LONGER: 'MAKE_LONGER',
  MAKE_IT_PROFESSIONAL: 'MAKE_IT_PROFESSIONAL',
  SUMMARIZE: 'SUMMARIZE',
  TRANSLATE: 'TRANSLATE',
  EXPLAIN_THIS: 'EXPLAIN',
  CONTINUE_WRITING: 'CONTINUE_WRITING',
})

export const RICH_CONTENT_AI_OPERATION_MAPPING = enumMapping({
  [RICH_CONTENT_AI_OPERATIONS.IMPROVE_WRITING]: 1,
  [RICH_CONTENT_AI_OPERATIONS.FIX_SPELLING_AND_GRAMMAR]: 2,
  [RICH_CONTENT_AI_OPERATIONS.MAKE_SHORTER]: 3,
  [RICH_CONTENT_AI_OPERATIONS.MAKE_LONGER]: 4,
  [RICH_CONTENT_AI_OPERATIONS.MAKE_IT_PROFESSIONAL]: 5,
  [RICH_CONTENT_AI_OPERATIONS.SUMMARIZE]: 6,
  [RICH_CONTENT_AI_OPERATIONS.TRANSLATE]: 7,
  [RICH_CONTENT_AI_OPERATIONS.EXPLAIN_THIS]: 8,
  [RICH_CONTENT_AI_OPERATIONS.CONTINUE_WRITING]: 9,
})

export function mapRichContentOperation(operation) {
  return i18n.t(`fields.general.ai-popup.operations.${operation?.toLowerCase()}`)
}

/**
 *
 * @param {String} entryId Entry ID
 * @param {String} fieldAlias Target Field Alias
 * @param {String} prompt Prompt for the AI
 * @param {String} selectedText Selected Text from Rich Content field
 * @param {Number} operation Operation Type
 * @param {String} lang Language to translate into
 *
 * @response
 * [string]
 */
export function generateRichContent({
  entryId = '', fieldAlias = '', prompt = '', selectedText = '', operation = 0, lang = null,
}, messages = {}) {
  return tc(axios.post('/{organization}/ai/generate/rich-content', {
    entryId,
    selectedText,
    prompt,
    fieldAlias,
    operation,
    languageToTranslateInto: lang,
  }), {
    successTitle: 'sdk.success-title',
    successMessage: 'sdk.ai.rich-content-success',
    failTitle: 'sdk.ai.rich-content-failed',
    catchError: false,
    ...messages,
  })
}

export function generateSeoAiContent({ entryId }, messages = {}) {
  return tc(axios.post(`/{organization}/ai/generate/seo-analysis/${entryId}`), {
    successTitle: 'sdk.success-title',
    successMessage: 'sdk.ai.seo-score-success',
    failTitle: 'sdk.ai.seo-score-failed',
    catchError: false,
    ...messages,
  })
}

export function retrySeoSuggestion({ entryId = '', feedback = '', fieldAlias = '' }, messages = {}) {
  const item = { entryId, feedback, fieldAlias }
  return tc(axios.post('/{organization}/ai/generate/retry-seo-suggestion', item), {
    successTitle: 'sdk.success-title',
    successMessage: 'sdk.ai.seo-score-success',
    failTitle: 'sdk.ai.seo-score-failed',
    catchError: false,
    ...messages,
  })
}

export function generateSentimentAiContent({ entryId }, messages = {}) {
  return tc(axios.post(`/{organization}/ai/generate/sentiment-analysis/${entryId}`), {
    successTitle: 'sdk.success-title',
    successMessage: 'sdk.ai.sentiment-score-success',
    failTitle: 'sdk.ai.sentiment-score-failed',
    catchError: false,
    ...messages,
  })
}
