<template>
  <div>
    <b-form-group>
      <b-form-radio-group v-model="appearance">
        <div class="author-field__appearance">
          <div class="author-field__icon-text">
            <GjIcon
              name="AvatarGroup"
              class="author-field__icon"
              size="24"
              style="fill: #1D79F2"
            />
            <span class="author-field__text">
              {{ $t('fields.codex-field-authors.appearance.author') }}
            </span>
          </div>
          <b-form-radio
            name="radio"
            :value="1"
            class="author-field__radio"
          />
        </div>
      </b-form-radio-group>
    </b-form-group>

    <FieldHelpTextInput
      :widget="widget"
      :update-attrs="updateAttrs"
    />

    <FieldConditions
      v-model="hidden"
      :widget="widget"
      :label="$t('fields.general.hidden.label')"
      :show-error-message="false"
      :tooltip="$t('fields.general.hidden.info')"
    />
  </div>
</template>

<script>
import { generateComputedPropsFromAttrs } from '@/components/codex-layout-editor/BuilderUtils'
import FieldHelpTextInput from '@/components/fields/FieldHelpTextInput.vue'
import BaseFieldMixin from '@/components/fields/BaseFieldMixin'
import FieldConditions from '@/components/fields/FieldConditions.vue'

export default {
  name: 'Appearance',
  components: { FieldConditions, FieldHelpTextInput },
  mixins: [BaseFieldMixin],
  computed: {
    ...generateComputedPropsFromAttrs([
      'helpText',
      'appearance',
      'hidden',
    ]),
  },
}
</script>

<style lang="scss" scoped>
.author-field__appearance {
  width: 100%;
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 4px 8px;
  border: 1px solid #E0E5EB;
  border-radius: 4px;
}
.author-field__icon-text {
  display: flex;
  flex-direction: row;
}
.author-field__icon {
  box-shadow: 0 4px 8px rgba(163, 176, 194, 0.32);
  border-radius: 3px;
}
.author-field__text {
  margin-top: 0.2rem;
  margin-bottom: 0;
  margin-left: 12px;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #052D61;
}
.author-field__radio {
  width: 16px;
  height: 16px;
  right: 0;
  padding: 0!important;
  margin: 0 !important;
}

</style>
