import { enumMapping } from '@/utils/helpers'
import { apiAxios as axios } from '../libs/axios'
import { tc } from './config'

/**
 * Constants
 */

export const COMMENT_TYPES = Object.freeze({
  ENTRY: 1,
})

export const COMMENT_STATUSES = Object.freeze({
  ACTIVE: 'ACTIVE',
  DELETED: 'DELETED',
  RESOLVED: 'RESOLVED',
})

export const COMMENT_STATUS_MAPPING = enumMapping({
  [COMMENT_STATUSES.ACTIVE]: 1,
  [COMMENT_STATUSES.DELETED]: 2,
  [COMMENT_STATUSES.RESOLVED]: 3,
})

/**
 * Helpers
 */
export function commentDefaults(comment = {}) {
  return {
    threadId: null,
    parentId: null,
    type: COMMENT_TYPES.ENTRY,
    content: '',
    mentions: [],
    attrs: {},
    ...comment,
  }
}

/**
 * Transforms to received data
 */
export function transformCommentFromRequest(comment) {
  comment.status = COMMENT_STATUS_MAPPING.toString(comment.status)
  return comment
}

/**
 * CRUD Operations
 * */

/**
 *
 * @param {Object} comment Comment Object
 * @param {Object} messages Message texts
 *
 * @response
 * {
 *  id: GUID
 *  errors: null | Object
 *  success: true | false
 * }
 */
export function createComment(comment, messages = {}) {
  return tc(axios.post('/{organization}/comments', comment).then(response => {
    response.data = transformCommentFromRequest(response.data)
    return response
  }), {
    successTitle: 'sdk.success-title',
    successMessage: comment.parentId ? 'sdk.comment.reply.create-successful' : 'sdk.comment.create-successful',
    failTitle: comment.parentId ? 'sdk.comment.reply.create-failed' : 'sdk.comment.create-failed',
    ...messages,
  })
}

/**
 *
 * @param {Object} comment
 *
 * @response
 * {
 *  id: GUID
 *  errors: null | Object
 *  success: true | false
 * }
 */
export function updateComment(comment, messages = {}) {
  return tc(axios.put(`/{organization}/comments/${comment.id}`, comment).then(response => {
    response.data = transformCommentFromRequest(response.data)
    return response
  }), {
    successTitle: 'sdk.success-title',
    successMessage: comment.parentId ? 'sdk.comment.reply.update-successful' : 'sdk.comment.update-successful',
    failTitle: comment.parentId ? 'sdk.comment.reply.update-failed' : 'sdk.comment.update-failed',
    ...messages,
  })
}

/**
 *
 * @param {*} comment
 *
 * @response
 * {
 *  id: GUID
 *  errors: null | Object
 *  success: true | false
 * }
 */
export function resolveComment(comment, messages = {}) {
  return tc(axios.patch(`/{organization}/comments/${comment.id}/resolve`).then(response => {
    response.data = transformCommentFromRequest(response.data)
    return response
  }), {
    successTitle: 'sdk.success-title',
    successMessage: 'sdk.comment.update-successful',
    failTitle: 'sdk.comment.update-failed',
    ...messages,
  })
}

/**
 *
 * @param {*} commentId
 *
 * @response
 * {
 *  errors: null | Object
 *  success: true | false
 * }
 */
export function deleteComment(commentId, messages = {}) {
  return tc(axios.delete(`/{organization}/comments/${commentId}`), {
    successTitle: 'sdk.success-title',
    successMessage: 'sdk.comment.delete-successful',
    failTitle: 'sdk.comment.delete-failed',
    ...messages,
  })
}
