import moment from 'moment'
import i18n from '@/libs/i18n'
import DatetimeComponent from './Datetime.vue'

export default class Datetime {
  static COMPONENT = DatetimeComponent;

  static OPERATORS = {
    equals: 'eq',
    notEquals: 'ne',
    greaterThan: 'gt',
    lowerThan: 'lt',
    between: 'bt',
    exists: 'exists',
    notExists: 'notExists',
  }

  static type = 'datetime'

  static options = [
    {
      label: i18n.t('filters.is'),
      value: this.OPERATORS.equals,
    },
    {
      label: i18n.t('filters.greater-than'),
      value: this.OPERATORS.greaterThan,
    },
    {
      label: i18n.t('filters.lower-than'),
      value: this.OPERATORS.lowerThan,
    },
    {
      label: i18n.t('filters.between'),
      value: this.OPERATORS.between,
    },
    {
      label: i18n.t('filters.is-not-empty'),
      value: this.OPERATORS.exists,
    },
    {
      label: i18n.t('filters.is-empty'),
      value: this.OPERATORS.notExists,
    },
  ]

  static graphqlType = 'DateTimeFilter'

  operator = null;

  value1 = null;

  value2 = null

  constructor({ operator = Datetime.OPERATORS.equals, value1, value2 } = {}) {
    this.operator = operator
    this.value1 = value1
    this.value2 = value2
  }

  set({ operator = Datetime.OPERATORS.equals, value1, value2 }) {
    this.operator = operator
    this.value1 = value1
    this.value2 = value2
  }

  getCount() {
    return 1
  }

  getValueLabel() {
    switch (this.operator) {
      case Datetime.OPERATORS.equals:
        if (moment(this.value1).year() !== moment().year()) {
          return i18n.t('filters.filter-operator-label.equals', { value: moment(this.value1).format('DD/MM/YYYY') })
        }
        return i18n.t('filters.filter-operator-label.equals', { value: moment(this.value1).format('DD/MM') })

      case Datetime.OPERATORS.notEquals:
        if (moment(this.value1).year() !== moment().year()) {
          return i18n.t('filters.filter-operator-label.not-equals', { value: moment(this.value1).format('DD/MM/YYYY') })
        }
        return i18n.t('filters.filter-operator-label.not-equals', { value: moment(this.value1).format('DD/MM') })

      case Datetime.OPERATORS.greaterThan:
        if (moment(this.value1).year() !== moment().year()) {
          return i18n.t('filters.filter-operator-label.gt', { value: moment(this.value1).format('DD/MM/YYYY') })
        }
        return i18n.t('filters.filter-operator-label.gt', { value: moment(this.value1).format('DD/MM') })

      case Datetime.OPERATORS.lowerThan:
        if (moment(this.value1).year() !== moment().year()) {
          return i18n.t('filters.filter-operator-label.lt', { value: moment(this.value1).format('DD/MM/YYYY') })
        }
        return i18n.t('filters.filter-operator-label.lt', { value: moment(this.value1).format('DD/MM') })

      case Datetime.OPERATORS.between:
        // if year is different than the current year, show it
        if (moment(this.value1).year() !== moment().year()) {
          return i18n.t('filters.filter-operator-label.bt', { value1: moment(this.value1).format('DD/MM/YYYY'), value2: moment(this.value2).format('DD/MM/YYYY') })
        }
        // If years are the same, don't show them
        if (moment(this.value1).year() === moment(this.value2).year()) {
          return i18n.t('filters.filter-operator-label.bt', { value1: moment(this.value1).format('DD/MM'), value2: moment(this.value2).format('DD/MM') })
        }
        return i18n.t('filters.filter-operator-label.bt', { value1: moment(this.value1).format('DD/MM/YYYY'), value2: moment(this.value2).format('DD/MM/YYYY') })

      case Datetime.OPERATORS.exists:
        return i18n.t('filters.is-not-empty')

      case Datetime.OPERATORS.notExists:
        return i18n.t('filters.is-empty')

      default:
        return null
    }
  }

  asQueryParam() {
    if (this.operator === Datetime.OPERATORS.exists || this.operator === Datetime.OPERATORS.notExists) {
      return {
        operator: this.operator,
      }
    }
    return {
      operator: this.operator,
      value1: this.value1,
      value2: this.value2,
    }
  }

  asGraphQL() {
    switch (this.operator) {
      case Datetime.OPERATORS.equals:
        return {
          gt: moment(this.value1).startOf('day').toISOString(),
          lt: moment(this.value1).endOf('day').toISOString(),
        }
      case Datetime.OPERATORS.notEquals:
        return {
          ne: this.value1,
        }
      case Datetime.OPERATORS.greaterThan:
        return {
          gt: moment(this.value1).endOf('day').toISOString(),
        }
      case Datetime.OPERATORS.lowerThan:
        return {
          lt: moment(this.value1).startOf('day').toISOString(),
        }
      case Datetime.OPERATORS.between:
        return {
          gt: moment(this.value1).startOf('day').toISOString(),
          lt: moment(this.value2).endOf('day').toISOString(),
        }
      case Datetime.OPERATORS.exists:
        return {
          exists: true,
        }
      case Datetime.OPERATORS.notExists:
        return {
          exists: false,
        }
      default:
        return null
    }
  }

  asGraphQLDynamicList() {
    if (this.asGraphQL()) {
      return {
        ...this.asGraphQL(),
        _t: Datetime.graphqlType,
      }
    }
    return null
  }

  static validate({ operator, value1, value2 }) {
    if (!operator) {
      return 'Operator is required'
    }

    if (operator === Datetime.OPERATORS.exists || operator === Datetime.OPERATORS.notExists) {
      return true
    }

    if (!value1) {
      return 'Value 1 is required'
    }

    if (operator === Datetime.OPERATORS.between && !value2) {
      return 'Value 2 is required because operator is "between"'
    }

    return true
  }

  static fromQueryParam({ operator, value1, value2 }) {
    return new Datetime({
      operator,
      value1,
      value2,
    })
  }

  static shouldApplyPredefinedValue(filter) {
    const forOperators = [Datetime.OPERATORS.equals]
    return forOperators.includes(filter.operator)
  }
}
