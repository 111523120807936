<template>
  <b-form-group class="date-time__field">
    <template #label>
      <FieldName
        :name="name"
        :help-text="helpText"
        :help-text-display="helpTextDisplay"
        :required="required"
      />
    </template>
    <div class="date-time__field--input">
      <flat-pickr
        :key="`${format}${timeMode}`"
        v-model="accessDate"
        class="form-control dashboard-overview__calendar datepicker"
        :config="config"
        :disabled="readOnly || !editable"
      />
      <span class="date-time__calendar-icon">
        <GjIcon name="Calendar" />
      </span>
    </div>
    <FieldError :error="error" />
  </b-form-group>
</template>

<script>
import { generateComputedPropsFromAttrs } from '@/components/codex-layout-editor/BuilderUtils'
import { DATE_FORMAT, DEFAULT_OPTION, TIME_FORMAT } from '@/components/fields/DateAndTime/index'
import moment from 'moment'
import { FIELD_FILTER_OPERATORS, RANGE_OPERATORS } from '@/views/models/constants'
import FieldName from '@/components/fields/FieldName.vue'
import Datetime from '@/components/filters-dropdown/filters/datetime/DatetimeFilter'
import BaseFieldMixin from '@/components/fields/BaseFieldMixin'
import FieldRenderMixin from '@/components/fields/RenderFieldMixin'
import FieldError from '@/components/fields/FieldError.vue'

export default {
  inject: ['filterValues', 'entryId'],
  components: {
    FieldError,
    FieldName,
  },
  mixins: [BaseFieldMixin, FieldRenderMixin],
  props: {
    value: {
      type: [String, Number, Array, Object],
      default: null,
    },
  },
  data() {
    return {
      DATE_FORMAT,
      TIME_FORMAT,
    }
  },
  computed: {
    ...generateComputedPropsFromAttrs([
      'alias',
      'name',
      'validation',
      'appearance',
      'helpText',
      'helpTextDisplay',
      'format',
      'timeMode',
      'defaultOption',
    ]),
    accessDate: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit('input', moment(v).utc(this.format === DATE_FORMAT.DATE).toISOString())
      },
    },
    config() {
      let altFormat = 'd/m/Y'
      if (this.format === DATE_FORMAT.DATETIME) {
        altFormat = this.timeMode === TIME_FORMAT.H24 ? 'd/m/Y H:i' : 'd/m/Y h:i K'
      }

      return {
        enableTime: this.format === DATE_FORMAT.DATETIME,
        altFormat,
        time_24hr: this.timeMode === TIME_FORMAT.H24,
      }
    },
  },
  beforeMount() {
    if (!this.widget.attrs.hidden) {
      this.$set(this.widget.attrs, 'hidden', {
        value: false,
        conditionsEnabled: false,
        conditions: [
          {
            isSystem: false,
            field: '',
            operator: FIELD_FILTER_OPERATORS.EXISTS,
            value: '',
          },
        ],
      })
    }
  },
  mounted() {
    if (this.entryId() === 'create' && !this.readOnly && this.editable) {
      // Prefill from filter
      let prefilledFromFilter = false
      if (this.filterValues && this.filterValues?.[this.alias] && Datetime.shouldApplyPredefinedValue(this.filterValues[this.alias])) {
        this.accessDate = moment(this.filterValues[this.alias].value1)
        prefilledFromFilter = true
      }
      // \Prefill from filter

      if (this.defaultOption === DEFAULT_OPTION.NOW && !prefilledFromFilter) {
        this.accessDate = moment()
      }
    }
  },
  methods: {
    validate(value) {
      const validationForm = this.format === DATE_FORMAT.DATE ? 'day' : undefined

      if (this.required && !value) {
        return { isValid: false, message: this.validation.required.errorMessage }
      }

      if (this.validation.range.isEnabled) {
        const currentValue = this.format === DATE_FORMAT.DATE ? moment(value).utc(true) : moment(value).utc(true)
        const min = this.format === DATE_FORMAT.DATE ? moment(this.validation.range.min).utc(true) : moment(this.validation.range.min).utc(true)
        const max = this.format === DATE_FORMAT.DATE ? moment(this.validation.range.max).utc(this) : moment(this.validation.range.max).utc(true)

        if (this.validation.range.rangeOperator === RANGE_OPERATORS.BETWEEN && (currentValue.isBefore(min, validationForm) || currentValue.isAfter(max, validationForm))) {
          return { isValid: false, message: this.validation.range.errorMessage }
        }
        if (this.validation.range.rangeOperator === RANGE_OPERATORS.GTE && currentValue.isBefore(min, validationForm)) {
          return { isValid: false, message: this.validation.range.errorMessage }
        }
        if (this.validation.range.rangeOperator === RANGE_OPERATORS.LTE && currentValue.isAfter(max, validationForm)) {
          return { isValid: false, message: this.validation.range.errorMessage }
        }
      }
      return { isValid: true }
    },
  },
}
</script>

<style lang="scss">
.date-time__field{
  .date-time__field--input {
    position: relative;
  }
  .date-time__calendar-icon {
    display: flex;
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
  }
}

</style>
