<template>
  <div class="new-comment">
    <MentionsInput
      id="at-articles-popup-comments-input"
      ref="commentInput"
      v-model="comment.content"
      :placeholder="$t('codex-editor.panels.wrapper-panel.comments-placeholder')"
      class="new-comment__input"
      @mentions="mentions => comment.mentions = mentions"
    />

    <div class="new-comment__actions">
      <b-button
        id="at-articles-popup-comments-cancelButton"
        size="sm"
        variant="outline-dark"
        @click.stop="show = false"
      >
        {{ $t('general.cancel-button') }}
      </b-button>
      <b-button
        id="at-articles-popup-comments-postButton"
        size="sm"
        variant="primary"
        @click.stop="postNewComment"
      >
        <b-spinner
          v-if="isSaving"
          small
          class="prompt-input-append-icon mr-1"
        />
        {{ $t('general.post-button') }}
      </b-button>
    </div>
  </div>
</template>

<script>
import MentionsInput from '@/components/MentionsInput.vue'
import { commentDefaults, createComment } from '@/codex-sdk/comments'

export default {
  name: 'CommentEdit',
  components: { MentionsInput },
  inject: ['entryId'],
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    fieldAlias: {
      type: String,
      default: null,
    },
    blockId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      isSaving: false,
      comment: this.generateDefault(),
    }
  },
  computed: {
    threadId() {
      return this.entryId()
    },
    show: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit('input', v)
      },
    },
  },
  watch: {
    value: {
      handler(v) {
        if (v) {
          this.comment = this.generateDefault()
          if (this.$refs.commentInput) {
            this.$refs.commentInput.$refs.editable.innerText = ''
          }
          this.$nextTick(() => {
            if (this.$refs.commentInput) {
              this.$refs.commentInput.$refs.editable.focus()
            }
          })
        }
      },
      immediate: true,
    },
  },
  methods: {
    generateDefault() {
      const attrs = {}
      if (this.fieldAlias) {
        attrs.fieldAlias = this.fieldAlias
      }
      if (this.blockId) {
        attrs.blockId = this.blockId
      }
      return commentDefaults({ attrs, threadId: this.threadId })
    },
    async postNewComment() {
      if (!this.comment.content?.length || this.isSaving) return
      this.isSaving = true

      try {
        this.$emit('saving', true)
        this.$emit('addComment', this.comment)
        await createComment(this.comment)
        this.comment = this.generateDefault()
        this.$refs.commentInput.$refs.editable.innerText = ''
        this.$emit('saving', false)
        this.$root.$emit('refreshComments', true)
      } catch (err) {
        console.log(err)
      }
      this.isSaving = false
    },
  },
}
</script>

<style lang="scss">
.new-comment {
  width: 300px;
  padding: 16px;
  border-top: 1px solid #e0e5eb;
}

.main-panel-wrapper__new-comment {
  padding: 16px;
}

.new-comment__input {
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #031C3C;
  border: none;
  overflow: hidden !important;
  // padding-top: 16px;

  &::placeholder {
    font-style: italic;
    color: #A3B0C2;
  }
}

.new-comment__actions {
  padding-top: 8px;
  // border-top: 1px solid #E0E5EB;
  display: flex;
  justify-content: flex-end;

  > .btn {
    margin-left: 8px;
  }
}
</style>
