/* eslint-disable no-unused-vars */
import { computed, watch, ref } from '@vue/composition-api'

export default {
  data() {
    const results = { codex_permissions_: { watchers: [] } }

    // Check if component has permissions object defined
    const permissions = this.$options.permissions

    // If permissions object is defined, loop through each permission and add it to data object
    if (permissions) {
      Object.entries(permissions).forEach(([key, value]) => {
        results[key] = null
      })
    }

    return results
  },

  mounted() {
    const permissions = this.$options.permissions
    if (permissions) {
      Object.entries(permissions).forEach(async ([key, value]) => {
        this.codex_permissions_.watchers.push(this.$watch(
          () => (typeof value.variables === 'function' ? value.variables.call(this) : value.variables),
          async val => {
            try {
              this[key] = await value.handler(val)
            } catch (e) {
              console.log(e)
            }
          },
          { deep: true, immediate: true },
        ))
      })
    }
  },

  beforeDestroy() {
    if (!this.codex_permissions_) return
    this.codex_permissions_.watchers.forEach(watcher => watcher())
  },
}
