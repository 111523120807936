export function YouTubeGetID(url) {
  let ID = ''
  url = url.replace(/(>|<)/gi, '').split(/(vi\/|v=|live\/|\/v\/|youtu\.be\/|\/embed\/)/)
  if (url[2] !== undefined) {
    // eslint-disable-next-line
    ID = url[2].split(/[^0-9a-z_\-]/i)
    // eslint-disable-next-line prefer-destructuring
    ID = ID[0]
  } else {
    ID = url
  }
  return ID
}
