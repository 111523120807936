<template>
  <div>
    <b-form-radio-group
      v-model="appearance"
      class="boolean-field"
    >
      <label
        class="boolean-field__appearance"
        for="boolean-field__appearance-radio"
      >
        <div class="boolean-field__icon-text">
          <GjIcon
            name="Video"
            class="boolean-field__icon"
            size="24"
            style="fill: #1D79F2"
          />
          <span class="boolean-field__text">
            {{ $t('fields.codex-field-boolean.appearance.radio') }}
          </span>
        </div>
        <b-form-radio
          id="boolean-field__appearance-radio"
          name="radio"
          :value="BOOLEAN_FIELD_APPEARANCES.RADIO"
          class="boolean-field__radio"
        />
      </label>
      <label
        class="boolean-field__appearance"
        for="boolean-field__appearance-checkbox"
      >
        <div class="boolean-field__icon-text">
          <GjIcon
            name="Check"
            class="boolean-field__icon"
            size="24"
            style="fill: #1D79F2"
          />
          <span class="boolean-field__text">
            {{ $t('fields.codex-field-boolean.appearance.checkbox') }}
          </span>
        </div>
        <b-form-radio
          id="boolean-field__appearance-checkbox"
          name="radio"
          :value="BOOLEAN_FIELD_APPEARANCES.CHECKBOX"
          class="boolean-field__radio"
        />
      </label>
      <label
        class="boolean-field__appearance"
        for="boolean-field__appearance-switch"
      >
        <div class="boolean-field__icon-text">
          <GjIcon
            name="Switch"
            class="boolean-field__icon"
            size="24"
            style="fill: #1D79F2"
          />
          <span class="boolean-field__text">
            {{ $t('fields.codex-field-boolean.appearance.toggle-switch') }}
          </span>
        </div>
        <b-form-radio
          id="boolean-field__appearance-switch"
          name="radio"
          :value="BOOLEAN_FIELD_APPEARANCES.SWITCH"
          class="boolean-field__radio"
        />
      </label>
    </b-form-radio-group>

    <FieldHelpTextInput
      :widget="widget"
      :update-attrs="updateAttrs"
    />

    <b-form-group
      v-if="appearance !== BOOLEAN_FIELD_APPEARANCES.RADIO"
      :label="$t('fields.codex-field-boolean.appearance.true-custom-label')"
    >
      <b-form-input
        v-model="checkboxLabel"
        type="text"
      />
    </b-form-group>

    <div
      v-if="appearance === BOOLEAN_FIELD_APPEARANCES.RADIO"
      class="boolean__true-text"
    >
      <label>{{ $t('fields.codex-field-boolean.appearance.true-custom-label') }}</label>
      <b-form-input
        v-model="trueCustomLabel"
        type="text"
      />
    </div>
    <div
      v-if="appearance === BOOLEAN_FIELD_APPEARANCES.RADIO"
      class="boolean__false-text"
    >
      <label>{{ $t('fields.codex-field-boolean.appearance.false-custom-label') }}</label>
      <b-form-input
        v-model="falseCustomLabel"
        type="text"
      />
    </div>
    <div class="boolean__default-value">
      {{ $t('fields.codex-field-boolean.appearance.default-value') }}
      <p class="info">
        {{ $t('fields.codex-field-boolean.appearance.default-value-info') }}
      </p>
      <b-form-radio-group
        v-model="defaultValue"
      >
        <b-form-radio :value="true">
          {{ $t('fields.codex-field-boolean.appearance.true-value-label') }}
        </b-form-radio>
        <b-form-radio :value="false">
          {{ $t('fields.codex-field-boolean.appearance.false-value-label') }}
        </b-form-radio>
        <b-button
          v-if="appearance === BOOLEAN_FIELD_APPEARANCES.RADIO"
          class="button_clear"
          variant="flat_primary"
          @click="defaultValue = null"
        >
          {{ $t('general.clear') }}
        </b-button>
      </b-form-radio-group>
    </div>
    <FieldConditions
      v-model="hidden"
      :widget="widget"
      :label="$t('fields.general.hidden.label')"
      :show-error-message="false"
      :tooltip="$t('fields.general.hidden.info')"
    />
  </div>
</template>

<script>
import { generateComputedPropsFromAttrs } from '@/components/codex-layout-editor/BuilderUtils'
import FieldHelpTextInput from '@/components/fields/FieldHelpTextInput.vue'
import BaseFieldMixin from '@/components/fields/BaseFieldMixin'
import { BOOLEAN_FIELD_APPEARANCES } from '@/views/models/constants'
import FieldConditions from '@/components/fields/FieldConditions.vue'

export default {
  components: { FieldConditions, FieldHelpTextInput },
  mixins: [BaseFieldMixin],
  data() {
    return {
      BOOLEAN_FIELD_APPEARANCES,
    }
  },
  computed: {
    ...generateComputedPropsFromAttrs([
      'appearance',
      'validation',
      'trueCustomLabel',
      'falseCustomLabel',
      'checkboxLabel',
      'defaultValue',
      'helpText',
      'hidden',
    ]),
  },
  watch: {
    appearance(v) {
      if (v !== BOOLEAN_FIELD_APPEARANCES.RADIO) {
        this.defaultValue = false
      }
    },
  },
}

</script>

<style lang="scss" scoped>
.help-message{
  font-weight: 600;
}
.info{
  margin-top: 5px;
  font-size: 14px;
  color: #667C99;

  opacity: .6;
}
.boolean__true-text{
  margin-top:20px
}
.boolean__false-text{
  margin-top:20px
}
.boolean__default-value{
  margin-top:20px
}
.button_clear{
  margin-left: 15px;
}
.button_clear:hover{
  cursor: pointer;
  color: rgb(63, 63, 199);
}
.boolean-field{
  margin-bottom: 20px;
}
.boolean-field__appearance {
  width: 100%;
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 4px 8px;
  border: 1px solid #E0E5EB;
  border-radius: 4px;
  margin-bottom: 8px;
}
.boolean-field__icon-text {
  display: flex;
  flex-direction: row;
}
.boolean-field__icon {
  box-shadow: 0px 4px 8px rgba(163, 176, 194, 0.32);
  border-radius: 3px;
}
.boolean-field__text {
  margin-top: 0.2rem;
  margin-bottom: 0;
  margin-left: 12px;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #052D61;
}
.boolean-field__radio {
  width: 16px;
  height: 16px;
  margin-right: -10px;

}
.boolean-field__help-text {
  margin-top: 24px;
}
.boolean-field__textarea {
  width: 100%;
  padding: 12px;
  border-radius: 4px;
  border: 1px solid #E0E5EB;
  resize: none;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  background-color: #fff;
  outline: none;
}
</style>
