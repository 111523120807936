<template>
  <div class="toolbar-right__tab-container">
    <template v-if="!loaded">
      <div class="d-flex justify-content-center">
        <b-spinner
          :label="$t('general.loading')"
          variant="primary"
        />
      </div>
    </template>
    <template v-else>
      <h6 class="toolbar-title">
        {{ $t('assets.edit-image.crop-image.title') }}
      </h6>

      <b-form-radio
        v-model="cropPreference"
        :value="CROP_PREFERENCES.PRESETS"
        class="mt-1"
      >
        {{ $t('assets.edit-image.crop-image.presets') }}
      </b-form-radio>

      <div class="mb-1 toolbar-form__margin-top">
        <vSelect
          v-model="selectedPreset"
          class="toolbar-crop__preset-select"
          :options="presetOptions"
          :reduce="(v) => v.type"
          :disabled="cropPreference !== CROP_PREFERENCES.PRESETS"
          :clearable="false"
        />
      </div>

      <b-form-radio
        v-model="cropPreference"
        :value="CROP_PREFERENCES.FIXED_RATIO"
        class="mt-1"
      >
        {{ $t('assets.edit-image.crop-image.fixed-ratio') }}
      </b-form-radio>

      <div class="d-flex toolbar-form__margin-top">
        <b-form-group
          class="toolbar-input__group w-100"
          :label="$t('assets.edit-image.crop-image.ratio')"
        >
          <b-form-input
            v-model.number="ratio.width"
            :disabled="isntFixedRatio"
            type="number"
            @keydown.delete.stop
          />
          <span class="toolbar-input__ratio-separate">:</span>
          <b-form-input
            v-model.number="ratio.height"
            :disabled="isntFixedRatio"
            type="number"
            @keydown.delete.stop
          />
        </b-form-group>
      </div>

      <b-form-radio
        v-model="cropPreference"
        :value="CROP_PREFERENCES.FREE"
      >
        {{ $t('assets.edit-image.crop-image.free-selection') }}
      </b-form-radio>

      <b-form-radio
        v-model="cropPreference"
        :value="CROP_PREFERENCES.RESIZE"
        class="mt-1"
      >
        {{ $t('assets.edit-image.crop-image.resize') }}
      </b-form-radio>

      <div
        v-if="cropPreference === CROP_PREFERENCES.RESIZE"
        class="ml-1"
      >
        <b-form-radio
          v-model="resizePreference"
          :value="1"
          class="mt-1"
        >
          {{ $t('assets.edit-image.crop-image.custom') }}
        </b-form-radio>
        <div
          v-if="cropPreference === CROP_PREFERENCES.RESIZE"
          class="toolbar-resize d-flex toolbar-form__margin-top"
        >
          <b-form-group
            class="toolbar-input__group"
            :label="$t('assets.edit-image.image-properties.width')"
            label-for="toolbar-resize__input-width"
            :data-placeholder="$t('assets.edit-image.image-properties.px')"
          >
            <b-form-input
              id="toolbar-resize__input-width"
              v-model.number="resizeDimensions.width"
              type="number"
              :min="DIMENSION_LIMITS.MIN"
              :max="DIMENSION_LIMITS.MAX"
              class="toolbar-input__resize"
              :disabled="resizePreference !== CROP_PREFERENCES.FREE"
              @blur="validateDimensions(resizeDimensions.width, 'width')"
            />
          </b-form-group>
          <b-form-group
            class="toolbar-input__group"
            :label="$t('assets.edit-image.image-properties.height')"
            label-for="toolbar-resize__input-height"
            :data-placeholder="$t('assets.edit-image.image-properties.px')"
          >
            <b-form-input
              id="toolbar-resize__input-height"
              v-model.number="resizeDimensions.height"
              type="number"
              :min="DIMENSION_LIMITS.MIN"
              :max="DIMENSION_LIMITS.MAX"
              class="toolbar-input__resize"
              :disabled="resizePreference !== CROP_PREFERENCES.FREE"
              @blur="validateDimensions(resizeDimensions.height, 'height')"
            />
          </b-form-group>
        </div>

        <b-form-radio
          v-model="resizePreference"
          :value="CROP_PREFERENCES.PRESETS"
          class="mt-1"
        >
          {{ $t('assets.edit-image.crop-image.aspect-ratio') }}
        </b-form-radio>

        <div class="d-flex toolbar-form__margin-top">
          <b-form-group
            class="toolbar-input__group w-100"
            :label="$t('assets.edit-image.crop-image.ratio')"
          >
            <b-form-input
              v-model.number="resizeRatio.width"
              type="number"
              :disabled="resizePreference !== CROP_PREFERENCES.PRESETS"
            />
            <span class="toolbar-input__ratio-separate">:</span>
            <b-form-input
              v-model.number="resizeRatio.height"
              type="number"
              :disabled="resizePreference !== CROP_PREFERENCES.PRESETS"
            />
          </b-form-group>
        </div>

      </div>

      <p
        class="toolbar-reset__button"
        @click="handleCancel"
      >
        {{ $t('assets.edit-image.crop-image.reset') }}
      </p>
    </template>
  </div>
</template>

<script>
import { IMAGE_EDITOR_TABS } from '@/components/image-editor/constants'

export default {
  name: 'Crop',
  props: ['imageEditor', 'reference', 'loaded', 'tabIndex', 'stack'],
  data() {
    const CROP_PREFERENCES = Object.freeze({
      FREE: 1,
      PRESETS: 2,
      FIXED_RATIO: 3,
      RESIZE: 4,
    })
    const DIMENSION_LIMITS = Object.freeze({
      MIN: 100,
      MAX: 3840,
    })
    return {
      cropPreference: CROP_PREFERENCES.PRESETS,
      resizePreference: 1,

      CROP_PREFERENCES,
      DIMENSION_LIMITS,
      dimensions: {
        width: null,
        height: null,
      },
      ratio: {
        width: 16,
        height: 9,
      },

      resizeDimensions: {
        height: null,
        width: null,
      },
      resizeRatio: {
        height: null,
        width: null,
      },

      selectedPreset: 6,
      historyIteration: this.stack.undo || 0,
    }
  },
  computed: {
    isntFixedRatio() {
      return this.cropPreference !== this.CROP_PREFERENCES.FIXED_RATIO
    },
    presetOptions() {
      return [
        { label: this.$t('assets.edit-image.crop-image.custom-image', { width: 16, height: 9 }), type: 6 },
        { label: this.$t('assets.edit-image.crop-image.custom-image', { width: 2, height: 3 }), type: 7 },
        { label: this.$t('assets.edit-image.crop-image.facebook-image'), type: 1 },
        { label: this.$t('assets.edit-image.crop-image.facebook-link'), type: 2 },
        { label: this.$t('assets.edit-image.crop-image.facebook-story'), type: 3 },
        { label: this.$t('assets.edit-image.crop-image.instagram-post'), type: 4 },
        { label: this.$t('assets.edit-image.crop-image.instagram-story'), type: 5 },
      ]
    },
  },
  watch: {
    ratio: {
      handler() {
        this.setFixedRatio()
      },
      deep: true,
    },
    loaded() {
      if (this.loaded) {
        this.getCanvasDimensions()
        this.loadCrop()
      }
    },
    tabIndex() {
      this.historyIteration = this.stack.undo
      this.loadCrop()
    },

    cropPreference(val) {
      this.handleCropzoneChange(val)
    },
    selectedPreset(val) {
      this.handleSocialMediaPreset(val)
    },
    // Resize
    resizeRatio: {
      deep: true,
      handler() { this.handleResizeRatio() },
    },
  },
  methods: {
    loadCrop() {
      if (this.tabIndex !== IMAGE_EDITOR_TABS.CROP) {
        if (this.imageEditor.getDrawingMode() === 'CROPPER') {
          this.imageEditor.stopDrawingMode()
        }

        return
      }
      this.handleSocialMediaPreset(this.selectedPreset)
      this.setCropZone()
    },
    validateDimensions(v, type) {
      let value = v
      if (!v) value = this.DIMENSION_LIMITS.MIN
      if (v <= this.DIMENSION_LIMITS.MIN) value = this.DIMENSION_LIMITS.MIN
      if (v >= this.DIMENSION_LIMITS.MAX) value = this.DIMENSION_LIMITS.MAX
      this.resizeDimensions[type] = value

      this.imageEditor.resize({
        width: this.resizeDimensions.width,
        height: this.resizeDimensions.height,
      })
    },
    setCropZone(aspectRatio) {
      if (this.imageEditor.getDrawingMode() !== 'CROPPER') {
        this.imageEditor.stopDrawingMode()
        this.imageEditor.startDrawingMode('CROPPER', { width: 50 })
      }

      this.imageEditor.setCropzoneRect(aspectRatio || null)
    },
    setFixedRatio() {
      const { width, height } = this.ratio
      this.setCropZone(width / height)
    },
    handleCropzoneChange(val) {
      switch (val) {
        case this.CROP_PREFERENCES.FREE: {
          this.setCropZone()
          break
        }
        case this.CROP_PREFERENCES.PRESETS: {
          this.handleSocialMediaPreset(this.selectedPreset)
          break
        }
        case this.CROP_PREFERENCES.FIXED_RATIO: {
          this.setFixedRatio()
          break
        }
        case this.CROP_PREFERENCES.RESIZE: {
          this.imageEditor.stopDrawingMode()
          break
        }

        default: {
          this.setCropZone()
          break
        }
      }
    },
    handleSocialMediaPreset(val) {
      let dimensions

      switch (val) {
        case 1:
        case 2: {
          dimensions = { width: 1.91, height: 1 }
          break
        }

        case 5:
        case 3: {
          dimensions = { width: 9, height: 16 }
          break
        }
        case 4: {
          dimensions = { width: 1, height: 1 }
          break
        }
        case 6: {
          dimensions = { width: 16, height: 9 }
          break
        }
        case 7: {
          dimensions = { width: 2, height: 3 }
          break
        }

        default: {
          dimensions = { width: null, height: null }
          break
        }
      }

      this.ratio = dimensions
      this.setFixedRatio()
    },

    async handleCrop() {
      const crop = this.imageEditor.getCropzoneRect()
      this.imageEditor.stopDrawingMode('CROPPER')
      this.imageEditor.crop(crop)
      this.setCropZone()
    },

    async handleCancel() {
      try {
        await this.imageEditor.undo(this.stack.undo - this.historyIteration)
      } catch (e) {
        console.log(e)
      }

      this.historyIteration = this.stack.undo

      this.$nextTick(() => {
        this.imageEditor.clearObjects()
        this.cropPreference = this.CROP_PREFERENCES.PRESETS

        this.resizeDimensions = {
          width: 16,
          height: 9,
        }
        this.handleSocialMediaPreset(this.selectedPreset)

        this.getCanvasDimensions()
      })
    },

    // resize

    getCanvasDimensions() {
      this.resizeDimensions = this.imageEditor.getCanvasSize()
    },
    handleResizeRatio() {
      const { height, width } = this.resizeRatio

      if (width && height) {
        const canvasDimensions = this.imageEditor.getCanvasSize()
        this.resizeDimensions = { ...this.resizeDimensions, height: canvasDimensions.width / (width / height) }
        this.imageEditor.resize(this.resizeDimensions)
      }
    },
  },
}
</script>
