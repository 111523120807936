export default {
  namespaced: true,
  state: {
    focusStatus: false, onEntryEditor: false,
  },
  getters: {
    focusGetter: state => state.focusStatus,
    onEntryEditorGetter: state => state.onEntryEditor,
  },
  mutations: {
    SET_FOCUS(state, val) { state.focusStatus = val },
    SET_ONENTRY(state, val) { state.onEntryEditor = val },
  },
  actions: {},
}
