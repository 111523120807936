<template>
  <div class="rich-content__blockquote">
    <blockquote
      :class="`blockquote ${block.type === CODEX_EDITOR_BLOCKS.FACTBOX ? 'blockquote-factbox' : ''}`"
    >
      <component
        :is="resolveRichContentComponent(item.type)"
        v-for="(item, index) in block.content"
        :key="`${block.attrs.blockId}-${index}`"
        v-bind="getCustomProps(item, isNestedItem = true)"
      />
    </blockquote>
  </div>
</template>

<script>
import { CODEX_EDITOR_BLOCKS } from '@/components/codex-editor/CodeEditorConstants'
import { resolveRichContentComponent, getCustomProps } from '../richContentUtils'

export default {
  name: 'BlockquoteComponent',
  components: {
    TextComponent: () => import('./TextComponent.vue'),
    ListComponent: () => import('./ListComponent.vue'),
    DefaultComponent: () => import('./DefaultComponent.vue'),
    ReferenceField: () => import('../ReferenceField.vue'),
    MediaField: () => import('../MediaField.vue'),
    LocationField: () => import('../LocationField.vue'),
    TableComponent: () => import('./TableComponent.vue'),
  },
  props: {
    block: {
      type: [Object],
    },
  },
  computed: {
    CODEX_EDITOR_BLOCKS() {
      return CODEX_EDITOR_BLOCKS
    },
  },
  setup() {
    return {
      resolveRichContentComponent,
      getCustomProps,
    }
  },
}
</script>

<style lang="scss">
.rich-content__blockquote .blockquote {
  position: relative;
  font-size: 16px;
  padding: 20px 0 0 30px;
  margin: 0;

  &::before {
    content: url('data:image/svg+xml; utf8, <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path fill="grey" d="M11 9.275c0 5.141-3.892 10.519-10 11.725l-.984-2.126c2.215-.835 4.163-3.742 4.38-5.746-2.491-.392-4.396-2.547-4.396-5.149 0-3.182 2.584-4.979 5.199-4.979 3.015 0 5.801 2.305 5.801 6.275zm13 0c0 5.141-3.892 10.519-10 11.725l-.984-2.126c2.215-.835 4.163-3.742 4.38-5.746-2.491-.392-4.396-2.547-4.396-5.149 0-3.182 2.584-4.979 5.199-4.979 3.015 0 5.801 2.305 5.801 6.275z"/></svg>');
    position: absolute;
    top: 5px;
    left: 0;
  }

  p {
    margin-bottom: 1rem;
  }

  small {
    margin-left: 15px;
    font-size: 16px;
  }

  small::before {
    content: " - ";
  }
}

.rich-content__blockquote .blockquote-factbox {
  padding: 15px;
  background: #f9f9f9;

  &::before {
    display: none;
  }

  p {
    margin: 0;
  }
}
</style>
