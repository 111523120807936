import { Link } from 'tiptap-extensions'
import { updateMark, removeMark, pasteRule } from 'tiptap-commands'

export default class CustomLink extends Link {
  get name() {
    return 'link'
  }

  get defaultOptions() {
    return {
      openOnClick: true,
      target: null,
    }
  }

  get schema() {
    return {
      attrs: {
        href: {
          default: null,
        },
        target: {
          default: null,
        },
        quickLinkId: {
          default: null,
        },
        entryId: {
          default: null,
        },
        noopener: {
          default: null,
        },
        nofollow: {
          default: null,
        },
        noreferrer: {
          default: null,
        },
      },
      inclusive: false,
      parseDOM: [{
        tag: 'a[href]',
        getAttrs: dom => ({
          href: dom.getAttribute('href'),
          target: dom.getAttribute('target'),
          quickLinkId: dom.getAttribute('data-quick-link-id'),
          entryId: dom.getAttribute('data-entry-id'),
        }),
      }],
      toDOM: node => ['a', {
        ...node.attrs,
        rel: `${node.attrs.noopener ? 'noopener ' : ''}${node.attrs.nofollow ? 'nofollow ' : ''}${node.attrs.noreferrer ? 'noreferrer ' : ''}`,
        target: node.attrs.target || this.options.target,
        'data-quick-link-id': node.attrs.quickLinkId,
        'data-entry-id': node.attrs.entryId,
      }, 0],
    }
  }

  commands({
    type,
  }) {
    return attrs => {
      if (attrs.href) {
        return updateMark(type, attrs)
      }

      return removeMark(type)
    }
  }

  pasteRules({
    type,
  }) {
    return [pasteRule(/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z]{2,}\b([-a-zA-Z0-9@:%_+.~#?&//=,()!]*)/gi, type, url => ({
      href: url,
    }))]
  }
}
