<template>
  <div>
    <b-form-group
      :label="$t('fields.codex-field-text.general.field-name')"
      label-for="text-field__name"
    >
      <b-form-input
        id="text-field__name"
        v-model="name"
        :placeholder="$t('fields.codex-field-text.general.enter-field-modal-name')"
      />
    </b-form-group>

    <b-form-group
      :label="$t('fields.codex-field-text.general.field-alias')"
      label-for="text-field__alias"
    >
      <b-form-input
        id="text-field__alias"
        :value="alias"
        disabled
        :placeholder="$t('fields.codex-field-text.general.enter-field-alias-name')"
      />
    </b-form-group>

    <b-form-group
      :label="$t('fields.codex-field-text.general.description')"
      label-for="text-field__description"
    >
      <b-form-textarea
        id="text-field__description"
        v-model="description"
        :placeholder="$t('fields.codex-field-text.general.enter-description')"
        rows="2"
        no-resize
      />
    </b-form-group>

    <b-form-radio-group
      v-model="previewType"
      disabled
    >
      <b-form-radio :value="VALUE_TYPES.SINGLE">
        {{ $t('fields.codex-field-text.general.single') }}
      </b-form-radio>
      <b-form-radio :value="VALUE_TYPES.LIST">
        {{ $t('fields.codex-field-text.general.list') }}
      </b-form-radio>
    </b-form-radio-group>

  </div>
</template>

<script>
import { generateComputedPropsFromAttrs } from '@/components/codex-layout-editor/BuilderUtils'
import { VALUE_TYPES } from '@/views/models/constants'
import BaseFieldMixin from '@/components/fields/BaseFieldMixin'

export default {
  name: 'General',
  mixins: [BaseFieldMixin],
  data() {
    return {
      VALUE_TYPES,
    }
  },
  computed: {
    ...generateComputedPropsFromAttrs([
      'name',
      'alias',
      'description',
      'valueType',
    ]),
    previewType: {
      get() {
        return this.widget.attrs.valueType
      },
      set() {},
    },
  },
}
</script>
