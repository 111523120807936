<template>
  <FullHeightLayout>
    <section class="merge-tool">
      <div class="merger__header">
        <div class="header__left">
          <div class="header__title">
            <h4>
              {{ $t('entries.merge-tool.current-version') }}
            </h4>
            <b-form-group>
              <b-form-checkbox v-model="showDifferencesOnly">
                {{ $t('entries.merge-tool.show-only-differences') }}
              </b-form-checkbox>
            </b-form-group>
          </div>
        </div>
        <div class="header__right">
          <div class="header__title">
            <h4>
              {{ $t('entries.merge-tool.comparing-version') }}
            </h4>
            <b-form-group>
              <b-form-checkbox
                v-model="allSelected.value"
                @input="val => selectAllChanges(val)"
              >
                {{ $t('entries.merge-tool.select-all-changes') }}
              </b-form-checkbox>
            </b-form-group>
          </div>
        </div>
      </div>
      <div class="merger__content">
        <div
          v-for="field in filteredModelFields"
          :key="field.alias"
          class="merger__field-row"
        >
          <div
            v-if="field.type === TYPES.RICH_CONTENT"
            class="content__wrapper"
          >
            <RichContentField
              :first-entry="firstEntry.content[field.alias]"
              :second-entry="secondEntry.content[field.alias]"
              :field="field"
              :all-selected="allSelected"
              :differences-only="showDifferencesOnly"
            />
          </div>
          <div
            v-else
            class="content__wrapper"
          >
            <div class="merger__field">
              <div class="field__checkbox">
                <b-form-radio
                  v-model="selectedFields[field.alias]"
                  :value="1"
                  :disabled="isEqual(firstEntry.content[field.alias], secondEntry.content[field.alias])"
                />
              </div>
              <component
                :is="resolveFieldTypeComponent(field.type)"
                v-if="Object.keys(firstEntry.content).some(key => key === field.alias)"
                :entry-id="`${firstEntry.id}-A`"
                :field-content="firstEntry.content[field.alias]"
                :field="field"
                class="field__content"
              />
              <EmptyField
                v-else
                :field-name="field.name"
              />
            </div>
            <div class="merger__divider">
              <GjIcon
                name="Switch"
                size="24"
              />
            </div>
            <div class="merger__field">
              <div class="field__checkbox">
                <b-form-radio
                  v-model="selectedFields[field.alias]"
                  :value="2"
                  :disabled="isEqual(firstEntry.content[field.alias], secondEntry.content[field.alias])"
                />
              </div>
              <component
                :is="resolveFieldTypeComponent(field.type)"
                v-if="Object.keys(secondEntry.content).some(key => key === field.alias)"
                :key="secondEntry.system.versionId"
                :entry-id="`${secondEntry.id}-B`"
                :field-content="secondEntry.content[field.alias]"
                :field="field"
                class="field__content"
              />
              <EmptyField
                v-else
                :field-name="field.name"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="merger__footer">
        <b-button
          variant="outline-dark"
          @click="$emit('close')"
        >
          {{ $t('entries.merge-tool.cancel-button-label') }}
        </b-button>
        <b-button
          v-permission="isEntryComparing ? ['canEditEntry', firstEntry ] : ['canEditAsset', { id: firstEntry.assetId }]"
          variant="primary"
          @click="mergeModels()"
        >
          {{ $t('entries.merge-tool.merge-button-label') }}
        </b-button>
      </div>
    </section>
  </FullHeightLayout>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { ref, computed } from '@vue/composition-api'
import store from '@/store'
import isEqual from 'lodash/isEqual'
import { getBlocks, TYPES } from '@/views/models/constants'
import { cloneDeep } from 'lodash'
import FullHeightLayout from '@/../gjirafa-front/script/full-height-layout.vue'
import BooleanField from './field-types/BooleanField.vue'
import DateTimeField from './field-types/DateTimeField.vue'
import JsonField from './field-types/JsonField.vue'
import MediaField from './field-types/MediaField.vue'
import ReferenceField from './field-types/ReferenceField.vue'
import RichContentField from './field-types/RichContentField.vue'
import RichTextField from './field-types/RichTextField.vue'
import TextField from './field-types/TextField.vue'
import AuthorField from './field-types/AuthorField.vue'
import SectionField from './field-types/SectionField.vue'
import TagField from './field-types/TagField.vue'
import UrlField from './field-types/UrlField.vue'
import LabelField from './field-types/LabelField.vue'
import EmptyField from './field-types/EmptyField.vue'
import { resolveFieldTypeComponent } from './MergingUtils'

export default {
  name: 'MergingTool',
  components: {
    /* eslint-disable vue/no-unused-components */
    // field components
    BooleanField,
    DateTimeField,
    JsonField,
    MediaField,
    ReferenceField,
    RichContentField,
    RichTextField,
    TextField,
    AuthorField,
    SectionField,
    TagField,
    UrlField,
    LabelField,
    EmptyField,
    FullHeightLayout,
  },
  setup(_, ctx) {
    const model = computed(() => store.getters['mergingTool/mergingModel'])
    const firstEntry = computed(() => store.getters['mergingTool/firstEntry'])
    const secondEntry = computed(() => store.getters['mergingTool/secondEntry'])
    const selectedFields = computed(() => store.getters['mergingTool/selectedFields'])
    const showDifferencesOnly = ref(false)
    const allSelected = ref({ value: false })
    const isEntryComparing = ref(false)
    isEntryComparing.value = !!model.value.alias

    const valuesDiffer = (first, second) => (JSON.stringify(first) !== JSON.stringify(second))

    const modelFields = computed(() => model.value.fields.filter(field => firstEntry.value.content[field.alias] !== undefined
     || secondEntry.value.content[field.alias] !== undefined))
    const differentFields = computed(() => model.value.fields.filter(field => {
      const isUsed = firstEntry.value.content[field.alias] !== undefined || secondEntry.value.content[field.alias] !== undefined
      const hasDifferences = isUsed ? !isEqual(firstEntry.value.content[field.alias], secondEntry.value.content[field.alias]) : false

      return isUsed && hasDifferences
    }))

    const filteredModelFields = computed(() => (showDifferencesOnly.value ? differentFields.value : modelFields.value))
    const selectAllChanges = value => {
      store.dispatch('mergingTool/selectAllChanges', value)
    }

    const mergeModels = () => {
      if (isEntryComparing.value) {
        const content = cloneDeep(firstEntry.value.content)
        // eslint-disable-next-line guard-for-in,no-restricted-syntax
        for (const value in selectedFields.value) {
          const field = getBlocks(model.value.blocks, block => block.isField && block.attrs.alias == value)?.[0]
          if (field.attrs.type === TYPES.RICH_CONTENT) {
          // eslint-disable-next-line guard-for-in,no-restricted-syntax
            for (const key of Object.keys(selectedFields.value[value])) {
              if (selectedFields.value[value][key] == 2) {
                const index = content[value].findIndex(block => block.attrs.blockId == key)
                content[value].splice(index, 1, secondEntry.value.content[value].find(b => b.attrs.blockId == key))
              }
            }
          }
          if (selectedFields.value[value] == 2) {
            content[value] = secondEntry.value.content[value]
          }
        }
        ctx.emit('mergeData', content)
      } else {
        const content = cloneDeep(firstEntry.value.content)
        // eslint-disable-next-line guard-for-in,no-restricted-syntax
        for (const value in selectedFields.value) {
          if (selectedFields.value[value] == 2) {
            content[value] = secondEntry.value.content[value]
          }
        }
        ctx.emit('mergeData', content)
      }
    }

    return {
      firstEntry,
      secondEntry,
      isEntryComparing,
      TYPES,
      resolveFieldTypeComponent,
      showDifferencesOnly,
      allSelected,
      filteredModelFields,
      selectedFields,
      selectAllChanges,
      valuesDiffer,
      isEqual,
      mergeModels,
    }
  },
}
</script>

<style lang="scss">
.merge-tool {
  height: 100%;

  .merger__header {
    display: flex;
    gap: 60px;
    height: 30px;

    > div {
      flex: 1 1 0;
    }

    .header__title {
      color: #052D61;
      display: flex;
      justify-content: space-between;
    }

    .header__title h4 {
      font-weight: 600;
      font-size: 18px;
      line-height: 24px;
    }
  }

  .merger__content {
    //height: calc(100vh - var(--layout-top) - 150px);
    height: calc(100% - 120px);
    overflow-y: auto;
  }

  .merger__field-row {
    margin-top: 24px;
  }

  .content__wrapper {
    display: flex;
    min-height: 100px;

    p {
      margin: 0;
    }
  }

  .merger__field {
    display: flex;
    gap: 14px;
    flex: 1 1 0;
    background: #FFFFFF;
    box-shadow: 0px 1px 3px rgba(224, 229, 235, 0.7);
    border-radius: 4px;
    padding: 20px 32px 20px 12px;

    .field__content {
      flex-grow: 1;
    }

    .field__checkbox {
      margin: auto 0;
    }
  }

  .merger__divider {
    width: 60px;
    margin: auto 0;
    text-align: center;
    color: #667C99;
  }

  .merger__footer {
    background: #fff;
    padding: 0 32px;
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    align-items: center;
    width: 100%;
    height: 60px;
    margin-top: 10px;

    .go-back__button {
      cursor: pointer;
      display: flex;
      gap: 4px;
      align-items: center;
    }
  }

  // Shared field-types styles
  .content-list__empty {
    background-color: #f6f7f9;
    border: 1px dashed #c8d0da;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px;
    padding: 8px;
    min-height: 100px;
  }
}
</style>
