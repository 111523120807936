import qs from 'qs'
import { tc } from '@/codex-sdk/config'
import store from '@/store'
import { getCurrentOrganizationId, getCurrentSiteId } from '@/services/siteService'
import useJwt from '@/auth/jwt/useJwt'
import i18n from '@/libs/i18n'
import { modelDefaults } from '@/codex-sdk/models'
import { apiAxios as axios } from '../libs/axios'

/**
 * Constants
 */

export const ENTRY_STATUSES = Object.freeze({
  PUBLISHED: 1,
  DELETED: 2,
  DRAFT: 3,
  UNPUBLISHED: 4,
  ARCHIVED: 5,
  SCHEDULED: 6,
  EDITED: 7,
})

export const SCHEDULED_ENTRY_VERSION = Object.freeze({
  SCHEDULED: 'SCHEDULED',
  FAILED: 'FAILED',
  SUCCEEDED: 'SUCCEEDED',
  CANCELED: 'CANCELED',
  DELETED: 'DELETED',
})

export const ENTRY_STATUSES_STRING = Object.freeze({
  [ENTRY_STATUSES.PUBLISHED]: 'PUBLISHED',
  [ENTRY_STATUSES.DELETED]: 'DELETED',
  [ENTRY_STATUSES.DRAFT]: 'DRAFT',
  [ENTRY_STATUSES.UNPUBLISHED]: 'UNPUBLISHED',
  [ENTRY_STATUSES.ARCHIVED]: 'ARCHIVED',
  [ENTRY_STATUSES.SCHEDULED]: 'SCHEDULED',
  [ENTRY_STATUSES.EDITED]: 'EDITED',
})

export const BULK_EDIT_OPTIONS = Object.freeze({
  CHANGE_STATUS: 1,
  DELETE: 2,
  ADD_LABEL: 3,
  REMOVE_LABEL: 4,
  CLONE: 5,
})

export function mapBulkOptions(entity) {
  const entities = {
    [BULK_EDIT_OPTIONS.ADD_LABEL]: 'popups.edit-labels.options.add',
    [BULK_EDIT_OPTIONS.REMOVE_LABEL]: 'popups.edit-labels.options.remove',
  }
  return i18n.t(entities[entity])
}

/**
 * Helpers
 */

export function mapEntryStatus(status) {
  const statuses = {
    [ENTRY_STATUSES.PUBLISHED]: 'entries.statuses.published',
    [ENTRY_STATUSES.DELETED]: 'entries.statuses.deleted',
    [ENTRY_STATUSES.DRAFT]: 'entries.statuses.draft',
    [ENTRY_STATUSES.UNPUBLISHED]: 'entries.statuses.unpublished',
    [ENTRY_STATUSES.ARCHIVED]: 'entries.statuses.archived',
    [ENTRY_STATUSES.SCHEDULED]: 'entries.statuses.scheduled',
    [ENTRY_STATUSES.EDITED]: 'entries.statuses.edited',
  }
  return statuses[status] || 'entries.statuses.draft'
}

export function entryDefaults(entry = {}) {
  return {
    system: {
      siteId: '',
      modelId: '',
      modelAlias: '',
      externalId: '',
      status: ENTRY_STATUSES.DRAFT,
      createdAt: '',
      updatedAt: '',
      publishedAt: '',
      unpublishedAt: '',
      sections: [],
      createdBy: '',
      updatedBy: '',
      versionId: '',
      revisionId: '',
      title: '',
      slug: '',
      labels: [],
      metrics: {
        wordCount: 0,
        characterCount: 0,
        sentenceCount: 0,
        imageCount: 0,
        videoCount: 0,
        filesCount: 0,
        playlistsCount: 0,
        audioCount: 0,
        podcastCount: 0,
        readingTime: 0,
      },
    },
    content: {},
    attrs: {},
    references: [],
    ...entry,
  }
}

/**
 * Transforms to received data
 */
export function transformEntryFromRequest(entry) {
  if (!entry?.system.labels) {
    entry.system.labels = []
  }
  return entry
}

export function transformEntryGraphQL(item) {
  if (item) {
    item.system.model = store?.getters?.['general/getModel']?.(item.system.modelId || item.system.modelAlias) || modelDefaults()
  }
}

export function transformEntriesGraphQL(items, modCallback) {
  if (items?.length) {
    items.forEach(e => {
      if (modCallback && typeof modCallback === 'function') {
        e = modCallback(e)
      }
      return transformEntryGraphQL(e)
    })
  }
}

/**
 *
 * @param {Object} [entry] Entry
 * @param {String} [modelAlias] Model Model Alias
 *
 * @response
 * {
 *  id: GUID
 *  slug: String
 *  errors: null | Object
 *  success: true | false
 * }
 */
export function createEntry(entry, messages) {
  return tc(axios.post('/{organization}/entries', entry, {
    params: {
      model: entry.system.modelAlias,
      siteId: entry.system.siteId,
    },
  }), {
    successTitle: 'sdk.success-title',
    successMessage: 'sdk.entry.create-successful',
    failTitle: 'sdk.entry.create-failed',
    ...messages,
  })
}

/**
 *
 * @param {Object} [entry] Entry
 * @param {String} [modelAlias] Model Alias
 * @param {Boolean} [isAutoSave] Indicates if save is initiated by auto save
 * @param {Boolean} [publishReferences] Determines if non-published/non-scheduled references inside the entry should be published/scheduled
 * @param {Object} [messages] Custom messages for possible responses
 *
 * @response
 * {
 *  id: GUID
 *  errors: null | Object
 *  success: true | false
 * }
 */
export function updateEntry(entry, isAutoSave, publishReferences = false, messages = {}) {
  entry.system.updatedAt = null
  return tc(axios.put(`/{organization}/entries/${entry.id}`, entry, {
    params: {
      model: entry.system.modelAlias,
      isAutoSave,
      publishReferences,
      siteId: entry.system.siteId,
    },
  }).then(response => {
    if (!response.data.errors?.length) { response.data.errors = ['Unknown reasons'] }
    return response
  }), {
    successTitle: 'sdk.success-title',
    successMessage: 'sdk.entry.update-successful',
    failTitle: 'sdk.entry.update-failed',
    catchError: false,
    ...messages,
  })
}

/**
 * @param {String} [modelAlias] Model alias
 * @param {Number} page Page Number
 * @param {Number} pageSize Page Size
 * @param {String} searchTerm Search Term
 * @returns {*}
 */
export function readEntries({
  modelAlias, page = 1, pageSize = 10, searchTerm, statuses = [], ids = [], externalIds = [],
} = {}) {
  return axios.get(`/{organization}/models/${modelAlias}/entries`, {
    params: {
      page,
      size: pageSize,
      q: searchTerm,
      statuses,
      ids,
      externalIds,
    },
    paramsSerializer(params) {
      return qs.stringify(params, { indices: false })
    },
  }).then(response => {
    response.data.data = response.data.data.map(entry => transformEntryFromRequest(entry))
    return response
  })
}

/**
 * @param {String} [model] model.alias
 * @param {String} [id] entry id
 * @returns {Promise<*>}
 */
export function readEntry({ model = '', id = '', siteId } = {}) {
  return axios.get(`/{organization}/entries/${id}`, {
    params: {
      model,
      siteId,
    },
  }).then(response => {
    response.data = transformEntryFromRequest(response.data)
    return response
  })
}

export function lockEntry({
  model = '', id = '', takeOverLock = false, siteId = '',
} = {}) {
  return axios.post(`{organization}/entries/${id}/lock`, {}, {
    params: { model, takeover: takeOverLock, siteId },
  })
}

export function unlockEntry({ model = '', id = '', siteId = '' } = {}) {
  return axios.delete(`{organization}/entries/${id}/lock`, {
    params: { model, siteId },
  })
}

export function deleteEntryLockFetch({ model = '', id = '' } = {}) {
  const siteId = getCurrentSiteId()
  const orgId = getCurrentOrganizationId()
  const token = localStorage.getItem(useJwt.jwtConfig.storageTokenKeyName)
  fetch(`${process.env.VUE_APP_API_BASE_URL}${orgId}/entries/${id}/lock?model=${model}&siteId=${siteId}`, {
    method: 'DELETE',
    keepalive: true,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

export function checkEntryLock({ model = '', id = '', siteId = '' } = {}) {
  return axios.get(`{organization}/entries/${id}/lock`, {
    params: { model, siteId },
  })
}

/**
 * @param {Object} entry Entry
 * @param {String} [modelAlias] Model Alias
 * @param {Array} messages
 * @returns {*}
 */
export function deleteEntry(entry, messages = []) {
  return tc(axios.delete(`/{organization}/entries/${entry.id}`, {
    params: {
      model: entry.system.modelAlias,
      siteId: entry.system.siteId,
    },
  }), {
    successTitle: 'sdk.success-title',
    successMessage: 'sdk.entry.delete-successful',
    failTitle: 'sdk.entry.delete-failed',
    ...messages,
  })
}

export function cloneEntry(id, fromModel, toModel, messages) {
  return tc(axios.post(`/{organization}/entries/${id}/clone`, {
    cloneAsModel: toModel,
  }, {
    params: {
      model: fromModel,
    },
  }), {
    successTitle: 'sdk.success-title',
    successMessage: 'sdk.entry.clone-successful',
    failTitle: 'sdk.entry.clone-failed',
    ...messages,
  })
}

/**
 * @param {Object} data Bulk Edit Data
 * @param {Array} data.ids Entry Ids
 * @param {String} data.siteId Site Id
 * @param {String} [data.cloneAsModel] Model Alias
 * @param {Number} data.bulkEditType Bulk Edit Type
 * @param {Array} [data.system.labels] Entry Labels
 * @param {Array} [data.system.status] Entry Status
 * @param {Array} [data.system.publishedAt] Entry Published At
 * @param {Array} [data.system.unpublishedAt] Entry Unpublished At
 * @param messages
 * @returns {*}
 */
export function editEntriesBulk(data = {
  ids: [],
  siteId: null,
  cloneAsModel: null,
  bulkEditType: 1,
  system: {
    status: null,
    publishedAt: null,
    unpublishedAt: null,
    labels: [],
  },
}, messages) {
  return tc(axios.put('/{organization}/entries/bulk', { ...data }), {
    ...messages,
  })
}

// Versions
/**
 * @param {String} [entryId] Entry Id
 * @param {Object} [modelAlias] Model
 * @returns {*}
 */
export function readEntryVersions(entryId, modelAlias, siteId) {
  return axios.get(`/{organization}/entries/${entryId}/versions`, {
    params: {
      model: modelAlias,
      siteId,
    },
  }).then(response => {
    // TODO: Remove after fix in API
    if (response?.data) {
      response.data.forEach(version => {
        if (version?.entity) {
          version.entity.system.versionId = version.id
        }
      })
    }
    return response
  })
}

/**
 * @param {String} [entryId] Entry Id
 * @param {String} [versionId] Entry VersionId
 * @param {Object} [modelAlias] Model
 * @returns {*}
 */
export function readEntryVersion({
  entryId, versionId, modelAlias, siteId,
}) {
  return axios.get(`/{organization}/entries/${entryId}/versions/${versionId}`, {
    params: {
      model: modelAlias,
      siteId,
    },
  }).then(response => {
    // TODO: Remove after fix in API
    if (response?.data?.entity) {
      response.data.entity.system.versionId = response.data.id
    }
    return response
  })
}

/**
 * @param {Object} [version] Version
 * @param {Object} [modelAlias] Model
 * @returns {*}
 */
export function updateEntryVersion(version, modelAlias, siteId, messages) {
  return tc(axios.put(`/{organization}/entries/${version.entryId}/versions/${version.id}`, version, {
    params: {
      siteId,
      model: modelAlias,
    },
  }), {
    successTitle: 'sdk.success-title',
    successMessage: 'sdk.entry.version.update-successful',
    failTitle: 'sdk.entry.version.update-failed',
    ...messages,
  })
}

export function createScheduleEntryVersion(scheduleData = {
  entryId: '', versionId: '', publishScheduledDate: '', unpublishScheduledDate: '', siteId: '', model: '', publishReferences: true,
}, messages = {}) {
  const { entryId, versionId, ...payload } = scheduleData
  return tc(axios.put(`/{organization}/entries/${entryId}/versions/${versionId}/schedule`, payload), {
    successTitle: 'sdk.success-title',
    successMessage: 'sdk.entry.version.schedule-successful',
    failTitle: 'sdk.entry.version.schedule-failed',
    catchError: false,
    ...messages,
  })
}

export function deleteScheduleEntryVersion(scheduleData = {
  id: '', entryId: '', versionId: '', siteId: '', model: '',
}, messages = {}) {
  const { entryId, versionId } = scheduleData
  return tc(axios.delete(`/{organization}/entries/${entryId}/versions/${versionId}/schedule`, {
    params: {
      model: scheduleData.model,
      siteId: scheduleData.siteId,
      scheduledVersionId: scheduleData.id,
    },
  }), {
    successTitle: 'sdk.success-title',
    successMessage: 'sdk.entry.version.delete-schedule-successful',
    failTitle: 'sdk.entry.version.delete-schedule-failed',
    catchError: false,
    ...messages,
  })
}

export function restoreEntryVersion(version, revisionId, modelAlias, siteId, messages) {
  return tc(axios.put(`/{organization}/entries/${version.entity.id}/versions/${version.id}/restore`, {}, {
    params: {
      model: modelAlias,
      revisionId,
      siteId,
    },
  }), {
    successTitle: 'sdk.success-title',
    successMessage: 'sdk.entry.version.restore-successful',
    failTitle: 'sdk.entry.version.restore-failed',
    ...messages,
  })
}

export function deleteEntryVersion(version, revisionId, modelAlias, siteId, messages) {
  return tc(axios.delete(`/{organization}/entries/${version.entity.id}/versions/${version.id}`, {
    params: {
      model: modelAlias,
      revisionId,
      siteId,
    },
  }), {
    successTitle: 'sdk.success-title',
    successMessage: 'sdk.entry.version.delete-successful',
    failTitle: 'sdk.entry.version.delete-failed',
    catchError: false,
    ...messages,
  })
}

export function cloneEntryVersion(id, versionId, model, siteId, messages) {
  return tc(axios.post(`/{organization}/entries/${id}/versions/${versionId}/clone`, {}, {
    params: {
      model,
      siteId,
    },
  }), {
    successTitle: 'sdk.success-title',
    successMessage: 'sdk.entry.version.clone-successful',
    failTitle: 'sdk.entry.version.clone-failed',
    ...messages,
  })
}
