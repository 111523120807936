<template>
  <div>
    <div
      v-if="showPanel && !postingComment && showPanels"
      ref="main-panel-wrapper"
      class="menububble__link-popup menububble__link-popup--panel"
      :style="{...getMainPopupPosition, overflow: 'visible'}"
      @mousedown="$refs.hackInput.$el.focus()"
    >
      <div
        v-show="!showAiPopup"
        v-click-outside="{ method: closeTurnIntoDropdown }"
        class="menububble__component-type"
        :class="{'cursor-pointer': showTurnIntoButton}"
        @click="toggleTurnIntoDropdown"
      >
        <GjIcon
          v-if="component.icon"
          :name="component.icon"
          size="20"
        />
        <GjIcon
          v-if="showTurnIntoButton"
          name="ArrowDown"
          size="20"
        />
      </div>
      <b-input
        v-show="!showAiPopup"
        ref="hackInput"
        style="width: 0; padding: 0; margin: 0; opacity: 0; min-width: 0; border: 0;"
      />
      <TextFormatPanel
        v-show="!showAiPopup"
        v-if="generalPanel"
        :commands="commands"
        :include-hyper-link="true"
        :toggle-link-popup="toggleLinkPopup"
        :is-active="isActive"
        :editor="editor"
        @click.stop
      />

      <component
        :is="panel"
        v-show="!showAiPopup"
        v-else-if="panel"
        ref="panel"
        :attrs="theNodesAttrs"
        :update-attrs="_updateAttrsFunction"
        :delete-block="removeBlock"
        :commands="commands"
        :is-active="isActive"
        :toggle-link-popup="toggleLinkPopup"
        class="menububble__panel"
        :editor="editor"
        @click.stop
      />

      <b-button-group v-show="!showAiPopup">
        <b-button
          v-if="component.renderEditorPanel"
          v-b-tooltip.hover.html.top="getTooltipData('settings')"
          variant="flat-dark"
          @click="openAttrsSidebar"
        >
          <GjIcon
            name="Settings"
            size="20"
          />
        </b-button>
        <b-button
          id="at-articles-popup-deleteBlock"
          v-b-tooltip.hover.html.top="getTooltipData('delete')"
          variant="flat-dark"
          @click="removeBlock"
        >
          <GjIcon
            name="Delete"
            size="20"
          />
        </b-button>
      </b-button-group>

      <b-button-group
        v-if="isTextSelected"
        v-show="!showAiPopup"
      >
        <b-button
          id="at-format-text-button"
          v-b-tooltip.hover.html.top="getTooltipData('clear-formatting', ['Ctrl', '\\'])"
          class="no-background-color"
          variant="flat-dark"
          @click="clearAllMarks"
        >
          <GjIcon
            name="EraseDelete"
            size="20"
          />
        </b-button>
      </b-button-group>

      <b-button-group
        v-if="toolbarOptions && toolbarOptions.comments && toolbarOptions.comments.isEnabled && entryId"
        v-show="!showAiPopup"
      >
        <b-button
          id="at-articles-popup-comments"
          v-b-tooltip.hover.html.top="getTooltipData('comment')"
          variant="flat-dark"
          @click.stop="showPostComment"
        >
          <GjIcon
            name="CommentAlt"
            size="20"
          />
        </b-button>
      </b-button-group>
      <b-button-group
        v-if="isEnabledAIFeatures"
        :style="showAiPopup && {position: 'absolute'}"
        :class="{'active': showAiPopup}"
      >
        <b-button
          v-show="!showAiPopup"
          variant="none"
          @click="showAiPopup = true"
        >
          <AiIcon size="18" />
        </b-button>

        <AiContentPopup
          v-if="showAiPopup"
          :selected-text="selectedText"
          @close="showAiPopup = false"
        />
      </b-button-group>
      <div
        v-show="!showAiPopup"
        v-if="characterSelectedCount > 0"
        class="menububble__panel__characters-count"
      >
        {{ $t('codex-editor.panels.wrapper-panel.character-count', { count: characterSelectedCount}) }}
      </div>
    </div>

    <!-- LINK -->
    <div
      v-if="showLinkPopup"
      ref="link-panel-wrapper"
      class="menububble__link-popup"
      :style="getLinkPopupPosition"
    >
      <LinkPopup
        :key="`link-${markLinkUrl.href}`"
        v-click-outside="{method: closeLinkPopup, excluded: ['#hyper-link-toggle-button']}"
        :value="linkUrl === '' ? linkUrl : linkUrl || markLinkUrl.href"
        :link-attrs="markLinkUrl"
        :is-editing="!!markLinkUrl.href"
        :active-link="activeLinkMark && activeLinkMark.attrs"
        :changed-url="!!linkUrl && !!markLinkUrl.href && linkUrl !== markLinkUrl.href"
        :insert="insertLink"
        @input="(v) => linkUrl = v"
      />
    </div>

    <!-- COMMENT -->
    <div
      v-if="showPanel && postingComment"
      ref="comment-panel-wrapper"
      class="menububble__link-popup"
      :style="getCommentPopupPosition"
    >
      <CommentEdit
        v-model="postingComment"
        :field-alias="widget.attrs.alias"
        :block-id="theNode.node.attrs.blockId"
        class="main-panel-wrapper__new-comment"
      />
    </div>

  </div>
</template>

<script>
import { TextSelection } from 'prosemirror-state'
import { removeMark } from 'tiptap-commands'
import MentionsInput from '@/components/MentionsInput.vue'
import LinkPopup from '@/components/codex-editor/marks/Link/LinkPopup.vue'
import hotkeys from 'hotkeys-js'
import { setTextAlign } from '@/components/codex-editor/plugins/CodexCommands'
import { BLOCK_GROUPS } from '@/components/codex-editor/nodes/constants'
import TextFormatPanel from '@/components/codex-editor/panels/TextFormatPanel.vue'
import { CODEX_EDITOR_BLOCKS } from '@/components/codex-editor/CodeEditorConstants'
import CommentEdit from '@/components/fields/CommentEdit.vue'
import AiIcon from '@/components/ai/AiIcon.vue'
import ClickOutside from '@/libs/click-outside'
import AiContentPopup from '@/components/ai/AiContentPopup.vue'
import CodexContentEditor from '../CodexContentEditor'

export default {
  inject: ['showBlockSidebar', 'deleteBlock', 'toolbarOptions', 'widget', 'isEnabledAIFeatures', 'entry'],
  components: {
    AiContentPopup,
    AiIcon,
    CommentEdit,
    MentionsInput,
    LinkPopup,
    TextFormatPanel,
  },
  provide() {
    return {
      getTooltipData: this.getTooltipData,
    }
  },
  directives: {
    'click-outside': ClickOutside,
  },
  props: ['component', 'selectedNodes', 'setCurrentNodeAttrs', 'editor', 'activeMarks', 'showPanels', 'generalPanel', 'editorFocused'],
  data() {
    return {
      postingComment: false,
      menuShowLinkPopup: false,
      linkUrl: '',
      scrollTop: window.scrollY,
      characterSelectedCount: 0,
      selectedText: '',
      selection: {
        from: 0,
        to: 0,
      },
      commentBlockId: null,
      showAiPopup: false,
    }
  },
  computed: {
    entryId() {
      return this.entry()?.id
    },
    isTextSelected() {
      return this.editor.state.selection instanceof TextSelection
    },
    _updateAttrsFunction() {
      return this.updateAttrsFunction.bind(this, this.theNodesAttrs.blockId)
    },
    commands() {
      return this.editor.commands
    },
    isActive() {
      return this.editor.isActive
    },
    panel() {
      return this.component.renderEditorBar
    },
    theNode() {
      // Only show if one node is selected, and if that one matches the given component
      return this.selectedNodes.length === 1 && this.selectedNodes[0].node.type.name === (this.component.type || this.component.name) ? this.selectedNodes[0] : null
    },
    showPanel() {
      let show = true
      if (this.$refs && this.$refs.panel && this.$refs.panel.showPanel) {
        show = this.$refs.panel.showPanel()
      }
      // const isEditorFocused = this.editor.view.dom.classList.contains('ProseMirror-focused')
      return !!this.theNode && show
    },
    theNodesAttrs() {
      return this.theNode && this.theNode.node.attrs ? this.theNode.node.attrs : {}
    },

    getMainPopupPosition() {
      return this.getPosition(this.$refs['main-panel-wrapper'])
    },

    getCommentPopupPosition() {
      return this.getPosition(this.$refs['comment-panel-wrapper'])
    },

    // Link

    markLinkUrl() {
      if (this.activeLinkMark) {
        return this.activeLinkMark.attrs
      }
      return { href: '' }
    },

    showLinkPopup() {
      return this.editorFocused && this.showPanel && (this.menuShowLinkPopup || this.activeLinkMark)
    },

    activeLinkMark() {
      if (!this.activeMarks || this.activeMarks.length === 0) return null
      const activeLink = this.activeMarks.find(mark => mark.type.name === 'link')
      return activeLink
    },

    getLinkPopupPosition() {
      const el = this.$refs['link-panel-wrapper']
      const { view, selection: { from, to } } = this.editor
      const start = view.coordsAtPos(from)
      const end = view.coordsAtPos(to, true)
      const editorDOM = this.editor.view.dom
      const editorRect = editorDOM.getBoundingClientRect()
      const panelRect = el?.getBoundingClientRect() || { height: 0 }
      const linkPopupHeight = (this.linkUrl === '' ? this.linkUrl : this.linkUrl || this.markLinkUrl.href).length === 0 ? 240 : 240
      // const articlesLength = this.articles.length > 2 ? 124 : this.articles.length * 47 + (this.articles.length === 0 ? 0 : 14)

      // eslint-disable-next-line no-unused-expressions
      this.scrollTop

      // Retrigger position calculation if the element is not yet in the DOM
      if (!el) {
        // eslint-disable-next-line vue/no-async-in-computed-properties
        setTimeout(() => {
          this.scrollTop++
        }, 1)
      }

      if (end.top < 120) {
        const popupBottom = this.scrollTop - window.innerHeight + 120 + linkPopupHeight
        const editorBottom = this.scrollTop + editorRect.top + editorRect.height - window.innerHeight
        const deltaMove = Math.min(editorBottom - popupBottom, 0)

        return {
          opacity: el ? 1 : 0,
          position: 'fixed',
          top: `${120 + deltaMove}px`,
          left: `${((start.left + end.left) / 2)}px`,
        }
      }

      return {
        opacity: el ? 1 : 0,
        top: `${end.bottom - editorRect.top + linkPopupHeight - panelRect.height}px`,
        left: `${((start.left + end.left) / 2) - editorRect.left}px`,
      }
    },

    showTurnIntoButton() {
      const components = CodexContentEditor.getRegisteredWidgets().filter(i => ((i.group === BLOCK_GROUPS.TEXTUAL || i.group[0] === BLOCK_GROUPS.TEXTUAL) && i.type !== CODEX_EDITOR_BLOCKS.BLOCKQUOTE) || i.type === CODEX_EDITOR_BLOCKS.CODE_BLOCK).map(component => component.type)
      return components.includes(this.theNode.node.type.name)
    },
  },
  watch: {
    'editor.selection': function () {
      this.menuShowLinkPopup = false
      this.linkUrl = null
      this.updateCharacterCount()
    },
    theNode(v) {
      // Wait for panel to change node/close
      setTimeout(() => {
        if (this.commentBlockId !== v?.node?.attrs?.blockId) {
          this.postingComment = false
          this.commentBlockId = null
        }
      }, 10)
    },
  },
  destroyed() {
    this.resizeObs.disconnect()
  },
  mounted() {
    this.resizeObs = new ResizeObserver(() => this.reposition())
    const tm = setInterval(() => {
      const node = document.querySelector('.ProseMirror')
      if (node && this.resizeObs) {
        this.resizeObs.observe(node)
        clearInterval(tm)
      }
    })
    hotkeys('command+\\, ctrl+\\', event => {
      event.preventDefault()
      this.clearAllMarks()
    })

    window.addEventListener('scroll', this.updateScroll)
  },
  beforeDestroy() {
    hotkeys.unbind('command+\\, ctrl+\\')
    window.removeEventListener('scroll', this.updateScroll)
  },
  methods: {
    updateCharacterCount() {
      const { from, to } = this.editor.state.selection
      this.selectedText = this.editor.state.doc.textBetween(from, to).trim()
      this.selection.from = from
      this.selection.to = to
      this.characterSelectedCount = this.selectedText?.length
    },
    getPosition(el) {
      const node = this.theNode
      if (!node) return {}

      const editorDOM = this.editor.view.dom
      const editorRect = editorDOM.getBoundingClientRect()
      const rect = node.dom.getBoundingClientRect()
      const panelRect = el?.getBoundingClientRect() || { height: 0 }

      // eslint-disable-next-line no-unused-expressions
      this.scrollTop

      // Retrigger position calculation if the element is not yet in the DOM
      if (!el) {
        setTimeout(() => {
          this.scrollTop++
        }, 1)
      }

      if (rect.y < 60 + panelRect.height) {
        return {
          opacity: el ? 1 : 0,
          position: 'fixed',
          top: `${60}px`,
          left: `${rect.left}px`,
          '--left': `${editorRect.left}px`,
        }
      }

      return {
        opacity: el ? 1 : 0,
        top: `${rect.top - editorRect.top - panelRect.height - 5}px`,
        left: `${rect.left - editorRect.left}px`,
        '--left': `${editorRect.left}px`,
      }
    },
    updateScroll() {
      this.scrollTop = window.scrollY
    },
    clearAllMarks() {
      const { from, to } = this.editor.state.selection
      const tr = this.editor.state.tr
      const markTypes = this.editor.schema.marks
      const marksToClear = ['color_style', 'underline', 'bold', 'strike', 'italic']
      // Set Alignment to left
      setTextAlign('left')(this.editor.state, this.editor.view.dispatch)
      // Loop through each active mark type
      Object.keys(markTypes).forEach(markType => {
        // Check if the mark is applied to the selection
        if (marksToClear.includes(markType) && tr.doc.rangeHasMark(from, to, markTypes[markType])) {
          // Remove the mark from the selection
          removeMark(markTypes[markType])(this.editor.state, this.editor.view.dispatch)
        }
      })
    },
    openAttrsSidebar() {
      this.showBlockSidebar({ component: this.component, node: this.theNode.node })
    },
    getSelectedNode(blockId) {
      const selectedNode = []

      if (blockId) {
        this.editor.state.doc.descendants((node, pos) => {
          if (node.attrs?.blockId === blockId) {
            selectedNode.push({
              node, pos,
            })
          }
        })
      } else {
        this.editor.state.tr.selection.ranges.forEach(range => {
          const from = range.$from.pos
          const to = range.$to.pos

          // eslint-disable-next-line no-unused-vars
          this.editor.state.doc.nodesBetween(from, to, (node, pos) => {
            if (node.type.name === this.theNode.node.type.name) {
              selectedNode.push({
                node, pos,
              })
            }
          })
        })
      }

      return blockId ? selectedNode[0] : selectedNode
    },
    // eslint-disable-next-line no-unused-vars
    updateAttrsFunction(blockId, attributes) {
      const selectedNode = this.getSelectedNode(blockId)

      if (selectedNode) {
        this.editor.view.dispatch(this.editor.state.tr.setNodeMarkup(selectedNode.pos, undefined, {
          ...selectedNode.node.attrs,
          ...attributes,
        }))
      }
    },
    reposition() {
      this.$forceUpdate()
    },
    showPostComment() {
      this.commentBlockId = this.theNode?.node?.attrs?.blockId
      this.postingComment = true
    },
    removeBlock() {
      const selectedNode = this.getSelectedNode()
      this.showBlockSidebar(null)
      if (selectedNode) {
        if (selectedNode.length === 1) {
          this.deleteBlock(selectedNode[0].node.attrs.blockId)
        } else {
          selectedNode.forEach(element => {
            this.deleteBlock(element.node.attrs.blockId)
          })
        }
      }
    },

    /* Links */
    insertLink(linkAttrs) {
      this.editor.commands.link(linkAttrs)
      this.linkUrl = null
      // this.articles = []
      this.menuShowLinkPopup = false
    },
    toggleLinkPopup(popup = 'link') {
      if (popup === 'link') {
        this.menuShowLinkPopup = !this.menuShowLinkPopup
        if (this.menuShowLinkPopup) {
          this.linkUrl = this.markLinkUrl.href || ''
        }
      }
    },
    closeLinkPopup() {
      this.menuShowLinkPopup = false
    },
    getTooltipData(label, ctrls = []) {
      if (window.navigator.userAgent.toLowerCase().indexOf('mac') != -1 && ctrls.length > 0) {
        const index = ctrls.indexOf('Ctrl')
        if (index !== -1) {
          ctrls[index] = '⌘'
        }
      }
      return { title: `<div class="d-flex flex-column"><span class="b-tooltip-label">${this.$t(`codex-editor.tooltip.${label}`)}${ctrls.length > 0 ? `</span><span class="b-tooltip-controls">${ctrls.join(' + ')}</span>` : ''}</div>` }
    },
    toggleTurnIntoDropdown() {
      if (this.showTurnIntoButton) {
        this.$emit('toggleTurnInto')
      }
    },
    closeTurnIntoDropdown() {
      this.$emit('toggleTurnInto', false)
    },
  },
}
</script>
<style lang="scss">

.menububble__panel__characters-count {
  display: flex;
  align-items: center;
  color: #052D61;
  font-size: 14px;
  font-weight: 400;
  padding: 8px;
}

.menububble__link-popup-open {
  position: absolute;
  background-color: white;
  left: 50%;
  top: 0;
  transform: translateX(-50%) translateY(15px);
  z-index: 15;
  border-radius: 0.5rem;
  overflow: hidden;
  padding: 2px;

  display: flex;

  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.12);

  .btn {
    height: 32px;
    padding: 0 8px;
  }
}

.menububble__link-popup {
  position: absolute;
  z-index: 11;
  // transform: translateY(calc(-100% - 5px));
  padding: 0;
  transition: opacity 0.4s;
  background-color: white;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.12);
  white-space: nowrap;
  display: flex;
  border-radius: 0.5rem;

  &.menububble__link-popup--panel {
    height: 32px;
    max-width: calc( 100vw - var(--left) - 32px );

    overflow-y: hidden;
    overflow-x: scroll;
    border-radius: 0 !important;

    // hide scrollbar
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
    &::-webkit-scrollbar {
      display: none;
    }
  }
  //overflow: hidden;

  .b-dropdown {
    .dropdown-toggle {
      &:after {
        display: none;
      }
    }
  }

  .menububble__panel {
    display: flex;

    .form-group {
      margin: 0;
    }
  }

  &.menububble {
    transform: translateX(-50%) translateY(-0.75rem);
    visibility: hidden !important;

    &.is-active {
      visibility: visible !important;
    }

    &:not(.is-active) {
      * {
        transition: none;
      }
    }
  }

  input {
    outline: none;
    padding: 0 6px;
    border: 1px solid #E0E5EB;
    border-top: none !important;
    border-bottom: none !important;
    height: 32px;
    border-radius: 0 !important;
    min-width: 200px;
  }

  .btn {
    height: 32px;
    padding: 0 8px;
  }

  .btn-group {
    position: relative;
    padding: 0 4px;

    &:not(:last-child):after {
      content: '';
      position: absolute;
      right: 0px;
      top: 4px;
      bottom: 4px;
      border-right: 1px solid #E2E2E2
    }
  }

  .btn-group, .menububble__panel .btn-group {
    position: relative;
    padding: 0 4px;

    &:after {
      content: '';
      position: absolute;
      right: 0px;
      top: 4px;
      bottom: 4px;
      border-right: 1px solid #E2E2E2
    }
  }

  > .btn-group > .btn, .menububble__panel .btn {
    border-radius: 0 !important;

    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: #2C2C2C;
  }

  select {
    height: unset;
    padding: 0 6px;
    border-radius: 0;
    border-bottom: none;
    border-top: none;
  }
}

.menububble__component-type {
  background: #1D79F2;
  box-shadow: inset -6px 0px 6px -4px rgba(0, 0, 0, 0.12);
  border-radius: 4px 0px 0px 4px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  // width: 28px;
  padding-left: 2px;
  color: white;
}

.b-tooltip {
  .tooltip-inner {
    padding: 8px 12px !important;

    span.b-tooltip-controls {
      color: rgba(255, 255, 255, 0.5);
    }
  }
}
.btn.btn-flat-dark.no-background-color:active,
.btn.btn-flat-dark.no-background-color:focus {
  background-color: transparent;
}
</style>
