<template>
  <div
    class="new-filters-dropdown__filter-body"
    :class="{'new-filters-dropdown__filter-body--scroll': usersAll.length > 3 }"
  >

    <b-row>
      <b-col cols="12">
        <vSelect
          v-model="value.operator"
          :options="options"
          :clearable="false"
          :reduce="option => option.value"
          :searchable="false"
          label="label"
        />
      </b-col>
    </b-row>

    <b-row
      align-v="center"
      no-gutters
    >
      <b-col cols="12">

        <b-checkbox-group
          v-model="value.value"
          class="new-filters-dropdown__checkbox-group"
          :options="usersAll"
          stacked
        />

        <!-- <div
          v-for="objValue in objectValues"
          :key="objValue.id"
          class="new-filters-dropdown__media-item"
        >
          {{ objValue }}
        </div> -->
      </b-col>
    </b-row>

  </div>
</template>

<script>
import gql from 'graphql-tag'
import UserFilter from './UserFilter'

export default {
  props: {
    filter: {
      type: Object,
      required: true,
    },
    filterObject: {
      type: [Object, Function],
      required: true,
    },
    value: {
      type: Object,
      required: true,
    },
    cache: {
      type: Object,
      required: true,
    },
    setCache: {
      type: Function,
      required: true,
    },
  },
  apollo: {
    users: {
      query: gql`
        query Users {
          userCollection (limit: 1000) {
            items {
              id
              imageUrl
              email
              firstName
              lastName
            }
          }
        }
      `,

      update(data) {
        return data.userCollection.items
      },
    },
  },
  computed: {
    usersAll() {
      if (!this.users) return []

      return this.users.map(user => ({
        value: user.id,
        text: `${user.firstName || ''} ${user.lastName || ''}`.trim(),
      }))
    },

    options() {
      return [
        {
          label: this.$t('filters.include'),
          value: UserFilter.OPERATORS.include,
        },
        {
          label: this.$t('filters.exclude'),
          value: UserFilter.OPERATORS.exclude,
        },
      ]
    },

    objectValues() {
      if (!this.value.value) return []

      return this.value.value.map(userId => this.cache[userId] || { id: userId })
    },
  },
  watch: {
    'value.value': function (value) {
      value.forEach(userId => {
        if (!this.cache[userId]) {
          this.setCache(this.users.find(user => user.id === userId))
        }
      })
    },
  },
  methods: {
  },
}
</script>

<style lang="scss">
.new-filters-dropdown__media-item {
  display: flex;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 0.5rem;
  }
}
.new-filters-dropdown__media-item-avatar {
  margin-right: 10px;
}
.new-filters-dropdown__media-item-title {
  flex-grow: 1;
  margin: 0;

  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #1D417B;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.new-filters-dropdown__media-item-remove {
  cursor: pointer;
  opacity: 1;
  transition: color 0.3s ease;

  &:hover {
    opacity: 0.8;
  }
}
</style>
