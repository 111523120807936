<template>
  <div class="new-filters-dropdown__filter-body">

    <b-row>
      <b-col cols="12">
        <vSelect
          v-model="value.operator"
          :options="options"
          :clearable="false"
          :reduce="option => option.value"
          :searchable="false"
          label="label"
        />
      </b-col>
    </b-row>

    <b-form-group v-if="appearance === NUMBER_FIELD_APPEARANCES.DROPDOWN || appearance === NUMBER_FIELD_APPEARANCES.RADIO || appearance === NUMBER_FIELD_APPEARANCES.CHECKBOX">
      <v-select
        v-model="value.value1"
        :clearable="false"
        :options="filter.field.attrs.predefinedValues"
        class="text-wrap__inputs"
      />
    </b-form-group> <!-- DROPDOWN / RADIO / CHECKBOX -->

    <b-row
      v-else-if="!isExistsOperator"
      align-v="center"
      no-gutters
    >
      <b-col class="flex-grow">
        <b-input
          v-model="value.value1"
          type="number"
          size="sm"
        />
      </b-col>

      <b-col
        v-if="isBetween"
        cols="auto"
      >
        <div class="new-filters-dropdown__range-divider" />
      </b-col>

      <b-col
        v-if="isBetween"
        class="flex-grow"
      >
        <b-input
          v-model="value.value2"
          type="number"
          size="sm"
        />
      </b-col>
    </b-row>

  </div>
</template>

<script>
import { NUMBER_FIELD_APPEARANCES } from '@/views/models/constants'
import NumberFilter from './NumberFilter'

export default {
  props: {
    filter: {
      type: Object,
      required: true,
    },
    filterObject: {
      type: [Object, Function],
      required: true,
    },
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isPredefined: false,
      NUMBER_FIELD_APPEARANCES,
    }
  },
  computed: {
    isBetween() {
      return this.value.operator === NumberFilter.OPERATORS.between
    },
    options() {
      if (this.isPredefined) {
        return [
          {
            label: this.$t('filters.is'),
            value: NumberFilter.OPERATORS.equals,
          },
          {
            label: this.$t('filters.greater-than'),
            value: NumberFilter.OPERATORS.greaterThan,
          },
          {
            label: this.$t('filters.lower-than'),
            value: NumberFilter.OPERATORS.lowerThan,
          },
        ]
      }
      return [
        {
          label: this.$t('filters.is'),
          value: NumberFilter.OPERATORS.equals,
        },
        {
          label: this.$t('filters.greater-than'),
          value: NumberFilter.OPERATORS.greaterThan,
        },
        {
          label: this.$t('filters.lower-than'),
          value: NumberFilter.OPERATORS.lowerThan,
        },
        {
          label: this.$t('filters.between'),
          value: NumberFilter.OPERATORS.between,
        },
        {
          label: this.$t('filters.is-not-empty'),
          value: NumberFilter.OPERATORS.exists,
        },
        {
          label: this.$t('filters.is-empty'),
          value: NumberFilter.OPERATORS.notExists,
        },
      ]
    },
    isExistsOperator() {
      return this.value.operator === NumberFilter.OPERATORS.exists || this.value.operator === NumberFilter.OPERATORS.notExists
    },
    appearance() {
      return this.filter?.field?.attrs?.appearance
    },
  },
  updated() {
    this.setPredefined()
  },
  mounted() {
    this.setPredefined()
  },
  methods: {
    setPredefined() {
      this.isPredefined = this.appearance == NUMBER_FIELD_APPEARANCES.DROPDOWN
      || this.appearance == NUMBER_FIELD_APPEARANCES.RADIO
      || this.appearance == NUMBER_FIELD_APPEARANCES.CHECKBOX
    },
  },
}
</script>

<style scoped>
.form-control {
  width: 100% !important;
}
</style>
