import Vue from 'vue'
import vSelect from 'vue-select'

// Set the components prop default to return our fresh components
vSelect.props.autoscroll = false
vSelect.props.components.default = () => ({
  Deselect: {
    render: h => h('GjIcon', { props: { size: '14', name: 'Close' } }),
  },
  OpenIndicator: {
    render: h => h('GjIcon', { props: { size: '15', name: 'ArrowDown' }, class: 'open-indicator' }),
  },
})

Vue.component(vSelect)
