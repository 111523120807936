export default {
  SET_SHOW(state, show) {
    state.show = show
  },
  SET_STATUS(state, status) {
    state.status = status
  },
  SET_UPDATE_TIME(state, updatedTime) {
    state.updatedTime = updatedTime
  },
  SET_RETRY_FUNCTION(state, retryFunction) {
    state.retryFunction = retryFunction
  },
  STASH_STATE(state) {
    const { history, ...other } = state
    state.history.push({ ...other })
  },
  APPLY_STATE(state) {
    const previousState = state.history.pop()
    if (!previousState) state.show = false
    Object.assign(state, {
      ...previousState,
      history: state.history,
    })
  },
}
