<template>
  <div>
    <b-button-group v-if="toolbarOptions && toolbarOptions.dropcap && toolbarOptions.dropcap.isEnabled">
      <b-button
        id="at-articles-dropcap"
        v-b-tooltip.hover.html.top="getTooltipData('dropcap')"
        variant="flat-dark"
        :class="{'active': attrs.dropCap }"
        @click="() => dropCap = !dropCap"
      >
        <GjIcon
          name="IconparkTextStyleOne"
          size="18"
        />
      </b-button>
    </b-button-group>
    <AlignBlockPanel
      :attrs="attrs"
      :update-attrs="updateAttrs"
    />
    <TextFormatPanel
      :commands="commands"
      :include-hyper-link="true"
      :is-active="isActive"
      :toggle-link-popup="toggleLinkPopup"
      :editor="editor"
    />
    <TableFormatPanel
      :commands="commands"
      :is-active="isActive"
    />

  </div>
</template>

<script>
import TextFormatPanel from '@/components/codex-editor/panels/TextFormatPanel.vue'
import TableFormatPanel from '@/components/codex-editor/panels/TableFormatPanel.vue'
import AlignBlockPanel from '@/components/codex-editor/panels/AlignBlockPanel.vue'

export default {
  components: {
    TextFormatPanel,
    AlignBlockPanel,
    TableFormatPanel,
  },
  inject: ['getTooltipData', 'toolbarOptions'],
  props: ['attrs', 'updateAttrs', 'commands', 'isActive', 'toggleLinkPopup', 'editor'],
  computed: {
    dropCap: { get() { return this.attrs.dropCap }, set(e) { this.updateAttrs({ dropCap: e }) } },
  },
}
</script>
