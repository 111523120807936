<!-- eslint-disable vue/no-textarea-mustache -->
<template>
  <b-form-group>
    <template #label>
      <FieldName
        :name="field.name"
        :help-text="''"
      />
    </template>
    <div
      v-if="fieldContent !== null"
      class="json-field__wrapper"
    >
      <textarea
        :id="'codemirror-' + entryId + field.alias"
        style="white-space: pre-line"
        :value="JSON.stringify(fieldContent, null, 2)"
      />
    </div>
    <div
      v-else
      class="content-list__empty"
    >
      <GjIcon name="Bracket" />
      <span>{{ $tc('entries.merge-tool.info.no-json-value') }}</span>
    </div>
  </b-form-group>
</template>

<script>
import FieldName from '@/components/fields/FieldName.vue'
import { onMounted } from '@vue/composition-api'
import * as CodeMirror from 'codemirror'
import 'codemirror/lib/codemirror.css'
import 'codemirror/theme/ayu-mirage.css'
import 'codemirror/mode/javascript/javascript'

export default {
  name: 'JsonField',
  components: {
    FieldName,
  },
  props: {
    entryId: {
      type: [String],
    },
    fieldContent: {
      type: [Object],
    },
    field: {
      type: [Object],
    },
  },
  setup(props) {
    const cmCfg = {
      lineNumbers: true,
      readOnly: true,
      mode: {
        name: 'javascript',
        json: true,
        statementIndent: 2,
      },
      theme: 'ayu-mirage',
    }

    onMounted(() => {
      const txtArea = document.getElementById(`codemirror-${props.entryId + props.field.alias}`)
      CodeMirror.fromTextArea(txtArea, cmCfg)
    })
  },
}
</script>

<style lang="scss">
.json-field__wrapper {
  .CodeMirror {
    height: fit-content;
  }

  .CodeMirror-cursor {
    display: none;
  }
}
</style>
