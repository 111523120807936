<template>
  <div class="focal-point-presets">
    <div class="focal-point__sidebar-header">
      <span class="focal-point__sidebar-title">
        {{ $t('popups.focal-point.title-update') }}
      </span>
      <span class="focal-point__sidebar-description">
        {{ $t('popups.focal-point.subtitle') }}
      </span>
    </div>
    <div class="focal-point-presets__list">
      <label
        class="focal-point-preset"
        :class="{'focal-point-preset--active': selectedPreset === 'default'}"
      >
        <div class="focal-point-presets__text">
          <div class="focal-point-presets__title">
            {{ $t('popups.focal-point.default.title') }}
          </div>
          <div class="focal-point-presets__name">
            {{ $t('popups.focal-point.default.subtitle') }}
          </div>
        </div>
        <b-radio
          v-model="selectedPreset"
          type="radio"
          name="focal-preset"
          value="default"
        />
      </label>
      <label
        v-for="(preset, idx) in presets"
        :key="preset.id"
        class="focal-point-preset"
        :class="{'focal-point-preset--active': selectedPreset === getKey(preset)}"
      >
        <div
          class="focal-point-presets__image"
          :style="{opacity: fitWatchers[idx] ? 1 : 0 }"
        >
          <img
            v-if="src && src.url && !isCustom(preset)"
            :style="{width: `${preset.width*(100 / preset.width)}px`, height: `${preset.height*(100 / preset.width)}px`,objectPosition: `${focals.default.x * 100}% ${focals.default.y * 100}%`} "
            :src="src.url"
            @load="fitImages"
          >
          <img
            v-if="src && src.url && isCustom(preset) "
            :style="{width: `${preset.width*(100 / preset.width)}px`, height: `${preset.height*(100 / preset.width)}px`,objectPosition: `${focals.presets[getKey(preset)].x * 100}% ${focals.presets[getKey(preset)].y * 100}%`} "
            :src="src.url"
            @load="fitImages"
          >
        </div>
        <div class="focal-point-presets__text">
          <div>
            <div class="focal-point-presets__badge">
              {{ isCustom(preset) ? $t('popups.focal-point.changes.custom') : $t('popups.focal-point.changes.default') }}
            </div>
            <GjIcon
              v-if="isCustom(preset)"
              style="margin-left: 4px;"
              name="ArrowResetRestartRotateLeft"
              size="16px"
              @click.native="$emit('removePreset', preset)"
            />
          </div>
          <div class="focal-point-presets__name">
            {{ preset.name }}
          </div>
          <div class="focal-point-presets__ratio">
            {{ getKey(preset) }}
          </div>
        </div>
        <b-radio
          v-model="selectedPreset"
          class="focal-point-presets__radio"
          type="radio"
          name="focal-preset"
          :value="`${preset.width}:${preset.height}`"
        />
      </label>
    </div>
  </div>
</template>

<script>
import fit from 'fit.js'

export default {
  name: 'FocalPointPresets',
  props: {
    src: {
      required: true,
    },
    presets: {
      type: Array,
      default: () => [],
      required: true,
    },
    value: {
      type: String,
      default: '',
    },
    focals: {
      type: Object,
      default: () => {
      },
    },
  },
  data() {
    return {
      fitWatchers: [],

      config: {
        hAlign: fit.CENTER,
        vAlign: fit.CENTER,
        apply: true,
        watch: true,
      },
    }
  },
  computed: {
    selectedPreset: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
  beforeDestroy() {
    this.fitWatchers.forEach(watcher => {
      watcher.off()
    })
  },
  methods: {
    removePreset(preset) {
      this.$emit('remove-preset', preset)
    },
    fitImages() {
      setTimeout(() => {
        const images = document.querySelectorAll('.focal-point-presets__image img')
        const imagesWrapper = document.querySelectorAll('.focal-point-presets__image')

        for (let i = 0; i <= this.presets.length; i++) {
          // eslint-disable-next-line no-continue
          if (!images[i] || !imagesWrapper[i]) continue
          if (this.fitWatchers[i]) this.fitWatchers[i].off()
          this.$set(this.fitWatchers, i, fit(images[i], imagesWrapper[i], { ...this.config }))
        }
      }, 200)
    },
    getKey(ratio) {
      return `${ratio.width}:${ratio.height}`
    },
    isCustom(ratio) {
      return this.focals.presets?.[this.getKey(ratio)]?.x !== undefined
    },
  },
}
</script>

<style scoped lang="scss">
.focal-point-presets {
  height: calc(100% - 86px);
  display: flex;
  flex-direction: column;
}

.focal-point-presets__list {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 8px;
  overflow-y: auto;
  height: calc(100% - 90px);
  margin-right: -8px;
}

.focal-point-preset {
  display: flex;
  align-items: flex-start;
  gap: 12px;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  padding: 8px;
  position: relative;
  margin: 0;
  margin-right: 8px;
}

.focal-point-preset--active {
  background: #ECF3FF;
  border-color: #206ED5;
}

.focal-point-presets__text {
  display: flex;
  flex-direction: column;
  margin-right: 8px;
  flex: 1;
}

.focal-point-presets__image {
  width: 88px;
  height: 76px;
  overflow: hidden;
  background: #DADFE7;
  border-radius: 2px;

  img {
    object-fit: cover;
  }
}

.focal-point-presets__badge {
  height: 19px;
  background: #EDEFF3;
  border-radius: 40px;
  font-weight: 500;
  font-size: 12px;
  line-height: 0;
  color: #667C99;
  display: inline-flex;
  align-items: center;
  width: fit-content;
  padding: 0 6px;
}

.focal-point-presets__title {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #052D61;
  margin-bottom: 6px;
}

.focal-point-presets__name {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #667C99;
  margin-top: 8px;
}
.focal-point-presets__ratio {
  position: absolute;
  left: 12px;
  top: 12px;
  padding: 0px 4px;
  border-radius: 40px;
  background: #FFF;
  box-shadow: 0px 2px 4px 0px rgba(163, 176, 194, 0.22);
  color: #052D61;
  font-size: 11px;
  font-weight: 400;
}
.focal-point-presets__radio {
  //margin-left: auto;
  &.custom-radio.custom-control {
    padding-left: 20px !important;
  }
}
</style>
