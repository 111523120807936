import { ApolloClient } from 'apollo-client'
import { createHttpLink } from 'apollo-link-http'
import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory'
import VueApollo from 'vue-apollo'
import introspectionQueryResultData from '@/fragmentTypes.json'
import { ApolloLink, split } from 'apollo-link'
import axios from 'axios'
import { WebSocketLink } from 'apollo-link-ws'
import { getMainDefinition } from 'apollo-utilities'
import router from '@/router'

const authLink = new ApolloLink((operation, forward) => {
  // Retrieve the authorization token from local storage.
  const token = localStorage.getItem('accessToken')

  // Use the setContext method to set the HTTP headers.
  operation.setContext({
    headers: {
      authorization: token ? `Bearer ${token}` : '',
    },
  })

  // Call the next link in the middleware chain.
  return forward(operation)
})

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData,
})
async function getJobApolloClient(organization) {
  const jobsHttpLink = createHttpLink({
    uri: process.env.VUE_APP_JOBS_GRAPHQL_URL ? process.env.VUE_APP_JOBS_GRAPHQL_URL.replace('{organization}', organization) : '',
  })

  let wsLink = null
  if (organization) {
    wsLink = new WebSocketLink({
      uri: process.env.VUE_APP_JOBS_GRAPHQL_WS_URL ? process.env.VUE_APP_JOBS_GRAPHQL_WS_URL.replace('{organization}', organization) : '',
      options: {
        reconnect: true,
      },
    })
  }
  // eslint-disable-next-line no-use-before-define
  const _jobsIntrospectionQueryResultData = await getFragment(organization)

  const _jobsFragmentMatcher = new IntrospectionFragmentMatcher({
    introspectionQueryResultData: _jobsIntrospectionQueryResultData,
  })

  const link = split(
    // split based on operation type
    ({ query }) => {
      const definition = getMainDefinition(query)
      return definition.kind === 'OperationDefinition'
            && definition.operation === 'subscription'
    },
    ...(wsLink ? [wsLink] : []),
    authLink.concat(jobsHttpLink),
  )
  return new ApolloClient({
    link,
    cache: new InMemoryCache({ fragmentMatcher: _jobsFragmentMatcher }),
  })
}

async function getClientApolloClient(organization) {
  return new ApolloClient({
    link: authLink.concat(createHttpLink({ uri: (process.env.VUE_APP_CLIENT_GRAPHQL_URL || 'https://graphql.codex.gjirafa.dev/v2/{organization}').replace('{organization}', organization) })),
    cache: new InMemoryCache({ fragmentMatcher }),
  })
}

const mainClient = new ApolloClient({
  link: authLink.concat(createHttpLink({
    uri: process.env.VUE_APP_MAIN_GRAPHQL_URL || 'https://api.codex.gjirafa.dev/v2/graphql',
  })),
  cache: new InMemoryCache({ fragmentMatcher }),
})

const organizationClient = new ApolloClient({
  link: authLink.concat(createHttpLink({
    uri: process.env.VUE_APP_GRAPHQL_URL,
  })),
  cache: new InMemoryCache({ fragmentMatcher }),
})

const jobsClient = getJobApolloClient()

const clientClient = getClientApolloClient()

const apolloProvider = new VueApollo({
  clients: {
    organizationClient,
    mainClient,
    jobsClient,
    clientClient,
  },
  defaultClient: organizationClient,
})

export async function changeApolloClient(organization) {
  const httpLink = createHttpLink({
    uri: process.env.VUE_APP_GRAPHQL_URL ? process.env.VUE_APP_GRAPHQL_URL.replace('{organization}', organization) : '',
  })

  // eslint-disable-next-line no-use-before-define
  const _introspectionQueryResultData = await getFragment(organization)

  const _fragmentMatcher = new IntrospectionFragmentMatcher({
    introspectionQueryResultData: _introspectionQueryResultData,
  })

  const apolloClient = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache({ fragmentMatcher: _fragmentMatcher }),
  })

  apolloProvider.clients.defaultClient = apolloClient
  apolloProvider.defaultClient = apolloClient
  apolloProvider.clients.jobsClient = await getJobApolloClient(organization)
  apolloProvider.clients.clientClient = await getClientApolloClient(organization)
}

async function getFragment(organization) {
  if (!organization) return Promise.resolve()
  return new Promise(resolve => {
    const url = process.env.VUE_APP_GRAPHQL_URL ? process.env.VUE_APP_GRAPHQL_URL.replace('{organization}', organization) : ''

    const token = localStorage.getItem('accessToken')

    axios({
      method: 'POST',
      url,
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
      data: {
        variables: {},
        query: `
          {
            __schema {
              types {
                kind
                name
                possibleTypes {
                  name
                }
              }
            }
          }
        `,
      },
    })
      .then(result => {
      // here we're filtering out any type information unrelated to unions or interfaces
        const filteredData = result.data.data.__schema.types.filter(
          type => type.possibleTypes !== null,
        )
        result.data.data.__schema.types = filteredData

        resolve(result.data.data)
      }).catch(error => {
        if (error.response && error.response.data && error.response.data.code === 1505) {
          router.push({ name: 'error', query: { errorCode: error.response.data.code, errorMessage: error.response.data.message } })
        }
      })
  })
}

export { apolloProvider }
