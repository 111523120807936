<template>
  <div
    :ref="name"
    class="media-field__container"
    @click="() => $refs.menu.close()"
  >
    <BulkEdit
      v-if="assetsBeingEdited"
      :selected-data-prop="selectedData"
      @updateList="updateList"
    />

    <vue-context ref="menu">
      <li>
        <span class="assets-context">{{ $t('assets.selected', { count: selectedAssets.length }) }}</span>
      </li>
      <li :class="{'perm-disabled': !selectedAssets.length}">
        <b-link
          id="at-context-menu-edit-asset-button"
          class="d-flex align-items-center"
          @click="assetsBeingEdited = true"
        >
          <GjIcon
            name="Edit"
            size="16"
          />
          <span class="ml-75">
            {{ $t('assets.edit-images') }}
          </span>
        </b-link>
      </li>
      <li :class="{'perm-disabled': !selectedAssets.length}">
        <b-link
          id="at-context-menu-delete-asset-button"
          class="d-flex align-items-center"
          :disabled="disabled"
          @click="handleAssetsRemoval"
        >
          <GjIcon
            name="Delete"
            size="16"
          />
          <span class="ml-75">
            {{ $t('assets.remove-images') }}
          </span>
        </b-link>
      </li>
      <!-- Move to top -->
      <li :class="{'perm-disabled': !selectedAssets.length}">
        <b-link
          id="at-context-menu-move-to-top-button"
          class="d-flex align-items-center"
          :disabled="disabled"
          @click="moveSelectedToTop"
        >
          <GjIcon
            name="IconparkToTop"
            size="16"
          />
          <span class="ml-75">
            {{ $t('assets.move-to-top') }}
          </span>
        </b-link>
      </li>
      <li
        v-if="selectedAssets.length > 0"
        :class="{'perm-disabled': !selectedAssets.length}"
      >
        <b-link
          id="at-context-menu-deselect-assets-button"
          class="d-flex align-items-center"
          :disabled="disabled"
          @click="deSelectAll"
        >
          <GjIcon
            name="Close_fill"
            size="16"
          />
          <span class="ml-75">
            {{ $t('assets.deselect') }}
          </span>
        </b-link>
      </li>
      <!-- Select all -->
      <li
        v-if="selectedAssets.length < medias.length"
        :class="{'perm-disabled': selectedData.total === selectedData.limit}"
      >
        <b-link
          id="at-context-menu-select-all-assets-button"
          class="d-flex align-items-center"
          @click="selectAll"
        >
          <GjIcon
            name="Select"
            size="16"
          />
          <span class="ml-75">
            {{ $t('assets.select-all') }}
          </span>
        </b-link>
      </li>
    </vue-context>

    <span
      v-if="medias.length > 5 && !disableScroll && !expand"
      class="media__chevron chevron-left"
      @click="scroll('left')"
    >
      <GjIcon
        name="ChevronFillLeft"
        size="28"
        style="fill: #052D61;"
      />
    </span>
    <div
      ref="draggy"
      class="media-field__draggable"
    >
      <draggable
        ref="draggableItems"
        v-model="medias"
        :class="generateMediaClasses(medias.length, expand)"
        :group="{pull: false, push: false}"
        :disabled="disabled"
      >
        <div
          v-for="(media,index) in medias"
          :key="media.id + index"
          class="media-block__media"
          :class="{
            'media-block_invalid': errorIndexes.length > 0 && errorIndexes.includes(index),
          }"
          @click="handleClick(media, $event)"
          @contextmenu.prevent="(v) => { if(!disabled) $refs.menu.open(v) }"
        >
          <div
            :id="`${name}-${index}`"
            style="position: relative;"
          >

            <div
              class="draggable-media__settings media-overlay "
              :style="isSelectedAsset(media) && 'margin-top: 2px;'"
            >
              <div class="media-overlay__left">
                <div>
                  <div
                    v-show="media.width && media.height"
                    class="media-overlay__resolution"
                  >
                    {{ media.width }} x {{ media.height }}
                  </div>
                </div>
              </div>
              <div class="media-overlay__right">
                <div
                  v-if="isDeleted(media)"
                  class="media-overlay__settings"
                >
                  <GjIcon
                    size="20px"
                    name="DeleteTrash"
                    @click.native.stop="$emit('delete', media.id)"
                  />

                </div>
                <div
                  v-else-if="!disabled"
                  class="media-overlay__settings"
                >
                  <span
                    v-permission="['canEditAsset', { id: media.id }]"
                    class="hide-element"
                    @click.stop="$emit('metadata', media)"
                  >
                    <GjIcon
                      size="20px"
                      name="Edit"
                    />
                  </span>
                  <span
                    v-if="media.type === ASSET_TYPES.IMAGE"
                    v-permission="['canUploadAsset', { folderId: media.folderId }]"
                    class="hide-element"
                    @click.stop="$emit('edit', media)"
                  >
                    <GjIcon
                      size="20px"
                      name="Crop"
                    />
                  </span>
                  <GjIcon
                    v-if="media.type === ASSET_TYPES.IMAGE"
                    name="IconparkFocus"
                    size="20px"
                    class="hide-element"
                    @click.native.stop="$emit('focalPoint', media)"
                  />
                  <GjIcon
                    size="20px"
                    name="DeleteTrash"
                    class="hide-element"
                    @click.native.stop="$emit('delete', media.id)"
                  />
                  <GjIcon
                    size="20px"
                    name="Show"
                    @click.native.stop="$emit('showAsset',media.id)"
                  />
                </div>
                <div
                  v-else
                  class="media-overlay__settings"
                >
                  <GjIcon
                    size="20px"
                    name="Show"
                    @click.native.stop="$emit('showAsset',media.id)"
                  />
                </div>
                <div
                  v-if="media.expiresAt && !disabled"
                  class="media-overlay__expire"
                >
                  <GjIcon
                    v-if="checkExpired(media.expiresAt)"
                    class="media-overlay__expire-expired"
                    size="16"
                    name="Denied"
                  />
                  <GjIcon
                    v-else
                    v-b-tooltip
                    :title="media.expiresAt | formatDateTime"
                    class="media-overlay__expire-info"
                    name="Info_fill"
                    size="16"
                  />
                </div>
              </div>
            </div>
            <div
              class="media-block__media-container"
              :class="{'media-block__media-container--selected': isSelectedAsset(media)}"
            >
              <div
                v-if="isDeleted(media)"
                class="draggable-media__file deleted-media media__image"
              >
                <span class="deleted-asset"> <GjIcon
                  name="HideAlt"
                  size="28"
                  style="fill: #9595A0;"
                /></span>
              </div>
              <b-img-aspect
                v-else-if="isLive(media) && media.id !== playingId"
                :key="media.url"
                aspect="16/9"
                class="media__image"
                :src="require('@/assets/images/icons/livestream.png')"
              />
              <b-aspect
                v-else-if="showMediaType(media.type) && media.id !== playingId && !isFailedLoading(media.url)"
                :key="media.url"
                aspect="16/9"
                class="position-relative align-items-center justify-content-center media__image"
              >
                <div class="media__image-container">
                  <b-img
                    :src="media.url"
                    :fluid="true"
                    @error="() => failedImages.push(media.url)"
                  />
                </div>
                <div
                  v-if="media.type !== ASSET_TYPES.IMAGE"
                  class="video__play-icon-container"
                >
                  <div
                    class="video__play-icon"
                    @click.capture.stop="setPlayer(media, $event)"
                  >
                    <GjIcon
                      :name="typeIcon(media.type)"
                      size="24"
                    />
                  </div>
                  <div
                    v-if="isLive(media)"
                    class="video__live-stream-icon"
                  >
                    <div class="video__live-stream-icon__dot" />
                    <span class="video__live-stream-icon__text">
                      {{ $t('assets.videos.live') }}
                    </span>
                  </div>
                  <div class="video__icons">
                    <div class="video__icon">
                      <img
                        src="@/assets/icons/vp-icon.svg"
                        class="video__vp-icon"
                        alt=""
                      >
                    </div>
                    <div
                      v-if="isLive(media)"
                      class="video__icon"
                    >
                      <GjIcon
                        name="Livestream - main"
                        size="16"
                      />
                    </div>
                  </div>
                </div>
              </b-aspect>
              <b-aspect
                v-else-if="media.type === ASSET_TYPES.VIDEO && media.id === playingId"
                :key="media.id"
                aspect="16/9"
                class="media__image"
              >
                <iframe
                  :src="media.videoUrl"
                  frameborder="0"
                  style="width: 100%;height: 100%;"
                  allow="autoplay *; encrypted-media *; fullscreen *"
                  sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-storage-access-by-user-activation allow-top-navigation-by-user-activation"
                />
              </b-aspect>

              <div
                v-else
                class="draggable-media__file media__image"
              >
                <span>
                  <b-spinner v-if="media.uploadStatus === 'uploading'" />
                  <img
                    v-else-if="media.type === ASSET_TYPES.FILE || media.type === ASSET_TYPES.IMAGE"
                    :src="require('@/assets/images/icons/file.svg')"
                    alt=""
                  >
                  <img
                    v-else-if="media.type === ASSET_TYPES.VIDEO_PLAYLIST || media.type === ASSET_TYPES.VIDEO"
                    :src="require('@/assets/images/icons/video-playlist.svg')"
                    alt=""
                  >
                  <img
                    v-else-if="media.type === ASSET_TYPES.AUDIO"
                    :src="require('@/assets/images/icons/audio.svg')"
                    alt=""
                  >
                  <img
                    v-else-if="media.type === ASSET_TYPES.PODCAST"
                    :src="require('@/assets/images/icons/podcast.svg')"
                    alt=""
                  >
                </span>
              </div>
            </div>
          </div>
          <div v-if="showImageDetails">
            <CaptionInput
              v-model="media.customCaption"
              :asset-caption="media.caption"
              :disabled="disabled"
              @input="$emit('input',medias)"
            />
            <AssetDetails :media="media" />
            {{ media.focals }}
          </div>
        </div>
      </draggable>
    </div>
    <span
      v-if="medias.length > 5 && !disableScroll && !expand"
      class="media__chevron chevron-right"
      @click="scroll('right')"
    >
      <GjIcon
        name="ChevronFillRight"
        size="28"
        style="fill: #052D61;"
      />
    </span>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import { ASSET_TYPES, ASSET_STATUSES } from '@/codex-sdk/assets'
import AssetDetails from '@/components/assets/AssetDetails.vue'
import CaptionInput from '@/components/assets/CaptionInput.vue'
import moment from 'moment'
import VueContext from 'vue-context'
import BulkEdit from '@/components/assets/BulkEdit.vue'
import gql from 'graphql-tag'

export default {
  name: 'DraggableAssets',
  components: {
    BulkEdit,
    CaptionInput,
    AssetDetails,
    draggable,
    VueContext,
  },
  props: {
    showImageDetails: {
      type: Boolean,
      required: false,
      default: false,
    },
    value: {
      type: Array,
      required: true,
    },
    disableScroll: {
      type: Boolean,
      required: false,
      default: false,
    },
    type: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: false,
      default: 'draggable-media-slick',
    },
    expand: {
      type: Boolean,
      required: false,
      default: false,
    },
    errorIndexes: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      ASSET_TYPES,
      scrollAmount: 0,
      playingId: null,

      // From modulesAssetsState.js
      selectedData: {
        items: [],
        show: false,
        total: 0,
        limit: Infinity,
        totalSize: 0,
      },
      lastClickedAsset: null,
      assetsBeingEdited: false,
      failedImages: [],
    }
  },
  computed: {
    isFailedLoading() {
      return mediaUrl => {
        // check if failedImages contains mediaUrl
        const found = this.failedImages.find(img => img === mediaUrl)
        return !!found
      }
    },
    selectedAssets: {
      get() {
        return this.selectedData.items
      },
      set(v) {
        if (v.length <= this.selectedData.limit) {
          const addedItem = v.length > this.selectedData.items.length

          this.selectedData = {
            ...this.selectedData,
            totalSize: 0,
            items: v,
            total: v.length,
            lastSelection: addedItem ? v[v.length - 1] : this.lastClickedAsset,
          }
        } else if (this.selectedData.limit == 1 && v.length == 2) {
          const array = [v[1]]
          this.selectedData = {
            ...this.selectedData,
            totalSize: 0,
            items: array,
            total: array.length,
            lastSelection: v[1],
          }
        }
      },
    },

    medias: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit('input', v)
      },
    },
    typeIcon() {
      return type => {
        if (type === ASSET_TYPES.VIDEO) return 'Play'
        if (type === ASSET_TYPES.AUDIO) return 'IconparkAudioFile'
        if (type === ASSET_TYPES.PODCAST) return 'IconparkMicrophone'
        return 'IconparkFileQuestion'
      }
    },
  },
  watch: {
    currentMedias() {
      this.$emit('change', this.currentMedias)
    },
  },
  methods: {
    selectAll() {
      if (this.disabled) return
      this.selectedAssets = this.medias
    },
    deSelectAll() {
      if (this.disabled) return
      this.selectedAssets = []
    },
    moveSelectedToTop() {
      if (this.disabled) return
      const movedAssets = []
      const notMovedAssets = []
      this.medias.forEach(media => {
        if (this.selectedAssets.some(img => img.id === media.id)) {
          movedAssets.push(media)
        } else {
          notMovedAssets.push(media)
        }
      })
      this.medias = [...movedAssets, ...notMovedAssets]
    },
    async updateList() {
      if (this.selectedData.items.length > 0) {
        const item = this.selectedData.items[0]
        if (item.labels.length > 0) {
          const { data } = await this.$apollo.query(
            {
              query: gql`
            query ($ids: [String!]) {
              labelCollection(where:{ id: {in: $ids}}) {
                items {
                  id
                  name
                  color
                }
              }
            }
          `,
              variables: {
                ids: item.labels.map(l => l.id),
              },
            },
          )
          // set the labels of each item to the labels from the query
          this.selectedData.items.forEach(img => {
            img.labels = data.labelCollection.items
          })
        }
      }
      this.assetsBeingEdited = false
    },
    handleAssetsRemoval() {
      if (this.disabled) return
      // for each
      // this.selectedAssets.forEach(asset => {
      //   this.$emit('delete', asset.id)
      // })

      this.$emit('delete', this.selectedAssets.map(asset => asset.id))

      this.selectedAssets = []
    },
    isSelectedAsset(asset) {
      return this.selectedAssets.some(img => img.id === asset.id)
    },
    handleClick(asset, e) {
      if (this.disabled) return
      this.lastClickedAsset = asset
      const exists = this.selectedAssets.some(img => img?.id === asset.id)

      if ((e.ctrlKey || e.metaKey) || this.selectedData.show) {
        // if ctrl / command key is pressed
        // add/remove asset to/from selected assets

        if (exists) {
          this.selectedAssets = this.selectedAssets.filter(img => img.id !== asset.id)
        } else if (asset.uploadStatus !== 'uploading') {
          this.selectedAssets = [...this.selectedAssets, asset]
        }
      } else if (e.shiftKey && this.selectedData.lastSelection && (this.selectedData.lastSelection.folderId === this.folderId || !this.folderId)) {
        // if shift key is pressed and last selection exists
        // select all assets between first and last selection

        const firstIndex = this.medias.findIndex(img => img.id === asset.id)
        const lastIndex = this.medias.findIndex(img => img.id === this.selectedData.lastSelection.id)
        const start = Math.min(firstIndex, lastIndex)
        const end = Math.max(firstIndex, lastIndex)
        const array = this.medias.slice(start, end + 1)

        this.selectedAssets = [
          ...this.selectedAssets,
          ...array.filter(x => {
            const alreadySelected = this.selectedAssets.some(img => img.id === x.id)
            const isUploading = x.uploadStatus === 'uploading'

            if (this.isPopup) {
              const isAfter = moment().isAfter(x.expiresAt)
              return !alreadySelected && !isUploading && !isAfter
            }

            return !alreadySelected && !isUploading
          }),
        ]
      } else {
        // if no meta key is pressed
        // select only this asset

        const otherFolderAssets = this.folderId ? this.selectedData.items.filter(x => x.folderId !== this.folderId) : []

        this.selectedAssets = asset.uploadStatus !== 'uploading' ? [...otherFolderAssets, asset] : [...otherFolderAssets]
      }
    },

    getHeight(type) {
      if (type === ASSET_TYPES.AUDIO) {
        return '180'
      }
      if (type === ASSET_TYPES.PODCAST) {
        return '410'
      }
      return ''
    },
    setPlayer(media, e) {
      if (media.type === ASSET_TYPES.VIDEO) {
        this.playingId = media.id
      } else this.handleClick(media, e)
    },
    checkExpired(time) {
      return moment().isAfter(time)
    },
    generateMediaClasses(length, overrule) {
      if (length >= 5 && overrule) {
        return 'draggable-images__expandable'
      }
      if (length <= 5 || length === 1) {
        return 'draggable-images__no-slick'
      }
      return 'draggable-images__slick'
    },
    scroll(side) {
      const speed = this.medias.length > 9 ? 350 : 220
      const element = this.$refs.draggy
      if (side == 'right') {
        element.scroll({
          left: this.scrollAmount < element.scrollWidth - element.clientWidth ? this.scrollAmount += speed : this.scrollAmount = 0,
          behavior: 'smooth',
        })
      } else {
        element.scrollTo({
          left: this.scrollAmount <= 0 ? this.scrollAmount = element.scrollWidth - element.clientWidth + 30 : this.scrollAmount -= speed,
          behavior: 'smooth',
        })
      }
    },
    showMediaType(type) {
      if (Number.isInteger(type)) {
        const keys = Object.keys(ASSET_TYPES)
        return keys.some(val => (ASSET_TYPES[val] === type && (val === ASSET_TYPES.IMAGE || val === ASSET_TYPES.VIDEO || val === ASSET_TYPES.AUDIO || val === ASSET_TYPES.PODCAST)))
      }
      return ASSET_TYPES[type] === ASSET_TYPES.IMAGE || ASSET_TYPES[type] === ASSET_TYPES.VIDEO || ASSET_TYPES[type] === ASSET_TYPES.AUDIO || ASSET_TYPES[type] === ASSET_TYPES.PODCAST
    },
    isLive(media) {
      let type
      if (Number.isInteger(media.type)) {
        const keys = Object.keys(ASSET_TYPES)
        keys.forEach(val => {
          if (ASSET_TYPES[val] === media.type) type = val
        })
        return type
      }
      return (media.type === ASSET_TYPES.VIDEO || type === ASSET_TYPES.VIDEO) && media.attrs && (media.attrs.isLive === 'true' || media.attrs.isLive === true)
    },
    isDeleted(media) {
      return media.status === ASSET_STATUSES.DELETED
    },
  },
}
</script>

<style lang="scss">

.media__chevron {
  z-index: 7;
  width: 33px;
  height: 33px;
  background: #FFFFFF;
  border-radius: 30px;
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  pointer-events: all;
}

.chevron-left {
  left: 20px;
}

.chevron-right {
  right: 20px;
}

.media-field__container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.media-field__container .assets-context {
  display: flex;
  padding: 0.5rem 1.5rem;
}

.media-field__chevron {
  width: 22px;
  height: 22px;
  border-radius: 50%;
}

.media-field__draggable {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: left;
  overflow: hidden;
  width: 100%;
  height: fit-content;
}

.media-field__fit {
  display: flex;
  flex-direction: row;
  height: fit-content;
  align-items: center;
  flex-wrap: nowrap;
  width: 100%;
}

.media-field__draggable {
  .media-block__media {
    margin: 1px;
    border: 2px solid transparent;
    border-radius: 4px;
  }
}

.media-block__media-container {
  margin: 2px;
}

.media-block__media-container--selected {
  margin: 0;
  border: 2px solid #1d79f2;
  border-radius: 3px;
}

.draggable-images__no-slick, .draggable-images__expandable {
  width: 100%;
  height: fit-content;

  // grid min-max 200px - 300px
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));

  .media-block__media {
    //width: calc(100% / 3);
    padding: 2px;

    .media__image {
      width: 100%;
      height: 12rem !important;

      .center {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .img-fluid {
        height: 100%;
      }
    }
  }
}

.media-field__container .media-block__media {
  cursor: pointer;
}

.media-block_invalid {
  border: 2px solid #e34850 !important;
  border-radius: 4px;
}

.draggable-images__wider-spread {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: fit-content;
  justify-content: left;

  .media-block__media {
    width: 33%;
    margin: 4px;

    .media__image {
      width: 100%;
      height: 260px;
      object-fit: cover;
    }
  }
}

.draggable-images__slick {
  display: flex;
  flex-direction: row;
}

@media(max-width: 1008px) {
  .draggable-images__wider-spread {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    .media-block__media {
      width: 100%;
    }
  }
}

@media(max-width: 1100px) {
  .draggable-images__no-slick {
    display: flex;
    flex-direction: column;
    align-items: center;

    .media-block__media:first-child, .media-block__media {
      width: 75%;

      .media__image {
        width: 100%;
        max-width: 100%;
        object-position: center;
        height: 150px;
        object-fit: cover;
        padding: 8px;
        margin: 0;
      }
    }
  }
}

.media__image {
  width: 13rem;
  border-radius: 4px;
  object-fit: cover;
  background-color: rgba(0,0,0,0.1);
  .media__image-container {
    width: 100%;
    padding-bottom: 56.25%;
    overflow: hidden;
    img {
      position:absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  .b-aspect {
    height: 100%;
  }
}

.media__first {
  width: 99%;
  height: 24rem;
  object-fit: cover;
  object-position: center;
  border-radius: 4px;
  margin-bottom: 16px;
}

.media-field__labels {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .media-field__label-edit {
    cursor: pointer;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 18px;
    color: #455A82;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: center;

    .media-field__edit-icon {
      width: 14px;
      height: 14px;
      margin-left: 4px;
      margin-bottom: 2px;
      padding-bottom: 1px;
    }
  }
}
.media-overlay {
  position: absolute;
  z-index: 6;
  display: flex;
  justify-content: space-between;
  height: 100%;
  width: calc(100% - 4px);
  max-height: 100%;
  padding: 6px;
  pointer-events: none;
  .media-overlay__left, .media-overlay__right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: fit-content;
    gap: 4px;
  }
}

.media-overlay__settings {
  display: flex;
  pointer-events: all;
  flex-direction: column;
  align-items: flex-end;
  flex-wrap: wrap-reverse;
  justify-content: flex-start;
  align-content: flex-start;
  flex: 1;
  min-height: 0;
  min-width: 0;
  opacity: 0;
  transition: all 0.3s ease-out;
  @at-root .media-block__media:hover & {
    opacity: 1;
  }
  svg {
    &:first-child {
      border-top-right-radius: 4px;
    }
    box-sizing: content-box;
    padding: 4px;
    background-color: rgba(18, 37, 62, 0.5);
    color: white;
    cursor: pointer;
  }
}

.media-overlay__expire {
  flex-shrink: 0;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  pointer-events: all;
  .media-overlay__expire-expired {
    background-color: white;
    border-radius: 1000px;
    color: #E34850;
    padding: 1px;
  }
  .media-overlay__expire-info {
    border-radius: 1000px;
    color: #EF8C13;
  }
}

.media-overlay__resolution {
  border-radius: 4px;
  background: rgba(18, 37, 62, 0.50);
  backdrop-filter: blur(10px);
  color: white;
  padding: 2px 4px;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
}
.draggable-media__file {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: #F4F6FA;
  aspect-ratio: 16 / 9;

  img {
    width: 96px;
  }
}

.video__play-icon-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  z-index: 1;
}

.video__play-icon {
  color: white;
  position: absolute;
  z-index: 1;
  pointer-events: all;
}

.draggable__expendable {
  position: absolute;
  top: 20px;
  height: 45px;
  width: 40px;
  left: 49%;
  z-index: 100;
  display: flex;
  flex-direction: row;

  .expandable__icon {
    display: none;
  }

  justify-content: center;

  &:hover {
    .expandable__icon {
      z-index: 5;
      background: white;
      width: 30px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      height: 30px;
      border-radius: 50%;
    }
  }
}

.media-image__details {
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 4px 0;

  p {
    margin: 0;
    letter-spacing: 1px;
  }
}
.media-image__caption {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 4px;
  svg {
    flex-shrink: 0;
    cursor: pointer;
  }
}
.media-image__input {
  width: 100%;
  padding: 2px;
  margin: -2px;
  max-height: 36px;
  overflow-y: auto;
  pointer-events: none;

  line-height: 18px;
  font-weight: 500;
  font-size: 12px;
  color: #052D61;
  transition: max-height 0.3s ease;
  &:focus {
    outline-color: var(--border-color);
    max-height: 90px;
    pointer-events: all;
  }

  &.media-image__input--empty {
    font-style: italic;
    font-weight: 400;
    color: #667C99;
    pointer-events: all;

    &::before {
      content: 'Add caption';
      color: #667C99;
      position: absolute;
    }
  }
}

.media-image__badges {
  display: flex;
  flex-direction: row;
  gap: 4px;
  flex-wrap: wrap;

  .badge {
    background: #F4F6FA;
    border: 1px solid #E0E5EB;
    color: #052D61;
    font-weight: 400;
    font-size: 10px;
    line-height: 1;
    height: 22px;
    padding-top: 0;
    padding-bottom: 0;
    display: flex;
    align-items: center;
  }
}

.media-image__labels {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 4px;
  align-items: center;

  .media-image__label {
    color: #052D61;
    font-size: 10px;
    font-weight: 400;
    line-height: 18px;
  }

  .media-image__labels-dropdown {
    button.btn.dropdown-toggle {
      padding: 4px 4px;
      margin: 0;
      height: 22px !important;
      background: #fff;
      border: 1px solid #E0E5EB !important;
      color: #052D61;
      font-weight: 400;
      font-size: 10px;
      border-radius: 1000px;
    }
    .dropdown-menu {
      list-style: none;
      min-width: 5rem;
      max-width: 10rem;
      a {
        background-color: transparent;
        text-decoration: none;
        color: #6e6b7b;
        font-size: 12px;
        padding: 4px 8px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        &:hover {
          color: #1D79F2;
        }
      }
    }
  }
}

.media-image__badges-dropdown {
  button.btn.dropdown-toggle {
    padding: 4px 4px;
    margin: 0;
    height: 22px !important;
    background: #F4F6FA;
    border: 1px solid #E0E5EB !important;
    color: #052D61;
    font-weight: 400;
    font-size: 10px;
    border-radius: 1000px;
  }
  .dropdown-menu {
    list-style: none;
    min-width: 5rem;
    max-width: 10rem;
    a {
      background-color: transparent;
      text-decoration: none;
      color: #6e6b7b;
      font-size: 12px;
      padding: 4px 8px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      &:hover {
        color: #1D79F2;
      }
    }
  }
}

.media-image__primary {
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #052D61;
}

.media-image__secondary {
  font-weight: 400;
  font-size: 10px;
  line-height: 16px;
  color: #667C99;
}

.deleted-media{
  background-color: #F7F7F8  ;
}

.deleted-asset{
  background: white!important;
  background-image: linear-gradient(to top, #EBDDFD, #F3F2F4  ) !important;
  padding:9px;
  border-radius:12px;

}

</style>
