<template>
  <b-sidebar
    id="quick-view-entry_sidebar"
    v-model="showPopup"
    :title="$t('popups.quickViewEntryPopup.title')"
    class="c-sidebar c-sidebar--xl"
    right
    shadow
    backdrop
  >
    <FullHeightLayout
      class="entry-edit__main-inner"
      min
    >
      <ModelRenderer
        v-if="model && !loading"
        :key="entry.system.versionId"
        :model="model"
        :is-read-only="true"
      />
    </FullHeightLayout>
  </b-sidebar>
</template>
<script>
import PopupsMixin from '@/components/popups/PopupsMixin'
import { readModel } from '@/codex-sdk/models'
import { readEntry, entryDefaults } from '@/codex-sdk/entries'
import { getBlocks, VALUE_TYPES } from '@/views/models/constants'
import FullHeightLayout from '@/../gjirafa-front/script/full-height-layout.vue'
import ModelRenderer from '@/views/entries/render-components/ModelRenderer.vue'
import { checkPermissionAsync } from '@/codex-permissions/config'
import { checkConditions, getFieldPermissions } from '@/utils/helpers'
import { debounce } from 'lodash'

export default {
  name: 'QuickViewEntry',
  components: {
    ModelRenderer,
    FullHeightLayout,
  },
  mixins: [PopupsMixin],
  data() {
    return {
      model: { blocks: [] },
      version: {
        currentVersionId: '',
        previewingVersion: null,
        version: null,
      },
      loading: false,
      validation: {},
      entry: entryDefaults(),
      entryId: this.data?.entryId,
      modelAlias: this.data?.modelAlias,
      siteId: this.data?.siteId,
      currentEntry: this.data?.entry,
      previousEntry: null,
      nextEntry: null,
    }
  },
  computed: {
    getEntry() { return this.entry || {} },
  },
  provide() {
    return {
      entry: () => this.getEntry,
      model: () => this.model,
      validationData: () => this.validation,
      comments: [],
      allComments: () => [],
      selectedEditor: () => {},
      setSelectedEditor: () => {},
      showError: () => false,
      fieldPermissions: () => ({
        viewableFields: this.viewableFields,
        editableFields: this.editableFields,
      }),
      isPreview: () => false,
      checkConditions: this.checkConditions,
      setFieldValue: () => {},
      filterValues: null,
      entryId: () => this.entryId,
      site: () => {},
    }
  },
  permissions: {
    viewableFields: {
      async handler(vars) {
        const { entry, model } = vars
        if (!model?.alias || !model?.fields || model?.fields.length === 0) return false

        const canViewFields = await checkPermissionAsync(entry.id, 'entry', 'view', { model: entry?.system?.model?.alias || model?.alias }, true)
        return getFieldPermissions(canViewFields, model)
      },
      variables() {
        return {
          entry: this.entry,
          model: this.model,
        }
      },
    },

    editableFields: {
      async handler(vars) {
        const { entry, model } = vars
        if (!model?.alias || !model?.fields || model?.fields.length === 0) return false

        const canEditFields = await checkPermissionAsync(entry.id, 'entry', 'editcontent', { model: entry?.system?.model?.alias || model?.alias }, true)
        return getFieldPermissions(canEditFields, model)
      },
      variables() {
        return {
          entry: this.entry,
          model: this.model,
        }
      },
    },
  },
  watch: {
    currentEntry: {
      handler: debounce(function () {
        this.fetchModelEntry()
      }, 100),
      deep: true,
    },
  },
  beforeMount() {
    window.addEventListener('keydown', this.handleKeydown)
  },
  beforeDestroy() {
    window.removeEventListener('keydown', this.handleKeydown)
    this.$root.$emit('quickViewChanged', { id: null })
  },
  mounted() {
    this.fetchModelEntry()
    this.getEntries()
  },
  methods: {
    checkConditions(config) {
      return checkConditions(config, this.model, this.getEntry)
    },
    async getEntries() {
      if (this.data?.entries) {
        const currentIndex = this.data.entries.findIndex(x => x.id === this.currentEntry.id)
        this.previousEntry = currentIndex > 0 ? this.data.entries[currentIndex - 1] : null
        this.nextEntry = currentIndex < this.data.entries.length - 1 ? this.data.entries[currentIndex + 1] : null
      }
    },
    async fetchModelEntry() {
      try {
        this.loading = true
        await this.fetchModel()
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    handleKeydown(e) {
      if (e.keyCode === 38) {
        // Up
        if (this.previousEntry) {
          this.currentEntry = this.previousEntry
          this.modelAlias = this.previousEntry.system.modelAlias
        }
      } else if (e.keyCode === 40) {
        // Down
        if (this.nextEntry) {
          this.currentEntry = this.nextEntry
          this.modelAlias = this.nextEntry.system.modelAlias
        }
      }
      this.$root.$emit('quickViewChanged', { id: this.currentEntry?.id })
      this.getEntries()
    },
    async fetchModel() {
      try {
        const { data } = await readModel(this.modelAlias)
        this.model = data
      } catch (e) {
        console.log(e)
      } finally {
        await this.fetchEntry()
      }
    },
    async fetchEntry() {
      try {
        const { data } = await readEntry({
          model: this.model.alias,
          id: this.currentEntry?.id || this.entryId,
          siteId: this.currentEntry?.system?.siteId || this.siteId,
        })
        this.entry = data
        this.$root.$emit('quickViewChanged', { id: data.id })
      } catch (e) {
        console.log(e)
      }
      this.prepareFields()
    },
    prepareFields() {
      const fields = this.getFields(this.model.blocks)

      fields.forEach(field => {
        if (this.entry.content[field.alias] == undefined) {
          if (Object.prototype.hasOwnProperty.call(field, 'value') && field?.value?.toString()?.length) {
            this.$set(this.entry.content, field.alias, field.value)
          } else {
            this.$set(this.entry.content, field.alias, field.valueType === VALUE_TYPES.SINGLE ? null : [])
          }
        }
        this.validation[field.alias] = { isValid: true }
      })
    },
    getFields(blocks) {
      const fields = getBlocks(blocks, block => block.isField)
      return fields.map(block => ({ alias: block?.attrs?.alias, valueType: block?.attrs?.valueType, value: block?.attrs?.defaultValue }))
    },
  },
}
</script>

<style lang="scss">
#quick-view-entry_sidebar {
  .codex-field {
    // pointer-events: none;

    .custom-switch .custom-control-label::before {
      // pointer-events: none;
    }
  }
  .entry-edit__main-inner {
    margin: 0 !important;
    max-width: 100%;
  }

  // Hide placeholders
  p.is-empty {
    visibility: hidden;
  }

  .rich-text-field .codex-field__quill .ql-editor.ql-blank::before {
    display: none;
  }

  input::placeholder {
    color: transparent;
  }

  .hide-in-quick-view {
    display: none;
  }

  .disable-in-quick-view {
    pointer-events: none;
  }
  .b-form-tag-remove {
    display: none;
  }
}
</style>
