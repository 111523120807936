export function TikTokGetID(url) {
  let ID = ''
  if (url && url.length) {
    const a = url.split(/[^0-9a-z_\\-]/i)
    for (let i = 0; i < a.length; i++) {
      if (a[i].match(/^[0-9]+$/) && a[i].length > 1) {
        ID = a[i]
        break
      }
    }
  }
  return ID
}
