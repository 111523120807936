<template>
  <div>
    <b-form-group :label="$t('fields.general.help-text.label')">
      <b-form-textarea
        v-model="helpText"
        rows="3"
        no-resize
        :placeholder="$t('fields.general.help-text.placeholder')"
      />
    </b-form-group>

    <b-form-group>
      <legend
        tabindex="-1"
        class="bv-no-focus-ring col-form-label pt-0"
      >
        {{ $t('fields.general.help-text.display-title') }}
      </legend>
      <v-select
        v-model="helpTextDisplay"
        :options="helpTextDisplayOptions"
        :clearable="false"
        :reduce="e => e.value"
      />
    </b-form-group>
  </div>
</template>

<script>
import { generateComputedPropsFromAttrs } from '@/components/codex-layout-editor/BuilderUtils'

export default {
  name: 'FieldHelpTextInput',
  props: {
    updateAttrs: {
      type: Function,
      default: () => {},
    },
    widget: {
      required: true,
      type: Object,
    },
  },
  computed: {
    ...generateComputedPropsFromAttrs([
      'helpText',
      'helpTextDisplay',
    ]),

    helpTextDisplayOptions() {
      return [
        {
          value: 'tooltip',
          label: this.$t('fields.general.help-text.display-option-tooltip'),
        },
        {
          value: 'inline-top',
          label: this.$t('fields.general.help-text.display-option-inline-top'),
        },
      ]
    },
  },
}
</script>
