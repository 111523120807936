<template>
  <div>
    <div class="bubble-comment__main">
      <div class="bubble-comment__header">
        <div class="bubble-comment__author">
          <b-avatar
            size="sm"
            :text="userInitial"
            :src="userAvatar"
          />
          <span>{{ userFullName }}</span>
        </div>
        <div class="bubble-comment__actions">
          <span class="bubble-comment__status">
            <GjIcon
              v-if="comment.status === COMMENT_STATUSES.RESOLVED"
              name="Check"
              size="20"
            />
          </span>
          <b-dropdown
            v-if="(!isReply && comment.status !== COMMENT_STATUSES.RESOLVED) || currentUserComment"
            variant="flat-none"
            class="dropdown-icon-wrapper"
            size="sm"
            right
          >
            <template #button-content>
              <b-spinner
                v-if="saving || isDeleting"
                small
              />
              <GjIcon
                v-else
                name="Moreoptions"
                size="20"
              />
            </template>

            <b-dropdown-item
              v-if="!isReply && comment.status !== COMMENT_STATUSES.RESOLVED"
              class="at-article-comment-resolve"
              @click="resolveComment"
            >
              {{ $t('codex-editor.panels.comment.resolve') }}
            </b-dropdown-item>
            <b-dropdown-item
              v-if="currentUserComment"
              class="at-article-comment-edit"
              @click="startEditing"
            >
              {{ $t('codex-editor.panels.comment.edit') }}
            </b-dropdown-item>
            <b-dropdown-item
              v-if="currentUserComment"
              class="at-article-commets-delete"
              @click="deleteComment"
            >
              {{ $t('codex-editor.panels.comment.delete') }}
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </div>
      <div class="bubble-comment__content">
        <template v-if="!editingComment">
          <div
            class="cursor-pointer text-break"
            @click="scrollToBlock"
            v-html="comment.content"
          />
        </template>
        <template v-if="editingComment">
          <MentionsInput
            v-model="replyEditComment"
            :placeholder="$t('codex-editor.panels.comment.replay-edit-placeholder')"
            class="bubble-comment__reply-form-input"
            @mentions="mentions => replyEditMentions = mentions"
          />

          <div class="bubble-comment__reply-form-actions">
            <b-button
              class="at-article-comment-edit-cancel"
              size="sm"
              variant="link-dark"
              @click.stop="cancelEditing"
            >
              {{ $t('general.cancel-button') }}
            </b-button>
            <b-button
              class="at-article-comment-edit-save"
              size="sm"
              variant="link-primary"
              @click.stop="updateComment"
            >
              {{ $t('general.save-button') }}
            </b-button>
          </div>
        </template>
      </div>

      <div class="bubble-comment__footer">
        <div class="bubble-comment__date">
          {{ comment.createdAt | formatDateTime }}
        </div>
        <div
          v-if="!isReply"
          class="bubble-comment__reply-form-toggle at-article-comment-reply"
          @click="toggleReplies"
        >
          <GjIcon
            name="Reply"
            size="18"
          />{{ $t('codex-editor.panels.comment.reply') }}
        </div>
      </div>

    </div>

    <div
      v-if="replies.length > 0"
      class="bubble-comment__replies"
    >
      <Comment
        v-for="r in replies"
        :key="r.id"
        :comment="r"
        :parent-comment="comment"
        class="bubble-comment__reply"
      />
    </div>

    <div
      v-if="!isReply && showReply"
      class="bubble-comment__reply-form"
    >
      <MentionsInput
        ref="replayInput"
        v-model="reply.content"
        :placeholder="$t('codex-editor.panels.comment.reply-placeholder')"
        class="bubble-comment__reply-form-input"
        @mentions="mentions => reply.mentions = mentions"
      />
      <div class="bubble-comment__reply-form-actions">
        <b-button
          class="at-article-comment-reply-cancel"
          size="sm"
          variant="link-dark"
          @click.stop="toggleReplies"
        >
          {{ $t('general.cancel-button') }}
        </b-button>
        <b-button
          class="at-article-comment-reply-post"
          size="sm"
          variant="link-primary"
          @click.once.stop="postNewReply"
        >
          {{ $t('general.post-button') }}
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import {
  commentDefaults, COMMENT_STATUSES, deleteComment, updateComment, resolveComment, createComment,
} from '@/codex-sdk/comments'
import { mapGetters } from 'vuex'
import { OIDC_STORE_MODULE } from '@/../gjirafa-front/script/auth'
import MentionsInput from '@/components/MentionsInput.vue'
import { orderBy } from 'lodash'
import moment from 'moment'

export default {
  name: 'Comment',
  inject: ['allComments', 'entryId'],
  components: {
    MentionsInput,
  },
  props: ['parentComment', 'comment', 'saving'],
  data() {
    return {
      COMMENT_STATUSES,
      reply: commentDefaults({
        parentId: this.comment.id,
        attrs: { ...(this.comment?.attrs || {}) },
        threadId: this.comment.threadId,
        openBubble: true,
      }),
      replyEditComment: '',
      replyEditMentions: [],
      showReply: false,
      editingComment: false,
      isDeleting: false,
    }
  },
  computed: {
    ...mapGetters(OIDC_STORE_MODULE, [
      'oidcUser',
    ]),
    replies() {
      if (!this.comment?.children?.items) return []
      return orderBy(this.comment.children.items, comment => moment(comment.createdAt).format('x'), 'asc')
    },
    currentUserComment() {
      return this.comment?.createdBy?.id === this.oidcUser?.sub
    },
    userFullName() {
      return `${this.comment?.createdBy?.firstName || ''} ${this.comment?.createdBy?.lastName || ''}`.trim() || this.comment?.createdBy?.email || ''
    },
    userInitial() {
      return this.userFullName.slice(0, 1).trim()
    },
    userAvatar() {
      return this.comment?.createdBy?.imageUrl || null
    },
    isReply() {
      return !!this.comment.parentId
    },
  },
  methods: {
    scrollToBlock() {
      const comment = this.isReply ? this.parentComment : this.comment
      const { blockId, fieldAlias } = comment.attrs
      let node = null
      if (blockId) {
        node = document.querySelector(`.ProseMirror [data-id='${blockId}']`)
      } else if (fieldAlias) {
        node = document.querySelector(`.codex-field-${fieldAlias} .codex-field__label-container`)
      }

      if (!node) return
      const rect = node.getBoundingClientRect()
      const scrollY = window.pageYOffset || window.scrollY
      window.scroll({ left: 0, top: rect.top + scrollY - 140, behavior: 'smooth' })
      this.$set(comment, 'openBubble', true)
    },
    startEditing() {
      this.replyEditComment = this.comment.content
      this.editingComment = true
    },
    cancelEditing() {
      this.replyEditComment = ''
      this.replyEditMentions = []
      this.editingComment = false
    },
    toggleReplies() {
      this.showReply = !this.showReply
      if (!this.showReply) this.reply.content = ''
      this.$nextTick(() => {
        const replyInput = this.$refs?.replayInput?.$el || this.$refs?.replayInput
        if (replyInput) {
          const el = replyInput.querySelector('.mentions-input')
          el.focus()
          el.scrollIntoView({ behavior: 'smooth', block: 'nearest' })
        }
      })
    },
    async deleteComment() {
      try {
        this.isDeleting = true
        const { data } = await deleteComment(this.comment.id)
        if (data.deleted && this.isReply) {
          this.parentComment.children.items = this.parentComment.children.items.filter(c => c.id !== this.comment.id)
        }
        this.isDeleting = false
      } catch (e) {
        console.log(e)
      }
      this.$root.$emit('refreshComments')
    },
    async resolveComment() {
      try {
        await resolveComment(this.comment)
        this.$set(this.comment, 'status', COMMENT_STATUSES.RESOLVED)
      } catch (e) {
        console.log(e)
      }
    },
    async updateComment() {
      try {
        const comment = {
          id: this.comment.id,
          content: this.replyEditComment,
          mentions: this.replyEditMentions,
        }
        await updateComment(comment)
        this.comment.content = this.replyEditComment
      } catch (err) {
        console.log(err)
      }
      this.editingComment = false
      this.$root.$emit('refreshComments')
    },
    async postNewReply() {
      try {
        const { data } = await createComment(this.reply)

        const userData = JSON.parse(localStorage.getItem('userData') || '{}')
        data.createdBy = {
          id: userData.id,
          firstName: userData.given_name,
          lastName: userData.family_name,
          email: userData.email,
          imageUrl: userData.picture,
        }

        this.comment.children.items = [...this.comment.children.items, data]
        this.showReply = false

        this.reply = commentDefaults({
          parentId: this.comment.id,
          attrs: { ...(this.comment?.attrs || {}) },
          threadId: this.comment.threadId,
          openBubble: true,
        })
        this.$root.$emit('refreshComments')
      } catch (e) {
        console.log(e)
      }
    },
  },
}
</script>

<style lang="scss">
</style>
