//
import { truncate } from 'lodash'
import UserFilterComponent from './User.vue'

export default class UserFilter {
  static COMPONENT = UserFilterComponent;

  static OPERATORS = {
    include: 'include',
    exclude: 'exclude',
  }

  static type = 'user'

  operator = null;

  value = null;

  constructor({ operator = UserFilter.OPERATORS.include, value = [] } = {}) {
    this.operator = operator
    this.value = value
  }

  set({ operator = UserFilter.OPERATORS.include, value = [] }) {
    this.operator = operator
    this.value = value
  }

  getCount() {
    return this.value.length
  }

  getValueLabel({ cache }) {
    const user = this.value.map(userId => cache[userId] || { id: userId })
    return user.map(m => truncate([m.firstName, m.lastName].filter(Boolean).join(' '), { length: 20, separator: ' ' })).join(', ')
  }

  toLoad() {
    return {
      users: this.value,
    }
  }

  asQueryParam() {
    return {
      operator: this.operator,
      value: this.value,
    }
  }

  asGraphQL() {
    switch (this.operator) {
      case UserFilter.OPERATORS.include:
        return {
          in: this.value,
        }
      case UserFilter.OPERATORS.exclude:
        return {
          notIn: this.value,
        }
      default:
        return null
    }
  }

  static validate({ operator, value }) {
    if (!operator) {
      return 'Operator is required'
    }

    if (!value || value.length === 0) {
      return 'Value is required'
    }

    return true
  }

  static fromQueryParam({ operator, value }) {
    return new UserFilter({
      operator,
      value,
    })
  }
}
