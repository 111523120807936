<template>
  <ResizableBlock
    :attrs="attrs"
    :select-node="selectNode"
    :selected="selected"
    :update-attrs="updateAttrs"
    :entity-ref="$refs.youtubeEmbed"
  >
    <div
      v-show="attrs.src"
      ref="youtubeEmbed"
      class="youtube-embed-block"
    >
      <iframe
        :src="embedUrl"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      />
    </div>
    <div
      v-if="!selected"
      class="embed-block__block"
      @click="selectNode"
    />
  </ResizableBlock>
</template>

<script>
import ResizableBlock from '@/components/ResizableBlock.vue'
import { YouTubeGetID } from './YouTubeHelpers'

export default {
  components: {
    ResizableBlock,
  },
  props: ['attrs', 'updateAttrs', 'selectNode', 'selected'],
  inject: ['deleteBlock', 'showPromptPopup', 'toastNotification'],
  data() {
    return {
      initialSrc: null,
    }
  },
  computed: {
    embedUrl() {
      if (this.videoId) {
        return `https://www.youtube.com/embed/${this.videoId}?`
      }
      return ''
    },
    videoId: {
      get() {
        return this.attrs.videoId
      },
      set(e) {
        this.updateAttrs({ videoId: e })
      },
    },
  },
  watch: {
    src() {
      this.$nextTick(() => {
        this.selectNode()
      })
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.initialSrc = this.attrs.src
      this.videoId = YouTubeGetID(this.attrs.src)
    })
  },
  methods: {
    initialize() {
      if (!this.attrs.src) {
        this.openModal()
      }
    },
    async openModal() {
      const url = await this.showPromptPopup({
        title: this.$t('codex-editor.nodes.youtube-embed.youtube-video-url'),
      })
      if (!url) {
        this.deleteBlock(this.attrs.blockId)
      } else if (url.length && url.match((/(http:|https:)?\/\/(www\.)?(youtube.com|youtu.be)\/(watch)?(\?v=)?(\S+)?/))) {
        this.updateAttrs({
          src: url,
          videoId: YouTubeGetID(url),
        })
      } else {
        this.toastNotification({
          icon: 'XIcon',
          variant: 'danger',
          title: 'Error',
          text: this.$t('codex-editor.nodes.youtube-embed.youtube-video-invalid-url'),
        })
        this.deleteBlock(this.attrs.blockId)
      }
    },
    setSrc() {
      this.updateAttrs({
        src: this.initialSrc,
        videoId: YouTubeGetID(this.initialSrc),
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.embed-block__block {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0,0,0,0);
}

.youtube-embed-block {
  width: 100%;
  position: relative;
  padding-top: 56.25%;

  iframe {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
  }
}

.sourceField {
  width: 100%;
  white-space: nowrap;
}
</style>
