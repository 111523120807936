<template>
  <div
    class="media_section assets-listing__container"
    :class="{'assets-listing__container--popup': isPopup}"
  >
    <div
      v-if="isPopup"
      class="media_header-mobile mobile-only"
    >
      <GjIcon
        key="AlignLeftAlt"
        name="AlignLeftAlt"
        size="28px"
        @click.native="$emit('openTree')"
      />
      <GjIcon
        key="Close"
        name="Close"
        size="28px"
        @click.native="$emit('closePopup', true)"
      />
    </div>
    <div
      class="media__actions"
      @dragenter="!draggingAssets && (isBeingDragged = true)"
    >
      <div
        :key="mBaseUrl"
        class="media__actions-wrapper"
      >
        <div class="media__actions-buttons">
          <b-dropdown
            v-if="!disableUpload"
            split
            variant="primary"
            size="=sm"
            class="media__actions-button_dropdown"
            :class="{'hide-dropdown': !mBaseUrl}"
          >
            <template #button-content>
              <b-button
                id="at-media-upload-button"
                v-permission="['canUploadAsset', {folderId: filters.folderId}]"
                variant="primary"
                tag="label"
                size="sm"
                style="border: none"
                class="media__upload media__actions-button first-media-button"
              >
                <GjIcon
                  name="Plus"
                  size="24"
                />
                <span class="action-title">
                  {{ $t('general.upload') }}
                </span>
                <input
                  id="at-media-upload-input"
                  ref="uploadInput"
                  type="file"
                  multiple
                  @change="uploadFiles($event.target.files)"
                >
              </b-button>
            </template>
            <a
              v-if="mBaseUrl"
              :href=" mBaseUrl"
              target="_blank"
              class="dropdown-item media-upload-mbase"
            >
              <GjIcon
                name="Images"
                class="cursor-pointer m-50"
              />
              <span>{{ $t('assets.upload-from-mbase') }}</span>
            </a>
            <!-- <a
              v-if="vpUploader"
              class="dropdown-item media-upload-mbase"
              @click="uploadMedia()"
            >
              <GjIcon
                name="VideoPlayer"
                class="cursor-pointer m-50"
              />
              <span>{{ $t('assets.upload-to-vp') }}</span>
            </a> -->
          </b-dropdown>
          <b-button
            v-show="selectedData.items.length"
            class="assets-selected-button desktop-only"
            :class="{'assets-selected-button__white': !isPopup}"
            variant="light"
            @click="selectedData.show = !selectedData.show"
          >
            <GjIcon
              name="Files"
              size="14"
            />
            {{ $t('assets.selected', {count: selectedData.total}) }}
          </b-button>
        </div>
        <div class="media__actions-buttons media__actions-buttons--filters">
          <div
            class="media-search__container"
            :class="{'media-search__container--popup': isPopup}"
          >
            <b-form-input
              id="search-media-items"
              v-model.trim="filters.searchTerm"
              class="media-search__input"
              autocomplete="off"
              :debounce="250"
              size="sm"
              :placeholder="$t('assets.search-placeholder')"
              clearable
              @keydown.esc="clearSearch"
            />

            <div v-if="$apollo.loading">
              <b-spinner
                class="assets-loader"
                variant="primary"
              />
            </div>
            <div class="media__actions-filters">
              <FiltersVue :filters="queryFilters" />
            </div>
            <b-dropdown
              class="assets-sort__by-date b-dropdown--compact"
              size="sm"
              variant="none"
              no-caret
              right
            >
              <template #button-content>
                <GjIcon
                  :key="useOrder.order"
                  size="24"
                  :style="useOrder.orderBy? 'color: #206ED5;' : ''"
                  :name="useOrder.order === 'DESC' ? 'OrderDesc_fill' : 'OrderAsc_fill'"
                />
              </template>
              <b-dropdown-form>

                <vSelect
                  v-model="useOrder.order"
                  class="v-select--gray"
                  :options="[
                    { value: 'ASC', label: $t('assets.sorts.asc') },
                    { value: 'DESC', label: $t('assets.sorts.desc') },
                  ]"
                  :disabled="selectedData.show && !useOrder.orderBy"
                  :clearable="false"
                  :reduce="option => option.value"
                  label="label"
                />

              </b-dropdown-form>
              <b-dropdown-item
                v-if="selectedData.show"
                :active="useOrder.orderBy === null"
                @click="useOrder.orderBy = null"
              >
                {{ $t('assets.sorts.none') }}
              </b-dropdown-item>
              <b-dropdown-item
                :active="useOrder.orderBy === 'createdAt'"
                @click="useOrder.orderBy = 'createdAt'"
              >
                {{ $t('assets.sorts.createdDate') }}
              </b-dropdown-item>
              <b-dropdown-item
                :active="useOrder.orderBy === 'title'"
                @click="useOrder.orderBy = 'title'"
              >
                {{ $t('assets.sorts.aToZ') }}
              </b-dropdown-item>
              <b-dropdown-item
                :active="useOrder.orderBy === 'expiresAt'"
                @click="useOrder.orderBy = 'expiresAt'"
              >
                {{ $t('assets.sorts.expirationDate') }}
              </b-dropdown-item>
              <b-dropdown-item
                :active="useOrder.orderBy === 'updatedAt'"
                @click="useOrder.orderBy = 'updatedAt'"
              >
                {{ $t('assets.sorts.updatedDate') }}
              </b-dropdown-item>
              <b-dropdown-item
                :active="useOrder.orderBy === 'size'"
                @click="useOrder.orderBy = 'size'"
              >
                {{ $t('assets.sorts.assetSize') }}
              </b-dropdown-item>
              <div
                v-show="useOrder.order"
                class="new-filters-dropdown__clear-all"
                @click="clearOrder"
              >
                <GjIcon
                  name="Close"
                  size="18"
                  class="new-filters-dropdown__clear-all-icon"
                />
                {{ $t('assets.clear-sort') }}
              </div>
            </b-dropdown>
          </div>
        </div>
      </div>
      <div
        v-if="!draggingAssets && !disableUpload"
        class="drop-files-overlay"
        :class="{'asset-dragging-overlay': isBeingDragged}"
        @dragover.stop.prevent
        @dragleave.prevent.stop="isBeingDragged = false"
        @drop.prevent.stop="assetOverlayDrop($event)"
      >
        <div
          v-if="isBeingDragged"
          class="text"
        >
          <h4>
            {{ $t('assets.drop-files-here') }}
          </h4>
        </div>
      </div>
    </div>
    <app-collapse class="narrow-collapse assets-collapse mb-2">
      <app-collapse-item
        class="assets-collapse__item"
        :is-visible="false"
        title=""
      >
        <template slot="header">
          <span class="assets-collapse__title">{{ $t('assets.folders-label') }}</span>
          <span>{{ $t('assets.folders-total', {totalFolders: $options.filters.numberWithThousandsCommas(totalFolders)}) }}</span>
        </template>
        <FoldersGrid
          :ref="`foldersGrid-listing`"
          :parent-id="filters.folderId"
          :is-popup="isPopup"
          :total-assets="assets.total"
          @total-folders="totalFolders = $event"
        />
        <div
          v-if="totalFolders == 0"
          id="at-empty-folder-template"
          class="empty-message"
        >
          <GjIcon
            name="Folders"
            size="34"
          />
          <h6>
            {{ $t('assets.no-folders') }}
          </h6>

          <p v-permission="['canCreateFolder', { id: filters.folderId }]">
            <i18n
              path="assets.new-folder-text.text"
              :tag="false"
            >
              <label
                class="text-primary text-decoration-underline cursor-pointer"
                @click="createFolder"
              >
                {{ $t('assets.new-folder-text.0') }}
              </label>
            </i18n>
          </p>
        </div>
        <!-- {{ folders.items }} -->
      </app-collapse-item>
      <app-collapse-item
        class="assets-collapse__item"
        :is-visible="true"
        title=""
      >
        <template slot="header">
          <span class="assets-collapse__title">{{ $t('assets.assets-label') }}</span>
          <span>{{ $t('assets.assets-total', {totalAssets: $options.filters.numberWithThousandsCommas(assets.total)}) }}</span>
        </template>
        <div
          class="media-main-section"
          :class="{ 'assets-disable__scroll': assetsBeingEdited }"
        >
          <div class="assets-listing">
            <div v-show="selectedAssets.length">
              <vue-context ref="menu">
                <li>
                  <span class="assets-context">{{ $t('assets.selected', { count: selectedAssets.length }) }}</span>
                </li>
                <li :class="{'perm-disabled': !canEditAsset}">
                  <b-link
                    id="at-context-menu-edit-asset-button"
                    class="d-flex align-items-center"
                    @click="assetsBeingEdited = true"
                  >
                    <GjIcon
                      name="Edit"
                      size="16"
                    />
                    <span class="ml-75">
                      {{ $t('assets.edit-images') }}
                    </span>
                  </b-link>
                </li>
                <li :class="{'perm-disabled': !canDeleteAsset}">
                  <b-link
                    id="at-context-menu-delete-asset-button"
                    class="d-flex align-items-center"
                    @click="handleAssetsDeletion"
                  >
                    <GjIcon
                      name="Delete"
                      size="16"
                    />
                    <span class="ml-75">
                      {{ $t('assets.delete-images') }}
                    </span>
                  </b-link>
                </li>
                <li>
                  <b-link
                    id="at-context-menu-deselect-assets-button"
                    class="d-flex align-items-center"
                    @click="selectedAssets=[]"
                  >
                    <GjIcon
                      name="Close_fill"
                      size="16"
                    />
                    <span class="ml-75">
                      {{ $t('assets.deselect') }}
                    </span>
                  </b-link>
                </li>
              </vue-context>
            </div>
            <Edit
              v-if="assetId"
              :asset-id="assetId"
              :assets="assets.items"
              :total="assets.total"
              @fetchMoreAssets="loadMore"
              @finishedEditing="SET_ASSET_ID(null)"
              @assetUpdated="assetUpdated"
            />
            <BulkEdit
              v-if="assetsBeingEdited"
              @updateList="updateList"
            />
            <div class="assets-listing-container">
              <div
                v-if="(!assets || !assets.items || !assets.items.length) && !$apollo.loading"
                id="at-media-template"
                class="empty-message"
              >
                <GjIcon
                  name="Media"
                  size="34"
                />
                <h6>
                  {{ $t('assets.no-files') }}
                </h6>

                <p v-permission="['canUploadAsset', {folderId: filters.folderId}]">
                  <i18n
                    path="assets.upload-text.text"
                    :tag="false"
                  >
                    <label class="text-primary text-decoration-underline cursor-pointer">
                      {{ $t('assets.upload-text.0') }}
                      <input
                        id="at-folder-id-upload-input"
                        ref="uploadFileInListing"
                        type="file"
                        multiple
                        @change="uploadFiles($event.target.files)"
                      >
                    </label>
                  </i18n>
                </p>
              </div>
              <LoadingSpinner
                v-if="$apollo.loading && firstLoad"
                style="padding-top: 40px"
              />
              <div
                v-if="assets && assets.items && assets.items.length"
                style="height: 100%;"
              >
                <template v-if="layout === 'grid' || layout === 'thumbnail'">
                  <AssetsGrid
                    ref="assetsGrid"
                    :is-popup="isPopup"
                    :total="assets.total"
                    :items="assets.items"
                    :folder-id="filters.folderId"
                    :total-size="assets.totalSize"
                    :filtered="isFiltered"
                    :column-index="columnIndex"
                    :min-range="2"
                    :max-range="10"
                    :options="options"
                    @loadMore="loadMore"
                    @contextmenu.native.prevent="$refs.menu.open"
                    @setCustomSort="setCustomSort"
                  >
                    <template v-slot:default="item">
                      <AssetGridDisplay
                        :key="item.id"
                        :show-details="layout === 'thumbnail'"
                        :asset="item"
                        style="cursor:pointer;"
                        class="item"
                        :is-popup="isPopup"
                        :assets="assets.items"
                        :folder-id="filters.folderId"
                        @deleteAsset="handleAssetDeletion"
                        @dragstart.native="handleAssetDragstart($event, item, isAssetSelected(item))"
                        @dragend.native="removeDragAsset"
                      />
                    </template>
                  </AssetsGrid>
                </template>
                <template v-if="layout === 'list'">
                  <div
                    style="height: 100%;"
                    @contextmenu.prevent="$refs.menu.open"
                  >
                    <AssetsList
                      ref="assetsList"
                      :total="assets.total"
                      :items="assets.items"
                      :folder-id="filters.folderId"
                      :table-columns="tableColumns"
                      :is-popup="isPopup"
                      :filtered="isFiltered"
                      @loadMore="loadMore"
                      @setCustomSort="setCustomSort"
                    >
                      <template v-slot:default="{props}">
                        <AssetListDisplay
                          :props="props"
                          draggable="true"
                          @deleteAsset="handleAssetDeletion"
                          @dragstart.native="handleAssetDragstart($event, props.row, isAssetSelected(props.row))"
                          @dragend.native="removeDragAsset"
                        />
                      </template>
                    </AssetsList>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
      </app-collapse-item>
    </app-collapse>
    <div class="assets-listing-footer">
      <div class="footer__left-side">
        <Breadcrumbs
          :items="assetBreadcrumb"
          @folder="folder => goToFolder(folder)"
        />
      </div>

      <div class="footer__right-side ">
        <div class="assets__details">
          <GjIcon
            name="Files"
            size="20"
            class="assets__details-icon"
          />
          <span
            class="desktop-only"
            :title="$options.filters.numberWithThousandsCommas(assets.total)"
          >
            {{ $t('assets.total-files', {total: totalNumberOfAssets || 0}) }}
          </span>
          <span class="mobile-only">
            {{ totalNumberOfAssets }}
          </span>
        </div>
        <div class="assets__details">
          <GjIcon
            name="DatabaseTwo"
            size="20"
            class="assets__details-icon assets__details-total-size-icon"
          />
          <span class="assets__total-gb desktop-only">
            {{ $t('assets.total-files-size', {totalSize: `${bytesToSize(assets.totalSize, 2)}`}) }}
          </span>
          <span class="assets__total-gb mobile-only">
            {{ bytesToSize(assets.totalSize, 2) }}
          </span>
        </div>
        <div
          v-if="layout === 'grid' || layout === 'thumbnail'"
          class="assets-listing-range desktop-only"
        >
          <b-form-input
            v-model.number="columnIndex"
            class="display-button"
            type="range"
            :min="0"
            :max="options.length-1"
          />
        </div>
        <div class="at-media-list-button desktop-only">
          <b-button
            id="at-media-list-button"
            class="media__actions-layout-button"
            :variant="layout === 'list' ? 'link' : 'none'"
            size="sm"
            @click="layout = 'list'"
          >
            <GjIcon
              name="ListAlt"
              size="34"
            />
          </b-button>
        </div>
        <div class="at-media-list-button desktop-only">
          <b-button
            id="at-media-grid-button"
            class="media__actions-layout-button"
            :variant="layout === 'grid' ? 'link' : 'none'"
            size="sm"
            @click="layout = 'grid'"
          >
            <GjIcon
              name="Grid"
              size="34"
            />
          </b-button>
        </div>
        <div class="at-media-list-button desktop-only">
          <b-button
            id="at-media-details-button"
            class="media__actions-layout-button"
            :variant="layout === 'thumbnail' ? 'link' : 'none'"
            size="sm"
            @click="layout = 'thumbnail'"
          >
            <GjIcon
              name="AlignRight"
              size="34"
            />
          </b-button>
        </div>
        <div
          v-if="isPopup"
          class="mobile-only ml-1"
        >
          <b-button
            variant="primary"
            size="sm"
            @click="$emit('insertAsset')"
          >
            Insert
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import gql from 'graphql-tag'
import {
  mapGetters, mapMutations, mapState, mapActions,
} from 'vuex'
import { bytesToSize, GenerateUUID } from '@/utils/helpers'
import AssetsGrid from '@/components/assets/listing-layouts/AssetsGrid.vue'
import AssetsList from '@/components/assets/listing-layouts/AssetsList.vue'
import {
  ASSET_TYPES, deleteAsset, deleteAssets, uploadAsset,
} from '@/codex-sdk/assets'
import Edit from '@/components/assets/Edit.vue'
import VueContext from 'vue-context'
import BulkEdit from '@/components/assets/BulkEdit.vue'
import AssetGridDisplay from '@/components/assets/assets-display/AssetGridDisplay.vue'
import { ENTITY_TYPES, MAX_FILE_SIZE } from '@/utils/constants'
import AssetListDisplay from '@/components/assets/assets-display/AssetListDisplay.vue'
import {
  debounce, isNumber, merge, set, uniqBy,
} from 'lodash'
import qs from 'qs'
import moment from 'moment'
import assetsMixin from '@/components/assets/assetsMixin'
import FoldersGrid from '@/views/assets/components/folders/FoldersGrid.vue'
import Filters from '@/components/filters-dropdown/Filters'
import FiltersVue from '@/components/filters-dropdown/Filters.vue'
import { canDeleteAsset, canEditAsset } from '@/codex-permissions/assets'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import { INTEGRATION_ALIASES } from '@/codex-sdk/all-integrations'
import Breadcrumbs from '@/components/assets/listings/Breadcrumbs.vue'
import LoadingSpinner from '@/components/LoadingSpinner.vue'
import { LABEL_ENTITIES } from '@/codex-sdk/labels'

export default {
  permissions: {
    canEditAsset: {
      async handler(vars) {
        let hasPermissions = await Promise.all(vars.selectedAssets.map(async asset => {
          const hasPermission = await canEditAsset({ entity: { id: asset.id, status: asset?.status } })
          return hasPermission.can
        }))

        hasPermissions = !hasPermissions.includes(false)

        return hasPermissions
      },
      variables() {
        return {
          selectedAssets: this.selectedAssets || [],
        }
      },
    },
    canDeleteAsset: {
      async handler(vars) {
        let hasPermissions = await Promise.all(vars.selectedAssets.map(async asset => {
          const hasPermission = await canDeleteAsset({ entity: { id: asset.id, status: asset?.status } })
          return hasPermission.can
        }))

        hasPermissions = !hasPermissions.includes(false)

        return hasPermissions
      },
      variables() {
        return {
          selectedAssets: this.selectedAssets || [],
        }
      },
    },
  },
  name: 'AssetsListing',
  components: {
    LoadingSpinner,
    Breadcrumbs,
    AssetListDisplay,
    AssetGridDisplay,
    AssetsGrid,
    Edit,
    BulkEdit,
    VueContext,
    AssetsList,
    FiltersVue,
    FoldersGrid,
    AppCollapse,
    AppCollapseItem,
  },
  inject: ['showConfirmDeletePopup', 'showConfirmPopup', 'toastNotification', 'showFolderPopup', 'showVPUploadPopup', 'showVPProgressPopup'],
  mixins: [assetsMixin],
  props: {
    minCols: {
      type: Number,
      default: 3,
    },
    maxCols: {
      type: Number,
      default: 9,
    },
    defaultCols: {
      type: Number,
      default: 7,
    },
    isPopup: {
      type: Boolean,
      default: false,
    },
    initialFilters: {
      type: Object,
      default: () => ({}),
    },
    disableUpload: {
      type: Boolean,
      default: false,
    },
    labels: {
      type: Array,
      default: () => [],
    },
  },
  apollo: {
    assetCollection: {
      query() {
        return this.ASSET_COLLECTION_QUERY // Access the GraphQL query via `this`
      },
      debounce: 200,
      fetchPolicy: 'network-only',
      variables() { return this.assetCollectionVariables() },
      update(results) {
        if (this.selectedData.show) {
          Object.assign(this.filteredSelectedAssets, results.assetCollection)
        } else {
          let uploaded = []
          if (this.filters.folderId) {
            uploaded = this.uploadingAssets.filter(a => a.folderId === this.filters.folderId && a.uploadStatus === 'success' && !results.assetCollection.items.some(el => el.id === a.id))
          } else {
            uploaded = this.uploadingAssets.filter(a => a.uploadStatus === 'success' && !results.assetCollection.items.some(el => el.id === a.id))
          }

          this.successfulMediaUploads.forEach(media => {
            const item = results.assetCollection.items.find(el => el.fileName === media.mediaId)
            if (item && !media.addedToSelected) {
              media.addedToSelected = true
              this.selectedAssets = [...this.selectedAssets, item]
            }
          })

          // let items = [...uploaded]
          // if (this.allAssets?.items && this.allAssets?.items.length) {
          //   items = [...items, ...this.allAssets.items]
          // } else {
          //   items = [...items, ...results.assetCollection.items]
          // }
          Object.assign(this.allAssets, {
            ...results.assetCollection,
            items: [
              ...uploaded,
              ...results.assetCollection.items,
            ],
            total: results.assetCollection.total + uploaded.length,
          })
        }
        this.firstLoad = false
        return results
      },
    },
  },
  data() {
    const range = this.generateRange()

    const queryFilters = new Filters(this.assetFilters)

    const orderFromLocalStorage = this.isPopup ? null : localStorage.getItem('asset-order')
    const filtersFromLocalStorage = localStorage.getItem('asset-filters')
    if (filtersFromLocalStorage && !this.isPopup) {
      queryFilters.loadFromQuery(filtersFromLocalStorage)
    }
    if (this.initialFilters) {
      queryFilters.setActiveFilters(this.initialFilters)
    }
    const localStorageAssetsLayout = localStorage.getItem('assets-layout') || '{}'
    const assetsLayout = JSON.parse(localStorageAssetsLayout)

    return {
      ASSET_COLLECTION_QUERY: gql`
        query ($limit: Int, $offset: Int, $where: CodexAssetFilter, $order: CodexAssetOrder) {
         assetCollection (limit: $limit, offset: $offset, where: $where, order: $order) {
            items {
              id
              folderId
              folder {
                path {
                    id
                    name
                }
              }
              type
              title
              contentType
              createdAt
              expiresAt
              updatedAt
              status
              size
              url(transformation: {width: 200,height: 200,quality: 60, resize: FILL, format: THUMBNAIL})
              fileName
              caption
              alt
              source
              duration
              author
              copyright
              tags
              attrs
              width
              height
              focalPoint {
                x
                y
              }
              labels {
                id
                name
                color
              }
            }
            limit
            offset
            total
            totalSize
          }
        }
      `,
      queryFilters,

      isBeingDragged: false,

      firstLoad: true,

      assetsBeingEdited: false,

      ASSET_TYPES,
      columnIndex: isNumber(assetsLayout.columnIndex) ? assetsLayout.columnIndex : range.indexOf(this.defaultCols),
      options: range,
      bytesToSize,
      layout: assetsLayout.layout || 'grid',
      removeAssetsTimeout: null,
      itemsToRemove: [],
      uploadingSession: [],
      allAssets: {
        items: [],
        total: 0,
        totalSize: 0,
      },
      filteredSelectedAssets: {
        items: [],
        total: 0,
        totalSize: 0,
      },
      order: orderFromLocalStorage ? JSON.parse(orderFromLocalStorage) : {
        orderBy: null,
        order: 'DESC',
      },
      selectedOrder: {
        orderBy: null,
        order: 'DESC',
      },
      refetch: debounce(function () {
        this.$apollo.queries.assetCollection.refetch()
      }, 1000),
      totalFolders: 0,
    }
  },
  computed: {
    assetBreadcrumb() {
      let breadcrumb = []

      if (this.selectedData.show) return []
      if (!this.isPopup) {
        breadcrumb = this.selectedAssets.length === 1 ? this.selectedAssets[0]?.folder?.path : []
      } else {
        if (this.targetFolder?.id) {
          const fromSameFolder = this.selectedAssets.filter(a => a?.folderId === this.targetFolder?.id)
          breadcrumb = fromSameFolder.length === 1 ? fromSameFolder[0]?.folder?.path : []
          return breadcrumb
        }
        breadcrumb = this.selectedAssets.length === 1 ? this.selectedAssets[0]?.folder?.path : []
      }

      return breadcrumb
    },
    ...mapState('assets', [
      'filters',
      'assetId',
      'selectedData',
      'targetFolder',
    ]),
    ...mapState('general', [
      'allIntegrationConnections',
      'currentOrganization',
    ]),
    ...mapGetters('general', [
      'getConnections',
    ]),
    ...mapGetters('vpUploads', {
      uploadingAssets: 'getUploads',
      isUploading: 'getVPUploadIsUploading',
      successfulMediaUploads: 'getSuccessMediaUploads',
    }),
    vpUploader() {
      const vpUploaders = this.getConnections(INTEGRATION_ALIASES.VP_UPLOADER)
      return vpUploaders?.[0]
    },
    mBaseIntegration() {
      return this.allIntegrationConnections?.find(c => c.integration === INTEGRATION_ALIASES.MBASE)
    },
    mBaseUrl() {
      if (!this.mBaseIntegration) return false
      const queryParams = {
        apiDomain: process.env.VUE_APP_API_DOMAIN,
        organization: this.currentOrganization.id,
        token: this.mBaseIntegration.attrs?.apiKey,
      }
      if (this.targetFolder) {
        queryParams.folderId = this.targetFolder.id
      }

      return `${this.mBaseIntegration?.attrs?.url}?${qs.stringify(queryParams, { indices: false })}`
    },
    tableColumns() {
      return [
        {
          label: this.$t('assets.columns.file'),
          field: 'file',
          width: '40%',
          sortable: false,
        },
        {
          label: this.$t('assets.columns.type'),
          field: 'type',
          sortable: false,
        },
        {
          label: this.$t('assets.columns.date'),
          field: 'date',
          sortable: false,
        },
        {
          label: this.$t('assets.columns.resolution'),
          field: 'resolution',
          sortable: false,
        },
        {
          label: this.$t('assets.columns.duration'),
          field: 'duration',
          sortable: false,
        },
        {
          label: this.$t('assets.columns.size'),
          field: 'file-size',
          sortable: false,
        },
        {
          label: '',
          field: 'more-options',
          width: '5%',
          sortable: false,
        },
      ]
    },
    useOrder() {
      return this.selectedData.show ? this.selectedOrder : this.order
    },
    assets() {
      if (this.selectedData.show && this.layout) {
        let totalSize = 0
        if (this.isFiltered) {
          this.filteredSelectedAssets.items.forEach(a => {
            totalSize += a.size
          })
          return {
            items: this.filteredSelectedAssets.items,
            total: this.filteredSelectedAssets.total,
            totalSize,
          }
        }
        this.selectedAssets.forEach(a => {
          totalSize += a.size
        })
        return {
          items: this.selectedAssets,
          total: this.selectedData.total,
          totalSize,
        }
      }
      return this.allAssets
    },
    selectedAssets: {
      get() {
        return this.selectedData.items
      },
      set(v) {
        v = uniqBy(v, 'id')

        let totalSize = 0
        v.forEach(a => {
          totalSize += a.size
        })
        this.SET_SELECTED_DATA({
          ...this.selectedData,
          totalSize: totalSize || 0,
          items: v,
          total: v.length,
        })
      },
    },
    totalNumberOfAssets() {
      return this.$options.filters.formatNumber(this.assets.total)
    },
    assetFilters() {
      /**
       *
       *     alt: { contains: "Test" },
       *     author: { contains: "Test" },
       *     caption: { contains: "test" },
       *     contentType: { contains: "Test" },
       *     fileName: { contains: "Test" },
       *     height: { gt: 4 },
       *     path: { contains: "Test" },
       *     query: "Test",
       *     size: { gt: 3 },
       *     source: { contains: "Test" },
       *     tags: { all: ["Test"] },
       *     title: { contains: "Test" },
       *     type: { in: [FILE, IMAGE, VIDEO, VIDEO_PLAYLIST] },
       *     width: { gt: 3 },
       *
       */
      return [
        ...(!this.filters.types || this.filters.types.length !== 1 ? [{
          type: 'assetType', name: 'type', label: this.$t('assets.filters.type'), types: this.filters.types,
        }] : []),
        { type: 'datetime', name: 'createdAt', label: this.$t('assets.filters.created-date') },
        { type: 'datetime', name: 'updatedAt', label: this.$t('assets.filters.updated-date') },
        { type: 'string', name: 'title', label: this.$t('assets.filters.title') },
        { type: 'string', name: 'caption', label: this.$t('assets.filters.caption') },
        { type: 'string', name: 'alt', label: this.$t('assets.filters.alt') },
        { type: 'string', name: 'source', label: this.$t('assets.filters.source') },
        { type: 'string', name: 'author', label: this.$t('assets.filters.author') },
        { type: 'string-list', name: 'tags', label: this.$t('assets.filters.tags') },
        { type: 'string', name: 'contentType', label: this.$t('assets.filters.content-type') },
        { type: 'string', name: 'fileName', label: this.$t('assets.filters.fileName') },
        { type: 'number', name: 'size', label: this.$t('assets.filters.size') },
        {
          type: 'labels',
          name: 'labels',
          label: this.$t('assets.filters.labels'),
          entities: [LABEL_ENTITIES.ALL, LABEL_ENTITIES.ASSET],
          labels: this.labels,
        },
        { type: 'number', name: 'width', label: this.$t('assets.filters.width') },
        { type: 'number', name: 'height', label: this.$t('assets.filters.height') },
        { type: 'minutes', name: 'duration', label: this.$t('assets.filters.duration') },
        { type: 'customParameters', name: 'customParameters', label: this.$t('assets.filters.custom-parameters') },
        { type: 'datetime', name: 'expiresAt', label: this.$t('assets.filters.expiration-date') },
        { type: 'user', name: 'createdBy', label: this.$t('assets.filters.created-by') },
        { type: 'user', name: 'updatedBy', label: this.$t('assets.filters.updated-by') },
      ]
    },
    isFiltered() {
      return this.queryFilters.getActiveFiltersCount() > 0 || this.filters.searchTerm?.length > 0
    },
  },
  watch: {
    columnIndex() {
      this.saveAssetsLayout()
    },
    successfulMediaUploads: {
      handler() {
        this.refetchList()
      },
    },
    isUploading: {
      handler(v) {
        if (!v) this.uploadingSession = []
      },
    },
    layout() {
      this.saveAssetsLayout()
    },
    'queryFilters.asQueryParams': {
      handler: debounce(function () {
        if (!this.isPopup) {
          localStorage.setItem('asset-filters', this.queryFilters.asQueryParams)
        }
      }, 100), // 100ms delay
      deep: true,
    },
    order: {
      handler() {
        if (!this.isPopup) {
          localStorage.setItem('asset-order', JSON.stringify(this.order))
        }
      },
      deep: true,
    },
    assetFilters: {
      handler(v) {
        this.queryFilters.setFilters(v)
      },
      deep: true,
      immediate: true,
    },
    assetsBeingEdited(v) {
      document.body.style.overflow = v ? 'hidden' : 'auto'
    },
    assetBeingEdited(v) {
      document.body.style.overflow = v ? 'hidden' : 'auto'
    },
    '$route.query.assetId': function () {
      this.checkRoute()
    },
    selectedData: {
      handler(v) {
        if (v?.total == 0 && v.show) {
          this.selectedData.show = false
        }
      },
      deep: true,
    },
    selectedAssets: {
      handler(n, o) {
        if (n?.length != o?.length) {
          this.sortSelected()
        }
      },
      deep: true,
    },
    uploadingAssets: {
      async handler(v) {
        const newArray = v.filter(a => a.status === 'new')
        if (newArray?.length) {
          newArray.map(async e => {
            await this.initiateUploadAsset(e)
              .then(a => {
                let j = 0
                for (j; j < this.uploadingSession.length; j++) {
                  if (moment(this.uploadingSession[j].createdAt).isBefore(moment(a.createdAt))) { break }
                }
                this.allAssets.items.splice(j, 0, a)
                this.uploadingSession.splice(j, 0, a)
              })
              .catch(() => {
                this.toastNotification({
                  icon: 'XIcon',
                  variant: 'danger',
                  title: this.$t('assets.upload.failTitle'),
                  text: this.$t('assets.upload.failDescription'),
                })
              })
          })
          this.showVPProgress()
        }
      },
      deep: true,
    },
    'filters.folderId': function () {
      if (this.$refs.assetsGrid) {
        this.$refs.assetsGrid.scrollToTop()
      }
      if (this.$refs.assetsList) {
        this.$refs.assetsList.scrollToTop()
      }
    },
    selectedOrder: {
      async handler() {
        if (this.selectedData.show) {
          this.sortSelected()
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.checkRoute()
    this.$root.$on('assetsDelete', this.removeAssets)
    this.$root.$on('refetch', this.refetchList)
    this.$root.$on('editedImage', this.editedImage)
    this.SET_API_KEY(this.vpUploader.attrs.apiKey)

    document.addEventListener('visibilitychange', this.onTabChange, false)
    document.addEventListener('dragover', this._preventDefault, false)
    document.addEventListener('dragenter', this.handleDragEnter, false)
    document.addEventListener('drag', this.scrollWhenHover, false)
    document.addEventListener('drop', this.handleFolderDrop, false)
  },
  beforeDestroy() {
    this.clearSearch()
  },
  destroyed() {
    this.$root.$off('assetsDelete', this.removeAssets)
    this.$root.$off('refetch', this.refetchList)
    this.$root.$off('editedImage', this.editedImage)

    document.removeEventListener('visibilitychange', this.onTabChange, false)
    document.removeEventListener('dragover', this._preventDefault, false)
    document.removeEventListener('dragenter', this.handleDragEnter, false)
    document.removeEventListener('drag', this.scrollWhenHover, false)
    document.removeEventListener('drop', this.handleFolderDrop, false)
  },
  methods: {
    ...mapActions('vpUploads', [
      'addUploadFile',
      'addUploadingAsset',
    ]),
    ...mapMutations('vpUploads', [
      'SET_API_KEY',
      'UPDATE_IS_UPLOADING',
    ]),
    ...mapMutations('assets', [
      'SET_ASSET_ID',
      'SET_SELECTED_DATA',
      'SET_DEFAULT_SELECTED_DATA',
      'SET_TARGET_FOLDER',
    ]),
    assetCollectionVariables() {
      const filters = this.queryFilters.asGraphQL

      const folderId = { eq: null }
      if (this.filters.folderId) {
        folderId.eq = this.filters.folderId
        folderId.exists = true
        folderId.includeChildren = this.filters.searchTerm?.length > 0
      }

      if (this.isPopup) {
        if (!filters.labels && this.labels?.length) {
          set(filters, 'labels.some', this.labels)
        }
      }

      let limit = 70
      const id = { in: [] }
      if (this.selectedData.show) {
        id.in = this.selectedData.items.map(s => s.id)
        limit = this.selectedAssets.length
      }
      const order = {}
      if (this.order.orderBy) {
        order[this.order.orderBy] = this.order.order
      }
      return {
        offset: 0,
        limit,
        order,
        where: merge({
          id,
          folderId,
          type: !filters.type ? { in: this.filters.types } : null,
          labels: !filters.labels ? { some: this.filters.labels } : null,
          query: this.filters.searchTerm,
        }, filters),
      }
    },
    goToFolder(item) {
      if (!this.isPopup && this.folderId !== item.id) {
        this.$router.replace({ name: this.$route.name, query: { ...this.$route.query, folder: item.id } })
      }
      this.SET_TARGET_FOLDER(item)
      this.filters.folderId = item.id
    },
    clearOrder() {
      this.useOrder.orderBy = null
    },
    saveAssetsLayout() {
      localStorage.setItem('assets-layout', JSON.stringify({ layout: this.layout, columnIndex: this.columnIndex }))
    },
    async createFolder() {
      const folder = await this.showFolderPopup({ parent: { id: this.filters.folderId } })
      if (folder) {
        this.$root.$emit('refetch-folders')
      }
    },
    uploadFailed(v) {
      if (v) {
        this.showConfirmPopup({
          title: this.$t('assets.uploaded-failed.title'),
          description: this.$t('assets.uploaded-failed.description'),
          okTitle: this.$t('assets.uploaded-failed.okTitle'),
          okOnly: true,
        })
      }
    },
    async uploadMedia(mediaArray = []) {
      const { projectId, medias } = await this.showVPUploadPopup({
        title: 'medias',
        organizationId: this.vpUploader.attrs.organizationId,
        medias: mediaArray,
      })

      if (projectId && medias.length) {
        medias.forEach(v => {
          this.addUploadFile({
            file: {
              file: v,
              fileType: v.type.split('/')?.[0],
              uploadStatus: 'uploading',
              id: GenerateUUID.ASSET(),
              _id: GenerateUUID.ASSET(),
              folderId: this.filters.folderId,
            },
            projectId,
          })
        })
        this.showVPProgress()
      }
    },
    showVPProgress() {
      if (!this.isUploading) {
        this.UPDATE_IS_UPLOADING(true)
        this.showVPProgressPopup()
      }
    },
    setCustomSort() {
      this.selectedOrder.order = null
    },
    editedImage(image) {
      this.assets.items.unshift(image)
    },
    async sortSelected() {
      if (this.selectedOrder.order) {
        const id = { in: [] }
        id.in = this.selectedData.items.map(s => s.id)
        const limit = this.selectedAssets.length

        if (limit < 1 || !this.selectedOrder.orderBy) return

        const { data } = await this.$apollo.query({
          query: gql`
            query ($limit: Int, $offset: Int, $where: CodexAssetFilter, $order: CodexAssetOrder) {
             assets: assetCollection (limit: $limit, offset: $offset, where: $where, order: $order) {
                items {
                  id
                  folderId
                  type
                  title
                  contentType
                  createdAt
                  expiresAt
                  updatedAt
                  size
                  url(transformation: {width: 200,height: 200,quality: 60, resize: FILL, format: THUMBNAIL})
                  fileName
                  caption
                  alt
                  source
                  author
                  copyright
                  tags
                  attrs
                  width
                  height
                  labels {
                    id
                  }
                }
                limit
                offset
                total
                totalSize
              }
            }
          `,
          fetchPolicy: 'no-cache',
          variables: {
            offset: 0,
            limit,
            order: {
              [this.selectedOrder.orderBy]: this.selectedOrder.order,
            },
            where: { id },
          },
        })
        this.selectedAssets = data.assets.items
      }
    },
    onTabChange() {
      if (!document.hidden) {
        this.refetchList()
      }
    },
    assetOverlayDrop(event) {
      this.isBeingDragged = false
      if (this.draggingAssets) return
      const { files } = event.dataTransfer
      this.uploadFiles(files)
    },
    refetchList() {
      this.refetch()
    },
    async initiateUploadAsset(asset) {
      if (asset.status === 'new') {
        asset.status = 'uploading'
        try {
          const { data } = await uploadAsset(asset, { successTitle: false })
          Object.assign(asset, data, {
            status: 'success',
            fileName: asset.file.name,
            contentType: asset.file.type,
          })
          const org = this.$store.state.general.currentOrganization
          if (org?.storage?.id === asset.storageId) {
            asset.url = `https://${org.storage.cdnDomain}/${asset.path}?width=200&height=200&quality=60&r=fill`
          }
          if (!this.selectedData.items.find(img => img.id === asset.id)) {
            if (this.isPopup || !this.filters.folderId || this.filters.folderId === asset.folderId) {
              this.selectedData.items.push(asset)
              this.selectedData.total += 1
            }
          }
          if (!this.filters.folderId || this.filters.folderId === asset.folderId) {
            this.allAssets.total += 1
            this.allAssets.totalSize += asset.size
          }

          return asset
        } catch (e) {
          console.log(e)
          asset.status = 'failed'
          asset.failedStatus = 'not-supported'
          return asset
        }
      }
      return null
    },
    checkFileSize(files) {
      const array = []
      files.forEach((file, index) => {
        if (file.size > MAX_FILE_SIZE) {
          array.push(index)
        }
      })
      return array
    },
    async uploadFiles(f) {
      if (this.disableUpload) return
      const files = Array.from(f)
      const filesArray = []
      const assetFiles = files.filter(file => !(file.type.includes('video') || file.type.includes('audio')))
      const oversizeFiles = this.checkFileSize(assetFiles)

      if (oversizeFiles.length) {
        const confirm = await this.showConfirmPopup({
          title: this.$t('assets.filesize-alert.title'),
          description: this.$t('assets.filesize-alert.description', {
            count: oversizeFiles.length,
            total: assetFiles.length,
            maxSize: `${MAX_FILE_SIZE / 1024 ** 2}MB`,
          }),
          okTitle: this.$t('assets.filesize-alert.okTitle'),
          cancelTitle: this.$t('assets.filesize-alert.cancelTitle'),
        })
        if (!confirm) {
          this.clearFields()
          return
        }
      }

      assetFiles.forEach((file, index) => {
        if (!oversizeFiles.includes(index)) {
          filesArray.push({
            file,
            folderId: this.filters.folderId,
            uploadStatus: 'new',
            id: GenerateUUID.ASSET(),
            _id: GenerateUUID.ASSET(),
          })
        }
      })

      // Add assets to the state
      filesArray.forEach(file => {
        if (file.file && file.file.type.includes('image')) {
          this.addUploadingAsset({ asset: file, type: ASSET_TYPES.IMAGE })
        } else {
          this.addUploadingAsset({ asset: file, type: ASSET_TYPES.FILE })
        }
      })

      if (this.vpUploader) {
        const mediaFiles = files.filter(file => file.type.includes('video') || file.type.includes('audio'))
        if (mediaFiles.length) this.uploadMedia(mediaFiles)
      }

      this.clearFields()
    },
    clearFields() {
      this.isBeingDragged = false

      if (this.$refs.uploadInput) {
        this.$refs.uploadInput.value = ''
      }
      if (this.$refs.uploadFileInListing) {
        this.$refs.uploadFileInListing.value = ''
      }
    },
    updateList() {
      this.assetsBeingEdited = false
      this.selectedAssets.forEach(asset => this.assetUpdated(asset))
    },
    async handleAssetDeletion(asset) {
      try {
        const { data } = await this.$apollo.query({
          query: gql`
            query AssetUsedIn($id: String!) {
              assetUsedIn: entryCollection(where: {
                system: {
                  references: {
                    id: {
                      some: [$id]
                    }
                  }
                }
              }) {
                total
              }
            }`,
          fetchPolicy: 'no-cache',
          variables: {
            id: asset.id,
          },
        })
        const count = data?.assetUsedIn?.total || 0
        if (count > 0) {
          this.showConfirmPopup({
            title: this.$t('assets.delete-used-asset.title'),
            description: this.$tc('assets.delete-used-asset.text', count || 1, { count }),
            okTitle: this.$t('assets.delete-used-asset.ok-title'),
            okOnly: true,
          })
          return
        }
        const result = await this.showConfirmDeletePopup({
          items: [asset.title || asset.fileName],
          type: ENTITY_TYPES.ASSET,
        })

        if (result) {
          await deleteAsset(asset)
          this.removeAssets([asset])
        }
      } catch (e) {
        console.log(e)
      }
    },
    async handleAssetsDeletion() {
      try {
        const result = await this.showConfirmDeletePopup({
          items: this.selectedAssets.map(asset => (asset.title ? asset.title : asset.fileName)),
          type: ENTITY_TYPES.ASSET,
        })

        if (result) {
          await deleteAssets(this.selectedAssets)
          this.removeAssets(this.selectedAssets)
        }
      } catch (error) {
        const assetsToRemove = this.selectedAssets.filter(asset => error.response.data.attrs.ids.includes(asset.id))
        this.SET_SELECTED_DATA({
          ...this.selectedData,
          totalSize: (this.selectedData.totalSize || 0) - assetsToRemove.reduce((acc, asset) => acc + asset.size, 0),
          items: this.selectedData.items.filter(asset => !error.response.data.attrs.ids.includes(asset.id)),
          total: this.selectedData.total - error.response.data.attrs.ids.length,
          lastSelection: this.selectedData.lastSelection,
        })
        console.log(error)
      }
    },
    checkRoute() {
      const { assetId } = this.$route.query
      if (this.assetId == assetId) return
      this.SET_ASSET_ID(assetId)
    },
    removeAssets(items, removeStats = true) {
      if (this.removeAssetsTimeout) clearTimeout(this.removeAssetsTimeout)
      if (!this.itemsToRemove) this.itemsToRemove = []
      this.itemsToRemove = [...this.itemsToRemove, ...items]

      this.removeAssetsTimeout = setTimeout(() => {
        this.itemsToRemove.forEach(a => {
          this.selectedAssets = this.selectedAssets.filter(asset => asset.id !== a.id)
          this.allAssets.items = this.allAssets.items.filter(asset => asset.id !== a.id)
          if (removeStats) {
            // Update total count and size
            this.allAssets.total -= 1
            this.allAssets.totalSize -= a.size

            const apolloClient = this.$apollo.getClient()

            // Update Apollo cache
            const data = apolloClient.readQuery({
              query: this.ASSET_COLLECTION_QUERY,
              variables: this.assetCollectionVariables(),
            })

            // Modify the cached data to reflect the asset removal
            const updatedData = {
              ...data,
              assetCollection: {
                ...data.assetCollection,
                items: data.assetCollection.items.filter(asset => asset.id !== a.id),
                total: data.assetCollection.total - 1,
                totalSize: data.assetCollection.totalSize - a.size,
              },
            }

            // Write the updated data back to the cache
            apolloClient.writeQuery({
              query: this.ASSET_COLLECTION_QUERY,
              variables: this.assetCollectionVariables(),
              data: updatedData,
            })
          }
        })
        this.itemsToRemove = []
      }, 50)
    },
    async assetUpdated(asset) {
      const nf = this.allAssets.items.find(el => el.id === asset.id)
      const { url, ...others } = asset
      if (others.labels.length > 0) {
        const { data } = await this.$apollo.query(
          {
            query: gql`
            query ($ids: [String!]) {
              labelCollection(where:{ id: {in: $ids}}) {
                items {
                  id
                  name
                  color
                }
              }
            }
          `,
            variables: {
              ids: others.labels.map(l => l.id),
            },
          },
        )
        others.labels = data.labelCollection.items
      }
      if (nf) Object.assign(nf, others)
    },
    generateRange() {
      return Array(this.maxCols - this.minCols + 1).fill().map((e, i) => i + this.minCols).reverse()
    },
    async loadMore() {
      if (this.$apollo.loading) return
      this.fetchMore()
    },
    fetchMore() {
      if (this.selectedData.show) return
      this.$apollo.queries.assetCollection.fetchMore({
        variables: { offset: this.allAssets.items?.length },
        updateQuery: (previewResult, { fetchMoreResult }) => {
          const items = this.allAssets.items
          items.push(...fetchMoreResult.assetCollection.items)
          Object.assign(this.allAssets, {
            ...fetchMoreResult.assetCollection,
            items,
          })
          return {
            assetCollection: { ...fetchMoreResult.assetCollection, items },
          }
        },
      })
    },
    clearSearch() {
      this.filters.searchTerm = ''
    },
  },
}
</script>

<style lang="scss">
.assets-listing__container {
  .assets-listing {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .assets-listing-container {
    width: 100%;
    height: calc(100% - 35px);
    overflow-y: auto;
  }

  .footer__left-side {
    display: flex;
    align-items: center;
    color: #A3B0C2;
    font-weight: 400;
    flex-grow: 1;
  }

  .footer__right-side {
    display: flex;
    align-items: center;
    color: #A3B0C2;
    font-weight: 400;
  }

  .assets-listing-footer {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding-top: 4px;
    z-index: 1;
  }

  .assets__details {
    color: #667C99;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px; /* 150% */
    margin-right: 16px;
  }

  .assets__details-icon {
    color: #10879a;
    opacity: 0.7;
  }

  .assets__details:last-child {
    margin-left: 50px;
  }

  .media-main-section {
    height: calc(100vh - 230px);
  }

  .assets-loader {
    width: 24px;
    height: 24px;
    margin-right: 10px;
  }

  #assets-popup {
    .media-main-section {
      height: 70vh;
    }
  }

  .media-upload-mbase {
    color: #052D61;
    padding: 0 !important;
    padding-right: 10px !important;
  }

  .media__actions-button_dropdown {
    .btn {
      height: 39px;
    }

    & > .btn:first-child {
      padding: 0;
    }

    & > .btn {
      padding-left: 0;
    }
  }

  .media-upload-mbase:hover {
    background-color: transparent;
  }

  .assets-sort__by-date .dropdown-menu {
    padding-bottom: 0;
  }

  .assets-sort__by-date button {
    padding: 0;
    margin: 0 10px;
  }

  .assets-context {
    display: flex;
    padding: 0.5rem 1.5rem;
  }

  .assets-collapse {
    * {
      box-shadow: none !important;
    }

    .assets-collapse__item {
      background-color: transparent;

      > div.card-header::after {
        left: 0;
        background-size: 18px;
      }

      .assets-collapse__title {
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: #052D61;
        margin-left: 1.5rem;
      }
    }

    .card-body .row {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }
  }

  .hide-dropdown {
    .btn {
      border-top-right-radius: 5.728px !important;
      border-bottom-right-radius: 5.728px !important;
    }

    .dropdown-toggle {
      display: none;
    }
  }
}
</style>
