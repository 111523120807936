<template>
  <div
    class="ig-embed-block-wrapper"
    :style="`text-align: ${alignClass}`"
  >
    <div class="ig-embed-block">
      <div class="ig-embed-holder">
        <InstagramBlockQuote
          v-if="showBlok"
          :key="src"
          :src="src"
          :include-caption="includeCaption"
        />
      </div>
      <b-form-input
        v-model="caption"
        class="ig-embed-block__caption"
        :placeholder="$t('codex-editor.nodes.instagram-embed.caption')"
        rows="1"
        max-rows="10"
      />
    </div>
    <div
      v-if="!selected"
      class="widget-select-overlay"
      @click="selectNode"
    />
  </div>
</template>

<script>
import InstagramBlockQuote from './InstagramBlockQuote.vue'

export default {
  components: {
    InstagramBlockQuote,
  },
  props: ['attrs', 'updateAttrs', 'selectNode', 'selected'],
  inject: ['deleteBlock', 'showPromptPopup', 'toastNotification'],
  data() {
    return {
      initialSrc: '',
      showBlok: true,
    }
  },
  computed: {
    alignClass() {
      return `${this.align}`
    },
    blockId() {
      return this.attrs.blockId
    },
    src() {
      return this.attrs.src
    },
    align: {
      get() {
        return this.attrs.align
      },
      set(e) {
        this.updateAttrs({ align: e })
      },
    },
    includeCaption: {
      get() {
        return this.attrs.includeCaption
      },
      set(e) {
        this.updateAttrs({ includeCaption: e })
      },
    },
    caption: {
      get() {
        return this.attrs.caption
      },
      set(e) {
        this.updateAttrs({ caption: e })
      },
    },
  },
  watch: {
    src: {
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.showBlok = false
          this.$nextTick(() => {
            this.showBlok = true
          })
        }
        if (!oldVal) {
          this.$nextTick(() => {
            this.selectNode()
          })
        }
      },
      immediate: true,
    },
  },
  methods: {
    initialize() {
      if (!this.attrs.src) {
        this.openModal()
      }
    },
    async openModal() {
      const url = await this.showPromptPopup({ title: this.$t('codex-editor.nodes.instagram-embed.instagram-url') })
      if (!url) {
        this.deleteBlock(this.attrs.blockId)
      } else if (url.length && url.match((/^(https?:\/\/)?((w{3}\.)?)instagram.com\/.*/i))) {
        this.updateAttrs({
          src: url,
        })
      } else {
        this.toastNotification({
          icon: 'XIcon',
          variant: 'danger',
          title: 'Error',
          text: this.$t('codex-editor.nodes.instagram-embed.instagram-invalid-url'),
        })
        this.deleteBlock(this.attrs.blockId)
      }
    },
  },
}
</script>

<style scoped lang="scss">

.ig-embed-block-wrapper {
  position: relative;
}

.widget-select-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0,0,0,0);
}

.ig-embed-holder {
    position: relative;
  }
.ig-embed-block {
  margin-top: 15px;
  width: 540px;
  position: relative;
  display: inline-block;
}

.ig-embed-block__caption {
    width: 100%;
    padding: 5px 5px !important;
    font-size: 14px;
    color: gray;
    border: none;
    overflow: hidden !important;
  }

</style>
