import { mapUrlEntityType } from '@/codex-sdk/urls'
import i18n from '@/libs/i18n'
import UrlEntityType from './UrlEntityType.vue'

export default class UrlEntityTypeFilter {
  static COMPONENT = UrlEntityType;

  static type = 'urlEntityType'

  static OPERATORS = {
    includeOneOf: 'includeOneOf',
    includeAllOf: 'includeAllOf',
    exclude: 'exclude',
  }

  operator = null;

  value = null;

  constructor({ operator = UrlEntityTypeFilter.OPERATORS.includeOneOf, value = [] } = {}) {
    this.operator = operator
    this.value = value
  }

  set({ operator = UrlEntityTypeFilter.OPERATORS.includeOneOf, value = [] }) {
    this.operator = operator
    this.value = value
  }

  getCount() {
    return this.value.length
  }

  getValueLabel() {
    return this.value.map(v => i18n.t(mapUrlEntityType(v?.toLowerCase()))).join(', ')
  }

  toLoad() {
    return {
      sections: this.value,
    }
  }

  asQueryParam() {
    return {
      operator: this.operator,
      value: this.value,
    }
  }

  asGraphQL() {
    return {
      in: this.value,
    }
  }

  static validate({ value }) {
    if (!value || value.length === 0) {
      return 'Value is required'
    }

    return true
  }

  static fromQueryParam({ operator, value }) {
    return new UrlEntityTypeFilter({
      operator,
      value,
    })
  }
}
