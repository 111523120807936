<template>
  <div
    class="version__history"
    @mouseover="showPopup = true"
    @mouseleave="showPopup = false"
  >
    <b-modal
      v-model="shown"
      :title="$t('models.edit.versions.edit-modal.edit-version-name')"
      :ok-title="$t('models.edit.versions.edit-modal.ok-title')"
      :cancel-title="$t('models.edit.versions.edit-modal.cancel-title')"
      @cancel="handleModalCancel"
      @close="handleModalCancel"
      @ok="handleNameChange()"
    >
      <b-form-group :label="$t('models.edit.versions.edit-modal.name-label')">
        <b-form-input
          v-model="editFormattedName"
          :placeholder="$t('models.edit.versions.edit-modal.name-placeholder')"
        />
      </b-form-group>
    </b-modal>

    <div class="version__history-title-options">
      <div
        class="version__history-title cursor-pointer"
        :title="formattedName"
        @click="version.id !== assetVersion.currentVersionId ? setAsset() : ''"
      >
        <h5>{{ formattedName }}</h5>
      </div>
      <div class="version__history-options">
        <b-dropdown
          right
          variant="flat"
          class="dropdown-icon-wrapper version__history-dropdown"
        >
          <template #button-content>
            <b-button variant="btn-outline-white">
              <GjIcon name="Moreoptions" />
            </b-button>
          </template>
          <b-dropdown-item
            id="class-history-edit-version"
            v-permission="['canManageAssetVersions', version.entity]"
            @click="showModal"
          >
            <span class="d-flex align-items-center">
              <GjIcon name="Edit" />
              <p class="p-0 m-0 ml-50">
                {{ $t('entries.versions.edit') }}
              </p>
            </span>
          </b-dropdown-item>

          <b-dropdown-item
            v-if="version.id !== assetVersion.currentVersionId"
            id="class-history-restore-version"
            v-permission="['canEditAsset', { id: version.entity.id }]"
            @click="restoreVersion"
          >
            <span class="d-flex align-items-center">
              <GjIcon name="Refresh" />
              <p class="p-0 m-0 ml-50">
                {{ $t('entries.versions.restore') }}
              </p>
            </span>
          </b-dropdown-item>
          <b-dropdown-item
            v-if="version.id !== assetVersion.currentVersionId"
            id="class-history-edit-compareVersion"
            @click="setAsset"
          >
            <span class="d-flex align-items-center">
              <GjIcon name="Modeling" />
              <p class="p-0 m-0 ml-50">
                {{ $t('entries.versions.compare') }}
              </p>
            </span>
          </b-dropdown-item>
        </b-dropdown></div>
    </div>

    <div class="version__history-date-published">
      <div
        v-if="version.id === assetVersion.currentVersionId"
        class="version__current-version version__current-title"
      >
        <div class="version__history-date-published-dot version__little-dot" />
        {{ $t('entries.versions.current-version') }}
      </div>
      <div
        v-if="version.id === comparingId"
        class="version__current-version version__current-title"
      >
        <div class="version__history-date-published-dot version__little-dot" />
        {{ $t('entries.versions.comparing-version') }}
      </div>
      <p
        v-else
        class="version__history-date"
      >
        {{ formatDateTime(version.createdAt) }}
      </p>
    </div>
    <div
      v-if="version.createdBy"
      class="d-flex"
    >
      <Popper
        v-if="showPopup"
        :user="version.createdBy"
      >
        <div class="version__history-author">
          {{ version.createdBy.fullName }}
        </div>
      </Popper>
      <div
        v-else
        class="version__history-author"
      >
        {{ version.createdBy.fullName }}
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { restoreAssetVersion, updateAssetVersion } from '@/codex-sdk/assets'
import Popper from '@/components/popper/Popper.vue'

export default {
  name: 'Version',
  components: { Popper },
  props: {
    version: Object,
    assetVersion: Object,
    comparingId: String,
  },
  inject: ['showConfirmDeletePopup'],
  data() {
    return {
      shown: false,
      editName: null,
      showPopup: false,
    }
  },
  computed: {
    formattedName: {
      get() {
        return this.getFormattedName()
      },
      set(v) {
        this.version.name = v
      },
    },
    editFormattedName: {
      get() {
        const date = moment(this.editName, true)
        if (date.isValid()) return date.format('MMMM D, H:mm')
        return this.editName
      },
      set(v) {
        this.editName = v
      },
    },
  },
  methods: {
    showModal() {
      this.editName = this.version.name
      this.shown = true
    },
    async handleNameChange() {
      try {
        await updateAssetVersion({ ...this.version, name: this.editName })
        this.$emit('fetchVersions')
      } catch (e) {
        console.log(e)
      }
    },
    handleModalCancel() {
      this.editName = null
      this.shown = false
    },
    setAsset() {
      this.$root.$emit('compareAsset', this.version)
    },
    getFormattedName() {
      const date = moment(this.version.name, true)
      if (date.isValid()) return date.format('MMMM D, H:mm')
      return this.version.name
    },
    formatDateTime(date) {
      return moment(date).format('MMMM D, H:mm')
    },

    async restoreVersion() {
      try {
        const { data } = await restoreAssetVersion(this.version)
        if (data) {
          this.$root.$emit('assetVersionRestored')
          this.assetVersion.currentVersionId = this.version.id
        }
      } catch (e) {
        console.log(e)
      }
    },
  },
}
</script>
