<template>
  <div>
    <b-button-group>
      <b-input
        v-model="initialSrc"
        :placeholder="$t('codex-editor.nodes.spotify-podcast.url')"
        autocomplete="off"
        @keyup.enter="setSrc"
      />
      <b-button
        v-b-tooltip.hover.html.top="getTooltipData('save')"
        variant="flat-dark"
        @click="setSrc"
      >
        <GjIcon
          name="Save"
          size="18"
        />
      </b-button>
    </b-button-group>
    <AlignBlockPanel
      :attrs="attrs"
      :update-attrs="updateAttrs"
    />
  </div>
</template>

<script>
import AlignBlockPanel from '@/components/codex-editor/panels/AlignBlockPanel.vue'

export default {
  components: { AlignBlockPanel },
  props: ['attrs', 'updateAttrs'],
  inject: ['toastNotification', 'getTooltipData'],
  data() {
    return {
      initialSrc: '',
    }
  },
  watch: {
    attrs: {
      handler() {
        this.initialSrc = this.attrs.src
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    setSrc() {
      if (this.initialSrc.match((/^(https?:\/\/)?((w{3}\.)?)open.spotify.com\/.*/i))) {
        this.updateAttrs({ src: this.initialSrc })
      } else {
        this.toastNotification({
          icon: 'XIcon',
          variant: 'danger',
          title: 'Error',
          text: this.$t('codex-editor.nodes.spotify-podcast.spotify-podcast-invalid-url'),
        })
      }
    },
  },
}
</script>
