//
import { truncate } from 'lodash'
import ModelFilterComponent from './Model.vue'

export default class ModelFilter {
  static COMPONENT = ModelFilterComponent;

  static type = 'model'

  static graphqlType = 'StringFilter'

  static OPERATORS = {
    includeOneOf: 'includeOneOf',
    includeAllOf: 'includeAllOf',
    exclude: 'exclude',
  }

  operator = null;

  value = null;

  constructor({ operator = ModelFilter.OPERATORS.includeOneOf, value = [] } = {}) {
    this.operator = operator
    this.value = value
  }

  set({ operator = ModelFilter.OPERATORS.includeOneOf, value = [] }) {
    this.operator = operator
    this.value = value
  }

  getCount() {
    return this.value.length
  }

  getValueLabel({ cache }) {
    const model = this.value.map(modelId => cache[modelId] || { id: modelId })
    return model.map(m => truncate(m.name, { length: 15, separator: ' ' })).join(', ')
  }

  // toLoad() {
  //   return {
  //     models: this.value,
  //   }
  // }

  asQueryParam() {
    return {
      operator: this.operator,
      value: this.value,
    }
  }

  asGraphQL() {
    return {
      in: this.value,
    }
  }

  asGraphQLDynamicList() {
    if (this.asGraphQL()) {
      return {
        ...this.asGraphQL(),
        _t: ModelFilter.graphqlType,
      }
    }
    return null
  }

  static validate({ operator, value }) {
    if (!operator) {
      return 'Operator is required'
    }

    if (!value || value.length === 0) {
      return 'Value is required'
    }

    return true
  }

  static fromQueryParam({ operator, value }) {
    return new ModelFilter({
      operator,
      value,
    })
  }
}
