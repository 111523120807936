// import store from '@/store'
// import store from '@/store'
import {
  encodesVideo, encodesAudio,
} from '../../../codex-sdk/vp-upload'

async function encode(file, requestKey) {
  file.status = 'encoding'

  const encodeHeader = {
    downloadUrl: requestKey,
  }

  if (file.fileType === 'video') {
    await encodesVideo(encodeHeader)
      .then(r => {
        file.status = 'success'
        file.mediaId = r.data.result.name
      })
      .catch(err => {
        file.status = 'failed'
        file.failedStatus = 'encode'
        console.error(err)
      })
  } else if (file.fileType === 'audio') {
    await encodesAudio(encodeHeader)
      .then(r => {
        file.status = 'success'
        file.mediaId = r.data.result.publicId
      })
      .catch(err => {
        file.status = 'failed'
        file.failedStatus = 'encode'

        console.error(err)
      })
  } else {
    file.status = 'failed'
    file.failedStatus = 'encode'

    console.error('File type should be \'Video\' or \'Audio\'')
  }
}

export default encode
