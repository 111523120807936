import mutations from './modulesEditorMutations'
import state from './modulesEditorState'
import actions from './modulesEditorActions'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
