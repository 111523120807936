// import { isUserLoggedIn } from '@/auth/utils'
import { routePluginsState } from '@/plugins'
import i18n, { setFlatpickrLocale, setMomentLocale } from '@/libs/i18n'
import store from '@/store/index'
import { LANGUAGES } from '@/utils/constants'

export function getCurrentSiteId() {
  return store.state.general.currentSite?.id
}

export function getCurrentOrganizationId() {
  return store.state.general.currentOrganization?.id
}

export function getCurrentOrganizationAlias() {
  return store.state.general.currentOrganization?.alias
}

export function getCurrentSiteVPConfig() {
  return store.state.general.video
}

export function routerGuards(router) {
  try {
    if (JSON.parse(localStorage.getItem('language'))?.language) {
      i18n.locale = JSON.parse(localStorage.getItem('language')).language
    } else {
      i18n.locale = LANGUAGES.ENGLISH
    }
    setFlatpickrLocale(i18n.locale)
    setMomentLocale(i18n.locale)
  } catch (e) {
    console.log(e)
  }
  router.beforeEach(async (to, from, next) => {
    if (to.params.orgName && !to.meta.isPublic) {
      localStorage.setItem('last-route', JSON.stringify({ name: to?.meta?.lastRouteLink || to?.meta?.navActiveLink || to.name, params: to.params }))
    }

    if (to.meta.isPublic) {
      next()
      return
    }

    routePluginsState.addedRoutesCount = 0

    try {
      /**
       * If we have orgName in url params, and its not the current one
       */
      if (!to.params.orgName) {
        await store.dispatch('general/setCurrentOrganization', { organizationId: null })
      } else if (
        (from.params.orgName && to.params.orgName && from.params.orgName !== to.params.orgName)
        || (!from.params.orgName && from.name !== null && to.params.orgName)
      ) {
        window.location.href = to.fullPath
        return
      } else if (
        (
          (to.params.orgName && !store.state.general.currentOrganization)
          || (to.params.orgName && store.state.general.currentOrganization.alias !== to.params.orgName)
        )
        && to.meta.organizationRequired
      ) {
        await store.dispatch('general/setCurrentOrganization', { organizationId: to.params.orgName })
      }

      /**
       * If we have orgId in url params, and its not the current one
       */
      if (!to.params.siteName) {
        await store.dispatch('general/setCurrentSite', { siteId: null })
      } else if (
        (
          (to.params.siteName && !store.state.general.currentSite)
          || (to.params.siteName && store.state.general.currentSite.name !== to.params.siteName)
        )
        && to.meta.organizationRequired
      ) {
        await store.dispatch('general/setCurrentSite', { siteId: to.params.siteName })
      }
    } catch (error) {
      console.log('Error setting current organization', error)

      /**
       * If there was an error fetching the organzation or site,
       * it can mean that one of them:
       *  - Does not exist
       *  - User has no permission to access it
       */
      if (getCurrentOrganizationId()) {
        next(`${getCurrentOrganizationAlias()}/sites`)
        return
      }

      // Leaving the redirect for now..
      next('/organizations')
      return
    }

    /**
     * Last step, make sure we have the organzation/site set for
     * pages that require it
     */
    if (to.meta.organizationRequired && !getCurrentOrganizationId()) {
      // Leaving the redirect for now..
      next('/organizations')
      return
    }
    if (to.meta.siteRequired && !getCurrentSiteId()) {
      next(`${getCurrentOrganizationAlias()}/sites`)
      return
    }

    // According to https://router.vuejs.org/guide/advanced/dynamic-routing.html#adding-routes-inside-navigation-guards
    // If we add routes inside a navigation guard, we need to call next() with a route, to trigger a redirect
    if (routePluginsState.addedRoutesCount !== 0 && from.fullPath !== to.fullPath) {
      next(to.fullPath)
      return
    }

    next()
  })
}
