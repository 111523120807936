import {
  createOrganization, readOrganizations, updateOrganization, deleteOrganization, deleteOrganizations,
} from '../../codex-sdk/organizations'

export default {
  addOrganization({ commit }, { organization, message }) {
    return new Promise((resolve, reject) => {
      createOrganization(organization, message)
        .then(response => {
          commit('ADD_ORGANIZATION', Object.assign(organization, { id: response.data.id }))
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  fetchOrganizations({ commit }) {
    return new Promise((resolve, reject) => {
      readOrganizations()
        .then(response => {
          const { data: { data } } = response
          commit('SET_ORGANIZATIONS', data)
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  updateOrganization({ commit }, { organization, message }) {
    return new Promise((resolve, reject) => {
      updateOrganization(organization, message)
        .then(response => {
          commit('UPDATE_ORGANIZATION', organization)
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  removeOrganization({ commit }, { organizationId, message }) {
    return new Promise((resolve, reject) => {
      deleteOrganization(organizationId, message)
        .then(response => {
          commit('REMOVE_ORGANIZATION', organizationId)
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  removeOrganizations({ commit }, { organizationIds, message }) {
    return new Promise((resolve, reject) => {
      deleteOrganizations(organizationIds, message)
        .then(response => {
          commit('REMOVE_ORGANIZATIONS', organizationIds)
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
}
