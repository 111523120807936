<template>
  <div
    class="embed-dropdown__all-options"
    :style="getLinkPopupPosition"
  >
    <div
      v-for="(option,index) in options"
      :key="option.key"
      class="embed-dropdown__single-option"
      :class="{ 'is-selected': navigatedItemIndex === index }"
      @click="process(option)"
    >
      <p>
        {{ option.value }}
      </p>
    </div>
  </div>
</template>

<script>

import { CODEX_EDITOR_BLOCKS } from '@/components/codex-editor/CodeEditorConstants'
import CodexContentEditor from '@/components/codex-editor/CodexContentEditor'

export default {
  inject: ['deleteBlock', 'includeBlocks'],
  props: ['editor', 'value'],
  data() {
    return {
      embeds: [
        { name: CODEX_EDITOR_BLOCKS.YOUTUBE_EMBED, regex: /(http:|https:)?\/\/(www\.)?(youtube.com|youtu.be)\/(watch)?(\?v=)?(\S+)?/ },
        { name: CODEX_EDITOR_BLOCKS.FACEBOOK_EMBED, regex: /^(https?:\/\/)?((w{3}\.)?)facebook.com\/.*/i },
        { name: CODEX_EDITOR_BLOCKS.INSTAGRAM_EMBED, regex: /^(https?:\/\/)?((w{3}\.)?)instagram.com\/.*/i },
        { name: CODEX_EDITOR_BLOCKS.TWITTER_EMBED, regex: /^(https?:\/\/)?((w{3}\.)?)(twitter|x).com\/.*/i },
        { name: CODEX_EDITOR_BLOCKS.VIMEO_EMBED, regex: /^(https?:\/\/)?((w{3}\.)?)vimeo.com\/.*/i },
        { name: CODEX_EDITOR_BLOCKS.FAMEPLAY_EMBED, regex: /^(https?:\/\/)?((w{3}\.)?)(mall.tv|mall.fameplay.tv|fameplay.tv)\/.*/i },
        { name: CODEX_EDITOR_BLOCKS.GOOGLE_PODCAST, regex: /^(https?:\/\/)?((w{3}\.)?)podcasts.google.com\/.*/i },
        { name: CODEX_EDITOR_BLOCKS.APPLE_PODCAST, regex: /^(https?:\/\/)?((w{3}\.)?)podcasts.apple.com\/.*/i },
        { name: CODEX_EDITOR_BLOCKS.SPOTIFY_PODCAST, regex: /^(https?:\/\/)?((w{3}\.)?)open.spotify.com\/.*/i },
        { name: CODEX_EDITOR_BLOCKS.TIKTOK_EMBED, regex: /\bhttps?:\/\/(?:m|www|vm)\.tiktok\.com\/\S*?\b(?:(?:(?:usr|v|embed|user|video)\/|\?shareId=|&item_id=)(\d+)|(?=\w{7})(\w*?[A-Z\d]\w*)(?=\s|\/$))\b/gm },
      ],
      navigatedItemIndex: 0,
    }
  },

  computed: {
    getLinkPopupPosition() {
      const { view, selection: { from, to } } = this.editor
      const start = view.coordsAtPos(from)
      const end = view.coordsAtPos(to, true)

      const editorDOM = this.editor.view.dom
      const editorRect = editorDOM.getBoundingClientRect()

      return {
        top: `${end.bottom - editorRect.top}px`,
        left: `${((start.left + end.left) / 2) - editorRect.left + 10}px`,
      }
    },
    node() {
      const nodeOption = this.embeds.find(embed => this.value.match(embed.regex))

      if (nodeOption && this.includeBlocks.includes(nodeOption?.name)) {
        return CodexContentEditor.getWidgetObjectByType(nodeOption.name)
      }
      return this.includeBlocks.includes(CODEX_EDITOR_BLOCKS.IFRAME) && CodexContentEditor.getWidgetObjectByType(CODEX_EDITOR_BLOCKS.IFRAME)
    },
    options() {
      const options = [
        { key: 'link', value: this.$t('codex-editor.plugins.embed-dropdown.link') },
        { key: 'dismiss', value: this.$t('codex-editor.plugins.embed-dropdown.dismiss') },
      ]

      if (this.node) {
        options.unshift({ key: 'embed', value: this.$t(this.node.displayName) })
      }
      return options
    },
  },
  methods: {
    process(option) {
      if (option.key === 'dismiss') {
        const { to: updatedTo } = this.editor.selection
        this.editor.setSelection(updatedTo - this.value.length, updatedTo)
        this.editor.commands.link('')
        this.editor.setSelection(updatedTo, updatedTo)
      } else if (option.key === 'embed' && this.node) {
        const { from, to } = this.editor.selection
        this.editor.view.dispatch(this.editor.state.tr.insertText('', from - this.value.length, to))
        this.editor.commands[this.node.type]({ src: this.value })
        if (!this.editor.state.selection.$head.parent.textContent) {
          const id = this.editor.state.selection.$head.parent.attrs.blockId
          this.deleteBlock(id)
        }
      }
      this.$emit('close')
    },
    upHandlerEmbedDropdown() {
      this.navigatedItemIndex = ((this.navigatedItemIndex + this.options.length) - 1) % this.options.length
    },
    downHandlerEmbedDropdown() {
      this.navigatedItemIndex = ((this.navigatedItemIndex + this.options.length) + 1) % this.options.length
    },

    checkKeysEmbedDropdown(key) {
      if (key === 'ArrowUp') {
        this.upHandlerEmbedDropdown()
        return true
      }
      if (key === 'ArrowDown') {
        this.downHandlerEmbedDropdown()
        return true
      }
      if (key === 'Enter') {
        this.options.forEach((a, index) => {
          if (index == this.navigatedItemIndex) {
            this.process(a)
          }
        })
        this.navigatedItemIndex = 0
        return true
      }
      if (key === 'Escape') {
        this.$emit('close')
        this.navigatedItemIndex = 0
        return false
      }

      this.$emit('close')

      return false
    },
  },
}
</script>

<style scoped lang="scss">
.embed-dropdown__all-options {
  position: absolute;
  width: 250px;
  min-height: 70px;
  box-shadow: rgb(15 15 15 / 5%) 0px 0px 0px 1px, rgb(15 15 15 / 10%) 0px 3px 6px, rgb(15 15 15 / 20%) 0px 9px 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background-color: #ffffff;
  z-index: 10000;
}

.embed-dropdown__single-option {
  width: 95%;
  height: 30%;
  padding: 3px;
  display: flex;
  align-items: center;

  &:last-child {
    margin-bottom: 5px;
    margin-top: 3px;
  }

  &:first-child {
    margin-top: 5px;
    margin-bottom: 3px;
  }

  &.is-selected {
    background: #f8f8f8;
    border-radius: 5px;
  }

  &:hover {
    background: #f8f8f8;
    cursor: pointer;
    border-radius: 5px;
  }

  p {
    margin: 0px;
    margin-left: 4px;
    font-size: 15px;
  }
}
</style>
