/* eslint-disable no-use-before-define */
/* eslint-disable max-classes-per-file */
/* eslint-disable no-unused-vars */

import Vue from 'vue'
import { cloneDeep, merge, mergeWith } from 'lodash'
import AddBlockDivider from '@/components/codex-layout-editor/components/AddBlock.vue'
import { widgetGroups } from '@/codex-sdk/layouts'
import _EditorBlockPanel from '@/components/codex-layout-editor/components/BuilderBlockPanel.vue'
import EventEmitter from '@/components/codex-layout-editor/EventEmitter'
import { LAYOUT_BLOCKS } from '@/utils/constants'
import { TYPES, VALUE_TYPES } from '@/views/models/constants'
import { ENTRY_STATUSES } from '@/codex-sdk/entries'
import FieldValidations from '@/components/fields-validations/validations'
import FieldValidationsComponent from '@/components/fields-validations/FieldValidations.vue'
import CodexLayoutError from './CodexLayoutError'
import AddBlock from './transactions/AddBlock'
import DuplicateBlock from './transactions/DuplicateBlock'
import MoveBlock from './transactions/MoveBlock'
import RemoveBlock from './transactions/RemoveBlock'
import UpdateBlockAttributes from './transactions/UpdateBlockAttributes'
import AddBlockBeforeAfter from './transactions/AddBlockBeforeAfter'

import FieldInitialPanel from './components/FieldInitialPanel.vue'
import FieldGeneralTabPanel from './components/FieldGeneralTabPanel.vue'

const { GenerateUUID, capitalizeFirstLetter } = require('@/utils/helpers')

/**
 * Layout Editor main class
 */
export default class CodexLayoutEditor extends EventEmitter {
  root = {
    type: 'ROOT',
    content: [],
  };

  selectedBlockId = null

  breadcrumb = []

  history = [];

  historyStep = 0;

  allBlocks = {};

  breakpoint = 'desktop';

  breakpoints = {
    mobile: 0,
    tablet: 576,
    desktop: 992,
  }

  resizeObserver = null;

  metadata = {}

  previewMode = true;

  allowDuplicate = true;

  constructor({ allowDuplicate = true } = {}) {
    super()
    this.allowDuplicate = allowDuplicate
    document.addEventListener('keydown', this.keyboardHandler.bind(this))
  }

  setMetadata(key, value) {
    Vue.set(this.metadata, key, value)
  }

  setAutomagicBreakpointChange(rootDom) {
    if (!rootDom) return

    this.unsetAutomagicBreakpointChange()

    this.resizeObserver = new ResizeObserver(entries => {
      entries.forEach(entry => {
        this.setBreakpointByWidth(entry.contentRect.width)
      })
    })
    this.resizeObserver.observe(rootDom)
  }

  unsetAutomagicBreakpointChange() {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect()
      this.resizeObserver = null
    }
  }

  setBreakpointByWidth(width) {
    if (width > this.breakpoints.mobile && width < this.breakpoints.tablet) {
      this.breakpoint = 'mobile'
    } else if (width > this.breakpoints.tablet && width < this.breakpoints.desktop) {
      this.breakpoint = 'tablet'
    } else {
      this.breakpoint = 'desktop'
    }
  }

  keyboardHandler(e) {
    if (e.code == 'KeyZ' && e.ctrlKey) {
      this.undo()
      e.preventDefault()
    }
    if (e.code == 'KeyY' && e.ctrlKey) {
      this.redo()
      e.preventDefault()
    }
  }

  dispatch(tr) {
    let r
    try {
      r = tr.up()
      this.emit(tr.name, tr)
      this.addToHistory(tr)
    } catch (e) {
      console.log(e)
    }
    return r
  }

  addToHistory(tr) {
    let createNewGroup = true
    if (this.history.length == 0 || this.historyStep !== this.history.length) {
      createNewGroup = true
    } else {
      const lastGroup = this.history[this.history.length - 1]
      if (tr.datetime.diff(lastGroup[0].datetime, 'seconds', true) < 1) {
        createNewGroup = false
      }
    }

    if (createNewGroup) {
      this.history.splice(this.historyStep, Math.min(), [tr])
      this.historyStep++
    } else {
      this.history[this.history.length - 1].push(tr)
    }
  }

  setContent(content) {
    this.root.content = content
    this.addBlocksToAllBlocks({ content })
  }

  getContent() {
    this.setRowLayout(this.root)
    return this.root.content
  }

  setRowLayout(block) {
    if (block && block.content && block.content.constructor == Array) {
      if (block.type == 'codex_row') {
        const layout = []
        block.content.forEach(childBlock => {
          childBlock.attrs.cols = childBlock.attrs._responsive.desktop.columns
          layout.push(childBlock.attrs.cols)
        })
        block.attrs.layout = layout.join('-')
      }
      block.content.forEach(childBlock => this.setRowLayout(childBlock))
    }
  }

  getBreadcrumb() {
    return [{ id: null, type: 'Root' }, ...this.breadcrumb.map(blockId => {
      const block = this.findBlockById(blockId)
      const widgetObject = CodexLayoutEditor.getWidgetObjectByType(block.type)
      return { ...block, ...widgetObject }
    })]
  }

  isSelectedBlock(blockId) {
    return this.selectedBlockId == blockId
  }

  isParentOfSelectedBlock(blockId) {
    if (this.isSelectedBlock(blockId)) return false
    return this.breadcrumb.indexOf(blockId) !== -1
  }

  selectBlock(blockId) {
    if (!blockId) {
      this.selectedBlockId = null
      this.breadcrumb = []
      return
    }

    const block = this.findBlockById(blockId)

    if (!block) {
      throw new CodexLayoutError(`Block ${blockId} not found`)
    }

    if (this.metadata.sidebarBlockId) {
      this.setMetadata('sidebarBlockId', blockId)
    }

    this.selectedBlockId = block.id
    this.breadcrumb = this.generateBreadcrumb(blockId, [blockId])
  }

  scrollToBlock(blockId) {
    const node = document.querySelector(`.layout-editor__block[data-id='${blockId}']`)
    if (!node) return
    const rect = node.getBoundingClientRect()
    const scrollY = window.pageYOffset || window.scrollY
    window.scroll({ left: 0, top: rect.top + scrollY - 140, behavior: 'smooth' })
  }

  generateBreadcrumb(blockId, breadcrumb = []) {
    const parentBlock = this.findParentBlockById(blockId)

    return parentBlock.id ? this.generateBreadcrumb(parentBlock.id, [parentBlock.id, ...breadcrumb]) : breadcrumb
  }

  undo() {
    try {
      if (this.historyStep > 0) {
        this.historyStep--
        for (let i = this.history[this.historyStep].length - 1; i >= 0; i--) {
          this.history[this.historyStep][i].down()
          this.emit('undo', this.history[this.historyStep][i])
        }
      }

      // Deselect
      this.selectBlock(null)
    } catch (e) {
      console.log(e)
    }
  }

  redo() {
    try {
      if (this.historyStep < this.history.length) {
        for (let i = 0; i <= this.history[this.historyStep].length - 1; i++) {
          this.history[this.historyStep][i].up()
          this.emit('redo', this.history[this.historyStep][i])
        }
        this.historyStep++

        // Deselect
        this.selectBlock(null)
      }
    } catch (e) {
      console.log(e)
    }
  }

  generateBlockId() {
    return GenerateUUID.BLOCK()
  }

  getResponsiveBreakpoints() {
    return ['desktop', 'tablet', 'mobile']
  }

  static getResponsiveObject(attrs = {}) {
    return {
      desktop: merge({ display: true }, attrs),
      tablet: merge({ display: true }, attrs),
      mobile: merge({ display: true }, attrs),
    }
  }

  createBlock({
    id = null, type, content = [], attrs = {},
  }) {
    id = id || this.generateBlockId()
    const defaultAttrs = CodexLayoutEditor.getWidgetAttrDefaults(type)

    return {
      id,
      type,
      content,
      isField: !LAYOUT_BLOCKS.includes(type),
      attrs: merge({
        _id: '',
        _class: '',
        _alias: '',
        _responsive: CodexLayoutEditor.getResponsiveObject(),
      }, defaultAttrs, attrs),
    }
  }

  addBlocksToAllBlocks(block) {
    if (block && block.content && block.content.constructor == Array) {
      this.allBlocks[block.id] = block

      // Backward compatibility
      if (!block.attrs || !block.attrs._responsive) {
        const defaultAttrs = CodexLayoutEditor.getWidgetAttrDefaults(block.type)
        const defaultResponsiveAttrs = {}
        if (block.attrs && block.attrs.cols) {
          defaultResponsiveAttrs.columns = block.attrs.cols
        }
        block.attrs = merge(defaultAttrs, {
          _id: '',
          _class: '',
          _alias: '',
          _responsive: CodexLayoutEditor.getResponsiveObject(defaultResponsiveAttrs),
        }, block.attrs)
      }

      block.content.forEach(childBlock => this.addBlocksToAllBlocks(childBlock))
    }
  }

  findBlockById(blockId, returnRoot = true) {
    if (!blockId && returnRoot) return this.root
    return this.allBlocks[blockId]
  }

  updateBlockIds(block) {
    if (block && block.content && block.content.constructor == Array) {
      block.id = this.generateBlockId()
      block.content.forEach(childBlock => this.updateBlockIds(childBlock))
    }
    return block
  }

  /**
   * This method finds the parent block of given blockId
   *
   * @param {String} blockId Block id we want to find the parent of
   * @param {Object} currentBlock The block we are currently searching in
   * @returns The parent block if found, false otherwise
   */
  findParentBlockById(blockId, currentBlock = this.root) {
    if (currentBlock && currentBlock.content && currentBlock.content.constructor == Array) {
      // eslint-disable-next-line no-restricted-syntax
      for (const block of currentBlock.content) {
        if (block.id === blockId) {
          return currentBlock
        }
        const b = this.findParentBlockById(blockId, block)
        if (b) return b
      }
    }
    return false
  }

  /**
   * Transactions made easy
   */
  addBlock(blockId, addIndex, blockOptions) {
    return this.dispatch(new AddBlock(this, blockId, addIndex, blockOptions))
  }

  addBlockBefore(blockId, blockOptions) {
    return this.dispatch(new AddBlockBeforeAfter(this, blockId, 'before', blockOptions))
  }

  addBlockAfter(blockId, blockOptions) {
    return this.dispatch(new AddBlockBeforeAfter(this, blockId, 'after', blockOptions))
  }

  removeBlock(blockId) {
    this.dispatch(new RemoveBlock(this, blockId))
  }

  moveBlock(blockId, newParentId, newIndex) {
    this.dispatch(new MoveBlock(this, blockId, newParentId, newIndex))
  }

  updateBlockAttributes(blockId, newAttrs, breakpoint) {
    this.dispatch(new UpdateBlockAttributes(this, blockId, newAttrs, breakpoint))
  }

  duplicateBlock(blockId) {
    this.dispatch(new DuplicateBlock(this, blockId))
  }

  /* *
   *
   * Widgets
   *
   * */

  static registeredWidgets = [];

  static getWidgetObjectByType(type) {
    const widgetObject = this.registeredWidgets.find(w => w.type == type)
    return widgetObject || {
      displayName: type,
      name: type,
      type,
      settings: {
        duplicate: false,
        drag: false,
      },
      missing: true,
      group: widgetGroups.GENERAL,
    }
  }

  static unregisterWidget(pluginName, widget) {
    const namespacedWidgetName = `${pluginName}-${widget.name}`

    const index = this.registeredWidgets.findIndex(regWidget => regWidget.type == namespacedWidgetName)
    this.registeredWidgets.splice(index, 1)

    delete Vue.options.components[namespacedWidgetName]
    delete Vue.options.components[`${namespacedWidgetName}-render`]
  }

  static registerField(pluginName, field, isPlugin) {
    field.builder = ['model']
    field.group = field.group || 'plugin'

    if (!field.type || !field.valueType) {
      console.error(`Field '${field.name}' must have a type and valueType`)
      return
    }

    const type = field.type.constructor === String ? TYPES[field.type] : field.type
    const valueType = field.type.constructor === String ? VALUE_TYPES[field.valueType] : field.valueType

    if (!type || !valueType) {
      console.error(`Field '${field.name}' has invalid type or valueType`)
      return
    }

    if (!field.renderEditorInitialPanel?.length) {
      field.renderEditorInitialPanel = [{ icon: 'Settings', label: 'fields.codex-field-boolean.tabs.initial', component: FieldInitialPanel }]
    }

    if (field.renderEditorPanel) {
      if (field.renderEditorPanel.constructor == Array) {
        if (!field.renderEditorPanel.find(e => e.isGeneral)) {
          field.renderEditorPanel.unshift({ icon: 'Settings', label: 'fields.codex-field-boolean.tabs.general', component: FieldGeneralTabPanel })
        }
      } else {
        field.renderEditorPanel = [
          { icon: 'Settings', label: 'fields.codex-field-boolean.tabs.general', component: FieldGeneralTabPanel },
          { icon: 'Settings', label: 'fields.codex-field-boolean.tabs.general', component: field.renderEditorPanel },
        ]
      }
    } else {
      field.renderEditorPanel = [{ icon: 'Settings', label: 'fields.codex-field-boolean.tabs.general', component: FieldGeneralTabPanel }]
    }

    if (isPlugin) {
      const fieldValidations = FieldValidations.getValidationsForType(type, valueType)
      if (fieldValidations.length) {
        field.renderEditorPanel.push(
          {
            icon: 'Tasks',
            label: 'fields.codex-field-json.tabs.validations',
            component: FieldValidationsComponent,
          },
        )
      }
    }

    field.attrs = {
      ...field.attrs,

      type: {
        default: type,
      },
      valueType: {
        default: valueType,
      },
      defaultValue: {
        default: field.defaultValue,
      },

      name: {
        default: '',
      },
      alias: {
        default: '',
      },
      description: {
        default: '',
      },

      validation: {
        default: {
          ...field?.attrs?.validation?.default,
          ...FieldValidations.getFieldValidationAttrs(type, valueType),
        },
      },
    }

    CodexLayoutEditor.registerWidget(pluginName, field)
  }

  static registerWidget(pluginName, widget) {
    // Widgets namespaced name
    let namespacedWidgetName = `${pluginName}_${widget.name}`

    // Backward compatibility
    if (namespacedWidgetName == 'codex-row') {
      namespacedWidgetName = 'codex_row'
    }
    if (namespacedWidgetName == 'codex-col') {
      namespacedWidgetName = 'codex_column'
    }

    if (!widget?.builder?.length) {
      widget.builder = ['layout']
    }
    const icon = {}
    if (widget.icon && widget.icon.constructor == String && !widget.icon.startsWith('./') && !widget.icon.startsWith('/')) {
      try {
        // eslint-disable-next-line global-require, import/no-dynamic-require
        icon.icon = require(`@/assets/icons/${widget?.icon}`)
      } catch (err) {
        console.log(err)
      }
    }

    // Push widget to list
    this.registeredWidgets.push({
      ...widget,
      ...icon,
      displayName: widget.displayName || capitalizeFirstLetter(widget.name),
      group: widget.group !== null ? widget.group || widgetGroups.GENERAL : null,
      type: namespacedWidgetName,
      widgetSidebar: widget.renderEditorPanel,
    })

    // Register Blocks Panel
    // if (widget.renderEditorPanel) {
    //   if( widget.renderEditorPanel.constructor == Array ) {

    //   } else {
    //     Vue.component(`${namespacedWidgetName}_panel`, widget.renderEditorPanel)
    //   }
    // }

    // Register Block
    // eslint-disable-next-line no-use-before-define
    this.registerNewBlock(namespacedWidgetName, widget.renderEditor)

    // ToDo: Move logic out of this file
    // Register field for model entry
    this.registerNewField(namespacedWidgetName, widget.renderEditor)
  }

  static registerNewField(name, EditorBlock) {
    return Vue.component(`${name}-render`, {
      components: {
        EditorBlock,
      },
      inject: ['entry', 'setFieldValue', 'validationData', 'showError', 'fieldPermissions', 'selectedEditor', 'setSelectedEditor', 'checkConditions', 'isReadOnly'],
      props: {
        widget: Object,
      },
      provide() {
        return {
          widget: this.widget,
          renderClass: `codex-field-${this.widget.attrs.alias}`,
        }
      },
      data() {
        return {
          resizeObserver: null,
          breakpointClasses: 'testt',
        }
      },
      computed: {
        val: {
          get() {
            return this.entry().content[this.widget.attrs.alias]
          },
          set(v) {
            this.entry().content[this.widget.attrs.alias] = v
          },
        },
        widgetAttrDefaults() {
          return CodexLayoutEditor.getWidgetAttrDefaults(this.widget.type)
        },
        readOnly() {
          if (this.isReadOnly()) return true
          if (!this.widget.attrs.validation?.readOnly) return false
          return this.checkConditions(this.widget.attrs.validation.readOnly)
        },
        required() {
          if (!this.widget.attrs.validation?.required) return false
          return this.checkConditions(this.widget.attrs.validation.required)
        },
        hidden() {
          if (!this.widget.attrs?.hidden) return false
          return this.checkConditions(this.widget.attrs.hidden)
        },
      },
      watch: {
        val: {
          handler() {
            this.handleMetrics()
          },
        },
      },
      mounted() {
        this.$root.$on('calculateMetrics', this.handleMetricsImmediate)
        this.$root.$on('validateEntry', this.validateOnSave)

        this.resizeObserver = new ResizeObserver(entries => {
          this.breakpointClasses = null
          if (entries.length === 0) return
          const el = entries[0]
          const elWidth = el.contentRect?.width
          const breakpoints = {
            xs: {
              min: 10,
              max: 575,
            },
            sm: {
              min: 576,
              max: 767,
            },
            md: {
              min: 768,
              max: 991,
            },
            lg: {
              min: 992,
              max: 1199,
            },
            xl: {
              min: 1200,
            },
          }
          function getBreakpointClasses(width) {
            return Object.entries(breakpoints).flatMap(([breakpoint, values]) => {
              const classes = []
              if (values.min && width <= values.min) {
                classes.push(`widget-${breakpoint}-min`)
              }
              if (values.min && values.max && width >= values.min && width <= values.max) {
                classes.push(`widget-${breakpoint}`)
              }
              return classes
            })
          }

          this.breakpointClasses = getBreakpointClasses(elWidth)
        })
        this.resizeObserver.observe(this.$refs.currentRef.$el)
        this.widget.attrs = mergeWith(cloneDeep(this.widgetAttrDefaults), this.widget.attrs, (objValue, srcValue) => {
          if (Array.isArray(objValue) && Array.isArray(srcValue)) {
            return srcValue
          }
          return undefined
        })
      },
      beforeDestroy() {
        this.$root.$off('calculateMetrics', this.handleMetricsImmediate)
        this.$root.$off('validateEntry', this.validateOnSave)
      },
      methods: {
        handleMetricsImmediate() {
          if (!this.$refs.currentRef || typeof this.$refs.currentRef.getMetrics !== 'function') return
          const metrics = this.$refs.currentRef.getMetrics()
          this.$root.$emit('entryMetrics', { id: this.widget.id, metrics })
        },
        handleMetrics() {
          if (!this.$refs.currentRef || typeof this.$refs.currentRef.getMetrics !== 'function') return

          this.$nextTick(() => {
            const metrics = this.$refs.currentRef.getMetrics()
            this.$root.$emit('entryMetrics', { id: this.widget.id, metrics })
          })
        },
        callValidate(v) {
          if (this.$refs.currentRef.validate) {
            this.validationData()[this.widget.attrs.alias] = this.$refs.currentRef.validate(v)
          }
          this.$forceUpdate()
        },
        clearError() {
          this.validationData()[this.widget.attrs.alias] = { isValid: true, message: '' }
        },
        validateOnSave() {
          if (this.$refs.currentRef.validate) {
            this.validationData()[this.widget.attrs.alias] = this.$refs.currentRef.validate(this.val)
          } else if (this.$refs.currentRef?.$children[0]?.validate && this.validationData()[this.widget.attrs.alias]?.isValid !== false) {
            // force update the "required" before validation
            this.$set(this.$refs.currentRef.$children[0], 'required', this.required)
            this.validationData()[this.widget.attrs.alias] = this.$refs.currentRef.$children[0].validate(this.val)
          }
          this.$forceUpdate()
        },
      },
      render(h) {
        const alias = this.widget.attrs.alias
        const { viewableFields, editableFields } = this.fieldPermissions()

        return h('div', {
          class: [
            'codex-widget',
            this.breakpointClasses,
            name,
            this.selectedEditor?.alias === alias ? 'widget-selected' : 'widget-not-selected',
            (viewableFields?.[alias] ? 'widget-viewable' : 'widget-not-viewable'),
            (editableFields?.[alias] ? 'widget-editable' : 'widget-not-editable'),
            this.readOnly ? 'widget-readonly' : '',
            this.hidden ? 'widget-hidden' : '',
          ],
          attrs: {
            'data-input-alias': alias,
          },
          on: {
            click: e => {
              this.setSelectedEditor(alias)
            },
          },
        },
        [h('EditorBlock', {
          props: {
            widget: this.widget,
            value: this.val,
            error: this.showError() ? this.validationData()[this.widget.attrs.alias] : { isValid: true },
            callValidate: this.callValidate,
            clearError: this.clearError,
            readOnly: this.readOnly,
            required: this.required,
            hidden: this.hidden,
            editable: editableFields?.[alias],
            entry: cloneDeep(this.entry()),
          },
          class: ['codex-field', `codex-field-${this.widget.attrs.alias}`],
          ref: 'currentRef',
          on: {
            input: v => {
              if (!this.readOnly && editableFields?.[alias] && !this.hidden) {
                this.val = v
                this.$forceUpdate()
              }
            },
          },
        })])
      },
    })
  }

  static getWidgetAttrDefaults(type) {
    const widget = this.getWidgetObjectByType(type)
    if (!widget || !widget.attrs) return {}

    const attrs = {
      _responsive: CodexLayoutEditor.getResponsiveObject(),
    }

    Object.keys(widget.attrs).forEach(attrName => {
      if (widget.attrs[attrName].responsive) {
        // eslint-disable-next-line no-restricted-syntax, guard-for-in
        for (const i in attrs._responsive) {
          attrs._responsive[i][attrName] = widget.attrs[attrName].default === undefined || widget.attrs[attrName].default === null ? '' : widget.attrs[attrName].default
        }
      } else {
        attrs[attrName] = widget.attrs[attrName].default === undefined ? '' : widget.attrs[attrName].default
      }
    })
    return attrs
  }

  static registerNewBlock(name, EditorBlock, EditorBlockPanel = _EditorBlockPanel) {
    return Vue.component(name, {
      components: {
        EditorBlock,
      },
      props: {
        widget: {
          required: true,
          type: Object,
        },
      },
      inject: ['editor', 'checkConditions'],
      data() {
        return {
          editorBlock: null,
        }
      },
      computed: {
        widgetObject() {
          return CodexLayoutEditor.getWidgetObjectByType(this.widget.type)
        },
        widgetAttrDefaults() {
          return CodexLayoutEditor.getWidgetAttrDefaults(this.widget.type)
        },
        previewMode() {
          return this.editor.previewMode || LAYOUT_BLOCKS.indexOf(this.widget.type) !== -1
        },
        classesFromEditorBlock() {
          if (!this.editorBlock) return {}
          return this.editorBlock.widgetClasses && this.editorBlock.widgetClasses.constructor == Function ? this.editorBlock.widgetClasses() : {}
        },
        selected() {
          return this.editor.isSelectedBlock(this.widget.id)
        },
        isParentOfSelected() {
          return this.editor.isParentOfSelectedBlock(this.widget.id)
        },
        required() {
          if (!this.widget.attrs.validation?.required) return false
          return this.checkConditions(this.widget.attrs.validation.required)
        },
      },
      mounted() {
        this.editorBlock = this.$refs.editorBlock
        this.widget.attrs = mergeWith(cloneDeep(this.widgetAttrDefaults), this.widget.attrs, (objValue, srcValue) => {
          if (Array.isArray(objValue) && Array.isArray(srcValue)) {
            return srcValue
          }
          return undefined
        })
      },
      render(h) {
        const settings = this.widgetObject?.settings
        return h('div', {
          attrs: {
            'data-id': this.widget.id,
          },
          class: {
            'layout-editor__block': true,
            selected: this.selected,
            'parent-selected': this.isParentOfSelected,
            ...this.classesFromEditorBlock,
            'layout-editor__block--resizing': this.editor.metadata.resizingColumn && this.editor.metadata.resizingColumn == this.widget.id,
            'layout-editor__block--resizing-other': this.editor.metadata.resizingColumn && this.editor.metadata.resizingColumn !== this.widget.id,
            'layout-editor__block--highlight': this.editor.metadata.highlightBlock && this.editor.metadata.highlightBlock == this.widget.id,
          },
        }, [
          // Debug Data
          // h('pre', {
          //   props: {
          //     widget: this.widget,
          //   },
          // }, [`${this.widget.type} - ${JSON.stringify(this.widget.attrs, null, 2)}`]),

          // Panel ( BLock options )
          settings?.panel === false ? null : h(EditorBlockPanel, {
            props: {
              widget: this.widget,
            },
          }),

          // Add Block Before
          settings?.addBlock === false ? null : h(AddBlockDivider, {
            attrs: {
              addPosition: 'before',
              widget: this.widget,
            },
            class: {
              'layout-editor__add-block--before': 1,
            },
          }),

          // Block content inner
          h('div', {
            class: {
              'layout-editor__block-inner': true,
            },
            on: {
              click: e => {
                e.stopPropagation()
                this.editor.selectBlock(this.widget.id)
              },
            },
          }, [
            this.previewMode

            // Preview

              ? h('EditorBlock', {
                ref: 'editorBlock',
                props: {
                  attrs: this.widget.attrs,
                  updateAttrs: this.editor.updateBlockAttributes.bind(this.editor, this.widget.id),
                  selected: this.selected,
                  widget: this.widget,
                  breakpoint: this.editor.breakpoint,
                  required: this.required,
                },
              })

            // Build

              : h('div', {
                class: 'layout-editor__block-inner-build',
              }, [
                h('h4', {
                  class: 'layout-editor__block-inner-build-title',
                }, [
                  this.widgetObject.displayName,
                ]),
              ]),
          ]),

          // Add Block After
          settings?.addBlock === false ? null : h(AddBlockDivider, {
            attrs: {
              addPosition: 'after',
              widget: this.widget,
            },
            class: {
              'layout-editor__add-block--after': 1,
            },
          }),
        ])
      },
    })
  }
}
