import { getUserData } from '@/auth/utils'
import ability from '@/libs/acl/ability'
import { pr } from './config'

// For regEx search in VSCode: canCreateSharedEntriesView|canEditEntriesView|canDeleteEntriesView

// * Create entity file, and can functions
// * Import and add them to allCanFunctions object down below
// * Add defaults into the permissions array down below
// * Implement v-permission directives on neccessary buttons
// * Implement permissions on entities sdk functions
// * Implement permissions on navigation and route

export const EntriesView = Object.freeze({
  MANAGE: 'shared_view_manage',
})

export const EntriesViewTooltips = {
  [EntriesView.MANAGE]: 'Allows a user to perform all CRUD operations in the entry list level such as: create, view, edit and delete.',
}

function isCurrentUser(entity) {
  if (!entity) return true
  const userId = entity?.createdBy?.id || entity?.createdBy
  const currentUserData = getUserData()
  if (!currentUserData) return false

  return userId == currentUserData.id
}

/**
 * _options
 *    entity = undefined
 *    showToast = false
 */

/**
 * Can CREATE EntriesView
 * */
export function canCreateSharedEntriesView(_options = {}) {
  const { entity, ...options } = _options

  return pr(
    ability.can(EntriesView.MANAGE),

    'You have no permission to create shared entries view',

    options,
  )
}

/**
 * Can EDIT EntriesView
 * */
export function canEditEntriesView(_options = {}) {
  const { entity, ...options } = _options

  return pr(
    ability.can(EntriesView.MANAGE)
    || isCurrentUser(entity),

    'You have no permission to edit shared entries view',

    options,
  )
}

/**
 * Can DELETE EntriesView
 * */
export function canDeleteEntriesView(_options = {}) {
  const { entity, ...options } = _options

  return pr(
    ability.can(EntriesView.MANAGE)
    || isCurrentUser(entity),

    'You have no permission to delete shared entries view',

    options,
  )
}
