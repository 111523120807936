import i18n from '@/libs/i18n'
import LabelsFilterComponent from './Labels.vue'

export default class LabelsFilter {
  static COMPONENT = LabelsFilterComponent;

  static OPERATORS = {
    all: 'all',
    some: 'some',
    none: 'none',
    exists: 'exists',
    notExists: 'notExists',
  }

  static type = 'labels'

  static graphqlType = 'StringListFilter'

  operator = null;

  value = null;

  constructor({ operator = LabelsFilter.OPERATORS.some, value = [] } = {}) {
    this.operator = operator
    this.value = value
  }

  set({ operator = LabelsFilter.OPERATORS.some, value = [] }) {
    this.operator = operator
    this.value = value
  }

  getCount() {
    if (this.operator === LabelsFilter.OPERATORS.exists || this.operator === LabelsFilter.OPERATORS.notExists) {
      return 1
    }
    return this.value?.length
  }

  getValueLabel({ cache }) {
    if (this.operator === LabelsFilter.OPERATORS.exists) {
      return i18n.t('filters.is-not-empty')
    }
    if (this.operator === LabelsFilter.OPERATORS.notExists) {
      return i18n.t('filters.is-empty')
    }
    const labels = this.value.map(labelId => cache[labelId] || { id: labelId })
    return labels.map(l => l.name).join(', ')
  }

  toLoad() {
    return {
      labels: this.value,
    }
  }

  asQueryParam() {
    if (this.operator === LabelsFilter.OPERATORS.exists || this.operator === LabelsFilter.OPERATORS.notExists) {
      return {
        operator: this.operator,
      }
    }
    return {
      operator: this.operator,
      value: this.value,
    }
  }

  asGraphQL() {
    switch (this.operator) {
      case LabelsFilter.OPERATORS.all:
        return {
          all: this.value,
        }
      case LabelsFilter.OPERATORS.some:
        return {
          some: this.value,
        }
      case LabelsFilter.OPERATORS.none:
        return {
          none: this.value,
        }
      case LabelsFilter.OPERATORS.exists:
        return {
          exists: true,
        }
      case LabelsFilter.OPERATORS.notExists:
        return {
          exists: false,
        }
      default:
        return null
    }
  }

  asGraphQLDynamicList() {
    if (this.asGraphQL()) {
      return {
        ...this.asGraphQL(),
        _t: LabelsFilter.graphqlType,
      }
    }
    return null
  }

  static validate({ operator, value }) {
    if (!operator) {
      return 'Operator is required'
    }

    if (operator === LabelsFilter.OPERATORS.exists || operator === LabelsFilter.OPERATORS.notExists) {
      return true
    }

    if (!value || value.length === 0) {
      return 'Value is required'
    }

    return true
  }

  static fromQueryParam({ operator, value }) {
    return new LabelsFilter({
      operator,
      value,
    })
  }
}
