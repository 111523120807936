import ability from '@/libs/acl/ability'
import { pr } from './config'

// For regEx search in VSCode: canViewLabel|canCreateLabel|canEditLabel|canDeleteLabel

/**
label_view
label_manage
 */
// * Create entity file, and can functions
// * Import and add them to allCanFunctions object down below
// * Add defaults into the permissions array down below
// Implement v-permission directives on neccessary buttons
// * Implement permissions on entities sdk functions
// * Implement permissions on navigation and route

export const Label = Object.freeze({
  VIEW: 'label_view',
  MANAGE: 'label_manage',
})

export const LabelTooltips = {
  [Label.VIEW]: 'Allows a user to view all settings in ‘view only’ mode, without performing any operations',
  [Label.MANAGE]: 'Allows a user to edit any information in all sections of label settings',
}

/**
 * _options
 *    entity = undefined
 *    showToast = false
 */

/**
 * Can VIEW Settings
 * */
export function canViewLabels(_options = {}) {
  const { entity, ...options } = _options

  return pr(
    ability.can(Label.VIEW) || ability.can(Label.MANAGE),

    'You have no permission to view label settings',

    options,
  )
}

/**
 * Can CREATE Label
 * */
export function canCreateLabel(_options = {}) {
  const { entity, ...options } = _options

  return pr(
    ability.can(Label.MANAGE),

    'You have no permission to create label',

    options,
  )
}

/**
 * Can EDIT Label
 * */
export function canEditLabel(_options = {}) {
  const { entity, ...options } = _options

  return pr(
    ability.can(Label.MANAGE),

    'You have no permission to edit label',

    options,
  )
}

/**
 * Can DELETE Label
 * */
export function canDeleteLabel(_options = {}) {
  const { entity, ...options } = _options

  return pr(
    ability.can(Label.MANAGE),

    'You have no permission to delete this label',

    options,
  )
}
