<template>
  <b-form-group class="merge-author-field">
    <template #label>
      <FieldName
        :name="field.name"
        :help-text="''"
      />
    </template>
    <div
      v-if="sections && sections.length > 0"
      class="entries-card"
    >
      <div class="entries-list">
        <div class="entries-list__draggable">
          <div
            v-for="(section, index) in sections"
            :key="section.id + index"
            class="entries-list__item"
          >
            <div class="entries-list__item-icon">
              <GjIcon
                name="Drag"
                size="24"
              />
              <GjIcon name="Section" />
            </div>
            <div class="entries-list__item-content">
              <div class="entries-list__item-name">
                {{ section.title }}
              </div>
            </div>
            <div class="entries-list__item-main mr-1">
              <template v-if="section.isMain">
                {{ $t('fields.codex-field-sections.render.main-section') }}
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-else
      class="content-list__empty"
    >
      <GjIcon name="Section" />
      <span>{{ $tc('entries.merge-tool.info.no-section-value', field.valueType === VALUE_TYPES.SINGLE ? 1 : 2) }}</span>
    </div>
  </b-form-group>
</template>

<script>
import FieldName from '@/components/fields/FieldName.vue'
import { VALUE_TYPES } from '@/views/models/constants'
import gql from 'graphql-tag'

export default {
  name: 'SectionField',
  components: {
    FieldName,
  },
  props: {
    fieldContent: {
      type: [String, Number, Array, Object],
    },
    field: {
      type: [Object],
    },
  },
  apollo: {
    sectionCollection: {
      query: gql`
        query ($ids: [String!], $limit: Int) {
          sectionCollection (
            where: { id: { in: $ids } }
            limit: $limit
          ) {
            items {
              id
              slug
              title
              url
            }
            total
          }
        }
      `,
      fetchPolicy: 'no-cache',
      variables() {
        return {
          ids: this.sectionIds?.map(s => s.id),
          limit: this.sectionIds?.length,
        }
      },
      result(results) {
        const sections = []
        this.sectionIds.forEach(e => {
          const section = results.data.sectionCollection.items.find(a => a.id === e.id)
          section.isMain = e.isMain
          sections.push(section)
        })
        this.sections = sections
        this.$apollo.queries.sectionCollection.skip = true
        return results
      },
      skip() {
        return !this.sectionIds?.length
      },
    },
  },
  data() {
    return {
      VALUE_TYPES,
      sectionIds: [],
      sections: [],
    }
  },
  watch: {
    fieldContent: {
      handler(v) {
        this.sections = []
        this.sectionIds = []
        if (v) {
          if (this.field.valueType === VALUE_TYPES.SINGLE) {
            this.sectionIds.push(v)
          } else {
            this.sectionIds = v || []
          }
        }
        if (this.$apollo.queries.sectionCollection) {
          this.$apollo.queries.sectionCollection.skip = false
        }
      },
      deep: true,
      immediate: true,
    },
  },
}
</script>

<style lang="scss">
</style>
