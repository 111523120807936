export default {
  ADD_AUTHOR(state, author) {
    state.authors.push(author)
  },
  SET_AUTHORS(state, authors) {
    state.authors = authors
  },
  UPDATE_AUTHOR(state, author) {
    const authorIndex = state.authors.findIndex(p => p.id === author.id)
    if (authorIndex === -1) return
    Object.assign(state.authors[authorIndex], author)
  },
  REMOVE_AUTHOR(state, authorId) {
    const authorIndex = state.authors.findIndex(p => p.id === authorId)
    state.authors.splice(authorIndex, 1)
  },
  REMOVE_AUTHORS(state, authorIds) {
    authorIds.forEach(authorId => {
      const authorIndex = state.authors.findIndex(p => p.id === authorId)
      state.authors.splice(authorIndex, 1)
    })
  },
  SET_AUTHORS_PAGINATION(state, pagination) {
    state.authorsPagination = pagination
  },
  CLEAR_AUTHORS_STATE(state) {
    state.authors = []
  },
}
