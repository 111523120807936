<template>
  <div
    class="new-filters-dropdown__filter-body"
    :class="{'new-filters-dropdown__filter-body--scroll': allAuthors.length > 3 && !isExistsOperator }"
  >
    <b-row v-if="showDropdown">
      <b-col cols="12">
        <vSelect
          v-model="value.operator"
          :options="options"
          :clearable="false"
          :reduce="option => option.value"
          :searchable="false"
          label="label"
        />
      </b-col>
    </b-row>

    <b-row
      v-if="!isExistsOperator"
      align-v="center"
      no-gutters
    >
      <b-col cols="12">
        <b-input
          v-model="search"
          :placeholder="$t('filters.authors.search-placeholder')"
        />
      </b-col>
    </b-row>
    <b-row
      v-if="!isExistsOperator"
      align-v="center"
      no-gutters
    >
      <b-col cols="12">
        <b-checkbox-group
          v-if="allAuthors.length"
          v-model="value.value"
          class="new-filters-dropdown__checkbox-group"
          :options="allAuthors"
          stacked
        />
        <div ref="loadMore" />
        <div
          v-if="$apollo.loading"
          class="notifications__loading-section m-1"
        >
          <b-spinner
            variant="primary"
          />
        </div>
      </b-col>
      <b-col
        v-if="allAuthors.length === 0"
        cols="12"
        class="new-filters-dropdown__checkbox-group-no-result"
      >
        <span>{{ $t('filters.authors.not-found') }}</span>
      </b-col>
    </b-row>

  </div>
</template>

<script>
import gql from 'graphql-tag'
import AuthorListFilter from './AuthorListFilter'

export default {
  props: {
    filter: {
      type: Object,
      required: true,
    },
    filterObject: {
      type: [Object, Function],
      required: true,
    },
    value: {
      type: Object,
      required: true,
    },
    cache: {
      type: Object,
      required: true,
    },
    setCache: {
      type: Function,
      required: true,
    },
  },
  apollo: {
    authors: {
      query: gql`
        query Authors ($search: String, $order: CodexAuthorOrder, $limit: Int, $offset: Int) {
          authorCollection (limit: $limit, offset: $offset where: { byline: { contains: $search }}, order: $order) {
            total
            items {
              id
              email
              firstName
              lastName
              byline
            }
          }
        }
      `,
      fetchPolicy: 'no-cache',
      update(data) {
        this.total = data.authorCollection.total
        this.authors_ = data.authorCollection.items
      },
      variables() {
        return {
          search: this.search,
          limit: this.limit,
          offset: 0,
          order: { ...this.order },
        }
      },
    },
    currentAuthors: {
      query: gql`
        query currentAuthors ($ids: [String!]){
          currentAuthors: authorCollection (where: {id: {in: $ids}}) {
            items {
              id
              email
              firstName
              lastName
              byline
            }
          }
        }
      `,
      fetchPolicy: 'no-cache',
      skip() {
        return this.inputIds.length < 1
      },
      variables() {
        return {
          ids: this.inputIds,
          limit: this.inputIds.length,
        }
      },
      update(results) {
        const items = results.currentAuthors.items.filter(t => this.inputIds.includes(t.id))
        return {
          ...results.currentAuthors,
          items,
        }
      },
    },
  },
  data() {
    return {
      authors_: [],
      search: '',
      order: { byline: 'ASC' },
      total: 0,
      offset: 0,
      limit: 20,
    }
  },
  computed: {
    allAuthors() {
      const currentAuthors = []
      // eslint-disable-next-line no-unused-expressions
      this.currentAuthors?.items?.forEach(author => {
        if (!this.authors_.some(t => t.id === author.id)) {
          currentAuthors.push(author)
        }
      })
      const currents = !this.search ? currentAuthors : []
      return [
        ...currents?.map(author => ({
          value: author.id,
          text: author.byline || [author.firstName, author.lastName].filter(Boolean).join(' '),
        })),
        ...this.authors_?.map(author => ({
          value: author.id,
          text: author.byline || [author.firstName, author.lastName].filter(Boolean).join(' '),
        })),
      ]
    },

    inputIds() {
      return this.value.value || []
    },

    options() {
      return [
        {
          label: this.$t('filters.all'),
          value: AuthorListFilter.OPERATORS.all,
        },
        {
          label: this.$t('filters.some'),
          value: AuthorListFilter.OPERATORS.some,
        },
        {
          label: this.$t('filters.is-not-empty'),
          value: AuthorListFilter.OPERATORS.exists,
        },
        {
          label: this.$t('filters.is-empty'),
          value: AuthorListFilter.OPERATORS.notExists,
        },
      ]
    },

    isExistsOperator() {
      return this.value.operator === AuthorListFilter.OPERATORS.exists || this.value.operator === AuthorListFilter.OPERATORS.notExists
    },

    objectValues() {
      if (!this.value.value) return []

      return this.value.value.map(authorId => this.cache[authorId] || { id: authorId })
    },
    showDropdown() {
      if (this.filter.showDropdown !== undefined) {
        return this.filter.showDropdown
      }
      return true
    },
  },
  watch: {
    'value.value': function (value) {
      value.forEach(authorId => {
        if (!this.cache[authorId]) {
          this.setCache(this.authors_.find(author => author.id === authorId))
        }
      })
    },
  },
  mounted() {
    if (!this.isExistsOperator) {
      this.observer = new IntersectionObserver(this.infiniteScroll)

      this.$nextTick(() => {
        this.observer.observe(this.$refs.loadMore)
      })
    }
  },
  beforeDestroy() {
    this.observer.disconnect()
  },
  methods: {
    async infiniteScroll([{ isIntersecting }]) {
      if (isIntersecting && !this.$apollo.loading && this.limit + this.offset < this.total) {
        await this.$apollo.queries.authors.fetchMore({
          variables: {
            offset: this.offset + this.limit,
          },
          updateQuery: (previousResult, { fetchMoreResult }) => {
            this.loading = false
            this.offset += this.limit
            this.authors_ = [...this.authors_, ...fetchMoreResult.authorCollection.items]
          },
        })
      }
    },
  },
}
</script>
