import { canDeleteAsset, canEditAsset } from '@/codex-permissions/assets'
import { transformTagFromRequest } from '@/codex-sdk/tags'
import i18n from '@/libs/i18n'
import { enumMapping } from '@/utils/helpers'
import { apiAxios as axios } from '../libs/axios'
import { tc } from './config'

// ASSETS

/**
 * Constants
 */
export const ASSET_TYPES = Object.freeze({
  IMAGE: 'IMAGE',
  FILE: 'FILE',
  VIDEO: 'VIDEO',
  VIDEO_PLAYLIST: 'VIDEO_PLAYLIST',
  AUDIO: 'AUDIO',
  PODCAST: 'PODCAST',
})

export const ASSET_TYPE_MAPPING = enumMapping({
  [ASSET_TYPES.IMAGE]: 1,
  [ASSET_TYPES.FILE]: 2,
  [ASSET_TYPES.VIDEO]: 3,
  [ASSET_TYPES.VIDEO_PLAYLIST]: 4,
  [ASSET_TYPES.AUDIO]: 5,
  [ASSET_TYPES.PODCAST]: 6,
})

export const ASSET_STATUSES = Object.freeze({
  ACTIVE: 'ACTIVE',
  DELETED: 'DELETED',
  PROCESSING: 'PROCESSING',
  FAILED: 'FAILED',
})

export function mapAssetTypeKey(status) {
  const statuses = {
    [ASSET_TYPES.IMAGE]: 'assets.asset-types.image',
    [ASSET_TYPES.FILE]: 'assets.asset-types.file',
    [ASSET_TYPES.VIDEO]: 'assets.asset-types.video',
    [ASSET_TYPES.VIDEO_PLAYLIST]: 'assets.asset-types.video_playlist',
    [ASSET_TYPES.AUDIO]: 'assets.asset-types.audio',
    [ASSET_TYPES.PODCAST]: 'assets.asset-types.podcast',
  }
  return statuses[status] || ''
}

export function mapAssetTypeLabel(status) {
  return i18n.t(mapAssetTypeKey(status)) || ''
}

export const TITLE_SUFFIX = Object.freeze({
  NUMBER: 1,
  LETTER: 2,
})

export const IMAGE_EXTENSIONS = ['jpg', 'jpeg', 'jfif', 'png', 'gif', 'webp', 'ico', 'svg']

/**
 * Helpers
 */
export function assetDefaults(asset = {}) {
  return {
    path: false,
    altAttribute: '',
    titleAttribute: '',
    versionId: '',
    width: 0,
    height: 0,
    inserted: false,
    progress: 0,
    ...asset,
  }
}

export function transformCompare(asset) {
  asset.title = asset.title || ''
  asset.caption = asset.caption || ''
  asset.alt = asset.alt || ''
  asset.source = asset.source || ''
  asset.author = asset.author || ''
  asset.copyright = asset.copyright || ''
  asset.tags = asset.tags || []
  asset.expiresAt = asset.expiresAt || null
}

export function assetTypeFromMime(mime) {
  if (mime.includes('image') && !mime.includes('tif')) return ASSET_TYPES.IMAGE

  if (mime.includes('video')) return ASSET_TYPES.VIDEO

  if (mime.includes('playlist')) return ASSET_TYPES.VIDEO_PLAYLIST

  if (mime.includes('audio')) return ASSET_TYPES.AUDIO

  if (mime.includes('podcast')) return ASSET_TYPES.PODCAST

  return ASSET_TYPES.FILE
}

/**
 * Transforms to received data
 */
export function transformAssetFromRequest(asset) {
  asset.type = ASSET_TYPE_MAPPING.toString(asset.type)
  return asset
}

export function transformVideoFromRequest(video) {
  return video
}

/**
 * CRUD Operations
 * */

export function uploadGeneralAsset(asset, messages) {
  const formData = new FormData()
  formData.append('file', asset)
  return tc(axios.post('/uploads', formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  }),
  {
    successTitle: 'sdk.success-title',
    successMessage: 'sdk.asset.upload-successful',
    failTitle: 'sdk.asset.upload-failed',
    ...messages,
  })
}

/**
 *
 * @param {*} asset
 *
 * @response
 * {
 *  id: GUID
 *  errors: null | Object
 *  success: true | false
 * }
 */
export function uploadAsset(asset = {}, messages = undefined) {
  const formData = new FormData()
  formData.append('file', asset.file)

  if (asset.folderId) formData.append('folderId', asset.folderId)
  if (asset.title) formData.append('title', asset.title)
  if (asset.caption) formData.append('caption', asset.caption)
  if (asset.alt) formData.append('alt', asset.alt)
  if (asset.author) formData.append('author', asset.author)
  if (asset.source) formData.append('source', asset.source)
  if (asset.copyright) formData.append('copyright', asset.copyright)
  if (asset.tags?.length) {
    asset.tags.forEach(tag => { formData.append('tags', tag) })
  }
  if (asset.expiresAt) formData.append('expiresAt', asset.expiresAt)

  return tc(axios.post('/{organization}/assets', formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
    onUploadProgress: progressEvent => {
      const { total, loaded } = progressEvent
      asset.progress = Math.round((loaded * 100) / total)
    },
  }).then(response => {
    transformAssetFromRequest(response.data)
    return response
  }),
  {
    successTitle: 'sdk.success-title',
    successMessage: 'sdk.asset.upload-successful',
    failTitle: 'sdk.asset.upload-failed',
    catchError: false,
    ...messages,
  })
}

/**
 *
 * @response
 * [{
 *  id: GUID
 *  parentId: GUID | null
 *  title: String
 *  slug: String
 *  canContainArticle: Bool
 * }, {...}, ...]
 */
export function readAssets({
  page = 1, pageSize = 10,
} = {}) {
  return axios.get('/{organization}/assets/asset', {
    params: {
      Page: page,
      Size: pageSize,
    },
  }).then(response => {
    response.data.data = response.data.data.map(asset => transformAssetFromRequest(asset))
    return response
  })
}

/**
 *
 * @param {*} id
 *
 * @response
 * {
 *  id: GUID
 *  parentId: GUID | null
 *  title: String
 *  slug: String
 *  canContainArticle: Bool
 * }
 */
export function readAsset(id) {
  return axios.get(`/{organization}/assets/${id}`).then(response => {
    response.data = transformAssetFromRequest(response.data)
    return response
  })
}

/**
 *
 * @param {*} asset
 *
 * @response
 * {
 *  id: GUID
 *  errors: null | Object
 *  success: true | false
 * }
 */
export async function updateAsset(asset, messages) {
  const permission = await canEditAsset({ entity: { folderId: asset.folderId, id: asset.id, status: asset?.status } })
  if (!permission.can) return permission

  return tc(axios.put(`/{organization}/assets/${asset.id}`, asset)
    .then(response => {
      transformAssetFromRequest(response.data)
      return response
    }), {
    successTitle: 'sdk.success-title',
    successMessage: 'sdk.asset.update-successful',
    failTitle: 'sdk.asset.update-failed',
    catchError: false,
    ...messages,
  })
}

export function updateAssets(assets, messages) {
  // ToDo: fix permission check
  // return checkPermission('canEditAsset')

  return tc(axios.put('/{organization}/assets', assets).then(response => {
    response.data.forEach(asset => transformAssetFromRequest(asset))
    return response
  }), {
    successTitle: 'sdk.success-title',
    successMessage: 'sdk.asset.update-assets-successful',
    failTitle: 'sdk.asset.update-assets-failed',
    ...messages,
  })
}

export async function deleteAsset(asset, messages = {}) {
  const permission = await canDeleteAsset({ entity: { folderId: asset.folderId } })
  if (!permission.can) return permission

  return tc(axios.delete(`/{organization}/assets/${asset.id}`), {
    successTitle: 'sdk.success-title',
    successMessage: 'sdk.asset.delete-successful',
    failTitle: 'sdk.asset.delete-failed',
    catchError: false,
    ...messages,
  })
}

export function deleteAssets(assets, messages = {}) {
  // ToDo: fix permission check
  // return checkPermission('canDeleteMedia')

  return tc(axios.delete(`/{organization}/assets/${assets.map(asset => asset.id).join(',')}`), {
    successTitle: 'sdk.success-title',
    successMessage: 'sdk.asset.delete-assets-successful',
    failTitle: 'sdk.asset.delete-assets-failed',
    catchError: false,
    ...messages,
  })
}

// VERSIONS
/**
 * @param {String} [assetId] Asset Id
 * @returns {*}
 */
export function readAssetVersions(assetId) {
  return axios.get(`/{organization}/assets/${assetId}/versions`)
}

/**
 * @param {String} [assetId] Asset Id
 * @param {String} [versionId] Asset VersionId
 * @returns {*}
 */
export function readAssetVersion({ assetId, versionId }) {
  return axios.get(`/{organization}/assets/${assetId}/versions/${versionId}`)
}

/**
 * @param {Object} [version] Version
 * @returns {*}
 */
export function updateAssetVersion(version, messages = {}) {
  return tc(axios.put(`/{organization}/assets/${version.entity.id}/versions/${version.id}`, version), {
    successTitle: 'sdk.success-title',
    successMessage: 'sdk.asset.version.update-successful',
    failTitle: 'sdk.asset.version.update-failed',
    ...messages,
  })
}

export function restoreAssetVersion(version, revisionId, messages = {}) {
  return tc(axios.put(`/{organization}/assets/${version.entity.id}/versions/${version.id}/restore`, {}, {
    params: {
      revisionId,
    },
  }), {
    successTitle: 'sdk.success-title',
    successMessage: 'sdk.asset.version.restore-successful',
    failTitle: 'sdk.asset.version.restore-failed',
    ...messages,
  })
}

/**
 *
 * @response
 * [{
 *  id: GUID
 *  name: String
 * }, {...}, ...]
 */
export function readAssetsTags({ search: q, pageSize = 20 } = {}) {
  return axios.get('/{organization}/assets/tags', { params: { q, Size: pageSize } }).then(response => {
    response.data = response.data.map(tag => transformTagFromRequest(tag))
    return response
  })
}

// VP - Videos
export function readVPOrganizations({
  page = 1, size = 10, search = null, connectionId = null,
}) {
  return axios.get('/{organization}/integrations/external/vp/accounts/users/organizations', {
    params: {
      connectionId,
      page,
      take: size,
      search,
    },
  })
}

export function readVPProjects({
  organizationId = null, page = 1, size = 1000, search = null, connectionId = null,
}) {
  return axios.get(`/{organization}/integrations/external/vp/accounts/organizations/${organizationId}/projects`, {
    params: {
      connectionId,
      page,
      take: size,
      search,
    },
  })
}

export function readVPApiKeys(organizationId, connectionId = null) {
  if (!organizationId) throw new Error('OrganizationId is missing')
  return axios.get(`/{organization}/integrations/external/vp/organizations/${organizationId}/api-keys`, {
    params: { connectionId },
  })
}

export function readVPAdSchedules(organizationId = null, connectionId = null) {
  if (!organizationId) throw new Error('OrganizationId is missing')
  return axios.get(`/{organization}/integrations/external/vp/organizations/${organizationId}/ad-schedules/select-list`, {
    params: { connectionId },
  })
}

export function readVPPlayers(organizationId = null, connectionId = null) {
  if (!organizationId) throw new Error('OrganizationId is missing')
  return axios.get(`/{organization}/integrations/external/vp/organizations/${organizationId}/players/select-list-details`, {
    params: { connectionId },
  })
}
export function readVPAudioPlayers(projectId = null, connectionId = null) {
  if (!projectId) throw new Error('OrganizationId is missing')
  return axios.get(`/{organization}/integrations/external/vp-audio/v1/${projectId}/audio-players/select-list`, {
    params: { connectionId },
  })
}

export function readVPPlayer(playerId = null, projectId = null, connectionId = null) {
  if (!projectId || !playerId) throw new Error('OrganizationId is missing')
  return axios.get(`/{organization}/integrations/external/vp/projects/${projectId}/players/${playerId}`, {
    params: { connectionId },
  })
}

export function readVPVideos({
  page = 1, pageSize = 10, query = null, folderId = 0, projectId = null, onlyReadyVideos = false, connectionId = null,
} = {}) {
  return axios.get(`/{organization}/integrations/external/vp/projects/${projectId}/videos`, {
    params: {
      connectionId,
      folderView: true,
      folderId,
      page,
      take: pageSize,
      search: query,
      onlyReadyVideos,
      order: 'desc',
    },
  }).then(response => {
    response.data = transformVideoFromRequest(response.data)
    return response
  })
}

export function readVPVideo(id, projectId, connectionId) {
  return axios.get(`/{organization}/integrations/external/vp/projects/${projectId}/videos/${id}`, {
    params: { connectionId },
  })
    .then(response => {
      response.data = transformVideoFromRequest(response.data)
      return response
    })
}
export function readVPStreams(id, projectId, connectionId) {
  return axios.get(`/{organization}/integrations/external/vp/projects/${projectId}/videos/${id}/stream`, {
    params: { connectionId },
  })
}

export function updateVPVideo(video, projectId, connectionId, messages) {
  return tc(axios.put(`/{organization}/integrations/external/vp/projects/${projectId}/videos`, video, {
    params: { connectionId },
  }), {
    successTitle: 'sdk.success-title',
    successMessage: 'sdk.asset.update-video-successful',
    failTitle: 'sdk.asset.update-video-failed',
    ...messages,
  })
}

// Playlists
export function readVPPlaylists({
  page = 1, pageSize = 10, searchTerm = null, projectId = null, connectionId = null,
}) {
  return axios.get(`/{organization}/integrations/external/vp/projects/${projectId}/playlists`, {
    params: {
      connectionId,
      page,
      take: pageSize,
      order: 'desc',
      search: searchTerm,
    },
  })
}

export function readVPPlaylist({ id = null, projectId = null, connectionId = null }) {
  return axios.get(`/{organization}/integrations/external/vp/projects/${projectId}/playlists/${id}`, {
    params: { connectionId },
  })
}

export function readVPPlaylistVideos({ projectId = null, playlistId = null }) {
  return axios.get(`https://cdn.vpplayer.tech/${projectId}/playlist/${playlistId}`)
}

export function readVPPlaylistVideosAPI({ projectId = null, playlistId = null, connectionId = null }) {
  return axios.get(`/{organization}/integrations/external/vp/projects/${projectId}/playlists/${playlistId}/videos`, {
    params: { connectionId },
  })
}

// VP Folders
export function readVPFolders({ folderId = 0, projectId = null, connectionId = null }) {
  return axios.get(`/{organization}/integrations/external/vp/projects/${projectId}/folders/${folderId}/children`, {
    params: { connectionId },
  })
}

export function readVPFolder({ folderId = 0, projectId = null, connectionId = null }) {
  return axios.get(`/{organization}/integrations/external/vp/projects/${projectId}/folders/${folderId}`, {
    params: { connectionId },
  })
}

export function createVPApiKey(apiKey, organizationId, messages) {
  return tc(axios.post(`/{organization}/integrations/external/vp/organizations/${organizationId}/api-keys`, apiKey), {
    successTitle: 'sdk.success-title',
    successMessage: 'sdk.asset.create-api-key-successful',
    failTitle: 'sdk.asset.create-api-key-failed',
    catchError: false,
    ...messages,
  })
}
