<template>
  <div
    class="new-filters-dropdown__filter-body"
    :class="{'new-filters-dropdown__filter-body--scroll': labels && labels.items && labels.items.length > 3 && !isExistsOperator }"
  >
    <b-row>
      <b-col cols="12">
        <vSelect
          v-model="value.operator"
          :options="options"
          :clearable="false"
          :reduce="option => option.value"
          :searchable="false"
          label="label"
        />
      </b-col>
    </b-row>

    <b-row
      v-if="!isExistsOperator"
      align-v="center"
      no-gutters
    >
      <b-col cols="12">
        <b-form-input
          v-model="search"
          type="text"
          :placeholder="$t('filters.labels.search-placeholder')"
        />
      </b-col>
    </b-row>

    <b-row
      v-if="!isExistsOperator"
      align-v="center"
      no-gutters
      class="filters-label__container"
    >
      <b-col
        v-if="labels && labels.items && labels.items.length"
        cols="12"
      >
        <div
          v-for="label in allLabels"
          :key="label.id"
          class="new-filters-dropdown__media-item filter-items"
          @click="addValue(label)"
        >
          <div
            :style="`background-color: ${label.color.length > 15 ? '#a9a9a9' : label.color};`"
            class="mr-1 filter-item__color"
          />
          <h4 class="new-filters-dropdown__media-item-title">
            {{ label.name }}
          </h4>
          <div class="new-filters-dropdown__media-item-remove">
            <GjIcon
              v-if="value.value && value.value.find(item => item === label.id)"
              name="Check"
              size="18"
              style="color:#052D61; margin-right: 2px; margin-left: 2px; flex-grow: 1;"
            />
          </div>
        </div>
      </b-col>
      <b-col
        v-else
        cols="12"
        class="new-filters-dropdown__checkbox-group-no-result"
      >
        <span>{{ $t('general.filters-dropdown.section-filter.no-section-found') }}</span>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import gql from 'graphql-tag'
import LabelsFilter from './LabelsFilter'

export default {
  name: 'Labels',
  props: {
    filter: {
      type: Object,
      required: true,
    },
    filterObject: {
      type: [Object, Function],
      required: true,
    },
    value: {
      type: Object,
      required: true,
    },
    cache: {
      type: Object,
      required: true,
    },
    setCache: {
      type: Function,
      required: true,
    },
  },
  apollo: {
    labels: {
      query: gql`
        query Labels ($entities: [CodexLabelEntity!], $ids: [String!]) {
          labels: labelCollection(limit: 10000, where: {
            entities: {
              some: $entities
            }
            id: {
              in: $ids
            }
          }) {
            items {
              id
              name
              color
            }
          }
        }
      `,
      variables() {
        return {
          entities: this.filter.entities,
          ids: this.filter.labels,
        }
      },
    },
  },
  data() {
    return {
      search: '',
    }
  },
  computed: {
    options() {
      return [
        {
          label: this.$t('filters.all'),
          value: LabelsFilter.OPERATORS.all,
        },
        {
          label: this.$t('filters.some'),
          value: LabelsFilter.OPERATORS.some,
        },
        {
          label: this.$t('filters.none'),
          value: LabelsFilter.OPERATORS.none,
        },
        {
          label: this.$t('filters.is-not-empty'),
          value: LabelsFilter.OPERATORS.exists,
        },
        {
          label: this.$t('filters.is-empty'),
          value: LabelsFilter.OPERATORS.notExists,
        },
      ]
    },

    allLabels() {
      return this.labels?.items?.filter(l => l.name.toLowerCase()?.includes(this.search.toLowerCase())) || []
    },
    objectValues() {
      if (!this.value.value) return []

      return this.value.value.map(labelId => this.cache[labelId] || { id: labelId })
    },
    isExistsOperator() {
      return this.value.operator === LabelsFilter.OPERATORS.exists || this.value.operator === LabelsFilter.OPERATORS.notExists
    },
  },
  methods: {
    addValue(label) {
      const find = this.value.value.find(item => item === label.id)
      if (find) {
        this.value.value = this.value.value.filter(item => item !== label.id)
      } else {
        this.value.value.push(label.id)
        this.setCache(label)
      }
      this.search = ''
    },
  },
  'value.value': function (value) {
    value.forEach(labelId => {
      if (!this.cache[labelId]) {
        this.setCache(this.labels.find(label => label.id === labelId))
      }
    })
  },
}
</script>
<style lang="scss">
.filter-items {
  height: 32px !important;
  cursor: pointer;
}
.filter-item__color {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  flex-shrink: 0;
}
</style>
