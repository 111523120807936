<template>
  <div
    class="new-filters-dropdown__filter-body"
    :class="{'new-filters-dropdown__filter-body--scroll': allSections && allSections.items.length > 3 && !isExistsOperator }"
  >

    <b-row>
      <b-col cols="12">
        <vSelect
          v-model="value.operator"
          :options="options"
          :clearable="false"
          :reduce="option => option.value"
          :searchable="false"
          label="label"
        />
      </b-col>
    </b-row>

    <b-row v-if="showIncludeChildrenOption">
      <b-col
        class="d-flex align-items-center section-filter__include-children"
        cols="12"
      >
        <b-checkbox
          v-model="includeChildren"
          class="section-filter__include-children-checkbox"
        >
          {{ $t('filters.sections.include') }}
        </b-checkbox>
        <GjIcon
          v-b-tooltip.hover="$t('filters.sections.info')"
          class="section-filter__info-icon"
          size="20"
          name="Info"
        />
      </b-col>
    </b-row>

    <b-row
      v-if="!isExistsOperator"
      align-v="center"
      no-gutters
    >
      <b-col cols="12">
        <b-input
          v-model="search"
          :placeholder="$t('general.filters-dropdown.section-filter.search-section')"
        />
      </b-col>
    </b-row>

    <b-row
      v-if="!isExistsOperator"
      align-v="center"
      no-gutters
    >
      <b-col
        v-if="allSections && allSections.items.length"
        cols="12"
      >
        <div
          v-for="section in allSections.items"
          :key="section.id"
        >
          <RecursiveSection
            :depth="depth "
            :items="section"
            :search="search"
            :include-children="includeChildren"
            :checkbox="true"
          />
        </div>
      </b-col>
      <b-col
        v-else
        cols="12"
        class="new-filters-dropdown__checkbox-group-no-result"
      >
        <span>{{ $t('general.filters-dropdown.section-filter.no-section-found') }}</span>
      </b-col>
    </b-row>

  </div>
</template>

<script>
import gql from 'graphql-tag'
import RecursiveSection from '@/components/fields/Section/Recursive-section.vue'
import { flattenSections } from '@/codex-sdk/sections'
import SectionFilter from './SectionFilter'

export default {
  components: { RecursiveSection },
  props: {
    filter: {
      type: Object,
      required: true,
    },
    filterObject: {
      type: [Object, Function],
      required: true,
    },
    value: {
      type: Object,
      required: true,
    },
    cache: {
      type: Object,
      required: true,
    },
    setCache: {
      type: Function,
      required: true,
    },
  },
  apollo: {
    allSections: {
      query: gql`
        fragment Section on CodexSection {
          id
          title
          parentId
        }
        query Sections ($siteId: String, $query: String, $searchLimit: Int, $limit: Int) {
          searchResults:sectionCollection (where: { siteId: {eq: $siteId }, title: { contains: $query } }, limit: $searchLimit) {
            total
            items {
              ...Section
                    path {
                        id
                        parentId
                        title
                    }
                  children {
                items {
                  ...Section
                  children {
                    items {
                      ...Section
                      children {
                        items {
                          ...Section
                          children {
                            items {
                              ...Section
                              children {
                                items {
                                  ...Section
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          sectionCollection (where: { siteId: {eq: $siteId }, parentId: { exists: false } }, limit: $limit) {
            total
            items {
              ...Section
              children {
                items {
                  ...Section
                  children {
                    items {
                      ...Section
                      children {
                        items {
                          ...Section
                          children {
                            items {
                              ...Section
                              children {
                                items {
                                  ...Section
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `,
      variables() {
        return {
          searchLimit: this.search ? 1000 : 0,
          limit: this.search ? 0 : 1000,
          siteId: this.$route.params.siteName,
          query: this.search,
        }
      },
      fetchPolicy: 'network-only',
      update(data) {
        if (data.sectionCollection?.items?.length > 0) {
          this.flatSections = flattenSections(data.sectionCollection.items)
        }
        this.isSearch = !(data.sectionCollection?.items?.length > 0)
        return data.sectionCollection?.items?.length > 0 ? data.sectionCollection : data.searchResults
      },
    },
  },
  data() {
    return {
      depth: 0,
      search: '',
      flatSections: {},
    }
  },
  provide() {
    return {
      addOrRemoveSection: this.addOrRemoveSection,
      sections: () => (this.value.value ? this.value.value : []),
    }
  },
  computed: {
    options() {
      return [
        {
          label: this.$t('filters.include'),
          value: SectionFilter.OPERATORS.include,
        },
        {
          label: this.$t('filters.exclude'),
          value: SectionFilter.OPERATORS.exclude,
        },
        {
          label: this.$t('filters.is-not-empty'),
          value: SectionFilter.OPERATORS.exists,
        },
        {
          label: this.$t('filters.is-empty'),
          value: SectionFilter.OPERATORS.notExists,
        },
      ]
    },

    includeChildren: {
      get() {
        return this.value.includeChildren != false
      },
      set(value) {
        this.value.includeChildren = value
      },
    },

    isExistsOperator() {
      return this.value.operator === SectionFilter.OPERATORS.exists || this.value.operator === SectionFilter.OPERATORS.notExists
    },

    showIncludeChildrenOption() {
      return this.value.operator === SectionFilter.OPERATORS.include
    },
  },
  watch: {
    'value.value': function (value) {
      value.forEach(sectionId => {
        if (!this.cache[sectionId]) {
          this.setCache(this.flatSections[sectionId])
        }
      })
    },
  },
  methods: {
    addOrRemoveSection(item) {
      if (!this.value.value) {
        this.value.value = []
      }
      const index = this.value.value.indexOf(item.id)
      if (index === -1) {
        this.value.value.push(item.id)
      } else {
        this.value.value.splice(index, 1)
      }
    },
  },
}
</script>

<style>

</style>
