import i18n from '@/libs/i18n'
import SchedulesFilterComponent from './ScheduledVersions.vue'

export default class ScheduledVersionsFilter {
    static COMPONENT = SchedulesFilterComponent;

    static OPERATORS = {
      equals: 'eq',
      notEquals: 'ne',
      exists: 'exists',
      notExists: 'notExists',
    }

    static type = 'scheduledVersions'

    operator = null;

    value = null;

    constructor({ operator = null, value } = {}) {
      this.operator = operator

      if (value !== undefined) {
        this.value = String(value) == 'true' // Handle cases where value is a string
      }
    }

    set({ operator = '', value }) {
      this.operator = operator
      this.value = String(value) == 'true' // Handle cases where value is a string
    }

    getCount() {
      return 1
    }

    getValueLabel() {
      switch (this.operator) {
        case ScheduledVersionsFilter.OPERATORS.equals:
          return this.value ? 'is true' : 'is false'
        case ScheduledVersionsFilter.OPERATORS.notEquals:
          return this.value ? 'is not true' : 'is not false'
        case ScheduledVersionsFilter.OPERATORS.exists:
          return i18n.t('filters.any')
        case ScheduledVersionsFilter.OPERATORS.notExists:
          return i18n.t('filters.none')
        default:
          return null
      }
    }

    asQueryParam() {
      if (this.operator === ScheduledVersionsFilter.OPERATORS.exists || this.operator === ScheduledVersionsFilter.OPERATORS.notExists) {
        return {
          operator: this.operator,
        }
      }
      return {
        operator: this.operator,
        value: this.value,
      }
    }

    asGraphQL() {
      switch (this.operator) {
        case ScheduledVersionsFilter.OPERATORS.equals:
          return {
            eq: Boolean(this.value),
          }
        case ScheduledVersionsFilter.OPERATORS.notEquals:
          return {
            ne: Boolean(this.value),
          }
        case ScheduledVersionsFilter.OPERATORS.exists:
          return {
            exists: true,
          }
        case ScheduledVersionsFilter.OPERATORS.notExists:
          return {
            exists: false,
          }
        default:
          return null
      }
    }

    static validate({ operator, value }) {
      if (!operator) {
        return 'Operator is required'
      }

      if (operator === ScheduledVersionsFilter.OPERATORS.exists || operator === ScheduledVersionsFilter.OPERATORS.notExists) {
        return true
      }

      if (!value && value !== false) {
        return 'Value is required'
      }

      return true
    }

    static fromQueryParam({ operator, value }) {
      return new ScheduledVersionsFilter({ operator, value })
    }

    static shouldApplyPredefinedValue(filter) {
      const forOperators = [ScheduledVersionsFilter.OPERATORS.equals]
      return forOperators.includes(filter.operator)
    }
}
