<template>
  <b-container
    :id="container.attrs._id ? container.attrs._id : null"
    :class="generateContainerClass(container)"
    :tag="container.attrs.tag"
    fluid
  >
    <Row
      v-for="(row, index) in rows"
      :key="row.id"
      :row="row"
      :container-index="containerIndex"
      :row-index="index"
    />
  </b-container>
</template>

<script>
import Row from './Row.vue'

export default {
  name: 'CodexContainer',
  components: {
    Row,
  },
  inject: ['generateDisplayClasses'],
  props: {
    container: Object,
    containerIndex: Number,
    items: Array,
  },
  computed: {
    rows() {
      return this.items.filter(block => block.type === 'codex_row')
    },
  },
  methods: {
    generateContainerClass(container) {
      const customClass = container.attrs._class
      const displayClasses = this.generateDisplayClasses(container, 'container')

      return [
        'd-flex flex-column',
        customClass,
        ...displayClasses,
      ].join(' ')
    },
  },
}
</script>
