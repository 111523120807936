<template>
  <b-sidebar
    id="edit-media-sidebar"
    ref="sidebar"
    v-model="showPopup"
    :title="$t('assets.edit-popup.title')"
    class="c-sidebar c-sidebar--sm"
    right
    shadow
    backdrop
    backdrop-variant="transparent"
    @change="visibilityChange"
  >
    <template #header-close>
      <GjIcon
        name="Close"
        @click.native.stop="handleCancel"
      />
    </template>
    <div>
      <AssetsMetadataFields
        v-model="currentAsset"
        :required-metadata="requiredMetadata"
        :disabled="disabled"
      />
    </div>
    <template #footer>
      <b-button
        id="at-edit-media-sidebar-cancel-btn"
        block
        variant="outline-secondary"
        @click="handleCancel"
      >
        {{ $t('assets.edit-popup.cancel') }}
      </b-button>
      <div
        v-b-tooltip="{ title: $t('assets.asset-processing-tooltip'), interactive: false, disabled: !disabled }"
        class="w-100"
      >
        <b-button
          id="at-edit-media-sidebar-create-btn"
          block
          variant="primary"
          :disabled="disabled"
          @click="handleOk"
        >
          {{ $t('assets.edit-popup.save') }}
        </b-button>
      </div>
    </template>
  </b-sidebar>
</template>

<script>
import PopupsMixin from '@/components/popups/PopupsMixin'
import { ASSET_STATUSES, transformCompare, updateAsset } from '@/codex-sdk/assets'
import AssetsMetadataFields from '@/components/assets/AssetsMetadataFields.vue'
import gql from 'graphql-tag'
import { cloneDeep, isEqual, omit } from 'lodash'

export default {
  name: 'EditAssetPopup',
  components: {
    AssetsMetadataFields,
  },
  inject: ['showConfirmPopup'],
  mixins: [PopupsMixin],
  apollo: {
    asset: {
      query: gql`
        query ($id: String!) {
          asset(id: $id) {
            id
            __typename
            alt
            author
            caption
            type
            contentType
            externalId
            fileName
            folderId
            height
            labels {
              id
              name
              color
            }
            id
            createdAt
            createdBy {
              id
              imageUrl
              firstName
              lastName
            }
            path
            url (transformation: {width: 1500, resize: FILL})
            originalUrl: url
            size
            source
            tags
            title
            copyright
            expiresAt
            customParameters
            updatedAt
            updatedBy {
              id
              imageUrl
              firstName
              lastName
            }
            width
            duration
            status
          }
        }
      `,
      variables() {
        return {
          id: this.currentAssetId,
        }
      },
      result(results) {
        this.currentAsset = cloneDeep(results.data.asset)
        this.oldAssetValue = cloneDeep(results.data.asset)
        return results
      },
      fetchPolicy: 'no-cache',
    },
  },
  data() {
    return {
      hasUnsavedChanges: false,
      currentAsset: {},
      oldAssetValue: {},
    }
  },
  computed: {
    currentAssetId() {
      return this.data.id
    },
    requiredMetadata() {
      return this.data.required || {}
    },
    disabled() {
      return this.currentAsset.status === ASSET_STATUSES.PROCESSING
    },
  },
  watch: {
    currentAsset: {
      handler(value) {
        const toOmit = ['createdBy', 'updatedBy']
        const first = omit(JSON.parse(JSON.stringify(value)), toOmit)
        const second = omit(JSON.parse(JSON.stringify(this.oldAssetValue)), toOmit)
        transformCompare(first)
        transformCompare(second)
        this.hasUnsavedChanges = !isEqual(first, second)
      },
      deep: true,
    },
    hasUnsavedChanges(newVal) {
      this.canClose = !newVal
    },
  },
  methods: {
    async visibilityChange(newVal) {
      if (!newVal && this.hasUnsavedChanges) {
        // Keep sidebar open
        this.showPopup = true
        this.$refs.sidebar.localShow = true

        const answer = await this.showConfirmPopup({})
        if (answer) {
          this.canClose = true
          this.closePopup()
        }
      }
    },
    async handleCancel() {
      if (this.hasUnsavedChanges) {
        const answer = await this.showConfirmPopup({})
        if (answer) {
          this.closePopup()
        }
      } else {
        this.closePopup()
      }
    },
    async handleOk() {
      try {
        if (this.disabled) return
        const { data } = await updateAsset(this.currentAsset)
        if (data) {
          setTimeout(async () => {
            await this.$apollo.queries.asset.refetch()
            this.closePopup(this.currentAsset)
          }, 1000)
        }
      } catch (error) {
        console.error(error)
      }
    },
  },
}
</script>

<style lang="scss">
.label__sidebar {
  header.b-sidebar-header {
    display: flex;
    flex-direction: row-reverse;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #052D61;
    padding: 12px 16px;

    .close {
      margin: 0 0 0 auto !important;
    }
  }
  .b-sidebar-body {
    background-color: white;
    padding: 16px;
  }
  .b-sidebar-footer {
    background-color: white;
    display: flex;
    justify-content: flex-end;
    padding:  16px;
    border-top: 1px solid var(--border-color);
  }

}
</style>
