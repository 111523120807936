<template>
  <b-row
    v-if="viewRow"
    :id="row.attrs._id ? row.attrs._id : null"
    :class="generateRowClass(row)"
  >
    <Col
      v-for="(column, index) in columns"
      :key="column.id"
      :column="column"
      :row-index="rowIndex"
      :column-index="index"
    />
  </b-row>
</template>

<script>
import { getBlocks } from '@/views/models/constants'
import Col from './Col.vue'

export default {
  name: 'CodexRow',
  components: {
    Col,
  },
  props: {
    row: Object,
    rowIndex: Number,
  },
  inject: ['generateDisplayClasses', 'fieldPermissions'],
  computed: {
    columns() {
      return this.row.content.filter(block => block.type === 'codex_column')
    },
    viewRow() {
      const blocks = getBlocks(this.row.content, e => e.isField)
      // eslint-disable-next-line no-unused-vars
      const viewableFields = Object.entries(this.fieldPermissions()?.viewableFields || {}).filter(([key, value]) => value).map(([key, value]) => key)
      const viewableBlocks = blocks.filter(block => viewableFields.includes(block.attrs.alias))
      return viewableBlocks.length > 0
    },
  },
  methods: {
    generateRowClass(row) {
      const customClass = row.attrs._class
      const displayClasses = this.generateDisplayClasses(row, 'row')

      return [
        customClass,
        ...displayClasses,
        'codex-entry___row',
      ].join(' ')
    },
  },
}
</script>
