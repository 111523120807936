<template>
  <b-sidebar
    id="at-entry-view-popup"
    v-model="showPopup"
    class="c-sidebar c-sidebar--md"
    :title="entryView.id ? $t('popups.entry-view-popup.update') : $t('popups.entry-view-popup.new')"
    :no-close-on-route-change="true"
    right
    shadow
    backdrop
    @ok="proceed"
    @cancel="cancel"
  >
    <div class="entry-view-popup__info">
      <span class="entry-view-popup__title">{{ $t('popups.entry-view-popup.save') }} </span>
      <span class="entry-view-popup__subtitle"> {{ $t('popups.entry-view-popup.subtitle') }} </span>
    </div>
    <b-form-group
      :label="$t('popups.entry-view-popup.name-label')"
      label-for="at-entry-view-name-input"
      class="mt-1"
    >
      <b-form-input
        id="at-entry-view-name-input"
        v-model.trim="entryView.name"
        :placeholder="$t('popups.entry-view-popup.name-placeholder')"
        :state="error"
        trim
        autocomplete="off"
        @focus="error = null"
      />
      <b-form-invalid-feedback>
        {{ $t('popups.entry-view-popup.name-error-message') }}
      </b-form-invalid-feedback>
    </b-form-group>
    <b-form-group
      :label="$t('popups.entry-view-popup.path-label')"
      label-for="at-entry-view-path-input"
      class="mt-1"
    >
      <v-select
        id="at-entry-view-path-input"
        v-model="entryView.path"
        :options="existingPaths"
        placeholder="/"
        taggable
      />
    </b-form-group>
    <b-form-group
      :label="$t('popups.entry-view-popup.share-label')"
      label-for="at-entry-view-share-with-select"
      class="mt-1"
    >
      <b-dropdown
        id="at-entry-view-share-with-select"
        size="sm"
        variant="none"
        :disabled="!canChangeType"
      >
        <template #button-content>
          <GjIcon
            :key="entryView.type"
            :name="entryView.type === VIEW_TYPE.SHARED ? 'AccountProfileUsersGroup' : 'Lock_fill'"
            size="20"
          />
          <span>
            {{ entryView.type === VIEW_TYPE.SHARED ? $t('popups.entry-view-popup.share-teams') : $t('popups.entry-view-popup.share-only-me') }}
          </span>
          <GjIcon
            v-if="canChangeType"
            :key="entryView.id"
            name="ArrowDown"
            size="20"
          />
        </template>
        <b-dropdown-item @click="entryView.type = VIEW_TYPE.PRIVATE">
          {{ $t('popups.entry-view-popup.share-only-me') }}
        </b-dropdown-item>
        <b-dropdown-item
          v-permission="['canEditEntriesView', entryView]"
          @click="entryView.type = VIEW_TYPE.SHARED"
        >
          {{ $t('popups.entry-view-popup.share-teams') }}
        </b-dropdown-item>
      </b-dropdown>
    </b-form-group>
    <b-form-group
      v-if="entryView.type === VIEW_TYPE.SHARED"
      :label="$t('popups.entry-view-popup.choose-teams-label')"
      label-for="at-entry-view-teams-select"
      class="mt-1"
    >
      <TeamsSelector
        ref="at-entry-view-teams-select"
        v-model="entryView.teams"
        :multiple="true"
        :close-on-select="false"
        deselect-from-dropdown
        :disable="!canCreateSharedEntriesView().can"
        :classes="'teams-select-entry-view'"
      />
    </b-form-group>
    <hr style="margin-top: 2rem;margin-bottom: 2rem">
    <b-form-group
      v-if="createdByUser"
      :label="$t('popups.entry-view-popup.created-label')"
      label-for="at-entry-view-created-by"
    >
      <div style="display:flex; align-items: center; gap: 8px; color: #667C99;">
        <b-avatar
          :src="createdByUser.imageUrl"
          variant="default"
          :text="createdByUser.fullName[0]"
        />
        <span class="entity-info__user">
          {{ createdByUser.fullName }}
        </span>
        <GjIcon
          name="RadioButtonCircleDisabledFilled"
          size="7"
        />
        <span>
          {{ entryView.createdAt | formatDateTime }}
        </span>
      </div>
    </b-form-group>
    <b-form-group
      v-if="updatedByUser"
      :label="$t('popups.entry-view-popup.updated-label')"
      label-for="at-entry-view-updated-by"
    >
      <div
        v-if="updatedByUser"
        style="display:flex; align-items: center; gap: 8px; color: #667C99;"
      >
        <b-avatar
          :src="updatedByUser.imageUrl"
          variant="default"
          :text="updatedByUser.fullName[0]"
        />
        <span class="entity-info__user">
          {{ updatedByUser.fullName }}
        </span>
        <GjIcon
          name="RadioButtonCircleDisabledFilled"
          size="7"
        />
        <span>
          {{ entryView.updatedAt | formatDateTime }}
        </span>
      </div>
    </b-form-group>
    <template #footer>
      <b-button
        variant="outline-secondary"
        @click="cancel"
      >
        {{ $t('general.cancel') }}
      </b-button>
      <b-button
        variant="primary"
        @click="proceed"
      >
        {{ $t('general.save') }}
      </b-button>
    </template>
  </b-sidebar>
</template>

<script>
import {
  createEntriesView, updateEntriesView, VIEW_TYPE, entriesViewDefaults, readEntriesView,
} from '@/codex-sdk/entries-views'
import TeamsSelector from '@/components/TeamsSelector.vue'
import { canCreateSharedEntriesView, canEditEntriesView } from '@/codex-permissions/entries-views'
import { getCurrentSiteId } from '@/services/siteService'
import { getUserData } from '@/auth/utils'
import gql from 'graphql-tag'
import PopupsMixin from '../PopupsMixin'

export default {
  name: 'EntriesViewPopup',
  components: {
    TeamsSelector,
  },
  mixins: [PopupsMixin],
  data() {
    return {
      canCreateSharedEntriesView,
      VIEW_TYPE,
      TeamsSelector,
      error: null,
      entryView: { ...entriesViewDefaults(), ...this.data?.entryView },
    }
  },
  computed: {
    createdByUser() {
      if (this.entryView?.createdBy) {
        return {
          ...this.entryView.createdBy,
          fullName: `${this.entryView.createdBy.firstName || ''} ${this.entryView.createdBy.lastName || ''}`.trim(),
        }
      }
      return null
    },
    updatedByUser() {
      if (this.entryView?.updatedBy) {
        return {
          ...this.entryView.updatedBy,
          fullName: `${this.entryView.updatedBy.firstName || ''} ${this.entryView.updatedBy.lastName || ''}`.trim(),
        }
      }
      return null
    },
    canChangeType() {
      return (canEditEntriesView().can && this.entryView.type === VIEW_TYPE.SHARED) || (this.entryView.type === VIEW_TYPE.PRIVATE && canCreateSharedEntriesView().can)
    },
    existingPaths() {
      const viewsFolders = this.$store.state.general.views.filter(view => view.siteId === getCurrentSiteId()) || []
      const paths = viewsFolders?.map(v => v.path)?.filter(p => p)
      return [...new Set(paths)]
    },
  },
  mounted() {
    if (this.entryView?.id) {
      this.fetchViewFromAPI()
    }
  },
  methods: {
    async fetchViewFromAPI() {
      const { data } = await readEntriesView(this.entryView.id)
      this.$set(this.entryView, 'customizations', data.customizations)
      if (data.type === VIEW_TYPE.SHARED) {
        this.$set(this.entryView, 'teams', data.teams)
        this.$nextTick(() => {
          this.$refs['at-entry-view-teams-select'].fetchTeam()
        })
      }
    },
    async proceed(e) {
      this.canClose = false
      try {
        if (this.entryView.name === '') {
          e.preventDefault()
          this.error = false
          return
        }

        if (this.entryView.id) {
          const { data } = await updateEntriesView(this.entryView)
          const users = await this.$apollo.query({
            query: gql`
          query Users($users: [String!]!){
            userCollection(where: { id: { in: $users  } }) {
                items {
                   id
                   firstName
                   lastName
                   imageUrl
                }
            }
          }
        `,
            variables: {
              users: [data.createdBy, data.updatedBy],
            },
          })
          data.createdBy = users.data.userCollection.items[0]
          data.updatedBy = users.data.userCollection.items.length > 1 ? users.data.userCollection.items[1] : users.data.userCollection.items[0]
          this.closePopup(data)
        } else {
          const { data } = await createEntriesView(this.entryView)
          const users = await this.$apollo.query({
            query: gql`
          query Users($users: [String!]!){
            userCollection(where: { id: { in: $users  } }) {
                items {
                   id
                   firstName
                   lastName
                   imageUrl
                }
            }
          }
        `,
            variables: {
              users: [getUserData().id],
            },
          })
          data.createdBy = users.data.userCollection.items[0]
          this.closePopup(data)
        }
      } catch (error) {
        console.log(error)
      }
    },
    cancel() {
      this.closePopup(null)
    },
  },
}
</script>
<style lang="scss">
  #at-entry-view-popup {
    .btn-outline-secondary {
      border: none !important;
      color: #052D61 !important;
    }
    .entry-view-popup__info {
      display: flex;
      flex-direction: column;
      gap: 10px;
      .entry-view-popup__title {
        font-weight: 500;
        font-size: 18px;
        line-height: 18px;
        color: #052D61;
      }
      .entry-view-popup__subtitle {
        font-weight: 400;
        font-size: 14px;
        line-height: 150%;
        color: #052D61;
      }
    }
  }
</style>
