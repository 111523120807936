<template>
  <div class="codex-warning__popup">
    <GjIcon :name="icon" />
    <p class="warning-popup__text">
      {{ warning }}
    </p>
    <button
      class="warning-popup__button"
      @click="$emit('close')"
    >
      <GjIcon name="Close" />
    </button>
  </div>
</template>

<script>
export default {
  name: 'WarningPopup',
  props: {
    warning: {
      type: String,
      default: 'We are giving you a warning',
    },
    icon: {
      type: String,
      default: 'InfoAlt',
    },
  },
}
</script>

<style lang="scss" scoped>
.codex-warning__popup{
  box-sizing: border-box;
  display: flex;
  z-index: 100;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 12px 10px 10px;
  gap: 14px;
  width: 452px;
  background: #FFFFFF;
  border-left: 4px solid #FFAB00;
  box-shadow: 0 4px 26px rgba(17, 21, 56, 0.1);
  border-radius: 4px;
  pointer-events: all;

  .warning-popup__text {
    margin: auto;
    font-size: 12px;
  }
  .warning-popup__button {
    background: transparent;
    border: none;
    outline: none;
    border-radius: 50%;
    font-weight: 600;
    margin-right: 8px;
    color: #052D61;
    cursor: pointer;
  }
}
</style>
