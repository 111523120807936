<template>
  <b-modal
    id="assets-popup"
    v-model="showPopup"
    ok-variant="primary"
    cancel-variant="outline-dark"
    size="xl"
    no-close-on-backdrop
    :no-close-on-esc="noCloseOnEsc"
    :static="true"
    @ok="closePopup(selectedAssets)"
    @close="closePopup()"
  >
    <div class="assets-popup__content">
      <div
        class="assets-popup__tree"
        :class="{'organization-tree--open': isSidebarOpen}"
      >
        <OrganizationTree
          style="padding: 0"
          :is-popup="true"
          @close-sidebar="isSidebarOpen = false"
        />
      </div>
      <div class="assets-popup__images">
        <AssetsListing
          :is-popup="true"
          :disable-upload="disableUpload"
          :initial-filters="data && data.initialFilters ? data.initialFilters : {}"
          :labels="data && data.labels"
          @openTree="isSidebarOpen = true"
          @closePopup="closePopup()"
          @insertAsset="closePopup(selectedAssets)"
        />
      </div>
    </div>
  </b-modal>
</template>

<script>
import OrganizationTree from '@/views/assets/components/folders/OrganizationTree.vue'
import PopupsMixin from '@/components/popups/PopupsMixin'
import AssetsListing from '@/components/assets/listings/AssetsListing.vue'
import { mapMutations, mapState } from 'vuex'
import { isNumber } from 'lodash'
import { ASSET_STATUSES, ASSET_TYPES } from '@/codex-sdk/assets'
import gql from 'graphql-tag'

export default {
  name: 'AssetPopup',
  components: {
    AssetsListing,
    OrganizationTree,
  },
  mixins: [PopupsMixin],
  apollo: {
    assetCollection: {
      query: gql`
        query ($ids: [String!], $limit: Int) {
         assetCollection (limit: $limit, where: {
            id: { in: $ids}
         }) {
            items {
              id
              folderId
              type
              title
              contentType
              createdAt
              expiresAt
              size
              url(transformation: {width: 200,height: 200,quality: 60, resize: FILL, format: THUMBNAIL})
              fileName
              caption
              alt
              source
              author
              copyright
              tags
              expiresAt
              attrs
              width
              height
              labels {
                id
              }
            }
            limit
            offset
            total
            totalSize
          }
        }
      `,
      fetchPolicy: 'no-cache',
      variables() {
        return {
          ids: this.ids,
          limit: Number(this.ids?.length || 0),
        }
      },
      update(results) {
        const assets = results.assetCollection.items || []

        if (this.ids) {
          this.ids.forEach(id => {
            const image = assets.find(img => img.id == id) || { id, status: ASSET_STATUSES.DELETED }
            this.selectedAssets.push(image)
          })
          this.selectedData.total = this.ids?.length || 0
        }
        return results
      },
    },
  },
  data() {
    return {
      ids: [],
      isSidebarOpen: false,
    }
  },
  computed: {
    ...mapState('assets', [
      'selectedData',
      'filters',
    ]),
    disableUpload() {
      return !!this.data?.disableUpload
    },
    _limit() {
      return this.data && isNumber(this.data.limit) ? this.data.limit : Math.min()
    },
    selectedAssets: {
      get() {
        return this.selectedData.items
      },
      set(v) {
        let totalSize = 0
        v.forEach(a => {
          totalSize += a.size
        })
        this.SET_SELECTED_DATA({
          ...this.selectedData,
          totalSize: totalSize || 0,
          items: v,
          total: v.length,
        })
      },
    },
    noCloseOnEsc() {
      return !!this.$route.query.assetId
    },
  },
  beforeMount() {
    this.SET_DEFAULT_SELECTED_DATA()
  },
  mounted() {
    this.selectedData.limit = this._limit
    this.filters.types = this.data?.types || [ASSET_TYPES.FILE, ASSET_TYPES.IMAGE, ASSET_TYPES.VIDEO, ASSET_TYPES.VIDEO_PLAYLIST, ASSET_TYPES.AUDIO, ASSET_TYPES.PODCAST]
    this.ids = this.data?.selectedIds ? this.data.selectedIds : this.data?.selectedAssets?.map(e => e.id)
    this.$root.$on('closeAssetsPopup', this.closeAssetsPopup)
  },
  beforeDestroy() {
    this.SET_DEFAULT_FILTERS()
    this.SET_DEFAULT_SELECTED_DATA()
    this.$root.$off('closeAssetsPopup', this.closeAssetsPopup)
  },
  methods: {
    ...mapMutations('assets', [
      'SET_SELECTED_DATA',
      'SET_DEFAULT_FILTERS',
      'SET_DEFAULT_SELECTED_DATA',
    ]),
    closeAssetsPopup() {
      this.closePopup(this.selectedAssets)
    },
  },
}
</script>

<style lang="scss">

</style>
