<template>
  <ResizableBlock
    :attrs="attrs"
    :select-node="selectNode"
    :selected="selected"
    :entity-ref="$refs.spotifyEmbed"
    :update-attrs="updateAttrs"
  >
    <iframe
      v-show="attrs.src"
      ref="spotifyEmbed"
      :src="embedSrc"
      width="100%"
      height="232"
      frameborder="0"
      allowtransparency="true"
      allow="encrypted-media"
    />
  </ResizableBlock>
</template>

<script>
import ResizableBlock from '@/components/ResizableBlock.vue'

export default {
  components: {
    ResizableBlock,
  },
  props: ['attrs', 'updateAttrs', 'selectNode', 'selected'],
  inject: ['deleteBlock', 'showPromptPopup', 'toastNotification'],
  data() {
    return {
      initialSrc: '',

    }
  },
  computed: {
    embedSrc() {
      if (this.attrs.src) {
        const rawUrl = this?.attrs?.src?.startsWith('http') ? this?.attrs?.src : `https://${this?.attrs?.src}`
        const url = new URL(rawUrl?.trim())
        return `${url.origin}/embed${url.pathname}`
      }
      return ''
    },
  },
  watch: {
    src() {
      this.$nextTick(() => {
        this.selectNode()
      })
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.initialSrc = this.attrs.src
      if (this.$refs.sourceField && this.$refs.sourceField.$refs && this.$refs.sourceField.$refs.vsinput) {
        this.$refs.sourceField.$refs.vsinput.focus()
      }
    })
  },
  methods: {
    initialize() {
      if (!this.attrs.src) {
        this.openModal()
      }
    },
    async openModal() {
      const url = await this.showPromptPopup({ title: this.$t('codex-editor.nodes.spotify-podcast.spotify-podcast-url') })
      if (!url) {
        this.deleteBlock(this.attrs.blockId)
      } else if (url.length && url.match((/^(https?:\/\/)?((w{3}\.)?)open.spotify.com\/.*/i))) {
        this.updateAttrs({
          src: url,
        })
      } else {
        this.toastNotification({
          icon: 'XIcon',
          variant: 'danger',
          title: 'Error',
          text: this.$t('codex-editor.nodes.spotify-podcast.spotify-podcast-invalid-url'),
        })
        this.deleteBlock(this.attrs.blockId)
      }
    },
    setSrc() {
      this.updateAttrs({
        src: this.initialSrc,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.sourceField {
  width: 100%;
  white-space: nowrap;
}
</style>
