<template>
  <b-form-group>
    <template #label>
      <FieldName
        :name="field.name"
        :help-text="''"
      />
    </template>
    <div v-if="tags && tags.length > 0">
      <v-select
        :value="tags"
        :options="[]"
        multiple
        disabled
      />
    </div>
    <div
      v-else
      class="content-list__empty"
    >
      <GjIcon name="Tag" />
      <span>{{ $tc('entries.merge-tool.info.no-tag-value') }}</span>
    </div>
  </b-form-group>
</template>

<script>
import FieldName from '@/components/fields/FieldName.vue'

export default {
  name: 'TagField',
  components: {
    FieldName,
  },
  props: {
    fieldContent: {
      type: [String, Number, Array, Object],
    },
    field: {
      type: [Object],
    },
  },
  computed: {
    tags() {
      return this.fieldContent?.map(tag => tag.tag || tag)
    },
  },
}
</script>
