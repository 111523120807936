<template>
  <div>
    <b-form-group v-if="!isTitle">
      <b-form-checkbox v-model="configuration.searchable">
        {{ $t('fields.codex-field-text.configuration.searchable') }}
        <GjIcon
          v-b-tooltip.hover="$t('fields.codex-field-text.configuration.searchable-tooltip')"
          name="Info"
          size="20"
        />
      </b-form-checkbox>
    </b-form-group>
    <b-form-group>
      <b-form-checkbox v-model="configuration.filterable">
        {{ $t('fields.codex-field-text.configuration.filterable') }}
      </b-form-checkbox>
    </b-form-group>
    <b-form-group v-if="valueType === VALUE_TYPES.SINGLE && (appearance === TEXT_FIELD_APPEARANCES.SINGLE_LINE || appearance === TEXT_FIELD_APPEARANCES.MULTIPLE_LINES)">
      <b-form-checkbox v-model="configuration.aiFeatures">
        {{ $t('fields.codex-field-text.configuration.ai-features') }}
      </b-form-checkbox>
    </b-form-group>
  </div>
</template>

<script>
import { generateComputedPropsFromAttrs } from '@/components/codex-layout-editor/BuilderUtils'
import BaseFieldMixin from '@/components/fields/BaseFieldMixin'
import { TEXT_FIELD_APPEARANCES, VALUE_TYPES } from '@/views/models/constants'

export default {
  inject: ['model'],
  mixins: [BaseFieldMixin],
  data() {
    return {
      VALUE_TYPES,
      TEXT_FIELD_APPEARANCES,
    }
  },
  computed: {
    ...generateComputedPropsFromAttrs([
      'valueType',
      'configuration',
      'alias',
      'appearance',
    ]),
    isTitle() {
      const model = this.model?.() || {}
      return model.titleAlias === this.alias
    },
  },
}
</script>
