import { Node } from 'tiptap'
import { setBlockType, textblockTypeInputRule, toggleBlockType } from 'tiptap-commands'
import { getBlockIdFromDom } from '@/components/codex-editor/CodexEditorUtils'
import CodexContentEditor from '../../CodexContentEditor'
import HeadingPanel from './HeadingPanel.vue'
import { BLOCK_GROUPS } from '../constants'

// eslint-disable-next-line no-unused-vars
export function headingTipTapNode(componentName, componentAttrs, component) {
  class Heading extends Node {
    get name() {
      return componentName
    }

    get defaultOptions() {
      return {
        levels: [1, 2, 3, 4, 5, 6],
      }
    }

    get schema() {
      return {
        attrs: componentAttrs,
        content: 'inline*',
        group: 'block',
        defining: true,
        draggable: false,
        parseDOM: this.options.levels
          .map(level => ({
            tag: `h${level}`,
            getAttrs: dom => ({
              ...JSON.parse(dom.dataset?.attrs || '{}'),
              style: `text-align: ${dom.style.textAlign}`,
              blockId: getBlockIdFromDom(dom),
              level,
            }),
          })),
        toDOM: node => [`h${node.attrs.level}`, {
          'data-id': node.attrs.blockId,
          'data-attrs': JSON.stringify(node.attrs) || '{}',
          style: `text-align: ${node.attrs.align}`,
        }, 0],
      }
    }

    commands({ type, schema }) {
      return attrs => toggleBlockType(type, schema.nodes.paragraph, attrs)
    }

    keys({ type }) {
      return this.options.levels.reduce((items, level) => ({
        ...items,
        ...{
          [`Shift-Ctrl-${level}`]: setBlockType(type, { level }),
        },
      }), {})
    }

    inputRules({ type }) {
      return this.options.levels.map(level => textblockTypeInputRule(
        new RegExp(`^(#{1,${level}})\\s$`),
        type,
        () => ({ level }),
      ))
    }
  }

  return new Heading()
}

CodexContentEditor.registerWidget(null, {
  displayName: 'codex-editor.block-names.heading',
  name: 'heading',
  group: [BLOCK_GROUPS.TEXTUAL, BLOCK_GROUPS.MOST_USED],
  icon: 'Bookmarks',
  // eslint-disable-next-line global-require
  image: require('../../icons/heading-1/icon.svg'),
  attrs: {
    align: {
      default: 'left',
    },
    level: {
      default: 1,
    },
    blockId: {
      default: null,
    },
  },
  // renderEditor: ImageVue,
  createTiptapNode: headingTipTapNode,
  renderEditorBar: HeadingPanel,
})
