import { setBlockType } from 'tiptap-commands'
import { Node } from 'tiptap'
import { getBlockIdFromDom } from '@/components/codex-editor/CodexEditorUtils'
import ParagraphVue from './Paragraph.vue'
import ParagraphPanel from './ParagraphPanel.vue'
import CodexContentEditor from '../../CodexContentEditor'
import { setTextAlign } from '../../plugins/CodexCommands'
import { BLOCK_GROUPS } from '../constants'

// eslint-disable-next-line no-unused-vars
export function paragraphTipTapNode(componentName, componentAttrs, component) {
  class CodexParagraph extends Node {
    get name() {
      return componentName
    }

    get schema() {
      return {
        attrs: componentAttrs,
        content: 'inline*',
        group: 'block',
        draggable: false,
        parseDOM: [
          {
            tag: 'p',
            getAttrs: dom => ({
              ...JSON.parse(dom.dataset?.attrs || '{}'),
              blockId: getBlockIdFromDom(dom),
              style: dom.style.textAlign,
              class: dom.classList.contains('dropcap') ? 'dropCap' : '',
            }),
          },
        ],
        // eslint-disable-next-line no-sequences
        toDOM: node => ['p', {
          'data-id': node.attrs.blockId,
          'data-attrs': JSON.stringify(node.attrs) || '{}',
          class: node.attrs.dropCap === true ? 'dropcap' : '',
          style: `text-align: ${node.attrs.align}`,
        }, 0],
      }
    }

    commands({ type }) {
      return () => setBlockType(type)
    }

    keys() {
      return {
        'Mod-e': setTextAlign('center'),
        'Mod-r': setTextAlign('right'),
        'Mod-l': setTextAlign('left'),
      }
    }

    // get view() {
    //   return component
    // }
  }

  return new CodexParagraph()
}

CodexContentEditor.registerWidget(null, {
  displayName: 'codex-editor.block-names.paragraph',
  name: 'paragraph',
  group: BLOCK_GROUPS.TEXTUAL,
  icon: 'Paragraph',
  // eslint-disable-next-line global-require
  image: require('../../icons/paragraph/icon.svg'),
  attrs: {
    align: {
      default: 'left',
    },
    blockId: {
      default: null,
    },
    dropCap: {
      type: Boolean,
      default: false,
    },
  },
  renderEditor: ParagraphVue,
  createTiptapNode: paragraphTipTapNode,
  renderEditorBar: ParagraphPanel,
})
