<template>
  <b-form-group>
    <b-button
      v-if="shouldDisplay('alignLeft')"
      v-b-tooltip.hover.html.top="getTooltipData('align-left', ['Ctrl','Shift', 'L'])"
      variant="flat-dark"
      :class="{'active': isAlign('left')}"
      @click="() => setAlign('left')"
    >
      <GjIcon
        name="AlignLeftAlt"
        size="18"
      />
    </b-button>

    <b-button
      v-if="shouldDisplay('alignCenter')"
      v-b-tooltip.hover.html.top="getTooltipData('align-center', ['Ctrl','Shift', 'E'])"
      variant="flat-dark"
      :class="{'active': isAlign('center')}"
      @click="() => setAlign('center')"
    >
      <GjIcon
        name="AlignCenterAlt"
        size="18"
      />
    </b-button>

    <b-button
      v-if="shouldDisplay('alignRight')"
      v-b-tooltip.hover.html.top="getTooltipData('align-right', ['Ctrl','Shift', 'R'])"
      variant="flat-dark"
      :class="{'active': isAlign('right')}"
      @click="() => setAlign('right')"
    >
      <GjIcon
        name="AlignRightAlt"
        size="18"
      />
    </b-button>

    <b-button
      v-if="includeStretch"
      v-b-tooltip.hover.html.top="getTooltipData('full-width')"
      variant="flat-dark"
      :class="{'active': isAlign('stretch')}"
      @click="() => setAlign('stretch')"
    >
      <GjIcon
        name="Fullscreen"
        size="18"
      />
    </b-button>
  </b-form-group>
</template>

<script>
import hotkeys from 'hotkeys-js'

// eslint-disable-next-line func-names
hotkeys.filter = function () {
  return true
}

export default {
  inject: ['getTooltipData', 'toolbarOptions'],
  props: {
    attrs: Object,
    includeStretch: Boolean,
    updateAttrs: Function,
  },
  computed: {
    align: {
      get() {
        return this.attrs.align
      },
      set(e) {
        this.updateAttrs({ align: e })
      },
    },
  },
  mounted() {
    if (this.shouldDisplay('alignRight')) {
      hotkeys('ctrl+shift+r, command+shift+r', event => {
        event.preventDefault()
        this.setAlign('right')
      })
    }
    if (this.shouldDisplay('alignLeft')) {
      hotkeys('ctrl+shift+l, command+shift+l', event => {
        event.preventDefault()
        this.setAlign('left')
      })
    }
    if (this.shouldDisplay('alignCenter')) {
      hotkeys('ctrl+shift+e, command+shift+e', event => {
        event.preventDefault()
        this.setAlign('center')
      })
    }
  },
  beforeDestroy() {
    hotkeys.unbind('ctrl+shift+r, command+shift+r')
    hotkeys.unbind('ctrl+shift+e, command+shift+e')
    hotkeys.unbind('ctrl+shift+l, command+shift+l')
  },
  methods: {
    shouldDisplay(align) {
      return this.toolbarOptions?.textAlignment?.isEnabled && this.toolbarOptions?.textAlignment?.[align]
    },
    isAlign(align) {
      return this.align === align
    },
    setAlign(align) {
      this.align = align
    },
  },
}
</script>
