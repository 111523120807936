// import { handleCancelRequest } from 'axios'
import store from '@/store'
import Asset from '../constructors/Asset'
import singlePartUpload from './singlePartUpload'
import multiPartUpload from './multiPartUpload'
import multipartComplete from './multipartComplete'
import encode from './encode'
import {
  getMultipartUploadedParts,
} from '../../../codex-sdk/vp-upload'

export async function upload(file, { reupload = false, index = null } = {}) {
  const { FILE_CHUNK_SIZE } = store.state.vpUploads
  const CHUNK_SIZE = FILE_CHUNK_SIZE
  if (file.file.size > CHUNK_SIZE * 1000) {
    throw new Error('File larger than 50GB!')
  } else if (reupload && index === null) {
    throw new Error('No index provided!')
  } else {
    const FILE = new Asset()
    FILE.fromData(file)

    if (!FILE.fileType) {
      throw new Error('No file type provided!')
    }

    if (reupload) {
      store.commit('vpUploads/ADD_REUPLOADING_FILE', { FILE, index })
    } else store.commit('vpUploads/ADD_NEW_UPLOADING_FILE', FILE)

    if (file.size > CHUNK_SIZE) {
      let alreadyUploadedParts = []
      if (reupload) {
        const aup = await getMultipartUploadedParts(FILE.requestKey)
        if (aup.data.result) alreadyUploadedParts = aup.data.result.map(p => p.partNumber)
      }
      await multiPartUpload(FILE, alreadyUploadedParts)
    } else {
      await singlePartUpload(FILE)
    }
  }
}

export async function retryUpload(file, index) {
  if (file.failedStatus === 'encode') {
    file.failedStatus = null
    encode(file, file.requestKey)
  } else if (file.failedStatus === 'complete') {
    file.failedStatus = null
    multipartComplete(file, file.requestKey)
  } else {
    await upload(file, { reupload: true, index, fileType: file.fileType })
  }
}

export async function destroyUploads() {
  const { uploads } = store.state.vpUploads
  // handleCancelRequest()
  await uploads.forEach(u => {
    if (u?.cancelToken) {
      u.cancelToken.cancel()
    }
  })
  store.commit('vpUploads/CLEAR_UPLOADS')
}
