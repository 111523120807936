<template>
  <b-form-group>
    <template
      v-if="showFieldName"
      #label
    >
      <FieldName
        :name="field.name"
        :help-text="''"
      />
    </template>
    <ReferenceComponent
      v-if="references && references.length > 0"
      v-model="references"
      :loading="$apollo.loading"
      :value-type="field.valueType"
      :include-action-buttons="false"
    />
    <div
      v-else
      class="content-list__empty"
    >
      <GjIcon name="ArrowExportPopout" />
      <span>{{ $tc('entries.merge-tool.info.no-references-value', field.valueType === VALUE_TYPES.SINGLE ? 1 : 2) }}</span>
    </div>
  </b-form-group>
</template>

<script>
import FieldName from '@/components/fields/FieldName.vue'
import { VALUE_TYPES } from '@/views/models/constants'
import gql from 'graphql-tag'
import { ENTRY_STATUSES, transformEntriesGraphQL } from '@/codex-sdk/entries'
import ReferenceComponent from '@/components/fields/Reference/ReferenceComponent.vue'

export default {
  name: 'ReferenceField',
  components: { ReferenceComponent, FieldName },
  props: {
    fieldContent: {
      type: [Object, Array],
    },
    field: {
      type: [Object],
    },
    showFieldName: {
      type: [Boolean],
      default: true,
    },
    classes: {
      type: [Array],
      default: () => [],
    },
  },
  apollo: {
    entryCollection: {
      query: gql`
        query ($ids: [String!], $limit: Int) {
          entryCollection (where: {
              id: {
                in: $ids
              }
            }, limit: $limit) {
              items {
                id
                system {
                  title
                  slug
                  status
                  publishedAt
                  featuredMedia {
                    id
                    url(transformation: { width: 70, height: 70, format: THUMBNAIL })
                  }
                  modelId
                  modelAlias
                  createdBy {
                    id
                    email
                    firstName
                    lastName
                    imageUrl
                  }
                }
              }
            }
          }
      `,
      variables() {
        return {
          ids: this.ids,
          limit: this.ids.length,
        }
      },
      update(results) {
        if (results?.entryCollection?.items) {
          transformEntriesGraphQL(results.entryCollection.items)
        }
      },
      result(results) {
        this.references = []
        this.ids.forEach(e => {
          let item = results.data.entryCollection.items.find(c => c.id === e)
          if (!item) {
            item = {
              id: e,
              system: {
                status: ENTRY_STATUSES.DELETED,
                model: {
                  name: e.model,
                  iconId: 'Modeling',
                  alias: e.model,
                },
              },
            }
          }
          this.references.push(item)
        })
        this.$apollo.queries.entryCollection.skip = true
      },
    },
  },
  data() {
    return {
      references: [],
    }
  },
  setup(props) {
    let ids = []
    if (props.field.valueType === VALUE_TYPES.SINGLE && props.fieldContent !== null) {
      ids.push(props.fieldContent.entryId)
    } else if (props.field.valueType === VALUE_TYPES.LIST && props.fieldContent) {
      ids = props.fieldContent.map(e => e.entryId)
    }

    return {
      ids,
      VALUE_TYPES,
    }
  },
}
</script>

<style lang="scss">
.reference-field__item {
      background: #F4F6FA;
    border-radius: 4px;
    padding: 10px 8px;
    margin-top: 14px;
    display: flex;
    align-items: center;
    gap: 8px;
}

.reference__icon {
  display: inline-flex;
  background: linear-gradient(180deg, #D9D9D9 0%, #F3F2F4 0.01%, #F0E4FF 100%);
  border-radius: 4px;
  width: 34px;
  height: 34px;
  align-items: center;
  justify-content: center;
}

.reference__content {
  flex-grow: 1;
  display: flex;
  justify-content: space-between;

  .reference__model {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #667C99;
  }

  .reference__name {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #052D61;
  }

  .reference__status {
    display: flex;
    align-items: center;
    margin-right: 30px;

    .status__indicator {
      height: 5px;
      width: 5px;
      border-radius: 50%;
      display: inline-block;
      background: #1D79F2;
      margin: 0 8px;
    }

    .status__name {
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      color: #031C3C;
    }
  }

}

.refrence-list__empty {
  background-color: #f6f7f9;
  border: 1px dashed #c8d0da;
  border-radius: 4px;
  min-height: 54px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 14px;
}
</style>
