<template>
  <div class="new-filters-dropdown__filter-body new-filters-dropdown__filter-body--scroll">
    <b-row
      align-v="center"
      no-gutters
    >
      <b-col cols="12">
        <b-checkbox-group
          v-model="value.value"
          class="new-filters-dropdown__checkbox-group"
          :options="options"
          stacked
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapUrlEntityType, URL_ENTITY_TYPES_STRING } from '@/codex-sdk/urls'

export default {
  props: {
    filter: {
      type: Object,
      required: true,
    },
    filterObject: {
      type: [Object, Function],
      required: true,
    },
    value: {
      type: Object,
      required: true,
    },
    cache: {
      type: Object,
      required: true,
    },
    setCache: {
      type: Function,
      required: true,
    },
  },
  computed: {
    options() {
      return [
        {
          value: URL_ENTITY_TYPES_STRING.SECTION,
          text: this.$t(mapUrlEntityType(URL_ENTITY_TYPES_STRING.SECTION)),
        },
        {
          value: URL_ENTITY_TYPES_STRING.ENTRY,
          text: this.$t(mapUrlEntityType(URL_ENTITY_TYPES_STRING.ENTRY)),
        },
        {
          value: URL_ENTITY_TYPES_STRING.REDIRECT,
          text: this.$t(mapUrlEntityType(URL_ENTITY_TYPES_STRING.REDIRECT)),
        },
      ]
    },
  },
}
</script>
