import CustomParametersComponent from './CustomParameters.vue'

export default class CustomParametersFilter {
  static COMPONENT = CustomParametersComponent;

  static OPERATORS = {
    some: 'some',
  }

  static type = 'customParameters'

  operator = null;

  key= null;

  value = null;

  constructor({ operator = CustomParametersFilter.OPERATORS.some, key, value } = {}) {
    this.operator = operator
    this.key = key
    this.value = value
  }

  set({ operator = CustomParametersFilter.OPERATORS.some, key, value }) {
    this.operator = operator
    this.key = key
    this.value = value
  }

  getCount() {
    return 1
  }

  getValueLabel() {
    return `${this.key}:${this.value}`
  }

  asQueryParam() {
    return {
      operator: this.operator,
      key: this.key,
      value: this.value,
    }
  }

  asGraphQL() {
    if (!this.key?.length || !this.value?.length) return null

    return {
      some: [`${this.key}:${this.value}`],
    }
  }

  static validate({ operator, key, value }) {
    if (!operator) {
      return 'Operator is required'
    }

    if (!key?.length) {
      return 'Key is required'
    }

    if (!value?.length) {
      return 'Value is required'
    }

    return true
  }

  static fromQueryParam({ operator, key, value }) {
    return new CustomParametersFilter({
      operator,
      key,
      value,
    })
  }
}
