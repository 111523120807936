import CodexContentEditor from '../../CodexContentEditor'
import InstagramEmbed from './InstagramEmbed.vue'
import InstagramEmbedPanel from './InstagramEmbedPanel.vue'
import { BLOCK_GROUPS } from '../constants'

CodexContentEditor.registerWidget('codex', {
  displayName: 'codex-editor.block-names.instagram-embed',
  name: 'instagram_embed',
  group: BLOCK_GROUPS.EMBEDS,
  // eslint-disable-next-line global-require
  image: require('../../icons/instagram-post/icon.svg'),
  attrs: {
    blockId: {
      default: null,
    },
    src: {
      default: null,
    },
    align: {
      default: 'left',
    },
    includeCaption: {
      default: true,
    },
    caption: {
      default: '',
    },
  },
  renderEditor: InstagramEmbed,
  renderEditorBar: InstagramEmbedPanel,
})
