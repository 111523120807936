<template>
  <b-form-group class="section-field section-field__container">
    <template #label>
      <FieldName
        :name="name"
        :help-text="helpText"
        :help-text-display="helpTextDisplay"
        :required="required"
      />
    </template>
    <div class="entries-card">
      <div class="entries-list">
        <template v-if="sections">
          <draggable
            v-if="sections.length > 0"
            v-model="sections"
            :disabled="!isSectionEditable || readOnly || !editable"
            class="entries-list__draggable sections-list__draggable"
            :group="{pull: false, push: false}"
          >
            <div
              v-for="(section, index) in sections"
              :key="section.id + index"
              class="entries-list__item"
            >
              <div class="entries-list__item-icon">
                <GjIcon
                  name="Drag"
                  size="24"
                />
                <GjIcon name="Section" />
              </div>
              <div class="entries-list__item-content">
                <div class="entries-list__item-name">
                  {{ section.title }}
                </div>
              </div>
              <div class="entries-list__item-main mr-1">
                <template v-if="section.isMain">
                  {{ $t('fields.codex-field-sections.render.main-section') }}
                </template>
                <template v-else>
                  <a
                    v-if="isMainEditable && !readOnly && editable"
                    class="entries-list__item-hover"
                    href="#"
                    @click.prevent="setMain(section)"
                  >
                    {{ $t('fields.codex-field-sections.render.set-main-section') }}
                  </a>
                </template>
              </div>
              <div
                v-if="((!section.isMain && isSectionEditable) || (section.isMain && isMainEditable && isSectionEditable && valueType === VALUE_TYPES.LIST) || (isSectionEditable && valueType === VALUE_TYPES.SINGLE)) && !readOnly && editable"
                class="entries-list__item-delete"
                @click="removeSection(section.id)"
              >
                <GjIcon
                  name="Delete"
                  size="22"
                />
              </div>
            </div>
          </draggable>
          <div
            v-else
            class="entries-list__empty"
          >
            {{ $t('fields.codex-field-sections.render.no-sections-selected') }}
          </div>
          <div class="sections-select__wrapper">
            <b-dropdown
              v-if="isSectionEditable && !readOnly && editable"
              variant="asd"
              no-caret
              class="sections-select"
              @click="search = ''"
            >
              <template #button-content>
                <div class="entries-list__add sections-select__add  hide-in-quick-view">
                  <GjIcon
                    name="Plus"
                    size="20"
                  />
                  {{ $t('fields.codex-field-sections.render.add-section') }}
                </div>
              </template>
              <div class="sections-select__search">
                <GjIcon name="Search" />
                <b-form-input
                  v-model="search"
                  :placeholder="$t('fields.codex-field-sections.render.search-placeholder')"
                />
              </div>
              <template v-if="allSections && allSections.items">
                <div
                  v-for="section in allSections.items"
                  :key="section.id"
                >
                  <RecursiveSection
                    :items="section"
                    :disabled-items="validation.parentChildValidation.restrictParentChildSelection ? disabledSections : []"
                    :search="isSearch"
                  />
                </div>
              </template>
            </b-dropdown>
          </div>
        </template>

        <div
          v-else
          class="entries-list__loading"
        >
          <b-spinner
            variant="secondary"
            :label="$t('general.loading')"
            small
          />
          {{ $t('general.loading') }}
        </div>
      </div>
    </div>
    <FieldError :error="error" />
  </b-form-group>
</template>

<script>

import draggable from 'vuedraggable'
import { generateComputedPropsFromAttrs } from '@/components/codex-layout-editor/BuilderUtils'
import { VALUE_TYPES, RANGE_OPERATORS, FIELD_FILTER_OPERATORS } from '@/views/models/constants'
import gql from 'graphql-tag'
import FieldName from '@/components/fields/FieldName.vue'
import RecursiveSection from '@/components/fields/Section/Recursive-section.vue'
import { mapEntryStatus } from '@/codex-sdk/entries'
import store from '@/store'
import SectionFilter from '@/components/filters-dropdown/filters/section/SectionFilter'
import SectionListFilter from '@/components/filters-dropdown/filters/sectionList/SectionListFilter'
import { flattenSections } from '@/codex-sdk/sections'
import BaseFieldMixin from '@/components/fields/BaseFieldMixin'
import FieldRenderMixin from '@/components/fields/RenderFieldMixin'
import FieldError from '@/components/fields/FieldError.vue'

export default {
  inject: ['toastNotification', 'filterValues', 'entryId'],
  components: {
    FieldError,
    RecursiveSection,
    draggable,
    FieldName,
  },
  mixins: [BaseFieldMixin, FieldRenderMixin],
  props: {
    value: {
      type: [Object, Array],
      default: null,
    },
    entry: Object,
  },
  apollo: {
    allSections: {
      query: gql`
        fragment Section on CodexSection {
          id
          title
          parentId
        }
        query Sections ($siteId: String, $query: String, $searchLimit: Int, $limit: Int) {
          searchResults:sectionCollection (where: { siteId: {eq: $siteId }, title: { contains: $query } }, limit: $searchLimit) {
            total
            items {
              ...Section
                    path {
                        id
                        parentId
                        title
                    }
                  children {
                items {
                  ...Section
                  children {
                    items {
                      ...Section
                      children {
                        items {
                          ...Section
                          children {
                            items {
                              ...Section
                              children {
                                items {
                                  ...Section
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          sectionCollection (where: { siteId: {eq: $siteId }, parentId: { exists: false } }, limit: $limit) {
            total
            items {
              ...Section
              children {
                items {
                  ...Section
                  children {
                    items {
                      ...Section
                      children {
                        items {
                          ...Section
                          children {
                            items {
                              ...Section
                              children {
                                items {
                                  ...Section
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `,
      variables() {
        return {
          searchLimit: this.search ? 1000 : 0,
          limit: this.search ? 0 : 1000,
          siteId: this.$route.params.siteName,
          query: this.search,
        }
      },
      fetchPolicy: 'network-only',
      update(data) {
        this.isSearch = !(data.sectionCollection?.items?.length > 0)
        return data.sectionCollection?.items?.length > 0 ? data.sectionCollection : data.searchResults
      },
    },
    sectionsData: {
      query: gql`
        query Sections ($siteId: String, $sectionsIds: [String!]) {
          sectionCollection (where: { siteId: {eq: $siteId }, id: { in: $sectionsIds } }) {
            total
            items {
              id
              title
              parentId
            }
          }
        }
      `,
      variables() {
        return {
          siteId: this.$route.params.siteName,
          sectionsIds: this.computedValue?.map(section => section.id),
        }
      },
      update(data) {
        const sections = []
        data.sectionCollection.items.forEach(section => {
          sections[section.id] = section
        })
        return sections
      },
    },
  },
  provide() {
    return {
      addOrRemoveSection: this.addOrRemoveSection,
      sections: () => this.sections,
    }
  },
  data() {
    return {
      VALUE_TYPES,
      userData: JSON.parse(localStorage.getItem('userData') || '{}'),
      search: '',
      size: 10,
      selectedSections_: [],
      showSectionChildren: false,
      isSearch: '',
    }
  },
  computed: {
    ...generateComputedPropsFromAttrs([
      'name',
      'alias',
      'configured',
      'appearance',
      'validation',
      'valueType',
      'helpText',
      'helpTextDisplay',
      'defaultValue',
      'editableIn',
      'mainEditableIn',
    ]),
    isSectionEditable() {
      return (this.editableIn || [])?.includes(this.entry?.system?.status)
    },
    isMainEditable() {
      if (this.valueType === VALUE_TYPES.SINGLE) return true
      return (this.mainEditableIn || [])?.includes(this.entry?.system?.status)
    },
    computedValue: {
      get() {
        if (this.valueType === VALUE_TYPES.SINGLE) {
          return this.value ? [this.value] : []
        }
        return this.value
      },
      set(v) {
        if (v.length > 0) {
          let mainSection = v.find(section => section.isMain)
          const previousMainSection = this.computedValue.find(section => section.isMain)
          if (!mainSection && this.isMainEditable) {
            v[0].isMain = true
            mainSection = v[0]
          }
          if ((mainSection?.id !== previousMainSection?.id || !mainSection) && !this.isMainEditable) {
            this.toastNotification({
              title: this.$t('fields.codex-field-sections.render.errors.set-main-no-permission-title', { entryStatus: this.$t(mapEntryStatus(this.entry.system.status)) }),
              icon: 'Close',
              text: this.$t('fields.codex-field-sections.render.errors.set-main-no-permission-text'),
              variant: 'danger',
            })
            return
          }
        }

        if (this.valueType === VALUE_TYPES.SINGLE) {
          this.$emit('input', v[0])
        } else {
          this.$emit('input', v)
        }
      },
    },
    sections: {
      get() {
        return this.computedValue?.map(section => ({
          ...section,
          ...(this.sectionsData?.[section.id] ? this.sectionsData[section.id] : { title: this.$t('fields.codex-field-sections.render.not-found') }),
        })) || []
      },
      set(v) {
        this.computedValue = v.map(section => ({
          id: section.id,
          isMain: section.isMain,
        }))
      },
    },
    disabledSections() {
      let disabledIds = []
      const allSections = flattenSections(this.allSections.items)
      if (this.search && allSections && this.sections.length) {
        const searchedPaths = {}
        const parentsPath = this.allSections.items.map(i => i.path)
        if (parentsPath && parentsPath.length) {
          parentsPath.forEach(i => {
            const local = flattenSections(i)
            if (local) {
              Object.assign(searchedPaths, local)
            }
          })
        }
        if (searchedPaths) {
          Object.assign(allSections, searchedPaths)
        }
      }
      if (allSections && this.sections.length) {
        this.sections.forEach(section => {
          if (section) {
            const shakeTree = [...this.findAncestors(allSections, section), ...this.findChildren(allSections, section)
              .map(i => i.id)]
            disabledIds = [...disabledIds, ...shakeTree]
          }
        })
      }
      return disabledIds
    },
  },
  mounted() {
    if (this.entryId() === 'create' && !this.readOnly && this.editable) {
      // Prefill from filter
      if (this.filterValues && this.filterValues?.[this.alias]) {
        if (this.valueType === VALUE_TYPES.SINGLE) {
          if (SectionFilter.shouldApplyPredefinedValue(this.filterValues[this.alias])) {
            this.sections = [this.sectionFromFiltersToValue(this.filterValues[this.alias].value[0])]
          }
        } else if (SectionListFilter.shouldApplyPredefinedValue(this.filterValues[this.alias])) {
          this.sections = this.filterValues[this.alias].value.map(asset => this.sectionFromFiltersToValue(asset))
        }
      }
      // \Prefill from filter
    }
  },
  beforeMount() {
    if (!this.validation.parentChildValidation) {
      this.$set(this.validation, 'parentChildValidation', {
        restrictParentChildSelection: false,
        errorMessage: 'You cant select Sections which have parent or children selected',
      })
    }
    if (!this.widget.attrs.mainEditableIn) {
      this.widget.attrs.mainEditableIn = this.widget.attrs.editableIn
    }

    if (!this.widget.attrs.hidden) {
      this.$set(this.widget.attrs, 'hidden', {
        value: false,
        conditionsEnabled: false,
        conditions: [
          {
            isSystem: false,
            field: '',
            operator: FIELD_FILTER_OPERATORS.EXISTS,
            value: '',
          },
        ],
      })
    }
  },
  methods: {
    sectionFromFiltersToValue(sectionId) {
      const assetFromFiltersCache = store.state.filters.filtersCache[sectionId] || {}

      return {
        ...assetFromFiltersCache,
      }
    },
    findAncestors(sections, section) {
      let currentSection = section
      const ancestors = []

      while (currentSection && currentSection?.parentId) {
        const find = sections[currentSection.parentId]
        if (find) {
          ancestors.push(find)
        }
        currentSection = find
      }
      return ancestors.map(i => i.id)
    },
    findChildren(sections, section) {
      const keys = Object.keys(sections)
      let children = []
      keys.forEach(key => {
        if (sections[key].parentId === section.id) {
          children.push(sections[key])
        }
      })
      if (children.length > 0) {
        children.forEach(child => {
          children = [...children, ...this.findChildren(sections, child)]
        })
      }
      return children
    },
    addOrRemoveSection({
      id,
      title,
      isMain,
      parentId,
    }) {
      const sec = {
        id,
        title,
        isMain,
        parentId,
      }
      if (this.validation.parentChildValidation.restrictParentChildSelection && this.disabledSections.find(item => item === sec.id)) {
        return
      }
      if (this.sections.some(el => el.id === id)) {
        if (this.sections.some(el => el.id === id && el.isMain) && !this.isMainEditable) return
        this.removeSection(id)
      } else {
        this.sectionsData[id] = sec

        if (this.valueType === VALUE_TYPES.SINGLE) {
          this.computedValue = [sec]
        } else {
          this.computedValue = [...this.computedValue, sec]
        }
      }
    },
    setMain(section) {
      this.computedValue = this.computedValue.map(s => ({
        ...s,
        isMain: s.id === section.id,
      }))
    },
    removeSection(id) {
      if (this.readOnly || !this.editable) return
      this.computedValue = this.computedValue.filter(s => s.id !== id)
    },
    validate(value) {
      if (this.required && (value?.length < 1 || !value)) {
        return {
          isValid: false,
          message: this.validation.required.errorMessage,
        }
      }
      try {
        if (this.validation.numberOfSections.isEnabled && this.computedValue.length > 0) {
          if (this.validation.numberOfSections.rangeOperator === RANGE_OPERATORS.BETWEEN) {
            if (this.computedValue.length < this.validation.numberOfSections.min || this.computedValue.length > this.validation.numberOfSections.max) {
              return {
                isValid: false,
                message: this.validation.numberOfSections.errorMessage,
              }
            }
          }
          if (this.validation.numberOfSections.rangeOperator === RANGE_OPERATORS.LTE) {
            if (this.computedValue.length > this.validation.numberOfSections.max) {
              return {
                isValid: false,
                message: this.validation.numberOfSections.errorMessage,
              }
            }
          }
          if (this.validation.numberOfSections.rangeOperator === RANGE_OPERATORS.GTE) {
            if (this.computedValue.length < this.validation.numberOfSections.min) {
              return {
                isValid: false,
                message: this.validation.numberOfSections.errorMessage,
              }
            }
          }
        }
      } catch (e) {
        return {
          isValid: false,
          message: this.validation.invalidAuth,
        }
      }
      return {
        isValid: true,
        message: '',
      }
    },
  },
}

</script>

<style lang="scss">
.sections-select {
  ul {
    max-height: 300px;
    overflow-y: auto;
    padding: 8px;
    width: 400px;
  }
}

.sections-select__search {
  //display: flex;
  //align-items: center;
  position: relative;

  svg {
    position: absolute;
    top: 50%;
    left: 4px;
    transform: translateY(-50%);
    pointer-events: none;
  }

  input {
    padding-left: 32px;
    background-color: transparent;
    border: 0px solid transparent;
  }
}

.sections-select__item {
  border-radius: 4px;
  padding: 6px 12px;
  color: #052D61;
  font-weight: 400;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
  line-height: 24px;
  margin: 2px 0;

  &:hover {
    background-color: #ECF3FF;
  }

  .sections-select__checkmark {
    color: #206ED5;
    margin-left: auto;
  }

  &.sections-select__item--no-child:not(.sections-select__item--checkbox) {
    padding-left: 36px;
  }

  &.sections-select__item--selected {
    background-color: #ECF3FF;
  }
}

.sections-select__wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.sections-select__add {
  gap: 8px !important;
}
</style>
