<template>
  <div class="codex-warning__popup">
    <div class="warning-icon">
      <GjIcon
        name="InfoAlt"
        size="20"
        color=" #E34850"
      />
    </div>
    <p class="warning-popup__text">
      {{ $t('popups.alert-popup.warning') }}
    </p>
    <button
      class="refresh-buttons"
      @click="$emit('checkingRefresh')"
    >
      <GjIcon
        name="RefreshAlt"
        size="20"
      />
      {{ $t('popups.alert-popup.refresh') }}
    </button>
  </div>
</template>

<script>
export default {
  name: 'WarningPopup',
}
</script>

<style lang="scss" scoped>
.codex-warning__popup{
  width: 100%;
  max-width: 700px;
  margin-top: 10px;
  display: flex;
  box-sizing: border-box;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  gap: 14px;
  min-width: 0;
  background: #FDEDEE;
  box-shadow: 0 4px 26px rgba(17, 21, 56, 0.1);
  border-radius: 4px;
  pointer-events: all;
  position: fixed;
  z-index: 1000;
  left: 50%;
  transform: translateX(-50%);
}
  .warning-icon{
    margin-left: 20px;
  }
  .warning-popup__text {
    margin: auto;
    //height: 22px;
    color:#E34850 ;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    display: flex;
    align-items: center;
    order: 0;
    flex-grow: 1;
  }

  .refresh-buttons {
    display: flex;
    align-items: center;
    padding: 0 12px;
    background: #ffffff;
    border: 1px solid #e0e5eb;
    border-radius: 4px;
    height: 34px;
    white-space: nowrap;

    &, span {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
      color: #E34850;
    }

    &:focus {
      outline: none;
    }
    svg:first-child {
      margin-right: 5px;
    }
    svg:last-child {
      cursor: pointer;
    }
  }
</style>
