<template>
  <b-modal
    id="codex-jobs-view"
    v-model="showPopup"
    ok-variant="primary"
    cancel-variant="outline-dark"
    size="lg"
    no-close-on-backdrop
    :title="$t('jobs.view-popup.title')"
    :static="true"
    :ok-title="$t('jobs.view-popup.ok-title')"
    :ok-only="true"
    @ok.prevent="closePopup(false)"
    @close="closePopup(false)"
  >
    <template v-if="recurringJob">
      <b-form-group :label="$t('jobs.view-popup.input-labels.name-label')">
        <b-form-input
          :value="recurringJob.name"
          readonly
        />
      </b-form-group>
      <b-row>
        <b-col>
          <b-form-group :label="$t('jobs.view-popup.input-labels.source-environment')">
            <b-form-input
              :value="recurringJob.baseEnvironment.alias"
              readonly
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group :label="$t('jobs.view-popup.input-labels.target-environment')">
            <b-form-input
              :value="recurringJob.targetEnvironment.alias"
              readonly
            />
          </b-form-group>
        </b-col>
      </b-row>
      <CronScheduler
        v-model="recurringJob.cronExpression"
        disabled
      />
    </template>
  </b-modal>
</template>

<script >
import PopupsMixin from '@/components/popups/PopupsMixin'
import gql from 'graphql-tag'
import CronScheduler from '@/views/jobs/components/CronScheduler.vue'

export default {
  name: 'CreateJobPopup',
  components: { CronScheduler },
  mixins: [PopupsMixin],
  inject: ['showConfirmPopup'],
  apollo: {
    $client: 'jobsClient',
    recurringJob: {
      query: gql`query ($id: String!) {
        recurringJob(id: $id) {
          name
          baseEnvironment {
            alias
          }
          targetEnvironment {
            alias
          }
          cronExpression
        }
      }`,
      fetchPolicy: 'network-only',
      variables() {
        return {
          id: this?.data?.id,
        }
      },
    },
  },
}
</script>
