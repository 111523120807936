<template>
  <b-sidebar
    v-model="showPopup"
    class="c-sidebar c-sidebar--lg mapping-sidebar"
    :title="mapping ? $t('modelMapping.sidebarTitleUpdate') : $t('modelMapping.sidebarTitle')"
    right
    backdrop
  >
    <div
      v-if="loading"
      class="mapping-sidebar__loader-container"
    >
      <b-spinner />
    </div>

    <h3 class="mapping-sidebar__title">
      {{ $t('modelMapping.sidebarSecondaryTitle') }}
    </h3>
    <p class="mapping-sidebar__subtitle">
      {{ $t('modelMapping.sidebarDescription') }}
    </p>
    <div
      v-if="showAlert"
      class="mapping-sidebar-alert"
    >
      <span class="mapping-sidebar-alert__icon">
        <GjIcon name="Info_fill" />
      </span>
      <div class="mapping-sidebar-alert__content">
        <span class="mapping-sidebar-alert__title">
          {{ $t('modelMapping.alert-title') }}
        </span>
        <span class="mapping-sidebar-alert__subtitle">
          {{ $t('modelMapping.alert-description') }}
        </span>
      </div>
      <GjIcon
        class="mapping-sidebar-alert__close-alert"
        name="Close"
        size="20px"
        @click.native="showAlert = false"
      />
    </div>
    <b-form-group :label="$t('modelMapping.modelSelectLabel')">
      <ModelSelector
        v-if="mapping"
        v-model="mapping.toModel"
        :multiple="false"
        :close-on-select="true"
      />
    </b-form-group>
    <div
      v-if="toModel"
      class="mapping-sidebar-fields"
    >
      <div
        v-for="field in currentFields"
        :key="field.alias"
        class="mapping-sidebar-field"
        :class="{'mapping-sidebar-field--no-match': !mappingObject[field.alias].length}"
      >
        <div class="mapping-sidebar-field__col">
          <b-input
            :value="field.name"
            disabled
          />
        </div>
        <GjIcon
          class="mapping-sidebar-field__mapping-icon"
          name="IconparkApi"
          size="20px"
        />
        <div class="mapping-sidebar-field__col">
          <v-select
            v-if="mappingObject[field.alias].length"
            v-model="mapping.fields[field.alias]"
            :placeholder="$t('modelMapping.field-selector.placeholder')"
            :reduce="o => o.alias"
            :options="mappingObject[field.alias]"
            label="name"
          />
          <b-input
            v-else
            :value="$t('modelMapping.field-selector.no-fields')"
            disabled
          />
        </div>
      </div>
    </div>
    <div class="mapping-sidebar__clone-asset">
      <b-form-checkbox v-model="mapping.configurations.asset.isEnabled">
        <span class="">
          {{ $t('modelMapping.cloneAsset.title') }}
        </span>
        <GjIcon
          v-b-tooltip.hover="$t('modelMapping.cloneAsset.tooltip')"
          name="Info"
          size="20"
        />
      </b-form-checkbox>
      <b-form-group
        v-if="mapping.configurations.asset.isEnabled"
        :label="$t('modelMapping.cloneAsset.folderLabel')"
      >
        <FolderSelect
          v-model="mapping.configurations.asset.folderId"
          :has-multiple="false"
          :close-on-select="true"
          :placeholder="$t('modelMapping.cloneAsset.folderPlaceholder')"
        />
      </b-form-group>
    </div>
    <template #footer>
      <b-button
        v-if="mapping && mapping.id"
        class="mapping-sidebar__delete"
        variant="outline-danger"
        @click="handleDelete"
      >
        <GjIcon
          name="DeleteTrash"
          size="20px"
        />
        {{ $t('modelMapping.sidebarDeleteButton') }}
      </b-button>
      <b-button
        variant="outline-secondary"
        @click="handleCancel"
      >
        {{ $t('modelMapping.sidebarCancelButton') }}
      </b-button>
      <b-button
        variant="primary"
        @click="handleOk"
      >
        {{ $t('modelMapping.sidebarSaveButton') }}
      </b-button>
    </template>
  </b-sidebar>
</template>

<script>
import PopupsMixin from '@/components/popups/PopupsMixin'
import ModelSelector from '@/components/ModelSelector.vue'
import {
  createModelMapping, updateModelMapping, deleteModelMapping, readModel, readModelMapping, mappingDefaults,
} from '@/codex-sdk/models'
import { omitBy, isNil } from 'lodash'
import { TYPES } from '@/views/models/constants'
import FolderSelect from '@/components/assets/FolderSelect.vue'

export default {
  name: 'ModelMappingSidebar',
  components: { FolderSelect, ModelSelector },
  mixins: [PopupsMixin],
  inject: ['showConfirmPopup'],
  data() {
    return {
      mapping: mappingDefaults(),
      fromModel: null,
      toModel: null,
      showAlert: true,
      loading: false,
    }
  },
  computed: {
    mappingId() {
      return this.data.mappingId
    },
    fomModelId() {
      return this.data.modelId
    },
    currentFields() {
      return this.fromModel?.fields.filter(f => f.type !== TYPES.URL) || []
    },
    toModelFields() {
      return this.toModel.fields.filter(newField => newField.type !== TYPES.URL)
    },
    mappingObject() {
      const mappingObject = {}
      this.currentFields.forEach(currentField => {
        if (this.toModel === null) {
          mappingObject[currentField.alias] = []
          return
        }
        mappingObject[currentField.alias] = this.toModelFields.filter(newField => newField.type === currentField.type)
      })
      return mappingObject
    },
  },
  watch: {
    'mapping.toModel': {
      handler(newVal, oldVal) {
        if (!isNil(oldVal)) {
          this.mapping.fields = {}
        }
        this.readToModel()
      },
    },
  },
  async mounted() {
    this.loading = true
    await this.readMapping()
    await this.readFromModel()
    if (this.mapping.toModel) {
      await this.readToModel()
      Object.keys(this.mapping.fields).forEach(key => {
        const aliasExists = this.fromModel.fields.some(aliasObj => aliasObj.alias === key)
        if (!aliasExists) {
          this.mapping.fields[key] = null
        }
      })
    }
    this.loading = false
  },
  methods: {
    async readMapping() {
      if (!this.mappingId) return
      try {
        const { data } = await readModelMapping(this.fomModelId, this.mappingId)
        this.mapping = data
      } catch (e) {
        console.log(e)
      }
    },
    async readFromModel() {
      try {
        const { data } = await readModel(this.fomModelId)
        this.fromModel = data
      } catch (e) {
        console.log(e)
      }
    },
    async readToModel() {
      try {
        const { data } = await readModel(this.mapping.toModel)
        this.toModel = data
      } catch (e) {
        console.log(e)
      }
    },
    async handleDelete() {
      const res = await this.showConfirmPopup({
        title: this.$t('modelMapping.delete-confirm-popup.title'),
        description: this.$t('modelMapping.delete-confirm-popup.description'),
        size: 'lg',
        alertVariant: 'warning',
        alertTitle: this.$t('modelMapping.delete-confirm-popup.alert.title'),
        alertIcon: 'AlertTriangleFilled',
        alertDescription: this.$t('modelMapping.delete-confirm-popup.alert.description'),
        leftAlign: true,
        okVariant: 'danger',
        okTitle: this.$t('modelMapping.delete-confirm-popup.ok-title'),
        cancelTitle: this.$t('modelMapping.delete-confirm-popup.cancel-title'),
      })
      if (res) {
        try {
          await deleteModelMapping(this.fromModel.id, this.data.mappingId)
          const data = { ...this.mapping }
          data.action = 'delete'
          this.closePopup(data)
        } catch (e) {
          console.log(e)
        }
      }
    },
    async handleOk() {
      this.mapping.fields = omitBy(this.mapping.fields, isNil)
      if (this.mapping.id) {
        await this.updateMapping()
      } else {
        await this.createMapping()
      }
    },
    async updateMapping() {
      try {
        const { data } = await updateModelMapping(this.fromModel.id, this.mapping.id, this.mapping)
        if (data) {
          data.action = 'update'
          this.closePopup(data)
        }
      } catch (e) {
        console.log(e)
      }
    },
    async createMapping() {
      try {
        const { data } = await createModelMapping(this.fromModel.id, this.mapping)
        if (data) {
          this.closePopup(data)
        }
      } catch (e) {
        console.log(e)
      }
    },
    handleCancel() {
      this.showPopup = false
    },
  },
}
</script>

<style lang="scss">
.mapping-sidebar__loader-container {
  display: flex;
  justify-content: center;
}
.mapping-sidebar__clone-asset {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.mapping-sidebar__title {
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  color: #052D61;
  margin: 0 0 8px 0;
  padding: 0;
}

.mapping-sidebar__subtitle {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #052D61;
}

.mapping-sidebar-alert {
  background: #ECF3FF;
  border: 1px solid #D8E6FD;
  border-radius: 8px;
  display: flex;
  position: relative;
  padding: 16px;
  margin-bottom: 16px;

  .mapping-sidebar-alert__close-alert {
    position: absolute;
    margin: 0;
    padding: 0;
    right: 8px;
    top: 8px;
    color: #052D61;
  }
}

.mapping-sidebar-alert__icon {
  color: #8C7BE4;
  margin-right: 8px;
}

.mapping-sidebar-alert__content {
  display: inline-flex;
  flex-direction: column;
}

.mapping-sidebar-alert__title {
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #052D61;
  margin-bottom: 8px;
}

.mapping-sidebar-alert__subtitle {
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #052D61;
}

.mapping-sidebar-field {
  margin-bottom: 16px;
  display: flex;
  align-items: center;

  .form-control, .v-select {
    width: 100%;
    margin: 0;
  }

}

.mapping-sidebar-field__col {
  width: 100%;
}

.mapping-sidebar-field__mapping-icon {
  color: #206ED5;
  margin: 0 8px;
  flex-shrink: 0;
  @at-root .mapping-sidebar-field--no-match & {
    color: #A3B0C2;
  }
}

.mapping-sidebar .b-sidebar-footer {
  border-top: 0px solid transparent !important;

  .btn-outline-secondary {
    border: 1px solid transparent !important;
  }
}

button.mapping-sidebar__delete {
  border-color: transparent !important;
  margin-right: auto !important;

  svg {
    margin-right: 8px;
  }
}
</style>
