<template>
  <div class="cron-scheduler">
    <b-form-group>
      <b-form-checkbox
        v-model="scheduler.enabled"
        :disabled="disabled"
      >
        {{ $t('jobs.cron-scheduler.label') }}
      </b-form-checkbox>
    </b-form-group>
    <template v-if="scheduler.enabled">
      <b-form-group>
        <b-form-radio-group
          v-model="scheduler.type"
          value-field="value"
          :options="typeOptions"
          :disabled="disabled"
        />
      </b-form-group>
      <b-form-group
        v-if="scheduler.type === 'day'"
        :label="$t('jobs.cron-scheduler.type-options.days')"
      >
        <v-select
          :key="scheduler.type"
          v-model="scheduler.days"
          :disabled="disabled"
          :reduce="d => d.value"
          :options="daysOptions"
          :multiple="true"
          :close-on-select="false"
          :deselect-from-dropdown="true"
          :placeholder="$t('jobs.cron-scheduler.days-options.all')"
        />
      </b-form-group>
      <b-form-group
        v-if="scheduler.type === 'date'"
        :label="$t('jobs.cron-scheduler.type-options.dates')"
      >
        <v-select
          :key="scheduler.type"
          v-model="scheduler.dates"
          :disabled="disabled"
          :options="datesOptions"
          :reduce="d=> d.value"
          :multiple="true"
          labal="label"
          :close-on-select="false"
          :deselect-from-dropdown="true"
          :placeholder="$t('jobs.cron-scheduler.dates-options.all')"
        />
      </b-form-group>
      <b-form-group>
        <template #label>
          <div class="time-tooltip">
            <span>
              {{ $t('jobs.cron-scheduler.time.label') }}
            </span>
            <GjIcon
              v-b-tooltip.hover="$t('jobs.cron-scheduler.time.tooltip')"
              name="Info"
              size="18"
            />
          </div>
        </template>
        <b-form-input
          v-model="scheduler.time"
          type="time"
          :disabled="disabled"
        />
      </b-form-group>
    </template>
  </div>
</template>

<script>
export default {
  name: 'CronScheduler',
  props: {
    value: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      scheduler: {
        enabled: true,
        type: 'date',
        time: '00:00',
        dates: [],
        days: [],
      },
    }
  },
  computed: {
    datesOptions() {
      return [
        { label: this.$t('jobs.cron-scheduler.dates-options.1'), value: 1 },
        { label: this.$t('jobs.cron-scheduler.dates-options.2'), value: 2 },
        { label: this.$t('jobs.cron-scheduler.dates-options.3'), value: 3 },
        { label: this.$t('jobs.cron-scheduler.dates-options.4'), value: 4 },
        { label: this.$t('jobs.cron-scheduler.dates-options.5'), value: 5 },
        { label: this.$t('jobs.cron-scheduler.dates-options.6'), value: 6 },
        { label: this.$t('jobs.cron-scheduler.dates-options.7'), value: 7 },
        { label: this.$t('jobs.cron-scheduler.dates-options.8'), value: 8 },
        { label: this.$t('jobs.cron-scheduler.dates-options.9'), value: 9 },
        { label: this.$t('jobs.cron-scheduler.dates-options.10'), value: 10 },
        { label: this.$t('jobs.cron-scheduler.dates-options.11'), value: 11 },
        { label: this.$t('jobs.cron-scheduler.dates-options.12'), value: 12 },
        { label: this.$t('jobs.cron-scheduler.dates-options.13'), value: 13 },
        { label: this.$t('jobs.cron-scheduler.dates-options.14'), value: 14 },
        { label: this.$t('jobs.cron-scheduler.dates-options.15'), value: 15 },
        { label: this.$t('jobs.cron-scheduler.dates-options.16'), value: 16 },
        { label: this.$t('jobs.cron-scheduler.dates-options.17'), value: 17 },
        { label: this.$t('jobs.cron-scheduler.dates-options.18'), value: 18 },
        { label: this.$t('jobs.cron-scheduler.dates-options.19'), value: 19 },
        { label: this.$t('jobs.cron-scheduler.dates-options.20'), value: 20 },
        { label: this.$t('jobs.cron-scheduler.dates-options.21'), value: 21 },
        { label: this.$t('jobs.cron-scheduler.dates-options.22'), value: 22 },
        { label: this.$t('jobs.cron-scheduler.dates-options.23'), value: 23 },
        { label: this.$t('jobs.cron-scheduler.dates-options.24'), value: 24 },
        { label: this.$t('jobs.cron-scheduler.dates-options.25'), value: 25 },
        { label: this.$t('jobs.cron-scheduler.dates-options.26'), value: 26 },
        { label: this.$t('jobs.cron-scheduler.dates-options.27'), value: 27 },
        { label: this.$t('jobs.cron-scheduler.dates-options.28'), value: 28 },
        { label: this.$t('jobs.cron-scheduler.dates-options.29'), value: 29 },
        { label: this.$t('jobs.cron-scheduler.dates-options.30'), value: 30 },
        { label: this.$t('jobs.cron-scheduler.dates-options.31'), value: 31 },
      ]
    },
    daysOptions() {
      return [
        { label: this.$t('jobs.cron-scheduler.days-options.monday'), value: 'MON' },
        { label: this.$t('jobs.cron-scheduler.days-options.tuesday'), value: 'TUE' },
        { label: this.$t('jobs.cron-scheduler.days-options.wednesday'), value: 'WED' },
        { label: this.$t('jobs.cron-scheduler.days-options.thursday'), value: 'THU' },
        { label: this.$t('jobs.cron-scheduler.days-options.friday'), value: 'FRI' },
        { label: this.$t('jobs.cron-scheduler.days-options.saturday'), value: 'SAT' },
        { label: this.$t('jobs.cron-scheduler.days-options.sunday'), value: 'SUN' },
      ]
    },
    typeOptions() {
      return [
        { text: this.$t('jobs.cron-scheduler.type-options.days'), value: 'day' },
        { text: this.$t('jobs.cron-scheduler.type-options.dates'), value: 'date' },
      ]
    },
    cronScheduler: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit('input', v)
      },
    },
  },
  watch: {
    scheduler: {
      handler() {
        this.setCronValue()
      },
      deep: true,
    },
    'scheduler.enabled': function (v) {
      if (v) {
        this.$set(this, 'scheduler', {
          enabled: true,
          type: 'day',
          time: '00:00',
          dates: [],
          days: [],
        })
      } else {
        this.cronScheduler = ''
      }
    },
  },
  mounted() {
    this.setCronObject()
  },
  methods: {
    setCronValue() {
      if (this.disabled) return
      const [hours, minutes] = this.scheduler.time.split(':')
      let dates = ''
      let days = ''
      if (this.scheduler.type === 'date') {
        dates = this.scheduler.dates?.join(',')
        days = '?'
      }
      if (this.scheduler.type === 'day') {
        days = this.scheduler.days?.join(',')
        dates = '?'
      }
      this.cronScheduler = `0 ${Number(minutes)} ${Number(hours)} ${dates || '*'} * ${days || '*'}`
    },
    setCronObject() {
      if (!this.cronScheduler) this.scheduler.enabled = !!this.cronScheduler

      // eslint-disable-next-line no-unused-vars
      const [seconds, minutes, hours, dates, months, days] = this.cronScheduler?.split(' ')

      const prepDates = dates === '*' || dates === '?' ? [] : dates?.split(',')?.map(n => Number(n)) || []
      const prepDays = days === '*' || days === '?' ? [] : days?.split(',') || []

      if (prepDates.length) this.scheduler.type = 'date'
      if (prepDays.length) this.scheduler.type = 'day'
      this.scheduler.days = prepDays
      this.scheduler.dates = prepDates
      this.scheduler.time = `${hours < 10 ? `0${hours}` : hours}:${minutes < 10 ? `0${minutes}` : minutes}`
    },
  },
}
</script>

<style lang="scss">
.cron-scheduler {
  .vs__dropdown-menu {
    max-height: 240px;
  }

  .time-tooltip {
    display: flex;
    align-items: center;
    gap: 8px;
  }
}

</style>
