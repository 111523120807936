import i18n from '@/libs/i18n'
import { TYPES } from '@/views/models/constants'
import NumberFilterComponent from './Number.vue'

export default class NumberFilter {
  static COMPONENT = NumberFilterComponent;

  static OPERATORS = {
    equals: 'eq',
    notEquals: 'ne',
    greaterThan: 'gt',
    lowerThan: 'lt',
    between: 'bt',
    exists: 'exists',
    notExists: 'notExists',
  }

  static type = 'number'

  static options = [
    {
      label: i18n.t('filters.is'),
      value: this.OPERATORS.equals,
    },
    {
      label: i18n.t('filters.greater-than'),
      value: this.OPERATORS.greaterThan,
    },
    {
      label: i18n.t('filters.lower-than'),
      value: this.OPERATORS.lowerThan,
    },
    {
      label: i18n.t('filters.between'),
      value: this.OPERATORS.between,
    },
    {
      label: i18n.t('filters.is-not-empty'),
      value: this.OPERATORS.exists,
    },
    {
      label: i18n.t('filters.is-empty'),
      value: this.OPERATORS.notExists,
    },
  ]

  graphqlType = ''

  operator = null;

  value1 = null;

  value2 = null

  constructor({ operator = NumberFilter.OPERATORS.equals, value1, value2 } = {}, filter = {}) {
    this.operator = operator
    this.value1 = value1
    this.value2 = value2
    this.graphqlType = filter.fieldType === TYPES.INTEGER ? 'LongFilter' : 'FloatFilter'
  }

  set({ operator = NumberFilter.OPERATORS.equals, value1, value2 }) {
    this.operator = operator
    this.value1 = value1
    this.value2 = value2
  }

  getCount() {
    return 1
  }

  getValueLabel() {
    switch (this.operator) {
      case NumberFilter.OPERATORS.equals:
        return i18n.t('filters.filter-operator-label.equals', { value: this.value1 })
      case NumberFilter.OPERATORS.notEquals:
        return i18n.t('filters.filter-operator-label.not-equals', { value: this.value1 })
      case NumberFilter.OPERATORS.greaterThan:
        return i18n.t('filters.filter-operator-label.gt', { value: this.value1 })
      case NumberFilter.OPERATORS.lowerThan:
        return i18n.t('filters.filter-operator-label.lt', { value: this.value1 })
      case NumberFilter.OPERATORS.between:
        return i18n.t('filters.filter-operator-label.bt', { value1: this.value1, value2: this.value2 })
      case NumberFilter.OPERATORS.exists:
        return i18n.t('filters.is-not-empty')
      case NumberFilter.OPERATORS.notExists:
        return i18n.t('filters.is-empty')
      default:
        return null
    }
  }

  asQueryParam() {
    if (this.operator === NumberFilter.OPERATORS.exists || this.operator === NumberFilter.OPERATORS.notExists) {
      return {
        operator: this.operator,
      }
    }
    return {
      operator: this.operator,
      value1: this.value1,
      value2: this.value2,
    }
  }

  asGraphQL() {
    switch (this.operator) {
      case NumberFilter.OPERATORS.equals:
        return {
          eq: parseFloat(this.value1),
        }
      case NumberFilter.OPERATORS.notEquals:
        return {
          ne: parseFloat(this.value1),
        }
      case NumberFilter.OPERATORS.greaterThan:
        return {
          gt: parseFloat(this.value1),
        }
      case NumberFilter.OPERATORS.lowerThan:
        return {
          lt: parseFloat(this.value1),
        }
      case NumberFilter.OPERATORS.between:
        return {
          gt: parseFloat(this.value1),
          lt: parseFloat(this.value2),
        }
      case NumberFilter.OPERATORS.exists:
        return {
          exists: true,
        }
      case NumberFilter.OPERATORS.notExists:
        return {
          exists: false,
        }
      default:
        return null
    }
  }

  asGraphQLDynamicList() {
    if (this.asGraphQL()) {
      return {
        ...this.asGraphQL(),
        _t: this.graphqlType,
      }
    }
    return null
  }

  static validate({ operator, value1, value2 }) {
    if (!operator) {
      return 'Operator is required'
    }

    if (operator === NumberFilter.OPERATORS.exists || operator === NumberFilter.OPERATORS.notExists) {
      return true
    }

    if (!value1) {
      return 'Value 1 is required'
    }

    if (operator === NumberFilter.OPERATORS.between && !value2) {
      return 'Value 2 is required because operator is "between"'
    }

    return true
  }

  static fromQueryParam({ operator, value1, value2 }) {
    return new NumberFilter({
      operator,
      value1,
      value2,
    })
  }

  static shouldApplyPredefinedValue(filter) {
    const forOperators = [NumberFilter.OPERATORS.equals]
    return forOperators.includes(filter.operator)
  }

  toJSON() {
    return {
      operator: this.operator,
      value1: this.value1,
      value2: this.value2,
    }
  }
}
