<template>
  <b-form-group :label="$t('assets.edit-image.shapes-selector.label')">
    <div class="shapes">
      <div
        :class="{'selected-option': shape === 'rect'}"
        class="shape-options"
        @click="shape = 'rect'"
      >
        <RectangleFill :color="color" />
      </div>
      <div
        :class="{'selected-option': shape === 'triangle'}"
        class="shape-options"
        @click="shape = 'triangle'"
      >
        <TriangleFill :color="color" />
      </div>
      <div
        :class="{'selected-option': shape === 'circle'}"
        class="shape-options"
        @click="shape = 'circle'"
      >
        <RoundFill :color="color" />
      </div>
    </div>
  </b-form-group>
</template>

<script>
import RectangleFill from '@/components/assets/image-editor/icons/RectangleFill.vue'
import TriangleFill from '@/components/assets/image-editor/icons/TriangleFill.vue'
import RoundFill from '@/components/assets/image-editor/icons/RoundFill.vue'

export default {
  name: 'ShapeSelector',
  components: { RoundFill, TriangleFill, RectangleFill },
  props: {
    value: {
      type: String,
      default: 'rectangle',
    },
    color: {
      type: String,
      default: '',
    },
  },
  computed: {
    shape: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit('input', v)
      },
    },
  },
}
</script>

<style scoped lang="scss">
.shapes {
  display: flex;
  align-items: center;
  gap: 16px;
  justify-content: center;
}

.shape-options {
  width: 50px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.selected-option {
  border-radius: 4px;
  border: 2px solid #1D79F2;
}
</style>
