import { getCurrentSiteId } from '@/services/siteService'
import { apiAxios as axios } from '../libs/axios'
import { tc } from './config'

export const TEAMS_EFFECTS = Object.freeze({
  ALLOW: 'Allow',
  DENY: 'Deny',
})

export function teamDefaults() {
  return {
    name: '',
    description: '',
    isPredefined: false,
    members: [],
    accessControl: {
      content: {
        rules: [],
        labelsRules: [],
        permissions: [],
      },
      assets: {
        rules: [],
      },
      builder: {
        permissions: [],
      },
      administration: {
        permissions: [],
      },
    },
    status: 1,
    createdAt: '',
    updatedAt: null,
    createdBy: '',
    updatedBy: null,
  }
}

export function entryRuleDefaults() {
  return {
    effect: 'Allow',
    actions: ['All'],
    modelsFilter: {
      all: true,
      models: [],
    },
    sitesFilter: {
      all: true,
      ids: [],
    },
    entriesFilter: {
      labels: {
        all: true,
        ids: [],
      },
    },
  }
}

export const LABELS_RULES_ACTIONS = Object.freeze({
  ALL: 'All',
  ADD: 'Add',
  REMOVE: 'Remove',
})

export function labelsRuleDefaults() {
  return {
    effect: TEAMS_EFFECTS.ALLOW,
    actions: [LABELS_RULES_ACTIONS.ALL],
    labels: {
      all: true,
      ids: [],
    },
    modelsFilter: {
      all: true,
      models: [],
    },
    sitesFilter: {
      all: true,
      ids: [],
    },
    entriesFilter: {
      labels: {
        all: true,
        ids: [],
      },
    },
  }
}

export function assetRuleDefaults() {
  return {
    effect: 'Allow',
    actions: ['All'],
    foldersFilter: {
      all: true,
      ids: [],
    },
  }
}

/**
 *
 * @param {*} site
 *
 * @response
 * {
 *  id: GUID
 *  errors: null | Object
 *  success: true | false
 * }
 */
export function createTeam(team, messages) {
  return tc(axios.post('/{organization}/teams', team), {
    successTitle: 'sdk.success-title',
    successMessage: 'sdk.team.create-successful',
    failTitle: 'sdk.team.create-failed',
    ...messages,
  })
}

/**
 *
 * @param {*} section
 *
 * @response
 * {
 *  id: GUID
 *  errors: null | Object
 *  success: true | false
 * }
 */
export function updateTeam(team, messages) {
  return tc(axios.put(`/{organization}/teams/${team.id}`, team), {
    successTitle: 'sdk.success-title',
    successMessage: 'sdk.team.update-successful',
    failTitle: 'sdk.team.update-failed',
    ...messages,
  })
}

/**
 *
 * @response
 * {
 * }
 */

export function readAccessControl() {
  return axios.get('/{organization}/teams/access-control/permissions')
}

/**
 *
 * @response
 * [{
 *  id: GUID
 *  parentId: GUID | null
 *  title: String
 *  slug: String
 *  canContainArticle: Bool
 * }, {...}, ...]
 */
export function readAccessControlPermission({
  id, type, permission, extraProps,
} = {}) {
  const siteId = extraProps.siteId || getCurrentSiteId()
  return axios.post('/{organization}/teams/access-control/check-permission', {
    object: {
      type,
      id,
      siteId,
      ...extraProps,
    },
    permission,
    siteId,
    // hasPermission: Math.random() > 0.5,
  }, {
    params: {
      idd: id,
      siteId,
    },
  })
}

/**
 *
 * @response
 * {
 * id
 * }
 */

export function readTeam(id) {
  return axios.get(`/{organization}/teams/${id}`)
}

/**
 *
 * @param {*} sectionId
 *
 * @response
 * {
 *  errors: null | Object
 *  success: true | false
 * }
 */
export function deleteTeam(team, messages) {
  return tc(axios.delete(`/{organization}/teams/${team.id}`), {
    successTitle: 'sdk.success-title',
    successMessage: 'sdk.team.delete-successful',
    failTitle: 'sdk.team.delete-failed',
    catchError: false,
    ...messages,
  })
}
