import { directoryMapping, $IMAGE_SIZES, suffixMapping } from '@/utils/constants'
import { readAsset } from '@/codex-sdk/assets'

export function imageUrlFromID(id) {
  if (!id) return null
  let parts; let fileName; let suffix; let tenant; let type

  if (id.indexOf('/') !== -1 && id.indexOf('http') !== -1) {
    return [
      id,
      false,
    ]
  }

  if (id.indexOf('/') !== -1) {
    return [
      `https://noah.gjirafa.com${id.includes('/codex') ? id : `/codex${id}`}`,
      false,
    ]
  }

  if (id.startsWith('ast_')) {
    parts = id.replace('ast_', '').split('-')
    if (parts.length == 1) {
      // 1) ast_f9493638adaa4085b89386
      type = 1
      fileName = parts[0]
    } else {
      // 2) ast_f9493638adaa4085b89386-p
      type = 2
      fileName = parts[0]
      suffix = suffixMapping[parts[1]] || parts[1]
    }
  } else {
    parts = id.split('-')
    if (parts.length == 1) {
      // 1) asf9493638adaa4085b89386
      type = 1
      fileName = parts[0].slice(2)
    } else if (parts.length == 2) {
      // 3) asf9493638adaa4085b89386-p
      type = 3
      fileName = parts[0].slice(2)
      suffix = suffixMapping[parts[1]] || parts[1]
    } else {
      // 4) gjirafa-f9493638adaa4085b89386-p  /  gjirafa-stg-f9493638adaa4085b89386-p
      // No as prefix
      type = 4
      tenant = parts.slice(0, parts.length - 2).join('-')
      fileName = parts[parts.length - 2]
      suffix = suffixMapping[parts[parts.length - 1]] || parts[parts.length - 1]
    }
  }

  /**
   * Parts:
   *  tenant:   gjirafa
   *  fileName: f9493638adaa4085b89386
   *  suffix:   png
   */
  const useSize = $IMAGE_SIZES.ORIGINAL
  const imageURLs = [
    // CDN
    useSize <= $IMAGE_SIZES.THUMBNAIL_200 ? `https://${tenant}.codexcdn.net/images/${fileName}-regular.${suffix}?w=200&q=60` : '',
    useSize <= $IMAGE_SIZES.SMALL_400 ? `https://${tenant}.codexcdn.net/images/${fileName}-regular.${suffix}?w=400&q=60` : '',
    useSize <= $IMAGE_SIZES.REGULAR_1080 ? `https://${tenant}.codexcdn.net/images/${fileName}-regular.${suffix}` : '',
    useSize <= $IMAGE_SIZES.ORIGINAL ? `https://${tenant}.codexcdn.net/images/${fileName}.${suffix}` : '',

    // AMAZON
    useSize <= $IMAGE_SIZES.THUMBNAIL_200 ? `https://aws.codexcdn.net/${tenant}/images/${fileName}-regular.${suffix}?w=200&q=60` : '',
    useSize <= $IMAGE_SIZES.SMALL_400 ? `https://aws.codexcdn.net/${tenant}/images/${fileName}-regular.${suffix}?w=400&q=60` : '',
    useSize <= $IMAGE_SIZES.REGULAR_1080 ? `https://aws.codexcdn.net/${tenant}/images/${fileName}-regular.${suffix}` : '',
    useSize <= $IMAGE_SIZES.ORIGINAL ? `https://aws.codexcdn.net/${tenant}/images/${fileName}.${suffix}` : '',

    // NOAH
    useSize <= $IMAGE_SIZES.THUMBNAIL_200 ? `https://noah.gjirafa.com/codex/images/${fileName}-regular.${suffix}?w=200&q=60` : '',
    useSize <= $IMAGE_SIZES.SMALL_400 ? `https://noah.gjirafa.com/codex/images/${fileName}-regular.${suffix}?w=400&q=60` : '',
    useSize <= $IMAGE_SIZES.REGULAR_1080 ? `https://noah.gjirafa.com/codex/images/${fileName}-regular.${suffix}` : '',
    useSize <= $IMAGE_SIZES.ORIGINAL ? `https://noah.gjirafa.com/codex/images/${fileName}.${suffix}` : '',
    false,
  ].filter(url => url !== '')

  switch (type) {
    // 1) ast_f9493638adaa4085b89386
    // 1) asf9493638adaa4085b89386
    case 1:
      readAsset(id).then(({ data }) => {
        this.urls = [
          `https://noah.gjirafa.com${data.path}`,
        ]
      })

      return null

    // 2) ast_f9493638adaa4085b89386-p
    case 2:
      return imageURLs

    // 3) asf9493638adaa4085b89386-p
    case 3:
      return imageURLs
    // 4) gjirafa-f9493638adaa4085b89386-p  /  gjirafa-stg-f9493638adaa4085b89386-p
    case 4:
      return imageURLs
    default: return [false]
  }
}

export function fileUrlFromID(id) {
  if (!id) return null
  let parts; let fileName; let suffix; let tenant; let type

  if (id.indexOf('/') !== -1 && id.indexOf('http') !== -1) {
    return [
      id,
      false,
    ]
  }

  if (id.indexOf('/') !== -1) {
    return [
      `https://noah.gjirafa.com${id.includes('/codex') ? id : `/codex${id}`}`,
      false,
    ]
  }

  if (id.startsWith('ast_')) {
    parts = id.replace('ast_', '').split('-')
    if (parts.length == 1) {
      // 1) ast_f9493638adaa4085b89386
      type = 1
      fileName = parts[0]
    } else {
      // 2) ast_f9493638adaa4085b89386-p
      type = 2
      fileName = parts[0]
      suffix = suffixMapping[parts[1]] || parts[1]
    }
  } else {
    parts = id.split('-')
    if (parts.length == 1) {
      // 1) asf9493638adaa4085b89386
      type = 1
      fileName = parts[0].slice(2)
    } else if (parts.length == 2) {
      // 3) asf9493638adaa4085b89386-p
      type = 3
      fileName = parts[0].slice(2)
      suffix = suffixMapping[parts[1]] || parts[1]
    } else {
      // 4) gjirafa-f9493638adaa4085b89386-p
      // No as prefix
      type = 4
      tenant = parts.slice(0, parts.length - 2).join('-')
      fileName = parts[parts.length - 2]
      suffix = suffixMapping[parts[parts.length - 1]] || parts[parts.length - 1]
    }
  }

  /**
   * Parts:
   *  tenant:   gjirafa
   *  fileName: f9493638adaa4085b89386
   *  suffix:   png
   */

  switch (type) {
    // 1) ast_f9493638adaa4085b89386
    // 1) asf9493638adaa4085b89386
    case 1:
      readAsset(id).then(({ data }) => {
        this.urls = [
          `https://noah.gjirafa.com${data.path}`,
        ]
      })

      return null

    // 2) ast_f9493638adaa4085b89386-p
    case 2:
      return [
        `https://noah.gjirafa.com/codex/${directoryMapping(suffix)}/${fileName}.${suffix}`,
        false,
      ]

    // 3) asf9493638adaa4085b89386-p
    case 3:
      return [
        `https://noah.gjirafa.com/codex/${directoryMapping(suffix)}/${fileName}.${suffix}`,
        false,
      ]

    // 4) gjirafa-f9493638adaa4085b89386-p
    case 4:
      return [
        `https://${tenant}.codexcdn.net/${directoryMapping(suffix)}/${fileName}.${suffix}`,
        `https://aws.codexcdn.net/${tenant}/${directoryMapping(suffix)}/${fileName}.${suffix}`,
        `https://noah.gjirafa.com/codex/${directoryMapping(suffix)}/${fileName}.${suffix}`,
        false,
      ]
    default: return [false]
  }
}

export function modifyHeadingContent(html, allowedHeadings) {
  // Example: Replace any heading from 1 to 6 that is not in allowedHeadings
  for (let i = 1; i <= 6; i++) {
    if (!allowedHeadings.includes(i)) {
      const regex = new RegExp(`<h${i}[^>]*>(.*?)</h${i}>`, 'g')
      html = html.replace(regex, '<p>$1</p>')
    }
  }

  return html
}
export function removeAllStyles(html) {
  // Remove style attributes from HTML tags
  return html.replace(/<[^>]*>/g, match => match.replace(/\s*style="[^"]*"/g, ''))
}
export function removeClasses(html) {
  // Remove all classes from HTML tags
  return html.replace(/<[^>]*>/g, match => match.replace(/\s+class\s*=\s*["'][^"']*["']/g, ''))
}
export function removeNonQuillClasses(content, substring) {
  // Remove classes from HTML tags that don't start with 'ql-'
  return content.replace(/<[^>]*>/g, match => match.replace(/\s+class\s*=\s*["']([^"']*)["']/g, (classAttribute, classes) => {
    // Keep only classes starting with 'ql-'
    const filteredClasses = classes.split(/\s+/).filter(className => className.startsWith(substring)).join(' ')
    return filteredClasses.length > 0 ? ` class="${filteredClasses}"` : ''
  }))
}
