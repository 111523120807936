<template>
  <b-sidebar
    v-model="showPopup"
    :title="$t('popups.edit-author.title')"
    class="c-sidebar c-sidebar--lg"
    :no-close-on-route-change="true"
    right
    backdrop
    shadow
  >
    <Edit
      ref="editAuthor"
      :is-popup="true"
      :author-id="author.id"
    />
    <template #footer>
      <div class="author-popup__action-buttons">
        <b-button
          variant="outline-secondary"
          @click="handleCancel"
        >
          {{ $t('general.cancel') }}
        </b-button>
        <b-button
          v-permission="['canEditAuthor', author]"
          variant="primary"
          @click="handleSave"
        >
          {{ $t('general.save') }}
        </b-button>
      </div>
    </template>
  </b-sidebar>
</template>

<script>
import PopupsMixin from '@/components/popups/PopupsMixin'
import Edit from '@/views/authors/Edit.vue'

export default {
  name: 'EditAuthorPopup',
  components: { Edit },
  mixins: [PopupsMixin],
  computed: {
    author() {
      return this.data?.author || null
    },
  },
  methods: {
    handleCancel() {
      this.closePopup(false)
    },
    async handleSave() {
      try {
        await this.$store.dispatch('authors/updateAuthor', this.$refs.editAuthor.author)
      } catch (e) {
        console.log(e)
        return
      }
      this.closePopup(this.$refs.editAuthor.author)
    },
  },
}
</script>

<style lang="scss" scoped>
.author-popup__action-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
}
</style>
