//
import Vue from 'vue'
import SimpleStringFilterComponent from './SimpleString.vue'

export default class SimpleStringFilter {
  static COMPONENT = SimpleStringFilterComponent;

  static type = 'simple-string'

  value = null;

  constructor({ value } = {}) {
    this.value = value
  }

  set({ value }) {
    Vue.set(this, 'value', value)
  }

  getCount() {
    return 1
  }

  getValueLabel() {
    return this.value
  }

  asQueryParam() {
    return {
      value: this.value,
    }
  }

  asGraphQL() {
    return this.value
  }

  static validate({ value }) {
    if (!value) {
      return 'Value is required'
    }

    return true
  }

  static fromQueryParam({ value }) {
    return new SimpleStringFilter({
      value,
    })
  }
}
