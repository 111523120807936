<template>
  <div
    class="turn-into"
    :style="getTurnIntoDropdownPosition"
  >
    <div class="turn-into__list">
      <template v-for="(component, componentIndex) in components">
        <div
          :key="componentIndex"
          class="turn-into__list-item"
          @click="turnInto(component.type)"
        >
          <div class="turn-into__item-image">
            <img
              v-if="component.type"
              :src="component.icon"
            >
          </div>
          <span class="turn-into__item-content">{{ $t(component.name) }}
          </span></div>
      </template>
    </div>
  </div>
</template>

<script>
import { BLOCK_GROUPS } from '@/components/codex-editor/nodes/constants'
import { CODEX_EDITOR_BLOCKS } from '@/components/codex-editor/CodeEditorConstants'
import { intersection } from 'lodash'
import CodexContentEditor from '../../CodexContentEditor'

export default {
  props: ['editor', 'includeBlocks', 'selectedNodes'],
  data() {
    return {
      components: [],
    }
  },
  computed: {
    getTurnIntoDropdownPosition() {
      const node = this.theNode
      if (!node) return { }

      const editorDOM = this.editor.view.dom
      const editorRect = editorDOM.getBoundingClientRect()
      const rect = node.dom.getBoundingClientRect()
      const position = {
        top: `${rect.top - editorRect.top}px`,
        left: `${rect.left - editorRect.left}px`,
      }
      return position
    },
    theNode() {
      return this.selectedNodes.length === 1 ? this.selectedNodes[0] : null
    },
  },
  mounted() {
    this.getComponents()
  },
  methods: {
    async getComponents() {
      const codeBlockName = CODEX_EDITOR_BLOCKS.CODE_BLOCK
      const blocks = await Promise.all(CodexContentEditor.getRegisteredWidgets().filter(i => (((i.group === BLOCK_GROUPS.TEXTUAL || i.group[0] === BLOCK_GROUPS.TEXTUAL) && this.includeBlocks.includes(i.type) && i.type !== CODEX_EDITOR_BLOCKS.BLOCKQUOTE)) || (this.includeBlocks.includes(codeBlockName) && i.type === codeBlockName)).map(async ({
        name, displayName, type, group,
      }) => {
        let icon = false

        try {
          // eslint-disable-next-line global-require, import/no-dynamic-require
          icon = require(`../../icons/turn-into/${type}.svg`)
        } catch (e) {
          // console.log(e)
        }

        return {
          name: displayName,
          type: type || name,
          group,
          icon,
        }
      }))

      this.components = [
        ...blocks,
      ]
    },
    turnInto(type) {
      // First call to remove other types, so to return text in paragraph
      this.editor.commands[this.theNode.node.type.name]()
      // Change block to selected type
      if (type === CODEX_EDITOR_BLOCKS.HEADING) {
        const headingsOrder = [CODEX_EDITOR_BLOCKS.HEADING_2, CODEX_EDITOR_BLOCKS.HEADING_1, CODEX_EDITOR_BLOCKS.HEADING_3, CODEX_EDITOR_BLOCKS.HEADING_4, CODEX_EDITOR_BLOCKS.HEADING_5, CODEX_EDITOR_BLOCKS.HEADING_6]
        const headings = intersection(headingsOrder, this.includeBlocks)
        const level = parseInt(headings[0].slice(-1), 10)
        this.editor.commands[type]({ level })
      } else {
        this.editor.commands[type]()
      }
    },
  },
}
</script>

<style lang="scss">
.turn-into {
  position: absolute;
  width: 145px;
  max-height:240px;
  box-shadow: rgb(15 15 15 / 5%) 0px 0px 0px 1px, rgb(15 15 15 / 10%) 0px 3px 6px, rgb(15 15 15 / 20%) 0px 9px 24px;
  border-radius:4px;
  background-color: #ffffff;
  z-index:99;
  &__list{
    padding: 4px;
    &-item {
      font-size: 14px;
      color: #667c99;
      padding: 6px 4px;
      margin: 2px;
      display: flex;
      align-items: center;
      &:hover{
        cursor: pointer;
        background: #f8f8f8;
        border-radius: 5px;
      }
    }
  }
  &__item-image{
    margin-right: 6px;
  }
}
</style>
