import { apiAxios as axios } from '../libs/axios'
import { checkPermission, tc } from './config'

/**
 * Constants
 */

/**
 * Helpers
 */
export function userDefaults(user = {}) {
  return {
    fullName: '',
    email: '',
    createdAt: '',
    rolesPermissions: [],
    userPermissions: [],
    ...user,
  }
}

export function transformUserFromRequest(user) {
  user.userName = user.fullName.trim() ? user.fullName : user.email
  user.email = user.email || ''

  return user
}

export function readUsers({
  page = null, pageSize = null, query, excludeIds = [],
} = {}, site) {
  return axios.get('/{organization}/site/{site}/user', {
    params: {
      Page: page,
      Size: pageSize,
      Q: query,
      ExcludeIds: excludeIds,
      site,
    },
  }).then(response => {
    response.data.data = response.data.data.map(user => transformUserFromRequest(user))
    return response
  })
}

export function removeUserRole(userId, roleId, messages) {
  return checkPermission('canEditUser')

  && tc(axios.delete(`/{organization}/site/{site}/user/${userId}/role/${roleId}`, { params: { roleId } }), {
    successTitle: 'sdk.success-title',
    successMessage: 'sdk.site-user.remove-role-successful',
    failTitle: 'sdk.site-user.remove-role-failed',
    ...messages,
  })
}

export function removeUserRoleFromOrg(userId, roleId, siteId, messages) {
  return checkPermission('canEditUser')

  && tc(axios.delete(`/{organization}/site/${siteId}/user/${userId}/role/${roleId}`, { params: { roleId, siteId } }), {
    successTitle: 'sdk.success-title',
    successMessage: 'sdk.site-user.remove-organization-role-successful',
    failTitle: 'sdk.site-user.remove-organization-role-failed',
    ...messages,
  })
}

export function addUsers(users, messages) {
  return checkPermission('canInviteUser')

  && tc(axios.post('/{organization}/user', { users }), {
    successTitle: 'sdk.success-title',
    successMessage: 'sdk.site-user.add-users-successful',
    failTitle: 'sdk.site-user.add-users-failed',
    ...messages,
  })
}

export function updateUserSiteRoles(user, messages) {
  return checkPermission('canEditUser')

  && tc(axios.put(`/{organization}/site/{site}/user/${user.id}/role`, user), {
    successTitle: 'sdk.success-title',
    successMessage: 'sdk.site-user.update-site-roles-successful',
    failTitle: 'sdk.site-user.update-site-roles-failed',
    ...messages,
  })
}

export function updateUserSiteRolesFromOrg(user, siteId, messages) {
  return checkPermission('canEditUser')

  && tc(axios.put(`/{organization}/site/${siteId}/user/${user.id}/role`, { ...user }), {
    successTitle: 'sdk.success-title',
    successMessage: 'sdk.site-user.update-organization-role-successful',
    failTitle: 'sdk.site-user.update-organization-role-failed',
    ...messages,
  })
}

export function deleteSiteUser(userId, messages) {
  return checkPermission('canDeleteUser')

  && tc(axios.delete(`/{organization}/site/{site}/user/${userId}`), {
    successTitle: 'sdk.success-title',
    successMessage: 'sdk.site-user.delete-successful',
    failTitle: 'sdk.site-user.delete-failed',
    ...messages,
  })
}

// eslint-disable-next-line consistent-return
export async function readSiteUser(id) {
  try {
    const response = await axios.get(`/{organization}/site/{site}/user/${id}`)
    response.data = transformUserFromRequest(response.data)
    return response
  } catch (e) {
    console.log(e)
  }
}

export function readSiteUserFromOrg(siteId, id) {
  return axios.get(`/{organization}/site/${siteId}/user/${id}`).then(response => response)
}

export function updateSiteUserPermissions(user, messages) {
  return checkPermission('canEditUser')

  && tc(axios.put(`/{organization}/site/{site}/user/${user.id}/permission`, user), {
    successTitle: 'sdk.success-title',
    successMessage: 'sdk.site-user.update-permissions-successful',
    failTitle: 'sdk.site-user.update-permissions-failed',
    ...messages,
  })
}
