var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"sections-select__item",class:( _obj = {
      'sections-select__item--disabled': _vm.disabledItems.find(function (item) { return item === _vm.items.id; }),
      'sections-select__item--selected': _vm.isSelected(_vm.items),
      'sections-select__item--no-child': _vm.items.children && !_vm.search ? _vm.items.children.items.length === 0 : false,
      'sections-select__item--has-child': _vm.items.children && !_vm.search ? _vm.items.children.items.length > 0 : true ,
      'sections-select__item--checkbox': _vm.checkbox
    }, _obj[("sections-select__item-depth-" + _vm.depth)] = true, _obj ),on:{"click":function($event){!_vm.isDisabled && _vm.addOrRemoveSection(_vm.items)}}},[(_vm.checkbox)?_c('b-checkbox',{attrs:{"disabled":_vm.isDisabled,"checked":_vm.isSelected(_vm.items)}}):_vm._e(),(_vm.items.children && _vm.items.children.items.length && !_vm.search)?_c('GjIcon',{key:_vm.isOpen ? 'ArrowUp' : 'ArrowDown',attrs:{"name":_vm.isOpen ? 'ArrowUp' : 'ArrowDown',"size":"24px"},nativeOn:{"click":function($event){$event.stopPropagation();_vm.isOpen = !_vm.isOpen}}}):_vm._e(),_vm._v(" "+_vm._s(_vm.items.title)+" "),(_vm.isSelected(_vm.items) && !_vm.checkbox)?_c('GjIcon',{staticClass:"sections-select__checkmark",attrs:{"name":"Check"},nativeOn:{"click":function($event){$event.stopPropagation();}}}):_vm._e()],1),(_vm.items.children && !_vm.search)?_vm._l((_vm.items.children.items),function(item){return _c('div',{key:item.id,staticClass:"recursive-section"},[_c('div',{staticClass:"recursive-section__children",class:{'ml-1': !_vm.checkbox}},[(_vm.isOpen)?_c('Recursive-section',{attrs:{"depth":_vm.depth + 1,"checkbox":_vm.checkbox,"items":item,"include-children":_vm.includeChildren,"disabled-items":_vm.disabledItems,"parent-checked":_vm.isSelected(_vm.items)}}):_vm._e()],1)])}):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }