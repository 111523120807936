<template>
  <b-form-group
    aria-required="true"
    class="rich-text-field"
  >
    <template #label>
      <div class="codex-field__field-name-container">
        <FieldName
          :name="name"
          :help-text="helpText"
          :help-text-display="helpTextDisplay"
          :required="required"
        >
          <template #top-right>
            <span
              v-if="showCharacterCount && validation.limitCharacterCount.value"
              :class="{ 'validation-invalid': !validationLabel.isValid }"
              class="codex-field__count"
            >
              {{ validationLabel.text }}
            </span>
            <span
              v-else-if="showCharacterCount"
              class="codex-field__count"
            >
              {{ $t('fields.codex-field-rich-text.characters', { count: characterCount }) }}
            </span>
          </template>
        </FieldName>

      </div>
    </template>
    <quillEditor
      ref="myQuillEditor"
      v-model="content"
      class="codex-field__quill disable-in-quick-view"
      :data-prolexis="alias"
      :options="editorOption"
      :disabled="readOnly || !editable"
      @paste.native.capture.stop="handlePaste"
    >
      <div
        :id="id"
        slot="toolbar"
      >
        <select class="ql-header">
          <template v-for="option in header">
            <option
              v-if="option === ''"
              :key="option"
              :value="option"
            />
            <option
              v-else
              :key="option"
              :value="option"
            >
              {{ $t(`fields.codex-field-rich-text.text-format.heading-${option}`) }}
            </option>
          </template>
        </select>

        <button
          v-show="formattingOption.bold"
          :title="$t('fields.codex-field-rich-text.tooltips.bold')"
          class="ql-bold"
        />
        <button
          v-show="formattingOption.italicize"
          :title="$t('fields.codex-field-rich-text.tooltips.italic')"
          class="ql-italic"
        />
        <button
          v-show="formattingOption.underline"
          :title="$t('fields.codex-field-rich-text.tooltips.underline')"
          class="ql-underline"
        />

        <button
          v-show="formattingOption.subscript"
          ref="subscript"
          :title="$t('fields.codex-field-rich-text.tooltips.subscript')"
          class="ql-script"
          value="sub"
        />

        <button
          v-show="formattingOption.superscript"
          ref="superscript"
          :title="$t('fields.codex-field-rich-text.tooltips.superscript')"
          class="ql-script"
          value="super"
        />

        <button
          v-show="formattingOption.codeBlock"
          :title="$t('fields.codex-field-rich-text.tooltips.code')"
          class="ql-code-block"
        />

        <button
          v-show="formattingOption.bulletPoint"
          class="ql-list"
          :title="$t('fields.codex-field-rich-text.tooltips.bullet-list')"
          value="bullet"
        />

        <button
          v-show="formattingOption.numberLine"
          class="ql-list"
          :title="$t('fields.codex-field-rich-text.tooltips.ordered-list')"
          value="ordered"
        />

        <button
          v-show="formattingOption.quote"
          :title="$t('fields.codex-field-rich-text.tooltips.blockquote')"
          class="ql-blockquote"
        />

        <button
          v-show="hyperlinks.url"
          :title="$t('fields.codex-field-rich-text.tooltips.hyperlink')"
          class="ql-link"
          @click="openLinkModal"
        />
      </div>
    </quillEditor>
    <LinkModal
      v-model="showLinkModal"
      :link-text="linkText"
      :hyperlinks="hyperlinks"
      @link="linkHandler($event)"
      @closeModal="showLinkModal = false"
    />
    <FieldError :error="error" />
  </b-form-group>
</template>

<script>
/* eslint-disable import/no-extraneous-dependencies */
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'
import { generateComputedPropsFromAttrs } from '@/components/codex-layout-editor/BuilderUtils'
import { FIELD_FILTER_OPERATORS, RANGE_OPERATORS } from '@/views/models/constants'
import { ASSET_TYPES } from '@/codex-sdk/assets'
import FieldName from '@/components/fields/FieldName.vue'
import { enumMapping } from '@/utils/helpers'
import hotkeys from 'hotkeys-js'
import { debounce, uniqBy } from 'lodash'
import BaseFieldMixin from '@/components/fields/BaseFieldMixin'
import FieldRenderMixin from '@/components/fields/RenderFieldMixin'
import FieldError from '@/components/fields/FieldError.vue'
import { modifyHeadingContent, removeAllStyles, removeClasses } from './utils'
import LinkModal from './LinkModal.vue'

hotkeys.filter = function () {
  return true
}

export default {
  name: 'RichText',
  inject: ['showAssetsPopup'],
  components: {
    FieldError,
    quillEditor,
    LinkModal,
    FieldName,
  },
  mixins: [BaseFieldMixin, FieldRenderMixin],
  props: {
    value: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      ASSET_TYPES,
      id: `rich-text-editor-${Math.random()}`.replaceAll('.', ''),
      linkText: '',
      range: null,
      showLinkModal: false,
      characterCount: 0,
      setValue_: debounce(this.setValue, 500),
    }
  },
  computed: {
    ...generateComputedPropsFromAttrs([
      'name',
      'alias',
      'description',
      'helpText',
      'helpTextDisplay',
      'formattingOption',
      'hyperlinks',
      'embedded',
      'calculateMetrics',
      'validation',
      'showCharacterCount',
    ]),
    validationLabel() {
      const { length, isValid } = this.validateTextLengthRange(this.characterCount)
      const textLengthRange = this.validation.limitCharacterCount

      return {
        isValid: this.required || this.characterCount > 0 ? isValid : true,
        text: this.$t(
          `fields.codex-field-rich-text.validation-labels.${enumMapping(
            RANGE_OPERATORS,
          )
            .toString(textLengthRange.rangeOperator)
            .toLowerCase()}`,
          {
            count: length,
            min: textLengthRange.min,
            max: textLengthRange.max,
            exactly: textLengthRange.exactly,
          },
        ),
      }
    },
    content: {
      get() {
        return this.value
      },
      set(v) {
        this.setValue_(v)
      },
    },
    editorOption() {
      return {
        modules: {
          clipboard: true,
          toolbar: {
            container: `#${this.id}`,
          },
        },
        placeholder: this.$t(
          'fields.codex-field-rich-text.preview.placeholder',
        ),
        bounds: '.quill-editor',
      }
    },
    header() {
      const header = uniqBy(this.formattingOption.headers, item => item)
      return header.sort()
    },
  },
  beforeMount() {
    if (!this.widget.attrs.hidden) {
      this.$set(this.widget.attrs, 'hidden', {
        value: false,
        conditionsEnabled: false,
        conditions: [
          {
            isSystem: false,
            field: '',
            operator: FIELD_FILTER_OPERATORS.EXISTS,
            value: '',
          },
        ],
      })
    }
  },
  mounted() {
    hotkeys('ctrl+,, command+,', event => {
      if (this.formattingOption.subscript) {
        if (this.$refs.myQuillEditor.quill.hasFocus()) {
          event.preventDefault()
          this.$refs.subscript.click()
        }
      }
    })

    hotkeys('ctrl+., command+.', event => {
      if (this.formattingOption.superscript) {
        if (this.$refs.myQuillEditor.quill.hasFocus()) {
          event.preventDefault()
          this.$refs.superscript.click()
        }
      }
    })
  },
  beforeDestroy() {
    hotkeys.unbind('ctrl+,, command+,')
    hotkeys.unbind('ctrl+., command+.')
  },
  methods: {
    handlePaste(event) {
      const myQuillEditor = this.$refs.myQuillEditor

      const clipboardData = event.clipboardData || window.clipboardData
      const copiedText = clipboardData.getData('text/html')
        || clipboardData.getData('text/plain')
        || clipboardData.getData('text/uri-list')
        || clipboardData.getData('text/csv')

      if (myQuillEditor.quill.hasFocus()) {
        event.preventDefault()

        // Get the current cursor position
        const range = myQuillEditor.quill.getSelection()
        const position = range ? range.index : 0

        // Modify heading content based on this.header
        let modifiedText = modifyHeadingContent(copiedText, this.header)

        // Modify other formatting options
        modifiedText = this.modifyOtherFormatting(modifiedText)

        // If text is selected, delete the selected text
        if (range && range.length > 0) {
          myQuillEditor.quill.deleteText(range.index, range.length)
        }

        // Insert the modified content at the cursor position
        myQuillEditor.quill.clipboard.dangerouslyPasteHTML(
          position,
          modifiedText,
        )
      }
    },

    removeFormattingOption(html, tag, options) {
      const regex = new RegExp(`<${tag}(?:\\s[^>]*)?(?:>([\\s\\S]*?)<\\/${tag}>|\\s*\\/>|>)`, 'g')
      if (!options.isEnabled) {
        if (typeof options.replacement === 'function') {
          html = html.replace(regex, (_, content) => options.replacement(content))
        } else if (options.replacement) {
          html = html.replace(regex, (_, content) => options.replacement.replace('$1', content))
        } else {
          html = html.replace(regex, '')
        }
      }
      return html
    },
    modifyOtherFormatting(html) {
      const options = {
        sub: {
          isEnabled: this.formattingOption.subscript,
          label: 'sub',
          replacement: '$1',
        },
        sup: {
          isEnabled: this.formattingOption.superscript,
          label: 'sup',
          replacement: '$1',
        },
        pre: {
          isEnabled: this.formattingOption.codeBlock,
          label: 'pre',
          replacement: codeContent => `<p>${codeContent}</p>`,
        },
        blockquote: {
          isEnabled: this.formattingOption.quote,
          label: 'blockquote',
          replacement: quoteContent => `<p>${quoteContent}</p>`,
        },
        ol: {
          isEnabled: this.formattingOption.numberLine,
          label: 'ol',
          replacement: listContent => `<p>${listContent}</p>`,
        },
        ul: {
          isEnabled: this.formattingOption.bulletPoint,
          label: 'ul',
          replacement: listContent => `<p>${listContent}</p>`,
        },
        strong: {
          isEnabled: this.formattingOption.bold,
          label: 'strong',
          replacement: '$1',
        },
        em: {
          isEnabled: this.formattingOption.italicize,
          label: 'em',
          replacement: '$1',
        },
        u: {
          isEnabled: this.formattingOption.underline,
          label: 'u',
          replacement: '$1',
        },
        a: {
          isEnabled: this.hyperlinks.url,
          label: 'a',
          replacement: '$1',
        },
        // remove unsupported tags by options
        code: {
          isEnabled: false,
          label: 'code',
          replacement: '$1',
        },
        iframe: {
          isEnabled: false,
          label: 'iframe',
          replacement: '',
        },
        img: {
          isEnabled: false,
          label: 'img',
          replacement: '',
        },
        figure: {
          isEnabled: false,
          label: 'figure',
          replacement: '',
        },
      }

      Object.entries(options).forEach(([tag, elem]) => {
        html = this.removeFormattingOption(
          html,
          tag,
          elem,
        )
      })

      html = removeAllStyles(html)
      html = removeClasses(html)

      return html
    },

    setValue(value) {
      this.$emit('input', value)
    },
    countSentences(str) {
      if (!str?.length) return 0
      return str.split(/[.!?]+(?=\s|$)/).filter(s => s.trim() !== '').length
    },
    countWords(str) {
      if (!str?.length) return 0
      return str.split(/\s+/).length
    },
    countCharacters(str) {
      if (!str?.length) return 0
      return str.trim().length
    },
    readTimeEstimate(words) {
      return Math.ceil(words / 265)
    },
    getMetrics(
      counts = {
        words: 0,
        sentences: 0,
        characters: 0,
        images: 0,
      },
    ) {
      const contentToCount = this.$refs.myQuillEditor.quill

      const metrics = {
        characterCount: 0,
        imageCount: 0,
        sentenceCount: 0,
        wordCount: 0,
        readTime: 0,
      }

      const text = contentToCount.getText()?.trim()
      counts.words += this.countWords(text)
      counts.characters += this.countCharacters(text)
      this.characterCount = counts.characters
      counts.sentences += this.countSentences(text)

      const images = contentToCount.container.querySelectorAll('img')
      counts.images = images.length

      metrics.characterCount = counts.characters
      metrics.imageCount = counts.images
      metrics.sentenceCount = counts.sentences
      metrics.wordCount = counts.words
      metrics.readTime = this.readTimeEstimate(counts.words)
      if (!this.calculateMetrics) {
        metrics.characterCount = 0
        metrics.imageCount = 0
        metrics.sentenceCount = 0
        metrics.wordCount = 0
        metrics.readTime = 0

        return metrics
      }
      return metrics
    },
    validate() {
      const quill = this.$refs.myQuillEditor.quill
      const length = quill.getText().length - 1

      if (this.required && length <= 0) {
        return {
          isValid: false,
          message: this.validation.required.errorMessage,
        }
      }

      if (this.validation.limitCharacterCount.value && length > 0) {
        return this.validateTextLengthRange(length)
      }
      return { isValid: true }
    },
    validateTextLengthRange(textLength) {
      const limitCharacterCount = this.validation.limitCharacterCount
      if (
        limitCharacterCount.value
        && ((limitCharacterCount.rangeOperator === RANGE_OPERATORS.GTE
          && textLength < limitCharacterCount.min)
          || (limitCharacterCount.rangeOperator === RANGE_OPERATORS.LTE
            && textLength > limitCharacterCount.max)
          || (limitCharacterCount.rangeOperator === RANGE_OPERATORS.BETWEEN
            && (textLength < limitCharacterCount.min
              || textLength > limitCharacterCount.max))
          || (limitCharacterCount.rangeOperator === RANGE_OPERATORS.EXACTLY
            && textLength != limitCharacterCount.exactly))
      ) {
        return {
          isValid: false,
          message: limitCharacterCount.errorMessage,
          length: textLength,
        }
      }
      return { isValid: true, length: textLength }
    },
    // async embedImagesHandler() {
    //   const medias = await this.showAssetsPopup({ types: [ASSET_TYPES.IMAGE] })
    //   const quill = this.$refs.myQuillEditor.quill
    //   const range = quill.getSelection()
    //   let index = quill.scroll.length()
    //   if (range) {
    //     index = range.index
    //   }
    //   if (medias.length) {
    //     medias.forEach(media => {
    //       const imageUrl = imageUrlFromID(media.id)[0]
    //       quill.editor.insertEmbed(index, 'image', imageUrl)
    //     })
    //   }
    //   this.content = quill.root.innerHTML
    // },
    async linkHandler(event) {
      const quill = this.$refs.myQuillEditor.quill
      if (event?.text === this.linkText) {
        quill.editor.formatText(this.range.index, this.range.length, {
          link: event.link,
        })
      } else {
        quill.editor.deleteText(this.range.index, this.linkText.length)
        quill.editor.insertText(this.range.index, event.text)
        quill.editor.formatText(this.range.index, event.text.length, {
          link: event.link,
        })
      }
      this.content = quill.root.innerHTML
      this.linkText = ''
      this.range = null
    },
    openLinkModal() {
      const quill = this.$refs.myQuillEditor.quill
      quill.focus()
      this.range = quill.getSelection()
      if (this.range?.length) {
        this.linkText = quill.getText(this.range.index, this.range.length)
      } else {
        this.linkText = ''
      }
      this.showLinkModal = !this.showLinkModal
    },
  },
}
</script>

<style lang="scss">
$labels: [1, 2, 3, 4, 5, 6, ""];

.rich-text-field {
  button:focus {
    outline: none;
  }

  .validation-invalid {
    color: #e34850;
  }

  .codex-field__quill {
    background: #ffffff;
    border: 1px solid #dadfe7;
    border-radius: 4px;

    .ql-toolbar.ql-snow {
      border: none;
      border-bottom: 1px solid #dadfe7;
    }

    .ql-container.ql-snow {
      border: none;
    }

    .ql-active {
      background: #ecf3ff;
      border-radius: 4px;
      color: #1d79f2;
      margin-right: 2px;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      align-self: center;
    }

    .ql-snow .ql-picker.ql-header {
      width: 45px;
      margin-right: 2px;
    }

    @each $label in $labels {
      @if ($label !="") {
        .ql-picker-label[data-value="#{$label}"]::before {
          content: "H#{$label}" !important;
        }
      }

      @else {
        .ql-picker-label[data-value="#{$label}"]::before {
          content: "N";
        }
      }
    }

    .ql-editor.ql-blank::before {
      border: none !important;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: #a3b0c2;
    }
  }

  .richtext-field__button-text {
    padding-left: 10px;
  }

  .ql-editor {
    min-height: 120px !important;
    height: fit-content !important;
  }

  .richtext-field__dropdown-button {
    width: max-content;
    line-height: 22px;
  }
}
</style>
