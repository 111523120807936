/* eslint-disable no-use-before-define */
/* eslint-disable max-classes-per-file */
/* eslint-disable no-unused-vars */

import { Node } from 'tiptap'
import { toggleWrap, replaceText } from 'tiptap-commands'
import Vue from 'vue'
import EventEmitter from '@/components/codex-layout-editor/EventEmitter'
import { getBlockIdFromDom, selectNode } from './CodexEditorUtils'
import { BLOCK_GROUPS } from './nodes/constants'

const { capitalizeFirstLetter } = require('@/utils/helpers')

/**
 * Content Editor main class
 */
export default class CodexContentEditor extends EventEmitter {
  /* *
   *
   * Widgets
   *
   * */

  static registeredWidgets = [];

  static tiptapNodeComponents = {};

  static getRegisteredWidgets() {
    return this.registeredWidgets
  }

  static getRegisteredNodes() {
    return Object.values(this.tiptapNodeComponents)
  }

  static getWidgetObjectByType(type) {
    const widgetObject = this.registeredWidgets.find(w => w.type == type)
    return widgetObject || {
      displayName: type,
      name: type,
      type,
      settings: {
        duplicate: false,
        drag: false,
      },
      missing: true,
      group: BLOCK_GROUPS.OTHERS,
    }
  }

  static unregisterWidget(pluginName, widget) {
    const namespacedWidgetName = widget.type || `${pluginName}-${widget.name}`

    const index = this.registeredWidgets.findIndex(regWidget => regWidget.type == namespacedWidgetName)
    this.registeredWidgets.splice(index, 1)

    delete Vue.options.components[`${namespacedWidgetName}-node-view`]
    delete this.tiptapNodeComponents[namespacedWidgetName]

    console.log('Unregister: ', namespacedWidgetName)
  }

  static registerWidget(pluginName, widget) {
    // Widgets namespaced name
    const namespacedWidgetName = widget.type || (pluginName ? `${pluginName}_${widget.name}` : widget.name)

    if (!widget?.builder?.length) {
      widget.builder = ['content']
    }
    // const icon = {}
    // if (widget.icon) {
    //   try {
    //     // eslint-disable-next-line global-require, import/no-dynamic-require
    //     icon.icon = require(`@/views/layouts/widgets/_Shared/icons/${widget?.icon}`)
    //   } catch (err) {
    //     console.log(err)
    //   }
    // }

    // Push widget to list
    this.registeredWidgets.push({
      ...widget,
      // ...icon,
      displayName: widget.displayName || capitalizeFirstLetter(widget.name),
      type: namespacedWidgetName,
      widgetSidebar: widget.renderEditorPanel,
      group: widget.group || BLOCK_GROUPS.OTHERS,
    })

    // Register Block
    const createTiptapNode = widget.createTiptapNode || this.genericTipTapNode

    this.tiptapNodeComponents[namespacedWidgetName] = createTiptapNode(
      namespacedWidgetName,
      { ...widget.attrs, blockId: { default: '' } },
      this.nodeViewComponentWrapper(namespacedWidgetName, widget.renderEditor),
    )
  }

  /**
   *
   *
   * @param {string} name
   * @param {component} EditorBlock
   * @returns
   *
   *
   */

  static nodeViewComponentWrapper(name, EditorBlock) {
    return Vue.component(`${name}-node-view`, {
      components: {
        EditorBlock,
      },
      inject: ['setUpdateAttributeFunction', 'existingBlockIds', 'showBlockSidebar'],
      props: ['node', 'updateAttrs', 'editor', 'selected'],
      data() {
        return {}
      },
      mounted() {
        if (!this.existingBlockIds.includes(this.node.attrs.blockId)) {
          this.existingBlockIds.push(this.node.attrs.blockId)

          if (this.$refs.editorBlock.initialize && this.$refs.editorBlock.initialize.constructor === Function) {
            this.$refs.editorBlock.initialize()
          }
        }
        this.setUpdateAttributeFunction(this.node.attrs.blockId, this.updateAttrs)
      },
      methods: {
        selectNode(showPanel = false) {
          if (showPanel === true) {
            this.showBlockSidebar({
              component: CodexContentEditor.getWidgetObjectByType(name),
              node: this.node,
            })
          }

          /* eslint-disable */
          if (this.selected) return

          selectNode(this.node, this.editor)
        }
      },
      render (h) {
        return h('EditorBlock', {
          ref: 'editorBlock',
          attrs: {
            'data-id': this.node.attrs.blockId,
          },
          class: {
            'block-component': true,
            [name]: true,
            selected: this.selected
          },
          props: {
            widget: this.node,
            attrs: this.node.attrs,
            updateAttrs: this.updateAttrs,
            selectNode: this.selectNode,
            selected: this.selected,
          },
          nativeOn: {
            paste: (e) => e.stopPropagation(),
            click: (e) => (e.stopPropagation(), this.selectNode())
          },
        })
      }
    })
  }

  /**
   *
   *
   * @param {string} componentName
   * @param {object} componentAttrs
   * @param {component} component
   * @returns
   *
   *
   */
  static genericTipTapNode(componentName, componentAttrs, component) {
    class Component extends Node {

      get name () {
        return componentName
      }

      get schema () {
        return {
          attrs: componentAttrs,
          group: 'block',
          draggable: true,
          atom: true,
          parseDOM: [{ tag: `div[data-component='${componentName}']`,
            getAttrs: dom => {
              return {
                'data-component': componentName,
                ...JSON.parse(dom.dataset?.attrs || '{}'),
                blockId: getBlockIdFromDom(dom),
              };
            }
          }],
          toDOM: node => {
            const attrs = JSON.stringify(node.attrs);
            return ['div', {
              'data-component': componentName,
              'data-id': node.attrs.blockId,
              'data-attrs': attrs || {},
            }];
          }
        }
      }

      commands ({ type }) {
          return attrs => (state, dispatch) => {
            const { selection } = state
            const position = selection.$cursor ? selection.$cursor.pos : selection.$to.pos
            const node = type.create(attrs)
            const transaction = state.tr.insert(position, node)
            dispatch(transaction)
          }
      }

      keys ({ type }) {
        return {
          'Ctrl->': toggleWrap(type)
        }
      }

      get view () {
        return component
      }
    }

    return new Component()
  }
}


window.CodexContentEditor = CodexContentEditor;
