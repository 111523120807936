/* eslint-disable no-unused-vars */
import Asset from './constructors/Asset'
import { upload, destroyUploads, retryUpload } from './scripts/uploadFile'

export default {
  namespaced: true,
  state: {
    isHidden: false,
    isUploading: false,
    uploads: [],
    FILE_CHUNK_SIZE: 50000000, // 50000000 = 50MB
    organizationId: '',
    projectId: '',
    apiKey: '',
  },
  mutations: {
    SET_ORGANIZATION_ID: (state, organizationId) => { state.organizationId = organizationId },
    SET_PROJECT_ID: (state, projectId) => { state.projectId = projectId },
    SET_API_KEY: (state, apiKey) => { state.apiKey = apiKey },
    UPDATE_IS_UPLOADING: (state, v) => { state.isUploading = v },
    ADD_NEW_UPLOADING_FILE: (state, file) => { state.uploads.unshift(file) },
    ADD_REUPLOADING_FILE: (state, { FILE, index }) => { state.uploads.splice(index, 1, FILE) },
    CLEAR_UPLOADS: state => { state.uploads = []; state.isUploading = false },
    DELETE_OBJECT_FROM_LIST: (state, index) => { state.uploads.splice(index, 1) },
  },
  getters: {
    getUploads: state => state.uploads,
    getVPUploadIsUploading: state => state.isUploading,
    getUploadsByStatus: state => status => state.uploads.filter(x => x.status === status).length,
    getActiveUploads: state => state.uploads.filter(x => x.status !== 'success' && x.status !== 'failed').length,
    getActiveUploading: state => state.uploads.filter(x => x.status === 'uploading').length,
    getSuccessUploads: state => state.uploads.filter(x => x.status === 'success').length,
    getSuccessMediaUploads: state => state.uploads.filter(x => x.status === 'success' && (x.fileType === 'video' || x.fileType === 'audio')),
    getFailedUploads: state => state.uploads.filter(x => x.status === 'failed').length,
  },
  actions: {
    addUploadFile({ commit }, {
      file, projectId, reupload = false, index = 0,
    }) {
      if (projectId) commit('SET_PROJECT_ID', projectId)
      upload(file, { reupload, index })
    },
    addUploadingAsset({ commit }, { asset, type }) {
      const FILE = new Asset()
      FILE.fromData(asset)
      FILE.fileType = asset.file.type.split('/')?.[0]
      FILE.contentType = asset.file.type
      FILE.type = type
      commit('ADD_NEW_UPLOADING_FILE', FILE)
    },
    retryUploadFile({ commit }, {
      file, index,
    }) {
      retryUpload(file, index)
    },
    destroyUploadFiles(state) {
      state.isUploading = false
      destroyUploads()
    },
  },
}
