<template>
  <b-sidebar
    id="vp-upload-popup"
    v-model="showPopup"
    :title="$t('assets.vp-upload-popup.title')"
    class="c-sidebar c-sidebar--md"
    body-class="d-flex flex-column"
    right
    shadow
    backdrop
    no-close-on-esc
    no-close-on-backdrop
    @ok="proceedSelect"
    @close="cancelSelect"
  >
    <input
      id="at-media-upload-input"
      ref="vpUploadInput"
      type="file"
      accept="video/*, audio/*"
      multiple
      hidden
      @change="checkFileSizeAndType($event.target.files)"
    >

    <b-form-group :label="$t('assets.vp-upload-popup.project.label')">
      <vSelect
        v-model="projectId"
        label="name"
        :clearable="false"
        :options="projects"
        :reduce="e => e.value"
        :get-option-key="e => e.value"
        class=""
        :placeholder="$t('assets.vp-upload-popup.project.placeholder')"
      >
        <!-- :disabled="!!vpUploader.id" -->
        <template slot="no-options">
          {{ $t('assets.vp-upload-popup.project.no-projects') }}
        </template>
      </vSelect>
    </b-form-group>

    <div
      :class="{
        'vp-upload__wrapper': true,
        'drop-upload': true,
        'drop-upload--over': isOver,
      }"
      @dragenter.stop.prevent="dragenter"
      @dragover.stop.prevent="dragover"
      @dragleave="dragleave"
      @drop.stop.prevent="drop"
    >
      <drag-and-upload
        :max-file-size="bytesToSize(MAX_VP_UPLOAD_SIZE, 0)"
        @insert="$refs.vpUploadInput.click()"
      />
    </div>

    <p
      v-if="medias.length > 0"
      class="text-thick mt-2"
    >
      {{ $t('assets.vp-upload-popup.files') }}
    </p>

    <div
      class="d-flex flex-grow-1 w-100"
      style="overflow-y: auto;"
    >
      <div class="medias-list w-100 pb-1">
        <div
          v-for="(media, index) in medias"
          :key="index + index"
          class="medias-list__item border shadow-sm"
        >
          <div class="medias-list__item-content text-secondary">
            <div class="medias-list__item-icon">
              <GjIcon
                v-if="media.type.split('/')[0] === 'video'"
                name="CameraVideo"
              />
              <GjIcon
                v-if="media.type.split('/')[0] === 'audio'"
                name="IconparkAudioFile"
              />
            </div>
            <div class="medias-list__item-details">
              <p class="medias-list__item-name">
                {{ media.name }}
              </p>
              <small class="medias-list__item-size text-xs">
                {{ $t('assets.vp-upload-popup.file-size', { size: bytesToSize(media.size) }) }}
              </small>
            </div>
          </div>
          <div
            class="medias-list__item-delete"
            @click="removeMediaFromMedias(index)"
          >
            <GjIcon
              name="Delete"
              size="22"
            />
          </div>
        </div>
      </div>
    </div>

    <template #footer>
      <b-button
        variant="flat-dark"
        @click="cancelSelect"
      >
        {{ $t('assets.vp-upload-popup.cancel') }}
      </b-button>
      <b-button
        variant="primary"
        :disabled="isDropDisabled || !medias.length"
        @click="proceedSelect"
      >
        {{ $t('assets.vp-upload-popup.save') }}
      </b-button>
    </template>
  </b-sidebar>
</template>

<script>
import { mapGetters } from 'vuex'
import { INTEGRATION_ALIASES } from '@/codex-sdk/all-integrations'
import { readVPProjects } from '@/codex-sdk/vp-upload'
import { bytesToSize } from '@/utils/helpers'
import { MAX_VP_UPLOAD_SIZE } from '@/utils/constants'
import PopupsMixin from '@/components/popups/PopupsMixin'
import DragAndUpload from '@/components/fields/MediaContent/DragAndUpload.vue'
import { assetTypeFromMime, mapAssetTypeLabel } from '@/codex-sdk/assets'

export default {
  name: 'VPUploadPopup',
  components: { DragAndUpload },
  mixins: [PopupsMixin],
  inject: ['showConfirmPopup', 'toastNotification'],
  data() {
    return {
      isOver: false,
      dragEnterTarget: null,
      projects: [],
      projectId: null,
      medias: [],
      allowedTypes: ['video', 'audio'],
      MAX_VP_UPLOAD_SIZE,
      bytesToSize,
    }
  },
  computed: {
    ...mapGetters('general', [
      'getConnections',
    ]),
    vpUploader() {
      const vpUploaders = this.getConnections(INTEGRATION_ALIASES.VP_UPLOADER)
      return vpUploaders?.[0]
    },
    isDropDisabled() {
      return !this.projectId
    },
  },
  watch: {
    'vpUploader.attrs.organizationId': {
      handler(v) {
        if (v) {
          this.fetchVPProjects()
        }
      },
      deep: true,
    },
  },
  created() {},
  mounted() {
    this.medias.push(...this.data.medias)
    this.fetchVPProjects()
  },
  methods: {
    proceedSelect() {
      try {
        this.closePopup({
          projectId: this.projectId,
          medias: this.medias,
        })
      } catch (error) {
        console.log(error)
      }
    },
    cancelSelect() {
      this.closePopup(false)
    },
    async fetchVPProjects() {
      this.loading = true
      if (this.vpUploader.attrs.organizationId) {
        const { organizationId, apiKey } = this.vpUploader.attrs
        try {
          const response = await readVPProjects(organizationId, apiKey)
          this.projects = response.data.result
          this.projectId = this.projects?.[0]?.value
        } catch (err) {
          console.log(err)
        }
      }
    },

    /*
    **
    **  Input handle and validate
    **
    */
    async checkFileSizeAndType(files) {
      const oversizeFiles = []
      const unsupportedFiles = []
      const filteredFiles = []

      // check for oversized and unsupported files
      files.forEach((file, index) => {
        if (file.size > this.MAX_VP_UPLOAD_SIZE) {
          oversizeFiles.push(index)
        } else if (this.allowedTypes.length && !this.allowedTypes.includes(assetTypeFromMime(file.type).toLocaleLowerCase())) unsupportedFiles.push(file)
        else filteredFiles.unshift(file)
      })

      // Get confirm to continue or stop without oversized files
      if (oversizeFiles.length) {
        const confirm = await this.showConfirmPopup({
          title: this.$t('assets.filesize-alert.title'),
          description: this.$t('assets.filesize-alert.description', {
            count: oversizeFiles.length,
            total: files.length,
            maxSize: bytesToSize(this.MAX_VP_UPLOAD_SIZE, 2),
          }),
          okTitle: this.$t('assets.filesize-alert.okTitle'),
          cancelTitle: this.$t('assets.filesize-alert.cancelTitle'),
        })
        if (!confirm) {
          return
        }
      }

      // Alert about not supported file types
      if (unsupportedFiles.length > 0) {
        this.toastNotification({
          icon: 'XIcon',
          variant: 'danger',
          title: this.$t('assets.upload.unsupportedFileTypeTitle'),
          text: this.$t('assets.upload.unsupportedFileTypeDescription', { fileType: mapAssetTypeLabel(assetTypeFromMime(unsupportedFiles[0].type)) }),
        })
      }
      this.uploadFiles(filteredFiles)
    },
    removeMediaFromMedias(index) {
      this.medias.splice(index, 1)
    },

    uploadFiles(files) {
      files.forEach(file => {
        this.medias.unshift(file)
      })
    },

    /*
    **
    **  Drag&Drop events
    **
    */
    dragenter(e) {
      if (this.disabled) return
      if (!e.dataTransfer?.types?.includes('Files')) return

      this.dragEnterTarget = e.target
      this.isOver = true
    },
    dragover() {
      // Needed for drop event to fire
    },
    dragleave(e) {
      if (this.disabled) return
      if (e.target !== this.dragEnterTarget) return

      e.stopPropagation()
      e.preventDefault()
      this.dragEnterTarget = null
      this.isOver = false
    },
    drop(e) {
      if (this.disabled) return
      if (!e.dataTransfer?.types?.includes('Files')) return

      this.dragEnterTarget = null
      this.isOver = false

      const dt = e.dataTransfer
      const files = [...dt.files]

      if (this.limit && files.length > this.limit) {
        files.splice(this.limit)
      }

      this.checkFileSizeAndType(files)
    },
  },

}
</script>

<style lang="scss" scoped>
.drop-upload--over {
  border: none !important;

  &:before {
    border-radius: 8px;
  }
}

.vp-upload__wrapper {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.medias-list {
  display: flex;
  flex-direction: column;
  height: auto;
  gap: 12px;
  padding-right: 10px;

  &__item {
    display: flex;
    flex-direction: row;
    width: 100%;
    max-width: 100%;
    align-items: center;
    justify-content: space-between;
    padding: 12px;
    border-radius: 5px;

    &-content {
      display: flex;
      flex-direction: row;
      gap: 12px;
      align-items: center;
      max-width: calc(100% - 30px);
    }
    &-details {
      display: flex;
      flex-direction: column;
      gap: 4px;
      overflow: hidden;
    }
    &-name {
      margin: 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &-icon, &-delete {
      height: fit-content;
    }
    &-delete {
      cursor: pointer;
      &:hover {
        color: rgba(255, 0, 0, .75);
      }
    }
  }
}
</style>
