<template>
  <div
    ref="tagsSelect"
    class="tags-select"
    :class="[dropdownClass, { 'tags-select__open': showOptions }]"
  >
    <div
      class="tags-select__input"
      @click="showOptions = true; $emit('reFetch',true)"
      @focus.capture="$emit('reFetch',true)"
      @keydown.esc="showOptions = false"
      @keydown.tab="showOptions = false"
    >
      <div class="tags-select__tags">
        <div
          v-for="item in _value"
          :key="item.id"
          class="tags-select__tag"
          :title="item.tagAlias"
        >
          {{ item.tagValue }}
          <GjIcon
            v-if="!disabled"
            name="Close"
            class="hide-in-quick-view"
            size="18px"
            @click.native="removeItem(item)"
          />
        </div>
        <b-form-input
          v-show="!disabled"
          ref="tagsSelectInput"
          v-model="query"
          :placeholder="$t('fields.codex-field-tag.render.search-placeholder')"
          @input="activeTab = 0 "
          @keyup.enter="addTag"
        >
          <GjIcon
            name="ArrowDown_fill"
            size="16px"
            class="tags-select__search-icon"
          />
        </b-form-input>
        <BSpinner
          v-if="loading"
          style="color: #1d79f2;"
          small
        />
      </div>
    </div>
    <div
      v-if="showOptions && !disabled"
      class="tags-select__dropdown"
    >
      <b-tabs
        v-model="activeTab"
        class="tags-select__tabs"
        :class="{'tags-select__tabs--hidden': query}"
      >
        <b-tab
          v-for="tab in tagsTabs"
          :key="tab.title"
        >
          <template #title>
            {{ tab.title }}
            <AiIcon
              v-if="tab.hasIcon"
              size="18"
              color="#9747ff"
              class="mr-0 ms-1"
            />

          </template>
          <div
            v-if="tab.items.length > 0 && tagsTabs[activeTab].type == tab.type"
            class="tags-select__items"
          >
            <span
              class="tags-select__header-button"
              @click="addAllItems(tab.items)"
            >
              {{ $t('fields.codex-field-tag.render.select-all') }}
            </span>
            <div
              v-for="item in tab.items"
              :key="item.id"
              class="tags-select__item"
              :class="{'tags-select__item--active': isSelected(item) }"
              @click="addOrRemoveItem(item)"
            >
              <span class="tags-select__item-name">
                {{ item.tagValue }}
              </span>
              <span
                v-if="item.tagAlias"
                class="tags-select__item-path"
              >
                {{ item.tagAlias }}
              </span>
              <GjIcon
                v-if="isSelected(item, tab.items)"
                class="tags-select__item-tick"
                name="Check"
              />
            </div>
          </div>
          <NoElements
            v-else
            class="tags-select__no-items"
            :title="$t('fields.codex-field-tag.render.no-options')"
            icon="Tag"
          />
        </b-tab>
      </b-tabs>
    </div>
  </div>
</template>

<script>
import { mapSourceToNumber, mapSourceToString, TAG_SOURCES } from '@/codex-sdk/tags'
import { canCreateCustomTag } from '@/codex-permissions/tags'
import NoElements from '@/components/NoElements.vue'
import AiIcon from '@/components/ai/AiIcon.vue'

export default {
  name: 'TagsSelect',
  components: { NoElements, AiIcon },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    savedOptions: {
      type: Array,
      default: () => [],
    },
    geneeaOptions: {
      type: Array,
      default: () => [],
    },
    dandelionOptions: {
      type: Array,
      default: () => [],
    },
    cmifrancetagsOptions: {
      type: Array,
      default: () => [],
    },
    aigeneratedtagsOptions: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    blockId: {
      type: String,
      default: '0',
    },
    configuration: {
      type: Object,
      default: () => ({}),
    },
    widget: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      activeTab: 0,
      showOptions: false,
      query: '',
    }
  },
  computed: {
    insertParentTags() {
      return this.widget?.attrs?.configuration?.insertParentTags
    },
    dropdownClass() {
      return `tags-select-${this.blockId}`
    },
    tagsTabs() {
      return [
        {
          title: this.$t('fields.codex-field-tag.render.tag-types.saved-tags'),
          items: this.savedOptions,
          type: TAG_SOURCES.CODEX,
          hasIcon: false,
        },
        {
          title: this.$t('fields.codex-field-tag.render.tag-types.aigeneratedtags'),
          items: this.aigeneratedtagsOptions,
          type: 'AI',
          hasIcon: true,
        },
        {
          title: this.$t('fields.codex-field-tag.render.tag-types.geneea'),
          items: this.geneeaOptions,
          type: TAG_SOURCES.GENEA,
          hasIcon: false,
        },
        {
          title: this.$t('fields.codex-field-tag.render.tag-types.dandelion'),
          items: this.dandelionOptions,
          type: TAG_SOURCES.DANDELION,
          hasIcon: false,
        },
        {
          title: this.$t('fields.codex-field-tag.render.tag-types.cmifrancetags'),
          items: this.cmifrancetagsOptions,
          type: TAG_SOURCES.CMI_FRANCE,
          hasIcon: false,
        },

      ].filter(tab => this.configuration[tab.type])
    },
    _value: {
      get() {
        return this.value?.map(t => ({
          id: t.id,
          source: mapSourceToString(t.source),
          tagValue: t.tag,
          tagAlias: t.alias,
          externalId: t.externalId,
        })) || []
      },
      set(value) {
        this.$emit('input', value.map(t => ({
          id: t.id,
          source: mapSourceToNumber(t.source),
          tag: t.tagValue,
          alias: t.tagAlias,
          externalId: t.externalId,
        })))
      },
    },
  },
  watch: {
    query() {
      this.$emit('search', this.query)
    },
  },
  mounted() {
    document.addEventListener('click', this.maybeCloseTagsDropdown)
    document.addEventListener('focus', this.maybeCloseTagsDropdown)
    document.addEventListener('click', this.maybeFocusInput)
    this.$emit('ref', this.$refs.tagsSelect)
  },
  destroyed() {
    document.removeEventListener('click', this.maybeCloseTagsDropdown)
    document.addEventListener('focus', this.maybeCloseTagsDropdown)
    document.removeEventListener('click', this.maybeFocusInput)
  },
  methods: {
    addTag() {
      if (this.disabled) return
      const query = this.query?.trim()
      if (!canCreateCustomTag().can || !query?.length) return
      const tagArray = this.query?.split('/')?.map(e => e?.trim())?.filter(Boolean)
      if (!tagArray.length) return
      const tagAlias = tagArray.join('/')
      if (this._value.some(e => e.tagAlias === tagAlias)) return

      this.addItem({
        tagValue: tagArray[tagArray.length - 1],
        tagAlias,
        source: TAG_SOURCES.CODEX,
      })

      this.query = ''
    },
    isSelected(item) {
      return !!this._value.find(i => {
        if (i.tagAlias && item.tagAlias) {
          return i.tagAlias === item.tagAlias
        }
        return i.tagValue === item.tagValue
      })
    },
    itemExist(item) {
      return !!this._value.some(i => {
        if (i.tagAlias && item.tagAlias) {
          return i.tagAlias === item.tagAlias
        }
        return i.tagValue === item.tagValue
      })
    },
    removeItem(item) {
      if (this.disabled) return
      this._value = this._value.filter(i => {
        if (i.tagAlias && item.tagAlias) {
          return i.tagAlias !== item.tagAlias
        }
        return i.tagValue !== item.tagValue
      })
    },
    addItem(item) {
      if (this.disabled) return
      let parentTags = []

      if (this.insertParentTags) {
        parentTags = this.generateParentTags(item)
      }

      this._value = [...this._value, ...parentTags, item]
    },

    generateParentTags(item) {
      const tagArray = item.tagAlias.split('/')
      let parentTag = ''
      const results = []

      tagArray.forEach(tag => {
        parentTag = `${parentTag + tag}/`

        const tagAlias = parentTag.slice(0, -1)

        if (!this.itemExist({ tagAlias }) && tagAlias !== item.tagAlias) {
          results.push({
            tagValue: tag,
            tagAlias,
            source: item.source || TAG_SOURCES.CODEX,
          })
        }
      })

      return results
    },

    addOrRemoveItem(item) {
      if (this.disabled) return
      const exist = this.itemExist(item)
      if (!exist) {
        this.addItem(item)
      } else {
        this.removeItem(item)
      }
      this.query = ''
    },

    addAllItems(items) {
      if (this.disabled) return
      const tags = [...this._value]
      items.forEach(item => {
        if (!this.itemExist(item)) {
          tags.push(item)
        }
      })
      this._value = tags
      this.query = ''
    },
    maybeFocusInput() {
      if (this.showOptions) {
        this.$refs.tagsSelectInput.focus()
      }
    },
    maybeCloseTagsDropdown(e) {
      this.showOptions = !!this.$refs.tagsSelect?.contains(e.target)
    },
  },
}
</script>

<style lang="scss">
.tags-select {
  position: relative;
}

.tags-select__input {
  position: relative;
  border: 1px solid #E0E5EB;
  border-radius: 4px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 6px;
  input {
    border: 0;
    flex: 1 1 160px;
    padding: 0;
    padding-left: 8px;
    height: unset;
  }
  .spinner-border {
    position: absolute;
    right: 8px;
    top: calc(50% - 8px);
  }
}

.tags-select__search-icon {
  position: absolute;
  top: 50%;
  right: 8px;
  transform: translateY(-50%);
  transition: 0.3s all linear;
  pointer-events: none;
  @at-root .tags-select__open & {
    transform: translateY(-50%) rotate(180deg);
  }
}

.tags-select__dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: #FFFFFF;
  border: 1px solid #E0E5EB;
  box-shadow: 0px 4px 20px rgba(5, 45, 97, 0.07);
  border-radius: 4px;
  //padding: 8px 16px;
  margin-top: 4px;
  z-index: 10;
}

.tags-select__items {
  max-height: 300px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 8px 0;
  position: relative;
}
.tags-select__no-items {
  padding: 10px;
}

.tags-select__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 8px;
}

.tags-select__header-button {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #A3B0C2;
  cursor: pointer;
  margin-left: auto;
  margin-right: 8px;
  margin-bottom: -4px;
}

.tags-select__item {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  border-radius: 2px;
  padding: 6px;
  margin: 0 8px;
  position: relative;
  &.tags-select__item--active {
    background-color: #F4F6FA;
  }
  &:hover {
    background-color: #F4F6FA;
  }
}
.tags-select__item-tick {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 8px;
  color: #206ED5;
}

.tags-select__item-name {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #052D61;
}

.tags-select__item-path {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #667C99;
}

.tags-select__tabs {
  //border-bottom: 1px solid #E0E5EB;
  ul.nav {
    padding: 0 16px;
    margin-bottom: 8px;
    border-radius: 0px !important;
    gap: 24px;
    flex-wrap: nowrap;
    overflow-x:scroll;
    white-space: nowrap;
    overflow-y:hidden;
    @at-root .tags-select__tabs--hidden ul.nav.nav-tabs {
      display: none;
    }
  }

  .nav-link {
    font-weight: 600 !important;
    font-size: 12px !important;
    color: #667C99 !important;
    margin-bottom: -1px;
    padding-left: 0 !important;
    padding-right: 0 !important;
    //padding-top: 4px !important;
    text-transform: uppercase;

    &::after {
      height: 1px !important;
    }

    &.active {
      color: #206ED5 !important;
    }
  }
}
.tags-select__tags {
  display: flex;
  align-items: center;
  gap: 6px;
  flex-wrap: wrap;
}
.tags-select__tag {
  display: flex;
  align-items: center;
  padding: 3px 6px 3px 10px;
  gap: 6px;
  background: #F4F6FA;
  border: 1px solid #E0E5EB;
  border-radius: 20px;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #052D61;
  word-break: break-word;
  svg {
    cursor: pointer;
  }
}
.tags-select__no-options {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #A3B0C2;
  padding: 8px 16px;
}
</style>
