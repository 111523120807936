<template>
  <b-form-group>
    <template #label>
      <FieldName
        :name="field.name"
        :help-text="''"
      />
    </template>
    <div
      v-if="fieldContent !== null"
      class="rich-text__wrapper"
      v-html="fieldContent"
    />
    <div
      v-else
      class="content-list__empty"
    >
      <GjIcon name="EditTextAAlt" />
      <span>{{ $t('entries.merge-tool.info.no-content') }}</span>
    </div>
  </b-form-group>
</template>

<script>
import FieldName from '@/components/fields/FieldName.vue'

export default {
  name: 'RichTextField',
  components: {
    FieldName,
  },
  props: {
    fieldContent: {
      type: [String],
    },
    field: {
      type: [Object],
    },
  },
}
</script>

<style lang="scss">
.rich-text__wrapper {
  margin-top: 14px;
  padding: 20px;
  border: 1px solid #E0E5EB;
  border-radius: 4px;
}
</style>
