<template>
  <b-modal
    v-model="showPopup"
    :static="true"
    :title="$t('popups.edit-labels.title')"
    modal-class="labels-bulk-edit"
    size="sm"
    @ok="closePopup({labelAction, labels})"
  >
    <p>
      {{ $t('popups.edit-labels.select-action') }}
      <v-select
        v-model="labelAction"
        :reduce="option => option.value"
        :options="options"
        :close-on-select="true"
        :clearable="false"
      />
    </p>

    <DynamicLabels
      v-model="labels"
      :entity-type="LABEL_COMPONENT_ENTITY_TYPES.ENTRY"
      :allow-all="true"
      :check-permissions="false"
    />
  </b-modal>
</template>

<script>
import PopupsMixin from '@/components/popups/PopupsMixin'
import { BULK_EDIT_OPTIONS, mapBulkOptions } from '@/codex-sdk/entries'
import DynamicLabels from '@/components/DynamicLabels.vue'
import { LABEL_COMPONENT_ENTITY_TYPES } from '@/codex-sdk/labels'

export default {
  name: 'BulkEditLabelsPopup',
  components: {
    DynamicLabels,
  },
  mixins: [PopupsMixin],
  data() {
    return {
      labelAction: BULK_EDIT_OPTIONS.ADD_LABEL,
      options: [
        { label: mapBulkOptions(BULK_EDIT_OPTIONS.ADD_LABEL), value: BULK_EDIT_OPTIONS.ADD_LABEL },
        { label: mapBulkOptions(BULK_EDIT_OPTIONS.REMOVE_LABEL), value: BULK_EDIT_OPTIONS.REMOVE_LABEL },
      ],
      labels: [],
      LABEL_COMPONENT_ENTITY_TYPES,
    }
  },
  provide() {
    return {
      entryLabels: () => [],
    }
  },
  methods: {
    handleCancel() {
      this.showPopup = false
    },
  },
}
</script>

<style lang="scss">
</style>
