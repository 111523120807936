<template>
  <div>
    <b-button-group>
      <b-modal
        key="generic-iframe"
        v-model="showModal"
        :title="$t('codex-editor.nodes.generic-iframe.title')"
      >
        <div>
          <b-form-group
            :label="$t('codex-editor.nodes.generic-iframe.url')"
          >
            <b-input v-model="src" />
          </b-form-group>

          <b-form-group
            :label="$t('codex-editor.nodes.generic-iframe.height')"
          >
            <b-input
              v-model="height"
              type="number"
              min="1"
            />
          </b-form-group>

          <b-form-group
            :label="$t('codex-editor.nodes.generic-iframe.scrolling')"
          >
            <v-select
              v-model="scrolling"
              :clearable="false"
              :options="['auto', 'no', 'yes']"
            />
          </b-form-group>

          <b-form-group
            :label="$t('codex-editor.nodes.generic-iframe.caption')"
          >
            <b-input v-model="caption" />
          </b-form-group>

        </div>
      </b-modal>

      <b-button
        v-if="false"
        v-b-tooltip.hover.html.top="getTooltipData('edit-iframe')"
        variant="flat-primary"
        @click="showModal=true"
      >
        <GjIcon
          key="Edit"
          name="Edit"
        />
      </b-button>
    </b-button-group>
    <AlignBlockPanel
      :attrs="attrs"
      :update-attrs="updateAttrs"
    />
  </div>
</template>

<script>
import vSelect from 'vue-select'
import AlignBlockPanel from '@/components/codex-editor/panels/AlignBlockPanel.vue'

export default {
  components: {
    AlignBlockPanel,
    'v-select': vSelect,
  },
  inject: ['getTooltipData'],
  props: ['attrs', 'updateAttrs'],
  data() {
    return {
      initialSrc: '',
      showModal: false,
    }
  },
  computed: {
    src: { get() { return this.attrs.src }, set(e) { this.updateAttrs({ src: e }) } },
    height: { get() { return this.attrs.height }, set(e) { this.updateAttrs({ height: e }) } },
    scrolling: { get() { return this.attrs.scrolling }, set(e) { this.updateAttrs({ scrolling: e }) } },
    caption: { get() { return this.attrs.caption }, set(e) { this.updateAttrs({ caption: e }) } },
  },
  watch: {
    attrs: {
      handler() {
        this.initialSrc = this.attrs.src
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    setSrc() {
      this.updateAttrs({ src: this.initialSrc })
    },
  },
}
</script>
