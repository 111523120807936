<template>
  <div>
    <FieldConditions
      v-model="validation.required"
      :widget="widget"
      :label="$t('fields.general.required.label')"
      :tooltip="$t('fields.general.required.info')"
      :error-message-label="$t('fields.general.required.error-message')"
    />

    <FieldConditions
      v-model="validation.readOnly"
      :widget="widget"
      :label="$t('fields.general.read-only.label')"
      :show-error-message="false"
    />

    <b-form-group v-if="VALUE_TYPES.LIST === valueType">
      <b-form-checkbox v-model="validation.numberOfSections.isEnabled">
        <span>
          {{ $t('fields.codex-field-sections.validation.limit.placeholder') }}
        </span>
        <GjIcon
          v-b-tooltip.hover="$t('fields.codex-field-json.validation.limit-info')"
          name="Info"
          size="20"
        />
      </b-form-checkbox>
    </b-form-group>

    <template v-if="validation.numberOfSections.isEnabled && VALUE_TYPES.LIST === valueType">
      <b-form-group>
        <v-select
          v-model="validation.numberOfSections.rangeOperator"
          :options="options"
          :reduce="e => e.value"
          :clearable="false"
          @input="changeRangeOperator"
        />
      </b-form-group>
      <b-row>
        <b-col v-if="validation.numberOfSections.rangeOperator === RANGE_OPERATORS.GTE || validation.numberOfSections.rangeOperator === RANGE_OPERATORS.BETWEEN">
          <b-form-group :label="$t('fields.codex-field-sections.validation.limit.min')">
            <b-form-input
              v-model.number="validation.numberOfSections.min"
              :state="!validationError.isValid ? validationError.isValid : null"
              min="0"
              type="number"
              @keydown="validateNumber($event, validation.numberOfSections.min)"
              @input="validationError = checkRangeValidation(validation.numberOfSections)"
            />
          </b-form-group>
        </b-col>
        <b-col v-if="validation.numberOfSections.rangeOperator === RANGE_OPERATORS.LTE || validation.numberOfSections.rangeOperator === RANGE_OPERATORS.BETWEEN">
          <b-form-group :label="$t('fields.codex-field-sections.validation.limit.max')">
            <b-form-input
              v-model.number="validation.numberOfSections.max"
              :state="!validationError.isValid ? validationError.isValid : null"
              min="0"
              type="number"
              @keydown="validateNumber($event, validation.numberOfSections.max)"
              @input="validationError = checkRangeValidation(validation.numberOfSections)"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-form-group>
        <b-form-invalid-feedback :state="validationError.isValid">
          {{ $t(validationError.emptyMessage) }}
        </b-form-invalid-feedback>
        <b-form-invalid-feedback :state="validationError.isValid">
          {{ $t(validationError.differenceMessage) }}
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group>
        <template #label>
          <label>
            {{ $t('fields.codex-field-sections.validation.limit.custom-error-template-label') }}
          </label>
          <span
            id="section__inputs-allowed-tooltip"
            class="codex-field-range-tooltip"
          >
            <GjIcon
              name="Info"
              size="20"
            />
          </span>
        </template>
        <PlaceholdersTooltip
          :validation="validation.numberOfSections.rangeOperator"
          :target="'section__inputs-allowed-tooltip'"
        />
        <b-form-input
          v-model="validation.numberOfSections.errorTemplate"
          :placeholder="$t('fields.codex-field-sections.validation.limit.custom-error-template-placeholder')"
        />

      </b-form-group>

      <b-form-group
        :label="$t('fields.codex-field-sections.validation.limit.custom-error-message-label')"
        class="form-group__validation-last-field-margin"
      >
        <b-form-input
          v-model.trim="validation.numberOfSections.errorMessage"
          :placeholder="$t('fields.codex-field-sections.validation.limit.custom-error-message-placeholder')"
          readonly
        />
      </b-form-group>
    </template>
    <b-form-group>
      <b-form-checkbox v-model="validation.parentChildValidation.restrictParentChildSelection">
        <span>
          {{ $t('fields.codex-field-sections.validation.restriction.label') }}
        </span>
        <GjIcon
          v-b-tooltip.hover="$t('fields.codex-field-sections.validation.restriction.tooltip')"
          name="Info"
          size="20"
        />
      </b-form-checkbox>
    </b-form-group>
    <b-form-group
      v-if="validation.parentChildValidation.restrictParentChildSelection"
      :label="$t('fields.codex-field-sections.validation.restriction.error-message-label')"
      class="form-group__validation-last-field-margin"
    >
      <b-form-input
        v-model.trim="validation.parentChildValidation.errorMessage"
        :placeholder="$t('fields.codex-field-sections.validation.restriction.error-message-placeholder')"
        type="text"
      />
    </b-form-group>

    <b-form-group :label="VALUE_TYPES.LIST == valueType ? $t('fields.codex-field-sections.validation.editable-in') : $t('fields.codex-field-sections.validation.single-editable-in')">
      <b-form-checkbox-group
        v-model="editableIn"
        :options="statusOptions"
      />
    </b-form-group>

    <b-form-group
      v-if="VALUE_TYPES.LIST === valueType"
      :label="$t('fields.codex-field-sections.validation.main-editable-in')"
    >
      <b-form-checkbox-group
        v-model="mainEditableIn"
        :options="statusOptions"
      />
    </b-form-group>
  </div>
</template>

<script>
import { generateComputedPropsFromAttrs } from '@/components/codex-layout-editor/BuilderUtils'
import {
  RANGE_OPERATORS, checkRangeValidation, validateNumber, VALUE_TYPES, errorTemplateParser,
} from '@/views/models/constants'
import { ENTRY_STATUSES, mapEntryStatus } from '@/codex-sdk/entries'
import FieldConditions from '@/components/fields/FieldConditions.vue'
import BaseFieldMixin from '@/components/fields/BaseFieldMixin'
import PlaceholdersTooltip from '@/components/fields/PlaceholdersTooltip.vue'

export default {
  name: 'Validations',
  components: { PlaceholdersTooltip, FieldConditions },
  mixins: [BaseFieldMixin],
  data() {
    return {
      RANGE_OPERATORS,
      VALUE_TYPES,
      validateNumber,
      checkRangeValidation,
      validationError: {
        isValid: true,
        emptyMessage: '',
        differenceMessage: '',
      },
    }
  },
  computed: {
    ...generateComputedPropsFromAttrs([
      'validation',
      'valueType',
      'editableIn',
      'mainEditableIn',
    ]),
    options() {
      return [
        { label: this.$t('fields.codex-field-sections.validation.limit.between'), value: RANGE_OPERATORS.BETWEEN },
        { label: this.$t('fields.codex-field-sections.validation.limit.at-least'), value: RANGE_OPERATORS.GTE },
        { label: this.$t('fields.codex-field-sections.validation.limit.no-more-than'), value: RANGE_OPERATORS.LTE },
      ]
    },
    statusOptions() {
      return [
        { text: this.$t(mapEntryStatus(ENTRY_STATUSES.DRAFT)), value: ENTRY_STATUSES.DRAFT },
        { text: this.$t(mapEntryStatus(ENTRY_STATUSES.PUBLISHED)), value: ENTRY_STATUSES.PUBLISHED },
        { text: this.$t(mapEntryStatus(ENTRY_STATUSES.UNPUBLISHED)), value: ENTRY_STATUSES.UNPUBLISHED },
        { text: this.$t(mapEntryStatus(ENTRY_STATUSES.EDITED)), value: ENTRY_STATUSES.EDITED },
      ]
    },
  },
  watch: {
    'validation.numberOfSections': {
      handler() {
        errorTemplateParser(this.validation.numberOfSections)
      },
      deep: true,
    },
  },
  mounted() {
    if (!this.editableIn) {
      this.editableIn = []
    }
  },
  methods: {
    changeRangeOperator() {
      this.validationError = checkRangeValidation(this.validation.numberOfSections)
    },
  },
}
</script>
