var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"filtersContainer",staticClass:"new-filters-dropdown__active-filters-outter"},[_c('div',{ref:"filtersSpacer",staticClass:"new-filters-dropdown__active-filters-spacer"}),_c('div',{staticClass:"new-filters-dropdown__actions"},[_c('div',{ref:"actionsContainer",staticClass:"new-filters-dropdown__actions-inner"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.filtersNotFitInContainer.length),expression:"filtersNotFitInContainer.length"}],ref:"notFittingFiltersButton",staticClass:"new-filters-dropdown__toggle-not-fitting",class:{
          'new-filters-dropdown__toggle-not-fitting--active': _vm.showNotFittingFiltersPopper,
        },on:{"click":function($event){$event.stopPropagation();return _vm.toggleNotFittingFiltersPopper($event)}}},[_vm._v(" "+_vm._s(_vm.filtersNotFitInContainer.length)+"+ ")])])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.filtersNotFitInContainer.length && _vm.showNotFittingFiltersPopper),expression:"filtersNotFitInContainer.length && showNotFittingFiltersPopper"}],ref:"notFittingFiltersDropdown",staticClass:"new-filters-dropdown"},[_c('div',{staticClass:"new-filters-dropdown__body"},[_c('div',{staticClass:"new-filters-dropdown__items"},_vm._l((_vm.filtersNotFitInContainer),function(filterName){return _c('div',{key:filterName,ref:(filterName + "-not-fitting"),refInFor:true,staticClass:"new-filters-dropdown__item",class:{
            'new-filters-dropdown__item--active': _vm.activeFilter.filter && _vm.activeFilter.filter.name === filterName && _vm.activeFilter.location === _vm.location,
          },on:{"click":function($event){return _vm.editFilterin(filterName, (filterName + "-not-fitting"), 'left-start')}}},[(_vm.getActiveFilterBadgeLabel(filterName).count > 1)?[_c('span',{staticClass:"new-filters-dropdown__active-filter-label"},[_vm._v(" "+_vm._s(_vm.getActiveFilterBadgeLabel(filterName).label)+": ")]),_c('span',{staticClass:"new-filters-dropdown__active-filter-value"},[_vm._v(" "+_vm._s(_vm.getActiveFilterBadgeLabel(filterName).count)+" values selected ")])]:[_c('span',{staticClass:"new-filters-dropdown__active-filter-label"},[_vm._v(" "+_vm._s(_vm.getActiveFilterBadgeLabel(filterName).label)+": ")]),_c('span',{staticClass:"new-filters-dropdown__active-filter-value"},[_vm._v(" "+_vm._s(_vm.getActiveFilterBadgeLabel(filterName).value)+" ")])],_c('span',{staticClass:"new-filters-dropdown__active-filter-caret"},[_c('GjIcon',{attrs:{"name":"ArrowDown","size":"20"}})],1)],2)}),0)])]),_c('div',{staticClass:"new-filters-dropdown__active-filters"},_vm._l((_vm.activeFilters),function(filterName){return _c('div',{key:filterName,ref:filterName,refInFor:true,staticClass:"new-filters-dropdown__active-filter",class:{
        'new-filters-dropdown__active-filter--open': _vm.activeFilter.filter && _vm.activeFilter.filter.name === filterName && _vm.activeFilter.location === _vm.location,
        'new-filters-dropdown__active-filter--active': _vm.isActiveFilter(filterName),
        'new-filters-dropdown__active-filter--inactive': !_vm.isActiveFilter(filterName),
        'new-filters-dropdown__active-filter--not-fitting': !_vm.filterFitsInContainer(filterName),
      },on:{"click":function($event){return _vm.editFilterin(filterName)}}},[(!_vm.isActiveFilter(filterName))?[_c('span',{staticClass:"new-filters-dropdown__active-filter-label"},[_vm._v(" "+_vm._s(_vm.getActiveFilterBadgeLabel(filterName).label)+" ")])]:(_vm.getActiveFilterBadgeLabel(filterName).count > 1)?[_c('span',{staticClass:"new-filters-dropdown__active-filter-value"},[_vm._v(" "+_vm._s(_vm.getActiveFilterBadgeLabel(filterName).label)+" ("+_vm._s(_vm.getActiveFilterBadgeLabel(filterName).count)+") ")])]:[_c('span',{staticClass:"new-filters-dropdown__active-filter-label"},[_vm._v(" "+_vm._s(_vm.getActiveFilterBadgeLabel(filterName).label)+": ")]),_c('span',{staticClass:"new-filters-dropdown__active-filter-value"},[_vm._v(" "+_vm._s(_vm.getActiveFilterBadgeLabel(filterName).value)+" ")])],_c('span',{staticClass:"new-filters-dropdown__active-filter-caret"},[_c('GjIcon',{attrs:{"name":"ArrowDown","size":"20"}})],1)],2)}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }