import { CodeBlockHighlight } from 'tiptap-extensions'
import { toggleBlockType } from 'tiptap-commands'
import javascript from 'highlight.js/lib/languages/javascript'
import css from 'highlight.js/lib/languages/css'

import { CODEX_EDITOR_BLOCKS } from '@/components/codex-editor/CodeEditorConstants'
import { getBlockIdFromDom } from '@/components/codex-editor/CodexEditorUtils'
import CodexContentEditor from '../../CodexContentEditor'
import { BLOCK_GROUPS } from '../constants'

// eslint-disable-next-line no-unused-vars
export function paragraphTipTapNode(componentName, componentAttrs, component) {
  class CodexBlockHighlight extends CodeBlockHighlight {
    get name() {
      return componentName
    }

    get defaultOptions() {
      return {
        languages: { javascript, css },
      }
    }

    get schema() {
      return {
        attrs: componentAttrs,
        content: 'text*',
        marks: '',
        group: 'block',
        code: true,
        defining: true,
        draggable: false,
        parseDOM: [{
          tag: 'pre',
          preserveWhitespace: 'full',
          getAttrs: dom => ({
            ...JSON.parse(dom.dataset?.attrs || '{}'),
            class: 'code-highlight',
            spellcheck: 'false',
            blockId: getBlockIdFromDom(dom),
          }),
        }],
        toDOM: node => ['pre', {
          'data-id': node.attrs.blockId,
          'data-attrs': JSON.stringify(node.attrs) || '{}',
          class: 'code-highlight',
          spellcheck: 'false',
        }, ['code', 0]],
      }
    }

    commands({ type, schema }) {
      return attrs => toggleBlockType(type, schema.nodes.paragraph, attrs)
    }

    keys() {
      return {
        Tab: (state, dispatch) => {
          const { $anchor } = state.selection
          if ($anchor.parent.type.name !== CODEX_EDITOR_BLOCKS.CODE_BLOCK) return false

          dispatch(state.tr.insertText('  '))
          return true
        },
      }
    }
  }

  return new CodexBlockHighlight()
}

CodexContentEditor.registerWidget('codex', {
  displayName: 'codex-editor.block-names.code-block',
  name: 'code_block',
  group: BLOCK_GROUPS.OTHERS,
  icon: 'Paragraph',
  // eslint-disable-next-line global-require
  image: require('../../icons/paragraph/icon.svg'),
  attrs: {
    blockId: {
      default: null,
    },
  },
  createTiptapNode: paragraphTipTapNode,
})
