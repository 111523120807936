<template>
  <b-sidebar
    id="at-test-webhook-popup"
    v-model="showPopup"
    :title="$t('webhooks.test-webhook.title')"
    class="c-sidebar c-sidebar--md"
    :no-close-on-route-change="false"
    right
    shadow
    backdrop
    @close="closePopup"
  >
    <div
      v-if="loading"
      class="webhook-loader"
    >
      <b-spinner />
    </div>
    <div
      v-else-if="!loading && !webhook"
      class="webhook-not-found"
    >
      {{ $t('webhooks.test-webhook.not-found') }}
    </div>
    <div v-else-if="step === STEPS.EDIT && webhook">
      <h4>{{ $t('webhooks.test-webhook.description', { name: webhook.name }) }}</h4>
      <p>{{ $t('webhooks.test-webhook.event-description') }}</p>

      <b-form-group :label="$t('webhooks.test-webhook.event-type.label')">
        <v-select
          v-model="eventType"
          :options="eventTypes"
          :reduce="e => e.value"
          :placeholder="$t('webhooks.test-webhook.event-type.placeholder')"
          :clearable="false"
        />
        <b-form-invalid-feedback :state="!error">
          {{ $t('webhooks.test-webhook.event-type.required') }}
        </b-form-invalid-feedback>
      </b-form-group>
    </div>
    <div v-else-if="step === STEPS.LOG && log">
      <div class="top__details">
        <div class="text">
          {{ webhook.name }}
        </div>
      </div>

      <div class="middle_details">
        <div class="row">
          <div class="col">
            <div class="font-size">
              {{ $t('webhooks.test-webhook.response-status-code') }}
            </div>
            <div
              class="text font-size mt-8 webhook-responseCode-badge"
              :class="log.responseStatusCode < 400 ? 'webhook-responseCode-badge__active' : 'webhook-responseCode-badge__inactive'"
            >
              {{ mapHttpStatusCode(log.responseStatusCode) }}
            </div>
          </div>
        </div>
      </div>
      <div class="request-details">
        <p class="text m-0">
          {{ $t('webhooks.test-webhook.request-details') }}
        </p>
        <div class="request__details">
          <b-form-textarea
            :value="JSON.stringify(log.requestDetails, null, 4)"
            no-resize
            rows="9"
            disabled
            class="textarea"
          />
        </div>
      </div>
    </div>
    <b-alert
      variant="danger"
      :show="testError"
    >
      {{ $t('webhooks.test-webhook.error-occurred') }}
    </b-alert>
    <div class="text-webhooks-actions">
      <b-button
        class="text-webhooks-actions--cancel"
        @click="closePopup"
      >
        {{ $t('webhooks.test-webhook.cancel') }}
      </b-button>
      <b-button
        v-if="step == STEPS.EDIT && !loading && webhook"
        variant="primary"
        :disabled="testing"
        class="text-webhooks-actions--test"
        @click="testWebhook"
      >
        <b-spinner
          v-if="testing"
          small
        />
        {{ $t('webhooks.test-webhook.test') }}
      </b-button>
    </div>
  </b-sidebar>
</template>

<script>
import PopupsMixin from '@/components/popups/PopupsMixin'
import {
  mapHttpStatusCode, mapWebhookEventType, readWebhook, testEvent,
} from '@/codex-sdk/webhooks'

export default {
  name: 'TestWebhook',
  mixins: [PopupsMixin],
  data() {
    const STEPS = Object.freeze({ EDIT: 0, LOG: 1 })
    return {
      mapHttpStatusCode,
      STEPS,
      eventType: null,
      webhook: null,
      step: STEPS.EDIT,
      log: null,
      error: false,
      loading: false,
      testing: false,
      testError: false,
    }
  },
  computed: {
    webhookId() {
      return this.$route.params.id
    },
    eventTypes() {
      if (this.webhook?.eventTypes) {
        return this.webhook?.eventTypes?.map(event => ({
          value: event,
          label: this.$t(mapWebhookEventType(event)),
        }))
      }
      return []
    },
  },
  watch: {
    eventType() {
      this.error = false
    },
  },
  mounted() {
    if (this.webhookId) {
      this.fetchWebhook()
    }
  },
  methods: {
    async testWebhook() {
      this.testError = false
      if (!this.eventType) {
        this.error = true
        return
      }
      try {
        this.testing = true
        const { data } = await testEvent(this.webhookId, this.eventType, { successTitle: false })
        this.log = data
        this.step = this.STEPS.LOG
      } catch (e) {
        console.log(e)
        this.testError = true
      } finally {
        this.testing = false
      }
    },
    async fetchWebhook() {
      try {
        this.loading = true
        const { data } = await readWebhook(this.webhookId)
        this.webhook = data
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
  },
}
</script>
