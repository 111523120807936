import CodexLayoutError from '../CodexLayoutError'
import CodexLayoutTransaction from './Base'

/**
 * Add Block Transaction
 */
export default class AddBlock extends CodexLayoutTransaction {
  name = 'AddBlock';

  blockId = null

  addIndex = null

  blockOptions = null

  constructor(editor, blockId, addIndex, blockOptions) {
    super(editor)

    const parentBlock = this.editor.findBlockById(blockId)

    if (!parentBlock) {
      throw new CodexLayoutError(`Block '${blockId}' not found`)
    }
    if (addIndex < 0 || addIndex > parentBlock.content.length) {
      throw new CodexLayoutError('addIndex out of bounds')
    }

    this.blockId = blockId
    this.addIndex = addIndex
    this.blockOptions = blockOptions
  }

  up() {
    // Get parent block, where we'll add the block
    const parentBlock = this.editor.findBlockById(this.blockId)

    // Create block
    const block = this.editor.createBlock(this.blockOptions)

    // Lets set the ID to blockOptions, so on undo/redo we keep the same ID
    this.blockOptions.id = block.id

    // Insert block on allBlock object, so we can find it easily
    this.editor.addBlocksToAllBlocks(block)

    // Add block to parents content
    parentBlock.content.splice(this.addIndex, 0, block)

    return block
  }

  down() {
    // Get parent block, where we'll remove the block from
    const parentBlock = this.editor.findBlockById(this.blockId)

    // Remove block from parents content
    parentBlock.content.splice(this.addIndex, 1)
  }
}
