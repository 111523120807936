<template>
  <ResizableBlock
    :attrs="attrs"
    :selected="selected"
    :select-node="selectNode"
    :update-attrs="updateAttrs"
    :entity-ref="$refs.appleEmbed"
  >
    <iframe
      v-show="attrs.src"
      ref="appleEmbed"
      allow="autoplay *; encrypted-media *; fullscreen *"
      frameborder="0"
      :height="height"
      style="width:100%;overflow:hidden;background:transparent;min-height:175px;min-width:290px;"
      sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-storage-access-by-user-activation allow-top-navigation-by-user-activation"
      :src="embedSrc"
    />
  </ResizableBlock>
</template>

<script>
import ResizableBlock from '@/components/ResizableBlock.vue'

export default {
  components: {
    ResizableBlock,
  },
  props: ['attrs', 'updateAttrs', 'selectNode', 'selected'],
  inject: ['deleteBlock', 'showPromptPopup'],
  data() {
    return {
      initialSrc: '',
      resizing: null,
      width: null,
    }
  },
  computed: {
    embedSrc() {
      if (this.attrs.src) {
        return this.attrs.src.replace('podcasts', 'embed.podcasts')
      }
      return ''
    },
    height() {
      return this.attrs.src && this.attrs.src.includes('?i=') ? '175' : '450'
    },
  },
  watch: {
    src() {
      this.$nextTick(() => {
        this.selectNode()
      })
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.initialSrc = this.src
      if (this.$refs.sourceField) {
        this.$refs.sourceField.$refs.vsinput.focus()
      }
    })
  },
  methods: {
    initialize() {
      if (!this.attrs.src) {
        this.openModal()
      }
    },
    async openModal() {
      const url = await this.showPromptPopup({ title: this.$t('codex-editor.nodes.apple-podcast.podcast-url') })
      if (!url) {
        this.deleteBlock(this.attrs.blockId)
      } else {
        this.updateAttrs({
          src: url,
        })
      }
    },
    setSrc() {
      this.updateAttrs({
        src: this.initialSrc,
      })
    },
  },
}
</script>

<style scoped lang="scss">
.sourceField {
  width: 100%;
  white-space: nowrap;
}
</style>
