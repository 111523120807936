<template>
  <div
    :class="{
      'drop-upload': true,
      'drop-upload--over': isOver,
      'drop-upload--uploading': isUploading,
    }"
    @dragenter.stop.prevent="dragenter"
    @dragover.stop.prevent="dragover"
    @dragleave="dragleave"
    @drop.stop.prevent="drop"
  >
    <slot />
  </div>
</template>

<script>
import { MAX_FILE_SIZE } from '@/utils/constants'
import {
  assetDefaults, assetTypeFromMime, mapAssetTypeLabel, uploadAsset,
} from '@/codex-sdk/assets'
import { GenerateUUID, bytesToSize } from '@/utils/helpers'

export default {
  name: 'DropUpload',
  inject: ['showConfirmPopup', 'toastNotification', 'site'],
  props: {
    allowedTypes: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    limit: {
      type: Number,
      default: null,
    },
    defaultFolder: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      isOver: false,
      isUploading: false,
      dragEnterTarget: null,
    }
  },
  methods: {
    // From AssetsListing.vue
    checkFileSize(files) {
      const array = []
      files.forEach((file, index) => {
        if (file.size > MAX_FILE_SIZE) {
          array.push(index)
        }
      })
      return array
    },

    // From AssetsListing.vue
    async uploadAsset(asset) {
      const assetId = asset.id

      if (asset.uploadStatus === 'new') {
        asset.uploadStatus = 'uploading'

        this.$emit('dropUpdate', assetId, asset)

        try {
          const { data } = await uploadAsset(asset, { successTitle: false })

          if (this.allowedTypes.length && !this.allowedTypes.includes(data.type)) {
            this.$emit('dropRemove', asset)
            return asset
          }

          Object.assign(asset, data, {
            uploadStatus: 'success',
            fileName: asset.file.name,
            contentType: asset.file.type,
          })

          const org = this.$store.state.general.currentOrganization
          if (org?.storage?.id === asset.storageId) {
            asset.url = `https://${org.storage.cdnDomain}/${asset.path}?width=400&quality=80&r=fill`
          }

          this.$emit('dropUpdate', assetId, asset)

          return asset
        } catch (e) {
          this.$emit('dropRemove', asset)
          return false
        }
      }
      return null
    },

    // From AssetsListing.vue
    async uploadFiles(files) {
      this.isUploading = true

      const imagesArray = []
      const oversizeFiles = this.checkFileSize(files)

      if (oversizeFiles.length) {
        const confirm = await this.showConfirmPopup({
          title: this.$t('assets.filesize-alert.title'),
          description: this.$t('assets.filesize-alert.description', {
            count: oversizeFiles.length,
            total: files.length,
            maxSize: bytesToSize(MAX_FILE_SIZE, 2),
          }),
          okTitle: this.$t('assets.filesize-alert.okTitle'),
          cancelTitle: this.$t('assets.filesize-alert.cancelTitle'),
        })
        if (!confirm) {
          this.isUploading = false
          return
        }
      }

      // check for unsupported types
      let unsupportedFiles = []
      if (this.allowedTypes.length) {
        unsupportedFiles = Array.from(files).filter(file => !this.allowedTypes.includes(assetTypeFromMime(file.type)))
      }

      if (unsupportedFiles.length > 0) {
        this.toastNotification({
          icon: 'XIcon',
          variant: 'danger',
          title: this.$t('assets.upload.unsupportedFileTypeTitle'),
          text: this.$t('assets.upload.unsupportedFileTypeDescription', { fileType: mapAssetTypeLabel(assetTypeFromMime(unsupportedFiles[0].type)) }),
        })

        this.isUploading = false
        return
      }

      files.forEach((file, index) => {
        if (!oversizeFiles.includes(index)) {
          imagesArray.push(assetDefaults({
            file,
            folderId: this.defaultFolder,
            uploadStatus: 'new',
            id: GenerateUUID.ASSET(),
          }))
        }
      })

      this.$emit('dropInsert', imagesArray)

      const promises = await Promise.all(imagesArray.map(e => this.uploadAsset(e)))

      const success = promises.filter(e => e).length
      const total = promises.length
      if (success === total) {
        this.toastNotification({
          icon: 'Check',
          variant: 'success',
          title: this.$t('assets.upload.successTitle'),
          text: this.$t('assets.upload.successDescription'),
        })
      } else if (success > 0) {
        this.toastNotification({
          icon: 'Check',
          variant: 'success',
          title: this.$t('assets.upload.partialSuccessTitle'),
          text: this.$t('assets.upload.partialSuccessDescription', { success, total }),
        })
      } else {
        this.toastNotification({
          icon: 'XIcon',
          variant: 'danger',
          title: this.$t('assets.upload.failTitle'),
          text: this.$t('assets.upload.failDescription'),
        })
      }

      this.isUploading = false
    },

    /**
     * Drag&Drop events
     */
    dragenter(e) {
      if (this.disabled) return
      if (!e.dataTransfer?.types?.includes('Files')) return

      this.dragEnterTarget = e.target
      this.isOver = true
    },
    dragover() {
      // Needed for drop event to fire
    },
    dragleave(e) {
      if (this.disabled) return
      if (e.target !== this.dragEnterTarget) return

      e.stopPropagation()
      e.preventDefault()
      this.dragEnterTarget = null
      this.isOver = false
    },
    drop(e) {
      if (this.disabled) return
      if (!e.dataTransfer?.types?.includes('Files')) return

      this.dragEnterTarget = null
      this.isOver = false

      const dt = e.dataTransfer
      const files = [...dt.files]

      if (this.limit && files.length > this.limit) {
        files.splice(this.limit)
      }

      this.uploadFiles(files)
    },
  },
}
</script>

<style lang="scss">
.drop-upload {
  position: relative;

  &:before {
    content: '';
    inset: 0;
    position: absolute;
    border-radius: 3px;
    background-color: #206ED588;
    z-index: 10;
    display: none;
  }
}

.drop-upload--over {
  border: 1px dashed #206ED5;

  &:before {
    display: block;
  }
}
</style>
