<template>
  <div class="toolbar-right__tab-container">
    <template v-if="!loaded">
      <div class="d-flex justify-content-center">
        <b-spinner
          :label="$t('general.loading')"
          variant="primary"
        />
      </div>
    </template>
    <template v-else>
      <h6 class="toolbar-title">
        {{ $t('assets.edit-image.image-properties.title') }}
      </h6>

      <div class="toolbar-top">
        <div class="toolbar-rotate-flip d-flex">
          <b-form-group
            class="toolbar-input__group mb-0"
            label-for="#"
            :label="$t('assets.edit-image.image-properties.rotation')"
          >
            <b-button
              variant="outline-primary"
              class="toolbar-flip__button"
              @click="handleRotation(-90)"
            >
              <GjIcon name="ArrowResetRestartRotateLeft" />
            </b-button>
            <b-button
              variant="outline-primary"
              class="toolbar-flip__button"
              @click="handleRotation(90)"
            >
              <GjIcon
                name="ArrowResetRestartRotateLeft"
                style="transform: scaleX(-1)"
              />
            </b-button>
          </b-form-group>

          <b-form-group
            class="toolbar-input__group mb-0"
            label-for="#"
            :label="$t('assets.edit-image.image-properties.flip')"
          >
            <b-button
              variant="outline-primary"
              class="toolbar-flip__button"
              @click="handleFlip('flipY')"
            >
              <GjIcon name="ChevronSwapVertical" />
            </b-button>
            <b-button
              variant="outline-primary"
              class="toolbar-flip__button"
              @click="handleFlip('flipX')"
            >
              <GjIcon
                name="ChevronSwapVertical"
                style="transform: rotate(90deg)"
              />
            </b-button>
          </b-form-group>
        </div>
      </div>

      <div class="toolbar-bottom">
        <b-form-group :label="$t('assets.edit-image.image-properties.brightness')">
          <div
            class="toolbar-presets__range-text"
            data-placeholder="%"
          >
            <b-form-input
              v-model.number="brightness"
              class="toolbar-presets__range"
              type="range"
              :min="0"
              :max="100"
            />
            <b-form-input
              v-model.number="brightness"
              class="toolbar-presets__text"
              type="number"
              :min="0"
              :max="100"
            />
          </div>
        </b-form-group>
        <b-form-group :label="$t('assets.edit-image.image-properties.contrast')">
          <div
            class="toolbar-presets__range-text"
            data-placeholder="%"
          >
            <b-form-input
              v-model.number="contrast"
              class="toolbar-presets__range"
              type="range"
              :min="0"
              :max="100"
            />
            <b-form-input
              v-model.number="contrast"
              class="toolbar-presets__text"
              type="number"
              :min="0"
              :max="100"
            />
          </div>
        </b-form-group>
        <b-form-group :label="$t('assets.edit-image.image-properties.saturation')">
          <div
            class="toolbar-presets__range-text"
            data-placeholder="%"
          >
            <b-form-input
              v-model.number="saturation"
              class="toolbar-presets__range"
              type="range"
              :min="0"
              :max="100"
            />
            <b-form-input
              v-model.number="saturation"
              class="toolbar-presets__text"
              type="number"
              :min="0"
              :max="100"
            />
          </div>
        </b-form-group>
      </div>
    </template>
    <p
      class="toolbar-reset__button"
      @click="handleCancel"
    >
      {{ $t('assets.edit-image.image-properties.reset') }}
    </p>
  </div>
</template>

<script>
import { debounce } from 'lodash'

export default {
  name: 'ImageProperties',
  props: ['imageEditor', 'reference', 'loaded', 'tabIndex'],
  data() {
    return {
      rotation: 0,
      rotationDir: 0,

      presets: {
        brightness: 50,
        contrast: 50,
        saturation: 50,
      },
      dApplyFilter: debounce((filter, value) => {
        this.imageEditor.applyFilter(filter, { [filter]: value })
      }, 20),
    }
  },
  computed: {
    brightness: {
      get() { return this.presets.brightness },
      set(v) {
        this.presets.brightness = this.validateValue(v)
        this.dApplyFilter('brightness', -1 + ((this.validateValue(v) * 2) / 100))
      },
    },
    contrast: {
      get() { return this.presets.contrast },
      set(v) {
        this.presets.contrast = this.validateValue(v)
        this.dApplyFilter('contrast', -1 + ((this.validateValue(v) * 2) / 100))
      },
    },
    saturation: {
      get() { return this.presets.saturation },
      set(v) {
        this.presets.saturation = this.validateValue(v)
        this.dApplyFilter('saturation', -1 + ((this.validateValue(v) * 2) / 100))
      },
    },
  },
  watch: {
    tabIndex() {
      this.rotation = 0
      this.presets = {
        brightness: 50,
        contrast: 50,
        saturation: 50,
      }
    },
  },
  methods: {
    validateValue(v) {
      if (!v) return 0
      if (v > 100) { return 100 }
      if (v < 0) { return 0 }
      return v
    },
    async handleRotation(dir) {
      await this.imageEditor.rotate(dir)

      this.rotationDir += dir
    },
    handleFlip(type) {
      this.imageEditor[type]()
    },
    async handleCancel() {
      this.rotation = 0
      this.presets = {
        brightness: 50,
        contrast: 50,
        saturation: 50,
      }

      await this.handleRotation(-this.rotationDir)

      if (this.imageEditor.hasFilter('contrast')) {
        await this.imageEditor.removeFilter('contrast')
      }
      if (this.imageEditor.hasFilter('brightness')) {
        await this.imageEditor.removeFilter('brightness')
      }
      if (this.imageEditor.hasFilter('saturation')) {
        await this.imageEditor.removeFilter('saturation')
      }

      await this.imageEditor.resetFlip().catch(() => {})
    },
  },
}
</script>
