<template>
  <div>
    <b-button-group>
      <b-input
        v-model="initialSrc"
        :placeholder="$t('codex-editor.nodes.youtube-embed.url')"
        autocomplete="off"
        @keyup.enter="setSrc"
      />
      <b-button
        v-b-tooltip.hover.html.top="getTooltipData('save')"
        variant="flat-dark"
        @click="setSrc"
      >
        <GjIcon
          name="Save"
          size="18"
        />
      </b-button>
    </b-button-group>
    <AlignBlockPanel
      :include-stretch="true"
      :attrs="attrs"
      :update-attrs="updateAttrs"
    />
  </div>
</template>

<script>
import AlignBlockPanel from '@/components/codex-editor/panels/AlignBlockPanel.vue'
import { YouTubeGetID } from './YouTubeHelpers'

export default {
  components: { AlignBlockPanel },
  props: ['attrs', 'updateAttrs'],
  inject: ['toastNotification', 'getTooltipData'],
  data() {
    return {
      initialSrc: '',
    }
  },
  computed: {
    src: { get() { return this.attrs.src }, set(e) { this.updateAttrs({ src: e }) } },
    videoId: { get() { return this.attrs.videoId }, set(e) { this.updateAttrs({ videoId: e }) } },
  },
  watch: {
    attrs: {
      handler() {
        this.initialSrc = this.attrs.src
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    setSrc() {
      if (this.initialSrc.match(((/^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})?$/)))) {
        this.updateAttrs({
          src: this.initialSrc,
          videoId: YouTubeGetID(this.initialSrc),
        })
      } else {
        this.toastNotification({
          icon: 'XIcon',
          variant: 'danger',
          title: 'Error',
          text: this.$t('codex-editor.nodes.youtube-embed.youtube-video-invalid-url'),
        })
      }
    },
  },
}
</script>
