<template>
  <div class="ratios-tab">
    <div
      v-if="ratios.length === 0"
      class="ratios-tab__empty"
    >
      <span class="ratios-tab__empty-icon">
        <GjIcon
          name="MonitorFullScreenAlt"
        />
      </span>
      <p class="ratios-tab__empty-title">
        {{ $t('popups.ratios.forms.empty.title') }}
      </p>
      <p class="ratios-tab__empty-description">
        {{ $t('popups.ratios.forms.empty.description') }}
      </p>
    </div>
    <div class="ratios-tab__ratios">
      <div
        v-for="(ratio, index) in ratios"
        :key="ratio.name"
        class="ratios-tab__ratio"
      >
        <div class="ratios-tab__ratio-left">
          <p class="ratios-tab__ratio-name">
            {{ ratio.name }}
          </p>
          <p class="ratios-tab__ratio-value">
            {{ ratio.width }}:{{ ratio.height }}
          </p>
        </div>
        <div class="ratios-tab__ratio-right">
          <GjIcon
            size="16px"
            name="Edit"
            @click.native="editRatio(index)"
          />
          <GjIcon
            size="16px"
            name="Delete"
            @click.native="deleteRatio(ratio)"
          />
        </div>
      </div>
    </div>
    <a
      href="javascript:void(0);"
      class="ratios-tab__add"
      @click="createRatio"
    >
      {{ $t('fields.codex-field-media-content.ratios.add') }}
      <GjIcon name="Plus" />
    </a>
  </div>
</template>

<script>
import { generateComputedPropsFromAttrs } from '@/components/codex-layout-editor/BuilderUtils'
import BaseFieldMixin from '@/components/fields/BaseFieldMixin'

export default {
  name: 'Ratios',
  mixins: [BaseFieldMixin],
  inject: ['showRatiosPopup'],
  computed: {
    ...generateComputedPropsFromAttrs([
      'ratios',
    ]),
  },
  methods: {
    async editRatio(idx) {
      const data = await this.showRatiosPopup({
        allRatios: this.ratios, idx,
      })

      if (!data) return

      const index = this.ratios[idx]
      if (index) {
        Object.assign(index, data)
      }
    },
    deleteRatio(ratio) {
      this.ratios = this.ratios.filter(r => r !== ratio)
    },
    async createRatio() {
      const data = await this.showRatiosPopup({ allRatios: this.ratios })
      if (!data) return
      this.ratios.push(data)
    },
  },
}
</script>

<style lang="scss">
.ratios-tab__ratio {
  display: flex;
  justify-content: space-between;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  margin-top: 12px;
  padding: 8px 12px;

  p {
    margin: 0;
  }
}
.ratios-tab__ratio-name {
  color: #052D61;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
}
.ratios-tab__ratio-value {
  color: #455A82;
  font-size: 12px;
  font-weight: 400;
  margin-top: 4px;
}
.ratios-tab__ratio-right {
  display: flex;
  align-items: flex-start;
  gap: 8px;
  .gj-icon {
    cursor: pointer;
  }
}

.ratios-tab__empty {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  p {
    margin: 0;
  }
}
.ratios-tab__empty-icon {
  margin-bottom: 12px;
  background:  #F4F6FA;

  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #206ED5;
}
.ratios-tab__empty-title {
  color: #052D61;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}
.ratios-tab__empty-description {
  color: #667C99;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;
}

.ratios-tab__add {
  display: flex;
  align-items: center;
  color: #1D79F2;
  justify-content: center;
  margin-top: 12px;
}
</style>
