import { render, staticRenderFns } from "./UploadField.vue?vue&type=template&id=4931087c&"
import script from "./UploadField.vue?vue&type=script&lang=js&"
export * from "./UploadField.vue?vue&type=script&lang=js&"
import style0 from "./UploadField.vue?vue&type=style&index=0&id=4931087c&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports