import i18n from '@/libs/i18n'
import { TYPES } from '@/views/models/constants'
import NumberListFilterComponent from './NumberList.vue'

export default class NumberListFilter {
  static COMPONENT = NumberListFilterComponent;

  static OPERATORS = {
    all: 'all',
    some: 'some',
    exists: 'exists',
    notExists: 'notExists',
  }

  static type = 'number-list'

  static options = [
    {
      label: i18n.t('filters.all'),
      value: this.OPERATORS.all,
    },
    {
      label: i18n.t('filters.some'),
      value: this.OPERATORS.some,
    },
    {
      label: i18n.t('filters.is-not-empty'),
      value: this.OPERATORS.exists,
    },
    {
      label: i18n.t('filters.is-empty'),
      value: this.OPERATORS.notExists,
    },
  ]

  graphqlType = ''

  operator = null;

  value = null;

  constructor({ operator = NumberListFilter.OPERATORS.some, value } = {}, filter = {}) {
    this.operator = operator
    this.value = value
    this.graphqlType = filter.fieldType === TYPES.INTEGER ? 'LongListFilter' : 'FloatListFilter'
  }

  set({ operator = NumberListFilter.OPERATORS.some, value }) {
    this.operator = operator
    this.value = value
  }

  getCount() {
    return 1
  }

  getValueLabel() {
    switch (this.operator) {
      case NumberListFilter.OPERATORS.all:
        return `${i18n.t('filters.all')} "${this.value}"`
      case NumberListFilter.OPERATORS.some:
        return `${i18n.t('filters.some')} "${this.value}"`
      case NumberListFilter.OPERATORS.exists:
        return i18n.t('filters.is-not-empty')
      case NumberListFilter.OPERATORS.notExists:
        return i18n.t('filters.is-empty')
      default:
        return null
    }
  }

  asQueryParam() {
    if (this.operator === NumberListFilter.OPERATORS.exists || this.operator === NumberListFilter.OPERATORS.notExists) {
      return {
        operator: this.operator,
      }
    }
    return {
      operator: this.operator,
      value: this.value,
    }
  }

  asGraphQL() {
    switch (this.operator) {
      case NumberListFilter.OPERATORS.all:
        return {
          all: parseFloat(this.value),
        }
      case NumberListFilter.OPERATORS.some:
        return {
          some: parseFloat(this.value),
        }
      case NumberListFilter.OPERATORS.exists:
        return {
          exists: true,
        }
      case NumberListFilter.OPERATORS.notExists:
        return {
          exists: false,
        }
      default:
        return null
    }
  }

  asGraphQLDynamicList() {
    if (this.asGraphQL()) {
      return {
        ...this.asGraphQL(),
        _t: this.graphqlType,
      }
    }
    return null
  }

  static validate({ operator, value }) {
    if (!operator) {
      return 'Operator is required'
    }

    if (operator === NumberListFilter.OPERATORS.exists || operator === NumberListFilter.OPERATORS.notExists) {
      return true
    }

    if (!value) {
      return 'Value is required'
    }

    return true
  }

  static fromQueryParam({ operator, value }) {
    return new NumberListFilter({
      operator,
      value,
    })
  }

  static shouldApplyPredefinedValue(filter) {
    const forOperators = [NumberListFilter.OPERATORS.all, NumberListFilter.OPERATORS.some]
    return forOperators.includes(filter.operator)
  }

  toJSON() {
    return {
      operator: this.operator,
      value: this.value,
    }
  }
}
