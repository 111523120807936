import i18n from '@/libs/i18n'
import { secondsToHMS } from '@/utils/helpers'
import MinutesFilterComponent from './Minutes.vue'

export default class MinutesFilter {
  static COMPONENT = MinutesFilterComponent;

  static OPERATORS = {
    equals: 'eq',
    notEquals: 'ne',
    greaterThan: 'gt',
    lowerThan: 'lt',
    between: 'bt',
  }

  static type = 'minutes'

  operator = null;

  value1 = null;

  value2 = null

  constructor({ operator = MinutesFilter.OPERATORS.equals, value1, value2 } = {}) {
    this.operator = operator
    this.value1 = value1
    this.value2 = value2
  }

  set({ operator = MinutesFilter.OPERATORS.equals, value1, value2 }) {
    this.operator = operator
    this.value1 = value1
    this.value2 = value2
  }

  getCount() {
    return 1
  }

  getValueLabel() {
    let value1Label = ''
    let value2Label = ''

    {
      // block scoped
      const value1 = secondsToHMS(this.value1)

      const hoursLabel = value1.hours < 10 ? `0${value1.hours}` : value1.hours
      const minutesLabel = value1.minutes < 10 ? `0${value1.minutes}` : value1.minutes
      const secondsLabel = value1.seconds < 10 ? `0${value1.seconds}` : value1.seconds

      value1Label = i18n.t('general.date-time-format.seconds', { seconds: secondsLabel })
      if (value1.hours > 0) {
        value1Label = i18n.t('general.date-time-format.hours', { hours: hoursLabel, minutes: minutesLabel, seconds: secondsLabel })
      } else if (value1.minutes > 0) {
        value1Label = i18n.t('general.date-time-format.minutes', { minutes: minutesLabel, seconds: secondsLabel })
      }
    }

    if (this.operator === MinutesFilter.OPERATORS.between) {
      const value2 = secondsToHMS(this.value2)
      const hoursLabel = value2.hours < 10 ? `0${value2.hours}` : value2.hours
      const minutesLabel = value2.minutes < 10 ? `0${value2.minutes}` : value2.minutes
      const secondsLabel = value2.seconds < 10 ? `0${value2.seconds}` : value2.seconds

      value2Label = i18n.t('general.date-time-format.seconds', { seconds: secondsLabel })
      if (value2.hours > 0) {
        value2Label = i18n.t('general.date-time-format.hours', { hours: hoursLabel, minutes: minutesLabel, seconds: secondsLabel })
      } else if (value2.minutes > 0) {
        value2Label = i18n.t('general.date-time-format.minutes', { minutes: minutesLabel, seconds: secondsLabel })
      }
    }

    switch (this.operator) {
      case MinutesFilter.OPERATORS.equals:
        return i18n.t('filters.filter-operator-label.equals', { value: value1Label })
      case MinutesFilter.OPERATORS.notEquals:
        return i18n.t('filters.filter-operator-label.not-equals', { value: value1Label })
      case MinutesFilter.OPERATORS.greaterThan:
        return i18n.t('filters.filter-operator-label.gt', { value: value1Label })
      case MinutesFilter.OPERATORS.lowerThan:
        return i18n.t('filters.filter-operator-label.lt', { value: value1Label })
      case MinutesFilter.OPERATORS.between:
        return i18n.t('filters.filter-operator-label.bt', { value1: value1Label, value2: value2Label })
      default:
        return null
    }
  }

  asQueryParam() {
    return {
      operator: this.operator,
      value1: this.value1,
      value2: this.value2,
    }
  }

  asGraphQL() {
    switch (this.operator) {
      case MinutesFilter.OPERATORS.equals:
        return {
          gte: parseInt(this.value1, 10),
          lt: parseInt(this.value1, 10) + 1,
        }
      case MinutesFilter.OPERATORS.notEquals:
        return {
          ne: parseInt(this.value1, 10),
        }
      case MinutesFilter.OPERATORS.greaterThan:
        return {
          gt: parseInt(this.value1, 10),
        }
      case MinutesFilter.OPERATORS.lowerThan:
        return {
          lt: parseInt(this.value1, 10),
        }
      case MinutesFilter.OPERATORS.between:
        return {
          gt: parseInt(this.value1, 10),
          lt: parseInt(this.value2, 10),
        }
      default:
        return null
    }
  }

  static validate({ operator, value1, value2 }) {
    if (!operator) {
      return 'Operator is required'
    }

    if (!value1) {
      return 'Value 1 is required'
    }

    if (operator === MinutesFilter.OPERATORS.between && !value2) {
      return 'Value 2 is required because operator is "between"'
    }

    return true
  }

  static fromQueryParam({ operator, value1, value2 }) {
    return new MinutesFilter({
      operator,
      value1,
      value2,
    })
  }
}
