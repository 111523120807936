import qs from 'qs'

import { apiAxios as axios } from '../libs/axios'
import { checkPermission, tc } from './config'

/**
 * Transforms to received data
 */
export function transformAuthorFromRequest(author) {
  if (!author.socialAccounts) {
    author.socialAccounts = {}
  }
  return author
}
/**
 * Constants
 */
export const authorStatuses = Object.freeze({
  PUBLISHED: 1,
  DELETED: 2,
  UNPUBLISHED: 3,
})

export const AUTHORS = Object.freeze({
  NATIVE: 'native-author',
})

/**
 * Helpers
 */

export function authorDefaults(defaults) {
  return {
    byline: '',
    biography: '',
    profession: [],
    isCustom: true,
    status: 1,
    isUser: false,
    firstName: '',
    lastName: '',
    image: null,
    imageId: null,
    url: null,

    email: '',
    website: '',
    facebook: '',
    instagram: '',
    twitter: '',
    linkedin: '',
    telegram: '',
    tiktok: '',

    ...defaults,
  }
}

export function readAuthors({
  page = null, pageSize = null, query,
} = {}) {
  return axios.get('/{organization}/authors', {
    params: {
      Page: page,
      Size: pageSize,
      Q: query,
    },
  }).then(response => {
    response.data.data = response.data.data.map(author => transformAuthorFromRequest(author))
    return response
  })
}

/**
 *
 * @param {*} id
 *
 * @response
 * {
 *  id: GUID
 *  parentId: GUID | null
 *  title: String
 *  slug: String
 *  canContainArticle: Bool
 * }
 */
export function readAuthor(id) {
  return axios.get(`/{organization}/authors/${id}`).then(response => {
    response.data = transformAuthorFromRequest(response.data)
    return response
  })
}

export function previewAuthors({
  isNative = false,
  count = 10,
  excludeAuthorsIds = [],
  authorsIds = [],
  primaryType = null,
  secondaryType = null,
  authorsProfessions = [],
  authorsSort = 0,
}) {
  return axios.get('/{organization}/authors/preview', {
    params: {
      isNative,
      count,
      excludeAuthorsIds,
      authorsIds,
      primaryType,
      secondaryType,
      authorsProfessions,
      authorsSort,
    },
    paramsSerializer(params) {
      return qs.stringify(params, { indices: false })
    },
  }).then(response => {
    response.data.authors.forEach(author => {
      Object.assign(author, transformAuthorFromRequest(author))
    })
    return response
  })
}
/**
 * CRUD Operations
 * */

/**
 *
 * @param {*} author
 *
 * @response
 * {
 *  id: GUID
 *  errors: null | Object
 *  success: true | false
 * }
 */
export function createAuthor(author, messages) {
  return checkPermission('canCreateAuthors')

  && tc(axios.post('/{organization}/authors', author), {
    successTitle: 'sdk.success-title',
    successMessage: 'sdk.author.create-successful',
    failTitle: 'sdk.author.create-failed',
    ...messages,
  })
}

/**
 *
 * @param {*} author
 *
 * @response
 * {
 *  id: GUID
 *  errors: null | Object
 *  success: true | false
 * }
 */
export function updateAuthor(author, messages) {
  return checkPermission('canEditAuthor', { entity: author })
  && tc(axios.put(`/{organization}/authors/${author.id}`, author), {
    successTitle: 'sdk.success-title',
    successMessage: 'sdk.author.update-successful',
    failTitle: 'sdk.author.update-failed',
    catchError: false,
    ...messages,
  })
}

/**
 *
 * @param {*} authorId
 *
 * @response
 * {
 *  errors: null | Object
 *  success: true | false
 * }
 */
export function deleteAuthor(authorId, messages) {
  return checkPermission('canDeleteAuthor')

  && tc(axios.delete(`/{organization}/authors/${authorId}`), {
    successTitle: 'sdk.success-title',
    successMessage: 'sdk.author.delete-successful',
    failTitle: 'sdk.author.delete-failed',
    ...messages,
  })
}

/**
 *
 * @param {*} author
 *
 * @response
 * {
 *  errors: null | Object
 *  success: true | false
 * }
 */

export function searchAuthors({
  query, excludeIds = [], includeIds = [], page = 1, size = 20,
} = {}) {
  return axios.get('/{organization}/authors/search', {
    params: {
      Q: query,
      ExcludeIds: excludeIds,
      IncludeIds: includeIds,
      Page: page,
      Size: size,
    },
    paramsSerializer(params) {
      return qs.stringify(params, { indices: false })
    },
  })
}
