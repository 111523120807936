import { tc } from '@/codex-sdk/config'
import { merge } from 'lodash'
import i18n from '@/libs/i18n'
import { apiAxios as axios } from '../libs/axios'

/**
 * Constants
 */

// CODEX
// DANDELION
// GENEA

export const TAG_SOURCES = Object.freeze({
  CODEX: 'CODEX',
  DANDELION: 'DANDELION',
  GENEA: 'GENEA',
  CMI_FRANCE: 'CMI_FRANCE',
})

export function mapSourceToNumber(source) {
  return {
    [TAG_SOURCES.CODEX]: 0,
    [TAG_SOURCES.DANDELION]: 1,
    [TAG_SOURCES.GENEA]: 2,
    [TAG_SOURCES.CMI_FRANCE]: 3,
    0: 0,
    1: 1,
    2: 2,
    3: 3,
  }[source]
}

export function mapSourceToString(source) {
  return {
    0: TAG_SOURCES.CODEX,
    1: TAG_SOURCES.DANDELION,
    2: TAG_SOURCES.GENEA,
    3: TAG_SOURCES.CMI_FRANCE,
    [TAG_SOURCES.CODEX]: TAG_SOURCES.CODEX,
    [TAG_SOURCES.DANDELION]: TAG_SOURCES.DANDELION,
    [TAG_SOURCES.GENEA]: TAG_SOURCES.GENEA,
    [TAG_SOURCES.CMI_FRANCE]: TAG_SOURCES.CMI_FRANCE,
  }[source]
}

export const tagTypes = Object.freeze({
  ARTICLE: 1,
  AUTHOR: 2,
  ASSET: 3,
})

/**
 * Helpers
 */

export function mapTagSourceLabel(type) {
  const sources = {
    [TAG_SOURCES.CODEX]: i18n.t('content.tags.sources.codex'),
    [TAG_SOURCES.DANDELION]: i18n.t('content.tags.sources.dandelion'),
    [TAG_SOURCES.GENEA]: i18n.t('content.tags.sources.geneea'),
    [TAG_SOURCES.CMI_FRANCE]: i18n.t('content.tags.sources.cmifrancetags'),
  }
  return sources[type] || ''
}

export function tagDefaults(tag = {}) {
  return merge({
    externalId: null,
    id: null,
    parentId: null,
    path: [],
    source: TAG_SOURCES.CODEX,
    tagAlias: '',
    tagValue: '',
    references: [],
  }, tag)
}

/**
 * Transforms to received data
 */
export function transformTagFromRequest(tag) {
  if (!tag.references) tag.references = []
  tag.source = mapSourceToString(tag.source)
  return merge(tagDefaults(), tag)
}

/**
 * CRUD Operations
 * */

/**
 *
 * @response
 * [{
 *  id: GUID
 *  name: String
 * }, {...}, ...]
 */
export function readTags({ search: q, pageSize = 20, type = 1 } = {}) {
  return axios.get('/{organization}/tag', { params: { q, Size: pageSize, Type: type } }).then(response => {
    response.data = response.data.map(tag => transformTagFromRequest(tag))
    return response
  })
}

export function readTag(id) {
  return axios.get(`/{organization}/tags/${id}`).then(response => {
    response.data = transformTagFromRequest(response.data)
    return response
  })
}

export function createTag(tag, messages) {
  tag.source = mapSourceToNumber(tag.source)
  return tc(axios.post('/{organization}/tags', tag).then(response => {
    response.data = transformTagFromRequest(response.data)
    return response
  }), {
    successTitle: 'sdk.success-title',
    successMessage: 'sdk.tags.create-successful',
    failTitle: 'sdk.tags.create-failed',
    catchError: false,
    ...messages,
  })
}

export function updateTag(tag, messages = {}) {
  tag.source = mapSourceToNumber(tag.source)
  return tc(axios.put(`/{organization}/tags/${tag.id}`, tag).then(response => {
    response.data = transformTagFromRequest(response.data)
    return response
  }), {
    successTitle: 'sdk.success-title',
    successMessage: 'sdk.tags.update-successful',
    failTitle: 'sdk.tags.update-failed',
    catchError: false,
    ...messages,
  })
}

export function deleteTag(tag, messages = []) {
  return tc(axios.delete(`/{organization}/tags/${tag.id}`, {
    params: { newParentId: tag.parentId },
  }), {
    successTitle: 'sdk.success-title',
    successMessage: 'sdk.tags.delete-successful',
    failTitle: 'sdk.tags.delete-failed',
    catchError: false,
    ...messages,
  })
}

export function mergeTags(mergeData = { tags: [], target: '' }, messages = {}) {
  return tc(axios.put('/{organization}/tags/merge', mergeData), {
    successTitle: 'sdk.success-title',
    successMessage: 'sdk.tags.merge-successful',
    failTitle: 'sdk.tags.merge-failed',
    catchError: false,
    ...messages,
  })
}
