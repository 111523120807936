/* eslint-disable no-unused-vars */
// import store from '@/store'
import encode from './encode'
import {
  multipartCompleteAction,
} from '../../../codex-sdk/vp-upload'

async function multipartComplete(file, requestKey) {
  file.status = 'multipartComplete'

  multipartCompleteAction({
    requestKey,
    parts: file.uploadedParts,
  })
    .then(async () => {
      encode(file, requestKey)
    })
    .catch(err => {
      file.status = 'failed'
      file.failedStatus = 'complete'
    })
}

export default multipartComplete
