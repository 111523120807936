//
import { ENTRY_STATUSES, ENTRY_STATUSES_STRING, mapEntryStatus } from '@/codex-sdk/entries'
import i18n from '@/libs/i18n'
import EntryStatusFilterComponent from './EntryStatus.vue'

export default class EntryStatusFilter {
  static COMPONENT = EntryStatusFilterComponent;

  static type = 'entryStatus'

  static graphqlType = 'EntryStatusFilter'

  static OPERATORS = {
    includeOneOf: 'includeOneOf',
    includeAllOf: 'includeAllOf',
    exclude: 'exclude',
  }

  static OPTIONS = {
    [ENTRY_STATUSES_STRING[ENTRY_STATUSES.PUBLISHED]]: i18n.t(mapEntryStatus(ENTRY_STATUSES.PUBLISHED)),
    [ENTRY_STATUSES_STRING[ENTRY_STATUSES.SCHEDULED]]: i18n.t(mapEntryStatus(ENTRY_STATUSES.SCHEDULED)),
    [ENTRY_STATUSES_STRING[ENTRY_STATUSES.DRAFT]]: i18n.t(mapEntryStatus(ENTRY_STATUSES.DRAFT)),
    [ENTRY_STATUSES_STRING[ENTRY_STATUSES.UNPUBLISHED]]: i18n.t(mapEntryStatus(ENTRY_STATUSES.UNPUBLISHED)),
    [ENTRY_STATUSES_STRING[ENTRY_STATUSES.EDITED]]: i18n.t(mapEntryStatus(ENTRY_STATUSES.EDITED)),
  }

  operator = null;

  value = null;

  constructor({ operator = EntryStatusFilter.OPERATORS.includeOneOf, value = [] } = {}) {
    this.operator = operator
    this.value = value
  }

  set({ operator = EntryStatusFilter.OPERATORS.includeOneOf, value = [] }) {
    this.operator = operator
    this.value = value
  }

  getCount() {
    return this.value.length
  }

  getValueLabel() {
    return this.value.map(modelId => EntryStatusFilter.OPTIONS[modelId]).join(', ')
  }

  // toLoad() {
  //   return {
  //     assets: this.value,
  //   }
  // }

  asQueryParam() {
    return {
      operator: this.operator,
      value: this.value,
    }
  }

  asGraphQL() {
    return {
      in: this.value,
    }
  }

  asGraphQLDynamicList() {
    if (this.asGraphQL()) {
      return {
        ...this.asGraphQL(),
        _t: EntryStatusFilter.graphqlType,
      }
    }
    return null
  }

  static validate({ operator, value }) {
    if (!operator) {
      return 'Operator is required'
    }

    if (!value || value.length === 0) {
      return 'Value is required'
    }

    return true
  }

  static fromQueryParam({ operator, value }) {
    return new EntryStatusFilter({
      operator,
      value,
    })
  }
}
