<template>
  <div>
    <v-select
      v-model="inputValue"
      :options="models"
      label="name"
      :multiple="multiple"
      :reduce="(model) => model.id"
      :placeholder="placeholder"
      :close-on-select="closeOnSelect"
      deselect-from-dropdown
    />
  </div>
</template>

<script>
export default {
  name: 'ModelSelector',
  props: {
    value: {
      type: [String, Array, Object],
      default: null,
    },
    placeholder: {
      type: String,
      default: 'Select models',
    },
    multiple: {
      type: Boolean,
      default: true,
    },
    closeOnSelect: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    models() {
      return this.$store.state.general.allModels
    },
    inputValue: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit('input', v)
      },
    },
  },
}
</script>

<style scoped>

</style>
