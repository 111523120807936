<template>
  <div
    class="popper"
    @mouseenter="togglePop(true)"
    @mouseleave="togglePop(false)"
  >
    <div
      ref="label"
      class="popper__label"
    >
      <slot />
    </div>
    <div
      v-show="showPop"
      ref="pop"
      style="box-shadow: 0px 6px 20px 0px rgba(30, 30, 54, 0.20) !important;"
      class="popper__pop"
    >
      <slot name="pop">
        <div
          v-if="user"
          class="users-pop__content"
        >
          <div>
            <b-avatar :src="user.imageUrl" />
          </div>
          <div class="users-pop__text">
            <div class="users-pop__content-name">
              {{ user.fullName }}
            </div>
            <div class="users-pop__content-email">
              {{ user.email }}
            </div>
          </div>
        </div>
      </slot>
    </div>
  </div>
</template>

<script>
import { createPopper } from '@popperjs/core'

export default {
  name: 'UsersData',
  props: ['user'],
  data() {
    return {
      showPop: false,
      popper: null,
      timeoutId: null,
    }
  },
  mounted() {
    this.popper = createPopper(this.$refs.label, this.$refs.pop, {
      placement: 'bottom-start',
      strategy: 'fixed',
      modifiers: [
        {
          name: 'preventOverflow',
          options: {
            boundary: 'viewport',
          },
        },
      ],
    })

    this.$nextTick(() => {
      this.popper.update()
    })
  },
  methods: {
    togglePop(val) {
      if (val) {
        this.timeoutId = setTimeout(() => {
          this.showPop = val

          this.$nextTick(() => {
            this.popper.update()
          })
        }, 500)
      } else {
        clearTimeout(this.timeoutId)
        this.showPop = val

        this.$nextTick(() => {
          this.popper.update()
        })
      }
    },
  },
}
</script>

<style lang="scss">

div.popper__pop {
  border: 1px solid #DEDEE2;
  background: #FFF;
  box-shadow: 0px 6px 20px 0px rgba(30, 30, 54, 0.20) !important;
  border-radius: 4px;
  z-index: 100;
}
.users-pop__content {
  display: flex;
  gap: 8px;
  padding: 12px 14px;

  .b-avatar{
    width: 40px;
    height: 40px;
  }
}
.users-pop__content-name {
  color: #1E1E36;
  font-size: 14px;
  font-weight: 500;
}
.users-pop__content-email {
  color: #9595A0;
  font-size: 14px;
  font-weight: 400;
  margin-top: -2px;
}
.users-pop__text {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
</style>
