import { FIELD_GROUPS } from '@/codex-sdk/models'
import { registerField } from '@/components/codex-layout-editor/BuilderUtils'
import { FIELD_FILTER_OPERATORS, TYPES, VALUE_TYPES } from '@/views/models/constants'
import Render from './Render.vue'
import Initial from './Initial.vue'
import General from './General.vue'
import Appearance from './Appearance.vue'
import Validation from './Validation.vue'
import Configuration from './Configuration.vue'

registerField('codex_field', {
  displayName: 'Boolean',
  name: 'boolean',
  render: Render,
  renderEditor: Render,
  renderEditorPanel: [
    {
      icon: 'Settings', label: 'fields.codex-field-boolean.tabs.general', component: General, isGeneral: true,
    },
    { icon: 'Brush', label: 'fields.codex-field-boolean.tabs.appearance', component: Appearance },
    { icon: 'Tasks', label: 'fields.codex-field-boolean.tabs.validations', component: Validation },
    { icon: 'SettingsPreferencesTools', label: 'fields.codex-field-boolean.tabs.configuration', component: Configuration },
  ],
  renderEditorInitialPanel: [
    { icon: 'Settings', label: 'fields.codex-field-boolean.tabs.initial', component: Initial },
  ],
  group: FIELD_GROUPS.BASIC,
  layoutTypes: null,
  icon: 'field-boolean.png',

  type: TYPES.BOOLEAN,
  valueType: VALUE_TYPES.SINGLE,
  defaultValue: false,

  attrs: {
    validation: {
      default: {
        required: {
          value: false,
          errorMessage: 'Boolean is required.',
          conditionsEnabled: false,
          conditions: [
            {
              isSystem: false,
              field: '',
              operator: FIELD_FILTER_OPERATORS.EXISTS,
              value: '',
            },
          ],
        },
        readOnly: {
          value: false,
          conditionsEnabled: false,
          conditions: [
            {
              isSystem: false,
              field: '',
              operator: FIELD_FILTER_OPERATORS.EXISTS,
              value: '',
            },
          ],
        },
      },
    },
    appearance: {
      default: 1,
    },
    trueCustomLabel: {
      default: 'Yes',
    },
    falseCustomLabel: {
      default: 'No',
    },
    checkboxLabel: {
      default: 'Yes',
    },
    helpText: {
      default: '',
    },
    helpTextDisplay: {
      default: 'tooltip',
    },
    configuration: {
      default: {
        searchable: true,
        filterable: true,
      },
    },
    hidden: {
      value: false,
      conditionsEnabled: false,
      conditions: [
        {
          isSystem: false,
          field: '',
          operator: FIELD_FILTER_OPERATORS.EXISTS,
          value: '',
        },
      ],
    },
  },
})
