<template>

  <!-- eslint-disable vue/valid-v-model -->
  <b-sidebar
    id="attributes-sidebar"
    v-model="_value"
    shadow
    right
    bg-variant="white"
  >
    <template #header>
      <button
        type="button"
        aria-label="Close"
        class="close text-dark"
        @click="_value = false"
      >
        <GjIcon name="Close" />
      </button>
      <h3 class="b-sidebar-title">
        {{ $t('layouts.builderAttrsSideBar.configuration') }}
      </h3>
      <p class="b-sidebar-subtitle">
        {{ $t('layouts.builderAttrsSideBar.for') }} <span>{{ $t(widgetName) }}</span>
      </p>
    </template>

    <template v-if="value">

      <div class="tabbed-sidebar">
        <b-tabs class="article-edit__sidebar-tabs vertical-tabs">
          <b-tab
            v-for="tab in widgetTabs"
            :key="tab.label"
          >
            <template v-slot:title>
              <span
                v-b-tooltip.viewport.left
                :title="tab.label"
              >
                <GjIcon
                  :key="tab.icon"
                  size="20"
                  :name="tab.icon"
                />
              </span>
            </template>

            <h3 class="vertical-tabs__content-title vertical-tabs__content-title--attrs">
              {{ tab.label }}
            </h3>

            <div>
              <component
                :is="tab.component"
                :update-attrs="updateAttrs"
                :widget="node"
                :selected="true"
              />
            </div>

          </b-tab>
        </b-tabs>
      </div>

    </template>

    <!-- <component
      :is="selectedWidget.component.sidebar"
      v-if="selectedWidget"
      :widget="node"
      :update-attrs="updateAttrs"
    /> -->

    <!-- <template #footer>
      <b-button
        variant="primary"
        block
      >
        Save
      </b-button>
    </template> -->
  </b-sidebar>

</template>

<script>
export default {
  props: ['value', 'editor'],
  data() {
    return {
      selectedWidget: null,
    }
  },
  computed: {

    widgetTabs() {
      if (!this.selectedWidget) return []
      if (this.selectedWidget.component.renderEditorPanel && this.selectedWidget.component.renderEditorPanel.constructor == Array) {
        return [...this.selectedWidget.component.renderEditorPanel]
      }
      return [{ icon: 'Settings', label: this.$t('layouts.builderAttrsSideBar.generalLabel'), component: this.selectedWidget.component.renderEditorPanel }]
    },

    node() {
      return this.selectedWidget.node
    },
    widgetName() {
      return this.selectedWidget ? this.selectedWidget.component.displayName : ''
    },
    _value: {
      get() { return this.value !== null && this.value !== false },
      set(v) { if (v === false) this.$emit('input', v) },
    },
  },
  watch: {
    value: {
      handler(v) {
        if (v) {
          this.selectedWidget = v
        }
      },
      deep: true,
    },
  },
  methods: {
    getSelectedNode() {
      let selectedNode = null

      this.editor.state.doc.descendants((node, pos) => {
        if (node.attrs?.blockId === this.selectedWidget.node.attrs.blockId) {
          selectedNode = {
            node, pos,
          }
        }
      })

      return selectedNode
    },
    // eslint-disable-next-line no-unused-vars
    updateAttrs(attributes) {
      const selectedNode = this.getSelectedNode()

      if (selectedNode) {
        this.editor.view.dispatch(this.editor.state.tr.setNodeMarkup(selectedNode.pos, undefined, {
          ...selectedNode.node.attrs,
          ...attributes,
        }))
      }
    },
  },
}
</script>

<style lang="scss">
  @import "@core/scss/base/bootstrap-extended/include"; // Bootstrap includes
  @import "@core/scss/base/components/include"; // Components includes

  .b-sidebar-body .card {
    box-shadow: none !important;
  }

  #attributes-sidebar {
    box-shadow: -2px 0px 12px #E0E5EB !important;

    .b-sidebar-back, .b-sidebar-next {
      display: flex;
      align-items: center;
      margin-bottom: 24px;

      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
      color: #2C2C2C;
    }
    .b-sidebar-next {
      justify-content: flex-end;
    }

    .b-sidebar-title {
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 27px;
      color: #2C2C2C;
      margin-bottom: 4px;
    }

    .b-sidebar-subtitle {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
      color: #6E6E6E;
      margin: 0;

      span {
        color: $primary;
      }
    }

    .b-sidebar-header, .b-sidebar-footer {
      padding: 24px 0;
      margin: 0 16px;
    }
    .b-sidebar-body {
      padding: 24px 16px;
    }

    .b-sidebar-header {
      border-bottom: 1px solid #E2E2E2;
      flex-direction: column;
      align-items: flex-start;

      .close {
        position: absolute;
        top: 24px;
        right: 16px;
      }
    }

    .b-sidebar-footer {
      border-top: 1px solid #E2E2E2;
    }
  }
</style>
