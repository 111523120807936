import CodexContentEditor from '../../CodexContentEditor'
import TwitterEmbed from './TwitterEmbed.vue'
import TwitterEmbedPanel from './TwitterEmbedPanel.vue'
import { BLOCK_GROUPS } from '../constants'

CodexContentEditor.registerWidget('codex', {
  displayName: 'codex-editor.block-names.twitter-embed',
  name: 'twitter_embed',
  group: BLOCK_GROUPS.EMBEDS,
  // eslint-disable-next-line global-require
  image: require('../../icons/tweet/icon.svg'),
  attrs: {
    blockId: {
      default: null,
    },
    src: {
      default: null,
    },
    align: {
      default: 'left',
    },
    caption: {
      default: '',
    },
  },
  renderEditor: TwitterEmbed,
  renderEditorBar: TwitterEmbedPanel,
})
