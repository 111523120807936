<template>
  <div v-if="tweetId">
    <Tweet :id="tweetId" />
  </div>
</template>

<script>
import { Tweet } from 'vue-tweet-embed'

export default {
  components: {
    Tweet,
  },
  props: {
    src: { required: true, default: '', type: String },
  },
  data() {
    return {
      tweetId: '',
    }
  },
  watch: {
    src: {
      async handler() {
        const regex = /^https?:\/\/(twitter|x)\.com\/(?:#!\/)?(\w+)\/status(es)?\/(?<id>\d+)/
        let m

        // eslint-disable-next-line no-cond-assign
        if ((m = regex.exec(this.src)) !== null && m.groups && m.groups.id) {
          this.tweetId = m.groups.id
        } else {
          this.tweetId = null
        }
      },
      immediate: true,
    },
  },
  mounted() {

  },
}
</script>
