<template>
  <div>
    <b-form-radio-group v-model="appearance">
      <label
        v-for="ao in appearanceOptions"
        :key="ao.value"
        :for="`text-field__${ao.value}`"
        class="text-field__radio-button"
      >
        <div class="text-field__radio-container">
          <div class="text-field__radio-button-left">
            <GjIcon
              :name="ao.icon"
              class="number-field__icon"
              size="24"
              style="fill: #1D79F2"
            />
            <p class="mb-0 text-field__radio-button-text">
              {{ $t(ao.label) }}
            </p>
          </div>
          <div class="text-field__radio-button-right">
            <b-form-radio
              :id="`text-field__${ao.value}`"
              :value="ao.value"
              class="mr-0"
            />
          </div>
        </div>
      </label>
    </b-form-radio-group>

    <NumberInputs
      v-if="showPredefinedValues"
      v-model="predefinedValues"
      :is-decimal="isDecimal"
      field-label="fields.codex-field-number.appearance.predefined-value.label"
      placeholder-label="fields.codex-field-number.appearance.predefined-value.value"
      button-label="fields.codex-field-number.appearance.predefined-value.add"
    />
    <b-form-group
      v-if="appearance === NUMBER_FIELD_APPEARANCES.RATING"
      class="mt-1"
      :label="$t('fields.codex-field-number.appearance.number-of-stars')"
    >
      <v-select
        v-model.number="stars"
        :options="[1, 2, 3, 4, 5]"
        :clearable="false"
      />
    </b-form-group>

    <b-form-group
      v-if="valueType === VALUE_TYPES.SINGLE && appearance === NUMBER_FIELD_APPEARANCES.INPUT"
      class="mt-1"
      :label="$t('fields.codex-field-number.appearance.default-value')"
    >
      <b-form-input
        id="specified-values__default-value"
        v-model.number="computedDefaultValue"
        type="number"
        :placeholder="$t('fields.codex-field-number.appearance.default-value')"
      />
    </b-form-group>
    <NumberInputs
      v-else-if="valueType === VALUE_TYPES.LIST && appearance === NUMBER_FIELD_APPEARANCES.INPUT"
      v-model="computedDefaultValue"
      :is-decimal="isDecimal"
      :field-label="$t('fields.codex-field-number.appearance.default-value')"
      :placeholder-label="$t('fields.codex-field-number.appearance.predefined-value.value')"
      :button-label="$t('fields.codex-field-number.appearance.predefined-value.add')"
    />
    <b-form-group
      v-else-if="(appearance === NUMBER_FIELD_APPEARANCES.DROPDOWN || appearance === NUMBER_FIELD_APPEARANCES.CHECKBOX || appearance === NUMBER_FIELD_APPEARANCES.RADIO) && predefinedValues.length"
      class="mt-1"
      :label="$t('fields.codex-field-number.appearance.default-value')"
    >
      <v-select
        v-model="computedDefaultValue"
        :options="predefinedValues"
        no-close-on-backdrop
        :multiple="valueType === VALUE_TYPES.LIST"
      />
    </b-form-group>
    <b-form-group
      v-else-if="appearance === NUMBER_FIELD_APPEARANCES.RATING"
      :label="$t('fields.codex-field-number.appearance.default-value')"
    >
      <v-select
        v-model="computedDefaultValue"
        :options="[1, 2, 3, 4, 5].slice(0, stars)"
        no-close-on-backdrop
        :multiple="valueType === VALUE_TYPES.LIST"
      />
    </b-form-group>

    <FieldHelpTextInput
      :widget="widget"
      :update-attrs="updateAttrs"
    />

    <FieldConditions
      v-model="hidden"
      :widget="widget"
      :label="$t('fields.general.hidden.label')"
      :show-error-message="false"
      :tooltip="$t('fields.general.hidden.info')"
    />
  </div>
</template>

<script>
import { generateComputedPropsFromAttrs } from '@/components/codex-layout-editor/BuilderUtils'
import { NUMBER_FIELD_APPEARANCES, VALUE_TYPES } from '@/views/models/constants'
import FieldHelpTextInput from '@/components/fields/FieldHelpTextInput.vue'
import BaseFieldMixin from '@/components/fields/BaseFieldMixin'
import FieldConditions from '@/components/fields/FieldConditions.vue'
import NumberInputs from './NumberInputs.vue'
import { NUMBER_TYPES } from './index'

export default {
  name: 'Appearance',
  components: { FieldConditions, FieldHelpTextInput, NumberInputs },
  mixins: [BaseFieldMixin],
  data() {
    return {
      VALUE_TYPES,
      NUMBER_FIELD_APPEARANCES,
    }
  },
  computed: {
    ...generateComputedPropsFromAttrs([
      'valueType',
      'appearance',
      'predefinedValues',
      'stars',
      'helpText',
      'helpTextDisplay',
      'defaultValue',
      'hidden',
    ]),
    isDecimal() {
      return NUMBER_TYPES.DECIMAL === this.widget.attrs.type
    },
    computedDefaultValue: {
      get() {
        if (this.valueType === VALUE_TYPES.SINGLE) {
          return this.defaultValue?.toString()
        }
        return this.defaultValue
      },
      set(v) {
        if (this.valueType === VALUE_TYPES.SINGLE) {
          this.defaultValue = Number.isFinite(v) ? Number(v) : null
        } else {
          v = v.map(e => (Number.isFinite(e) ? Number(e) : null))
          this.defaultValue = v
        }
      },
    },
    showPredefinedValues() {
      return this.appearance === NUMBER_FIELD_APPEARANCES.DROPDOWN || this.appearance === NUMBER_FIELD_APPEARANCES.RADIO || this.appearance === NUMBER_FIELD_APPEARANCES.CHECKBOX || this.appearance === NUMBER_FIELD_APPEARANCES.DROPDOWN
    },
    appearanceOptions() {
      if (this.valueType === VALUE_TYPES.SINGLE) {
        return [
          {
            value: NUMBER_FIELD_APPEARANCES.INPUT,
            label: 'fields.codex-field-number.appearance.number-input',
            icon: 'DoubleArrow',
          },
          {
            value: NUMBER_FIELD_APPEARANCES.DROPDOWN,
            label: 'fields.codex-field-number.appearance.dropdown',
            icon: 'ArrowDown',
          },
          {
            value: NUMBER_FIELD_APPEARANCES.RADIO,
            label: 'fields.codex-field-number.appearance.radio',
            icon: 'MinusAlt',
          },
          {
            value: NUMBER_FIELD_APPEARANCES.RATING,
            label: 'fields.codex-field-number.appearance.rating',
            icon: 'Star_fill',
          },
        ]
      }
      return [
        {
          value: NUMBER_FIELD_APPEARANCES.INPUT,
          label: 'fields.codex-field-number.appearance.number-input',
          icon: 'DoubleArrow',
        },
        {
          value: NUMBER_FIELD_APPEARANCES.CHECKBOX,
          label: 'fields.codex-field-number.appearance.checkbox',
          icon: 'Check',
        },
        {
          value: NUMBER_FIELD_APPEARANCES.DROPDOWN,
          label: 'fields.codex-field-number.appearance.dropdown',
          icon: 'ArrowDown',
        },
      ]
    },
  },
  beforeMount() {
    if (this.valueType === VALUE_TYPES.LIST && !this.defaultValue) {
      this.defaultValue = []
    }
  },
}
</script>

<style lang="scss" scoped>
.text-field__radio-button {
  width: 100%;

  margin-bottom: 8px;

  cursor: pointer;
  height: 40px;
}

.text-field__radio-button-left {
  display: flex;
}

.text-field__radio-button-text {
  margin-left: 8px;
  font-size: 12px;
  font-weight: bold;
}

.text-field__radio-container {
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  height: 100%;

  padding: 4px 8px;
  padding-right: 0;

  border: 1px solid #E0E5EB;
  border-radius: 4px;
}
.text-field__error-text {
  font-size: 14px;
  color: red;
  margin-bottom: 0;
}

.help-message {
  font-weight: 600;
}
.info {
  font-size: 12px;
  font-weight: 400;
  margin-top: 5px;
}
.number-field__appearance {
  width: 100%;
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 4px 8px;
  border: 1px solid #E0E5EB;
  border-radius: 4px;
}
.number-field__icon {
  box-shadow: 0px 4px 8px rgba(163, 176, 194, 0.32);
  border-radius: 3px;
}
</style>
