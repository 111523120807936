import { Node } from 'tiptap'
import { wrappingInputRule, toggleList } from 'tiptap-commands'
import { getBlockIdFromDom } from '@/components/codex-editor/CodexEditorUtils'
import CodexContentEditor from '../../CodexContentEditor'
import BulletListPanel from './BulletListPanel.vue'
import { BLOCK_GROUPS } from '../constants'

// eslint-disable-next-line no-unused-vars
export function bulletListTipTapNode(componentName, componentAttrs, component) {
  class BulletList extends Node {
    get name() {
      return componentName
    }

    get schema() {
      return {
        attrs: componentAttrs,
        content: 'list_item+',
        group: 'block',
        parseDOM: [{
          tag: 'ul',
          getAttrs: dom => ({
            ...JSON.parse(dom.dataset?.attrs || '{}'),
            blockId: getBlockIdFromDom(dom),
          }),
        }],
        toDOM: node => ['ul', {
          'data-id': node.attrs.blockId,
          'data-attrs': JSON.stringify(node.attrs) || '{}',
        }, 0],
      }
    }

    commands({ type, schema }) {
      return () => toggleList(type, schema.nodes.list_item)
    }

    keys({ type, schema }) {
      return {
        'Shift-Ctrl-8': toggleList(type, schema.nodes.list_item),
      }
    }

    inputRules({ type }) {
      return [wrappingInputRule(/^\s*([-+*])\s$/, type)]
    }
  }

  return new BulletList()
}

CodexContentEditor.registerWidget(null, {
  displayName: 'codex-editor.block-names.bullet-list',
  name: 'bullet_list',
  group: BLOCK_GROUPS.TEXTUAL,
  icon: 'List_fill',
  // eslint-disable-next-line global-require
  image: require('../../icons/bulleted-list/icon.svg'),
  attrs: {
    blockId: {
      default: null,
    },
  },
  // renderEditor: ImageVue,
  createTiptapNode: bulletListTipTapNode,
  renderEditorBar: BulletListPanel,
})
