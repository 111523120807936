import { set } from 'lodash'
import ActionBase from './ActionBase'

export default {
  ADD_ACTION(state, index) {
    const at = index ?? state.workflow.actions.length
    state.workflow.actions.splice(at, 0, new ActionBase({ eventTypes: state.workflow.automation.eventTypes.join('.') }))
  },
  REMOVE_ACTION(state, index) {
    state.workflow.actions.splice(index, 1)
  },
  UPDATE_ACTION(state, { index, action }) {
    state.workflow.actions.splice(index, 1, action)
  },
  UPDATE_ACTION_TYPE(state, {
    index, actionType = null, id = null, ...rest
  } = {}) {
    // If we don't overwrite the ...rest, use the state's actual data
    if (!Object.keys(rest).length) rest = { ...state.workflow.actions[index] }
    state.workflow.actions[index].actionType = actionType ?? state.workflow.actions[index].actionType
    state.workflow.actions[index].id = id
    state.workflow.actions[index].setProperties(actionType, rest)
  },
  UPDATE_ACTION_PROP(state, prop) {
    prop = [prop].flat()
    prop.forEach(({ index, key, value }) => {
      set(state.workflow.actions[index], key, value)
    })
  },
  RESET_ACTION(state, index) {
    state.workflow.actions[index].resetAction()
  },
}
