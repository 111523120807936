<template>
  <div>
    <b-form-group :label="$t(fieldLabel)">
      <div class="specified-values__section">
        <b-form-input
          id="specified-values__input"
          v-model="tempValue"
          :placeholder="$t(placeholderLabel)"
          @keypress.enter="handleAddText"
          @input="resetValidation"
        />
        <span
          class="specified-values__button"
          @click="handleAddText"
        >
          <GjIcon
            size="18"
            style="margin-top: 2px; fill: #1D79F2"
            name="Plus"
          />
          {{ $t(buttonLabel) }}
        </span>
      </div>
      <b-form-invalid-feedback :state="hasValue">
        {{ $t('fields.codex-field-text.appearance.no-value') }}
      </b-form-invalid-feedback>
      <b-form-invalid-feedback :state="isValid">
        {{ $t('fields.codex-field-text.appearance.text-exists') }}
      </b-form-invalid-feedback>
      <div class="specified-values__number-section">
        <div
          v-for="(num, idx) of value"
          :key="idx"
          class="specified-values__number"
        >
          {{ num }}
          <GjIcon
            name="Close"
            size="16"
            style="cursor: pointer;"
            @click.native="handleRemoveText(num)"
          />
        </div>
      </div>
    </b-form-group>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    fieldLabel: String,
    placeholderLabel: String,
    buttonLabel: String,
  },
  data() {
    return {
      isValid: true,
      hasValue: true,
      tempValue: null,
    }
  },
  methods: {
    handleAddText() {
      if (!this.tempValue?.length) {
        this.hasValue = false
        return
      }
      if (this.value.includes(this.tempValue)) {
        this.isValid = false
        return
      }
      this.$emit('input', [...this.value, this.tempValue])
      this.tempValue = ''
      this.resetValidation()
    },
    handleRemoveText(num) {
      this.$emit('input', this.value.filter(n => n !== num))
      this.$emit('removed', num)
    },
    resetValidation() {
      this.isValid = true
      this.hasValue = true
    },
  },
}
</script>

<style lang="scss" scoped>
.specified-values__section {
  position: relative;

  #specified-values__input {
    width: 100%;
    padding-right: 64px;
  }
  .specified-values__button {
    position: absolute;
    top: 50%;
    right: 12px;
    transform: translate(0%, -50%);
    display: flex;
    align-content: center;
    cursor: pointer;
    color: #1D79F2;
    font-weight: 600;
  }
}

.specified-values__number-section {
  margin-top: 8px;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.specified-values__number {
  border-radius: 4px;
  padding: 8px 12px;
  border: 1px solid #E0E5EB;
}
</style>
