<template>
  <input
    v-model="alias"
    :placeholder="$t('codex-editor.nodes.pagebreak.alias-placeholder')"
  >
</template>

<script>
export default {
  props: ['attrs', 'updateAttrs'],
  computed: {
    alias: { get() { return this.attrs.alias }, set(v) { this.updateAttrs({ alias: v }) } },
  },
}
</script>
