<template>
  <div>
    <FieldConditions
      v-model="validation.required"
      :widget="widget"
      :label="$t('fields.general.required.label')"
      :tooltip="$t('fields.general.required.info')"
      :error-message-label="$t('fields.general.required.error-message')"
    />

    <FieldConditions
      v-model="validation.readOnly"
      :widget="widget"
      :label="$t('fields.general.read-only.label')"
      :show-error-message="false"
    />

    <template v-if="valueType === VALUE_TYPES.SINGLE">
      <b-form-group>
        <b-form-checkbox v-model.number="validation.uniqueInCollection.value">
          {{ $t('fields.codex-field-number.validation.unique.label') }}
          <GjIcon
            v-b-tooltip.hover="$t('fields.codex-field-number.validation.unique.help-text')"
            name="Info"
            size="20"
          />
        </b-form-checkbox>
      </b-form-group>
      <b-form-group
        v-if="validation.uniqueInCollection.value"
        :label="$t('fields.codex-field-number.validation.unique.error-message')"
        class="form-group__validation-last-field-margin"
      >
        <b-form-input v-model="validation.uniqueInCollection.errorMessage" />
      </b-form-group>
    </template>

    <b-form-group>
      <b-form-checkbox v-model.number="validation.range.isEnabled">
        {{ $t('fields.codex-field-number.validation.range.label') }}
      </b-form-checkbox>
    </b-form-group>

    <b-form-group v-if="validation.range.isEnabled">
      <v-select
        v-model="validation.range.rangeOperator"
        :options="rangeOptions"
        :clearable="false"
        :reduce="e => e.value"
      />
    </b-form-group>

    <b-row v-if="validation.range.isEnabled">
      <b-col v-if="validation.range.rangeOperator === RANGE_OPERATORS.BETWEEN || validation.range.rangeOperator === RANGE_OPERATORS.GTE">
        <b-form-group :label="$t('fields.codex-field-number.validation.range.values.min')">
          <b-form-input
            v-model.number="validation.range.min"
            :state="!validationError.isValid ? validationError.isValid : null"
            type="number"
            @keydown="validateNumber($event, validation.range.min, true)"
            @input="validationError = checkRangeValidation(validation.range)"
          />
        </b-form-group>
      </b-col>
      <b-col v-if="validation.range.rangeOperator === RANGE_OPERATORS.BETWEEN || validation.range.rangeOperator === RANGE_OPERATORS.LTE">
        <b-form-group :label="$t('fields.codex-field-number.validation.range.values.max')">
          <b-form-input
            v-model.number="validation.range.max"
            :state="!validationError.isValid ? validationError.isValid : null"
            type="number"
            @keydown="validateNumber($event, validation.range.max, true)"
            @input="validationError = checkRangeValidation(validation.range)"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-form-group v-if="validation.range.isEnabled">
      <b-form-invalid-feedback :state="validationError.isValid">
        {{ $t(validationError.emptyMessage) }}
      </b-form-invalid-feedback>
      <b-form-invalid-feedback :state="validationError.isValid">
        {{ $t(validationError.differenceMessage) }}
      </b-form-invalid-feedback>
    </b-form-group>

    <b-form-group v-if="validation.range.isEnabled">
      <template #label>
        <label>{{ $t('fields.codex-field-number.validation.range.error-template') }}</label>
        <span
          id="number__range-tooltip"
          class="codex-field-range-tooltip"
        >
          <GjIcon
            name="Info"
            size="20"
          />
        </span>
      </template>
      <PlaceholdersTooltip
        :validation="validation.range.rangeOperator"
        target="number__range-tooltip"
      />
      <b-form-input
        v-model="validation.range.errorTemplate"
        :placeholder="$t('fields.codex-field-number.validation.range.error-placeholder')"
        type="text"
      />
    </b-form-group>
    <b-form-group
      v-if="validation.range.isEnabled"
      :label="$t('fields.codex-field-number.validation.range.error-message')"
    >
      <b-form-input
        v-model="validation.range.errorMessage"
        readonly
        type="text"
      />
    </b-form-group>
    <template v-if="valueType === VALUE_TYPES.LIST">
      <b-form-group>
        <b-form-checkbox v-model="validation.listRange.isEnabled">
          {{ $t('fields.codex-field-number.validation.listRange.label') }}
          <GjIcon
            v-b-tooltip.hover="$t('fields.codex-field-number.validation.listRange.tooltip')"
            name="Info"
            size="20"
          />
        </b-form-checkbox>
      </b-form-group>

      <template v-if="validation.listRange.isEnabled">
        <b-form-group>
          <v-select
            v-model="validation.listRange.rangeOperator"
            :options="listRangeOptions"
            :clearable="false"
            :reduce="v => v.value"
            @input="changedListLengthOperator"
          />
        </b-form-group>

        <b-row>
          <b-col v-if="validation.listRange.rangeOperator === RANGE_OPERATORS.BETWEEN || validation.listRange.rangeOperator === RANGE_OPERATORS.GTE">
            <b-form-group :label="$t('fields.codex-field-number.validation.listRange.values.min')">
              <b-form-input
                v-model.number="validation.listRange.min"
                :state="!listValidationError.isValid ? listValidationError.isValid : null"
                min="0"
                type="number"
                @keydown="validateNumber($event, validation.listRange.min)"
                @input="listValidationError = checkRangeValidation(validation.listRange)"
              />
            </b-form-group>
          </b-col>
          <b-col v-if="validation.listRange.rangeOperator === RANGE_OPERATORS.BETWEEN || validation.listRange.rangeOperator === RANGE_OPERATORS.LTE">
            <b-form-group :label="$t('fields.codex-field-number.validation.listRange.values.max')">
              <b-form-input
                v-model.number="validation.listRange.max"
                :state="!listValidationError.isValid ? listValidationError.isValid : null"
                min="0"
                type="number"
                @keydown="validateNumber($event, validation.listRange.max)"
                @input="listValidationError = checkRangeValidation(validation.listRange)"
              />
            </b-form-group>
          </b-col>
          <b-col v-if="validation.listRange.rangeOperator === RANGE_OPERATORS.EXACTLY">
            <b-form-group :label="$t('fields.codex-field-number.validation.listRange.values.exactly')">
              <b-form-input
                v-model.number="validation.listRange.exactly"
                :state="!listValidationError.isValid ? listValidationError.isValid : null"
                min="0"
                type="number"
                @keydown="validateNumber($event, validation.listRange.exactly)"
                @input="listValidationError = checkRangeValidation(validation.listRange, true)"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-form-group>
          <b-form-invalid-feedback :state="listValidationError.isValid">
            {{ $t(listValidationError.emptyMessage) }}
          </b-form-invalid-feedback>
          <b-form-invalid-feedback :state="listValidationError.isValid">
            {{ $t(listValidationError.differenceMessage) }}
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group v-if="validation.listRange.isEnabled">
          <template #label>
            <span>{{ $t('fields.codex-field-number.validation.listRange.error-template') }}</span>
            <span
              id="number__listRange-tooltip"
              class="codex-field-range-tooltip"
            >
              <GjIcon
                name="Info"
                size="20"
              />
            </span>
          </template>
          <PlaceholdersTooltip
            :validation="validation.listRange.rangeOperator"
            target="number__listRange-tooltip"
          />
          <b-form-input
            v-model="validation.listRange.errorTemplate"
            :placeholder="$t('fields.codex-field-number.validation.listRange.error-placeholder')"
            type="text"
          />
        </b-form-group>
        <b-form-group
          v-if="validation.listRange.isEnabled"
          :label="$t('fields.codex-field-number.validation.listRange.error-message')"
          class="form-group__validation-last-field-margin"
        >
          <b-form-input
            v-model="validation.listRange.errorMessage"
            readonly
          />
        </b-form-group>
      </template>
    </template>
  </div>
</template>

<script>
import { generateComputedPropsFromAttrs } from '@/components/codex-layout-editor/BuilderUtils'
import {
  RANGE_OPERATORS, VALUE_TYPES, checkRangeValidation, validateNumber, errorTemplateParser,
} from '@/views/models/constants'
import FieldConditions from '@/components/fields/FieldConditions.vue'
import BaseFieldMixin from '@/components/fields/BaseFieldMixin'
import PlaceholdersTooltip from '../PlaceholdersTooltip.vue'

export default {
  name: 'Validations',
  components: { FieldConditions, PlaceholdersTooltip },
  mixins: [BaseFieldMixin],
  data() {
    return {
      VALUE_TYPES,
      RANGE_OPERATORS,
      validateNumber,
      checkRangeValidation,
      validationError: {
        isValid: true,
        emptyMessage: '',
        differenceMessage: '',
      },
      listValidationError: {
        isValid: true,
        emptyMessage: '',
        differenceMessage: '',
      },
    }
  },
  watch: {
    'validation.range.rangeOperator': function (v) {
      this.validation.range.errorTemplate = this.predefinedErrorTemplates[v]
    },
    'validation.listRange.rangeOperator': function (v) {
      this.validation.listRange.errorTemplate = this.rangeListPredefinedErrorTemplates[v]
    },
    'validation.range': {
      handler() {
        errorTemplateParser(this.validation.range)
      },
      deep: true,
    },
    'validation.listRange': {
      handler() {
        errorTemplateParser(this.validation.listRange)
      },
      deep: true,
    },
  },
  computed: {
    ...generateComputedPropsFromAttrs([
      'helpMessage',
      'appearance',
      'validation',
      'valueType',
    ]),
    rangeOptions() {
      return [
        { label: this.$t('fields.codex-field-number.validation.range.rangeOptions.between'), value: RANGE_OPERATORS.BETWEEN },
        { label: this.$t('fields.codex-field-number.validation.range.rangeOptions.at-least'), value: RANGE_OPERATORS.GTE },
        { label: this.$t('fields.codex-field-number.validation.range.rangeOptions.no-more-than'), value: RANGE_OPERATORS.LTE },
      ]
    },
    listRangeOptions() {
      return [
        { label: this.$t('fields.codex-field-number.validation.listRange.rangeOptions.between'), value: RANGE_OPERATORS.BETWEEN },
        { label: this.$t('fields.codex-field-number.validation.listRange.rangeOptions.gte'), value: RANGE_OPERATORS.GTE },
        { label: this.$t('fields.codex-field-number.validation.listRange.rangeOptions.lte'), value: RANGE_OPERATORS.LTE },
        { label: this.$t('fields.codex-field-number.validation.listRange.rangeOptions.exactly'), value: RANGE_OPERATORS.EXACTLY },
      ]
    },
    predefinedErrorTemplates() {
      return {
        [RANGE_OPERATORS.BETWEEN]: this.$t('fields.codex-field-number.validation.range-of-number-templates.between'),
        [RANGE_OPERATORS.GTE]: this.$t('fields.codex-field-number.validation.range-of-number-templates.gte'),
        [RANGE_OPERATORS.LTE]: this.$t('fields.codex-field-number.validation.range-of-number-templates.lte'),
      }
    },
    rangeListPredefinedErrorTemplates() {
      return {
        [RANGE_OPERATORS.BETWEEN]: this.$t('fields.codex-field-number.validation.listRange.range-templates.between'),
        [RANGE_OPERATORS.GTE]: this.$t('fields.codex-field-number.validation.listRange.range-templates.gte'),
        [RANGE_OPERATORS.LTE]: this.$t('fields.codex-field-number.validation.listRange.range-templates.lte'),
        [RANGE_OPERATORS.EXACTLY]: this.$t('fields.codex-field-number.validation.listRange.range-templates.exactly'),
      }
    },
  },
  mounted() {
    this.validationError = checkRangeValidation(this.validation.range)
  },
  methods: {
    changeRangeOperator() {
      this.validationError = checkRangeValidation(this.validation.range)
    },
    changedListLengthOperator() {
      this.validation.listRange.errorMessage = ''
      this.listValidationError = checkRangeValidation(this.validation.range)
    },
  },
}
</script>
