<template>
  <div>
    <b-form-group
      :label="$t('fields.codex-field-rich-text.general.name-label')"
      label-for="rich-text-field__name"
    >
      <b-form-input
        id="rich-text-field__name"
        v-model="name"
        :placeholder="$t('fields.codex-field-rich-text.general.name-placeholder')"
      />
    </b-form-group>
    <b-form-group
      :label="$t('fields.codex-field-rich-text.general.alias-label')"
      label-for="rich-text-field__alias"
    >
      <b-form-input
        id="rich-text-field__alias"
        :value="alias"
        disabled
      />
    </b-form-group>

    <b-form-group
      :label="$t('fields.codex-field-rich-text.general.description-label')"
      label-for="rich-text-field__description"
    >
      <b-form-textarea
        id="rich-text-field__description"
        v-model="description"
        :placeholder="$t('fields.codex-field-rich-text.general.description-placeholder')"
        no-resize
      />
    </b-form-group>

    <b-form-group
      label-for="rich-text__calculate-metrics"
      class="mb-3"
    >
      <template #label>
        <span>
          {{ $t('fields.general.metrics.calculate-metrics') }}
        </span>
        <span
          id="rich-text__calculate-metrics-tooltip"
          class="codex-field-range-tooltip"
        >
          <GjIcon
            name="Info"
            size="20"
          />
        </span>
      </template>
      <b-form-checkbox
        id="rich-text__calculate-metrics"
        v-model="calculateMetrics"
        switch
        @input="($event) => { updateAttrs({calculateMetrics: $event}) }"
      />
    </b-form-group>

    <b-tooltip
      target="rich-text__calculate-metrics-tooltip"
      triggers="hover"
    >
      <p class="rich-text__calculate-metrics-intro">
        {{ $t('fields.general.metrics.calculate-metrics-tooltip') }}
      </p>

      <ul>
        <li>{{ $t('fields.general.metrics.character-count') }}</li>
        <li>{{ $t('fields.general.metrics.image-count') }}</li>
        <li>{{ $t('fields.general.metrics.sentences') }}</li>
        <li>{{ $t('fields.general.metrics.words') }}</li>
      </ul>

      <p class="rich-text__calculate-metrics-intro">
        {{ $t('fields.general.metrics.enable-reading-time') }}
      </p>

    </b-tooltip>

    <p class="richtext-field__configuration">
      {{ $t('fields.codex-field-rich-text.general.configuration') }}
    </p>
    <b-form-group :label="$t('fields.codex-field-rich-text.general.formatting')">
      <b-form-checkbox-group
        v-model="formattingOption.headers"
        name="headers"
        button-variant="outline"
        buttons
      >
        <b-form-checkbox
          v-for="header in heading"
          :key="header.value"
          :value="header.value"
          class="rich-text__button-group"
        >
          {{ header.text }}
        </b-form-checkbox>
      </b-form-checkbox-group>
    </b-form-group>
    <b-form-group>
      <b-form-checkbox
        v-model="formattingOption.bold"
        class="rich-text__button"
        button
        button-variant="outline"
      >
        <GjIcon
          class="rich-text__fill"
          name="FormatBold"
          size="21"
        />
      </b-form-checkbox>
      <b-form-checkbox
        v-model="formattingOption.italicize"
        class="rich-text__button"
        button
        button-variant="outline"
      >
        <GjIcon
          class="rich-text__fill"
          name="FormatItalic"
          size="21"
        />
      </b-form-checkbox>
      <b-form-checkbox
        v-model="formattingOption.underline"
        class="rich-text__button"
        button
        button-variant="outline"
      >
        <GjIcon
          class="rich-text__fill"
          name="FormatUnderline"
          size="21"
        />
      </b-form-checkbox>
      <b-form-checkbox
        v-model="formattingOption.codeBlock"
        class="rich-text__button"
        button
        button-variant="outline"
      >
        <GjIcon
          class="rich-text__fill"
          name="Embed"
          size="21"
        />
      </b-form-checkbox>
      <b-form-checkbox
        v-model="formattingOption.bulletPoint"
        class="rich-text__button"
        button
        button-variant="outline"
      >
        <GjIcon
          class="rich-text__fill"
          name="UnorderedList"
          size="21"
        />
      </b-form-checkbox>
      <b-form-checkbox
        v-model="formattingOption.numberLine"
        class="rich-text__button"
        button
        button-variant="outline"
      >
        <GjIcon
          class="rich-text__fill"
          name="NumberdList"
          size="21"
        />
      </b-form-checkbox>
      <b-form-checkbox
        v-model="formattingOption.quote"
        class="rich-text__button"
        button
        button-variant="outline"
      >
        <GjIcon
          class="rich-text__fill"
          name="Quote_fill"
          size="21"
        />
      </b-form-checkbox>
      <b-form-checkbox
        v-model="formattingOption.subscript"
        class="rich-text__button"
        button
        button-variant="outline"
      >
        X<sub>2</sub>
      </b-form-checkbox>
      <b-form-checkbox
        v-model="formattingOption.superscript"
        class="rich-text__button"
        button
        button-variant="outline"
      >
        X<sup>2</sup>
      </b-form-checkbox>
    </b-form-group>
    <b-form-group
      :label="$t('fields.codex-field-rich-text.general.hyperlinks.label')"
    >
      <b-form-checkbox
        v-model="hyperlinks.url"
        class="rich-text__button"
        button
        button-variant="outline"
      >
        <GjIcon
          class="rich-text__fill"
          name="Hyperlink"
          size="21"
        /> <span class="richtext-field__button-text">{{ $t('fields.codex-field-rich-text.general.hyperlinks.url') }}</span>
      </b-form-checkbox>
      <!--      <b-form-checkbox-->
      <!--        v-model="hyperlinks.images"-->
      <!--        class="rich-text__button"-->
      <!--        button-->
      <!--        button-variant="outline"-->
      <!--      >-->
      <!--        <GjIcon-->
      <!--          class="rich-text__fill"-->
      <!--          name="Image"-->
      <!--          size="21"-->
      <!--        /> <span class="richtext-field__button-text">{{ $t('fields.codex-field-rich-text.general.hyperlinks.images') }}</span>-->
      <!--      </b-form-checkbox>-->
      <!--      <b-form-checkbox-->
      <!--        v-model="hyperlinks.files"-->
      <!--        class="rich-text__button"-->
      <!--        button-->
      <!--        button-variant="outline"-->
      <!--      >-->
      <!--        <GjIcon-->
      <!--          class="rich-text__fill"-->
      <!--          name="Files"-->
      <!--          size="21"-->
      <!--        /> <span class="richtext-field__button-text">{{ $t('fields.codex-field-rich-text.general.hyperlinks.files') }}</span>-->
      <!--      </b-form-checkbox>-->
    </b-form-group>
    <!--    <b-form-group :label="$t('fields.codex-field-rich-text.general.embeds.label')">-->
    <!--      <b-form-checkbox-->
    <!--        v-model="embedded.images"-->
    <!--        class="rich-text__button"-->
    <!--        button-->
    <!--        button-variant="outline"-->
    <!--      >-->
    <!--        <GjIcon-->
    <!--          class="rich-text__fill"-->
    <!--          name="Image"-->
    <!--          size="21"-->
    <!--        /> <span class="richtext-field__button-text">{{ $t('fields.codex-field-rich-text.general.embeds.images') }}</span>-->
    <!--      </b-form-checkbox>-->
    <!--    </b-form-group>-->
  </div>
</template>

<script>
import { generateComputedPropsFromAttrs } from '@/components/codex-layout-editor/BuilderUtils'
import BaseFieldMixin from '@/components/fields/BaseFieldMixin'

export default {
  mixins: [BaseFieldMixin],
  computed: {
    ...generateComputedPropsFromAttrs([
      'name',
      'alias',
      'description',
      'formattingOption',
      'hyperlinks',
      'embedded',
      'calculateMetrics',
    ]),
    heading() {
      return [
        { text: 'H1', value: 1 },
        { text: 'H2', value: 2 },
        { text: 'H3', value: 3 },
        { text: 'H4', value: 4 },
        { text: 'H5', value: 5 },
        { text: 'H6', value: 6 },
      ]
    },
  },
}
</script>
<style lang="scss">
.btn-group [class*=btn-]:not([class*=btn-outline-]):not([class*=btn-flat-]):not([class*=btn-gradient-]):not([class*=btn-relief-]).rich-text__button-group {
  color: #7483A1;
  background-color: #FFFFFF;
  border: 1px solid #E0E5EB !important;
  &.active {
    background-color: #ECF3FF;
    color: #3A81F6;
    border: 1px solid #3A81F6 !important;
  }
  &:hover {
    background: #f5f5f5;
  }
}
.rich-text__button-group {
  margin: 5px;
  border-radius: 4px !important;
  &:first-child {
    margin-left: 2px;
  }
}

.rich-text__button {
  padding: 2px;

  label {
    background-color: #FFFFFF;
    border: 1px solid #E0E5EB;
    color: #7483A1;
    &.active {
      background-color: #ECF3FF;
      color: #3A81F6;
      border: 1px solid #3A81F6;
    }
  }
}
.richtext-field__button-text{
  padding-left: 10px;
}

.rich-text__calculate-metrics-intro {
  font-size: 14px;
}
</style>
