<template>
  <b-modal
    id="edit-entry-version-name-popup"
    v-model="showPopup"
    :title="$t('entries.versions.edit-modal.edit-version-name')"
    :static="true"
    @cancel="handleModalCancel"
    @close="handleModalCancel"
    @ok.prevent="handleNameChange"
  >
    <b-form-group :label="$t('entries.versions.edit-modal.label')">
      <b-form-input
        id="at-history-write-versionName"
        v-model="editName"
        :placeholder="$t('entries.versions.edit-modal.version-name')"
      />
    </b-form-group>
  </b-modal>
</template>

<script>
import PopupsMixin from '@/components/popups/PopupsMixin'
import { updateEntryVersion } from '@/codex-sdk/entries'
import moment from 'moment'

export default {
  name: 'EditVersionNamePopup',
  mixins: [PopupsMixin],
  data() {
    return {
      editName: this.getFormattedName(this.data.version.name),
    }
  },
  methods: {
    handleModalCancel() {
      this.editName = this.getFormattedName()
      this.closePopup(false)
    },
    async handleNameChange() {
      try {
        await updateEntryVersion({ ...this.data.version, name: this.editName, entryId: this.data.version.entity.id }, this.data.model.alias, this.data.siteId)
        this.closePopup(true)
      } catch (e) {
        console.log(e)
      }
    },
    getFormattedName(name) {
      const date = moment(name, true)
      if (date.isValid()) return date.format('MMMM D, H:mm')
      return name
    },
  },
}
</script>
