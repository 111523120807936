<template>
  <div
    class="new-filters-dropdown__filter-body"
    :class="{'new-filters-dropdown__filter-body--scroll': allFolders.length > 3 }"
  >

    <b-row v-if="showDropdown">
      <b-col cols="12">
        <vSelect
          v-model="value.operator"
          :options="options"
          :clearable="false"
          :reduce="option => option.value"
          :searchable="false"
          label="label"
        />
      </b-col>
    </b-row>

    <b-row
      align-v="center"
      no-gutters
    >
      <b-col cols="12">
        <b-checkbox-group
          v-model="value.value"
          class="new-filters-dropdown__checkbox-group"
          :options="allFolders"
          stacked
        />
      </b-col>
    </b-row>

  </div>
</template>

<script>
import gql from 'graphql-tag'
import FolderFilter from './FolderFilter'

export default {
  props: {
    filter: {
      type: Object,
      required: true,
    },
    filterObject: {
      type: [Object, Function],
      required: true,
    },
    value: {
      type: Object,
      required: true,
    },
    cache: {
      type: Object,
      required: true,
    },
    setCache: {
      type: Function,
      required: true,
    },
  },
  apollo: {
    folders: {
      query: gql`
      fragment Folder on CodexFolder {
          id
          name
          parentId
       }
       query Folder {
          folderCollection (limit: 1000) {
             items {
              ...Folder
              children {
                items {
                  ...Folder
                  children {
                    items {
                      ...Folder
                      children {
                        items {
                          ...Folder
                          children {
                            items {
                              ...Folder
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `,

      update(data) {
        return data.folderCollection.items
      },
    },
  },
  computed: {
    allFolders() {
      if (!this.folders) return []

      return this.folders.map(folder => ({
        value: folder.id,
        text: folder.name,
      }))
    },
    options() {
      return [
        {
          label: this.$t('filters.all'),
          value: FolderFilter.OPERATORS.all,
        },
        {
          label: this.$t('filters.some'),
          value: FolderFilter.OPERATORS.some,
        },
      ]
    },
    showDropdown() {
      if (typeof this.filter.showDropdown !== 'undefined') {
        return this.filter.showDropdown
      }
      return true
    },
  },
  watch: {
    'value.value': function (value) {
      value.forEach(folderId => {
        if (!this.cache[folderId]) {
          this.setCache(this.folders.find(folder => folder.id === folderId))
        }
      })
    },
  },
}
</script>
