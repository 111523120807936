<template>
  <b-form-group class="media-field">
    <template #label>
      <div class="media-field__label">
        <FieldName
          :name="name"
          :help-text="helpText"
          :help-text-display="helpTextDisplay"
          :required="required"
        >
          <template #top-right>
            <div
              v-if="medias && medias.length"
              class="media-field__label-edit hide-in-quick-view"
            >
              <div
                v-if="!(readOnly || !editable)"
                class="media-field__edit-cont"
                @click="insert"
              >
                <img
                  class="media-field__edit-icon"
                  src="../../../assets/icons/pencil.svg"
                  alt="media field button where you can open the media adder"
                >
                <span class="media-field__edit-text">{{ $t('fields.codex-field-media-content.preview.edit') }}</span>
              </div>
              <div>
                <GjIcon
                  :color="showImageDetails ? '#1D79F2' : '#455A82'"
                  name="CaptionBelow"
                  class="caption__button"
                  @click.native="showImageDetails = !showImageDetails"
                />
              </div>
              <span
                v-if="medias && medias.length > 5"
                class="expandable__parent"
              >
                <GjIcon
                  v-b-tooltip.hover.html.top="!expand ? $t('fields.codex-field-media-content.preview.expand-media') : $t('fields.codex-field-media-content.preview.collapse-media') "
                  :color="expand ? '#1D79F2' : '#455A82'"
                  name="ArrowExpand"
                  size="16"
                  class="expandable__button"
                  @click.native="expand = !expand"
                />
              </span>
            </div>
          </template>
        </FieldName>
      </div>
    </template>
    <DropUpload
      class="media-content__wrapper"
      :allowed-types="allowedTypes"
      :disabled="!showUploadNewAsset"
      :limit="valueType === VALUE_TYPES.SINGLE ? 1 : null"
      :default-folder="defaultFolder"
      @dropInsert="dropInsert"
      @dropUpdate="dropUpdate"
      @dropRemove="dropRemove"
    >
      <AssetViewPopup
        v-if="assetId"
        :assets="medias"
        :total="medias.length"
        :asset-id="assetId"
        @close="assetId = null"
      />
      <NoAssets
        v-if="!medias || medias.length === 0"
        :value-type="valueType"
        class="disable-in-quick-view"
        :disabled="readOnly || !editable"
        @insert="insert"
      />
      <DraggableAssets
        v-model="medias"
        :show-image-details="showImageDetails"
        :name="name"
        :type="computedValue && computedValue.id && medias && medias[0] ? 'single' : 'multiple'"
        :expand="medias && medias.length > 5 ? expand : false"
        :error-indexes="showError ? currentError.indexes : error.indexes"
        :disabled="readOnly || !editable"
        @edit="editMedia"
        @metadata="editMediaMetadata"
        @delete="deleteMedia"
        @showAsset="showAsset"
        @focalPoint="focalPoint"
      />
      <FieldError
        v-if="showError"
        :error="currentError"
      />
      <FieldError
        v-else
        :error="error"
      />
    </DropUpload>
  </b-form-group>
</template>

<script>
import { ASSET_TYPE_MAPPING, ASSET_TYPES, ASSET_STATUSES } from '@/codex-sdk/assets'
import DraggableAssets from '@/components/assets/DraggableAssets.vue'
import { generateComputedPropsFromAttrs } from '@/components/codex-layout-editor/BuilderUtils'
import {
  VALUE_TYPES, RANGE_OPERATORS, assetsCountProperties, FIELD_FILTER_OPERATORS,
} from '@/views/models/constants'
import gql from 'graphql-tag'
import NoAssets from '@/components/fields/MediaContent/NoAssets.vue'
import FieldName from '@/components/fields/FieldName.vue'
import AssetViewPopup from '@/components/assets/AssetViewPopup.vue'
import store from '@/store'
import MediaFilter from '@/components/filters-dropdown/filters/media/MediaFilter'
import MediaListFilter from '@/components/filters-dropdown/filters/mediaList/MediaListFilter'
import { isNumber } from 'lodash'
import DropUpload from '@/components/DropUpload.vue'
import BaseFieldMixin from '@/components/fields/BaseFieldMixin'
import FieldRenderMixin from '@/components/fields/RenderFieldMixin'
import FieldError from '@/components/fields/FieldError.vue'
import { someParentHasClass } from '@/utils/helpers'

export default {
  name: 'MediaContent',
  inject: ['showAssetsPopup', 'showEditAssetPopup', 'showImageEditorPopup', 'showFocalPointPopup', 'filterValues', 'entryId', 'model', 'site'],
  components: {
    FieldError,
    AssetViewPopup,
    NoAssets,
    FieldName,
    DraggableAssets,
    DropUpload,
  },
  mixins: [BaseFieldMixin, FieldRenderMixin],
  props: {
    value: {
      type: [Array, Object],
      default: () => ([]),
    },
  },
  apollo: {
    assetCollection: {
      query: gql`
        query ($ids: [String!], $limit: Int) {
          assetCollection (where: {
              id: {
                in: $ids
              }
            }, limit: $limit) {
              items {
                id
                title
                expiresAt
                fileName
                type
                author
                source
                alt
                caption
                copyright
                width
                height
                tags
                folderId
                url(transformation: {width: 400, quality: 80, format: THUMBNAIL})
                originalUrl: url
                videoUrl: url(transformation: {format: HTML})
                createdBy {
                  id
                  firstName
                  lastName
                  email
                  imageUrl
                }
                updatedBy {
                  id
                  firstName
                  lastName
                  email
                  imageUrl
                }
                labels {
                  id
                  name
                  color
                }
                createdAt
                updatedAt
                attrs
                status
              }
            }
          }
      `,
      fetchPolicy: 'no-cache',
      variables() {
        return {
          ids: this.ids,
          limit: this.ids.length,
        }
      },
      skip() {
        return !this.ids.length
      },
      result(results) {
        this.medias_ = []
        // eslint-disable-next-line no-nested-ternary
        const assets = this.valueType === VALUE_TYPES.LIST ? this.computedValue : (this.computedValue?.id ? [this.computedValue] : [])
        assets.forEach(e => {
          let item = results.data.assetCollection.items.find(c => c.id === e.id)

          if (!item) {
            item = this.droppedMedias.find(c => c.id === e.id)
          }

          if (item) {
            this.medias_.push({
              ...item,
              customCaption: e.caption,
              focalPoints: e.focalPoints,
            })
          } else {
            this.medias_.push({
              id: e.id,
              status: ASSET_STATUSES.DELETED,
              labels: [],
            })
          }
        })
        this.$apollo.queries.assetCollection.skip = true
        this.$root.$emit('calculateMetrics')
      },
    },
  },
  data() {
    return {
      showImageDetails: false,
      assetId: null,
      VALUE_TYPES,
      ASSET_TYPES,
      medias_: [],
      droppedMedias: [],
      expand: false,
      setValue_: this.setValue, // debounce(this.setValue, 500),
      showError: false,
      currentError: { isValid: true },
    }
  },
  computed: {
    ...generateComputedPropsFromAttrs([
      'validation',
      'valueType',
      'name',
      'alias',
      'calculateMetrics',
      'description',
      'appearance',
      'helpText',
      'helpTextDisplay',
      'showUploadNewAsset',
      'ratios',
      'version',
      'defaultFolderId',
    ]),
    computedValue: {
      get() {
        return this.value
      },
      set(v) {
        this.setValue_(v)
      },
    },
    medias: {
      get() {
        return this.medias_
      },
      set(v) {
        this.medias_ = v
        this.updateValue()
      },
    },
    defaultFolder() {
      if (this.defaultFolderId) {
        return this.defaultFolderId
      }
      if (this.model()?.defaultFolderId) {
        return this.model().defaultFolderId
      }
      if (this.site?.()?.defaultFolder?.id) {
        return this.site().defaultFolder.id
      }
      return null
    },
    ids() {
      if (!this.computedValue) {
        return []
      }
      // eslint-disable-next-line no-nested-ternary
      return this.valueType === VALUE_TYPES.LIST ? this.computedValue.map(e => e.id) : (this.computedValue?.id ? [this.computedValue.id] : [])
    },
    allowedTypes() {
      const types = []
      if (this.validation.fileType.image) {
        types.push(ASSET_TYPES.IMAGE)
      }
      if (this.validation.fileType.files) {
        types.push(ASSET_TYPES.FILE)
      }
      if (this.validation.fileType.video) {
        types.push(ASSET_TYPES.VIDEO)
      }
      if (this.validation.fileType.playlists) {
        types.push(ASSET_TYPES.VIDEO_PLAYLIST)
      }
      if (this.validation.fileType.audio) {
        types.push(ASSET_TYPES.AUDIO)
      }
      if (this.validation.fileType.podcast) {
        types.push(ASSET_TYPES.PODCAST)
      }
      return types
    },
  },
  beforeMount() {
    if (!this.widget.attrs.version) {
      this.v1()
    }

    if (!this.widget.attrs.hidden) {
      this.$set(this.widget.attrs, 'hidden', {
        value: false,
        conditionsEnabled: false,
        conditions: [
          {
            isSystem: false,
            field: '',
            operator: FIELD_FILTER_OPERATORS.EXISTS,
            value: '',
          },
        ],
      })
    }
  },
  mounted() {
    if (this.entryId() === 'create' && !this.readOnly && this.editable) {
      // Prefill from filter
      let prefilledFromFilter = false
      if (this.filterValues && this.filterValues?.[this.alias]) {
        if (this.valueType === VALUE_TYPES.SINGLE) {
          if (MediaFilter.shouldApplyPredefinedValue(this.filterValues[this.alias])) {
            this.medias = [this.assetFromFiltersToValue(this.filterValues[this.alias].value[0])]
            prefilledFromFilter = true
          }
        } else if (MediaListFilter.shouldApplyPredefinedValue(this.filterValues[this.alias])) {
          this.medias = this.filterValues[this.alias].value.map(asset => this.assetFromFiltersToValue(asset))
          prefilledFromFilter = true
        }
      }
      // \Prefill from filter

      if (!prefilledFromFilter) {
        if (this.valueType === VALUE_TYPES.SINGLE) {
          this.computedValue = null
        } else {
          this.computedValue = []
        }
      }
    }

    document.addEventListener('click', this.focusChanged, true)
  },
  beforeDestroy() {
    document.removeEventListener('click', this.focusChanged, true)
  },
  methods: {
    focusChanged(event) {
      this.showError = !!someParentHasClass(event.target, `codex-field-${this.widget.attrs.alias}`)
    },
    v1() {
      this.widget.attrs.validation.requiredLabels = {
        isEnabled: false,
        allowedLabels: [],
        errorMessage: 'Assets should have at least one of selected labels',
      }
      this.widget.attrs.version = 1
    },
    setValue(value) {
      this.$emit('input', value)
    },
    assetFromFiltersToValue(assetId) {
      const assetFromFiltersCache = store.state.filters.filtersCache[assetId] || {}

      return {
        id: assetFromFiltersCache.id,
        type: ASSET_TYPES[assetFromFiltersCache.type],
        customCaption: assetFromFiltersCache.caption,
        focalPoints: { default: assetFromFiltersCache.focalPoint || { x: 0.5, y: 0.5 } },
      }
    },
    async focalPoint(media) {
      const res = await this.showFocalPointPopup({
        imageId: media.id, focals: JSON.parse(JSON.stringify(media.focalPoints)), from: 'entry', ratios: this.ratios,
      })
      if (res) {
        media.focalPoints = res
        this.updateValue()
      }
    },
    showAsset(assetId) {
      this.assetId = assetId
    },
    async editMediaMetadata(media) {
      const updatedMedia = await this.showEditAssetPopup({ ...media, required: this.validation.requiredMetadata })
      Object.assign(this.medias.find(m => m.id === media.id), updatedMedia)
      this.updateValue()
      this.callValidate(this.computedValue)
    },
    async editMedia(media) {
      const data = await this.showImageEditorPopup({ imageId: media.id })
      if (data?.id) {
        const asset = this.medias.find(a => a.id === media.id)
        Object.assign(asset, data)
        this.updateValue()
      }
    },
    deleteMedia(idOrIds) {
      if (Array.isArray(idOrIds)) {
        this.medias = this.medias.filter(i => !idOrIds.includes(i.id))
        return
      }
      this.medias = this.medias.filter(i => i.id !== idOrIds)
    },
    updateValue() {
      this.showError = true
      if (this.valueType === VALUE_TYPES.SINGLE) {
        this.computedValue = this.medias?.length ? {
          id: this.medias[0].id,
          type: ASSET_TYPE_MAPPING.toNumber(this.medias[0].type),
          caption: this.medias[0].customCaption,
          focalPoints: this.medias[0].focalPoints,
        } : null
      } else if (this.medias) {
        this.computedValue = this.medias.map(e => ({
          id: e.id,
          type: ASSET_TYPE_MAPPING.toNumber(e.type),
          caption: e.customCaption,
          focalPoints: e.focalPoints,
        }))
      }
      this.$forceUpdate()
    },
    getMetrics() {
      const metrics = {
        imageCount: 0,
        videoCount: 0,
        filesCount: 0,
        playlistsCount: 0,
        audioCount: 0,
        podcastCount: 0,
      }

      if (!this.calculateMetrics) {
        return metrics
      }

      if (this.medias?.length > 0) {
        if (this.valueType === VALUE_TYPES.SINGLE) {
          const countProp = assetsCountProperties[this.medias[0].type]
          if (countProp) {
            metrics[countProp]++
          }
        } else if (this.valueType === VALUE_TYPES.LIST) {
          this.medias.forEach(item => {
            const countProp = assetsCountProperties[item.type]
            if (countProp) {
              metrics[countProp]++
            }
          })
        }
      }

      return metrics
    },
    validateMetadata() {
      const indexes = []
      if (this.validation.requiredMetadata.isEnabled) {
        for (let i = 0; i < this.medias.length; i++) {
          const media = this.medias[i]
          if ((this.validation.requiredMetadata.title && !media.title)
              || (this.validation.requiredMetadata.caption && !media.caption)
              || (this.validation.requiredMetadata.alt && !media.alt)
              || (this.validation.requiredMetadata.source && !media.source)
              || (this.validation.requiredMetadata.author && !media.author)
              || (this.validation.requiredMetadata.copyright && !media.copyright)
              || (this.validation.requiredMetadata.tags && !media.tags.length)) {
            indexes.push(i)
          }
        }
      }
      return indexes
    },
    validateRequiredLabels() {
      const indexes = []
      if (this.validation.requiredLabels.isEnabled) {
        for (let i = 0; i < this.medias.length; i++) {
          const media = this.medias[i]

          if (!this.validation.requiredLabels.allowedLabels.some(l => media.labels.map(e => e.id).includes(l))) {
            indexes.push(i)
          }
        }
      }
      return indexes
    },
    validateDimensions() {
      const indexes = []
      if (this.validation.dimensions.isEnabled) {
        for (let i = 0; i < this.medias.length; i++) {
          const asset = this.medias[i]
          if (ASSET_TYPES[asset.type] === ASSET_TYPES.IMAGE) {
            if (isNumber(this.validation.dimensions.minWidth) && asset.width < this.validation.dimensions.minWidth) {
              indexes.push(i)
            }
            if (isNumber(this.validation.dimensions.maxWidth) && asset.width > this.validation.dimensions.maxWidth) {
              indexes.push(i)
            }
            if (isNumber(this.validation.dimensions.minHeight) && asset.height < this.validation.dimensions.minHeight) {
              indexes.push(i)
            }
            if (isNumber(this.validation.dimensions.maxHeight) && asset.height > this.validation.dimensions.maxHeight) {
              indexes.push(i)
            }
          }
        }
      }
      return indexes
    },
    validate(value) {
      this.currentError = this.validateValue(value)
      return this.currentError
    },
    validateValue(value) {
      if (this.valueType === VALUE_TYPES.SINGLE) {
        if (this.required && !value) {
          return {
            isValid: false,
            message: this.validation.required.errorMessage,
          }
        }
        const metadataValidation = this.validateMetadata()
        if (metadataValidation.length) {
          return {
            isValid: false,
            message: this.validation.requiredMetadata.errorMessage,
            indexes: metadataValidation,
          }
        }
        const dimensions = this.validateDimensions()
        if (dimensions.length) {
          return {
            isValid: false,
            message: this.validation.dimensions.errorMessage,
            indexes: dimensions,
          }
        }

        const requiredLabelsValidation = this.validateRequiredLabels()
        if (requiredLabelsValidation.length) {
          return {
            isValid: false,
            message: this.validation.requiredLabels.errorMessage,
            indexes: requiredLabelsValidation,
          }
        }
      }
      if (this.valueType === VALUE_TYPES.LIST) {
        const length = value.length
        if (this.required && length < 1) {
          return {
            isValid: false,
            message: this.validation.required.errorMessage,
          }
        }

        const metadataValidation = this.validateMetadata()
        if (metadataValidation.length) {
          return {
            isValid: false,
            message: this.validation.requiredMetadata.errorMessage,
            indexes: metadataValidation,
          }
        }

        const dimensions = this.validateDimensions()
        if (dimensions.length) {
          return {
            isValid: false,
            message: this.validation.dimensions.errorMessage,
            indexes: dimensions,
          }
        }

        const requiredLabelsValidation = this.validateRequiredLabels()
        if (requiredLabelsValidation.length) {
          return {
            isValid: false,
            message: this.validation.requiredLabels.errorMessage,
            indexes: requiredLabelsValidation,
          }
        }

        if (this.validation.numberOfAssets.isEnabled && length > 0
          && ((this.validation.numberOfAssets.rangeOperator === RANGE_OPERATORS.BETWEEN && (length < this.validation.numberOfAssets.min || length > this.validation.numberOfAssets.max))
            || (this.validation.numberOfAssets.rangeOperator === RANGE_OPERATORS.GTE && length < this.validation.numberOfAssets.min)
            || (this.validation.numberOfAssets.rangeOperator === RANGE_OPERATORS.LTE && length > this.validation.numberOfAssets.max))
        ) {
          return {
            isValid: false,
            message: this.validation.numberOfAssets.errorMessage,
          }
        }
      }
      return {
        isValid: true,
        message: '',
        indexes: [],
      }
    },
    async insert() {
      if (this.readOnly || !this.editable) return
      const types = []
      if (this.validation.fileType.image) {
        types.push(ASSET_TYPES.IMAGE)
      }
      if (this.validation.fileType.files) {
        types.push(ASSET_TYPES.FILE)
      }
      if (this.validation.fileType.video) {
        types.push(ASSET_TYPES.VIDEO)
      }
      if (this.validation.fileType.playlists) {
        types.push(ASSET_TYPES.VIDEO_PLAYLIST)
      }
      if (this.validation.fileType.audio) {
        types.push(ASSET_TYPES.AUDIO)
      }
      if (this.validation.fileType.podcast) {
        types.push(ASSET_TYPES.PODCAST)
      }

      const result = await this.showAssetsPopup({
        limit: this.valueType === VALUE_TYPES.SINGLE ? 1 : null,
        disableUpload: !this.showUploadNewAsset,
        selectedAssets: this.medias,
        types,
        labels: this.validation?.requiredLabels?.isEnabled ? this.validation.requiredLabels.allowedLabels : null,
      })

      this.insertImages(result)

      this.clearError()
    },

    dropRemove(asset) {
      this.droppedMedias = this.droppedMedias.filter(i => i.id !== asset.id)
      this.medias = this.medias.filter(i => i.id !== asset.id)
    },

    dropUpdate(assetId, asset) {
      this.droppedMedias = this.droppedMedias.map(i => (i.id === assetId ? this.createAssetObject(asset) : i))
      this.medias = this.medias.map(i => (i.id === assetId ? this.createAssetObject(asset) : i))
    },

    dropInsert(result) {
      this.droppedMedias = [...this.droppedMedias, ...result]
      this.insertImages(result, true)
    },

    insertImages(result, append) {
      if (this.valueType === VALUE_TYPES.SINGLE) {
        if (Array.isArray(result)) {
          if (result[0]) {
            if (result[0].id !== this.medias[0]?.id) {
              this.medias = [this.createAssetObject(result[0])]
            }
          } else {
            this.medias = []
          }
          this.$apollo.queries.assetCollection.skip = false
          this.$apollo.queries.assetCollection.refetch()
        }
      } else if (Array.isArray(result)) {
        if (append) {
          this.medias = [
            ...this.medias,
            ...result.map(asset => (this.createAssetObject(asset))),
          ]
        } else {
          this.medias = result.map(asset => {
            const existingAsset = this.medias.find(a => a.id === asset.id)
            if (existingAsset) {
              return existingAsset
            }
            return this.createAssetObject(asset)
          })
        }
        this.$apollo.queries.assetCollection.skip = false
        this.$apollo.queries.assetCollection.refetch()
      }
    },
    createAssetObject(asset) {
      return {
        ...asset,
        focalPoints: {
          default: asset.focalPoint || {
            x: 0.5,
            y: 0.5,
          },
        },
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import "@core/scss/base/bootstrap-extended/include";
// Bootstrap includes
@import "@core/scss/base/components/include"; // Components includes

.media-field__edit-cont {
  width: 100%;
}

.media-field__label {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .media-field__label-edit {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    font-size: 14px;
    align-items: center;
    word-break: break-all;
    justify-content: space-evenly;
    flex-shrink: 0;

    span {
      margin-left: 2px;
      margin-right: 2px;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
    }

    .media-field__edit-icon {

      width: 14.63px;
      height: 14.63px;
      margin-bottom: 2.4px;
      padding: .8px;
    }
  }
}

.expandable__parent {
  border-left: 1px solid #E0E5EB;
}

.expandable__button {
  width: 16px;
  height: 16px;
  margin-left: 4px;
  pointer-events: all;
}

.caption__button {
  cursor: pointer;
  pointer-events: all;
}

.media-content__wrapper {
  position: relative;

  .card-image {
    width: 100%;
    align-items: initial;
    flex-direction: row;
  }

  .media-file__card {
    width: 100%;
    height: 100%;
    margin-bottom: 0 !important;
  }
}

.media-field::v-deep .image-aspect {
  background-repeat: no-repeat;
  background-size: contain;
}

.gallery-list-edit__main {
  background-color: white;
  padding: 16px;
}

.articles-list--list {
  .card-body {
    padding: 0;
  }
}

.articles-list--grid {
  display: flex;
  flex-flow: wrap;
  margin-top: -16px;
  @include make-row();

  .article-card-column {
    @include make-col-ready();

    @include media-breakpoint-up(sm) {
      @include make-col(4);
    }
    @include media-breakpoint-up(lg) {
      @include make-col(3);
    }

    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .article-card {
    position: relative;
    flex-direction: column;
    align-items: initial;
  }

  .article-card__image {
    margin: 0;
  }

  .article-card__title {
    padding: 0;
    margin-top: 8px;
    display: flex;
  }

  .article-card__image-wrapper {
    width: 100%;
    align-items: initial;
    flex-direction: column;
  }

  .article-card__index {
    position: absolute;
    bottom: 10px;
    left: 10px;
    margin: 0;
    z-index: 1;
  }

  .article-card__remove {
    position: absolute;
    top: 10px;
    right: 10px;
    margin: 0;
    background: $primary;
    border-radius: 100px;
    color: white;
  }

  .article-card__edit {
    position: absolute;
    top: 10px;
    right: 35px;
    margin: 0;
    background: $primary;
    border-radius: 100px;
    color: white;
  }
}

.article-card {
  display: flex;
  min-height: 40px;
  margin: 20px 0;
  align-items: center;
}

.article-card:hover {
  .article-card__title {
    color: $primary;
  }

  .article-card__remove {
    visibility: visible;
  }

  .article-card__edit {
    visibility: visible;
  }
}

.article-card__image-wrapper {
  display: flex;
  align-items: center;
  position: relative;
}

.selected .b-aspect {
  outline: 4px solid #1d79f2;
  border-radius: 4px;
}

.article-card__index {
  margin: 0 24px 0 10px;

  span {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    background: #1D79F2;
    border-radius: 20px;

    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #FFFFFF;
  }
}

.article-card__image {
  min-width: 80px;
  margin-right: 24px;
}

.article-card__content {
  flex-grow: 1;
}

.article-card__title {

  &, textarea {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #2C2C2C;
    transition: color 0.3s;
  }

  textarea {
    padding: 0 !important;
    overflow: hidden !important;
    border: none;
    resize: none;

    &::placeholder {
      font-style: italic;
      color: #8E8E8E;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.article-card__remove {
  cursor: pointer;
  visibility: hidden;
}

.article-card__edit {
  cursor: pointer;
  visibility: hidden;
}

.action-buttons {
  margin-right: -8px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 34px;

  span {
    display: inline-block;
    cursor: pointer;
    padding: 0;
    margin: 0 8px;

    &.active {
      color: $primary;
    }
  }
}

</style>
