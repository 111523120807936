import i18n from '@/libs/i18n'
import StringFilterComponent from './String.vue'

export default class StringFilter {
  static COMPONENT = StringFilterComponent;

  static OPERATORS = {
    contains: 'contains',
    notContains: 'notContains',
    equals: 'eq',
    notEquals: 'ne',
    exists: 'exists',
    notExists: 'notExists',
  }

  static type = 'string'

  static options = [
    {
      label: i18n.t('filters.contains'),
      value: this.OPERATORS.contains,
    },
    {
      label: i18n.t('filters.not-contains'),
      value: this.OPERATORS.notContains,
    },
    {
      label: i18n.t('filters.is'),
      value: this.OPERATORS.equals,
    },
    {
      label: i18n.t('filters.is-not'),
      value: this.OPERATORS.notEquals,
    },
    {
      label: i18n.t('filters.is-not-empty'),
      value: this.OPERATORS.exists,
    },
    {
      label: i18n.t('filters.is-empty'),
      value: this.OPERATORS.notExists,
    }]

  static graphqlType = 'StringFilter'

  operator = null;

  value = null;

  constructor({ operator = StringFilter.OPERATORS.contains, value } = {}) {
    this.operator = operator
    this.value = value
  }

  set({ operator = StringFilter.OPERATORS.contains, value }) {
    this.operator = operator
    this.value = value
  }

  getCount() {
    return 1
  }

  getValueLabel() {
    switch (this.operator) {
      case StringFilter.OPERATORS.contains:
        return `contains "${this.value}"`
      case StringFilter.OPERATORS.notContains:
        return `does not contain "${this.value}"`
      case StringFilter.OPERATORS.equals:
        return `equals "${this.value}"`
      case StringFilter.OPERATORS.notEquals:
        return `does not equal "${this.value}"`
      case StringFilter.OPERATORS.exists:
        return i18n.t('filters.is-not-empty')
      case StringFilter.OPERATORS.notExists:
        return i18n.t('filters.is-empty')
      default:
        return null
    }
  }

  asQueryParam() {
    if (this.operator === StringFilter.OPERATORS.exists || this.operator === StringFilter.OPERATORS.notExists) {
      return {
        operator: this.operator,
      }
    }
    return {
      operator: this.operator,
      value: this.value,
    }
  }

  asGraphQL() {
    switch (this.operator) {
      case StringFilter.OPERATORS.contains:
        return {
          contains: this.value,
        }
      case StringFilter.OPERATORS.notContains:
        return {
          notContains: this.value,
        }
      case StringFilter.OPERATORS.equals:
        return {
          eq: this.value,
        }
      case StringFilter.OPERATORS.notEquals:
        return {
          ne: this.value,
        }
      case StringFilter.OPERATORS.exists:
        return {
          exists: true,
        }
      case StringFilter.OPERATORS.notExists:
        return {
          exists: false,
        }
      default:
        return null
    }
  }

  asGraphQLDynamicList() {
    if (this.asGraphQL()) {
      return {
        ...this.asGraphQL(),
        _t: StringFilter.graphqlType,
      }
    }
    return null
  }

  static validate({ operator, value }) {
    if (!operator) {
      return 'Operator is required'
    }

    if (operator === StringFilter.OPERATORS.exists || operator === StringFilter.OPERATORS.notExists) {
      return true
    }

    if (!value) {
      return 'Value is required'
    }

    return true
  }

  static fromQueryParam({ operator, value }) {
    return new StringFilter({
      operator,
      value,
    })
  }

  static shouldApplyPredefinedValue(filter) {
    const forOperators = [StringFilter.OPERATORS.equals, StringFilter.OPERATORS.contains]
    return forOperators.includes(filter.operator)
  }
}
