import Vue from 'vue'
import GjIcon from '@gjirafatech/gjirafa-icons/IconCdn.vue'
import ImageAspect from '@/components/image-aspect/ImageAspect.vue'
import CodexImage from '@/components/assets/CodexImage.vue'
import vSelect from 'vue-select'
import { $IMAGE_SIZES } from '@/utils/constants'

Vue.component('CodexImage', CodexImage)
Vue.component('GjIcon', GjIcon)
Vue.component('b-img-aspect', ImageAspect)
Vue.component('vSelect', vSelect)

Vue.prototype.$IMAGE_SIZES = $IMAGE_SIZES
