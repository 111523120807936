export default [
  {
    name: 'home-module',
    title: 'general.navbar.not-organization.home',
    icon: 'Home',
    route: { name: 'home', params: { siteName: null } },
    id: 'at-dashboard',
    group: 'top',
    children: [
      {
        name: 'home-dashboard',
        title: 'general.navbar.is-organization.dashboard',
        icon: 'Dashboard',
        route: 'home',
      },
    ],
  },
  {
    name: 'assets-module',
    title: 'general.navbar.not-organization.assets',
    icon: 'Assets',
    route: { name: 'assets', params: { siteName: null } },
    id: 'at-assets',
    group: 'top',
    children: [
      {
        name: 'assets',
        title: 'general.navbar.not-organization.assets',
        route: 'assets',
      },
    ],
  },
  {
    name: 'builder-module',
    title: 'general.navbar.not-organization.builder',
    icon: 'Builder',
    id: 'at-builder',
    route: { name: 'models', params: { siteName: null } },
    group: 'bottom',
    children: [
      {
        permission: 'canViewModels',
        name: 'builder-models',
        title: 'general.navbar.is-organization.models',
        icon: 'Modeling',
        route: 'models',
      },
      {
        permission: 'canViewWebhooks',
        name: 'builder-webhooks',
        title: 'general.navbar.is-organization.webhooks',
        icon: 'FastForward',
        route: 'webhooks',
      },
      {
        permission: 'canViewWorkflows',
        name: 'builder-workflows',
        title: 'general.navbar.is-organization.workflows',
        icon: 'FlowVerticalAlt',
        route: 'workflows',
        tag: 'general.beta',
        tagVariant: 'danger',
      },
      {
        name: 'builder-presets',
        title: 'general.navbar.is-organization.presets',
        icon: 'Crop',
        route: 'presets',
        permission: 'canViewPresets',
      },
      {
        permission: 'canViewJobs',
        name: 'builder-jobs',
        title: 'general.navbar.is-organization.jobs',
        icon: 'Settings',
        route: 'jobs',
      },
      {
        permission: 'canViewPlugins',
        name: 'builder-plugins',
        title: 'general.navbar.is-organization.plugins',
        icon: 'IntegrationPluginsPuzzleAdd',
        route: 'plugins',
      },
    ].filter(Boolean),
  },
  {
    name: 'administration-module',
    title: 'general.navbar.not-organization.administration',
    icon: 'Administration',
    id: 'at-administration',
    group: 'bottom',
    route: { name: 'dashboard-users', params: { siteName: null } },
    children: [
      {
        name: 'administration-sites',
        title: 'general.navbar.is-organization.sites',
        icon: 'Webpage',
        route: 'sites',
        permission: 'canViewSites',
      },
      {
        name: 'administration-users',
        title: 'general.navbar.is-organization.users',
        icon: 'AvatarGroup',
        route: 'dashboard-users',
        permission: 'canViewUsers',
      },
      {
        name: 'administration-teams',
        title: 'general.navbar.is-organization.teams',
        route: 'teams',
        icon: 'AccountProfileUsersGroup',
        permission: 'canViewTeams',
      },
      {
        name: 'administration-authors',
        title: 'general.navbar.is-organization.authors',
        icon: 'Avatar',
        route: 'authors',
        permission: 'canViewAuthors',
      },
      {
        name: 'administration-apikeys',
        title: 'general.navbar.is-organization.api-keys',
        icon: 'Api',
        route: 'api-keys',
        permission: 'canViewApiKeys',
      },
      {
        name: 'administration-labels',
        title: 'general.navbar.is-organization.labels',
        icon: 'Tag',
        route: 'labels',
        permission: 'canViewLabels',
      },
      {
        name: 'administration-integrations',
        title: 'general.navbar.is-organization.integrations',
        route: 'all-integrations',
        icon: 'IconparkPuzzle',
        permission: 'canViewIntegrations',
      },
      {
        name: 'administration-localizations',
        title: 'general.navbar.is-organization.localizations',
        route: 'localizations',
        icon: 'Language',
        permission: 'canManageLocalizations',
      },
    ],
  },
]
