import { ASSET_TYPES } from '@/codex-sdk/assets'

export default {
  SET_TARGET_FOLDER(state, folder) {
    state.targetFolder = folder
  },
  SET_ASSET_ID(state, assetId) {
    state.assetId = assetId
  },
  SET_SELECTED_DATA(state, data) {
    state.selectedData = data
  },
  SET_DEFAULT_SELECTED_DATA(state) {
    Object.assign(state.selectedData, {
      items: [],
      show: false,
      limit: Infinity,
      total: 0,
      totalSize: 0,
      lastSelection: null,
    })
  },
  SET_DEFAULT_FILTERS(state) {
    Object.assign(state.filters, {
      folderId: null,
      searchTerm: null,
      types: [ASSET_TYPES.IMAGE, ASSET_TYPES.FILE, ASSET_TYPES.VIDEO, ASSET_TYPES.VIDEO_PLAYLIST, ASSET_TYPES.AUDIO, ASSET_TYPES.PODCAST],
    })
  },
  UPDATE_SELECTED_ASSET(state, asset) {
    if (asset?.id) {
      const storedAsset = state.selectedData.items.find(e => e.id === asset.id)
      if (storedAsset) {
        Object.assign(storedAsset, asset)
      }
    }
  },
  OPEN_CLOSE_FOLDER(state, id) {
    if (state.openedFolders.includes(id)) {
      state.openedFolders = state.openedFolders.filter(e => e !== id)
    } else {
      state.openedFolders = [...state.openedFolders, id]
    }
  },
  CLOSE_FOLDERS(state) {
    state.openedFolders = []
  },
}
