export default class ActionSysNotifications {
  constructor({
    Subject = '',
    Body = '',
    Receiver = {
      Type: null,
      ReceiverIds: [],
    },
  } = {}) {
    this.subject = Subject
    this.body = Body
    this.isMessageCustom = !!Body
    this.receiver = Receiver ? {
      type: Receiver.Type,
      receiverIds: Receiver.ReceiverIds,
    } : {
      type: null,
      receiverIds: [],
    }
  }
}
