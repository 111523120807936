<template>
  <b-aspect
    class="image-aspect"
    :aspect="aspect"
    :style="getStyle"
  />
</template>

<script>
export default {
  name: 'BImgAspect',
  props: ['src', 'aspect'],
  computed: {
    getStyle() {
      return {
        backgroundImage: `url(${this.src})`,
      }
    },
  },
}
</script>

<style>
  .image-aspect {
    background-color: rgba(0,0,0,0.1);
    background-size: cover;
    background-position: center;
    border-radius: 4px;
  }
</style>
