import {
  createSection, readSections, readSectionsHierarchy, updateSection, deleteSection, deleteSections,
} from '../../codex-sdk/sections'

export default {
  addSection({ commit }, { section, message }) {
    return new Promise((resolve, reject) => {
      createSection(section, message)
        .then(response => {
          commit('ADD_SECTION', Object.assign(section, { id: response.data.id }))
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  fetchSections({ commit }, { ...filtersAndPagination } = {}) {
    commit('CLEAR_SECTIONS_STATE')
    return new Promise((resolve, reject) => {
      readSections(filtersAndPagination)
        .then(response => {
          const { data: { data, ...pagination } } = response
          commit('SET_SECTIONS_PAGINATION', pagination)
          commit('SET_SECTIONS', data)
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  fetchSectionsHierarchy({ commit }, { ...filtersAndPagination } = {}) {
    return new Promise((resolve, reject) => {
      readSectionsHierarchy(filtersAndPagination)
        .then(response => {
          const { data: { data, ...pagination } } = response
          commit('SET_SECTIONS_HIERARCHY_PAGINATION', pagination)
          commit('SET_SECTIONS_HIERARCHY', data)
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  updateSection({ commit }, { section, message }) {
    return new Promise((resolve, reject) => {
      updateSection(section, message)
        .then(response => {
          commit('UPDATE_SECTION', response.data)
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  removeSection({ commit }, { section, message }) {
    return new Promise((resolve, reject) => {
      deleteSection(section, message)
        .then(response => {
          commit('REMOVE_SECTION', section)
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  removeSections({ commit }, { sections, message }) {
    return new Promise((resolve, reject) => {
      deleteSections(sections, message)
        .then(response => {
          commit('REMOVE_SECTIONS', sections)
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
}
