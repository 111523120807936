import PageBreak from './PageBreak.vue'
import PageBreakPanel from './PageBreakPanel.vue'
import CodexContentEditor from '../../CodexContentEditor'
import { BLOCK_GROUPS } from '../constants'

CodexContentEditor.registerWidget('codex', {
  displayName: 'codex-editor.block-names.page-break',
  name: 'page_break',
  group: [BLOCK_GROUPS.NEWSROOM, BLOCK_GROUPS.MOST_USED],
  icon: 'List',
  // eslint-disable-next-line global-require
  image: require('../../icons/numbered-list/icon.svg'),
  attrs: {
    name: {
      default: null,
    },
    blockId: {
      default: null,
    },
    alias: {
      default: null,
    },
  },
  renderEditor: PageBreak,
  renderEditorBar: PageBreakPanel,
})
