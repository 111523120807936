<template>
  <div class="widget-not-registered">
    <p>
      <i18n path="layouts.missing-widget">
        <q>{{ attrs.original.type }}</q>
      </i18n>
    </p>
  </div>
</template>

<script>

export default {
  props: ['attrs', 'updateAttrs', 'selectNode'],
}
</script>

<style scoped lang="scss">
.widget-not-registered {
  // error style
  color: #f44336;
  font-size: 1.2em;
  text-align: center;
  padding: 1em;
  background: #f4433609;
  border-radius: 4px;
  border: 1px solid #f4433644;

  p {
    margin: 0;
  }
}
</style>
