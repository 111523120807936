<template>
  <ResizableBlock
    :attrs="attrs"
    :selected="selected"
    :select-node="selectNode"
    :update-attrs="updateAttrs"
    :entity-ref="$refs.vimeoEmbed"
  >
    <b-button-group
      v-if="!attrs.src"
      class="sourceField"
    >
      <b-input
        ref="sourceField"
        v-model="initialSrc"
        :placeholder="$t('codex-editor.nodes.vimeo-embed.vimeo-url')"
        autocomplete="off"
        @keyup.enter="setSrc"
      />
      <b-button
        variant="outline-primary"
        @click="setSrc()"
      >
        {{ $t('general.save') }}
      </b-button>
    </b-button-group>
    <div
      v-if="attrs.src"
      ref="vimeoEmbed"
      style="position:relative; width:100%;"
      :draggable="false"
      :style="{paddingTop: attrs.aspectRatio}"
    >
      <iframe
        v-if="embedSrc"
        :src="embedSrc"
        width="100%"
        frameborder="0"
        allow="autoplay;fullscreen;picture-in-picture"
        allowfullscreen
        :draggable="false"
        :title="$t('codex-editor.nodes.vimeo-embed.vimeo-video-player')"
        class="embed"
      />
      <div v-else>
        {{ $t('codex-editor.nodes.vimeo-embed.provied-url-invalid') }}
      </div>
    </div>
  </ResizableBlock>
</template>

<script>
import { getVimeoEmbed } from '@/codex-sdk/vimeo'
import ResizableBlock from '@/components/ResizableBlock.vue'

export default {
  components: {
    ResizableBlock,
  },
  props: ['attrs', 'updateAttrs', 'selectNode', 'selected'],
  inject: ['deleteBlock', 'showPromptPopup', 'toastNotification'],
  data() {
    return {
      initialSrc: '',
    }
  },
  computed: {
    embedSrc() {
      this.fetchData()
      if (this.attrs.video_id) {
        return `https://player.vimeo.com/video/${this.attrs.video_id}`
      }
      return null
    },
  },
  watch: {
    src() {
      this.$nextTick(() => {
        this.selectNode()
      })
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.initialSrc = this.src
      if (this.$refs.sourceField) {
        this.$refs.sourceField.$refs.vsinput.focus()
      }
    })
  },
  methods: {
    initialize() {
      if (!this.attrs.src) {
        this.openModal()
      }
    },
    async openModal() {
      const url = await this.showPromptPopup({ title: this.$t('codex-editor.nodes.vimeo-embed.vimeo-url') })
      if (!url) {
        this.deleteBlock(this.attrs.blockId)
      } else if (url.length && url.match((/^(https?:\/\/)?((w{3}\.)?)vimeo.com\/.*/i))) {
        this.updateAttrs({
          src: url,
        })
      } else {
        this.toastNotification({
          icon: 'XIcon',
          variant: 'danger',
          title: 'Error',
          text: this.$t('codex-editor.nodes.vimeo-embed.vimeo-invalid-url'),
        })
        this.deleteBlock(this.attrs.blockId)
      }
    },
    setSrc() {
      this.attrs.src = this.initialSrc
      this.updateAttrs({
        src: this.initialSrc,
      })
    },
    async fetchData() {
      try {
        const data = await getVimeoEmbed({
          url: this.attrs.src,
        })
        this.updateAttrs({
          video_id: data.video_id,
          aspectRatio: `${(data.height / data.width) * 100}%`,
        })
      // eslint-disable-next-line no-empty
      } catch (e) {
      }
    },
  },
}
</script>

<style scoped lang="scss">
.sourceField {
  width: 100%;
  white-space: nowrap;
}

.embed {
  position:absolute;
  left:0;
  top:0;
  width: 100%;
  height:100%;
  display: block;
}
</style>
