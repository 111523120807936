import { TYPES } from '@/views/models/constants'
import { ASSET_COMPARE_TYPES } from '@/components/assets/constants'

export const resolveFieldTypeComponent = fieldType => {
  switch (fieldType) {
    case TYPES.TEXT:
    case TYPES.INTEGER:
    case TYPES.DECIMAL:
      return 'text-field'
    case TYPES.DATE_TIME:
      return 'date-time-field'
    case TYPES.BOOLEAN:
      return 'boolean-field'
    case TYPES.JSON:
      return 'json-field'
    case TYPES.REFERENCE:
      return 'reference-field'
    case TYPES.MEDIA:
      return 'media-field'
    case TYPES.RICH_TEXT:
      return 'rich-text-field'
    case TYPES.RICH_CONTENT:
      return 'rich-content-field'
    case TYPES.AUTHOR:
      return 'author-field'
    case TYPES.SECTION:
      return 'section-field'
    case TYPES.TAG:
      return 'tag-field'
    case TYPES.URL:
      return 'url-field'
    case ASSET_COMPARE_TYPES.LABELS:
      return 'label-field'
    default:
      return 'empty-field'
  }
}
