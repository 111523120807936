<template>
  <div>
    <b-button-group>
      <b-button
        v-if="shouldDisplayHeading(CODEX_EDITOR_BLOCKS.HEADING_1)"
        v-b-tooltip.hover.html.top="getTooltipData('heading-1', ['Shift', 'Ctrl', '1'])"
        variant="flat-dark"
        :class="{'active': isLevel(1)}"
        @click="() => setLevel(1)"
      >
        H1
      </b-button>

      <b-button
        v-if="shouldDisplayHeading(CODEX_EDITOR_BLOCKS.HEADING_2)"
        v-b-tooltip.hover.html.top="getTooltipData('heading-2', ['Shift', 'Ctrl', '2'])"
        variant="flat-dark"
        :class="{'active': isLevel(2)}"
        @click="() => setLevel(2)"
      >
        H2
      </b-button>

      <b-button
        v-if="shouldDisplayHeading(CODEX_EDITOR_BLOCKS.HEADING_3)"
        v-b-tooltip.hover.html.top="getTooltipData('heading-3', ['Shift', 'Ctrl', '3'])"
        variant="flat-dark"
        :class="{'active': isLevel(3)}"
        @click="() => setLevel(3)"
      >
        H3
      </b-button>

      <b-button
        v-if="shouldDisplayHeading(CODEX_EDITOR_BLOCKS.HEADING_4)"
        v-b-tooltip.hover.html.top="getTooltipData('heading-4', ['Shift', 'Ctrl', '4'])"
        variant="flat-dark"
        :class="{'active': isLevel(4)}"
        @click="() => setLevel(4)"
      >
        H4
      </b-button>

      <b-button
        v-if="shouldDisplayHeading(CODEX_EDITOR_BLOCKS.HEADING_5)"
        v-b-tooltip.hover.html.top="getTooltipData('heading-5', ['Shift', 'Ctrl', '5'])"
        variant="flat-dark"
        :class="{'active': isLevel(5)}"
        @click="() => setLevel(5)"
      >
        H5
      </b-button>

      <b-button
        v-if="shouldDisplayHeading(CODEX_EDITOR_BLOCKS.HEADING_6)"
        v-b-tooltip.hover.html.top="getTooltipData('heading-6', ['Shift', 'Ctrl', '6'])"
        variant="flat-dark"
        :class="{'active': isLevel(6)}"
        @click="() => setLevel(6)"
      >
        H6
      </b-button>

    </b-button-group>

    <AlignBlockPanel
      :attrs="attrs"
      :update-attrs="updateAttrs"
    />

    <TextFormatPanel
      :toggle-link-popup="toggleLinkPopup"
      :commands="commands"
      :is-active="isActive"
      :include-hyper-link="true"
      :editor="editor"
    />

    <TableFormatPanel
      :commands="commands"
      :is-active="isActive"
    />
  </div>
</template>

<script>
import TextFormatPanel from '@/components/codex-editor/panels/TextFormatPanel.vue'
import AlignBlockPanel from '@/components/codex-editor/panels/AlignBlockPanel.vue'
import TableFormatPanel from '@/components/codex-editor/panels/TableFormatPanel.vue'
import { CODEX_EDITOR_BLOCKS } from '@/components/codex-editor/CodeEditorConstants'

export default {
  inject: ['getTooltipData', 'includeBlocks'],
  components: {
    TextFormatPanel,
    AlignBlockPanel,
    TableFormatPanel,
  },
  props: ['attrs', 'updateAttrs', 'commands', 'isActive', 'toggleLinkPopup', 'editor'],
  data() {
    return {
      CODEX_EDITOR_BLOCKS,
    }
  },
  computed: {
    level: { get() { return this.attrs.level }, set(e) { this.updateAttrs({ level: e }) } },
  },
  methods: {
    isLevel(level) {
      return this.level === level
    },
    setLevel(level) {
      this.level = level
    },
    shouldDisplayHeading(heading) {
      return this.includeBlocks.includes(heading)
    },
  },
}
</script>
