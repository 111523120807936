<template>
  <div class="entries-entity-info">
    <div
      v-if="entity.createdAt || (createdByUser && createdByUser.fullName)"
      class="entity-info__item"
    >
      <h5 class="entity-info__label">
        {{ $t('global-components.entity-info.create-label') }}
      </h5>
      <b-row style="display: flex; align-items: center;">
        <b-col style="padding-right: 0;">
          <p>
            {{ entity.createdAt | formatDateTime }}
          </p>
        </b-col>
        <b-col style="padding-left: 0;">
          <p
            v-if="createdByUser && createdByUser.fullName"
            class="entity-info__user-wrapper"
          >
            <b-avatar
              variant="default"
              :text="createdByUser && createdByUser.fullName[0]"
            />
            <span class="entity-info__user">
              {{ createdByUser.fullName }}
            </span>
          </p>
        </b-col>
      </b-row>
    </div>
    <div
      v-if="entity.updatedAt || (updatedByUser && updatedByUser.fullName)"
      class="entity-info__item"
    >
      <h5 class="entity-info__label">
        {{ $t('global-components.entity-info.update-label') }}
      </h5>
      <b-row style="display: flex; align-items: center;">
        <b-col style="padding-right: 0;">
          <p>
            {{ entity.updatedAt | formatDateTime }}
          </p>
        </b-col>
        <b-col style="padding-left: 0;">
          <p
            v-if="updatedByUser && updatedByUser.fullName"
            class="entity-info__user-wrapper"
          >
            <b-avatar
              variant="default"
              :text="updatedByUser.fullName && updatedByUser.fullName[0]"
            />
            <span class="entity-info__user">
              {{ updatedByUser.fullName }}
            </span>
          </p>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import gql from 'graphql-tag'

export default {
  name: 'EntityCreateUpdateInfo',
  props: {
    entity: Object,
  },
  apollo: {
    userCollection: {
      query: gql`
        query ($ids: [String!]){
          userCollection (where: {id: {in: $ids}}) {
            items {
              id
              imageUrl
              email
              firstName
              lastName
            }
          }
        }
      `,
      result({ data }) { this.users = data.userCollection.items },
      variables() {
        const ids = []
        if (this.entity.createdBy && typeof this.entity.createdBy == 'string') ids.push(this.entity.createdBy)
        if (this.entity.updatedBy && typeof this.entity.updatedBy == 'string') ids.push(this.entity.updatedBy)
        return { ids }
      },
      skip() {
        return !!this.entity.createdBy?.id && !!this.entity.updatedBy?.id
      },
    },
  },
  data() {
    return {
      users: [],
    }
  },
  computed: {
    createdByUser() {
      let user = null
      if (this.entity.createdBy?.id) {
        user = this.entity.createdBy
      } else {
        user = this.users.find(u => u.id == this.entity.createdBy)
      }

      if (user) user.fullName = `${user.firstName || ''} ${user.lastName || ''}`.trim()
      return user
    },
    updatedByUser() {
      let user = null
      if (this.entity.updatedBy?.id) {
        user = this.entity.updatedBy
      } else {
        user = this.users.find(u => u.id == this.entity.updatedBy)
      }
      if (user) user.fullName = `${user.firstName || ''} ${user.lastName || ''}`.trim()
      return user
    },
  },
}
</script>

<style lang="scss">
.entries-entity-info {
  overflow: hidden;
  .form-group {
    margin-bottom: 2px;
     .col-form-label {
       padding: 0 5px;
     }
  }
  p, legend {
    margin: 0;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #667C99 !important;
  }
  .b-avatar {
    width: 22px;
    height: 22px;
    display: flex !important;
  }
  .entity-info__labels {
    min-width: 88px;
  }
  .entity-info__label {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #052D61;
    margin: 0;
    padding: 0;
    margin-bottom: 8px;
  }
  .entity-info__item {
    margin-bottom: 12px;
  }
  .entity-info__user-wrapper {
    display: flex;
    align-items: center;
    margin-top: 4px;
    .entity-info__user {
      margin-left: 8px;
      display: -webkit-box !important;
      -webkit-box-orient: vertical;
      overflow: hidden;
      -webkit-line-clamp: 1;
      word-break: break-all;
    }
  }
}

</style>
