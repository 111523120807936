<template>
  <section class="rich-content">
    <div
      v-if="distinctBlocks && !distinctBlocks.length"
      class="rich-content__item no-content"
    >
      <div
        v-for="i in 2"
        :key="i"
        class="rich-content__entry"
      >
        <div class="field__checkbox">
          <b-form-radio disabled />
        </div>
        <b-form-group
          class="rich-content__content"
        >
          <template #label>
            <FieldName
              :name="field.name"
              :help-text="''"
            />
          </template>
          <div class="content-list__empty">
            <GjIcon name="EditTextAAlt" />
            <span>{{ $tc('entries.merge-tool.info.no-content') }}</span>
          </div>
        </b-form-group>
      </div>
    </div>
    <div
      v-for="(block, index) in (differencesOnly ? filteredBlocks : distinctBlocks)"
      :key="block"
      class="rich-content__item"
    >
      <div class="rich-content__entry">
        <div class="field__checkbox">
          <b-form-radio
            v-model="selectedFields[field.alias][block]"
            :value="1"
            :disabled="entriesAreEqual(block)"
          />
        </div>
        <b-form-group
          v-if="getBlock(firstEntry, block)"
          class="rich-content__content"
        >
          <template
            v-if="index === 0"
            #label
          >
            <FieldName
              :name="field.name"
              :help-text="''"
            />
          </template>
          <component
            :is="resolveRichContentComponent(getBlock(firstEntry, block).type)"
            :id="`first-${block}`"
            v-bind="getCustomProps(getBlock(firstEntry, block))"
          />
        </b-form-group>
        <EmptyField v-else />
      </div>
      <div class="rich-content__entry">
        <div class="field__checkbox">
          <b-form-radio
            v-model="selectedFields[field.alias][block]"
            :value="2"
            :disabled="entriesAreEqual(block)"
          />
        </div>
        <b-form-group
          v-if="getBlock(secondEntry, block)"
          class="rich-content__content"
        >
          <template
            v-if="index === 0"
            #label
          >
            <FieldName
              :name="field.name"
              :help-text="''"
            />
          </template>
          <component
            :is="resolveRichContentComponent(getBlock(secondEntry, block).type)"
            :id="`second-${block}`"
            v-bind="getCustomProps(getBlock(secondEntry, block))"
          />
        </b-form-group>
        <EmptyField v-else />
      </div>
    </div>
    <div class="rich-content__divider">
      <GjIcon
        name="Switch"
        size="24"
      />
    </div>
  </section>
</template>

<script>
import FieldName from '@/components/fields/FieldName.vue'
import { BFormRadio } from 'bootstrap-vue'
import { computed, watch } from '@vue/composition-api'
import store from '@/store'
import isEqual from 'lodash/isEqual'
import GjIconLoader from '@gjirafatech/gjirafa-icons/GjIconLoader'
import { CODEX_EDITOR_BLOCKS } from '@/components/codex-editor/CodeEditorConstants'
import EmptyField from './EmptyField.vue'
import { resolveRichContentComponent, getCustomProps } from './richContentUtils'
import TextComponent from './rich-content-components/TextComponent.vue'
import ListComponent from './rich-content-components/ListComponent.vue'
import BlockquoteComponent from './rich-content-components/BlockquoteComponent.vue'
import DefaultComponent from './rich-content-components/DefaultComponent.vue'
import ReferenceField from './ReferenceField.vue'
import MediaField from './MediaField.vue'
import TableComponent from './rich-content-components/TableComponent.vue'
import EmbedComponent from './rich-content-components/EmbedComponent.vue'

export default {
  name: 'RichContentField',
  components: {
    FieldName,
    BFormRadio,
    EmptyField,
    /* eslint-disable vue/no-unused-components */
    TextComponent,
    ListComponent,
    BlockquoteComponent,
    DefaultComponent,
    ReferenceField,
    MediaField,
    TableComponent,
    EmbedComponent,
    GjIconLoader,
  },
  props: {
    firstEntry: Array,
    secondEntry: Array,
    field: Object,
    differencesOnly: Boolean,
    allSelected: Object,
  },
  // eslint-disable-next-line object-curly-newline
  setup(props) {
    const selectedFields = computed(() => store.getters['mergingTool/selectedFields'])
    const distinctBlocks = computed(() => {
      // remove the last empty paragraphs of richContent
      const lastElement = props.firstEntry[props.firstEntry.length - 1]
      if (lastElement && lastElement.type === CODEX_EDITOR_BLOCKS.PARAGRAPH && !lastElement.content) props.firstEntry.pop()

      const lastElem = props.secondEntry[props.secondEntry.length - 1]
      if (lastElem && lastElem.type === CODEX_EDITOR_BLOCKS.PARAGRAPH && !lastElem.content) props.secondEntry.pop()

      const arr = props.firstEntry.map(entry => entry.attrs.blockId).concat(props.secondEntry.map(entry => entry.attrs.blockId))
      return [...new Set(arr)]
    })
    const getBlock = (entry, blockId) => entry.find(e => e.attrs.blockId === blockId)

    const entriesAreEqual = blockId => {
      const firstBlock = getBlock(props.firstEntry, blockId)
      const secondBlock = getBlock(props.secondEntry, blockId)

      return isEqual(firstBlock, secondBlock)
    }

    const filteredBlocks = computed(() => distinctBlocks.value.reduce((acc, rec) => {
      if (!isEqual(getBlock(props.firstEntry, rec), getBlock(props.secondEntry, rec))) {
        acc.push(rec)
      }
      return acc
    }, []))

    watch(props.allSelected, value => store.dispatch('mergingTool/selectRichContentFields', { field: props.field.alias, list: distinctBlocks.value, status: value.value }))

    return {
      getBlock,
      entriesAreEqual,
      resolveRichContentComponent,
      distinctBlocks,
      filteredBlocks,
      getCustomProps,
      selectedFields,
    }
  },
}
</script>

<style lang="scss">
.rich-content {
  position: relative;
  flex-grow: 1;
  max-width: 100%;
}

.rich-content__item {
  display: flex;
  max-width: 100%;
  justify-content: space-between;
}

.rich-content__entry {
  display: flex;
  gap: 14px;
  width: calc(50% - 30px);
  align-items: center;
  background: #fff;
  padding: 20px 32px 20px 12px;
  border-bottom: 1px solid #E0E5EB;
  position: relative;
}

.rich-content__item.no-content .rich-content__entry {
  padding: 20px;

  h6.no-transform {
    top: 0;
    left: 0;
    position: unset;
  }
}

.rich-content__item:first-child .rich-content__entry {
  border-radius: 4px 4px 0 0;
}

.rich-content__item:nth-last-child(2) .rich-content__entry {
  border: none;
  border-radius: 0 0 4px 4px;
}

.rich-content__content {
  flex-grow: 1;
  align-self: start;
  overflow-x: auto;

  > h6 {
    margin-bottom: 24px;
    padding-bottom: 8px;
    position: absolute;
    left: 12px;
    top: 12px;

    &.no-transform {
      transform: none;
      margin: 0;
    }
  }
}

.rich-content__divider {
  position: absolute;
  top: calc(50% - 12px);
  left: calc(50% - 12px);
}

</style>
