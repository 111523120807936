<template>
  <b-container
    fluid
    class="pb-1"
    :class="isReadOnly && 'is-preview'"
  >
    <component
      :is="item.type"
      v-for="(item, index) in blocks"
      :id="item.attrs._id ? item.attrs._id : null"
      :key="item.id"
      :row="item.type === 'codex_row' ? item : null"
      :container="item.type === 'codex_container' ? item : null"
      :row-index="item.type === 'codex_row' ? index : null"
      :items="item.blocks || item.content"
    />
  </b-container>
</template>

<script>
import CodexContainer from './CodexContainer.vue'
import Row from './Row.vue'

export default {
  name: 'ModelRenderer',
  components: {
    codex_container: CodexContainer,
    codex_row: Row,
  },
  provide() {
    return {
      generateDisplayClasses: this.generateDisplayClasses,
      isReadOnly: () => this.isReadOnly,
    }
  },
  props: {
    model: Object,
    properties: Object,
    isReadOnly: Boolean,
  },
  computed: {
    blocks() {
      return this.model.blocks
    },
  },
  methods: {
    generateDisplayClasses(item, type) {
      const classes = []
      const displayType = type === 'widget' ? 'block' : 'flex'

      // DESKTOP DISPLAY
      if (item.attrs._responsive.desktop.display) {
        classes.push(`d-lg-${displayType} d-xl-${displayType}`)
      } else {
        classes.push('d-lg-none d-xl-none')
      }
      // TABLET DISPLAY
      if (item.attrs._responsive.tablet.display) {
        classes.push(`d-md-${displayType}`)
      } else {
        classes.push('d-md-none')
      }
      // MOBILE DISPLAY
      if (item.attrs._responsive.mobile.display) {
        classes.push(`d-sm-${displayType} d-${displayType}`)
      } else {
        classes.push('d-sm-none d-none')
      }

      return classes
    },
  },
}
</script>

<style>
.is-preview .disable-in-quick-view {
  pointer-events: none;
}
</style>
