import navMenuItems from '@/navigation/site'

export default {
  currentOrganization: null,
  currentSite: null,
  currentAuthor: null,
  breadcrumbItems: [],

  // Used by plugins to add new navigation items
  extraNavigationItems: [],
  siteNavigationItems: navMenuItems,

  views: [],
  domain: '',
  currentUserPermissions: [{
    action: 'manage',
    subject: 'all',
  }],
  video: {
    organizationId: null,
    projectId: null,
    apiKey: null,
    adScheduleId: null,
    playerId: null,
    sizeType: null,
    width: null,
    height: null,
  },
  site_settings: {},
  vpConnection: null,
  models: [],

  // All models from model management
  allModels: [],

  allIntegrationConnections: [],
}
