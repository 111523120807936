<template>
  <b-form-group>
    <template #label>
      <FieldName
        :name="name"
        :help-text="helpText"
        :help-text-display="helpTextDisplay"
        :required="required"
      />
    </template>
    <TagsSelect
      v-model="computedValue"
      :saved-options="savedTagsOptions"
      :geneea-options="geneeaOptions"
      :dandelion-options="dandelionOptions"
      :cmifrancetags-options="cmiFranceTagsOptions"
      :aigeneratedtags-options="aiGeneratedTagsOptions"
      :loading="geneeaTagsLoading || dandelionTagsLoading || savedTagsLoading || cmiFranceTagsLoading || aiGeneratedTagsLoading"
      :block-id="widget.id"
      :configuration="configurationComputed"
      :widget="widget"
      :disabled="readOnly || !editable"
      @ref="handleTagsSelectRef"
      @search="val => query = val"
      @reFetch="reFetchData"
    />
    <FieldError :error="error" />
  </b-form-group>
</template>

<script>
import { generateComputedPropsFromAttrs } from '@/components/codex-layout-editor/BuilderUtils'
import {
  FIELD_FILTER_OPERATORS, getContentText, getGeneeaContent, RANGE_OPERATORS,
} from '@/views/models/constants'
import {
  dandelionTags, geneeaTags, geneeaV2Tags, cmiFranceTags, INTEGRATION_ALIASES, aiGeneratedTags,
} from '@/codex-sdk/all-integrations'
import FieldName from '@/components/fields/FieldName.vue'
import gql from 'graphql-tag'
import TagsSelect from '@/components/fields/Tag/TagsSelect.vue'
import { mapSourceToNumber, TAG_SOURCES } from '@/codex-sdk/tags'
import { mapGetters } from 'vuex'
import { canCreateSuggestedTag } from '@/codex-permissions/tags'
import store from '@/store'
import TagListFilter from '@/components/filters-dropdown/filters/tagList/TagListFilter'
import BaseFieldMixin from '@/components/fields/BaseFieldMixin'
import FieldRenderMixin from '@/components/fields/RenderFieldMixin'
import FieldError from '@/components/fields/FieldError.vue'

export default {
  name: 'TagField',
  inject: ['model', 'entry', 'filterValues', 'entryId', 'toastNotification'],
  components: {
    FieldError,
    TagsSelect,
    FieldName,
  },
  mixins: [BaseFieldMixin, FieldRenderMixin],
  props: {
    value: {
      type: [String, Array],
      default: null,
    },
  },
  apollo: {
    savedTagsOptions: {
      fetchPolicy: 'network-only',
      query: gql`
        query tags ($query: String, $siteId: String) {
          tags: tagCollection (where: {query: $query, siteId: { eq: $siteId }}, limit: 40, offset: 0) {
            items {
              id
              tagAlias
              tagValue
              source
              externalId
              siteId
            }
            total
          }
        }
      `,
      variables() {
        return {
          query: this.query,
          siteId: this.currentSite.id,
        }
      },
      update(data) {
        this.savedTagsLoading = false
        return data.tags.items
      },
    },
  },
  data() {
    return {
      previousContent: '',
      geneeaOptions: [],
      dandelionOptions: [],
      cmiFranceTagsOptions: [],
      aiGeneratedTagsOptions: [],
      savedTags: [],
      savedTagsLoading: false,
      geneeaTagsLoading: false,
      dandelionTagsLoading: false,
      cmiFranceTagsLoading: false,
      aiGeneratedTagsLoading: false,
      tagsSelectRef: null,

      query: '',
      configuration: {
        [TAG_SOURCES.CODEX]: true,
        [TAG_SOURCES.GENEA]: false,
        [TAG_SOURCES.DANDELION]: false,
        [TAG_SOURCES.CMI_FRANCE]: false,
      },
    }
  },
  computed: {
    ...mapGetters('sites', [
      'getSite',
    ]),
    ...generateComputedPropsFromAttrs([
      'alias',
      'name',
      'alias',
      'configured',
      'appearance',
      'validation',
      'valueType',
      'helpText',
      'helpTextDisplay',
      'defaultValue',
    ]),
    computedValue: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit('input', v)
      },
    },
    currentModel() {
      return this.model()
    },
    currentEntry() {
      return this.entry()
    },
    currentSite() {
      const siteId = this.currentEntry?.system?.siteId || this.$store.state.general.currentSite?.id
      return this.getSite(siteId) || this.$store.state.general.currentSite || {}
    },
    loading() {
      return this.savedTagsLoading || this.geneeaTagsLoading || this.dandelionTagsLoading || this.aiGeneratedTagsLoading
    },
    geneeaV1Connection() {
      return this.$store.state.general.allIntegrationConnections?.find(item => item.integration === INTEGRATION_ALIASES.GENEEA_V1 && item.attrs?.userKey)
    },
    geneeaV2Connection() {
      return this.$store.state.general.allIntegrationConnections?.find(item => item.integration === INTEGRATION_ALIASES.GENEEA_V2 && item.attrs?.userKey && item.attrs?.sites.some(s => s === this.currentSite.id || s === 'all'))
    },
    dandelionConnection() {
      return this.$store.state.general.allIntegrationConnections?.find(item => item.integration === INTEGRATION_ALIASES.DANDELION && item.attrs?.apiKey && item.attrs?.sites.some(s => s === this.currentSite.id || s === 'all'))
    },
    cmiFranceConnection() {
      return this.$store.state.general.allIntegrationConnections?.find(item => item.integration === INTEGRATION_ALIASES.CMI_FRANCE && item.attrs?.apiKey && item.attrs?.sites.some(s => s === this.currentSite.id || s === 'all'))
    },
    content() {
      return getContentText(this.currentModel.blocks, this.currentEntry) || ''
    },
    configurationComputed() {
      return {
        ...this.configuration,
        AI: this.widget?.attrs?.configuration?.aiFeatures,
      }
    },
  },
  beforeMount() {
    if (!this.validation.numberOfTags) {
      this.$set(this.validation, 'numberOfTags', {
        isEnabled: false,
        rangeOperator: RANGE_OPERATORS.BETWEEN,
        min: 2,
        max: 5,
        exactly: 1,
        errorMessage: 'Number of tags: 2-5.',
        errorTemplate: 'Number of tags: {min}-{max}',
      })
    }

    if (!this.widget.attrs.hidden) {
      this.$set(this.widget.attrs, 'hidden', {
        value: false,
        conditionsEnabled: false,
        conditions: [
          {
            isSystem: false,
            field: '',
            operator: FIELD_FILTER_OPERATORS.EXISTS,
            value: '',
          },
        ],
      })
    }
  },
  mounted() {
    if (this.entryId() === 'create' && !this.readOnly && this.editable) {
      // Prefill from filter
      if (this.filterValues && this.filterValues?.[this.alias]) {
        if (TagListFilter.shouldApplyPredefinedValue(this.filterValues[this.alias])) {
          this.computedValue = this.filterValues?.[this.alias].value.map(tagId => {
            const tag = store.state.filters.filtersCache[tagId]

            return {
              id: tag.id,
              source: mapSourceToNumber(tag.source),
              tag: tag.tagValue,
              alias: tag.tagAlias,
              externalId: tag.externalId,
            }
          })
        }
      }
    }
    this.$root.$on('ai-generated-content', this.setAiGeneratedContent)
  },
  beforeDestroy() {
    this.$root.$off('ai-generated-content', this.setAiGeneratedContent)
  },
  methods: {
    handleTagsSelectRef(ref) {
      this.tagsSelectRef = ref
    },
    setAiGeneratedContent(alias, value, replace) {
      if (this.alias === alias && replace) {
        const tagReplaceOptions = value.split(',').map(item => item.trim())
        const aiSuggestedReplace = tagReplaceOptions.slice(0, 20).map(val => ({
          alias: val?.trim()?.toLowerCase()?.replaceAll('  ', ' '),
          tag: val,
          source: TAG_SOURCES.CODEX,
        }))

        this.computedValue = aiSuggestedReplace
        this.toastNotification({
          title: replace ? this.$t('site.add-site.replace') : this.$t('fields.general.ai-popup.results.retry-label'),
          text: this.$t('entries.ai-content-analyzer.seo.ai-suggestion-replaced'),
          variant: 'success',
          icon: 'Check',

        })
      }
    },

    reFetchData() {
      if (this.previousContent === this.content) return
      this.previousContent = this.content
      this.getGeneeaTags()
      this.getDandelionTags()
      this.getCmiFranceTags()
      this.getAIGeneratedTags()
    },
    validate(value) {
      const listLength = value?.length
      if (this.required && !listLength) {
        return { isValid: false, message: this.validation.required.errorMessage }
      }

      if (this.validation.numberOfTags.isEnabled && listLength > 0) {
        if (this.validation.numberOfTags.rangeOperator === RANGE_OPERATORS.BETWEEN && (
          listLength < this.validation.numberOfTags.min || listLength > this.validation.numberOfTags.max)) {
          return { isValid: false, message: this.validation.numberOfTags.errorMessage }
        }
        if (this.validation.numberOfTags.rangeOperator === RANGE_OPERATORS.EXACTLY
          && listLength != this.validation.numberOfTags.exactly) {
          return { isValid: false, message: this.validation.numberOfTags.errorMessage }
        }
        if (this.validation.numberOfTags.rangeOperator === RANGE_OPERATORS.GTE
          && listLength < this.validation.numberOfTags.min) {
          return { isValid: false, message: this.validation.numberOfTags.errorMessage }
        }
        if (this.validation.numberOfTags.rangeOperator === RANGE_OPERATORS.LTE
          && listLength > this.validation.numberOfTags.max) {
          return { isValid: false, message: this.validation.numberOfTags.errorMessage }
        }
      }
      return { isValid: true }
    },
    async getCmiFranceTags() {
      if ((!this.cmiFranceConnection || !canCreateSuggestedTag().can) || this.cmiFranceTagsLoading) return
      this.cmiFranceTagsLoading = true
      this.configuration[TAG_SOURCES.CMI_FRANCE] = true

      try {
        const { data } = await cmiFranceTags(this.content, this.cmiFranceConnection?.attrs)

        if (data?.items?.length) {
          this.cmiFranceTagsOptions = data?.items?.map(tag => {
            const path = tag?.path || []
            let parentPath = path.join('/')
            if (parentPath) parentPath += '/'

            const value = tag.value
            const tagAlias = (parentPath || '') + value
            return {
              tagAlias: tagAlias?.trim()?.toLowerCase()?.replaceAll('  ', ' '),
              tagValue: tag.value,
              source: TAG_SOURCES.CMI_FRANCE,
              externalId: tag.id,
            }
          })
        }
      } catch (e) {
        console.log(e)
      }
      this.cmiFranceTagsLoading = false
    },
    async getGeneeaTags() {
      if (!canCreateSuggestedTag().can || this.geneeaTagsLoading) return

      try {
        if (this.geneeaV2Connection) {
          // Geneea V2
          await this.geneeaV2Tags()
        } else if (this.geneeaV1Connection) {
          // Geneea V1
          await this.geneeaV1Tags()
        }
      } catch (e) {
        console.log(e)
      }
      this.geneeaTagsLoading = false
    },
    async geneeaV1Tags() {
      this.configuration[TAG_SOURCES.GENEA] = true
      this.geneeaTagsLoading = true
      const { data } = await geneeaTags(this.content, this.geneeaV1Connection.attrs.userKey)
      this.geneeaOptions = data?.tags?.map(tag => ({
        tagAlias: tag.stdForm?.trim()?.toLowerCase()?.replaceAll('  ', ' '),
        tagValue: tag.stdForm,
        source: TAG_SOURCES.GENEA,
        externalId: tag.id,
      }))
    },
    async geneeaV2Tags() {
      const fetchTaxonomySections = async content => {
        const { data } = await this.$apollo.query({
          query: gql`
          query Sections($sections: [String!]!){
            sectionCollection(where: { id: { in: $sections  } }) {
                items {
                   url
                   id
                   description
                }
            }
          }
        `,
          variables: {
            sections: content.sections.map(item => item.id),
          },
        })
        return data
      }

      this.geneeaTagsLoading = true
      this.configuration[TAG_SOURCES.GENEA] = true
      const content = getGeneeaContent(this.currentModel.blocks, this.currentEntry, this.currentModel)

      const taxonomy = content?.sections?.length ? await fetchTaxonomySections(content) : null

      const payload = {
        id: this.currentEntry.id,
        title: content.title,
        text: this.content,
        categories: taxonomy?.sectionCollection?.items?.length
          ? taxonomy.sectionCollection.items.map(item => ({ taxonomy: 'Custom', code: `${this.currentSite.domain}${item.url}` })) : [
            { taxonomy: 'Custom', code: this.currentSite.domain },
          ],
        presentationLanguage: 'cs',
      }
      const { data } = await geneeaV2Tags(payload, this.geneeaV2Connection.attrs.userKey)
      this.geneeaOptions = data?.tags?.map(tag => ({
        tagAlias: tag.stdForm?.trim()?.toLowerCase()?.replaceAll('  ', ' '),
        tagValue: tag.stdForm,
        source: TAG_SOURCES.GENEA,
        externalId: tag.id,
      })) || []
    },
    async getDandelionTags() {
      try {
        if ((!this.dandelionConnection || !canCreateSuggestedTag().can) || this.dandelionTagsLoading) return

        this.dandelionTagsLoading = true
        this.configuration[TAG_SOURCES.DANDELION] = true
        const { data } = await dandelionTags(this.content.slice(0, 1300), this.dandelionConnection.attrs)

        const collection = []
        if (data?.annotations?.length > 1) {
          data.annotations.sort((a, b) => {
            if (a.confidence > b.confidence) return -1
            if (a.confidence < b.confidence) return 1
            return 0
          }).forEach(annotation => {
            if (!collection.some(item => item.label === annotation.label)) {
              collection.push(annotation)
            }
          })
        }
        this.dandelionOptions = collection.slice(0, 20).map(value => ({
          tagAlias: value.label?.trim()?.toLowerCase()?.replaceAll('  ', ' '),
          tagValue: value.label,
          source: TAG_SOURCES.DANDELION,
          externalId: value.id,
        }))
      } catch (e) {
        console.log(e)
      }
      this.dandelionTagsLoading = false
    },
    async getAIGeneratedTags() {
      if (!this.widget?.attrs?.configuration?.aiFeatures) return
      try {
        this.aiGeneratedTagsLoading = true
        this.configuration[TAG_SOURCES.CODEX] = true
        const { data } = await aiGeneratedTags(this.entryId())
        if (data.tags.length > 0) {
          this.aiGeneratedTagsOptions = data.tags.slice(0, 20).map(tag => ({
            tagAlias: tag?.trim()?.toLowerCase()?.replaceAll('  ', ' '),
            tagValue: tag,
            source: TAG_SOURCES.CODEX,
          }))
        }
      } catch (e) {
        console.log(e)
      }
      this.aiGeneratedTagsLoading = false
    },
  },
}
</script>
