export default [
  {
    path: '/:orgName/plugins/*',
    name: 'plugins',
    component: () => import('@/views/plugins/Index.vue'),
    meta: {
      rule: 'editor',
      organizationRequired: true,
    },
  },
  {
    path: '/:orgName/:siteName/plugins/*',
    name: 'plugins-site',
    component: () => import('@/views/plugins/Index.vue'),
    meta: {
      rule: 'editor',
      siteRequired: true,
      organizationRequired: true,
    },
  },
  {
    path: '/:orgName/:siteName/sections',
    name: 'sections',
    component: () => import('@/views/sections/Listing.vue'),
    meta: {
      permission: 'canViewSections',
      rule: 'editor',
      siteRequired: true,
      organizationRequired: true,
      pageTitle: 'routes.site.pageTitles.sections',
      navActiveLink: 'name:sections-{siteName}',
      lastRouteLink: 'sections',
      breadcrumb: [

        {
          text: 'routes.site.pageTitles.sections',
          active: true,
        },
      ],
    },
  },
  {
    path: '/:orgName/:siteName/urls',
    name: 'urls',
    component: () => import('@/views/urls/Listing.vue'),
    meta: {
      permission: 'canViewUrl',
      rule: 'editor',
      siteRequired: true,
      organizationRequired: true,
      pageTitle: 'routes.site.pageTitles.url',
      navActiveLink: 'name:urls-{siteName}',
      lastRouteLink: 'urls',
      breadcrumb: [
        {
          text: 'routes.site.pageTitles.url',
          active: true,
        },
      ],
    },
  },
  {
    path: '/:orgName/:siteName/lists/static',
    name: 'lists-static',
    component: () => import('@/views/lists/Listing.vue'),
    props: true,
    meta: {
      permission: 'canViewLists',
      rule: 'editor',
      siteRequired: true,
      organizationRequired: true,
      pageTitle: 'routes.site.pageTitles.lists',
      navActiveLink: 'name:lists-static-{siteName}',
      breadcrumb: [
        {
          text: 'routes.site.pageTitles.lists',
          active: true,
        },
      ],
    },
  },
  {
    path: '/:orgName/:siteName/lists/static/:id',
    name: 'lists-static-edit',
    component: () => import('@/views/lists/Edit.vue'),
    meta: {
      permission: 'canViewLists',
      rule: 'editor',
      siteRequired: true,
      organizationRequired: true,
      collapseSidebar: true,
      pageTitle: 'routes.site.pageTitles.editCreateList',
      navActiveLink: 'name:lists-static-{siteName}',
      breadcrumb: [
        {
          text: 'routes.site.pageTitles.lists',
          to: { name: 'lists-static' },
        },
        {
          text: 'routes.site.pageTitles.editCreateList',
          active: true,
        },
      ],
    },
  },
  {
    path: '/:orgName/:siteName/lists/dynamic',
    name: 'lists-dynamic',
    component: () => import('@/views/dynamic-lists/Listing.vue'),
    props: true,
    meta: {
      permission: 'canViewLists',
      rule: 'editor',
      siteRequired: true,
      organizationRequired: true,
      pageTitle: 'routes.site.pageTitles.lists',
      navActiveLink: 'name:lists-dynamic-{siteName}',
      breadcrumb: [
        {
          text: 'routes.site.pageTitles.lists',
          active: true,
        },
      ],
    },
  },
  {
    path: '/:orgName/:siteName/lists/dynamic/:id',
    name: 'lists-dynamic-edit',
    component: () => import('@/views/dynamic-lists/Edit.vue'),
    meta: {
      permission: 'canViewLists',
      rule: 'editor',
      siteRequired: true,
      organizationRequired: true,
      collapseSidebar: true,
      pageTitle: 'routes.site.pageTitles.editCreateList',
      navActiveLink: 'name:lists-dynamic-{siteName}',
      breadcrumb: [
        {
          text: 'routes.site.pageTitles.lists',
          to: { name: 'lists-dynamic' },
        },
        {
          text: 'routes.site.pageTitles.editCreateList',
          active: true,
        },
      ],
    },
  },
  {
    path: '/:orgName/:siteName/entries/:viewId',
    name: 'entries',
    component: () => import('@/views/entries/Listing.vue'),
    meta: {
      rule: 'editor',
      siteRequired: true,
      organizationRequired: true,
      pageTitle: 'routes.site.pageTitles.createEditModels',
      navActiveLink: 'name:entries-{siteName}-{viewId}',
      lastRouteLink: 'entries',
      breadcrumb: [

      ],
    },
  },
  {
    path: '/:orgName/:siteName/entries/:viewId/:model/:id',
    name: 'entries-edit',
    component: () => import('@/views/entries/Edit.vue'),
    props: route => ({ viewId: route.params.viewId || 'all' }),
    meta: {
      permission: 'canViewEntries',
      rule: 'editor',
      siteRequired: true,
      organizationRequired: true,
      collapseSidebar: true,
      pageTitle: 'routes.site.pageTitles.createEditModels',
      navActiveLink: 'name:entries-{siteName}-{viewId}',
      lastRouteLink: 'entries',
      breadcrumb: [

      ],
    },
  },
  {
    path: '/:orgName/:siteName/tags',
    name: 'tags',
    component: () => import('@/views/tags/Listing.vue'),
    meta: {
      rule: 'editor',
      permission: 'canViewTags',
      siteRequired: true,
      organizationRequired: true,
      pageTitle: 'routes.site.pageTitles.tags',
      navActiveLink: 'name:tags-{siteName}',
      lastRouteLink: 'tags',
      breadcrumb: [
        {
          text: 'routes.site.pageTitles.tags',
          active: true,
        },
      ],
    },
  },
]
