<template>
  <transition>
    <b-modal
      id="mbase-disconnect__popup"
      v-model="showPopup"
      :title="$t('integrations.integrations.prolexis.modal.title')"
      centered
      :ok-variant="showConnectionList ? 'outline-secondary':'primary'"
      :ok-title="showPropperTitle"
      cancel-variant="outline-secondary"
      :cancel-title="$t('general.cancel')"
      size="md"
      :ok-disabled="disableOk"
      :ok-only="showConnectionList"
      :static="true"
      @ok="triggerPropperMethod"
      @cancel="handleCancel"
      @hide="handleCancel"
    >
      <div v-if="showMoreDetails">
        <div
          class="vp-integration__sidebar-info"
        >
          <div class="integration-logo">
            <img
              src="../../../assets/icons/prolexis.svg"
              alt=""
            >
          </div>
          <div class="vp-integration__sidebar-header">
            <p>{{ getIntegration.name }}</p>
            <small>{{ $t('integrations.prolexis-details') }}</small>
          </div>
        </div>
      </div>

      <div v-else>

        <div
          v-if="showConnectionList"
        >
          <div
            class="vp-integration__sidebar-info"
          >
            <div class="integration-logo">
              <img
                src="../../../assets/icons/prolexis.svg"
                alt=""
              >
            </div>
            <div class="vp-integration__sidebar-header">
              <p>{{ getIntegration.name }}</p>
              <small>{{ getIntegration.description }}</small>
            </div>
          </div>

          <div
            class="vp-integration__sidebar-content"
          >
            <div
              v-for="activation in activations"
              :key="activation.id"
              class="vp-integration__sidebar-connection"
            >
              <p @click="editActivation(activation)">
                {{ activation.attrs.connectionName }}
              </p>
              <div class="connection__more-actions">
                <div
                  v-permission="['canEditIntegration']"
                  class="edit-option"
                  @click="editActivation(activation)"
                >
                  <GjIcon
                    name="Edit"
                    size="20"
                    class="cursor-pointer"
                  />
                  <small>{{ $t('integrations.vp.edit') }}</small>
                </div>
                <div
                  v-permission="['canDeleteIntegration']"
                  class="disconnect-option"
                  @click="disconnectActivation(activation)"
                >
                  <GjIcon
                    name="DeleteTrash"
                    size="20"
                    class="cursor-pointer"
                  />
                  <small>{{ $t('integrations.vp.disconnect') }}</small>
                </div>
              </div>
            </div>

            <div>
              <p
                v-permission="['canCreateIntegration']"
                class="new-connection"
                @click="showConnectionList = !showConnectionList"
              >
                {{ $t('integrations.vp.add-connection') }}
              </p>
            </div>
          </div>
        </div>

        <div v-else>
          <div class="new-connection__back-arrow">
            <GjIcon
              name="ArrowLeft"
              size="30"
              class="cursor-pointer"
              @click.native="goBack"
            />
            <p :class="{'connection-name': prolexis.attrs.connectionName}">
              {{ prolexis.attrs.connectionName ? prolexis.attrs.connectionName : $t('integrations.integrations.prolexis.modal.new') }}
            </p>
          </div>
          <div class="vp-connection__new">
            <b-form-group :label="$t('integrations.integrations.prolexis.modal.connection-label')">
              <b-input
                v-model="prolexis.attrs.connectionName"
                style="font-size:14px"
                autofocus
                :placeholder="$t('integrations.integrations.prolexis.modal.connection-placeholder')"
              />
            </b-form-group>
            <b-form-group :label="$t('integrations.integrations.prolexis.modal.url-label')">
              <b-input
                v-model="prolexis.attrs.url"
                style="font-size:14px"
                :placeholder="$t('integrations.integrations.prolexis.modal.url-placeholder')"
              />
            </b-form-group>
            <b-form-group :label="$t('integrations.integrations.prolexis.modal.api-key-label')">
              <b-input
                v-model="prolexis.attrs.apiKey"
                style="font-size:14px"
                :placeholder="$t('integrations.integrations.prolexis.modal.api-key-placeholder')"
              />
            </b-form-group>
            <b-form-group :label="$t('integrations.integrations.prolexis.modal.sites-label')">
              <SitesDropdownSelect
                v-model="prolexis.attrs.sites"
                :placeholder="$t('integrations.integrations.prolexis.modal.sites-placeholder')"
                class="sites-dropdown"
                :all="true"
                :clearable="false"
                :integration="!prolexis.id ? getIntegration : null"
              />
            </b-form-group>
          </div>
        </div>
      </div>
    </b-modal>
  </transition>
</template>

<script>
import PopupsMixin from '@/components/popups/PopupsMixin'
import SitesDropdownSelect from '@/components/SitesDropdownSelect.vue'
import { cloneDeep } from 'lodash'
import { prolexisDefaults } from '@/codex-sdk/all-integrations'
import { canEditIntegration } from '@/codex-permissions/integrations'
import { ENTITY_TYPES } from '@/utils/constants'

export default {
  components: {
    SitesDropdownSelect,
  },
  mixins: [PopupsMixin],
  inject: ['showConfirmDeletePopup'],
  data() {
    return {
      prolexis: prolexisDefaults(),
      integration: null,
      activations: [],
      showConnectionList: true,

    }
  },
  computed: {
    getIntegration() {
      return this.data.integration
    },
    disableOk() {
      return (!this.showConnectionList && !canEditIntegration().can) || (!Object.values(this.prolexis.attrs).every(value => value) && !this.showConnectionList)
    },
    triggerPropperMethod() {
      if (this.showConnectionList || !this.prolexis) return this.handleCancel
      return this.handleOk
    },
    showMoreDetails() {
      return this.data.showMoreDetails
    },
    prolexisConnections() {
      return this.$store.getters['general/getConnections'](this.getIntegration.alias) || {}
    },
    showPropperTitle() {
      if (!this.showConnectionList && !this.prolexis.id) {
        return this.$t('general.connect')
      }

      if (!this.showConnectionList) {
        return this.$t('general.save')
      }

      return this.$t('general.cancel')
    },
  },
  mounted() {
    if (this.prolexisConnections.length) {
      this.activations = this.prolexisConnections
    }
    if (this.getIntegration) {
      this.prolexis.integration = this.getIntegration.alias
    }
  },
  methods: {
    handleCancel() {
      this.closePopup(this.prolexis)
    },
    async editActivation(activation) {
      try {
        this.showConnectionList = !this.showConnectionList
        this.prolexis = cloneDeep(activation)
      } catch (error) {
        console.log(error)
      }
    },
    async handleOk() {
      try {
        if (!this.prolexis.id) {
          this.prolexis.integration = this.getIntegration.alias
          this.$store.dispatch('general/addConnections', this.prolexis)
        } else {
          this.$store.dispatch('general/updateConnections', this.prolexis)
        }
      } catch (err) {
        console.log(err)
      }
    },
    async disconnectActivation(activation) {
      const result = await this.showConfirmDeletePopup({
        items: [activation.attrs.apiKey],
        type: ENTITY_TYPES.CONNECTION,
      })
      try {
        if (result) {
          this.$store.dispatch('general/removeConnections', activation)
          this.activations = this.activations.filter(getActivation => getActivation.id !== activation.id)
        }
      } catch (err) {
        console.log(err)
      }
    },
    goBack() {
      this.showConnectionList = true
      this.prolexis = prolexisDefaults()
    },
  },
}
</script>

<style lang="scss" scoped>
.vp-integration__sidebar-info{
  display: flex;
  width: 100%;
  align-items: flex-start;
  gap: 20px;
  margin: 3% auto;

  img{
    width: 36px;
    height: 36px;
  }

  .integration-logo{
    background: linear-gradient(
      180deg,
      #d9d9d9 0%,
      #f3f2f4 0.01%,
      #ebddfd 100%
    );
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
  }

  .vp-integration__sidebar-header{
    p {
      font-weight: 500;
      font-size: 18px;
      color: #052D61;
      margin-bottom: 5px;
    }

    small{
      font-size: 14px;
      color: #667C99;
    }
  }
}

.vp-integration__sidebar-content{
  width: 100%;
  margin: 4% auto;

  .vp-integration__sidebar-connection{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 3% auto;
    background: #F4F6FA;
    padding: 4px 10px;
    border-radius: 4px;

    p{
      margin: 0;
      font-weight: 600;
      font-size: 14px;
      color: #052D61;
      cursor: pointer;
    }

    .connection__more-actions{
      display: flex;
      gap: 10px;
      .edit-option, .disconnect-option{
        display: flex;
        gap: 2px;
        font-weight: 500;
        color:#A3B0C2;
        font-size: 14px;
        margin: 0;

        &:hover{
          cursor: pointer;
          color: #475263;
        }
      }
    }
  }

  .new-connection{
    color:#1D79F2;
    font-size: 14px;
    margin-top: 1%;
    cursor: pointer;
    font-weight: 500;
  }

}
  .new-connection__back-arrow{
    display: flex;
    align-items: center;
    gap: 5px;
    margin-bottom: 2%;

    p{
      font-weight: 600;
      font-size: 18px;
      color: #A3B0C2;
      margin: 0;
    }

    svg{
      margin: 0;
    }

    .connection-name{
      color: #052D61;
    }
  }
</style>
