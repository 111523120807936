export default {
  ADD_USER(state, user) {
    state.users.push(user)
  },
  SET_USERS(state, users) {
    state.users = users
  },
  UPDATE_USER(state, user) {
    const userIndex = state.users.findIndex(p => p.id === user.id)
    if (userIndex === -1) return
    Object.assign(state.users[userIndex], user)
  },
  REMOVE_USER(state, userId) {
    const userIndex = state.users.findIndex(p => p.id === userId)
    state.users.splice(userIndex, 1)
  },
  REMOVE_USERS(state, userIds) {
    userIds.forEach(userId => {
      const userIndex = state.users.findIndex(p => p.id === userId)
      state.users.splice(userIndex, 1)
    })
  },
  SET_USERS_PAGINATION(state, pagination) {
    state.usersPagination = pagination
  },
  CLEAR_USERS_STATE(state) {
    state.users = []
  },
}
