import ability from '@/libs/acl/ability'
import { pr } from './config'

/**
apiKey_view
apiKey_manage
 */
// * Create entity file, and can functions
// * Import and add them to allCanFunctions object down below
// * Add defaults into the permissions array down below
// * Implement v-permission directives on neccessary buttons
// Implement permissions on entities sdk functions
// * Implement permissions on navigation and route - Everyone has access to view media

export const ApiKeys = Object.freeze({
  VIEW: 'apiKey_view',
  MANAGE: 'apiKey_manage',
})

export const ApiKeysToolTips = {
  [ApiKeys.VIEW]: 'Allows users to view API Keys list and details in ‘view only’ mode',
  [ApiKeys.MANAGE]: 'Allows users to perform all CRUD operations in API Keys',
}

/**
 * Can VIEW API Keys
 * */

export function canViewApiKeys(_options = {}) {
  const { entity, ...options } = _options

  return pr(
    ability.can(ApiKeys.VIEW) || ability.can(ApiKeys.MANAGE),

    'You have no permission to view API Keys',

    options,
  )
}

/**
 * Can VIEW API Key details
 * */

export function canViewApiKeyDetails(_options = {}) {
  const { entity, ...options } = _options

  return pr(
    ability.can(ApiKeys.VIEW),

    'You have no permission to view API Key Details',

    options,
  )
}

/**
 * Can EDIT API Key permissions
 * */

export function canEditApiKeyPermissions(_options = {}) {
  const { entity, ...options } = _options

  return pr(
    ability.can(ApiKeys.MANAGE),

    "You don't have permission to edit permissions of API keys",

    options,
  )
}

/**
 * Can CREATE API Key
 * */

export function canCreateApiKey(_options = {}) {
  const { entity, ...options } = _options

  return pr(
    ability.can(ApiKeys.MANAGE),

    "You don't have permission to create API Key",

    options,
  )
}

/**
 * Can DELETE API Key
 * */

export function canDeleteApiKey(_options = {}) {
  const { entity, ...options } = _options

  return pr(
    ability.can(ApiKeys.MANAGE),

    "You don't have permission to delete API Key",

    options,
  )
}

/**
 * Can REVOKE API Key
 * */

export function canRevokeApiKey(_options = {}) {
  const { entity, ...options } = _options

  return pr(
    ability.can(ApiKeys.MANAGE),

    "You don't have permission to revoke API Key",

    options,
  )
}

/**
 * Can REGENERATE API Key
 * */

export function canRegenerateApiKey(_options = {}) {
  const { entity, ...options } = _options

  return pr(
    ability.can(ApiKeys.MANAGE),

    "You don't have permission to regenerate API Key",

    options,
  )
}
