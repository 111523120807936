import { getUserData } from '@/auth/utils'
import ability from '@/libs/acl/ability'
import { pr } from './config'

/**
model_manage
model_view
model_create
model_edit
model_delete
model_publish
 */
// For regEx search in VSCode: canViewModel|canCreateModel|canEditModel|canDeleteModel

// * Create entity file, and can functions
// * Import and add them to allCanFunctions object down below
// * Add defaults into the permissions array down below
// * Implement v-permission directives on neccessary buttons
// * Implement permissions on entities sdk functions
// * Implement permissions on navigation and route

export const Model = Object.freeze({
  VIEW: 'model_view',
  CREATE: 'model_create',
  EDIT: 'model_edit',
  DELETE: 'model_delete',
  PUBLISH: 'model_publish',
  MANAGE_VERSIONS: 'model_version_manage',
})

export const ModelTooltips = {
  [Model.VIEW]: '',
  [Model.CREATE]: '',
  [Model.EDIT]: '',
  [Model.DELETE]: '',
  [Model.PUBLISH]: '',
}

function isCurrentUser(entity) {
  const userId = entity?.createdBy?.id || entity?.createdBy

  const currentUserData = getUserData()
  if (!currentUserData) return false

  return userId == currentUserData.id
}

/**
 * _options
 *    entity = undefined
 *    showToast = false
 */

/**
 * Can VIEW Models
 * */
export function canViewModels(_options = {}) {
  const { entity, ...options } = _options

  return pr(
    ability.can(Model.VIEW),

    'You have no permission to view models',

    options,
  )
}

/**
 * Can CREATE Model
 * */
export function canCreateModel(_options = {}) {
  const { entity, ...options } = _options

  return pr(
    ability.can(Model.CREATE),

    'You have no permission to create model',

    options,
  )
}

/**
 * Can EDIT Model
 * */
export function canEditModel(_options = {}) {
  const { entity, ...options } = _options

  return pr(
    ability.can(Model.EDIT)
    || (ability.can(Model.CREATE) && isCurrentUser(entity)),

    'You have no permission to edit model',

    options,
  )
}

/**
 * Can DELETE Model
 * */
export function canDeleteModel(_options = {}) {
  const { entity, ...options } = _options

  return pr(
    ability.can(Model.DELETE)
    || (ability.can(Model.CREATE) && isCurrentUser(entity)),

    'You have no permission to delete this model',

    options,
  )
}

/**
 * Can DELETE Model
 * */
export function canPublishModel(_options = {}) {
  const { entity, ...options } = _options

  return pr(
    ability.can(Model.PUBLISH),

    'You have no permission to publish this model',

    options,
  )
}

/**
 * Can DELETE Model
 * */
export function canUnpublishModel(_options = {}) {
  const { entity, ...options } = _options

  return pr(
    ability.can(Model.PUBLISH),

    'You have no permission to unpublish this model',

    options,
  )
}
/**
 * Can Mange Model Versions
 * */
export function canManageModelVersions(_options = {}) {
  const { entity, ...options } = _options

  return pr(
    ability.can(Model.MANAGE_VERSIONS),

    'You have no permission to manage versions of models',

    options,
  )
}
