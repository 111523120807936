import { VBTooltip } from 'bootstrap-vue'
import Vue from 'vue'
import { checkPermission } from '@/codex-permissions/config'
import { isEqual } from 'lodash'

/**
 * Wrap element if needed and return the element we want to disable and the one to initialize tooltip on
 *
 * @param {DOMElement} toWrap Element we want to wrap
 * @returns Object with element we want to disable and the ones we want to initialize tooltip on
 */
function getWrapperAndElement(toWrap) {
  /**
   * If its dropdown item we dont need to wrap, just return the correct elements
   * */
  if (toWrap.tagName == 'LI') {
    if (toWrap.classList.contains('perm-wrapper')) {
      return { wrapped: false, wrapperEl: toWrap, disableEl: toWrap.children[0] }
    }

    toWrap.classList.add('perm-wrapper')
    return { wrapped: true, wrapperEl: toWrap, disableEl: toWrap.children[0] }
  }

  /**
   * Else if its a button/link wrap it on a span
   * */
  const wrapper = document.createElement('div')

  /**
   * For button groups
   */
  if (toWrap?.classList?.contains('btn-group')) {
    wrapper.classList.add('btn-block')

    if (toWrap.children[0].classList.contains('perm-wrapper')) {
      toWrap = toWrap.children[0].children[0]
    } else {
      toWrap = toWrap.children[0]
    }
  }

  // Prevent wrapping the element twice
  if (toWrap.parentNode && toWrap.parentNode.classList.contains('perm-wrapper')) {
    return { wrapped: false, wrapperEl: toWrap.parentNode, disableEl: toWrap }
  }

  wrapper.classList.add('perm-wrapper')
  // wrap the element
  // toWrap.insertBefore(wrapper, toWrap)
  // wrapper.appendChild(toWrap)

  toWrap.after(wrapper)
  wrapper.appendChild(toWrap)
  return { wrapped: true, wrapperEl: wrapper, disableEl: toWrap }
}

/**
 * Return if user has permission for given function/entity
 *
 * @param {Array} props Array containing function name as first item, and entity as second ( if applicable )
 * @returns true/false
 */
async function hasPermission(props) {
  if (props && props.constructor == Array && props.length > 0) {
    return checkPermission(props[0], { entity: props[1] })
  }
  return { can: true }
}

/**
 * Show tooltip on element
 */
function showTooltip(el, bindings, vnode, permission, elements) {
  if (!elements.wrapperEl.classList.contains('v-permission--show-tooltip') || !isEqual(bindings.value, bindings.oldValue)) {
    const tooltipBindings = JSON.parse(JSON.stringify(bindings))
    tooltipBindings.value = { title: permission.message, customClass: 'permission-tooltip' }

    VBTooltip.bind(elements.wrapperEl, tooltipBindings, vnode)
    // VBTooltip.componentUpdated(elements.wrapperEl, bindings, vnode)

    elements.wrapperEl.classList.add('v-permission--show-tooltip')
    elements.disableEl.classList.add('disabled')
    elements.disableEl.classList.add('perm-disabled')
  }
}

/**
 * Hide tooltip on element
 */
function hideTooltip(el, bindings, vnode, elements) {
  if (elements.wrapperEl.classList.contains('v-permission--show-tooltip')) {
    VBTooltip.unbind(elements.wrapperEl, bindings, vnode)
    elements.wrapperEl.classList.remove('v-permission--show-tooltip')
    elements.disableEl.classList.remove('disabled')
    elements.disableEl.classList.remove('perm-disabled')
  }
}

/**
 * Register a global custom directive called `v-permission`
 *
 * ToDo: Cleaner way to initialize / destroy tooltip
 * */
const register = true

if (register) {
  Vue.directive('permission', {
    async inserted(el, bindings, vnode) {
      const elements = getWrapperAndElement(el)

      const permission = await hasPermission(bindings.value)

      if (!permission.can) {
        // Enable
        showTooltip(el, bindings, vnode, permission, elements)
      }
    },
    async componentUpdated(el, bindings, vnode) {
      const elements = getWrapperAndElement(el)

      const permission = await hasPermission(bindings.value)

      if (!permission.can) {
        // Enable
        showTooltip(el, bindings, vnode, permission, elements)
      } else {
        // Disable
        hideTooltip(el, bindings, vnode, elements)
      }
    },
    unbind(el, bindings, vnode) {
      const elements = getWrapperAndElement(el)

      hideTooltip(el, bindings, vnode, elements)
    },
  })
}
