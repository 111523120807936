import moment from 'moment'
import { getCurrentOrganizationId } from '@/services/siteService'
import { enumMapping } from '@/utils/helpers'
import { isNil } from 'lodash'
import { apiAxios as axios } from '../libs/axios'
import { tc, checkPermission } from './config'

/**
 * Constants
 */

export const LIST_TYPES = Object.freeze({
  LEGACY: 'LEGACY',
  ENTRY: 'ENTRY',
  AUTHOR: 'AUTHOR',
})

export const LIST_TYPE_MAPPING = enumMapping({
  [LIST_TYPES.LEGACY]: 0,
  [LIST_TYPES.ENTRY]: 1,
  [LIST_TYPES.AUTHOR]: 2,
})

export const LIST_TYPE_INDEXES = Object.freeze([
  LIST_TYPES.ENTRY.toLowerCase(),
  LIST_TYPES.AUTHOR.toLowerCase(),
])

/**
 * Helpers
 */

export function staticListDefaults(list = {}) {
  return {
    id: null,
    name: '',
    alias: '',
    description: '',
    type: LIST_TYPE_MAPPING.toNumber(LIST_TYPES.ENTRY),
    externalId: '',
    entries: [],
    authors: [],
    createdAt: moment().toISOString(),
    createdBy: '',
    updatedAt: '',
    updatedBy: '',
    siteId: '',
    configurations: {
      models: {
        allowAll: true,
        allowed: [],
      },
    },
    ...list,
  }
}

export function dynamicListDefaults(list = {}) {
  return {
    id: null,
    name: '',
    alias: '',
    description: '',
    externalId: '',
    adminFilter: { },
    adminOrder: [],
    filter: '{}',
    order: {},
    columns: ['id', 'published-at', 'filter'],
    createdAt: moment().toISOString(),
    createdBy: '',
    updatedAt: '',
    updatedBy: '',
    siteId: '',
    ...list,
  }
}

/**
 * Transforms to received data
 */

export function transformStaticListFromRequest(list) {
  const data = staticListDefaults(list)
  data.type = isNil(data.type) ? LIST_TYPES.LEGACY : LIST_TYPE_MAPPING.toString(data.type)
  return data
}

export function transformStaticListToRequest(list) {
  list.type = isNil(list.type) ? LIST_TYPE_MAPPING.toNumber(LIST_TYPES.LEGACY) : LIST_TYPE_MAPPING.toNumber(list.type)
  return list
}

export function transformDynamicList(list) {
  return dynamicListDefaults(list)
}

// STATIC LIST

/**
 * CRUD Operations
 * */

/**
 *
 * @param {*} id
 *
 * @response
 * {
 *  id: GUID
 *  blocks: []
 *  siteId: null
 *  slug: String
 *  status: 1 | 2
 *  title: String
 * }
 */
export function readStaticList(id) {
  const org = getCurrentOrganizationId()
  return axios.get(`/{organization}/lists/${id}`, { params: { organization: org } }).then(response => {
    response.data = transformStaticListFromRequest(response.data)
    return response
  })
}

/**
 *
 * @param {*} list
 *
 * @response
 * {
 *  id: GUID
 *  slug: String
 *  errors: null | Object
 *  success: true | false
 * }
 */
export function createStaticList(list, messages) {
  return checkPermission('canCreateList')
      && tc(axios.post('/{organization}/lists', transformStaticListToRequest(list))
        .then(response => {
          response.data = transformStaticListFromRequest(response.data)
          return response
        }), {
        successTitle: 'sdk.success-title',
        successMessage: 'sdk.lists.create-successful',
        failTitle: 'sdk.lists.create-failed',
        catchError: false,
        ...messages,
      })
}

/**
 *
 * @param {*} list
 *
 * @response
 * {
 *  id: GUID
 *  errors: null | Object
 *  success: true | false
 * }
 */
export function updateStaticList(list, messages = {}) {
  return checkPermission('canEditList', { entity: list })
      && tc(axios.put(`/{organization}/lists/${list.id}`, transformStaticListToRequest(list))
        .then(response => {
          response.data = transformStaticListFromRequest(response.data)
          return response
        }), {
        successTitle: 'sdk.success-title',
        successMessage: 'sdk.lists.update-successful',
        failTitle: 'sdk.lists.update-failed',
        catchError: false,
        ...messages,
      })
}

/**
 *
 * @param {*} list
 *
 * @response
 * {
 *  errors: null | Object
 *  success: true | false
 * }
 */
export function deleteStaticList(list, messages = {}) {
  return checkPermission('canDeleteList', { entity: list })
      && tc(axios.delete(`/{organization}/lists/${list.id}`), {
        successTitle: 'sdk.success-title',
        successMessage: 'sdk.lists.delete-successful',
        failTitle: 'sdk.lists.delete-failed',
        ...messages,
      })
}

// DYNAMIC LIST

/**
 * CRUD Operations
 * */

/**
 *
 * @param {*} id
 *
 * @response
 * {
 *  id: GUID
 *  blocks: []
 *  siteId: null
 *  slug: String
 *  status: 1 | 2
 *  title: String
 * }
 */
export function readDynamicList(id) {
  const org = getCurrentOrganizationId()
  return axios.get(`/{organization}/dynamiclists/${id}`, { params: { organization: org } }).then(response => {
    response.data = transformDynamicList(response.data)
    return response
  })
}

/**
 *
 * @param {*} list
 *
 * @response
 * {
 *  id: GUID
 *  slug: String
 *  errors: null | Object
 *  success: true | false
 * }
 */
export function createDynamicList(list, messages) {
  return checkPermission('canCreateList')
      && tc(axios.post('/{organization}/dynamiclists', list)
        .then(response => {
          response.data = transformDynamicList(response.data)
          return response
        }), {
        successTitle: 'sdk.success-title',
        successMessage: 'sdk.lists.create-successful',
        failTitle: 'sdk.lists.create-failed',
        catchError: false,
        ...messages,
      })
}

/**
 *
 * @param {*} list
 *
 * @response
 * {
 *  id: GUID
 *  errors: null | Object
 *  success: true | false
 * }
 */
export function updateDynamicList(list, messages = {}) {
  return checkPermission('canEditList', { entity: list })
      && tc(axios.put(`/{organization}/dynamiclists/${list.id}`, list)
        .then(response => {
          response.data = transformDynamicList(response.data)
          return response
        }), {
        successTitle: 'sdk.success-title',
        successMessage: 'sdk.lists.update-successful',
        failTitle: 'sdk.lists.update-failed',
        catchError: false,
        ...messages,
      })
}

/**
 *
 * @param {*} list
 *
 * @response
 * {
 *  errors: null | Object
 *  success: true | false
 * }
 */
export function deleteDynamicList(list, messages = {}) {
  return checkPermission('canDeleteList', { entity: list })
      && tc(axios.delete(`/{organization}/dynamiclists/${list.id}`), {
        successTitle: 'sdk.success-title',
        successMessage: 'sdk.lists.delete-successful',
        failTitle: 'sdk.lists.delete-failed',
        ...messages,
      })
}
