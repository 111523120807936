export default {
  ADD_ORGANIZATION(state, organization) {
    state.organizations.push(organization)
  },
  SET_ORGANIZATIONS(state, organizations) {
    state.organizations = organizations
  },
  UPDATE_ORGANIZATION(state, organization) {
    const organizationIndex = state.organizations.findIndex(p => p.id === organization.id)
    if (organizationIndex === -1) return
    Object.assign(state.organizations[organizationIndex], organization)
  },
  REMOVE_ORGANIZATION(state, organizationId) {
    const organizationIndex = state.organizations.findIndex(p => p.id === organizationId)
    state.organizations.splice(organizationIndex, 1)
  },
  REMOVE_ORGANIZATIONS(state, organizationIds) {
    organizationIds.map(organizationId => {
      const organizationIndex = state.organizations.findIndex(p => p.id === organizationId)
      state.organizations.splice(organizationIndex, 1)
      return organizationId
    })
  },
}
