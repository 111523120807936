import qs from 'qs'
import { apiAxios as axios } from '../libs/axios'
import { checkPermission, tc } from './config'

/**
 * Constants
 */
export const SECTIONS = Object.freeze({
  ALL: 'all',
  NATIVE_SECTION: 'native-section',
})

/**
 * Helpers
 */
export function tabDefaults() {
  return [
    {
      name: 'content.sections.sdk.overview',
      route: 'sections-edit',
    },
    {
      name: 'content.sections.sdk.seo',
      route: 'sections-seo',
    },
    // {
    //   name: 'content.sections.sdk.scripts',
    //   route: 'sections-scripts',
    // },
  ]
}

export function sectionDefaults(section = {}) {
  return {
    externalId: '',
    parentId: null,
    title: '',
    media: [],
    description: '',
    references: [],
    url: '',
    ...section,
  }
}
export function scriptDefaults() {
  return {
    name: '',
    description: '',
    code: '',
    inheritToChildren: true,
  }
}
export function flattenSections(sections) {
  const result = {}
  sections.forEach(sec => {
    result[sec.id] = sec
    if (sec.children && sec.children.items) {
      Object.assign(result, flattenSections(sec.children.items))
    }
  })
  return result
}

/**
 * Transforms to received data
 */
export function transformSectionFromRequest(section) {
  return section
}

/**
 * CRUD Operations
 * */

/**
 *
 * @param {*} section
 *
 * @response
 * {
 *  id: GUID
 *  errors: null | Object
 *  success: true | false
 * }
 */
export function createSection(section, messages) {
  return checkPermission('canCreateSection')

  && tc(axios.post('/{organization}/sections', section), {
    successTitle: 'sdk.success-title',
    successMessage: 'sdk.section.create-successful',
    failTitle: 'sdk.section.create-failed',
    ...messages,
  })
}

/**
 *
 * @response
 * [{
 *  id: GUID
 *  parentId: GUID | null
 *  title: String
 *  slug: String
 *  canContainArticle: Bool
 * }, {...}, ...]
 */
export function readSections({
  page = null, pageSize = null, searchTerm,
} = {}) {
  return axios.get('/{organization}/sections', {
    params: {
      Page: page,
      Size: pageSize,
      Q: searchTerm,
    },
  }).then(response => {
    response.data.data = response.data.data.map(section => transformSectionFromRequest(section))
    return response
  })
}

export function readSectionsHierarchy({
  page = null, pageSize = null, searchTerm, canContainArticle = null,
} = {}) {
  return axios.get('/{organization}/sections/hierarchy', {
    params: {
      Page: page,
      Size: pageSize,
      Q: searchTerm,
      CanContainArticle: canContainArticle,
    },
  }).then(response => {
    response.data.data = response.data.data.map(section => transformSectionFromRequest(section))
    return response
  })
}

/**
 *
 * @response
 * [{
 *  id: GUID
 *  parentId: GUID | null
 *  title: String
 *  slug: String
 *  canContainArticle: Bool
 * }, {...}, ...]
 */
export function searchSections({
  query, excludeIds = [], page = 1, size = 20,
} = {}) {
  return axios.get('/{organization}/sections/search', {
    params: {
      Q: query,
      ExcludeIds: excludeIds,
      Page: page,
      Size: size,
    },
    paramsSerializer(params) {
      return qs.stringify(params, { indices: false })
    },
  }).then(response => response)
}

/**
 *
 * @param {*} id
 *
 * @response
 * {
 *  id: GUID
 *  parentId: GUID | null
 *  title: String
 *  slug: String
 *  canContainArticle: Bool
 * }
 */
export function readSection(id) {
  return axios.get(`/{organization}/sections/${id}`).then(response => {
    response.data = transformSectionFromRequest(response.data)
    return response
  })
}

/**
 *
 * @param {*} id
 *
 * @response
 * [{
 * }, ...]
 */
export function fetchSectionArticlesAndChildren(id) {
  return axios.get(`/{organization}/sections/${id}/used-in`).then(response => {
    response.data = transformSectionFromRequest(response.data)
    return response
  })
}

/**
 *
 * @response
 * [{
 *  id: GUID
 *  parentId: GUID | null
 *  title: String
 *  slug: String
 *  children: Array
 * }, {...}, ...]
 */

/**
 *
 * @response
 * [{
 *  id: GUID
 *  parentId: GUID | null
 *  title: String
 *  slug: String
 *  children: Array
 * }, {...}, ...]
 */
export function readSectionsByIds(ids) {
  return axios.get('/{organization}/sections/collection/byids', {
    params: {
      ids,
    },
    paramsSerializer(params) {
      return qs.stringify(params, { indices: false })
    },
  })
}

/**
 *
 * @param {*} section
 *
 * @response
 * {
 *  id: GUID
 *  errors: null | Object
 *  success: true | false
 * }
 */
export function updateSection(section, messages) {
  return checkPermission('canEditSection', { entity: section })

  && tc(axios.put(`/{organization}/sections/${section.id}`, section), {
    successTitle: 'sdk.success-title',
    successMessage: 'sdk.section.update-successful',
    failTitle: 'sdk.section.update-failed',
    ...messages,
  })
}

/**
 *
 * @param {*} sectionId
 *
 * @response
 * {
 *  errors: null | Object
 *  success: true | false
 * }
 */
export function deleteSection(section, messages) {
  return checkPermission('canDeleteSection', { entity: section })

  && tc(axios.delete(`/{organization}/sections/${section.id}`), {
    successTitle: 'sdk.success-title',
    successMessage: 'sdk.section.delete-successful',
    failTitle: 'sdk.section.delete-failed',
    ...messages,
  })
}

/**
 *
 * @param {*} sectionId
 *
 * @response
 * [{
 *  errors: null | Object
 *  success: true | false
 * },...]
 */
export function deleteSections(sections, messages) {
  return sections.every(section => checkPermission('canDeleteSection', { entity: section }))

  && tc(Promise.all(sections.map(section => axios.delete(`/{organization}/sections/${section.id}`))), {
    successTitle: 'sdk.success-title',
    successMessage: 'sdk.section.delete-sections-successful',
    failTitle: 'sdk.section.delete-sections-failed',
    ...messages,
  }, true)
}
