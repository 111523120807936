import i18n from '@/libs/i18n'
import SectionFilterComponent from './Section.vue'

export default class SectionFilter {
  static COMPONENT = SectionFilterComponent;

  static OPERATORS = {
    include: 'include',
    exclude: 'exclude',
    exists: 'exists',
    notExists: 'notExists',
  }

  static type = 'section'

  static graphqlType = 'SectionFieldFilter'

  operator = null;

  value = null;

  includeChildren = false;

  constructor({ operator = SectionFilter.OPERATORS.include, value, includeChildren = false } = {}) {
    this.operator = operator
    this.value = value
    this.includeChildren = includeChildren
  }

  set({ operator = SectionFilter.OPERATORS.include, value, includeChildren = false }) {
    this.operator = operator
    this.value = value
    this.includeChildren = includeChildren
  }

  getCount() {
    if (this.operator === SectionFilter.OPERATORS.exists || this.operator === SectionFilter.OPERATORS.notExists) {
      return 1
    }
    return this.value?.length
  }

  getValueLabel({ cache }) {
    if (this.operator === SectionFilter.OPERATORS.exists || this.operator === SectionFilter.OPERATORS.notExists) {
      return this.operator === SectionFilter.OPERATORS.exists ? i18n.t('filters.is-not-empty') : i18n.t('filters.is-empty')
    }
    const section = this.value.map(sectionId => cache[sectionId] || { id: sectionId })
    return section.map(m => m.title).join(', ')
  }

  toLoad() {
    return {
      sections: this.value,
      includeChildren: this.includeChildren,
    }
  }

  asQueryParam() {
    if (this.operator === SectionFilter.OPERATORS.exists || this.operator === SectionFilter.OPERATORS.notExists) {
      return {
        operator: this.operator,
      }
    }
    if (this.operator === SectionFilter.OPERATORS.include) {
      return {
        operator: this.operator,
        value: this.value,
        includeChildren: this.includeChildren,
      }
    }
    return {
      operator: this.operator,
      value: this.value,
    }
  }

  asGraphQL() {
    switch (this.operator) {
      case SectionFilter.OPERATORS.include:
        return {
          in: this.value,
          includeChildren: this.includeChildren != false,
        }
      case SectionFilter.OPERATORS.exclude:
        return {
          notIn: this.value,
        }
      case SectionFilter.OPERATORS.exists:
        return {
          exists: true,
        }
      case SectionFilter.OPERATORS.notExists:
        return {
          exists: false,
        }
      default:
        return null
    }
  }

  asGraphQLDynamicList() {
    if (this.asGraphQL()) {
      return {
        ...this.asGraphQL(),
        _t: SectionFilter.graphqlType,
      }
    }
    return null
  }

  static validate({ operator, value }) {
    if (!operator) {
      return 'Operator is required'
    }

    if (operator === SectionFilter.OPERATORS.exists || operator === SectionFilter.OPERATORS.notExists) {
      return true
    }

    if (!value || value.length === 0) {
      return 'Value is required'
    }

    return true
  }

  static fromQueryParam({ operator, value, includeChildren }) {
    return new SectionFilter({
      operator,
      value,
      includeChildren,
    })
  }

  static shouldApplyPredefinedValue(filter) {
    const forOperators = [SectionFilter.OPERATORS.include]
    return forOperators.includes(filter.operator)
  }
}
