<template>
  <div class="organization-tree">
    <div
      v-for="option in menuOptions"
      :key="option.id"
      class="organization-tree__main"
      data-folder=""
    >
      <div class="organization-tree__header">
        <div
          v-if="isPopup"
          class="organization-tree__header--mobile mobile-only"
        >
          <GjIcon
            name="ArrowLeft"
            size="38px"
            @click.native="$emit('close-sidebar', false)"
          />
          <span>
            {{ $t('assets.folders.all-assets') }}
          </span>
        </div>
        <div
          class="organization-tree__name"
          @click="rootLevel"
        >
          <span id="organization-tree__collapse-icon">
            <GjIcon
              v-b-toggle="`toggle${option.id}`"
              class="float-left"
              name="ArrowRight"
              size="16"
            />
          </span>
          <span
            @contextmenu.prevent="$refs.menu.$refs.menu.open"
            @click.right="handleTargetFolder"
          >
            {{ option.name }}
          </span>
          <div class="organization-tree__header-actions">
            <b-dropdown
              class="folders-sort b-dropdown--compact"
              no-caret
              size="md"
              variant="none"
            >
              <template #button-content>
                <GjIcon
                  :key="order.order"
                  :style="order.orderBy? 'color: #206ED5;' : ''"
                  :name="order.order === 'asc' ? 'OrderAsc_fill' : 'OrderDesc_fill'"
                />
              </template>
              <b-dropdown-form>
                <vSelect
                  v-model="order.order"
                  class="v-select--gray"
                  :options="[
                    { value: 'asc', label: $t('assets.folders.sorts.asc') },
                    { value: 'desc', label: $t('assets.folders.sorts.desc') },
                  ]"
                  :disabled="!order.orderBy"
                  :clearable="false"
                  :reduce="option => option.value"
                  label="label"
                />
              </b-dropdown-form>
              <b-dropdown-item
                :active="order.orderBy === 'name'"
                @click="order.orderBy = 'name'"
              >
                {{ $t('assets.folders.sort-by.name') }}
              </b-dropdown-item>
              <b-dropdown-item
                :active="order.orderBy === 'createdAt'"
                @click="order.orderBy = 'createdAt'"
              >
                {{ $t('assets.folders.sort-by.createdAt') }}
              </b-dropdown-item>
            </b-dropdown>
            <b-button
              v-permission="['canCreateFolder', targetFolder]"
              class="organization-tree__header-add"
              variant="none"
              @click.stop="createFolder"
            >
              <GjIcon name="FolderAdd" />
            </b-button>
          </div>
        </div>
      </div>
      <div class="organization-tree__main-content">
        <div class="organization-tree__inner-content">
          <b-collapse
            :id="`toggle${option.id}`"
            visible
          >
            <FoldersTree
              :ref="`foldersTree${option.id}`"
              :is-popup="isPopup"
              :order="order"
              @closeSidebar="$emit('close-sidebar')"
            />
          </b-collapse>
        </div>
      </div>
    </div>
    <div v-show="showMenu">
      <ContextMenu ref="menu" />
    </div>
  </div>
</template>
<script>
import { mapMutations, mapState } from 'vuex'
import { GenerateUUID } from '@/utils/helpers'
import gql from 'graphql-tag'
import FoldersTree from './FoldersTree.vue'
import ContextMenu from './ContextMenu.vue'

export default {
  name: 'OrganizationTree',
  inject: ['showFolderPopup'],
  components: {
    ContextMenu,
    FoldersTree,
  },
  props: {
    organization: {
      type: String,
      default: null,
    },
    isPopup: {
      type: Boolean,
      default: false,
    },
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
  apollo: {
    folder: {
      query: gql`
        query ($id: String!) {
          folder (id: $id) {
            id
            name
            parentId
            createdAt
            children {
              total
            }
            parent {
              id
              parent {
                id
                parent {
                  id
                  parent {
                    id
                    parent {
                      id
                        parent {
                        id
                        parent {
                          id
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `,
      skip() {
        return !this.folderId || this.skipOpenTree
      },
      variables() {
        return {
          id: this.folderId,
        }
      },
      result({ data }) {
        // get Ids recursively
        this.SET_TARGET_FOLDER(data.folder)

        const getIds = folder => {
          this.OPEN_CLOSE_FOLDER(folder.id)
          if (folder.parent) {
            getIds(folder.parent)
          }
        }
        getIds(data.folder)
        this.skipOpenTree = true
      },
    },
  },
  data() {
    return {
      assetsFolderKey: this.isPopup ? 'assets-popup-folder' : 'assets-folder',
      skipOpenTree: false,
      menuOptions: [
        {
          id: GenerateUUID.GENERAL(),
          name: this.organization || this.$store.state.general.currentOrganization.name,
        },
      ],
      showMenu: false,
      order: {
        orderBy: 'name',
        order: 'asc',
      },
    }
  },
  computed: {
    ...mapState('assets', [
      'targetFolder',
      'filters',
    ]),
    folderId() {
      return this.isPopup ? this.getAssetsFolder() : this.$route.query.folder
    },
  },
  watch: {
    targetFolder: {
      handler(v) {
        if (v !== null) {
          this.$refs.menu.$refs.menu.close()
        }
      },
      deep: true,
    },
    'filters.folderId': function () {
      this.SET_DEFAULT_SELECTED_DATA()
      if (this.filters.folderId) {
        this.setAssetsFolder(this.filters.folderId)
      } else {
        this.removeAssetsFolder()
      }
    },
  },
  beforeMount() {
    this.CLOSE_FOLDERS()
    // Check localStorage for folder
    if (this.$route.query.folder) {
      this.filters.folderId = this.$route.query.folder
    } else {
      const folderId = this.getAssetsFolder()
      if (folderId) {
        this.filters.folderId = folderId
        if (!this.isPopup) {
          this.$router.replace({ query: { ...this.$route.query, folder: folderId } })
        }
      }
    }
  },
  methods: {
    ...mapMutations('assets', [
      'SET_TARGET_FOLDER',
      'OPEN_CLOSE_FOLDER',
      'CLOSE_FOLDERS',
      'SET_DEFAULT_SELECTED_DATA',
    ]),
    getAssetsFolderObject() {
      return (localStorage.getItem(this.assetsFolderKey) && JSON.parse(localStorage.getItem(this.assetsFolderKey))) || {}
    },
    getAssetsFolder() {
      const folders = this.getAssetsFolderObject()
      return folders[this.$store.state.general?.currentOrganization?.alias] || ''
    },
    setAssetsFolder(folderId) {
      const folders = this.getAssetsFolderObject()
      folders[this.$store.state.general.currentOrganization?.alias] = folderId
      localStorage.setItem(this.assetsFolderKey, JSON.stringify(folders))
    },
    removeAssetsFolder() {
      const folders = this.getAssetsFolderObject()
      delete folders[this.$store.state.general.currentOrganization?.alias]
      localStorage.setItem(this.assetsFolderKey, JSON.stringify(folders))
    },
    async createFolder() {
      const folder = await this.showFolderPopup({ parent: this.targetFolder })
      if (folder) {
        this.$root.$emit('refetch-folders')
      }
    },
    rootLevel() {
      const { folder, ...remaining } = this.$route.query
      this.SET_TARGET_FOLDER(null)
      if (!this.isPopup) {
        this.$router.replace({ name: 'assets', query: { ...remaining } }).catch(() => {})
      }
      this.filters.folderId = null
    },
    handleTargetFolder() {
      this.SET_TARGET_FOLDER(null)
      this.filters.folderId = null
      if (!this.isPopup) {
        const { folder, ...remaining } = this.$route.query
        this.$router.replace({ name: 'assets', query: { ...remaining } }).catch(() => {})
      }
      this.showMenu = !this.showMenu
    },
  },
}
</script>

<style lang="scss" scoped>
.organization-tree {
  position: relative;
  padding-left: 1rem;
  padding-right: 1rem;
}

.organization-tree__header {
  display: flex;
  justify-content: space-between;
}

.organization-tree__header-actions {
  border-left: 1px solid #C8D0DA;
  display: flex;
  align-items: center;
  margin-left: auto;
}

.organization-tree__header-sort{
  width: 55%;
}

.organization-tree__header-add {
  padding: 0;
}

.organization-tree__main-content {
  padding-left: 0.25rem;
  cursor: pointer;
}

.organization-tree__inner-content {
  margin-left: 1.6rem;
}

.organization-tree__name {
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 100%;

  > span {
    color: #1D79F2;
    font-size: 14px;
    line-height: 18px;
    white-space: break-spaces;
    text-align: left;
    margin: 0;
  }
}

.collapse-margin {
  margin-left: -1.6em;
}
</style>

<style lang="scss">
.folders-sort .dropdown-menu {
  padding: 8px 0;
}
.folders-sort button {
  padding: 0;
  margin: 0 10px;
}
</style>
