import { readEntryVersions } from '@/codex-sdk/entries'
import gql from 'graphql-tag'
import moment from 'moment'
import { orderBy } from 'lodash'

export default {
  apollo: {
    scheduledEntryVersions: {
      query: gql`query scheduledEntryVersions ($entryId: String) {
        scheduledEntryVersions: scheduledVersionCollection(where: {
          entryId: {
            eq: $entryId
          }
          status: {
            in: [SCHEDULED]
          }
        }) {
          total
          items {
            id
            versionId
            publishScheduledDate
            unpublishScheduledDate
            status
            versionId
            createdAt
          }
        }
      }`,
      variables() {
        return {
          entryId: this.entry?.id,
        }
      },
      skip() {
        return !this.entry?.id
      },
      result({ data }) {
        const filtered = data.scheduledEntryVersions?.items?.filter(r => moment(r.publishScheduledDate).isAfter(moment()) || moment(r.unpublishScheduledDate).isAfter(moment())).map(s => ({
          ...s,
          scheduleDate: s.publishScheduledDate || s.unpublishScheduledDate,
        })) || []
        this.scheduledVersions = orderBy(filtered, ['scheduleDate'], 'asc')
        return data
      },
      fetchPolicy: 'network-only',
    },
  },
  data() {
    return {
      versions: [],
      scheduledVersions: [],
    }
  },
  methods: {
    async fetchVersions() {
      if (!this.entry?.id) return
      try {
        const { data } = await readEntryVersions(this.entry.id, this.entry.system.modelAlias, this.entry.system.siteId)
        this.versions = data
      } catch (e) {
        console.log(e)
      }

      try {
        if (!this.versions?.length) return

        const { data: { userCollection } } = await this.$apollo.query({
          query: gql`
            query ($ids: [String!]){
              userCollection (where: {id: {in: $ids}}) {
                items {
                  id
                  imageUrl
                  email
                  firstName
                  lastName
                }
              }
            }
          `,
          variables: {
            ids: this.versions.map(v => v.createdBy),
          },
        })

        this.versions.forEach(version => {
          const user = userCollection.items.find(u => u.id === version.createdBy)
          if (user) {
            user.fullName = user ? `${user.firstName || ''} ${user.lastName || ''}`.trim() : ''
          }
          version.createdBy = user
        })
      } catch (e) {
        console.log(e)
      }
    },
    refetchScheduledVersions() {
      setTimeout(() => {
        this.$apollo.queries.scheduledEntryVersions.refetch()
      }, 1000)
    },
  },
}
