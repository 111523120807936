import { FIELD_GROUPS } from '@/codex-sdk/models'
import { registerField } from '@/components/codex-layout-editor/BuilderUtils'
import {
  FIELD_FILTER_OPERATORS,
  RANGE_OPERATORS,
  TYPES,
  VALUE_TYPES,
} from '@/views/models/constants'
import Ratios from './Ratios.vue'
import Render from './Render.vue'
import General from './General.vue'
import Appearance from './Appearance.vue'
import Validation from './Validation.vue'
import Initial from './Initial.vue'
import Configuration from './Configuration.vue'

export const APPEARANCE_TYPES = {
  GRID: 1,
}

registerField('codex_field', {
  displayName: 'Media Content',
  name: 'media_content',
  render: Render,
  renderEditor: Render,
  renderEditorPanel: [
    {
      icon: 'Settings', label: 'fields.codex-field-media-content.tabs.general', component: General, isGeneral: true,
    },
    { icon: 'Brush', label: 'fields.codex-field-media-content.tabs.appearance', component: Appearance },
    { icon: 'Tasks', label: 'fields.codex-field-media-content.tabs.validations', component: Validation },
    { icon: 'SettingsPreferencesTools', label: 'fields.codex-field-media-content.tabs.configuration', component: Configuration },
    { icon: 'IconparkExtend', label: 'fields.codex-field-media-content.tabs.ratios', component: Ratios },
  ],
  renderEditorInitialPanel: [
    { icon: 'Settings', label: 'fields.codex-field-media-content.tabs.initial', component: Initial },
  ],
  group: FIELD_GROUPS.BASIC,
  icon: 'field-media.png',

  type: TYPES.MEDIA,
  valueType: VALUE_TYPES.SINGLE,
  defaultValue: null,

  attrs: {
    defaultFolderId: {
      default: null,
    },
    version: {
      default: null,
    },
    validation: {
      default: {
        required: {
          value: false,
          errorMessage: 'Media field is required',
          conditionsEnabled: false,
          conditions: [
            {
              isSystem: false,
              field: '',
              operator: FIELD_FILTER_OPERATORS.EXISTS,
              value: '',
            },
          ],
        },
        readOnly: {
          value: false,
          conditionsEnabled: false,
          conditions: [
            {
              isSystem: false,
              field: '',
              operator: FIELD_FILTER_OPERATORS.EXISTS,
              value: '',
            },
          ],
        },
        requiredMetadata: {
          isEnabled: false,
          title: true,
          caption: true,
          alt: false,
          source: false,
          author: false,
          copyright: false,
          tags: false,
          errorMessage: 'Metadata is required',
        },
        numberOfAssets: {
          isEnabled: false,
          rangeOperator: RANGE_OPERATORS.BETWEEN,
          min: 2,
          max: 5,
          exactly: 0,
          errorMessage: 'Media should contains 2-5 files',
          errorTemplate: 'Media should contains {min}-{max}',
        },
        fileType: {
          isEnabled: true,
          image: true,
          files: false,
          video: false,
          playlists: false,
          audio: false,
          podcast: false,
          errorMessage: 'Unsupported type',
        },
        dimensions: {
          isEnabled: false,
          minWidth: 100,
          maxWidth: 1000,
          minHeight: 100,
          maxHeight: 1000,
          errorMessage: 'Media width should be 100-1000 (pixels) and height 100-1000 (pixels)',
          errorTemplate: 'Media width should be {minWidth}-{maxWidth} (pixels) and height {minHeight}-{maxHeight} (pixels)',
        },
        requiredLabels: {
          isEnabled: false,
          allowedLabels: [],
          errorMessage: 'Assets should have at least one of selected labels',
        },
      },
    },
    appearance: {
      default: APPEARANCE_TYPES.GRID,
    },
    showUploadNewAsset: {
      default: true,
    },
    helpText: {
      default: '',
    },
    helpTextDisplay: {
      default: 'tooltip',
    },
    calculateMetrics: {
      default: false,
    },
    configuration: {
      default: {
        searchable: true,
        filterable: true,
      },
    },
    ratios: {
      default: [],
    },
    hidden: {
      value: false,
      conditionsEnabled: false,
      conditions: [
        {
          isSystem: false,
          field: '',
          operator: FIELD_FILTER_OPERATORS.EXISTS,
          value: '',
        },
      ],
    },
  },
})
