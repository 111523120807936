import CodexContentEditor from '../../CodexContentEditor'
import ApplePodcast from './ApplePodcast.vue'
import ApplePodcastPanel from './ApplePodcastPanel.vue'
import { BLOCK_GROUPS } from '../constants'

CodexContentEditor.registerWidget('codex', {
  displayName: 'codex-editor.block-names.apple-podcast',
  name: 'apple_podcast',
  group: BLOCK_GROUPS.EMBEDS,
  // eslint-disable-next-line global-require
  image: require('../../icons/apple-podcast/icon.svg'),
  attrs: {
    blockId: {
      default: null,
    },
    src: {
      default: null,
    },
    align: {
      default: 'left',
    },
    width: {
      default: '50%',
    },
    caption: {
      default: null,
    },
  },
  renderEditor: ApplePodcast,
  renderEditorBar: ApplePodcastPanel,
})
