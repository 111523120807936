/* eslint-disable import/no-dynamic-require */
import Vue from 'vue'
import { GenerateUUID, someParentHasClass } from '@/utils/helpers'
import CodexLayoutEditor from '@/components/codex-layout-editor/CodexLayoutEditor'
import { isEqual } from 'lodash'
import BuilderBlockPanel from '@/components/codex-layout-editor/components/BuilderBlockPanel.vue'

export const Layouts = {
  12: {
    icon: 'Stop',
    value: [
      { cols: 12 },
    ],
  },
  '4-8': {
    icon: 'Devices',
    value: [
      { cols: 4 },
      { cols: 8 },
    ],
  },
  '8-4': {
    icon: 'Devices',
    value: [
      { cols: 8 },
      { cols: 4 },
    ],
  },
  '6-6': {
    icon: 'Pause',
    value: [
      { cols: 6 },
      { cols: 6 },
    ],
  },
  '4-4-4': {
    icon: 'Widget',
    value: [
      { cols: 4 },
      { cols: 4 },
      { cols: 4 },
    ],
  },
}

// [Delete]
export const HeaderLayouts = {
  'header-l': {
    icon: 'Stop',
    value: [
      { cols: 12, align: 'left' },
    ],
  },
  'header-lr': {
    icon: 'Pause',
    value: [
      { cols: 6, align: 'left' },
      { cols: 6, align: 'right' },
    ],
  },
  'header-lcr': {
    icon: 'Widget',
    value: [
      { cols: 4, align: 'left' },
      { cols: 4, align: 'center' },
      { cols: 4, align: 'right' },
    ],
  },
}

export function registerWidget(pluginName, widget) {
  CodexLayoutEditor.registerWidget(pluginName, widget)
}

export function registerField(pluginName, widget) {
  CodexLayoutEditor.registerField(pluginName, widget)
}

const registeredWidgets = []

export function getWidget(type) {
  const widget = registeredWidgets.filter(w => w.type === type)
  if (widget.length) return widget[0]
  return null
}

export function getWidgetAttrDefaults(type) {
  let widget = getWidget(type)
  if (!widget || !widget.widget || !widget.widget.attrs) return {}
  widget = widget.widget

  const attrs = {}

  Object.keys(widget.attrs).forEach(attrName => {
    attrs[attrName] = widget.attrs[attrName].default === undefined || widget.attrs[attrName].default === null ? '' : widget.attrs[attrName].default
  })

  return attrs
}

export function getRegisteredWidgets() {
  return CodexLayoutEditor.registeredWidgets
}

export function childSelected(widgets, selectedWidgetID) {
  // eslint-disable-next-line no-restricted-syntax
  for (const widget of widgets) {
    if (widget.id === selectedWidgetID) return true
    if (widget.content && widget.content.length) {
      const s = childSelected(widget.content, selectedWidgetID)
      if (s) return true
    }
  }
  return false
}

export function removeBlockToContent(widget, id) {
  if (widget.content) {
    widget.content = widget.content.filter(b => b.id !== id)
  }
}

/**
 * Called to add a block inside another block, at given position
 *
 * @param {Object} widget Where to add the block
 * @param {Object} block The block that needs to be added
 * @param {String/Number} id The id of the block, where the new block will be added before/after it
 * @param {String} position Should we insert it before or after the given block id
 * @returns null
 */

export function addBlockToContent(widget, block, id, position = 'after') {
  if (widget.content) {
    // If -1 add at the beginning
    if (id === -1) {
      widget.content = [block, ...widget.content]
      return
    }

    let insertAt = widget.content.findIndex(b => b.id === id)
    if (insertAt !== -1) {
      if (position === 'after') {
        insertAt += 1
      }

      widget.content = [
        ...widget.content.slice(0, insertAt),
        block,
        ...(-widget.content.length + insertAt !== 0 ? widget.content.slice(-widget.content.length + insertAt) : []),
      ]
    }
  }
}

/**
 * Creates an object for given type, with the default attributes of the type
 *
 * @param {String} type The type of the block to create
 * @param {Array} content The children blocks of this block
 * @param {Object} attrs Attributes of the block, overwrites default ones
 * @returns Object representing a block
 */

export function createBlock(type, content = [], attrs = {}) {
  const typeAttrs = getWidgetAttrDefaults(type)

  return {
    id: GenerateUUID.BLOCK(),
    type,
    content,
    attrs: {
      ...typeAttrs,
      ...attrs,
    },
  }
}

/**
 * Creates a new wrapper vue component which provides all the props and functions
 * for the given widget preview component
 *
 * @param {String} name The name of the component to register
 * @param {Component} EditorBlock The component that we want to wrap
 * @param {Object} param2 Behaviour options
 * @returns A new vue component that wrapt the given component
 */

export function registerBuilderBlock(name, EditorBlock, { selectable: __selectable = true, wrap: __wrap = false } = {}) {
  return Vue.component(name, {
    components: {
      EditorBlock,
    },
    props: {
      widget: {
        required: true,
        type: Object,
      },
    },
    data() {
      return {
        disableOver: false,
        over: false,
      }
    },
    computed: {
      childSelected() {
        return childSelected(this.widget.content, this.selectedWidget?.widget?.id)
      },
      selected() {
        return this.selectedWidget && (this.selectedWidget?.widget?.id === this.widget.id)
      },

      canSelect() {
        // return true
        return this.parentSelected || this.parentChildSelected
      },
      parentChildSelected() {
        return this.parentStatus.childSelected
      },
      parentSelected() {
        return this.parentStatus.selected
      },
    },
    inject: ['selectWidget', 'selectedWidget', 'parentStatus', 'addBlockParent', 'removeBlockParent'],
    provide() {
      const provideObj = {
        addBlockParent: this._addBlockParent,
        addBlock: this.addBlock,
        removeBlockParent: this._removeBlockParent,
        removeBlock: this.removeBlock,
      }

      if (__selectable) {
        const parentStatus = {}

        Object.defineProperty(parentStatus, 'selected', {
          enumerable: true,
          get: () => this.selected,
        })

        Object.defineProperty(parentStatus, 'childSelected', {
          enumerable: true,
          get: () => this.childSelected,
        })

        provideObj.parentStatus = parentStatus
      }

      return provideObj
    },
    methods: {
      addBlock(block, id, position) {
        this.addBlockParent(block, id, position)
      },
      _addBlockParent(block, id, position = 'after') {
        addBlockToContent(this.widget, block, id, position)
      },
      removeBlock(id) {
        this.removeBlockParent(id)
      },
      _removeBlockParent(id) {
        removeBlockToContent(this.widget, id)
      },
      updateAttrs(attrs) {
        this.widget.attrs = { ...this.widget.attrs, ...attrs }
      },
    },
    render(h) {
      const ParentAttributes = {
        attrs: {
          'data-id': this.widget.id,
        },
        class: {
          widgetWrapper: __wrap,
          selected: __selectable && this.selected,
          over: __selectable && !this.disableOver && this.over && this.canSelect,
        },
        [!__wrap ? 'nativeOn' : 'on']: {
          // eslint-disable-next-line no-sequences
          click: e => {
            if (!__selectable) return
            if (this.disableOver) return
            if (!this.canSelect) return
            e.stopPropagation()
            this.selectWidget(this.widget)
          },
          mouseover: e => {
            if (!__selectable) return
            this.disableOver = someParentHasClass(e.target, 'builder-add-divider')
            this.over = !this.disableOver
          },
          mouseenter: () => {
            if (this.disableOver) return
            if (!__selectable) return
            // e.stopPropagation()
            this.over = true
          },
          mouseleave: () => {
            if (this.disableOver) return
            if (!__selectable) return
            // if (!this.parentStatus.selected) return
            // e.stopPropagation()
            this.over = false
          },
        },
      }

      const EditorBlockComp = h('EditorBlock', {
        props: {
          attrs: this.widget.attrs,
          updateAttrs: this.updateAttrs,
          selected: this.selected,
          widget: this.widget,
        },
        ...(!__wrap ? ParentAttributes : {}),
      })

      return !__wrap ? EditorBlockComp : h('div', {
        ...ParentAttributes,
      }, [
        h(BuilderBlockPanel, {
          props: {
            widget: this.widget,
          },
          class: {
            'widget-panel': __wrap,
          },
        }),
        EditorBlockComp,
      ])
    },
  })
}

/**
 * Function that generates computed props for widget attributes
 *
 * @param {Array} attrs An array of attribute names
 * @returns An array of computed props with getters/setters
 */

export function generateComputedPropsFromAttrs(attrs) {
  /* eslint-disable func-names */
  const computedProps = {}

  // eslint-disable-next-line no-restricted-syntax
  for (const attrName of attrs) {
    computedProps[attrName] = {
      get() {
        return this.widget.attrs && this.widget.attrs[attrName]
      },
      set(value) {
        return this.updateAttrs({ [attrName]: value })
      },
    }
  }

  return computedProps
}

export function mapAttrsWatch(watcherAttrs) {
  /* eslint-disable func-names */
  const watchers = {}

  // eslint-disable-next-line no-restricted-syntax
  for (const attrName in watcherAttrs) {
    if (Object.prototype.hasOwnProperty.call(watcherAttrs, attrName)) {
      watchers[attrName] = function (newVal, oldVal) {
        if (isEqual(newVal, oldVal)) return

        if (watcherAttrs[attrName] && watcherAttrs[attrName].constructor == Function) {
          // eslint-disable-next-line prefer-rest-params
          watcherAttrs[attrName].call(this, arguments)
        }
      }
    }
  }

  return watchers
}
