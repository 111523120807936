export default [
  {
    path: '/:orgName/user-settings/profile',
    name: 'user-profile',
    component: () => import('@/views/users/settings/Profile.vue'),
    meta: {
      rule: 'editor',
      siteRequired: false,
      organizationRequired: true,
      pageTitle: 'routes.organization.pageTitles.profile',
      headerLogo: true,
      hideMenu: true,
      breadcrumb: [
        {
          text: 'routes.organization.pageTitles.profile',
          active: true,
        },
      ],
    },
  },
  {
    path: '/:orgName/user-settings/notifications',
    name: 'user-notifications',
    component: () => import('@/views/users/settings/Notifications.vue'),
    meta: {
      rule: 'editor',
      siteRequired: false,
      organizationRequired: true,
      pageTitle: 'routes.organization.pageTitles.notifications',
      headerLogo: true,
      hideMenu: true,
      breadcrumb: [
        {
          text: 'routes.organization.pageTitles.notifications',
          active: true,
        },
      ],
    },
  },
  {
    path: '/:orgName/user-settings/author',
    name: 'user-author',
    component: () => import('@/views/users/settings/Author.vue'),
    meta: {
      rule: 'editor',
      siteRequired: false,
      organizationRequired: true,
      pageTitle: 'routes.organization.pageTitles.author',
      headerLogo: true,
      hideMenu: true,
      breadcrumb: [
        {
          text: 'routes.organization.pageTitles.author',
          active: true,
        },
      ],
    },
  },
  {
    path: '/:orgName/authors',
    name: 'authors',
    component: () => import('@/views/authors/Listing.vue'),
    meta: {
      permission: 'canViewAuthors',
      rule: 'editor',
      organizationRequired: true,
      pageTitle: 'routes.organization.pageTitles.authors',
      breadcrumb: [
        {
          text: 'routes.organization.pageTitles.authors',
          active: true,
        },
      ],
    },
  },
  {
    path: '/:orgName/authors/:id',
    name: 'authors-edit',
    component: () => import('@/views/authors/Edit.vue'),
    meta: {
      permission: 'canViewAuthors',
      rule: 'editor',
      organizationRequired: true,
      collapseSidebar: true,
      pageTitle: 'routes.organization.pageTitles.editCreateAuthor',
      navActiveLink: 'authors',
      breadcrumb: [
        {
          text: 'routes.organization.pageTitles.authors',
          to: { name: 'authors' },
        },
        {
          text: 'routes.organization.pageTitles.editCreateAuthor',
          active: true,
        },
      ],
    },
  },
  {
    path: '/:orgName/dashboard-users',
    name: 'dashboard-users',
    component: () => import('@/views/dashboardUsers/Listing.vue'),
    meta: {
      permission: 'canViewUsers',
      rule: 'editor',
      organizationRequired: true,
      pageTitle: 'routes.organization.pageTitles.users',
      breadcrumb: [
        {
          text: 'routes.organization.pageTitles.users',
          active: true,
        },
      ],
    },
  },
  {
    path: '/:orgName/dashboard-users/:id',
    name: 'edit-siteUsers',
    component: () => import('@/views/dashboardUsers/Edit.vue'),
    meta: {
      rule: 'editor',
      organizationRequired: true,
      collapseSidebar: true,
      navActiveLink: 'dashboard-users',
      pageTitle: 'routes.organization.pageTitles.edit-user',
      breadcrumb: [
        {
          text: 'routes.organization.pageTitles.users',
          to: { name: 'dashboard-users' },
        },
        {
          text: 'routes.organization.pageTitles.edit-user',
          active: true,
        },
      ],
    },
  },
  {
    path: '/:orgName/home',
    name: 'home',
    component: () => import('@/views/sites/Listing.vue'),
    meta: {
      organizationRequired: true,
      pageTitle: 'routes.organization.pageTitles.sites',
      breadcrumb: [
        {
          text: 'routes.organization.pageTitles.sites',
          active: true,
        },
      ],
    },
  },
  {
    path: '/:orgName/sites',
    name: 'sites',
    component: () => import('@/views/sites/Listing.vue'),
    meta: {
      permission: 'canViewSites',
      organizationRequired: true,
      pageTitle: 'routes.organization.pageTitles.sites',
      breadcrumb: [
        {
          text: 'routes.organization.pageTitles.sites',
          active: true,
        },
      ],
    },
  },
  {
    path: '/:orgName/notifications/',
    name: 'all-notifications',
    component: () => import('@core/layouts/components/app-navbar/components/AllNotifications.vue'),
    meta: {
      rule: 'editor',
      siteRequired: false,
      organizationRequired: true,
      pageTitle: 'routes.organization.pageTitles.allNotifications',
      breadcrumb: [],
    },
  },
  {
    path: '/:orgName/api-keys',
    name: 'api-keys',
    component: () => import('@/views/api-keys/Listing.vue'),
    meta: {
      permission: 'canViewApiKeys',
      rule: 'editor',
      organizationRequired: true,
      pageTitle: 'routes.organization.pageTitles.apiKeys',
      breadcrumb: [
        {
          text: 'routes.organization.pageTitles.apiKeys',
          active: true,
        },
      ],
    },
  },
  {
    path: '/:orgName/labels',
    name: 'labels',
    component: () => import('@/views/labels/Listing.vue'),
    meta: {
      permission: 'canViewLabels',
      rule: 'editor',
      organizationRequired: true,
      pageTitle: 'routes.organization.pageTitles.labels',
      breadcrumb: [
        {
          text: 'routes.organization.pageTitles.labels',
          active: true,
        },
      ],
    },
  },
  {
    path: '/:orgName/teams',
    name: 'teams',
    component: () => import('@/views/teams/Listing.vue'),
    meta: {
      permission: 'canViewTeams',
      rule: 'editor',
      organizationRequired: true,
      pageTitle: 'routes.organization.pageTitles.teams',
      breadcrumb: [
        {
          text: 'routes.organization.pageTitles.teams',
          active: true,
        },
      ],
    },
  },
  {
    path: '/:orgName/teams/:id',
    name: 'teams-edit',
    component: () => import('@/views/teams/Edit.vue'),
    meta: {
      permission: 'canViewTeams',
      rule: 'editor',
      organizationRequired: true,
      collapseSidebar: true,
      navActiveLink: 'teams',
      pageTitle: 'routes.organization.pageTitles.teams',
      breadcrumb: [
        {
          text: 'routes.organization.pageTitles.teams',
          to: { name: 'teams' },
        },
        {
          text: 'routes.organization.pageTitles.createEditTeams',
          active: true,
        },
      ],
    },
  },
  {
    path: '/:orgName/models',
    name: 'models',
    component: () => import('@/views/models/Listing.vue'),
    meta: {
      permission: 'canViewModels',
      rule: 'editor',
      organizationRequired: true,
      pageTitle: 'routes.organization.pageTitles.models',
      breadcrumb: [
        {
          text: 'routes.organization.pageTitles.models',
          active: true,
        },
      ],
    },
  },
  {
    path: '/:orgName/models/:id',
    name: 'models-edit',
    component: () => import('@/views/models/Edit.vue'),
    meta: {
      permission: 'canViewModels',
      rule: 'editor',
      organizationRequired: true,
      collapseSidebar: true,
      pageTitle: 'routes.organization.pageTitles.createEditModels',
      navActiveLink: 'models',
      breadcrumb: [
        {
          text: 'routes.organization.pageTitles.models',
          to: { name: 'models' },
        },
        {
          text: 'routes.organization.pageTitles.createEditModels',
          active: true,
        },
      ],
    },
  },
  {
    path: '/:orgName/plugins',
    name: 'plugins',
    component: () => import('@/views/plugins/Listing.vue'),
    meta: {
      permission: 'canViewPlugins',
      rule: 'editor',
      organizationRequired: true,
      pageTitle: 'routes.organization.pageTitles.plugins',
      breadcrumb: [
        {
          text: 'routes.organization.pageTitles.plugins',
          active: true,
        },
      ],
    },
  },
  {
    path: '/:orgName/plugins/:id',
    name: 'plugins-edit',
    component: () => import('@/views/plugins/Edit.vue'),
    meta: {
      permission: 'canViewPlugins',
      rule: 'editor',
      organizationRequired: true,
      collapseSidebar: true,
      pageTitle: 'routes.organization.pageTitles.editPlugins',
      navActiveLink: 'plugins',
      breadcrumb: [
        {
          text: 'routes.organization.pageTitles.plugins',
          to: { name: 'plugins' },
        },
        {
          text: 'routes.organization.pageTitles.editPlugins',
          active: true,
        },
      ],
    },
  },
  {
    path: '/:orgName/webhooks',
    name: 'webhooks',
    component: () => import('@/views/webhooks/Listing.vue'),
    meta: {
      permission: 'canViewWebhooks',
      rule: 'editor',
      organizationRequired: true,
      pageTitle: 'routes.organization.pageTitles.webhooks',
      breadcrumb: [
        {
          text: 'routes.organization.pageTitles.webhooks',
          active: true,
        },
      ],
    },
  },
  {
    path: '/:orgName/webhooks/:id',
    name: 'webhooks-edit',
    component: () => import('@/views/webhooks/Webhook.vue'),
    meta: {
      permission: 'canViewWebhooks',
      rule: 'editor',
      organizationRequired: true,
      collapseSidebar: true,
      pageTitle: 'routes.organization.pageTitles.createEditWebhooks',
      navActiveLink: 'webhooks',
      breadcrumb: [
        {
          text: 'routes.organization.pageTitles.webhooks',
          to: { name: 'webhooks' },
        },
        {
          text: 'routes.organization.pageTitles.createEditWebhooks',
          active: true,
        },
      ],
    },
  },
  {
    path: '/:orgName/presets',
    name: 'presets',
    component: () => import('@/views/presets/Listing.vue'),
    meta: {
      permission: 'canViewPresets',
      rule: 'editor',
      organizationRequired: true,
      pageTitle: 'routes.organization.pageTitles.presets',
      navActiveLink: 'presets',
      breadcrumb: [
        {
          text: 'routes.organization.pageTitles.presets',
          active: true,
        },
      ],
    },
  },

  {
    path: '/:orgName/assets',
    name: 'assets',
    component: () => import('@/views/assets/Listing.vue'),
    meta: {
      rule: 'editor',
      organizationRequired: true,
      pageTitle: 'routes.organization.pageTitles.assets',
      breadcrumb: [

        {
          text: 'routes.organization.pageTitles.assets',
          folder: '',
          to: { name: 'images' },
        },
      ],
    },
  },
  {
    path: '/:orgName/all-integrations',
    name: 'all-integrations',
    component: () => import('@/views/all-integrations/Listing.vue'),
    meta: {
      permission: 'canViewIntegrations',
      rule: 'editor',
      organizationRequired: true,
      pageTitle: 'routes.organization.pageTitles.all-integrations',
      breadcrumb: [
        {
          text: 'routes.organization.pageTitles.all-integrations',
          active: true,
        },
      ],
    },
  },
  {
    path: '/:orgName/workflows',
    name: 'workflows',
    component: () => import('@/views/workflows/Listing.vue'),
    meta: {
      permission: 'canViewWorkflows',
      rule: 'editor',
      organizationRequired: true,
      pageTitle: 'routes.organization.pageTitles.workflows',
      breadcrumb: [
        {
          text: 'routes.organization.pageTitles.workflows',
          active: true,
        },
      ],
    },
  },
  {
    path: '/:orgName/workflow/create',
    name: 'workflow-create',
    component: () => import('@/views/workflows/Create.vue'),
    props: { isCreate: true },
    meta: {
      permission: 'canViewJobs',
      rule: 'editor',
      organizationRequired: true,
      collapseSidebar: true,
      pageTitle: 'routes.organization.pageTitles.workflow-create',
      navActiveLink: 'workflows',
      breadcrumb: [
        {
          text: 'routes.organization.pageTitles.workflows',
          to: { name: 'workflows' },
        },
        {
          text: 'routes.organization.pageTitles.workflow-create',
          active: true,
        },
      ],
    },
  },
  {
    path: '/:orgName/workflow/:id/edit',
    name: 'workflow-edit',
    component: () => import('@/views/workflows/Create.vue'),
    props: { isCreate: false },
    meta: {
      permission: 'canViewJobs',
      rule: 'editor',
      organizationRequired: true,
      collapseSidebar: true,
      pageTitle: 'routes.organization.pageTitles.workflow-edit',
      navActiveLink: 'workflows',
      breadcrumb: [
        {
          text: 'routes.organization.pageTitles.workflows',
          to: { name: 'workflows' },
        },
        {
          text: 'routes.organization.pageTitles.workflow-edit',
          active: true,
        },
      ],
    },
  },
  {
    path: '/:orgName/workflow/:id',
    name: 'workflow-logs',
    component: () => import('@/views/workflows/Logs.vue'),
    meta: {
      permission: 'canViewJobs',
      rule: 'editor',
      organizationRequired: true,
      pageTitle: 'routes.organization.pageTitles.workflow-logs',
      navActiveLink: 'workflows',
      breadcrumb: [
        {
          text: 'routes.organization.pageTitles.workflows',
          to: { name: 'workflows' },
        },
        {
          text: 'routes.organization.pageTitles.workflow-logs',
          active: true,
        },
      ],
    },
  },
  {
    path: '/:orgName/jobs',
    name: 'jobs',
    component: () => import('@/views/jobs/Listing.vue'),
    meta: {
      permission: 'canViewJobs',
      rule: 'editor',
      organizationRequired: true,
      pageTitle: 'routes.organization.pageTitles.jobs',
      breadcrumb: [
        {
          text: 'routes.organization.pageTitles.jobs',
          active: true,
        },
      ],
    },
  },
  {
    path: '/:orgName/jobs/:id',
    name: 'jobs-details',
    component: () => import('@/views/jobs/Details.vue'),
    meta: {
      permission: 'canViewJobs',
      rule: 'editor',
      organizationRequired: true,
      collapseSidebar: true,
      pageTitle: 'routes.organization.pageTitles.jobs-details',
      navActiveLink: 'jobs',
      breadcrumb: [
        {
          text: 'routes.organization.pageTitles.jobs',
          to: { name: 'jobs' },
        },
        {
          text: 'routes.organization.pageTitles.jobs-details',
          active: true,
        },
      ],
    },
  },
  {
    path: '/:orgName/localizations',
    name: 'localizations',
    component: () => import('@/views/localizations/Listing.vue'),
    meta: {
      permission: 'canManageLocalizations',
      rule: 'editor',
      organizationRequired: true,
      pageTitle: 'routes.organization.pageTitles.localizations',
      breadcrumb: [
        {
          text: 'routes.organization.pageTitles.localizations',
          active: true,
        },
      ],
    },
  },
  {
    path: '/:orgName/localizations/:language',
    name: 'edit-localizations',
    component: () => import('@/views/localizations/Edit.vue'),
    meta: {
      permission: 'canManageLocalizations',
      rule: 'editor',
      organizationRequired: true,
      collapseSidebar: true,
      navActiveLink: 'localizations',
      pageTitle: 'routes.organization.pageTitles.edit-localizations',
      breadcrumb: [
        {
          text: 'routes.organization.pageTitles.localizations',
          to: { name: 'dashboard-users' },
        },
        {
          text: 'routes.organization.pageTitles.edit-localizations',
          active: true,
        },
      ],
    },
  },
]
