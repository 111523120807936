<template>
  <b-sidebar
    id="urls-popup"
    v-model="showPopup"
    title="Add Url"
    class="c-sidebar c-sidebar--xl"
    :no-close-on-route-change="true"
    right
    shadow
    backdrop
    @ok="proceedSelect"
    @close="cancelSelect"
  >
    <Listing
      :is-popup="true"
      :type="data.type"
      @selectedUrls="selected"
    />
    <template #footer>
      <b-button @click="cancelSelect">
        {{ $t('general.cancel') }}
      </b-button>
      <b-button
        variant="primary"
        @click="proceedSelect"
      >
        {{ $t('general.insert') }}
      </b-button>
    </template>
  </b-sidebar>
</template>
<script>
import PopupsMixin from '@/components/popups/PopupsMixin'
import Listing from '@/views/urls/Listing.vue'

export default {
  name: 'UrlsPopup',
  components: {
    Listing,
  },
  mixins: [PopupsMixin],
  data() {
    return {
      selectedUrls: [],
    }
  },
  methods: {
    selected(data) {
      this.selectedUrls = data
    },
    proceedSelect() {
      try {
        this.closePopup(this.selectedUrls)
      } catch (error) {
        console.log(error)
      }
    },
    cancelSelect() {
      this.closePopup(false)
    },
  },
}
</script>
<style lang="scss">
  #urls-popup {
    .btn-secondary{
      border-color: #fff !important;
      background-color: #fff !important;
      color:black!important;
    }
    .full-height-layout {
      height: calc(100vh - 155px) !important;
    }
  }
</style>
