<template>
  <div class="assets-grid">
    <div
      ref="containerWrapper"
      class="grid-container"
      @scroll="loadMore"
    >
      <div
        ref="container"
        class="grid-view"
      >
        <draggable
          v-if="selectedData.show && !filtered"
          v-model="selectedAssets"
          class="grid__display-style"
          :style="gridStyle"
          draggable=".item"
          @change="$emit('setCustomSort')"
        >
          <slot
            v-for="item in selectedAssets"
            v-bind="item"
          />
        </draggable>
        <div
          v-else
          class="grid__display-style"
          :style="gridStyle"
        >
          <slot
            v-for="item in items"
            v-bind="item"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import { mapMutations, mapState } from 'vuex'
import hotkeys from 'hotkeys-js'
import moment from 'moment'

hotkeys.filter = function () {
  return true
}

export default {
  components: {
    draggable,
  },
  props: {
    total: {
      type: Number,
    },
    totalSize: {
      type: Number,
    },
    items: {
      type: Array,
      default: null,
    },
    columnIndex: {
      type: Number,
    },
    options: {
      type: Array,
      default: () => [9, 8, 7, 6, 5, 4, 3],
    },
    changeRoute: {
      type: Boolean,
      default: false,
    },
    filtered: {
      type: Boolean,
      default: false,
    },
    isPopup: {
      type: Boolean,
      default: false,
    },
    folderId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      currentAsset: null,
      layout: 'grid',
    }
  },
  mounted() {
    hotkeys('ctrl+a, command+a', event => {
      // if target is input, textarea or select, do not select all
      const target = event?.target?.tagName?.toLowerCase()
      if (target === 'input' || target === 'textarea' || target === 'select') {
        return
      }

      event.preventDefault()

      this.selectedAssets = this.folderId ? this.selectedAssets.filter(x => x.folderId !== this.folderId) : []
      this.selectedAssets = [...this.selectedAssets, ...this.items.filter(x => {
        const isUploading = x.uploadStatus === 'uploading'

        if (this.isPopup) {
          const isAfter = moment().isAfter(x.expiresAt)
          return !isUploading && !isAfter
        }

        return !isUploading
      })]
    })
  },
  beforeDestroy() {
    hotkeys.unbind('ctrl+a, command+a')
  },
  computed: {
    ...mapState('assets', [
      'selectedData',
    ]),
    gridStyle() {
      return {
        gridTemplateColumns: `repeat(${this.options[this.columnIndex]}, minmax(0, 1fr))`,
      }
    },
    selectedAssets: {
      get() {
        return this.selectedData.items
      },
      set(v) {
        let totalSize = 0
        v.forEach(a => { totalSize += a.size })
        this.SET_SELECTED_DATA({
          ...this.selectedData,
          totalSize: totalSize || 0,
          items: v,
          total: v.length,
        })
      },
    },
  },
  methods: {
    ...mapMutations('assets', [
      'SET_SELECTED_DATA',
    ]),
    loadMore() {
      const containerWrapper = this.$refs.containerWrapper.getBoundingClientRect().bottom
      const container = this.$refs.container.getBoundingClientRect().bottom - 1000
      if (containerWrapper > container && this.items.length < this.total) {
        this.$emit('loadMore')
      }
    },
    scrollToTop() {
      this.$refs.containerWrapper.scrollTo({ top: 0, behavior: 'smooth' })
    },
  },
}
</script>

<style lang="scss">
@import '../../../../node_modules/vue-context/dist/css/vue-context.css';

</style>
