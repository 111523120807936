import axios from 'axios'
import store from '@/store'
// import state from '../modulesVpUploadState'
import multipartComplete from './multipartComplete'
import {
  getMultipartPreSigned, multipartAbort,
} from '../../../codex-sdk/vp-upload'

async function uploadParts(file, alreadyUploadedParts) {
  file.uploadType = 'multiple'

  const { FILE_CHUNK_SIZE } = store.state.vpUploads
  const PART_NUMBERS = Math.ceil(file.size / FILE_CHUNK_SIZE)
  const promises = []
  const PROGRESS = []
  file.cancelToken = axios.CancelToken.source()

  let one = true
  let urls = []
  let requestKey = null
  if (file.requestKey) {
    requestKey = file.requestKey // get request key from state because is required to call multipart-complete for uploaded chunks
    urls = file.urls // get array of urls from state because file is retrying upload. It requires same urls to multipart-complete
  } else {
    await getMultipartPreSigned({
      partsNumber: PART_NUMBERS, // number of parts to generate presigned urls
      fileName: file.name,
      contentLength: FILE_CHUNK_SIZE, // limit of chunk size
      mimeType: file.type,
    }).then(res => {
      requestKey = res.data.result.requestKey // get requestKey
      file.requestKey = requestKey // set requestKey to file object on state in case of failed upload
      urls = res.data.result.presignedUrl // get array of urls from response
      file.urls = urls // set urls to file object on state in case of failed upload
    }).catch(() => {
      file.status = 'failed'
    })
  }

  urls.forEach((url, i) => {
    // For Each url on urls array, split file into FILE_CHUNK_SIZE size
    const index = parseInt(i, 10)
    if (!alreadyUploadedParts.includes(index + 1)) {
      const start = index * FILE_CHUNK_SIZE
      const end = (index + 1) * FILE_CHUNK_SIZE
      const blob = index < urls.length
        ? file.file.slice(start, end)
        : file.file.slice(start)

      // Add axios.put request with url, chunk(blob) and headers in array promises and later we can use Promise.all() on this
      promises.push(axios.put(url.presignedUrl, blob, {
        headers: {
          PartSize: FILE_CHUNK_SIZE,
          PartNumber: String(index),
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/octet-stream',
          ACL: 'public-read',
        },
        onUploadProgress: progressEvent => {
          const { total, loaded } = progressEvent
          PROGRESS[index] = total
          if (PROGRESS[index] !== null) {
            PROGRESS[index] = Math.round((loaded * 100) / total)
          }
          const p = PROGRESS.reduce((x, y) => x + y)
          file.progress = Math.round(p / PART_NUMBERS) + Math.ceil((100 / PART_NUMBERS) * alreadyUploadedParts.length)

          if (one) file.status = 'uploading'; one = false
        },
        cancelToken: file.cancelToken.token,
      }))
    }
  })

  file.status = 'uploading'

  // await all files to upload on this Promise
  await Promise.allSettled(promises)
    .then(response => {
      const parts = response.filter(x => x.status === 'fulfilled').map(part => ({
        // response header 'eTag' might not be accessible by default this should be allowed on server-side
        // eTag header is returned as "\"eTagKey\"", so we remove each key's double quotes
        // eslint-disable-next-line no-useless-escape
        eTag: part.value.headers.etag.split('\"').join(''),
        partNumber: parseInt(part.value.config.headers.PartNumber, 10) + 1,
      }))

      if (response.filter(x => x.status === 'rejected').length < 1) {
        const concatedArray = file.uploadedParts.concat(parts)
        file.uploadedParts = concatedArray
        multipartComplete(file, requestKey)
      } else {
        const canceled = response.filter(x => axios.isCancel(x.reason))
        if (canceled.length > 0) {
          file.status = 'canceled'
          multipartAbort(requestKey)
        } else {
          file.status = 'failed'
          const concatedArray = file.uploadedParts.concat(parts)
          file.uploadedParts = concatedArray
        }
      }
    })
    .catch(err => {
      if (axios.isCancel(err)) {
        file.status = 'canceled'
        multipartAbort(requestKey)
      } else file.status = 'failed'
    })
}

export default uploadParts
