<template>
  <div :class="{'site__settings-save-alignment': alignment, 'site__settings-save-border-top': topBorder, 'site__settings-save-section': !topBorder}">
    <b-button
      v-if="cancel"
      id="at-site-settings-cancel-button"
      class="site__settings-save-action"
      variant="outline-secondary"
      @click="$emit('cancel')"
    >
      {{ $t('general.cancel') }}
    </b-button>
    <b-button
      id="at-site-settings-save-button"
      v-permission="permission"
      class="site__settings-save-action"
      variant="primary"
      :disabled="saving"
      @click="$emit('save')"
    >
      {{ $t('general.save') }}
    </b-button>
  </div>
</template>

<script>
export default {
  name: 'SaveButton',
  props: {
    permission: {
      type: Array,
      default: () => [],
    },
    saving: Boolean,
    topBorder: {
      type: Boolean,
      default: false,
    },
    cancel: {
      type: Boolean,
      default: false,
    },
    alignment: {
      type: String,
      default: 'right',
    },
  },
}
</script>
