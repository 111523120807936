<template>
  <b-form-group>
    <template #label>
      <FieldName
        :name="field.name"
        :help-text="''"
      />
    </template>
    <b-form-input
      v-if="fieldContent"
      type="text"
      :value="dateTimeValue"
      disabled
    />
    <div
      v-else
      class="content-list__empty"
    >
      <GjIcon name="Calendar" />
      <span>{{ $tc('entries.merge-tool.info.no-date-value') }}</span>
    </div>
  </b-form-group>
</template>

<script>
import FieldName from '@/components/fields/FieldName.vue'
import { BFormInput } from 'bootstrap-vue'
import { DATE_FORMAT } from '@/components/fields/DateAndTime'

export default {
  name: 'DateTimeField',
  components: {
    BFormInput,
    FieldName,
  },
  props: {
    fieldContent: {
      type: [String],
    },
    field: {
      type: [Object],
    },
  },
  computed: {
    dateTimeValue() {
      const dateTime = this.fieldContent ?? ''
      if (this.field.format === DATE_FORMAT.DATE) {
        return this.$options.filters.formatDate(dateTime, true)
      }
      return this.$options.filters.formatDateTime(dateTime)
    },
  },
}
</script>
