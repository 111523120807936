<template>
  <div>
    <b-button
      v-if="!entry.id"
      variant="primary"
      class="w-100"
      @click="save"
    >
      {{ $t('entries.save-button.save') }}
    </b-button>
    <b-dropdown
      v-else
      v-permission="['canPublishEntry', { ...entry, entryLabels: currentEntryLabels }]"
      split
      variant="primary"
      block
      class="entry-save__dropdown"
      :disabled="isSaving"
      @click="entry.system.status !== ENTRY_STATUSES.PUBLISHED ? publish(true) : save()"
    >
      <template #button-content>
        <b-spinner
          v-if="isSaving && showSpinner"
          class="entry-save__spinner"
        />
        {{ isStatusPublishedOrEdited ? $t('entries.save-button.republish') : $t('entries.save-button.publish') }}
      </template>

      <b-dropdown-item
        v-show="entry.system.status !== ENTRY_STATUSES.PUBLISHED"
        id="at-entries-general-publish-dropdown"
        v-permission="['canPublishEntry', { ...entry, entryLabels: currentEntryLabels }]"
        @click="publish(false)"
      >
        <GjIcon name="SendMessageAlt" />
        {{ isStatusPublishedOrEdited ? $t('entries.save-button.republish-continue') : $t('entries.save-button.publish-continue') }}
      </b-dropdown-item>
      <b-dropdown-item
        v-if="showSchedule"
        id="at-entries-general-scheduleDropdown"
        v-permission="['canPublishEntry', { ...entry, entryLabels: currentEntryLabels }]"
        @click="openScheduler"
      >
        <GjIcon name="Calendar" />
        {{ $t('entries.save-button.schedule') }}
      </b-dropdown-item>
      <b-dropdown-item
        v-show="entry.system.status === ENTRY_STATUSES.PUBLISHED || entry.system.status === ENTRY_STATUSES.EDITED"
        id="at-entries-general-unPublish"
        v-permission="['canUnpublishEntry', { ...entry, entryLabels: currentEntryLabels }]"
        @click="unpublish"
      >
        <GjIcon name="Denied" />
        {{ $t('entries.save-button.unpublish') }}
      </b-dropdown-item>
      <b-dropdown-item
        v-show="entry.system.status === ENTRY_STATUSES.SCHEDULED"
        id="at-entries-general-unSchedule"
        v-permission="['canUnpublishEntry', { ...entry, entryLabels: currentEntryLabels }]"
        @click="unschedule"
      >
        <GjIcon name="Denied" />
        {{ $t('entries.save-button.unschedule') }}
      </b-dropdown-item>
      <b-dropdown-item
        v-show="entry.id && ![ENTRY_STATUSES.PUBLISHED,ENTRY_STATUSES.EDITED].includes(entry.system.status)"
        id="at-articles-tab-deleteButton"
        v-permission="['canDeleteEntry', { ...entry, entryLabels: currentEntryLabels }]"
        @click="deleteEntry"
      >
        <GjIcon name="Delete" />
        {{ $t('entries.save-button.delete') }}
      </b-dropdown-item>
    </b-dropdown>
    <SaveModal
      :key="showEntrySaveModal"
      v-model="showEntrySaveModal"
      :published-date-time="entry.system.publishedAt"
      :status="entry.system.status"
      :unpublished-entries="unpublishedEntries"
      @republishDate="republishDate"
      @cancelSelect="cancel"
    />
  </div>
</template>

<script>
import moment from 'moment'
import { ENTRY_STATUSES, transformEntriesGraphQL } from '@/codex-sdk/entries'
import SaveModal from '@/views/entries/components/SaveModal.vue'
import { mapState } from 'vuex'
import { AUTO_SAVE_STATUSES, CMI_ORGANIZATIONS } from '@/utils/constants'
import { getBlocks, TYPES, VALUE_TYPES } from '@/views/models/constants'
import gql from 'graphql-tag'
import * as yup from 'yup'

export default {
  components: {
    SaveModal,
  },
  props: {
    entry: {
      type: Object,
      required: true,
    },
    model: {
      type: Object,
      required: true,
    },
  },
  inject: ['toastNotification', 'showUnpublishEntryPopup', 'entryLabels', 'showScheduleEntryPopup'],
  data() {
    const isPublishedOrScheduled = this.entry.system.status === ENTRY_STATUSES.PUBLISHED
    return {
      isPublishedOrScheduled,
      showEntrySaveModal: false,
      datePickrConfig: {
        enableTime: true,
      },
      redirect: false,
      showSpinner: this.entry.system.status !== ENTRY_STATUSES.DRAFT,
      unpublishedEntries: [],
      callback: undefined,
      withCallback: false,
    }
  },
  computed: {
    ...mapState('autoSave', [
      'status',
    ]),
    isSaving() {
      return this.status === AUTO_SAVE_STATUSES.SAVING
    },
    hasUnpublishedEntries() {
      return this.unpublishedEntries?.length > 0
    },
    currentEntryLabels() {
      return this.entryLabels?.()
    },
    ENTRY_STATUSES() {
      return ENTRY_STATUSES
    },
    isStatusPublishedOrEdited() {
      return this.entry.system.status === ENTRY_STATUSES.PUBLISHED || this.entry.system.status === ENTRY_STATUSES.EDITED
    },
    showSchedule() {
      return this.entry.system.status !== ENTRY_STATUSES.PUBLISHED || !CMI_ORGANIZATIONS.includes(this.$store.state.general.currentOrganization.alias)
    },
  },
  methods: {
    sendCallback(callback, value) {
      return new Promise(() => {
        try {
          callback(value)
        } catch {
          //
        }
      })
    },
    cancel() {
      if (this.withCallback) {
        this.sendCallback(this.callback, false)
      }
    },
    async fetchUnpublishedEntries() {
      if (!this.model || !this.entry) return
      try {
        const ids = []
        // reference field
        const referenceFields = getBlocks(this.model.blocks, block => block.isField && block.attrs.type == TYPES.REFERENCE).map(f => ({
          valueType: f.attrs.valueType,
          alias: f.attrs.alias,
        }))
        referenceFields.forEach(field => {
          if (field.valueType === VALUE_TYPES.SINGLE && this.entry?.content?.[field.alias]?.entryId) {
            ids.push(this.entry.content[field.alias].entryId)
          } else if (this.entry?.content?.[field.alias]?.length) {
            ids.push(...this.entry?.content?.[field.alias].map(e => e.entryId))
          }
        })

        const validateReferences = yup.array().of(yup.object().shape({
          entryId: yup.string().required(),
          model: yup.string().required(),
        }))

        // rich content field
        const richContentFields = getBlocks(this.model.blocks, block => block.isField && block.attrs.type == TYPES.RICH_CONTENT).map(f => ({ alias: f.attrs.alias }))
        const marks = []
        richContentFields.forEach(field => {
          const referenceBlocks = getBlocks(this.entry?.content?.[field.alias], block => {
            if (block.marks) marks.push(...block.marks.filter(mark => mark.type === 'link'))
            try {
              if (block.attrs?.references?.length > 0) {
                validateReferences.validateSync(block.attrs.references)
                return true
              }
            } catch {
              return false
            }
            return false
          })
          const referenceIds = referenceBlocks.flatMap(rf => rf.attrs.references?.map(r => r.entryId))
          if (referenceIds?.length) ids.push(...referenceIds)
        })
        if (marks.length) {
          ids.push(...marks.filter(e => e.attrs.entryId).map(m => m.attrs.entryId))
        }

        if (!ids.length) {
          this.unpublishedEntries = []
          return
        }
        // ids
        const useIds = ids.filter(Boolean)
        const { data: { entryCollection } } = await this.$apollo.query({
          query: gql`
            query ($limit: Int, $ids: [String!]) {
              entryCollection (limit: $limit, where: {
               id: {
                 in: $ids
               }
               system: {
                 status: {
                    notIn: [PUBLISHED]
                 }
               }
              },
              order: {
                system: {
                  createdAt: DESC
                }
              }) {
                items {
                  id
                  system {
                    title
                    slug
                    status
                    publishedAt
                    featuredMedia {
                      id
                      type
                      url (transformation: {format: THUMBNAIL})
                      title
                    }
                    modelId
                    modelAlias
                    createdAt
                    createdBy {
                      id
                      email
                      firstName
                      lastName
                      imageUrl
                    }
                    scheduledVersions (where: {
                      status: { in: [SCHEDULED] }
                    }) {
                      items {
                        id
                        versionId
                        publishScheduledDate
                        unpublishScheduledDate
                        versionId
                        createdAt
                      }
                    }
                  }
                }
              }
            }
          `,
          variables: {
            limit: useIds.length || 0,
            ids: useIds,
          },
          fetchPolicy: 'network-only',
        })
        if (entryCollection?.items) {
          transformEntriesGraphQL(entryCollection.items)
        }

        this.unpublishedEntries = entryCollection.items || []
      } catch (e) {
        console.error(e)
      }
    },
    async openScheduler() {
      await this.fetchUnpublishedEntries()
      const data = await this.showScheduleEntryPopup({
        entry: this.entry,
        unpublishedEntries: this.unpublishedEntries,
      })

      if (data) {
        this.$emit('scheduleVersion', data)
      }
    },
    deleteEntry() {
      this.$emit('delete')
    },
    republishDate(date, publishAll) {
      this.showSpinner = true
      this.$emit('save', { status: ENTRY_STATUSES.PUBLISHED, isStatusSchedule: null, publishedAt: date }, this.redirect, publishAll, this.withCallback ? this.callback : undefined, this.withCallback)
      this.showEntrySaveModal = false
    },
    setCallback(callback) {
      this.callback = callback
      this.publish(false, true)
    },
    async unpublish() {
      const res = await this.showUnpublishEntryPopup({
        entry: this.entry,
      })
      if (!res) return
      this.showSpinner = true
      if (this.entry.system.status === ENTRY_STATUSES.SCHEDULED) {
        this.$emit('save', { status: ENTRY_STATUSES.DRAFT, isStatusSchedule: null })
      } else {
        this.$emit('save', { status: ENTRY_STATUSES.UNPUBLISHED, isStatusSchedule: null })
      }
    },
    async save() {
      this.showSpinner = true
      this.withCallback = false
      if (this.entry.system.status === ENTRY_STATUSES.PUBLISHED || this.entry.system.status === ENTRY_STATUSES.EDITED) {
        await this.fetchUnpublishedEntries()
        this.redirect = true
        this.showEntrySaveModal = true
      } else {
        this.$emit('save', { status: this.entry.system.status, isStatusSchedule: null, publishedAt: this.entry.system.publishedAt })
      }
    },
    async publish(redirect = false, withCallback = false) {
      this.withCallback = withCallback
      await this.fetchUnpublishedEntries()
      if (this.entry.system.status === ENTRY_STATUSES.PUBLISHED || this.entry.system.status === ENTRY_STATUSES.EDITED) {
        this.redirect = redirect
        this.showEntrySaveModal = true
      } else if (this.hasUnpublishedEntries) {
        this.showEntrySaveModal = true
      } else {
        this.showSpinner = true
        this.$emit('save', { status: ENTRY_STATUSES.PUBLISHED, publishedAt: moment(), isStatusSchedule: false }, redirect, false, this.withCallback ? this.callback : undefined, this.withCallback)
      }
    },
    async unschedule() {
      // Todo STATUS maybe should be set to DRAFT
      const res = await this.showUnpublishEntryPopup({
        entry: this.entry,
      })
      if (!res) return
      this.showSpinner = true
      this.$emit('save', { status: ENTRY_STATUSES.UNPUBLISHED, publishedAt: null, isStatusSchedule: false })
    },
  },
}
</script>

<style lang="scss">
</style>
