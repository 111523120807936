import { NodeSelection } from 'tiptap'
import { findParentNodeOfTypeClosestToPos } from 'prosemirror-utils'
import { CellSelection } from 'prosemirror-tables'
import { CODEX_EDITOR_BLOCKS } from '@/components/codex-editor/CodeEditorConstants'

/**
 * From TipTap v2
 *
 * https://github.com/ueberdosis/tiptap/blob/develop/packages/extension-table/src/utilities/deleteTableWhenAllCellsSelected.ts
 */
export function allCellsSelected(editor) {
  const { selection } = editor.state

  if (selection === null) return false

  if (!(selection instanceof CellSelection)) return false

  let cellCount = 0
  const table = findParentNodeOfTypeClosestToPos(selection.ranges[0].$from, editor.schema.nodes.table)

  table.node.descendants(node => {
    if (node.type.name === CODEX_EDITOR_BLOCKS.TABLE) {
      return
    }

    if (['table_cell', 'table_header'].includes(node.type.name)) {
      cellCount += 1
    }
  })

  return cellCount === selection.ranges.length
}

/**
 * Find the position in doc for given node
 */
export function findNodePosition(doc, node) {
  /* eslint-disable */
  let n = -1
  doc.descendants((function (desNode, r) {
    let nodeAttrs, desNodeAttrs
    if (
      (null === node || void 0 === node || null === (nodeAttrs = node.attrs) || void 0 === nodeAttrs ? void 0 : nodeAttrs.blockId) ===
      (null === desNode || void 0 === desNode || null === (desNodeAttrs = desNode.attrs) || void 0 === desNodeAttrs ? void 0 : desNodeAttrs.blockId)
    ) return n = r, !1
  }))
  return n
}

/**
 * Find nodes by type
 */
export function findNodesByType(doc, type){
  const nodes = []
  doc.descendants(function(node) {
    if(node?.type?.name === type)
      nodes.push(node)
  })
  return nodes
}

/**
 * Find a node by blockId
 */
 export function findNodeByBlockId(blockId, editorOrNode) {
  if( editorOrNode.view ) return findNodeByBlockId(blockId, editorOrNode.view.state.doc);
  if(editorOrNode.attrs?.blockId == blockId) return editorOrNode;
  for(var node of editorOrNode.content.content) {
    let found = findNodeByBlockId(blockId, node);
    if(found) return found;
  }
  return null;
}

/**
 * Select node
 */
export function selectNode(node, editor) {
  if(!node || !editor) return

  if(node.constructor === String) {
    node = findNodeByBlockId(node, editor);
  }

  let t = editor.view.state,
    e = t.doc,
    n = t.tr,
    r = findNodePosition(e, node),
    o = new NodeSelection(n.doc.resolve(r)),
    i = n.setSelection(o)
  editor.view.dispatch(i)
}


export function deletePreviousCharacters(editor, count) {
  const { state, dispatch } = editor.view
  const { selection } = state
  if (!selection.empty) return

  const from = Math.max(selection.from - count, 0)
  const to = selection.from
  const transaction = state.tr.delete(from, to)
  dispatch(transaction)
}

export function getBlockIdFromDom(dom) {
  return dom.dataset.id && dom.dataset.id.startsWith('bl') && dom.dataset.id.length === 22 ? dom.dataset.id : null
}
