<template>
  <div class="embed-block ">
    <template v-if="attrs.src">
      <div
        class="d-flex facebook-embed-block"
        :class="alignClass"
      >
        <div class="facebook-embed__format-block">
          <div
            class="fb-post"
            :data-href="src"
            style="width: 500px"
          />
          <b-form-input
            v-model="caption"
            class="facebook-embed__caption"
            :placeholder="$t('codex-editor.nodes.facebook-embed.caption')"
            rows="1"
            max-rows="10"
          />
        </div>
      </div>
      <div
        v-if="!selected"
        class="embed-block__block"
        @click="selectNode"
      />
    </template>
  </div>
</template>

<script>
export default {
  props: ['attrs', 'updateAttrs', 'selectNode', 'selected'],
  inject: ['deleteBlock', 'showPromptPopup', 'toastNotification'],
  data() {
    return {
      initialSrc: null,
    }
  },
  computed: {
    src: { get() { return this.attrs.src }, set(e) { this.updateAttrs({ src: e }) } },
    width() {
      return this.attrs.width
    },
    alignClass() {
      switch (this.attrs.align) {
        case 'left':
          return 'justify-content-start'
        case 'right':
          return 'justify-content-end'
        default:
          return 'justify-content-center'
      }
    },
    caption: {
      get() {
        return this.attrs.caption
      },
      set(v) {
        this.updateAttrs({ caption: v })
      },
    },
  },
  watch: {
    src: {
      handler(newVal, oldVal) {
        if (newVal !== oldVal && window.FB) {
          window.FB.init({
            xfbml: true,
            version: 'v16.0',
          })
        }
        if (!oldVal) {
          this.$nextTick(() => {
            this.selectNode()
          })
        }
      },
      immediate: true,
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (this.attrs.src) {
        this.initialSrc = this.attrs.src
      }
    })
  },
  methods: {
    initialize() {
      if (!this.attrs.src) {
        this.openModal()
      }
    },
    async openModal() {
      const url = await this.showPromptPopup({ title: this.$t('codex-editor.nodes.facebook-embed.facebook-url') })
      if (!url) {
        this.deleteBlock(this.attrs.blockId)
      } else if (url.length && url.match((/^(https?:\/\/)?((w{3}\.)?)facebook.com\/.*/i))) {
        this.updateAttrs({
          src: url,
        })
      } else {
        this.toastNotification({
          icon: 'XIcon',
          variant: 'danger',
          title: 'Error',
          text: this.$t('codex-editor.nodes.facebook-embed.facebook-invalid-url'),
        })
        this.deleteBlock(this.attrs.blockId)
      }
    },
    setSrc() {
      this.updateAttrs({
        src: this.initialSrc,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
  .embed-block {
    position: relative;
  }

  .embed-block__block {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0,0,0,0);
  }

  .facebook-embed__format-block {
    display:flex;
    flex-direction: column;
  }

  .facebook-embed__caption {
    width: 100%;
    padding: 5px 5px !important;
    font-size: 12px;
    color: gray;
    border: none;
    overflow: hidden !important;
  }

</style>
