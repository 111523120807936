export default class ConditionParameters {
  constructor({
    field = null,
    type = 1,
    operator = null,
    values = [],
  } = {}) {
    this.field = field
    this.operator = operator
    this.type = type
    this.values = values
  }
}
