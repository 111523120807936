<template>
  <div>
    <div>
      <b-form-group
        :label="$t('codex-editor.nodes.generic-iframe.url')"
      >
        <b-input v-model="url" />
      </b-form-group>

      <b-form-group
        :label="$t('codex-editor.nodes.generic-iframe.height')"
      >
        <b-input
          v-model="height"
          type="number"
          min="1"
        />
      </b-form-group>

      <b-form-group
        :label="$t('codex-editor.nodes.generic-iframe.scrolling')"
      >
        <v-select
          v-model="scrolling"
          :clearable="false"
          :options="['auto', 'no', 'yes']"
        />
      </b-form-group>

      <b-form-group
        :label="$t('codex-editor.nodes.generic-iframe.caption')"
      >
        <b-input v-model="caption" />
      </b-form-group>

    </div>
  </div>
</template>

<script>
import { generateComputedPropsFromAttrs } from '@/components/codex-layout-editor/BuilderUtils'

export default {
  name: 'GenericIframeSidebar',
  inject: ['toastNotification'],
  props: {
    attrs: {
      type: Object,
      default: () => ({}),
    },
    updateAttrs: {
      type: Function,
      default: () => { },
    },
    widget: {
      required: true,
      type: Object,
    },
  },
  computed: {
    ...generateComputedPropsFromAttrs([
      'src',
      'height',
      'scrolling',
      'caption',
    ]),
    url: {
      get() {
        return this.src
      },
      set(e) {
        if (e) {
          this.updateAttrs({ src: e })
        } else {
          this.toastNotification({
            icon: 'XIcon',
            variant: 'danger',
            title: 'Error',
            text: this.$t('codex-editor.nodes.generic-iframe.generic-iframe-invalid-url'),
          })
        }
      },
    },
  },
}
</script>

<style scoped>

</style>
