import { FIELD_GROUPS } from '@/codex-sdk/models'
import {
  VALUE_TYPES, TYPES, RANGE_OPERATORS, FIELD_FILTER_OPERATORS,
} from '@/views/models/constants'
import Initial from './Initial.vue'
import { registerField } from '../../codex-layout-editor/BuilderUtils'
import Render from './Render.vue'

import General from './General.vue'
import Appearance from './Appearance.vue'
import Validation from './Validation.vue'
import Configuration from './Configuration.vue'

export const DATE_FORMAT = Object.freeze({
  DATE: 1,
  DATETIME: 5,
})

export const TIME_FORMAT = Object.freeze({
  AmPm: 1,
  H24: 2,
})

export const DEFAULT_OPTION = Object.freeze({
  NOW: 1,
  CUSTOM: 2,
})

registerField('codex_field', {
  displayName: 'Date and Time',
  name: 'date-and-time',
  render: Render,
  renderEditor: Render,
  renderEditorPanel: [
    {
      icon: 'Settings', label: 'fields.codex-field-date-and-time.tabs.general', component: General, isGeneral: true,
    },
    { icon: 'Brush', label: 'fields.codex-field-date-and-time.tabs.appearance', component: Appearance },
    { icon: 'Tasks', label: 'fields.codex-field-date-and-time.tabs.validations', component: Validation },
    { icon: 'SettingsPreferencesTools', label: 'fields.codex-field-date-and-time.tabs.configuration', component: Configuration },
  ],
  renderEditorInitialPanel: [
    { icon: 'Settings', label: 'fields.codex-field-date-and-time.tabs.initial', component: Initial },
  ],
  group: FIELD_GROUPS.BASIC,
  icon: 'field-date-and-time.png',

  type: TYPES.DATE_TIME,
  valueType: VALUE_TYPES.SINGLE,
  defaultValue: '',

  attrs: {
    defaultOption: {
      default: DEFAULT_OPTION.CUSTOM,
    },
    format: {
      default: DATE_FORMAT.DATE,
    },
    timeMode: {
      default: TIME_FORMAT.AmPm,
    },
    helpText: {
      default: '',
    },
    helpTextDisplay: {
      default: 'tooltip',
    },
    appearance: {
      default: 1,
    },
    validation: {
      default: {
        range: {
          isEnabled: false,
          rangeOperator: RANGE_OPERATORS.BETWEEN,
          min: new Date(),
          max: new Date(),
          exactly: new Date(),
          errorMessage: 'Check date range.',
        },
        required: {
          value: false,
          errorMessage: 'Date field is required.',
          conditionsEnabled: false,
          conditions: [
            {
              isSystem: false,
              field: '',
              operator: FIELD_FILTER_OPERATORS.EXISTS,
              value: '',
            },
          ],
        },
        readOnly: {
          value: false,
          conditionsEnabled: false,
          conditions: [
            {
              isSystem: false,
              field: '',
              operator: FIELD_FILTER_OPERATORS.EXISTS,
              value: '',
            },
          ],
        },
      },
    },
    configuration: {
      default: {
        searchable: true,
        filterable: true,
      },
    },
    hidden: {
      value: false,
      conditionsEnabled: false,
      conditions: [
        {
          isSystem: false,
          field: '',
          operator: FIELD_FILTER_OPERATORS.EXISTS,
          value: '',
        },
      ],
    },
  },
})
