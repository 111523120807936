<template>
  <div>
    <b-button-group>
      <b-input
        v-model="initialSrc"
        :placeholder="$t('codex-editor.nodes.facebook-embed.url')"
        @keyup.enter="setSrc"
      />
      <b-button
        v-b-tooltip.hover.html.top="getTooltipData('save')"
        variant="flat-dark"
        @click="setSrc"
      >
        <GjIcon
          name="Save"
          size="18"
        />
      </b-button>
    </b-button-group>
    <AlignBlockPanel
      :attrs="attrs"
      :update-attrs="updateAttrs"
    />
  </div>
</template>

<script>
import AlignBlockPanel from '@/components/codex-editor/panels/AlignBlockPanel.vue'

export default {
  components: { AlignBlockPanel },
  props: ['attrs', 'updateAttrs'],
  inject: ['toastNotification', 'getTooltipData'],
  data() {
    return {
      initialSrc: '',
    }
  },
  computed: {
    src: { get() { return this.attrs.src }, set(e) { this.updateAttrs({ src: e }) } },
    width: {
      get() {
        return this.attrs.width
      },
      set(v) {
        this.updateAttrs({ width: null })
        this.updateAttrs({ width: v })
      },
    },
  },
  watch: {
    attrs: {
      handler() {
        this.initialSrc = this.attrs.src
      },
      immediate: true,
    },
  },
  methods: {
    setSrc() {
      if (this.initialSrc.match((/^(https?:\/\/)?((w{3}\.)?)facebook.com\/.*/i))) {
        this.updateAttrs({ src: this.initialSrc })
      } else {
        this.toastNotification({
          icon: 'XIcon',
          variant: 'danger',
          title: 'Error',
          text: this.$t('codex-editor.nodes.facebook-embed.facebook-invalid-url'),
        })
      }
    },
  },
}
</script>
