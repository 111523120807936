<template>
  <b-sidebar
    id="ratios-popup"
    v-model="showPopup"
    :title="index >= 0 ? $t('popups.ratios.edit-ratio') : $t('popups.ratios.create-ratio')"
    class="c-sidebar c-sidebar--md"
    :no-close-on-route-change="true"
    right
    backdrop
    shadow
  >
    <div class="ratios-popup">
      <div class="ratios-popup__header">
        <p class="ratios-popup__title">
          {{ $t('popups.ratios.title') }}
        </p>
        <p class="ratios-popup__subtitle">
          {{ $t('popups.ratios.subtitle') }}
        </p>
      </div>
      <div class="ratios-popup__form">
        <b-form-group
          class="ratios-popup__name"
          :label="$t('popups.ratios.forms.name.label')"
        >
          <b-form-input
            v-model="ratio.name"
            :state="validateName"
            placeholder="Ratio name"
          />
          <b-form-invalid-feedback :state="validateName">
            {{ $t('popups.ratios.forms.validations.name') }}
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group
          :label="$t('popups.ratios.forms.ratio.label')"
        >
          <div class="ratios-popup__ratio">
            <b-form-input
              v-model="ratio.width"
              type="number"
              :min="0"
              :state="validateRatio"
              :placeholder="$t('popups.ratios.forms.ratio.width-placeholder')"
              @keydown="validateNumber($event, ratio.width)"
            />
            :
            <b-form-input
              v-model="ratio.height"
              type="number"
              :min="0"
              :state="validateRatio"
              :placeholder="$t('popups.ratios.forms.ratio.height-placeholder')"
              @keydown="validateNumber($event, ratio.height)"
            />
          </div>
          <b-form-invalid-feedback :state="validateRatio">
            {{ $t('popups.ratios.forms.validations.ratio') }}
          </b-form-invalid-feedback>
        </b-form-group>
      </div>
    </div>

    <template #footer>
      <b-button
        variant="outline-secondary"
        @click="handleCancel"
      >
        {{ $t('general.cancel') }}
      </b-button>
      <b-button
        variant="primary"
        :disabled="validatedForm"
        @click="handleOk"
      >
        {{ index >= 0 ? $t('general.save') : $t('general.create') }}
      </b-button>
    </template>
  </b-sidebar>
</template>

<script>
import PopupsMixin from '@/components/popups/PopupsMixin'
import { validateNumber } from '@/views/models/constants'
import { cloneDeep } from 'lodash'

export default {
  name: 'RatiosPopup',
  mixins: [PopupsMixin],
  data() {
    return {
      validateNumber,
      ratio: this.data.idx >= 0 ? cloneDeep(this.data.allRatios[this.data.idx]) : {},
      allRatios: cloneDeep(this.data.allRatios),
    }
  },
  computed: {
    index() {
      return this.data.idx
    },
    validateName() {
      if (!this.ratio.name) return null
      const exist = this.allRatios.find((r, i) => r.name === this.ratio.name && i !== this.data.idx)
      return exist ? false : null
    },
    validateRatio() {
      const exist = this.allRatios.find((r, i) => Number(r.width) === Number(this.ratio.width) && Number(r.height) === Number(this.ratio.height) && i !== this.data.idx)
      return exist ? false : null
    },
    validatedForm() {
      if (this.validateName === false || !this.ratio.name) return true
      return this.validateRatio === false || !this.ratio.width || !this.ratio.height
    },
  },
  methods: {
    handleCancel() {
      this.closePopup(false)
    },
    handleOk() {
      this.closePopup(this.ratio)
    },
  },
}
</script>

<style lang="scss">
.ratios-popup__ratio {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
}
.ratios-popup__title {
  color: #052D61;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}
.ratios-popup__subtitle {
  color: #667C99;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
}
.ratios-popup__header {
  margin-bottom: 24px;
  p {
    margin: 0;
  }
}
</style>
