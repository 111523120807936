<template>
  <b-sidebar
    id="entry-editor-popup"
    v-model="showThePopup"
    cancel-variant="outline-dark"
    right
    no-close-on-backdrop
    :no-close-on-route-change="true"
    backdrop
    class="entry-editor-popup c-sidebar c-sidebar--xl"
  >
    <template #header>
      <div class="entry-editor__header">
        <div class="entry-editor__title">
          {{ title }}
        </div>
        <div class="entry-editor__close">
          <AutoSave />
          <GjIcon
            name="Moreoptions"
            size="22"
            class="mobile-only"
            @click.native="showSidebar"
          />
          <GjIcon
            name="Close"
            size="22"
            @click.native="closeEditor"
          />
        </div>
      </div>

    </template>
    <Edit
      ref="entryEdit"
      :is-popup="true"
      :e-id="id"
      :m-id="model"
      :site-id="siteId"
      :filter-values="filterValues"
      style="height: 100%;"
      @deleted="deleted"
      @updated="updated"
      @created="created"
      @close="closeEditor"
    />
  </b-sidebar>
</template>

<script>
import PopupsMixin from '@/components/popups/PopupsMixin'
import Edit from '@/views/entries/Edit.vue'
import AutoSave from '@/components/auto-save/AutoSave.vue'
import { mapMutations } from 'vuex'
import gql from 'graphql-tag'

export default {
  name: 'EntryEditorPopup',
  components: { AutoSave, Edit },
  mixins: [PopupsMixin],
  data() {
    return {
      action: null,
      entry: {
        id: this.data?.id,
        model: this.data?.model,
      },
    }
  },
  computed: {
    filterValues() {
      return this.data?.filterValues
    },
    model() {
      return this.data?.model
    },
    id() {
      return this.data?.id
    },
    siteId() {
      return this.data?.siteId || this.$store.state.general.currentSite.id
    },
    title() {
      return this.entry?.id == 'create'
        ? this.$t('popups.entry-editor-popup.create-entry')
        : this.$t('popups.entry-editor-popup.edit-entry')
    },
    showThePopup: {
      get() {
        return this.showPopup
      },
      set(value) {
        if (!value) {
          this.closeEditor()
        }
        this.SET_SHOW(value)
      },
    },
  },
  destroyed() {
    this.APPLY_STATE()
  },
  mounted() {
    this.STASH_STATE()
  },
  methods: {
    showSidebar() {
      this.$refs.entryEdit.showSidebar = true
    },
    ...mapMutations('autoSave', [
      'SET_SHOW',
      'APPLY_STATE',
      'STASH_STATE',
    ]),
    created(entry) {
      this.action = 'created'
      this.entry = entry
    },
    updated(entry) {
      this.action = 'updated'
      this.entry = entry
    },
    deleted() {
      this.action = 'deleted'
      this.closeEditor()
    },
    async closeEditor() {
      try {
        if (this.entry.system) {
          const { data } = await this.$apollo.query({
            query: gql`
            query ($id: String!) {
              model(id: $id) {
                id
                alias
                createdAt
                updatedAt
                iconId
                name
                description
                status
              }
            }
          `,
            variables: {
              id: this.model,
            },
            fetchPolicy: 'network-only',
          })
          this.entry.system.model = data.model
        }
      } catch (e) {
        console.log(e)
      }

      this.SET_SHOW(false)
      this.closePopup({
        action: this.action,
        entry: this.entry,
      })
    },
  },
}
</script>

<style lang="scss">
#entry-editor-popup {
  width: 85%;

  .b-sidebar-header {
    background: #fff;
  }
  .b-sidebar-body {
    background: #F6F7F9;
  }
  .entry-edit__main-inner {
    margin-bottom: 0 !important;
  }
  .entry-edit__sidebar-inner {
    top: 50px;
  }
  .entry-edit__main--focus {
    top: 48px;
  }
  .entry-edit__main--vertical-tabs {
    overflow-y: scroll;
  }

  .entry-editor__header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .entry-editor__close {
    gap: 8px;
    display: flex;
    align-items: center;
  }
}
</style>
