<template>
  <svg
    v-if="name === 'FixSpellingAndGrammar'"
    :width="size"
    :height="size"
    viewBox="0 0 18 18"
    class="ai-icons-fill"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.75 2.125C2.58424 2.125 2.42527 2.19085 2.30806 2.30806C2.19085 2.42527 2.125 2.58424 2.125 2.75V15.25C2.125 15.4158 2.19085 15.5747 2.30806 15.6919C2.42527 15.8092 2.58424 15.875 2.75 15.875H15.25C15.4158 15.875 15.5747 15.8092 15.6919 15.6919C15.8092 15.5747 15.875 15.4158 15.875 15.25V7.33333C15.875 6.98816 16.1548 6.70833 16.5 6.70833C16.8452 6.70833 17.125 6.98816 17.125 7.33333V15.25C17.125 15.7473 16.9275 16.2242 16.5758 16.5758C16.2242 16.9275 15.7473 17.125 15.25 17.125H2.75C2.25272 17.125 1.7758 16.9275 1.42417 16.5758C1.07254 16.2242 0.875 15.7473 0.875 15.25V2.75C0.875 2.25272 1.07254 1.77581 1.42417 1.42417C1.77581 1.07254 2.25272 0.875 2.75 0.875H11.5C11.8452 0.875 12.125 1.15482 12.125 1.5C12.125 1.84518 11.8452 2.125 11.5 2.125H2.75Z"
      fill="#767685"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16.4466 1.40808C16.7275 1.60871 16.7925 1.99906 16.5919 2.27994L10.3419 11.0299C10.2421 11.1696 10.0893 11.2621 9.91925 11.2857C9.74921 11.3093 9.57695 11.2619 9.4429 11.1547L5.27623 7.82138C5.00669 7.60574 4.96299 7.21244 5.17862 6.9429C5.39425 6.67336 5.78756 6.62966 6.0571 6.84529L9.70838 9.76631L15.5747 1.55339C15.7754 1.27251 16.1657 1.20745 16.4466 1.40808Z"
      fill="#767685"
    />
  </svg>
  <svg
    v-else-if="name === 'ContinueWriting'"
    :width="size"
    :height="size"
    viewBox="0 0 20 20"
    class="ai-icons-fill"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M15.4424 8.7746L16.7634 7.45362C17.6584 6.55867 17.6584 5.10766 16.7634 4.21271L15.7871 3.2364C14.8922 2.34145 13.4412 2.34145 12.5462 3.2364L2.54621 13.2364C2.11644 13.6662 1.875 14.2491 1.875 14.8569V17.0832C1.875 17.6585 2.34137 18.1248 2.91667 18.1248H17.5C17.8452 18.1248 18.125 17.845 18.125 17.4998C18.125 17.1547 17.8452 16.8748 17.5 16.8748H7.34222L15.4414 8.77561C15.4416 8.77544 15.4423 8.77477 15.4424 8.7746C15.4426 8.77443 15.4423 8.77477 15.4424 8.7746ZM14.9032 4.12028C14.4964 3.71349 13.8369 3.71349 13.4301 4.12028L12.5506 4.99983L15 7.44928L15.8795 6.56973C16.2863 6.16294 16.2863 5.50339 15.8795 5.09659L14.9032 4.12028ZM14.1161 8.33316L11.6667 5.88371L3.4301 14.1203C3.23475 14.3156 3.125 14.5806 3.125 14.8569V16.8748H5.14298C5.41925 16.8748 5.6842 16.7651 5.87955 16.5697L14.1161 8.33316Z"
      fill="#767685"
    />
  </svg>
  <svg
    v-else-if="name === 'MakeShorter'"
    :width="size"
    :height="size"
    viewBox="0 0 20 20"
    class="ai-icons-stroke"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.5 7H17.5"
      stroke="#767685"
      stroke-width="1.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M2.5 12H9.16667"
      stroke="#767685"
      stroke-width="1.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
  <svg
    v-else-if="name === 'MakeLonger'"
    :width="size"
    :height="size"
    viewBox="0 0 20 20"
    class="ai-icons-stroke"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.5 2.49976H17.5"
      stroke="#767685"
      stroke-width="1.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M2.5 7.49976H9.16667"
      stroke="#767685"
      stroke-width="1.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M2.5 12.4998H17.5"
      stroke="#767685"
      stroke-width="1.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M2.5 17.4998H9.16667"
      stroke="#767685"
      stroke-width="1.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
  <svg
    v-else-if="name === 'Summarize'"
    :width="size"
    :height="size"
    viewBox="0 0 20 20"
    class="ai-icons-stroke"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.16406 5H17.4974"
      stroke="#767685"
      stroke-width="1.25"
      stroke-linecap="round"
    />
    <path
      d="M9.16406 10H17.4974"
      stroke="#767685"
      stroke-width="1.25"
      stroke-linecap="round"
    />
    <path
      d="M9.16406 15H17.4974"
      stroke="#767685"
      stroke-width="1.25"
      stroke-linecap="round"
    />
    <path
      d="M2.5 6.16064C2.5 6.16064 3.33333 6.7038 3.75 7.49992C3.75 7.49992 5 4.37492 6.66667 3.33325"
      stroke="#767685"
      stroke-width="1.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M2.5 15.3272C2.5 15.3272 3.33333 15.8703 3.75 16.6664C3.75 16.6664 5 13.5414 6.66667 12.4998"
      stroke="#767685"
      stroke-width="1.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
  <svg
    v-else-if="name === 'ImproveWriting'"
    :width="size"
    :height="size"
    viewBox="0 0 20 20"
    fill="none"
    class="ai-icons-stroke"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.5 12.4998L13.75 13.7498"
      stroke="#767685"
      stroke-width="1.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M14.1159 15.8825C13.628 15.3947 13.628 14.6036 14.1159 14.1157C14.6039 13.6278 15.3949 13.6278 15.8828 14.1157L17.5508 15.7837C18.0387 16.2715 18.0387 17.0626 17.5508 17.5505C17.0629 18.0384 16.2718 18.0384 15.7839 17.5505L14.1159 15.8825Z"
      stroke="#767685"
      stroke-width="1.25"
      stroke-linecap="round"
    />
    <path
      d="M4.49304 2.98104L6.81666 3.76422C7.13792 3.87543 7.62544 3.80271 7.90149 3.60605L9.53153 2.44355C10.5744 1.70061 11.4376 2.14371 11.4385 3.4316L11.4474 5.61181C11.4489 5.98104 11.7058 6.43923 12.0186 6.63831L13.6841 7.6825C15.0015 8.50992 14.8517 9.4895 13.3503 9.87009L11.2606 10.3978C10.883 10.4929 10.4935 10.8825 10.394 11.2645L9.86645 13.3546C9.49028 14.8519 8.50212 15.0017 7.67929 13.6884L6.63529 12.0226C6.43624 11.7098 5.97814 11.4529 5.60898 11.4514L3.42918 11.4424C2.14591 11.4371 1.69851 10.5782 2.44132 9.53509L3.6036 7.90474C3.79584 7.63302 3.86855 7.14541 3.75736 6.82409L2.97433 4.50004C2.55164 3.23679 3.2344 2.55389 4.49304 2.98104Z"
      stroke="#767685"
      stroke-width="1.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
  <svg
    v-else-if="name === 'MakeItProfessional'"
    :width="size"
    :height="size"
    viewBox="0 0 20 20"
    class="ai-icons-fill"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10 2.5C8.61929 2.5 7.5 3.61929 7.5 5V10C7.5 11.3807 8.61929 12.5 10 12.5C11.3807 12.5 12.5 11.3807 12.5 10V5C12.5 3.61929 11.3807 2.5 10 2.5ZM11.25 10V5C11.25 4.30964 10.6904 3.75 10 3.75C9.30964 3.75 8.75 4.30964 8.75 5V10C8.75 10.6904 9.30964 11.25 10 11.25C10.6904 11.25 11.25 10.6904 11.25 10Z"
      fill="#767685"
    />
    <path
      d="M10 14.375C7.58375 14.375 5.625 12.4162 5.625 10C5.625 9.65482 5.34518 9.375 5 9.375C4.65482 9.375 4.375 9.65482 4.375 10C4.375 12.8953 6.56253 15.2798 9.375 15.5907V17.5C9.375 17.8452 9.65482 18.125 10 18.125C10.3452 18.125 10.625 17.8452 10.625 17.5V15.5907C13.4375 15.2798 15.625 12.8953 15.625 10C15.625 9.65482 15.3452 9.375 15 9.375C14.6548 9.375 14.375 9.65482 14.375 10C14.375 12.4162 12.4162 14.375 10 14.375Z"
      fill="#767685"
    />
  </svg>
  <svg
    v-else-if="name === 'ExplainThis'"
    :width="size"
    :height="size"
    viewBox="0 0 20 20"
    class="ai-icons-fill"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.8346 14.1667C10.8346 14.627 10.4615 15.0001 10.0013 15.0001C9.54106 15.0001 9.16797 14.627 9.16797 14.1667C9.16797 13.7065 9.54106 13.3334 10.0013 13.3334C10.4615 13.3334 10.8346 13.7065 10.8346 14.1667Z"
      fill="#767685"
    />
    <path
      d="M8.1263 7.50008C8.1263 6.46455 8.96577 5.62508 10.0013 5.62508C11.0368 5.62508 11.8763 6.46455 11.8763 7.50008C11.8763 8.42898 11.2002 9.20119 10.3133 9.34935C9.84925 9.42685 9.3763 9.82035 9.3763 10.4167V11.6667C9.3763 12.0119 9.65612 12.2917 10.0013 12.2917C10.3465 12.2917 10.6263 12.0119 10.6263 11.6667V10.5624C12.0528 10.2726 13.1263 9.01228 13.1263 7.50008C13.1263 5.77419 11.7272 4.37508 10.0013 4.37508C8.27541 4.37508 6.8763 5.77419 6.8763 7.50008C6.8763 7.84526 7.15612 8.12508 7.5013 8.12508C7.84648 8.12508 8.1263 7.84526 8.1263 7.50008Z"
      fill="#767685"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.0013 18.3334C14.6037 18.3334 18.3346 14.6025 18.3346 10.0001C18.3346 5.39771 14.6037 1.66675 10.0013 1.66675C5.39893 1.66675 1.66797 5.39771 1.66797 10.0001C1.66797 14.6025 5.39893 18.3334 10.0013 18.3334ZM10.0013 17.0834C13.9133 17.0834 17.0846 13.9121 17.0846 10.0001C17.0846 6.08806 13.9133 2.91675 10.0013 2.91675C6.08929 2.91675 2.91797 6.08806 2.91797 10.0001C2.91797 13.9121 6.08929 17.0834 10.0013 17.0834Z"
      fill="#767685"
    />
  </svg>
  <svg
    v-else-if="name === 'Translate'"
    :width="size"
    :height="size"
    viewBox="0 0 20 20"
    class="ai-icons-fill"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.54167 3.33325C8.54167 2.98807 8.26185 2.70825 7.91667 2.70825C7.57149 2.70825 7.29167 2.98807 7.29167 3.33325V4.37492H2.5C2.15482 4.37492 1.875 4.65474 1.875 4.99992C1.875 5.3451 2.15482 5.62492 2.5 5.62492H10.064C9.7992 6.51585 9.16335 7.89821 7.91113 9.5334C7.24587 8.64905 6.69235 7.75907 6.37969 7.1963C6.21206 6.89456 5.83156 6.78585 5.52982 6.95348C5.22808 7.12111 5.11936 7.50162 5.287 7.80336C5.64771 8.45264 6.28954 9.47912 7.05704 10.4751L7.09747 10.5274C6.2281 11.5244 5.13681 12.5901 3.77626 13.6786C3.50672 13.8942 3.46302 14.2875 3.67865 14.5571C3.89428 14.8266 4.28759 14.8703 4.55712 14.6547C5.90196 13.5788 7.00687 12.5133 7.90763 11.4982C8.46407 12.1129 9.06884 12.67 9.67845 13.0358C9.97444 13.2134 10.3584 13.1174 10.5359 12.8214C10.7135 12.5254 10.6176 12.1415 10.3216 11.9639C9.80955 11.6567 9.25755 11.141 8.71906 10.53C10.3172 8.50578 11.0909 6.75262 11.3572 5.62492H13.3333C13.6785 5.62492 13.9583 5.3451 13.9583 4.99992C13.9583 4.65474 13.6785 4.37492 13.3333 4.37492H8.54167V3.33325Z"
      fill="#767685"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M15.9826 14.7916L16.9288 16.9205C17.069 17.2359 17.4384 17.378 17.7538 17.2378C18.0692 17.0976 18.2113 16.7283 18.0711 16.4128L15.1185 9.76953C14.7519 8.94471 13.5813 8.94472 13.2147 9.76953L10.2622 16.4128C10.122 16.7283 10.264 17.0976 10.5794 17.2378C10.8949 17.378 11.2642 17.2359 11.4044 16.9205L12.3506 14.7916H15.9826ZM15.4271 13.5416L14.1666 10.7055L12.9062 13.5416H15.4271Z"
      fill="#767685"
    />
  </svg>
</template>

<script>

export default {
  name: 'AiIcons',
  props: {
    size: {
      type: String,
      default: '24',
    },
    name: {
      type: String,
      default: '',
    },
  },
}
</script>

<style>
.ai-icons-fill * {
  fill: currentColor;
}
.ai-icons-stroke * {
  stroke: currentColor;
}
</style>
