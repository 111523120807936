import { getUserData } from '@/auth/utils'
import ability from '@/libs/acl/ability'
import { pr } from './config'

// For regEx search in VSCode: canViewSection|canCreateSection|canEditSection|canDeleteSection

// * Create entity file, and can functions
// * Import and add them to allCanFunctions object down below
// * Add defaults into the permissions array down below
// * Implement v-permission directives on neccessary buttons
// * Implement permissions on entities sdk functions
// * Implement permissions on navigation and route

export const Section = Object.freeze({
  VIEW: 'section_view',
  MANAGE: 'section_manage',
  CREATE: 'section_create',
})

export const SectionTooltips = {
  [Section.VIEW]: 'Allows a user to view all sections in ‘view only’ mode,  without performing any operations',
  [Section.CREATE]: 'Allows a user to create new sections, edit and delete own sections and view other sections in ‘view only’ mode',
  [Section.MANAGE]: 'Allows a user to perform all CRUD operations in the section level such as: create, view, edit/clone and delete.',
}

function isCurrentUser(entity) {
  const userId = entity?.createdBy?.id || entity?.createdBy

  const currentUserData = getUserData()
  if (!currentUserData) return false

  return userId == currentUserData.id
}

/**
 * _options
 *    entity = undefined
 *    showToast = false
 */

/**
 * Can VIEW Sections
 * */
export function canViewSections(_options = {}) {
  const { entity, ...options } = _options

  return pr(
    ability.can(Section.VIEW) || ability.can(Section.CREATE) || ability.can(Section.MANAGE),

    'You have no permission to view sections',

    options,
  )
}

/**
 * Can CREATE Section
 * */
export function canCreateSection(_options = {}) {
  const { entity, ...options } = _options

  return pr(
    ability.can(Section.CREATE) || ability.can(Section.MANAGE),

    'You have no permission to create section',

    options,
  )
}

/**
 * Can EDIT Section
 * */
export function canEditSection(_options = {}) {
  const { entity, ...options } = _options

  return pr(
    ability.can(Section.MANAGE)
    || (ability.can(Section.CREATE) && isCurrentUser(entity)),

    'You have no permission to edit section',

    options,
  )
}

/**
 * Can DELETE Section
 * */
export function canDeleteSection(_options = {}) {
  const { entity, ...options } = _options

  return pr(
    ability.can(Section.MANAGE)
    || (ability.can(Section.CREATE) && isCurrentUser(entity)),

    'You have no permission to delete this section',

    options,
  )
}
