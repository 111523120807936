<template>
  <div class="layout-editor__add-block">
    <div
      v-b-tooltip.hover
      class="layout-editor__add-block-button"
      :title="buttonTitle"
      @click="addBlock"
    >
      <GjIcon
        name="Plus"
        size="18"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: ['widget', 'addPosition'],
  inject: ['editor'],
  computed: {
    buttonTitle() {
      return this.widget.type == 'codex_column' ? 'Add Column' : 'Add Row'
    },
  },
  methods: {
    addBlock() {
      let blockToAdd

      if (this.widget.type == 'codex_column') {
        const parent = this.editor.findParentBlockById(this.widget.id)
        const responsive = { desktop: { columns: 2 }, tablet: { columns: 2 }, mobile: { columns: 12 } }

        Object.keys(responsive).forEach(breakpoint => {
          // Sort columns for the given breakpoint
          const sortedColumns = [...parent.content].sort((ai, bi) => {
            const a = ai.attrs._responsive[breakpoint].order
            const b = bi.attrs._responsive[breakpoint].order
            // eslint-disable-next-line no-nested-ternary
            return a == b ? 0 : (a > b ? 1 : -1)
          })

          // Find the position
          let position = null
          sortedColumns.forEach((block, idx) => {
            if (block.id == this.widget.id) {
              position = this.addPosition == 'before' ? idx : idx + 1
            }
          })

          // Update the order of exising columns
          sortedColumns.splice(position, 0, 'new')
          sortedColumns.forEach((block, idx) => {
            if (block !== 'new') {
              this.editor.updateBlockAttributes(block.id, { order: idx }, breakpoint)
            }
          })

          // Set the order in given breakpoint
          responsive[breakpoint].order = position
        })

        blockToAdd = { type: 'codex_column', attrs: { _responsive: responsive } }
      } else {
        blockToAdd = { type: 'codex_row' }
      }

      if (this.addPosition == 'before') {
        this.editor.addBlockBefore(this.widget.id, blockToAdd)
      } else {
        this.editor.addBlockAfter(this.widget.id, blockToAdd)
      }
    },
  },
}
</script>

<style>

</style>
