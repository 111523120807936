<template>
  <div>
    <b-form-group
      :label="$t('fields.codex-field-boolean.general.field-name')"
      label-for="boolean-field__name"
    >
      <b-form-input
        id="boolean-field__name"
        v-model="name"
        :placeholder="$t('fields.codex-field-boolean.general.field-name-placeholder')"
      />
    </b-form-group>
    <b-form-group
      :label="$t('fields.codex-field-boolean.general.field-alias')"
      label-for="boolean-field__alias"
    >
      <b-form-input
        id="boolean-field__alias"
        :value="alias"
        disabled
      />
    </b-form-group>
    <b-form-group
      :label="$t('fields.codex-field-boolean.general.description')"
      label-for="boolean-field__description"
    >
      <b-form-textarea
        id="boolean-field__description"
        v-model="description"
        :placeholder="$t('fields.codex-field-boolean.general.description-placeholder')"
        rows="2"
        no-resize
      />
    </b-form-group>
  </div>
</template>

<script>
import { generateComputedPropsFromAttrs } from '@/components/codex-layout-editor/BuilderUtils'

export default {
  props: {
    updateAttrs: {
      type: Function,
      default: () => {},
    },
    widget: {
      required: true,
      type: Object,
    },
  },
  computed: {
    ...generateComputedPropsFromAttrs([
      'alias',
      'name',
      'description',
    ]),
  },
}
</script>
