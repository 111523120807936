<template>
  <section>
    <div class="empty-field__wrapper">
      <div class="empty-field__img">
        <svg
          width="59"
          height="59"
          viewBox="0 0 59 59"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M29.69 58.9994C29.6267 58.9998 29.5634 59 29.5 59C29.4366 59 29.3733 58.9998 29.31 58.9994H29.69ZM47.1998 53.1023V20.8461C46.3732 20.8479 45.5544 20.6857 44.7908 20.3689C44.0273 20.0522 43.3342 19.587 42.7516 19.0005C42.1653 18.418 41.7003 17.7249 41.3836 16.9614C41.0669 16.198 40.9047 15.3793 40.9065 14.5527H18.0931C18.0913 16.2213 17.4276 17.8209 16.2478 19.0007C15.068 20.1806 13.4683 20.8442 11.7998 20.8461V53.102C4.63468 47.72 0 39.1512 0 29.5C0 13.2076 13.2076 0 29.5 0C45.7924 0 59 13.2076 59 29.5C59 39.1514 54.3652 47.7203 47.1998 53.1023Z"
            fill="url(#paint0_linear_22942_235975)"
          />
          <path
            d="M29.5001 40.1202C34.7136 40.1202 38.9401 35.8938 38.9401 30.6802C38.9401 25.4667 34.7136 21.2402 29.5001 21.2402C24.2865 21.2402 20.0601 25.4667 20.0601 30.6802C20.0601 35.8938 24.2865 40.1202 29.5001 40.1202Z"
            fill="url(#paint1_linear_22942_235975)"
          />
          <path
            d="M32.8375 35.1302L29.5 31.7927L26.1625 35.1302L25.0499 34.0177L28.3875 30.6802L25.0499 27.3426L26.1625 26.2301L29.5 29.5677L32.8375 26.2301L33.9501 27.3426L30.6125 30.6802L33.9501 34.0177L32.8375 35.1302Z"
            fill="white"
          />
          <path
            d="M34.6132 42.4805H24.3865C23.7348 42.4805 23.2065 43.0088 23.2065 43.6605C23.2065 44.3122 23.7348 44.8405 24.3865 44.8405H34.6132C35.2649 44.8405 35.7932 44.3122 35.7932 43.6605C35.7932 43.0088 35.2649 42.4805 34.6132 42.4805Z"
            fill="#DFEAFB"
          />
          <path
            d="M38.1532 47.1992H20.8465C20.1948 47.1992 19.6665 47.7275 19.6665 48.3792C19.6665 49.0309 20.1948 49.5592 20.8465 49.5592H38.1532C38.8049 49.5592 39.3332 49.0309 39.3332 48.3792C39.3332 47.7275 38.8049 47.1992 38.1532 47.1992Z"
            fill="#DFEAFB"
          />
          <defs>
            <linearGradient
              id="paint0_linear_22942_235975"
              x1="29.5"
              y1="0"
              x2="29.5"
              y2="59"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#D9D9D9" />
              <stop
                offset="0.0001"
                stop-color="#F3F2F4"
              />
              <stop
                offset="1"
                stop-color="#EBDDFD"
              />
            </linearGradient>
            <linearGradient
              id="paint1_linear_22942_235975"
              x1="29.5001"
              y1="21.2402"
              x2="29.5001"
              y2="40.1202"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#ABBBD0" />
              <stop
                offset="1"
                stop-color="#5EA3FF"
              />
            </linearGradient>
          </defs>
        </svg>
      </div>
      <p>{{ $t('entries.merge-tool.info.missing-field') }}</p>
    </div>
  </section>
</template>

<script>
export default {
  name: 'EmptyField',
  props: {
    fieldName: {
      type: [String],
    },
  },
}
</script>

<style lang="scss">
.empty-field__wrapper {
  background: #F7F8FC;
  border-radius: 4px;
  padding: 26px 20px;
  text-align: center;

  p {
    margin-top: 14px;
    font-style: italic;
    font-weight: 400;
    font-size: 14px;
    color: #667C99;
  }
}
</style>
