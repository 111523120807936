<template>
  <div
    class="new-filters-dropdown__filter-body"
    :class="{'new-filters-dropdown__filter-body--scroll': value.value && value.value.length > 3 && !isExistsOperator}"
  >
    <b-row>
      <b-col cols="12">
        <vSelect
          v-model="value.operator"
          :options="options"
          :clearable="false"
          :reduce="option => option.value"
          :searchable="false"
          label="label"
        />
      </b-col>
    </b-row>
    <b-row
      v-if="value.value && value.value.length && !isExistsOperator"
      align-v="center"
      no-gutters
    >
      <b-col cols="12">
        <div
          v-for="objValue in objectValues"
          :key="objValue.id"
          class="new-filters-dropdown__media-item"
        >
          <b-avatar
            v-if="objValue.system.featuredMedia"
            :key="objValue.id"
            class="new-filters-dropdown__media-item-avatar"
            :src="objValue.system.featuredMedia.url"
          />
          <div
            v-else
            class="new-filters-dropdown__media-item-avatar"
          >
            <GjIcon
              :key="objValue.id"
              size="32"
              :name="getEntryModel(objValue).iconId"
            />
          </div>
          <h4 class="new-filters-dropdown__media-item-title">
            <span
              v-if="!objValue.system.title"
              style="opacity:0.5"
            >{{ $t('entries.untitled') }}</span>
            {{ objValue.system.title }}
          </h4>
          <div
            class="new-filters-dropdown__media-item-remove"
            @click="removeEntry(objValue.id)"
          >
            <GjIcon
              name="Close"
              size="20"
            />
          </div>
        </div>
      </b-col>
    </b-row>
    <b-row
      v-if="!isExistsOperator"
      align-v="center"
      no-gutters
    >
      <b-button
        size="sm"
        variant="primary"
        block
        @click="addEntries"
      >
        {{ $t('filters.references.add') }}
      </b-button>
    </b-row>
  </div>
</template>

<script>
import { getEntryModel } from '@/utils/helpers'
import ReferenceFilter from './ReferenceFilter'

export default {
  inject: ['showEntriesPopup'],
  props: {
    filter: {
      type: Object,
      required: true,
    },
    filterObject: {
      type: [Object, Function],
      required: true,
    },
    value: {
      type: Object,
      required: true,
    },
    cache: {
      type: Object,
      required: true,
    },
    setCache: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      getEntryModel,
    }
  },
  computed: {
    options() {
      return [
        {
          label: this.$t('filters.include'),
          value: ReferenceFilter.OPERATORS.include,
        },
        {
          label: this.$t('filters.exclude'),
          value: ReferenceFilter.OPERATORS.exclude,
        },
        {
          label: this.$t('filters.is-not-empty'),
          value: ReferenceFilter.OPERATORS.exists,
        },
        {
          label: this.$t('filters.is-empty'),
          value: ReferenceFilter.OPERATORS.notExists,
        },
      ]
    },

    objectValues() {
      if (!this.value.value) return []

      return this.value.value.map(entryId => this.cache[entryId] || { id: entryId })
    },
    isExistsOperator() {
      return this.value.operator === ReferenceFilter.OPERATORS.exists || this.value.operator === ReferenceFilter.OPERATORS.notExists
    },
  },
  methods: {
    removeEntry(id) {
      this.value.value = this.value.value.filter(entryId => entryId !== id)
    },
    async addEntries() {
      const models = this.filter?.validations?.modelTypes?.isEnabled ? this.filter?.validations?.modelTypes?.acceptedModels : null
      const entries = await this.showEntriesPopup({ excludeIds: this.value.value, models })
      if (entries) {
        entries.forEach(entry => {
          this.setCache(entry)
          this.value.value.push(entry.id)
        })
      }
    },
  },
}
</script>

<style>

</style>
