<template>
  <div
    ref="containerWrapper"
    class="containerWrapper"
    style="height: 100%; overflow-y: auto;"
    @scroll="loadMore"
  >
    <div ref="container">
      <vue-good-table
        v-if="selectedData.show && !filtered"
        :columns="tableColumns"
        :rows="selectedAssets"
        :select-options="{
          enabled: true,
          disableSelectInfo: true
        }"
        class="media-list-view"
        @on-row-click="selectionChanged"
        @on-row-dblclick="maybeOpenAsset"
      >
        <template
          slot="table-row"
          slot-scope="props"
        >
          <draggable
            :key="props.row.id + props.index"
            :list="selectedAssets"
            group="assets-group-index"
            @change="order(props, $event)"
          >
            <slot v-bind="{props}" />
          </draggable>
        </template>
      </vue-good-table>
      <vue-good-table
        v-else
        :columns="tableColumns"
        :rows="items"
        :select-options="{
          enabled: true,
          disableSelectInfo: true
        }"
        class="media-list-view"
        @on-row-click="selectionChanged"
        @on-row-dblclick="maybeOpenAsset"
      >
        <template
          slot="table-row"
          slot-scope="props"
        >
          <slot v-bind="{props}" />
        </template>
      </vue-good-table>
    </div>
  </div>
</template>

<script>
import { VueGoodTable } from 'vue-good-table'
import draggable from 'vuedraggable'
import { mapMutations, mapState } from 'vuex'
import moment from 'moment'
import hotkeys from 'hotkeys-js'

hotkeys.filter = function () {
  return true
}

export default {
  name: 'MediaListView',
  components: {
    VueGoodTable,
    draggable,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
    total: {
      type: Number,
      default: 0,
    },
    tableColumns: {
      type: Array,
      default: () => [],
    },
    isPopup: {
      type: Boolean,
      default: false,
    },
    filtered: {
      type: Boolean,
      default: false,
    },
    folderId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      lastClickedAsset: null,
      move: {
        item: null,
        from: null,
        to: null,
      },
    }
  },
  computed: {
    ...mapState('assets', [
      'selectedData',
    ]),
    selectedAssets: {
      get() {
        return this.selectedData.items
      },
      set(v) {
        if (v.length <= this.selectedData.limit) {
          let totalSize = 0
          v.forEach(a => { totalSize += a.size })
          const addedItem = v.length > this.selectedData.items.length

          this.SET_SELECTED_DATA({
            ...this.selectedData,
            totalSize: totalSize || 0,
            items: v,
            total: v.length,
            lastSelection: addedItem ? v[v.length - 1] : this.lastClickedAsset,
          })
        } else if (this.selectedData.limit == 1 && v.length == 2) {
          const array = [v[1]]
          let totalSize = 0
          array.forEach(a => { totalSize += a.size })
          this.SET_SELECTED_DATA({
            ...this.selectedData,
            totalSize: totalSize || 0,
            items: array,
            total: array.length,
            lastSelection: v[1],
          })
        }
        this.$nextTick(() => {
          this.refreshSelected()
        })
      },
    },
  },
  watch: {
    items() {
      this.refreshSelected()
    },
    selectedData: {
      handler(v) {
        if (v?.total == 0) {
          this.refreshSelected()
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.refreshSelected()

    hotkeys('ctrl+a, command+a', event => {
      // if target is input, textarea or select, do not select all
      const target = event?.target?.tagName?.toLowerCase()
      if (target === 'input' || target === 'textarea' || target === 'select') {
        return
      }

      event.preventDefault()

      this.selectedAssets = this.folderId ? this.selectedAssets.filter(x => x.folderId !== this.folderId) : []
      this.selectedAssets = [...this.selectedAssets, ...this.items.filter(x => {
        const isUploading = x.uploadStatus === 'uploading'

        if (this.isPopup) {
          const isAfter = moment().isAfter(x.expiresAt)
          return !isUploading && !isAfter
        }

        return !isUploading
      })]
    })
  },
  beforeDestroy() {
    hotkeys.unbind('ctrl+a, command+a')
  },
  methods: {
    ...mapMutations('assets', [
      'SET_SELECTED_DATA',
      'SET_ASSET_ID',
    ]),
    order(props, $event) {
      const keys = Object.keys($event)
      if (keys.includes('removed')) {
        this.move.from = props.index
        this.move.item = props.row
      }
      if (keys.includes('added')) {
        this.move.to = props.index
      }
      if (this.move.item && this.move.from >= 0 && this.move.to >= 0) {
        this.selectedAssets.splice(this.move.from, 1)
        this.selectedAssets.splice(this.move.to, 0, this.move.item)
        this.move.item = null
        this.move.from = null
        this.move.to = null
        this.$emit('setCustomSort')
      }
    },
    loadMore() {
      const containerWrapper = this.$refs.containerWrapper.getBoundingClientRect().bottom
      const container = this.$refs.container.getBoundingClientRect().bottom - 1000
      if (containerWrapper > container && this.items.length < this.total) {
        this.$emit('loadMore')
      }
    },
    refreshSelected() {
      this.items.forEach(a => {
        const newSelected = this.selectedAssets.some(i => i.id === a.id)
        if (newSelected !== a.vgtSelected) {
          this.$set(a, 'vgtSelected', newSelected)
        }
      })
    },
    isAfter(asset) {
      return moment().isAfter(asset.expiresAt)
    },
    selectionChanged({ row, event: e }) {
      const asset = row
      this.lastClickedAsset = asset

      if (this.isPopup && this.isAfter(row)) {
        row.vgtSelected = false
        return
      }

      const exists = this.selectedAssets.some(img => img.id === asset.id)

      if ((e.ctrlKey || e.metaKey) || this.selectedData.show) {
        // if ctrl / command key is pressed
        // add/remove asset to/from selected assets

        if (exists) {
          this.selectedAssets = this.selectedAssets.filter(img => img.id !== asset.id)
        } else if (asset.uploadStatus !== 'uploading') {
          this.selectedAssets = [...this.selectedAssets, asset]
        }
      } else if (e.shiftKey && this.selectedData.lastSelection && (this.selectedData.lastSelection.folderId === this.folderId || !this.folderId)) {
        // if shift key is pressed and last selection exists
        // select all assets between first and last selection

        const firstIndex = this.items.findIndex(img => img.id === asset.id)
        const lastIndex = this.items.findIndex(img => img.id === this.selectedData.lastSelection.id)
        const start = Math.min(firstIndex, lastIndex)
        const end = Math.max(firstIndex, lastIndex)
        const array = this.items.slice(start, end + 1)

        this.selectedAssets = [
          ...this.selectedAssets,
          ...array.filter(x => {
            const alreadySelected = this.selectedAssets.some(img => img.id === x.id)
            const isUploading = x.uploadStatus === 'uploading'

            if (this.isPopup) {
              const isAfter = moment().isAfter(x.expiresAt)
              return !alreadySelected && !isUploading && !isAfter
            }

            return !alreadySelected && !isUploading
          }),
        ]
      } else {
        // if no meta key is pressed
        // select only this asset

        const otherFolderAssets = this.folderId ? this.selectedData.items.filter(x => x.folderId !== this.folderId) : []

        this.selectedAssets = asset.uploadStatus !== 'uploading' ? [...otherFolderAssets, asset] : [...otherFolderAssets]
      }
    },
    maybeOpenAsset({ row }) {
      if (this.isPopup) {
        if (!this.isAfter(row)) {
          this.selectedAssets = [row]
          this.$root.$emit('closeAssetsPopup')
        }
      } else {
        this.SET_ASSET_ID(row.id)
      }
    },
    scrollToTop() {
      this.$refs.containerWrapper.scrollTo({ top: 0, behavior: 'smooth' })
    },
  },
}
</script>

<style lang="scss">
// Per table
.file-column {
  display: flex;
}
.file-column-name-details {
  width: 100%;
    margin-left: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    h6 {
      font-size: 14px;
    }
    p {
      font-size: 12px;
    }
}
.img-title{
  width: 100%;
  max-width: 500px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.more-options-label {
  visibility: hidden;
}

.media-list-view--color {
  color: #6e6b7b;
  height: calc(100% - 35px);
  overflow: hidden;
}

.media-list-view thead .vgt-checkbox-col input {
  display: none;
}

</style>
