import ability from '@/libs/acl/ability'
import { pr } from './config'

/**
 job_view
 job_create
 */
// For regEx search in VSCode: canViewJob|canCreateJob

// * Create entity file, and can functions
// * Import and add them to allCanFunctions object down below
// * Add defaults into the permissions array down below
// * Implement v-permission directives on necessary buttons
// * Implement permissions on entities sdk functions
// * Implement permissions on navigation and route

export const Job = Object.freeze({
  VIEW: 'job_view',
  CREATE: 'job_create',
})

export const JobTooltips = {
  [Job.VIEW]: '',
  [Job.VIEW]: '',
}

/**
 * _options
 *    entity = undefined
 *    showToast = false
 */

/**
 * Can VIEW Jobs
 * */
export function canViewJobs(_options = {}) {
  const { entity, ...options } = _options

  return pr(
    ability.can(Job.VIEW) || ability.can(Job.CREATE),

    'You have no permission to view jobs',

    options,
  )
}

/**
 * Can CREATE Job
 * */
export function canCreateJob(_options = {}) {
  const { entity, ...options } = _options

  return pr(
    ability.can(Job.CREATE),

    'You have no permission to create job',

    options,
  )
}
