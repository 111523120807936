<template>
  <div class="folders-tree">
    <ContextMenu
      ref="menu"
      @openFolder="clickFolder"
    />
    <div
      v-for="folder in folders_"
      :key="folder.id"
      class="folders-tree__folder"
      :data-folder="folder.id"
      draggable="true"
      @dragstart="handleAssetDragstart($event, folder, null)"
    >
      <div
        :class="{'collapse-margin': folder.children && folder.children.total}"
        class="folders-tree__folder-content"
        @contextmenu.prevent="$refs.menu.$refs.menu.open"
        @click.right.prevent="openContext(folder)"
      >
        <span
          v-if="folder.children && folder.children.total"
          class="folders-tree__collapse-icon"
          :class="{'folder-transform': isFolderOpened(folder.id)}"
        >
          <GjIcon
            class="float-left"
            name="ArrowRight"
            size="16"
            @click.native="OPEN_CLOSE_FOLDER(folder.id)"
          />
        </span>
        <div
          :class="{
            'folder-focus': folder.id === folderId || (isPopup && folder.id === (targetFolder && targetFolder.id)),
          }"
          class="folders-tree__folder-info"
          @click="clickFolder(folder)"
        >
          <div class="folders-tree__folder-icon">
            <img
              src="@/assets/icons/assets-folder.svg"
              alt=""
            >
          </div>
          <div
            class="folders-tree__folder-name"
            :title="folder.name"
          >
            <div class="text-dark at-folder-item-button">
              <span class="folder-name__holder">
                {{ folder.name }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div
        v-show="folder.children && folder.children.total"
        class="folders-tree__collapse-content"
      >
        <b-collapse :visible="isFolderOpened(folder.id)">
          <FoldersTree
            v-if="folder.children.total && isFolderOpened(folder.id)"
            :key="folder.children.total"
            :is-popup="isPopup"
            :parent-id="folder.id"
            :level="level+1"
            :order="order"
            @openFolder="openFolder(folder)"
            @closeSidebar="closeSidebar"
          />
        </b-collapse>
      </div>
    </div>
  </div>
</template>

<script>
import { orderBy } from 'lodash'
import gql from 'graphql-tag'
import { mapMutations, mapState } from 'vuex'
import assetsMixin from '@/components/assets/assetsMixin'
import ContextMenu from './ContextMenu.vue'

export default {
  name: 'FoldersTree',
  components: {
    ContextMenu,
  },
  mixins: [assetsMixin],
  props: {
    isPopup: {
      type: Boolean,
    },
    parentId: {
      type: String,
      default: null,
    },
    level: {
      type: Number,
      default: 1,
    },
    order: {
      type: Object,
    },
  },
  apollo: {
    folderCollection: {
      query: gql`
        query ($limit: Int, $parentId: CodexStringFilter) {
         folderCollection (limit: $limit, where: {
            parentId: $parentId,
          }) {
            total
            items {
              id
              name
              parentId
              createdAt
              children {
                total
              }
            }
          }
        }
      `,
      variables() {
        return {
          limit: this.folders.total,
          parentId: {
            in: this.parentId ? [this.parentId] : [''],
            exists: !!this.parentId,
          },
        }
      },
      fetchPolicy: 'cache-and-network',
      result(results) {
        if (results?.data?.folderCollection?.items) {
          if (!this.parentId && !this.folderId && results.data.folderCollection.items?.length === 1) {
            const folder = results.data.folderCollection.items[0]
            if (folder && folder?.children?.total === 0) {
              this.clickFolder(results.data.folderCollection.items[0])
            }
          }
          this.folders.items = results.data.folderCollection.items
        }
      },
    },
  },
  data() {
    return {
      isFolderModalShown: {
        shown: false,
        mode: '',
        isEdit: true,
      },
      folders: {
        items: [],
        total: 10000,
      },
    }
  },
  computed: {
    ...mapState('assets', [
      'targetFolder',
      'filters',
      'openedFolders',
    ]),
    folderId() {
      return this.$route.query.folder
    },
    folders_() {
      if (this.folders && this.folders.items) {
        if (this.order.order === 'asc') {
          return orderBy(this.folders.items, [f => f?.[this.order.orderBy]?.toLowerCase()], ['asc'])
        }
        if (this.order.order === 'desc') {
          return orderBy(this.folders.items, [f => f?.[this.order.orderBy]?.toLowerCase()], ['desc'])
        }
        return this.folders.items
      }
      return this.folders.items
    },
  },
  watch: {
    targetFolder: {
      handler(v) {
        if (!this.folders.items.some(folder => folder.id === v?.id)) {
          this.$refs.menu.$refs.menu.close()
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.$root.$on('refetch-folders', this.refetchFolders)
  },
  destroyed() {
    this.$root.$off('refetch-folders', this.refetchFolders)
  },
  methods: {
    ...mapMutations('assets', [
      'SET_TARGET_FOLDER',
      'OPEN_CLOSE_FOLDER',
    ]),
    isFolderOpened(id) {
      return this.openedFolders.includes(id)
    },
    refetchFolders() {
      setTimeout(() => {
        this.$apollo.queries.folderCollection.refetch()
      }, 250)
    },
    clickFolder(folder) {
      if (!this.isPopup && this.folderId !== folder.id) {
        this.$router.replace({ name: this.$route.name, query: { ...this.$route.query, folder: folder.id } })
      }
      this.SET_TARGET_FOLDER(folder)
      this.filters.folderId = folder.id
      this.closeSidebar()
    },
    openContext(folder) {
      folder.isEdit = false
      if (this.folderId !== folder.id) {
        if (!this.isPopup) {
          this.$router.replace({ name: this.$route.name, query: { ...this.$route.query, folder: folder?.id } })
        }
        this.SET_TARGET_FOLDER(folder)
      }
    },
    openFolder(folder) {
      this.OPEN_CLOSE_FOLDER(folder.id)
      this.closeSidebar()
    },
    closeSidebar() {
      this.$emit('closeSidebar')
    },
  },
}
</script>
<style lang="scss">
@import '../../../../../node_modules/vue-context/dist/css/vue-context.css';

.folders-tree {
  .folder-focus {
    border: 1px rgba(29, 121, 242, 0.2);
    border-radius: 4px;
    background-color: rgba(29, 121, 242, 0.2);
  }

  .folder-transform {
    transform: rotate(90deg);
    transition: 0.3s ease;
  }

  .folders-tree__collapse-content {
    padding-left: 1rem;
  }

  .folders-tree__folder-content {
    border: 1px solid transparent;
    display: flex;
    font-family: "Montserrat", Helvetica, Arial, serif;
    padding: 3px 0;
    align-items: center;

    > span {
      margin: 3px;
    }
  }

  .folder-name__input-group {
    width: 90%;
    padding: 0;
    margin: 0 7px 0 0;

    .folder-name__input {
      border: none;
      border-bottom: 1px solid #d8d6de;
      border-radius: 0;
      height: 30px;
      padding: 0;
    }

    .folder-name__input-text {
      border: none;
      border-bottom: 1px solid #d8d6de;
      border-radius: 0;
      height: 30px;
      padding: 5px;
    }
  }

  .folders-tree__folder-icon {
    margin-right: 10px;
  }

  .folders-tree__folder-info {
    display: flex;
    flex-grow: 1;
    padding: 2px 5px;
    align-items: center;
  }

  .folder-name__holder{
    display: block;
    max-width: 150px;
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .folders-tree__folder-name {
    display: flex;
    flex-grow: 2;

    > svg {
      position: absolute;
      right: 10%;
      top: 67px;
    }
  }

  .collapse-margin {
    margin-left: -1.3em;
  }

  .folders-tree__collapse-icon {
    width: 16px;
  }

  .float-left {
    outline: none;
  }
}
</style>
