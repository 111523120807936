<template>
  <v-select
    v-model="inputVal"
    :multiple="true"
    :required="true"
    :options="sitesOptions"
    label="name"
    :reduce="el => el.id"
    :clearable="clearable"
    deselect-from-dropdown
    :close-on-select="false"
    :placeholder="placeholder"
  >
    <template v-slot:option="option">
      <div class="site-info">
        <b-img
          v-if="option.id !== SITES.ALL"
          style="width: 24px; height: 24px;"
          variant="info"
          :src="option.logo"
        />
        <span class="mb-0 table__collapse-name">
          {{ option.name }}
        </span>
      </div>
    </template>
    <template #no-options="">
      {{ $t('integrations.sites-dropdown-select.no-sites-found') }}
    </template>
  </v-select>
</template>

<script>
import { SITES } from '@/codex-sdk/sites'

export default {
  props: ['value', 'all', 'placeholder', 'clearable', 'integration'],
  data() {
    return {
      SITES,
      sites: [],
    }
  },
  computed: {
    sitesOptions() {
      const sites = this.$store.state.sites.sites ? [...this.$store.state.sites.sites] : []
      if (this.sites) {
        if (this.all) {
          sites.unshift({
            id: SITES.ALL,
            name: this.$t('integrations.sites-dropdown-select.all-sites'),
          })
        }
        sites.push(...this.sites)
      }
      return sites
    },
    inputVal: {
      get() {
        return this.value
      },
      async set(val) {
        if (!this.clearable && val.length < 1) return

        if (val[val.length - 1] === SITES.ALL) {
          this.$emit('input', [SITES.ALL])
        } else {
          this.$emit('input', val.filter(e => e !== SITES.ALL))
        }
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.site-info{
  display: flex;
  font-size: 14px;
  font-weight: 400;
  align-items: center;
  gap: 10px;
}

.select-all__option{
  padding: 10px 20px;
  display: flex;
  align-items: center;
  margin: 0;
}
</style>
