<template>
  <b-modal
    v-model="showPopup"
    :title="$t('entries.listing.clone-modal-title', {name: selectedEntry.system.title ? selectedEntry.system.title : ''})"
    :static="true"
    @ok="cloneMappedEntry(selectedEntry)"
    @hidden="handleCancel"
  >
    <v-select
      v-model="selectedMapping"
      :options="currentMappings"
      :clearable="false"
      label="name"
    />
  </b-modal>
</template>

<script>
import PopupsMixin from '@/components/popups/PopupsMixin'
import gql from 'graphql-tag'
import { cloneEntry, cloneEntryVersion, readEntry } from '@/codex-sdk/entries'

export default {
  name: 'CloneEntryPopup',
  mixins: [PopupsMixin],
  data() {
    return {
      modelId: '',
      currentMappings: [],
      allModels: this.$store.state.general.models,
      selectedMapping: {},
      selectedEntry: this.data.entry,
    }
  },
  async mounted() {
    await this.checkMappings(this.data.entry)
  },
  methods: {
    handleCancel() {
      this.closePopup(false)
    },
    async checkMappings(row) {
      try {
        const { data } = await this.$apollo.query({
          query: gql`
          query Mappings($modelId: String) {
            modelMappingCollection (where: { fromModel: { eq: $modelId }}) {
              items {
                id
                fields
                fromModel
                toModel
              }
            }
          }
        `,
          variables: {
            modelId: row.system.model.id,
          },
        })
        const mappings = data.modelMappingCollection.items
        const localArr = []
        if (mappings && mappings.length) {
          mappings.forEach(mapping => {
            const exist = this.allModels.find(model => model.id === mapping.toModel)
            if (exist) {
              localArr.push(exist)
            }
          })
          this.selectedEntry = row
        }
        // if (localArr.length > 0) {
        const defaultMapped = this.allModels.find(model => model.id === row.system.model.id)
        localArr.push(defaultMapped)
        this.currentMappings = localArr
        this.selectedMapping = defaultMapped
      } catch (e) {
        console.log(e)
      }
    },
    async cloneMappedEntry(entry) {
      try {
        const find = this.currentMappings.find(item => item.id === this.selectedMapping.id)
        if (entry.system.model.alias === find.alias) {
          await this.cloneDefaultEntry(entry)
        } else {
          await cloneEntry(entry.id, entry.system.model.alias, find.alias, { successMessage: 'sdk.entry.clone-successful' })
        }
      } catch (e) {
        console.log(e)
      }
    },
    async cloneDefaultEntry(entry) {
      try {
        const { data: current } = await readEntry({ model: entry.system.modelAlias, id: entry.id })
        await cloneEntryVersion(current.id, current.system.versionId, current.system.modelAlias, current.system.siteId, {
          successMessage: 'sdk.entry.clone-successful',
        })
      } catch (e) {
        console.log(e)
      }
    },
  },
}
</script>
