<template>
  <transition>
    <b-modal
      id="mbase-disconnect__popup"
      v-model="showPopup"
      :title="$t('integrations.integrations.vp.modal.title')"
      centered
      :ok-variant="showConnectionList ? 'outline-secondary':'primary'"
      :ok-title="showPropperTitle"
      cancel-variant="outline-secondary"
      :cancel-title="$t('general.cancel')"
      size="md"
      :ok-disabled="disableOk"
      :ok-only="showConnectionList"
      :static="true"
      @ok="triggerPropperMethod"
      @cancel="handleCancel"
      @hide="handleCancel"
    >
      <div v-if="showMoreDetails">
        <div
          class="vp-integration__sidebar-info"
        >
          <div class="integration-logo">
            <img
              src="../../../assets/icons/vp.svg"
              alt=""
            >
          </div>
          <div class="vp-integration__sidebar-header">
            <p>{{ getIntegration.name }}</p>
            <small>{{ $t('integrations.vp-details') }}</small>
          </div>
        </div>
      </div>

      <div v-else>

        <div
          v-if="showConnectionList"
        >
          <div
            class="vp-integration__sidebar-info"
          >
            <div class="integration-logo">
              <img
                src="../../../assets/icons/vp.svg"
                alt=""
              >
            </div>
            <div class="vp-integration__sidebar-header">
              <p>{{ getIntegration.name }}</p>
              <small>{{ getIntegration.description }}</small>
            </div>
          </div>

          <div
            class="vp-integration__sidebar-content"
          >
            <div
              v-for="activation in activations"
              :key="activation.id"
              class="vp-integration__sidebar-connection"
            >
              <p @click="editActivation(activation)">
                {{ activation.attrs.connectionName }}
              </p>
              <div class="connection__more-actions">
                <div
                  v-permission="['canEditIntegration']"
                  class="edit-option"
                  @click="editActivation(activation)"
                >
                  <GjIcon
                    name="Edit"
                    size="20"
                    class="cursor-pointer"
                  />
                  <small>{{ $t('integrations.vp.edit') }}</small>
                </div>
                <div
                  v-permission="['canDeleteIntegration']"
                  class="disconnect-option"
                  @click="disconnectActivation(activation)"
                >
                  <GjIcon
                    name="DeleteTrash"
                    size="20"
                    class="cursor-pointer"
                  />
                  <small>{{ $t('integrations.vp.disconnect') }}</small>
                </div>
              </div>
            </div>

            <div>
              <p
                v-permission="['canCreateIntegration']"
                class="new-connection"
                @click="showConnectionList = !showConnectionList"
              >
                {{ $t('integrations.vp.add-connection') }}
              </p>
            </div>
          </div>
        </div>

        <div v-else>
          <div class="new-connection__back-arrow">
            <GjIcon
              name="ArrowLeft"
              size="30"
              class="cursor-pointer"
              @click.native="goBack"
            />
            <p :class="{'connection-name': vpPlayer.attrs.connectionName}">
              {{ vpPlayer.attrs.connectionName ? vpPlayer.attrs.connectionName : $t('integrations.integrations.vp.modal.new') }}
            </p>
          </div>
          <div class="vp-connection__new">
            <b-form-group :label="$t('integrations.vp.connection-name')">
              <b-input
                v-model="vpPlayer.attrs.connectionName"
                style="font-size:14px"
                autofocus
                :placeholder="$t('integrations.vp.connection-name-placeholder')"
              />
            </b-form-group>
            <b-form-group :label="$t('integrations.vp.organization-name')">
              <VPOrganizationSelector
                v-model="vpPlayer.attrs.organization"
                :has-permission="hasPermission"
              />
            </b-form-group>
            <b-form-group :label="$t('integrations.vp.projects')">
              <vSelect
                v-model="vpPlayer.attrs.projects"
                label="name"
                :clearable="false"
                :options="projects.items"
                :reduce="e => e.publicProjectId"
                :get-option-key="e => e.publicProjectId"
                class="vp-integration-project-selector"
                :placeholder="$t('integrations.vp.projects-placeholder')"
                multiple
                :disabled="!hasPermission"
              />
            </b-form-group>

            <b-form-group :label="$t('integrations.vp.video-player')">
              <vSelect
                v-model="vpPlayer.attrs.player"
                :options="playerIds[vpPlayer.attrs.organization] || []"
                label="name"
                :get-option-key="e => e.value"
                :reduce="e => `${e.value}`"
                :disabled="!hasPermission"
                :placeholder="$t('integrations.vp.video-player-placeholder')"
              />
            </b-form-group>

            <b-form-group :label="$t('integrations.vp.audio-player')">
              <vSelect
                v-model="vpPlayer.attrs.audioPlayer"
                :options="audioPlayers"
                label="name"
                :get-option-key="e => e.value"
                :reduce="e => `${e.value}`"
                :disabled="!hasPermission"
                :placeholder="$t('integrations.vp.audio-player-placeholder')"
              />
            </b-form-group>

            <b-form-group :label="$t('integrations.vp.folder')">
              <FolderSelect
                v-model="vpPlayer.attrs.folderId"
                :has-multiple="false"
                :placeholder="$t('integrations.vp.folder-placeholder')"
              />
            </b-form-group>
            <p
              v-if="errorMessage"
              class="text-center text-danger m-0 text-sm"
            >
              {{ errorMessage }}
            </p>
          </div>
        </div>
      </div>
    </b-modal>
  </transition>
</template>

<script>
import PopupsMixin from '@/components/popups/PopupsMixin'
import VPOrganizationSelector from '@/components/vp-components/VPOrganizationSelector.vue'
import { vpDefaults } from '@/codex-sdk/all-integrations'
import { readVPProjects, readVPPlayers, readVPAudioPlayers } from '@/codex-sdk/assets'
import { cloneDeep } from 'lodash'
import FolderSelect from '@/components/assets/FolderSelect.vue'
import { canEditIntegration } from '@/codex-permissions/integrations'
import { ENTITY_TYPES } from '@/utils/constants'

export default {
  name: 'VPPlayerPopup',
  components: {
    VPOrganizationSelector,
    FolderSelect,
  },
  mixins: [PopupsMixin],
  inject: ['showConfirmDeletePopup'],
  data() {
    return {
      vpPlayer: vpDefaults(),
      showConnectionList: true,
      projects: {},
      playerIds: {},
      audioPlayers: [],
      activations: [],
      filters: {
        page: 1,
        size: 1000,
      },
      allowVideoUpload: false,
      project: null,
      errorMessage: '',
      hasPermission: true,
    }
  },
  computed: {
    getIntegration() {
      return this.data.integration
    },
    disableOk() {
      return (!this.showConnectionList && !canEditIntegration().can) || (!Object.values(this.vpPlayer.attrs).every(value => value) && !this.showConnectionList)
    },
    triggerPropperMethod() {
      if (this.showConnectionList || !this.vpPlayer) return this.handleCancel
      return this.handleOk
    },
    showMoreDetails() {
      return this.data.showMoreDetails
    },
    vpConnections() {
      return this.$store.getters['general/getConnections'](this.getIntegration.alias) || {}
    },
    showPropperTitle() {
      if (!this.showConnectionList && !this.vpPlayer.id) {
        return this.$t('general.connect')
      }

      if (!this.showConnectionList) {
        return this.$t('general.save')
      }

      return this.$t('general.cancel')
    },
  },
  watch: {
    'vpPlayer.attrs.organization': {
      handler() {
        if (this.vpPlayer.attrs.organization) {
          this.fetchVPProjects()
        }
      },
      deep: true,
    },
    'vpPlayer.attrs.projects': {
      handler() {
        if (this.vpPlayer.attrs.projects) {
          this.fetchConnectionConfig()
        }
      },
      deep: true,
    },
  },
  mounted() {
    if (this.vpConnections.length) {
      this.activations = this.vpConnections
    }
    if (this.getIntegration) {
      this.vpPlayer.integration = this.getIntegration.alias
    }
  },
  methods: {
    handleCancel() {
      this.closePopup(this.activations)
    },
    async fetchVPProjects() {
      this.loading = true
      if (this.vpPlayer.attrs.organization) {
        this.filters.organizationId = this.vpPlayer.attrs.organization
        try {
          const response = await readVPProjects(this.filters)
          this.projects = response.data.result
        } catch (err) {
          console.log(err)
        }
      }
    },

    async fetchConnectionConfig() {
      this.loading = true
      if (this.vpPlayer.attrs.organization) {
        try {
          const value = await readVPPlayers(this.vpPlayer.attrs.organization)
          this.$set(this.playerIds, this.vpPlayer.attrs.organization, value.data.result)

          this.audioPlayers = []
          this.vpPlayer.attrs.projects.forEach(projectId => {
            this.readVPAudioPlayers(projectId)
          })
        } catch (err) {
          console.log(err)
          this.showPermissionError()
        }
      }
    },
    async readVPAudioPlayers(projectId) {
      try {
        const { data } = await readVPAudioPlayers(projectId)
        if (data.result.length) {
          data.result.forEach(player => {
            if (this.audioPlayers.some(p => p.value === player.value)) return
            this.audioPlayers.push(player)
          })
        }
      } catch (err) {
        console.log(err)
        this.showPermissionError()
      }
    },
    async disconnectActivation(activation) {
      const result = await this.showConfirmDeletePopup({
        items: [activation.attrs.connectionName],
        type: ENTITY_TYPES.CONNECTION,
      })
      try {
        if (result) {
          this.$store.dispatch('general/removeConnections', activation)
          this.activations = this.activations.filter(getActivation => getActivation.id !== activation.id)
        }
      } catch (err) {
        console.log(err)
      }
    },
    async editActivation(activation) {
      try {
        this.showConnectionList = !this.showConnectionList
        this.vpPlayer = cloneDeep(activation)
      } catch (error) {
        console.log(error)
      }
    },
    async handleOk() {
      try {
        if (!this.vpPlayer.id) {
          this.$store.dispatch('general/addConnections', this.vpPlayer)
          this.activations.push(this.vpPlayer)
        } else {
          this.$store.dispatch('general/updateConnections', this.vpPlayer)
        }
      } catch (err) {
        console.log(err)
      }
    },
    goBack() {
      this.showConnectionList = true
      this.vpPlayer = vpDefaults()
      this.hasPermission = true
      this.errorMessage = ''
    },
    showPermissionError() {
      this.errorMessage = "You don't have access in this VP Platform organization"
      this.hasPermission = false
    },
  },
}
</script>

<style lang="scss">
.vp-integration__sidebar-info{
  display: flex;
  width: 100%;
  align-items: flex-start;
  gap: 20px;
  margin: 3% auto;

  img{
    width: 36px;
    height: 36px;
  }

  .integration-logo{
    background: linear-gradient(
      180deg,
      #d9d9d9 0%,
      #f3f2f4 0.01%,
      #ebddfd 100%
    );
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
  }

  .vp-integration__sidebar-header{
    p {
      font-weight: 500;
      font-size: 18px;
      color: #052D61;
      margin-bottom: 5px;
    }

    small{
      font-size: 14px;
      color: #667C99;
    }
  }
}

.vp-integration__sidebar-content{
  width: 100%;
  margin: 4% auto;

  .vp-integration__sidebar-connection{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 3% auto;
    background: #F4F6FA;
    padding: 4px 10px;
    border-radius: 4px;

    p{
      margin: 0;
      font-weight: 600;
      font-size: 14px;
      color: #052D61;
      cursor: pointer;
    }

    .connection__more-actions{
      display: flex;
      gap: 10px;
      .edit-option, .disconnect-option{
        display: flex;
        gap: 2px;
        font-weight: 500;
        color:#A3B0C2;
        font-size: 14px;

        &:hover{
          cursor: pointer;
          color: #475263;
        }
      }
    }
  }

  .new-connection{
    color:#1D79F2;
    font-size: 14px;
    margin-top: 1%;
    cursor: pointer;
    font-weight: 500;
  }

}
  .new-connection__back-arrow{
    display: flex;
    align-items: center;
    gap: 5px;
    margin-bottom: 2%;

    p{
      font-weight: 600;
      font-size: 18px;
      color: #A3B0C2;
      margin: 0;
    }

    svg{
      margin: 0;
    }

    .connection-name{
      color: #052D61;
    }
  }

.vp-integration-project-selector .vs__selected-options {
    padding: 0 7px;
    font-size: 14px;
}
</style>
