<template>
  <b-modal
    id="merge-tag-popup"
    v-model="showPopup"
    ok-variant="primary"
    cancel-variant="outline-dark"
    size="md"
    no-close-on-backdrop
    :static="true"
    :title="$t('content.tags.merge-popup.title')"
    :ok-title="$t('content.tags.merge-popup.merge')"
    :cancel-title="$t('content.tags.merge-popup.cancel')"
    :ok-disabled="disableOk"
    @ok.prevent="merge"
    @close="closePopup"
  >
    <span class="merge-description">
      {{ $t('content.tags.merge-popup.description') }}
    </span>
    <div>
      <b-form-group
        :label="$t('content.tags.merge-popup.tags-to-merge')"
        label-class="merge-labels"
      >
        <TagSelect
          v-model="tagsId"
          :multiple="true"
        />
      </b-form-group>
      <b-form-group
        :label="$t('content.tags.merge-popup.target-tag')"
        label-class="merge-labels"
      >
        <TagSelect v-model="targetId" />
      </b-form-group>
    </div>
  </b-modal>
</template>

<script>
import PopupsMixin from '@/components/popups/PopupsMixin'
import TagSelect from '@/views/tags/TagSelect.vue'
import { mergeTags } from '@/codex-sdk/tags'

export default {
  name: 'MergeTag',
  components: { TagSelect },
  mixins: [PopupsMixin],
  data() {
    const tagsId = []
    if (this.data?.tagsToMerge?.length) {
      tagsId.push(...this.data.tagsToMerge)
    }
    return {
      targetId: null,
      tagsId,
    }
  },
  computed: {
    disableOk() {
      return !this.targetId || !this.tagsId.length
    },
  },
  methods: {
    async merge() {
      try {
        await mergeTags({
          targetTag: this.targetId,
          tagsToMerge: this.tagsId,
        })
        this.closePopup(true)
      } catch (e) {
        console.log(e, 'error')
      }
    },
  },
}
</script>

<style lang="scss">
#merge-tag-popup {
  .modal-body {
    display: flex;
    flex-direction: column;
    gap: 32px;
  }
  .merge-labels {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #868693;
  }

  .merge-description {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #4B4B5F;
  }
}
</style>
