import CodexContentEditor from '../../CodexContentEditor'
import VimeoEmbed from './VimeoEmbed.vue'
import VimeoEmbedPanel from './VimeoEmbedPanel.vue'
import { BLOCK_GROUPS } from '../constants'

CodexContentEditor.registerWidget('codex', {
  displayName: 'codex-editor.block-names.vimeo-embed',
  name: 'vimeo_embed',
  group: BLOCK_GROUPS.EMBEDS,
  // eslint-disable-next-line global-require
  image: require('../../icons/spotify-podcast/icon.svg'),
  attrs: {
    blockId: {
      default: null,
    },
    src: {
      default: null,
    },
    caption: {
      default: null,
    },
    align: {
      default: 'left',
    },
    width: {
      default: '50%',
    },
    video_id: {
      default: null,
    },
    aspectRatio: {
      default: null,
    },
  },
  renderEditor: VimeoEmbed,
  renderEditorBar: VimeoEmbedPanel,
})
