import { checkConditions } from '@/utils/helpers'
// import NumberValidations from './Number/index'
import { getBlocks } from '@/views/models/constants'
import JsonValidations from './Json/index'

export default class FieldValidations {
  static allValidations = [
    // ...NumberValidations,
    ...JsonValidations,
  ]

  static getValidationsForType(type, valueType) {
    if (!type) return []
    if (!valueType) return []
    if (!Array.isArray(valueType)) valueType = [valueType]

    return FieldValidations.allValidations.filter(validation => {
      const typeCheck = validation.type === type
      const valueTypeCheck = validation.valueType.some(vt => valueType.includes(vt))

      return typeCheck && valueTypeCheck
    })
  }

  static getFieldValidationAttrs(type, valueType) {
    const validations = FieldValidations.getValidationsForType(type, valueType)
    const attrs = {}
    validations.forEach(validation => {
      attrs[validation.name] = validation.attr
    })
    return attrs
  }

  static validateField(field, value, entry, model) {
    const validations = FieldValidations.getValidationsForType(field.type, field.valueType)

    let fieldValidation = { isValid: true }

    validations.every(validation => {
      if (!field.validation) return true
      const attr = field.validation[validation.name]
      const hasConditions = attr?.conditionsEnabled && attr?.conditions?.length > 0

      if (!attr?.conditions || !hasConditions || checkConditions(attr, model, entry)) {
        if (field?.validation?.[validation.name]) {
          fieldValidation = validation.validation(value, field.validation[validation.name], field) ?? { isValid: true }
          return fieldValidation?.isValid
        }
      }
      return true
    })

    return fieldValidation
  }

  static validateForm(entry, model) {
    const errors = {}
    const fields = getBlocks(model.blocks, f => f.isField)
    fields.forEach(field => {
      const fieldErrors = FieldValidations.validateField(field.attrs, entry.content[field.attrs.alias], entry, model)
      if (fieldErrors) {
        errors[field.attrs.alias] = fieldErrors
      }
    })

    return errors
  }
}
