<template>
  <div
    class="media-caption"
    style="text-align: left;"
  >
    <p
      ref="editable"
      class="media-caption__input"
      :contenteditable="!disabled"
      :title="!value ? assetCaption : ''"
      v-on="listeners"
    />
    <span
      v-show="!value"
      class="media-caption__placeholder"
    >
      {{ assetCaption ? assetCaption : $t('fields.codex-field-media-content.general.add-caption') }}
    </span>
    <span
      style="z-index: 10;"
      @mousedown.stop.prevent="enableEditing"
    >
      <GjIcon
        v-show="value"
        name="Edit"
        size="16px"
      />
    </span>
  </div>
</template>

<script>
export default {
  name: 'CaptionInput',
  props: {
    value: {
      type: String,
      default: '',
      validate: value => typeof value === 'string',
    },
    assetCaption: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    listeners() {
      return {
        ...this.$listeners,
        input: this.onInput,
      }
    },
  },
  mounted() {
    this.$refs.editable.innerText = this.value
  },
  methods: {
    onInput(e) {
      this.$emit('input', e.target.innerText)
    },
    enableEditing() {
      this.$nextTick(() => {
        this.$refs.editable.focus()
        this.moveCursorToEnd()
        this.scrollToBottom()
      })
    },
    scrollToBottom() {
      this.$refs.editable.scrollTop = this.$refs.editable.scrollHeight
    },
    moveCursorToEnd() {
      const range = document.createRange()
      const sel = window.getSelection()
      range.setStart(this.$refs.editable, 1)
      range.collapse(true)
      sel.removeAllRanges()
      sel.addRange(range)
    },
  },
}
</script>

<style lang="scss">
.media-caption {
  margin-top: 4px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 4px;

  svg {
    flex-shrink: 0;
    cursor: pointer;
  }
}

.media-caption__input {
  width: 100%;
  padding: 2px;
  margin: -2px;
  min-height: 18px;
  max-height: 36px;
  overflow-y: auto;
  &, * {
    line-height: 18px !important;
    font-weight: 500 !important;
    font-size: 12px !important;
    color: #052D61 !important;
  }
  transition: max-height 0.3s ease;

  &:focus {
    outline: none;
    max-height: 90px; // 5 lines
  }
}

.media-caption__placeholder {
  position: absolute;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  pointer-events: none;
  font-style: italic;
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  color: #667C99;
}
</style>
