import state from './modulesSectionsState'
import mutations from './modulesSectionsMutations'
import actions from './modulesSectionsActions'
import getters from './modulesSectionsGetters'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
