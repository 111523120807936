import { truncate } from 'lodash'
import i18n from '@/libs/i18n'
import TagList from './TagList.vue'

export default class TagListFilter {
    static COMPONENT = TagList;

    static OPERATORS = {
      all: 'all',
      some: 'some',
      none: 'none',
      exists: 'exists',
      notExists: 'notExists',
    }

    static type = 'tag-list'

    static graphqlType = 'TagListFieldFilter'

    operator = null;

    value = null;

    constructor({ operator = TagListFilter.OPERATORS.some, value = [] } = {}) {
      this.operator = operator
      this.value = value
    }

    set({ operator = TagListFilter.OPERATORS.some, value = [] }) {
      this.operator = operator
      this.value = value
    }

    getCount() {
      if (this.operator === TagListFilter.OPERATORS.exists || this.operator === TagListFilter.OPERATORS.notExists) {
        return 1
      }
      return this.value?.length
    }

    getValueLabel({ cache }) {
      if (this.operator === TagListFilter.OPERATORS.exists || this.operator === TagListFilter.OPERATORS.notExists) {
        return this.operator === TagListFilter.OPERATORS.exists ? i18n.t('filters.is-not-empty') : i18n.t('filters.is-empty')
      }
      const tag = this.value.map(tagId => cache[tagId] || { id: tagId })
      return tag.map(m => truncate(m.tagValue, { length: 20, separator: ' ' })).join(', ')
    }

    toLoad() {
      return {
        tags: this.value,
      }
    }

    asQueryParam() {
      if (this.operator === TagListFilter.OPERATORS.exists || this.operator === TagListFilter.OPERATORS.notExists) {
        return {
          operator: this.operator,
        }
      }
      return {
        operator: this.operator,
        value: this.value,
      }
    }

    asGraphQL() {
      switch (this.operator) {
        case TagListFilter.OPERATORS.all:
          return {
            all: this.value,
          }
        case TagListFilter.OPERATORS.some:
          return {
            some: this.value,
          }
        case TagListFilter.OPERATORS.none:
          return {
            none: this.value,
          }
        case TagListFilter.OPERATORS.exists:
          return {
            exists: true,
          }
        case TagListFilter.OPERATORS.notExists:
          return {
            exists: false,
          }
        default:
          return null
      }
    }

    asGraphQLDynamicList() {
      if (this.asGraphQL()) {
        return {
          ...this.asGraphQL(),
          _t: TagListFilter.graphqlType,
        }
      }
      return null
    }

    static validate({ operator, value }) {
      if (!operator) {
        return 'Operator is required'
      }

      if (operator === TagListFilter.OPERATORS.exists || operator === TagListFilter.OPERATORS.notExists) {
        return true
      }

      if (!value || value.length === 0) {
        return 'Value is required'
      }

      return true
    }

    static fromQueryParam({ operator, value }) {
      return new TagListFilter({
        operator,
        value,
      })
    }

    static shouldApplyPredefinedValue(filter) {
      const forOperators = [TagListFilter.OPERATORS.all, TagListFilter.OPERATORS.some]
      return forOperators.includes(filter.operator)
    }
}
