<template>
  <div
    class="version__history"
    @mouseover="showPopup = true"
    @mouseleave="showPopup = false"
  >
    <div class="version__history-title-options">
      <div
        class="version__history-title cursor-pointer"
        :title="formattedName"
        @click="$emit('handleVersionFetch')"
      >
        <h5>{{ formattedName }}</h5>
      </div>
      <div class="version__history-options">
        <b-dropdown
          right
          variant="flat"
          class="dropdown-icon-wrapper version__history-dropdown"
        >
          <template #button-content>
            <b-button variant="btn-outline-white">
              <GjIcon name="Moreoptions" />
            </b-button>
          </template>
          <b-dropdown-item
            id="class-history-edit-version"
            v-permission="['canManageEntryVersions', version.entity]"
            @click="showModal"
          >
            <span class="d-flex align-items-center">
              <GjIcon name="Edit" />
              <p class="p-0 m-0 ml-50">
                {{ $t('entries.versions.edit') }}
              </p>
            </span>
          </b-dropdown-item>

          <b-dropdown-item
            v-if="version.id !== entryVersion.currentVersionId && (!entryVersion.previewingVersion || version.id !== entryVersion.previewingVersion.id)"
            id="class-history-edit-previewVersion"
            v-b-tooltip="comparingId ? $t('entries.versions.disabled-preview') : ''"
            :disabled="!!comparingId"
            @click="$emit('handleVersionFetch')"
          >
            <span class="d-flex align-items-center">
              <GjIcon name="Show" />
              <p class="p-0 m-0 ml-50">
                {{ $t('entries.versions.preview') }}
              </p>
            </span>
          </b-dropdown-item>
          <b-dropdown-item
            id="class-history-clone-version"
            v-permission="['canCreateEntry', model]"
            @click="cloneVersion"
          >
            <span class="d-flex align-items-center">
              <GjIcon name="Layers_fill" />
              <p class="p-0 m-0 ml-50">
                {{ $t('entries.versions.clone') }}
              </p>
            </span>
          </b-dropdown-item>

          <b-dropdown-item
            v-if="version.id !== entryVersion.currentVersionId"
            id="class-history-restore-version"
            v-permission="['canEditEntry', canEditEntryPayload]"
            @click="restoreVersion"
          >
            <span class="d-flex align-items-center">
              <GjIcon name="Refresh" />
              <p class="p-0 m-0 ml-50">
                {{ $t('entries.versions.restore') }}
              </p>
            </span>
          </b-dropdown-item>
          <b-dropdown-item
            v-if="version.id !== entryVersion.currentVersionId"
            id="class-history-edit-previewVersion"
            v-b-tooltip="isPreviewing ? $t('entries.versions.disabled-compare') : ''"
            :disabled="!!isPreviewing"
            @click="setEntry"
          >
            <span class="d-flex align-items-center">
              <GjIcon name="Modeling" />
              <p class="p-0 m-0 ml-50">
                {{ $t('entries.versions.compare') }}
              </p>
            </span>
          </b-dropdown-item>
          <b-dropdown-item
            v-if="version.id !== entryVersion.currentVersionId && version.id !== lastPublishedVersionId && !hasSchedules"
            id="class-history-delete-version"
            v-permission="['canManageEntryVersions', version.entity]"
            @click="deleteVersion"
          >
            <span class="d-flex align-items-center">
              <GjIcon name="Delete" />
              <p class="p-0 m-0 ml-50">
                {{ $t('entries.versions.delete') }}
              </p>
            </span></b-dropdown-item>
        </b-dropdown>
      </div>
    </div>

    <div class="version__history-date-published">
      <div
        v-if="version.id === entryVersion.currentVersionId"
        class="version__current-version version__current-title"
      >
        <div class="version__history-date-published-dot version__little-dot" />
        {{ $t('entries.versions.current-version') }}
      </div>
      <div
        v-else-if="entryVersion.previewingVersion && version.id === entryVersion.previewingVersion.id"
        class="version__currently-previewing version__current-title"
      >
        <div class="version__currently-previewing-version-dot version__little-dot" />
        {{ $t('entries.versions.previewing-version') }}
      </div>
      <div
        v-if="version.id === comparingId"
        class="version__current-version version__current-title"
      >
        <div class="version__history-date-published-dot version__little-dot" />
        {{ $t('entries.versions.comparing-version') }}
      </div>
      <div
        v-if="version.id === lastPublishedVersionId"
        class="version__current-published version__current-title"
      >
        <div class="version__currently-last-published-version-dot version__little-dot" />
        {{ $t('entries.versions.published-version') }}
      </div>
    </div>
    <StatusChip
      v-if="hasSchedules"
      :entry="hasSchedules.publishScheduledDate ? publishedConfig : unpublishConfig"
      :no-text="false"
      :with-background="true"
      :scheduled-versions="[hasSchedules]"
      is-schedule-status
      class="version__history-date-published"
    />

    <div
      class="entry-log"
      @mouseover="showPopup = true"
      @mouseleave="showPopup = false"
    >
      <div
        v-if="version.createdBy && version.createdAt"
        class="entry-log__details"
      >
        <Popper
          v-if="showPopup"
          :user="version.createdBy"
        >
          <div class="entry-log__createdBy">
            <b-avatar
              :src="version.createdBy.imageUrl"
              class="entry-log__createdBy__image"
            />
            <span class="entry-log__createdBy__name">
              {{ version.createdBy.fullName }}
            </span>
          </div>
        </Popper>
        <div
          v-else
          class="entry-log__createdBy"
        >
          <b-avatar
            :src="version.createdBy.imageUrl"
            class="entry-log__createdBy__image"
          />
          <span class="entry-log__createdBy__name">
            {{ version.createdBy.fullName }}
          </span>
        </div>
        <div
          class="entry-log__details__date"
          :title="version.createdAt | formatDateTime"
        >
          {{ formatDateTime(version.createdAt) }}
        </div>
      </div>
      <ul
        v-if="showLogs && version.activityLogs.length"
        class="entry-log__list"
      >
        <EntryLog
          v-for="activity in version.activityLogs"
          :key="`${activity.fieldAlias}${activity.isSystemField}`"
          :activity="activity"
          :site-id="siteId"
          :all-fields="allFields"
        />
      </ul>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import {
  cloneEntryVersion, restoreEntryVersion, deleteEntryVersion, ENTRY_STATUSES,
} from '@/codex-sdk/entries'
import { ENTITY_TYPES } from '@/utils/constants'
import { getBlocks } from '@/views/models/constants'
import Popper from '@/components/popper/Popper.vue'
import EntryLog from '@/views/entries/versions/EntryLog.vue'
import StatusChip from '@/views/entries/components/StatusChip.vue'

export default {
  name: 'Version',
  components: { StatusChip, EntryLog, Popper },
  props: {
    version: Object,
    entryVersion: Object,
    model: Object,
    lastPublishedVersionId: String,
    revisionId: String,
    comparingId: String,
    siteId: String,
    entry: Object,
    showLogs: Boolean,
    scheduledVersions: Array,
  },
  inject: ['showEditVersionNamePopup', 'showConfirmDeletePopup', 'entryLabels'],
  data() {
    return {
      showPopup: false,
      publishedConfig: { id: this.entry.id, system: { status: ENTRY_STATUSES.PUBLISHED } },
      unpublishConfig: { id: this.entry.id, system: { status: ENTRY_STATUSES.UNPUBLISHED } },
    }
  },
  computed: {
    hasSchedules() {
      return this.scheduledVersions?.filter(v => v.versionId === this.version.id)?.[0]
    },
    entryId() {
      return this.entry.id
    },
    formattedName: {
      get() {
        return this.getFormattedName()
      },
      set(v) {
        this.version.name = v
      },
    },
    isPreviewing() {
      return this.entryVersion?.previewingVersion?.id
    },
    allFields() {
      const fields = getBlocks(this.model.blocks, block => block.isField) || []
      const deletedFields = this.model.deletedBlocks || []
      return [
        ...fields,
        ...deletedFields,
      ]
    },
    canEditEntryPayload() {
      return {
        ...this.version.entity,
        entryLabels: this.entryLabels(),
      }
    },
  },
  methods: {
    setEntry() {
      this.$root.$emit('compareEntry', this.version.entity)
    },
    getFormattedName() {
      const date = moment(this.version.name, true)
      if (date.isValid()) return date.format('MMMM D, H:mm')
      return this.version.name
    },
    async showModal() {
      try {
        const data = await this.showEditVersionNamePopup({ version: this.version, model: this.model, siteId: this.siteId })
        if (data) {
          this.$emit('fetchVersions')
        }
      } catch (e) {
        console.log(e)
      }
    },
    formatDateTime(date) {
      return moment(date).format('MMMM D, H:mm')
    },
    async cloneVersion() {
      try {
        const { data } = await cloneEntryVersion(this.version.entity.id, this.version.id, this.model.alias, this.siteId)
        this.$router.replace({
          name: this.$route.name,
          params: {
            ...this.$route.params,
            id: data.id,
            evId: undefined,
          },
        })
      } catch (e) {
        console.log(e)
      }
    },
    async restoreVersion() {
      try {
        await restoreEntryVersion(this.version, this.revisionId, this.model.alias, this.siteId)
        this.$router.go()
      } catch (e) {
        console.log(e)
      }
    },
    async deleteVersion() {
      try {
        const result = await this.showConfirmDeletePopup({
          items: [this.formattedName],
          type: ENTITY_TYPES.ENTRY_VERSION,
        })
        if (result) {
          await deleteEntryVersion(this.version, this.revisionId, this.model.alias, this.siteId)
          this.$emit('fetchVersions')
        }
      } catch (e) {
        console.log(e)
      }
    },
  },
}
</script>

<style lang="scss">
.entry-log__list {
  padding-left: 1.3rem;
  margin: 0;
}

.entry-log {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.entry-log__details {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.entry-log__details__date {
  color: #667C99;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
}

.entry-log__createdBy {
  display: flex;
  gap: 4px;
  align-items: center;
  color: #667C99;
}

.entry-log__createdBy__name {
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
}

.entry-log__createdBy__image {
  width: 16px !important;
  height: 16px !important;
}
</style>
