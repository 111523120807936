import { apiAxios as axios } from '../libs/axios'
import { tc } from './config'

/**
 * Constants
 */
export const ENVIRONMENTS_STATUSES = Object.freeze({
  ACTIVE: 'ACTIVE',
  DELETED: 'DELETED',
  INACTIVE: 'INACTIVE',
  UNDEFINED: 'UNDEFINED',
})

/**
 * Helpers
 */
export function organizationDefaults(organization = {}) {
  return {
    name: '',
    description: '',
    ...organization,
  }
}

/**
 * Transforms to received data
 */
export function transformOrganizationFromRequest(organization) {
  return organization
}

/**
 * CRUD Operations
 * */

/**
 *
 * @param {*} organization
 *
 * @response
 * {
 *  id: GUID
 *  errors: null | Object
 *  success: true | false
 * }
 */
export function createOrganization(organization, messages) {
  return tc(axios.post('/organizations', organization), {
    successTitle: 'sdk.success-title',
    successMessage: 'sdk.organization.create-successful',
    failTitle: 'sdk.organization.create-failed',
    ...messages,
  })
}

/**
 *
 * @response
 * [{
 *  id: GUID
 *  parentId: GUID | null
 *  title: String
 *  slug: String
 *  canContainArticle: Bool
 * }, {...}, ...]
 */
export function readOrganizations() {
  return axios.get('/organizations').then(response => {
    response.data.data = response.data.data.map(organization => transformOrganizationFromRequest(organization))
    return response
  })
}

/**
 *
 * @param {*} id
 *
 * @response
 * {
 *  id: GUID
 *  parentId: GUID | null
 *  title: String
 *  slug: String
 *  canContainArticle: Bool
 * }
 */
export function readOrganization(id) {
  return axios.get(`/organizations/${id}`, { params: { siteId: null } }).then(response => {
    response.data = transformOrganizationFromRequest(response.data)
    return response
  })
}

/**
 *
 * @param {*} organization
 *
 * @response
 * {
 *  id: GUID
 *  errors: null | Object
 *  success: true | false
 * }
 */
export function updateOrganization(organization, messages) {
  return tc(axios.put(`/organizations/${organization.id}`, organization), {
    successTitle: 'sdk.success-title',
    successMessage: 'sdk.organization.update-successful',
    failTitle: 'sdk.organization.update-failed',
    ...messages,
  })
}

/**
 *
 * @param {*} organizationId
 *
 * @response
 * {
 *  errors: null | Object
 *  success: true | false
 * }
 */
export function deleteOrganization(organizationId, messages) {
  return tc(axios.delete(`/organizations/${organizationId}`), {
    successTitle: 'sdk.success-title',
    successMessage: 'sdk.organization.delete-successful',
    failTitle: 'sdk.organization.delete-failed',
    ...messages,
  })
}

/**
 *
 * @param {*} organizationId
 *
 * @response
 * [{
 *  errors: null | Object
 *  success: true | false
 * },...]
 */
export function deleteOrganizations(organizationIds, messages) {
  return tc(Promise.all(organizationIds.map(organizationId => axios.delete(`/organizations/${organizationId}`))), {
    successTitle: 'sdk.success-title',
    successMessage: 'sdk.organization.delete-organizations-successful',
    failTitle: 'sdk.organization.delete-organizations-failed',
    ...messages,
  }, true)
}
