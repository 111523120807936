<template>
  <div>
    <Mentionable
      ref="mentionable"
      :keys="['@']"
      :items="items"
      offset="6"
      :limit="300"
      insert-space
      class="comment-input-mentionable"
      popover-class="mentions"
      @apply="applyMention"
    >
      <div
        ref="editable"
        class="mentions-input"
        contenteditable="true"
        @input="onInput"
      />

      <div
        v-if="!value"
        class="mention-placeholder"
      >
        {{ placeholder }}
      </div>

      <template #no-result>
        <div class="dim">
          No result
        </div>
      </template>

      <template #item-@="{ item }">
        <div class="user">
          {{ item.fullName || item.email }}
        </div>
      </template>
    </Mentionable>
  </div>
</template>

<script>
import { Mentionable } from 'vue-mention'
import { mapGetters } from 'vuex'
import { OIDC_STORE_MODULE } from '@/../gjirafa-front/script/auth'
import { getCurrentSiteId } from '@/services/siteService'
import gql from 'graphql-tag'

export default {

  components: {
    Mentionable,
  },
  props: ['value', 'placeholder'],
  apollo: {
    userCollection: {
      query: gql`
        query {
          userCollection (limit: 10000){
            items {
              id
              email
              firstName
              lastName
            }
          }
        }
      `,
      result({ data }) {
        const users = data.userCollection.items.map(u => ({
          value: u.id,
          email: u.email,
          fullName: `${u.firstName || ''} ${u.lastName || ''}`.trim(),
        }))
        this.items = users.filter(item => item.value !== this.oidcUser?.sub)
      },
    },
  },
  data() {
    return {
      text: '',
      items: [],
      mentions: [],
      siteId: getCurrentSiteId(),
      input: null,
    }
  },
  computed: {
    ...mapGetters(OIDC_STORE_MODULE, [
      'oidcUser',
    ]),
  },
  mounted() {
    if (this.value) {
      this.$refs.editable.innerHTML = this.value
      this.setMentions()
    }

    this.$nextTick(() => {
      const el = this.$refs.mentionable.$el
      this.input = el.querySelector('input') ?? el.querySelector('textarea') ?? el.querySelector('[contenteditable="true"]')
      this.input.addEventListener('keyup', this.scrollToView)
    })
  },

  beforeDestroy() {
    this.input.removeEventListener('keyup', this.scrollToView)
  },

  methods: {
    scrollToView() {
      this.$nextTick(() => {
        const mentionSelected = document.querySelector('.mention-selected')
        if (!mentionSelected) return

        mentionSelected.scrollIntoView({
          block: 'nearest',
          inline: 'start',
        })
      })
    },
    onInput(e) {
      this.$emit('input', e.target.innerHTML)
      this.setMentions()
    },
    setMentions() {
      const mentions = []
      const mentionItems = this.$refs.editable.querySelectorAll('.mentions-item')
      mentionItems.forEach(mentionItem => {
        mentions.push({
          id: mentionItem.dataset.user,
          email: mentionItem.dataset.email,
          name: mentionItem.innerText,
        })
      })
      this.mentions = mentions
      this.$emit('mentions', mentions.map(a => a.id))
    },
    applyMention(item) {
      const mentionElement = document.createElement('span')
      mentionElement.contentEditable = false
      mentionElement.classList.add('mentions-item')
      mentionElement.dataset.user = item.value
      mentionElement.dataset.email = item.email
      mentionElement.id = item.value
      mentionElement.innerText = item.fullName || item.email
      mentionElement.title = item.email

      const range = window.getSelection().getRangeAt(0)
      range.setStart(range.startContainer, range.startOffset - 1)
      range.deleteContents()
      range.insertNode(mentionElement)
      range.setStart(range.endContainer, range.endOffset)

      this.$emit('input', this.$refs.editable.innerHTML)
      this.setMentions()
    },
  },
}
</script>

<style lang="scss">
  @import "@core/scss/base/bootstrap-extended/include"; // Bootstrap includes
  @import "@core/scss/base/components/include"; // Components includes

  @media screen and (max-width: 1191px) {
    .tooltip.popover.mentions {
      left: 180px !important;
    }
  }

  .mention-placeholder {
    top: 0;
    position: absolute;
    font-style: italic;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: #A3B0C2;
    pointer-events: none;
  }

  .mentions-input {
    background: #FFFFFF;
    box-sizing: border-box;
    border-radius: 4px;
    outline: none;
    white-space: normal;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: #031C3C;
  }

  .mentions-item {
    background: transparentize($primary, 0.9);
    color: $primary;
    display: inline-block;
    padding: 2px 1px;
    // margin: 0 4px;
    border-radius: 3px;
  }

  .mentions.popover .tooltip-inner {
    background: white !important;
    color: black;
  }

  // .b-tooltip-mention {
  //   pointer-events: none;
  //   // z-index: 100;
  //   // padding: 0;
  //   .tooltip-inner {
  //     box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.12);
  //     border-radius: 4px;
  //     font-size: 12px;
  //     color: #A3B0C2;
  //   }

  //   &.tooltip.bs-tooltip-bottom .arrow::before {
  //     border-bottom-color: white;
  //   }
  // }
</style>
