<template>
  <div class="panel-comments">
    <app-collapse class="comments-collapse narrow-collapse widget-collapse">
      <template v-for="field in fields">
        <app-collapse-item
          v-if="commentsFlat.filter(c => c.attrs.fieldAlias === field.alias || (field.alias === false && !c.attrs.fieldAlias)).length"
          :key="field.alias"
          :title="field.name"
          :is-visible="true"
        >
          <Comment
            v-for="comment in commentsFlat.filter(c => c.attrs.fieldAlias === field.alias || (field.alias === false && !c.attrs.fieldAlias))"
            :key="comment.id"
            :comment="comment"
            class="panel-comment"
          />
        </app-collapse-item>
      </template>
    </app-collapse>
  </div>
</template>

<script>
import { getBlocks } from '@/views/models/constants'
import { groupBy, orderBy, values } from 'lodash'
import moment from 'moment'
import Comment from '@/components/codex-editor/panels/Comment.vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'

export default {
  name: 'Comments',
  components: { AppCollapseItem, AppCollapse, Comment },
  props: {
    comments: {
      type: Array,
      default: () => [],
    },
    model: {
      type: Object,
      default: () => ({}),
    },
    fieldAlias: {
      type: [String, Boolean],
      default: null,
    },
  },
  computed: {
    fieldsOrder() {
      let comments = this.comments.reduce((acc, comment) => {
        acc.push({
          alias: comment.attrs.fieldAlias || '-----',
          createdAt: comment.createdAt,
        })
        if (comment?.children?.items?.length) {
          comment.children.items.forEach(child => {
            acc.push({
              alias: child.attrs.fieldAlias || '-----',
              createdAt: child.createdAt,
            })
          })
        }
        return acc
      }, [])

      comments = orderBy(comments, comment => moment(comment.createdAt).format('x'), ['desc'])

      const seenAliases = new Set()
      const orderByAlias = {}
      comments.filter(comment => {
        if (!seenAliases.has(comment.alias)) {
          seenAliases.add(comment.alias)
          return true
        }
        return false
      }).forEach((el, index) => {
        orderByAlias[el.alias] = index
      })

      return orderByAlias
    },
    fields() {
      let fields = getBlocks(this.model.blocks, e => e.isField) || []
      fields.push({
        attrs: {
          name: this.$t('entries.sidebar-tabs.comments.filter-options.entry'),
          alias: false,
        },
      })
      fields = orderBy(fields, f => this.fieldsOrder[f.attrs.alias || '-----'] ?? 9999, ['asc'])
      return fields.map(f => ({
        alias: f.attrs.alias,
        name: f.attrs.name,
      }))
    },
    commentsFlat() {
      let filteredComments = []
      if (this.fieldAlias === true) {
        filteredComments = this.comments
      } else if (this.fieldAlias === false) {
        filteredComments = this.comments.filter(c => !c.attrs?.fieldAlias)
      } else if (this.fieldAlias) {
        filteredComments = this.comments.filter(c => c.attrs?.fieldAlias === this.fieldAlias)
      }
      return orderBy(
        values(groupBy(filteredComments, c => c.attrs?.blockId)).reduce((flatList, blockComments) => [...flatList, ...blockComments], []),
        comment => moment(comment.createdAt).format('x'),
        ['asc'],
      )
    },
  },
}
</script>

<style lang="scss">
.comments-collapse.collapse-default div.card div.card-header .lead.collapse-title {
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  color: #031C3C;
  text-transform: uppercase;
}

</style>
