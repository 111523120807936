import Vue from 'vue'
import AutomationBase from './automation/AutomationBase'
import ActionBase from './actions/ActionBase'
import ConditionBase from './conditions/ConditionBase'

export default class WorkflowBase {
  constructor() {
    this.automation = new AutomationBase()
    this.conditions = []
    this.actions = [new ActionBase()]
  }

  fromData({
    automation = {},
    conditions = [],
    actions = [],
  }) {
    const o = {
      automation: new AutomationBase(automation),
      conditions: conditions.length ? conditions.map(c => new ConditionBase(c)) : [],
      actions: actions.length ? actions.map(a => {
        const parsedAction = JSON.parse(a.data)
        const actionBody = { id: a?.id, actionType: a?.actionType, ...parsedAction }
        const newAction = new ActionBase(actionBody)
        newAction.setProperties(a.actionType, actionBody)
        return newAction
      }) : [new ActionBase()],
    }
    Object.keys(o).forEach(key => {
      Vue.set(this, key, o[key])
    })
    return this
  }

  getPayload() {
    return {
      automation: this.automation.getPayload(),
      conditions: this.conditions,
      actions: this.actions.map(a => a.getPayload(this.automation?.id)).filter(Boolean),
    }
  }
}
