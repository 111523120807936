<template>
  <b-sidebar
    id="seo-analyzer-popup"
    v-model="showPopup"
    :static="true"
    right
    :title="$t('entries.ai-content-analyzer.title')"
    header-class="seo-modal-header"
  >
    <div style="height: 100%;">
      <div
        v-if="loading"
        class="ai-spinner-wrapper"
      >
        <b-spinner
          class="ai-spinner"
          variant="primary"
        />
        <p>{{ $t('general.loading') }}</p>
      </div>
      <b-tabs
        v-else
        class="content-analyzer-tabs"
      >
        <b-tab
          :title="$t('entries.ai-content-analyzer.seo-analysis')"
          title-item-class="tab-title"
        >

          <div class="tab-scores-content">
            <seo-score :seo="seo" />
            <ai-content-suggestions-vue
              :seo-feedback="seoFeedback"
              :loading="loading"
              :entry-id="entryId"
              :get-path="data.getPath"
              :fields="fields"
            />
          </div>
        </b-tab>
        <b-tab title-item-class="tab-title">
          <template #title>
            <div>
              <span>
                {{ $t('entries.ai-content-analyzer.sentiment-analysis') }}
              </span>
              <b-badge
                variant="danger"
                pill
                style="margin-left: 5px"
              >
                BETA
              </b-badge>
            </div>
          </template>
          <div class="tab-scores-content">
            <sentiment-score :entry-id="entryId" />
          </div>
        </b-tab>
      </b-tabs>
    </div>
  </b-sidebar>
</template>

<script>
import PopupsMixin from '@/components/popups/PopupsMixin'
import { generateSeoAiContent } from '@/codex-sdk/ai'
import { isNil } from '../image-editor/js/util'
import AiContentSuggestionsVue from './AiContentSuggestions.vue'
import SeoScore from './SeoScore.vue'
import SentimentScore from './SentimentScore.vue'

export default {

  name: 'SeoAnalyzerPopup',
  components: {
    AiContentSuggestionsVue,
    SeoScore,
    SentimentScore,
  },
  mixins: [PopupsMixin],
  data() {
    return {
      loading: false,
      seo: {
        contentScore: null,
        readability: null,
        keywordDensity: null,
        seoFeedback: [],
      },

    }
  },
  computed: {
    circleStyle() {
      const degrees = (this.seo.contentScore / 100) * 180
      return {
        transform: `rotate(${degrees}deg)`,
      }
    },
    readabilityScore() {
      return !isNil(this.seo.readability) ? `${this.seo.readability}%` : ''
    },
    keywordDensity() {
      return !isNil(this.seo.keywordDensity) ? `${this.seo.keywordDensity}%` : ''
    },
    contentScore() {
      return !isNil(this.seo.contentScore) ? `${this.seo.contentScore}%` : ''
    },
    seoFeedback() {
      return Array.isArray(this.seo.seoFeedback) ? this.seo.seoFeedback : []
    },
    entryId() {
      return !isNil(this.data.entryId) ? `${this.data.entryId}` : ''
    },
    fields() {
      return this.data.fields || []
    },

  },
  mounted() {
    this.getSEO()
  },

  methods: {
    async getSEO() {
      if (this.loading) return
      this.loading = true
      try {
        const { data } = await generateSeoAiContent({ entryId: this.data.entryId }, { successTitle: false })
        data.seoFeedback.forEach(element => {
          element.fieldName = this.fields.find(f => f.attrs.alias === element.fieldAlias)?.attrs?.name || element.fieldAlias
          element.isVisible = true
        })

        this.seo = data
      } catch (e) {
        console.log(e)
      }
      this.loading = false
    },
  },
}
</script>

<style lang="scss">
#seo-analyzer-popup {
  background-color: white !important;
  width: 500px;
  box-shadow: -5px 0 10px -3px rgba(0, 0, 0, 0.1),
  -4px 0 6px -1px rgba(0, 0, 0, 0.05);
  .content-analyzer-tabs{

    .tab-scores-content{
      display: flex;
      flex-direction: column;
      gap:16px;
      padding:16px;
    }
    ul{
      margin-bottom:0;

    }
  }
  .ai-spinner-wrapper{
    height: 100%;
    width:100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap:4px;

    p{
      font-size:12px;
    }
  }

  .seo-analyzer {

    display: flex;
    flex-direction: column;
    gap: 32px;
  }

  .modal-content {
    border-radius: 12px !important;
  }

  .seo-modal-title {
    font-size: 18px !important;
    line-height: unset !important;
  }

  .seo-modal-header{
    background-color: #F7F7F8;
    border-bottom: 1px solid #EBEBED;
    flex-direction: row-reverse;
    #seo-analyzer-popup___title__{
      font-size:14px;
    }
    button{
      margin-left:auto;
      margin-right:0px
    }

  }

  .seo-analyzed-content {
    display: flex;
    align-items: flex-end;
    padding: 14px 18px 24px 18px;
    border: solid 1px #ebebed;
    border-radius: 6px;
  }

  .scores {
    width: 100%;
    border-left: solid 1px #ebebed;
    padding-left: 16px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    gap: 16px;
  }

  .score {
    position: relative;
    .score-title{
      font-size:14px;
      font-weight:500;
    }
  }

  .score-circle {
    width: 161.8px;
    height: 80.5px;
    border-top-left-radius: 110px;
    border-top-right-radius: 110px;
    border-bottom: 0;
    overflow: hidden;
  }

  .score-circle-overlay {
    position: absolute !important;
    top: 0px;
    transition: transform 1s;

  }

  .progress {
    height: 4px !important;
    border-radius: 20px;
    margin-top: 6px;
  }

  .progress-bar-overlay {
    height: 100%;
    margin-top: 0;
    background-image: linear-gradient(to right, #206ed5, #9747ff);
    transition: width 1s ease-in-out;
  }

  .percentage {
    position: absolute;
    top: 0px;
    right: 0px;
    font-weight: 500;
    font-size: 14px;;
  }

  .modal-body {
    padding: 0 !important;
  }

  .content-score {
    padding-right: 16px;
    gap: 16px;

    h2 {
      font-size:16px !important;
      line-height: unset !important;
      font-weight: 500;
    }
    .description{
      font-size:14px;
    }
  }

  .seo-popup-close {
    position: absolute !important;
    right: 12px;
    top: 12px;
    background-color: #f5f5f6;
    height: 28px;
    width: 28px;
    cursor: pointer;
    border-radius: 100%;
    padding:4px;
  }
}
</style>
