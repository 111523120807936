<template>
  <svg
    width="30"
    height="24"
    viewBox="0 0 30 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15 0L0 24H30L15 0Z"
      :fill="color || '#667C99'"
    />
  </svg>
</template>

<script>
export default {
  name: 'TriangleFill',
  props: {
    color: {
      type: String,
      default: '#667C99',
    },
  },
}
</script>
