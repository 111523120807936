import Vue from 'vue'
import { BootstrapVue, ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import VueApollo from 'vue-apollo'

import i18n from '@/libs/i18n'
import { apolloProvider } from '@/libs/vue-apollo'
import router from './router'
import store from './store'
import App from './App.vue'
// Global Components
import './global-components'

// Directives
import './directives'

// Global Filters
import './filters'

// 3rd party plugins
import '@axios'
import '@/libs/acl'
import '@/libs/portal-vue'
import '@/libs/clipboard'
import '@/libs/toastification'
// import '@/libs/sweet-alerts'
import '@/libs/vue-select'
import '@/libs/tour'
import '@/libs/flatpickr'

// Plugins
import './plugins/index'

// Register global mixin
import PermissionsMixin from './mixins/permissions'

Vue.mixin(PermissionsMixin)

// Boostrap Vue
Vue.use(BootstrapVue, {
  BDropdown: {
    popperOpts: {
      positionFixed: true,
    },
    boundary: 'window',
  },
  BFormInput: {
    autocomplete: 'off',
  },
  BInput: {
    autocomplete: 'off',
  },
  BModal: {
    noEnforceFocus: true,
  },
  BTooltip: {
    popperOpts: {
      positionFixed: true,
    },
    boundary: 'window',
    customClass: 'b-tooltip-non-interactive',
  },
})

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

// Vue Apollo
Vue.use(VueApollo)

// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require('@core/assets/fonts/feather/iconfont.css') // For form-wizard

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  apolloProvider,
  render: h => h(App),
}).$mount('#app')
