<template>
  <div>
    <b-form-group
      :label="$t('fields.codex-field-number.general.field-name')"
      label-for="number-field__name"
    >
      <b-form-input
        id="number-field__name"
        v-model="name"
        :placeholder="$t('fields.codex-field-number.general.enter-field-modal-name')"
      />
    </b-form-group>

    <b-form-group
      :label="$t('fields.codex-field-number.general.field-alias')"
      label-for="number-field__alias"
    >
      <b-form-input
        id="number-field__alias"
        :value="alias"
        :placeholder="$t('fields.codex-field-number.general.enter-field-alias-name')"
        disabled
      />
    </b-form-group>

    <b-form-group
      :label="$t('fields.codex-field-number.general.description')"
      label-for="number-field__description"
    >
      <b-form-textarea
        id="number-field__description"
        v-model="description"
        :placeholder="$t('fields.codex-field-number.general.enter-description')"
        rows="2"
        no-resize
      />
    </b-form-group>

    <b-form-group :label="$t('fields.codex-field-number.general.numbers')">
      <b-form-radio-group
        v-model="getType"
        disabled
      >
        <b-form-radio :value="NUMBER_TYPES.INTEGER">
          {{ $t('fields.codex-field-number.general.integer') }}
        </b-form-radio>
        <b-form-radio :value="NUMBER_TYPES.DECIMAL">
          {{ $t('fields.codex-field-number.general.decimal') }}
        </b-form-radio>
      </b-form-radio-group>
    </b-form-group>

    <b-form-group :label="$t('fields.codex-field-number.general.display')">
      <b-form-radio-group
        v-model="getValueType"
        disabled
      >
        <b-form-radio :value="VALUE_TYPES.SINGLE">
          {{ $t('fields.codex-field-number.general.single') }}
        </b-form-radio>
        <b-form-radio :value="VALUE_TYPES.LIST">
          {{ $t('fields.codex-field-number.general.list') }}
        </b-form-radio>
      </b-form-radio-group>
    </b-form-group>
  </div>
</template>

<script>
import { generateComputedPropsFromAttrs } from '@/components/codex-layout-editor/BuilderUtils'
import { VALUE_TYPES } from '@/views/models/constants'
import BaseFieldMixin from '@/components/fields/BaseFieldMixin'
import { NUMBER_TYPES } from './index'

export default {
  name: 'General',
  mixins: [BaseFieldMixin],
  data() {
    return {
      NUMBER_TYPES,
      VALUE_TYPES,
      tempValue: '',
    }
  },
  computed: {
    ...generateComputedPropsFromAttrs([
      'type',
      'valueType',
      'numberType',
      'name',
      'alias',
      'description',

      'predefinedValues',
    ]),
    getType: {
      get() {
        return this.widget.attrs.type
      },
      set() { },
    },
    getValueType: {
      get() {
        return this.widget.attrs.valueType
      },
      set() {},
    },
  },
}
</script>
