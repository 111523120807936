<template>
  <v-select
    v-model="setUsers"
    :options="userList && userList.length ? userList.filter((v, i, a) => a.findIndex(v2 => (v2.id === v.id)) === i) : []"
    label="fullName"
    multiple
    :clearable="typeof clearable === 'boolean' ? clearable : true"
    class="team-select__parent"
    :close-on-select="false"
    deselect-from-dropdown
    :filterable="false"
    @close="onClose()"
    @search="(e) => search = e"
    @open="onOpen()"
  >
    <template #list-header>
      <div class="user-select__list-header">
        <small
          v-if="!showSelectedToggler"
          :class="{ 'user-select__list-selected' : selectAll }"
          class="text-secondary selected__button"
          @click="selectAllToggle"
        >
          {{ selectAll ? $t('teams.user-select.clear-all') : $t('teams.user-select.select-all') }}
        </small>
        <small
          v-if="selectedUsers.length > 0 && !selectAll"
          :class="{ 'user-select__list-selected' : showSelectedToggler }"
          class="text-secondary selected__button"
          @click="showSelectedUsers"
        >
          {{ showSelectedToggler ? $t('teams.user-select.show-all') : $t('teams.user-select.show-selected', {count: counter}) }}
        </small>
      </div>
    </template>
    <template #no-options>
      {{ $t('teams.user-select.no-results-found', { name:search }) }}
    </template>
    <template v-slot:option="option">
      <div
        class="team-select__dropdown-container"
        style="display: flex;"
      >
        <b-avatar
          :src="option.imageUrl"
          :alt="option.fullName"
          class="team-select__dropdown-image"
        />
        <div class="team-select__dropdown-name">
          <p>
            {{ option.fullName }}
          </p>
        </div>
      </div>
    </template>
    <template #selected-option="{imageUrl,fullName}">
      <div class="team-select__option">
        <b-avatar
          :src="imageUrl"
          :alt="fullName"
          class="team-select__image"
        />
        <p class="team-select__name">
          {{ fullName }}
        </p>
      </div>
    </template>
    <template #list-footer>
      <div
        ref="load"
        class="loader"
      >
        <b-spinner
          v-show="$apollo.loading"
          label="Spinning"
        />
      </div>
    </template>
  </v-select>
</template>

<script>
import gql from 'graphql-tag'

export default {
  name: 'UsersSelect',
  props: ['value', 'multiple', 'clearable'],
  data() {
    return {
      observer: null,
      offset: 0,
      limit: 20,
      search: null,
      totalCount: 0,
      users: [],
      selectedUsers: [],
      selectAll: false,
      showSelectedToggler: false,
    }
  },
  apollo: {
    userList: {
      query: gql`
        query Users($offset:Int, $limit: Int, $query: String) {
           userCollection(offset:$offset, limit:$limit, where: {query: $query } ){
            total
              items {
              id
              imageUrl
              email
              firstName
              lastName
            }
           }
        }
      `,
      variables() {
        return {
          offset: this.offset,
          limit: this.limit,
          query: this.search,
        }
      },
      fetchPolicy: 'network-only',
      update: data => data.userCollection.items,
      result({ data }) {
        if (data && data.userCollection && data.userCollection.total) {
          data.userCollection.items.forEach(user => {
            user.fullName = `${user.firstName || ''} ${user.lastName || ''}`.trim()
          })
          this.totalCount = data.userCollection.total
        }
      },
    },
  },
  computed: {
    setUsers: {
      get() {
        return this.selectedUsers
      },
      set(v) {
        this.selectedUsers = v
        this.$emit('input', v.map(i => i.id))
      },
    },
    counter() {
      return this.showSelectedToggler ? this.totalCount : this.selectedUsers.length
    },
  },
  mounted() {
    this.observer = new IntersectionObserver(this.infiniteScroll)
  },
  methods: {
    async selectAllToggle() {
      this.selectAll = !this.selectAll
      if (this.selectAll) {
        await this.fetchMembers('all')
        this.showSelectedToggler = false
      } else if (!this.showSelectedToggler) {
        this.setUsers = []
      }
    },
    async showSelectedUsers() {
      this.showSelectedToggler = !this.showSelectedToggler
      if (this.showSelectedToggler && !this.selectAll) {
        await this.fetchMembers('selected')
        this.selectAll = false
      } else {
        await this.fetchMembers('default')
      }
    },
    async onOpen() {
      if (this.userList && this.totalCount && this.userList.length <= this.totalCount) {
        await this.$nextTick()
        this.observer.observe(this.$refs.load)
      }
    },
    async fetchMembers(type) {
      const { data } = await this.$apollo.query({
        query: gql`
        query ($ids: [String!], $limit: Int!){
          userCollection (limit: $limit, where: {id: {in: $ids}}) {
            total
            items {
              id
              imageUrl
              email
              firstName
              lastName
            }
          }
        }
      `,
        variables: {
          ids: type !== 'selected' ? [] : this.selectedUsers.map(user => user.id),
          limit: type !== 'default' ? 10000 : this.limit,
        },
      })
      if (type === 'all') {
        this.setUsers = data.userCollection.items
      }
      this.totalCount = data.userCollection.total
      data.userCollection.items.forEach(user => {
        user.fullName = `${user.firstName || ''} ${user.lastName || ''}`.trim()
      })

      this.userList = data.userCollection.items
    },
    onClose() {
      this.observer.disconnect()
    },
    async infiniteScroll([{ isIntersecting, target }]) {
      try {
        if (isIntersecting && this.userList.length < this.totalCount) {
          await this.$apollo.queries.userList.fetchMore({
            variables: {
              offset: this.userList?.length,
            },
            updateQuery: (prev, { fetchMoreResult }) => {
              if (!fetchMoreResult) return prev
              this.userList = [...this.userList, ...fetchMoreResult.userCollection.items]
              this.totalCount = fetchMoreResult.userCollection.total
              return {
                userCollection: {
                  items: [...prev.userCollection.items, ...fetchMoreResult.userCollection.items],
                  total: fetchMoreResult.userCollection.total,
                  __typename: 'CodexUserCollection',
                },
              }
            },
          })
          const parent = target.parentNode
          target.parentNode.scrollTo({
            top: parent.scrollHeight - 500,
          })
        }
      } catch (e) {
        console.log(e)
      }
    },
  },
}
</script>

<style lang="scss">
.loader {
  text-align: center;
  color: #bbbbbb;
}

.vs__dropdown-option--selected {
  .team-select__dropdown-name {
    color: white !important;
  }
}
.team-select__parent {
  margin-bottom: 12px;
  .vs__dropdown-menu {
    max-height: 190px !important;
  }
  .team-select__dropdown-container {
    height: fit-content;
    display: flex;
    align-items: center;
    .team-select__dropdown-name {
      color: #1D417B;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      p {
        padding-left: 4px;
        margin: auto;
      }
    }
  }

  .team-select__option {
    display: flex;
    align-items: center;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    .team-select__image {
      margin: 2px;
      width: 24px;
      height: 24px;
    }
    .team-select__name {
      padding-left: 4px;
      margin: auto;
      text-overflow: ellipsis;
    }
  }
}

.user-select__list-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  padding: 8px;
  width: 100%;

  .user-select__list-selected {
    color: #1D417B !important;
  }
  .selected__button {
    cursor: pointer;
  }
}

.team-select__parent {
  .vs__dropdown-toggle {
    max-height: 150px;
    overflow: scroll;
  }
}
</style>
