export default {
  ADD_SITE(state, site) {
    state.sites.push(site)
  },
  SET_SITES(state, sites) {
    state.sites = sites
  },
  UPDATE_SITE(state, site) {
    const siteIndex = state.sites.findIndex(p => p.id === site.id)
    if (siteIndex === -1) return
    Object.assign(state.sites[siteIndex], site)
  },
  REMOVE_SITE(state, siteId) {
    const siteIndex = state.sites.findIndex(p => p.id === siteId)
    state.sites.splice(siteIndex, 1)
  },
  REMOVE_SITES(state, siteIds) {
    siteIds.forEach(siteId => {
      const siteIndex = state.sites.findIndex(p => p.id === siteId)
      state.sites.splice(siteIndex, 1)
    })
  },
  CLEAR_SITES_STATE(state) {
    state.sites = []
  },
}
