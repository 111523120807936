<template>
  <v-select
    id="at-sections-create-parent-section-input"
    v-model="inputVal"
    class="sections-select"
    :options="sections ? sections.items.filter(s => s.show || s.id === inputVal) : []"
    :placeholder="placeholder"
    :multiple="multiple"
    label="title"
    :reduce="s => s.id"
    :class="classes"
    :close-on-select="true"
  >
    <template v-slot:option="option">
      <div :class="[option.collapsable ? 'table__collapse-arrow' : `table__collapse-no-arrow-${option.indent}`, `table__indent-${option.indent}`, 'd-flex']">
        <div @mousedown.stop.prevent="handleTableCollapse(option)">
          <GjIcon
            v-show="option.collapsable"
            :key="option.collapsed ? 'ArrowDown' : 'ArrowRight'"
            size="16"
            style="fill: #052D61;"
            :name="option.collapsed ? 'ArrowDown' : 'ArrowRight'"
          />
        </div>
        <span
          class="mb-0 table__collapse-name"
          :class="{'table__collapse-first': option.isFirstOfSet, 'section-name__no-arrow': !option.collapsable && !option.parentId}"
          :style="`${!option.collapsable && option.indent ? 'margin-left: 16px;' :''}`"
        >
          {{ option.title }}
        </span>
      </div>
    </template>
  </v-select>
</template>

<script>
import gql from 'graphql-tag'
import { cloneDeep } from 'lodash'

export default {
  props: ['value', 'placeholder', 'classes', 'multiple'],
  apollo: {
    sections: {
      query: gql`
        fragment Section on CodexSection {
          id
          title
          parentId
        }
        query Sections ($siteId: String) {
          sectionCollection (where: { siteId: {eq: $siteId }, parentId: {exists: false}}) {
            total
            items {
              ...Section
              children {
                items {
                  ...Section
                  children {
                    items {
                      ...Section
                      children {
                        items {
                          ...Section
                          children {
                            items {
                              ...Section
                              children {
                                items {
                                  ...Section
                                  children {
                                    items {
                                      ...Section
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `,
      variables() {
        return {
          siteId: this.$route.params.siteName,
        }
      },
      update(data) {
        return {
          ...data.sectionCollection,
          items: this.flatten(cloneDeep(data.sectionCollection.items)),
        }
      },
    },
  },
  computed: {
    inputVal: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
  },
  mounted() {
    this.fetchSections()
  },
  methods: {
    async fetchSections() {
      this.$apollo.queries.sections.refetch()
    },
    flatten(items, parentId, indent = 0) {
      for (let i = 0; i < items.length; i++) {
        const childItems = []

        if (items[i].indent === undefined) {
          items[i].indent = indent
        }

        if (parentId && items[i].parentId === undefined) {
          items[i].parentId = parentId
        }

        if (items[i].show === undefined) {
          items[i].show = indent === 0
        }

        if (items[i].children?.items?.length) {
          items[i].collapsable = true
          items[i].collapsed = false
          childItems.push(...this.flatten(items[i].children?.items, items[i].id, indent + 1))

          childItems[0].isFirstOfSet = true
        }

        delete items[i].children
        items = [...items.slice(0, i + 1), ...childItems, ...items.slice(i + 1)]
      }

      return items
    },
    handleTableCollapse(item, state = undefined) {
      this.sections.items = this.sections.items.map(section => {
        if (section.id === item.id) {
          section.collapsed = state === undefined ? !section.collapsed : state
        }

        if (section.parentId === item.id) {
          section.show = state === undefined ? !section.show : state

          if ((state === undefined && !section.show) || state === false) {
            this.handleTableCollapse(section, false)
          }
        }

        return section
      })
    },
  },
}
</script>

<style lang="scss" >
@import "@core/scss/base/bootstrap-extended/include"; // Bootstrap includes

#at-sections-create-parent-section-input {
  .vs__selected-options{
    width: 100%;
      .vs__selected {
        word-break: break-all;
      }
  }

.table__collapse-arrow {
  position: relative;
}

.table__collapse-arrow-button {
  position: absolute;
  left: -16px;
  top: 5px;
  cursor: pointer;
  transition: opacity 0.3s;

  &:hover {
    opacity: 0.6;
  }
}

.sections__vgt {
  .vgt-table td {
      padding-top: 6px;
      padding-bottom: 6px;
  }
  .table__collapse-name {
    font-size: 14px;
  }
  tbody {
    tr:hover {
      background-color: #1d79f210;
    }
  }
  .b-dropdown {
    > .dropdown-toggle {
      height: 26px;
      padding-left: 5px;
      padding-right: 5px;
    }
  }
}

.table__collapse-link {
  color: #052D61;
}

// INDENT

.section-name__no-arrow{
  padding-left: 1.15em;
}

.table__collapse-first {
  position: relative;
  &::before {
    width: 12px;
    height: 12px;
    position: absolute;
    border-bottom: 1px solid #E0E5EB;
    border-left: 1px solid #E0E5EB;
    top: 0;
    left: -28px;
  }
  .table__collapse-name {
    cursor: pointer;
    font-size: 14px;

    &:hover {
      color: $primary;
    }
  }
}

@for $i from 1 through 20 {
  .table__indent-#{$i} {
      margin-left: $i * 24px;
      .table__collapse-first {
        &::before {
          content: '';
          left: -32px;
        }
      }
  }

  .table__collapse-no-arrow-#{$i} {
    margin-left: $i * 22px;
    .table__collapse-first {
      &::before {
        content: '';
        left: -14px;
      }
    }
  }
}
}
</style>
