<template>
  <div class="entry-edit--status-container">
    <div
      class="entry-badge-container"
      :class="{'entry-badge-container__background': withBackground}"
    >
      <span
        class="entry-badge"
        :class="entryStatusClass"
      >
        <span v-if="!noText">
          {{ $t(entryStatus) | capitalize }}
        </span>
      </span>
    </div>
    <div
      v-if="scheduledVersion"
      v-b-tooltip="scheduledVersion.unpublishScheduledDate ?
        $t('entries.scheduled-versions.unpublishAt', { datetime: $options.filters.formatDateTime(scheduledVersion.unpublishScheduledDate) }) :
        $t('entries.scheduled-versions.publishAt', { datetime: $options.filters.formatDateTime(scheduledVersion.publishScheduledDate) })"
      class="entries-field-status-schedules"
      :class="{
        'entries-field-status-schedules__unpublish': scheduledVersion.unpublishScheduledDate,
        'entries-field-status-schedules__publish': scheduledVersion.publishScheduledDate
      }"
    >
      <GjIcon
        v-if="scheduledVersion"
        name="Calendar"
        size="14"
      />
    </div>
    <div
      v-else
      class="entries-field-status-schedules"
    />
  </div>
</template>

<script>
import { ENTRY_STATUSES, mapEntryStatus } from '@/codex-sdk/entries'
import moment from 'moment'
import { orderBy } from 'lodash'

export default {
  props: {
    entry: {
      type: Object,
      default: null,
    },
    noText: {
      type: Boolean,
      default: false,
    },
    isQuery: Boolean,
    withBackground: Boolean,
    scheduledVersions: {
      type: Array,
      default: () => [],
    },
    isScheduleStatus: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    schedulesStatus() {
      return {
        [ENTRY_STATUSES.PUBLISHED]: this.$t('entries.scheduled-versions.statuses.publish'),
        [ENTRY_STATUSES.UNPUBLISHED]: this.$t('entries.scheduled-versions.statuses.unpublish'),
      }[this.entry.system.status]
    },
    entryStatus() {
      if (!this.entry.id) return this.$t('entries.statuses.unsaved')
      if (this.isScheduleStatus) return this.schedulesStatus
      if (this.isQuery) return mapEntryStatus(ENTRY_STATUSES[this.entry.system.status])
      return mapEntryStatus(this.entry.system.status)
    },
    entryStatusClass() {
      if (!this.entry.id) return 'entries.statuses.unsaved'
      if (this.isQuery) return `status-${ENTRY_STATUSES[this.entry.system.status]}`
      return `status-${this.entry.system.status}`
    },
    scheduledVersion() {
      const aggregates = this.scheduledVersions?.filter(r => moment(r.publishScheduledDate).isAfter(moment()) || moment(r.unpublishScheduledDate).isAfter(moment())).map(s => ({
        ...s,
        scheduleDate: s.publishScheduledDate || s.unpublishScheduledDate,
      })) || []
      return orderBy(aggregates, ['scheduleDate'], 'asc')?.[0]
    },
  },
}
</script>

<style lang="scss" scoped>
.entry-edit--status-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-grow: 0;
  gap: 8px;
}

.entries-field-status-schedules {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 22px;
  height: 22px;
  border-radius: 50%;
}

.entries-field-status-schedules__unpublish {
  background-color: #EDEFF3;

  svg {
    color: #031C3C;
  }
}

.entries-field-status-schedules__publish {
  background-color: #D7F0E5;

  svg {
    color: #031C3C;
  }
}

.entry-badge-container__background {
  .entry-badge {
    padding: 2px 8px;

    &.status-3 {
      // Draft
      background: rgba(128, 86, 0, 0.1);
    }

    &.status-4 {
      // Unpublished
      background-color: #EDEFF3
    }

    &.status-1 {
      // Published
      background-color: #D7F0E5;
    }

    &.status-6 {
      // Scheduled
      background: rgba(128, 86, 0, 0.1);
    }

    &.status-7 {
      // Edited
      background-color: #ECF3FF;
    }

    &.status-2 {
      // Deleted
      background-color: #FDEDEE;
    }
  }
}

.entry-badge-container {
  display: flex;

  .entry-badge {
    position: relative;
    display: flex;
    align-items: center;
    border-radius: 15px;
    color: #052D61;

    span {
      margin: 0;
      font-size: 12px;
      color: #031C3C;
      font-weight: 400;
      line-height: 18px;
    }
    &::before {
      content: '';
      width: 6px;
      display: flex;
      height: 6px;
      border-radius: 500px;
      margin-right: 10px;
    }

    &.status-3::before {
      // Draft
      background-color: #FFAB00;
    }

    &.status-4::before {
      // Unpublished
      background-color: #A3B0C2;
    }

    &.status-1::before {
      // Published
      background-color: #36B37E;
    }

    &.status-6::before {
      // Scheduled
      background-color: #FFAB00;
    }

    &.status-7::before {
      // Edited
      background-color: #1D79F2;
    }

    &.status-2::before {
      // Deleted
      background-color: #E34850;
    }
  }
}
</style>
