<template>
  <div @scroll="loadMore">
    <draggable
      v-if="entries && entries.length > 0"
      id="entries-list--list"
      v-model="entries"
      class="entries-list--list"
      :group="{pull: false, push: false}"
      @move="() => isMobile ? null : true"
    >
      <template v-for="(element,index) in entries">
        <EntryDraggableElement
          v-if="(type === LIST_TYPES.ENTRY || type === LIST_TYPES.LEGACY) && element.entry"
          v-show="showOnlyActiveEntries && !isActive(element) ? false : true"
          :key="element.entry.id + index"
          v-model="entries"
          :element="element"
          :index="index"
          @moveItemToTop="moveItemToTop"
          @moveItemToBottom="moveItemToBottom"
          @moveItemDown="moveItemDown"
          @moveItemUp="moveItemUp"
          @remove="removeEntity"
        />
        <AuthorDraggableElement
          v-else-if="type === LIST_TYPES.AUTHOR && element.author"
          v-show="showOnlyActiveEntries && !isActive(element) ? false : true"
          :key="element.author.id + index"
          v-model="entries"
          :element="element"
          :index="index"
          @moveItemToTop="moveItemToTop"
          @moveItemToBottom="moveItemToBottom"
          @moveItemDown="moveItemDown"
          @moveItemUp="moveItemUp"
          @remove="removeEntity"
        />
      </template>
    </draggable>
  </div>
</template>

<script>
import moment from 'moment'
import draggable from 'vuedraggable'
import EntryDraggableElement from '@/components/lists/EntryDraggableElement.vue'
import AuthorDraggableElement from '@/components/lists/AuthorDraggableElement.vue'
import { LIST_TYPES } from '@/codex-sdk/lists'

export default {
  name: 'EntriesListsDraggable',
  components: {
    AuthorDraggableElement,
    EntryDraggableElement,
    draggable,
  },
  props: {
    value: Array,
    showOnlyActiveEntries: Boolean,
    currentDatetime: [Date, String],
    loading: Boolean,
    type: {
      type: String,
      default: LIST_TYPES.ENTRY,
    },
  },
  inject: ['showEntryEditorPopup'],
  computed: {
    LIST_TYPES() {
      return LIST_TYPES
    },
    isMobile() {
      return window.innerWidth < 768
    },
    entries: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit('input', v)
      },
    },
    activeList() {
      return this.entries.filter(e => this.isActive(e))
    },
  },
  watch: {
    entries: {
      handler(val, oldValue) {
        if (this.checkContentChange) {
          this.hasUnsavedChanges = val == oldValue
        }
        if (val) {
          val.forEach((e, index) => {
            e.order = index + 1
          })
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    loadMore() {
      if (this.loading) return
      const listContainer = document.getElementById('static-list-list-container').getBoundingClientRect().bottom
      const container = document.getElementById('entries-list--list').getBoundingClientRect().bottom - 1000

      if (container < listContainer) {
        this.$emit('loadMore')
      }
    },
    isActive(entry) {
      if (entry.startAt && entry.endAt) return moment(this.currentDatetime).isBetween(entry.startAt, entry.endAt)
      if (entry.startAt) return moment(this.currentDatetime).isSameOrAfter(entry.startAt)
      if (entry.endAt) return moment(this.currentDatetime).isSameOrBefore(entry.endAt)
      return true
    },
    moveItemUp(index, element) {
      if (index > 0) {
        const temp = this.entries[index - 1]
        this.$set(this.entries, index - 1, this.entries[index])
        this.$set(this.entries, index, temp)
        this.$nextTick(() => {
          this.$refs[`dropdown-${element.entry.id}-${index - 1}`][0].show()
        })
      }
    },
    moveItemDown(index, element) {
      if (index < this.entries.length - 1) {
        const temp = this.entries[index + 1]
        this.$set(this.entries, index + 1, this.entries[index])
        this.$set(this.entries, index, temp)
        this.$nextTick(() => {
          this.$refs[`dropdown-${element.entry.id}-${index + 1}`][0].show()
        })
      }
    },
    moveItemToTop(index, element) {
      if (index > 0) {
        const temp = this.entries[index]
        this.entries.splice(index, 1)
        this.entries.unshift(temp)
        this.$nextTick(() => {
          this.$refs[`dropdown-${element.entry.id}-${0}`][0].show()
        })
      }
    },
    moveItemToBottom(index, element) {
      if (index < this.entries.length - 1) {
        const temp = this.entries[index]
        this.entries.splice(index, 1)
        this.entries.push(temp)
        this.$nextTick(() => {
          this.$refs[`dropdown-${element.entry.id}-${this.entries.length - 1}`][0].show()
        })
      }
    },
    removeEntity(index) {
      this.entries = this.entries.filter((el, i) => i !== index)
    },
  },
}
</script>

<style lang="scss">
.entries-lists-draggable-element-order {
  position:absolute;
  left: 0;
  bottom: 0;
  border-radius: 50%;
  background-color: #fff;
  width: 30px;
  display: flex;
  justify-content: center;
  font-size: 10px;
  align-items: center;
}
</style>
