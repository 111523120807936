import axios from 'axios'

/**
 * Get Vimeo Embed object
 * */

/**
 *
 * @response
 * {
 *  type: String
 *  duration: Number,
 *  html: String,
 *  ...
 * }
 */
export function getVimeoEmbed({ url }) {
  return axios.get('https://vimeo.com/api/oembed.json', {
    params: {
      url,
    },
  }).then(response => response.data)
}
