import state from './modulesPluginsState'
import mutations from './modulesPluginsMutations'
import actions from './modulesPluginsActions'
import getters from './modulesPluginsGetters'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
