<template>
  <b-modal
    id="vp-uploader-disconnect__popup"
    v-model="showPopup"
    title="Integration Details"
    centered
    :ok-variant="showMoreDetails ? 'outline-secondary':'primary'"
    :ok-title="showPropperTitle"
    cancel-variant="outline-secondary"
    size="md"
    :ok-only="showMoreDetails"
    :ok-disabled="disableOk"
    :static="true"
    @ok="triggerPropperMethod"
    @cancel="handleCancel"
    @hide="handleCancel"
  >
    <div v-if="showMoreDetails">
      <div class="vp-integration__sidebar-info">
        <div class="integration-logo">
          <img
            src="@/assets/icons/vp.svg"
            alt=""
          >
        </div>
        <div class="vp-integration__sidebar-header">
          <p>{{ getIntegration.name }}</p>
          <small>{{ $t('integrations.vp-uploader-details') }}</small>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="vp-uploader-integration__info">
        <div class="integration-logo">
          <img
            src="@/assets/icons/vp.svg"
            alt=""
          >
        </div>
        <div class="vp-uploader-integration__popup-header">
          <p>
            {{ getIntegration.name }}
          </p>
          <small>
            {{ getIntegration.description }}
          </small>
          <p
            v-if="vpUploader.id"
            v-permission="['canDeleteIntegration']"
            class="disconnect-button"
            @click="disconnectVpUploader"
          >
            {{ $t('integrations.card-buttons.disconnect') }}
          </p>
        </div>
      </div>
      <div class="vp-connection__new">
        <b-form-group :label="$t('integrations.vp-uploader.connection-name')">
          <b-input
            v-model="vpUploader.attrs.connectionName"
            style="font-size:14px"
            autofocus
            :disabled="!!vpUploader.id"
            :placeholder="$t('integrations.vp-uploader.connection-name-placeholder')"
          />
        </b-form-group>
        <b-form-group :label="$t('integrations.vp-uploader.organization-name')">
          <VPOrganizationSelector
            v-model="vpUploader.attrs.organizationId"
            :has-permission="hasPermission"
            :disabled="!!vpUploader.id"
          />
        </b-form-group>
        <b-form-group :label="$t('integrations.vp-uploader.projects')">
          <vSelect
            v-model="vpUploader.attrs.projectIds"
            label="name"
            :clearable="false"
            :options="projects.items"
            :reduce="e => e.publicProjectId"
            :get-option-key="e => e.publicProjectId"
            class=""
            :disabled="!!vpUploader.id"
            :placeholder="$t('integrations.vp-uploader.projects-placeholder')"
            multiple
          >
            <template slot="no-options">
              {{ $t('integrations.vp-uploader.no-results-found') }}
            </template>
          </vSelect>
        </b-form-group>

        <p
          v-if="errorMessage"
          class="text-center text-danger m-0 text-sm"
        >
          {{ errorMessage }}
        </p>
      </div>
    </div>
  </b-modal>
</template>

<script>
import PopupsMixin from '@/components/popups/PopupsMixin'
import { vpUploaderDefaults } from '@/codex-sdk/all-integrations'
import { cloneDeep } from 'lodash'
import { createVPApiKey, readVPProjects } from '@/codex-sdk/assets'
import VPOrganizationSelector from '@/components/vp-components/VPOrganizationSelector.vue'
import { canEditIntegration } from '@/codex-permissions/integrations'
import { ENTITY_TYPES } from '@/utils/constants'

export default {
  components: { VPOrganizationSelector },
  mixins: [PopupsMixin],
  inject: ['showConfirmDeletePopup'],
  data() {
    return {
      vpUploader: vpUploaderDefaults(),
      hasPermission: true,
      projects: [],
      playerIds: [],
      errorMessage: '',
      filters: {
        page: 1,
        size: 1000,
      },
    }
  },
  computed: {
    getIntegration() {
      return this.data.integration
    },
    showMoreDetails() {
      return this.data.showMoreDetails
    },
    triggerPropperMethod() {
      if (this.showMoreDetails || !this.vpUploader) return this.handleCancel
      return this.handleOk
    },
    disableOk() {
      return !canEditIntegration().can || (!this.showMoreDetails && !!this.vpUploader.id)
    },
    vpUploaderConnections() {
      return this.$store.getters['general/getConnections'](this.getIntegration.alias) || {}
    },
    showPropperTitle() {
      if (!this.showMoreDetails && !this.vpUploader.id) {
        return this.$t('integrations.vp-uploader.connect')
      }

      if (!this.showMoreDetails && this.vpUploader.id) {
        return this.$t('integrations.vp-uploader.save')
      }

      return this.$t('integrations.vp-uploader.cancel')
    },
  },
  watch: {
    'vpUploader.attrs.organizationId': {
      handler(v) {
        if (v) {
          this.fetchVPProjects()
        }
      },
      deep: true,
    },
  },
  mounted() {
    if (this.vpUploaderConnections.length) {
      this.vpUploader = cloneDeep(this.vpUploaderConnections[0])
    }
  },
  methods: {
    handleCancel() {
      this.closePopup(this.vpUploader)
    },
    async fetchVPProjects() {
      this.loading = true
      if (this.vpUploader.attrs.organizationId) {
        this.filters.organizationId = this.vpUploader.attrs.organizationId
        try {
          const response = await readVPProjects(this.filters)
          this.projects = response.data.result
        } catch (err) {
          console.log(err)
        }
      }
    },
    async handleOk() {
      try {
        if (!this.vpUploader.id) {
          this.vpUploader.integration = this.getIntegration.alias
          const { data: { result: { apiKey } } } = await createVPApiKey({
            listProjectsId: this.vpUploader.attrs.projectIds,
            roleId: 3,
            userApiKey: {
              apiName: this.vpUploader.attrs.connectionName,
              hasExpireTime: false,
              startDateTime: new Date(),
            },
          }, this.vpUploader.attrs.organizationId, { successTitle: false })
          this.vpUploader.attrs.apiKey = apiKey
          this.$store.dispatch('general/addConnections', this.vpUploader)
        } else {
          this.$store.dispatch('general/updateConnections', this.vpUploader)
        }
      } catch (err) {
        console.log(err)
      }
    },
    async disconnectVpUploader() {
      const result = await this.showConfirmDeletePopup({
        items: [this.vpUploader.attrs.connectionName],
        type: ENTITY_TYPES.CONNECTION,
      })
      try {
        if (result) {
          this.$store.dispatch('general/removeConnections', this.vpUploader)

          this.vpUploader.attrs.connectionName = null
          this.closePopup(this.vpUploader)
        }
      } catch (err) {
        console.log(err)
      }
    },
  },
}
</script>
<style lang='scss' scoped>

.vp-uploader-integration__info{
  display: flex;
  width: 100%;
  align-items: flex-start;
  gap: 30px;
  margin: 3% auto;

  img{
    width: 36px;
    height: 36px;
  }

  .integration-logo{
    background: linear-gradient(
        180deg,
        #d9d9d9 0%,
        #f3f2f4 0.01%,
        #ebddfd 100%
    );
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
  }

  .vp-uploader-integration__popup-header{
    p {
      font-weight: 500;
      font-size: 18px;
      color: #052D61;
      margin-bottom: 5px;
    }

    small{
      font-size: 14px;
      color: #667C99;
    }

    .disconnect-button{
      font-weight: 600;
      font-size: 14px;
      background: #E34850;
      padding: 8px 14px;
      color: white;
      width: fit-content;
      border-radius: 42px;
      margin-top: 3%;
      cursor: pointer;
    }
  }
}
</style>
