import ability from '@/libs/acl/ability'
import { getUserData } from '@/auth/utils'
import { pr } from './config'

/**
webhook_create
webhook_view
webhook_edit
webhook_delete
 */
// For regEx search in VSCode: canViewWebhook|canCreateWebhook|canEditWebhook|canDeleteWebhook

// * Create entity file, and can functions
// * Import and add them to allCanFunctions object down below
// * Add defaults into the permissions array down below
// * Implement v-permission directives on neccessary buttons
// * Implement permissions on entities sdk functions
// * Implement permissions on navigation and route

export const Webhook = Object.freeze({
  VIEW: 'webhook_view',
  CREATE: 'webhook_create',
  EDIT: 'webhook_edit',
  DELETE: 'webhook_delete',
})

export const WebhookTooltips = {
  [Webhook.VIEW]: '',
  [Webhook.CREATE]: '',
  [Webhook.EDIT]: '',
  [Webhook.DELETE]: '',
}

function isCurrentUser(webhook) {
  const userId = webhook?.createdBy

  const currentUserData = getUserData()
  if (!currentUserData) return false

  return userId == currentUserData.id
}

/**
 * _options
 *    entity = undefined
 *    showToast = false
 */

/**
 * Can VIEW Webhooks
 * */
export function canViewWebhooks(_options = {}) {
  const { entity, ...options } = _options

  return pr(
    ability.can(Webhook.VIEW),

    'You have no permission to view webhooks',

    options,
  )
}

/**
 * Can CREATE Webhook
 * */
export function canCreateWebhook(_options = {}) {
  const { entity, ...options } = _options

  return pr(
    ability.can(Webhook.CREATE),

    'You have no permission to create webhook',

    options,
  )
}

/**
 * Can EDIT Webhook
 * */
export function canEditWebhook(_options = {}) {
  const { entity, ...options } = _options

  return pr(
    ability.can(Webhook.EDIT)
    || (ability.can(Webhook.CREATE) && isCurrentUser(entity)),

    'You have no permission to edit webhook',

    options,
  )
}

/**
 * Can DELETE Webhook
 * */
export function canDeleteWebhook(_options = {}) {
  const { entity, ...options } = _options

  return pr(
    ability.can(Webhook.DELETE),

    'You have no permission to delete this webhook',

    options,
  )
}
