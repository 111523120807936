import { enumMapping } from '@/utils/helpers'
import { checkPermission, tc } from './config'
import { apiAxios as axios } from '../libs/axios'

/**
 * Constants
 */
export const apiKeyStatuses = Object.freeze({
  ACTIVE: 1,
  DELETED: 2,
  REVOKED: 3,
  EXPIRED: 4,
})

export const API_KEY_STATUS_MAPPING = enumMapping({
  ACTIVE: apiKeyStatuses.ACTIVE,
  DELETED: apiKeyStatuses.DELETED,
  REVOKED: apiKeyStatuses.REVOKED,
  EXPIRED: apiKeyStatuses.EXPIRED,
})

/**
 * Helpers
 */

export function mapApiKeyStatuses(status) {
  const statuses = {
    [apiKeyStatuses.ACTIVE]: this.$t('apiKeys.status.active'),
    [apiKeyStatuses.DELETED]: this.$t('apiKeys.status.deleted'),
    [apiKeyStatuses.REVOKED]: this.$t('apiKeys.status.revoked'),
    [apiKeyStatuses.EXPIRED]: this.$t('apiKeys.status.expired'),
  }
  return statuses[status] || apiKeyStatuses.REVOKED
}

export function apikeyDefaults(apiKey = {}) {
  return {
    name: '',
    expiresAt: '',
    teams: [],
    ...apiKey,
  }
}

/**
 * Transforms to received data
 */
export function transformApikeyFromRequest(apiKey) {
  return apiKey
}

/**
 * CRUD Operations
 * */

/**
 *
 * @response
 * [{
 *  id: GUID
 *  name: String
 * }, {...}, ...]
 */
export function readApiKeys({ page = null, pageSize = null, query } = {}) {
  return axios.get('/{organization}/api-keys', {
    params: {
      Page: page,
      Size: pageSize,
      Q: query,
    },
  }).then(response => {
    response.data.items = response.data.items.map(apiKey => transformApikeyFromRequest(apiKey))
    return response
  })
}

/**
 * CRUD Operations
 * */

/**
 *
 * @param {*} role
 *
 * @response
 * {
 *  id: GUID
 *  errors: null | Object
 *  success: true | false
 * }
 */
export function createApiKey(apiKey, messages) {
  return checkPermission('canCreateApiKey')
  && tc(axios.post('/{organization}/api-keys', apiKey), {
    successTitle: 'sdk.success-title',
    successMessage: 'sdk.api-key.create-successful',
    failTitle: 'sdk.api-key.create-failed',
    catchError: false,
    ...messages,
  })
}
/**
 * CRUD Operations
 * */

/**
 *
 * @response
 * [{
 *  id: GUID
 *  name: String
 * }, {...}, ...]
 */
// eslint-disable-next-line consistent-return
export async function readApiKey(id) {
  try {
    const response = await axios.get(`/{organization}/api-keys/${id}`)
    response.data = transformApikeyFromRequest(response.data)
    return response
  } catch (e) {
    console.log(e)
  }
}

/**
 *
 * @param {*} user
 *
 * @response
 * {
 *  id: GUID
 *  errors: null | Object
 *  success: true | false
 * }
 */
// eslint-disable-next-line consistent-return
export function updateApiKey(apikey, messages) {
  return checkPermission('canEditApiKeyPermissions')

  && tc(axios.put(`/{organization}/api-keys/${apikey.id}`, apikey), {
    successTitle: 'sdk.success-title',
    successMessage: 'sdk.api-key.update-successful',
    failTitle: 'sdk.api-key.update-failed',
    catchError: false,
    ...messages,
  })
}

/**
 *
 * @param {*} user
 *
 * @response
 * {
 *  id: GUID
 *  errors: null | Object
 *  success: true | false
 * }
 */
// eslint-disable-next-line consistent-return
export function revokeApiKey(apikey, messages) {
  return checkPermission('canRevokeApiKey')

  && tc(axios.put(`/{organization}/api-keys/${apikey}/revoke`, apikey), {
    successTitle: 'sdk.success-title',
    successMessage: 'sdk.api-key.revoke-successful',
    failTitle: 'sdk.api-key.revoke-failed',
    catchError: false,
    ...messages,
  })
}

/**
 *
 * @param {*} user
 *
 * @response
 * {
 *  id: GUID
 *  errors: null | Object
 *  success: true | false
 * }
 */
// eslint-disable-next-line consistent-return
export function regenerateApiKey(apikey, messages) {
  return checkPermission('canRegenerateApiKey')

  && tc(axios.put(`/{organization}/api-keys/${apikey}/regenerate`, apikey), {
    successTitle: 'sdk.success-title',
    successMessage: 'sdk.api-key.regenerate-successful',
    failTitle: 'sdk.api-key.regenerate-failed',
    catchError: false,
    ...messages,
  })
}

/**
 *
 * @response
 * [{
 *  id: GUID
 *  name: String
 * }, {...}, ...]
 */

export function deleteApiKey(id, messages = {}) {
  return checkPermission('canDeleteApiKey')

  && tc(axios.delete(`/{organization}/api-keys/${id}`), {
    successTitle: 'sdk.success-title',
    successMessage: 'sdk.api-key.delete-successful',
    failTitle: 'sdk.api-key.delete-failed',
    catchError: false,
    ...messages,
  })
}
