import CodexContentEditor from '../../CodexContentEditor'
import GooglePodcast from './GooglePodcast.vue'
import GooglePodcastPanel from './GooglePodcastPanel.vue'
import { BLOCK_GROUPS } from '../constants'

CodexContentEditor.registerWidget('codex', {
  displayName: 'codex-editor.block-names.google-podcast',
  name: 'google_podcast',
  group: BLOCK_GROUPS.EMBEDS,
  // eslint-disable-next-line global-require
  image: require('../../icons/google-podcast/icon.svg'),
  attrs: {
    blockId: {
      default: null,
    },
    src: {
      default: null,
    },
    align: {
      default: 'left',
    },
    size: {
      default: 'small',
    },
  },
  renderEditor: GooglePodcast,
  renderEditorBar: GooglePodcastPanel,
})
