<template>
  <div
    class="media-image__details"
    style="text-align: left;"
  >
    <p
      v-if="media.createdAt"
      class="media-image__secondary media-image__secondary--small"
    >
      {{ media.createdAt |formatDate() }}
    </p>
    <p
      v-if="media.source"
      class="media-image__secondary"
    >
      {{ media.source }}
    </p>
    <p
      v-if="media.author"
      class="media-image__secondary"
    >
      {{ media.author }}
    </p>
    <div
      v-if="media.labels && media.labels.length > 0"
      class="media-image__labels"
    >
      <div
        v-for="label in media.labels.slice(0, 2)"
        :key="label.id"
        class="media-image__label"
      >
        <GjIcon
          :key="label.id"
          name="IconparkDot"
          :color="label.color"
          size="10"
        />
        {{ label.name }}
      </div>
      <b-dropdown
        v-if="media.labels.length > 2"
        size="sm"
        variant="outline"
        class="media-image__labels-dropdown"
        toggle-class="btn-gradient-no-gradient"
        no-caret
      >
        <template #button-content>
          <GjIcon
            name="Moreoptions"
            size="10px"
          />
        </template>
        <b-dropdown-item
          v-for="label in media.labels.slice(2)"
          :key="label.id"
          @click.native.capture.stop.prevent
        >
          <GjIcon
            :key="label.id"
            name="IconparkDot"
            :color="label.color"
            size="10"
          />
          {{ label.name }}
        </b-dropdown-item>
      </b-dropdown>
    </div>
    <div
      v-if="media.tags && media.tags.length > 0"
      class="media-image__badges"
    >
      <template
        v-for="tag in media.tags.slice(0, 2)"
      >
        <b-badge
          :key="tag"
          pill
        >
          {{ tag }}
        </b-badge>
      </template>
      <b-dropdown
        v-if="media.tags.length > 2"
        variant="asda"
        size="sm"
        class="media-image__badges-dropdown"
        toggle-class="btn-gradient-no-gradient"
        no-caret
      >
        <template #button-content>
          <GjIcon
            name="Moreoptions"
            size="12px"
          />
        </template>
        <b-dropdown-item
          v-for="tag in media.tags.slice(2)"
          :key="tag"
          @click.native.capture.stop.prevent
        >
          {{ tag }}
        </b-dropdown-item>
      </b-dropdown>
    </div>
  </div>

</template>

<script>

export default {
  name: 'AssetDetails',
  props: {
    media: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      //
    }
  },
  computed: {},
  watch: {},
  created() {
    //
  },
  mounted() {
    //
  },
}

</script>
<style scoped lang="scss">

</style>
