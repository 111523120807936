<template>
  <div class="dynamic-labels">
    <b-form-group
      v-if="showAddButton"
      :label="showLabel ? $t('entries.sidebar-tabs.general.labels') : null"
      class="dynamic-labels__actions"
      :class="{'dynamic-labels__actions__no-label': noTitle}"
      label-cols="6"
    >
      <b-dropdown
        no-caret
        variant="none"
        class="dynamic-labels__dropdown"
        boundary="scrollParent"
      >
        <template #button-content>
          <GjIcon
            name="Plus"
            size="14"
          />
          {{ $t('entries.labels.add') }}
        </template>
        <b-dropdown-group
          v-if="filteredLabels.length"
          class="dynamic-labels__dropdown-group--scrollable"
        >
          <span
            v-for="label in filteredLabels"
            :key="label.id"
            v-permission="getPermission(label)"
            class="prevent"
          >
            <b-dropdown-item
              class="dynamic-labels__dropdown-item"
              :class="{'dynamic-labels__dropdown-item--selected': isSelected(label)}"
              :title="label.name"
              @click.native.capture.stop.prevent="addOrRemoveLabel(label)"
            >
              <GjIcon
                name="IconparkDot"
                :color="label.color"
              />
              {{ label.name }}
            </b-dropdown-item>
          </span>
        </b-dropdown-group>
        <b-dropdown-divider v-if="allowAll" />
        <b-dropdown-group v-if="allowAll">
          <b-dropdown-item
            v-permission="['canCreateLabel']"
            @click="createLabel"
          >
            <GjIcon
              size="18px"
              name="Plus"
            />
            <span>
              {{ $t('entries.labels.create-label') }}
            </span>
          </b-dropdown-item>
        </b-dropdown-group>
      </b-dropdown>
    </b-form-group>
    <template v-if="usedLabels.length">
      <div
        v-for="label in usedLabels"
        :key="label.id"
        class="dynamic-labels__label"
        :style="label.name
          ? `background-color: ${label.color}`
          : `background-color:white; border: 1px solid red; text-decoration: line-through; color: red;`"
      >
        {{ label.name || label.id }}
        <span
          v-if="showRemoveButton"
          v-permission="getPermission(label)"
          style="display: flex;align-items: center;"
        >
          <GjIcon
            name="Close"
            style="fill: white;cursor: pointer"
            size="16px"
            @click.native="removeLabel(label)"
          />
        </span>
      </div>
    </template>
  </div>
</template>
<script>
import gql from 'graphql-tag'
import { LABEL_ENTITIES, LABEL_COMPONENT_ENTITY_TYPES } from '@/codex-sdk/labels'
import { mapState } from 'vuex'
import { AUTO_SAVE_STATUSES } from '@/utils/constants'

export default {
  name: 'DynamicLabels',
  inject: ['showLabelsPopup', 'entryLabels'],
  props: {
    entry: {
      type: Object,
      default: () => ({}),
    },
    entityType: {
      type: String,
      required: false,
      default: LABEL_COMPONENT_ENTITY_TYPES.ENTRY,
    },
    value: {
      type: [Array, null],
      default: () => ([]),
    },
    siteId: {
      type: String,
      default: null,
    },
    noTitle: {
      type: Boolean,
      default: false,
    },
    allowAll: {
      type: Boolean,
      default: true,
    },
    allowed: {
      type: Array,
      default: () => ([]),
    },
    showAddButton: {
      type: Boolean,
      default: true,
    },
    showLabel: {
      type: Boolean,
      default: true,
    },
    showRemoveButton: {
      type: Boolean,
      default: true,
    },
    checkPermissions: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      LABEL_COMPONENT_ENTITY_TYPES,
      allLabels: [],
      previousLabels: this.entry?.system?.labels || [],
    }
  },
  apollo: {
    labelCollection: {
      query: gql`
        query {
          labelCollection(limit: 1000) {
            items {
              id
              name
              entities
              description
              color
              restrictions {
                sites {
                  allowed
                  allowAll
                }
              }
          }
        }
      }`,
      result({ data }) {
        this.allLabels = data.labelCollection.items
      },
      fetchPolicy: 'no-cache',
    },
  },
  computed: {
    ...mapState('autoSave', [
      'status',
    ]),
    currentEntryLabels() {
      return this.entryLabels?.()
    },
    entity() {
      const entities = [LABEL_ENTITIES.ALL]
      if (this.entityType === LABEL_COMPONENT_ENTITY_TYPES.ENTRY) {
        entities.push(LABEL_ENTITIES.ENTRY)
      }
      if (this.entityType === LABEL_COMPONENT_ENTITY_TYPES.ASSET) {
        entities.push(LABEL_ENTITIES.ASSET)
      }
      return entities
    },
    filteredLabels() {
      return this.allLabels.filter(label => {
        const entities = !label.entities || label.entities.some(entity => this.entity.includes(entity))
        const sites = !this.siteId || label.restrictions?.sites?.allowAll || !label.restrictions?.sites?.allowed || label.restrictions?.sites?.allowed?.includes(this.siteId)
        const allowed = this.allowAll ? true : this.allowed.includes(label.id)
        return entities && sites && allowed
      })
    },
    usedLabels() {
      return this._value.map(item => this.allLabels.find(label => label.id === item?.id) || item)
    },
    _value: {
      get() {
        return this.value || []
      },
      set(val) {
        this.$emit('input', val)
      },
    },
  },
  watch: {
    status(v) {
      if ((this.showAddButton || this.showRemoveButton) && this.entityType === LABEL_COMPONENT_ENTITY_TYPES.ENTRY && v === AUTO_SAVE_STATUSES.SAVED) {
        this.previousLabels = [...this.entry.system.labels]
      }
    },
  },
  methods: {
    isSelected(label) {
      return this._value.find(item => item.id === label.id)
    },
    getPermission(label) {
      const permissions = []
      if (this.entityType === LABEL_COMPONENT_ENTITY_TYPES.ENTRY && label?.id && (this.showAddButton || this.showRemoveButton) && this.checkPermissions) {
        permissions.push(this.isSelected(label) ? 'canRemoveEntryLabel' : 'canAddEntryLabel')
        permissions.push({
          id: this.entry.id,
          model: this.entry?.system?.model?.alias || this.entry?.system?.modelAlias,
          siteId: this.entry?.system?.siteId,
          labelId: label.id,
          entryLabels: this.currentEntryLabels,
        })
      }

      return permissions
    },
    addOrRemoveLabel(label) {
      const exist = this.isSelected(label)
      if (exist) {
        this._value = this._value.filter(item => item.id !== label.id)
      } else {
        this._value = [...this._value, { id: label.id }]
      }
    },
    removeLabel(label) {
      this._value = this._value.filter(item => item.id !== label.id)
    },
    async createLabel() {
      const res = await this.showLabelsPopup({})
      this.allLabels.unshift(res)
    },
  },
}
</script>

<style lang="scss">
.dynamic-labels {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;

  > fieldset {
    margin-bottom: 0;
  }
}

.dynamic-labels__label {
  font-size: 12px;
  color: white;
  background-color: var(--border-color);
  border-radius: 1000px;
  padding: 2px 8px;
  display: inline-flex;
  align-items: center;
}

.dynamic-labels__actions {
  width: 100%;
}
.dynamic-labels__actions__no-label {
  .form-row {
    legend {
      display: none !important;
    }
  }
}

.dynamic-labels__dropdown {
  button.btn {
    padding: 0;
    margin: 0;
    font-size: 12px;
    color: #667C99;
    height: unset;
    font-weight: normal;

    .gj-icon {
      margin-right: 7px;
    }
  }
}
.dynamic-labels__dropdown-group--scrollable {
  max-height: 250px;
  overflow-y: auto;
  overflow-x: hidden;
}
.dynamic-labels__dropdown-item {

  a {
    display: flex;
    align-items: center;
    width: 100%;
    flex: 1;
    color: #052D61;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    max-width: 300px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }

  &.dynamic-labels__dropdown-item--selected a {
    background-color:  #1D79F2;
    color: white;
  }
}
</style>
