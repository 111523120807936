import { Link } from 'tiptap-extensions'
import { updateMark, removeMark } from 'tiptap-commands'

export default class ColorStyle extends Link {
  get name() {
    return 'color_style'
  }

  get defaultOptions() {
    return {}
  }

  get schema() {
    return {
      attrs: {
        color: {
          default: '#000000',
        },
      },
      inclusive: false,
      parseDOM: [{
        style: 'color',
        getAttrs: value => ({ color: value }),
      }],
      toDOM: node => ['span', {
        style: `color: ${node.attrs.color};`,
      }, 0],
    }
  }

  commands({
    type,
  }) {
    return attrs => {
      if (attrs.color) {
        return updateMark(type, attrs)
      }
      return removeMark(type)
    }
  }
}
