import { getUserData } from '@/auth/utils'
import ability from '@/libs/acl/ability'
import { pr } from './config'

// For regEx search in VSCode: canViewLists|canCreateList|canEditList|canDeleteList

// * Create entity file, and can functions
// * Import and add them to allCanFunctions object down below
// * Add defaults into the permissions array down below
// * Implement v-permission directives on neccessary buttons
// * Implement permissions on entities sdk functions
// * Implement permissions on navigation and route

export const List = Object.freeze({
  VIEW: 'list_view',
  CREATE: 'list_create',
  MANAGE: 'list_manage',
  CONFIGURE: 'list_configure',
  DELETE: 'list_delete',
})

export const ListTooltips = {
  [List.VIEW]: 'Allows a user to view all lists in ‘view only’ mode, without performing any operations',
  [List.CREATE]: 'Allows a user to create new list, edit and delete own list and view other lists in ‘view only’ mode ',
  [List.MANAGE]: 'Allows a user to perform all CRUD operations in the list level such as: create, view, edit and delete.',
}

function isCurrentUser(entity) {
  const userId = entity?.createdBy?.id || entity?.createdBy

  const currentUserData = getUserData()
  if (!currentUserData) return false

  return userId == currentUserData.id
}

/**
 * _options
 *    entity = undefined
 *    showToast = false
 */

/**
 * Can VIEW Lists
 * */
export function canViewLists(_options = {}) {
  const { entity, ...options } = _options

  return pr(
    ability.can(List.VIEW) || ability.can(List.CREATE) || ability.can(List.MANAGE),

    'You have no permission to view lists',

    options,
  )
}

/**
 * Can CREATE List
 * */
export function canCreateList(_options = {}) {
  const { entity, ...options } = _options

  return pr(
    ability.can(List.CREATE) || ability.can(List.MANAGE),

    'You have no permission to create list',

    options,
  )
}

/**
 * Can EDIT List
 * */
export function canEditList(_options = {}) {
  const { entity, ...options } = _options

  return pr(
    ability.can(List.MANAGE)
    || isCurrentUser(entity),

    'You have no permission to edit list',

    options,
  )
}

/**
 * Can DELETE List
 * */
export function canDeleteList(_options = {}) {
  const { entity, ...options } = _options

  return pr(
    ability.can(List.DELETE),

    'You have no permission to delete list',

    options,
  )
}

/**
 * Can CONFIGURE List
 * */
export function canConfigureList(_options = {}) {
  const { entity, ...options } = _options

  return pr(
    ability.can(List.CONFIGURE),

    'You have no permission to configure list',

    options,
  )
}
