import WorkflowBase from './constructors/WorkflowBase'
import ActionsMutations from './constructors/actions/ActionsMutations'
import ConditionsMutations from './constructors/conditions/ConditionsMutations'

export default {
  SET_WORKFLOWS(state, workflows) {
    state.workflows = workflows
  },
  SET_WORKFLOW(state, workflow) {
    state.workflow = state.workflow.fromData(workflow)
  },
  SET_WORKFLOWS_PAGINATION(state, pagination) {
    state.workflowsPagination = pagination
  },
  CLEAR_WORKFLOWS_STATE(state) {
    state.workflows = []
  },
  RESET_WORKFLOW_STATE(state) {
    state.workflow = new WorkflowBase()
  },
  FILTER_MODELS(state, alias) {
    state.workflow.automation.models = state.workflow.automation.models.filter(model => model !== alias)
  },
  ...ActionsMutations,
  ...ConditionsMutations,
}
