import state from './modulesWorkflowsState'
import mutations from './modulesWorkflowsMutations'
import actions from './modulesWorkflowsActions'
import getters from './modulesWorkflowsGetters'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
