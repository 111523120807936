import { ASSET_TYPE_MAPPING, ASSET_TYPES } from '@/codex-sdk/assets'
import * as yup from 'yup'
import { isEmpty } from 'lodash'
import i18n from '@/libs/i18n'

export const TYPES = Object.freeze({
  TEXT: 1,
  INTEGER: 2,
  DECIMAL: 3,
  DATE_TIME: 4,
  BOOLEAN: 5,
  JSON: 6,
  REFERENCE: 7,
  MEDIA: 8,
  LOCATION: 9,
  RICH_TEXT: 10,
  RICH_CONTENT: 11,
  AUTHOR: 12,
  TAG: 13,
  SECTION: 14,
  URL: 15,

  // Legacy code
  DATETIME: 4,
})

export const PATTERN_TYPE = Object.freeze({
  CUSTOM: 1,
  EMAIL: 2,
  URL: 3,
})

export const FLAGS = Object.freeze({
  IGNORE_CASE: 'i',
  MULTI_LINE: 'm',
  SINGLE_LINE: 's',
  PATTERN_WHITESPACE: 'x',
  EXPLICIT_CAPTURE: 'n',
  CULTURE_INVARIANT: 'c',
  RIGHT_TO_LEFT: 'r',
  ECMA_SCRIPT: 'e',
})

export const CONDITIONAL_SYSTEM_FIELDS = Object.freeze({
  SITE_ID: 'SiteId',
})

export const VALUE_TYPES = Object.freeze({
  SINGLE: 1,
  LIST: 2,
})

export const RANGE_OPERATORS = Object.freeze({
  GTE: 1,
  LTE: 2,
  BETWEEN: 3,
  EXACTLY: 4,
})

export const FIELD_FILTER_OPERATORS = Object.freeze({
  CONTAINS: 1,
  NOT_CONTAINS: 2,
  EQUALS: 3,
  NOT_EQUALS: 4,
  EXISTS: 5,
  NOT_EXISTS: 6,
})

export function mapFieldFilterOperatorLabel(operator) {
  const keys = {
    [FIELD_FILTER_OPERATORS.CONTAINS]: 'contains',
    [FIELD_FILTER_OPERATORS.NOT_CONTAINS]: 'notContains',
    [FIELD_FILTER_OPERATORS.EQUALS]: 'eq',
    [FIELD_FILTER_OPERATORS.NOT_EQUALS]: 'ne',
    [FIELD_FILTER_OPERATORS.EXISTS]: 'exists',
    [FIELD_FILTER_OPERATORS.NOT_EXISTS]: 'notExists',
  }
  return i18n.t(`fields.general.conditions-configuration.operators.${keys[operator]}`)
}

export const MODEL_RESERVED_ALIASES = Object.freeze([
  'models',
  'model',
  'modelversions',
  'entries',
  'entry',
  'entrycollection',
  'entriescollection',
  'articles',
  'article',
  'articlelist',
  'articleslist',
  'sections',
  'section',
  'assets',
  'asset',
  'media',
  'medias',
  'system',
  'systems',
  'query',
  'queries',
  'string',
  'strings',
  'float',
  'int',
  'boolean',
  'datetime',
  'json',
  'contentblock',
  'reference',
  'references',
  'location',
  'locations',
  'gallery',
  'galleries',
  'gallerylist',
  'gallerieslist',
  'layout',
  'layouts',
  'codelet',
  'codelets',
  'theme',
  'themes',
  'webhooks',
  'webhook',
  'domain',
  'domains',
  'urls',
  'url',
  'adplacement',
  'adplacements',
  'metrics',
  'contentblockmark',
  'label',
  'id',
  'byte',
  'bytearray',
  'short',
  'long',
  'decimal',
  'date',
  'timespan',
  'uuid',
  'any',
  'mutation',
  'list',
  'tag',
  'subscription',
])

export const FIELD_RESERVED_ALIASES = Object.freeze([
  'id',
  'system',
  'attrs',
  'content',
])

export const TEXT_FIELD_APPEARANCES = Object.freeze({
  SINGLE_LINE: 1,
  MULTIPLE_LINES: 2,
  URL: 3,
  DROPDOWN: 4,
  RADIO: 5,
  SLUG: 6,
  TAG: 9,
  LIST: 10,
  CHECKBOX: 11,
})

export const BOOLEAN_FIELD_APPEARANCES = Object.freeze({
  RADIO: 1,
  CHECKBOX: 2,
  SWITCH: 3,
})

export const NUMBER_FIELD_APPEARANCES = Object.freeze({
  INPUT: 1,
  DROPDOWN: 2,
  RADIO: 3,
  RATING: 4,
  CHECKBOX: 5,
})

export const assetsCountProperties = {
  [ASSET_TYPES.IMAGE]: 'imageCount',
  [ASSET_TYPES.VIDEO]: 'videoCount',
  [ASSET_TYPES.FILE]: 'filesCount',
  [ASSET_TYPES.VIDEO_PLAYLIST]: 'playlistsCount',
  [ASSET_TYPES.AUDIO]: 'audioCount',
  [ASSET_TYPES.PODCAST]: 'podcastCount',
}

function getBlocks_(content, condition, blocks) {
  if (!content) return
  content.forEach(block => {
    if (condition(block)) {
      blocks.push(block)
    }
    getBlocks_(block.content, condition, blocks)
  })
}

export function getBlocks(content, condition = () => true) {
  const blocks = []
  getBlocks_(content, condition, blocks)
  return blocks
}

export function checkRangeValidation(range, hasExactly = false) {
  const message = { emptyMessage: '', differenceMessage: '' }
  let isValid = true
  if ((hasExactly && !Number.isInteger(range.exactly)) || !Number.isInteger(range.min) || !Number.isInteger(range.max)) {
    message.emptyMessage = 'models.error-messages.emptyValue'
    isValid = false
    return { isValid, ...message }
  }
  if (range.rangeOperator === RANGE_OPERATORS.BETWEEN && range.min > range.max) {
    message.differenceMessage = 'models.error-messages.minMaxDifference'
    isValid = false
    return { isValid, ...message }
  }
  return { isValid }
}

export function getFieldParams(alias, type, valueType) {
  const configs = Object.freeze({
    [TYPES.INTEGER]: {
      [VALUE_TYPES.SINGLE]: '',
      [VALUE_TYPES.LIST]: '',
    },
    [TYPES.DECIMAL]: {
      [VALUE_TYPES.SINGLE]: ' ',
      [VALUE_TYPES.LIST]: ' ',
    },
    [TYPES.TEXT]: {
      [VALUE_TYPES.SINGLE]: '',
      [VALUE_TYPES.LIST]: '',
    },
    [TYPES.MEDIA]: {
      [VALUE_TYPES.SINGLE]: '{ id type media { ... on CodexAsset { url alt } } }',
      [VALUE_TYPES.LIST]: '{ id type media { ... on CodexAsset { url alt } } }',
    },
    [TYPES.RICH_TEXT]: {
      [VALUE_TYPES.SINGLE]: '',
    },
    [TYPES.JSON]: {
      [VALUE_TYPES.SINGLE]: '',
    },
    [TYPES.BOOLEAN]: {
      [VALUE_TYPES.SINGLE]: '',
    },
    [TYPES.LOCATION]: {
      [VALUE_TYPES.SINGLE]: '{ address latitude longitude }',
    },
    [TYPES.REFERENCE]: {
      [VALUE_TYPES.SINGLE]: '{ id system { title slug model { iconId } } } ',
      [VALUE_TYPES.LIST]: '{ items { id system { title slug model { iconId } } } } ',
    },
    [TYPES.DATE_TIME]: {
      [VALUE_TYPES.SINGLE]: '',
    },
    [TYPES.RICH_CONTENT]: {
      [VALUE_TYPES.SINGLE]: '{ contentHTML text }',
      [VALUE_TYPES.LIST]: '{ contentHTML text }',
    },
    [TYPES.AUTHOR]: {
      [VALUE_TYPES.SINGLE]: '{ id firstName lastName image { id alt url }  }',
      [VALUE_TYPES.LIST]: '{ id firstName lastName image { id alt url } }',
    },
    [TYPES.TAG]: {
      [VALUE_TYPES.LIST]: '{ id tagAlias tagValue }',
    },
    [TYPES.SECTION]: {
      [VALUE_TYPES.SINGLE]: '{ id title }',
      [VALUE_TYPES.LIST]: '{ id title }',
    },
    [TYPES.URL]: {
      [VALUE_TYPES.SINGLE]: '',
    },
  })
  return `${alias}  ${configs[type][valueType]}`
}

export function validateNumber(e, number, negativeNumbers = false) {
  const isBackspaceOrArrows = e.key === 'Backspace' || e.code.includes('Arrow') || e.code.includes('Tab') || e.code.includes('Delete')
  if (number?.toString().length > 14 && !isBackspaceOrArrows) {
    e.preventDefault()
  }
  if (!/^[0-9]+$/.test(e.key) && !isBackspaceOrArrows && !(negativeNumbers && e.key === '-' && number >= 0)) {
    e.preventDefault()
  }
}

export function errorTemplateParser(validation) {
  let text = validation.errorTemplate.replaceAll('{min}', validation.min)
  text = text.replaceAll('{max}', validation.max)
  text = text.replaceAll('{exactly}', validation.exactly)
  validation.errorMessage = text
}

export function getContentText(blocks, entry) {
  function addText(block, array) {
    if (typeof block.text === 'string') {
      array.push(block.text)
    }
    if (typeof block.content === 'object' && Array.isArray(block.content)) {
      block.content.forEach(c => addText(c, array))
    }
  }

  const allFields = getBlocks(blocks,
    block => block.isField && (block.attrs.type === TYPES.TEXT
      || block.attrs.type === TYPES.RICH_CONTENT
      || block.attrs.type === TYPES.RICH_TEXT))
    .map(block => ({ alias: block.attrs.alias, valueType: block.attrs.valueType, type: block.attrs.type }))
  const data = []
  allFields.forEach(field => {
    if (!entry?.content?.[field.alias]) return

    if (field.type === TYPES.TEXT) {
      if (field.valueType === VALUE_TYPES.SINGLE) {
        data.push(entry.content[field.alias])
      } else {
        data.push(...entry.content[field.alias])
      }
    }
    if (field.type === TYPES.RICH_TEXT && field.valueType === VALUE_TYPES.SINGLE) {
      const dom = document.createElement('div')
      dom.innerHTML = entry.content[field.alias]
      data.push(dom.textContent || dom.innerText || '')
    }
    if (field.type === TYPES.RICH_CONTENT && field.valueType === VALUE_TYPES.LIST) {
      const content = entry.content[field.alias]
      content.forEach(block => addText(block, data))
    }
  })
  return data.join(' ')
}

export function getGeneeaContent(blocks, entry, model) {
  const content = getBlocks(blocks, block => block.isField && (block.attrs.type === TYPES.SECTION || block.attrs.type === TYPES.TEXT))
    .map(block => ({ alias: block.attrs.alias, valueType: block.attrs.valueType, type: block.attrs.type }))
  const data = { sections: [], title: '' }
  content.forEach(field => {
    if (!entry?.content?.[field.alias]) return

    if (field.type === TYPES.SECTION) {
      if (field.valueType === VALUE_TYPES.SINGLE) {
        data.sections.push(entry.content[field.alias])
      } else {
        data.sections.push(...entry.content[field.alias])
      }
    }
    if (field.alias === model.titleAlias) {
      data.title = entry.content[field.alias]
    }
  })
  return data
}

// Given field, generate a text with `type` and `valueType` information as string
export function generateFieldText(field) {
  const { type, valueType } = field
  const typeText = Object.keys(TYPES).find(key => TYPES[key] === type)
  const valueTypeText = Object.keys(VALUE_TYPES).find(key => VALUE_TYPES[key] === valueType)

  return `${typeText} (${valueTypeText})`
}

/** Validate field value schema */
export async function validateFieldValueSchema(field, fieldValue) {
  const { type, valueType } = field

  const MEDIA_VALIDATION = yup.object().shape({
    id: yup.string().required(),
    type: yup.number().oneOf(Object.values(ASSET_TYPE_MAPPING._enum)).required(),
    caption: yup.string().nullable(),
    focalPoints: yup.lazy(value => {
      if (!isEmpty(value)) {
        const validationObject = {
          x: yup.number().min(0).max(1).required(),
          y: yup.number().min(0).max(1).required(),
        }
        const newEntries = Object.keys(value).reduce(
          (acc, val) => ({
            ...acc,
            [val]: yup.object(validationObject),
          }),
          {},
        )
        return yup.object().shape(newEntries).noUnknown()
      }
      return yup.mixed().notRequired()
    }),
  }).noUnknown()

  const ISO_VALIDATION_REGEX = /(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))/

  const schema = {
    [TYPES.INTEGER]: {
      // Checked
      [VALUE_TYPES.SINGLE]: yup.number().integer().nullable(),
      [VALUE_TYPES.LIST]: yup.array().of(yup.number().integer()),
    },
    [TYPES.DECIMAL]: {
      // Checked
      [VALUE_TYPES.SINGLE]: yup.number().nullable(),
      [VALUE_TYPES.LIST]: yup.array().of(yup.number()),
    },
    [TYPES.TEXT]: {
      // Checked
      [VALUE_TYPES.SINGLE]: yup.string(),
      [VALUE_TYPES.LIST]: yup.array().of(yup.string()),
    },
    [TYPES.MEDIA]: {
      // Checked
      [VALUE_TYPES.SINGLE]: MEDIA_VALIDATION,
      [VALUE_TYPES.LIST]: yup.array().of(MEDIA_VALIDATION),
    },
    [TYPES.RICH_TEXT]: {
      // Checked
      [VALUE_TYPES.SINGLE]: yup.string(),
    },
    [TYPES.JSON]: {
      // Checked
      [VALUE_TYPES.SINGLE]: yup.object(),
    },
    [TYPES.BOOLEAN]: {
      // Checked
      [VALUE_TYPES.SINGLE]: yup.boolean(),
    },
    [TYPES.LOCATION]: {
      // Checked
      [VALUE_TYPES.SINGLE]: yup.object().shape({
        address: yup.string(),
        latitude: yup.number(),
        longitude: yup.number(),
      }).noUnknown(),
    },
    [TYPES.REFERENCE]: {
      // Checked
      [VALUE_TYPES.SINGLE]: yup.object().shape({
        entryId: yup.string().required(),
        model: yup.string().required(),
      }).noUnknown(),
      [VALUE_TYPES.LIST]: yup.array().of(yup.object().shape({
        entryId: yup.string().required(),
        model: yup.string().required(),
      }).noUnknown()),
    },
    [TYPES.DATE_TIME]: {
      // Checked
      [VALUE_TYPES.SINGLE]: yup.string().matches(ISO_VALIDATION_REGEX),
      [VALUE_TYPES.LIST]: yup.array().of(yup.string().matches(ISO_VALIDATION_REGEX)),
    },
    [TYPES.RICH_CONTENT]: {
      // Checked - todo: validate content
      [VALUE_TYPES.LIST]: yup.array().of(yup.object()),
    },
    [TYPES.AUTHOR]: {
      // Checked
      [VALUE_TYPES.SINGLE]: yup.object().shape({
        id: yup.string(),
      }).noUnknown(),
      [VALUE_TYPES.LIST]: yup.array().of(yup.object().shape({
        id: yup.string(),
      }).noUnknown()),
    },
    [TYPES.TAG]: {
      // Checked
      [VALUE_TYPES.LIST]: yup.array().of(yup.object().shape({
        alias: yup.string().required(),
        externalId: yup.string().nullable(),
        id: yup.string(),
        source: yup.number().integer().oneOf([0, 1, 2, 3]).required(),
        tag: yup.string().required(),
      }).noUnknown()),
    },
    [TYPES.SECTION]: {
      // Checked
      [VALUE_TYPES.SINGLE]: yup.object().shape({
        id: yup.string().required(),
        isMain: yup.boolean().nullable(),
      }),
      [VALUE_TYPES.LIST]: yup.array().of(yup.object().shape({
        id: yup.string().required(),
        isMain: yup.boolean().nullable(),
      })),
    },
    [TYPES.URL]: {
      // Checked
      [VALUE_TYPES.SINGLE]: yup.string().nullable(),
    },
  }
  const validationSchema = schema[type][valueType]

  if (validationSchema) {
    // console.log(field.alias, '/', generateFieldText(field), 'Value:', fieldValue)

    await validationSchema.validate(fieldValue, { strict: true })
  }

  // If validation fails, yup throws an error
  return true
}
