import { FIELD_GROUPS } from '@/codex-sdk/models'
import {
  FIELD_FILTER_OPERATORS, RANGE_OPERATORS, TEXT_FIELD_APPEARANCES, TYPES, VALUE_TYPES,
} from '@/views/models/constants'
import Initial from './Initial.vue'
import { registerField } from '../../codex-layout-editor/BuilderUtils'
import Render from './Render.vue'

import General from './General.vue'
import Appearance from './Appearance.vue'
import Validations from './Validations.vue'
import Configuration from './Configuration.vue'

registerField('codex_field', {
  displayName: 'Text',
  name: 'text',
  render: Render,
  renderEditor: Render,
  renderEditorPanel: [
    {
      icon: 'Settings', label: 'fields.codex-field-text.tabs.general', component: General, isGeneral: true,
    },
    { icon: 'Brush', label: 'fields.codex-field-text.tabs.appearance', component: Appearance },
    { icon: 'Tasks', label: 'fields.codex-field-text.tabs.validations', component: Validations },
    { icon: 'SettingsPreferencesTools', label: 'fields.codex-field-text.tabs.configuration', component: Configuration },
  ],
  renderEditorInitialPanel: [
    { icon: 'Settings', label: 'fields.codex-field-text.tabs.initial', component: Initial },
  ],
  group: FIELD_GROUPS.BASIC,
  icon: 'field-text.png',

  type: TYPES.TEXT,
  valueType: VALUE_TYPES.SINGLE,
  defaultValue: null,

  attrs: {
    // New configs
    appearance: {
      default: TEXT_FIELD_APPEARANCES.SINGLE_LINE,
    },
    showCharacterCount: {
      default: false,
    },
    helpText: {
      default: '',
    },
    helpTextDisplay: {
      default: 'tooltip',
    },
    generateSlug: {
      default: '',
    },
    validation: {
      default: {
        isSlug: {
          value: false,
          errorMessage: '',
        },
        required: {
          value: false,
          errorMessage: 'Text is required',
          conditionsEnabled: false,
          conditions: [
            {
              isSystem: false,
              field: '',
              operator: FIELD_FILTER_OPERATORS.EXISTS,
              value: '',
            },
          ],
        },
        readOnly: {
          value: false,
          conditionsEnabled: false,
          conditions: [
            {
              isSystem: false,
              field: '',
              operator: FIELD_FILTER_OPERATORS.EXISTS,
              value: '',
            },
          ],
        },
        uniqueInCollection: {
          value: false,
          errorMessage: 'Text should be unique',
        },
        textLengthRange: {
          isEnabled: false,
          rangeOperator: RANGE_OPERATORS.BETWEEN,
          min: 10,
          max: 50,
          exactly: 0,
          errorMessage: 'Text length should be 10-50',
          errorTemplate: 'Text length should be {min}-{max}',
        },
        selectFrom: {
          value: false,
          predefinedValues: [],
          inputValue: '',
        },
        listLengthRange: {
          isEnabled: false,
          rangeOperator: RANGE_OPERATORS.BETWEEN,
          min: 2,
          max: 5,
          exactly: 0,
          errorMessage: 'List length should be 2-5',
          errorTemplate: 'List length should be {min}-{max}',
        },
        matchingRegex: {
          isEnabled: false,
          type: 1,
          patterns: [],
          flags: [],
          errorMessage: 'Pattern does not match',
        },
        prohibitedRegex: {
          isEnabled: false,
          patterns: [],
          flags: [],
          errorMessage: 'This words are prohibited',
        },
        predefinedValues: {
          isEnabled: false,
          values: [],
          errorMessage: 'Only specified values are accepted',
        },
      },
    },
    placeholder: {
      default: '',
    },
    configuration: {
      default: {
        searchable: true,
        filterable: true,
        aiFeatures: false,
      },
    },
    hidden: {
      value: false,
      conditionsEnabled: false,
      conditions: [
        {
          isSystem: false,
          field: '',
          operator: FIELD_FILTER_OPERATORS.EXISTS,
          value: '',
        },
      ],
    },
  },
})
