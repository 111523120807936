<template>
  <b-sidebar
    id="entries-popup"
    v-model="showPopup"
    :title="$t('entries.popup.label')"
    class="c-sidebar c-sidebar--xl"
    :no-close-on-route-change="true"
    right
    shadow
    backdrop
    @ok="proceedSelect"
    @close="cancelSelect"
  >
    <Listing
      :is-popup="true"
      :exclude-ids="excludeIds"
      :models="models"
      :_limit="_limit"
      :disable-entry-creation="data.disableEntryCreation"
      :include-custom-site-filter="includeCustomSiteFilter"
      :add-on-publish="addOnPublish"
      :statuses="statuses"
      @selectedEntries="selected"
      @proceedSelect="proceedSelect"
    />
    <template #footer>
      <b-button @click="cancelSelect">
        {{ $t('general.cancel') }}
      </b-button>
      <b-button
        variant="primary"
        @click="proceedSelect"
      >
        {{ $t('general.insert') }}
      </b-button>
    </template>
  </b-sidebar>
</template>
<script>
import PopupsMixin from '@/components/popups/PopupsMixin'
import Listing from '@/views/entries/Listing.vue'
import { isNumber } from 'lodash'

export default {
  name: 'EntriesPopup',
  components: {
    Listing,
  },
  mixins: [PopupsMixin],
  data() {
    return {
      excludeIds: this.data.excludeIds || [],
      selectedEntries: [],
    }
  },
  computed: {
    models() {
      return this.data && this.data.models ? this.data.models : null
    },
    _limit() {
      return this.data && isNumber(this.data.limit) ? this.data.limit : Math.min()
    },
    includeCustomSiteFilter() {
      return this.data?.includeCustomSiteFilter || false
    },
    addOnPublish() {
      return this.data?.addOnPublish || false
    },
    statuses() {
      return this.data?.statuses || []
    },
  },
  methods: {
    selected(data) {
      this.selectedEntries = data
    },
    proceedSelect() {
      try {
        this.closePopup(this.selectedEntries)
      } catch (error) {
        console.log(error)
      }
    },
    cancelSelect() {
      this.closePopup(false)
    },
  },
}
</script>
<style lang="scss">
  #entries-popup {
    .btn-secondary{
      border-color: #fff !important;
      background-color: #fff !important;
      color:black!important;
    }
    .full-height-layout {
      height: calc(100vh - 155px) !important;
    }
  }
</style>
