import state from './modulesModelsState'
import mutations from './modulesModelsMutations'
import actions from './modulesModelsActions'
import getters from './modulesModelsGetters'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
