import ability from '@/libs/acl/ability'
import { getUserData } from '@/auth/utils'
import { pr } from './config'

/**
workflows_create
workflows_view
workflows_edit
workflows_delete
 */
// For regEx search in VSCode: canViewWebhook|canCreateWebhook|canEditWebhook|canDeleteWebhook

// * Create entity file, and can functions
// * Import and add them to allCanFunctions object down below
// * Add defaults into the permissions array down below
// * Implement v-permission directives on neccessary buttons
// * Implement permissions on entities sdk functions
// * Implement permissions on navigation and route

export const Workflows = Object.freeze({
  VIEW: 'workflow_view',
  CREATE: 'workflow_create',
  EDIT: 'workflow_edit',
  DELETE: 'workflow_delete',
})

export const WorkflowsTooltips = {
  [Workflows.VIEW]: '',
  [Workflows.CREATE]: '',
  [Workflows.EDIT]: '',
  [Workflows.DELETE]: '',
}

function isCurrentUser(workflows) {
  const userId = workflows?.createdBy

  const currentUserData = getUserData()
  if (!currentUserData) return false

  return userId == currentUserData.id
}

/**
 * _options
 *    entity = undefined
 *    showToast = false
 */

/**
 * Can VIEW Workflows
 * */
export function canViewWorkflows(_options = {}) {
  const { entity, ...options } = _options

  return pr(
    ability.can(Workflows.VIEW),

    'You have no permission to view workflows',

    options,
  )
}

/**
 * Can CREATE Workflows
 * */
export function canCreateWorkflows(_options = {}) {
  const { entity, ...options } = _options

  return pr(
    ability.can(Workflows.CREATE),

    'You have no permission to create workflows',

    options,
  )
}

/**
 * Can EDIT Workflows
 * */
export function canEditWorkflows(_options = {}) {
  const { entity, ...options } = _options

  return pr(
    ability.can(Workflows.EDIT)
    || (ability.can(Workflows.CREATE) && isCurrentUser(entity)),

    'You have no permission to edit workflows',

    options,
  )
}

/**
 * Can DELETE Workflows
 * */
export function canDeleteWorkflows(_options = {}) {
  const { entity, ...options } = _options

  return pr(
    ability.can(Workflows.DELETE),

    'You have no permission to delete this workflows',

    options,
  )
}
