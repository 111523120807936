<template>
  <v-select
    id="at-folders-select"
    v-model="folderId"
    class="folder-vselect"
    :options="options"
    label="name"
    :placeholder="placeholder"
    :reduce="s => s.id"
    :multiple="hasMultiple"
    :close-on-select="closeOnSelect"
    :selectable="(option) => showFolder(option)"
    deselect-from-dropdown
  >
    <template v-slot:option="option">
      <div
        :class="[option.collapsable ? 'table__collapse-arrow' : `table__collapse-no-arrow-${option.indent}`, `table__indent-${option.indent}`, 'd-flex']"
      >
        <div @mousedown.stop.prevent="handleTableCollapse(option)">
          <GjIcon
            v-show="option.collapsable"
            :key="option.collapsed ? 'ArrowDown' : 'ArrowRight'"
            size="16"
            style="fill: #052D61;"
            :name="option.collapsed ? 'ArrowDown' : 'ArrowRight'"
          />
        </div>
        <span
          class="mb-0 table__collapse-name"
          :class="{'table__collapse-first': option.isFirstOfSet, 'section-name__no-arrow': !option.collapsable && !option.parentId}"
        >
          <div class="folders-tree__folder-icon">
            <img
              v-show="showIcon"
              src="@/assets/icons/assets-folder.svg"
              alt=""
            >
            <span class="webhook-edit__select-options">
              {{ option.name }}
            </span>
          </div>
        </span>
      </div>
    </template>
  </v-select>
</template>
<script>
import gql from 'graphql-tag'
import { cloneDeep } from 'lodash'

export default {
  props: {
    value: {
      type: [Array, String],
    },
    placeholder: {
      type: String,
      default: '',
    },
    hasMultiple: {
      type: Boolean,
      default: true,
    },
    showIcon: {
      type: Boolean,
      default: true,
    },
    closeOnSelect: {
      type: Boolean,
      default: false,
    },
  },
  apollo: {
    folderCollection: {
      query: gql`
        query ($parentId: CodexStringFilter) {
         folderCollection (limit: 99999, where: {
            parentId: $parentId,
          }) {
            total
            items {
              id
              name
              parentId
              children {
                total
                items {
                  id
                  name
                  parentId
                  children {
                    total
                    items {
                      id
                      name
                      parentId
                      children {
                        total
                        items {
                          id
                          name
                          parentId
                          children {
                            total
                            items {
                              id
                              name
                              parentId
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `,
      variables() {
        return {
          parentId: {
            in: this.parentId ? [this.parentId] : [''],
            exists: !!this.parentId,
          },
        }
      },
      update(data) {
        this.loading = false
        return {
          ...data.folderCollection,
          items: this.flatten(cloneDeep(data.folderCollection.items)),
        }
      },
      fetchPolicy: 'cache-and-network',
    },
  },
  computed: {
    folderId: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
    options() {
      return this.folderCollection ? this.folderCollection.items : []
    },
  },
  methods: {
    // s.show || (this.folderId && (this.folderId.constructor === Array && (this.folderId.find(f => s.id === f) || this.folderId === s.id)))
    showFolder(folder) {
      if (folder.show) {
        return true
      }

      if (this.folderId && (this.folderId.constructor === Array && (this.folderId.find(f => folder.id === f) || this.folderId === folder.id))) {
        return true
      }

      return false
    },
    flatten(items, parentId, indent = 0) {
      for (let i = 0; i < items.length; i++) {
        const childItems = []

        if (items[i].indent === undefined) {
          items[i].indent = indent
        }

        if (parentId && items[i].parentId === undefined) {
          items[i].parentId = parentId
        }

        if (items[i].show === undefined) {
          items[i].show = indent === 0
        }

        if (items[i].children?.items?.length) {
          items[i].collapsable = true
          items[i].collapsed = false
          childItems.push(...this.flatten(items[i].children?.items, items[i].id, indent + 1))

          childItems[0].isFirstOfSet = true
        }

        delete items[i].children
        items = [...items.slice(0, i + 1), ...childItems, ...items.slice(i + 1)]
      }

      return items
    },
    handleTableCollapse(item, state = undefined) {
      this.folderCollection.items.forEach(folder => {
        if (folder.id === item.id) {
          folder.collapsed = state === undefined ? !folder.collapsed : state
        }

        if (folder.parentId === item.id) {
          folder.show = state === undefined ? !folder.show : state

          if ((state === undefined && !folder.show) || state === false) {
            this.handleTableCollapse(folder, false)
          }
        }
      })
    },
  },
}
</script>
<style lang="scss" scoped>
@import "@core/scss/base/bootstrap-extended/include"; // Bootstrap includes

// Hack to hide disabled options in v-select, normally disabled options are visible but not selectable
.folder-vselect::v-deep {
  .vs__dropdown-option.vs__dropdown-option--disabled {
    display: none !important;
  }
}

#at-folders-select {
  li {
    height: 0!important;
    overflow-y:hidden;
  }
}

.table__collapse-arrow {
  position: relative;
}

.table__collapse-arrow-button {
  position: absolute;
  left: -16px;
  top: 5px;
  cursor: pointer;
  transition: opacity 0.3s;

  &:hover {
    opacity: 0.6;
  }
}

.sections__vgt {
  .vgt-table td {
      padding-top: 6px;
      padding-bottom: 6px;
  }
  .table__collapse-name {
    font-size: 14px;
  }
  tbody {
    tr:hover {
      background-color: #1d79f210;
    }
  }
  .b-dropdown {
    > .dropdown-toggle {
      height: 26px;
      padding-left: 5px;
      padding-right: 5px;
    }
  }
}

.table__collapse-link {
  color: #052D61;
}

// INDENT

.section-name__no-arrow{
  padding-left: 1.15em;
}

.table__collapse-first {
  position: relative;
  &::before {
    width: 12px;
    height: 12px;
    position: absolute;
    border-bottom: 1px solid #E0E5EB;
    border-left: 1px solid #E0E5EB;
    top: 0;
    left: -28px;
  }
  .table__collapse-name {
    cursor: pointer;
    font-size: 14px;

    &:hover {
      color: $primary;
    }
  }
}

@for $i from 1 through 20 {
  .table__indent-#{$i} {
      margin-left: $i * 24px;
      .table__collapse-first {
        &::before {
          content: '';
          left: -32px;
        }
      }
  }

  .table__collapse-no-arrow-#{$i} {
    margin-left: $i * 22px;
    .table__collapse-first {
      &::before {
        content: '';
        left: -14px;
      }
    }
  }
}
</style>
