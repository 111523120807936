import { set } from 'lodash'
import ConditionBase from './ConditionBase'
import ConditionParameters from './ConditionParameters'

export default {
  ADD_CONDITION(state, index) {
    const at = index ?? state.workflow.conditions.length
    state.workflow.conditions.splice(at, 0, new ConditionBase())
  },
  UPDATE_CONDITION_PROP(state, { index, key, value }) {
    set(state.workflow.conditions[index], key, value)
  },
  REMOVE_CONDITION(state, index) {
    state.workflow.conditions.splice(index, 1)
  },
  UPDATE_CONDITION_OPERATOR(state, { value = 0 } = {}) {
    state.workflow.conditions.map(c => {
      c.operator = value
      return c
    })
  },
  ADD_PARAMETER(state, { index, field, filter }) {
    // eslint-disable-next-line new-cap
    const init = new filter()
    const newCondition = new ConditionParameters({
      field: field.name,
      type: field.type,
      ...init,
    })
    state.workflow.conditions[index].parameters.push(newCondition)
  },
  REMOVE_PARAMETER(state, { index, parameterIndex }) {
    state.workflow.conditions[index].parameters.splice(parameterIndex, 1)
  },
  FILTER_CONDITION_FIELDS(state) {
    const CONDITION_SYSTEM_FIELDS = [16, 17, 24]
    state.workflow.conditions.map(c => {
      c.parameters = c.parameters.filter(f => CONDITION_SYSTEM_FIELDS.includes(f.type))
      return c
    })
  },
}
