<template>
  <transition>
    <b-sidebar
      id="teams__sidebar"
      v-model="showPopup"
      :title="!getSection ? $t('teams.popup.new') : $t('teams.popup.update')"
      class="c-sidebar c-sidebar--md"
      backdrop
      shadow
      right
    >
      <b-form-group
        :label="$t('teams.popup.name')"
        label-for="team-title"
      >
        <b-input
          id="at-teams-create-title-input"
          v-model="team.name"
          :placeholder="$t('teams.popup.namePlaceholder')"
        />
      </b-form-group>

      <b-form-group
        :label="$t('teams.popup.description')"
        label-for="team-title"
      >
        <b-textarea
          id="at-teams-create-title-input"
          v-model="team.description"
          :placeholder="$t('teams.popup.descriptionPlaceholder')"
        />
      </b-form-group>

      <b-form-group
        :label="$t('teams.popup.addMembers')"
        label-for="team-parent"
      >
        <UsersSelect
          v-model="team.members"
          :placeholder="$t('teams.popup.descriptionPlaceholder')"
          :exclude-ids="[team.id]"
        />
      </b-form-group>

      <template #footer>
        <b-button
          id="at-teams-sidebar-cancel-btn"
          :variant="`outline-secondary`"
          class="footer-button"
          @click="handleCancel"
        >
          {{ $t('general.cancel') }}
        </b-button>
        <b-button
          id="at-teams-sidebar-create-btn"
          :variant="`primary`"
          class="footer-button"
          @click="handleOk"
        >
          {{ team.id ? $t('general.save') : $t('general.create') }}
        </b-button>
      </template>
    </b-sidebar>
  </transition>
</template>

<script>
import UsersSelect from '@/components/UsersSelect.vue'
import PopupsMixin from '@/components/popups/PopupsMixin'
import { createTeam } from '@/codex-sdk/teams'

export default {
  name: 'TeamsPopup',
  components: {
    UsersSelect,
  },
  mixins: [PopupsMixin],
  data() {
    return {
      team: { name: '', description: '', members: [] },
      loading: true,
      toggleOverview: true,
    }
  },
  computed: {
    getSection() {
      return this.data.team
    },
  },
  mounted() {
  },
  methods: {
    handleCancel() {
      this.showPopup = false
      this.team = { name: '', description: '', members: [] }
    },
    async handleOk() {
      try {
        const { data } = await createTeam(this.team)
        this.team.id = data.id
        if (this.team.id) {
          this.closePopup(this.team)
        }
      } catch (e) {
        console.log(e)
      }
    },
  },
}
</script>

<style lang="scss">

.teams-overview__tabs {
  display: flex;
  margin-bottom: 24px;
  border-bottom: 1px solid var(--border-color);
}
.teams-overview__tab {
  color: #667C99;
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  margin-right: 24px;
  padding-bottom: 16px;
  position: relative;
}
.teams-overview__tab--active {
  color: #052d61;
  &:before {
    content: '';
    position: absolute;
    display: block;
    height: 2px ;
    width: 100%;
    border-bottom: 2px solid #1D79F2;
    border-radius: 10px;
    bottom: -1px;
  }

}

.seo-tab{
  overflow-x: none;
}
</style>
