<template>
  <b-dropdown
    ref="dropdown"
    variant="flat"
    class="compact-dropdown expire-date-selector"
    no-caret
    :disabled="disabled"
  >
    <template slot="button-content">
      <div class="input-container">
        <b-form-input
          disabled
          :value=" currentDateTime ? currentDateTime : $t('assets.metadata.expiration.input-placeholder')"
        />
        <div class="icon-container">
          <GjIcon
            name="Calendar"
            size="18"
          />
        </div>
      </div>
    </template>
    <div class="dashboard-calendar">
      <flat-pickr
        v-model="accessDate"
        size="sm"
        class="media-list__calendar"
        :config="dateConfig"
      />
      <div
        v-if="asset"
        class="dashboard-overview__header-action"
      >
        <p class="default-date">
          {{ $t('assets.metadata.expiration.defaults') }}
        </p>
        <button
          class="dashboard-overview__filter-preset"
          :class="{'dashboard-overview__filter-preset-selected': currentDateType === filterPresets.ONE_DAY,}"
          @click="setDateRange(filterPresets.ONE_DAY)"
        >
          {{ $t('assets.metadata.expiration.predefined-options.days-1') }}
        </button>
        <button
          class="dashboard-overview__filter-preset "
          :class="{ 'dashboard-overview__filter-preset-selected': currentDateType === filterPresets.THIRTY_DAYS,}"
          @click="setDateRange(filterPresets.THIRTY_DAYS)"
        >
          {{ $t('assets.metadata.expiration.predefined-options.days-30') }}
        </button>
        <button
          class=" prevMonthDay dashboard-overview__filter-preset"
          :class="{'dashboard-overview__filter-preset-selected': currentDateType === filterPresets.NINETY_DAYS,}"
          @click="setDateRange(filterPresets.NINETY_DAYS)"
        >
          {{ $t('assets.metadata.expiration.predefined-options.days-90') }}
        </button>
      </div>
    </div>
    <div class="dashboard-buttons">
      <b-button
        variant="outline-secondary"
        @click="clear"
      >
        {{ $t('general.clear') }}
      </b-button>
      <b-button
        variant="outline-secondary"
        @click="clearDateRange"
      >
        {{ $t('general.cancel') }}
      </b-button>
      <b-button
        variant="primary"
        @click="apply"
      >
        {{ $t('general.apply') }}
      </b-button>
    </div>
  </b-dropdown>
</template>

<script>
import moment from 'moment'

export default {
  name: 'ExpireDateSelector',
  props: {
    value: String,
    asset: Object,
    disabled: Boolean,
  },
  data() {
    const filterPresets = Object.freeze({
      CUSTOM: 'custom',
      ONE_DAY: 'one-day',
      THIRTY_DAYS: 'thirty-days',
      NINETY_DAYS: 'ninety-days',
    })
    return {
      filterPresets,
      date: this.value,
      currentDateFilter: filterPresets.CUSTOM,
      dateConfig: {
        enableTime: true,
        inline: true,
        minDate: 'today',
        altInput: false,
      },
    }
  },
  computed: {
    accessDate: {
      get() {
        const date = moment(this.date)
        return date.isValid() ? this.date : ''
      },
      set(v) {
        this.date = v
      },
    },
    currentDateTime() {
      return this.$options.filters.formatDateTime(this.value)
    },
    currentDateType() {
      return this.currentDateFilter
    },
  },
  watch: {
    date: {
      handler() {
        this.setDateFilter()
      },
      deep: true,
    },
  },
  methods: {
    clear() {
      this.date = ''
      this.apply()
    },
    setDateFilter() {
      if (this.asset) {
        if (this.date === moment(this.asset.createdAt).add(1, 'days').format('YYYY-MM-DD HH:mm')) {
          this.currentDateFilter = this.filterPresets.ONE_DAY
        } else if (this.date === moment(this.asset.createdAt).add(30, 'days').format('YYYY-MM-DD HH:mm')) {
          this.currentDateFilter = this.filterPresets.THIRTY_DAYS
        } else if (this.date === moment(this.asset.createdAt).add(90, 'days').format('YYYY-MM-DD HH:mm')) {
          this.currentDateFilter = this.filterPresets.NINETY_DAYS
        } else {
          this.currentDateFilter = this.filterPresets.CUSTOM
        }
      }
    },
    setDateRange(type) {
      if (this.asset) {
        if (type === this.filterPresets.ONE_DAY) {
          this.date = moment(this.asset.createdAt).add(1, 'days').format('YYYY-MM-DD HH:mm')
        } else if (type === this.filterPresets.THIRTY_DAYS) {
          this.date = moment(this.asset.createdAt).add(30, 'days').format('YYYY-MM-DD HH:mm')
        } else if (type === this.filterPresets.NINETY_DAYS) {
          this.date = moment(this.asset.createdAt).add(90, 'days').format('YYYY-MM-DD HH:mm')
        }
      } else {
        this.currentDateFilter = type
      }
    },
    apply() {
      setTimeout(() => {
        if (!this.asset && this.currentDateFilter !== this.filterPresets.CUSTOM) {
          this.$emit('input', this.currentDateFilter)
        } else {
          this.$emit('input', moment(this.date).utc().toISOString())
        }

        this.$refs.dropdown.hide()
      }, 500)
    },
    clearDateRange() {
      this.$refs.dropdown.hide()
    },
  },
}
</script>

<style lang="scss">
.expire-date-selector {
  display: flex;
  width: 100%;

  .dropdown.compact-dropdown {
    .flatpickr-input {
      display: none;
    }
    .dropdown-menu {
      min-width: 100px;
      padding: 5px 0;
      li {
        font-size: 14px;
        line-height: 18px;
      }
      .dropdown-item {
        padding: 5px 12px;
      }
    }
  }

  .input-container {
    position: relative;
    width: 100%;
    input {
      width: 100%;
      background-color: transparent;
    }
  }

  .icon-container {
    width: 100%;
    display: flex;
    justify-content: right;
    align-items: center;
    position:absolute;
    top: 0;
    height: 100%;
    padding-right: 10px;
  }
}
</style>
