<template>
  <div>
    <b-form-group
      :label="$t('fields.codex-field-reference.general.field-name')"
      label-for="reference-field__name"
    >
      <b-form-input
        id="reference-field__name"
        v-model="name"
        :placeholder="$t('fields.codex-field-reference.general.enter-field-modal-name')"
      />
    </b-form-group>

    <b-form-group
      :label="$t('fields.codex-field-reference.general.field-alias')"
      label-for="reference-field__alias"
    >
      <b-form-input
        id="reference-field__alias"
        :value="alias"
        :placeholder="$t('fields.codex-field-reference.general.enter-field-alias-name')"
        disabled
      />
    </b-form-group>

    <b-form-group
      :label="$t('fields.codex-field-reference.general.description')"
      label-for="reference-field__description"
    >
      <b-form-textarea
        id="reference-field__description"
        v-model="description"
        :placeholder="$t('fields.codex-field-reference.general.enter-description')"
        rows="2"
        no-resize
      />
    </b-form-group>

    <b-form-group
      style="opacity: .7; pointer-events: none"
      :label="$t('fields.codex-field-reference.general.nr-of-references')"
    >
      <b-form-radio-group v-model="valueType">
        <b-form-radio :value="VALUE_TYPES.SINGLE">
          {{ $t('fields.codex-field-reference.general.one-reference') }}
          <GjIcon
            v-b-tooltip.hover
            name="Info"
            title="This is similar to one-to-one relationships between objects in a database.. For example, a blog post can reference its only author"
            size="20"
          />
        </b-form-radio>
        <b-form-radio :value="VALUE_TYPES.LIST">
          {{ $t('fields.codex-field-reference.general.multiple-references') }}
          <GjIcon
            v-b-tooltip.hover
            name="Info"
            title="This is similar to one-to-many relationships between objects in a database. For example, a blog post can reference its several authors. API response will include a separate block for each field"
            size="20"
          />
        </b-form-radio>
      </b-form-radio-group>
    </b-form-group>
  </div>
</template>

<script>
import { generateComputedPropsFromAttrs } from '@/components/codex-layout-editor/BuilderUtils'
import { VALUE_TYPES } from '@/views/models/constants'
import BaseFieldMixin from '@/components/fields/BaseFieldMixin'

export default {
  name: 'General',
  mixins: [BaseFieldMixin],
  data() {
    return {
      VALUE_TYPES,
    }
  },
  computed: {
    ...generateComputedPropsFromAttrs([
      'name',
      'alias',
      'valueType',
      'description',
    ]),
  },
}
</script>
