/* eslint-disable vue/require-default-prop */
<template>
  <div v-if="data">
    <div
      class="authors__profile"
      :class="{'authors__profile--no-title': !showTitle}"
    >
      <div class="authors__profile-left">
        <div class="authors__profile-picture">
          <div
            v-permission="permission"
            class="author__profile-picture-section"
          >
            <template v-if="!data.image">
              <GjIcon
                name="Image"
                size="32"
              />
              <p class="authors__profile-img-desc">
                {{ $t('author-profile.image') }}
              </p>

              <label
                v-permission="['canEditAuthor', {entity: data}]"
                class="author__profile-picture-upload authors__profile-browse text-primary"
                @click="uploadImage"
              >
                <b-img :src="require(`@/assets/images/author-profile/Photo.svg`)" />
              </label>
            </template>

            <template v-else>
              <CodexImage
                :id="data.image.url"
                class="author__profile-picture"
                :size="$IMAGE_SIZES.THUMBNAIL_200"
              />
              <label
                v-permission="['canEditAuthor', {entity: data}]"
                class="author__profile-picture-upload authors__profile-browse text-primary"
                @click="uploadImage"
              >
                <b-img :src="require(`@/assets/images/author-profile/Photo.svg`)" />
              </label>
            </template>
          </div>
        </div>
      </div>

      <div class="authors__profile-right">
        <div
          v-if="showTitle"
          class="authors__profile-title"
        >
          <h5>{{ $t('author-profile.update-your-profile') }}</h5>
        </div>

        <div class="authors__profile-intro">
          <b-form-group :disabled="!data.isCustom && !!data.userId">
            <template #label>
              <div class="author-profile__name-label">
                <div class="author-profile__name-label-left">
                  {{ $t('author-profile.intro-isCustom') }}
                </div>
                <div
                  v-if="data.userId"
                  class="author-profile__name-label-right"
                >
                  <p>{{ $t('author-profile.custom-name') }}</p>
                  <b-form-checkbox
                    v-model="isCustom"
                    :disabled="!canEditAuthor({entity: data}).can"
                    size="md"
                    switch
                  />
                </div>
              </div>
            </template>
            <b-form-input
              id="at-user-settings-author-introduction-radio-customByline"
              v-model="data.byline"
              :disabled="!canEditAuthor({entity: data}).can"
              :placeholder="$t('author-profile.intro-isCustom-placeholder')"
            />
          </b-form-group>

          <b-form-group :label="$t('author-profile.author-bio')">
            <b-form-textarea
              id="at-user-settings-author-introduction-bio"
              v-model="data.biography"
              :disabled="!canEditAuthor({entity: data}).can"
              :placeholder="$t('author-profile.author-bio-placeholder')"
              rows="3"
            />
          </b-form-group>

          <b-form-group :label="$t('author-profile.email-address')">
            <b-form-input
              v-model="data.email"
              :disabled="!canEditAuthor({entity: data}).can"
              :placeholder="$t('author-profile.email-address-placeholder')"
            />
          </b-form-group>

          <b-input-group class="author-url__group">
            <template #prepend>
              <label
                :style="urlDisabled ? 'background: #efefef;' : 'background: white;'"
                class="author__hyperlink"
                for="author-url"
              >
                <GjIcon name="Hyperlink" />
              </label>
            </template>
            <b-form-input
              id="author-url"
              v-model="data.url"
              placeholder="URL"
              class="author__url"
              :disabled="urlDisabled"
            />
            <b-input-group-append>
              <span
                class="author-profile__edit shadow-sm"
                :style="urlDisabled ? 'background: #efefef;' : 'background: white;'"
              >
                <GjIcon
                  v-if="urlDisabled"
                  name="EditAlt"
                  @click.native="urlDisabled = !urlDisabled"
                />
              </span>
            </b-input-group-append>
          </b-input-group>
        </div>

        <div class="authors-settings__visibility">
          <h6> {{ $t('author-profile.profile-visibility') }}</h6>
          <div class="authors__card-header-right authors__profile-status">
            <b-form-checkbox
              v-model="data.status"
              :value="authorStatuses.PUBLISHED"
              :disabled="!canEditAuthor({entity: data}).can"
              :unchecked-value="authorStatuses.UNPUBLISHED"
              size="md"
              switch
              class="authors__profile-status-desc"
            >
              {{ $t('author-profile.make-author-profile-public') }}
            </b-form-checkbox>
          </div>
        </div>

        <h6>
          {{ $t('author-profile.online-connection') }}
        </h6>

        <div v-if="data">
          <div
            v-for="(contact, idx) in social"
            :key="idx"
            class="authors-online"
          >
            <div class="authors-online__icon">
              <b-img :src="require(`@/assets/images/author-profile/${contact.image}.svg`)" />
            </div>
            <div class="authors-online__title">
              {{ contact.value }}
            </div>
            <div class="authors-online__input">
              <b-form-input
                v-model="data[contact.type]"
                :disabled="!canEditAuthor({entity: data}).can"
                class="at-authors-create-contact-info-input"
                :placeholder="contact.placeholder"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import { authorStatuses } from '@/codex-sdk/authors'
import { canEditAuthor } from '@/codex-permissions/authors'

export default {
  name: 'SettingsAuthorProfile',
  props: {
    placement: String,
    data: Object,
    showTitle: {
      type: Boolean,
      default: true,
    },

    permission: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      authorStatuses,
      user: null,
      canEditAuthor,
      urlDisabled: true,
    }
  },
  inject: ['toastNotification', 'showAssetsPopup'],
  computed: {
    isCustom: {
      get() {
        return !!this.data.isCustom
      },
      set(v) {
        this.$set(this.data, 'isCustom', v)

        if (this.data.userId) {
          this.data.byline = [this.data.firstName, this.data.lastName].filter(Boolean).join(' ')
        }
      },
    },

    social() {
      return [
        {
          value: this.$t('author-profile.contact-info.author-personal-website'),
          type: 'website',
          image: 'Personal website',
          placeholder: this.$t('author-profile.contact-info.author-personal-website-placeholder'),
        },
        {
          value: this.$t('author-profile.contact-info.author-facebook'),
          type: 'facebook',
          image: 'Facebook',
          placeholder: this.$t('author-profile.contact-info.author-facebook-placeholder'),
        },
        {
          value: this.$t('author-profile.contact-info.author-instagram'),
          type: 'instagram',
          image: 'Instagram',
          placeholder: this.$t('author-profile.contact-info.author-instagram-placeholder'),
        },
        {
          value: this.$t('author-profile.contact-info.author-twitter'),
          type: 'twitter',
          image: 'Instagram',
          placeholder: this.$t('author-profile.contact-info.author-twitter-placeholder'),
        },
        {
          value: this.$t('author-profile.contact-info.author-linkedIn'),
          type: 'linkedin',
          image: 'LinkedIn',
          placeholder: this.$t('author-profile.contact-info.author-linkedIn-placeholder'),
        },
        {
          value: this.$t('author-profile.contact-info.author-telegram'),
          type: 'telegram',
          image: 'Telegram',
          placeholder: this.$t('author-profile.contact-info.author-telegram-placeholder'),
        },
        {
          value: this.$t('author-profile.contact-info.author-tiktok'),
          type: 'tiktok',
          image: 'TikTok',
          placeholder: this.$t('author-profile.contact-info.author-tiktok-placeholder'),
        },
      ]
    },
  },
  methods: {
    removeImageUrl() {
      this.data.imageUrl = ''
    },
    async uploadImage() {
      const asset = await this.showAssetsPopup({ limit: 1 })

      if (asset && asset.length > 0) {
        this.data.imageId = asset[0].id
        this.data.image = {
          url: asset[0].url,
        }
      }
    },
  },
}
</script>

<style lang="scss">
.author__hyperlink {
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  border-left: 1px solid #d8d6de;
  border-top: 1px solid #d8d6de;
  border-bottom: 1px solid #d8d6de;
  padding: 8px;
  color: #206ED5;
}

.author-profile__edit {
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  border-top: 1px solid #d8d6de;
  border-bottom: 1px solid #d8d6de;
  padding: 8px;
  height: 90%;
}

.author-url__group {
  &:focus-within {
    .author-profile__edit {
      border-right: 1px solid #206ED5 !important;
      border-top: 1px solid #206ED5;
      border-bottom: 1px solid #206ED5;
    }
  }
}
.author__url {
  border-right: none;

}
</style>
