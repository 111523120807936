export default {
  props: {
    widget: {
      required: true,
      type: Object,
    },
    updateAttrs: {
      type: Function,
      default: () => { },
    },
  },
}
