<template>
  <div class="entry-edit">
    <div
      v-if="!exists && !loading"
      class="entry-edit__not-found"
    >
      <NoElements
        :title="$t('entries.not-found.title')"
        :icon="model.iconId || 'Modeling'"
      >
        <template #content>
          <router-link :to="{ name: 'entries', params: { viewId: $route.params.viewId || 'all'} }">
            {{ $t('entries.not-found.back-to-list') }}
          </router-link>
        </template>
      </NoElements>
    </div>
    <div
      v-if="!isPopup && exists"
      class="entry-edit__header-mobile"
    >
      <AutoSave class="codex-autosave--no-text" />
      <GjIcon
        v-if="entry.system.status !== ENTRY_STATUSES.PUBLISHED"
        class="entry-edit__header-publish"
        name="Rocket"
        @click.native="publishEntry"
      />
      <GjIcon
        name="Moreoptions"
        @click.native="showSidebar = true"
      />
    </div>
    <div
      :class="{
        'entry-edit__main--focus': focusMode,
        'entry-edit__main': !focusMode,
        'entry-edit__main--vertical-tabs': !focusMode,
        'entry-edit__main--vertical-tabs-collapsed': isCollapsed && !focusMode
      }"
    >
      <AlertPopup
        v-if="showAlertPopup"
        @close="showAlertPopup = false"
        @checkingRefresh="refreshPage"
      />
      <div
        v-if="hasLockError"
        class="entry-edit__locked"
      >
        <div class="entry-edit__locked-inner">
          <div class="entry-edit__locked-inner-left">
            <GjIcon
              key="Lock_fill"
              name="Lock_fill"
              class="entry-edit__locked-inner-icon"
            />
            <span>
              {{ lockError }}
            </span>
          </div>
          <div
            v-if="canTakeOverLock"
            class="entry-edit__locked-inner-right"
          >
            <b-button
              class="entry-edit__locked-take-over"
              @click="takeOverLock"
            >
              {{ $t('entries.lock-take-over.button-label') }}
            </b-button>
          </div>
        </div>
      </div>
      <div
        v-if="showScheduledNotification"
        class="entry-edit__scheduled"
      >
        <div class="entry-edit__scheduled-inner">
          <div class="entry-edit__scheduled-inner-left">
            <GjIcon
              name="AlertTriangleFilled"
              class="entry-edit__scheduled-inner-icon"
            />
            <span>
              {{ $t('entries.entry-scheduled-notification') }}
            </span>
          </div>
          <div class="entry-edit__scheduled-inner-right">
            <GjIcon
              name="Close"
              @click.native="showScheduledNotification = false"
            />
          </div>
        </div>
      </div>
      <FullHeightLayout
        v-show="exists"
        :class="{'entry-edit__main-inner': true,'entry-edit__compare-screen': compareEntry, 'version__preview': preview}"
        style="border: none !important; box-shadow: none !important; margin-top: -19px;"
        min
      >
        <div
          v-if="getEntry && !!version.version"
          class="entry-edit__other-logs"
        >
          <span class="entry-edit__other-logs-title">
            {{ $t('entries.listing.system-fields') }}
          </span>
          <div class="entry-edit__other-logs-grid">
            <span class="entry-edit__other-logs-text">
              {{ $t('entries.sidebar-tabs.general.state') }}
            </span>
            <div>
              <StatusChip
                v-if="!loading"
                :key="entry.id + getEntry.system.status"
                :with-background="true"
                :entry="getEntry"
              />
            </div>
            <template v-if="getEntry.system.publishedAt && getEntry.system.status === ENTRY_STATUSES.PUBLISHED || getEntry.system.status === ENTRY_STATUSES.EDITED || getEntry.system.status === ENTRY_STATUSES.SCHEDULED">
              <div class="entry-edit__other-logs-text">
                {{ getEntry.system.status === ENTRY_STATUSES.SCHEDULED ? $t('entries.sidebar-tabs.general.scheduled-date') : $t('entries.sidebar-tabs.general.published-date') }}
              </div>
              <div class="entry-edit__other-logs-text">
                {{ getEntry.system.publishedAt | formatDateTime }}
              </div>
            </template>
            <template v-if="getEntry.system.unpublishedAt">
              <div class="entry-edit__other-logs-text">
                {{ $t('entries.sidebar-tabs.general.schedule-unpublish') }}
              </div>
              <div class="entry-edit__other-logs-text">
                {{ getEntry.system.unpublishedAt | formatDateTime }}
              </div>
            </template>
            <template v-if="getEntry.system.firstPublishedAt">
              <div class="entry-edit__other-logs-text">
                {{ $t('entries.sidebar-tabs.general.first-published-at') }}
              </div>
              <div class="entry-edit__other-logs-text">
                {{ getEntry.system.firstPublishedAt | formatDateTime }}
              </div>
            </template>

            <div class="entry-edit__other-logs-text">
              {{ $t('entries.sidebar-tabs.general.labels') }}
            </div>
            <div>
              <DynamicLabels
                v-model="getEntry.system.labels"
                :site-id="site.id"
                :entity-type="LABEL_COMPONENT_ENTITY_TYPES.ENTRY"
                :allow-all="model.configurations.labels.allowAll"
                :allowed="model.configurations.labels.allowed"
                :show-add-button="false"
                :show-label="false"
              />
            </div>
          </div>
        </div>

        <MergingTool
          v-if="compareEntry"
          @close="cancelCompare"
          @mergeData="mergeData"
        />
        <ModelRenderer
          v-else-if="model && !loading"
          :key="evId"
          :model="model"
          :is-read-only="preview"
        />
      </FullHeightLayout>
    </div>

    <div
      v-show="!focusMode"
      ref="entryEditSidebar"
      class="entry-edit__sidebar"
      :class="{'entry-edit__sidebar--hidden': !showSidebar}"
    >
      <div
        class="entry-edit__sidebar-close"
        @click="showSidebar = false"
      >
        <GjIcon name="Close" />
      </div>
      <div
        v-if="exists"
        class="entry-edit__sidebar-inner entry-edit__sidebar-inner--active"
      >
        <b-tabs
          v-model="tabIndex"
          class="entry-edit__sidebar-tabs vertical-tabs"
        >
          <b-tab name="at-articles-tabs-general">
            <template v-slot:title>
              <span
                v-b-tooltip.viewport.left
                :title="$t('entries.sidebar-tabs.general.title')"
              >
                <GjIcon
                  size="20"
                  name="Settings"
                />
              </span>
            </template>

            <h3 class="vertical-tabs__content-title">
              {{ $t('entries.sidebar-tabs.general.title') }}
            </h3>
            <div class="vertical-tabs__content-content">
              <!--              Preview-->
              <b-form-group
                v-if="getGeneralSidebar('preview').isEnabled"
                :style="`order: ${getGeneralSidebar('preview').order};`"
                :class="{disabled: isSaving}"
              >
                <div class="entry-edit__sidebar__header">
                  <a
                    :id="`at-preview-url${entry.id}`"
                    target="_blank"
                    :style="`${!getPreviewURL ? 'color: #667C99;pointer-events: none;' : ''}}`"
                    class="entry-edit__sidebar__urls entry-edit__sidebar__preview-url"
                    @click="handlePreview"
                  >
                    <span>{{ $t('entries.preview-url') }}</span>
                    <GjIcon
                      name="Show"
                      size="16"
                      style="flex-shrink: 0"
                    />
                  </a>
                  <!-- Don't remove "custom-class" prop -->
                  <b-tooltip
                    v-if="!!getPreviewURL"
                    triggers="hover"
                    delay="500"
                    :target="`at-preview-url${entry.id}`"
                    class="entry-edit__sidebar__popup"
                    custom-class=""
                  >
                    <span @click="copyPreviewURL">
                      {{ getPreviewURL }}
                    </span>
                  </b-tooltip>
                </div>
              </b-form-group>
              <!--              View-->
              <b-form-group
                v-if="getGeneralSidebar('view').isEnabled && !!getViewUrl"
                :style="`order: ${getGeneralSidebar('view').order};`"
              >
                <div class="entry-edit__sidebar__header">
                  <a
                    :id="`at-view-url${entry.id}`"
                    target="_blank"
                    :href="getViewUrl"
                    class="entry-edit__sidebar__urls entry-edit__sidebar__view-url"
                  >
                    <span>{{ $t('entries.view-url') }}</span>
                    <GjIcon
                      name="Redirect"
                      size="16"
                      style="flex-shrink: 0"
                    />
                  </a>
                  <!-- Don't remove "custom-class" prop -->
                  <b-tooltip
                    triggers="hover"
                    delay="500"
                    :target="`at-view-url${entry.id}`"
                    class="entry-edit__sidebar__popup"
                    custom-class=""
                  >
                    <span @click="copyViewUrl">
                      {{ getViewUrl }}
                    </span>
                  </b-tooltip>
                </div>
              </b-form-group>
              <!--              SEO Analyzer-->

              <b-button
                v-if="getEntry.id && getGeneralSidebar('aiContentAnalyzer').isEnabled"
                id="seo-analyzer-button"
                :key="`${getEntry.id}-seo`"
                size="sm"
                variant="outline-dark"
                class="entries-edit__seo-button"
                :style="`order: ${getGeneralSidebar('aiContentAnalyzer').order};`"
                :class="{version__preview: preview}"
                @click="handleShowSeoAnalyzer"
              >
                {{ $t('entries.sidebar-tabs.general.ai-content-analyzer') }}
                <AiIcon size="18" />
              </b-button>

              <!--              Status-->
              <b-form-group
                v-if="getGeneralSidebar('status').isEnabled"
                :label="$t('entries.sidebar-tabs.general.state')"
                label-cols="4"
                :style="`order: ${getGeneralSidebar('status').order}`"
                class="entry-edit__sidebar-status-wrapper"
              >
                <StatusChip
                  v-if="!loading"
                  :key="entry.id + getEntry.system.status"
                  :with-background="true"
                  :entry="getEntry"
                  :scheduled-versions="scheduledVersions"
                />
              </b-form-group>
              <!--              Labels-->
              <div
                v-if="getGeneralSidebar('labels').isEnabled"
                class="entry-edit__sidebar-labels-wrapper"
                :style="`order: ${getGeneralSidebar('labels').order}`"
                :class="{version__preview: preview}"
              >
                <DynamicLabels
                  v-model="getEntry.system.labels"
                  v-permission="['canEditEntryLabels', { ...entry, entryLabels }]"
                  :entry="getEntry"
                  :site-id="site.id"
                  :entity-type="LABEL_COMPONENT_ENTITY_TYPES.ENTRY"
                  :allow-all="model.configurations.labels.allowAll"
                  :allowed="model.configurations.labels.allowed"
                />
              </div>

              <!--              Id-->
              <b-form-group
                v-if="getGeneralSidebar('id').isEnabled && getEntry.id"
                :key="getEntry.id"
                content-cols="6"
                :label="$t('entries.sidebar-tabs.general.id')"
                :style="`order: ${getGeneralSidebar('id').order};`"
              >
                <div class="entry-edit__sidebar-ids">
                  <p>
                    {{ getEntry.id }}
                  </p>
                  <GjIcon
                    name="Copy"
                    size="14"
                    style="flex-shrink: 0; cursor: pointer;"
                    @click.native="copyId"
                  />
                </div>
              </b-form-group>

              <!--              ExternalId-->
              <b-form-group
                v-if="getGeneralSidebar('externalId').isEnabled && getEntry.system.externalId"
                :key="getEntry.system.externalId"
                content-cols="6"
                :label="$t('entries.sidebar-tabs.general.externalId')"
                :style="`order: ${getGeneralSidebar('externalId').order}`"
              >
                <div class="entry-edit__sidebar-ids">
                  <p>
                    {{ getEntry.system.externalId }}
                  </p>
                  <GjIcon
                    name="Copy"
                    size="14"
                    style="flex-shrink: 0; cursor: pointer;"
                    @click.native="copyExternalId"
                  />
                </div>
              </b-form-group>

              <!--              Published Date-->
              <div
                v-if="getGeneralSidebar('publishedAt').isEnabled"
                :style="`order: ${getGeneralSidebar('publishedAt').order}`"
              >
                <b-form-group
                  v-if="getEntry.system.status === ENTRY_STATUSES.PUBLISHED || getEntry.system.status === ENTRY_STATUSES.SCHEDULED || getEntry.system.status === ENTRY_STATUSES.EDITED"
                  :label="getEntry.system.status === ENTRY_STATUSES.SCHEDULED ? $t('entries.sidebar-tabs.general.scheduled-date') : $t('entries.sidebar-tabs.general.published-date')"
                  label-cols="6"
                >
                  <p>
                    {{ getEntry.system.publishedAt | formatDateTime }}
                  </p>
                </b-form-group>
                <b-form-group
                  v-if="getEntry.system.unpublishedAt"
                  :label="$t('entries.sidebar-tabs.general.schedule-unpublish')"
                  label-cols="6"
                >
                  <p>
                    {{ getEntry.system.unpublishedAt | formatDateTime }}
                  </p>
                </b-form-group>
              </div>

              <!--              Metrics-->
              <div
                v-if="getGeneralSidebar('metrics').isEnabled"
                :style="`order: ${getGeneralSidebar('metrics').order}`"
              >
                <div class="entry-edit__metrics">
                  <p class="entry-edit__metrics__label">
                    {{ $t('entries.sidebar-tabs.general.metrics') }}
                  </p>
                  <b-form-group
                    content-cols="6"
                    :label="$t('entries.sidebar-tabs.general.reading-time')"
                  >
                    <p>
                      {{ getMetric('readingTime') }}
                    </p>
                  </b-form-group>
                  <b-form-group
                    content-cols="6"
                    :label="$t('entries.sidebar-tabs.general.words')"
                  >
                    <p>
                      {{ getMetric('wordCount') }}
                    </p>
                  </b-form-group>
                  <b-form-group
                    content-cols="6"
                    :label="$t('entries.sidebar-tabs.general.characters')"
                  >
                    <p>
                      {{ getMetric('characterCount') }}
                    </p>
                  </b-form-group>
                  <b-form-group
                    content-cols="6"
                    :label="$t('entries.sidebar-tabs.general.sentences')"
                  >
                    <p>
                      {{ getMetric('sentenceCount') }}
                    </p>
                  </b-form-group>
                  <b-form-group
                    v-b-tooltip.hover.html="metricsTooltip"
                    content-cols="6"
                    :label="$t('entries.sidebar-tabs.general.assets')"
                  >
                    <p>
                      {{ assetsCount }}
                    </p>
                  </b-form-group>
                </div>
              </div>
              <!--              Entry Create-->
              <EntityCreateInfo
                v-if="getGeneralSidebar('created').isEnabled"
                :key="`${getEntry.id}-create`"
                :style="`order: ${getGeneralSidebar('created').order}`"
                :entry="getEntry"
              />

              <!--              Entry Update-->
              <EntityUpdateInfo
                v-if="getGeneralSidebar('updated').isEnabled"
                :key="`${getEntry.id}-update`"
                :style="`order: ${getGeneralSidebar('updated').order}`"
                :entry="getEntry"
              />

              <!--              Prolexis-->
              <b-button
                v-if="prolexisConfiguration.show"
                id="prolexis-button"
                :key="`${getEntry.id}-prolexis`"
                size="sm"
                variant="outline-dark"
                class="entries-edit__prolexis-button"
                :style="`order: ${getGeneralSidebar('prolexis').order}`"
                :class="{version__preview: preview}"
                @click="checkWithProlexis"
              >
                <img
                  src="../../../src/assets/images/icons/prolexis.svg"
                  alt="Prolexis logo"
                  class="mr-1"
                >
                {{ $t('entries.sidebar-tabs.general.check-with-prolexis') }}
              </b-button>

              <!--              Add to List-->
              <div
                v-if="getGeneralSidebar('addToList').isEnabled"
                class="entry-edit__sidebar-lists-wrapper"
                :style="`order: ${getGeneralSidebar('addToList').order}`"
              >
                <b-form-group
                  content-cols="6"
                  :label="$t('entries.sidebar-tabs.general.add-to-list-label')"
                >
                  <AddToEntriesLists
                    v-if="entry && entry.id && getGeneralSidebar('addToList').isEnabled"
                    :entry-id="entry.id"
                    :model-alias="entry.system.modelAlias"
                    :classes="'entries-list-dropdown'"
                    :style="`order: ${getGeneralSidebar('addToList').order}`"
                  >
                    <template slot="button">
                      <p class="entry-edit__sidebar-lists-show">
                        <GjIcon
                          name="Plus"
                          size="14"
                        />
                        <span>
                          {{ $t('entries.lists.add') }}
                        </span>
                      </p>
                    </template>
                  </AddToEntriesLists>
                </b-form-group>
              </div>

              <div
                v-if="getGeneralSidebar('firstPublishedAt').isEnabled"
                :style="`order: ${getGeneralSidebar('firstPublishedAt').order}`"
              >
                <b-form-group
                  v-if="getEntry.system.firstPublishedAt"
                  :label="$t('entries.sidebar-tabs.general.first-published-at')"
                  label-cols="6"
                >
                  <p>
                    {{ getEntry.system.firstPublishedAt | formatDateTime }}
                  </p>
                </b-form-group>
              </div>

              <!-- Plugin sidebar items -->
              <template v-for="item in entrySidebarItems">
                <component
                  :is="item.component"
                  v-if="getGeneralSidebar(item.name, false).isEnabled && !!$refs.saveButtonEntries"
                  :key="item.name"
                  :style="`order: ${getGeneralSidebar(item.name, ).order}`"
                  :entry="entryClone"
                  :entry-version="entryVersionClone"
                  :model="modelClone"
                  :set-field-value="setFieldValue"
                />
              </template>
              <!-- /Plugin sidebar items -->

              <ScheduledVersions
                v-if="getGeneralSidebar('scheduledVersions').isEnabled && scheduledVersions"
                :style="`order: ${getGeneralSidebar('scheduledVersions', true).order}`"
                :entry-id="entityId"
                :model-alias="model.alias"
                :site-id="site.id"
                :versions="versions"
                :scheduled-versions="scheduledVersions"
                @refetchScheduledVersions="refetchScheduledVersions"
              />
            </div>
          </b-tab>

          <b-tab name="at-articles-tab-comments">
            <template v-slot:title>
              <span
                v-b-tooltip.viewport.left
                :title="$t('entries.sidebar-tabs.comments.title')"
              >
                <GjIcon
                  size="20"
                  name="CommentAlt"
                />
              </span>
            </template>

            <h3
              class="vertical-tabs__content-title"
              style="margin-bottom: 0;"
            >
              {{ $t('entries.sidebar-tabs.comments.title') }}
              <b-dropdown
                variant="transparent"
                no-caret
                class="comments-filter"
                size="small"
              >
                <template #button-content>
                  {{ commentFilter.label }}
                  <GjIcon
                    name="ArrowDown"
                    size="20"
                  />
                </template>
                <b-dropdown-item
                  v-for="option in commentFilterOptions"
                  :key="option.value"
                  @click="commentsFieldAlias = option.value"
                >
                  {{ option.label }}
                </b-dropdown-item>
              </b-dropdown>
            </h3>
            <Comments
              :class="{version__preview: preview}"
              :comments="allComments"
              :model="model"
              :field-alias="commentsFieldAlias"
            />
          </b-tab>
          <b-tab
            v-if="entityId !== 'create' && getHistorySidebar().isEnabled"
            class="h-100"
            name="at-articles-tab-history"
          >
            <template v-slot:title>
              <span
                v-b-tooltip.viewport.left
                :title="$t('entries.sidebar-tabs.versions.title')"
              >
                <GjIcon
                  size="20"
                  name="Info"
                />
              </span>
            </template>
            <h3 class="vertical-tabs__content-title">
              {{ $t('entries.sidebar-tabs.versions.title') }}
              <b-form-checkbox
                v-model="showLogs"
                class="entry-edit__show-logs"
              >
                {{ $t('entries.sidebar-tabs.versions.activity-logs') }}
              </b-form-checkbox>
            </h3>
            <Listing
              ref="versionListing"
              :versions="versions"
              :entry-version="version"
              :model="model"
              :ev-id="evId"
              :comparing-id="comparingId"
              :entry="entry"
              :site-id="site.id"
              :show-logs="showLogs"
              :scheduled-versions="scheduledVersions"
              @handleVersionPreview="handleVersionPreview"
              @fetchVersions="fetchVersions"
            />
          </b-tab>
          <b-tab v-if="getReferencedBySidebar().isEnabled">
            <template v-slot:title>
              <span
                v-b-tooltip.viewport.left
                :title="$t('entries.sidebar-tabs.references.referenced-by')"
              >
                <GjIcon
                  size="20"
                  name="FlowVerticalAlt"
                />
              </span>
            </template>

            <h3 class="vertical-tabs__content-title">
              {{ $t('entries.sidebar-tabs.references.referenced-by') }}
            </h3>
            <div class="entry-edit__sidebar-references">
              <div
                v-if="entryReferences && entryReferences.total > 0"
                class="entry-edit__references-container"
              >
                <p class="entry-edit__reference-amount">
                  {{ $t('entries.sidebar-tabs.references.count',{ title: entry.system.title, count: entryReferences.total }) }}
                </p>
                <ReferenceEntryList :entries="entryReferences.items" />
              </div>
              <div
                v-else
                class="entry-edit__no-reference"
              >
                <GjIcon
                  name="FlowVerticalAlt"
                  class="entry-edit__no-icon"
                  size="32"
                />
                <span class="entry-edit__reference-no-text">
                  {{ $t('entries.sidebar-tabs.references.no-reference') }}
                </span>
              </div>
            </div>
          </b-tab>
          <b-tab v-if="getClonedReferenceSidebar().isEnabled && clonedFromEntry || (clonedTo && clonedTo.total)">
            <template v-slot:title>
              <span
                v-b-tooltip.viewport.left
                :title="$t('entries.sidebar-tabs.cloned-references.title')"
              >
                <GjIcon
                  size="20"
                  name="Copy"
                />
              </span>
            </template>

            <h3 class="vertical-tabs__content-title">
              {{ $t('entries.sidebar-tabs.cloned-references.title') }}
            </h3>
            <app-collapse class="entry-edit__sidebar-clone-info">
              <app-collapse-item
                v-if="clonedFromEntry"
                :is-visible="true"
              >
                <template #header>
                  <span>{{ $t('entries.sidebar-tabs.cloned-references.from') }}</span>
                </template>
                <ReferenceEntryList
                  v-if="clonedFromEntry"
                  :entries="[clonedFromEntry]"
                />
              </app-collapse-item>
              <app-collapse-item
                v-if="clonedTo && clonedTo.total"
                :is-visible="true"
              >
                <template #header>
                  <span>{{ $t('entries.sidebar-tabs.cloned-references.to', { count: clonedTo.total }) }}</span>
                </template>
                <ReferenceEntryList :entries="clonedTo.items" />
              </app-collapse-item>
            </app-collapse>
          </b-tab>
        </b-tabs>
        <Previewing
          v-if="version.previewingVersion"
          :previewing="version.previewingVersion"
          @handleCancelPreview="$refs.versionListing.handleCancelPreview()"
        />

        <div
          v-if="!preview"
          :class="['entry-edit__sidebar-footer', tabIndex === 1 && 'p-0']"
        >
          <CommentEdit
            v-if="tabIndex === 1 && entry.id"
            :value="true"
            style="width: 100%;"
          />
          <SaveButton
            v-show="tabIndex !== 1"
            ref="saveButtonEntries"
            :key="`${entry.system.status}${entry.id}`"
            name="at-entries-tab-saveOrPublish"
            :entry="entry"
            :model="model"
            @save="publishOrSaveEntry"
            @scheduleVersion="createScheduleEntryVersion"
            @delete="deleteEntry"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  debounce, pick, isEqual, omit, cloneDeep, throttle,
} from 'lodash'
import { ACCESS_STRATEGIES, modelDefaults, readModel } from '@/codex-sdk/models'
import FullHeightLayout from '@/../gjirafa-front/script/full-height-layout.vue'
import {
  checkConditions, GenerateUUID, getFieldPermissions, someParentHasClass,
} from '@/utils/helpers'
import Previewing from '@/components/version-history/Previewing.vue'
import {
  createEntry,
  deleteEntry,
  ENTRY_STATUSES,
  entryDefaults,
  readEntry,
  updateEntry,
  lockEntry,
  checkEntryLock,
  unlockEntry,
  transformEntriesGraphQL,
  readEntryVersion,
  deleteEntryLockFetch,
  createScheduleEntryVersion,
} from '@/codex-sdk/entries'
import SaveButton from '@/views/entries/components/SaveButton.vue'
import moment from 'moment'
import {
  getBlocks, VALUE_TYPES, validateFieldValueSchema, TYPES,
} from '@/views/models/constants'
import { mapGetters, mapMutations, mapState } from 'vuex'
import { AUTO_SAVE_STATUSES, ENTITY_TYPES, ERROR_CODES } from '@/utils/constants'
import gql from 'graphql-tag'
import EntityCreateInfo from '@/views/entries/components/EntityCreateInfo.vue'
import EntityUpdateInfo from '@/views/entries/components/EntityUpdateInfo.vue'
import { generateURL, LOCK_TIME_OUT } from '@/views/entries/configs'
import AlertPopup from '@/components/AlertPopup.vue'
import { useClipboard } from '@vueuse/core'
import MergingTool from '@/views/merging-tool/MergingTool.vue'
import AutoSave from '@/components/auto-save/AutoSave.vue'
import { getCurrentSiteId, getCurrentOrganizationAlias } from '@/services/siteService'
import { checkPermissionAsync } from '@/codex-permissions/config'
import {
  canEditEntry, canEditEntryLabels, canPublishEntry, canTakeOverLockEntry, canUnpublishEntry,
} from '@/codex-permissions/entries'
import AddToEntriesLists from '@/components/lists/AddToEntriesLists.vue'
import NoElements from '@/components/NoElements.vue'
import { INTEGRATION_ALIASES } from '@/codex-sdk/all-integrations'
import { getUserData } from '@/auth/utils'
import DynamicLabels from '@/components/DynamicLabels.vue'
import { LABEL_COMPONENT_ENTITY_TYPES } from '@/codex-sdk/labels'
import ReferenceEntryList from '@/views/entries/components/ReferenceEntryList.vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import * as yup from 'yup'
import { CODEX_EDITOR_BLOCKS } from '@/components/codex-editor/CodeEditorConstants'
import Validations from '@/components/fields-validations/validations'
import CommentEdit from '@/components/fields/CommentEdit.vue'
import AiIcon from '@/components/ai/AiIcon.vue'
import ScheduledVersions from '@/views/entries/components/ScheduledVersions.vue'
import EntryVersionsMixin from '@/views/entries/EntryVersionsMixin'
import StatusChip from './components/StatusChip.vue'
import ModelRenderer from './render-components/ModelRenderer.vue'
import Listing from './versions/Listing.vue'
import Comments from './components/Comments.vue'

const COPY_PROPERTIES = ['createdAt', 'updatedAt', 'publishedAt', 'firstPublishedAt', 'unpublishedAt', 'createdBy', 'updatedBy', 'createdByUser', 'updatedByUser', 'metrics', 'slug', 'title', 'versionId', 'status', 'revisionId']

export default {
  inject: ['showConfirmDeletePopup', 'showConfirmPopup', 'toastNotification', 'showLabelsPopup', 'showSeoAnalyzerPopup'],
  components: {
    ScheduledVersions,
    CommentEdit,
    AppCollapseItem,
    AppCollapse,
    ReferenceEntryList,
    DynamicLabels,
    NoElements,
    AutoSave,
    EntityUpdateInfo,
    EntityCreateInfo,
    MergingTool,
    SaveButton,
    StatusChip,
    FullHeightLayout,
    Listing,
    Previewing,
    ModelRenderer,
    AlertPopup,
    AddToEntriesLists,
    Comments,
    AiIcon,
  },
  mixins: [EntryVersionsMixin],
  props: {
    isPopup: Boolean,
    mId: String,
    eId: String,
    siteId: String,
    filterValues: Object,
  },
  permissions: {
    viewableFields: {
      async handler(vars) {
        const {
          entryId, model, siteId, entryLabels,
        } = vars
        if (!model?.alias || !model?.fields || model?.fields.length === 0) return false

        const canViewFields = await checkPermissionAsync(entryId === 'create' ? undefined : entryId, 'entry', 'view', { model: model?.alias, siteId, entryLabels }, true)
        return getFieldPermissions(canViewFields, model)
      },
      variables() {
        return {
          entryId: this.entityId,
          model: this.model,
          siteId: this.site?.id,
          entryLabels: this.entryLabels,
        }
      },
    },

    editableFields: {
      async handler(vars) {
        const {
          entryId, model, siteId, entryLabels,
        } = vars
        if (!model?.alias || !model?.fields || model?.fields.length === 0) return false

        const canEditFields = await checkPermissionAsync(entryId === 'create' ? undefined : entryId, 'entry', 'editcontent', { model: model?.alias, siteId, entryLabels }, true)
        return getFieldPermissions(canEditFields, model)
      },
      variables() {
        return {
          entryId: this.entityId,
          model: this.model,
          siteId: this.site?.id,
          entryLabels: this.entryLabels,
        }
      },
    },
  },

  data() {
    const siteId = this.isPopup ? this.siteId : getCurrentSiteId()
    return {
      tabIndex: 0,
      showSidebar: false,
      // Entry REFERENCED BY ENTRIES
      entryReferences: {
        total: 0,
        items: [],
      },
      canTakeOverLock: false,
      showError: false,

      // Model
      model: modelDefaults(),
      compareEntry: null,
      validation: {},
      entry: entryDefaults(),
      entryPrevious: entryDefaults(),
      ENTRY_STATUSES,

      LABEL_COMPONENT_ENTITY_TYPES,

      versionId: GenerateUUID.VERSION(),
      version: {
        currentVersionId: '',
        previewingVersion: null,
        version: null,
      },

      versions: [],

      metrics: {},
      showScheduledNotification: null,

      // Auto-save
      saveTimeoutId: null,
      saving: false,
      saveEntryThrottle: throttle(this.saveEntry, 1000),
      changesWhileSaving: false,
      checkContentChange: false,

      // Saved changes
      deletedEntry: null,
      hasUnsavedChanges: false,

      // Versions
      loading: false,

      // Comments
      allComments: [],
      commentsFieldAlias: true,

      toggleIcon: 'ArrowExpandAlt',
      showAlertPopup: false,

      // Preview Error Messages
      evId_: null,
      currentPreviewUrl: '',

      // View
      viewUrl: '',

      // Editor
      selectedEditor: {
        alias: '',
      },

      // Concurrency
      lockError: '',
      hasLockError: false,
      lockedAt: null,
      unlockEntry: this._unlockEntry.bind(this, { siteId }),
      clonedFromEntry: null,
      entryLabels: '',
      showLogs: false,

      // SEO POPUP
      isCollapsed: false,
    }
  },
  apollo: {
    comments: {
      query: gql`
        query Comments($threadId: String!) {
          comments: commentCollection(where: { threadId: { eq: $threadId }, parentId: { exists: false } }, order: { createdAt: ASC }, limit: 10000) {
            items {
              id
              parentId
              threadId
              content
              attrs
              status
              createdAt
              createdBy {
                id
                firstName
                lastName
                email
                imageUrl
              }
              children {
                items {
                  id
                  parentId
                  threadId
                  content
                  attrs
                  status
                  createdAt
                  createdBy {
                    id
                    firstName
                    lastName
                    email
                    imageUrl
                  }
                }
              }
            }
          }
        }
      `,
      fetchPolicy: 'network-only',
      variables() {
        return {
          threadId: this.entry?.id,
        }
      },
      pollInterval: 60000,
      skip() {
        return !this.entry?.id
      },
      result({ data }) {
        this.allComments = data.comments.items.map(c => ({
          ...c,
          openBubble: false,
        }))
      },
    },
    entryReference: {
      query: gql`
        query Entry($id:String){
          entry(id: $id){
            system {
              title
            }
            relations {
              entryCollection (limit: 100000) {
                total
                items {
                  id
                  system {
                    title
                    slug
                    featuredMedia {
                      id
                      url(transformation: { width: 70, height: 70, format: THUMBNAIL })
                    }
                    status
                    modelId
                    modelAlias
                    siteId
                    scheduledVersions (where: {
                      status: { in: [SCHEDULED] }
                    }) {
                      items {
                        id
                        versionId
                        publishScheduledDate
                        unpublishScheduledDate
                        versionId
                        createdAt
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `,
      variables() {
        return {
          id: this.entityId,
        }
      },
      fetchPolicy: 'cache-and-network',
      update(results) {
        if (results?.entry?.relations?.entryCollection?.items?.length) {
          this.entryReferences.items = results?.entry?.relations?.entryCollection?.items || []
          this.entryReferences.total = results?.entry?.relations?.entryCollection?.total || 0
          transformEntriesGraphQL(this.entryReferences.items)
        }
      },
    },
    clonedFrom: {
      query: gql`
        query ($id: String!) {
          clonedFrom: entry(id: $id) {
            system {
              clonedFrom {
                items {
                  id
                  system {
                    title
                    slug
                    status
                    publishedAt
                    featuredMedia {
                      id
                      url(transformation: { width: 70, height: 70, format: THUMBNAIL })
                    }
                    modelId
                    modelAlias
                    siteId
                    createdBy {
                      id
                      email
                      firstName
                      lastName
                      imageUrl
                    }
                  }
                }
              }
            }
          }
      }`,
      variables() {
        return {
          id: this.entry.id,
        }
      },
      skip() {
        return !this.entry?.id || !this.getClonedReferenceSidebar().isEnabled
      },
      fetchPolicy: 'network-only',
      result({ data }) {
        if (data?.clonedFrom?.system?.clonedFrom?.items?.length) {
          transformEntriesGraphQL(data.clonedFrom.system.clonedFrom.items)
          this.clonedFromEntry = data.clonedFrom.system.clonedFrom.items[0]
        }
      },
    },
    clonedTo: {
      query: gql`
        query ($id: String!) {
        clonedTo: entryCollection(where: {
          system: {
            clonedFrom: {
              eq: $id
            }
          }
        }, limit: 10000) {
          total
          items {
            id
            system {
              title
              slug
              status
              publishedAt
              featuredMedia {
                id
                url(transformation: { width: 70, height: 70, format: THUMBNAIL })
              }
              modelId
              modelAlias
              siteId
              createdBy {
                id
                email
                firstName
                lastName
                imageUrl
              }
            }
          }
        }
      }`,
      variables() {
        return {
          id: this.entry.id,
        }
      },
      skip() {
        return !this.entry?.id || !this.getClonedReferenceSidebar().isEnabled
      },
      fetchPolicy: 'network-only',
      result({ data }) {
        if (data?.clonedTo?.items?.length) {
          transformEntriesGraphQL(data.clonedTo.items)
        }
        return data
      },
    },
  },
  computed: {
    /** For plugins: Clone entry to prevent changing the original entry */
    entryClone() {
      return cloneDeep(this.entry)
    },
    /** For plugins: Clone entry version to prevent changing the original entry */
    entryVersionClone() {
      if (!this.version.version) return cloneDeep(this.entry)
      return cloneDeep(this.version.version)
    },
    /** For plugins: Clone model to prevent changing the original model */
    modelClone() {
      return cloneDeep(this.model)
    },
    ...mapGetters('sites', [
      'getSite',
    ]),
    ...mapState('autoSave', [
      'status',
    ]),
    ...mapGetters('plugins', [
      'entrySidebarItems',
    ]),
    isSaving() {
      return this.status === AUTO_SAVE_STATUSES.SAVING
    },
    exists() {
      return !!this.entry?.id || this.entityId === 'create'
    },
    isLockEnabled() {
      return this.model?.configurations?.accessStrategy === ACCESS_STRATEGIES.RESTRICTED && (this.entry?.id && this.entry?.id !== 'create')
    },
    focusMode() {
      return this.$store.getters['focus/focusGetter']
    },
    commentFilterOptions() {
      const fields = this.fields.filter(field => this.allComments.some(c => c.attrs?.fieldAlias === field.attrs.alias)) || []
      return [
        { value: true, label: this.$t('entries.sidebar-tabs.comments.filter-options.all') },
        { value: false, label: this.$t('entries.sidebar-tabs.comments.filter-options.entry') },
        ...fields.map(field => ({
          value: field.attrs.alias,
          label: field.attrs.name,
        })),
      ]
    },
    commentFilter() {
      return this.commentFilterOptions.find(c => c.value === this.commentsFieldAlias)
    },
    evId: {
      get() {
        if (this.isPopup) return this.evId_
        return this.$route.query.evId
      },
      set(v) {
        if (this.isPopup) {
          this.evId_ = v
        } else {
          const query = v ? { evId: v } : {}
          this.$router.replace({ name: this.$route.name, query, params: { id: this.entry.id } }).catch(() => {})
        }
      },
    },
    comparingId() {
      return this.compareEntry?.system?.versionId
    },
    preview() {
      return !!this.version.version || this.hasLockError
    },
    previewConfig() {
      return this.model?.configurations?.sidebar?.general?.items?.preview || {
        isEnabled: true,
        order: 0,
        pattern: '',
      }
    },
    getPreviewURL() {
      if (this.getEntry.system.status !== ENTRY_STATUSES.PUBLISHED) {
        return this.prependDomain(this.site?.previewDomain, this.currentPreviewUrl)
      }

      return ''
    },
    getViewUrl() {
      if (!this.viewUrl) return ''
      return this.prependDomain(this.site?.domain, this.viewUrl)
    },
    getEntry() {
      if (this.version.version) {
        return this.version.version
      }
      return this.entry || {}
    },
    entityId() {
      if (this.isPopup) return this.eId
      return this.$route.params.id
    },
    modelId() {
      if (this.isPopup) return this.mId
      return this.$route.params.model
    },
    site() {
      if (this.isPopup) return this.getSite(this.siteId) || {}
      return this.$store.state.general.currentSite || {}
    },
    prolexisConfiguration() {
      const prolexisIntegrations = this.$store.getters['general/getConnections'](INTEGRATION_ALIASES.PROLEXIS).filter(i => i.organizationId === getCurrentOrganizationAlias()) || {}
      let config = { apiKey: '', show: false, url: '' }
      prolexisIntegrations.some(integration => {
        if (integration.attrs.sites.some(site => (site === 'all' || site === getCurrentSiteId()) && this.getGeneralSidebar('prolexis').isEnabled)) {
          config = { apiKey: integration.attrs.apiKey, url: integration.attrs.url, show: true }
          return true
        }
        return false
      })
      return config
    },
    metricsTooltip() {
      return { title: `<div class="d-flex flex-column">${this.$t('entries.sidebar-tabs.general.images')} ${this.getMetric('imageCount')}</div><div class="d-flex flex-column">${this.$t('entries.sidebar-tabs.general.videos')} ${this.getMetric('videoCount')}</div><div class="d-flex flex-column">${this.$t('entries.sidebar-tabs.general.files')} ${this.getMetric('filesCount')}</div><div class="d-flex flex-column">${this.$t('entries.sidebar-tabs.general.playlists')} ${this.getMetric('playlistsCount')}</div><div class="d-flex flex-column">${this.$t('entries.sidebar-tabs.general.audio')} ${this.getMetric('audioCount')}</div><div class="d-flex flex-column">${this.$t('entries.sidebar-tabs.general.podcast')} ${this.getMetric('podcastCount')}</div>` }
    },
    assetsCount() {
      if (!this.hasMetrics()) return 0

      return this.getMetric('imageCount') + this.getMetric('videoCount') + this.getMetric('filesCount') + this.getMetric('playlistsCount') + this.getMetric('audioCount') + this.getMetric('podcastCount')
    },
    urlFieldAliases() {
      return this.model?.fields?.filter(f => f.type === TYPES.URL).map(f => f.alias) || []
    },
    fields() {
      return getBlocks(this.model.blocks, block => block.isField) || []
    },
  },
  watch: {
    entry: {
      handler(newVal) {
        const systemFields = []
        Object.keys(newVal.system).forEach(key => {
          if (key !== 'labels') {
            systemFields.push(`system.${key}`)
          }
        })

        const toOmit = [...systemFields]

        if (isEqual(
          omit(JSON.parse(JSON.stringify(newVal)), toOmit),
          omit(JSON.parse(JSON.stringify(this.entryPrevious)), toOmit),
        )) return

        this.entryPrevious = cloneDeep(newVal)
        this.contentChanged()
      },
      deep: true,
    },
    focusMode(state) {
      if (state) {
        this.toggleIcon = 'ArrowShrinkAlt'
      } else {
        this.toggleIcon = 'ArrowExpandAlt'
      }
    },
    getEntry: {
      handler() {
        this.getCurrentPreviewUrl()
      },
      deep: true,
      immediate: true,
    },
    'entry.system.revisionId': function () {
      setTimeout(() => {
        this.getUrl()
      }, 1000)
    },
  },
  async mounted() {
    this.$root.$on('validateEntry', this.runValidations)
    document.addEventListener('keydown', this.handleKeys)
    window.addEventListener('unload', this.deleteLockOnUnload)
    document.addEventListener('focus', this.handleInputFocus, { capture: true })
    try {
      this.loading = true
      await Promise.allSettled([
        checkPermissionAsync(this.entityId === 'create' ? undefined : this.entityId, 'entry', 'editcontent', { model: this.modelId, siteId: this.site?.id, entryLabels: this.entryLabels }, true),
        checkPermissionAsync(this.entityId === 'create' ? undefined : this.entityId, 'entry', 'view', { model: this.modelId, siteId: this.site?.id, entryLabels: this.entryLabels }, true),
        this.fetchModel(),
      ])
      await this.fetchEntry()
      this.fetchVersions()
      if (this.entry?.id) {
        this.setTakeOver()
        this.lockEntry()
      }
      this.SET_RETRY_FUNCTION(this.saveEntry.bind(this, [{}, { successTitle: null }]))
      this.$forceUpdate()
    } catch (e) {
      console.log(e)
    } finally {
      this.loading = false

      setTimeout(() => {
        this.checkContentChange = true

        this.$root.$emit('validateEntry')
      }, 2000)
    }

    this.$root.$on('refreshComments', this.refreshComments)
    this.$root.$on('compareEntry', this.setCompareEntry)

    this.$root.$on('entryMetrics', ({ id, metrics }) => {
      // if (!this.checkContentChange) return

      this.metrics[id] = metrics

      const characterCount = Object.values(this.metrics).map(m => m.characterCount || 0).reduce((a, b) => a + b, 0) || 0
      const imageCount = Object.values(this.metrics).map(m => m.imageCount || 0).reduce((a, b) => a + b, 0) || 0
      const videoCount = Object.values(this.metrics).map(m => m.videoCount || 0).reduce((a, b) => a + b, 0) || 0
      const filesCount = Object.values(this.metrics).map(m => m.filesCount || 0).reduce((a, b) => a + b, 0) || 0
      const playlistsCount = Object.values(this.metrics).map(m => m.playlistsCount || 0).reduce((a, b) => a + b, 0) || 0
      const audioCount = Object.values(this.metrics).map(m => m.audioCount || 0).reduce((a, b) => a + b, 0) || 0
      const podcastCount = Object.values(this.metrics).map(m => m.podcastCount || 0).reduce((a, b) => a + b, 0) || 0
      const sentenceCount = Object.values(this.metrics).map(m => m.sentenceCount || 0).reduce((a, b) => a + b, 0) || 0
      const wordCount = Object.values(this.metrics).map(m => m.wordCount || 0).reduce((a, b) => a + b, 0) || 0
      const readTime = Object.values(this.metrics).map(m => m.readTime || 0).reduce((a, b) => a + b, 0) || 0

      if (!this.entry.system?.metrics) {
        this.$set(this.entry.system, 'metrics', entryDefaults().system.metrics)
      }

      this.entry.system.metrics.characterCount = characterCount
      this.entry.system.metrics.imageCount = imageCount
      this.entry.system.metrics.videoCount = videoCount
      this.entry.system.metrics.filesCount = filesCount
      this.entry.system.metrics.playlistsCount = playlistsCount
      this.entry.system.metrics.audioCount = audioCount
      this.entry.system.metrics.podcastCount = podcastCount
      this.entry.system.metrics.sentenceCount = sentenceCount
      this.entry.system.metrics.wordCount = wordCount
      this.entry.system.metrics.readingTime = readTime
    })
  },
  destroyed() {
    this.$root.$off('validateEntry', this.runValidations)

    document.removeEventListener('keydown', this.handleKeys)
    document.removeEventListener('focus', this.handleInputFocus)
    this.$store.commit('focus/SET_ONENTRY', false)
    this.$store.commit('focus/SET_FOCUS', false)

    this.removeLockEventListeners()

    this.$root.$off('refreshComments', this.refreshComments)
    this.$root.$off('compareEntry', this.setCompareEntry)

    this.unlockEntry()
    window.removeEventListener('unload', this.deleteLockOnUnload)
  },
  provide() {
    return {
      entry: () => this.getEntry,
      getFields: this.filterFields,
      setFieldValue: this.setFieldValue,
      validationData: () => this.validation,
      model: () => this.model,
      allComments: () => this.allComments,
      showError: () => this.showError,
      setSelectedEditor: this.setSelectedEditor,
      handleInputFocus: this.handleInputFocus,
      selectedEditor: this.selectedEditor,
      site: () => this.site,
      entryId: () => this.entityId,
      fieldPermissions: () => ({
        viewableFields: this.viewableFields,
        editableFields: this.editableFields,
      }),
      filterValues: this.filterValues || this.$route.params.filters || null,
      isPreview: () => !!this.version.version,
      checkConditions: this.checkConditions,
      publishCallback: callback => {
        setTimeout(() => {
          if (this.$refs.saveButtonEntries) {
            this.$refs.saveButtonEntries.setCallback(callback)
          }
        }, 1000)
      },
      entryLabels: () => this.entryLabels,
      getPath: this.getPath,
      createEntry: this.createNewEntry,
    }
  },
  methods: {
    ...mapMutations('autoSave', [
      'SET_SHOW',
      'SET_STATUS',
      'SET_UPDATE_TIME',
      'SET_RETRY_FUNCTION',
    ]),
    async createNewEntry() {
      if (!this.entry?.id || this.entry?.id === 'create') {
        await this.saveEntry()
      }
      return this.entry?.id
    },
    /** For plugins: Set field value */
    async setFieldValue(alias, value) {
      if (alias === 'system.labels') {
        try {
          const isValid = await yup.array().of(yup.object().shape({
            id: yup.string().required(),
          }).noUnknown()).required().validate(value)

          if (isValid) {
            this.entry.system.labels = value
          }
        } catch (e) {
          console.log(e)
        }

        return
      }

      const field = this.getFieldByAlias(alias)
      if (!field) return

      try {
        const isValid = await validateFieldValueSchema(field, value)

        if (!isValid) return

        this.$set(this.entry.content, alias, value)
      } catch (e) {
        console.log(e)
      }
    },

    /** For plugins: Get field by alias */
    getFieldByAlias(alias) {
      return this.model.fields.find(f => f.alias === alias)
    },

    /** Run validations * */
    runValidations() {
      this.validation = {
        ...this.validation,
        ...Validations.validateForm(this.getEntry, this.model),
      }
    },

    checkConditions(config) {
      return checkConditions(config, this.model, this.getEntry)
    },
    hasMetrics() {
      return this.getEntry?.system?.metrics
    },
    getMetric(metric) {
      if (!this.hasMetrics()) return 0

      return this.getEntry.system.metrics[metric] || 0
    },
    async checkLock() {
      try {
        const { data: lockData } = await checkEntryLock({ model: this.model.alias, id: this.entry.id, siteId: this.entry?.system?.siteId })
        const currentUser = getUserData()

        if (lockData && lockData.lockedBy && lockData.lockedBy !== currentUser.id) {
          this.lockError = this.$t('entries.locked-by-unknown-user')
          this.hasLockError = true

          const { data } = await this.$apollo.query({
            query: gql`
              query ($id: String!){
                user(id: $id) {
                  id
                  firstName
                  lastName
                }
              }
            `,
            variables: {
              id: lockData.lockedBy,
            },
          })

          const user = data?.user
          if (user) {
            const fullName = `${user.firstName || ''} ${user.lastName || ''}`.trim()
            this.lockError = this.$t('entries.locked-by', { user: fullName })
          }
        } else {
          this.hasLockError = false
          this.lockError = ''
        }
      } catch (e) {
        console.log(e)
        this.hasLockError = false
      }
    },
    async getUrl() {
      if (!this.entry?.id) return
      try {
        const { data } = await this.$apollo.query({
          query: gql`
            query ($entryId: String) {
              urls: urlCollection(where: {
                entityId: {eq: $entryId}
              }) {
                items {
                  url
                }
              }
            }
          `,
          fetchPolicy: 'network-only',
          variables: {
            entryId: this.entry.id,
          },
        })
        this.viewUrl = data?.urls?.items?.[0]?.url
      } catch (e) {
        console.log(e)
      }
    },
    prependDomain(domain, path) {
      if (domain && !domain.startsWith('http')) {
        domain = `https://${domain}`
      }

      try {
        const buildURL = new URL(`${domain || ''}${path || ''}`)
        return buildURL.href
      } catch {
        //
      }
      return ''
    },
    async getCurrentPreviewUrl() {
      try {
        this.currentPreviewUrl = await generateURL(this.previewConfig.pattern, this.getEntry, this.model)
      } catch (e) {
        console.log(e)
      }
    },
    async setTakeOver() {
      try {
        this.canTakeOverLock = (await canTakeOverLockEntry({ entity: { ...this.entry, entryLabels: this.entryLabels }, showToast: false })).can
      } catch (e) {
        console.log(e)
      }
    },
    addLockEventListeners() {
      document.addEventListener('mousedown', this.setLock)
      document.addEventListener('keydown', this.setLock)
    },
    removeLockEventListeners() {
      document.removeEventListener('mousedown', this.setLock)
      document.removeEventListener('keydown', this.setLock)
    },
    async setLock() {
      if (this.isLockEnabled && (!this.lockedAt || moment().diff(this.lockedAt, 'seconds') > LOCK_TIME_OUT)) {
        await this.lockEntry()
      }
    },
    async takeOverLock() {
      const confirm = await this.showConfirmPopup({
        title: this.$t('entries.lock-take-over.confirm-popup-title'),
        description: this.$t('entries.lock-take-over.confirm-popup-description'),
        helperText: this.$t('entries.lock-take-over.confirm-popup-help-text'),
        okTitle: this.$t('entries.lock-take-over.confirm-popup-ok'),
        cancelTitle: this.$t('entries.lock-take-over.confirm-popup-cancel'),
        okVariant: 'danger',
      })
      if (!confirm) return
      await this.lockEntry(true)
      this.refreshPage()
    },
    async lockEntry(takeOverLock = false) {
      if (this.isLockEnabled && this.model?.alias && this.entry?.id) {
        try {
          this.removeLockEventListeners()
          const { data } = await lockEntry({
            model: this.model.alias,
            id: this.entry.id,
            takeOverLock: takeOverLock && this.canTakeOverLock,
            siteId: this.entry.system.siteId,
          })
          this.lockedAt = data.updatedAt || data.createdAt
          this.lockError = ''
          this.hasLockError = false
          this.addLockEventListeners()
        } catch (error) {
          this.hasLockError = true
          if (error?.response?.data?.code === ERROR_CODES.CONTENT.EntryIsLocked) {
            this.checkLock()
          } else {
            const errorCode = error?.response?.data?.code
            if (errorCode) {
              this.lockError = this.$t(`sdk.error-codes.${errorCode}`)
            } else {
              this.lockError = this.$t('entries.lock-failed')
            }
          }
          console.log(error)
        }
      }
    },
    async _unlockEntry({ siteId }) {
      if (!this.isLockEnabled) return
      try {
        unlockEntry({
          id: this.entry.id,
          model: this.model.alias,
          siteId,
        })
      } catch (e) {
        console.log(e)
      }
    },
    deleteLockOnUnload() {
      deleteEntryLockFetch({
        id: this.entry.id,
        model: this.model.alias,
      })
    },
    mergeData(content) {
      this.entry.content = content
      this.cancelCompare()
    },
    cancelCompare() {
      this.compareEntry = null
      this.$router.replace({ name: this.$route.name, query: {}, params: { id: this.entry.id } }).catch(() => {})
    },
    copyPreviewURL() {
      const { copy } = useClipboard()
      copy(this.getPreviewURL)

      this.toastNotification({
        title: this.$t('entries.preview-url-notification.title'),
        icon: 'Copy',
        text: this.$t('entries.preview-url-notification.text'),
        variant: 'success',
      })
    },
    copyViewUrl() {
      const { copy } = useClipboard()
      copy(this.getViewUrl)

      this.toastNotification({
        title: this.$t('entries.view-url-notification.title'),
        icon: 'Copy',
        text: this.$t('entries.view-url-notification.text'),
        variant: 'success',
      })
    },
    copyId() {
      const { copy } = useClipboard()
      copy(this.getEntry.id)

      this.toastNotification({
        title: this.$t('entries.id-notification.title'),
        icon: 'Copy',
        text: this.$t('entries.id-notification.text'),
        variant: 'success',
      })
    },
    copyExternalId() {
      const { copy } = useClipboard()
      copy(this.getEntry.system.externalId)

      this.toastNotification({
        title: this.$t('entries.external-id-notification.title'),
        icon: 'Copy',
        text: this.$t('entries.external-id-notification.text'),
        variant: 'success',
      })
    },
    getGeneralSidebar(key, isEnabled = true) {
      // TODO: Check if isEnabled can be replaced with false value for all cases
      if (!this.model?.id) {
        return {
          isEnabled: false,
          order: 0,
        }
      }

      return this.model?.configurations?.sidebar?.general?.items?.[key] || {
        isEnabled,
        order: 0,
      }
    },
    getHistorySidebar() {
      return this.model?.configurations?.sidebar?.history || {
        isEnabled: true,
      }
    },
    getReferencedBySidebar() {
      return this.model?.configurations?.sidebar?.referencedBy || {
        isEnabled: true,
      }
    },
    getClonedReferenceSidebar() {
      return this.model?.configurations?.sidebar?.clonedReferences || {
        isEnabled: true,
      }
    },
    async setCompareEntry(compareEntry) {
      try {
        {
          const { data } = await readEntryVersion({
            entryId: this.entry.id,
            versionId: this.entry.system.versionId,
            modelAlias: this.entry.system.modelAlias,
            siteId: this.entry.system.siteId,
          })
          await this.$store.dispatch('mergingTool/setFirstEntry', data.entity)
        }
        {
          const { data } = await readEntryVersion({
            entryId: compareEntry.id,
            versionId: compareEntry.system.versionId,
            modelAlias: compareEntry.system.modelAlias,
            siteId: compareEntry.system.siteId,
          })
          await this.$store.dispatch('mergingTool/getSecondEntry', data.entity)
        }
        await this.$store.dispatch('mergingTool/getMergingModel', this.model)
        this.compareEntry = compareEntry
      } catch (e) {
        console.log(e)
      }
    },
    handleKeys(eventObj) {
      if (eventObj.keyCode === 69 && eventObj.ctrlKey && eventObj.shiftKey) {
        this.$store.commit('focus/SET_FOCUS', !this.focusMode)
        eventObj.preventDefault()
      } else if (eventObj.keyCode === 83 && eventObj.ctrlKey) {
        this.$refs.saveButtonEntries.save()
        eventObj.preventDefault()
      }
    },
    getFields(blocks) {
      const fields = getBlocks(blocks, block => block.isField)
      return fields.map(block => ({
        name: block?.attrs?.name,
        alias: block?.attrs?.alias,
        valueType: block?.attrs?.valueType,
        value: block?.attrs?.defaultValue,
      }))
    },
    filterFields(condition) {
      return this.fields.filter(condition).map(block => ({
        alias: block?.attrs?.alias,
        name: block?.attrs?.name,
        type: block?.attrs?.type,
        valueType: block?.attrs?.valueType,
      }))
    },
    async fetchModel() {
      try {
        const { data } = await readModel(this.modelId)
        this.model = data

        if (!this.isPopup) {
          this.$store.dispatch('general/setBreadcrumbItems', [
            {
              text: this.$t('general.navbar.not-organization.content-options.entries'),
              to: { name: 'entries' },
            },
            {
              text: `{mode} ${this.model.name}`,
              to: { name: 'entries', params: { model: this.model.alias } },
              active: true,
            },
          ])
        }
      } catch (e) {
        console.log(e)
      }
    },
    async fetchEntry() {
      if (this.entityId !== 'create') {
        try {
          const { data } = await readEntry({
            model: this.model.alias,
            id: this.entityId,
            siteId: this.site?.id,
          })
          this.entry = data
          this.entryLabels = JSON.stringify(data?.system?.labels || [])
          this.entryPrevious = cloneDeep(data)
          this.hasUnsavedChanges = false
          this.version.currentVersionId = this.entry.system.versionId

          if (this.entry.system.status === ENTRY_STATUSES.SCHEDULED && this.showScheduledNotification === null) {
            this.showScheduledNotification = true
          }
        } catch (e) {
          console.log(e)
        }
      } else {
        this.entry.system.modelId = this.model.id
        this.entry.system.modelAlias = this.model.alias
        this.entry.system.siteId = this.site?.id
      }
      if (this.exists) {
        this.SET_SHOW(true)
        this.SET_UPDATE_TIME(this.entry.system.updatedAt || this.entry.system.createdAt)
        this.$store.commit('focus/SET_ONENTRY', true)
      }

      this.prepareFields()
    },
    async handleVersionPreview(version) {
      const previewingVersionId = version.id

      if (previewingVersionId === this.entry.system.versionId) {
        this.version = { currentVersionId: previewingVersionId, version: null, previewingVersion: null }

        this.evId = null
        this.version.version = null
        return
      }

      this.version = {
        ...this.version, previewingVersion: version, version: { ...version.entity, versionId: this.entry.system.versionId },
      }

      this.evId = previewingVersionId
    },
    async deleteEntry() {
      await this.fetchEntry()

      const result = await this.showConfirmDeletePopup({
        items: [this.getEntry.system.title],
        type: ENTITY_TYPES.ENTRY,
      })
      if (result) {
        this.deletedEntry = result
        try {
          await deleteEntry(this.entry)
          if (this.isPopup) {
            this.$emit('deleted', this.entry)
          } else {
            this.$router.push({ name: 'entries' })
          }
        } catch (e) {
          console.log(e)
        }
      }
    },
    /**
     * Called when either title/slug/content is changed
     */
    contentChanged() {
      this.$root.$emit('calculateMetrics')
      this.$root.$emit('validateEntry')

      if (this.saving) {
        this.changesWhileSaving = true
        return
      }

      if (!this.checkContentChange || this.evId) return
      this.hasUnsavedChanges = true

      if (this.saveTimeoutId) clearTimeout(this.saveTimeoutId)
      this.saveTimeoutId = setTimeout(() => {
        const saveProps = {}
        if (this.entry.system.status === ENTRY_STATUSES.PUBLISHED) {
          saveProps.status = ENTRY_STATUSES.EDITED
        }
        this.saveEntry(saveProps, { successTitle: null }, false, true)
      }, 300)
    },
    prepareFields() {
      const fields = this.getFields(this.model.blocks)

      fields.forEach(field => {
        if (this.entry.content[field.alias] == undefined) {
          if (Object.prototype.hasOwnProperty.call(field, 'value') && field?.value?.toString()?.length) {
            this.$set(this.entry.content, field.alias, field.value)
          } else {
            this.$set(this.entry.content, field.alias, field.valueType === VALUE_TYPES.SINGLE ? null : [])
          }
        }
        this.validation[field.alias] = { isValid: true }
      })
    },
    getPath(alias) {
      // eslint-disable-next-line no-unused-vars,no-inner-declarations
      function findPathToBlock(array, targetAlias) {
        function findPath(block, path = []) {
          if (block.attrs.alias === targetAlias) {
            return path
          }

          if (block.content && block.content.length > 0) {
            for (let i = 0; i < block.content.length; i++) {
              // path.push({ block: block.content[i], i })
              const result = findPath(block.content[i], [...path, { block: block.content[i], i }])
              if (result) {
                return result
              }
            }
          }
          return null
        }

        for (let i = 0; i < array.length; i++) {
          const path = findPath(array[i], [{ block: array[i], i }])
          if (path) {
            return path
          }
        }
        return null
      }
      const array = findPathToBlock(this.model.blocks, alias).filter(t => t.block.type === 'codex_tabs' || t.block.type === 'codex_tab')

      const prepare = []

      for (let i = 0; i < array.length; i += 2) {
        prepare.push({
          blockId: array[i].block.id,
          tabIndex: array[i + 1].i,
        })
      }

      return prepare
    },
    async saveEntry(saveProps = {}, messages, redirect = false, isAutoSave = false, publishReferences = false, callback = undefined, withCallback = false) {
      if (this.saving && withCallback) {
        setTimeout(() => {
          this.saveEntry(saveProps, messages, redirect, isAutoSave, publishReferences, callback, withCallback)
        }, 500)
      }

      if (this.saving || this.preview) return
      this.$root.$emit('calculateMetrics')

      const entryToSave = {
        ...this.entry,
        system: {
          ...this.entry.system,
          ...saveProps,
          siteId: this.site?.id,
          versionId: this.versionId,
          modelId: this.model.id,
        },
      }

      let callbackValue = null

      if (entryToSave.system.status === ENTRY_STATUSES.PUBLISHED || entryToSave.system.status === ENTRY_STATUSES.SCHEDULED) {
        this.$root.$emit('validateEntry')
        this.showError = true
        if (!this.validateEntry()) {
          if (callback) {
            this.sendCallback(callback, false)
          }
          return
        }
      }
      this.version.version = null
      this.saving = true
      try {
        this.checkContentChange = false
        if (!this.entry.id) {
          const { data } = await this.createEntry(entryToSave, messages)
          this.entryLabels = JSON.stringify(data?.system?.labels || [])
          await Promise.allSettled([
            checkPermissionAsync(data.id, 'entry', 'editcontent', { model: this.modelId, siteId: this.site?.id, entryLabels: this.entryLabels }, true),
            checkPermissionAsync(data.id, 'entry', 'view', { model: this.modelId, siteId: this.site?.id, entryLabels: this.entryLabels }, true),
          ])
          this.$set(this.entry, 'id', data.id)

          this.lockEntry()
          this.fetchVersions()

          Object.assign(this.entry.system, pick(data.system, COPY_PROPERTIES))
          this.version.currentVersionId = data.system.versionId

          if (this.isPopup) {
            this.$emit('created', data)
          }
          if (!this.isPopup) {
            this.$router.replace({ name: 'entries-edit', params: { id: data.id } })
          }
        } else {
          const response = await this.updateEntry(entryToSave, isAutoSave, publishReferences, messages)
          if (!response?.data) {
            callbackValue = false
            return
          }
          callbackValue = true
          const { data } = response
          this.entryLabels = JSON.stringify(data?.system?.labels || [])
          await Promise.allSettled([
            checkPermissionAsync(data.id, 'entry', 'editcontent', { model: this.modelId, siteId: this.site?.id, entryLabels: this.entryLabels }, true),
            checkPermissionAsync(data.id, 'entry', 'view', { model: this.modelId, siteId: this.site?.id, entryLabels: this.entryLabels }, true),
          ])

          const payload = JSON.parse(response?.config?.data ?? null)

          this.urlFieldAliases.forEach(alias => {
            if (payload?.content?.[alias] !== data?.content?.[alias]) {
              this.entry.content[alias] = data.content[alias]
            }
          })

          const invalidReferencesCount = data?.invalidReferences?.length || 0
          if (invalidReferencesCount > 0) {
            this.toastNotification({
              icon: 'XIcon',
              variant: 'danger',
              title: 'Error',
              text: this.$tc('entries.invalidReferences', invalidReferencesCount, { count: invalidReferencesCount }),
            })
          }

          this.fetchVersions()

          Object.assign(this.entry.system, pick(data.system, COPY_PROPERTIES))

          this.version.currentVersionId = data.system.versionId
          if (this.isPopup) {
            this.$emit(this.eId === 'create' ? 'created' : 'updated', data)
          } else if (redirect) {
            this.$router.push({ name: 'entries' })
          }
        }
        // Doesn't let to confirm modal to show after saving @re-route
        this.hasUnsavedChanges = false
      } catch (error) {
        console.log(error)
      } finally {
        this.saving = false
        this.$nextTick(() => { this.checkContentChange = true })

        if (this.changesWhileSaving) {
          this.changesWhileSaving = false

          const _saveProps = {}
          if (this.entry.system.status === ENTRY_STATUSES.PUBLISHED) {
            _saveProps.status = ENTRY_STATUSES.EDITED
          }
          this.saveEntryThrottle(_saveProps, { successTitle: null }, false, true)
        }
        if (callback) {
          this.sendCallback(callback, callbackValue)
        }
      }

      if (this.isPopup && redirect) {
        this.$emit('close')
      }
    },
    async createEntry(entryToSave, messages) {
      try {
        this.SET_SHOW(true)
        this.SET_STATUS(AUTO_SAVE_STATUSES.SAVING)
        const response = await createEntry(entryToSave, messages)
        this.SET_STATUS(AUTO_SAVE_STATUSES.SAVED)
        this.SET_UPDATE_TIME(moment())
        return response
      } catch (e) {
        console.log(e)
        this.SET_STATUS(AUTO_SAVE_STATUSES.FAILED)
        return null
      }
    },
    sendCallback(callback, value) {
      return new Promise(() => {
        try {
          callback(value)
        } catch {
          //
        }
      })
    },
    async updateEntry(entryToSave, isAutoSave, publishReferences, messages) {
      if (isAutoSave) {
        // Can Edit or Edit Label
        if (!(await canEditEntryLabels({ entity: { ...this.entry, entryLabels: JSON.stringify(entryToSave?.system?.labels || []) }, showToast: false })).can && !(await canEditEntry({ entity: { ...this.entry, entryLabels: JSON.stringify(entryToSave?.system?.labels || []) }, showToast: true })).can) {
          this.SET_STATUS(AUTO_SAVE_STATUSES.FAILED)
          return null
        }
      } else {
        // Can Publish
        if ((entryToSave.system.status === ENTRY_STATUSES.PUBLISHED || entryToSave.system.status === ENTRY_STATUSES.SCHEDULED)
          && !(await canPublishEntry({ entity: { ...this.entry, entryLabels: JSON.stringify(entryToSave?.system?.labels || []) }, showToast: true })).can) {
          this.SET_STATUS(AUTO_SAVE_STATUSES.FAILED)
          return null
        }
        // Can Unpublish
        if (entryToSave.system.status === ENTRY_STATUSES.UNPUBLISHED && !(await canUnpublishEntry({ entity: { ...this.entry, entryLabels: JSON.stringify(entryToSave?.system?.labels || []) }, showToast: true })).can) {
          this.SET_STATUS(AUTO_SAVE_STATUSES.FAILED)
          return null
        }
      }
      try {
        this.SET_STATUS(AUTO_SAVE_STATUSES.SAVING)
        const response = await updateEntry(entryToSave, isAutoSave, publishReferences, messages)
        this.SET_STATUS(AUTO_SAVE_STATUSES.SAVED)
        this.SET_UPDATE_TIME(moment())

        if (publishReferences && (entryToSave.system.status === ENTRY_STATUSES.PUBLISHED || entryToSave.system.status === ENTRY_STATUSES.SCHEDULED)) {
          this.$root.$emit('entry-updated')
        }

        return response
      } catch (e) {
        console.log(e)
        if (e?.response?.data?.code === ERROR_CODES.CONTENT.EntryRevisionMismatch) {
          this.showAlertPopup = true
        }

        // TODO: Update ERROR_CODES LIST
        if (e?.response?.data?.code === 2201) {
          this.hasLockError = true
          this.checkLock()
        }
        this.SET_STATUS(AUTO_SAVE_STATUSES.FAILED)
        return null
      }
    },
    async refreshPage() {
      await this.fetchEntry()
      this.fetchVersions()
      this.refetchScheduledVersions()
      this.$root.$emit('revision-mismatch')
      this.showAlertPopup = false
    },
    /**
     * Publish the entry
     *
     * Change the status to published before saving
     */
    async publishOrSaveEntry(data = {
      status: this.entry.system.status, unpublishedAt: null, publishedAt: null, isStatusSchedule: null,
    }, redirect = false, publishReferences = false, callback = undefined, withCallback = false) {
      this.version.version = null
      if (this.entry.system.status === data.status && data.isStatusSchedule == null) {
        await this.saveEntry({ unpublishedAt: data.unpublishedAt, publishedAt: data.publishedAt }, {}, redirect, false, publishReferences, callback, withCallback)
      } else if (data.status === ENTRY_STATUSES.SCHEDULED) {
        await this.saveEntry({ status: data.status, unpublishedAt: data.unpublishedAt, publishedAt: data.publishedAt }, { successMessage: this.$t('entries.message-entry-scheduled') }, redirect, false, publishReferences)
      } else if (data.status === ENTRY_STATUSES.PUBLISHED) {
        await this.saveEntry({ status: data.status, unpublishedAt: data.unpublishedAt, publishedAt: data.publishedAt }, { successMessage: this.$t('entries.message-entry-published') }, redirect, false, publishReferences, callback, withCallback)
      } else if (data.status === ENTRY_STATUSES.UNPUBLISHED) {
        await this.saveEntry({ status: data.status }, { successMessage: this.$t('entries.message-entry-unpublished') }, false, false, publishReferences)
      } else if (data.status === ENTRY_STATUSES.DRAFT) {
        await this.saveEntry({ status: data.status, unpublishedAt: data.unpublishedAt }, {}, false, false, publishReferences)
      }
    },
    async createScheduleEntryVersion(payload = {
      publishedAt: null, unpublishedAt: null, publishReferences: false, redirect: false,
    }) {
      try {
        this.$root.$emit('validateEntry')
        this.showError = true
        if (!this.validateEntry()) return
        await createScheduleEntryVersion({
          entryId: this.entry.id,
          model: this.entry.system.modelAlias,
          versionId: this.entry.system.versionId,
          siteId: this.entry.system.siteId,
          publishScheduledDate: payload.publishedAt,
          unpublishScheduledDate: payload.unpublishedAt,
          publishReferences: payload.publishReferences,
        })
        this.versionId = GenerateUUID.VERSION()
        if (payload.redirect) {
          this.$router.push({ name: 'entries' })
        }
        this.refetchScheduledVersions()
      } catch (e) {
        console.log(e)
      }
    },
    publishEntry() {
      this.publishOrSaveEntry({ status: ENTRY_STATUSES.PUBLISHED, publishedAt: moment(), isStatusSchedule: false }, true)
    },

    async handlePreview() {
      this.$root.$emit('validateEntry')
      this.showError = true
      if (this.validateEntry() && this.getPreviewURL) window.open(this.getPreviewURL, '_blank')
    },

    /**
     * Here we can add any validation rule for the entry
     */
    validateEntry(showError = true) {
      const errorMessages = []
      const fields = Object.keys(this.validation)
      fields.forEach(field => {
        if (this.validation[field].isValid === false) {
          errorMessages.push(this.$t('entries.display-error-messages', {
            fieldName: this.getFieldByAlias(field)?.name?.toUpperCase(),
            errorMessage: this.validation[field].message,
          }))
        }
      })

      if (errorMessages.length && this.showError && showError) {
        this.toastNotification({
          icon: 'XIcon',
          variant: 'danger',
          title: 'Error',
          text: errorMessages.join('<br>'),
        })
      }
      return !errorMessages.length
    },
    checkWithProlexis() {
      // Content nodes to corrent
      const nodesToCorrect = []
      // Field types to check
      const fieldToCheck = ['codex_field_rich_content', 'codex_field_rich_text', 'codex_field_text']
      // Get rich content types from entry
      const blocks = getBlocks(this.model.blocks, block => fieldToCheck.includes(block.type), this.entry)
      // Add blocks to nodesToCorrect array
      blocks.forEach(block => {
        // eslint-disable-next-line default-case
        switch (block.type) {
          case 'codex_field_rich_content':
            this.prepareRichContentData(this.entry.content[block.attrs.alias], nodesToCorrect, block.attrs.name)
            break
          case 'codex_field_rich_text':
            this.prepareRichTextData(block, nodesToCorrect, block.attrs.name)
            break
          case 'codex_field_text':
            this.prepareFieldTextData(block, nodesToCorrect, block.attrs.name)
            break
        }
      })

      this.initProlexis(nodesToCorrect)
    },
    prepareRichContentData(content, nodesToCorrect, name) {
      if (!content) return

      // Block types to check in content
      const blocksToCheck = [CODEX_EDITOR_BLOCKS.PARAGRAPH, CODEX_EDITOR_BLOCKS.HEADING]

      // Check content if includes one of block types to correct
      content.forEach(block => {
        if (blocksToCheck.includes(block.type)) {
          const { blockId } = block.attrs
          const node = document.querySelector(`[data-id="${blockId}"`)
          if (node.innerText.trim().length > 0) {
            nodesToCorrect.push({
              src: node,
              type: 'DOM',
              label: `${name} ${this.$t(`articles.prolexis-${block.type}`)}`,
            })
          }
        }
        this.prepareRichContentData(block.content, nodesToCorrect, name)
      })
    },
    prepareRichTextData(block, nodesToCorrect, name) {
      const input = document.querySelector(`[data-prolexis="${block.attrs.alias}"`)
      const editor = input.childNodes[input.childNodes.length - 1]
      const editorContent = editor.childNodes[0]

      editorContent.childNodes.forEach(element => {
        if (element.innerText.trim().length > 0) {
          nodesToCorrect.push({
            src: element,
            type: 'DOM',
            label: `${name} ${this.$t('entries.sidebar-tabs.general.prolexis-paragraph')}`,
          })
        }
      })
    },
    prepareFieldTextData(block, nodesToCorrect, name) {
      const blocks = []
      if (block.attrs.valueType === VALUE_TYPES.SINGLE) {
        blocks.push(document.querySelector(`[data-prolexis="${block.attrs.alias}"`))
      } else {
        for (let index = 0; index < this.entry.content[block.attrs.alias].length; index++) {
          blocks.push(document.querySelector(`[data-prolexis="${block.attrs.alias}-${index}"`))
        }
      }

      blocks.forEach(input => {
        if (input && input.value.trim().length > 0) {
          nodesToCorrect.push({
            src: input,
            type: 'txt',
            label: `${name} ${this.$t('entries.sidebar-tabs.general.prolexis-paragraph')} `,
          })
        }
      })
    },
    initProlexis(nodesToCorrect) {
      // Prolexis init
      // eslint-disable-next-line no-undef
      DiagPlWs.init({
        sUrlProxy: this.prolexisConfiguration.url,
        sCorePath: 'https://admin.codexcdn.net/integrations/prolexis-4',
        excludedElement: '.is-empty',
        headers: {
          'X-PLWs-Key': this.prolexisConfiguration.apiKey,
        },
      })
      // eslint-disable-next-line no-undef
      DiagPlWs.analyze(nodesToCorrect)
    },
    // Check when respoose from graphql is fixed -- remove debounce
    // eslint-disable-next-line func-names
    refreshComments: debounce(function () {
      this.$apollo.queries.comments.refresh()
    }, 500),
    setSelectedEditor(label) {
      this.selectedEditor.alias = label

      // Save the initial click position
      this.initialClick = { x: null, y: null }
      // Create mousedown listener to track initial click position
      document.addEventListener('mousedown', this.mousedownHandler)
    },
    mousedownHandler(event) {
      // Save the initial click position on mousedown
      this.initialClick = { x: event.clientX, y: event.clientY }

      // Create mouseup listener to handle click after dragging
      document.addEventListener('mouseup', this.mouseupHandler)
    },
    mouseupHandler(event) {
      // Remove the mouseup listener
      document.removeEventListener('mouseup', this.mouseupHandler)

      // Check if there was significant movement between mousedown and mouseup
      const movementThreshold = 10 // Adjust as needed
      const movementX = Math.abs(event.clientX - this.initialClick.x)
      const movementY = Math.abs(event.clientY - this.initialClick.y)

      if (movementX > movementThreshold || movementY > movementThreshold) {
        // If there was significant movement, consider it as dragging
        return
      }

      // Handle the click event if it's not considered dragging
      if (this.canSetSelectedEditor(event)) {
        const target = event.target
        const closestParent = target.closest('.widget-selected')

        // In click check if it's outside, to reset selectedEditor and remove listener
        if (target && !(target.classList.contains('widget-selected') || closestParent)) {
          // Reset selectedEditor alias
          this.selectedEditor.alias = ''

          // Remove listener, to prevent duplicated listeners
          document.removeEventListener('mousedown', this.mousedownHandler)
        }
      }
    },
    handleInputFocus(event) {
      if (!this.canSetSelectedEditor(event)) return

      const target = event.target
      const closestParent = target.closest('[data-input-alias]')
      const alias = target?.getAttribute('data-input-alias') || closestParent?.getAttribute('data-input-alias')

      if (alias) this.setSelectedEditor(alias)
    },
    canSetSelectedEditor(e) {
      if ((!someParentHasClass(e.target, 'popups__item') || someParentHasClass(e.target, 'entry-editor-popup'))
          && !someParentHasClass(e.target, 'flatpickr-calendar')
      ) {
        return true
      }

      return false
    },
    async handleShowSeoAnalyzer() {
      this.isCollapsed = true
      await this.showSeoAnalyzerPopup({
        entryId: this.entry.id, fields: this.fields, getPath: this.getPath,
      })
      this.isCollapsed = false
    },
  },
  async beforeRouteLeave(to, from, next) {
    if (this.hasUnsavedChanges && !this.deletedEntry) {
      const answer = await this.showConfirmPopup({})
      if (answer) {
        if (!to.name.startsWith('entries')) {
          this.$store.dispatch('general/setBreadcrumbItems', [])
        }
        this.SET_SHOW(false)
        next()

        // unlock when next
      } else {
        next(false)
      }
    } else {
      if (!to.name.startsWith('entries')) {
        this.$store.dispatch('general/setBreadcrumbItems', [])
      }
      this.SET_SHOW(false)
      next()
      // unlock when next
    }
  },
}
</script>
