<template>
  <b-form-group class="reference-field disable-in-quick-view">
    <template #label>
      <FieldName
        :name="name"
        :help-text="helpText"
        :help-text-display="helpTextDisplay"
        :required="required"
      />
    </template>
    <ReferenceComponent
      v-model="references"
      :loading="$apollo.loading"
      :value-type="valueType"
      :models="acceptedModels"
      :disable-entry-creation="disableCreateButton"
      :disable-entry-browse="disableBrowseButton"
      :include-site-logo="true"
      :disabled="readOnly || !editable"
      @add="browseEntries"
      @remove="remove"
    />
    <FieldError :error="error" />
  </b-form-group>
</template>

<script>
import { generateComputedPropsFromAttrs } from '@/components/codex-layout-editor/BuilderUtils'
import { VALUE_TYPES, RANGE_OPERATORS, FIELD_FILTER_OPERATORS } from '@/views/models/constants'
import ReferenceComponent from '@/components/fields/Reference/ReferenceComponent.vue'
import gql from 'graphql-tag'
import { ENTRY_STATUSES, ENTRY_STATUSES_STRING, transformEntriesGraphQL } from '@/codex-sdk/entries'
import FieldName from '@/components/fields/FieldName.vue'
import { mapGetters } from 'vuex'
import store from '@/store'
import ReferenceFilter from '@/components/filters-dropdown/filters/reference/ReferenceFilter'
import ReferenceListFilter from '@/components/filters-dropdown/filters/referenceList/ReferenceListFilter'
import BaseFieldMixin from '@/components/fields/BaseFieldMixin'
import FieldRenderMixin from '@/components/fields/RenderFieldMixin'
import FieldError from '@/components/fields/FieldError.vue'

export default {
  name: 'ReferenceField',
  inject: ['showEntriesPopup', 'filterValues', 'entryId'],
  components: {
    FieldError,
    ReferenceComponent,
    FieldName,
  },
  mixins: [BaseFieldMixin, FieldRenderMixin],
  props: {
    value: {
      type: [Array, Object],
      default: () => ([]),
    },
  },
  apollo: {
    entryCollection: {
      query: gql`
        query ($ids: [String!], $limit: Int) {
          entryCollection (where: {
              id: {
                in: $ids
              }
            }, limit: $limit) {
              items {
                id
                system {
                  title
                  slug
                  status
                  publishedAt
                  featuredMedia {
                    id
                    url(transformation: { width: 70, height: 70, format: THUMBNAIL })
                  }
                  modelId
                  modelAlias
                  siteId
                  createdBy {
                    id
                    email
                    firstName
                    lastName
                    imageUrl
                  }
                  scheduledVersions (where: {
                    status: { in: [SCHEDULED] }
                  }) {
                    items {
                      id
                      versionId
                      publishScheduledDate
                      unpublishScheduledDate
                      versionId
                      createdAt
                    }
                  }
                }
              }
            }
          }
      `,
      fetchPolicy: 'no-cache',
      update(results) {
        if (results?.entryCollection?.items) {
          transformEntriesGraphQL(results.entryCollection.items)
        }
      },
      variables() {
        // eslint-disable-next-line no-nested-ternary
        const ids = this.valueType === VALUE_TYPES.LIST ? this.computedValue.map(e => e.entryId) : (this.computedValue?.entryId ? [this.computedValue.entryId] : [])
        return {
          ids,
          limit: ids.length,
        }
      },
      result(results) {
        this.references_ = []
        // eslint-disable-next-line no-nested-ternary
        const ids = this.valueType === VALUE_TYPES.LIST ? this.computedValue : (this.computedValue?.entryId ? [this.computedValue] : [])
        ids.forEach(e => {
          let item = results.data.entryCollection.items.find(c => c.id === e.entryId)
          if (!item) {
            item = {
              id: e.entryId,
              system: {
                status: ENTRY_STATUSES_STRING[ENTRY_STATUSES.DELETED],
                modelAlias: e.model,
                model: {
                  name: e.model,
                  iconId: 'Modeling',
                  alias: e.model,
                },
                site: {},
              },
            }
          }
          if (item?.system?.siteId) {
            item.system.site = this.getSite(item.system.siteId)
          }
          this.references_.push(item)
        })
        this.$apollo.queries.entryCollection.skip = true
      },
    },
  },
  data() {
    let acceptedModels = null
    if (this.widget.attrs.validation.modelTypes.isEnabled) {
      acceptedModels = this.widget.attrs.validation.modelTypes.acceptedModels
    }
    return {
      acceptedModels,
      VALUE_TYPES,
      RANGE_OPERATORS,
      loading: false,
      references_: [],
    }
  },
  computed: {
    ...generateComputedPropsFromAttrs([
      'alias',
      'name',
      'configured',
      'appearance',
      'validation',
      'disableCreateButton',
      'disableBrowseButton',
      'valueType',
      'helpText',
      'helpTextDisplay',
    ]),
    ...mapGetters('sites', [
      'getSite',
    ]),
    computedValue: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit('input', v)
      },
    },
    references: {
      get() {
        return this.references_
      },
      set(v) {
        this.references_ = v
        this.updateValue()
      },
    },
  },
  beforeMount() {
    if (!this.widget.attrs.hidden) {
      this.$set(this.widget.attrs, 'hidden', {
        value: false,
        conditionsEnabled: false,
        conditions: [
          {
            isSystem: false,
            field: '',
            operator: FIELD_FILTER_OPERATORS.EXISTS,
            value: '',
          },
        ],
      })
    }
  },
  mounted() {
    if (this.entryId() === 'create' && !this.readOnly && this.editable) {
      // Prefill from filter
      if (this.filterValues && this.filterValues?.[this.alias]) {
        if (this.valueType === VALUE_TYPES.SINGLE) {
          if (ReferenceFilter.shouldApplyPredefinedValue(this.filterValues[this.alias])) {
            this.references = [this.entryFromFiltersToValue(this.filterValues[this.alias].value[0])]
          }
        } else if (ReferenceListFilter.shouldApplyPredefinedValue(this.filterValues[this.alias])) {
          this.references = this.filterValues[this.alias].value.map(asset => this.entryFromFiltersToValue(asset))
        }
      }
      // \Prefill from filter
    }
    this.$root.$on('entry-updated', this.refetchEntries)
  },
  destroyed() {
    this.$root.$off('entry-updated', this.refetchEntries)
  },
  methods: {
    entryFromFiltersToValue(entryId) {
      const assetFromFiltersCache = store.state.filters.filtersCache[entryId] || {}

      return {
        ...assetFromFiltersCache,
      }
    },

    refetchEntries() {
      setTimeout(() => {
        this.$apollo.queries.entryCollection.skip = false
        this.$apollo.queries.entryCollection.refetch()
      }, 1000)
    },
    updateValue() {
      if (this.valueType === VALUE_TYPES.SINGLE) {
        this.computedValue = this.references?.length ? {
          entryId: this.references[0].id,
          model: this.references[0].system.modelAlias,
        } : null
      } else if (this.references) {
        this.computedValue = this.references.map(e => ({
          entryId: e.id,
          model: e.system.modelAlias,
        }))
      }
    },
    validate(v) {
      if (this.valueType == VALUE_TYPES.SINGLE) {
        return this.validateSingle(v)
      }
      return this.validateList(v)
    },
    validateSingle(v) {
      if (this.required && !v) {
        return { isValid: false, message: this.validation.required.errorMessage }
      }
      return { isValid: true }
    },
    validateList(v) {
      const listLength = v?.length
      if (this.required && !listLength) {
        return { isValid: false, message: this.validation.required.errorMessage }
      }

      if (this.validation.numberOfReferences.isEnabled && listLength > 0) {
        if (this.validation.numberOfReferences.rangeOperator === RANGE_OPERATORS.BETWEEN && (
          listLength < this.validation.numberOfReferences.min || listLength > this.validation.numberOfReferences.max)) {
          return { isValid: false, message: this.validation.numberOfReferences.errorMessage }
        }
        if (this.validation.numberOfReferences.rangeOperator === RANGE_OPERATORS.EXACTLY
          && listLength != this.validation.numberOfReferences.exactly) {
          return { isValid: false, message: this.validation.numberOfReferences.errorMessage }
        }
        if (this.validation.numberOfReferences.rangeOperator === RANGE_OPERATORS.GTE
          && listLength < this.validation.numberOfReferences.min) {
          return { isValid: false, message: this.validation.numberOfReferences.errorMessage }
        }
        if (this.validation.numberOfReferences.rangeOperator === RANGE_OPERATORS.LTE
          && listLength > this.validation.numberOfReferences.max) {
          return { isValid: false, message: this.validation.numberOfReferences.errorMessage }
        }
      }
      return { isValid: true }
    },
    async browseEntries() {
      if (this.readOnly || !this.editable) return
      if (this.valueType === VALUE_TYPES.SINGLE) {
        const result = await this.showEntriesPopup({
          models: this.acceptedModels,
          excludeIds: this.references?.map(e => e.id),
          limit: 1,
          disableEntryCreation: this.disableCreateButton,
          includeCustomSiteFilter: true,
          addOnPublish: true,
        })
        if (result?.length) {
          this.references = [result[0]]
        }
      } else {
        const result = await this.showEntriesPopup({
          models: this.acceptedModels,
          excludeIds: this.validation.allowDuplicates ? null : this.references?.map(e => e.id),
          disableEntryCreation: this.disableCreateButton,
          includeCustomSiteFilter: true,
          addOnPublish: true,
        })
        if (result?.length) {
          const references = this.references
          if (this.validation.allowDuplicates) {
            references.push(...result)
          } else {
            result.forEach(e => {
              if (!references.find(el => el.id == e.id)) {
                references.push(e)
              }
            })
          }
          this.references = references
        }
      }
    },
    remove(index) {
      if (this.readOnly || !this.editable) return
      this.references.splice(index, 1)
      this.updateValue()
    },
  },
}
</script>
