<template>
  <b-form-group
    class="codex-field-rich-content"
    :class="`codex-field-rich-content-${widget.attrs.alias}`"
  >
    <template #label>
      <div class="codex-field__field-name-container">
        <FieldName
          :name="name"
          :help-text="helpText"
          :help-text-display="helpTextDisplay"
        >
          <template #top-left>
            <AiIcon
              v-if="isEnabledAIFeatures && !readOnly"
              class="codex-field__ai-icon hide-in-quick-view"
              size="18"
              @click.native="showAiPopup = true"
            />
          </template>
          <template #top-right>
            <span
              v-if="showCharacterCount"
              class="codex-field__count"
            >
              {{ $t('fields.codex-field-rich-content.characters', { count: characterCount }) }}
            </span>
          </template>
        </FieldName>
      </div>
    </template>
    <div style="position:relative;">
      <AiContentPopup
        v-if="isEnabledAIFeatures && showAiPopup"
        @close="showAiPopup = false"
      />
    </div>

    <WarningPopup
      v-if="showWarning"
      class="codex-field-rich-content__warning-popup"
      :warning="$t('fields.codex-field-rich-content.warning')"
      @close="warningStatus = false"
    />
    <CodexEditor
      ref="editor"
      :include-blocks="validation.blocks.values"
      :include-models="validation.models.values"
      :toolbar-options="validation.toolbarOptions"
      :hacky-content="content"
      :focused="isEditorFocused"
      :read-only="!isEditable"
      class="disable-in-quick-view"
      @change="editorContentChanged"
    />
  </b-form-group>
</template>

<script>
import { generateComputedPropsFromAttrs } from '@/components/codex-layout-editor/BuilderUtils'
import CodexEditor from '@/components/codex-editor/CodexEditor.vue'
import { groupBy, isObject } from 'lodash'
import FieldName from '@/components/fields/FieldName.vue'
import WarningPopup from '@/components/WarningPopup.vue'
import CodexContentEditor from '@/components/codex-editor/CodexContentEditor'
import { getBlocks, assetsCountProperties, FIELD_FILTER_OPERATORS } from '@/views/models/constants'
import { ASSET_TYPE_MAPPING } from '@/codex-sdk/assets'
import BaseFieldMixin from '@/components/fields/BaseFieldMixin'
import FieldRenderMixin from '@/components/fields/RenderFieldMixin'
import { CODEX_EDITOR_BLOCKS } from '@/components/codex-editor/CodeEditorConstants'
import AiIcon from '@/components/ai/AiIcon.vue'
import AiContentPopup from '@/components/ai/AiContentPopup.vue'
import { defaultToolbar } from './defaultValues'

export default {
  components: {
    AiContentPopup,
    AiIcon,
    CodexEditor,
    WarningPopup,
    FieldName,
  },
  inject: ['allComments', 'selectedEditor', 'entry', 'toastNotification'],
  mixins: [BaseFieldMixin, FieldRenderMixin],
  props: {
    value: {
      type: Array,
      default: () => [],
    },
  },
  provide() {
    return {
      getEditor: () => this.$refs?.editor,
      comments: this.comments,
      filteredComments: () => this.filteredComments,
      isReadOnly: () => this.readOnly,
      fieldAlias: this.widget.attrs.alias,
      isEnabledAIFeatures: this.isEnabledAIFeatures,
    }
  },
  data() {
    return {
      showAiPopup: false,
      characterCount: 0,
      comments: { list: [] },
      isFullWidth: true,
      isModelEditor: this.$route.name === 'models-edit',
    }
  },
  computed: {
    ...generateComputedPropsFromAttrs([
      'name',
      'alias',
      'description',
      'helpText',
      'helpTextDisplay',
      'formattingOption',
      'hyperlinks',
      'embedded',
      'validation',
      'calculateMetrics',
      'warningStatus',
      'configured',
      'showCharacterCount',
      'configuration',
    ]),
    content: {
      get() {
        return this.value || []
      },
      set(v) {
        this.$emit('input', v)
      },
    },
    filteredComments() {
      return groupBy(this.allComments()?.filter(c => c.attrs.fieldAlias === this.widget.attrs.alias), c => c.attrs?.blockId)
    },
    showWarning() {
      return this.warningStatus && !this.isFullWidth && this.configured && this.isModelEditor
    },
    isEditorFocused() {
      return this.selectedEditor?.alias === this.widget.attrs.alias
    },
    isEditable() {
      return !this.readOnly && this.editable
    },
    isEnabledAIFeatures() {
      return this.configuration?.aiFeatures
    },
  },
  watch: {
    isEditable(v) {
      if (this.$refs?.editor?.editor) {
        this.$refs.editor.editor.setOptions({ editable: v })
      }
    },
  },
  beforeMount() {
    if (!this.widget.attrs.validation) {
      this.widget.attrs.validation = {}
    }

    if (!this.widget.attrs.validation.blocks || !isObject(this.widget.attrs.validation.blocks) || Array.isArray(this.widget.attrs.validation.blocks)) {
      this.$set(this.widget.attrs.validation, 'blocks', {
        isEnabled: true,
        values: this.widget.attrs.includeBlocks || [CODEX_EDITOR_BLOCKS.PARAGRAPH],
        errorMessage: '',
      })
    }

    if (!this.widget.attrs.validation.models || !isObject(this.widget.attrs.validation.models) || Array.isArray(this.widget.attrs.validation.models)) {
      this.$set(this.widget.attrs.validation, 'models', {
        isEnabled: true,
        values: this.widget.attrs.includeModels || [],
        errorMessage: '',
      })
    }

    const models = this.$store.state.general.models.map(model => model.alias)
    this.widget.attrs.validation.models.values = this.widget.attrs.validation.models.values.filter(model => models.includes(model))

    const allBlocks = CodexContentEditor.getRegisteredWidgets().map(block => block.type)
    this.widget.attrs.validation.blocks.values = this.widget.attrs.validation.blocks.values.filter(block => allBlocks.includes(block) || block !== CODEX_EDITOR_BLOCKS.HEADING)

    this.widget.attrs.validation.toolbarOptions = {
      ...defaultToolbar,
      ...this.widget.attrs.validation.toolbarOptions,
    }

    if (!this.widget.attrs.configuration) {
      this.$set(this.widget.attrs, 'configuration', {
        searchable: true,
        filterable: true,
        aiFeatures: false,
      })
    }

    if (!this.widget.attrs.hidden) {
      this.$set(this.widget.attrs, 'hidden', {
        value: false,
        conditionsEnabled: false,
        conditions: [
          {
            isSystem: false,
            field: '',
            operator: FIELD_FILTER_OPERATORS.EXISTS,
            value: '',
          },
        ],
      })
    }

    if (!this.widget.attrs.version) {
      this.v1()
    } else if (this.widget.attrs.version === 1) {
      this.v2()
    } else if (this.widget.attrs.version === 2) {
      this.v3()
    }
  },
  mounted() {
    if (this.$refs.editor) {
      this.$refs.editor.setContent(this.content)
    }
    if (this.isModelEditor) {
      this.$nextTick(() => { this.checkForWidth() })
    }
    this.$nextTick(() => {
      this.getMetrics()
    })
    this.$root.$on('revision-mismatch', this.setContent)
    this.$root.$on('ai-generated-content', this.setAiGeneratedContent)
  },
  beforeDestroy() {
    this.$root.$off('revision-mismatch', this.setContent)
    this.$root.$off('ai-generated-content', this.setAiGeneratedContent)
  },
  methods: {
    setAiGeneratedContent(alias, value, replace) {
      if (this.alias === alias && replace) {
        let position = 0
        let n = null

        this.$refs.editor.editor.state.doc.descendants((node, pos) => {
          n = node
          position = pos
        })
        this.$refs.editor.upsertNodes(false, value, 0, position + n.nodeSize)
        this.toastNotification({
          title: replace ? this.$t('site.add-site.replace') : this.$t('fields.general.ai-popup.results.retry-label'),
          text: this.$t('entries.ai-content-analyzer.seo.ai-suggestion-replaced'),
          variant: 'success',
          icon: 'Check',
        })
      }
    },
    v1() {
      this.widget.attrs.validation.blocks.values = this.widget.attrs.validation.blocks.values.concat([CODEX_EDITOR_BLOCKS.HEADING_1, CODEX_EDITOR_BLOCKS.HEADING_2, CODEX_EDITOR_BLOCKS.HEADING_3, CODEX_EDITOR_BLOCKS.HEADING_4, CODEX_EDITOR_BLOCKS.HEADING_5, CODEX_EDITOR_BLOCKS.HEADING_6])
      this.widget.attrs.version = 1
      this.v2()
    },
    v2() {
      this.widget.attrs.validation.blocks.values = this.widget.attrs.validation.blocks.values.concat([CODEX_EDITOR_BLOCKS.MEDIA_IMAGE, CODEX_EDITOR_BLOCKS.MEDIA_FILE, CODEX_EDITOR_BLOCKS.MEDIA_VIDEO, CODEX_EDITOR_BLOCKS.MEDIA_VIDEO_PLAYLIST])
      this.widget.attrs.version = 2
      this.v3()
    },
    v3() {
      this.widget.attrs.validation.blocks.values = this.widget.attrs.validation.blocks.values.concat([CODEX_EDITOR_BLOCKS.MEDIA_AUDIO, CODEX_EDITOR_BLOCKS.MEDIA_PODCAST])
      this.widget.attrs.version = 3
    },
    async setContent() {
      if (this.$refs.editor) {
        this.$refs.editor.setContent(this.content)
      }
    },
    checkForWidth() {
      const content = document.querySelector(`.layout-editor__block[data-id=${this.widget.id}]`).offsetWidth
      const root = document.querySelector('.layout-editor__root').offsetWidth
      this.isFullWidth = ((content * 100) / root) > 75
    },
    countSentences(str) {
      if (!str) {
        return 0
      }
      return str.split(/[.!?]+(?=\s|$)/).filter(s => s.trim() !== '').length
    },
    countWords(str) {
      if (!str) {
        return 0
      }
      return str.trim().split(/\s+/).length
    },
    countCharacters(str) {
      if (!str?.length) return 0
      return str.length
    },
    readTimeEstimate(words) {
      return Math.ceil(words / 265)
    },

    getMetrics(counts = {
      words: 0, sentences: 0, characters: 0, images: 0,
    }) {
      const contentToCount = this.$refs.editor.getContent()

      const metrics = {
        characterCount: 0,
        imageCount: 0,
        videoCount: 0,
        filesCount: 0,
        playlistsCount: 0,
        audioCount: 0,
        podcastCount: 0,
        sentenceCount: 0,
        wordCount: 0,
        readTime: 0,
      }

      if (!this.calculateMetrics && !this.showCharacterCount) return metrics

      const textBlocks = getBlocks(contentToCount, t => this.$refs.editor?.editor?.schema?.nodes?.[t.type]?.isTextblock)

      for (let i = 0; i < textBlocks.length; i += 1) {
        const content = textBlocks[i]?.content?.reduce((acc, curr) => acc + (curr.text || ''), '')

        counts.words += this.countWords(content)
        counts.characters += this.countCharacters(content)
        counts.sentences += this.countSentences(content)
      }

      this.characterCount = counts.characters

      if (!this.calculateMetrics) {
        return metrics
      }

      const mediaBlocks = getBlocks(contentToCount, t => t.type === CODEX_EDITOR_BLOCKS.MEDIA)

      for (let i = 0; i < mediaBlocks.length; i += 1) {
        if (mediaBlocks[i]?.attrs?.media?.length) {
          mediaBlocks[i].attrs.media.forEach(media => {
            metrics[assetsCountProperties[ASSET_TYPE_MAPPING.toString(media.type)]] += 1
          })
        }
      }

      metrics.characterCount = counts.characters
      metrics.sentenceCount = counts.sentences
      metrics.wordCount = counts.words
      metrics.readTime = this.readTimeEstimate(counts.words)

      return metrics
    },

    async editorContentChanged() {
      const content = this.$refs.editor.getContent()
      // ToDo - validate content is used to handle default attrs & marks
      this.validateContent(content)
      this.content = content
    },
    validateContent(content) {
      if (content) {
        content.forEach(e => {
          if (!e.marks) e.marks = []
          if (!e.attrs) e.attrs = {}
          this.validateContent(e.content)
        })
      }
    },
    validate() {
      return { isValid: true }
    },
  },
}
</script>

<style lang="scss">
.codex-field-rich-content {
  .codex-editor {
    width: 100%;
    border: 1px solid #DADFE7;
    border-radius: 4px;
    padding: 2px 8px;
    min-height: 250px;
  }

  .codex-field-rich-content__warning-popup {
    position: absolute;
    bottom: 100px;
    left: 15%;
  }
}
</style>
