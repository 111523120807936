<template>
  <div>
    <b-form-group
      :label="$t('fields.codex-field-rich-content.general.name-label')"
      label-for="at-location-field__name-input"
    >
      <b-form-input
        id="at-location-field__name-input"
        v-model.trim="name"
        :placeholder="$t('fields.codex-field-rich-content.general.name-placeholder')"
      />
    </b-form-group>
    <b-form-group
      :label="$t('fields.codex-field-rich-content.general.alias-label')"
      label-for="at-location-field__alias-input"
      aria-required="true"
    >
      <b-form-input
        id="at-location-field__alias-input"
        v-model.trim="tempAlias"
        :placeholder="$t('fields.codex-field-rich-content.general.alias-placeholder')"
        :state="tempAlias === null ? null : !aliasState"
        trim
      />
      <b-form-invalid-feedback v-if="!nameState.isValid">
        {{ $t('fields.general.validation.alias-invalid') }}
      </b-form-invalid-feedback>
      <b-form-invalid-feedback v-if="nameState.isReserved">
        {{ $t('fields.general.validation.alias-is-reserved') }}
      </b-form-invalid-feedback>
      <b-form-invalid-feedback v-if="!nameState.isUnique">
        {{ $t('fields.general.validation.alias-is-used') }}
      </b-form-invalid-feedback>
    </b-form-group>
    <b-form-group
      :label="$t('fields.codex-field-rich-content.general.description-label')"
      label-for="at-location-field__description-input"
    >
      <b-form-textarea
        id="at-rich-content-field__description-input"
        v-model="description"
        :placeholder="$t('fields.codex-field-rich-content.general.description-placeholder')"
        rows="2"
        no-resize
      />
    </b-form-group>
    <b-button
      id="at-location-field__create-button"
      variant="primary"
      class="w-100"
      :disabled="aliasState"
      @click="create"
    >
      {{ $t('fields.codex-field-rich-content.general.create') }}
    </b-button>
  </div>
</template>

<script>
import { generateComputedPropsFromAttrs } from '@/components/codex-layout-editor/BuilderUtils'
import CodexContentEditor from '@/components/codex-editor/CodexContentEditor'
import FieldValidationMixin from '@/components/fields/FieldValidationMixin'
import BaseFieldMixin from '@/components/fields/BaseFieldMixin'
import { CODEX_EDITOR_BLOCKS } from '@/components/codex-editor/CodeEditorConstants'

export default {
  inject: ['validateAlias'],
  mixins: [BaseFieldMixin, FieldValidationMixin],
  computed: {
    ...generateComputedPropsFromAttrs([
      'name',
      'alias',
      'description',
      'configured',
      'validation',
    ]),
    aliasState() {
      return this.nameState.isReserved || !this.nameState.isUnique || !this.nameState.isValid
    },
  },
  beforeDestroy() {
    if (this.configured) {
      this.validation.blocks.values = CodexContentEditor.getRegisteredWidgets().filter(i => !i._internal).map(block => block.type)
      this.validation.blocks.values = this.validation.blocks.values.concat([CODEX_EDITOR_BLOCKS.HEADING_1, CODEX_EDITOR_BLOCKS.HEADING_2, CODEX_EDITOR_BLOCKS.HEADING_3, CODEX_EDITOR_BLOCKS.HEADING_4, CODEX_EDITOR_BLOCKS.HEADING_5, CODEX_EDITOR_BLOCKS.HEADING_6, CODEX_EDITOR_BLOCKS.MEDIA_IMAGE, CODEX_EDITOR_BLOCKS.MEDIA_FILE, CODEX_EDITOR_BLOCKS.MEDIA_VIDEO, CODEX_EDITOR_BLOCKS.MEDIA_VIDEO_PLAYLIST, CODEX_EDITOR_BLOCKS.MEDIA_AUDIO, CODEX_EDITOR_BLOCKS.MEDIA_PODCAST])

      this.validation.models.values = this.$store.state.general.allModels.map(model => model.alias)
    }
  },
}
</script>
