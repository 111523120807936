<template>
  <div>
    <b-form-group>
      <b-form-checkbox v-model="computedValue.value">
        {{ label }}
        <GjIcon
          v-if="tooltip"
          v-b-tooltip.hover="tooltip"
          name="Info"
          size="20"
        />
      </b-form-checkbox>
    </b-form-group>

    <template v-if="computedValue.value">
      <b-form-group
        v-if="showErrorMessage"
        :label="errorMessageLabel"
      >
        <b-form-input
          v-model.trim="computedValue.errorMessage"
          :placeholder="errorMessagePlaceholder"
        />
      </b-form-group>

      <b-form-group class="ml-1">
        <b-form-checkbox v-model="computedValue.conditionsEnabled">
          {{ $t('fields.general.conditions-configuration.label') }}
        </b-form-checkbox>
      </b-form-group>

      <!--      form-group__validation-last-field-margin-->
      <div
        v-if="computedValue.conditionsEnabled"
        class="ml-1"
      >
        <b-form-group :label="$t('fields.general.conditions-configuration.condition.field')">
          <v-select
            v-model="condition.field"
            :options="fields"
            :clearable="false"
            :reduce="e => e.attrs.alias"
            :get-option-label="e => e.attrs.name"
            @option:selected="v => selected(v, condition)"
          >
            <template #option="{ attrs, isSystem }">
              <span :style="{ fontStyle: isSystem ? 'italic': '' }">
                {{ attrs.name }}
              </span>
            </template>
          </v-select>
        </b-form-group>
        <b-form-group :label="$t('fields.general.conditions-configuration.condition.operator')">
          <v-select
            v-model="operator"
            :options="filterOperators"
            :clearable="false"
            :reduce="e => e.value"
          />
        </b-form-group>
        <b-form-group
          v-if="showValueInput"
          :label="$t('fields.general.conditions-configuration.condition.value')"
        >
          <template v-if="condition.isSystem">
            <v-select
              v-if="$store.state.sites.sites && condition.field === CONDITIONAL_SYSTEM_FIELDS.SITE_ID"
              v-model="condition.value"
              :options="$store.state.sites.sites"
              :reduce="e => e.id"
              label="name"
              :clearable="false"
            >
              <template #selected-option="site">
                <div style="display: flex; align-items: center; gap: 10px;">
                  <b-img-aspect
                    :src="site.logo"
                    aspect="1:1"
                    style="width: 30px;"
                  />
                  <span>
                    {{ site.name }}
                  </span>
                </div>
              </template>
              <template #option="site">
                <div style="display: flex; align-items: center; gap: 10px;">
                  <b-img-aspect
                    :src="site.logo"
                    aspect="1:1"
                    style="width: 30px;"
                  />
                  <span>
                    {{ site.name }}
                  </span>
                </div>
              </template>
            </v-select>
          </template>
          <v-select
            v-else-if="predefinedValues.isEnabled"
            v-model="condition.value"
            :options="predefinedValues.values"
          />
          <b-form-input
            v-else
            v-model.trim="condition.value"
          />
        </b-form-group>
      </div>
    </template>
  </div>
</template>

<script>
import {
  FIELD_FILTER_OPERATORS,
  mapFieldFilterOperatorLabel,
  TYPES,
  VALUE_TYPES,
  TEXT_FIELD_APPEARANCES,
  CONDITIONAL_SYSTEM_FIELDS,
} from '@/views/models/constants'

export default {
  name: 'FieldConditions',
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    label: {
      type: String,
      default: '',
    },
    widget: {
      type: Object,
      default: () => ({}),
    },
    systemFieldsOnly: {
      type: Boolean,
      default: false,
    },
    tooltip: {
      type: String,
      default: null,
    },
    errorMessageLabel: {
      type: String,
      default: null,
    },
    errorMessagePlaceholder: {
      type: String,
      default: null,
    },
    showErrorMessage: {
      type: Boolean,
      default: true,
    },
  },
  inject: ['getFieldsObjects'],
  data() {
    return {
      CONDITIONAL_SYSTEM_FIELDS,
    }
  },
  computed: {
    computedValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
    condition: {
      get() {
        return this.computedValue.conditions[0]
      },
      set(v) {
        this.$emit('input', {
          ...this.computedValue,
          conditions: [v],
        })
      },
    },
    operator: {
      get() {
        return this.condition.operator
      },
      set(value) {
        this.condition.operator = value
      },
    },
    showValueInput() {
      return this.operator !== FIELD_FILTER_OPERATORS.EXISTS && this.operator !== FIELD_FILTER_OPERATORS.NOT_EXISTS
    },
    fields() {
      const contentFields = this.getFieldsObjects(field => field.attrs.type === TYPES.TEXT && field.attrs.alias !== this.widget.attrs.alias)
      // TODO: IMPORTANT - before adding other system fields, make sure that proper handling are implemented in Backend (Reference field)
      const systemFields = [{
        attrs: {
          name: this.$t('fields.general.conditions-configuration.system-fields.siteId'),
          alias: CONDITIONAL_SYSTEM_FIELDS.SITE_ID,
        },
        isSystem: true,
      }]
      return this.systemFieldsOnly ? systemFields : [...contentFields, ...systemFields]
    },
    field() {
      return this.getField(this.condition.field)
    },
    isSelectedFieldValueTypeList() {
      return this.field?.attrs.valueType === VALUE_TYPES.LIST
    },
    filterOperators() {
      const filters = []
      if (!this.condition.isSystem) {
        filters.push(...[
          {
            value: FIELD_FILTER_OPERATORS.EXISTS,
            label: mapFieldFilterOperatorLabel(FIELD_FILTER_OPERATORS.EXISTS),
          },
          {
            value: FIELD_FILTER_OPERATORS.NOT_EXISTS,
            label: mapFieldFilterOperatorLabel(FIELD_FILTER_OPERATORS.NOT_EXISTS),
          },
        ])
      }

      if (!this.predefinedValues.isEnabled && !this.condition.isSystem) {
        filters.push(
          ...[{
            value: FIELD_FILTER_OPERATORS.CONTAINS,
            label: mapFieldFilterOperatorLabel(FIELD_FILTER_OPERATORS.CONTAINS),
          },
          {
            value: FIELD_FILTER_OPERATORS.NOT_CONTAINS,
            label: mapFieldFilterOperatorLabel(FIELD_FILTER_OPERATORS.NOT_CONTAINS),
          }],
        )
      }

      if (!this.isSelectedFieldValueTypeList || this.predefinedValues.isEnabled) {
        filters.push(
          ...[
            {
              value: FIELD_FILTER_OPERATORS.EQUALS,
              label: mapFieldFilterOperatorLabel(FIELD_FILTER_OPERATORS.EQUALS),
            },
            {
              value: FIELD_FILTER_OPERATORS.NOT_EQUALS,
              label: mapFieldFilterOperatorLabel(FIELD_FILTER_OPERATORS.NOT_EQUALS),
            },
          ],
        )
      }
      return filters
    },
    predefinedValues() {
      if (!this.condition?.field) {
        return {
          isEnabled: false,
          values: [],
        }
      }

      let isSelectable = false

      if (this.field.attrs.type === TYPES.TEXT) {
        isSelectable = this.field.attrs.appearance === TEXT_FIELD_APPEARANCES.RADIO
            || this.field.attrs.appearance === TEXT_FIELD_APPEARANCES.DROPDOWN
            || this.field.attrs.appearance === TEXT_FIELD_APPEARANCES.CHECKBOX
      }

      return {
        isEnabled: isSelectable,
        values: isSelectable ? this.field.attrs?.validation?.selectFrom.predefinedValues : [],
      }
    },
  },
  watch: {
    isSelectedFieldValueTypeList(v) {
      if (v && (this.condition.operator === FIELD_FILTER_OPERATORS.EQUALS || this.condition.operator === FIELD_FILTER_OPERATORS.NOT_EQUALS)) {
        this.condition.operator = FIELD_FILTER_OPERATORS.EXISTS
      }
    },
    predefinedValues: {
      handler(n, o) {
        if (n?.isEnabled !== o?.isEnabled) {
          this.condition.operator = FIELD_FILTER_OPERATORS.EXISTS
        }
      },
      deep: true,
    },
    field: {
      handler() {
        if (this.predefinedValues.isEnabled && (this.condition.operator === FIELD_FILTER_OPERATORS.CONTAINS || this.condition.operator === FIELD_FILTER_OPERATORS.NOT_CONTAINS)) {
          this.condition.operator = FIELD_FILTER_OPERATORS.EQUALS
        }
      },
      deep: true,
      immediate: true,
    },
    condition: {
      handler(v) {
        if (v.field === CONDITIONAL_SYSTEM_FIELDS.SITE_ID && this.condition.operator !== FIELD_FILTER_OPERATORS.EQUALS && this.condition.operator !== FIELD_FILTER_OPERATORS.NOT_EQUALS) {
          this.condition.operator = FIELD_FILTER_OPERATORS.EQUALS
        }
      },
      deep: true,
    },
  },
  methods: {
    getField(alias) {
      return this.fields.find(f => f.attrs.alias === alias)
    },
    selected(option, condition) {
      if (option) {
        condition.isSystem = !!option.isSystem
      }
    },
  },
}
</script>
