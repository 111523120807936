<template>
  <div class="new-filters-dropdown__filter-body">
    <b-row>
      <b-col>
        <b-input
          v-model="values.key"
          size="sm"
          :placeholder="$t('filters.custom-parameters.key')"
        />
      </b-col>
      <b-col>
        <b-input
          v-model="values.value"
          size="sm"
          :placeholder="$t('filters.custom-parameters.value')"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  props: {
    filter: {
      type: Object,
      required: true,
    },
    filterObject: {
      type: [Object, Function],
      required: true,
    },
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      values: {
        key: '',
        value: '',
      },
    }
  },
  watch: {
    values: {
      handler(v) {
        this.value.key = v.key
        this.value.value = v.value
      },
      deep: true,
    },
    value: {
      handler(v) {
        this.values.key = v.key
        this.values.value = v.value
      },
      deep: true,
      immediate: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.form-control {
  width: 100% !important;
}
</style>
