<template>
  <div class="seo-analyzer sentiment-analyzer">

    <div
      class="seo-analyzed-content"
    >
      <div class="content-score d-flex flex-column justify-content-end">
        <div class="d-flex justify-content-between align-items-center">
          <h2 style="margin-bottom: 0; display: flex; align-items: center; gap: 5px;">
            {{ $t('entries.ai-content-analyzer.sentiment.sentiment-score') }}
            <GjIcon
              v-b-tooltip.hover="$t('entries.ai-content-analyzer.sentiment.sentiment-score-description')"
              name="Info"
              size="20"
            />
          </h2>
          <!-- <button
            class="suggestion-button"
            @click="getSentiment(f)"
          >
            <b-spinner
              class="field-spinner"
              variant="primary"
              small
            />
            {{ $t('fields.general.ai-popup.results.retry-label') }}
          </button> -->
        </div>
        <div class="score-circle d-flex flex-column justify-content-end align-items-center position-relative bg-white">
          <div class="h4 mb-0 d-flex flex-column justify-content-center align-items-center">
            {{ maxVal }}%
            <span class="description"> {{ nameOfMaxVal }}</span>
          </div>
          <div
            class="score-circle-overlay"
          >
            <svg
              width="161"
              height="161"
              viewBox="0 0 161 161"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 80.5C1.86647e-06 59.1501 8.48123 38.6746 23.5779 23.5779C38.6746 8.48123 59.1501 2.29051e-06 80.5 0C101.85 -2.29051e-06 122.325 8.48122 137.422 23.5779C152.519 38.6746 161 59.1501 161 80.5H144.9C144.9 63.42 138.115 47.0397 126.038 34.9623C113.96 22.885 97.5799 16.1 80.5 16.1C63.4201 16.1 47.0397 22.885 34.9623 34.9623C22.885 47.0397 16.1 63.4201 16.1 80.5H0Z"
                fill="none"
              />
              <path
                d="M0 80.5C1.86647e-06 59.1501 8.48123 38.6746 23.5779 23.5779C38.6746 8.48123 59.1501 2.29051e-06 80.5 0C101.85 -2.29051e-06 122.325 8.48122 137.422 23.5779C152.519 38.6746 161 59.1501 161 80.5H144.9C144.9 63.42 138.115 47.0397 126.038 34.9623C113.96 22.885 97.5799 16.1 80.5 16.1C63.4201 16.1 47.0397 22.885 34.9623 34.9623C22.885 47.0397 16.1 63.4201 16.1 80.5H0Z"
                fill="#FFA01C"
              />
              <path
                d="M0 80.5C1.86647e-06 101.85 8.48123 122.325 23.5779 137.422C38.6746 152.519 59.1501 161 80.5 161C101.85 161 122.325 152.519 137.422 137.422C152.519 122.325 161 101.85 161 80.5H144.9C144.9 97.58 138.115 113.96 126.038 126.038C113.96 138.115 97.5799 144.9 80.5 144.9C63.4201 144.9 47.0397 138.115 34.9623 126.038C22.885 113.96 16.1 97.5799 16.1 80.5H0Z"
                fill="none"
              />
              <path
                d="M0 80.5C1.86647e-06 101.85 8.48123 122.325 23.5779 137.422C38.6746 152.519 59.1501 161 80.5 161C101.85 161 122.325 152.519 137.422 137.422C152.519 122.325 161 101.85 161 80.5H144.9C144.9 97.58 138.115 113.96 126.038 126.038C113.96 138.115 97.5799 144.9 80.5 144.9C63.4201 144.9 47.0397 138.115 34.9623 126.038C22.885 113.96 16.1 97.5799 16.1 80.5H0Z"
                fill="nnone"
              />
            </svg>

          </div>
          <div
            class="score-circle-overlay"
            :style="negativeGraph"
          >
            <svg
              width="162"
              height="161"
              viewBox="0 0 162 161"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.237305 80.5C0.237307 59.1501 8.71853 38.6746 23.8152 23.5779C38.9119 8.48123 59.3874 2.29051e-06 80.7373 0C102.087 -2.29051e-06 122.563 8.48122 137.659 23.5779C152.756 38.6746 161.237 59.1501 161.237 80.5H145.137C145.137 63.42 138.352 47.0397 126.275 34.9623C114.198 22.885 97.8172 16.1 80.7373 16.1C63.6574 16.1 47.277 22.885 35.1996 34.9623C23.1223 47.0397 16.3373 63.4201 16.3373 80.5H0.237305Z"
                fill="nones"
              />
              <path
                d="M0.237305 80.5C0.237307 59.1501 8.71853 38.6746 23.8152 23.5779C38.9119 8.48123 59.3874 2.29051e-06 80.7373 0C102.087 -2.29051e-06 122.563 8.48122 137.659 23.5779C152.756 38.6746 161.237 59.1501 161.237 80.5H145.137C145.137 63.42 138.352 47.0397 126.275 34.9623C114.198 22.885 97.8172 16.1 80.7373 16.1C63.6574 16.1 47.277 22.885 35.1996 34.9623C23.1223 47.0397 16.3373 63.4201 16.3373 80.5H0.237305Z"
                fill="#E5484D"
              />
              <path
                d="M0.237305 80.5C0.237307 101.85 8.71853 122.325 23.8152 137.422C38.9119 152.519 59.3874 161 80.7373 161C102.087 161 122.563 152.519 137.659 137.422C152.756 122.325 161.237 101.85 161.237 80.5H145.137C145.137 97.58 138.352 113.96 126.275 126.038C114.198 138.115 97.8172 144.9 80.7373 144.9C63.6574 144.9 47.277 138.115 35.1996 126.038C23.1223 113.96 16.3373 97.5799 16.3373 80.5H0.237305Z"
                fill="none"
              />
              <path
                d="M0.237305 80.5C0.237307 101.85 8.71853 122.325 23.8152 137.422C38.9119 152.519 59.3874 161 80.7373 161C102.087 161 122.563 152.519 137.659 137.422C152.756 122.325 161.237 101.85 161.237 80.5H145.137C145.137 97.58 138.352 113.96 126.275 126.038C114.198 138.115 97.8172 144.9 80.7373 144.9C63.6574 144.9 47.277 138.115 35.1996 126.038C23.1223 113.96 16.3373 97.5799 16.3373 80.5H0.237305Z"
                fill="none"
              />
            </svg>

          </div>
          <div
            class="score-circle-overlay"
            :style="positiveGraph"
          >
            <svg
              width="161"
              height="161"
              viewBox="0 0 161 161"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 80.5C1.86647e-06 59.1501 8.48123 38.6746 23.5779 23.5779C38.6746 8.48123 59.1501 2.29051e-06 80.5 0C101.85 -2.29051e-06 122.325 8.48122 137.422 23.5779C152.519 38.6746 161 59.1501 161 80.5H144.9C144.9 63.42 138.115 47.0397 126.038 34.9623C113.96 22.885 97.5799 16.1 80.5 16.1C63.4201 16.1 47.0397 22.885 34.9623 34.9623C22.885 47.0397 16.1 63.4201 16.1 80.5H0Z"
                fill="none"
              />
              <path
                d="M0 80.5C1.86647e-06 59.1501 8.48123 38.6746 23.5779 23.5779C38.6746 8.48123 59.1501 2.29051e-06 80.5 0C101.85 -2.29051e-06 122.325 8.48122 137.422 23.5779C152.519 38.6746 161 59.1501 161 80.5H144.9C144.9 63.42 138.115 47.0397 126.038 34.9623C113.96 22.885 97.5799 16.1 80.5 16.1C63.4201 16.1 47.0397 22.885 34.9623 34.9623C22.885 47.0397 16.1 63.4201 16.1 80.5H0Z"
                fill="#46A758"
              />
              <path
                d="M0 80.5C1.86647e-06 101.85 8.48123 122.325 23.5779 137.422C38.6746 152.519 59.1501 161 80.5 161C101.85 161 122.325 152.519 137.422 137.422C152.519 122.325 161 101.85 161 80.5H144.9C144.9 97.58 138.115 113.96 126.038 126.038C113.96 138.115 97.5799 144.9 80.5 144.9C63.4201 144.9 47.0397 138.115 34.9623 126.038C22.885 113.96 16.1 97.5799 16.1 80.5H0Z"
                fill="none"
              />
              <path
                d="M0 80.5C1.86647e-06 101.85 8.48123 122.325 23.5779 137.422C38.6746 152.519 59.1501 161 80.5 161C101.85 161 122.325 152.519 137.422 137.422C152.519 122.325 161 101.85 161 80.5H144.9C144.9 97.58 138.115 113.96 126.038 126.038C113.96 138.115 97.5799 144.9 80.5 144.9C63.4201 144.9 47.0397 138.115 34.9623 126.038C22.885 113.96 16.1 97.5799 16.1 80.5H0Z"
                fill="none"
              />
            </svg>

          </div>
        </div>
      </div>
      <div class="scores">
        <div class="score">
          <div class="score-title">
            <span>
              <svg
                width="19"
                height="18"
                viewBox="0 0 19 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_6191_27888)">
                  <path
                    d="M9.7627 16.5C13.9048 16.5 17.2627 13.1421 17.2627 9C17.2627 4.85786 13.9048 1.5 9.7627 1.5C5.62056 1.5 2.2627 4.85786 2.2627 9C2.2627 13.1421 5.62056 16.5 9.7627 16.5Z"
                    stroke="#46A758"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M6.7627 11.25C7.44679 12.1608 8.53592 12.75 9.7627 12.75C10.9895 12.75 12.0786 12.1608 12.7627 11.25"
                    stroke="#46A758"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M6.76942 6.75H6.7627M12.7627 6.75H12.7559"
                    stroke="#46A758"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_6191_27888">
                    <rect
                      width="18"
                      height="18"
                      fill="white"
                      transform="translate(0.762695)"
                    />
                  </clipPath>
                </defs>
              </svg>

            </span>
            <span>            {{ $t('entries.ai-content-analyzer.sentiment.positive') }}
            </span>
          </div>
          <span class="percentage">{{ positive }}</span>
        </div>
        <div class="score">
          <div class="score-title">
            <svg
              width="19"
              height="18"
              viewBox="0 0 19 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_6191_27898)">
                <path
                  d="M9.7627 16.5C13.9048 16.5 17.2627 13.1421 17.2627 9C17.2627 4.85786 13.9048 1.5 9.7627 1.5C5.62056 1.5 2.2627 4.85786 2.2627 9C2.2627 13.1421 5.62056 16.5 9.7627 16.5Z"
                  stroke="#FFA01C"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M6.76942 6.75H6.7627M12.7627 6.75H12.7559"
                  stroke="#FFA01C"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M7.5127 12H12.0127"
                  stroke="#FFA01C"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_6191_27898">
                  <rect
                    width="18"
                    height="18"
                    fill="white"
                    transform="translate(0.762695)"
                  />
                </clipPath>
              </defs>
            </svg>

            <span>  {{ $t('entries.ai-content-analyzer.sentiment.neutral') }}</span>

          </div>
          <span class="percentage">{{ neutral }}</span>
        </div>
        <div class="score">
          <div class="score-title">
            <!-- {{ $t('entries.ai-content-analyzer.keyword-density') }} -->
            <span><svg
              width="19"
              height="18"
              viewBox="0 0 19 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_6191_27908)">
                <path
                  d="M9.7627 16.5C13.9048 16.5 17.2627 13.1421 17.2627 9C17.2627 4.85786 13.9048 1.5 9.7627 1.5C5.62056 1.5 2.2627 4.85786 2.2627 9C2.2627 13.1421 5.62056 16.5 9.7627 16.5Z"
                  stroke="#E5484D"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M12.7627 11.7L12.5607 11.55C12.0221 11.15 11.3881 11.15 10.8495 11.55L10.6475 11.7C10.1089 12.1 9.47492 12.1 8.93635 11.7L8.7343 11.55C8.19571 11.15 7.56174 11.15 7.02312 11.55L6.7627 11.7434"
                  stroke="#E5484D"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M6.76942 6.75H6.7627M12.7627 6.75H12.7559"
                  stroke="#E5484D"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_6191_27908">
                  <rect
                    width="18"
                    height="18"
                    fill="white"
                    transform="translate(0.762695)"
                  />
                </clipPath>
              </defs>
            </svg>
            </span>
            <span>  {{ $t('entries.ai-content-analyzer.sentiment.negative') }}</span>
          </div>
          <span class="percentage">{{ negative }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { generateSentimentAiContent } from '@/codex-sdk/ai'
import { isNil } from '../image-editor/js/util'

export default {

  name: 'SentimentScore',
  components: {
    // AiIcon,
  },
  props: {
    entryId: {
      type: String,
      default: () => '',
    },
  },
  data() {
    return {
      sentiment: {
        type: Object,
        default: () => {},
      },
    }
  },
  computed: {
    positiveGraph() {
      const degrees = ((this.sentiment.positive / 50) * 90 + 180)
      return {
        transform: `rotate(-${degrees}deg)`,
      }
    },
    negativeGraph() {
      const degrees = ((this.sentiment.negative / 50) * 90 + 180)
      return {
        transform: `rotate(${degrees}deg)`,
      }
    },
    maxVal() {
      return Math.max(this.sentiment.positive, this.sentiment.neutral, this.sentiment.negative) || 0
    },
    nameOfMaxVal() {
      const maxVal = this.maxVal
      if (maxVal === this.sentiment.positive) {
        return this.$t('entries.ai-content-analyzer.sentiment.positive')
      } if (maxVal === this.sentiment.neutral) {
        return this.$t('entries.ai-content-analyzer.sentiment.neutral')
      }
      if (maxVal === this.sentiment.negative) {
        return this.$t('entries.ai-content-analyzer.sentiment.negative')
      }
      return ''
    },
    negative() {
      return !isNil(this.sentiment.negative) ? `${this.sentiment.negative}%` : ''
    },
    neutral() {
      return !isNil(this.sentiment.neutral) ? `${this.sentiment.neutral}%` : ''
    },
    positive() {
      return !isNil(this.sentiment.positive) ? `${this.sentiment.positive}%` : ''
    },

  },
  mounted() {
    this.getSentiment()
  },

  methods: {
    async getSentiment() {
      if (this.loading) return
      this.loading = true
      try {
        const { data } = await generateSentimentAiContent({ entryId: this.entryId }, { successTitle: false })
        this.sentiment = data
      } catch (e) {
        console.log(e)
      }
      this.loading = false
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    },
  },
}
</script>

<style lang="scss">

.sentiment-analyzer{
  .suggestion-button{
    background-color: unset;
    padding:4px 8px;
    gap: 4px;
    border-radius: 6px;
    border: 1px solid #DEDEE2;
    font-size: 12px;
    font-weight: 500;
    height:fit-content;
    outline: none !important;

  }
  .score-title{
    display: flex;
    align-items: center;
    gap:8px;

  }
}

</style>
