<template>
  <b-form-invalid-feedback :state="error.isValid">
    {{ error.message }}
  </b-form-invalid-feedback>
</template>

<script>
export default {
  name: 'FieldError',
  props: {
    error: {
      type: Object,
      default: () => ({ isValid: true, message: '' }),
    },
  },
}
</script>

<style lang="scss">
</style>
