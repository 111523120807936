/* eslint-disable no-unused-vars */
import axios from 'axios'
import encode from './encode'
import {
  getPreSigned,
} from '../../../codex-sdk/vp-upload'

async function uploadPart(file) {
  file.uploadType = 'single'

  const params = {
    fileName: file.name,
    mimeType: file.type,
  }

  const headers = {
    'Access-Control-Allow-Origin': '*',
  }
  let one = true
  await getPreSigned({ params, headers })
    .then(response => {
      file.cancelToken = axios.CancelToken.source()
      file.requestKey = response.data.result.requestKey
      const options = {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/octet-stream',
          ACL: 'public-read',
        },
        onUploadProgress: progressEvent => {
          const { total, loaded, lengthComputable } = progressEvent
          file.progress = lengthComputable ? total : null
          if (file.progress !== null) {
            file.progress = Math.round((loaded * 100) / total)
          }
          if (one) file.status = 'uploading'; one = false
        },
        cancelToken: file.cancelToken.token,
      }

      // response.data.result.url => presigned url from response,
      // file => file to upload,
      // options => headers, params, onUploadProgress, cancelToken
      return axios.put(response.data.result.url, file.file, options)
        .then(async () => {
          encode(file, file.requestKey)
        })
        .catch(err => {
          if (axios.isCancel(err)) {
            file.status = 'canceled'
          } else file.status = 'failed'; file.failedStatus = 'upload'
        })
    })
    .catch(err => {
      file.status = 'failed'
    })
}

export default uploadPart
