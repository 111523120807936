<template>
  <div>
    <template v-for="(blockComments, blockId) in filteredComments()">
      <CommentBubble
        v-if="getPosition(blockId)"
        :key="blockId"
        class="comments-bubble"
        :block-comments="blockComments"
        :style="getPosition(blockId)"
      />
    </template>
  </div>
</template>

<script>
import CommentBubble from './CommentBubble.vue'

export default {
  inject: ['filteredComments', 'renderClass'],
  components: {
    CommentBubble,
  },
  data() {
    return {
      observing: [],
      resizeObs: null,
    }
  },
  mounted() {
    this.resizeObs = new ResizeObserver(() => this.reposition())
    const tm = setInterval(() => {
      const node = document.querySelector(`.${this.renderClass} .ProseMirror`)
      if (node && this.resizeObs) {
        this.resizeObs.observe(node)
        clearInterval(tm)
      }
    })
  },
  destroyed() {
    this.resizeObs.disconnect()
  },
  methods: {
    reposition() {
      this.$forceUpdate()
    },
    getPosition(blockId) {
      const node = document.querySelector(`.${this.renderClass} .ProseMirror [data-id='${blockId}']`)
      if (!node) return null
      const editorDOM = document.querySelector(`.${this.renderClass} .codex-editor`)
      const editorRect = editorDOM.getBoundingClientRect()
      const rect = node.getBoundingClientRect()
      return {
        top: `${rect.top + rect.height / 2 - editorRect.top - 17}px`,
        right: '8px',
      }
    },
  },
}
</script>

<style lang="scss">
</style>
