import { Mark } from 'tiptap'
import { toggleMark } from 'tiptap-commands'

export default class Premium extends Mark {
  get name() {
    return 'premium'
  }

  get schema() {
    return {
      parseDOM: [{
        tag: 'span[data-type="premium"]',
      }],
      toDOM: () => ['span', { 'data-type': 'premium' }, 0],
    }
  }

  // keys({ type }) {
  //   return {
  //     'Mod-.': toggleMark(type),
  //   }
  // }

  commands({ type }) {
    return () => toggleMark(type)
  }
}
