<template>
  <div class="section-field-initial__container">
    <b-form-group
      :label="$t('fields.codex-field-sections.general.field-label')"
      label-for="section-field__name"
    >
      <b-form-input
        id="section-field__name"
        v-model="name"
        :placeholder="$t('fields.codex-field-sections.general.field-name-placeholder')"
      />
    </b-form-group>
    <b-form-group
      :label="$t('fields.codex-field-sections.general.alias-label')"
      label-for="section-field__alias"
    >
      <b-form-input
        id="section-field__alias"
        v-model.trim="tempAlias"
        :state="tempAlias === null ? null : !aliasState"
        trim
        :placeholder="$t('fields.codex-field-sections.general.alias-name-placeholder')"
      />
      <b-form-invalid-feedback v-if="!nameState.isValid">
        {{ $t('fields.general.validation.alias-invalid') }}
      </b-form-invalid-feedback>
      <b-form-invalid-feedback v-if="nameState.isReserved">
        {{ $t('fields.general.validation.alias-is-reserved') }}
      </b-form-invalid-feedback>
      <b-form-invalid-feedback v-if="!nameState.isUnique">
        {{ $t('fields.general.validation.alias-is-used') }}
      </b-form-invalid-feedback>
    </b-form-group>
    <b-form-group
      :label="$t('fields.codex-field-sections.general.description-label')"
      label-for="section-field__description"
    >
      <b-form-textarea
        id="section-field__description"
        v-model="description"
        :placeholder="$t('fields.codex-field-sections.general.description-placeholder')"
        rows="2"
        no-resize
      />
    </b-form-group>
    <b-form-group :label="$t('fields.codex-field-sections.general.display')">
      <b-form-radio-group v-model="valueType">
        <b-form-radio :value="VALUE_TYPES.SINGLE">
          {{ $t('fields.codex-field-sections.general.single') }}
        </b-form-radio>
        <b-form-radio :value="VALUE_TYPES.LIST">
          {{ $t('fields.codex-field-sections.general.list') }}
        </b-form-radio>
      </b-form-radio-group>
    </b-form-group>
    <b-button
      variant="primary"
      class="w-100"
      :disabled="aliasState"
      @click="create"
    >
      {{ $t('general.create') }}
    </b-button>
  </div>

</template>

<script>
import { generateComputedPropsFromAttrs } from '@/components/codex-layout-editor/BuilderUtils'
import FieldValidationMixin from '@/components/fields/FieldValidationMixin'
import { VALUE_TYPES } from '@/views/models/constants'
import BaseFieldMixin from '@/components/fields/BaseFieldMixin'

export default {
  inject: ['validateAlias'],
  mixins: [BaseFieldMixin, FieldValidationMixin],
  data() {
    return {
      VALUE_TYPES,
    }
  },
  computed: {
    ...generateComputedPropsFromAttrs([
      'alias',
      'name',
      'description',
      'valueType',
      'configured',
      'defaultValue',
    ]),
    aliasState() {
      return this.nameState.isReserved || !this.nameState.isUnique || !this.nameState.isValid
    },
  },
  methods: {
    setDefaultValueType() {
      this.defaultValue = this.valueType === VALUE_TYPES.LIST ? [] : null
    },
  },
}
</script>
