import { Mark } from 'tiptap'
import { toggleMark } from 'tiptap-commands'

export default class Superscript extends Mark {
  get name() {
    return 'superscript'
  }

  get schema() {
    return {
      parseDOM: [{
        tag: 'sup',
      },
      {
        style: 'vertical-align',
        getAttrs(value) {
          // Don’t match this rule if the vertical align isn’t super.
          if (value !== 'super') {
            return false
          }

          // If it falls through we’ll match, and this mark will be applied.
          return null
        },
      }],
      toDOM: () => ['sup', 0],
    }
  }

  keys({ type }) {
    return {
      'Mod-.': toggleMark(type),
    }
  }

  commands({ type }) {
    return () => toggleMark(type)
  }
}
