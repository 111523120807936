<template>
  <div>
    <NoPermission v-if="!$store.state.sites.sites.some((site) => site.id === $route.params.siteName )" />
    <div
      v-else
      class="entries article-listing__wrapper"
      :class="{'article-listing__wrapper-popup': isPopup}"
    >
      <full-height-layout>
        <div
          class="article-listing"
          :class="{'article-listing--not-popup': !isPopup }"
        >
          <div class="article-listing__header">
            <div class="page-header">
              <div
                v-if="!disableEntryCreation || isPopup"
                class="page-header__actions"
                style="display: flex; gap: 16px;"
              >
                <b-dropdown
                  v-if="!disableEntryCreation"
                  variant="primary"
                  size="sm"
                  class="add-entry"
                  no-caret
                  no-flip
                >
                  <template #button-content>
                    <GjIcon
                      name="Plus"
                      class="mr-50"
                    />
                    <span class="align-middle">
                      {{ $t('entries.create') }}
                    </span>
                  </template>
                  <b-input
                    v-model="search"
                    :placeholder="$t('entries.search-models')"
                    class="entries-dropdown__search"
                    :autofocus="true"
                  />
                  <div
                    v-if="searchModels.length <=0"
                    class="entries-dropdown__no-results"
                  >
                    <p>{{ $t('entries.no-result') }}</p>
                  </div>
                  <template v-if="isPopup">
                    <b-dropdown-item
                      v-for="m in searchModels"
                      :key="m.id"
                      v-permission="['canCreateEntry', m]"
                      @click.native="createEntry(m)"
                    >
                      <GjIcon
                        v-if="doesIconExist(m.iconId)"
                        :key="m.iconId"
                        :name="m.iconId"
                      />
                      {{ m.name }}
                    </b-dropdown-item>
                  </template>
                  <template v-else>
                    <b-dropdown-item
                      v-for="m in searchModels"
                      :key="m.id"
                      v-permission="['canCreateEntry', m]"
                      :to="{name: 'entries-edit', params: {model: m.alias, id:'create', filters: queryForPrefilledFields(m)}}"
                      :title="m.name"
                    >
                      <GjIcon
                        v-if="doesIconExist(m.iconId)"
                        :key="m.iconId"
                        :name="m.iconId"
                      />
                      {{ m.name }}
                    </b-dropdown-item>
                  </template>

                </b-dropdown>
                <b-button
                  v-if="isPopup && hasSelectedEntries"
                  variant="light"
                  style="height: 34px;padding: 8px 12px;color: #052D61;gap:9px;"
                  @click="showSelected = !showSelected"
                >
                  <GjIcon
                    name="Files"
                    size="18"
                  />
                  {{ $t('entries.listing.selected', {count: selectedEntries.length}) }}
                </b-button>
              </div>
              <div class="page-header__filters page-header__filters-filters--full-width">
                <div class="page-header__filters-group">
                  <!-- search -->
                  <div class="page-header__filters-search">
                    <b-form-input
                      v-if="showSelected"
                      v-model="searchSelected"
                      :debounce="250"
                      :placeholder="$t('entries.search')"
                      type="text"
                      size="sm"
                    />
                    <b-form-input
                      v-else
                      v-model="view.variables.searchTerm"
                      :debounce="250"
                      :placeholder="$t('entries.search')"
                      type="text"
                      size="sm"
                    />
                  </div>
                  <div
                    v-if="(isCustomView || filters.getActiveFiltersCount() > 0 || columnsHaveChanged) && !isPopup && !isEmbed"
                    class="page-header__view-button"
                  >
                    <b-dropdown
                      v-if="viewHasChanged"
                      id="entries-view__dropdown"
                      v-b-tooltip="!canEditEntriesView({ entity: isCustomView ? entryView : null }).can ? $t('entries.listing.entry-view.no-permission-update') : ''"
                      v-permission="['canEditEntriesView', isCustomView ? entryView : null]"
                      split
                      size="sm"
                      variant="outline-primary"
                      @click="createView()"
                    >
                      <template #button-content>
                        <span>{{ !isCustomView ? $t('entries.listing.entry-view.save') : $t('entries.listing.entry-view.update') }}</span>
                      </template>
                      <b-dropdown-item
                        v-if="isCustomView"
                        @click="createView(true)"
                      >
                        <span>
                          <GjIcon
                            name="Save"
                            size="16"
                          />
                          {{ $t('entries.listing.entry-view.save-as-new') }}
                        </span>
                      </b-dropdown-item>
                      <b-dropdown-item @click="discardChanges">
                        <span>
                          <GjIcon
                            name="Save"
                            size="16"
                          />
                          {{ $t('entries.listing.entry-view.discard') }}
                        </span>
                      </b-dropdown-item>
                    </b-dropdown>
                  </div>
                  <div
                    v-show="!showSelected"
                    class="page-header__filters-filters"
                  >
                    <FiltersVue :filters="filters" />
                  </div>
                </div>

              </div>
            </div> <!-- page-header -->
          </div> <!-- article-listing__header -->
          <div
            v-if="!isPopup"
            class="entries-header-mobile"
          >
            <div class="entries-header-mobile__actions">
              <span class="entries-header-mobile__title">Entries Listing</span>
              <span class="entries-header-mobile__buttons">
                <b-dropdown
                  variant="asd"
                  size="sm"
                  class="entries-header-mobile__dropdown"
                  no-caret
                >
                  <template #button-content>
                    <GjIcon
                      name="Plus"
                    />
                  </template>
                  <b-input
                    v-model="search"
                    :placeholder="$t('entries.search')"
                    class="entries-dropdown__search"
                    :autofocus="true"
                  />
                  <div
                    v-if="searchModels.length <=0"
                    class="entries-dropdown__no-results"
                  >
                    <p>{{ $t('entries.no-result') }}</p>
                  </div>
                  <b-dropdown-item
                    v-for="model in searchModels"
                    :key="model.id"
                    :to="{name: 'entries-edit', params: {model: model.alias, id:'create'}}"
                  >
                    <GjIcon
                      v-if="doesIconExist(model.iconId)"
                      :key="model.iconId"
                      :name="model.iconId"
                    />
                    {{ model.name }}
                  </b-dropdown-item>
                </b-dropdown>

                <div
                  v-show="!showSelected"
                  class="entries-header-mobile__filters"
                >
                  <FiltersVue :filters="filters" />
                </div>

                <span @click="showMobileInput = !showMobileInput"><GjIcon name="Search" /></span>
              </span>
            </div>
            <transition name="mobile-search">
              <div
                v-show="showMobileInput"
                class="entries-header-mobile__input"
              >
                <b-form-input
                  v-model="view.variables.searchTerm"
                  :debounce="250"
                  :placeholder="$t('entries.search')"
                  type="text"
                  size="sm"
                />
              </div>
            </transition>
          </div>
          <!-- Error -->

          <!-- Result -->
          <div class="entry-field__table-wrapper">
            <vue-good-table
              v-if="entryCollection && entryCollection.items"
              ref="entries-listing-table"
              mode="remote"
              :columns="allColumns"
              class="entry-field__table"
              :class="{
                'listing-popup-table': isPopup || quickViewEntryId,
                'listing-popup-table-no-hover': !isPopup,
                'listing-popup-table--has-checkbox': isPopup,
                'vgt--loading': $apollo.loading || loadingColumns,
              }"
              :rows="entryRows"
              :select-options="{
                enabled: true,
                disableSelectInfo: true,
                selectOnCheckboxOnly: !isPopup
              }"
              :sort-options="{
                initialSortBy: {}
              }"
              :row-style-class="rowClasses"
              @contextmenu.native.prevent="$refs.menu.open"
              @on-row-click="selectionChanged"
              @on-sort-change="onSortChange"
              @on-selected-rows-change="handleRowsSelect"
            >
              <!-- @on-select-all="handleRowsSelectAll" -->
              <template slot="emptystate">
                <div
                  v-if="$apollo.error || entryCollection.items.length === 0 || selectedEntries.length === 0 || searchSelected.length"
                  class="no-result apollo"
                >
                  <div
                    v-if="$apollo.error || entryCollection.items.length === 0 || (showSelected && selectedEntries.length === 0 || searchSelected.length)"
                    class="error apollo"
                  >
                    <NoElements
                      :title="showSelected ? $t('entries.listing.no-entries-selected') : $t('entries.listing.no-entries-found')"
                      icon="Modeling"
                    />
                  </div>
                </div>
              </template>
              <template
                slot="table-column"
                slot-scope="props"
              >
                <div class="entry-field__th">
                  <span v-if="props.column.alias == 'filter'">
                    <GjIcon
                      v-if="!isPopup"
                      v-b-toggle.filter-sidebar
                      name="Filters"
                    />
                  </span>
                  <span v-else>
                    {{ props.column.label }}
                  </span>
                </div>
              </template>
              <template
                slot="table-row"
                slot-scope="props"
              >
                <div
                  class="entry-field__row"
                  :class="{
                    'popup-row-clicked': selectedEntries.find(e => e.id == props.row.id) || quickViewEntryId == props.row.id,
                    'entry-field__row__disabled': isExcluded(props.row.id)
                  }"
                >
                  <div
                    v-if="props.column.field === 'id'"
                    class="stacked-card__content"
                    :class="{'pointer-events-none': isPopup || isEmbed}"
                  >
                    <router-link
                      class="stacked-card__image-wrapper"
                      :to="{name: 'entries-edit', params: { viewId, model: props.row.system.modelAlias, id: props.row.id }}"
                    >
                      <div
                        :key="props.row.id"
                        class="stacked-card__image"
                      >
                        <b-img-aspect
                          v-if="props.row.system.featuredMedia"
                          :key="props.row.id + props.row.system.featuredMedia.url"
                          aspect="1:1"
                          style="width: 34px;"
                          :src="props.row.system.featuredMedia.url"
                        />
                        <GjIcon
                          v-else
                          :key="`${getEntryModel(props.row).iconId}${props.row.id}`"
                          style="width: 34px;"
                          size="24"
                          :name="getEntryModel(props.row).iconId && doesIconExist(getEntryModel(props.row).iconId) ? getEntryModel(props.row).iconId : 'Modeling'"
                        />
                      </div>
                      <div
                        v-if="showSiteIcon"
                        class="stacked-card__site-logo"
                      >
                        <b-img-aspect
                          :src="props.row.system.site.logo"
                          aspect="1:1"
                          style="width: 100%"
                          :title="props.row.system.site.name"
                        />
                      </div>
                    </router-link>
                    <div class="stacked-card__title-content-wrapper">
                      <div class="stacked-card__title-content">
                        <router-link
                          :to="{name: 'entries-edit', params: { viewId, model: props.row.system.modelAlias, id: props.row.id }}"
                        >
                          <span
                            v-if="!props.row.system.title"
                            class="stacked-card__title-untitled"
                          >
                            {{ $t('entries.untitled') }}
                          </span>
                          <span
                            v-else
                            class="stacked-card__title"
                            :title="props.row.system.title"
                          >
                            {{ props.row.system.title }}
                          </span>
                        </router-link>
                      </div>
                      <div class="stacked-card__quick-buttons">
                        <span class="stacked-card__title-model-name">
                          {{ getEntryModel(props.row).name }}
                        </span>
                        <span class="stacked-card__status-mobile">
                          <div>
                            <StatusChip
                              :entry="props.row"
                              :is-query="true"
                              :scheduled-versions="props.row.system.scheduledVersions && props.row.system.scheduledVersions.items"
                            />
                          </div>
                        </span>
                        <div class="stacked-card__quick-actions">
                          <span
                            :key="`${props.row.system.status}${props.row.id}quickView`"
                            v-b-tooltip.hover="$t('entries.quick-actions.quick-view')"
                            class="stacked-card__quick-action-buttons"
                            :class="{'stacked-card__quick-view-button': isPopup}"
                            @click.stop="showQuickView(props.row)"
                          >
                            <GjIcon
                              key="QuickView"
                              name="Show"
                              size="16"
                            />
                          </span>
                          <span
                            v-if="isEmbed"
                            :key="`${props.row.system.status}${props.row.id}quickEdit`"
                            v-b-tooltip.hover="$t('entries.quick-actions.quick-edit')"
                            class="stacked-card__quick-action-buttons"
                            :class="{'stacked-card__quick-edit-button': isPopup}"
                            @click.stop="editEntry(props.row)"
                          >
                            <GjIcon
                              key="Edit"
                              name="Edit"
                              size="16"
                            />
                          </span>
                          <span
                            v-if="!isPopup && !isEmbed"
                            :key="`${props.row.system.status}${props.row.id}addToList`"
                            v-permission="['canEditList']"
                          >
                            <AddToEntriesLists
                              :entry-id="props.row.id"
                              :model-alias="props.row.system.modelAlias"
                              :classes="'entries-list-dropdown--listing'"
                            >
                              <template slot="button">
                                <GjIcon
                                  key="QuickAddToList"
                                  v-b-tooltip.hover="$t('entries.quick-actions.quick-add-to-list')"
                                  name="IconparkListAdd"
                                  size="16"
                                />
                              </template>
                            </AddToEntriesLists>
                          </span>
                          <span
                            v-if="!isPopup && !isEmbed && showSchedulePopup(props.row)"
                            :key="`${props.row.system.status}${props.row.id}schedule`"
                            v-permission="['canPublishEntry', props.row]"
                            class="stacked-card__quick-action-buttons"
                            @click="openSchedulePopup(props.row)"
                          >
                            <span v-b-tooltip.hover="$t('entries.quick-actions.quick-schedule')">
                              <GjIcon
                                key="QuickSchedule"
                                name="Calendar"
                                size="16"
                              />
                            </span>
                          </span>
                          <span
                            v-if="!isPopup && !isEmbed && showPublishButton(props.row)"
                            :key="`${props.row.system.status}${props.row.id}publish`"
                            v-permission="['canPublishEntry', props.row]"
                            class="stacked-card__quick-action-buttons"
                            @click="publish(props.row)"
                          >
                            <span v-b-tooltip.hover="isStatusPublishedOrEdited(props.row.system.status) ? $t('entries.quick-actions.quick-republish') : $t('entries.quick-actions.quick-publish')">
                              <GjIcon
                                key="RocketPublish"
                                name="Rocket"
                                size="16"
                                class="stacked-card__publish-icon"
                              />
                            </span>
                          </span>
                          <span
                            v-if="!isPopup && !isEmbed && showPublishDate(props.row)"
                            :key="`${props.row.system.status}${props.row.id}unpublish`"
                            v-permission="['canUnpublishEntry', props.row]"
                            class="stacked-card__quick-action-buttons"
                            @click="unpublish(props.row)"
                          >
                            <span v-b-tooltip.hover="!showPublishButton(props.row) ? $t('entries.quick-actions.quick-unpublish') : $t('entries.quick-actions.quick-unschedule')">
                              <GjIcon
                                key="Denied"
                                name="Denied"
                                size="16"
                              />
                            </span>
                          </span>

                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-else-if="props.column.field === 'system.createdAt'">
                    {{ props.row.system.createdAt | formatDateTime }}
                  </div>
                  <div v-else-if="props.column.field === 'system.updatedAt'">
                    {{ props.row.system.updatedAt | formatDateTime }}
                  </div>
                  <div v-else-if="props.column.field === 'system.firstPublishedAt'">
                    {{ props.row.system.firstPublishedAt | formatDateTime }}
                  </div>
                  <div
                    v-else-if="props.column.field === 'system'"
                    class="entry-field__table-actions"
                  >
                    <b-dropdown
                      v-if="!isPopup"
                      right
                      variant="flat-primary"
                      class="entries__dropdown--mobile"
                      no-caret
                    >
                      <template #button-content>
                        <GjIcon name="Moreoptions" />
                      </template>
                      <b-dropdown-item :key="`${props.row.system.status}${props.row.id}quickView`">
                        <span
                          v-b-tooltip.hover="$t('entries.quick-actions.quick-view')"
                          @click="showQuickView(props.row)"
                        >
                          <GjIcon
                            key="QuickView"
                            name="Show"
                            size="16"
                          />
                          {{ $t('entries.quick-actions.quick-view') }}
                        </span>
                      </b-dropdown-item>
                      <b-dropdown-item
                        :key="`${props.row.system.status}${props.row.id}addToList`"
                        @click="openAddToList(props.row)"
                      >
                        <GjIcon
                          key="QuickAddToList"
                          name="IconparkListAdd"
                          size="16"
                        />
                        {{ $t('entries.quick-actions.quick-add-to-list') }}
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-if="showSchedulePopup(props.row)"
                        :key="`${props.row.system.status}${props.row.id}schedule`"
                      >
                        <span
                          v-b-tooltip.hover="$t('entries.quick-actions.quick-schedule')"
                          v-permission="['canPublishEntry', props.row]"
                          @click="openSchedulePopup(props.row)"
                        >
                          <GjIcon
                            key="QuickSchedule"
                            name="Calendar"
                            size="16"
                          />
                          {{ $t('entries.quick-actions.quick-schedule') }}
                        </span>
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-if="showPublishButton(props.row)"
                        :key="`${props.row.system.status}${props.row.id}publish`"
                      >
                        <span
                          v-b-tooltip.hover="isStatusPublishedOrEdited(props.row.system.status) ? $t('entries.quick-actions.quick-republish') : $t('entries.quick-actions.quick-publish')"
                          v-permission="['canPublishEntry', props.row]"
                          @click="publish(props.row)"
                        >
                          <GjIcon
                            key="RocketPublish"
                            name="Rocket"
                            size="16"
                            class="stacked-card__publish-icon"
                          />
                          {{ isStatusPublishedOrEdited(props.row.system.status) ? $t('entries.quick-actions.quick-republish') : $t('entries.quick-actions.quick-publish') }}
                        </span>
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-if="showPublishDate(props.row)"
                        :key="`${props.row.system.status}${props.row.id}unpublish`"
                      >
                        <span
                          v-b-tooltip.hover="!isPublishedOrEdited(props.row) ? $t('entries.quick-actions.quick-unpublish') : $t('entries.quick-actions.quick-unschedule')"
                          v-permission="['canUnpublishEntry', props.row]"
                          @click="unpublish(props.row)"
                        >
                          <GjIcon
                            key="Denied"
                            name="Denied"
                            size="16"
                          />
                          {{ isPublishedOrEdited(props.row) ? $t('entries.quick-actions.quick-unpublish') : $t('entries.quick-actions.quick-unschedule') }}
                        </span>
                      </b-dropdown-item>
                    </b-dropdown>
                    <b-dropdown
                      v-if="!isPopup && !isEmbed"
                      toggle-class="stacked-card__options-button"
                      right
                      variant="flat-primary"
                      class="dropdown-icon-wrapper entries__dropdown--desktop"
                      :lazy="true"
                    >
                      <template #button-content>
                        <GjIcon name="Moreoptions" />
                      </template>
                      <b-dropdown-item
                        v-permission="['canEditEntry', props.row]"
                        :to="{name: 'entries-edit', params: { viewId, model: props.row.system.modelAlias, id: props.row.id }}"
                      >
                        <span class="d-flex align-items-center">
                          <GjIcon name="Edit" />
                          <p class="p-0 m-0 ml-50">
                            {{ $t('entries.listing.edit', {name: ''}) }}
                          </p>
                        </span>
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-if="![ENTRY_STATUSES_STRING[ENTRY_STATUSES.PUBLISHED], ENTRY_STATUSES_STRING[ENTRY_STATUSES.EDITED], ENTRY_STATUSES_STRING[ENTRY_STATUSES.SCHEDULED]].includes(props.row.system.status)"
                        v-permission="['canDeleteEntry', props.row]"
                        @click="deleteEntry(props.row)"
                      >
                        <span class="d-flex align-items-center">
                          <GjIcon name="Delete" />
                          <p class="p-0 m-0 ml-50">
                            {{ $t('entries.listing.delete', {name: ''}) }}
                          </p>
                        </span>
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-else
                        v-permission="['canUnpublishEntry', props.row]"
                        @click="unpublish(props.row)"
                      >
                        <span class="d-flex align-items-center">
                          <GjIcon name="Denied" />
                          <p class="p-0 m-0 ml-50">
                            {{ isPublishedOrEdited(props.row) ? $t('entries.listing.unpublish', {name: ''}) : $t('entries.listing.unschedule', {name: ''}) }}
                          </p>
                        </span>
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-if="!props.row.system.model.configurations.hideInCloneButton"
                        v-permission="['canCreateEntry', props.row.system.model]"
                        @click="checkMappings(props.row)"
                      >
                        <span class="d-flex align-items-center">
                          <GjIcon name="Layers_fill" />
                          <p class="p-0 m-0 ml-50">
                            {{ $t('entries.listing.clone', {name: ''}) }}
                          </p>
                        </span>
                      </b-dropdown-item>
                    </b-dropdown>
                    <div
                      v-if="isPopup"
                      class="entries-table__selected"
                    >
                      <GjIcon
                        :key="selectedEntries.find(e => e.id == props.row.id) ? 'Check' : 'Plus'"
                        :name="selectedEntries.find(e => e.id == props.row.id) ? 'Check' : 'Plus'"
                      />
                    </div>
                    <AddToEntriesLists
                      :ref="`add-${props.row.id}`"
                      class="mobile-only entries__dropdown-add-list entries-list-dropdown--listing"
                      :entry-id="props.row.id"
                      :model-alias="props.row.system.modelAlias"
                    >
                      <template slot="button" />
                    </AddToEntriesLists>

                  </div>
                  <div v-else-if="props.column.field === 'system.createdBy.id' && props.row.system.createdBy">
                    <div
                      class="stacked-card__author"
                      :title="fullName(props.row.system.createdBy)"
                    >
                      <div class="stacked-card__author-avatar">
                        <b-avatar
                          :text="fullName(props.row.system.createdBy)[0]"
                          :src="props.row.system.createdBy.imageUrl"
                        />
                      </div>
                      <div class="stacked-card__author-data">
                        <p class="stacked-card__author-name">
                          {{ fullName(props.row.system.createdBy) }}
                        </p>
                        <p class="stacked-card__author-role">
                          {{ $t('entries.listing.created-by') }}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div v-else-if="props.column.field === 'system.updatedBy.id' && props.row.system.updatedBy">
                    <div
                      class="stacked-card__author"
                      :title="fullName(props.row.system.updatedBy)"
                    >
                      <div class="stacked-card__author-avatar">
                        <b-avatar
                          :text="fullName(props.row.system.updatedBy)[0]"
                          :src="props.row.system.updatedBy.imageUrl"
                        />
                      </div>
                      <div class="stacked-card__author-data">
                        <p class="stacked-card__author-name">
                          {{ fullName(props.row.system.updatedBy) }}
                        </p>
                        <p class="stacked-card__author-role">
                          {{ $t('entries.listing.updated-by') }}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div v-else-if="props.column.field === 'system.publishedAt' && (props.row.system.status == ENTRY_STATUSES_STRING[ENTRY_STATUSES.PUBLISHED] || props.row.system.status == ENTRY_STATUSES_STRING[ENTRY_STATUSES.SCHEDULED] || props.row.system.status == ENTRY_STATUSES_STRING[ENTRY_STATUSES.EDITED])">
                    <span>{{ props.row.system.publishedAt | formatDateTime }}</span>
                  </div>
                  <div v-else-if="props.column.field === 'system.status'">
                    <StatusChip
                      :entry="props.row"
                      :is-query="true"
                      :with-background="true"
                      :scheduled-versions="props.row.system.scheduledVersions && props.row.system.scheduledVersions.items"
                    />
                  </div>
                  <div v-else-if="props.column.field && props.column.field === TYPES.TEXT.toString()">
                    <template v-if="Array.isArray(props.row[props.column.alias])">
                      <div v-if="props.row[props.column.alias] && props.row[props.column.alias].length > 0">
                        <span
                          v-for="text in props.row[props.column.alias].length > 2 ? props.row[props.column.alias].slice(0,2) : props.row[props.column.alias]"
                          :key="text"
                        >
                          {{ text }}
                        </span>
                        <span
                          v-if="props.row[props.column.alias].length > 3"
                          class="entry-field__count"
                        >{{ props.row[props.column.alias].length - 3 }}+</span>
                      </div>
                    </template>
                    <span v-else>{{ props.row[props.column.alias] }}</span>
                  </div>
                  <div
                    v-else-if="props.column.field && props.column.field === TYPES.INTEGER.toString() || props.column.field === TYPES.DECIMAL.toString()"
                  >
                    <template
                      v-if="Array.isArray(props.row[props.column.alias]) && props.row[props.column.alias]"
                    >
                      <span
                        v-for="(number,index) in props.row[props.column.alias]"
                        :key="number + index"
                      >
                        {{ number }}
                      </span>
                    </template>
                    <template v-else>
                      {{ props.row[props.column.alias] }}
                    </template>
                  </div>
                  <div v-else-if="props.column.field && props.column.field === TYPES.URL.toString()">
                    {{ getDomain(props.row[props.column.alias]) }}
                  </div>
                  <div v-else-if="props.column.field && props.column.field === TYPES.DATE_TIME.toString()">
                    <span v-if="props.row[props.column.alias]">{{
                      props.row[props.column.alias] | formatDateTime
                    }}</span>
                    <span v-else>N/A</span>
                  </div>
                  <span v-else-if="props.column.field && props.column.field === TYPES.BOOLEAN.toString()">
                    <template v-if="model.fieldAttrs[props.column.alias] || getFieldAttrs(props.column.alias)">
                      <template v-if="model.fieldAttrs[props.column.alias].appearance === BOOLEAN_FIELD_APPEARANCES.RADIO">
                        <template v-if="isNil(props.row[props.column.alias])">
                          N/A
                        </template>
                        <template v-else>
                          {{ props.row[props.column.alias] ? model.fieldAttrs[props.column.alias].trueCustomLabel : model.fieldAttrs[props.column.alias].falseCustomLabel }}
                        </template>
                      </template>
                      <template v-else>
                        {{ props.row[props.column.alias] ? model.fieldAttrs[props.column.alias].checkboxLabel : '' }}
                      </template>
                    </template>
                    <template v-else>
                      {{ isNil(props.row[props.column.alias]) ? 'N/A' : props.row[props.column.alias] }}
                    </template>
                  </span>
                  <div
                    v-else-if="props.column.field && props.column.field === TYPES.REFERENCE.toString()"
                    class="entry-field__reference"
                  >
                    <div
                      v-if="props.row[props.column.alias] && Array.isArray(props.row[props.column.alias].items) && props.row[props.column.alias].items.length > 0"
                      class="entries__listing-references"
                    >
                      <GjIcon
                        v-if="doesIconExist(getEntryModel(props.row[props.column.alias].items[0]).iconId)"
                        :name="getEntryModel(props.row[props.column.alias].items[0]).iconId"
                        class="entry-field__reference-image"
                      />
                      <span class="entry-field__reference-title">{{
                        props.row[props.column.alias].items[0].system.title
                      }}</span>
                      <span
                        v-if="props.row[props.column.alias].items.length > 1"
                        class="entry-field__reference-count"
                      >{{ props.row[props.column.alias].items.length - 1 }}+</span>
                    </div>
                    <div
                      v-else-if="props.row[props.column.alias] && !Array.isArray(props.row[props.column.alias].items)"
                      style="display: flex; align-items: center;"
                    >
                      <GjIcon
                        v-if="doesIconExist(getEntryModel(props.row[props.column.alias]).iconId)"
                        :name="getEntryModel(props.row[props.column.alias]).iconId"
                        class="entry-field__reference-image"
                      />
                      <span class="entry-field__reference-title">{{
                        props.row[props.column.alias].system.title
                      }}</span>
                    </div>
                  </div>
                  <div v-else-if="props.column.field && props.column.field === TYPES.MEDIA.toString()">
                    <div
                      v-if="Array.isArray(props.row[props.column.alias]) && props.row[props.column.alias]"
                      class="entry-field__media"
                    >
                      <template
                        v-for="image in props.row[props.column.alias].length > 3 ? props.row[props.column.alias].slice(0,3) : props.row[props.column.alias] "
                      >
                        <b-img-aspect
                          v-if="image && image.media"
                          :key="image.id"
                          v-b-tooltip.hover="image.media.title ? `${image.media.title.slice(0,25)}...` : ''"
                          class="entry-field__media-image"
                          :src="image.media.url"
                          :alt="image.media.alt"
                        />
                      </template>

                      <span
                        v-if="props.row[props.column.alias].length > 3"
                        class="entry-field__media__count"
                      >{{ props.row[props.column.alias].length - 3 }}+</span>
                    </div>
                    <div
                      v-else
                      class="entry-field__media"
                    >
                      <b-img-aspect
                        v-if="props.row[props.column.alias] && props.row[props.column.alias].media"
                        :key="props.row[props.column.alias].id"
                        v-b-tooltip.hover="props.row[props.column.alias].media && props.row[props.column.alias].media.title ? `${props.row[props.column.alias].media.title.slice(0,25)}...` : ''"
                        class="entry-field__media-image"
                        :src="props.row[props.column.alias].media.url"
                        :alt="props.row[props.column.alias].media.alt"
                      />
                    </div>
                  </div>
                  <div v-else-if="props.column.field && props.column.field === TYPES.RICH_TEXT.toString()">
                    <div
                      class="entry-listing__rich-text"
                      v-html="props.row[props.column.alias]"
                    />
                  </div>
                  <div
                    v-else-if="props.column.field && props.column.field === TYPES.RICH_CONTENT.toString() && props.row[props.column.alias]"
                  >
                    <span
                      class="entry-listing__rich-content"
                      v-html="generateRichContent(props.row[props.column.alias])"
                    />
                  </div>
                  <div v-else-if="props.column.field && props.column.field === TYPES.AUTHOR.toString()">
                    <div
                      v-if="Array.isArray(props.row[props.column.alias]) && props.row[props.column.alias] && props.row[props.column.alias].length > 0"
                      class="entries__listing-authors"
                    >
                      <b-avatar-group style="margin-right: 2px;">
                        <b-avatar
                          v-for="author in props.row[props.column.alias].length > 2 ? props.row[props.column.alias].slice(0,2) : props.row[props.column.alias]"
                          :key="author"
                          v-b-tooltip.hover="author.firstName && author.lastName? author.firstName + ' ' + author.lastName : ''"
                          :src="author.image && author.image.url ? author.image.url : ''"
                          :alt="author.image && author.image.alt ? author.image.alt : ''"
                          :text="author.firstName ? author.firstName.charAt(0) : author.id.charAt(0)"
                          size="0.7rem"
                        />
                      </b-avatar-group>
                      <span
                        v-if=" props.row[props.column.alias].length > 2"
                        class="entries__listing-authors-cc"
                      >{{ props.row[props.column.alias].length - 2 }} +</span>
                    </div>
                    <div
                      v-else-if="!Array.isArray(props.row[props.column.alias]) && props.row[props.column.alias]"
                      style="display: flex; align-items: center;"
                    >
                      <b-avatar
                        v-b-tooltip.hover="props.row[props.column.alias].firstName && props.row[props.column.alias].lastName? props.row[props.column.alias].firstName + ' ' + props.row[props.column.alias].lastName : ''"
                        :src="props.row[props.column.alias].image && props.row[props.column.alias].image.url ? props.row[props.column.alias].image : ''"
                        :text="props.row[props.column.alias].firstName ? props.row[props.column.alias].firstName.charAt(0) : props.row[props.column.alias].image"
                      />
                      <span
                        class="entry-listing__author-name"
                        :title="(props.row[props.column.alias].firstName || '') + ' ' + (props.row[props.column.alias].lastName || '')"
                      >
                        {{ (props.row[props.column.alias].firstName || '') + ' ' + (props.row[props.column.alias].lastName || '') }}
                      </span>
                    </div>
                    <span v-else>
                      N/A
                    </span>
                  </div>
                  <div v-else-if="props.column.field && props.column.field === TYPES.TAG.toString()">
                    <div
                      v-if="Array.isArray(props.row[props.column.alias]) && props.row[props.column.alias] && props.row[props.column.alias].length > 0 "
                    >
                      <span
                        v-for="text in props.row[props.column.alias].length > 2 ? props.row[props.column.alias].slice(0,2) : props.row[props.column.alias]"
                        :key="text.tagValue"
                      >
                        {{ text.tagValue }}
                      </span>
                      <span
                        v-if="props.row[props.column.alias].length > 3"
                        class="entry-field__count"
                      >
                        {{ props.row[props.column.alias].length - 3 }}+
                      </span>
                    </div>
                  </div>
                  <div v-else-if="props.column.field && props.column.field === TYPES.SECTION.toString()">
                    <div
                      v-if="Array.isArray(props.row[props.column.alias]) && props.row[props.column.alias]"
                      class="entry-listing__sections"
                    >
                      <span
                        v-for="section in props.row[props.column.alias].length > 3 ? props.row[props.column.alias].splice(0,2) : props.row[props.column.alias]"
                        :key="section.id"
                        class="entry-listing__section"
                      >
                        {{
                          section.title && section.title.length > 15 ? `${section.title.slice(0, 15)}...` : section.title
                        }}
                      </span>
                      <span
                        v-if="props.row[props.column.alias].length > 3"
                        class="entry-field__count"
                      >
                        {{ props.row[props.column.alias].length - 3 }}+
                      </span>
                    </div>
                    <div
                      v-else-if="!Array.isArray(props.row[props.column.alias]) && props.row[props.column.alias]"
                      class="entry-listing__sections"
                    >
                      <span
                        class="entry-listing__section"
                      >
                        {{
                          props.row[props.column.alias].title && props.row[props.column.alias].title.length > 15 ? `${props.row[props.column.alias].title.slice(0, 15)}...` : props.row[props.column.alias].title
                        }}
                      </span>
                    </div>
                    <span v-else>N/A</span>
                  </div>
                  <div
                    v-else-if="props.column.field && props.column.field === TYPES.LOCATION.toString() && props.row[props.column.alias]"
                  >
                    {{ Number(props.row[props.column.alias].latitude) }} {{
                      Number(props.row[props.column.alias].longitude)
                    }}
                  </div>
                  <div
                    v-else-if="props.column.field && props.column.field === TYPES.JSON.toString()"
                    class="entry-listing__json"
                  >
                    <div v-if="props.row[props.column.alias]">
                      <div
                        v-for="key in generateJsonKeys(props.row[props.column.alias]).items"
                        :key="key"
                        class="entry-listing__json-keys"
                      >
                        <span>{{ key }}</span>
                      </div>
                      <span
                        v-if="generateJsonKeys(props.row[props.column.alias]).total"
                        class="entry-field__count"
                      >
                        {{ generateJsonKeys(props.row[props.column.alias]).total }}+
                      </span>
                    </div>
                    <span v-else>N/A</span>
                  </div>
                  <div
                    v-else-if="props.column.alias === 'labels'"
                    class="labels__field"
                  >
                    <div
                      v-if="props.row.system.labels && props.row.system.labels.length > 0"
                      class="labels__field-container"
                    >
                      <div class="entry-listing__labels">
                        <div class="entry-listing__labels-data">
                          <div
                            class="entry-listing__labels-color"
                            :style="`background-color: ${props.row.system.labels[0].color.length > 15 ? '#a9a9a9' : props.row.system.labels[0].color};`"
                          />
                          <small class="entry-listing__labels-name">
                            {{ props.row.system.labels[0].name }}
                          </small>
                        </div>
                      </div>
                      <span
                        v-if="props.row.system.labels.length > 1"
                        :id="`entry-listing__${props.row.id}`"
                        class="entry-field__count"
                      >
                        {{ props.row.system.labels.length - 1 }}+
                      </span>
                      <b-tooltip
                        :target="`entry-listing__${props.row.id}`"
                        placement="top"
                        custom-class="custom-tooltip"
                      >
                        <div
                          v-for="label in props.row.system.labels.slice(1,props.row.system.labels.length)"
                          :key="label.name"
                          class="entry-listing__labels-data"
                        >
                          <div
                            :style="`background-color: ${label.color.length > 15 ? '#a9a9a9' : label.color}; padding: 4px; width: 8px; border-radius: 50%;`"
                            class="entry-listing__labels-color"
                          />
                          <small
                            class="entry-listing__labels-name"
                          >
                            {{ label.name }}
                          </small>
                        </div>
                      </b-tooltip>
                    </div>
                  </div>
                  <div v-else>
                    {{ props.row[props.column.alias] }}
                  </div>
                </div>
              </template>
            </vue-good-table>
            <LoadingSpinner v-if="$apollo.loading || loadingColumns" />
          </div>
          <div
            v-if="!showSelected"
            slot="table-actions-bottom"
          >
            <div class="entries-listing__footer">
              <div class="codex-pagination">
                <b-pagination
                  v-show="total"
                  v-model="view.variables.page"
                  :total-rows="(total > 10000 ? 10000 : total) || Number.MAX_SAFE_INTEGER"
                  :per-page="view.variables.limit"
                  hide-ellipsis
                  limit="3"
                  prev-class="prev-item"
                  next-class="next-item"
                >
                  <template #prev-text>
                    <GjIcon
                      name="ArrowLeft"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <GjIcon
                      name="ArrowRight"
                      size="18"
                    />
                  </template>
                </b-pagination>
                <div class="codex-pagination__text">
                  <span>
                    {{
                      $t('general.pagination', {
                        from: from,
                        to: to > 10000 ? 10000 : to,
                        totalItems: total
                      })
                    }}
                  </span>
                  <b-dropdown
                    variant="none"
                    no-caret
                    class="codex-pagination__select"
                  >
                    <template #button-content>
                      <span class="codex-pagination__select">
                        {{ view.variables.limit }}
                        <GjIcon
                          size="16px"
                          name="ArrowDown_fill"
                        />
                      </span>
                    </template>
                    <b-dropdown-item
                      v-for="limit in pageLimitOptions"
                      :key="limit"
                      class="codex-pagination__select-item"
                      :class="{'codex-pagination__select-item--active': limit === view.variables.limit}"
                      @click="view.variables.limit = limit"
                    >
                      {{ limit }}
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </div>
            </div>
          </div>
        </div>
        <b-sidebar
          id="filter-sidebar"
          right
          shadow
        >
          <template #header="{hide}">
            <div class="filter-sidebar__header">
              <h4>{{ $t('entries.listing.filter') }}</h4>
              <span @click="hide">
                <GjIcon name="CloseCancelCircle" />
              </span>
            </div>
          </template>
          <div class="entry-field__filters-sidebar">
            <div
              v-if="filterMessage"
              class="entry-field__filters-message"
            >
              <img
                src="../../assets/icons/info.svg"
                class="entry-field__close"
              >
              <span class="entry-field__filters-text">{{ $t('entries.listing.filter-message') }}</span>
              <span @click="filterMessage = false">
                <GjIcon
                  class="entry-field__close"
                  name="CloseCancelCircle"
                />
              </span>
            </div>
            <div class="entry-field__filters-containers">
              <draggable v-model="allColumns">
                <div
                  v-for="column in allColumns.filter(col => col.label !== 'Filter')"
                  :key="column.field + column.label"
                  class="entry-field__filter"
                >
                  <div class="entry-field__column-text">
                    <GjIcon
                      class="entry-field__draggable-icon"
                      name="DragPointsSix"
                    />
                    <span :title="column.label">{{ column.label }}</span>
                  </div>
                  <span
                    v-if="column.field !== 'id'"
                    class="entry-field__draggable-remove-container"
                    @click="removeColumn(column)"
                  >
                    <GjIcon
                      name="Close"
                      class="entry-field__draggable-remove"
                    />
                  </span>
                </div>
              </draggable>
            </div>
            <div class="entry-listing__add-column-dropdown">
              <span>{{ $t('entries.listing.new-column-label') }}</span>
              <b-dropdown
                style="background: #FFFFFF;"
                class="add-column__dropdown"
                variant="light"
                no-caret
                lazy
                :text="$t('entries.listing.new-column-button')"
              >
                <b-dropdown-item
                  v-for="(col,index) in sideBarColumnsFiltered"
                  :key="col.alias +index"
                  :title="col.name"
                  @click="addCol(col)"
                >
                  {{ col.name }}
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </div>
        </b-sidebar>

        <div v-show="!isPopup && !isEmbed && selectedRows.length">
          <vue-context
            ref="menu"
            class="border-light shadow rounded"
          >
            <li>
              <span class="articles-context">{{ $t('entries.listing.bulk-context.selected', { count: selectedRows.length }) }}</span>
            </li>
            <li>
              <b-link
                id="at-context-menu-clone-entries-button"
                class="d-flex align-items-center"
                @click="bulkCloneSelected"
              >
                <GjIcon
                  name="Layers_fill"
                  size="16"
                />
                <span class="ml-75">
                  {{ $t('entries.listing.bulk-context.clone') }}
                </span>
              </b-link>
            </li>
            <li v-if="canPublishEntries">
              <b-link
                id="at-context-menu-publish-entries-button"
                class="d-flex align-items-center"
                @click="bulkPublishSelected"
              >
                <GjIcon
                  name="Rocket"
                  size="16"
                />
                <span class="ml-75">
                  {{ $t('entries.listing.bulk-context.publish') }}
                </span>
              </b-link>
            </li>
            <li v-if="canRepublishEntries">
              <b-link
                id="at-context-menu-republish-entries-button"
                class="d-flex align-items-center"
                @click="showEntrySaveModal = true"
              >
                <GjIcon
                  name="Rocket"
                  size="16"
                />
                <span class="ml-75">
                  {{ $t('entries.listing.bulk-context.republish') }}
                </span>
              </b-link>
            </li>
            <li v-if="canScheduleEntries">
              <b-link
                id="at-context-menu-schedule-entries-button"
                class="d-flex align-items-center"
                @click="bulkScheduleSelected"
              >
                <GjIcon
                  name="Calendar"
                  size="16"
                />
                <span class="ml-75">
                  {{ $t('entries.listing.bulk-context.schedule') }}
                </span>
              </b-link>
            </li>
            <li v-if="canUnscheduleEntries">
              <b-link
                id="at-context-menu-unschedule-entries-button"
                class="d-flex align-items-center"
                @click="bulkUnscheduleSelected"
              >
                <GjIcon
                  name="Denied"
                  size="16"
                />
                <span class="ml-75">
                  {{ $t('entries.listing.bulk-context.unschedule') }}
                </span>
              </b-link>
            </li>
            <li>
              <b-link
                id="at-context-menu-unschedule-entries-button"
                class="d-flex align-items-center"
                @click="bulkEditLabelsOfSelected"
              >
                <GjIcon
                  name="Edit"
                  size="16"
                />
                <span class="ml-75">
                  {{ $t('entries.listing.bulk-context.edit-label') }}
                </span>
              </b-link>
            </li>
            <li v-if="canDeleteEntries">
              <b-link
                id="at-context-menu-delete-asset-button"
                class="d-flex align-items-center"
                @click="bulkDeleteSelected"
              >
                <GjIcon
                  name="Delete"
                  size="16"
                />
                <span class="ml-75">
                  {{ $t('entries.listing.bulk-context.delete' ) }}
                </span>
              </b-link>
            </li>
            <li>
              <b-link
                id="at-context-menu-deselect-assets-button"
                class="d-flex align-items-center"
                @click="handleRowsDeselect"
              >
                <GjIcon
                  name="Close_fill"
                  size="16"
                />
                <span class="ml-75">
                  {{ $t('entries.listing.bulk-context.deselect') }}
                </span>
              </b-link>
            </li>
          </vue-context>
        </div>
        <RepublishButton
          :key="showEntrySaveModal"
          v-model="showEntrySaveModal"
          @republishDate="bulkRepublishSelected"
        />
      </full-height-layout>
    </div>
  </div>
</template>

<script>
import FullHeightLayout from '@gjirafatech/gjirafa-front/script/full-height-layout.vue'
import NoElements from '@/components/NoElements.vue'
import gql from 'graphql-tag'
import {
  deleteEntry,
  ENTRY_STATUSES,
  ENTRY_STATUSES_STRING,
  BULK_EDIT_OPTIONS,
  readEntry, transformEntriesGraphQL,
  updateEntry,
  editEntriesBulk,
  createScheduleEntryVersion,
} from '@/codex-sdk/entries'

import {
  cloneDeep, merge, debounce, set, capitalize, isEqual, isNil,
} from 'lodash'
import { GenerateUUID, getFieldPermissions, getEntryModel } from '@/utils/helpers'
import Filters from '@/components/filters-dropdown/Filters'
import FiltersVue from '@/components/filters-dropdown/Filters.vue'
import moment from 'moment'
import { VueGoodTable } from 'vue-good-table'
import draggable from 'vuedraggable'
import { mapGetters } from 'vuex'
import VueContext from 'vue-context'
import { updateEntriesView, entriesViewDefaults, readEntriesView } from '@/codex-sdk/entries-views'
import { canEditEntriesView } from '@/codex-permissions/entries-views'
import { checkPermissionAsync } from '@/codex-permissions/config'
import AddToEntriesLists from '@/components/lists/AddToEntriesLists.vue'
import LoadingSpinner from '@/components/LoadingSpinner.vue'
import NoPermission from '@/components/NoPermission.vue'
import { CMI_ORGANIZATIONS, ENTITY_TYPES } from '@/utils/constants'
import { LABEL_ENTITIES } from '@/codex-sdk/labels'
import { readModel } from '@/codex-sdk/models'
import RepublishButton from '@/views/entries/components/RepublishButton.vue'
import StatusChip from './components/StatusChip.vue'
import {
  getFieldParams, TYPES, VALUE_TYPES, getBlocks, BOOLEAN_FIELD_APPEARANCES,
} from '../models/constants'

export default {
  inject: [
    'showUnpublishEntryPopup',
    'showCloneEntryPopup',
    'showBulkCloneEntryPopup',
    'toastNotification',
    'showConfirmDeletePopup',
    'showBulkEditLabelsPopup',
    'showQuickViewEntryPopup',
    'showConfirmPopup',
    'showEntryEditorPopup',
    'doesIconExist',
    'showQuickScheduler',
    'showEntriesViewPopup',
    'showScheduleEntryPopup',
  ],
  components: {
    NoPermission,
    LoadingSpinner,
    NoElements,
    FullHeightLayout,
    StatusChip,
    FiltersVue,
    VueGoodTable,
    VueContext,
    draggable,
    AddToEntriesLists,
    RepublishButton,
  },
  props: {
    isPopup: {
      type: Boolean,
      default: false,
    },
    statuses: {
      type: Array,
      default: () => [],
    },
    excludeIds: {
      type: Array,
      default: () => [],
    },
    models: {
      type: Array,
    },
    _limit: {
      type: Number,
      default: null,
    },
    disableEntryCreation: {
      type: Boolean,
      required: false,
      default: false,
    },
    includeCustomSiteFilter: {
      type: Boolean,
      default: false,
    },
    alwaysShowModelFilter: {
      type: Boolean,
      default: true,
    },
    referenceIds: {
      type: Array,
      default: () => [],
    },
    isEmbed: {
      type: Boolean,
      default: false,
    },
    columns: {
      type: Array,
      default: () => ['created-at', 'created-by', 'status', 'published-at'],
    },
    addOnPublish: {
      type: Boolean,
      default: false,
    },
  },
  apollo: {
    entryCollection: {
      query() {
        return this.entryQuery
      },
      variables() {
        const filters = this.filters.asGraphQL
        if (this.isPopup && this.models?.length) {
          const modelsIds = []
          if (!filters?.system?.modelId) {
            this.models.forEach(m => {
              const model = this.getModel(m)
              if (model) modelsIds.push(model.id)
            })
            if (modelsIds?.length) {
              set(filters, 'system.modelId.in', modelsIds)
            }
          }
        }
        const siteFilter = {}
        if (!this.includeCustomSiteFilter) {
          if (!siteFilter.system) siteFilter.system = {}
          siteFilter.system.siteId = {
            in: [this.view.variables.siteId],
          }
        }
        if (this.referenceIds?.length) {
          if (!siteFilter.system) siteFilter.system = {}
          siteFilter.system.references = {
            id: {
              some: this.referenceIds,
            },
          }
        }
        const currentLimit = Number(this.offset) + Number(this.view.variables.limit)
        return {
          offset: this.offset,
          limit: currentLimit > 10000 ? 10000 - this.offset : Number(this.view.variables.limit),
          siteId: this.view.variables.siteId,
          searchTerm: this.view.variables.searchTerm,
          statuses: this.view.variables.status ? [this.view.variables.status] : this.entryStatuses,
          createdBy: this.view.variables.createdBy,

          where: merge({
            system: {
              createdBy: {
                eq: this.view.variables.createdBy,
              },
            },
            query: this.view.variables.searchTerm,
          }, siteFilter, filters),
        }
      },
      fetchPolicy: 'network-only',
      update(results) {
        const collection = this.selectedOneModel ? results.content[`${this.model.alias}Collection`] : results.entryCollection
        this.total = collection.total
        collection.items.forEach(entry => {
          entry.system.site = this.getSite(entry.system.siteId)
        })
        transformEntriesGraphQL(collection.items, item => {
          if (item?.system?.labels && item?.system?.labels?.length) {
            item.system.labels = this.entryLabels(item)
          }
          return item
        })
        return collection
      },
      skip() {
        return (!this.graphQuery && !this.entryQuery) || !this.filters.ready
      },
    },
  },
  data() {
    let currentPage = 1
    let limit = 10

    if (!this.isPopup) {
      const localStoragePage = this.getPageFromLocalStorage(`entry-page-${this.$route.params.viewId}-${this.$store.state.general.currentSite.id}`)
      currentPage = this.$route.query.page ? Number(this.$route.query.page) : localStoragePage

      const localStorageLimit = this.getLimitFromLocalStorage(`entry-limit-${this.$route.params.viewId}-${this.$store.state.general.currentSite.id}`)
      limit = this.$route.query.limit ? Number(this.$route.query.limit) : localStorageLimit
    }

    const user = JSON.parse(localStorage.getItem('userData') || '{}')
    const defaultViews = [
      {
        id: GenerateUUID.GENERAL(),
        title: this.$t('entries.listing.tabs.my-entries'),
        variables: {
          page: currentPage,
          offset: 0,
          limit,
          status: null,
          siteId: this.$store.state.general.currentSite.id,
          searchTerm: null,
          createdBy: user.id,
        },
      },
      {
        id: GenerateUUID.GENERAL(),
        title: this.$t('entries.listing.tabs.all-entries'),
        variables: {
          page: currentPage,
          offset: 0,
          limit,
          siteId: this.$store.state.general.currentSite.id,
          status: null,
          searchTerm: null,
          createdBy: '',
        },
      },
    ]

    let view = defaultViews[1]
    if (this.$route.params.viewId && this.$route.params.viewId == 'mine' && !this.isPopup) {
      view = defaultViews[0]
    }

    // check query params for search
    if (this.$route.query.s) {
      view.variables.searchTerm = this.$route.query.s
    }

    const isCustomView = this.$route.params.viewId && this.$route.params.viewId !== 'mine' && this.$route.params.viewId !== 'all'
    const filters = new Filters(this.entryFilters, ['s', 'page', 'limit'])
    if (!this.isPopup && !isCustomView && !this.isEmbed) {
      filters.loadFromQuery(this.$route.query).then(addedFiltersCount => {
        if (addedFiltersCount == 0) {
          const filtersFromLocalStorage = this.getFiltersFromLocalStorage(`entry-filters-${this.$route.params.viewId}-${this.$store.state.general.currentSite.id}`)
          if (filtersFromLocalStorage) {
            filters.loadFromQuery(filtersFromLocalStorage)
          }
        }
      })
    }
    if (this.isPopup || this.isEmbed) {
      filters.ready = true
    }

    window.filters = filters

    return {
      searchHandler: debounce(function (currentView, v) {
        if (this.isPopup) {
          currentView.variables.searchTerm = v
          currentView.variables.page = 1
        } else {
          currentView.variables.page = 1
          const { s, ...rest } = this.$route.query
          this.$router.push({ query: { ...rest, ...(v?.length ? { s: v } : {}), page: 1 } }).catch(() => {})
        }
      }, 100),
      canEditEntriesView,
      getEntryModel,
      fieldsToSort: [TYPES.BOOLEAN, TYPES.DATE_TIME, TYPES.TEXT, TYPES.URL, TYPES.INTEGER, TYPES.DECIMAL],
      entryView: entriesViewDefaults(),
      showMobileInput: false,
      ENTRY_STATUSES_STRING,
      ENTRY_STATUSES,
      BULK_EDIT_OPTIONS,
      BOOLEAN_FIELD_APPEARANCES,
      filters,
      pageLimitOptions: [10, 25, 50, 100],
      filtersModel: {},
      queryRetried: false,
      TYPES,
      views: [...defaultViews],
      entryStatuses: Object.values(ENTRY_STATUSES_STRING)
        .filter(f => f !== ENTRY_STATUSES_STRING[ENTRY_STATUSES.DELETED]),
      view: cloneDeep(view),
      total: 0,
      search: '',
      model: null,
      selectedEntries: [],
      selectedRows: [],
      showSelected: false,
      searchSelected: '',
      filterMessage: true,
      entryCollection: [],
      graphQuery: '',
      quickViewEntryId: null,
      showEntrySaveModal: false,
      allColumnsCollections: [
        {
          label: this.$t('entries.listing.columns.entry'),
          field: 'id',
          alias: 'id',
          sortable: true,
          sortFn: sort => `{ system: { title: ${sort} } }`,
          tdClass: 'entries-table__info',
          system: true,
        },
        {
          label: 'Filter',
          system: true,
          field: 'system',
          alias: 'filter',
          sortable: false,
          width: '50px',
          tdClass: 'entries-table__actions',
        },
      ],
      loadingColumns: false,
      order: null,
      isNil,
    }
  },
  computed: {
    ...mapGetters('general', [
      'getModel',
    ]),
    ...mapGetters('sites', [
      'getSite',
    ]),
    hasSelectedEntries() {
      return this.selectedEntries?.length > 0
    },
    allColumns: {
      get() {
        return this.allColumnsCollections
      },
      set(v) {
        this.allColumnsCollections = v
      },
    },
    viewId() {
      return this.$route.params.viewId || 'all'
    },
    staticSidebarColumns() {
      return [
        {
          name: this.$t('entries.listing.columns.created-date'),
          label: this.$t('entries.listing.columns.created-date'),
          system: true,
          alias: 'created-at',
          field: 'system.createdAt',
          sortable: true,
          sortFn: sort => `{ system: { createdAt: ${sort} } }`,
          skip: true,
        },
        {
          name: this.$t('entries.listing.columns.updated-date'),
          label: this.$t('entries.listing.columns.updated-date'),
          system: true,
          alias: 'updated-at',
          field: 'system.updatedAt',
          sortable: true,
          sortFn: sort => `{ system: { updatedAt: ${sort} } }`,
          skip: true,
        },
        {
          name: this.$t('entries.listing.columns.created-by'),
          label: this.$t('entries.listing.columns.created-by'),
          system: true,
          alias: 'created-by',
          sortable: false,
          field: 'system.createdBy.id',
          skip: true,
        },
        {
          name: this.$t('entries.listing.columns.updated-by'),
          label: this.$t('entries.listing.columns.updated-by'),
          system: true,
          alias: 'updated-by',
          sortable: false,
          field: 'system.updatedBy.id',
          skip: true,
        },
        {
          name: this.$t('entries.listing.columns.status'),
          label: this.$t('entries.listing.columns.status'),
          system: true,
          alias: 'status',
          sortable: false,
          field: 'system.status',
          skip: true,
        },
        {
          name: this.$t('entries.listing.columns.publish-date'),
          label: this.$t('entries.listing.columns.publish-date'),
          system: true,
          alias: 'published-at',
          field: 'system.publishedAt',
          sortable: true,
          sortFn: sort => `{ system: { publishedAt: ${sort} } }`,
          skip: true,
        },
        {
          name: this.$t('entries.listing.columns.first-published-at'),
          label: this.$t('entries.listing.columns.first-published-at'),
          system: true,
          alias: 'first-published-at',
          field: 'system.firstPublishedAt',
          sortable: true,
          sortFn: sort => `{ system: { firstPublishedAt: ${sort} } }`,
          skip: true,
        },
        {
          name: this.$t('entries.listing.columns.labels'),
          label: this.$t('entries.listing.columns.labels'),
          system: true,
          alias: 'labels',
          sortable: false,
          field: 'system.labels',
          skip: true,
        },
      ]
    },
    filterSelectedModels() {
      return this.filters.activeFilters?.model?.value?.length > 0 ? this.filters.activeFilters?.model?.value : []
    },
    selectedOneModel() {
      return this.filters.activeFilters?.model?.value?.length === 1 && this.model
    },
    isCustomView() {
      return this.$route.params.viewId && this.$route.params.viewId !== 'mine' && this.$route.params.viewId !== 'all'
    },
    viewHasChanged() {
      const filters = JSON.parse(JSON.stringify(this.filters.getActiveFilters()))
      const entryViewFilters = JSON.parse(JSON.stringify(this.entryView.filters))
      const entryViewColumns = this.entryView?.customizations?.columns

      return (this.filters.ready && !isEqual(filters, entryViewFilters)) || !isEqual(this.allColumns.map(c => c.alias), entryViewColumns)
    },
    columnsHaveChanged() {
      const defaultColumns = ['id', 'created-at', 'created-by', 'status', 'published-at', 'filter']
      return !this.isCustomView && !isEqual(this.allColumns.map(c => c.alias), defaultColumns)
    },
    entryRows() {
      if (this.showSelected) {
        if (this.searchSelected.length) {
          return this.selectedEntries.filter(e => e.system.title && e.system.title.match(new RegExp(this.searchSelected, 'i')))
        }
        return this.selectedEntries
      }
      return this.entryCollection.items
    },
    entryFilters() {
      let extraFields = []

      if (this.filtersModel.fields) {
        const modelAlias = this.filtersModel.alias

        const typeMapping = {
          [`${TYPES.TEXT}-${VALUE_TYPES.SINGLE}`]: 'string',
          [`${TYPES.INTEGER}-${VALUE_TYPES.SINGLE}`]: 'number',
          [`${TYPES.DECIMAL}-${VALUE_TYPES.SINGLE}`]: 'number',
          [`${TYPES.BOOLEAN}-${VALUE_TYPES.SINGLE}`]: 'boolean',
          [`${TYPES.DATE_TIME}-${VALUE_TYPES.SINGLE}`]: 'datetime',
          [`${TYPES.SECTION}-${VALUE_TYPES.SINGLE}`]: 'section',
          [`${TYPES.AUTHOR}-${VALUE_TYPES.SINGLE}`]: 'author',
          [`${TYPES.REFERENCE}-${VALUE_TYPES.SINGLE}`]: 'reference',
          [`${TYPES.MEDIA}-${VALUE_TYPES.SINGLE}`]: 'media',

          [`${TYPES.TEXT}-${VALUE_TYPES.LIST}`]: 'string-list',
          [`${TYPES.INTEGER}-${VALUE_TYPES.LIST}`]: 'number-list',
          [`${TYPES.DECIMAL}-${VALUE_TYPES.LIST}`]: 'number-list',
          [`${TYPES.SECTION}-${VALUE_TYPES.LIST}`]: 'section-list',
          [`${TYPES.AUTHOR}-${VALUE_TYPES.LIST}`]: 'author-list',
          [`${TYPES.REFERENCE}-${VALUE_TYPES.LIST}`]: 'reference-list',
          [`${TYPES.MEDIA}-${VALUE_TYPES.LIST}`]: 'media-list',
          [`${TYPES.TAG}-${VALUE_TYPES.LIST}`]: 'tag-list',
        }

        const filters = this.filtersModel.fields.map(field => {
          const filterType = typeMapping[`${field.type}-${field.valueType}`]
          if (!filterType || field.configuration?.filterable === false) return null

          let append = ''
          if (filterType === 'reference-list') {
            append += '.entryId'
          }
          const f = this.model.fieldObjects.find(b => b.attrs.alias == field.alias)

          return {
            field: f,
            type: filterType,
            name: field.alias,
            label: field.name,
            validations: field.validation,
            graphQLPath: this.selectedOneModel ? `${field.alias}${append}` : `${modelAlias}.${field.alias}${append}`,
          }
        })
          .filter(Boolean)

        if (filters.length) {
          extraFields = [
            {
              type: 'divider',
            },
            {
              type: 'group',
              label: this.filtersModel.name,
              filters,
            },
          ]
        }
      }
      const filters = [{
        type: 'group',
        label: this.$t('entries.listing.filters.system-filters'),
        filters: [
          {
            type: 'model',
            name: 'model',
            label: this.$t('entries.listing.filters.models'),
            graphQLPath: 'system.modelId',
            models: this.models,
            alwaysShow: this.alwaysShowModelFilter,
          },
          {
            type: 'entryStatus',
            name: 'status',
            label: this.$t('entries.listing.filters.status'),
            graphQLPath: 'system.status',
          },
          {
            type: 'user',
            name: 'creator',
            label: this.$t('entries.listing.filters.created-by'),
            graphQLPath: 'system.createdBy',
          },
          {
            type: 'user',
            name: 'updater',
            label: this.$t('entries.listing.filters.updated-by'),
            graphQLPath: 'system.updatedBy',
          },
          {
            type: 'datetime',
            name: 'published',
            label: this.$t('entries.listing.filters.published-at'),
            graphQLPath: 'system.publishedAt',
          },
          {
            type: 'datetime',
            name: 'firstPublishedAt',
            label: this.$t('entries.listing.filters.first-published-at'),
            graphQLPath: 'system.firstPublishedAt',
          },
          {
            type: 'datetime',
            name: 'created',
            label: this.$t('entries.listing.filters.created'),
            graphQLPath: 'system.createdAt',
          },
          {
            type: 'datetime',
            name: 'updated',
            label: this.$t('entries.listing.filters.updated'),
            graphQLPath: 'system.updatedAt',
          },
          {
            type: 'labels',
            name: 'labels',
            label: this.$t('entries.listing.filters.labels'),
            graphQLPath: 'system.labels',
            entities: [LABEL_ENTITIES.ALL, LABEL_ENTITIES.ENTRY],
          },
          {
            type: 'scheduledVersions',
            name: 'scheduledVersions',
            label: this.$t('entries.listing.filters.scheduled-versions'),
            graphQLPath: 'system.scheduledVersions',
          },
        ],
      },
      {
        type: 'divider',
      }, {
        type: 'group',
        label: this.$t('entries.listing.filters.codex-filters'),
        filters: [
          {
            type: 'section-list',
            name: 'sections',
            label: this.$t('entries.listing.filters.sections'),
            graphQLPath: 'system.references',
            isReference: true,
            showDropdown: false,
          },
          {
            type: 'tag-list',
            name: 'tags',
            label: this.$t('entries.listing.filters.tags'),
            graphQLPath: 'system.references.id',
            showDropdown: false,
          },
          {
            type: 'author-list',
            name: 'authors',
            label: this.$t('entries.listing.filters.authors'),
            graphQLPath: 'system.references.id',
            showDropdown: false,
          },
          {
            type: 'media-list',
            name: 'assets',
            label: this.$t('entries.listing.filters.assets'),
            graphQLPath: 'system.references',
            showDropdown: false,
          },
          {
            type: 'reference-list',
            name: 'entries',
            label: this.$t('entries.listing.filters.entries'),
            graphQLPath: 'system.references.id',
            showDropdown: false,
          },
        ],
      }, ...extraFields]

      if (this.includeCustomSiteFilter) {
        filters[0].filters.push({
          type: 'site',
          name: 'site',
          label: this.$t('entries.listing.filters.sites'),
          graphQLPath: 'system.siteId',
          alwaysShow: true,
        })
      }

      return filters
    },
    view_: {
      get() {
        return this.view
      },
      set(v) {
        this.view = cloneDeep(v)
      },
    },
    sideBarColumnsFiltered() {
      const sideBarColumns = [...this.staticSidebarColumns]
      const models = this.filters.activeFilters?.model?.value?.length || 0
      if (this.model && models === 1) {
        sideBarColumns.push(...this.model.fields)
      }
      return sideBarColumns.filter(item => !this.allColumns.find(col => col.alias === item.alias))
    },
    to() {
      return this.view.variables.page * this.view.variables.limit > this.total
        ? this.total : this.view.variables.page * this.view.variables.limit
    },
    from() {
      return !this.total ? 0 : (this.view.variables.page - 1) * this.view.variables.limit + 1
    },
    offset() {
      return (this.view.variables.page - 1) * this.view.variables.limit
    },
    searchModels() {
      let models = this.$store.state.general.models
      if (!this.isPopup) {
        models = this.$store.state.general.models.filter(m => !m.configurations?.hideInCreateButton)
      }
      if (this.filterSelectedModels.length > 0) {
        return models.filter(item => this.filterSelectedModels.includes(item.id))
      }
      if (this.models) {
        return models.filter(item => this.models?.includes(item.alias) && item.name.toLowerCase().includes(this.search.toLowerCase()))
      }
      return models.filter(item => item.name.toLowerCase().includes(this.search.toLowerCase()))
    },
    entryQuery() {
      const openingTag = this.selectedOneModel ? `content { ${this.model.alias}Collection` : 'entryCollection'
      const closingTag = this.selectedOneModel ? '}' : ''
      const where = this.selectedOneModel ? `${capitalize(this.model.alias)}Filter` : 'CodexEntryFilter'
      const order = this.order ? `order: ${this.order}` : ''
      return gql`query Entries ($where: ${where}, $offset: Int, $limit: Int) {
        ${openingTag} (offset: $offset, limit: $limit, where: $where, ${order}) {
          items {
            id
            ${this.graphQuery && this.model ? `... on ${this.$options.filters.capitalize(this.model.alias)} { ${this.graphQuery} }` : ''}
            system {
              title
              slug
              status
              publishedAt
              unpublishedAt
              firstPublishedAt
              featuredMedia {
                id
                url(transformation: { width: 70, height: 70, format: THUMBNAIL })
              }
              labels {
                id
                name
                description
                color
              }
              scheduledVersions (where: {
                status: { in: [SCHEDULED] }
              }) {
                items {
                  id
                  versionId
                  publishScheduledDate
                  unpublishScheduledDate
                  versionId
                  createdAt
                }
              }
              siteId
              createdAt
              modelId
              modelAlias
              createdBy {
                id
                email
                firstName
                lastName
                imageUrl
              }
              updatedAt
              updatedBy {
                id
                email
                firstName
                lastName
                imageUrl
              }
            }
          }
          offset
          limit
          total
        }
        ${closingTag}
      }`
    },
    showSiteIcon() {
      const countSiteIds = this.filters?.getActiveFilters()?.site?.value?.length || 0
      return this.includeCustomSiteFilter && countSiteIds > 1
    },
    canPublishEntries() {
      return this.selectedRows.every(({ system }) => system.status === ENTRY_STATUSES_STRING[ENTRY_STATUSES.DRAFT] || system.status === ENTRY_STATUSES_STRING[ENTRY_STATUSES.SCHEDULED] || system.status === ENTRY_STATUSES_STRING[ENTRY_STATUSES.UNPUBLISHED])
    },
    canRepublishEntries() {
      return this.selectedRows.every(({ system }) => system.status === ENTRY_STATUSES_STRING[ENTRY_STATUSES.EDITED])
    },
    canScheduleEntries() {
      return this.selectedRows.every(({ system }) => system.status === ENTRY_STATUSES_STRING[ENTRY_STATUSES.DRAFT] || system.status === ENTRY_STATUSES_STRING[ENTRY_STATUSES.UNPUBLISHED])
    },
    canUnscheduleEntries() {
      return this.selectedRows.every(({ system }) => system.status === ENTRY_STATUSES_STRING[ENTRY_STATUSES.SCHEDULED])
    },
    canDeleteEntries() {
      return this.selectedRows.every(({ system }) => system.status === ENTRY_STATUSES_STRING[ENTRY_STATUSES.DRAFT] || system.status === ENTRY_STATUSES_STRING[ENTRY_STATUSES.UNPUBLISHED])
    },
  },
  watch: {
    'filters.asQueryParams': {
      handler: debounce(function () {
        if (!this.isPopup && !this.isCustomView) {
          this.setFiltersToLocalStorage(`entry-filters-${this.$route.params.viewId}-${this.$store.state.general.currentSite.id}`, this.filters.asQueryParams)

          this.$router.push({
            query: { s: this.$route.query.s, ...this.filters.getQueryParamsObject() },
          }).catch(() => {
          })
        }
      }, 100), // 100ms delay
      deep: true,
    },
    'view.variables.searchTerm': {
      handler(v) {
        this.view.variables.page = 1
        this.searchHandler(this.view, v) // 100ms delay
      },
    },
    'view.variables.page': {
      handler(v) {
        if (!this.isPopup) {
          this.$router.replace({ query: { ...this.$route.query, page: v, limit: this.view.variables.limit } }).catch(() => { })

          this.setPageToLocalStorage(`entry-page-${this.$route.params.viewId}-${this.$store.state.general.currentSite.id}`, v)
        }
      },
    },
    // limit
    'view.variables.limit': {
      handler(v) {
        if (!this.isPopup) {
          this.$router.replace({ query: { ...this.$route.query, limit: v, page: this.view.variables.page } }).catch(() => { })

          this.setLimitToLocalStorage(`entry-limit-${this.$route.params.viewId}-${this.$store.state.general.currentSite.id}`, v)
        }
      },
    },
    'filters.activeFilters': {
      async handler(activeFilters) {
        try {
          this.loadingColumns = true
          if (activeFilters.model && activeFilters.model.value.length === 1) {
            if (activeFilters.model.value[0] !== this.filtersModel.id) {
              const modelId = activeFilters.model.value[0]
              this.$set(this.filtersModel, 'id', modelId)

              const { data: model } = await readModel(modelId)
              const canViewFields = await checkPermissionAsync(null, 'entry', 'view', { model: model?.alias }, true)
              const fields = getFieldPermissions(canViewFields, model)
              const modelFields = model.fields.filter(f => fields[f.alias] == true)
              this.model = {
                ...model, fields: modelFields, fieldObjects: getBlocks(model.blocks, block => block.isField), fieldAttrs: {},
              }
              this.$set(this.filtersModel, 'fields', modelFields)
              this.$set(this.filtersModel, 'alias', model.alias)
              this.$set(this.filtersModel, 'name', model.name)

              await this.generateQueryParams()
            }
          } else {
            this.$set(this.filtersModel, 'id', null)
            this.$set(this.filtersModel, 'fields', null)
            this.$set(this.filtersModel, 'alias', null)
            this.$set(this.filtersModel, 'name', null)
            this.model = null
            this.allColumns = this.allColumns.filter(item => item.system)
          }
        } catch (e) {
          console.log(e)
        } finally {
          this.queryRetried = true
          this.setActiveColumns()
          this.loadingColumns = false
        }
      },
      deep: true,
    },
    'filters.ready': {
      handler: debounce(function (v) {
        if (v && this.filters.getActiveFiltersCount() == 0) {
          this.setActiveColumns()
        }
      }, 100),
    },
    entryFilters: {
      handler(v, oldV) {
        if (JSON.stringify(v) !== JSON.stringify(oldV)) this.filters.setFilters(v)
      },
      deep: true,
      immediate: true,
    },
    allColumns: {
      handler: debounce(function () {
        this.generateQueryParams()
        if (!this.isPopup && !this.isCustomView && !this.isEmbed) {
          this.setColumnsToLocalStorage(`entry-columns-${this.$route.params.viewId}-${this.$store.state.general.currentSite.id}`, this.allColumns.map(c => c.alias))
        }
      }, 250),
      deep: true,
    },
  },
  async beforeMount() {
    this.columns.reverse().forEach(alias => {
      const column = this.staticSidebarColumns.find(c => c.alias === alias)
      if (column) this.allColumnsCollections.splice(1, 0, column)
    })
    await this.generateQueryParams()
  },
  async mounted() {
    if (!this.isPopup && !this.isEmbed) {
      if (this.$route.params.viewId != 'mine' && this.$route.params.viewId != 'all') {
        await this.getEntryView()
      }
      this.$store.dispatch('general/setBreadcrumbItems', [
        {
          text: this.$t('routes.site.pageTitles.entries'),
          to: { name: 'entries' },
          active: true,
        },
      ])
    }

    if (this.includeCustomSiteFilter) {
      this.filters.setActiveFilters({
        site: {
          operator: 'includeOneOf',
          value: [this.$store.state.general.currentSite.id],
        },
      })
    }
    if (this.statuses.length > 0) {
      this.filters.setActiveFilters({
        status: {
          operator: 'includeOneOf',
          value: this.statuses,
        },
      })
    }
    if (this.$route.params.sections) {
      this.filters.setActiveFilters({
        sections: {
          isReference: true,
          operator: 'some',
          value: [this.$route.params.sections],
        },
      })
    }
    this.$root.$on('quickViewChanged', this.quickViewChanged)
  },
  destroyed() {
    this.$root.$off('quickViewChanged', this.quickViewChanged)
  },
  methods: {
    getFieldAttrs(alias) {
      if (this.model) {
        this.model.fieldAttrs[alias] = this.model?.fieldObjects?.find(field => field.attrs.alias === alias)?.attrs || null
      }
      return this.model.fieldAttrs[alias]
    },
    isStatusPublishedOrEdited(status) {
      return ENTRY_STATUSES[status] === ENTRY_STATUSES.PUBLISHED || ENTRY_STATUSES[status] === ENTRY_STATUSES.EDITED
    },
    discardChanges() {
      if (this.entryView.id) {
        this.filters.clearActiveFilters()
        this.filters.setActiveFilters(this.entryView.filters)
      } else {
        this.filters.clearActiveFilters()
        const defaultColumns = ['id', 'created-at', 'created-by', 'status', 'published-at', 'filter']
        this.setColumnsToLocalStorage(`entry-columns-${this.$route.params.viewId}-${this.$store.state.general.currentSite.id}`, defaultColumns)
      }
    },
    queryForPrefilledFields(model) {
      if (this.model && model.alias === this.model.alias) {
        return this.filters.getActiveFilters()
      }
      return null
    },
    entryLabels(row) {
      if (row?.system?.labels?.length) {
        const filteredLabels = this.filters.getActiveFilter('labels')
        if (filteredLabels && filteredLabels.value.length) {
          return row.system.labels.sort((a, b) => {
            const aIndex = filteredLabels.value.includes(a.id) ? 0 : filteredLabels.value.length
            const bIndex = filteredLabels.value.includes(b.id) ? 0 : filteredLabels.value.length
            return aIndex - bIndex
          })
        }
        return row.system.labels
      }
      return []
    },
    handleRowsSelect(e) {
      this.selectedRows = e.selectedRows
      this.$refs.menu.close()
    },
    handleRowsDeselect() {
      this.$refs['entries-listing-table'].unselectAllInternal()
      this.$refs.menu.close()
      this.selectedRows = []
    },
    async bulkCloneSelected() {
      if (this.isPopup) return
      let answer = false
      try {
        const isSameModel = !!this.selectedRows.reduce((a, b) => (a?.system?.modelId !== b?.system?.modelId ? false : b))

        let cloneModel = null
        if (isSameModel) {
          const currentModel = this.selectedRows[0].system.modelId
          answer = true
          const newModel = await this.showBulkCloneEntryPopup({
            modelId: this.selectedRows[0].system.modelId,
          })

          cloneModel = currentModel == newModel ? null : newModel
        } else {
          answer = await this.showConfirmPopup({
            title: this.$t('popups.clone-entries.title'),
            description: this.$t('popups.clone-entries.description'),
            okTitle: this.$t('popups.clone-entries.ok-title'),
            cancelTitle: this.$t('popups.clone-entries.cancel-title'),
          })
        }
        if (!answer || cloneModel === false) return
        answer = true
        await editEntriesBulk({
          ids: this.selectedRows.map(row => row.id),
          bulkEditType: this.BULK_EDIT_OPTIONS.CLONE,
          siteId: this.$store.state.general.currentSite.id,
          cloneAsModel: cloneModel,
        }, {
          successTitle: 'sdk.success-title',
          successMessage: 'sdk.entry.bulk.clone.successful',
          failTitle: 'sdk.entry.bulk.clone.failed',
        })
      } catch (e) {
        console.log(e)
      }
      if (answer) {
        this.refetchEntries()
      }
    },
    async bulkPublishSelected() {
      if (this.isPopup) return
      let confirm = false
      try {
        const entries = this.selectedRows.map(r => r.system.title)

        confirm = await this.showConfirmPopup({
          title: this.$t('entries.listing.confirm-publish.title'),
          description: entries.length > 1 ? this.$t('entries.listing.confirm-publish.description-plural') : this.$t('entries.listing.confirm-publish.description', { title: entries[0] }),
          okTitle: this.$t('entries.listing.confirm-publish.okTitle'),
          cancelTitle: this.$t('entries.listing.confirm-publish.cancelTitle'),
          items: entries,
          leftAlign: entries.length > 1,
        })
        if (!confirm) return
        const selectedIds = this.selectedRows.map(row => row.id)

        await editEntriesBulk({
          ids: selectedIds,
          bulkEditType: this.BULK_EDIT_OPTIONS.CHANGE_STATUS,
          siteId: this.$store.state.general.currentSite.id,
          system: {
            status: this.ENTRY_STATUSES.PUBLISHED,
            publishedAt: moment().toISOString(),
          },
        }, {
          successTitle: 'sdk.success-title',
          successMessage: 'sdk.entry.bulk.publish.successful',
          failTitle: 'sdk.entry.bulk.publish.failed',
        })
      } catch (e) {
        console.log(e)
      }
      if (confirm) {
        this.refetchEntries()
      }
    },
    async bulkRepublishSelected(publishedAt = null) {
      if (this.isPopup) return
      try {
        this.showEntrySaveModal = false

        const selectedIds = this.selectedRows.map(row => row.id)

        await editEntriesBulk({
          siteId: this.$store.state.general.currentSite.id,
          bulkEditType: this.BULK_EDIT_OPTIONS.CHANGE_STATUS,
          ids: selectedIds,
          system: {
            status: ENTRY_STATUSES.PUBLISHED,
            publishedAt,
          },
        }, {
          successTitle: 'sdk.success-title',
          successMessage: 'sdk.entry.bulk.republish.successful',
          failTitle: 'sdk.entry.bulk.republish.failed',
        })
      } catch (e) {
        console.log(e)
      }
      this.refetchEntries()
    },
    async bulkUnscheduleSelected() {
      if (this.isPopup) return
      let confirm = false
      try {
        confirm = await this.showConfirmPopup({
          title: this.$t('entries.listing.confirm-bulk-unpublish.title'),
          description: this.$t('entries.listing.confirm-bulk-unpublish.description-unschedule'),
          okTitle: this.$t('entries.listing.confirm-bulk-unpublish.okTitle'),
          cancelTitle: this.$t('entries.listing.confirm-bulk-unpublish.cancelTitle'),
        })
        if (!confirm) return

        const selectedIds = this.selectedRows.map(row => row.id)

        await editEntriesBulk({
          siteId: this.$store.state.general.currentSite.id,
          bulkEditType: this.BULK_EDIT_OPTIONS.CHANGE_STATUS,
          ids: selectedIds,
          system: {
            status: ENTRY_STATUSES.UNPUBLISHED,
          },
        }, {
          successTitle: 'sdk.success-title',
          successMessage: 'sdk.entry.bulk.unschedule.successful',
          failTitle: 'sdk.entry.bulk.unschedule.failed',
        })
      } catch (e) {
        console.log(e)
      }
      if (confirm) {
        this.refetchEntries()
      }
    },
    async bulkScheduleSelected() {
      if (this.isPopup) return

      try {
        const payload = await this.showQuickScheduler({ })
        if (!payload) return
        const selectedIds = this.selectedRows.map(row => row.id)

        await editEntriesBulk({
          siteId: this.$store.state.general.currentSite.id,
          bulkEditType: this.BULK_EDIT_OPTIONS.CHANGE_STATUS,
          ids: selectedIds,
          system: {
            status: ENTRY_STATUSES.SCHEDULED,
            ...payload,
          },
        }, payload.publishedAt ? {
          successTitle: 'sdk.success-title',
          successMessage: 'sdk.entry.bulk.schedule.successful',
          failTitle: 'sdk.entry.bulk.schedule.failed',
        } : {
          successTitle: 'sdk.success-title',
          successMessage: 'sdk.entry.bulk.unschedule.successful',
          failTitle: 'sdk.entry.bulk.unschedule.failed',
        })

        this.refetchEntries()
      } catch (e) {
        console.log(e)
      }
    },
    async bulkEditLabelsOfSelected() {
      if (this.isPopup) return
      let result = null
      try {
        result = await this.showBulkEditLabelsPopup()
        if (result && result?.labels.length) {
          const selectedIds = this.selectedRows.map(row => row.id)
          const { labelAction, labels } = result

          await editEntriesBulk({
            ids: selectedIds,
            bulkEditType: labelAction,
            siteId: this.$store.state.general.currentSite.id,
            system: {
              labels,
            },
          }, {
            successTitle: 'sdk.success-title',
            successMessage: 'sdk.entry.bulk.edit-labels.successful',
            failTitle: 'sdk.entry.bulk.edit-labels.failed',
          })
        }
      } catch (e) {
        console.log(e)
      }
      if (result) {
        this.refetchEntries()
      }
    },
    async bulkDeleteSelected() {
      if (this.isPopup) return
      let result = null
      try {
        result = await this.showConfirmDeletePopup({
          type: ENTITY_TYPES.ENTRY,
          items: this.selectedRows.map(x => x.system.title),
        })
        if (result) {
          const selectedIds = this.selectedRows.map(row => row.id)
          await editEntriesBulk({
            ids: selectedIds,
            bulkEditType: this.BULK_EDIT_OPTIONS.DELETE,
            siteId: this.$store.state.general.currentSite.id,
          }, {
            successTitle: 'sdk.success-title',
            successMessage: 'sdk.entry.bulk.delete.successful',
            failTitle: 'sdk.entry.bulk.delete.failed',
          })
        }
      } catch (e) {
        console.log(e)
      }
      if (result) {
        this.refetchEntries()
      }
    },
    rowClasses(row) {
      return this.selectedEntries.find(e => e.id == row.id) || this.quickViewEntryId == row.id ? 'popup-row-selected' : ''
    },
    openAddToList(data) {
      this.$nextTick(() => {
        this.$refs[`add-${data.id}`].showDropdown()
      })
    },
    quickViewChanged({ id }) {
      this.quickViewEntryId = id
    },
    setActiveColumns() {
      let customColumns = []
      if (this.isCustomView) {
        customColumns = this.entryView.customizations.columns
      } else {
        const localStorageColumns = this.getColumnsFromLocalStorage(`entry-columns-${this.$route.params.viewId}-${this.$store.state.general.currentSite.id}`)
        if (localStorageColumns) {
          customColumns = localStorageColumns
        }
      }
      if (customColumns.length == 0) return
      const dynamicColumns = this.model?.fields?.map(col => this.generateCol(col)) || []
      const allColumns = [...this.allColumnsCollections, ...this.staticSidebarColumns, ...dynamicColumns]
      const uniqueAliases = new Set(allColumns.map(c => c.alias))
      this.allColumns = customColumns
        .filter(alias => uniqueAliases.has(alias))
        .map(alias => allColumns.find(c => c.alias === alias))
      if (this.entryView?.customizations?.order && this.isCustomView) {
        this.order = this.entryView.customizations.order
      }
    },
    async checkMappings(row) {
      await this.showCloneEntryPopup({ entry: row })
      this.refetchEntries()
    },
    async createView(newView = false) {
      if (this.entryView.id && !newView) {
        await this.updateView()
        return
      }
      const entryView = await this.showEntriesViewPopup({
        entryView: {
          filters: this.filters.getActiveFilters(),
          searchTerm: this.view.variables.searchTerm,
          customizations: {
            columns: this.allColumns.map(c => c.alias),
            order: this.order,
          },
        },
      })
      if (entryView) {
        await this.$store.dispatch('general/addView', { item: entryView })
        if (this.$store.state.general.views.filter(view => view.siteId === this.$store.state.general.currentSite.id).length === 1) {
          await this.$store.dispatch(('sites/fetchSites'))
        } else {
          await this.$store.dispatch('general/updateViewsNavigationItems')
        }

        this.$router.replace({
          params: { viewId: entryView.id },
        })
      }
    },
    async updateView() {
      const updatedView = {
        ...this.entryView,
        filters: this.filters.getActiveFilters(),
        searchTerm: this.view.variables.searchTerm,
        customizations: {
          columns: this.allColumns.map(c => c.alias),
          order: this.order,
        },
      }
      const { data } = await updateEntriesView(updatedView)
      const users = await this.$apollo.query({
        query: gql`
          query Users($users: [String!]!){
            userCollection(where: { id: { in: $users  } }) {
                items {
                   id
                   firstName
                   lastName
                   imageUrl
                }
            }
          }
        `,
        variables: {
          users: [data.createdBy, data.updatedBy],
        },
      })
      data.createdBy = users.data.userCollection.items[0]
      data.updatedBy = users.data.userCollection.items.length > 1 ? users.data.userCollection.items[1] : users.data.userCollection.items[0]
      await this.$store.dispatch('general/updateView', { item: data })
      this.entryView = data
    },
    async getEntryView() {
      const { data } = await readEntriesView(this.$route.params.viewId)
      this.entryView = data
      this.view.variables.searchTerm = this.entryView.searchTerm
      if (Object.keys(data.customizations).length === 0) {
        this.entryView.customizations = { columns: this.allColumns.map(c => c.alias) }
      }
      if (Object.keys(this.entryView.filters).length > 0) {
        this.filters.setActiveFilters(this.entryView.filters)
      } else {
        // ToDo: Handle this better on filters component
        this.filters.ready = true
      }
    },
    showSchedulePopup(row) {
      return row.system.status !== ENTRY_STATUSES_STRING[ENTRY_STATUSES.PUBLISHED] || !CMI_ORGANIZATIONS.includes(this.$store.state.general.currentOrganization.alias)
    },
    async openSchedulePopup(row) {
      try {
        const payload = await this.showQuickScheduler({ entry: row })

        if (payload) {
          await this.schedule({
            modelAlias: row.system.modelAlias,
            siteId: row.system.siteId,
            entryId: row.id,
            publishedAt: payload.publishedAt,
            unpublishedAt: payload.unpublishedAt,
          })

          this.refetchEntries()
        }
      } catch (e) {
        console.log(e)
      }
    },

    /** Page */
    setPageToLocalStorage(key, value) {
      // It should be one key entry-filters for all views, and the json should hold the keys for each view
      let page = localStorage.getItem('entry-page')
      if (page) {
        page = JSON.parse(page)
      } else {
        page = {}
      }

      page[key] = value || 1
      localStorage.setItem('entry-page', JSON.stringify(page))
    },
    getPageFromLocalStorage(key) {
      let page = localStorage.getItem('entry-page')
      if (page) {
        page = JSON.parse(page)
        return page[key] || 1
      }
      return 1
    },

    /** Limit */
    setLimitToLocalStorage(key, value) {
      // It should be one key entry-filters for all views, and the json should hold the keys for each view
      let limit = localStorage.getItem('entry-limit')
      if (limit) {
        limit = JSON.parse(limit)
      } else {
        limit = {}
      }

      limit[key] = value || 10
      localStorage.setItem('entry-limit', JSON.stringify(limit))
    },
    getLimitFromLocalStorage(key) {
      let limit = localStorage.getItem('entry-limit')
      if (limit) {
        limit = JSON.parse(limit)
        return limit[key] || 10
      }
      return 10
    },

    /** Filters */
    setFiltersToLocalStorage(key, value) {
      if (this.$route.params.sections) return
      // It should be one key entry-filters for all views, and the json should hold the keys for each view
      let filters = localStorage.getItem('entry-filters')
      if (filters) {
        filters = JSON.parse(filters)
      } else {
        filters = {}
      }

      filters[key] = value
      localStorage.setItem('entry-filters', JSON.stringify(filters))
    },
    getFiltersFromLocalStorage(key) {
      if (this.$route.params.sections) return null
      let filters = localStorage.getItem('entry-filters')
      if (filters) {
        filters = JSON.parse(filters)
        return filters[key]
      }
      return null
    },

    /** Columns */
    setColumnsToLocalStorage(key, value) {
      let columns = localStorage.getItem('entry-columns')
      if (columns) {
        columns = JSON.parse(columns)
      } else {
        columns = {}
      }
      columns[key] = value
      localStorage.setItem('entry-columns', JSON.stringify(columns))
    },
    getColumnsFromLocalStorage(key) {
      let columns = localStorage.getItem('entry-columns')
      if (columns) {
        columns = JSON.parse(columns)
        return columns[key]
      }
      return null
    },
    isExcluded(id) {
      if (!this.isPopup) return false
      return this.excludeIds.includes(id)
    },
    async createEntry(model) {
      const { action, entry } = await this.showEntryEditorPopup({
        model: model.id,
        id: 'create',
        filterValues: this.queryForPrefilledFields(model),
      })
      if ((action == 'created' || action == 'updated') && entry) {
        setTimeout(async () => {
          await this.$apollo.queries.entryCollection.refetch()
          const newEntry = this.entryCollection?.items?.find(e => e.id === entry.id)

          if (newEntry) {
            this.selectionChanged({ row: newEntry })
            if (this.addOnPublish && action == 'created' && this.selectedEntries.length == 1 && newEntry.system.status == ENTRY_STATUSES_STRING[ENTRY_STATUSES.PUBLISHED]) {
              this.$emit('proceedSelect')
            }
          }
        }, 1000)
      }
    },
    async editEntry(entity) {
      try {
        await this.showEntryEditorPopup({
          model: entity.system.modelId,
          id: entity.id,
        })
      } catch (e) {
        console.log(e)
      }
      this.refetchEntries()
    },
    onSortChange(data) {
      const filter = data[0]
      const column = this.allColumns.find(c => c.field == filter.field)
      if (this.showSelected) {
        const [system, col] = filter.field.split('.')
        this.selectedEntries.sort((a, b) => {
          if (!moment(a[system][col]).isValid()) return 1
          if (!moment(b[system][col]).isValid()) return -1
          if (moment(a[system][col]).isBefore(moment(b[system][col]))) return filter.type == 'asc' ? -1 : 1
          if (moment(a[system][col]).isAfter(moment(b[system][col]))) return filter.type == 'asc' ? 1 : -1
          return 0
        })
      } else if (filter.type == 'none') {
        this.order = null
      } else {
        this.order = column.sortFn(filter.type.toUpperCase())
      }
    },
    selectionChanged({ row }) {
      let v = []
      if (!this.selectedEntries.some(e => e.id === row.id) && !this.isExcluded(row.id)) {
        v = [...this.selectedEntries, row]
      } else {
        v = this.selectedEntries.filter(entry => entry.id !== row.id)
      }

      if (v.length <= this._limit) {
        this.selectedEntries = v
      } else if (this._limit == 1 && v.length == 2) {
        this.selectedEntries = [v[1]]
      }

      if (this.selectedEntries.length == 0) {
        this.showSelected = false
      }

      this.$emit('selectedEntries', this.selectedEntries)
    },
    getDomain(url) {
      if (!url) return ''
      let domain = this.$store?.state?.general?.currentSite?.domain
      if (domain && !domain.startsWith('http')) {
        domain = `https://${domain}`
      }
      return `${domain}${url}`
    },
    async generateQueryParams() {
      const models = this.filters.activeFilters?.model?.value?.length || 0
      if (!this.model && models !== 1) return
      let query = ''
      if (this.model) {
        this.model.fields.forEach(field => {
          if (this.allColumns.some(col => col.alias === field.alias)) {
            query += `${getFieldParams(field.alias, field.type, field.valueType)} `
          }
        })
      }
      this.graphQuery = query
    },
    addCol(col) {
      const exist = this.allColumns.find(elm => elm.label === col.name)
      if (!exist) {
        this.allColumns.splice(this.allColumns.length - 1, 0, this.generateCol(col))
      }
    },
    removeColumn(column) {
      this.allColumns = this.allColumns.filter(item => item.alias !== column.alias)
    },
    generateCol(col) {
      const shouldSort = this.fieldsToSort.includes(col.type) && col.valueType === VALUE_TYPES.SINGLE
      if (col?.skip) {
        return {
          label: col.name,
          field: col.field,
          alias: col.alias,
          sortFn: col.sortFn,
          sortable: col.sortable || false,
        }
      }
      return {
        label: col.name,
        field: `${col.type}`,
        alias: col.alias,
        sortable: shouldSort,
        sortFn: sort => `{${col.alias}:  ${sort} }`,
      }
    },
    generateRichContent(content) {
      let returnable = ''
      content.forEach(item => {
        returnable += item.contentHTML ? item.contentHTML : ' '
      })
      return returnable
    },
    generateJsonKeys(JSON) {
      const keys = Object.keys(JSON)
      if (keys && keys.length > 3) {
        return {
          items: keys.slice(0, 2),
          total: keys.length - 3,
        }
      }
      return { items: keys }
    },
    refetchEntries() {
      setTimeout(() => {
        this.$apollo.queries.entryCollection.refetch()
      }, 1000)
    },
    async publish(entry) {
      try {
        const confirm = await this.showConfirmPopup({
          title: this.$t('entries.listing.confirm-publish.title'),
          description: this.$t('entries.listing.confirm-publish.description', { title: entry.system.title }),
          okTitle: this.$t('entries.listing.confirm-publish.okTitle'),
          cancelTitle: this.$t('entries.listing.confirm-publish.cancelTitle'),
        })
        if (!confirm) return
        const { data } = await readEntry({
          id: entry.id,
          model: entry.system.modelAlias,
        })

        data.system.status = ENTRY_STATUSES.PUBLISHED
        data.system.publishedAt = moment()
        await updateEntry(data, false, false, { successMessage: this.$t('entries.message-entry-published') })
        this.refetchEntries()
      } catch (e) {
        console.log(e)
      }
    },
    async schedule(payload = {
      entryId: null, modelAlias: null, siteId: null, publishedAt: null, unpublishedAt: null,
    }) {
      try {
        const { data: entry } = await readEntry({
          id: payload.entryId,
          model: payload.modelAlias,
          siteId: payload.siteId,
        })

        await createScheduleEntryVersion({
          entryId: entry.id,
          model: entry.system.modelAlias,
          versionId: entry.system.versionId,
          siteId: entry.system.siteId,
          publishScheduledDate: payload.publishedAt,
          unpublishScheduledDate: payload.unpublishedAt,
          publishReferences: false,
        })
      } catch (e) {
        console.log(e)
      }
    },
    async unpublish(entry) {
      try {
        const res = await this.showUnpublishEntryPopup({
          entry,
        })
        if (!res) return
        const { data } = await readEntry({
          id: entry.id,
          model: entry.system.modelAlias,
        })
        data.system.status = ENTRY_STATUSES.UNPUBLISHED
        await updateEntry(data, false, false, { successMessage: this.$t('entries.message-entry-unpublished') })
        this.refetchEntries()
      } catch (e) {
        console.log(e)
      }
    },
    async showQuickView(entry) {
      await this.showQuickViewEntryPopup({
        entry,
        modelAlias: entry.system.modelAlias,
        entries: !this.isPopup ? this.entryRows : [],
      })
    },
    fullName(user) {
      if (!user) return ''
      return `${user.firstName || ''} ${user.lastName || ''}`.trim()
    },
    showPublishDate(entry) {
      return ENTRY_STATUSES[entry.system.status] === ENTRY_STATUSES.PUBLISHED || ENTRY_STATUSES[entry.system.status] === ENTRY_STATUSES.SCHEDULED
    },
    showPublishButton(entry) {
      return ENTRY_STATUSES[entry.system.status] !== ENTRY_STATUSES.PUBLISHED
    },
    isPublishedOrEdited(entry) {
      return ENTRY_STATUSES[entry.system.status] === ENTRY_STATUSES.PUBLISHED || ENTRY_STATUSES[entry.system.status] === ENTRY_STATUSES.EDITED || entry.system.status === ENTRY_STATUSES_STRING[ENTRY_STATUSES.PUBLISHED] || entry.system.status === ENTRY_STATUSES_STRING[ENTRY_STATUSES.EDITED]
    },
    async deleteEntry(entry) {
      try {
        const result = await this.showConfirmDeletePopup({
          items: [entry.system.title],
          type: ENTITY_TYPES.ENTRY,
        })
        if (result) {
          await deleteEntry(entry)
          this.refetchEntries()
        }
      } catch (e) {
        console.log(e)
      }
    },
  },
  beforeRouteLeave(from, to, next) {
    this.$store.dispatch('general/setBreadcrumbItems', [])
    next()
  },
}
</script>
