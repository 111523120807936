import { permissions as allPermissions, addCanFunction } from '@/codex-permissions/config'
import { readAccessControl } from '@/codex-sdk/teams'
import { Ability } from '@casl/ability'
import { cloneDeep } from 'lodash'
import { initialAbility } from './config'

//  Read ability from localStorage
// * Handles auto fetching previous abilities if already logged in user
// ? You can update this if you store user abilities to more secure place
// ! Anyone can update localStorage so be careful and please update this
const userData = JSON.parse(localStorage.getItem('userData'))
const existingAbility = userData ? userData.ability : null

const ability = new Ability(existingAbility || initialAbility)

export async function permissionsToAbilities(data) {
  const { data: permissionData } = await readAccessControl()

  const addedPermissions = {}

  const aclAbilities = [{
    action: 'manage',
    subject: 'all',
  }]

  Object.values(data.permissions).flat().forEach(permission => {
    if (permission.permissions) {
      // Add nested permissions - Plugin permissions
      permission.permissions.forEach(p => {
        const actionName = `${permission.pluginName}_${p.id}`
        aclAbilities.push({
          action: actionName,
          inverted: !(p.effect === 'Allow'),
          pluginName: permission.pluginName,
        })
        addedPermissions[actionName] = true
      })
    } else {
      aclAbilities.push({
        action: permission.id,
        inverted: !(permission.effect === 'Allow'),
      })
      addedPermissions[permission.id] = true
    }
  })

  // Add missing permissions, prmsns is a object with values as arrays
  Object.values(permissionData).flat().forEach(permission => {
    if (permission.permissions) {
      // Add nested permissions - Plugin permissions
      permission.permissions.forEach(p => {
        const actionName = `${permission.pluginName}_${p.id}`
        if (!addedPermissions[actionName]) {
          aclAbilities.push({
            action: actionName,
            inverted: !p.default,
            pluginName: permission.pluginName,
          })
        }
      })
      return
    }
    if (!addedPermissions[permission.id]) {
      aclAbilities.push({
        action: permission.id,
        inverted: !permission.default,
      })
    }
  })

  return aclAbilities
}

export function setAbilities(aclAbilities) {
  // Merge defaults for non-existing abilities
  Object.keys(allPermissions).forEach(permission => {
    const exists = aclAbilities.find(i => i.action == permission)
    if (!exists) {
      aclAbilities.push({ action: permission, inverted: !allPermissions[permission].default })
    } else if (allPermissions[permission].override !== undefined) {
      exists.inverted = !allPermissions[permission].override
    }
  })

  // Add all abilities as raw can functions
  aclAbilities.forEach(a => {
    addCanFunction(a.action)
  })

  ability.update(cloneDeep(aclAbilities))
}

export default ability
