<template>
  <div>
    <FieldConditions
      v-model="validation.required"
      :widget="widget"
      :label="$t('fields.general.required.label')"
      :tooltip="$t('fields.general.required.info')"
      :error-message-label="$t('fields.general.required.error-message')"
    />

    <FieldConditions
      v-model="validation.readOnly"
      :widget="widget"
      :label="$t('fields.general.read-only.label')"
      :show-error-message="false"
    />

    <b-form-group>
      <b-form-checkbox v-model="validation.limitCharacterCount.value">
        <span>
          {{ $t('fields.codex-field-rich-text.validation.character-count') }}
        </span>
      </b-form-checkbox>
    </b-form-group>

    <div v-if="validation.limitCharacterCount.value">
      <b-form-group>
        <v-select
          v-model="validation.limitCharacterCount.rangeOperator"
          :options="options"
          :clearable="false"
          :reduce="e => e.value"
          @input="changeRangeOperator"
        />
      </b-form-group>
      <b-row>
        <b-col v-if="validation.limitCharacterCount.rangeOperator === RANGE_OPERATORS.BETWEEN || validation.limitCharacterCount.rangeOperator === RANGE_OPERATORS.GTE">
          <b-form-group :label="$t('fields.codex-field-rich-text.validation.min')">
            <b-form-input
              v-model.number="validation.limitCharacterCount.min"
              min="0"
              :state="!validationError.isValid ? validationError.isValid : null"
              type="number"
              @keydown="validateNumber($event, validation.limitCharacterCount.min)"
              @input="validationError = checkRangeValidation(validation.limitCharacterCount)"
            />
          </b-form-group>
        </b-col>
        <b-col v-if="validation.limitCharacterCount.rangeOperator === RANGE_OPERATORS.BETWEEN || validation.limitCharacterCount.rangeOperator === RANGE_OPERATORS.LTE">
          <b-form-group :label="$t('fields.codex-field-rich-text.validation.max')">
            <b-form-input
              v-model.number="validation.limitCharacterCount.max"
              min="0"
              :state="!validationError.isValid ? validationError.isValid : null"
              type="number"
              @keydown="validateNumber($event, validation.limitCharacterCount.max)"
              @input="validationError = checkRangeValidation(validation.limitCharacterCount)"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-form-group>
        <b-form-invalid-feedback :state="validationError.isValid">
          {{ $t(validationError.emptyMessage) }}
        </b-form-invalid-feedback>
        <b-form-invalid-feedback :state="validationError.isValid">
          {{ $t(validationError.differenceMessage) }}
        </b-form-invalid-feedback>
      </b-form-group>
      <b-form-group>
        <template #label>
          <label>{{ $t('fields.codex-field-rich-text.validation.custom-error-template') }}</label>
          <span
            id="rich-text__inputs-allowed-tooltip"
            class="codex-field-range-tooltip"
          >
            <GjIcon
              name="Info"
              size="20"
            />
          </span>
        </template>
        <PlaceholdersTooltip
          :validation="validation.limitCharacterCount.rangeOperator"
          :target="'rich-text__inputs-allowed-tooltip'"
        />
        <b-form-input
          v-model="validation.limitCharacterCount.errorTemplate"
          :placeholder="$t('fields.codex-field-rich-text.validation.error-placeholder')"
          type="text"
        />
      </b-form-group>
      <b-form-group :label="$t('fields.codex-field-rich-text.validation.error-message')">
        <b-form-input
          v-model="validation.limitCharacterCount.errorMessage"
          readonly
          type="text"
        />
      </b-form-group>
    </div>
  </div>
</template>
<script>
import { generateComputedPropsFromAttrs } from '@/components/codex-layout-editor/BuilderUtils'
import {
  RANGE_OPERATORS, checkRangeValidation, validateNumber, errorTemplateParser,
} from '@/views/models/constants'
import FieldConditions from '@/components/fields/FieldConditions.vue'
import BaseFieldMixin from '@/components/fields/BaseFieldMixin'
import PlaceholdersTooltip from '../PlaceholdersTooltip.vue'

export default {
  components: { FieldConditions, PlaceholdersTooltip },
  mixins: [BaseFieldMixin],
  data() {
    return {
      RANGE_OPERATORS,
      validateNumber,
      checkRangeValidation,
      validationError: {
        isValid: true,
        emptyMessage: '',
        differenceMessage: '',
      },
    }
  },
  watch: {
    'validation.limitCharacterCount.rangeOperator': function (v) {
      this.validation.limitCharacterCount.errorTemplate = this.predefinedErrorTemplates[v]
    },
    'validation.limitCharacterCount': {
      handler() {
        errorTemplateParser(this.validation.limitCharacterCount)
      },
      deep: true,
    },
  },
  computed: {
    ...generateComputedPropsFromAttrs([
      'validation',
    ]),
    options() {
      return [
        { label: this.$t('fields.codex-field-rich-text.validation.between'), value: RANGE_OPERATORS.BETWEEN },
        { label: this.$t('fields.codex-field-rich-text.validation.at-least'), value: RANGE_OPERATORS.GTE },
        { label: this.$t('fields.codex-field-rich-text.validation.no-more-than'), value: RANGE_OPERATORS.LTE },
      ]
    },
    predefinedErrorTemplates() {
      return {
        [RANGE_OPERATORS.BETWEEN]: this.$t('fields.codex-field-rich-text.validation.number-of-characters-templates.between'),
        [RANGE_OPERATORS.GTE]: this.$t('fields.codex-field-rich-text.validation.number-of-characters-templates.gte'),
        [RANGE_OPERATORS.LTE]: this.$t('fields.codex-field-rich-text.validation.number-of-characters-templates.lte'),
      }
    },
  },
  mounted() {
    this.validationError = checkRangeValidation(this.validation.limitCharacterCount)
  },
  methods: {
    changeRangeOperator() {
      this.validationError = checkRangeValidation(this.validation.limitCharacterCount)
    },
  },
}
</script>
