<template>
  <div class="toastification">
    <div class="d-flex align-items-start">
      <b-avatar
        :variant="variant"
        size="14px"
        class="mr-75 toastification-status-icon flex-shrink-0"
      >
        <GjIcon
          :name="icon"
          size="12"
        />
      </b-avatar>
      <div class="d-flex flex-grow-1">
        <div class="toastification-text-container">
          <h5
            v-if="title"
            class="mb-0 font-weight-bolder toastification-title"
            :class="`text-${variant}`"
            v-text="title"
          />
          <small
            v-if="text"
            class="d-inline-block toastification-text text-body"
            v-html="text"
          />

        </div>
        <span
          class="cursor-pointer toastification-close-icon ml-auto "
          @click="$emit('close-toast')"
        >
          <GjIcon
            v-if="!hideClose"
            name="Close"
            class="text-body"
          />
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { BAvatar } from 'bootstrap-vue'

export default {
  components: {
    BAvatar,
  },
  props: {
    variant: {
      type: String,
      default: 'primary',
    },
    icon: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
    text: {
      type: String,
      default: null,
    },
    hideClose: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss">

.Vue-Toastification__toast {
  width: 223px;
  min-width: unset !important;
  max-width: 223px !important;

  border-radius: 8px;
  box-shadow: 0px 4px 48px -12px rgba(0, 0, 0, 0.24);
}

.toastification {
  border-radius: 8px;
}

.toastification-close-icon,
.toastification-title {
  display: flex;
  align-items: center;
  line-height: unset;

  font-size: 12px;
  margin-bottom: 8px;
}

.toastification-text {
  font-size: 12px;
  color: #667C99;
}

.toastification-text-container {
  position: relative;
  top: -1px;
  word-break: break-word;
}

.toastification-title {
  color: inherit;
}

.toastification__body-text {
  font-size: 12px;
  color: #667C99;
}

.toastification__bold {
  font-weight: 500;
}

.toastification-close-icon {
  height: fit-content;

  margin-bottom: 0;
  margin-left: 16px;
}
</style>
