export default class AutomationBase {
  constructor({
    id = null,
    title = '',
    description = '',
    siteId = '',
    entityType = null,
    eventTypes = [],
    models = [],
    status = 4,
  } = {}) {
    this.id = id
    this.title = title
    this.description = description
    this.siteId = siteId
    this.entityType = entityType || null
    this.eventTypes = eventTypes
    this.models = models
    this.status = status
  }

  getPayload() {
    return {
      ...this,
      entityType: this.entityType ?? 0,
    }
  }
}
