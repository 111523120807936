<template>
  <div class="new-filters-dropdown__filter-body">

    <b-row
      align-v="center"
      no-gutters
    >
      <b-col class="flex-grow">
        <b-input
          v-model="value.value"
          size="sm"
        />
      </b-col>
    </b-row>

  </div>
</template>

<script>
export default {
  props: {
    filter: {
      type: Object,
      required: true,
    },
    filterObject: {
      type: [Object, Function],
      required: true,
    },
    value: {
      type: Object,
      required: true,
    },
  },
  computed: {
  },
}
</script>

<style>

</style>
