import WorkflowBase from './constructors/WorkflowBase'

export default {
  workflows: [],
  workflow: new WorkflowBase(),

  filters: {
    page: 1,
    pageSize: 10,
    totalItems: 10,
    searchTerm: '',
  },
}
