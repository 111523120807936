<template>
  <div
    class="rich-content__embed"
  >
    <component
      :is="resolveEmbedTypeComponent(block.type)"
      :id="`${id}-embed-${block.attrs.blockId}`"
      :attrs="block.attrs"
      :update-attrs="() => {}"
      :select-node="() => {}"
      :selected="true"
    />
  </div>
</template>

<script>
import { onMounted } from '@vue/composition-api'
/* eslint-disable no-unused-vars */
import FacebookEmbed from '@/components/codex-editor/nodes/FacebookEmbed/FacebookEmbed.vue'
import SpotifyPodcast from '@/components/codex-editor/nodes/SpotifyPodcast/SpotifyPodcast.vue'
import VimeoEmbed from '@/components/codex-editor/nodes/VimeoEmbed/VimeoEmbed.vue'
import InstagramEmbed from '@/components/codex-editor/nodes/InstagramEmbed/InstagramEmbed.vue'
import YoutubeEmbed from '@/components/codex-editor/nodes/YouTubeEmbed/YouTubeEmbed.vue'
import FameplayEmbed from '@/components/codex-editor/nodes/FameplayEmbed/FameplayEmbed.vue'
import TiktokEmbed from '@/components/codex-editor/nodes/TikTokEmbed/TikTokEmbed.vue'
import TwitterEmbed from '@/components/codex-editor/nodes/TwitterEmbed/TwitterEmbed.vue'
import GooglePodcast from '@/components/codex-editor/nodes/GooglePodcast/GooglePodcast.vue'
import ApplePodcast from '@/components/codex-editor/nodes/ApplePodcast/ApplePodcast.vue'

export default {
  name: 'EmbedComponent',
  provide() {
    return {
      deleteBlock: () => {},
      showPromptPopup: () => {},
      toastNotification: () => {},
    }
  },
  components: {
    FacebookEmbed,
    SpotifyPodcast,
    VimeoEmbed,
    InstagramEmbed,
    YoutubeEmbed,
    TwitterEmbed,
    TiktokEmbed,
    FameplayEmbed,
    GooglePodcast,
    ApplePodcast,
  },
  props: {
    block: {
      type: [Object],
    },
    id: String,
  },
  setup({ block, id }) {
    onMounted(() => {
      const embedElem = document.getElementById(`${id}-embed-${block.attrs.blockId}`)
      const captionElem = embedElem.querySelector('input[placeholder="Caption"]')
      const embedCaption = block.attrs?.caption

      if (captionElem && block.attrs?.caption) {
        embedElem.setAttribute('value', embedCaption)
        embedElem.setAttribute('disabled', '')
        embedElem.classList.add('merger__disabled-caption')
      } else if (captionElem) {
        captionElem.classList.add('merger__no-caption')
      }
    })

    const resolveEmbedTypeComponent = type => {
      const splitType = type.split('_')

      return splitType.slice(1, splitType.length).join('-')
    }

    return {
      resolveEmbedTypeComponent,
    }
  },
}
</script>

<style lang="scss">
.merger__disabled-caption {
  background: #fff;
  pointer-events: none;
}

.merger__no-caption {
  display: none;
}
</style>
