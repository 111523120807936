import CodexContentEditor from '../../CodexContentEditor'
import FameplayEmbed from './FameplayEmbed.vue'
import FameplayEmbedPanel from './FameplayEmbedPanel.vue'
import { BLOCK_GROUPS } from '../constants'

CodexContentEditor.registerWidget('codex', {
  displayName: 'codex-editor.block-names.fameplay-embed',
  name: 'malltv_embed',
  group: BLOCK_GROUPS.EMBEDS,
  // eslint-disable-next-line global-require
  image: require('../../icons/spotify-podcast/icon.svg'),
  attrs: {
    blockId: {
      default: null,
    },
    src: {
      default: null,
    },
    caption: {
      default: null,
    },
    align: {
      default: 'left',
    },
    width: {
      default: '50%',
    },
  },
  renderEditor: FameplayEmbed,
  renderEditorBar: FameplayEmbedPanel,
})
