<template>
  <div
    v-if="show"
    class="codex-autosave"
  >
    <div
      v-if="status === AUTO_SAVE_STATUSES.SAVING"
      class="codex-autosave__block "
    >
      <img
        src="@/assets/images/icons/union.png"
        alt=""
      >
      <div>

        <p class="codex-autosave__text">
          {{ $t('general.auto-save.saving') }}
        </p>
        <p class="codex-autosave__time">
          {{ formatUpdatedTime | capitalize }}
        </p>
      </div>
    </div>

    <div
      v-else-if="status=== AUTO_SAVE_STATUSES.SAVED"
      class="codex-autosave__block"
    >
      <img
        src="@/assets/images/icons/vector.png"
        alt=""
      >
      <div>
        <p class="codex-autosave__text">
          {{ $t('general.auto-save.saved') }}
        </p>
        <p class="codex-autosave__time">
          {{ formatUpdatedTime | capitalize }}
        </p>
      </div>
    </div>
    <div
      v-else-if="status=== AUTO_SAVE_STATUSES.FAILED"
      class="codex-autosave__block"
      @click="retryFunction"
    >
      <img
        src="@/assets/images/icons/not-saved.png"
        alt=""
      >
      <div>
        <p class="codex-autosave__text">
          {{ $t('general.auto-save.failed') }}
        </p>
        <p
          class="codex-autosave__time"
        >
          {{ $t('general.auto-save.retry') }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>

import moment from 'moment'
import { mapState } from 'vuex'
import { AUTO_SAVE_STATUSES } from '@/utils/constants'

export default {
  name: 'AutoSave',
  data() {
    return {
      AUTO_SAVE_STATUSES,
      rerenderKey: 0,
    }
  },
  computed: {
    ...mapState('autoSave', [
      'show',
      'status',
      'updatedTime',
      'retryFunction',
    ]),
    formatUpdatedTime() {
      // eslint-disable-next-line no-unused-expressions
      this.rerenderKey
      return moment(this.updatedTime).fromNow()
    },
  },
  mounted() {
    setInterval(() => {
      this.rerenderKey++
    }, 1000)
  },
}
</script>
<style scoped lang="scss">

</style>
