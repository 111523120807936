<template>
  <div>
    <b-form-radio-group v-model="appearance">
      <div class="url-field__appearance">
        <div class="url-field__icon-text">
          <GjIcon
            name="Hyperlink"
            class="url-field__icon"
            size="24"
            style="fill: #1D79F2"
          />
          <span class="url-field__text">
            {{ $t('fields.codex-field-url.appearance.url') }}
          </span>
        </div>
        <b-form-radio
          name="radio"
          :value="1"
          class="url-field__radio"
        />
      </div>
    </b-form-radio-group>

    <FieldHelpTextInput
      :widget="widget"
      :update-attrs="updateAttrs"
    />
    <b-form-group>
      <b-form-checkbox v-model="generateOnly">
        {{ $t('fields.codex-field-url.appearance.generate-only') }}
        <GjIcon
          v-b-tooltip.hover="$t('fields.codex-field-url.appearance.generate-only-tooltip')"
          name="Info"
          size="20"
        />
      </b-form-checkbox>
    </b-form-group>

    <FieldConditions
      v-model="hidden"
      :widget="widget"
      :label="$t('fields.general.hidden.label')"
      :show-error-message="false"
      :tooltip="$t('fields.general.hidden.info')"
    />
  </div>
</template>

<script>
import { generateComputedPropsFromAttrs } from '@/components/codex-layout-editor/BuilderUtils'
import FieldHelpTextInput from '@/components/fields/FieldHelpTextInput.vue'
import BaseFieldMixin from '@/components/fields/BaseFieldMixin'
import FieldConditions from '@/components/fields/FieldConditions.vue'

export default {
  name: 'Appearance',
  components: { FieldConditions, FieldHelpTextInput },
  mixins: [BaseFieldMixin],
  computed: {
    ...generateComputedPropsFromAttrs([
      'valueType',
      'appearance',
      'helpText',
      'generateOnly',
      'hidden',
    ]),
  },
}
</script>

<style lang="scss" scoped>
.url-field__appearance {
  width: 100%;
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 4px 8px;
  border: 1px solid #E0E5EB;
  border-radius: 4px;
  margin-bottom: 10px;
}
.url-field__icon-text {
  display: flex;
  flex-direction: row;
}
.url-field__icon {
  box-shadow: 0 4px 8px rgba(163, 176, 194, 0.32);
  border-radius: 3px;
}
.url-field__text {
  margin-top: 0.2rem;
  margin-bottom: 0;
  margin-left: 12px;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #052D61;
}
.url-field__radio {
  width: 16px;
  height: 16px;
  right: 0;
  padding: 0!important;
  margin: 0 !important;
}
</style>
