import gql from 'graphql-tag'
import { apolloProvider } from '@/libs/vue-apollo'
import {
  createSite, updateSite, deleteSite, deleteSites,
} from '../../codex-sdk/sites'
import { generateSiteNavigationItems } from './modulesSites'

export default {
  addSite({ dispatch }, { site, message }) {
    return new Promise((resolve, reject) => {
      createSite(site, message)
        .then(response => {
          // commit('ADD_SITE', Object.assign(site, { id: response.data.id }))

          dispatch('fetchSites')

          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  fetchSites({ commit, dispatch }) {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const { data } = await apolloProvider.defaultClient.query({
            query: gql`
              query OrganizationSites {
                organization {
                  id
                  sites (limit: 1000) {
                    items {
                      id
                      name
                      alias
                      description
                      domain
                      previewDomain
                      logo
                      attrs
                      defaultFolder {
                        id
                        name
                      }
                    }
                  }
                  views(limit: 1000) {
                    items {
                      id
                      name
                      type
                      filters
                      searchTerm
                      siteId
                    }
                  }
                }
              }
            `,
            fetchPolicy: 'network-only',
          })

          const { organization: { sites, views } } = data

          commit('sites/SET_SITES', sites?.items || [], { root: true })

          dispatch('general/removeNavigationItems', { group: 'sites' }, { root: true })

          // Add entries and lists to menu for each site
          if (sites?.items) {
            sites.items.forEach(site => {
              dispatch('general/addNavigationItem', generateSiteNavigationItems(site, views?.items?.filter(view => view.siteId == site.id)?.length > 0), { root: true })
            })
          }
          dispatch('general/updateViewsNavigationItems', { group: 'views' }, { root: true })
          resolve()
        } catch (e) {
          reject(e)
        }
      })()
    })
  },
  updateSite({ dispatch }, { site, message }) {
    return new Promise((resolve, reject) => {
      updateSite(site, message)
        .then(response => {
          // commit('UPDATE_SITE', site)

          dispatch('fetchSites')

          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  setSite({ commit }, { site }) {
    return new Promise(resolve => {
      commit('UPDATE_SITE', site)
      resolve()
    })
  },
  removeSite({ commit, dispatch }, { site, message }) {
    return new Promise((resolve, reject) => {
      deleteSite(site, message)
        .then(response => {
          commit('REMOVE_SITE', site.id)

          dispatch('general/removeNavigationItem', { item: `site-${site.id}`, removeBy: 'name' }, { root: true })

          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  removeSites({ commit }, { siteIds, message }) {
    return new Promise((resolve, reject) => {
      deleteSites(siteIds, message)
        .then(response => {
          commit('REMOVE_SITES', siteIds)
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
}
