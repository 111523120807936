<template>
  <b-modal
    v-model="showPopup"
    :title="$tc(`popups.confirm-delete.title.${type}`, count || 1)"
    :static="true"
    :ok-title="$t('popups.confirm-delete.delete')"
    ok-variant="danger"
    :cancel-title="$t('popups.confirm-delete.cancel')"
    cancel-variant="outline-dark"
    centered
    @ok="proceedDelete"
    @cancel="cancelDelete"
  >
    <div
      v-if="count > 0"
      class="confirm-delete-popup__body"
    >
      <p :class="className">
        {{ $tc(`popups.confirm-delete.options.${type}`, count || 1, { name: data.items[0] }) }}
      </p>
      <ul
        v-if="count > 1"
        class="popup-list"
      >
        <li
          v-for="(section, index) in data.items"
          :key="index"
        >
          {{ section }}
        </li>
      </ul>
    </div>
  </b-modal>
</template>

<script>
import { ENTITY_TYPES } from '@/utils/constants'
import PopupsMixin from '../PopupsMixin'

export default {
  mixins: [PopupsMixin],
  data() {
    return {
      cloneOptions: {
        copySection: false,
        copyTags: false,
      },
    }
  },
  computed: {
    count() {
      return this.data?.items?.length || 0
    },
    className() {
      return this.count > 1 ? 'confirm-delete-popup__title--plurals' : 'confirm-delete-popup__title--singular'
    },
    type() {
      const type = ENTITY_TYPES[this.data?.type?.toUpperCase()]
      return type || ''
    },
  },
  methods: {
    async proceedDelete() {
      this.canClose = false

      try {
        this.closePopup(true)
      } catch (error) {
        // Empty
      }
    },
    cancelDelete() {
      this.closePopup(false)
    },
  },
}
</script>

<style lang="scss">
  .confirm-delete-popup__title--singular, .confirm-delete-popup__title--plural {
    color: #052D61;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 16px;
    line-height: 20px;
    margin: 0;
    overflow-wrap: break-word;
  }
  .popup-list {
    padding: 8px 0 0;
    overflow-y: auto;
    margin: 8px 0 0;
    max-height: calc(100vh - 300px);

    li {
      list-style-position: inside;
      padding: 0;
      margin: 0;
    }
  }
  .confirm-delete-popup__body {
    padding: 0;
  }
  .asdasd {
    font-weight: bold;
  }

</style>
