import { checkPermission as _checkPermission } from '@/codex-permissions/config'
import i18n from '@/libs/i18n'

/**
 * Here we can set the default error and success handlers
 */
const sdkConfig = {
  displayError(title, message) {
    console.log('error')
    console.log(title)
    console.log(message)
  },
  displaySuccess(title, message) {
    console.log('success')
    console.log(title)
    console.log(message)
  },
}

/**
 * Let the user of CodexSDK to set Error and Success handlers
 */
export function setErrorsHandler(fn) {
  sdkConfig.displayError = fn
}
export function setSuccessHandler(fn) {
  sdkConfig.displaySuccess = fn
}

/**
 * From the given response data, check if the request was successful or not
 */
function isSuccess(response, multiple) {
  if (!multiple) return response.status < 300
  return response.reduce((success, { status }) => status < 300, true)
}

/**
 * Get error messages from the given response data
 */
function getErrors(response, multiple) {
  if (!multiple) {
    const errors = [i18n.te(`sdk.error-codes.${response.data.code}`) ? i18n.t(`sdk.error-codes.${response.data.code}`, response.data.attrs) : response.data.message]

    if (response.data.errors) {
      errors.push(Object.values(response.data.errors).map(error => {
        if (error.constructor === String) return error
        return error.map(e => (i18n.te(`sdk.error-codes.${e.code}`) ? i18n.t(`sdk.error-codes.${e.code}`, e.attrs) : e.message)).join(', ')
      }).join(', '))
    }

    return errors.join('<br>')
  }
  return response.reduce((errors, { data }) => [...errors, ...Object.values(data.errors)], []).join('. ')
}

/**
 * Set catch and then callbacks for the given axios promise
 * tc - short for Then/Try and Catch :P
 */
export function tc(promise, {
  successTitle = 'Success',
  successMessage = 'Action successful',
  failTitle = 'Failed',
  catchError = true,
}, multiple = false) {
  return promise.then(response => {
    if (isSuccess(response, multiple)) {
      if (successTitle) sdkConfig.displaySuccess(i18n.t(successTitle), i18n.t(successMessage))
    } else {
      if (failTitle) sdkConfig.displayError(i18n.t(failTitle), getErrors(response.response))
      throw new Error(getErrors(response.response))
    }
    return response
  }).catch(error => {
    if (failTitle) sdkConfig.displayError(i18n.t(failTitle), getErrors(error.response))
    if (catchError) return error
    throw error
  })
}

export async function checkPermission(functionName, options = {}) {
  const permission = await _checkPermission(functionName, {
    showToast: true,
    ...options,
  })

  if (!permission.can) {
    throw new Error('No permission')
  }

  return true
}
