<template>
  <div>
    <FieldConditions
      v-model="validation.required"
      :widget="widget"
      :label="$t('fields.general.required.label')"
      :tooltip="$t('fields.general.required.info')"
      :error-message-label="$t('fields.general.required.error-message')"
    />

    <FieldConditions
      v-model="validation.readOnly"
      :widget="widget"
      :label="$t('fields.general.read-only.label')"
      :show-error-message="false"
    />

    <b-form-group v-if="VALUE_TYPES.LIST === valueType">
      <b-form-checkbox v-model="validation.numberOfProperties.isEnabled">
        <span>
          {{ $t('fields.codex-field-authors.validation.limit.placeholder') }}
        </span>
        <GjIcon
          v-b-tooltip.hover="$t('fields.codex-field-json.validation.limit-info')"
          name="Info"
          size="20"
        />
      </b-form-checkbox>
    </b-form-group>

    <template v-if="validation.numberOfProperties.isEnabled && VALUE_TYPES.LIST === valueType">
      <b-form-group>
        <v-select
          v-model="validation.numberOfProperties.rangeOperator"
          :options="options"
          :reduce="e => e.value"
          :clearable="false"
          @input="changeRangeOperator"
        />
      </b-form-group>
      <b-row>
        <b-col v-if="validation.numberOfProperties.rangeOperator === RANGE_OPERATORS.GTE || validation.numberOfProperties.rangeOperator === RANGE_OPERATORS.BETWEEN">
          <b-form-group :label="$t('fields.codex-field-authors.validation.limit.min')">
            <b-form-input
              v-model.number="validation.numberOfProperties.min"
              :state="!validationError.isValid ? validationError.isValid : null"
              min="0"
              type="number"
              @keydown="validateNumber($event, validation.numberOfProperties.min)"
              @input="validationError = checkRangeValidation(validation.numberOfProperties)"
            />
          </b-form-group>
        </b-col>
        <b-col v-if="validation.numberOfProperties.rangeOperator === RANGE_OPERATORS.LTE || validation.numberOfProperties.rangeOperator === RANGE_OPERATORS.BETWEEN">
          <b-form-group :label="$t('fields.codex-field-authors.validation.limit.max')">
            <b-form-input
              v-model.number="validation.numberOfProperties.max"
              :state="!validationError.isValid ? validationError.isValid : null"
              min="0"
              type="number"
              @keydown="validateNumber($event, validation.numberOfProperties.max)"
              @input="validationError = checkRangeValidation(validation.numberOfProperties)"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-form-group>
        <b-form-invalid-feedback :state="validationError.isValid">
          {{ $t(validationError.emptyMessage) }}
        </b-form-invalid-feedback>
        <b-form-invalid-feedback :state="validationError.isValid">
          {{ $t(validationError.differenceMessage) }}
        </b-form-invalid-feedback>
      </b-form-group>
      <b-form-group>
        <template #label>
          <label>{{ $t('fields.codex-field-authors.validation.limit.custom-error-template') }}</label>
          <span
            id="author__inputs-allowed-tooltip"
            class="codex-author-range-tooltip"
          >
            <GjIcon
              name="Info"
              size="20"
            />
          </span>
        </template>
        <PlaceholdersTooltip
          :validation="validation.numberOfProperties.rangeOperator"
          :target="'author__inputs-allowed-tooltip'"
        />
        <b-form-input
          v-model="validation.numberOfProperties.errorTemplate"
          :placeholder="$t('fields.codex-field-authors.validation.limit.custom-error-message-placeholder')"
          type="text"
        />
      </b-form-group>
      <b-form-group
        :label="$t('fields.codex-field-authors.validation.limit.custom-error-message-label')"
        class="form-group__validation-last-field-margin"
      >
        <b-form-input
          v-model.trim="validation.numberOfProperties.errorMessage"
          :placeholder="$t('fields.codex-field-authors.validation.limit.custom-error-message-placeholder')"
          readonly
          type="text"
        />
      </b-form-group>
    </template>
  </div>
</template>

<script>
import { generateComputedPropsFromAttrs } from '@/components/codex-layout-editor/BuilderUtils'
import {
  RANGE_OPERATORS, checkRangeValidation, validateNumber, VALUE_TYPES, errorTemplateParser,
} from '@/views/models/constants'
import FieldConditions from '@/components/fields/FieldConditions.vue'
import BaseFieldMixin from '@/components/fields/BaseFieldMixin'
import PlaceholdersTooltip from '../PlaceholdersTooltip.vue'

export default {
  name: 'Validations',
  components: { FieldConditions, PlaceholdersTooltip },
  mixins: [BaseFieldMixin],
  data() {
    return {
      RANGE_OPERATORS,
      VALUE_TYPES,
      validateNumber,
      checkRangeValidation,
      validationError: {
        isValid: true,
        emptyMessage: '',
        differenceMessage: '',
      },
    }
  },
  computed: {
    ...generateComputedPropsFromAttrs([
      'validation',
      'valueType',
    ]),
    options() {
      return [
        { label: this.$t('fields.codex-field-authors.validation.limit.between'), value: RANGE_OPERATORS.BETWEEN },
        { label: this.$t('fields.codex-field-authors.validation.limit.at-least'), value: RANGE_OPERATORS.GTE },
        { label: this.$t('fields.codex-field-authors.validation.limit.no-more-than'), value: RANGE_OPERATORS.LTE },
      ]
    },
    predefinedErrorTemplates() {
      return {
        [RANGE_OPERATORS.BETWEEN]: this.$t('fields.codex-field-authors.validation.number-of-authors-templates.between'),
        [RANGE_OPERATORS.GTE]: this.$t('fields.codex-field-authors.validation.number-of-authors-templates.gte'),
        [RANGE_OPERATORS.LTE]: this.$t('fields.codex-field-authors.validation.number-of-authors-templates.lte'),
      }
    },
  },
  watch: {
    'validation.numberOfProperties.rangeOperator': function (v) {
      this.validation.numberOfProperties.errorTemplate = this.predefinedErrorTemplates[v]
    },
    'validation.numberOfProperties': {
      handler() {
        errorTemplateParser(this.validation.numberOfProperties)
      },
      deep: true,
    },
  },
  methods: {
    changeRangeOperator() {
      this.validationError = checkRangeValidation(this.validation.numberOfProperties)
    },
  },
}
</script>
