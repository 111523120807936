import CodexLayoutError from '../CodexLayoutError'
import CodexLayoutTransaction from './Base'

/**
 * Move Block Transaction
 *
 */
export default class MoveBlock extends CodexLayoutTransaction {
  name = 'MoveBlock';

  blockId = null

  newParentBlockId = null

  newIndex = null

  oldParentBlockId = null

  oldIndex = null

  /**
   * @param {CodexLayoutEditor} editor The editor on which to make apply transaction
   * @param {String} blockId Id of the block we want to move
   * @param {String} newParentId Id of the block we want to move it to
   * @param {Int} newIndex The position we want to move the block to in the new parent
   */
  constructor(editor, blockId, newParentId, newIndex) {
    super(editor)

    // From block
    const oldParentBlock = this.editor.findParentBlockById(blockId)

    if (!oldParentBlock) {
      throw new CodexLayoutError(`Block '${blockId}' not found`)
    }

    // To block
    const newParentBlock = this.editor.findBlockById(newParentId)

    if (!newParentBlock) {
      throw new CodexLayoutError(`Block '${blockId}' not found`)
    }

    if (newIndex < 0 || newIndex > newParentBlock.content.length) {
      throw new CodexLayoutError('newIndex out of bounds')
    }

    this.blockId = blockId

    // From
    const oldIndex = oldParentBlock.content.findIndex(block => block.id === blockId)
    this.oldParentBlockId = oldParentBlock.id
    this.oldIndex = oldIndex

    // To
    this.newIndex = newIndex
    this.newParentBlockId = newParentBlock.id
  }

  up() {
    // Get parent block, where we'll remove the block from
    const oldParentBlock = this.editor.findBlockById(this.oldParentBlockId)

    // Find the index of block we want to remove
    const removeIndex = oldParentBlock.content.findIndex(block => block.id === this.blockId)

    // Add block to parents content
    let movedBlock = oldParentBlock.content.splice(removeIndex, 1)

    // Store the removed block so we can restore as needed
    movedBlock = movedBlock[0]

    // Get new parent block, where we'll move block to
    const newParentBlock = this.editor.findBlockById(this.newParentBlockId)

    newParentBlock.content.splice(this.newIndex, 0, movedBlock)
  }

  down() {
    // Get parent block, where we'll remove the block from
    const newParentBlock = this.editor.findBlockById(this.newParentBlockId)

    // Find the index of block we want to remove
    const removeIndex = newParentBlock.content.findIndex(block => block.id === this.blockId)

    // Add block to parents content
    let movedBlock = newParentBlock.content.splice(removeIndex, 1)

    // Store the removed block so we can restore as needed
    movedBlock = movedBlock[0]

    // Get old parent block, where we'll move block to
    const oldParentBlock = this.editor.findBlockById(this.oldParentBlockId)

    oldParentBlock.content.splice(this.oldIndex, 0, movedBlock)
  }
}
