import { FIELD_GROUPS } from '@/codex-sdk/models'
import {
  FIELD_FILTER_OPERATORS, PATTERN_TYPE, TYPES, VALUE_TYPES,
} from '@/views/models/constants'
import { ENTRY_STATUSES } from '@/codex-sdk/entries'
import Initial from './Initial.vue'
import { registerField } from '../../codex-layout-editor/BuilderUtils'
import Render from './Render.vue'

import General from './General.vue'
import Appearance from './Appearance.vue'
import Validations from './Validations.vue'
// import Configuration from './Configuration.vue'

registerField('codex_field', {
  displayName: 'Url',
  name: 'url',
  render: Render,
  renderEditor: Render,
  renderEditorPanel: [
    {
      icon: 'Settings', label: 'fields.codex-field-url.tabs.general', component: General, isGeneral: true,
    },
    { icon: 'Brush', label: 'fields.codex-field-url.tabs.appearance', component: Appearance },
    { icon: 'Tasks', label: 'fields.codex-field-url.tabs.validations', component: Validations },
    // { icon: 'SettingsPreferencesTools', label: 'fields.codex-field-url.tabs.configuration', component: Configuration },
  ],
  renderEditorInitialPanel: [
    { icon: 'Settings', label: 'fields.codex-field-url.tabs.initial', component: Initial },
  ],
  group: FIELD_GROUPS.CODEX,
  icon: 'field-url.png',

  type: TYPES.URL,
  valueType: VALUE_TYPES.SINGLE,
  defaultValue: null,

  attrs: {
    editableIn: {
      default: [ENTRY_STATUSES.DRAFT],
    },
    pattern: {
      default: '/{system.modelAlias}/{id}',
    },
    generateOnly: {
      default: false,
    },
    appearance: {
      default: 1,
    },
    helpText: {
      default: '',
    },
    helpTextDisplay: {
      default: 'tooltip',
    },
    validation: {
      default: {
        required: {
          value: false,
          errorMessage: 'URL is required',
          conditionsEnabled: false,
          conditions: [
            {
              isSystem: false,
              field: '',
              operator: FIELD_FILTER_OPERATORS.EXISTS,
              value: '',
            },
          ],
        },
        readOnly: {
          value: false,
          conditionsEnabled: false,
          conditions: [
            {
              isSystem: false,
              field: '',
              operator: FIELD_FILTER_OPERATORS.EXISTS,
              value: '',
            },
          ],
        },
        matchingRegex: {
          isEnabled: false,
          type: PATTERN_TYPE.CUSTOM,
          patterns: [],
          flags: [],
          errorMessage: 'Pattern does not match',
        },
      },
    },
    configuration: {
      default: {
        searchable: true,
        filterable: true,
      },
    },
    hidden: {
      value: false,
      conditionsEnabled: false,
      conditions: [
        {
          isSystem: false,
          field: '',
          operator: FIELD_FILTER_OPERATORS.EXISTS,
          value: '',
        },
      ],
    },
  },
})

export function getPossibleParams(name, type, valueType) {
  const configs = Object.freeze({
    [TYPES.SECTION]: {
      [VALUE_TYPES.SINGLE]: [`content.${name}.id`, `content.${name}.url`],
      [VALUE_TYPES.LIST]: [`getMain(content.${name}).id`, `getMain(content.${name}).url`],
    },
    [TYPES.AUTHOR]: {
      [VALUE_TYPES.SINGLE]: [`content.${name}.id`, `content.${name}.url`],
      [VALUE_TYPES.LIST]: [`getFirst(content.${name}).id`, `getFirst(content.${name}).url`],
    },
  })
  return configs[type]?.[valueType]
}
