// eslint-disable-next-line no-unused-vars
import ability from '@/libs/acl/ability'
import { getCurrentSiteId } from '@/services/siteService'
import { checkPermissionAsync, pr } from './config'

// For regEx search in VSCode: canViewEntry|canCreateEntry|canEditEntry|canDeleteEntry

// * Create entity file, and can functions
// Import and add them to allCanFunctions object down below
// Add defaults into the permissions array down below
// Implement v-permission directives on neccessary buttons
// Implement permissions on entities sdk functions
// Implement permissions on navigation and route

export const Entry = Object.freeze({
})

export const EntryTooltips = {

}

/**
 * _options
 *    entity = undefined
 *    showToast = false
 */

/**
 * Can CREATE Entries
 * */
export async function canCreateEntry(_options = {}) {
  const { entity, ...options } = _options

  return pr(
    await checkPermissionAsync('create', 'entry', 'create', { model: entity?.alias || entity?.system?.model?.alias || entity?.system?.modelAlias, siteId: entity?.siteId || entity?.system?.siteId || getCurrentSiteId() }),

    'You have no permission to create this entry',

    options,
  )
}

/**
 * Can VIEW Entries
 * */
export async function canViewEntry(_options = {}) {
  const { entity, ...options } = _options

  return pr(
    await checkPermissionAsync(entity.id, 'entry', 'view', { model: entity?.system?.model?.alias || entity?.system?.modelAlias, siteId: entity?.system?.siteId }),

    'You have no permission to view this entry',

    options,
  )
}

/**
 * Can EDIT Entries
 * */
export async function canEditEntry(_options = {}) {
  const { entity, ...options } = _options

  return pr(
    await checkPermissionAsync(entity.id, 'entry', 'editcontent', { model: entity?.system?.model?.alias || entity?.system?.modelAlias, siteId: entity?.system?.siteId, entryLabels: entity?.entryLabels }),

    'You have no permission to edit this entry',

    options,
  )
}

/**
 * Can DELETE Entries
 * */
export async function canDeleteEntry(_options = {}) {
  const { entity, ...options } = _options

  return pr(
    await checkPermissionAsync(entity.id, 'entry', 'delete', { model: entity?.system?.model?.alias || entity?.system?.modelAlias, siteId: entity?.system?.siteId, entryLabels: entity?.entryLabels }),

    'You have no permission to delete this entry',

    options,
  )
}

/**
 * Can PUBLISH Entries
 * */
export async function canPublishEntry(_options = {}) {
  const { entity, ...options } = _options

  return pr(
    await checkPermissionAsync(entity.id, 'entry', 'publish', { model: entity?.system?.model?.alias || entity?.system?.modelAlias, siteId: entity?.system?.siteId, entryLabels: entity?.entryLabels }),

    'You have no permission to publish this entry',

    options,
  )
}

/**
 * Can UNPUBLISH Entries
 * */
export async function canUnpublishEntry(_options = {}) {
  const { entity, ...options } = _options

  return pr(
    await checkPermissionAsync(entity.id, 'entry', 'unpublish', { model: entity?.system?.model?.alias || entity?.system?.modelAlias, siteId: entity?.system?.siteId, entryLabels: entity?.entryLabels }),

    'You have no permission to unpublish this entry',

    options,
  )
}

/**
 * Can EDIT Entry labels
 * */
export async function canEditEntryLabels(_options = {}) {
  const { entity, ...options } = _options

  return pr(
    await checkPermissionAsync(entity.id, 'entry', 'editlabel', { model: entity?.system?.model?.alias || entity?.system?.modelAlias, siteId: entity?.system?.siteId, entryLabels: entity?.entryLabels }),

    'You have no permission to edit the labels of this entry',

    options,
  )
}

/**
 * Can TAKE OVER LOCK Entries
 * */
export async function canTakeOverLockEntry(_options = {}) {
  const { entity, ...options } = _options

  return pr(
    await checkPermissionAsync(entity.id, 'entry', 'takeoverlock', { model: entity?.system?.model?.alias || entity?.system?.modelAlias, siteId: entity?.system?.siteId, entryLabels: entity?.entryLabels }),

    'You have no permission to take over lock of this entry',

    options,
  )
}

/**
 * Can MANAGE Entries Versions
 */
export async function canManageEntryVersions(_options = {}) {
  const { entity, ...options } = _options
  return pr(
    await checkPermissionAsync(entity.id, 'entry', 'manageentryversion', { model: entity?.system?.model?.alias || entity?.system?.modelAlias, siteId: entity?.system?.siteId }),

    'You have no permission to manage versions of entries',

    options,
  )
}

/**
 * Can Add Label to Entry
 * */
export async function canAddEntryLabel(_options = {}) {
  const { entity, ...options } = _options

  return pr(
    await checkPermissionAsync(entity.id, 'entry', 'addlabel', {
      ...entity,
    }),

    'You have no permission to add this label to the entry',

    options,
  )
}

/**
 * Can Remove Label to Entry
 * */
export async function canRemoveEntryLabel(_options = {}) {
  const { entity, ...options } = _options

  return pr(
    await checkPermissionAsync(entity.id, 'entry', 'removelabel', {
      ...entity,
    }),

    'You have no permission to remove this label from the entry',

    options,
  )
}
