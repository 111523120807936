import { tc } from '@/codex-sdk/config'
import { apiAxios as axios } from '../libs/axios'

export const URL_ENTITY_TYPES = Object.freeze({
  ENTRY: 1,
  SECTION: 2,
  AUTHOR: 3,
  REDIRECT: 4,
})

export const URL_ENTITY_TYPES_STRING = Object.freeze({
  ENTRY: 'ENTRY',
  SECTION: 'SECTION',
  AUTHOR: 'AUTHOR',
  REDIRECT: 'REDIRECT',
})

export const URL_REDIRECT_STATUS_CODE = Object.freeze({
  PERMANENT: 301,
  TEMPORARY: 302,
  GONE: 410,
})

export const URL_REDIRECT_STATUS_CODE_STRING = Object.freeze({
  PERMANENT: 'PERMANENT',
  TEMPORARY: 'TEMPORARY',
  GONE: 'GONE',
})

export function mapRedirectStatusCode(code) {
  return {
    [URL_REDIRECT_STATUS_CODE.PERMANENT]: 'urls.redirectStatusCodes.permanent',
    [URL_REDIRECT_STATUS_CODE.TEMPORARY]: 'urls.redirectStatusCodes.temporary',
    [URL_REDIRECT_STATUS_CODE.GONE]: 'urls.redirectStatusCodes.gone',
    [URL_REDIRECT_STATUS_CODE_STRING.PERMANENT]: 301,
    [URL_REDIRECT_STATUS_CODE_STRING.TEMPORARY]: 302,
    [URL_REDIRECT_STATUS_CODE_STRING.GONE]: 410,
  }[code] || ''
}

export function mapUrlEntityType(type) {
  return {
    [URL_ENTITY_TYPES.ENTRY]: 'urls.entity-types.entry',
    [URL_ENTITY_TYPES_STRING.ENTRY]: 'urls.entity-types.entry',
    [URL_ENTITY_TYPES.SECTION]: 'urls.entity-types.section',
    [URL_ENTITY_TYPES_STRING.SECTION]: 'urls.entity-types.section',
    [URL_ENTITY_TYPES.AUTHOR]: 'urls.entity-types.author',
    [URL_ENTITY_TYPES_STRING.AUTHOR]: 'urls.entity-types.author',
    [URL_ENTITY_TYPES.REDIRECT]: 'urls.entity-types.redirect',
    [URL_ENTITY_TYPES_STRING.REDIRECT]: 'urls.entity-types.redirect',
  }[type] || ''
}

export function urlDefaults(url = {}) {
  return {
    id: null,
    entityType: URL_ENTITY_TYPES.ENTRY,
    model: null,
    entityId: null,
    url: '/',
    statusCode: URL_REDIRECT_STATUS_CODE.PERMANENT,
    redirectUrl: null,
    ...url,
  }
}

export function transformUrlFromRequest(url) {
  return url
}

export function readUrl(id) {
  return axios.get(`/{organization}/urls/${id}`)
    .then(response => {
      response.data = transformUrlFromRequest(response.data)
      return response
    })
}

export function createUrl(url, messages = {}) {
  return tc(axios.post('/{organization}/urls', url), {
    successTitle: 'sdk.success-title',
    successMessage: 'sdk.url.create-successful',
    failTitle: 'sdk.url.create-failed',
    catchError: false,
    ...messages,
  })
}

export function updateUrl(url, messages = {}) {
  return tc(axios.put(`/{organization}/urls/${url.id}`, url), {
    successTitle: 'sdk.success-title',
    successMessage: 'sdk.url.update-successful',
    failTitle: 'sdk.url.update-failed',
    catchError: false,
    ...messages,
  })
}

export function deleteUrl(url, messages = []) {
  return tc(axios.delete(`/{organization}/urls/${url.id}`), {
    successTitle: 'sdk.success-title',
    successMessage: 'sdk.url.delete-successful',
    failTitle: 'sdk.url.delete-failed',
    ...messages,
  })
}
