import { FIELD_GROUPS } from '@/codex-sdk/models'
import {
  CONDITIONAL_SYSTEM_FIELDS,
  FIELD_FILTER_OPERATORS,
  RANGE_OPERATORS,
  TYPES,
  VALUE_TYPES,
} from '@/views/models/constants'
import Initial from './Initial.vue'
import { registerField } from '../../codex-layout-editor/BuilderUtils'
import Render from './Render.vue'

import General from './General.vue'
import Appearance from './Appearance.vue'
import Validations from './Validations.vue'
import Configuration from './Configuration.vue'

registerField('codex_field', {
  displayName: 'Reference',
  name: 'reference',
  render: Render,
  renderEditor: Render,
  renderEditorPanel: [
    {
      icon: 'Settings', label: 'fields.codex-field-reference.tabs.general', component: General, isGeneral: true,
    },
    { icon: 'Brush', label: 'fields.codex-field-reference.tabs.appearance', component: Appearance },
    { icon: 'Tasks', label: 'fields.codex-field-reference.tabs.validations', component: Validations },
    { icon: 'SettingsPreferencesTools', label: 'fields.codex-field-reference.tabs.configuration', component: Configuration },
  ],
  renderEditorInitialPanel: [
    { icon: 'Settings', label: 'fields.codex-field-reference.tabs.initial', component: Initial },
  ],
  group: FIELD_GROUPS.BASIC,
  icon: 'field-reference.png',

  type: TYPES.REFERENCE,
  valueType: VALUE_TYPES.SINGLE,

  attrs: {
    appearance: {
      default: 1,
    },
    helpText: {
      default: '',
    },
    helpTextDisplay: {
      default: 'tooltip',
    },
    validation: {
      default: {
        allowDuplicates: false,
        required: {
          value: false,
          errorMessage: 'Reference is required',
          conditionsEnabled: false,
          conditions: [
            {
              isSystem: true,
              field: CONDITIONAL_SYSTEM_FIELDS.SITE_ID,
              operator: FIELD_FILTER_OPERATORS.EQUALS,
              value: '',
            },
          ],
        },
        readOnly: {
          value: false,
          conditionsEnabled: false,
          conditions: [
            {
              isSystem: false,
              field: '',
              operator: FIELD_FILTER_OPERATORS.EXISTS,
              value: '',
            },
          ],
        },
        numberOfReferences: {
          isEnabled: false,
          rangeOperator: RANGE_OPERATORS.BETWEEN,
          min: 2,
          max: 5,
          exactly: 1,
          errorMessage: 'Number of references: 2-5.',
          errorTemplate: 'Number of references: {min}-{max}',
        },
        modelTypes: {
          isEnabled: false,
          acceptedModels: [],
          errorMessage: 'You can select entries only from specified models.',
        },
      },
    },
    disableCreateButton: {
      default: false,
    },
    disableBrowseButton: {
      default: false,
    },
    configuration: {
      default: {
        searchable: true,
        filterable: true,
      },
    },
    hidden: {
      value: false,
      conditionsEnabled: false,
      conditions: [
        {
          isSystem: false,
          field: '',
          operator: FIELD_FILTER_OPERATORS.EXISTS,
          value: '',
        },
      ],
    },
  },
})
