import { pr } from '@/codex-permissions/config'
import ability from '@/libs/acl/ability'
import { getUserData } from '@/auth/utils'
import { TAG_SOURCES } from '@/codex-sdk/tags'

export const TAG = Object.freeze({
  VIEW: 'tag_view',
  CREATE_CUSTOM: 'tag_create_custom',
  CREATE_SUGGESTED: 'tag_create_suggested',
  MANAGE: 'tag_manage',
})

export const TagTooltips = {
  [TAG.VIEW]: 'Allows users to view tags.',
  [TAG.CREATE_CUSTOM]: 'Allows users to create custom tags.',
  [TAG.CREATE_SUGGESTED]: 'Allows users to create suggested tags.',
  [TAG.MANAGE]: 'Allows users to manage tags.',
}

function isCurrentUser(entity) {
  const userId = entity?.createdBy?.id || entity?.createdBy

  const currentUserData = getUserData()
  if (!currentUserData) return false

  return userId == currentUserData.id
}

/**
 * Can VIEW Tags
 * */
export function canViewTags(_options = {}) {
  const { entity, ...options } = _options

  return pr(
    ability.can(TAG.VIEW) || ability.can(TAG.CREATE_CUSTOM) || ability.can(TAG.CREATE_SUGGESTED) || ability.can(TAG.MANAGE),
    'You have no permission to view tags',

    options,
  )
}

/**
 * Can CREATE Custom Tags
 */
export function canCreateCustomTag(_options = {}) {
  const { entity, ...options } = _options
  return pr(
    ability.can(TAG.CREATE_CUSTOM) || ability.can(TAG.MANAGE),
    'You have no permission to create custom tags',

    options,
  )
}

/**
 * Can CREATE Suggested Tags
 */
export function canCreateSuggestedTag(_options = {}) {
  const { entity, ...options } = _options

  return pr(
    ability.can(TAG.CREATE_SUGGESTED) || ability.can(TAG.MANAGE),
    'You have no permission to create suggested tags',

    options,
  )
}

/**
 * Can EDIT Tags
 */
export function canEditTag(_options = {}) {
  const { entity, ...options } = _options

  return pr(
    ability.can(TAG.MANAGE)
      || (ability.can(TAG.CREATE_CUSTOM) && isCurrentUser(entity) && entity.source === TAG_SOURCES.CODEX)
      || (ability.can(TAG.CREATE_SUGGESTED) && isCurrentUser(entity) && entity.source !== TAG_SOURCES.CODEX),
    'You have no permission to edit this tag',

    options,
  )
}

/**
 * Can DELETE Tags
 */
export function canDeleteTag(_options = {}) {
  const { entity, ...options } = _options

  return pr(
    ability.can(TAG.MANAGE)
      || (ability.can(TAG.CREATE_CUSTOM) && isCurrentUser(entity) && entity.source === TAG_SOURCES.CODEX)
      || (ability.can(TAG.CREATE_SUGGESTED) && isCurrentUser(entity) && entity.source !== TAG_SOURCES.CODEX),

    'You have no permission to delete this tag',

    options,
  )
}

/**
 * Can MERGE Tags
 */
export function canMergeTag(_options = {}) {
  const { entity, ...options } = _options

  return pr(
    ability.can(TAG.MANAGE),
    'You have no permission to merge this tag',

    options,
  )
}
