<template>
  <div
    class="overlay"
    style="pointer-events: all;"
  >
    <div class="overlay__container">
      <div class="overlay__container-left">
        <div class="curtain">
          <div class="curtain__header">
            <div class="curtain__header-left">
              <span class="icon-text">
                <span
                  v-if="asset"
                  class="ml-2 curtain__edit-title"
                >
                  {{ asset.title || asset.fileName || '' }}
                </span>
              </span>
            </div>
            <div
              v-if="asset"
              class="curtain__header-right"
            >
              <span
                v-if="asset.type === ASSET_TYPES.IMAGE || asset.type === ASSET_TYPES.FILE"
                id="at-media-download-button"
                class="white-icon ml-2"
                @click="downloadAsset(asset)"
              >
                <GjIcon
                  size="24"
                  name="Download"
                />
              </span>
              <GjIcon
                size="24"
                name="Close"
                class="white-icon ml-1"
                @click.native="$emit('close')"
              />

            </div>
          </div>
          <div
            v-if="asset && !$apollo.loading"
            class="curtain__image"
          >
            <img
              v-if="asset.type === ASSET_TYPES.IMAGE"
              :src="asset.url"
              class="curtain-image"
            >
            <iframe
              v-else-if="asset.type === ASSET_TYPES.VIDEO || asset.type === ASSET_TYPES.VIDEO_PLAYLIST || asset.type === ASSET_TYPES.AUDIO || asset.type === ASSET_TYPES.PODCAST"
              :src="asset.url"
              class="curtain-image"
              :style="height"
              allow="autoplay *; encrypted-media *; fullscreen *"
              frameborder="0"
              sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-storage-access-by-user-activation allow-top-navigation-by-user-activation"
            />
            <div
              v-else
              class="no-preview-available"
            >
              <b-card body-class="no-preview-body">
                <p>
                  {{ $t('assets.no-preview') }}
                </p>

                <b-button
                  v-if="asset.type === ASSET_TYPES.IMAGE || asset.type === ASSET_TYPES.FILE"
                  id="at-media-second-download-button"
                  variant="primary"
                  tag="label"
                  size="sm"
                  @click="downloadAsset(asset)"
                >
                  <GjIcon
                    name="Download"
                    size="24"
                  />
                  <span class="action-title">
                    {{ $t('general.download') }}
                  </span>
                </b-button>
              </b-card>
            </div>
          </div>
          <div
            v-if="$apollo.loading"
            class="curtain__image-loading"
          >
            <LoadingSpinner color="#ffffff" />
          </div>
          <div
            v-if="!asset && !$apollo.loading"
            class="curtain__image-404"
          >
            <div class="curtain__image-404-icon">
              <GjIcon name="IconparkFileFailedOne" />
            </div>
            <div>
              <p class="curtain__image-404-title">
                {{ $t('assets.not-found') }}
              </p>
              <p class="curtain__image-404-subtitle">
                {{ $t('assets.not-found-description') }}
              </p>
            </div>
          </div>
          <div class="prev__next-arrows">
            <div
              id="at-media-previous-button"
              :class="{'previous-visibility': !previousAsset}"
              class="previous"
              @click="setPreviousImage"
            >
              <GjIcon
                name="ArrowLeft"
                class="white-icon"
                size="32"
              />
            </div>
            <span class="mb-0 image__count">
              <span v-show="currentIndex">
                {{ currentIndex }}/{{ total }}
              </span>
            </span>
            <div
              id="at-media-next-button"
              :class="{'previous-visibility': !nextAsset}"
              class="next"
              @click="setNextImage"
            >
              <GjIcon
                name="ArrowRight"
                class="white-icon"
                size="32"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ASSET_STATUSES, ASSET_TYPES } from '@/codex-sdk/assets'
import { types } from '@/components/TagsSelector.vue'
import gql from 'graphql-tag'
import { downloadAsset } from '@/utils/helpers'
import LoadingSpinner from '@/components/LoadingSpinner.vue'

export default {
  name: 'AssetViewPopup',
  components: { LoadingSpinner },
  props: {
    assetId: {
      type: String,
      default: '',
    },
    assets: {
      type: Array,
      default: () => [],
    },
    total: {
      type: Number,
      default: 0,
    },
  },
  apollo: {
    asset: {
      query: gql`
        query ($id: String!) {
          asset(id: $id) {
            id
            folderId
            title
            fileName
            width
            height
            tags
            source
            size
            url(transformation: {format: HTML, resize: FILL, width: 1280})
            path
            type
            caption
            alt
            author
            copyright
            updatedAt
            expiresAt
            updatedBy {
              id
              email
              firstName
              lastName
              imageUrl
            }
            createdAt
            createdBy {
              id
              email
              firstName
              lastName
              imageUrl
            }
          }
        }
      `,
      variables() {
        return {
          id: this.currentAssetId,
        }
      },
      fetchPolicy: 'no-cache',
      update({ asset }) {
        const isDeleted = this.assets.find(a => a.id === this.currentAssetId).status === ASSET_STATUSES.DELETED
        if (isDeleted) {
          return {
            id: this.currentAssetId,
            status: ASSET_STATUSES.DELETED,
            labels: [],
          }
        }
        return asset
      },
    },
  },
  inject: ['showConfirmPopup', 'showConfirmDeletePopup'],
  data() {
    return {
      downloadAsset,
      ASSET_TYPES,
      currentIndex: null,
      previousAsset: null,
      nextAsset: null,
      fetchCount: 0,
      currentAssetId: this.assetId,
      tagTypes: types,
    }
  },
  computed: {
    height() {
      if (this.asset?.type === ASSET_TYPES.AUDIO) {
        return 'height: 180px;'
      }
      if (this.asset?.type === ASSET_TYPES.PODCAST) {
        return 'height: 410px;'
      }
      return ''
    },
  },
  watch: {
    asset() {
      this.getImageList()
    },
    assets() {
      if (this.asset) {
        this.getImageList()

        this.fetchCount = 0
      }
    },
  },
  beforeMount() {
    window.addEventListener('keydown', this.handleKeydown, true)
  },
  beforeDestroy() {
    window.removeEventListener('keydown', this.handleKeydown, true)
  },
  methods: {
    async getImageList() { // A function used per me tregu qka ka previous edhe qka ka next qikjo foto
      const currentImgIndex = this.assets.findIndex(x => x.id === this.asset.id)

      if (currentImgIndex !== -1) {
        const previousImage = this.assets[currentImgIndex - 1]
        const nextImage = this.assets[currentImgIndex + 1]

        this.currentIndex = currentImgIndex + 1
        this.previousAsset = previousImage || null
        this.nextAsset = nextImage || null

        const assetsLength = this.assets.length
        const length = assetsLength > 10 ? assetsLength - 10 : null

        if (length && currentImgIndex > assetsLength - 10 && currentImgIndex < assetsLength && !this.fetchCount) {
          this.$emit('fetchMoreAssets')

          this.fetchCount = 1
        }
      } else {
        this.currentIndex = null
        this.previousAsset = null
        this.nextAsset = null
      }
    },
    setNextImage() {
      if (this.nextAsset) {
        this.currentAssetId = this.nextAsset.id
        // this.asset = this.nextAsset
      }
    },
    setPreviousImage() {
      if (this.previousAsset) {
        this.currentAssetId = this.previousAsset.id
        // this.asset = this.previousAsset
      }
    },
    isFocusedElementInput() {
      const { activeElement } = document
      const inputs = ['input', 'select', 'button', 'textarea']

      if (activeElement && inputs.indexOf(activeElement.tagName.toLowerCase()) !== -1) {
        return true
      }
      return false
    },
    handleKeydown(e) {
      if (this.isFocusedElementInput()) return

      if (e.keyCode === 37) {
        this.setPreviousImage()
        // Left
      } else if (e.keyCode === 39) {
        this.setNextImage()
        // Right
      } else if (e.keyCode === 27) {
        e.stopPropagation()
        this.$emit('close')
      }
    },
  },
}
</script>
