import { mapUrlEntityType, URL_ENTITY_TYPES } from '@/codex-sdk/urls'
import { mapLabelEntityLabel, LABEL_ENTITIES } from '@/codex-sdk/labels'
import i18n from '@/libs/i18n'
import WebhookEntity from './WebhookEntity.vue'

export default class WebhookEntityFilter {
  static COMPONENT = WebhookEntity;

  static OPERATORS = {
    equals: 'eq',
  }

  static OPTIONS = {
    [URL_ENTITY_TYPES.SECTION]: i18n.t(mapUrlEntityType(URL_ENTITY_TYPES.SECTION)),
    [URL_ENTITY_TYPES.ENTRY]: i18n.t(mapUrlEntityType(URL_ENTITY_TYPES.ENTRY)),
    [LABEL_ENTITIES.ENTRY]: i18n.t(mapLabelEntityLabel(LABEL_ENTITIES.ENTRY)),
    [LABEL_ENTITIES.ASSET]: i18n.t(mapLabelEntityLabel(LABEL_ENTITIES.ASSET)),
    [LABEL_ENTITIES.ALL]: i18n.t(mapLabelEntityLabel(LABEL_ENTITIES.ALL)),
  }

  static type = 'webhookEntity'

  operator = null;

  value = null;

  constructor({ operator = WebhookEntityFilter.OPERATORS.equals, value } = {}) {
    this.operator = operator
    this.value = value
  }

  set({ operator = WebhookEntityFilter.OPERATORS.equals, value }) {
    this.operator = operator
    this.value = value
  }

  getCount() {
    return this.value.length
  }

  getValueLabel() {
    return this.value ? this.value.map(event => (Number.isFinite(event) ? event : WebhookEntityFilter.OPTIONS[event?.toLowerCase()])).join(', ') : null
  }

  toLoad() {
    return {
      assets: this.value,
    }
  }

  asQueryParam() {
    return {
      operator: this.operator,
      value: this.value,
    }
  }

  asGraphQL() {
    return this.value
  }

  static validate({ value }) {
    if (!value || value.length === 0) {
      return 'Value is required'
    }

    return true
  }

  static fromQueryParam({ operator, value }) {
    return new WebhookEntityFilter({
      operator,
      value,
    })
  }
}
