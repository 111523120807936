<template>
  <div
    v-if="blockComments.length > 0"
    :class="baseClass"
  >
    <div
      class="comments-bubble__toggle"
      @click="toggleComments"
    >
      <svg
        class="comments-bubble__toggle-icon"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1 5.45286C1 3.54589 2.54529 2 4.45149 2H19.5485C21.4547 2 23 3.5459 23 5.45286V14.723C23 16.63 21.4547 18.1759 19.5485 18.1759H16.2673C15.6727 18.1759 15.114 18.4605 14.7642 18.9415L12.8589 21.5625C12.4348 22.1458 11.5652 22.1458 11.1411 21.5625L9.23576 18.9415C8.88603 18.4605 8.32733 18.1759 7.73273 18.1759H4.45149C2.54528 18.1759 1 16.63 1 14.723V5.45286Z"
          fill="#1D79F2"
        />
      </svg>
      <div
        class="comments-bubble__toggle-text"
      >{{ blockComments.length }}</div>
    </div>

    <div
      v-if="showComments"
      class="comments-bubble__comments"
    >
      <div
        v-if="showComments"
        style="position: absolute;z-index: 11; top: 24px;left: 0; max-height: 400px;  overflow-y: auto; background: #fff;box-shadow: 0px 4px 25px 0px #11153814;border-radius: 8px;"
        :class="[baseClass]"
        @click.stop
      >
        <Comment
          v-for="(comment, i) in blockComments"
          :key="comment.id"
          :comment="comment"
          :saving="saving && i == blockComments.length - 1"
          class="bubble-comment"
        />
        <div
          class="menububble__link-popup"
          style="display: flex; gap: 15px; position:sticky; bottom: 0; box-shadow: none;"
        >
          <CommentEdit
            v-model="showComments"
            :field-alias="widget.attrs.alias"
            :block-id="blockComments[0].attrs.blockId"
            @saving="(e) => saving = e"
            @addComment="addComment"
          />
        </div>
      </div>
    </div>

  </div>

</template>

<script>
import { someParentHasClass } from '@/utils/helpers'
import CommentEdit from '@/components/fields/CommentEdit.vue'
import Comment from './Comment.vue'

export default {
  components: { CommentEdit, Comment },
  props: ['blockComments'],
  inject: ['widget'],
  data() {
    return {
      showComments: false,
      saving: false,
    }
  },
  computed: {
    baseClass() {
      if (!this.blockComments.length) return ''
      return this.blockComments[0].id
    },
  },
  watch: {
    blockComments: {
      handler(newValue) {
        let show = false
        newValue.forEach(c => {
          if (c.openBubble) show = true
        })
        if (show) {
          setTimeout(() => {
            this.showComments = true
          }, 100)
        }
      },
      deep: true,
    },
    showComments(v) {
      if (!v) {
        this.blockComments.forEach(c => {
          c.openBubble = false
        })
      }
    },
  },
  mounted() {
    document.addEventListener('click', this.documentClick)
  },
  destroyed() {
    document.removeEventListener('click', this.documentClick)
  },
  methods: {
    documentClick(e) {
      if (!someParentHasClass(e.target, this.baseClass) && !someParentHasClass(e.target, 'tooltip')) {
        this.showComments = false
      }
    },
    toggleComments() {
      this.showComments = !this.showComments
    },
    addComment(e) {
      this.blockComments.push(e)
    },
  },
}
</script>

<style lang="scss">
</style>
