<template>
  <b-modal
    id="at-unpublish-entry-popup"
    v-model="showPopup"
    :title="isScheduled ? $t('popups.unpublish-entry.unschedule-title') : $t('popups.unpublish-entry.title')"
    size="lg"
    :static="true"
    cancel-variant="outline-dark"
    ok-variant="danger"
    :centered="entriesTotal == 0"
    :ok-title="isScheduled ? $t('popups.unpublish-entry.ok-unschedule') : $t('popups.unpublish-entry.ok-unpublish')"
    no-close-on-backdrop
    :ok-disabled="disableOkButton"
    @ok.prevent="unpublishEntry"
    @cancel="closePopup(false)"
  >
    <div
      v-if="requiredEntriesTotal > 0"
      class="unpublish-entry__alert-warning"
    >
      <div class="unpublish-entry__alert-warning-text">
        <GjIcon
          name="AlertTriangleFilled"
          size="24"
          class="unpublish-entry__alert-warning-icon"
        />
        <span>
          {{ $t('popups.unpublish-entry.has-required-warning') }}
        </span>
      </div>
    </div>
    <LoadingSpinner v-if="$apollo.loading" />
    <div v-else-if="entriesTotal == 0">
      <span class="confirm-popup__description">
        {{ $t(getDescription, { title: getTitle }) }}
      </span>
    </div>
    <div v-else>
      <b-tabs v-model="tabIndex">
        <b-tab :title="$t('popups.unpublish-entry.required-title')">
          <div
            v-if="requiredEntriesTotal > 0"
            id="required-fields"
            class="unpublish-entry__items"
          >
            <ReferencedEntriesList
              :fields="fields"
              :tab-index="tabIndex"
              :field-names="requiredFieldNames"
              :default-total="defaultRequiredEntries.total"
              :client-total="clientRequiredEntries.total"
              @refetchEntries="refetchEntries"
              @graphql-loading="waitForLoading = $event"
              @publishedRequiredEntries="setEntries"
            />
          </div>
          <NoElements
            v-else
            :title="$t('popups.unpublish-entry.empty-required')"
          />
        </b-tab>
        <b-tab :title="$t('popups.unpublish-entry.non-required-title')">
          <div
            v-if="nonRequiredEntriesTotal > 0"
            id="non-required-fields"
            class="unpublish-entry__items"
          >
            <ReferencedEntriesList
              :fields="fields"
              :field-names="nonRequiredFieldNames"
              :default-total="defaultNonRequiredEntries.total"
              :client-total="clientNonRequiredEntries.total"
              @refetchEntries="refetchEntries"
              @graphql-loading="waitForLoading = $event"
            />
          </div>
          <NoElements
            v-else
            :title="$t('popups.unpublish-entry.empty-non-required')"
          />
        </b-tab>
      </b-tabs>
    </div>
  </b-modal>
</template>

<script>
import PopupsMixin from '@/components/popups/PopupsMixin'
import gql from 'graphql-tag'
import LoadingSpinner from '@/components/LoadingSpinner.vue'
import { TYPES } from '@/views/models/constants'
import { ENTRY_STATUSES, ENTRY_STATUSES_STRING } from '@/codex-sdk/entries'
import NoElements from '@/components/NoElements.vue'
import ReferencedEntriesList from '@/components/popups/unpublish-entry/ReferencedEntriesList.vue'

export default {
  name: 'UnpublishEntryPopup',
  components: {
    ReferencedEntriesList, NoElements, LoadingSpinner,
  },
  mixins: [PopupsMixin],
  inject: ['showEntryEditorPopup'],
  apollo: {
    // Default
    defaultRequiredEntries: {
      query() { return this.entryQuery('defaultRequiredEntries') },
      client: 'defaultClient',
      fetchPolicy: 'network-only',
      skip() { return this.fields.required.length === 0 },
      variables() {
        return {
          offset: 0,
          limit: 0,
          where: {
            system: {
              references: {
                referencingFields: { some: this.requiredFieldNames },
              },
              status: {
                notIn: [ENTRY_STATUSES_STRING[ENTRY_STATUSES.PUBLISHED]],
              },
            },
          },
        }
      },
    },
    defaultNonRequiredEntries: {
      query() { return this.entryQuery('defaultNonRequiredEntries') },
      client: 'defaultClient',
      fetchPolicy: 'network-only',
      skip() { return this.fields.nonRequired.length === 0 },
      variables() {
        return {
          offset: 0,
          limit: 0,
          where: {
            system: {
              references: {
                referencingFields: { some: this.nonRequiredFieldNames },
              },
              status: {
                notIn: [ENTRY_STATUSES_STRING[ENTRY_STATUSES.PUBLISHED]],
              },
            },
          },
        }
      },
    },

    // Client
    clientRequiredEntries: {
      query() { return this.entryQuery('clientRequiredEntries') },
      client: 'clientClient',
      fetchPolicy: 'network-only',
      skip() { return this.fields.required.length === 0 },
      variables() {
        return {
          offset: 0,
          limit: 0,
          where: {
            system: {
              references: {
                referencingFields: { some: this.requiredFieldNames },
              },
            },
          },
        }
      },
    },
    clientNonRequiredEntries: {
      query() { return this.entryQuery('clientNonRequiredEntries') },
      client: 'clientClient',
      fetchPolicy: 'network-only',
      skip() { return this.fields.nonRequired.length === 0 },
      variables() {
        return {
          offset: 0,
          limit: 0,
          where: {
            system: {
              references: {
                referencingFields: { some: this.nonRequiredFieldNames },
              },
            },
          },
        }
      },
    },
  },
  data() {
    return {
      tabIndex: 0,
      fields: {
        required: [],
        nonRequired: [],
      },
      entries: [],
      waitForLoading: true,
    }
  },
  computed: {
    disableOkButton() {
      return this.$apollo.loading || (this.entriesTotal > 0 && this.waitForLoading) || (this.requiredEntriesTotal > 0 && this.entries.length > 0)
    },
    entry() {
      return this.data?.entry
    },
    isScheduled() {
      return this.entry?.system?.status === ENTRY_STATUSES_STRING[ENTRY_STATUSES.SCHEDULED] || this.entry?.system?.status === ENTRY_STATUSES.SCHEDULED
    },
    requiredEntriesTotal() {
      return (this.defaultRequiredEntries?.total || 0) + (this.clientRequiredEntries?.total || 0)
    },
    nonRequiredEntriesTotal() {
      return (this.defaultNonRequiredEntries?.total || 0) + (this.clientNonRequiredEntries?.total || 0)
    },
    entriesTotal() {
      return this.requiredEntriesTotal + this.nonRequiredEntriesTotal
    },
    requiredFieldNames() {
      return this.fields.required.map(f => `${f.entryId}_${f.modelAlias}_${f.alias}`)
    },
    nonRequiredFieldNames() {
      return this.fields.nonRequired.map(f => `${f.entryId}_${f.modelAlias}_${f.alias}`)
    },
    getDescription() {
      return this.entry?.system?.status === ENTRY_STATUSES_STRING[ENTRY_STATUSES.PUBLISHED] || this.entry?.system?.status === ENTRY_STATUSES_STRING[ENTRY_STATUSES.EDITED] ? 'entries.listing.confirm-unpublish.description-unpublish' : 'entries.listing.confirm-unpublish.description-unschedule'
    },
    getTitle() {
      return this.entry?.system?.title
    },
  },
  watch: {
    requiredEntriesTotal: {
      handler(v) {
        this.tabIndex = !v ? 1 : 0
      },
      deep: true,
    },
  },
  created() {
    this.getReferenceFields()
  },
  methods: {
    setEntries(entries) {
      if (this.tabIndex === 0) {
        this.entries = entries
      }
    },
    unpublishEntry() {
      if (this.disableOkButton) return
      this.closePopup(true)
    },
    refetchEntries() {
      setTimeout(() => {
        this.$apollo.queries.defaultRequiredEntries.refetch()
        this.$apollo.queries.defaultNonRequiredEntries.refetch()
        this.$apollo.queries.clientRequiredEntries.refetch()
        this.$apollo.queries.clientNonRequiredEntries.refetch()
      }, 1000)
    },
    entryQuery(name) {
      return gql`
        query EntriesGetTotal($where: CodexEntryFilter, $offset: Int, $limit: Int) {
          ${name}: entryCollection(where: $where, offset: $offset, limit: $limit) {
            total
          }
        }`
    },
    getReferenceFields() {
      const allFields = this.$store.state.general.models.map(m => ({
        modelFields: m.fields.filter(f => f.type == TYPES.REFERENCE || f.type == TYPES.RICH_CONTENT).map(f => ({
          entryId: this.entry.id,
          modelAlias: m.alias,
          ...f,
        })),
      }))
      this.fields = {
        required: allFields.flatMap(m => m.modelFields.filter(f => f?.validation?.required?.value === true)),
        nonRequired: allFields.flatMap(m => m.modelFields.filter(f => !f?.validation?.required?.value)),
      }
    },
  },
}
</script>

<style lang="scss">
#at-unpublish-entry-popup {
  .unpublish-entry__reference-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    //margin-bottom: 10px;
  }

  .unpublish-entry__reference-title {
    color: #052D61;
    font-size: 14px;
    font-weight: 500;
  }

  .unpublish-entry__item {
    display: flex;
    align-items: center;
    border-radius: 4px;
    background: #F4F6FA;
    padding: 10px;
    margin-top: 8px;

    p {
      margin: 0;
      padding: 0;
    }
  }

  .unpublish-entry__icon {
    width: 34px;
    height: 34px;
    border-radius: 4px;
    background: #FFFFFF;
    color: #5E89CE;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .unpublish-entry__text {
    margin-left: 10px;
    flex: 1;
  }

  .unpublish-entry__alias {
    color: #667C99;
    font-size: 12px;
    font-weight: 400;
    line-height: 1;
  }

  .unpublish-entry__title {
    cursor: pointer;
    color: #052D61;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
  }

  .unpublish-entry__untitled {
    cursor: pointer;
    color: #031C3C;
    opacity: 0.6;
    font-size: 14px;
    line-height: 18px;
  }

  .unpublish-entry__alert-warning {
    display: flex;
    padding: 12px;
    align-items: flex-start;
    border-radius: 8px;
    border: 1px solid #FADBB6;
    background: #FDF6EC;
  }

  .unpublish-entry__alert-warning-icon {
    color: #D97F11;
    min-width: 0;
  }

  .unpublish-entry__alert-warning-text {
    display: flex;
    gap: 8px;
    color: #052D61;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
  }

  .unpublish-entry__pagination {
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
  }
}
</style>
