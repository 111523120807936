<template>
  <b-modal
    id="entry-edit__schedule_modal"
    v-model="showPopup"
    :static="true"
    :title="$t('entries.save-button.schedule-modal.title')"
  >
    <template #modal-footer>
      <b-button
        variant="outline-primary"
        @click="closePopup(false)"
      >
        {{ $t('entries.save-button.schedule-modal.cancel') }}
      </b-button>

      <b-dropdown
        split
        variant="primary"
        :text="hasUnpublishedEntries ? $t('entries.save-button.schedule-modal.schedule.current-label') : $t('entries.save-button.schedule-modal.schedule.label')"
        @click="schedule(true, false)"
      >
        <b-dropdown-item-button
          variant="primary"
          button-class="schedule-dropdown-options"
          @click="schedule(false, false)"
        >
          <GjIcon
            name="Calendar"
            size="18"
          />
          {{
            hasUnpublishedEntries ? $t('entries.save-button.schedule-modal.schedule.current-continue-label') : $t('entries.save-button.schedule-modal.schedule.continue-label')
          }}
        </b-dropdown-item-button>
      </b-dropdown>

      <b-dropdown
        v-if="hasUnpublishedEntries"
        variant="primary"
        split
        :text="$t('entries.save-button.schedule-modal.schedule-all.label')"
        @click="schedule(true, true)"
      >
        <b-dropdown-item-button
          variant="primary"
          button-class="schedule-dropdown-options"
          @click="schedule(false, true)"
        >
          <GjIcon
            name="Calendar"
            size="18"
          />
          {{ $t('entries.save-button.schedule-modal.schedule-all.continue-label') }}
        </b-dropdown-item-button>
      </b-dropdown>
    </template>

    <b-form-group>
      <b-form-radio
        v-model="scheduleType"
        value="publish"
        :disabled="isPublished"
      >
        {{ $t('entries.save-button.schedule-modal.publish') }}
      </b-form-radio>
    </b-form-group>
    <b-form-group
      v-if="scheduleType === 'publish'"
      :label="$t('entries.save-button.schedule-modal.date-label')"
    >
      <flat-pickr
        v-model="publishDate"
        class="form-control"
        :disabled="isPublished"
        :config="publishedAtConfig"
      />
    </b-form-group>
    <template v-if="showScheduleUnpublish">
      <b-form-group>
        <b-form-radio
          v-model="scheduleType"
          value="unpublish"
        >
          {{ $t('entries.save-button.schedule-modal.unpublish') }}
        </b-form-radio>
      </b-form-group>
      <b-form-group
        v-if="scheduleType === 'unpublish'"
        :label="$t('entries.save-button.schedule-modal.date-label')"
      >
        <flat-pickr
          v-model="unpublishDate"
          class="form-control"
          :config="unpublishedAtConfig"
        />
      </b-form-group>
    </template>

    <EntriesList
      v-if="scheduleType === 'publish'"
      :status="ENTRY_STATUSES.SCHEDULED"
      :unpublished-entries="unpublishedEntries"
    />
  </b-modal>
</template>

<script>
import PopupsMixin from '@/components/popups/PopupsMixin'
import EntriesList from '@/views/entries/components/EntriesList.vue'
import moment from 'moment'
import { ENTRY_STATUSES } from '@/codex-sdk/entries'
import { CMI_ORGANIZATIONS } from '@/utils/constants'

export default {
  name: 'ScheduleEntryPopup',
  components: { EntriesList },
  mixins: [PopupsMixin],
  inject: ['toastNotification'],
  data() {
    const isPublished = this.data.entry.system.status === ENTRY_STATUSES.PUBLISHED
    const showScheduleUnpublish = !CMI_ORGANIZATIONS.includes(this.$store.state.general.currentOrganization.alias)
    return {
      isPublished,
      showScheduleUnpublish,
      ENTRY_STATUSES,
      scheduleType: isPublished ? 'unpublish' : 'publish',
      publishDate: moment(Date.now()).add(1, 'm').set('seconds', 0).set('milliseconds', 0)
        .toDate(),
      unpublishDate: moment(Date.now()).add(1, 'm').set('seconds', 0).set('milliseconds', 0)
        .toDate(),
      datePickrConfig: {
        enableTime: true,
      },
    }
  },
  computed: {
    entry() {
      return this.data.entry
    },
    unpublishedEntries() {
      return this.data.unpublishedEntries || []
    },
    hasUnpublishedEntries() {
      return this.scheduleType === 'publish' && this.unpublishedEntries?.length > 0
    },
    currentDateTime() {
      return moment().toDate()
    },
    publishedAtConfig() {
      return {
        ...this.datePickrConfig,
        minDate: moment(this.currentDateTime).toISOString(),
      }
    },
    unpublishedAtConfig() {
      if (this.isPublished) {
        return {
          ...this.datePickrConfig,
          minDate: moment.max(moment(this.entry.system.publishedAt), moment(this.currentDateTime), moment(this.publishDate)).toISOString(),
        }
      }
      return {
        ...this.datePickrConfig,
        minDate: moment.max(moment(this.currentDateTime), moment(this.publishDate)).toISOString(),
      }
    },
  },
  methods: {
    async schedule(redirect = false, publishReferences = false) {
      this.showSpinner = true

      const max = moment.max(moment(this.entry.system.publishedAt), moment())

      if (this.scheduleType === 'publish') {
        if (this.isPublished || !this.publishDate || moment(this.publishDate).isSameOrBefore(moment())) {
          this.toastNotification({
            icon: 'XIcon',
            variant: 'danger',
            title: 'Error',
            text: this.$t('entries.save-button.schedule-past-date'),
          })
          return
        }
      } else if (!this.unpublishDate || moment(this.unpublishDate).isSameOrBefore(max)) {
        this.toastNotification({
          icon: 'XIcon',
          variant: 'danger',
          title: 'Error',
          text: this.$t('entries.save-button.schedule-past-date'),
        })
      }

      this.closePopup({
        publishedAt: this.scheduleType === 'publish' ? moment(this.publishDate).toISOString() : null,
        unpublishedAt: this.scheduleType === 'unpublish' ? moment(this.unpublishDate).toISOString() : null,
        redirect,
        publishReferences: this.scheduleType === 'publish' ? publishReferences : false,
      })
    },
  },
}
</script>

<style lang="scss">
#entry-edit__schedule_modal {
  #entry-edit__schedule_modal___BV_modal_outer_ {
    z-index: 99990 !important;
  }

  .schedule-dropdown-options {
    display: flex !important;
    align-items: center !important;
    gap: 5px !important;
    font-size: 14px;
  }
}
</style>
