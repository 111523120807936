<template>
  <b-form-group>
    <template #label>
      <FieldName
        :name="field.name"
        :help-text="''"
      />
    </template>
    <div
      v-if="labelIds && labelIds.length > 0"
      class="dynamic-labels"
    >
      <div
        v-for="label in labels"
        :key="label.id"
        class="dynamic-labels__label"
        :style="`background-color: ${label.color}`"
      >
        {{ label.name }}
      </div>
    </div>
    <div
      v-else
      class="content-list__empty"
    >
      <GjIcon name="IconparkLabel" />
      <span>{{ $tc('entries.merge-tool.info.no-label-value') }}</span>
    </div>
  </b-form-group>
</template>

<script>
import gql from 'graphql-tag'
import FieldName from '@/components/fields/FieldName.vue'

export default {
  name: 'LabelField',
  components: {
    FieldName,
  },
  props: {
    fieldContent: {
      type: [String, Number, Array],
    },
    field: {
      type: [Object],
    },
  },
  apollo: {
    labels: {
      query: gql`
        query Labels($where: CodexLabelFilter) {
              labelCollection(where: $where) {
                items {
                  id
                  name
                  color
                }
              }
            }
      `,
      cachePolicy: 'no-cache',
      update(data) {
        return data?.labelCollection?.items
      },
      variables() {
        const where = {
          id: { in: this.labelIds || [] },
        }
        return {
          where,
        }
      },
    },
  },
  computed: {
    labelIds() {
      return this.fieldContent?.map(label => label.id)
    },
  },
}
</script>

<style scoped lang="scss">

</style>
