<template>
  <b-button-group v-if="includeBlocks.includes(CODEX_EDITOR_BLOCKS.TABLE)">
    <b-button
      v-b-tooltip.hover.html.top="getTooltipData('create-table')"
      variant="flat-dark"
      @click="commands.createTable({rowsCount: 3, colsCount: 3, withHeaderRow: false })"
    >
      <GjIcon name="Table" />
    </b-button>
    <template v-if="isActive && isActive.table()">
      <b-button
        v-b-tooltip.hover.html.top="getTooltipData('toggle-header-row')"
        variant="flat-dark"
        @click="commands.toggleHeaderRow()"
      >
        <GjIcon name="MainArticle" />
      </b-button>
      <b-button
        v-b-tooltip.hover.html.top="getTooltipData('toggle-header-column')"
        variant="flat-dark"
        @click="commands.toggleHeaderColumn()"
      >
        <GjIcon name="Dashboard" />
      </b-button>
      <b-button
        v-b-tooltip.hover.html.top="getTooltipData('toggle-header-cell')"
        variant="flat-dark"
        @click="commands.toggleHeaderCell()"
      >
        <GjIcon name="GridLine" />
      </b-button>
      <b-button
        v-b-tooltip.hover.html.top="getTooltipData('add-column-before')"
        variant="flat-dark"
        @click="commands.addColumnBefore()"
      >
        <GjIcon name="AddColumnLeft" />
      </b-button>
      <b-button
        v-b-tooltip.hover.html.top="getTooltipData('add-column-after')"
        variant="flat-dark"
        @click="commands.addColumnAfter()"
      >
        <GjIcon name="AddColumnRight" />
      </b-button>
      <b-button
        v-b-tooltip.hover.html.top="getTooltipData('add-row-before')"
        variant="flat-dark"
        @click="commands.addRowBefore()"
      >
        <GjIcon name="AddRowTop" />
      </b-button>
      <b-button
        v-b-tooltip.hover.html.top="getTooltipData('add-row-after')"
        variant="flat-dark"
        @click="commands.addRowAfter()"
      >
        <GjIcon name="AddRowBottom" />
      </b-button>
      <b-button
        v-b-tooltip.hover.html.top="getTooltipData('delete-column')"
        variant="flat-dark"
        @click="commands.deleteColumn()"
      >
        <GjIcon name="RemoveColumn" />
      </b-button>
      <b-button
        v-b-tooltip.hover.html.top="getTooltipData('delete-row')"
        variant="flat-dark"
        @click="commands.deleteRow()"
      >
        <GjIcon name="RemoveRow" />
      </b-button>
      <b-button
        v-b-tooltip.hover.html.top="getTooltipData('toggle-cell-merge')"
        variant="flat-dark"
        @click="commands.toggleCellMerge()"
      >
        <GjIcon name="ColumnMerge" />
      </b-button>
    </template>
  </b-button-group>
</template>

<script>
import { CODEX_EDITOR_BLOCKS } from '@/components/codex-editor/CodeEditorConstants'

export default {
  inject: ['getTooltipData', 'includeBlocks'],
  props: {
    commands: Object,
    isActive: Object,
    // toggleLinkPopup: Function,
    // includeHyperLink: Boolean,
  },
  data() {
    return {
      CODEX_EDITOR_BLOCKS,
    }
  },
  mounted() {
  },
  methods: { },
}
</script>

<style lang="scss">
  .tableWrapper {
    table {
      max-width: 100% !important;
      min-width: auto  !important;
    }
    p {
      margin: 0;
      word-break: break-word;
    }
    table td{
      border: 1px solid #ddd;
      position: relative;
      min-width: 50px;
      padding:  5px;
      &.selectedCell {
        background-color: rgba(200,200,255,.3);;
      }
    }
    table th{
      border: 1px solid #ddd;
      position: relative;
      min-width: 50px;
      padding:  5px;
      &.selectedCell {
        background-color: rgba(200,200,255,.3);
      }
    }
  }
    .resize-cursor {
      * {
          &:hover {
            cursor: col-resize !important;
        }
        cursor: col-resize !important;
      }
      .column-resize-handle {
        &:before {
          content: '';
          position: absolute;
          //display: block;
          //height: 100%;
          right: 0;
          top: -1px;
          bottom: -1px;
          border: 1px solid #1d79f2;
          background-color: #1d79f2;
          max-width: 3px;
          overflow: hidden;
        }
      }
    }

</style>
