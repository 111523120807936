<template>
  <vSelect
    v-model="organizationId"
    label="name"
    :clearable="false"
    :options="items"
    :reduce="option => option.publicId"
    :loading="loadingOrg"
    :disabled="editing || !hasPermission || disabled"
    :placeholder="$t('integrations.integrations.vp-organization-selector.placeholder')"
  >
    <template #list-footer>
      <li
        v-show="currentPage < totalPages"
        ref="organizationsLoad"
        class="loader"
      />
    </template>
  </vSelect>
</template>

<script>
import { readVPOrganizations } from '@/codex-sdk/assets'

export default {
  name: 'VPOrganizationSelector',
  props: {
    value: String,
    loadData: Boolean,
    editing: Boolean,
    hasPermission: Boolean,
    disabled: Boolean,
  },
  data() {
    return {
      items: [],
      currentPage: 1,
      totalPages: 0,
      observer: null,
      loadingOrg: true,
    }
  },
  computed: {
    organizationId: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit('input', v)
      },
    },
  },
  mounted() {
    this.observer = new IntersectionObserver(this.infiniteScroll)
    this.fetchOrganizations()
  },
  methods: {
    async onOpen() {
      if (this.currentPage < this.totalPages) {
        await this.$nextTick()
        this.observer.observe(this.$refs.organizationsLoad)
      }
    },
    onClose() {
      this.observer.disconnect()
    },
    async infiniteScroll([{ isIntersecting, target }]) {
      if (isIntersecting) {
        const ul = target.offsetParent
        const scrollTop = target.offsetParent.scrollTop
        this.currentPage++
        this.fetchOrganizations()
        await this.$nextTick()
        ul.scrollTop = scrollTop
      }
    },
    async fetchOrganizations() {
      this.loadingOrg = true
      if (!this.loadData) {
        this.loadingOrg = true
      }
      try {
        const { data } = await readVPOrganizations({
          page: this.currentPage,
          size: 10,
        })
        this.organizations = data.result
        const list = [
          ...this.items,
        ]
        data.result.items.forEach(p => {
          const exist = list.find(a => a.publicId === p.publicId)
          if (!exist) {
            list.push(p)
          }
        })
        this.totalPages = data.result.totalPages
        this.currentPage = data.result.currentPage
        this.pageSize = data.result.pageSize
        this.items = list
      } catch {
        this.items = []
        if (this.organizationId) {
          this.items.push({ name: this.$t('integrations.integrations.vp-organization-selector.no-results'), publicId: this.organizationId })
        }
      } finally {
        this.loadingOrg = false
      }
    },
  },
}
</script>
