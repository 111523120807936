import { ROOT_FOLDER_ID } from '@/codex-sdk/folders'
import { checkPermissionAsync, pr } from './config'

// For regEx search in VSCode: canCreateFolder|canEditFolder|canDeleteFolder

// * Create entity file, and can functions
// * Import and add them to allCanFunctions object down below
// * Add defaults into the permissions array down below
// * Implement v-permission directives on neccessary buttons
// * Implement permissions on entities sdk functions
// * Implement permissions on navigation and route

export const Folder = Object.freeze({})

export const FolderTooltips = {}

/**
 * _options
 *    entity = undefined
 *    showToast = false
 */

/**
 * Can CREATE Folder
 * */
export async function canCreateFolder(_options = {}) {
  const { entity, ...options } = _options

  return pr(
    await checkPermissionAsync(entity?.id || ROOT_FOLDER_ID, 'asset', 'managefolder'),
    'You have no permission to create folder',
    options,
  )
}

/**
 * Can EDIT Folder
 * */
export async function canEditFolder(_options = {}) {
  const { entity, ...options } = _options

  return pr(
    await checkPermissionAsync(entity?.id || ROOT_FOLDER_ID, 'asset', 'managefolder'),
    'You have no permission to edit folder',
    options,
  )
}

/**
 * Can DELETE Folder
 * */
export async function canDeleteFolder(_options = {}) {
  const { entity, ...options } = _options

  return pr(
    await checkPermissionAsync(entity?.id || ROOT_FOLDER_ID, 'asset', 'managefolder'),
    'You have no permission to delete this folder',
    options,
  )
}
