export const defaultToolbar = {
  fontStyle: {
    isEnabled: true,
    bold: true,
    italic: true,
    underline: true,
    strikethrough: true,
    colorStyle: true,
  },
  premium: {
    isEnabled: true,
  },
  dropcap: {
    isEnabled: true,
  },
  textAlignment: {
    isEnabled: true,
    alignLeft: true,
    alignCenter: true,
    alignRight: true,
  },
  links: {
    isEnabled: true,
    external: false,
    codex: false,
    phone: false,
    email: false,
  },
  scripts: {
    isEnabled: true,
  },
  comments: {
    isEnabled: true,
  },
}
