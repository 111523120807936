import { sortBy } from 'lodash'

function sortViews(items) {
  items = sortBy(items, [view => view?.path?.toLowerCase(), view => view?.name?.toLowerCase()], ['asc', 'asc'])
  items = items.sort((a, b) => (b.path ? 1 : 0) - (a.path ? 1 : 0))
  return items
}

export default {
  SET_CURRENT_SITE(state, site) {
    state.currentSite = site
  },
  SET_CURRENT_ORGANIZATION(state, organization) {
    state.currentOrganization = organization
  },
  SET_CURRENT_AUTHOR(state, author) {
    state.currentAuthor = author
  },
  SET_BREADCRUMB_ITEMS(state, items) {
    state.breadcrumbItems = items
  },
  SET_VIDEO(state, video) {
    state.video = video
  },
  SET_DOMAIN(state, domain) {
    state.domain = domain
  },
  SET_CURRENT_USER_PERMISSIONS(state, currentUserPermissions) {
    state.currentUserPermissions = currentUserPermissions
  },
  SET_SITE_SETTINGS(state, settings) {
    state.site_settings = settings
  },

  ADD_NAVIGATION_ITEM(state, item) {
    const existingItem = state.extraNavigationItems.find(i => i.name === item.name)
    if (!existingItem) {
      state.extraNavigationItems = [...state.extraNavigationItems, item]
    }
  },
  UPDATE_NAVIGATION_ITEM(state, item) {
    state.extraNavigationItems = state.extraNavigationItems.map(exItem => (exItem.name == item.name ? item : exItem))
  },
  REMOVE_NAVIGATION_ITEM(state, { item, removeBy = 'id' }) {
    if (removeBy === 'id') {
      state.extraNavigationItems = [...state.extraNavigationItems.filter(fitem => fitem._id !== item)]
    }
    if (removeBy === 'name') {
      state.extraNavigationItems = [...state.extraNavigationItems.filter(fitem => fitem.name !== item)]
    }
  },
  REMOVE_NAVIGATION_ITEMS(state, { group = 'views' }) {
    if (group === 'views') {
      state.extraNavigationItems = [...state.extraNavigationItems.filter(item => item?.group !== 'views')]
    }
    if (group === 'sites') {
      state.extraNavigationItems = [...state.extraNavigationItems.filter(item => item?.group !== 'sites')]
    }
  },
  REMOVE_PLUGINS_NAVIGATION_ITEMS(state) {
    state.extraNavigationItems = [...state.extraNavigationItems.filter(item => !item?.plugin)]
  },
  SET_VP_CONNECTION(state, item) {
    state.vpConnection = item
  },
  SET_MODELS(state, item) {
    state.models = item
  },
  SET_ALL_MODELS(state, items) {
    state.allModels = items
  },

  SET_INTEGRATIONS(state, item) {
    state.allIntegrations = item
  },

  SET_INTEGRATION_CONNECTIONS(state, item) {
    if (Array.isArray(item)) {
      state.allIntegrationConnections = [...state.allIntegrationConnections, ...item]
    } else {
      state.allIntegrationConnections.push(item)
    }
  },

  UPDATE_INTEGRATION_CONNECTIONS(state, item) {
    const index = state.allIntegrationConnections.findIndex(e => e.id === item.id)

    if (index === -1) {
      state.allIntegrationConnections.push(item)
    } else {
      state.allIntegrationConnections[index] = item
    }
  },

  REMOVE_INTEGRATION_CONNECTIONS(state, item) {
    state.allIntegrationConnections = state.allIntegrationConnections.filter(e => e.id !== item.id)
  },

  SET_VIEWS(state, items) {
    state.views = sortViews(items)
  },

  ADD_VIEW(state, item) {
    state.views = sortViews([...state.views, item])
  },

  UPDATE_VIEW(state, item) {
    state.views = sortViews(state.views.map(exItem => (exItem.id == item.id ? item : exItem)))
  },

  REMOVE_VIEW(state, { item }) {
    state.views = sortViews([...state.views.filter(fitem => fitem.id !== item.id)])
  },
}
