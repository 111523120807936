class Asset {
  constructor() {
    this.file = null
    this.fileType = ''
    this.mediaId = ''
    this.id = ''
    this.name = ''
    this.size = 0
    this.type = ''
    this.uploadType = ''
    this.progress = 0
    this.requestKey = null
    this.downloadUrl = ''
    this.status = null
    this.failedStatus = null
    this.urls = []
    this.uploadedParts = []
    this.path = ''
    this.altAttribute = ''
    this.titleAttribute = ''
    this.versionId = ''
    this.width = 1024
    this.height = 1024
    this.inserted = false
    this.folderId = null
    this.id = ''
    this._id = ''
    this.externalId = null
    this.contentType = ''
    this.compressedPath = null
    this.fileName = ''
    this.title = ''
    this.caption = null
    this.alt = null
    this.author = null
    this.source = null
    this.copyright = null
    this.tags = []
    this.storageId = ''
    this.provider = ''
    this.duration = 0
    this.focalPoint = null
    this.attrs = {}
    this.expiresAt = null
    this.createdAt = ''
    this.updatedAt = null
    this.createdBy = ''
    this.updatedBy = null
    this.url = ''
  }

  fromData(data) {
    this.id = data?.id ?? ''
    this._id = data?._id ?? ''
    this.name = data?.file?.name ?? ''
    this.title = data?.title ?? ''
    this.contentType = data?.contentType ?? ''
    this.size = data?.file?.size ?? ''
    this.type = data?.type ?? ''
    this.file = data?.file ?? null
    this.fileType = data?.fileType ?? ''
    this.width = data?.width ?? 1024
    this.height = data?.height ?? 1024
    this.altAttribute = data?.altAttribute ?? ''
    this.titleAttribute = data?.titleAttribute ?? ''
    this.versionId = data?.versionId ?? ''
    this.inserted = data?.inserted ?? false
    this.folderId = data?.folderId ?? ''
    this.uploadType = data?.uploadType ?? ''
    this.progress = data?.progress ?? 0
    this.status = data?.uploadStatus ?? null
  }
}

export default Asset
