<template>
  <b-toast
    id="vp-progress-popup"
    v-model="showPopup"
    solid
    static
    no-auto-hide
    no-close-button
    toaster="b-toaster-bottom-left"
    body-class="p-0"
    header-class="p-1 modal-header bg-primary"
    :toast-class="{
      'toast-closed': !open,
      'shadow': true
    }"
  >
    <template #toast-title>
      <div
        class="d-flex flex-grow-1 align-items-center justify-content-between"
      >
        <span class="w-75 mw-75">
          <p
            v-if="failedUploads > 0"
            class="text-sm m-0 text-truncate"
          >
            {{ $tc(`assets.upload.status.failed`, failedUploads || 1, { failedUploads }) }}
          </p>
          <p
            v-else-if="activeUploads > 0"
            class="text-sm m-0 text-truncate"
          >
            {{ $tc(`assets.upload.status.active`, activeUploads || 1, { activeUploads }) }}
          </p>
          <p
            v-else
            class="text-sm m-0 text-truncate"
          >
            {{ $tc(`assets.upload.status.completed`, successUploads || 1, { successUploads, length: uploads.length }) }}
          </p>
        </span>
        <div class="d-flex w-25 justify-content-end">
          <button
            type="button"
            class="m-0 close p-0 "
            data-dismiss="toast"
            @click.capture="() => { open = !open }"
          >
            <GjIcon
              v-show="open"
              name="Minus"
              color=""
            />
            <GjIcon
              v-show="!open"
              name="FullscreenAlt"
              color=""
            />
          </button>
          <button
            v-show="open"
            type="button"
            class="m-0 ml-50 close p-0 "
            data-dismiss="toast"
            @click.prevent="closeToast"
          >
            <GjIcon
              name="Close_fill"
              color=""
            />
          </button>
        </div>
      </div>
    </template>

    <b-collapse
      :id="collapseId"
      :visible="open"
      class="p-0"
    >

      <b-list-group class=" p-0">
        <template v-for="(upload, i) in uploads">
          <b-list-group-item
            :key="upload.id"
            class="d-flex align-items-center rounded-0 border-right-0 border-top-0 border-left-0 px-2 py-1"
            :class="uploads.length > 1 && uploads.length - 1 > i ? 'border-bottom-light' : 'border-bottom-0'"
          >
            <div class="item-icon col-1 p-0">
              <GjIcon
                v-if="upload.fileType === 'video'"
                name="CameraVideo"
              />
              <GjIcon
                v-else-if="upload.fileType === 'audio'"
                name="IconparkAudioFile"
              />
              <GjIcon
                v-else-if="upload.fileType === 'image'"
                name="IconparkImageFiles"
              />
              <GjIcon
                v-else-if="upload.type === 'FILE'"
                name="File"
              />
            </div>
            <div class="col-10 px-1">
              <div class="d-flex">
                <p class="text-sm text-main m-0 text-truncate">
                  {{ upload.name }}
                </p>
              </div>
              <div class="title mt-05">
                <div class="d-flex flex-column">
                  <div class="d-flex justify-content-between">
                    <p class="text-xsm text-weight-bold m-0">
                      {{ bytesToSize(upload.size, 2) }}
                    </p>
                    <p class="text-xsm text-weight-bold m-0">
                      {{ uploaded(upload.status, upload.progress) }}
                    </p>
                  </div>
                  <span>
                    <b-progress
                      v-model="upload.progress"
                      height="3px"
                      max="100"
                      :variant="progressVariant(upload.status)"
                      :animated="upload.status === 'multipartComplete' || upload.status === 'encoding'"
                    />
                  </span>
                </div>
              </div>
            </div>
            <div style="min-width: 0">
              <b-button
                v-show="(upload.fileType === 'audio' || upload.fileType === 'video') && (upload.status === 'uploading' || upload.status === 'encoding' || upload.status === 'multipartComplete' || !upload.status)"
                id="skip-intro-info-button"
                title="Cancel upload"
                variant="flat-dark"
                class="p-0 rounded-circle col-1"
                style="height: 24px; cursor: pointer;"
                @click.capture.stop="upload.cancelToken.cancel()"
              >

                <GjIcon
                  name="Close"
                  size="24"
                  class="text-secondary"
                />
              </b-button>
              <GjIcon
                v-show="upload.status === 'success'"
                name="Check"
                size="20"
                class="text-white bg-success rounded-circle"
                style="padding: 3px;"
              />
              <b-button
                v-show="(upload.fileType === 'audio' || upload.fileType === 'video') && upload.status === 'failed'"
                id="skip-intro-info-button"
                title="Retry upload"
                variant="flat-dark"
                class="p-0 rounded-circle col-1"
                style="height: 24px; cursor: pointer;"
                @click.capture.stop="retry(upload, i)"
              >
                <GjIcon
                  name="Refresh"
                  size="24"
                  class="text-gray"
                />
              </b-button>
              <!-- <b-button
                v-show="(upload.fileType === 'audio' || upload.fileType === 'video') && upload.status === 'canceled'"
                id="skip-intro-info-button"
                title="Delete file from upload list"
                variant="flat-dark"
                class="p-0 rounded-circle col-1"
                style="height: 24px; cursor: pointer;"
                @click.stop="deleteFileFromUploads(i)"
              >
                <GjIcon
                  name="Delete"
                  size="24"
                  class="text-gray"
                />
              </b-button> -->
            </div>
          </b-list-group-item>
        </template>
      </b-list-group>
    </b-collapse>
  </b-toast>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import { bytesToSize } from '@/utils/helpers'
import PopupsMixin from '@/components/popups/PopupsMixin'

export default {
  name: 'VPProgressPopup',
  mixins: [PopupsMixin],
  data() {
    return {
      open: true,
      collapseId: 'vp-progress-collapse',
      bytesToSize,
    }
  },
  computed: {
    ...mapGetters('vpUploads', {
      uploads: 'getUploads',
      activeUploads: 'getActiveUploads',
      successUploads: 'getSuccessUploads',
      failedUploads: 'getFailedUploads',
    }),
    uploaded() {
      return (status, percentage) => {
        if (!status) return this.$t('assets.upload.progressStatus.starting')
        if (status === 'uploading') return this.$t('assets.upload.progressStatus.uploading', { percentage })
        if (status === 'multipartComplete') return this.$t('assets.upload.progressStatus.multipartComplete')
        if (status === 'encoding') return this.$t('assets.upload.progressStatus.encoding')
        if (status === 'success') return this.$t('assets.upload.progressStatus.success')
        if (status === 'canceled') return this.$t('assets.upload.progressStatus.canceled')
        if (status === 'failed') return this.$t('assets.upload.progressStatus.failed')
        return this.$t('assets.upload.progressStatus.unknown')
      }
    },
  },
  watch: {
    activeUploads(v) {
      if (!v && !this.failedUploads.length) this.open = false
      else this.open = true
    },
    open(v) {
      if (v) this.$bvToast.show(this.collapseId)
      else this.$bvToast.hide(this.collapseId)
    },
  },
  created() {},
  mounted() {},
  methods: {
    ...mapActions('vpUploads', [
      'retryUploadFile',
      'destroyUploadFiles',
    ]),
    ...mapMutations('vpUploads', [
      'DELETE_OBJECT_FROM_LIST',
    ]),
    proceedSelect() {
      try {
        this.closePopup(true)
      } catch (error) {
        console.log(error)
      }
    },
    progressVariant(status) {
      if (!status) return ''
      if (status === 'uploading') return ''
      if (status === 'multipartComplete') return 'warning'
      if (status === 'encoding') return 'warning'
      if (status === 'success') return 'success'
      if (status === 'canceled') return 'danger'
      if (status === 'failed') return 'danger'
      return ''
    },
    deleteFileFromUploads(index) {
      if (this.uploads.length - 1 < 1) this.closePopup(false)
      this.DELETE_OBJECT_FROM_LIST(index)
    },
    retry(u, i) {
      this.retryUploadFile({ file: u, index: i })
    },
    closeToast() {
      // TODO : in case of active uploads, ask user if he's sure to cancel active uploads
      // if (this.getActiveUploads > 0) {
      //   this.$bvModal.show(this.cancelUploadsWarningRef)
      // } else
      this.destroyToast()
    },
    destroyToast() {
      this.closePopup(false)
      this.destroyUploadFiles()
    },
  },

}
</script>

<style lang="scss">
#vp-progress-popup__toast_outer {
  position: fixed;
  bottom: 0;
  left: 20px;
  margin-bottom: 0;
}

.b-toaster-slot {
  width: fit-content !important;
}

.b-toaster {
  z-index: 11000 !important;
}

#vp-progress-popup {
  .toast-body {
    width: 380px;
    height: auto;
    max-height: 25vh;
    overflow-y: auto;

    .list-group-item {
      height: 84px;
    }
  }

  &.b-sidebar {
    top: auto !important;
  }

  &.toast.toast-closed {
    .toast-header, .toast-body {
      width: 240px;
    }
  }
}
</style>
