import CodexContentEditor from '../../CodexContentEditor'
import GenericIframe from './GenericIframe.vue'
import GenericIframePanel from './GenericIframePanel.vue'
import GenericIframeSidebar from './GenericIframeSidebar.vue'
import { BLOCK_GROUPS } from '../constants'

CodexContentEditor.registerWidget('codex', {
  displayName: 'codex-editor.block-names.generic-iframe',
  name: 'iframe',
  group: BLOCK_GROUPS.OTHERS,
  renderEditorPanel: GenericIframeSidebar,
  // eslint-disable-next-line global-require
  image: require('../../icons/generic-iframe/icon.svg'),
  attrs: {
    blockId: {
      default: null,
    },
    src: {
      default: null,
    },
    align: {
      default: 'left',
    },
    width: {
      default: '50%',
    },
    height: {
      default: 300,
    },
    scrolling: {
      default: 'auto',
    },
    caption: {
      default: '',
    },
  },
  renderEditor: GenericIframe,
  renderEditorBar: GenericIframePanel,
})
