import { apiAxios as axios } from '../libs/axios'
import { checkPermission, tc } from './config'

/**
 * Constants
 */

/**
 * Helpers
 */
export function userDefaults(user = {}) {
  return {
    fullName: '',
    email: '',
    createdAt: '',
    rolesPermissions: [],
    userPermissions: [],
    ...user,
  }
}

/**
 * Transforms to received data
 */
export function transformUserFromRequest(user) {
  user.userName = user?.fullName?.trim() ? user.fullName : user.email
  user.email = user?.email || ''

  return user
}

/**
 *
 * @response
 * {
 *  currentPage: Integer
 *  totalPages: Integer
 *  pageSize: Integer
 *  totalCount: Integer
 *  hasPrevious: Bool
 *  hasNext: Bool
 *  data: Array
 * }
 */
export function userInvitations({
  page = null, pageSize = null, query = null,
} = {}) {
  return axios.get('/{organization}/users/invitations', {
    params: {
      Page: page,
      Size: pageSize,
      Q: query,
    },
  })
}

/**
 *
 * @response
 * [{
 *  id: GUID
 *  parentId: GUID | null
 *  title: String
 *  slug: String
 *  canContainArticle: Bool
 * }, {...}, ...]
 */
export function userInvitation(inv, organization) {
  return axios.post(`/${organization}/users/invitations/${inv}/accept`)
}

/**
 *
 * @response
 * [{
 *  id: GUID
 *  parentId: GUID | null
 *  title: String
 *  slug: String
 *  canContainArticle: Bool
 * }, {...}, ...]
 */
export function readCurrentUserPermissions(siteId) {
  return axios.get('/{organization}/users/current-user/permissions', {
    params: {
      siteId: siteId || null,
    },
  })
}

/**
 *
 * @response
 * [{
 *  id: GUID
 *  parentId: GUID | null
 *  title: String
 *  slug: String
 *  canContainArticle: Bool
 * }, {...}, ...]
 */
export function readUsers({
  page = null, pageSize = null, query, excludeIds = [],
} = {}, site) {
  return axios.get('/{organization}/users', {
    params: {
      Page: page,
      Size: pageSize,
      Q: query,
      ExcludeIds: excludeIds,
      site,
    },
  }).then(response => {
    response.data.items = response.data.items.map(user => transformUserFromRequest(user))
    return response
  })
}

/**
 *
 * @param {*} id
 *
 * @response
 * {
 *  id: GUID
 *  parentId: GUID | null
 *  title: String
 *  slug: String
 *  canContainArticle: Bool
 * }
 */
// eslint-disable-next-line consistent-return
export async function readUser(id) {
  try {
    const response = await axios.get(`/{organization}/users/${id}`)
    response.data = transformUserFromRequest(response.data)
    return response
  } catch (e) {
    console.log(e)
  }
}
/// /////////////////////////////////////////////////////////////////////////////////
/// /////////////////////////////////////////////////////////////////////////////////
/// /////////////////////////////////////////////////////////////////////////////////
/// /////////////////////////////////////////////////////////////////////////////////
/// ///////////////////////// MU BART TEK NOTIFICATIONS.JS //////////////////////////
/// /////////////////////////////////////////////////////////////////////////////////
/// /////////////////////////////////////////////////////////////////////////////////
/// /////////////////////////////////////////////////////////////////////////////////
/// /////////////////////////////////////////////////////////////////////////////////

// eslint-disable-next-line consistent-return
export async function readUserPreferences() {
  try {
    const response = await axios.get('/{organization}/notification/notifications/user/subscriptions')
    // response.data = transformUserFromRequest(response.data)
    return response
  } catch (e) {
    console.log(e)
  }
}

export function updateUserPreferences(preferences, messages) {
  return tc(axios.post('/{organization}/notification/notifications/user/subscription/toggle', preferences), {
    successTitle: 'sdk.success-title',
    successMessage: 'sdk.user.update-preferences-successful',
    failTitle: 'sdk.user.update-preferences-failed',
    ...messages,
  })
}

/// /////////////////////////////////////////////////////////////////////////////////
/// /////////////////////////////////////////////////////////////////////////////////
/// /////////////////////////////////////////////////////////////////////////////////
/// /////////////////////////////////////////////////////////////////////////////////
/// /////////////////////////////////////////////////////////////////////////////////

/**
 * CRUD Operations
 * */

/**
 *
 * @param {*} user
 *
 * @response
 * {
 *  id: GUID
 *  errors: null | Object
 *  success: true | false
 * }
 */
export function createUser(user, messages) {
  return tc(axios.post('/{organization}/users', user), {
    successTitle: 'sdk.success-title',
    successMessage: 'sdk.user.create-successful',
    failTitle: 'sdk.user.create-failed',
    ...messages,
  })
}
/**
 * CRUD Operations
 * */

/**
 *
 * @param {*} user
 *
 * @response
 * {
 *  id: GUID
 *  errors: null | Object
 *  success: true | false
 * }
 */
export function inviteUsers(users, messages) {
  return tc(axios.post('/{organization}/users/invitations', { users }), {
    successTitle: 'sdk.success-title',
    successMessage: 'sdk.user.invite-successful',
    failTitle: 'sdk.user.invite-failed',
    ...messages,
  })
}
/**
 * CRUD Operations
 * */

/**
 *
 * @param {*} user
 *
 * @response
 * {
 *  id: GUID
 *  errors: null | Object
 *  success: true | false
 * }
 */
export function addUsers(users, messages) {
  return checkPermission('canInviteUser')

  && tc(axios.post('/{organization}/users', { users }), {
    successTitle: 'sdk.success-title',
    successMessage: 'sdk.user.add-successful',
    failTitle: 'sdk.user.add-failed',
    ...messages,
  })
}

/**
 *
 * @param {*} user
 *
 * @response
 * {
 *  id: GUID
 *  errors: null | Object
 *  success: true | false
 * }
 */
export function updateUser(user, messages) {
  return checkPermission('canEditUser')

  && tc(axios.put(`/{organization}/users/${user.id}`, user), {
    successTitle: 'sdk.success-title',
    successMessage: 'sdk.user.update-successful',
    failTitle: 'sdk.user.update-failed',
    ...messages,
  })
}

/**
 *
 * @param {*} user
 *
 * @response
 * {
 *  id: GUID
 *  errors: null | Object
 *  success: true | false
 * }
 */
export function updateUserPermissions(user, messages) {
  return checkPermission('canEditUser')

  && tc(axios.put(`/{organization}/users/${user.id}/permission`, user), {
    successTitle: 'sdk.success-title',
    successMessage: 'sdk.user.update-permissions-successful',
    failTitle: 'sdk.user.update-permissions-failed',
    ...messages,
  })
}

/**
 *
 * @param {*} user
 *
 * @response
 * {
 *  id: GUID
 *  errors: null | Object
 *  success: true | false
 * }
 */
export function updateUserRoles(user, messages) {
  return checkPermission('canEditUser')

  && tc(axios.put(`/{organization}/users/${user.id}/role`, user), {
    successTitle: 'sdk.success-title',
    successMessage: 'sdk.user.update-roles-successful',
    failTitle: 'sdk.user.update-roles-failed',
    ...messages,
  })
}

/**
 *
 * @param {*} userId
 *
 * @response
 * {
 *  errors: null | Object
 *  success: true | false
 * }
 */
export function deleteUser(userId, messages) {
  return checkPermission('canDeleteUser')

  && tc(axios.delete(`/{organization}/users/${userId}`), {
    successTitle: 'sdk.success-title',
    successMessage: 'sdk.user.delete-successful',
    failTitle: 'sdk.user.delete-failed',
    ...messages,
  })
}

/**
 *
 * @param {*} userId
 *
 * @response
 * [{
 *  errors: null | Object
 *  success: true | false
 * },...]
 */
export function deleteUsers(userIds, messages) {
  return checkPermission('canDeleteUser')

  && tc(Promise.all(userIds.map(userId => axios.delete(`/{organization}/users/${userId}`))), {
    successTitle: 'sdk.success-title',
    successMessage: 'sdk.user.delete-users-successful',
    failTitle: 'sdk.user.delete-users-failed',
    ...messages,
  }, true)
}

/**
 *
 * @param {*} userId
 *
 * @response
 * {
 *  errors: null | Object
 *  success: true | false
 * }
 */
export function removeUserRole(userId, roleId, messages) {
  return checkPermission('canEditUser')

  && tc(axios.delete(`/{organization}/users/${userId}/role/${roleId}`, { params: { roleId } }), {
    successTitle: 'sdk.success-title',
    successMessage: 'sdk.user.remove-role-successful',
    failTitle: 'sdk.user.remove-role-failed',
    ...messages,
  })
}

export function removeUserSiteRole(userId, roleId, messages) {
  return checkPermission('canEditUser')

  && tc(axios.delete(`/{organization}/site/{site}/user/${userId}`, { params: { roleId } }), {
    successTitle: 'sdk.success-title',
    successMessage: 'sdk.user.remove-site-role-successful',
    failTitle: 'sdk.user.remove-site-role-failed',
    ...messages,
  })
}

/**
 *
 * @param {*} userId
 *
 * @response
 * {
 *  errors: null | Object
 *  success: true | false
 * }
 */

export function removeUserSite(userId, siteId, messages) {
  return checkPermission('canEditUser')

  && tc(axios.delete(`/{organization}/site/${siteId}/user/${userId}`, { params: { siteId } }), {
    successTitle: 'sdk.success-title',
    successMessage: 'sdk.user.remove-site-successful',
    failTitle: 'sdk.user.remove-site-failed',
    ...messages,
  })
}

export function readUserOrganizations(siteId) {
  return axios.get('/{organization}/users/organizations-info', {
    params: {
      siteId: siteId || null,
    },
  }).then(response => response.data)
}
