import { apiAxios as axios } from '../libs/axios'
import { checkPermission, tc } from './config'

/**
 * Constants
 */

export const ROOT_FOLDER_ID = 'flRootFlId'

/**
 * Helpers
 */
export function folderDefaults(folder = {}) {
  return {
    parentId: null,
    name: '',
    ...folder,
  }
}

/**
 * Transforms to received data
 */
export function transformFolderFromRequest(folder) {
  return folder
}

/**
 * CRUD Operations
 * */

/**
 *
 * @param {*} folder
 *
 * @response
 * {
 *  id: GUID
 *  slug: String
 *  errors: null | Object
 *  success: true | false
 * }
 */
export async function createFolder(folder, messages) {
  return await checkPermission('canCreateFolder', { entity: { id: folder.parentId } })
   && tc(axios.post('/{organization}/folders', folder), {
     successTitle: 'sdk.success-title',
     successMessage: 'sdk.folder.create-successful',
     failTitle: 'sdk.folder.create-failed',
     folder,
     ...messages,
   })
}

/**
 *
 * @param {*} folder
 *
 * @response
 * {
 *  id: GUID
 *  errors: null | Object
 *  success: true | false
 * }
 */

// ToDo: This method is called for both updating folder name and moving folder
// Maybe separate for better permission control
export async function updateFolder(folder, messages = {}) {
  return await checkPermission('canEditFolder', { entity: { ...folder } })
  && tc(axios.put(`/{organization}/folders/${folder.id}`, folder), {
    successTitle: 'sdk.success-title',
    successMessage: 'sdk.folder.update-successful',
    failTitle: 'sdk.folder.update-failed',
    catchError: false,
    ...messages,
  })
}

/**
 *
 * @param {*} folderId
 *
 * @response
 * {
 *  errors: null | Object
 *  success: true | false
 * }
 */
export async function deleteFolder(folder, option, type, messages = {}) {
  return await checkPermission('canDeleteFolder', { entity: { ...folder } })
  && tc(axios.delete(`/{organization}/folders/${folder.id}`, { data: { option, type } }), {
    successTitle: 'sdk.success-title',
    successMessage: 'sdk.folder.delete-successful',
    failTitle: 'sdk.folder.delete-failed',
    ...messages,
  })
}

/**
 *
 * @param {*} folder
 *
 * @response
 * {
 *  id: GUID
 *  errors: null | Object
 *  success: true | false
 * }
 */
export function updateAssetsFolder(folderId, assets = [], messages = {}) {
  // ToDo: fix permission check
  return tc(axios.put('/{organization}/assets/move', { folderId, assets }), {
    successTitle: 'sdk.success-title',
    successMessage: 'sdk.asset.update-successful',
    failTitle: 'sdk.asset.update-failed',
    catchError: false,
    ...messages,
  })
}
