<template>
  <li class="entry-log__activity">
    <template v-if="activity.isSystemField">
      <div
        v-if="activity.fieldAlias === 'Labels'"
        class="entry-log__activity__labels__container"
      >
        <template v-if="addedLabels.length">
          <i18n
            path="entries.logs.system.labels.added"
            tag="span"
            class="entry-log__activity__labels"
          >
            <DynamicLabels
              :value="addedLabels"
              :entity-type="LABEL_COMPONENT_ENTITY_TYPES.ENTRY"
              :show-add-button="false"
              :show-label="false"
              :no-title="true"
              :site-id="siteId"
              :show-remove-button="false"
            />
          </i18n>
        </template>
        <template v-if="removedLabels.length">
          <i18n
            path="entries.logs.system.labels.removed"
            tag="span"
            class="entry-log__activity__labels"
          >
            <DynamicLabels
              :value="removedLabels"
              :entity-type="LABEL_COMPONENT_ENTITY_TYPES.ENTRY"
              :show-add-button="false"
              :show-label="false"
              :no-title="true"
              :site-id="siteId"
              :show-remove-button="false"
            />
          </i18n>
        </template>
      </div>
      <i18n
        v-else-if="activity.fieldAlias === 'Status'"
        path="entries.logs.system.status.template"
        tag="span"
      >
        <span class="entry-log__activity__placeholder">{{ $t('entries.logs.system.status.label') }}</span>
        <span class="entry-log__activity__placeholder">{{ $t(mapEntryStatus(activity.oldValue)) }}</span>
        <span class="entry-log__activity__placeholder">{{ $t(mapEntryStatus(activity.newValue)) }}</span>
      </i18n>
      <i18n
        v-else-if="activity.fieldAlias === 'PublishedAt'"
        tag="span"
        path="entries.logs.system.publishedAt.template"
      >
        <span class="entry-log__activity__placeholder">{{ $t('entries.logs.system.publishedAt.label') }}</span>
        <span class="entry-log__activity__placeholder">{{ activity.newValue | formatDateTime }}</span>
      </i18n>
      <i18n
        v-else-if="activity.fieldAlias === 'Title'"
        path="entries.logs.system.title.template"
        tag="span"
      >
        <span class="entry-log__activity__placeholder">{{ $t('entries.logs.system.title.label') }}</span>
        <span class="entry-log__activity__placeholder">{{ activity.newValue }}</span>
      </i18n>
    </template>
    <i18n
      v-else
      path="entries.logs.field"
      tag="span"
    >
      <span class="entry-log__activity__placeholder">{{ fieldName }}</span>
    </i18n>
  </li>
</template>

<script>
import DynamicLabels from '@/components/DynamicLabels.vue'
import { LABEL_COMPONENT_ENTITY_TYPES } from '@/codex-sdk/labels'
import { mapEntryStatus } from '@/codex-sdk/entries'

export default {
  name: 'EntryLog',
  components: {
    DynamicLabels,
  },
  props: {
    activity: {
      type: Object,
      default: () => ({}),
    },
    siteId: {
      type: String,
      default: '',
    },
    allFields: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      mapEntryStatus,
      LABEL_COMPONENT_ENTITY_TYPES,
    }
  },
  computed: {
    fieldName() {
      if (this.activity.isSystemField) return ''
      return this.allFields.find(f => f.attrs.alias === this.activity.fieldAlias)?.attrs?.name || this.activity.fieldAlias
    },
    addedLabels() {
      if (this.activity.isSystemField && this.activity.fieldAlias === 'Labels') {
        return this.activity.newValue.filter(n => !this.activity.oldValue.find(o => n.id === o.id))
      }
      return []
    },
    removedLabels() {
      if (this.activity.isSystemField && this.activity.fieldAlias === 'Labels') {
        return this.activity.oldValue.filter(o => !this.activity.newValue.find(n => o.id === n.id))
      }
      return []
    },
  },
}
</script>

<style lang="scss">
.entry-log__activity__labels__container {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.entry-log__activity__labels {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 4px;
  font-weight: 600;

  .dynamic-labels {
    font-size: 12px;
    font-weight: 400;
  }
}

.entry-log__activity {
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  word-break: break-all;
}

.entry-log__activity__placeholder {
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
}
</style>
