<template>
  <b-modal
    id="add-to-entries-lists"
    v-model="showPopup"
    ok-variant="primary"
    cancel-variant="outline-dark"
    size="lg"
    no-close-on-backdrop
    :ok-disabled="saving"
    :title="$t('popups.add-to-entries-lists.title', { name: entriesList ? entriesList.name : '' })"
    :ok-title="$t('general.save')"
    :cancel-title="$t('general.cancel')"
    :static="true"
    @ok.prevent="save"
    @close="closePopup"
  >
    <b-alert
      variant="primary"
      show
      class="space-after desktop-only"
    >
      <GjIcon name="Info_fill" />
      {{ $t('lists.entry.add-info') }}
    </b-alert>
    <b-alert
      v-show="exists"
      variant="danger"
      show
      class="space-after"
    >
      <GjIcon name="Info_fill" />
      {{ $t('lists.entry.exists') }}
    </b-alert>
    <div class="entries-lists_actions space-after">
      <div class="entries-lists_actions currentDate_picker">
        <flat-pickr
          v-model="currentDatetime"
          class="form-control"
          :config="config"
        />
        <GjIcon
          name="Refresh"
          class="currentDate_icon desktop-only"
          size="20"
          @click.native="setCurrentDatetime"
        />
      </div>
      <div class="entries-list__title">
        <b-form-checkbox v-model="showOnlyActiveEntries">
          {{ $t('lists.entry.show-only-active') }}
        </b-form-checkbox>
      </div>
    </div>
    <div
      id="static-list-list-container"
      class="entries-lists--container"
    >
      <div
        v-if="loading"
        class="entries-lists__loading"
      >
        <b-spinner />
      </div>
      <ListsDraggable
        v-if="entriesList && !loading"
        v-model="entriesList.entries"
        :show-only-active-entries="showOnlyActiveEntries"
        :loading="loadingEntries"
        :current-datetime="currentDatetime"
        style="height: 100%; overflow-y: auto"
        @loadMore="fetchEntries(false)"
      />
    </div>
  </b-modal>
</template>

<script>
import PopupsMixin from '@/components/popups/PopupsMixin'
import { readStaticList, updateStaticList } from '@/codex-sdk/lists'
import {
  ENTRY_STATUSES,
  ENTRY_STATUSES_STRING,
  transformEntriesGraphQL,
  transformEntryGraphQL,
} from '@/codex-sdk/entries'
import gql from 'graphql-tag'
import ListsDraggable from '@/components/lists/ListsDraggable.vue'
import { cloneDeep } from 'lodash'
import moment from 'moment'
import { mapGetters } from 'vuex'

export default {
  name: 'AddToEntriesListsPopup',
  components: {
    ListsDraggable,
  },
  mixins: [PopupsMixin],
  data() {
    return {
      currentDatetime: moment().toISOString(),
      config: {
        enableTime: true,
      },
      pagination: {
        offset: 0,
        limit: 30,
      },
      showOnlyActiveEntries: false,
      entriesList: null,
      loading: false,
      loadingEntries: false,
      saving: false,
      exists: false,
    }
  },
  computed: {
    ...mapGetters('sites', [
      'getSite',
    ]),
  },
  async mounted() {
    this.loading = true
    await this.fetchEntriesLists()
    const entry = this.entriesList?.entries.find(e => e.entryId == this.data.entryId)
    if (entry) {
      entry.new = true
      this.exists = true
    } else {
      await this.fetchEntry()
    }
    this.loading = false
  },
  methods: {
    setCurrentDatetime() {
      this.currentDatetime = moment().toISOString()
    },
    async fetchEntriesLists() {
      try {
        const { data } = await readStaticList(this.data.id)
        this.entriesList = data
        await this.fetchEntries()
      } catch (e) {
        console.log(e)
      }
    },
    async fetchEntries(reset = false) {
      try {
        if (!this.entriesList.entries?.length || this.loadingEntries) return

        this.loadingEntries = true

        if (reset) {
          this.pagination.offset = 0
        }

        const limit = this.pagination.offset + this.pagination.limit <= this.entriesList.entries.length ? this.pagination.offset + this.pagination.limit : this.entriesList.entries.length
        const ids = this.entriesList?.entries.slice(this.pagination.offset, limit).map(e => e.entryId)

        const { data } = await this.$apollo.query({
          query: gql`
            query ($limit: Int, $ids:[String!]) {
             entryCollection (offset: 0, limit: $limit, where: { id: { in: $ids } }) {
                items {
                  id
                  system {
                    title
                    slug
                    status
                    publishedAt
                    featuredMedia {
                      id
                      type
                      url (transformation: {format: THUMBNAIL, width: 50, height: 50, resize: FILL})
                      title
                    }
                    siteId
                    modelId
                    modelAlias
                    siteId
                    createdAt
                    createdBy {
                      id
                      email
                      firstName
                      lastName
                      imageUrl
                    }
                  }
                }
                offset
                limit
                total
              }
            }`,
          variables: {
            ids,
            limit,
          },
          fetchPolicy: 'no-cache',
        })

        if (data?.entryCollection?.items) {
          transformEntriesGraphQL(data.entryCollection.items)
        }

        if (this.entriesList?.entries) {
          this.entriesList.entries.slice(this.pagination.offset, limit).forEach(element => {
            let entry = data.entryCollection.items.find(e => e.id === element.entryId)
            if (!entry) {
              entry = {
                id: element.id,
                notFound: true,
                system: {
                  title: 'Entry not found',
                  slug: '',
                  status: ENTRY_STATUSES_STRING[ENTRY_STATUSES.DELETED],
                  publishedAt: null,
                  featuredMedia: {
                    id: '', type: '', url: '', title: '',
                  },
                  model: {
                    id: '', name: '', iconId: null, alias: '',
                  },
                  createdAt: '',
                  createdBy: {
                    id: '', email: '', firstName: '', lastName: '', imageUrl: '',
                  },
                  siteId: '',
                  site: {},
                },
              }
            } else {
              entry.notFound = false
            }
            if (entry?.system?.siteId) {
              entry.system.site = this.getSite(entry.system.siteId)
            }
            if (!element.entry || !element.loaded) {
              this.$set(element, 'entry', entry)
              this.$set(element, 'loaded', true)
            }
          })
        }
        this.pagination.offset += this.pagination.limit
      } catch (e) {
        console.log(e)
      }
      this.loadingEntries = false
    },
    async fetchEntry() {
      try {
        const { data } = await this.$apollo.query({
          query: gql`
            query ($id: String!) {
                entry (id: $id) {
                id
                system {
                  title
                  slug
                  status
                  publishedAt
                  featuredMedia {
                    id
                    type
                    url (transformation: {format: THUMBNAIL})
                    title
                  }
                  siteId
                  modelId
                  modelAlias
                  createdAt
                  siteId
                  createdBy {
                    id
                    email
                    firstName
                    lastName
                    imageUrl
                  }
                }
              }
            }`,
          variables: {
            id: this.data.entryId,
          },
          fetchPolicy: 'no-cache',
        })
        if (data?.entry) {
          transformEntryGraphQL(data.entry)
          data.entry.system.site = this.getSite(data.entry.system.siteId)

          this.entriesList.entries.unshift({
            entryId: data.entry.id,
            model: data.entry.system.modelAlias,
            startAt: '',
            endAt: '',
            entry: data.entry,
            order: null,
            new: true,
          })
        }
      } catch (e) {
        console.log(e)
      }
    },
    async save() {
      const dataToSave = cloneDeep(this.entriesList)
      dataToSave.entries.forEach(e => {
        e.startAt = moment(e.startAt)
        e.endAt = moment(e.endAt)
      })
      this.saving = true

      try {
        await updateStaticList(dataToSave)
        this.closePopup()
      } catch (error) {
        console.log(error)
      } finally {
        this.saving = false
      }
    },
  },
}
</script>

<style lang="scss">

</style>
