<template>
  <b-modal
    id="at-image-editor-popup"
    v-model="showPopup"
    :title="$t('popups.imageEditorPopup.title')"
    centered
    hide-footer
    size="xl"
    class="text-center"
    :static="true"
  >
    <div class="image-edit">
      <div class="image-edit__left">
        <div
          ref="image"
          class="image-editor__container"
          :class="{
            'image-editor__container--grab': cursorMode == 'grab',
          }"
        />
      </div>
      <div class="image-edit__right toolbar">
        <div class="toolbar-left">
          <div class="toolbar-left__container">
            <b-tabs
              v-model="tabIndex"
              nav-class="toolbar-left__tabs"
            >
              <b-tab
                title-item-class="toolbar-left__tabs-tab"
                title-link-class="toolbar-left__tabs-link"
              >
                <template #title>
                  <GjIcon
                    name="Crop"
                    class="tab-icon"
                  />
                </template>
              </b-tab>
              <b-tab
                title-item-class="toolbar-left__tabs-tab"
                title-link-class="toolbar-left__tabs-link"
              >
                <template #title>
                  <GjIcon
                    name="ColorContrast"
                    class="tab-icon"
                  />
                </template>
              </b-tab>
              <b-tab
                title-item-class="toolbar-left__tabs-tab"
                title-link-class="toolbar-left__tabs-link"
              >
                <template #title>
                  <GjIcon
                    name="ColorPaintDropBlurHalf"
                    class="tab-icon"
                  />
                </template>
              </b-tab>
              <b-tab
                title-item-class="toolbar-left__tabs-tab"
                title-link-class="toolbar-left__tabs-link"
              >
                <template #title>
                  <GjIcon
                    name="IconparkTriangleRoundRectangle"
                    class="tab-icon"
                  />
                </template>
              </b-tab>
            </b-tabs>
            <div class="toolbar-left__container-actions">
              <div
                class="undo"
                @click="handleUndo"
              >
                <GjIcon name="ArrowUndo" />
              </div>
              <div
                class="redo"
                @click="handleRedo"
              >
                <GjIcon name="ArrowRedo" />
              </div>
            </div>
          </div>
        </div>
        <div class="toolbar-right">
          <div class="toolbar-right__tab-content">
            <div class="toolbar-right__tab-no-padding">
              <ImageProperties
                v-show="tabIndex === IMAGE_EDITOR_TABS.IMAGE_PROPERTIES"
                :loaded="loadedCanvas"
                :reference="$refs.image"
                :image-editor="imageEditor"
                :tab-index="tabIndex"
              />
              <Crop
                v-show="tabIndex === IMAGE_EDITOR_TABS.CROP"
                ref="crop"
                :loaded="loadedCanvas"
                :tab-index="tabIndex"
                :reference="$refs.image"
                :image-editor="imageEditor"
                :stack="stack"
              />
              <Pixelate
                v-show="tabIndex === IMAGE_EDITOR_TABS.PIXELATE"
                :loaded="loadedCanvas"
                :reference="$refs.image"
                :tab-index="tabIndex"
                :image-editor="imageEditor"
              />
              <Shapes
                v-show="tabIndex === IMAGE_EDITOR_TABS.SHAPES"
                :loaded="loadedCanvas"
                :reference="$refs.image"
                :tab-index="tabIndex"
                :image-editor="imageEditor"
              />
              <ActionButtons
                :image-editor="imageEditor"
                :additional-button="tabIndex === IMAGE_EDITOR_TABS.CROP ? $t('assets.edit-image.action-buttons.crop-image') : null"
                :image-id="imageId"
                :asset="asset"
                @additionalButtonEvent="handleCrop"
                @save="save"
                @cancel="cancel"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { ImageEditor } from '@/components/image-editor'
import ImageProperties from '@/components/assets/image-editor/ImageProperties.vue'
import Crop from '@/components/assets/image-editor/Crop.vue'
import Pixelate from '@/components/assets/image-editor/Pixelate.vue'
import ActionButtons from '@/components/assets/image-editor/ActionButtons.vue'
import gql from 'graphql-tag'
import Shapes from '@/components/assets/image-editor/Shapes.vue'
import { IMAGE_EDITOR_TABS } from '@/components/image-editor/constants'
import PopupsMixin from '../PopupsMixin'

export default {
  name: 'ImageEditorPopup',
  components: {
    Shapes,
    ActionButtons,
    ImageProperties,
    Crop,
    Pixelate,
  },
  mixins: [PopupsMixin],
  apollo: {
    asset: {
      query: gql`
        query ($id: String!) {
          asset(id: $id) {
            id
            folderId
            title
            caption
            alt
            width
            height
            tags
            contentType
            source
            author
            copyright
            tags
            size
            expiresAt
            url
            path
            type
            fileName
        }
       }
      `,
      variables() {
        return {
          id: this.imageId,
        }
      },
      fetchPolicy: 'no-cache',
      result({ data }) {
        if (data?.asset) {
          this.imageUrl = data.asset.url
        }
      },
    },
  },
  data() {
    return {
      IMAGE_EDITOR_TABS,
      tabIndex: 0,

      imageEditor: null,
      imageUrl: null,
      loadedCanvas: false,
      zoom: 1,
      scale: 1,
      panning: false,
      pointX: 0,
      pointY: 0,
      start: { x: 0, y: 0 },
      cursorMode: 'default',

      stack: {
        undo: 0,
      },
    }
  },
  computed: {
    imageId() {
      return this.data?.imageId || null
    },
  },
  watch: {
    imageUrl: {
      handler() {
        this.initiateEditor()
      },
    },
    async tabIndex(value) {
      await this.imageEditor.loadImageFromURL(this.imageEditor.toDataURL(), '#')
      this.clearCache()

      if (value == this.IMAGE_EDITOR_TABS.IMAGE_PROPERTIES) {
        this.imageEditor.stopDrawingMode()
      }
    },
  },
  destroyed() {
    this.removeEventListeners()
  },
  beforeDestroy() {
    if (this.imageEditor) {
      this.imageEditor.removeCache()
    }
  },
  methods: {
    save(data) {
      this.canClose = false
      this.closePopup(data)
    },
    cancel() {
      this.closePopup(false)
    },
    handleCrop() {
      if (this.$refs.crop) {
        this.$refs.crop.handleCrop()
      }
    },
    async initiateEditor() {
      const image = this.$refs.image
      this.imageEditor = new ImageEditor(image, {
        cssMaxWidth: 700,
        cssMaxHeight: 500,
        useCommandShortcut: false,
        selectionStyle: {
          cornerSize: 20,
          rotatingPointOffset: 70,
        },
        usageStatistics: false,
      })

      this.imageEditor.on('undoStackChanged', length => {
        this.stack.undo = length
      })
      this.clearCache()
      await this.imageEditor.loadImageFromURL(this.imageUrl, '#')

      this.imageEditor.clearUndoStack()

      window.imageEditor = this.imageEditor

      this.trackShift()
      this.addEventListeners()
      this.loadedCanvas = true
    },

    setTransform() {
      this.$refs.image.style.transform = `translate(${this.pointX}px, ${this.pointY}px) scale(${this.zoom})`
    },

    handleMousedown(e) {
      if (!e.shiftKey) return

      e.preventDefault()
      this.start = { x: e.clientX - this.pointX, y: e.clientY - this.pointY }
      this.panning = true
    },
    handleMouseup() {
      this.panning = false
    },
    handleMouseMove(e) {
      e.preventDefault()
      if (!this.panning) {
        return
      }
      this.pointX = (e.clientX - this.start.x)
      this.pointY = (e.clientY - this.start.y)
      this.setTransform()
    },

    handleZoom(e) {
      const dir = (e.deltaY < 0) ? 0.05 : -0.05
      this.zoom += dir

      // eslint-disable-next-line no-nested-ternary
      this.zoom = this.zoom <= 0.1 ? 0.1 : this.zoom >= 2 ? 2 : this.zoom
      const fixed = Number(this.zoom.toFixed(1))

      if (fixed >= 0.1 && fixed < 2) {
        this.$refs.image.style.transform = `translate(${this.pointX}px, ${this.pointY}px) scale(${this.zoom})`
      }

      e.preventDefault()
    },

    trackShift() {
      document.addEventListener('keydown', event => {
        if (event.key === 'Shift') {
          this.cursorMode = 'grab'
        }
      })

      document.addEventListener('keyup', event => {
        if (event.key === 'Shift') {
          this.cursorMode = 'default'
        }
      })
    },

    addEventListeners() {
      this.$refs.image.addEventListener('wheel', this.handleZoom)
      this.$refs.image.addEventListener('mousemove', this.handleMouseMove)
      this.$refs.image.addEventListener('mouseup', this.handleMouseup)
      this.$refs.image.addEventListener('mousedown', this.handleMousedown)
    },

    removeEventListeners() {
      if (this.$refs.image) {
        this.$refs.image.removeEventListener('mousemove', this.handleMouseMove)
        this.$refs.image.removeEventListener('mouseup', this.handleMouseup)
        this.$refs.image.removeEventListener('mousedown', this.handleMousedown)
      }
    },
    handleUndo() {
      this.imageEditor.undo()
    },
    handleRedo() {
      this.imageEditor.redo()
    },
    clearCache() {
      if (this.imageEditor) {
        this.imageEditor.clearCache()
      }
    },
  },
}
</script>

<style lang="scss">
.tab-icon {
  color: #1D79F2;
}
</style>
