<template>
  <div
    v-if="previewingVersion"
    class="previewing"
  >
    <div class="previewing-container p-1">
      <div class="previewing-details mb-1">
        <p
          class="previewing-title"
          :title="formatDateTime(previewingVersion.name)"
        >
          {{ formatDateTime(previewingVersion.name) }}
        </p>
        <p class="previewing-date">
          {{ previewingVersion.createdAt | formatDate }}
        </p>
      </div>

      <b-button
        variant="outline-secondary"
        class="previewing-button"
        @click="$emit('handleCancelPreview')"
      >
        {{ $t('general.cancel') }}
      </b-button>
    </div>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  name: 'Previewing',
  props: {
    previewing: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      previewingVersion: null,
    }
  },
  watch: {
    previewing: {
      handler() {
        this.previewingVersion = this.previewing
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    formatDateTime(date) {
      const dateObj = moment(date, true)
      if (dateObj.isValid()) {
        return dateObj.format('MMMM D, H:mm')
      }
      return date
    },
  },
}
</script>

<style lang="scss" scoped>
.previewing {
  position: relative;
  bottom: 0;
  right: 0;

  width: 100%;
  box-shadow: 0px -2px 12px -2px #E0E5EB;

  background-color: #fff;
}

.previewing-button {
  width: 100%;
}

.previewing-title {
  font-size: 14px;
  color: #031c3c;
  font-weight: bold;

  margin-bottom: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.previewing-date {
  color: #667C99;
  font-size: 12px;

  margin-bottom: 0;
}

</style>
