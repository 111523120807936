import store from '@/store/index'
import SiteListFilterComponent from './SiteList.vue'

export default class SiteListFilter {
  static COMPONENT = SiteListFilterComponent;

  static type = 'siteList'

  static OPERATORS = {
    all: 'all',
    some: 'some',
    none: 'none',
  }

  operator = null;

  value = null;

  constructor({ operator = SiteListFilter.OPERATORS.all, value = [] } = {}) {
    this.operator = operator
    this.value = value
  }

  set({ operator = SiteListFilter.OPERATORS.all, value = [] }) {
    this.operator = operator
    this.value = value
  }

  getCount() {
    return this.value.length
  }

  getValueLabel() {
    return store.state.sites.sites.find(s => s.id === this.value[0]).name
  }

  toLoad() {
    return {
      sites: this.value,
    }
  }

  asQueryParam() {
    return {
      operator: this.operator,
      value: this.value,
    }
  }

  asGraphQL() {
    switch (this.operator) {
      case SiteListFilter.OPERATORS.all:
        return {
          all: this.value,
        }
      case SiteListFilter.OPERATORS.some:
        return {
          some: this.value,
        }
      case SiteListFilter.OPERATORS.none:
        return {
          none: this.value,
        }
      default:
        return null
    }
  }

  static validate({ value }) {
    if (!value || value.length === 0) {
      return 'Value is required'
    }

    return true
  }

  static fromQueryParam({ operator, value }) {
    return new SiteListFilter({
      operator,
      value,
    })
  }
}
