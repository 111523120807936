import { FIELD_GROUPS } from '@/codex-sdk/models'
import {
  FIELD_FILTER_OPERATORS,
  NUMBER_FIELD_APPEARANCES,
  RANGE_OPERATORS,
  TYPES,
  VALUE_TYPES,
} from '@/views/models/constants'
import Initial from './Initial.vue'
import { registerField } from '../../codex-layout-editor/BuilderUtils'
import Render from './Render.vue'

import General from './General.vue'
import Appearance from './Appearance.vue'
import Validations from './Validations.vue'
import Configuration from './Configuration.vue'

registerField('codex_field', {
  displayName: 'Number',
  name: 'number',
  render: Render,
  renderEditor: Render,
  renderEditorPanel: [
    {
      icon: 'Settings', label: 'fields.codex-field-number.tabs.general', component: General, isGeneral: true,
    },
    { icon: 'Brush', label: 'fields.codex-field-number.tabs.appearance', component: Appearance },
    { icon: 'Tasks', label: 'fields.codex-field-number.tabs.validations', component: Validations },
    { icon: 'SettingsPreferencesTools', label: 'fields.codex-field-number.tabs.configuration', component: Configuration },
  ],
  renderEditorInitialPanel: [
    { icon: 'Settings', label: 'fields.codex-field-number.tabs.initial', component: Initial },
  ],
  group: FIELD_GROUPS.BASIC,
  icon: 'field-number.png',

  type: TYPES.INTEGER,
  valueType: VALUE_TYPES.SINGLE,
  defaultValue: [],

  attrs: {
    // New configs
    appearance: {
      default: NUMBER_FIELD_APPEARANCES.INPUT,
    },
    validation: {
      default: {
        required: {
          value: false,
          errorMessage: 'Number is required.',
          conditionsEnabled: false,
          conditions: [
            {
              isSystem: false,
              field: '',
              operator: FIELD_FILTER_OPERATORS.EXISTS,
              value: '',
            },
          ],
        },
        readOnly: {
          value: false,
          conditionsEnabled: false,
          conditions: [
            {
              isSystem: false,
              field: '',
              operator: FIELD_FILTER_OPERATORS.EXISTS,
              value: '',
            },
          ],
        },
        uniqueInCollection: {
          value: false,
          errorMessage: 'Number should be unique',
        },
        range: {
          isEnabled: false,
          rangeOperator: RANGE_OPERATORS.BETWEEN,
          min: 2,
          max: 10,
          errorMessage: 'Number range: 2-10',
          errorTemplate: 'Number range: {min}-{max}',
        },
        listRange: {
          isEnabled: false,
          rangeOperator: RANGE_OPERATORS.BETWEEN,
          min: 2,
          max: 5,
          exactly: 1,
          errorMessage: 'List length between: 2-5',
          errorTemplate: 'List length between: {min}-{max}',
        },
      },
    },
    placeholder: {
      default: '',
    },
    predefinedValues: {
      default: [],
    },
    stars: {
      default: 5,
    },
    helpText: {
      default: '',
    },
    helpTextDisplay: {
      default: 'tooltip',
    },
    configuration: {
      default: {
        searchable: true,
        filterable: true,
      },
    },
    hidden: {
      value: false,
      conditionsEnabled: false,
      conditions: [
        {
          isSystem: false,
          field: '',
          operator: FIELD_FILTER_OPERATORS.EXISTS,
          value: '',
        },
      ],
    },
  },
})

export const NUMBER_TYPES = Object.freeze({
  INTEGER: TYPES.INTEGER,
  DECIMAL: TYPES.DECIMAL,
})
