<template>
  <table class="rich-content__table">
    <tr
      v-for="row in block.content"
      :key="`row-${row.attrs.blockId}`"
    >
      <td
        v-for="cell in row.content"
        :key="`cell-${cell.attrs.blockId}`"
        v-bind="cell.attrs"
      >
        <component
          :is="resolveRichContentComponent(item.type)"
          v-for="item in cell.content"
          :key="`cell-item-${item.attrs.blockId}`"
          v-bind="getCustomProps(item, isNestedItem = true)"
        />
      </td>
    </tr>
  </table>
</template>

<script>
import { resolveRichContentComponent, getCustomProps } from '../richContentUtils'

export default {
  name: 'TableComponent',
  components: {
    TextComponent: () => import('./TextComponent.vue'),
    ListComponent: () => import('./ListComponent.vue'),
    DefaultComponent: () => import('./DefaultComponent.vue'),
    ReferenceField: () => import('../ReferenceField.vue'),
    MediaField: () => import('../MediaField.vue'),
    LocationField: () => import('../LocationField.vue'),
    BlockquoteComponent: () => import('./BlockquoteComponent.vue'),
  },
  props: {
    block: {
      type: [Object],
    },
  },
  setup() {
    return {
      resolveRichContentComponent,
      getCustomProps,
    }
  },
}
</script>

<style lang="scss">
table.rich-content__table td {
  border: 1px solid #ddd;
  padding: 5px;
}
</style>
