<template>
  <b-modal
    v-model="showPopup"
    :title="$t('popups.clone-entries.title')"
    :static="true"
    @ok="handleOk"
    @hidden="handleCancel"
  >
    <v-select
      v-model="toModel"
      :options="mappings"
      :clearable="false"
      :reduce="m => m.id"
      label="name"
    />
  </b-modal>
</template>

<script>
import PopupsMixin from '@/components/popups/PopupsMixin'
import gql from 'graphql-tag'

export default {
  name: 'CloneEntryPopup',
  mixins: [PopupsMixin],
  data() {
    return {
      mappings: [],
      allModels: this.$store.state.general.models,
      toModel: null,
    }
  },
  computed: {
    modelId() {
      return this.data.modelId
    },
  },
  async mounted() {
    await this.checkMappings()
  },
  methods: {
    handleCancel() {
      this.closePopup(false)
    },
    async checkMappings() {
      try {
        const { data } = await this.$apollo.query({
          query: gql`
          query Mappings($modelId: String) {
            modelMappingCollection (where: { fromModel: { eq: $modelId }}) {
              items {
                id
                fields
                fromModel
                toModel
              }
            }
          }
        `,
          variables: {
            modelId: this.modelId,
          },
        })
        const mappings = data.modelMappingCollection.items
        const localArr = []
        if (mappings && mappings.length) {
          mappings.forEach(mapping => {
            const exist = this.allModels.find(model => model.id === mapping.toModel)
            if (exist) {
              localArr.push(exist)
            }
          })
        }
        const defaultMapped = this.allModels.find(model => model.id === this.modelId)
        localArr.unshift(defaultMapped)
        this.toModel = defaultMapped.id
        this.mappings = localArr
      } catch (e) {
        console.log(e)
      }
    },
    handleOk() {
      this.closePopup(this.toModel)
    },
  },
}
</script>
