<template>
  <div class="new-filters-dropdown__container">

    <!-- filters-dropdown  >  new-filters-dropdown -->
    <!-- York  >  New York -->

    <div class="new-filters-dropdown__active-filters-wrapper">
      <!-- Active Filters -->
      <ActiveFilters
        ref="activeFilters"
        :filters="filters"
      />
      <!-- / Active Filters -->
    </div>

    <div class="new-filters-dropdown__actions-wrapper">
      <!-- Toggle Button -->
      <div
        ref="allFiltersButton"
        class="new-filters-dropdown__actions-toggle"
        @click="toggleAllFiltersPopper"
      >
        <GjIcon name="FiltersAlt" />
      </div>
      <!-- / Toggle Button -->
    </div>

    <!-- ------- -->
    <!-- Poppers -->
    <!-- ------- -->

    <!-- All Filters Dropdown  -->
    <div
      v-show="showAllFiltersPopper"
      ref="allFiltersDropdown"
      class="new-filters-dropdown"
    >
      <div class="new-filters-dropdown__body">
        <FilterItems :filters="filters.filters" />
      </div>

      <!-- Clear All Button -->
      <div
        v-show="filters.hasActiveFilters()"
        class="new-filters-dropdown__clear-all"
        @click="clearAllFilters"
      >
        <GjIcon
          name="Close"
          size="18"
          class="new-filters-dropdown__clear-all-icon"
        />
        {{ $t('filters.clear-filters') }}
      </div>
      <!-- / Clear All Button -->

    </div>
    <!-- / All Filters Dropdown -->

    <!-- Active Filter -->
    <div
      v-show="activeFilterPopper"
      ref="activeFilter"
      class="new-filters-dropdown__filter"
      :class="`location-${activeFilter.location}`"
    >
      <template v-if="activeFilterObject">

        <a
          class="new-filters-dropdown__filter-back"
          href="#"
          @click.prevent="backToFilters"
        >
          <GjIcon name="ArrowLeft" />

          {{ $t('filters.go-back') }}
        </a>

        <component
          :is="activeFilterObject.COMPONENT"
          :filter="activeFilter.filter"
          :filter-object="activeFilterObject"
          :value="activeFilter.value"
          :set-cache="filters.setCache"
          :cache="filters.cache"
        />

        <!-- Clear Button -->
        <div
          v-show="filters.isFilterActive(activeFilter.filter.name)"
          class="new-filters-dropdown__filter-clear"
          @click="clearFilter(activeFilter.filter)"
        >
          <GjIcon
            name="Close"
            size="18"
            class="new-filters-dropdown__filter-clear-icon"
          />
          {{ $t('filters.clear-filter') }}
        </div>
        <!-- / Clear Button -->

      </template>
    </div>
    <!-- / Active Filter -->

  </div>
</template>

<script>
import { createPopper } from '@popperjs/core'
import { cloneDeep } from 'lodash'
import { someParentHasClass } from '@/utils/helpers'
import FilterItems from './FilterItems.vue'
import ActiveFilters from './ActiveFilters.vue'

export default {
  name: 'Filters',
  components: { FilterItems, ActiveFilters },
  props: {
    filters: {
      type: Object,
      required: true,
    },
  },

  provide() {
    return {
      filtersObject: this.filters,
      editFilter: this.editFilter,
      activeFilter: this.activeFilter,
    }
  },

  data() {
    return {
      // Popper object for all filters
      allFiltersPopper: null,

      // Should the all filters dropdown be shown
      showAllFiltersPopper: false,

      // Popper object for active filter
      activeFilterPopper: null,

      // Active filter and value
      activeFilter: {
        filter: null,
        value: null,
        location: null,
      },
    }
  },

  computed: {
    isMobile() {
      return window.innerWidth <= 768
    },
    // Filter object
    activeFilterObject() {
      if (!this.activeFilter.filter) return null

      try {
        return this.filters.getFilterObject(this.activeFilter.filter.name)
      } catch (e) {
        return null
      }
    },
  },

  watch: {
    'activeFilter.value': {
      handler(v) {
        if (v) {
          try {
            this.filters.setActiveFilter(this.activeFilter.filter.name, v)
          } catch (e) {
            this.filters.clearActiveFilter(this.activeFilter.filter.name)
            console.error(e)
          }
        }
      },
      deep: true,
    },
  },

  mounted() {
    this.allFiltersPopper = createPopper(this.$refs.allFiltersButton, this.$refs.allFiltersDropdown, {
      placement: 'bottom-end',
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, 4],
          },
        },
      ],
    })
    document.addEventListener('mousedown', this.documentClick)

    // Attach ResizeObserver to the active filter dropdown
    const resizeObserver = new ResizeObserver(this.handleResize)
    resizeObserver.observe(this.$refs.activeFilter)
  },

  beforeDestroy() {
    document.removeEventListener('mousedown', this.documentClick)
  },

  methods: {
    // Define the resize observer callback
    handleResize(entries) {
      // eslint-disable-next-line no-restricted-syntax
      for (const entry of entries) {
        // Check if the observed element has indeed been resized
        if (entry.contentRect.width !== entry.target.offsetWidth || entry.contentRect.height !== entry.target.offsetHeight) {
          // Update the active filter popper when the size changes
          this.activeFilterPopper.update()
        }
      }
    },
    /**
     * Check document click to hide poppers
     */
    documentClick(e) {
      const filtersModalElement = someParentHasClass(this.$refs.allFiltersDropdown, 'modal')
      const filtersSidebarElement = someParentHasClass(this.$refs.allFiltersDropdown, 'b-sidebar')
      const targetModalElement = someParentHasClass(e.target, 'modal')
      const targetSidebarElement = someParentHasClass(e.target, 'b-sidebar')

      if (
        !someParentHasClass(e.target, 'new-filters-dropdown')
        && !someParentHasClass(e.target, 'new-filters-dropdown__filter')
          && !someParentHasClass(e.target, 'charmap')
        && (!targetModalElement || targetModalElement === filtersModalElement)
        && (!targetSidebarElement || targetSidebarElement === filtersSidebarElement)
      ) {
        if (this.showAllFiltersPopper) {
          this.toggleAllFiltersPopper()
        } else {
          this.destroyActiveFilterDropdown()
        }
      }
    },

    /**
     * Clear all filters
     */
    clearAllFilters() {
      this.filters.clearActiveFilters()
      this.resetActiveFilterValue()
    },

    /**
     * Clear a filter
     */
    clearFilter(filter) {
      if (!this.showAllFiltersPopper) {
        this.destroyActiveFilterDropdown()
      }

      this.filters.clearActiveFilter(filter.name)
      this.resetActiveFilterValue()
    },

    /**
     * Reset active filter value
     */
    resetActiveFilterValue() {
      if (this.activeFilter.filter) {
        this.activeFilter.value = this.filters.getFilterDefaultValue(this.activeFilter.filter.name)
      }
    },

    /**
     * Toggle All Filters Popper
     */
    toggleAllFiltersPopper() {
      this.showAllFiltersPopper = !this.showAllFiltersPopper
      this.allFiltersPopper.update()

      // Destroy active filter dropdown
      if (!this.showAllFiltersPopper) {
        this.destroyActiveFilterDropdown()
      }
    },

    /**
     * Destroy active filter dropdown
     */
    destroyActiveFilterDropdown() {
      if (this.activeFilterPopper) {
        this.activeFilterPopper.destroy()
        this.activeFilterPopper = null
        this.activeFilter.filter = null
      }
    },

    backToFilters() {
      if (this.activeFilterPopper?.state?.options?.location === 'active-filters-dropdown') {
        this.$refs.activeFilters.toggleNotFittingFiltersPopper()
      } else {
        this.showAllFiltersPopper = true
        this.allFiltersPopper.update()
      }

      this.destroyActiveFilterDropdown()
    },

    /**
     * Called when a filter is clicked
     */
    editFilter(filterItemEl, filter, popperOptions = {}, location) {
      // Destroy active filter dropdown
      if (this.activeFilterPopper) {
        this.activeFilterPopper.destroy()
      }

      // Set the location where the filter is being edited from
      this.activeFilter.location = location

      // Set the filter value
      this.activeFilter.value = cloneDeep(this.filters.getActiveFilter(filter.name))

      // If its not yet set, use defaults
      if (!this.activeFilter.value) {
        this.activeFilter.value = this.filters.getFilterDefaultValue(filter.name)
      }

      // Set the active filter
      this.activeFilter.filter = filter

      //
      let itemEl
      let positionEl
      if (this.isMobile && location !== 'active-filters-dropdown') {
        itemEl = this.$refs.allFiltersButton
        positionEl = 'bottom-end'
        this.toggleAllFiltersPopper()
        popperOptions.modifiers = [
          {
            name: 'offset',
            options: {
              offset: [0, 4],
            },
          },
        ]
      } else if (this.isMobile && location === 'active-filters-dropdown') {
        itemEl = this.$refs.allFiltersButton
        positionEl = 'bottom-end'
        popperOptions.placement = 'bottom-end'
        this.$refs.activeFilters.toggleNotFittingFiltersPopper()
        popperOptions.modifiers = [
          {
            name: 'offset',
            options: {
              offset: [0, 4],
            },
          },
        ]
      } else {
        itemEl = filterItemEl
        positionEl = 'right-start'
      }

      // Create the new active filter dropdown
      this.activeFilterPopper = createPopper(itemEl, this.$refs.activeFilter, {
        placement: positionEl,
        location,
        ...popperOptions,
      })
    },
  },
}
</script>

<style lang="scss">

.new-filters-dropdown__filter-back {
  display: flex;
  align-items: center;
  padding: 10px 10px 0;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  text-transform: uppercase;
  color: #052D61;

  svg {
    position: relative;
    top: 1px;
  }
}

// .new-filters-dropdown__filter.location-active-filters-dropdown {
//   .new-filters-dropdown__filter-back {
//     display: none;
//   }
// }

@media (min-width: 769px) {
  .new-filters-dropdown__filter-back {
    display: none;
  }
}

.new-filters-dropdown__container {
  display: flex;
}

.new-filters-dropdown__active-filters-wrapper {
  flex-grow: 1;
  min-width: 0;
}

.new-filters-dropdown__actions-wrapper {
  margin-left: 10px;
}

.new-filters-dropdown__actions-toggle {
  cursor: pointer;
}

.new-filters-dropdown {
  z-index: 100;
  min-width: 220px;

  background: #FFFFFF;
  border: 1px solid #E0E5EB;
  box-shadow: 0px 4px 25px rgba(17, 21, 56, 0.08);
  border-radius: 4px;
}

.new-filters-dropdown__body {
  padding: 10px 0;
  overflow-y: auto;
  max-height: 400px;
}

.new-filters-dropdown__filter {
  z-index: 110;
  min-width: 220px;
  max-width: 350px;

  background: #FFFFFF;
  border: 1px solid #E0E5EB;
  box-shadow: 0px 4px 25px rgba(17, 21, 56, 0.08);
  border-radius: 4px;
}

.new-filters-dropdown__filter {
  .v-select.vs--single {
    min-height: 30px;

    .vs__dropdown-toggle {
      border: 1px solid #E0E5EB;
      background: #F4F6FA;
      border-radius: 4px;
      min-height: 30px;
    }

    .vs__selected {
      font-style: normal;
      font-weight: 500 !important;
      font-size: 14px;
      color: #1D417B;

      line-height: 1em;
      margin-top: 4px;
    }
    .vs__search, .vs__search:focus {
      line-height: 1em;
    }

  }
}

.new-filters-dropdown__filter-body {
  padding: 16px 16px;
  max-height: 440px;

  .row {
    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }
}

.new-filters-dropdown__filter-body--scroll {
  overflow-y: auto;
}

.new-filters-dropdown__clear-all,
.new-filters-dropdown__filter-clear {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #052D61;

  padding: 12px 16px;
  border-top: 1px solid #E0E5EB;

  cursor: pointer;
}

.new-filters-dropdown__clear-all-icon,
.new-filters-dropdown__filter-clear-icon {
  vertical-align: bottom;
}

.new-filters-dropdown__range-divider {
  width: 10px;
  height: 1px;
  background: #E0E5EB;
  margin: 0 6px;
}
.new-filters-dropdown__checkbox-group {
  word-break:break-all;
  .custom-checkbox:not(:last-child) {
    margin-bottom: 8px;
  }
}

.new-filters-dropdown__checkbox-group-no-result {
  text-align: center;
  font-size: 14px;
  opacity: 0.5;
}

.new-filters-dropdown__date-picker {
  display: none;

  & + .flatpickr-calendar {
    box-shadow: none;
  }
}

</style>
