<template>
  <div class="overlay">
    <div class="overlay__container">
      <div class="overlay__container-left">
        <div class="curtain">
          <div class="curtain__header">
            <div class="curtain__header-left">
              <span class="icon-text">
                <GjIcon
                  id="at-media-back-button"
                  size="24"
                  name="ArrowLeft"
                  class="white-icon"
                  @click.native="checkChanges"
                />
              </span>
            </div>
          </div>
          <div
            v-if="selectedAssets.length"
            class="curtain-image image"
            style="width: 100%;"
            @click.self="checkChanges"
          >
            <template v-if="selectedAssets.length">
              <div
                v-for="currentAsset in selectedAssets"
                :key="currentAsset.id"
                class="asset__exparation-date"
                :class="{'img-content': hovering === currentAsset.id}"
                @mouseover="hovering = currentAsset.id"
                @mouseout="hovering = null"
              >
                <b-img-aspect
                  v-if="currentAsset.type === ASSET_TYPES.IMAGE || currentAsset.type === ASSET_TYPES.VIDEO"
                  :key="currentAsset.id"
                  class="curtain-image_edit"
                  aspect="80:80"
                  :src="currentAsset.url"
                />
                <div
                  v-else
                  class="curtain-image_edit"
                >
                  <b-card
                    class="file__card at-media-item-image-card-button"
                    body-class="d-flex flex-column"
                  >
                    <div class="file__card-body">
                      <b-spinner v-if="currentAsset.uploadStatus === 'uploading'" />
                      <GjIcon
                        v-else
                        name="File"
                        size="96"
                      />
                    </div>
                    <b-card-footer footer-class="file__card-footer">
                      <p class="file__card-footer-filename">
                        {{ currentAsset.fileName }}
                      </p>
                    </b-card-footer>
                  </b-card>
                </div>
                <div class="asset__status-info">
                  <span>
                    {{ $t('assets.uploaded', { date: formatDateTime(currentAsset.createdAt) }) }}
                  </span>
                  <span>
                    {{ $t('assets.expires-date', { date: formatDateTime(currentAsset.expiresAt) }) }}
                  </span>
                </div>
                <div
                  class="close_img"
                  @click="unselectAsset(currentAsset.id)"
                >
                  <GjIcon
                    name="Close"
                    size="24"
                  />
                </div>
              </div>
            </template>
            <div
              v-else
              class="no-preview-available"
            >
              <b-card body-class="no-preview-body">
                <p>
                  {{ $t('assets.no-preview') }}
                </p>
              </b-card>
            </div>
          </div>
        </div>
      </div>
      <div class="overlay__container-right">
        <b-card
          :title="$t('assets.metadata.metadata-title')"
          class="overlay__card"
          body-class="overlay__card-body"
        >
          <div class="bulk-edit__details-body">
            <b-form-group>
              <b-form-checkbox v-model="data.title.isEdited">
                {{ $t('assets.metadata.title') }}
              </b-form-checkbox>
              <b-form-input
                v-model="data.title.value"
                :disabled="!data.title.isEdited"
                type="text"
                class="full-input bulk-edit__input_margin"
              />
            </b-form-group>
            <b-form-group
              :disabled="!data.title.isEdited"
              class="bulk-edit__title-suffix"
            >
              <b-form-checkbox v-model="data.titleSuffix.isEdited">
                {{ $t('assets.metadata.title-suffix') }}
              </b-form-checkbox>
              <v-select
                v-model="data.titleSuffix.value"
                :disabled="!data.titleSuffix.isEdited"
                :options="options"
                :reduce="e => e.value"
                label="text"
                :clearable="false"
                class="bulk-edit__input_margin"
              />
            </b-form-group>

            <b-form-group>
              <b-form-checkbox v-model="data.labels.isEdited">
                {{ $t('assets.metadata.labels') }}
              </b-form-checkbox>
              <DynamicLabels
                v-if="data.labels.isEdited"
                v-model="data.labels.value"
                class="bulk-edit__input_margin"
                :entity-type="LABEL_COMPONENT_ENTITY_TYPES.ASSET"
                :no-title="true"
              />
            </b-form-group>

            <b-form-group>
              <b-form-checkbox v-model="data.caption.isEdited">
                {{ $t('assets.metadata.caption') }}
              </b-form-checkbox>
              <b-form-textarea
                v-model="data.caption.value"
                :disabled="!data.caption.isEdited"
                class="full-input bulk-edit__input_margin"
                no-resize
                trim
                rows="2"
              />
            </b-form-group>

            <b-form-group>
              <b-form-checkbox v-model="data.alt.isEdited">
                {{ $t('assets.metadata.alt') }}
              </b-form-checkbox>
              <b-form-textarea
                v-model="data.alt.value"
                :disabled="!data.alt.isEdited"
                class="full-input bulk-edit__input_margin"
                no-resize
                trim
                rows="2"
              />
            </b-form-group>

            <b-form-group>
              <b-form-checkbox v-model="data.source.isEdited">
                {{ $t('assets.metadata.source') }}
              </b-form-checkbox>
              <b-form-input
                v-model="data.source.value"
                :disabled="!data.source.isEdited"
                class="full-input bulk-edit__input_margin"
                trim
              />
            </b-form-group>
            <b-form-group>
              <b-form-checkbox v-model="data.author.isEdited">
                {{ $t('assets.metadata.author') }}
              </b-form-checkbox>
              <b-form-input
                v-model="data.author.value"
                :disabled="!data.author.isEdited"
                class="full-input bulk-edit__input_margin"
                trim
              />
            </b-form-group>
            <b-form-group>
              <b-form-checkbox v-model="data.copyright.isEdited">
                {{ $t('assets.metadata.copyright') }}
              </b-form-checkbox>
              <b-form-input
                v-model="data.copyright.value"
                :disabled="!data.copyright.isEdited"
                class="full-input bulk-edit__input_margin"
                trim
              />
            </b-form-group>
            <b-form-group>
              <b-form-checkbox v-model="data.tags.isEdited">
                {{ $t('assets.metadata.tags') }}
              </b-form-checkbox>
              <TagsSelector
                v-model="data.tags.value"
                :disabled="!data.tags.isEdited"
                :multiple="true"
                :taggable="true"
                :type="tagTypes.ASSET"
                class="bulk-edit__input_margin"
              />
            </b-form-group>
            <b-form-group>
              <b-form-checkbox v-model="data.expiresAt.isEdited">
                {{ $t('assets.metadata.expiration.label') }}
              </b-form-checkbox>
              <ExpireDateSelector
                v-model="data.expiresAt.value"
                :disabled="!data.expiresAt.isEdited"
                class="bulk-edit__input_margin"
              />
            </b-form-group>
          </div>

          <b-card-footer footer-class="overlay__card-footer">
            <b-button
              id="at-media-save-button"
              v-permission="['canEditAsset',{}]"
              variant="primary"
              @click="updateAssetsInfo"
            >
              {{ $t('general.save') }}
            </b-button>
            <b-button
              id="at-media-cancel-button"
              variant="outline-secondary"
              @click="checkChanges"
            >
              {{ $t('general.cancel') }}
            </b-button>
          </b-card-footer>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import {
  updateAssets, TITLE_SUFFIX, ASSET_TYPES, ASSET_STATUSES,
} from '@/codex-sdk/assets'
import TagsSelector, { types } from '@/components/TagsSelector.vue'
import { mapMutations, mapState } from 'vuex'
import ExpireDateSelector from '@/views/assets/components/ExpireDateSelector.vue'
import DynamicLabels from '@/components/DynamicLabels.vue'
import { LABEL_COMPONENT_ENTITY_TYPES } from '@/codex-sdk/labels'

export default {
  name: 'BulkEdit',
  components: {
    DynamicLabels,
    ExpireDateSelector,
    TagsSelector,
  },
  props: {
    selectedDataProp: {
      type: Object,
      required: false,
    },
  },
  inject: ['showConfirmPopup'],
  data() {
    return {
      LABEL_COMPONENT_ENTITY_TYPES,
      hovering: null,
      ASSET_TYPES,
      data: {
        title: {
          isEdited: false,
          value: '',
        },
        titleSuffix: {
          isEdited: false,
          value: TITLE_SUFFIX.NUMBER,
        },
        labels: {
          isEdited: false,
          value: [],
        },
        caption: {
          isEdited: false,
          value: '',
        },
        alt: {
          isEdited: false,
          value: '',
        },
        source: {
          isEdited: false,
          value: '',
        },
        author: {
          isEdited: false,
          value: '',
        },
        tags: {
          isEdited: false,
          value: '',
        },
        expiresAt: {
          isEdited: false,
          value: '',
        },
        copyright: {
          isEdited: false,
          value: '',
        },
      },

      hasUnsavedChanges: false,
      tagTypes: types,
    }
  },
  computed: {
    options() {
      return [
        { value: TITLE_SUFFIX.NUMBER, text: this.$t('assets.metadata.suffix-options.number') },
        { value: TITLE_SUFFIX.LETTER, text: this.$t('assets.metadata.suffix-options.letter') },
      ]
    },
    ...mapState('assets', [
      'selectedData',
    ]),
    selectedAssets: {
      get() {
        if (this.selectedDataProp) {
          return this.selectedDataProp.items
        }
        return this.selectedData.items
      },
      set(v) {
        if (this.selectedDataProp) {
          // use object assign
          Object.assign(this.selectedDataProp, {
            ...this.selectedDataProp,
            items: v,
            total: v?.length || 0,
          })

          return
        }
        this.SET_SELECTED_DATA({
          ...this.selectedData,
          items: v,
          total: v?.length || 0,
        })
      },
    },
  },
  watch: {
    data: {
      handler() {
        for (let i = 0; i < Object.keys(this.data).length; i++) {
          if (this.data[Object.keys(this.data)[i]].isEdited) {
            this.hasUnsavedChanges = true
          }
        }
      },
      deep: true,
    },
    'data.title.isEdited': function (v) {
      if (!v) {
        this.data.titleSuffix.isEdited = false
      }
    },
  },
  methods: {
    ...mapMutations('assets', [
      'SET_SELECTED_DATA',
    ]),
    formatDateTime(expiresAt) {
      return expiresAt != null ? this.$options.filters.formatDateTime(expiresAt) : this.$t('assets.not-set')
    },
    unselectAsset(id) {
      this.selectedAssets = this.selectedAssets.filter(image => id !== image.id)
      if (this.selectedAssets.length === 0) {
        this.$emit('updateList')
      }
    },
    async updateAssetsInfo() {
      if (!this.data.title.isEdited) {
        this.data.titleSuffix = {
          isEdited: false,
          value: TITLE_SUFFIX.NUMBER,
        }
      }
      this.data.ids = this.selectedAssets.filter(asset => asset.status !== ASSET_STATUSES.DELETED).map(a => a.id)
      try {
        const { data } = await updateAssets(this.data)
        this.selectedAssets.forEach(el => {
          const {
            title, caption, alt, source, author, tags, expiresAt, copyright, labels,
          } = data.find(e => e.id === el.id)
          Object.assign(el, {
            title, caption, alt, source, author, tags, expiresAt, copyright, labels,
          })
        })

        this.hasUnsavedChanges = false
        this.$emit('updateList')
      } catch (e) {
        console.log(e)
      }
    },
    async checkChanges() {
      if (this.hasUnsavedChanges) {
        const answer = await this.showConfirmPopup({})
        if (answer) {
          this.$emit('updateList')
        }
      } else {
        this.$emit('updateList')
      }
    },
  },
}
</script>
