<template>
  <div
    class="ai-rich-content-popup"
    @mousedown.stop
    @keydown.esc="close"
  >
    <div class="container">
      <b-input-group class="ai-rich-content-popup__input-group">
        <b-input-group-prepend>
          <AiIcon />
        </b-input-group-prepend>
        <b-form-input
          v-model="prompt"
          class="prompt-input"
          :placeholder="$t('fields.general.ai-popup.prompt')"
          @keyup.enter="generateContent(0, prompt)"
        />
        <b-input-group-append>
          <b-input-group-text class="prompt-input-append">
            <b-spinner
              v-if="loading"
              small
              class="prompt-input-append-icon"
            />
            <GjIcon
              v-else-if="prompt.length"
              name="SendMessageAltFilled"
              class="prompt-input-append-icon"
              size="22"
              @click.native.stop="generateContent(0, prompt)"
            />
          </b-input-group-text>
        </b-input-group-append>
      </b-input-group>
      <div
        v-if="error || suggestions.length"
        class="results"
      >
        <div class="results-header">
          <div class="results-header_left">
            <AiIcon
              class="results-header_left-icon"
              size="20"
              :class="{'results-header_left-icon__failed': error}"
            />
            <span class="results-header_left-title">
              {{
                error ? $t('fields.general.ai-popup.results.error.title') : $t('fields.general.ai-popup.results.success.title')
              }}
            </span>
          </div>
          <div class="results-header_right">
            <GjIcon
              v-if="suggestions[0]"
              v-b-tooltip="{title: $t('fields.general.ai-popup.actions.add'), customClass: 'b-tooltip-non-interactive'}"
              name="Plus"
              size="18"
              class="b-tooltip-non-interactive"
              @click.native="setValue(true, suggestions[0].value)"
            />
            <GjIcon
              v-if="suggestions[0]"
              v-b-tooltip="$t('fields.general.ai-popup.actions.replace')"
              name="IconparkTransform"
              size="18"
              @click.native="setValue(false, suggestions[0].value)"
            />
            <GjIcon
              v-if="showRetry"
              v-b-tooltip="$t('fields.general.ai-popup.actions.regenerate')"
              name="Refresh"
              size="18"
              class="results-header_right-icon"
              @click.native="generateContent(previousSettings.operation, previousSettings.prompt)"
            />
          </div>
        </div>
        <div class="results-container">
          <span
            v-if="error"
            class="results-error"
          >
            {{ $t('fields.general.ai-popup.results.error.description') }}
          </span>
          <div
            v-for="suggest in suggestions"
            :key="suggest.value"
            class="result-element"
          >
            <span class="result-element-text">
              {{ suggest.display }}
            </span>
          </div>
        </div>
      </div>
      <div
        v-if="!suggestions.length && !prompt.length && !loading"
        class="operations"
      >
        <span style="font-size: 12px;font-weight: 600;line-height: 18px;color: #767685;text-transform: uppercase; padding: 0 16px;">
          {{ $t('fields.general.ai-popup.results.success.action-title') }}
        </span>
        <div class="operations-elements">
          <div
            v-for="option in operations"
            :key="option.value"
            class="operations-element"
            style="position:relative; overflow: visible;"
            @click.stop="generateContent(option.value, prompt)"
            @mouseover="hoverOperation = option.value"
          >
            <AiIcons
              :name="option.icon"
              size="18"
            />
            <!--          style="color: #9747FF;"-->
            <span>{{ option.label }}</span>
            <GjIcon
              v-if="option.operations && option.operations.length"
              name="ArrowRight"
              size="18"
              style="margin-right: 0; margin-left: auto;"
            />
            <div
              v-if="option.operations && hoverOperation === option.value"
              class="operations"
              style="position:absolute;left: calc(100% + 10px);top: -15px; max-height: 300px; overflow-y: auto; width: 200px;"
            >
              <div class="operations-elements">
                <div
                  v-for="innerOption in option.operations"
                  :key="innerOption.value"
                  class="operations-element"
                  style="position:relative;"
                  @click.stop="generateContent(option.value, '', innerOption.value)"
                >
                  <span>{{ innerOption.label }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <span
      class="close-icon"
      @mousedown="close"
    >
      <GjIcon
        name="Close"
      />
    </span>
  </div>
</template>

<script>
import {
  generateRichContent,
  mapRichContentOperation,
  RICH_CONTENT_AI_OPERATION_MAPPING,
  RICH_CONTENT_AI_OPERATIONS,
} from '@/codex-sdk/ai'
import AiIcons from '@/components/ai/icons/AiIcons.vue'
import AiIcon from '@/components/ai/AiIcon.vue'

export default {
  name: 'AiContentPopup',
  inject: ['entry', 'fieldAlias', 'getEditor', 'createEntry'],
  components: {
    AiIcon,
    AiIcons,
  },
  data() {
    return {
      editor: this.getEditor().editor,

      selectedText: '',
      from: null,
      to: null,
      lastPosition: null,

      loading: false,
      prompt: '',
      operation: null,
      previousSettings: {
        prompt: '',
        operation: '',
        lang: null,
      },
      showRetry: false,
      suggestions: [],
      error: false,
      RICH_CONTENT_AI_OPERATIONS,
      hoverOperation: null,
    }
  },
  computed: {
    entryIdComputed() {
      return this.entry()?.id
    },
    fieldAliasComputed() {
      return this.fieldAlias
    },
    operations() {
      return [
        {
          label: mapRichContentOperation(RICH_CONTENT_AI_OPERATIONS.IMPROVE_WRITING),
          value: RICH_CONTENT_AI_OPERATIONS.IMPROVE_WRITING,
          icon: 'ImproveWriting',
        },
        {
          label: mapRichContentOperation(RICH_CONTENT_AI_OPERATIONS.FIX_SPELLING_AND_GRAMMAR),
          value: RICH_CONTENT_AI_OPERATIONS.FIX_SPELLING_AND_GRAMMAR,
          icon: 'FixSpellingAndGrammar',
        },
        {
          label: mapRichContentOperation(RICH_CONTENT_AI_OPERATIONS.MAKE_SHORTER),
          value: RICH_CONTENT_AI_OPERATIONS.MAKE_SHORTER,
          icon: 'MakeShorter',
        },
        {
          label: mapRichContentOperation(RICH_CONTENT_AI_OPERATIONS.MAKE_LONGER),
          value: RICH_CONTENT_AI_OPERATIONS.MAKE_LONGER,
          icon: 'MakeLonger',
        },
        {
          label: mapRichContentOperation(RICH_CONTENT_AI_OPERATIONS.MAKE_IT_PROFESSIONAL),
          value: RICH_CONTENT_AI_OPERATIONS.MAKE_IT_PROFESSIONAL,
          icon: 'MakeItProfessional',
        },
        {
          label: mapRichContentOperation(RICH_CONTENT_AI_OPERATIONS.SUMMARIZE),
          value: RICH_CONTENT_AI_OPERATIONS.SUMMARIZE,
          icon: 'Summarize',
        },
        {
          label: mapRichContentOperation(RICH_CONTENT_AI_OPERATIONS.TRANSLATE),
          value: RICH_CONTENT_AI_OPERATIONS.TRANSLATE,
          icon: 'Translate',
          operations: [
            { label: 'English', value: 'en' },
            { label: 'Spanish', value: 'es' },
            { label: 'French', value: 'fr' },
            { label: 'Albanian', value: 'sq' },
            { label: 'German', value: 'de' },
            { label: 'Italian', value: 'it' },
            { label: 'Dutch', value: 'nl' },
            { label: 'Czech', value: 'cs' },
            { label: 'Hungarian', value: 'hu' },
            { label: 'Russian', value: 'ru' },
            { label: 'Portuguese', value: 'pt' },
            { label: 'Afrikaans', value: 'af' },
            { label: 'Hausa', value: 'ha' },
            { label: 'Zulu', value: 'zu' },
            { label: 'Swahili', value: 'sw' },
            { label: 'Amharic', value: 'am' },
            { label: 'Akan', value: 'ak' },
            { label: 'Shona', value: 'sn' },
            { label: 'Luganda', value: 'lg' },
            { label: 'Arabic', value: 'ar' },
            { label: 'Lingala', value: 'ln' },
          ],
        },
        {
          label: mapRichContentOperation(RICH_CONTENT_AI_OPERATIONS.EXPLAIN_THIS),
          value: RICH_CONTENT_AI_OPERATIONS.EXPLAIN_THIS,
          icon: 'ExplainThis',
        },
        {
          label: mapRichContentOperation(RICH_CONTENT_AI_OPERATIONS.CONTINUE_WRITING),
          value: RICH_CONTENT_AI_OPERATIONS.CONTINUE_WRITING,
          icon: 'ContinueWriting',
        },
      ]
    },
  },
  mounted() {
    this.getSelection()
  },
  beforeDestroy() {
    this.close()
  },
  methods: {
    getSelection() {
      const { from, to } = this.editor.state.selection
      this.selectedText = this.editor.state.doc.textBetween(from, to).trim()
      this.from = from
      this.to = to

      let position = 0
      let n = null

      this.editor.state.doc.descendants((node, pos) => {
        n = node
        position = pos
      })
      this.lastPosition = position + n.nodeSize
    },
    close() {
      this.$emit('close')
    },
    async generateContent(operation, prompt = '', lang = null) {
      try {
        if (this.loading || (operation === RICH_CONTENT_AI_OPERATIONS.TRANSLATE && !lang)) return

        if (!this.entry()?.id) {
          await this.createEntry()
        }

        this.error = false
        this.loading = true

        this.previousSettings.prompt = prompt
        this.previousSettings.operation = operation
        this.previousSettings.lang = operation === RICH_CONTENT_AI_OPERATIONS.TRANSLATE ? lang : null

        const { data } = await generateRichContent({
          fieldAlias: this.fieldAliasComputed,
          entryId: this.entryIdComputed,
          prompt: this.previousSettings.prompt,
          selectedText: this.selectedText,
          operation: RICH_CONTENT_AI_OPERATION_MAPPING.toNumber(this.previousSettings.operation),
          lang: this.previousSettings.lang,
        }, { successTitle: false })
        this.suggestions = [{
          value: data.response,
          display: data.response.replace(/(<([^>]+)>)/ig, ''),
        }]
      } catch (e) {
        this.error = true
        this.suggestions = []
        console.log(e)
      }
      this.showRetry = true
      this.loading = false
    },
    setValue(add, value) {
      if (this.getEditor()) {
        let startPosition
        let endPosition

        if (add) {
          if (this.selectedText?.length > 0) {
            startPosition = this.to
          } else {
            startPosition = this.lastPosition
          }
        } else if (this.selectedText?.length > 0) {
          startPosition = this.from
          endPosition = this.to
        } else {
          startPosition = 1
          endPosition = this.lastPosition
        }

        this.getEditor().upsertNodes(add, value, startPosition, endPosition)
      }
      this.close()
    },
  },
}
</script>

<style lang="scss">
.menububble__link-popup .ai-rich-content-popup {
  right: 0;
  left: 0;
}

.ai-rich-content-popup {
  position: absolute;
  top: calc(100% + 10px);
  left: 10px;
  width: 500px;
  margin-top: 4px;
  z-index: 10;
  display: flex;
  flex-direction: row;
  gap: 8px;

  .close-icon {
    cursor: pointer;
    background-color: white;
    border-radius: 50%;
    height: 24px;
    width: 24px;
    border: 1px solid #E0E5EB;
    box-shadow: 0 4px 20px rgba(5, 45, 97, 0.07) !important;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 8px;
  }

  .container {
    display: flex;
    flex-direction: column;
    gap: inherit;
    padding: 0;

  .btn-group:after {
    content: none !important;
  }

  .prompt-input {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    border: none;
    padding-left: 0;
  }

  .prompt-input-append {
    border: none;
    padding: 0;
  }

  .prompt-input-append-icon {
    color: #206ED5;
  }

  svg.prompt-input-append-icon {
    cursor: pointer;
  }

  .results {
    background: #FFFFFF;
    border: 1px solid #E0E5EB;
    box-shadow: 0 4px 20px rgba(5, 45, 97, 0.07) !important;
    border-radius: 8px;
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .results-header {
    display: flex;
    justify-content: space-between
  }

  .results-header_left {
    display: flex;
    gap: 8px;
    align-items: center;
  }

  .results-header_left-icon {
    color: #6A9EE3;
  }

  .results-header_left-icon__failed {
    color: #676777;
  }

  .results-header_left-title {
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    text-align: left;
    color: #1E1E36;
  }

  .results-header_right {
    display: flex;
    gap: 8px;
    align-items: center;
    cursor: pointer;
    color: #206ED5;
  }

  .results-header_right-label {
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    text-align: center;
  }

  .results-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .results-error {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    margin-left: 28px;
  }

  .result-element {
    border: 1px solid #DEDEE2;
    border-radius: 6px;
    padding: 4px 8px;
    display: flex;
    flex-direction: row;
    gap: 8px;
    cursor: pointer;
    max-height: 300px;
    overflow-y: auto;
  }

  .result-element-text {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    color: #1E1E36;
    text-wrap: wrap;
  }

  .result-element-button {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    color: #206ED5;
  }

  .operations {
    width: auto;
    display: flex;
    flex-direction: column;
    gap: 4px;

    background: #FFFFFF;
    border: 1px solid #E0E5EB;
    box-shadow: 0 4px 20px rgba(5, 45, 97, 0.07) !important;
    border-radius: 8px;
    padding: 16px 0 12px;
  }

  .operations-elements {
    padding: 0 8px;
  }

  .operations-element {
    position: relative;

    display: flex;
    flex-direction: row;
    gap: 8px;
    cursor: pointer;
    max-height: 300px;
    padding: 4px 8px;
    overflow-y: auto;
    align-items: center;

    span {
      color: #1E1E36;
    }
  }

  .operations-element:hover {
    background-color: #DEDEE2;
    border-radius: 4px;
  }

  .ai-rich-content-popup__input-group {
    background: #FFFFFF;
    border: 1px solid #E0E5EB;
    box-shadow: 0 4px 20px rgba(5, 45, 97, 0.07) !important;
    border-radius: 4px;
    padding: 4px 12px;

    .input-group-prepend {
      display: flex;;
      align-items: center;
      margin-right: 10px;
    }
  }
  }
}

</style>
