import state from './modulesUsersState'
import mutations from './modulesUsersMutations'
import actions from './modulesUsersActions'
import getters from './modulesUsersGetters'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
