<template>
  <div
    v-if="unpublishedEntries && unpublishedEntries.length > 0"
    class="modal-scroll-body entries-list-container"
  >
    <p class="unscheduled-entries-label">
      {{ $tc(status == ENTRY_STATUSES.PUBLISHED? 'entries.unpublished-entries' : 'entries.unscheduled-entries', unpublishedEntries.length, { count: unpublishedEntries.length }) }}
    </p>
    <div
      v-for="entry in unpublishedEntries"
      :key="entry.id"
      class="search-entries__unselected-item"
    >
      <span class="search-entries__item-icon">
        <GjIcon
          size="17"
          :name="getEntryModel(entry).iconId"
        />
      </span>
      <div class="search-entries__item-name">
        <p
          v-if="getEntryModel(entry).name"
          class="stacked-card__title-model"
        >
          {{ getEntryModel(entry).name }}
        </p>

        {{ entry.system.title }}
        <p
          v-if="!entry.system.title"
          class="stacked-card__title-untitled"
        >
          {{ $t('entries.untitled') }}
        </p>
      </div>
      <StatusChip
        :entry="entry"
        :is-query="true"
        :with-background="true"
        class="search-entries__item-status"
        :scheduled-versions="entry.system.scheduledVersions && entry.system.scheduledVersions.items"
      />
    </div>
  </div>
</template>

<script>
import StatusChip from '@/views/entries/components/StatusChip.vue'
import { ENTRY_STATUSES } from '@/codex-sdk/entries'
import { getEntryModel } from '@/utils/helpers'

export default {
  name: 'EntriesList',
  components: { StatusChip },
  props: {
    status: {
      type: [String, Number],
      default: null,
    },
    unpublishedEntries: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      ENTRY_STATUSES,
      getEntryModel,
    }
  },
}
</script>

<style lang="scss">
.entries-list-container {
  .search-entries__unselected-items {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
    height: calc(100% - 1rem);
    overflow-y: scroll;
  }

  .search-entries__unselected-item {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    background: #F4F6FA;
    padding: 10px;
  }

  .search-entries__item-icon {
    background-color: #fff;
    color: #5E89CE;
    border-radius: 4px;
    padding: 6px;
    width: 30px;
    height: 30px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
  }

  .search-entries__item-name {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #052D61;
  }

  .search-entries__item-status {
    display: flex;
    justify-content: flex-end;
    margin: 6px 6px 6px auto;
  }

  .unscheduled-entries-label {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #052D61;
    margin-bottom: 12px;
  }

  .entry-badge-container .entry-badge span {
    font-size: 12px;
    line-height: 16px;
  }
}
</style>
