<template>
  <div class="entries-card reference-field__container">
    <div class="entries-list">
      <template v-if="!loading && value">
        <draggable
          v-if="references.length > 0"
          v-model="references"
          class="entries-list__draggable"
          :disabled="disabled"
          :group="{pull: false, push: false}"
        >
          <div
            v-for="(entry, index) in references"
            :key="`${entry.id}${index}`"
            class="entries-list__item cursor-pointer"
            @click="editEntry(entry, index)"
          >
            <div class="entries-list__item-icon">
              <GjIcon
                name="Drag"
                size="24"
                style="width: 34px;"
              />
              <div class="entries-list__item-featured-media">
                <b-img-aspect
                  v-if="entry.system.featuredMedia"
                  :key="entry.id + entry.system.featuredMedia.url"
                  aspect="1:1"
                  style="width: 34px;"
                  :src="entry.system.featuredMedia.url"
                />
                <GjIcon
                  v-else
                  class="entries-list__item-model-icon"
                  size="34"
                  style="width: 34px;"
                  :name="getEntryModel(entry).iconId"
                />
                <div
                  v-if="includeSiteLogo && entry.system.site"
                  class="entries-list__item-site-logo"
                >
                  <b-img-aspect
                    :src="entry.system.site.logo"
                    aspect="1:1"
                    style="width: 100%"
                    :title="entry.system.site.name"
                  />
                </div>
              </div>
            </div>
            <div class="entries-list__item-content">
              <div class="entries-list__item-alias">
                {{ getEntryModel(entry).name }}
              </div>
              <div
                v-if="isNotFound(entry)"
                class="entries-list__item_not-found"
              >
                {{ $t('fields.codex-field-reference.preview.models.entry-not-found', {
                  id: entry.id,
                  model: entry.system.modelAlias
                }) }}
              </div>
              <div
                v-else-if="!entry.system.title"
                class="entries-list__item_untitled"
              >
                {{ $t('entries.untitled') }}
              </div>
              <div
                v-else
                class="entries-list__item-name"
                :title="entry.system.title"
              >
                {{ entry.system.title }}
              </div>
            </div>
            <div class="entries-list__item-status">
              <StatusChip
                :entry="entry"
                :is-query="true"
                :scheduled-versions="entry.system.scheduledVersions && entry.system.scheduledVersions.items"
              />
            </div>
            <div style="width: 20px">
              <router-link
                v-if="!isNotFound(entry)"
                :to="{name: 'entries-edit', params: { viewId: 'all', model: entry.system.modelAlias, id: entry.id,siteName: entry.system.siteId }}"
                target="_blank"
                class="entries-list__item-redirect"
                @click.native.stop
              >
                <GjIcon
                  name="ArrowExportPopout"
                  size="20"
                />
              </router-link>
            </div>
            <div
              v-if="!disabled"
              class="entries-list__item-delete"
              @click.stop="removeEntry(index)"
            >
              <GjIcon
                name="Delete"
                size="20"
              />
            </div>
          </div>
        </draggable>
        <div
          v-else
          class="entries-list__empty"
        >
          {{ $t('fields.codex-field-reference.preview.models.no-entries-selected') }}
        </div>
        <div
          v-if="includeActionButtons"
          class="entries-list__add hide-in-quick-view"
        >
          <span
            v-if="!disableEntryBrowse && !disabled"
            @click="addEntries"
          >
            <GjIcon
              name="IconparkListAdd"
              size="20"
            />
            {{ $t('fields.codex-field-reference.preview.models.browse') }}
          </span>
          <b-dropdown
            v-if="!disableEntryCreation && !disabled"
            variant="default"
            size="sm"
            class="entries-list__create"
            no-caret
          >
            <template #button-content>
              <span class="model-name">
                <GjIcon
                  name="Plus"
                  size="20"
                />
                {{ $t('fields.codex-field-reference.preview.models.create') }}
              </span>
            </template>
            <b-input
              v-model="search"
              :placeholder="$t('entries.search')"
              class="entries-dropdown__search"
              :autofocus="true"
            />
            <div
              v-if="allModels.length <=0"
              class="entries-dropdown__no-results"
            >
              <p>{{ $t('entries.no-result') }}</p>
            </div>
            <b-dropdown-item
              v-for="model in modelsArray"
              :key="model.id"
              :title="model.name"
              @click.native="createEntry(model)"
            >
              <GjIcon
                :key="model.iconId"
                :name="model.iconId"
              />
              {{ model.name }}
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </template>
      <div
        v-else
        class="entries-list__loading"
      >
        <b-spinner
          variant="secondary"
          :label="$t('general.loading')"
          small
        />
        {{ $t('general.loading') }}
      </div>
    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import StatusChip from '@/views/entries/components/StatusChip.vue'
import { VALUE_TYPES } from '@/views/models/constants'
import { ENTRY_STATUSES, ENTRY_STATUSES_STRING } from '@/codex-sdk/entries'
import { getEntryModel } from '@/utils/helpers'

export default {
  name: 'ReferenceComponent',
  inject: ['showEntryEditorPopup'],
  components: {
    draggable,
    StatusChip,
  },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    valueType: {
      type: Number,
      default: VALUE_TYPES.LIST,
    },
    models: {
      type: Array,
      default: null,
    },
    disableEntryCreation: {
      type: Boolean,
      default: false,
    },
    disableEntryBrowse: {
      type: Boolean,
      default: false,
    },
    includeSiteLogo: {
      type: Boolean,
      default: false,
    },
    includeActionButtons: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    let allModels = [...this.$store.state.general.models]
    if (this.models?.length) {
      allModels = allModels.filter(e => this.models.includes(e.alias))
    }
    return {
      allModels,
      search: '',
      ENTRY_STATUSES,
      getEntryModel,
    }
  },
  computed: {
    references: {
      get() { return this.value },
      set(v) { this.$emit('input', v) },
    },
    modelsArray() {
      return this.allModels.filter(item => item.name.toLowerCase().includes(this.search.toLowerCase()))
    },
  },
  methods: {
    async editEntry(entry, index) {
      if (this.disabled) return
      const data = await this.showEntryEditorPopup({
        model: entry.system.modelId,
        id: entry.id,
        siteId: entry.system.siteId,
      })
      if (data.action === 'deleted') {
        this.removeEntry(index)
      } else {
        Object.assign(entry.system, data.entry.system)
        entry.system.status = ENTRY_STATUSES_STRING[data.entry.system.status]
      }
    },
    async createEntry(model) {
      const { action, entry } = await this.showEntryEditorPopup({
        model: model.id,
        id: 'create',
      })
      entry.system.status = ENTRY_STATUSES_STRING[entry.system.status]
      if ((action == 'created' || action == 'updated') && entry) {
        if (this.valueType == VALUE_TYPES.SINGLE) {
          this.references = [entry]
        } else {
          this.references = [...this.references, entry]
        }
      }
    },
    removeEntry(index) {
      if (this.disabled) return
      this.$emit('remove', index)
    },
    addEntries() {
      this.$emit('add')
    },
    isNotFound(entry) {
      return entry.system.status === ENTRY_STATUSES.DELETED || entry.system.status === ENTRY_STATUSES_STRING[ENTRY_STATUSES.DELETED]
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
