import ability from '@/libs/acl/ability'
import { pr } from './config'

// * Create entity file, and can functions
// * Import and add them to allCanFunctions object down below
// * Add defaults into the permissions array down below
// * Implement v-permission directives on neccessary buttons
// * Implement permissions on entities sdk functions
// * Implement permissions on navigation and route

export const QuickLinks = Object.freeze({
  VIEW: 'QuickLink.View',
  MANAGE: 'QuickLink.Manage',
})

export const QuickLinksTooltips = {
  [QuickLinks.VIEW]: 'Allows a user to view all quick links, in  ‘view only’ mode,  without performing any operations',
  [QuickLinks.MANAGE]: 'Allows a user to perform all CRUD operations in quick links, such as create, edit, update and delete.',
}

/**
 * _options
 *    entity = undefined
 *    showToast = false
 */

/**
 * Can VIEW QuickLinks Catgories
 * */
export function canViewQuickLinksCategories(_options = {}) {
  const { entity, ...options } = _options

  return pr(
    ability.can(QuickLinks.VIEW)
    || ability.can(QuickLinks.MANAGE),

    'You have no permission to view quick links categories',

    options,
  )
}

/**
 * Can CREATE QuickLinks Catgories
 * */
export function canCreateQuickLinksCategories(_options = {}) {
  const { entity, ...options } = _options

  return pr(
    ability.can(QuickLinks.MANAGE),

    'You have no permission to create quick links categories',

    options,
  )
}

/**
 * Can EDIT QuickLinks Catgories
 * */
export function canEditQuickLinksCategories(_options = {}) {
  const { entity, ...options } = _options

  return pr(
    ability.can(QuickLinks.MANAGE),

    'You have no permission to edit quick links categories',

    options,
  )
}

/**
 * Can DELETE QuickLinks Catgories
 * */
export function canDeleteQuickLinksCategories(_options = {}) {
  const { entity, ...options } = _options

  return pr(
    ability.can(QuickLinks.MANAGE),

    'You have no permission to delete quick links categories',

    options,
  )
}

/**
 * Can VIEW QuickLinks
 * */
export function canViewQuickLinks(_options = {}) {
  const { entity, ...options } = _options

  return pr(
    ability.can(QuickLinks.VIEW)
    || ability.can(QuickLinks.MANAGE),

    'You have no permission to view quick links',

    options,
  )
}

/**
 * Can CREATE QuickLinks
 * */
export function canCreateQuickLinks(_options = {}) {
  const { entity, ...options } = _options

  return pr(
    ability.can(QuickLinks.MANAGE),

    'You have no permission to create quick links',

    options,
  )
}

/**
 * Can EDIT QuickLinks
 * */
export function canEditQuickLinks(_options = {}) {
  const { entity, ...options } = _options

  return pr(
    ability.can(QuickLinks.MANAGE),

    'You have no permission to edit quick links',

    options,
  )
}

/**
 * Can DELETE QuickLinks
 * */
export function canDeleteQuickLinks(_options = {}) {
  const { entity, ...options } = _options

  return pr(
    ability.can(QuickLinks.MANAGE),

    'You have no permission to delete quick links',

    options,
  )
}
