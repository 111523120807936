import ability from '@/libs/acl/ability'
import { isGjirafaUser } from '@/auth/utils'
import { pr } from './config'

/**
 localization_manage
 */
// For regEx search in VSCode: canViewModel|canCreateModel|canEditModel|canDeleteModel

// * Create entity file, and can functions
// * Import and add them to allCanFunctions object down below
// * Add defaults into the permissions array down below
// * Implement v-permission directives on neccessary buttons
// * Implement permissions on entities sdk functions
// * Implement permissions on navigation and route

export const Localization = Object.freeze({
  MANAGE: 'localization_manage',
})

export const LocalizationTooltips = {
  [Localization.MANAGE]: '',
}

/**
 * _options
 *    entity = undefined
 *    showToast = false
 */

/**
 * Can MANAGE Localization
 * */
export function canManageLocalizations(_options = {}) {
  const { entity, ...options } = _options

  return pr(
    isGjirafaUser() && ability.can(Localization.MANAGE),

    'You have no permission to manage localization',

    options,
  )
}
