<template>
  <b-modal
    id="entry-quick-schedule"
    v-model="showPopup"
    :title="$t('entries.save-button.schedule-modal.title')"
    :cancel-title="$t('general.cancel')"
    size="sm"
    cancel-variant="outline-primary"
    :ok-title="$t('entries.save-button.schedule-modal.ok-title')"
    ok-variant="primary"
    :static="true"
    @ok.prevent="schedule"
  >
    <b-form-group>
      <b-form-radio
        v-model="scheduleType"
        :disabled="isPublished"
        value="publish"
      >
        {{ $t('entries.save-button.schedule-modal.publish') }}
      </b-form-radio>
    </b-form-group>
    <b-form-group
      v-if="scheduleType === 'publish'"
      :label="$t('entries.save-button.schedule-modal.date-label')"
    >
      <flat-pickr
        v-model="publishDate"
        class="form-control"
        :disabled="isPublished"
        :config="publishedAtConfig"
      />
    </b-form-group>

    <template v-if="showScheduleUnpublish">
      <b-form-group>
        <b-form-radio
          v-model="scheduleType"
          value="unpublish"
        >
          {{ $t('entries.save-button.schedule-modal.unpublish') }}
        </b-form-radio>
      </b-form-group>
      <b-form-group
        v-if="scheduleType === 'unpublish'"
        :label="$t('entries.save-button.schedule-modal.date-label')"
      >
        <flat-pickr
          v-model="unpublishDate"
          class="form-control"
          :config="unpublishedAtConfig"
        />
      </b-form-group>
    </template>
  </b-modal>
</template>

<script>
import { ENTRY_STATUSES, ENTRY_STATUSES_STRING } from '@/codex-sdk/entries'
import moment from 'moment'
import PopupsMixin from '@/components/popups/PopupsMixin'
import { CMI_ORGANIZATIONS } from '@/utils/constants'

export default {
  name: 'QuickScheduler',
  inject: ['toastNotification'],
  mixins: [PopupsMixin],
  data() {
    const isPublished = this.data.entry?.system?.status === ENTRY_STATUSES_STRING[ENTRY_STATUSES.PUBLISHED]
    const showScheduleUnpublish = !CMI_ORGANIZATIONS.includes(this.$store.state.general.currentOrganization.alias)
    return {
      showScheduleUnpublish,
      isPublished,
      scheduleType: isPublished ? 'unpublish' : 'publish',
      publishDate: moment(Date.now()).add(1, 'm').set('seconds', 0).set('milliseconds', 0)
        .toDate(),
      unpublishDate: moment(Date.now()).add(1, 'm').set('seconds', 0).set('milliseconds', 0)
        .toDate(),
      datePickrConfig: {
        enableTime: true,
      },
    }
  },
  computed: {
    entry() {
      return this.data.entry
    },
    currentDateTime() {
      return moment().toDate()
    },
    publishedAtConfig() {
      return {
        ...this.datePickrConfig,
        minDate: this.isPublished ? null : this.currentDateTime,
      }
    },
    unpublishedAtConfig() {
      if (this.isPublished) {
        const minDate = moment.max(moment(this.entry?.system?.publishedAt), moment(this.currentDateTime), moment(this.publishDate)).toDate()
        return { ...this.datePickrConfig, minDate }
      }
      const minDate = moment.max(moment(this.currentDateTime), moment(this.publishDate)).toDate()
      return { ...this.datePickrConfig, minDate }
    },
  },
  methods: {
    async schedule() {
      const max = this.isPublished ? moment.max(moment(this.entry?.system?.publishedAt), moment()) : moment()

      if (this.scheduleType === 'publish') {
        if (this.isPublished || !this.publishDate || moment(this.publishDate).isSameOrBefore(moment())) {
          this.toastNotification({
            icon: 'XIcon',
            variant: 'danger',
            title: 'Error',
            text: this.$t('entries.save-button.schedule-past-date'),
          })
          return
        }
      } else if (!this.unpublishDate || moment(this.unpublishDate).isSameOrBefore(moment(max))) {
        this.toastNotification({
          icon: 'XIcon',
          variant: 'danger',
          title: 'Error',
          text: this.$t('entries.save-button.schedule-past-date'),
        })
        return
      }

      this.closePopup({
        publishedAt: this.scheduleType === 'publish' ? moment(this.publishDate).toISOString() : null,
        unpublishedAt: this.scheduleType === 'unpublish' ? moment(this.unpublishDate).toISOString() : null,
      })
    },
  },
}
</script>
