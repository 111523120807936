import MissingBlock from './MissingBlock.vue'
import CodexContentEditor from '../../CodexContentEditor'

CodexContentEditor.registerWidget('codex', {
  _internal: true,

  displayName: 'codex-editor.block-names.missing-block',
  name: 'missing_block',
  icon: 'List',
  // eslint-disable-next-line global-require
  image: require('../../icons/bulleted-list/icon.svg'),
  attrs: {
    original: {
      default: {},
    },
  },
  renderEditor: MissingBlock,
})
