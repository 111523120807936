<template>
  <div class="new-filters-dropdown__filter-body new-filters-dropdown__filter-body--scroll">
    <b-row
      align-v="center"
      no-gutters
    >
      <b-col cols="12">
        <b-checkbox-group
          v-model="value.value"
          class="new-filters-dropdown__checkbox-group"
          :options="options"
          stacked
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapTagSourceLabel, TAG_SOURCES } from '@/codex-sdk/tags'

export default {
  props: {
    filter: {
      type: Object,
      required: true,
    },
    filterObject: {
      type: [Object, Function],
      required: true,
    },
    value: {
      type: Object,
      required: true,
    },
    cache: {
      type: Object,
      required: true,
    },
    setCache: {
      type: Function,
      required: true,
    },
  },
  computed: {
    options() {
      return [
        {
          value: TAG_SOURCES.CODEX,
          text: this.$t(mapTagSourceLabel(TAG_SOURCES.CODEX)),
        },
        {
          value: TAG_SOURCES.DANDELION,
          text: this.$t(mapTagSourceLabel(TAG_SOURCES.DANDELION)),
        },
        {
          value: TAG_SOURCES.GENEA,
          text: this.$t(mapTagSourceLabel(TAG_SOURCES.GENEA)),
        },
      ]
    },
  },
}
</script>

<style lang="scss">
.new-filters-dropdown__media-item {
  display: flex;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 0.5rem;
  }
}
.new-filters-dropdown__media-item-avatar {
  margin-right: 10px;
}
.new-filters-dropdown__media-item-title {
  flex-grow: 1;
  margin: 0;

  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #1D417B;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.new-filters-dropdown__media-item-remove {
  cursor: pointer;
  opacity: 1;
  transition: color 0.3s ease;

  &:hover {
    opacity: 0.8;
  }
}
</style>
