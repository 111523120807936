<template>
  <div
    class="new-filters-dropdown__filter-body"
    :class="{'new-filters-dropdown__filter-body--scroll': value.value && value.value.length > 3}"
  >

    <b-row>
      <b-col cols="12">
        <vSelect
          v-model="value.operator"
          :options="options"
          :clearable="false"
          :reduce="option => option.value"
          :searchable="false"
          label="label"
        />
      </b-col>
    </b-row>

    <b-row
      v-if="value.value && value.value.length && !isExistsOperator "
      align-v="center"
      no-gutters
    >
      <b-col cols="12">
        <div
          v-for="objValue in objectValues"
          :key="objValue.id"
          class="new-filters-dropdown__media-item"
        >
          <b-avatar
            :src="objValue.url"
            class="new-filters-dropdown__media-item-avatar"
          />
          <h4 class="new-filters-dropdown__media-item-title">
            {{ objValue.title }}
          </h4>
          <div
            class="new-filters-dropdown__media-item-remove"
            @click="removeImage(objValue.id)"
          >
            <GjIcon
              name="Close"
              size="20"
            />
          </div>
        </div>
      </b-col>
    </b-row>

    <b-row
      v-if="!isExistsOperator"
      align-v="center"
      no-gutters
    >
      <b-button
        size="sm"
        variant="primary"
        block
        @click="addImages"
      >
        {{ $t('filters.assets.add') }}
      </b-button>
    </b-row>

  </div>
</template>

<script>
import { ASSET_TYPES } from '@/codex-sdk/assets'
import MediaFilter from './MediaFilter'

export default {
  props: {
    filter: {
      type: Object,
      required: true,
    },
    filterObject: {
      type: [Object, Function],
      required: true,
    },
    value: {
      type: Object,
      required: true,
    },
    cache: {
      type: Object,
      required: true,
    },
    setCache: {
      type: Function,
      required: true,
    },
  },
  inject: ['showAssetsPopup'],
  computed: {
    options() {
      return [
        {
          label: this.$t('filters.include'),
          value: MediaFilter.OPERATORS.include,
        },
        {
          label: this.$t('filters.exclude'),
          value: MediaFilter.OPERATORS.exclude,
        },
        {
          label: this.$t('filters.is-not-empty'),
          value: MediaFilter.OPERATORS.exists,
        },
        {
          label: this.$t('filters.is-empty'),
          value: MediaFilter.OPERATORS.notExists,
        },
      ]
    },

    objectValues() {
      if (!this.value.value) return []

      return this.value.value.map(mediaId => this.cache[mediaId] || { id: mediaId })
    },
    isExistsOperator() {
      return this.value.operator === MediaFilter.OPERATORS.exists || this.value.operator === MediaFilter.OPERATORS.notExists
    },
  },
  methods: {
    removeImage(id) {
      this.value.value = this.value.value.filter(mediaId => mediaId !== id)
    },
    async addImages() {
      const types = []
      if (this.filter?.validations?.fileType?.image) {
        types.push(ASSET_TYPES.IMAGE)
      }
      if (this.filter?.validations?.fileType?.files) {
        types.push(ASSET_TYPES.FILE)
      }
      if (this.filter?.validations?.fileType?.video) {
        types.push(ASSET_TYPES.VIDEO)
      }
      if (this.filter?.validations?.fileType?.playlists) {
        types.push(ASSET_TYPES.VIDEO_PLAYLIST)
      }
      if (this.filter?.validations?.fileType?.audio) {
        types.push(ASSET_TYPES.AUDIO)
      }
      if (this.filter?.validations?.fileType?.podcast) {
        types.push(ASSET_TYPES.PODCAST)
      }
      const assets = await this.showAssetsPopup({ selectedIds: this.value.value, types: this.filter?.validations?.fileType?.isEnabled ? types : null })

      if (assets) {
        this.value.value = assets.map(asset => {
          this.setCache(asset)
          return asset.id
        })
      }
    },
  },
}
</script>

<style lang="scss">
.new-filters-dropdown__media-item {
  display: flex;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 0.5rem;
  }
}
.new-filters-dropdown__media-item-avatar {
  margin-right: 10px;
}
.new-filters-dropdown__media-item-title {
  flex-grow: 1;
  margin: 0;

  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #1D417B;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.new-filters-dropdown__media-item-remove {
  cursor: pointer;
  opacity: 1;
  transition: color 0.3s ease;

  &:hover {
    opacity: 0.8;
  }
}
</style>
