<template>
  <div class="popups">
    <template v-for="(popup, idx) in popups">
      <component
        :is="popup.popup"
        :key="popup.id"
        :data="popup.data"
        :class="`popups__item popup--${idx}`"
        :is-last="idx === popups.length - 1"
        @close="(data) => closePopup(popup, data)"
      />
    </template>
    <slot />
  </div>
</template>

<script>
import LabelsPopup from '@/components/popups/labels-popup/LabelsPopup.vue'
import BulkEditLabelsPopup from '@/components/popups/labels-popup/BulkEditLabelsPopup.vue'
import QuickScheduler from '@/components/QuickScheduler.vue'
import ModelMappingSidebar from '@/views/models/ModelMappingSidebar.vue'
import { GenerateUUID } from '@/utils/helpers'
import TestWebhook from '@/views/webhooks/TestWebhook.vue'
import EditVersionNamePopup from '@/views/entries/versions/EditVersionNamePopup.vue'
import EditAssetPopup from '@/components/popups/edit-asset-popup/EditAssetPopup.vue'
import EditTag from '@/views/tags/popups/EditTag.vue'
import MergeTag from '@/views/tags/popups/MergeTag.vue'
import CreateJobPopup from '@/views/jobs/components/CreateJobPopup.vue'
import ViewJobPopup from '@/views/jobs/components/ViewJobPopup.vue'
import CreateApiKeyPopup from '@/views/api-keys/CreateApiKeyPopup.vue'
import EditAuthorPopup from '@/components/popups/edit-author-popup/EditAuthorPopup.vue'
import AuthorsPopup from '@/components/popups/authors-popup/AuthorsPopup.vue'
import ScheduleEntryPopup from '@/views/entries/components/ScheduleEntryPopup.vue'
import ConfirmDeletePopup from './confirm-delete-popup/ConfirmDeletePopup.vue'
import ConfirmPopup from './confirm-popup/ConfirmPopup.vue'
import FolderPopup from './folder-popup/FolderPopup.vue'
import PromptPopup from './prompt-popup/PromptPopup.vue'
import VideoPlatformPopup from '../../views/all-integrations/vp-integration/VPPlayerModal.vue'
import VPUploaderPopup from '../../views/all-integrations/vp-uploader-integration/VPUploaderModal.vue'
import VPUploadPopup from './vp-upload/VpUploadPopup.vue'
import VPProgressPopup from './vp-upload/VpProgressPopup.vue'
import ProlexisPopup from '../../views/all-integrations/prolexis-integration/ProlexisModal.vue'
import GeneeaPopup from '../../views/all-integrations/gennea-integrations/GeneeaModal.vue'
import MBasePopup from '../../views/all-integrations/mbase-integrations/MBaseModal.vue'
import CmiFranceTagsPopup from '../../views/all-integrations/cmi-france-tags-integration/CmiFranceTagsModal.vue'
import AssetsPopup from './assets-popup/AssetsPopup.vue'
import ImageEditorPopup from './image-editor-popup/ImageEditorPopup.vue'
import QuickViewEntryPopup from './quick-view-entry-popup/QuickViewEntryPopup.vue'
import SectionsPopup from './sections-popup/SectionsPopup.vue'
import TeamsPopup from './teams-popup/TeamsPopup.vue'
import EditSitePopup from './edit-site-popup/EditSitePopup.vue'
import DandelionPopup from '../../views/all-integrations/dandelion-integration/DandelionModal.vue'
import EntryEditorPopup from './entry-editor-popup/EntryEditorPopup.vue'
import EntriesPopup from './entries-popup/EntriesPopup.vue'
import AddToEntriesListsPopup from './add-to-entries-lists-popup/AddToEntriesListsPopup.vue'
import EntriesViewPopup from './entries-view-popup/EntriesViewPopup.vue'
import EditUrlPopup from './edit-url-popup/EditUrlPopup.vue'
import UrlsPopup from './urls-popup/UrlsPopup.vue'
import CloneEntryPopup from './clone-entry-popup/CloneEntryPopup.vue'
import BulkCloneEntryPopup from './clone-entry-popup/BulkCloneEntryPopup.vue'
import FocalPointPopup from './focal-point/FocalPointPopup.vue'
import PresetsPopup from './presets-popup/PresetsPopup.vue'
import RatiosPopup from './ratios-popup/RatiosPopup.vue'
import UnpublishEntryPopup from './unpublish-entry/UnpublishEntryPopup.vue'
import DeleteFolderPopup from './delete-folder-popup/DeleteFolderPopup.vue'
import SeoAnalyzerPopup from '../ai/SeoAnalyzerPopup.vue'

export default {
  components: {
    RatiosPopup,
    ConfirmDeletePopup,
    ConfirmPopup,
    PromptPopup,
    LabelsPopup,
    BulkEditLabelsPopup,
    VideoPlatformPopup,
    ProlexisPopup,
    GeneeaPopup,
    FolderPopup,
    AssetsPopup,
    ImageEditorPopup,
    EditAssetPopup,
    QuickViewEntryPopup,
    SectionsPopup,
    EditSitePopup,
    MBasePopup,
    CmiFranceTagsPopup,
    TeamsPopup,
    DandelionPopup,
    EntryEditorPopup,
    EntriesPopup,
    QuickScheduler,
    AddToEntriesListsPopup,
    VPUploaderPopup,
    VPUploadPopup,
    VPProgressPopup,
    EntriesViewPopup,
    EditUrlPopup,
    UrlsPopup,
    ModelMappingSidebar,
    TestWebhook,
    CloneEntryPopup,
    BulkCloneEntryPopup,
    EditVersionNamePopup,
    EditTag,
    MergeTag,
    FocalPointPopup,
    PresetsPopup,
    CreateJobPopup,
    CreateApiKeyPopup,
    EditAuthorPopup,
    ViewJobPopup,
    UnpublishEntryPopup,
    DeleteFolderPopup,
    SeoAnalyzerPopup,
    AuthorsPopup,
    ScheduleEntryPopup,
  },
  data() {
    return {
      popups: [],
      initialized: false,
    }
  },
  provide() {
    return {
      showConfirmDeletePopup: data => this.showPopup('ConfirmDeletePopup', data),
      showConfirmPopup: data => this.showPopup('ConfirmPopup', data),
      showFolderPopup: data => this.showPopup('FolderPopup', data),
      showPromptPopup: data => this.showPopup('PromptPopup', data),
      showLabelsPopup: data => this.showPopup('LabelsPopup', data),
      showBulkEditLabelsPopup: data => this.showPopup('BulkEditLabelsPopup', data),
      showVpPopup: data => this.showPopup('VideoPlatformPopup', data),
      showProlexisPopup: data => this.showPopup('ProlexisPopup', data),
      showSeoAnalyzerPopup: data => this.showPopup('SeoAnalyzerPopup', data),
      showGeneeaPopup: data => this.showPopup('GeneeaPopup', data),
      showMBasePopup: data => this.showPopup('MBasePopup', data),
      showCmiFranceTagsPopup: data => this.showPopup('CmiFranceTagsPopup', data),
      showAssetsPopup: data => this.showPopup('AssetsPopup', data),
      showImageEditorPopup: data => this.showPopup('ImageEditorPopup', data),
      showEditAssetPopup: data => this.showPopup('EditAssetPopup', data),
      showQuickViewEntryPopup: data => this.showPopup('QuickViewEntryPopup', data),
      showSectionsPopup: data => this.showPopup('SectionsPopup', data),
      showEditSitePopup: data => this.showPopup('EditSitePopup', data),
      showTeamsPopup: data => this.showPopup('TeamsPopup', data),
      showDandelionPopup: data => this.showPopup('DandelionPopup', data),
      showEntryEditorPopup: data => this.showPopup('EntryEditorPopup', data),
      showEntriesPopup: data => this.showPopup('EntriesPopup', data),
      showQuickScheduler: data => this.showPopup('QuickScheduler', data),
      showAddToEntriesListsPopup: data => this.showPopup('AddToEntriesListsPopup', data),
      showVPUploaderPopup: data => this.showPopup('VPUploaderPopup', data),
      showVPUploadPopup: data => this.showPopup('VPUploadPopup', data),
      showVPProgressPopup: data => this.showPopup('VPProgressPopup', data),
      showEntriesViewPopup: data => this.showPopup('EntriesViewPopup', data),
      showEditUrlPopup: data => this.showPopup('EditUrlPopup', data),
      showUrlsPopup: data => this.showPopup('UrlsPopup', data),
      showModelMappingPopup: data => this.showPopup('ModelMappingSidebar', data),
      showTestWebhook: data => this.showPopup('TestWebhook', data),
      showCloneEntryPopup: data => this.showPopup('CloneEntryPopup', data),
      showBulkCloneEntryPopup: data => this.showPopup('BulkCloneEntryPopup', data),
      showEditVersionNamePopup: data => this.showPopup('EditVersionNamePopup', data),
      showEditTagPopup: data => this.showPopup('EditTag', data),
      showMergeTagPopup: data => this.showPopup('MergeTag', data),
      showFocalPointPopup: data => this.showPopup('FocalPointPopup', data),
      showPresetsPopup: data => this.showPopup('PresetsPopup', data),
      showCreateJobPopup: data => this.showPopup('CreateJobPopup', data),
      showViewJobPopup: data => this.showPopup('ViewJobPopup', data),
      showCreateApiKeyPopup: data => this.showPopup('CreateApiKeyPopup', data),
      showRatiosPopup: data => this.showPopup('RatiosPopup', data),
      showEditAuthorPopup: data => this.showPopup('EditAuthorPopup', data),
      showUnpublishEntryPopup: data => this.showPopup('UnpublishEntryPopup', data),
      showDeleteFolderPopup: data => this.showPopup('DeleteFolderPopup', data),
      showAuthorsPopup: data => this.showPopup('AuthorsPopup', data),
      showScheduleEntryPopup: data => this.showPopup('ScheduleEntryPopup', data),
    }
  },
  methods: {
    showPopup(popup, data) {
      const newPopup = {
        popup,
        data,
        id: GenerateUUID.GENERAL(),
      }

      this.popup = popup

      this.promise = new Promise(resolve => {
        newPopup.promiseResolver = resolve
      })

      this.popups.push(newPopup)

      return this.promise
    },
    closePopup(popup, data) {
      if (!popup.promiseResolver) return
      this.popups = this.popups.filter(p => p !== popup)
      setTimeout(() => {
        popup.promiseResolver(data)
      }, 300)
    },
  },
}
</script>

<style lang="scss">
.popup--0 {
  z-index: 10000 !important;
}
.popup--1 {
  z-index: 10001 !important;
}
.popup--2 {
  z-index: 10002 !important;
}
.popup--3 {
  z-index: 10003 !important;
}
.popup--4 {
  z-index: 10004 !important;
}
.popup--5 {
  z-index: 10005 !important;
}
.popup--6 {
  z-index: 10006 !important;
}
.popup--7 {
  z-index: 10007 !important;
}

</style>
