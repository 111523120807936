import { replaceText } from 'tiptap-commands'
import { Node } from 'tiptap'
// eslint-disable-next-line import/no-extraneous-dependencies
import { Fragment } from 'prosemirror-model'
import { getBlockIdFromDom } from '@/components/codex-editor/CodexEditorUtils'
import CodexContentEditor from '../../CodexContentEditor'
import ReferenceInline from './ReferenceInline.vue'

// eslint-disable-next-line no-unused-vars
export function referenceInlineTipTapNode(componentName, componentAttrs, component) {
  class CodexReferenceInline extends Node {
    get name() {
      return componentName
    }

    get schema() {
      return {
        attrs: componentAttrs,
        group: 'inline',
        inline: true,
        content: 'text*',
        selectable: false,
        atom: true,
        parseDOM: [
          {
            tag: 'span[data-id^="bl"]',
            getAttrs: dom => ({
              ...JSON.parse(dom.dataset?.attrs || '{}'),
              blockId: getBlockIdFromDom(dom),
            }),
          },
        ],
        // eslint-disable-next-line no-sequences
        toDOM: node => ['span', {
          'data-id': node.attrs.blockId,
          'data-attrs': JSON.stringify(node.attrs) || '{}',
        }],
      }
    }

    createFragment(schema, label) {
      return Fragment.fromJSON(schema, [{ type: 'text', text: `${label}` }])
    }

    insertReferenceInline(range, attrs, schema) {
      const nodeType = schema.nodes[this.name]
      const nodeFragment = this.createFragment(schema, attrs?.label)
      console.log({
        range, nodeType, attrs, nodeFragment,
      })
      return replaceText(range, nodeType, attrs, nodeFragment)
    }

    commands({ type }) {
      return attrs => (state, dispatch) => {
        const { selection } = state
        const position = selection.$cursor ? selection.$cursor.pos : selection.$to.pos
        const node = type.create(attrs)
        const transaction = state.tr.insert(position, node)
        dispatch(transaction)
      }
    }

    keys() {
      return {
      }
    }

    get view() {
      return component
    }
  }

  return new CodexReferenceInline()
}

CodexContentEditor.registerWidget('codex', {
  displayName: 'codex-editor.block-names.reference-inline',
  name: 'reference_inline',
  group: null,
  keywords: ['reference', 'entry', 'article'],
  // eslint-disable-next-line global-require
  image: require('../../icons/models/icon.svg'),
  attrs: {
    blockId: {
      default: null,
    },
    modelAlias: {
      default: null,
    },
    references: {
      default: [],
    },
  },
  createTiptapNode: referenceInlineTipTapNode,
  renderEditor: ReferenceInline,
})
