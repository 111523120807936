<template>
  <div
    class="codex-editor notranslate"
    translate="no"
  >
    <div
      style="position: relative"
      :style="`--empty-text: '${$t('codex-editor.write-something')}'`"
    >
      <div
        @keydown="setHidePanels"
      >
        <EditorContent
          :editor="editor"
        />
      </div>

      <ComponentsDropdown
        :show="showComponentsDropdown"
        :editor="editor"
        :selected-nodes="selectedNodes"
        :include-blocks="includeBlocks"
        :include-models="includeModels"
      />

      <!--
      <QuickLinksDropdown
        :editor="editor"
        :selected-nodes="selectedNodes"
      />
      -->

      <QuickAddDropdown
        :editor="editor"
        :selected-nodes="selectedNodes"
        :include-models="includeModels"
      />

      <QuickAddInlineDropdown
        :editor="editor"
        :selected-nodes="selectedNodes"
        :include-models="includeModels"
      />

      <!--
      <SelectedBlocks
        :editor="editor"
        :selected-nodes="selectedNodes"
      />
      -->

      <EmbedDropdown
        v-if="showEmbedDropdown"
        ref="embedDropdown"
        :editor="editor"
        :value="url"
        @close="showEmbedDropdown = false"
      />

      <TurnIntoDropdown
        v-if="showTurnIntoDropdown"
        :include-blocks="includeBlocks"
        :selected-nodes="selectedNodes"
        :editor="editor"
      />

      <WrapperPanel
        v-for="(c, i) in registeredComponentsPanels"
        :key="`${c.name}${i}`"
        :component="c"
        :editor="editor"
        :selected-nodes="selectedNodes"
        :general-panel="showGeneralPanel"
        :set-current-node-attrs="setCurrentNodeAttrs"
        :active-marks="activeMarks()"
        :show-panels="showPanels && focused"
        :editor-focused="focused"
        @toggleTurnInto="toggleTurnIntoDropdown"
      />

      <CommentBubbles />

      <AttrsSidebar
        v-model="activeBlockSidebar"
        :editor="editor"
      />

    </div>
  </div>
</template>

<script>

// eslint-disable-next-line import/no-extraneous-dependencies
import hotEmitter from 'webpack/hot/emitter'
import { Editor, EditorContent, Mark } from 'tiptap'

import {
  Underline,
  Bold,
  Strike,
  Italic,
  History,
  TrailingNode,
  Placeholder,
  HardBreak,
  // Link,
} from 'tiptap-extensions'

import { findDomRefAtPos } from 'prosemirror-utils'
// eslint-disable-next-line import/no-extraneous-dependencies
import { DOMSerializer, DOMParser, Fragment } from 'prosemirror-model'

import { flatten, someParentHasClass } from '@/utils/helpers'
import TurnIntoDropdown from '@/components/codex-editor/plugins/TurnInto/TurnIntoDropdown.vue'
import { cloneDeep, debounce } from 'lodash'
import { mapActions } from 'vuex'
import { CODEX_EDITOR_BLOCKS } from '@/components/codex-editor/CodeEditorConstants'
import CodexContentEditor from './CodexContentEditor'
import BlockIds from './plugins/BlockIds'
import ComponentsDropdown from './plugins/ComponentsDropdown.vue'
import EmbedDropdown from './plugins/EmbedDropdown.vue'
// import SelectedBlocks from './panels/SelectedBlocks.vue'
import WrapperPanel from './panels/WrapperPanel.vue'
import CommentBubbles from './panels/CommentBubbles.vue'

import AttrsSidebar from './CodexEditorAttrsSidebar.vue'

import './nodes/MissingBlock/MissingBlock'
import './nodes/Paragraph/Paragraph'
import './nodes/Heading/Heading'
import './nodes/BulletList/BulletList'
import './nodes/OrderedList/OrderedList'
import './nodes/Factbox/Factbox'
import './nodes/Blockquote/Blockquote'
import './nodes/FacebookEmbed/FacebookEmbed'
import './nodes/YouTubeEmbed/YouTubeEmbed'
import './nodes/InstagramEmbed/InstagramEmbed'
import './nodes/TwitterEmbed/TwitterEmbed'
import './nodes/SpotifyPodcast/SpotifyPodcast'
import './nodes/FameplayEmbed/FameplayEmbed'
import './nodes/VimeoEmbed/VimeoEmbed'
import './nodes/ApplePodcast/ApplePodcast'
import './nodes/GooglePodcast/GooglePodcast'
import './nodes/GenericIframe/GenericIframe'
import './nodes/PageBreak/PageBreak'
// import './nodes/Lock/Lock'
// import './nodes/Newsletter/Newsletter'
import './nodes/CodeBlock/CodeBlock'
import './nodes/Media/Media'
import './nodes/TikTokEmbed/TikTokEmbed'
import { CodexTableHeader, CodexTableRow, CodexTableCell } from './nodes/Table/Table'

import './nodes/Models/Models'
import './nodes/ReferenceInline/ReferenceInline'
// import './nodes/Embed/Embed'
// import CodexParagraph from './nodes/Paragraph'
// import CodexHeading from './nodes/Heading/Heading'
// import CodexBulletList from './nodes/BulletList/BulletList'
// import CodexOrderedList from './nodes/OrderedList/OrderedList'
import CodexListItem from './nodes/ListItem'
// import CodexBlockquote from './nodes/Blockquote/Blockquote'
import CodexBlockquoteContent from './nodes/Blockquote/BlockquoteContent'
import CodexBlockquoteAuthor from './nodes/Blockquote/BlockquoteAuthor'

// QuickLinks
// import QuickLinksDropdown from './plugins/QuickLinks/QuickLinksDropdown.vue'
import Link from './marks/Link/Link'
import ColorStyle from './marks/ColorStyle/ColorStyle'
import Subscript from './marks/Subscript/Subscript'
import Superscript from './marks/Superscript/Superscript'
import Premium from './marks/Premium/Premium'
import { allCellsSelected, findNodeByBlockId } from './CodexEditorUtils'

// QuickAddDropdown
import QuickAddDropdown from './plugins/QuickAdd/QuickAddDropdown.vue'
import QuickAddInlineDropdown from './plugins/QuickAddInline/QuickAddInlineDropdown.vue'

// import QuickLinks from './plugins/QuickLinks/QuickLinks'

class TextStyle extends Mark {
  get name() {
    return 'textStyle'
  }

  get schema() {
    return {
      parseDOM: [],
      toDOM: () => ['span', 0],
    }
  }
}

export default {
  name: 'ContentEditor',
  components: {
    EditorContent,
    ComponentsDropdown,
    // SelectedBlocks,
    WrapperPanel,
    CommentBubbles,
    AttrsSidebar,
    // QuickLinksDropdown,
    QuickAddDropdown,
    QuickAddInlineDropdown,
    EmbedDropdown,
    TurnIntoDropdown,
  },
  props: {
    hackyContent: Array,
    value: Array,
    includeBlocks: Array,
    includeModels: {
      type: Array,
      default: null,
    },
    toolbarOptions: {
      type: Object,
      default: () => {},
    },
    focused: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  provide() {
    return {
      existingBlockIds: this.existingBlockIds,

      registerEditorExtension: this.registerEditorExtension,
      setUpdateAttributeFunction: this.setUpdateAttributeFunction,
      getUpdateAttributeFunction: this.getUpdateAttributeFunction,

      showBlockSidebar: this.showBlockSidebar,

      deleteBlock: this._deleteBlock,
      includeModels: this.includeModels,
      includeBlocks: this.includeBlocks,
      toolbarOptions: this.toolbarOptions,
      upsertNodes: this.upsertNodes,
    }
  },
  data() {
    return {
      showEmbedDropdown: false,
      existingBlockIds: [],
      editor: null,
      editorExtensions: [],
      updateAttrFunctions: {},
      showPanels: false,
      activeBlockSidebar: null,
      url: '',
      ignoreNextUpdate: false,
      showGeneralPanel: false,
      showTurnIntoDropdown: false,
      triggerChanged: debounce(this._triggerChanged, 500),
    }
  },
  computed: {
    registeredComponentsPanels() {
      return CodexContentEditor.getRegisteredWidgets()
    },
    setCurrentNodeAttrs() {
      return this.editor && this.editor.commands ? this.editor.commands.setCurrentNodeAttrs : () => {}
    },
    selectedNodes() {
      if (!this.editor) return []

      const { selection, view } = this.editor

      const selectedNodes = []
      this.editor.view.docView.node.nodesBetween(selection.from, selection.to, (node, pos) => {
        if (node.type.name === 'text') return
        const domAtPos = view.domAtPos.bind(view)
        const dom = findDomRefAtPos(pos, domAtPos)
        selectedNodes.push({ node, dom })
      })

      return this.checkSelectedNodes(selectedNodes, selection.from, selection.to)
    },
    showComponentsDropdown() {
      const { node } = this.selectedNodes.length > 0 ? this.selectedNodes[0] : []
      if (!node) return true
      if ([CODEX_EDITOR_BLOCKS.CODE_BLOCK, CODEX_EDITOR_BLOCKS.HEADING, CODEX_EDITOR_BLOCKS.BLOCKQUOTE].indexOf(node.type.name) !== -1) return false
      return true
    },
  },
  watch: {
    'editor.selection': function (val) {
      this.setShowPanels()
      this.showTurnIntoDropdown = false

      if (!someParentHasClass(document.activeElement, 'ProseMirror')) return

      const selection = JSON.parse(JSON.stringify(val))
      setTimeout(() => {
        if (!this.focused) return

        this.$store.dispatch('editor/updateSelection', selection)
      }, 100)
    },
  },
  inject: ['renderClass'],
  mounted() {
    if (hotEmitter) {
      hotEmitter.on('webpackHotUpdate', this.onHotReload)
    }

    document.addEventListener('mousedown', this.documentClick)
    window.codexEditor = this
    // eslint-disable-next-line no-multi-assign
    window.editor = this.editor = new Editor({
      editorProps: {
        handleDOMEvents: {
          mousedown: () => {
            if (this.showEmbedDropdown) {
              this.showEmbedDropdown = false
            }
          },
          keydown: (view, event) => {
            // if (event.code === 'Space' && this.selectedNodes.length === 1 && (this.selectedNodes[0].node.type.name === CODEX_EDITOR_BLOCKS.PARAGRAPH || this.selectedNodes[0].node.type.name === CODEX_EDITOR_BLOCKS.HEADING)) {
            //   // AI Generator
            //   const { node } = this.selectedNodes?.[0]
            //   if (!node.content.size) {
            //     event.preventDefault()
            //     console.log('node', node, node.attrs.blockId)
            //   }
            // }

            if (event.key === 'Backspace' || event.key === 'Delete') {
              if (allCellsSelected(this.editor)) {
                this._deleteBlock(this.selectedNodes[0].node.attrs.blockId)
                event.preventDefault()
              }
            }

            // prevent arrow key functionality when embed dropdown is open
            if (this.showEmbedDropdown) {
              event.preventDefault()
              this.$refs.embedDropdown.checkKeysEmbedDropdown(event.key)
            }

            if (this.selectedNodes && this.selectedNodes.length === 0) return

            const { node } = this.selectedNodes[0]

            if (!node) return

            // add new row to table when user presses Tab key in the last cell
            if (event.key === 'Tab' && node.type.name === CODEX_EDITOR_BLOCKS.TABLE) {
              const flattenTable = flatten(node.content.content, 'content')
              const lastCellBlockId = flattenTable[flattenTable.length - 1].lastChild.lastChild.attrs.blockId
              const selectedBlockId = this.editor.state.selection.$head.parent.attrs.blockId
              if (lastCellBlockId === selectedBlockId) { this.editor.commands.addRowAfter() }
            }

            if (event.key === 'Backspace' && node.type.name === CODEX_EDITOR_BLOCKS.BLOCKQUOTE) {
              const innerBlockquoteNodes = node.content.content
              if (!innerBlockquoteNodes[0].textContent.length && !innerBlockquoteNodes[1].textContent.length) {
                this._deleteBlock(node.attrs.blockId)
              }
            }

            // handling space key event in heading
            if (event.code === 'Space' && node.type.name === CODEX_EDITOR_BLOCKS.HEADING) {
              event.preventDefault()
              this.editor.view.dispatch(this.editor.state.tr.insertText(' '))
            }
          },
          paste: (view, event) => {
            const clipboardData = event.clipboardData
            const pastedHTML = clipboardData.getData('text/html')

            if (!pastedHTML) {
              // If no HTML content is detected, handle as plain text
              this.url = event.clipboardData.getData('Text')
              if (this.url.match(/^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z]{2,}\b([-a-zA-Z0-9@:%_+.~#?&//=,()!]*)$/gi)) {
                this.editor.view.dispatch(this.editor.state.tr.insertText(this.url))
                const { to: updatedTo } = this.editor.selection
                this.editor.setSelection(updatedTo - this.url.length, updatedTo)
                if (this.toolbarOptions.links.isEnabled) {
                  this.editor.commands.link({ href: this.url })
                  this.editor.setSelection(updatedTo, updatedTo)
                  this.showEmbedDropdown = true
                }
                event.preventDefault()
              } else {
                this.showEmbedDropdown = false
              }
              return
            }

            const filteredDoc = this.filterUnsupportedNodes(pastedHTML, view)

            const filteredHTML = this.convertDocToHTML(filteredDoc, view)

            const selectedBlocks = []
            this.editor.view.docView.node.nodesBetween(view.state.selection.from, view.state.selection.to, node => {
              if (node.type.name === 'text') return
              selectedBlocks.push(node)
            })

            const tempDiv = document.createElement('div')
            tempDiv.innerHTML = filteredHTML
            const docFrag = tempDiv.ownerDocument.createDocumentFragment()
            while (tempDiv.firstChild) {
              docFrag.appendChild(tempDiv.firstChild)
            }
            const parser = DOMParser.fromSchema(view.state.schema)
            const parsedDoc = parser.parse(docFrag)
            const { from, to } = view.state.selection

            let transaction = null
            if (selectedBlocks.length === 1 && selectedBlocks[0].content.size > 0) {
              if (from !== to) {
                transaction = view.state.tr.replaceText(view.state.selection.from, view.state.selection.to, clipboardData.getData('text'))
              } else {
                transaction = view.state.tr.insertText(clipboardData.getData('text'), view.state.selection.from)
              }
            } else {
              transaction = view.state.tr.replaceRangeWith(view.state.selection.from, view.state.selection.to, Fragment.from(parsedDoc.content))
            }

            if (transaction) {
              view.dispatch(transaction)
            }

            event.preventDefault()
          },
          focus: () => {
            this.$emit('click')
            this.$store.dispatch('editor/updateEditor', this.editor)
          },
          copy: (view, event) => {
            const { node } = this.selectedNodes[0]
            if (node.type.name === 'cnc_html_box') {
              event.clipboardData.setData('text/plain', document.getSelection().toString())
              event.preventDefault()
            }
          },
        },

      },
      extensions: [
        // Plugins
        new TrailingNode(),
        new History(),
        new HardBreak(),
        new Placeholder({
          emptyEditorClass: 'is-editor-empty',
          emptyNodeClass: 'is-empty',
          emptyNodeText: this.$t('codex-editor.write-something'),
          showOnlyWhenEditable: true,
          showOnlyCurrent: true,
        }),
        new BlockIds(),

        // Nodes
        // new CodexParagraph(),
        // new CodexImage(),
        // new CodexHeading(),
        // new CodexBulletList(),
        // new CodexOrderedList(),
        new CodexListItem(),
        // new CodexBlockquote(),
        new CodexBlockquoteContent(),
        new CodexBlockquoteAuthor(),

        // Marks
        new Premium(),
        new Link({
          openOnClick: false,
        }),
        new ColorStyle(),
        new Subscript(),
        new Superscript(),
        new TextStyle(),
        new Underline(),
        new Bold(),
        new Strike(),
        new Italic(),
        // new Table({
        //   resizable: true,
        // }),
        new CodexTableHeader(),
        new CodexTableCell(),
        new CodexTableRow(),

        ...this.editorExtensions, // Any plugins extracted to its own Vue Component ( ComponentsDropdown.. )
        ...CodexContentEditor.getRegisteredNodes(), // Any nodes registered by third-party plugins,
      ],
      content: '',
      onUpdate: () => {
        // Hacky Fix for blocks being replaced by simple paragraphs and heading tags
        if (this.ignoreNextUpdate) {
          this.ignoreNextUpdate = false

          this.$nextTick(() => {
            this.setContent(this.hackyContent)
          })

          return
        }
        this.resetActiveBlockSidebar()
        this.setTableDataIds()
        this.triggerChanged()
      },
    })
    this.editor.setOptions({ editable: !this.readOnly })
    this.$nextTick(() => {
      this.setContent(this.hackyContent)
    })
  },
  methods: {
    upsertNodes(add = false, value = '', startPosition = 0, endPosition = 0) {
      const filteredDoc = this.filterUnsupportedNodes(value, this.editor.view)

      const filteredHTML = this.convertDocToHTML(filteredDoc, this.editor.view)

      const tempDiv = document.createElement('div')
      tempDiv.innerHTML = filteredHTML
      const docFrag = tempDiv.ownerDocument.createDocumentFragment()
      while (tempDiv.firstChild) {
        docFrag.appendChild(tempDiv.firstChild)
      }
      const parser = DOMParser.fromSchema(this.editor.view.state.schema)
      const parsedDoc = parser.parse(docFrag)
      let transaction = null

      if (add) {
        transaction = this.editor.view.state.tr.insert(startPosition, Fragment.from(parsedDoc.content))
      } else {
        transaction = this.editor.view.state.tr.replaceRangeWith(startPosition, endPosition, Fragment.from(parsedDoc.content))
      }

      this.editor.view.dispatch(transaction)
    },
    filterUnsupportedNodes(pastedHTML, view) {
      const doc = this.parseHTMLToDoc(pastedHTML, view)
      const nativeBlocks = ['list_item', 'text', 'table_row', 'table_cell', 'table_header']

      const handleBlock = (node, content = []) => {
        if (this.includeBlocks.includes(node.type.name) || nativeBlocks.includes(node.type.name)) {
          if (node?.type?.name === CODEX_EDITOR_BLOCKS.HEADING && !this.includeBlocks.includes(`${CODEX_EDITOR_BLOCKS.HEADING}:h${node.attrs.level}`)) {
            node.attrs.level = this.includeBlocks.filter(f => f.startsWith(`${CODEX_EDITOR_BLOCKS.HEADING}:h`)).sort()[0].split('').pop()
          }
          if (node?.content?.content) {
            const nodeContent = []
            node.content.content.forEach(node1 => handleBlock(node1, nodeContent))
            node.content = nodeContent
          }

          content.push(node)
        } else {
          if (node.type.name === CODEX_EDITOR_BLOCKS.HEADING) {
            content.push(node.type.schema.nodes.paragraph.createAndFill(node.attrs, node.content, node.marks))
          }
          if (node.type.name === CODEX_EDITOR_BLOCKS.ORDERED_LIST || node.type.name === CODEX_EDITOR_BLOCKS.BULLET_LIST) {
            if (node.content.content.length > 0) {
              const paragraphs = []
              node.content.content.forEach(child => {
                if (child.type.name === 'list_item') {
                  child.content.forEach(listItemNode => {
                    handleBlock(listItemNode, content)
                  })
                }
              })
              content.push(...paragraphs)
            }
          }
        }
      }

      const content = []
      doc.content.content.forEach(node => handleBlock(node, content))

      const handleToolbarOptions = node => {
        node.attrs.dropCap = this.toolbarOptions?.dropcap?.isEnabled ? node.attrs.dropCap : false

        const defaultAlignment = (this.toolbarOptions?.textAlignment?.isEnabled
                && (
                  (this.toolbarOptions?.textAlignment?.alignLeft && 'left')
                    || (this.toolbarOptions?.textAlignment?.alignCenter && 'center')
                    || (this.toolbarOptions?.textAlignment?.alignRight && 'right')
                ))
            || 'left'

        if (this.toolbarOptions?.textAlignment?.isEnabled) {
          if (node.attrs.align === 'left' && this.toolbarOptions?.textAlignment?.alignLeft) {
            node.attrs.align = 'left'
          } else if (node.attrs.align === 'center' && this.toolbarOptions?.textAlignment?.alignCenter) {
            node.attrs.align = 'center'
          } else if (node.attrs.align === 'right' && this.toolbarOptions?.textAlignment?.alignRight) {
            node.attrs.align = 'right'
          } else {
            node.attrs.align = defaultAlignment
          }
        } else {
          node.attrs.align = 'left'
        }
      }

      const filterContentBlocks = contentArray => contentArray.filter(node => {
        if (node?.content?.content?.length) {
          node.content.content = filterContentBlocks(node.content.content)
        }

        if (!this.includeBlocks.includes(node.type.name) && node.type.name !== 'text') {
          return false
        }

        handleToolbarOptions(node)

        node.content.forEach(textNode => {
          if (textNode.marks) {
            textNode.marks = textNode.marks.filter(mark => {
              if (this.toolbarOptions.fontStyle.isEnabled) {
                if (this.toolbarOptions.fontStyle[mark.type.name]) {
                  return true
                }
              }
              if (mark.type.name === 'link') {
                return this.toolbarOptions.links.isEnabled
              }
              if (['subscript', 'superscript'].includes(mark.type.name)) {
                return this.toolbarOptions.scripts.isEnabled
              }
              return false
            })
          }
        })
        return true
      })

      const filteredContent = filterContentBlocks(content.filter(Boolean))

      return {
        ...doc,
        content: filteredContent,
      }
    },

    parseHTMLToDoc(html, view) {
      const tempDiv = document.createElement('div')
      tempDiv.innerHTML = html
      const parser = DOMParser.fromSchema(view.state.schema)
      return parser.parse(tempDiv)
    },

    convertDocToHTML(doc, view) {
      const serializer = DOMSerializer.fromSchema(view.state.schema)
      const tempDiv = document.createElement('div')
      tempDiv.appendChild(serializer.serializeFragment(doc.content))
      return tempDiv.innerHTML
    },

    // throttle change event
    _triggerChanged() {
      this.$emit('change')
    },

    ...mapActions(['editor/updateEditor']),
    onHotReload() {
      this.ignoreNextUpdate = true
    },

    _deleteBlock(blockId) {
      let selectedNode = null
      this.editor.state.doc.descendants((node, pos) => {
        if (node.attrs?.blockId === blockId) {
          selectedNode = {
            node, pos,
          }
        }
      })
      if (selectedNode) {
        this.editor.view.dispatch(this.editor.state.tr.delete(selectedNode.pos, selectedNode.pos + selectedNode.node.nodeSize))
      }
    },

    resetActiveBlockSidebar() {
      if (!this.activeBlockSidebar) return
      const newNode = findNodeByBlockId(this.activeBlockSidebar.node?.attrs?.blockId, this.editor)

      if (!newNode) {
        this.activeBlockSidebar = null
        return
      }

      this.activeBlockSidebar.node = newNode
    },

    showBlockSidebar(block) {
      this.activeBlockSidebar = block
    },

    setHidePanels(e) {
      if (e.ctrlKey || e.shiftKey || e.metaKey) return
      this.showPanels = false
    },
    setShowPanels() {
      this.$nextTick(() => {
        const name = this.selectedNodes[0]?.node?.type?.name
        const selectedNode = [CODEX_EDITOR_BLOCKS.PARAGRAPH, CODEX_EDITOR_BLOCKS.HEADING, CODEX_EDITOR_BLOCKS.BULLET_LIST, CODEX_EDITOR_BLOCKS.ORDERED_LIST, CODEX_EDITOR_BLOCKS.FACTBOX, CODEX_EDITOR_BLOCKS.BLOCKQUOTE]

        if (selectedNode.includes(name)) {
          const { view, state } = this.editor
          const { from, to } = view.state.selection
          const text = state.doc.textBetween(from, to, '')
          this.showPanels = text.length > 0
          // this.showEmbedDropdown = false
        } else {
          this.showPanels = true
        }
      })
    },

    // Find better way to update attrs?
    setUpdateAttributeFunction(blockId, fn) {
      this.$set(this.updateAttrFunctions, blockId, fn)
    },
    getUpdateAttributeFunction(blockId) {
      return this.updateAttrFunctions[blockId]
    },

    // Somehow this gets cached or something as a computed attribute
    // So moved it to methods..
    activeMarks() {
      if (!this.editor) return []

      const { selection, doc } = this.editor.state

      return [
        ...selection.$from.marks(),
        ...doc.resolve(selection.from + 1).marks(),
        ...selection.$to.marks(),
      ]
    },

    /**
     * Main function for setting the content to editor
     */
    setContent(content) {
      const flatBlocks = flatten(content, 'content')
      flatBlocks.forEach(n => this.existingBlockIds.push(n.attrs?.blockId))

      try {
        content = this.setMissingBlocks(content)
        this.editor.setContent({ type: 'doc', content })
      } catch (e) {
        console.log('Editor set content error:', e)
      }

      this.$nextTick(() => {
        this.editor.setSelection(0, 0)
        // remove undo changes when loading content from api
        this.editor.state.history$.prevRanges = null
        this.editor.state.history$.done.eventCount = 0
      })
    },

    /**
     * Set all missing blocks to correct type
     */
    setMissingBlocks(content) {
      if (!content || content.constructor !== Array) return content

      return content.map(block => {
        if (!this.editor.nodes[block.type]) {
          return {
            attrs: {
              blockId: block.attrs.blockId,
              original: block,
            },
            content: [],
            contentHTML: '-',
            marks: [],
            text: null,
            type: CODEX_EDITOR_BLOCKS.MISSING_BLOCK,
          }
        }
        block.content = this.setMissingBlocks(block.content)
        return block
      })
    },

    /**
     * Reseet all missing blocks to their original state
     */
    resetMissingBlocks(content) {
      if (!content || content.constructor !== Array) return content

      return content.map(block => {
        if (block.type === CODEX_EDITOR_BLOCKS.MISSING_BLOCK) {
          return block.attrs.original
        }
        block.content = this.resetMissingBlocks(block.content)
        return block
      })
    },

    /**
     * Table when set to resizable operate with nodeViews instead of using toDom,
     * which is where we set data-id to elements. So we set them manualy here.
     */
    setTableDataIds() {
      const blocks = this.editor.getJSON().content
      const elements = document.querySelectorAll(`.${this.renderClass} .ProseMirror > *`)

      if (!elements.length) return

      // eslint-disable-next-line no-restricted-syntax
      for (const i in blocks) {
        if (blocks[i].type == CODEX_EDITOR_BLOCKS.TABLE && elements[i]) {
          elements[i].querySelector(CODEX_EDITOR_BLOCKS.TABLE).setAttribute('data-id', blocks[i].attrs.blockId)
        }
      }
    },

    /**
     * Main function that returns the content for saving
     */
    getContent() {
      let content = cloneDeep(this.editor.getJSON().content)
      content = this.resetMissingBlocks(content)
      return this.setContentHtmlOf(content)
    },

    /**
     * This function sets the contentHTML for blocks, so
     * on front-end we don't have to also loop through 'marks'
     * and generate bold, italic, links..
     */
    setContentHtmlOf(blocks) {
      return blocks.map(block => {
        // if (['paragraph', 'heading'].indexOf(block.type) === -1) return block;

        if (block.content) {
          if (block.attrs) {
            const dom = document.querySelector(`[data-id="${block.attrs.blockId}"]`)
            block.contentHTML = dom ? dom.innerHTML : ''
          } else {
            block.contentHTML = ''
          }
          block.content = this.setContentHtmlOf(block.content)
        }
        return block
      })
    },
    documentClick(e) {
      if (!someParentHasClass(e.target, 'codex-editor')
          && !someParentHasClass(e.target, 'modal')
          && !someParentHasClass(e.target, 'tooltip')
          && !someParentHasClass(e.target, 'b-sidebar')) {
        this.editor.setSelection(0, 0)
        this.showBlockSidebar(null)
        this.showEmbedDropdown = false
      }
    },
    registerEditorExtension(extension) {
      this.editorExtensions.push(extension)
    },
    checkSelectedNodes(selectedNodes, from, to) {
      if (selectedNodes.length === 0) return []

      // check if selection contains two different blocks
      const differentBlocks = selectedNodes.some(node => node.node.attrs.blockId !== selectedNodes[0].node.attrs.blockId)
      if (from < to && differentBlocks) this.showGeneralPanel = true
      else this.showGeneralPanel = false

      if (selectedNodes?.length === 2 && selectedNodes[0].node.type.name === CODEX_EDITOR_BLOCKS.FACTBOX) {
        this.showGeneralPanel = false
        return [selectedNodes[1]]
      }

      // checks if first selected node that is in the list, so that we don't hide control panel
      if ([CODEX_EDITOR_BLOCKS.TABLE, 'blockquote'].indexOf(selectedNodes[0].node.type.name) !== -1) this.showGeneralPanel = false

      if (selectedNodes[0].node.type.name === 'blockquote') return [selectedNodes[0]]

      return from < to ? [selectedNodes[0]] : [selectedNodes[selectedNodes.length - 1]]
    },
    toggleTurnIntoDropdown(e = !this.showTurnIntoDropdown) {
      this.showTurnIntoDropdown = e
    },
  },
  beforeDestroy() {
    this.editor.destroy()

    if (hotEmitter) {
      hotEmitter.off('webpackHotUpdate', this.onHotReload)
    }

    document.removeEventListener('mousedown', this.documentClick)
  },
}
</script>

<style lang="scss">
  @import "@core/scss/base/bootstrap-extended/include"; // Bootstrap includes
  @import "@core/scss/base/components/include"; // Components includes

  .codex-editor {
    width: 100%;
  }
  .ProseMirror {
    .ProseMirror-gapcursor:not(:first-child):after {
      top: -1.625rem;
    }
    padding: 0.75rem 0;

    ::-moz-selection { /* Code for Firefox */
      color: currentColor;
      background: rgba(35, 131, 226, 0.28);;
    }

    ::selection {
      color: currentColor;
      background: rgba(35, 131, 226, 0.28);;
    }

    &:focus {
      outline: none;
    }

    > * {
      margin-top: 0.75rem;
      margin-bottom: 0.75rem;

      &:last-child {
        padding-bottom: 20vh;
      }
    }

    > .block-component {
      margin-bottom: 1.75rem;
    }
  }

  .has-focus {
    outline: 2px dotted black;
    outline-offset: 2px;
  }

  .is-empty::after {
    content: var(--empty-text) !important;
    color: #ddd;
    display: block;
    pointer-events: none;
    font-style: italic;
  }
  h1, h2, h3, h4, h5, h6 {
    &.is-empty::after {
      margin-top: -1.13em;
    }
  }
  p.is-empty::after {
    margin-top: -1.4em;
  }
  .ProseMirror {
    h1, h2, h3, h4, h5, h6 {
      font-weight: bold;
    }
    h1 { font-size: 1.875rem; line-height: 1.2 };
    h2 { font-size: 1.625rem; line-height: 1.2 };
    h3 { font-size: 1.375rem; line-height: 1.2 };
    h4 { font-size: 1.25rem; line-height: 1.2 };
    h5 { font-size: 1.125rem; line-height: 1.2 };
    h6 { font-size: 1rem; line-height: 1.2 };

    *[data-type=premium] {
      background: #E8F2F7;
      border: 1px solid #16749E;
      border-radius: 4px;
      padding: 0 2px;
      color: #173163;

      box-decoration-break: clone;
      -webkit-box-decoration-break: clone;

    }

    blockquote {
      &.blockquote {
        padding: 20px 0;
        padding-left: 30px;
        position: relative;
        font-size: 24px;

        small {
          &::before {
            content: ' - ';
          }
          font-size: 16px;
          display: block;
          margin-top: 20px;
          margin-left: 15px;
        }

        &::before {
          content: url('data:image/svg+xml; utf8, <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path fill="grey" d="M11 9.275c0 5.141-3.892 10.519-10 11.725l-.984-2.126c2.215-.835 4.163-3.742 4.38-5.746-2.491-.392-4.396-2.547-4.396-5.149 0-3.182 2.584-4.979 5.199-4.979 3.015 0 5.801 2.305 5.801 6.275zm13 0c0 5.141-3.892 10.519-10 11.725l-.984-2.126c2.215-.835 4.163-3.742 4.38-5.746-2.491-.392-4.396-2.547-4.396-5.149 0-3.182 2.584-4.979 5.199-4.979 3.015 0 5.801 2.305 5.801 6.275z"/></svg>');
          position: absolute;
          top: 5px;
          left: 0;
        }
      }

      &.codex-lock {
        padding: 15px;
        background: #f9f9f9;
        border: 1px dashed #E34850;

        > *:last-child {
          margin-bottom: 0;
        }
      }

      &.blockquote-factbox {
        padding: 15px;
        background: #f9f9f9;

        > *:last-child {
          margin-bottom: 0;
        }
      }

      &.blockquote-exergue {
        padding: 15px;
        border-left: 3px solid #ddd;

        > *:last-child {
          margin-bottom: 0;
        }
      }
    }

    ul {
      list-style: disc;
      margin-left: 20px;
    }
    ol {
      list-style: decimal;
      margin-left: 20px;
    }

    p {
      display: block;

      &::after {
        content: "";
        display: block;
        clear: both;
      }

      &.dropcap {
        position: relative;
        &:before, &:after {
          content: '';
          position: absolute;
          top: 0;
          left: -0.4em;
          width: 2px;
          height: 1em;
          background-color: #1D79F2FF;
        }
        &:after {
          transform: rotate(-90deg);
          transform-origin: top left;
        }
      }
    }

    a {
      color: $primary;
      background: rgba(0,0,0,0.02);
      text-decoration: underline;

      ::selection {
        text-decoration-color: $primary;
      }
    }

    .block-component::before, .block-component::after {
      display: none;
    }
  }

  .block-input {
    padding: 4px 10px;
    margin: 2px 2px;
    margin-left: 0px;
    border: 1px solid lightgray;

    &:focus {
      outline: none;
    }
  }
</style>
