import { cloneDeep } from 'lodash'
import CodexLayoutError from '../CodexLayoutError'
import AddBlock from './AddBlock'

/**
 * Duplicate Block Transaction
 */
export default class DuplicateBlock extends AddBlock {
  name = 'DuplicateBlock';

  clonedBlockId = null

  parentBlockId = null

  blockOptions = null

  addIndex = null

  constructor(editor, blockId) {
    super(editor)

    const block = this.editor.findBlockById(blockId)

    if (!block) {
      throw new CodexLayoutError(`Block '${blockId}' not found`)
    }

    const parentBlock = this.editor.findParentBlockById(blockId)

    if (!parentBlock) {
      throw new CodexLayoutError(`Parent block of '${blockId}' not found`)
    }

    const addIndex = parentBlock.content.findIndex(b => b.id === blockId)

    if (addIndex == -1) {
      throw new CodexLayoutError(`Invalid index when duplicating block '${blockId}'`)
    }

    this.clonedBlockId = block.id
    this.blockId = parentBlock.id
    this.blockOptions = this.editor.updateBlockIds(cloneDeep(block))
    this.addIndex = addIndex + 1 // Add 1 to add the dupliated block after the one we are cloning
  }
}
