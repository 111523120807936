<template>
  <div>
    <TextFormatPanel
      :toggle-link-popup="toggleLinkPopup"
      :commands="commands"
      :is-active="isActive"
      :include-hyper-link="true"
      :editor="editor"
    />
  </div>
</template>

<script>
import TextFormatPanel from '@/components/codex-editor/panels/TextFormatPanel.vue'

export default {
  components: {
    TextFormatPanel,
  },
  props: ['attrs', 'updateAttrs', 'commands', 'isActive', 'toggleLinkPopup', 'editor'],
  computed: {
    level: { get() { return this.attrs.level }, set(e) { this.updateAttrs({ level: e }) } },
  },
  methods: {
    isLevel(level) {
      return this.level === level
    },
    setLevel(level) {
      this.level = level
    },
  },
}
</script>
