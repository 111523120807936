<template>
  <b-form-group>
    <template #label>
      <FieldName
        :name="field.name"
        :help-text="''"
      />
    </template>
    <div
      v-if="fieldContent === null || !contentList.length"
      class="content-list__empty"
    >
      <GjIcon :name="field.type === TYPES.TEXT ? 'EditAltTextFilled' : 'IconparkListNumbers'" />
      <span>{{ $tc(`entries.merge-tool.info.${field.type === TYPES.TEXT ? 'no-text-value' : 'no-number-value'}`, field.valueType === VALUE_TYPES.SINGLE ? 1 : 2) }}</span>
    </div>
    <template v-else>
      <b-form-input
        v-for="(value, index) in contentList"
        :key="index"
        :value="value.toLocaleString()"
        class="text-field__item"
        disabled
      />
    </template>
  </b-form-group>
</template>

<script>
import FieldName from '@/components/fields/FieldName.vue'
import { VALUE_TYPES, TYPES } from '@/views/models/constants'

export default {
  name: 'TextField',
  components: {
    FieldName,
  },
  props: {
    fieldContent: {
      type: [String, Number, Array],
    },
    field: {
      type: [Object],
    },
  },
  setup(props) {
    let contentList = []

    if (props.field.valueType === VALUE_TYPES.SINGLE) {
      contentList.push(props.fieldContent)
    } else {
      contentList = props.fieldContent
    }

    return {
      contentList,
      VALUE_TYPES,
      TYPES,
    }
  },
}
</script>

<style lang="scss">
.text-field__item:not(first-child) {
  margin-top: 8px;
}
</style>
