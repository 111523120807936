<template>
  <div>
    <template v-for="val in validations">
      <div
        v-if="validation[val.name]"
        :key="val.name"
      >
        <component
          :is="val.component"
          v-model="validation[val.name]"
          :widget="widget"
        />
      </div>
    </template>
  </div>
</template>

<script>
import BaseFieldMixin from '@/components/fields/BaseFieldMixin'
import { generateComputedPropsFromAttrs } from '@/components/codex-layout-editor/BuilderUtils'
import Validations from './validations'

export default {
  name: 'FieldValidations',
  mixins: [BaseFieldMixin],
  data() {
    return {
      validations: [],
    }
  },
  computed: {
    ...generateComputedPropsFromAttrs([
      'valueType',
      'alias',
      'validation',
      'type',
    ]),
  },
  created() {
    this.validations = Validations.getValidationsForType(this.type, this.valueType)
  },
}
</script>
