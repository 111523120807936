export default {
  ADD_SECTION(state, section) {
    state.sections.unshift(section)
  },
  SET_SECTIONS(state, sections) {
    state.sections = sections
  },
  SET_SECTIONS_HIERARCHY(state, hierarchy) {
    state.hierarchy = hierarchy
  },
  UPDATE_SECTION(state, section) {
    const sectionIndex = state.sections.findIndex(p => p.id === section.id)
    if (sectionIndex === -1) return
    Object.assign(state.sections[sectionIndex], section)
  },
  REMOVE_SECTION(state, section) {
    const sectionIndex = state.sections.findIndex(p => p.id === section.id)
    state.sections.splice(sectionIndex, 1)
  },
  REMOVE_SECTIONS(state, sections) {
    sections.map(section => {
      const sectionIndex = state.sections.findIndex(p => p.id === section.id)
      state.sections.splice(sectionIndex, 1)
      return section.id
    })
  },
  SET_SECTIONS_PAGINATION(state, pagination) {
    state.sectionsPagination = pagination
  },
  SET_SECTIONS_HIERARCHY_PAGINATION(state, pagination) {
    state.hierarchyPagination = pagination
  },
  CLEAR_SECTIONS_STATE(state) {
    state.sections = []
  },
}
