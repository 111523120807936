<template>
  <div>
    <div
      v-for="item in entries"
      :key="item.id + item.system.status"
      class="unpublish-entry__item"
      @click="quickEdit(item)"
    >
      <span class="unpublish-entry__icon">
        <GjIcon
          style="width: 34px;"
          size="24"
          :name="item.system.model && item.system.model.iconId || 'FlowVerticalAlt'"
        />
      </span>
      <div class="unpublish-entry__text">
        <p
          v-if="!item.system.title"
          class="unpublish-entry__untitled"
        >
          {{ $t('entries.untitled') }}
        </p>
        <p
          v-else
          class="unpublish-entry__title"
        >
          {{ item.system.title }}
        </p>
        <p class="unpublish-entry__alias">
          {{ item.system.modelAlias }}
        </p>
      </div>
      <StatusChip
        :is-query="true"
        :entry="item"
        :scheduled-versions="item.system.scheduledVersions && item.system.scheduledVersions.items"
      />
    </div>

    <div class="gjirafa-tables__footer">
      <b-pagination
        v-model="page"
        :total-rows="totalItems"
        :per-page="limit"
        aria-controls="non-required-fields"
        align="left"
        prev-class="next-item"
        next-class="next-item"
        class="mt-1 mb-0"
      />
      <div class="gjirafa-tables__footer-leftside unpublish-entry__pagination">
        <span class="text-nowrap">{{ $t('general.pagination', { from: from, to: to, totalItems: totalItems}) }}</span>
      </div>
    </div>

  </div>
</template>

<script>
import StatusChip from '@/views/entries/components/StatusChip.vue'
import gql from 'graphql-tag'
import { ENTRY_STATUSES, ENTRY_STATUSES_STRING } from '@/codex-sdk/entries'

export default {
  name: 'ReferencedEntriesList',
  components: { StatusChip },
  inject: ['showEntryEditorPopup'],

  apollo: {
    contentEntriesClient: {
      query() {
        return this.contentEntryQuery
      },
      client: 'clientClient',
      fetchPolicy: 'network-only',
      skip() {
        return this.clientSkip
      },
      variables() {
        return {
          offset: this.clientOffset,
          limit: this.clientLimit,
          where: {
            system: {
              references: {
                referencingFields: { some: this.fieldNames },
              },
            },
          },
        }
      },
    },
    defaultEntriesClient: {
      query() {
        return this.entryQuery
      },
      client: 'defaultClient',
      fetchPolicy: 'network-only',
      skip() {
        return this.defaultSkip
        // return (!this.fields.required.length || !this.fields.nonRequired.length) || (this.tabIndex == 0 && (this.defaultOffset - this.limit) > this.lengthOfDefaultClient)
      },
      variables() {
        return {
          offset: this.defaultOffset,
          limit: this.defaultLimit,
          where: {
            system: {
              references: {
                referencingFields: { some: this.fieldNames },
              },
              status: {
                notIn: [ENTRY_STATUSES_STRING[ENTRY_STATUSES.PUBLISHED]],
              },
            },
          },
        }
      },
    },
  },
  props: {
    fields: {
      type: Object,
      required: true,
    },
    fieldNames: {
      type: Array,
      required: true,
    },
    defaultTotal: {
      type: Number,
      required: true,
    },
    clientTotal: {
      type: Number,
      required: true,
    },
    tabIndex: {
      type: Number,
    },
  },

  data() {
    return {
      page: 1,
      limit: 7,
      defaultEntries: [],
      contentEntries: [],
    }
  },

  computed: {
    publishedRequiredEntries() {
      return this.entries.filter(entry => entry.system.status === ENTRY_STATUSES_STRING[ENTRY_STATUSES.PUBLISHED] || entry.system.status === ENTRY_STATUSES_STRING[ENTRY_STATUSES.SCHEDULED])
    },
    entries() {
      const defaultEntries = this?.defaultEntries || []
      const contentEntries = this?.contentEntries || []
      return contentEntries.concat(defaultEntries)
    },

    defaultSkip() {
      const hasFields = (this.fields.required.length + this.fields.nonRequired.length) > 0
      if (!hasFields) return true

      return this.page < Math.ceil(this.clientTotal / this.limit)
    },
    clientSkip() {
      const hasFields = (this.fields.required.length + this.fields.nonRequired.length) > 0
      if (!hasFields) return true

      return this.page > Math.ceil(this.clientTotal / this.limit)
    },
    isMiddlePage() {
      return !this.defaultSkip && !this.clientSkip
    },

    clientOffset() {
      return (this.page - 1) * this.limit
    },
    defaultOffset() {
      const defaultStartPage = Math.ceil(this.clientTotal / this.limit) + 1
      const defaultOffset = Math.max(0, (this.page - defaultStartPage) * this.limit)
      const defaultAddition = this.clientTotal > 0 ? this.limit - (this.clientTotal % this.limit) : 0

      return defaultOffset + (!this.isMiddlePage ? defaultAddition : 0)
    },
    clientLimit() {
      return this.limit
    },
    defaultLimit() {
      const defaultAddition = this.limit - (this.clientTotal % this.limit)
      return this.isMiddlePage ? defaultAddition : this.limit
    },

    totalItems() {
      return this.defaultTotal + this.clientTotal
    },
    from() {
      return (this.page - 1) * this.limit + 1
    },
    to() {
      return (this.page * this.limit) > this.totalItems ? this.totalItems : (this.page * this.limit)
    },

    entryQuery() {
      return gql`
        query DefaultEntries($where: CodexEntryFilter, $offset: Int, $limit: Int) {
          defaultEntriesClient: entryCollection(where: $where, offset: $offset, limit: $limit) {
            total
            items {
              id
              system {
                status
                title
                modelAlias
                siteId
                scheduledVersions (where: {
                  status: { in: [SCHEDULED] }
                }) {
                  items {
                    id
                    versionId
                    publishScheduledDate
                    unpublishScheduledDate
                    versionId
                    createdAt
                  }
                }
              }
            }
          }
        }`
    },
    contentEntryQuery() {
      return gql`
        query ClientEntries($where: CodexEntryFilter, $offset: Int, $limit: Int) {
          contentEntriesClient: entryCollection(where: $where, offset: $offset, limit: $limit) {
            total
            items {
              id
              system {
                status
                title
                modelAlias
                siteId
              }
            }
          }
        }`
    },
  },
  watch: {
    '$apollo.loading': function (val) {
      if (!val) {
        this.defaultEntries = this.defaultSkip ? [] : this.defaultEntriesClient?.items || []
        this.contentEntries = this.clientSkip ? [] : this.contentEntriesClient?.items || []
      }
      this.$emit('graphql-loading', val)
    },
    publishedRequiredEntries() {
      this.$emit('publishedRequiredEntries', this.publishedRequiredEntries)
    },
  },
  methods: {
    async quickEdit(entry) {
      const edited = await this.showEntryEditorPopup({
        id: entry.id,
        model: entry.system.modelAlias,
        siteId: entry.system.siteId,
      })
      if (edited.action) {
        this.$emit('refetchEntries')
      }
    },
  },
}
</script>
