import { truncate } from 'lodash'
import i18n from '@/libs/i18n'
import AuthorFilterComponent from './Author.vue'

export default class AuthorFilter {
  static COMPONENT = AuthorFilterComponent;

  static OPERATORS = {
    include: 'include',
    exclude: 'exclude',
    exists: 'exists',
    notExists: 'notExists',
  }

  static type = 'author'

  static graphqlType = 'AuthorFilter'

  operator = null;

  value = null;

  constructor({ operator = AuthorFilter.OPERATORS.include, value = [] } = {}) {
    this.operator = operator
    this.value = value
  }

  set({ operator = AuthorFilter.OPERATORS.include, value = [] }) {
    this.operator = operator
    this.value = value
  }

  getCount() {
    if (this.operator === AuthorFilter.OPERATORS.exists || this.operator === AuthorFilter.OPERATORS.notExists) {
      return 1
    }
    return this.value?.length
  }

  getValueLabel({ cache }) {
    if (this.operator === AuthorFilter.OPERATORS.exists) {
      return i18n.t('filters.is-not-empty')
    }
    if (this.operator === AuthorFilter.OPERATORS.notExists) {
      return i18n.t('filters.is-empty')
    }
    const author = this.value.map(authorId => cache[authorId] || { id: authorId })
    return author.map(m => {
      let name = [m.firstName, m.lastName].filter(Boolean).join(' ')
      if (name === '') name = m.byline || m.email
      return truncate(name, { length: 20, separator: ' ' })
    }).join(', ')
  }

  toLoad() {
    return {
      authors: this.value,
    }
  }

  asQueryParam() {
    if (this.operator === AuthorFilter.OPERATORS.exists || this.operator === AuthorFilter.OPERATORS.notExists) {
      return {
        operator: this.operator,
      }
    }
    return {
      operator: this.operator,
      value: this.value,
    }
  }

  asGraphQL() {
    switch (this.operator) {
      case AuthorFilter.OPERATORS.include:
        return {
          in: this.value,
        }
      case AuthorFilter.OPERATORS.exclude:
        return {
          notIn: this.value,
        }
      case AuthorFilter.OPERATORS.exists:
        return {
          exists: true,
        }
      case AuthorFilter.OPERATORS.notExists:
        return {
          exists: false,
        }
      default:
        return null
    }
  }

  asGraphQLDynamicList() {
    if (this.asGraphQL()) {
      return {
        ...this.asGraphQL(),
        _t: AuthorFilter.graphqlType,
      }
    }
    return null
  }

  static validate({ operator, value }) {
    if (!operator) {
      return 'Operator is required'
    }

    if (operator === AuthorFilter.OPERATORS.exists || operator === AuthorFilter.OPERATORS.notExists) {
      return true
    }

    if (!value || value.length === 0) {
      return 'Value is required'
    }

    return true
  }

  static fromQueryParam({ operator, value }) {
    return new AuthorFilter({
      operator,
      value,
    })
  }

  static shouldApplyPredefinedValue(filter) {
    const forOperators = [AuthorFilter.OPERATORS.include]
    return forOperators.includes(filter.operator)
  }
}
