<template>
  <div class="no-items">
    <img
      src="../../../assets/icons/media.svg"
      class="no-assets-icon"
      :style="disabled ? 'cursor: default' : ''"
      @click="$emit('insert')"
    >
    <div class="no-items__text-container">
      <p
        class="no-items__text"
        :style="disabled ? 'cursor: default' : ''"
        @click="$emit('insert')"
      >
        <span class="no-items__browse hide-in-quick-view">{{ $t('fields.codex-field-media-content.preview.browse') }}</span> {{ $t('fields.codex-field-media-content.preview.browse-or') }}
      </p>
      <span class="no-items__max-file">{{ $t(`fields.codex-field-media-content.preview.max-file`, { maxSize: maxFileSize }) }}</span>
    </div>
  </div>
</template>

<script>
import { VALUE_TYPES } from '@/views/models/constants'

export default {
  name: 'NoAssets',
  props: {
    valueType: {
      type: Number,
      default: VALUE_TYPES.SINGLE,
    },
    maxFileSize: {
      type: String,
      default: '32MB',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss" scoped>

.no-items {
  background: #F4F6FA;
  margin-left: 2px;
  margin-right: 2px;
  margin-top: 8px;
  border-radius: 10px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  .no-assets-icon {
    padding-top: 4px;
    width: 48px;
    height: 48px;
    cursor: pointer;
  }
  .no-items__text-container{
    display: flex;
    flex-direction: column;
    .no-items__text {
      cursor: pointer;
      color: #1D417B;
      font-size: 14px;
      line-height: 18px;
      .no-items__browse {
        color: #206ED5;
        text-decoration-line: underline;
      }
    }
    .no-items__max-file{
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      text-align: center;
      color: #A3B0C2;
    }
  }

}
</style>
