export const BLOCK_GROUPS = Object.freeze({
  MOST_USED: 'mostUsed',
  TEXTUAL: 'textual',
  EMBEDS: 'embeds',
  NEWSROOM: 'newsroom',
  MEDIA: 'media',
  CONTENT: 'content',
  OTHERS: 'others',
})

export function mapBlockGroups(group) {
  return `codex-editor.node-groups.${group}`
}
