import { cloneDeep, merge } from 'lodash'
import Vue from 'vue'
import CodexLayoutError from '../CodexLayoutError'
import CodexLayoutTransaction from './Base'

/**
 * Update Block Attributes Transaction
 */
export default class UpdateBlockAttributes extends CodexLayoutTransaction {
  name = 'UpdateBlockAttributes';

  blockId = null

  newAttrs = {}

  oldAttrs = {}

  /**
   * @param {CodexLayoutEditor} editor The editor on which to make apply transaction
   * @param {String} blockId Id of the block we want to update the attributes
   */
  constructor(editor, blockId, newAttrs, breakpoint = null) {
    super(editor)

    // From block
    const block = this.editor.findBlockById(blockId)

    if (!block) {
      throw new CodexLayoutError(`Block '${blockId}' not found`)
    }

    if (newAttrs._responsive) {
      throw new CodexLayoutError('Cannot update responsive attributes directly')
    }

    if (breakpoint) {
      if (this.editor.getResponsiveBreakpoints().indexOf(breakpoint) !== -1) {
        newAttrs = {
          _responsive: {
            [breakpoint]: newAttrs,
          },
        }
      } else {
        throw new CodexLayoutError('Invalid breakpoint')
      }
    }

    this.blockId = blockId

    const clonedAttrs = cloneDeep(block.attrs)
    Object.keys(newAttrs).forEach(key => {
      if (!key.startsWith('_')) {
        delete clonedAttrs[key]
      }
    })

    this.newAttrs = merge(clonedAttrs, newAttrs)
    this.oldAttrs = cloneDeep(block.attrs)
  }

  up() {
    const block = this.editor.findBlockById(this.blockId)

    Vue.set(block, 'attrs', this.newAttrs)
  }

  down() {
    const block = this.editor.findBlockById(this.blockId)

    Vue.set(block, 'attrs', this.oldAttrs)
  }
}
