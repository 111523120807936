<template>
  <div class="article-card-column">
    <div
      v-if="element.new"
      class="entry__new-badge desktop-only"
    >
      {{ $t('lists.new-badge') }}
    </div>
    <div class="entry-card__wrapper">
      <div class="entries-list__card">
        <div
          style="margin-right: 10px;"
          Nstati
          class="desktop-only"
        >
          <GjIcon name="Drag" />
        </div>
        <div class="stacked-card__image desktop-only">
          <b-img-aspect
            v-if="element.entry.system.featuredMedia"
            :key="element.entry.id"
            aspect="1:1"
            style="width: 36px;"
            :src="element.entry.system.featuredMedia.url"
          />
          <GjIcon
            v-else
            size="36"
            :name="getEntryModel(element.entry).iconId"
          />
          <div
            v-if="element.entry.system.site"
            class="entries-list__item-site-logo"
          >
            <b-img-aspect
              :src="element.entry.system.site.logo"
              aspect="1:1"
              style="width: 100%"
              :title="element.entry.system.site.name"
            />
          </div>
          <div class="entries-lists-draggable-element-order">
            {{ showOnlyActiveEntries ? getIndex(element,index) : element.order }}
          </div>
        </div>
        <div class="entry-list__content">
          <div
            class="entries-list__card-title"
          >
            <span
              v-if="!element.entry.system.title"
              class="stacked-card__title-untitled"
              @click="editEntry(element)"
            >
              {{ $t('entries.untitled') }}
            </span>
            <span
              v-else
              @click="editEntry(element)"
            >
              {{ element.entry.notFound ? $t(element.entry.system.title) : element.entry.system.title }}
            </span>
            <div
              class="entry-list__content-details"
              :class="{'entry-list__content-details--inactive': !isActive(element) }"
            >
              <div
                v-if="element.new"
                class="entry__new-badge-mobile"
              >
                {{ $t('lists.new-badge') }}
              </div>
              <div class="entries-list__card-status mobile-only">
                <StatusChip
                  :entry="element.entry"
                  :is-query="true"
                  :with-background="true"
                  :scheduled-versions="element.entry.system.scheduledVersions && element.entry.system.scheduledVersions.items"
                />
                <b-dropdown
                  variant="asd"
                  no-caret
                  class="entries-list__calendar"
                >
                  <template #button-content>
                    <div class="entries-list__calendar-button">
                      <GjIcon
                        name="Calendar"
                        size="18"
                      />
                      <span>{{ element.startAt|formatDate(true) }}</span>
                      <span v-if="element.endAt"> - {{ element.endAt|formatDate(true) }}</span>
                      <span v-if="!element.startAt && !element.endAt">{{ $t('lists.schedule') }}</span>
                    </div>
                  </template>
                  <b-tabs class="entries-list__calendar-tabs">
                    <b-tab title="Start Date">
                      <flat-pickr
                        v-model="element.startAt"
                        class="form-control"
                        :config="config"
                        :placeholder="startAtPlaceholder"
                      />
                    </b-tab>
                    <b-tab title="End Date">
                      <flat-pickr
                        v-model="element.endAt"
                        class="form-control"
                        :config="config"
                        :placeholder="endAtPlaceholder"
                      />
                    </b-tab>
                  </b-tabs>
                </b-dropdown>
              </div>
              <span
                class="desktop-only"
                style="display: flex; align-items: center; gap: 5px;"
              >
                <span>{{ $t('lists.start-at-label') }}</span>
                <div class="calendar">
                  <flat-pickr
                    :key="element.entryId + startAtPlaceholder"
                    v-model="element.startAt"
                    class="form-control"
                    :config="config"
                    :placeholder="startAtPlaceholder"
                  />
                  <GjIcon
                    name="Calendar"
                    size="18"
                  />
                </div>
              </span>
              <GjIcon
                name="RadioButtonCircleDisabledFilled"
                size="7"
                class="desktop-only"
              />
              <span
                class="desktop-only"
                style="display: flex; align-items: center; gap: 5px;"
              >
                <span>{{ $t('lists.end-at-label') }}</span>
                <div class="calendar">
                  <flat-pickr
                    v-model="element.endAt"
                    class="form-control"
                    :config="config"
                    :placeholder="endAtPlaceholder"
                  />
                  <GjIcon
                    name="Calendar"
                    size="18"
                  />
                </div>
              </span>
            </div>
          </div>
        </div>

        <div class="entries-list__card-status desktop-only">
          <StatusChip
            :entry="element.entry"
            :is-query="true"
            :with-background="true"
            :scheduled-versions="element.entry.system.scheduledVersions && element.entry.system.scheduledVersions.items"
          />
        </div>
        <div class="entries-list__card-options">
          <b-dropdown
            :ref="`dropdown-${element.entry.id}-${index}`"
            size="sm"
            variant="aaa"
            class="entries-list__card-dropdown"
            no-caret
          >
            <template #button-content>
              <GjIcon name="Moreoptions" />
            </template>
            <b-dropdown-item @click.native.capture.stop="$emit('remove', index)">
              <span class="d-flex align-items-center">
                <GjIcon name="Delete" />
                <p class="p-0 m-0 ml-50">
                  {{ $t('lists.entry.remove') }}
                </p>
              </span>
            </b-dropdown-item>
            <b-dropdown-item
              class="mobile-only"
              @click.native.capture.stop="$emit('moveItemUp',index, element)"
            >
              <span class="d-flex align-items-center">
                <GjIcon name="ArrowUp" />
                <p class="p-0 m-0 ml-50">
                  {{ $t('lists.move-up') }}
                </p>
              </span>
            </b-dropdown-item>
            <b-dropdown-item
              class="mobile-only"
              @click.native.capture.stop="$emit('moveItemDown',index, element)"
            >
              <span class="d-flex align-items-center">
                <GjIcon name="ArrowDown" />
                <p class="p-0 m-0 ml-50">
                  {{ $t('lists.move-down') }}
                </p>
              </span>
            </b-dropdown-item>
            <b-dropdown-item
              class="mobile-only"
              @click.native.capture.stop="$emit('moveItemToTop',index, element)"
            >
              <span class="d-flex align-items-center">
                <GjIcon name="IconparkToTop" />
                <p class="p-0 m-0 ml-50">
                  {{ $t('lists.move-top') }}
                </p>
              </span>
            </b-dropdown-item>
            <b-dropdown-item
              class="mobile-only"
              @click.native.capture.stop="$emit('moveItemToBottom',index, element)"
            >
              <span class="d-flex align-items-center">
                <GjIcon name="IconparkToBottom" />
                <p class="p-0 m-0 ml-50">
                  {{ $t('lists.move-down') }}
                </p>
              </span>
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StatusChip from '@/views/entries/components/StatusChip.vue'
import { ENTRY_STATUSES_STRING } from '@/codex-sdk/entries'
import { getEntryModel } from '@/utils/helpers'
import moment from 'moment'

export default {
  name: 'EntryDraggableElement',
  components: { StatusChip },
  inject: ['showEntryEditorPopup'],
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    element: {
      type: Object,
      default: () => ({}),
    },
    index: {
      type: Number,
      default: 0,
    },
    showOnlyActiveEntries: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      getEntryModel,
    }
  },
  computed: {
    entries: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit('input', v)
      },
    },
    startAtPlaceholder() {
      return this.$t('lists.start-at-placeholder')
    },
    endAtPlaceholder() {
      return this.$t('lists.end-at-placeholder')
    },
    config() {
      return {
        enableTime: true,
        inline: this.isMobile,
        allowInput: false,
      }
    },
  },
  methods: {
    async editEntry(entry) {
      const res = await this.showEntryEditorPopup({
        model: entry.model,
        id: entry.entryId,
        siteId: entry.entry.system.siteId,
      })
      if (res.action) {
        const el = this.entries.find(e => e.entryId === entry.entryId)
        el.entry.system.title = res.entry.system.title
        el.entry.system.status = ENTRY_STATUSES_STRING[res.entry.system.status]
      }
    },
    getIndex(element) {
      return this.activeList.findIndex(e => e.entryId === element.entryId && element.order === e.order) + 1
    },
    isActive(entry) {
      if (entry.startAt && entry.endAt) return moment(this.currentDatetime).isBetween(entry.startAt, entry.endAt)
      if (entry.startAt) return moment(this.currentDatetime).isSameOrAfter(entry.startAt)
      if (entry.endAt) return moment(this.currentDatetime).isSameOrBefore(entry.endAt)
      return true
    },
  },
}
</script>

<style scoped lang="scss">

</style>
