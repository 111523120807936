import ability from '@/libs/acl/ability'
import { pr } from './config'

// For regEx search in VSCode: canViewIntegration|canCreateIntegration|canEditIntegration|canDeleteIntegration

/**
integration_view
integration_manage
 */
// * Create entity file, and can functions
// * Import and add them to allCanFunctions object down below
// * Add defaults into the permissions array down below
// Implement v-permission directives on neccessary buttons
// * Implement permissions on entities sdk functions
// * Implement permissions on navigation and route

export const Integration = Object.freeze({
  VIEW: 'integration_view',
  MANAGE: 'integration_manage',
})

export const IntegrationTooltips = {
  [Integration.VIEW]: 'Allows a user to view all settings in ‘view only’ mode, without performing any operations',
  [Integration.MANAGE]: 'Allows a user to edit any information in all sections of integration settings',
}

/**
 * _options
 *    entity = undefined
 *    showToast = false
 */

/**
 * Can VIEW Settings
 * */
export function canViewIntegrations(_options = {}) {
  const { entity, ...options } = _options

  return pr(
    ability.can(Integration.VIEW) || ability.can(Integration.MANAGE),

    'You have no permission to view integration settings',

    options,
  )
}

/**
 * Can CREATE Integration
 * */
export function canCreateIntegration(_options = {}) {
  const { entity, ...options } = _options

  return pr(
    ability.can(Integration.MANAGE),

    'You have no permission to create integration',

    options,
  )
}

/**
 * Can EDIT Integration
 * */
export function canEditIntegration(_options = {}) {
  const { entity, ...options } = _options

  return pr(
    ability.can(Integration.MANAGE),

    'You have no permission to edit integration',

    options,
  )
}

/**
 * Can DELETE Integration
 * */
export function canDeleteIntegration(_options = {}) {
  const { entity, ...options } = _options

  return pr(
    ability.can(Integration.MANAGE),

    'You have no permission to delete this integration',

    options,
  )
}
