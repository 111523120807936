<template>
  <b-form-group>
    <template #label>
      <FieldName
        :name="field.name"
        :help-text="''"
      />
    </template>

    <div
      v-if="authors && authors.length > 0"
      class="entries-card"
    >
      <div class="entries-list">
        <template>
          <div
            class="entries-list__draggable"
          >
            <div
              v-for="(author, index) in authors"
              :key="author.id + index"
              class="entries-list__item"
            >
              <div class="entries-list__item-icon">
                <GjIcon
                  name="Drag"
                  size="24"
                />
                <b-avatar
                  :text="isCharLetter(getDisplayName(author))"
                  :src="author.image ? author.image.url : null"
                  variant="default"
                  class="author-field__avatar"
                />
              </div>
              <div class="entries-list__item-content">
                <div class="entries-list__item-name">
                  {{ author.byline }}
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
    <div
      v-else
      class="content-list__empty"
    >
      <GjIcon name="AccountProfileUserCircle" />
      <span>{{ $tc('entries.merge-tool.info.no-author-value', field.valueType === VALUE_TYPES.SINGLE ? 1 : 2) }}</span>
    </div>
  </b-form-group>
</template>

<script>
import FieldName from '@/components/fields/FieldName.vue'
import { VALUE_TYPES } from '@/views/models/constants'
import gql from 'graphql-tag'

export default {
  name: 'AuthorField',
  components: {
    FieldName,
  },
  props: {
    fieldContent: {
      type: [String, Number, Array, Object],
    },
    field: {
      type: [Object],
    },
  },
  apollo: {
    authorCollection: {
      query: gql`
        query Authors($ids: [String!], $limit: Int) {
          authorCollection (
            where: { id: { in: $ids } }
            limit: $limit
          ) {
            items {
              id
              firstName
              lastName
              byline
              email
              image {
                url
              }
            }
            total
          }
        }
      `,
      fetchPolicy: 'no-cache',
      variables() {
        return {
          ids: this.ids,
          limit: this.ids?.length,
        }
      },
      result(results) {
        const authors = []
        this.ids.forEach(e => {
          const author = results.data.authorCollection.items.find(a => a.id === e)
          if (author) {
            authors.push(author)
          } else {
            authors.push({
              id: e,
              byline: this.$t('fields.codex-field-authors.render.not-found'),
            })
          }
        })
        this.authors = authors
        this.$apollo.queries.authorCollection.skip = true
        return results
      },
      skip() {
        return !this.ids?.length
      },
    },
  },
  data() {
    return {
      VALUE_TYPES,
      ids: [],
      authors: [],
    }
  },
  watch: {
    fieldContent: {
      handler(v) {
        this.authors = []
        this.ids = []
        if (v) {
          if (this.field.valueType === VALUE_TYPES.SINGLE) {
            this.ids.push(v.id)
          } else {
            this.ids = v.map(e => e.id) || []
          }
        }
        if (this.$apollo.queries.authorCollection) {
          this.$apollo.queries.authorCollection.skip = false
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    getDisplayName(author) {
      if (author.byline) return author.byline
      return `${author.firstName || ''} ${author.lastName || ''}`.trim()
    },
    isCharLetter(character) {
      const chars = []
      character.split('').forEach(char => {
        if ((/[a-zA-Z]/).test(char) && char.toLowerCase() != char.toUpperCase()) {
          chars.push(char)
        }
      })
      return chars[0]
    },
  },
}
</script>

<style lang="scss">

</style>
