import { Extension, Plugin, PluginKey } from 'tiptap'
import { GenerateUUID } from '@/utils/helpers'
import { CODEX_EDITOR_BLOCKS } from '@/components/codex-editor/CodeEditorConstants'

function checkForSameBlockIds(content, block) {
  if (content.content.filter(e => e.attrs.blockId === block.attrs.blockId).length > 1) { return true }

  const filteredContent = content.content.filter(x => x.type.name !== CODEX_EDITOR_BLOCKS.PARAGRAPH && x.content.content.length > 1)
  for (let i = 0; i < filteredContent.length; i++) {
    if (checkForSameBlockIds(filteredContent[i].content, block)) return true
  }

  return false
}

export default class ContentEditorBlockIds extends Extension {
  get name() {
    return 'codex-editor-block-ids'
  }

  get defaultOptions() {
    return {
    }
  }

  get plugins() {
    return [
      new Plugin({
        key: new PluginKey('codex-editor-block-ids'),
        appendTransaction(tr, oldState, newState) {
          const trans = newState.tr
          newState.doc.descendants((node, pos) => {
            // eslint-disable-next-line no-prototype-builtins
            if (!('blockId' in node.attrs)) return
            const sameBlockIds = checkForSameBlockIds(newState.doc.content, node)

            if (node.attrs.blockId && !sameBlockIds) return
            trans.setNodeMarkup(pos, null, { ...node.attrs, blockId: GenerateUUID.BLOCK() })
          })
          return trans
        },
      }),
    ]
  }
}
