<template>
  <div class="assets-metadata">
    <b-form-group :label="$t('assets.current-url')">
      <div class="assets-metadata__url">
        <span class="assets-metadata__url-title">
          {{ currentAsset.originalUrl }}
        </span>
        <GjIcon
          v-b-tooltip.hover="$t('assets.go-to')"
          name="Redirect"
          class="assets-metadata__url-icon cursor-pointer"
          size="18px"
          @click.native="redirectTo(currentAsset.originalUrl)"
        />
      </div>
    </b-form-group>

    <AppCollapse class="assets-metadata__collapsable">
      <AppCollapseItem
        :title="$t('assets.metadata.tabs-title.asset')"
        :is-visible="true"
      >
        <b-form-group
          v-permission="['canEditAssetLabels', currentAsset]"
          :disabled="disabled"
        >
          <DynamicLabels
            v-model="currentAsset.labels"
            :entity-type="LABEL_COMPONENT_ENTITY_TYPES.ASSET"
          />
        </b-form-group>
        <b-form-group label-for="input-title">
          <template v-slot:label>
            {{ $t('assets.metadata.title') }} <span
              v-if="isMetadataRequired && requiredMetadata.title"
              class="text-danger"
            >*</span>
          </template>
          <b-form-input
            id="input-title"
            v-model="currentAsset.title"
            class="full-input"
            trim
            :class="{'assets-metadata__invalid': isMetadataRequired && requiredMetadata.title && !currentAsset.title}"
            :disabled="disabled"
          />
        </b-form-group>

        <b-form-group label-for="input-caption">
          <template v-slot:label>
            {{ $t('assets.metadata.caption') }} <span
              v-if="isMetadataRequired && requiredMetadata.caption"
              class="text-danger"
            >*</span>
          </template>
          <b-form-textarea
            id="input-caption"
            v-model="currentAsset.caption"
            class="full-input"
            no-resize
            trim
            rows="2"
            :class="{'assets-metadata__invalid': isMetadataRequired && requiredMetadata.caption && !currentAsset.caption}"
            :disabled="disabled"
          />
        </b-form-group>

        <b-form-group label-for="input-alt">
          <template v-slot:label>
            {{ $t('assets.metadata.alt') }} <span
              v-if="isMetadataRequired && requiredMetadata.alt"
              class="text-danger"
            >*</span>
          </template>
          <b-form-textarea
            id="input-alt"
            v-model="currentAsset.alt"
            class="full-input"
            no-resize
            trim
            rows="2"
            :class="{'assets-metadata__invalid': isMetadataRequired && requiredMetadata.alt && !currentAsset.alt}"
            :disabled="disabled"
          />
        </b-form-group>

        <b-form-group label-for="input-source">
          <template v-slot:label>
            {{ $t('assets.metadata.source') }} <span
              v-if="isMetadataRequired && requiredMetadata.source"
              class="text-danger"
            >*</span>
          </template>
          <b-form-input
            id="input-source"
            v-model="currentAsset.source"
            class="full-input"
            trim
            :class="{'assets-metadata__invalid': isMetadataRequired && requiredMetadata.source && !currentAsset.source}"
            :disabled="disabled"
          />
        </b-form-group>

        <b-form-group label-for="input-author">
          <template v-slot:label>
            {{ $t('assets.metadata.author') }} <span
              v-if="isMetadataRequired && requiredMetadata.author"
              class="text-danger"
            >*</span>
          </template>
          <b-form-input
            id="input-author"
            v-model="currentAsset.author"
            class="full-input"
            trim
            :class="{'assets-metadata__invalid': isMetadataRequired && requiredMetadata.author && !currentAsset.author}"
            :disabled="disabled"
          />
        </b-form-group>

        <b-form-group label-for="input-copyright">
          <template v-slot:label>
            {{ $t('assets.metadata.copyright') }} <span
              v-if="isMetadataRequired && requiredMetadata.copyright"
              class="text-danger"
            >*</span>
          </template>
          <b-form-input
            id="input-copyright"
            v-model="currentAsset.copyright"
            class="full-input"
            trim
            :class="{'assets-metadata__invalid': isMetadataRequired && requiredMetadata.copyright && !currentAsset.copyright}"
            :disabled="disabled"
          />
        </b-form-group>
        <b-form-group label-for="select-tags">
          <template v-slot:label>
            {{ $t('assets.metadata.tags') }} <span
              v-if="isMetadataRequired && requiredMetadata.tags"
              class="text-danger"
            >*</span>
          </template>
          <TagsSelector
            id="select-tags"
            v-model="currentAsset.tags"
            :multiple="true"
            :taggable="true"
            :type="tagTypes.ASSET"
            :class="{'assets-metadata__invalid': isMetadataRequired && requiredMetadata.tags && (currentAsset.tags &&!currentAsset.tags.length)}"
            :disabled="disabled"
          />
        </b-form-group>
        <b-form-group :label="$t('assets.metadata.expiration.label')">
          <ExpireDateSelector
            v-model="currentAsset.expiresAt"
            :asset="currentAsset"
            :disabled="disabled"
          />
        </b-form-group>
      </AppCollapseItem>
      <AppCollapseItem :title="$t('assets.metadata.tabs-title.file')">
        <b-row
          v-if="currentAsset.type === ASSET_TYPES.IMAGE"
          class="assets-metadata__file-rows"
        >
          <b-col class="assets-metadata__file-labels">
            {{ $t('assets.metadata.file-metadata.resolution') }}
          </b-col>
          <b-col class="assets-metadata__file-values">
            {{ currentAsset.width }} x {{ currentAsset.height }}
          </b-col>
        </b-row>
        <b-row
          v-if="currentAsset.type === ASSET_TYPES.IMAGE || currentAsset.type === ASSET_TYPES.FILE"
          class="assets-metadata__file-rows"
        >
          <b-col class="assets-metadata__file-labels">
            {{ $t('assets.metadata.file-metadata.size') }}
          </b-col>
          <b-col class="assets-metadata__file-values">
            {{ bytesToSize(currentAsset.size, 2) }}
          </b-col>
        </b-row>
        <b-row class="assets-metadata__file-rows">
          <b-col class="assets-metadata__file-labels">
            {{ $t('assets.metadata.file-metadata.name') }}
          </b-col>
          <b-col
            class="assets-metadata__file-values"
            :title="currentAsset.fileName"
          >
            {{ currentAsset.fileName }}
          </b-col>
        </b-row>
        <b-row class="assets-metadata__file-rows">
          <b-col class="assets-metadata__file-labels">
            {{ $t('assets.metadata.file-metadata.type') }}
          </b-col>
          <b-col class="assets-metadata__file-values">
            {{ currentAsset.contentType }}
          </b-col>
        </b-row>
        <b-row
          v-if="currentAsset.type === ASSET_TYPES.VIDEO || currentAsset.type === ASSET_TYPES.AUDIO"
          class="assets-metadata__file-rows"
        >
          <b-col class="assets-metadata__file-labels">
            {{ $t('assets.metadata.file-metadata.duration') }}
          </b-col>
          <b-col class="assets-metadata__file-values">
            {{ duration }}
          </b-col>
        </b-row>
        <!-- Not Using b-row and b-col intentionally cuz some css problems        -->
        <div class="assets-metadata__file-rows">
          <div class="assets-metadata__file-labels">
            {{ $t('assets.metadata.file-metadata.id') }}
          </div>
          <div class="assets-metadata__file-values">
            {{ currentAsset.id }}
            <GjIcon
              name="Copy"
              size="14"
              @click.native="copyValue($t('assets.metadata.file-metadata.id'), currentAsset.id)"
            />
          </div>
        </div>
        <div
          v-if="currentAsset.externalId"
          class="assets-metadata__file-rows"
        >
          <div class="assets-metadata__file-labels">
            {{ $t('assets.metadata.file-metadata.external-id') }}
          </div>
          <div class="assets-metadata__file-values">
            {{ currentAsset.externalId }}
            <GjIcon
              name="Copy"
              size="14"
              @click.native="copyValue($t('assets.metadata.file-metadata.external-id'), currentAsset.externalId)"
            />
          </div>
        </div>

        <b-row
          v-for="row in customParameters"
          :key="row.key"
          class="assets-metadata__file-rows"
        >
          <b-col class="assets-metadata__file-labels">
            {{ row.key }}
          </b-col>
          <b-col
            class="assets-metadata__file-values"
            :title="row.value"
          >
            {{ row.value }}
            <GjIcon
              name="Copy"
              size="14"
              @click.native="copyValue(row.key, row.value)"
            />
          </b-col>
        </b-row>

      </AppCollapseItem>
      <AppCollapseItem :title="$t('assets.metadata.tabs-title.logs')">
        <EntityCreateUpdateInfo :entity="currentAsset" />
      </AppCollapseItem>
    </AppCollapse>
  </div>
</template>

<script>
import TagsSelector, { types } from '@/components/TagsSelector.vue'
import EntityCreateUpdateInfo from '@/components/EntityCreateUpdateInfo.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import { timeDuration, bytesToSize } from '@/utils/helpers'
import ExpireDateSelector from '@/views/assets/components/ExpireDateSelector.vue'
import { ASSET_TYPES } from '@/codex-sdk/assets'
import { useClipboard } from '@vueuse/core'
import DynamicLabels from '@/components/DynamicLabels.vue'
import { LABEL_COMPONENT_ENTITY_TYPES } from '@/codex-sdk/labels'

export default {
  name: 'AssetsMetadataFields',
  inject: ['toastNotification'],
  components: {
    ExpireDateSelector,
    TagsSelector,
    EntityCreateUpdateInfo,
    AppCollapseItem,
    DynamicLabels,
    AppCollapse,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    requiredMetadata: {
      type: Object,
      default: () => {},
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      LABEL_COMPONENT_ENTITY_TYPES,
      tagTypes: types,
      bytesToSize,
      ASSET_TYPES,
    }
  },
  computed: {
    customParameters() {
      if (!this.currentAsset?.customParameters) return []
      return Object.keys(this.currentAsset.customParameters).map(key => ({
        key,
        value: this.currentAsset.customParameters[key],
      }))
    },
    currentAsset: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit('input', v)
      },
    },
    duration() {
      return timeDuration(this.currentAsset.duration)
    },
    isMetadataRequired() {
      return this.requiredMetadata && this.requiredMetadata.isEnabled
    },
  },
  methods: {
    copyValue(key = '', value = '') {
      const { copy } = useClipboard()
      copy(value)

      this.toastNotification({
        title: this.$t('assets.metadata.file-metadata.copy-value.title'),
        icon: 'Copy',
        text: this.$t('assets.metadata.file-metadata.copy-value.text', { key }),
        variant: 'success',
      })
    },
    redirectTo(link) {
      window.open(link)
    },
  },
}
</script>

<style lang="scss">
.assets-metadata__collapsable .collapse-title {
  font-style: normal;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 18px !important;
  color: #052D61 !important;
}

.assets-metadata__collapsable .card-body {
  padding-top: 16px !important;
}

.assets-metadata__url {
  display: flex;
  align-items: center;
  max-width: 100%;
  overflow: hidden;
}
.assets-metadata__url-title {
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #667C99;
}
.assets-metadata__url-icon {
  color: #206ED5;
}

.assets-metadata__file-rows {
  margin-bottom: 10px;
  min-width: 0;
  display: flex;
  align-items: center;

  .gj-icon {
    cursor: pointer;
  }
}

.assets-metadata__file-labels {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #667C99;
}

.assets-metadata__file-values {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  text-align: right;
  color: #052D61;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  flex-grow: 2 !important;
}

.assets-metadata__invalid {
  border: 1px solid #e34850 !important;
  border-radius: 4px !important;
}

</style>
