<template>
  <div class="focal-point-preview__wrapper">
    <div class="focal-point__sidebar-header">
      <span class="focal-point__sidebar-title">
        {{ $t('popups.focal-point.title-update') }}
      </span>
      <span class="focal-point__sidebar-description">
        {{ $t('popups.focal-point.subtitle') }}
      </span>
    </div>
    <div class="focal-point-preview">
      <div>
        <div class="focal-point-preview__image focal-point-preview__image--wide">
          <img
            :style="`object-position: ${positions.left} ${positions.top}`"
            :src="src.url"
            alt=""
          >
        </div>
        <span>
          {{ $t('popups.focal-point.ratios.cover') }}
        </span>
      </div>
      <div>
        <div class="focal-point-preview__image focal-point-preview__image--square">
          <img
            :src="src.url"
            :style="`object-position: ${positions.left} ${positions.top}`"
            alt=""
          >
        </div>
        <span>
          {{ $t('popups.focal-point.ratios.square') }}
        </span>
      </div>
      <div>
        <div class="focal-point-preview__image focal-point-preview__image--portrait">
          <img
            :src="src.url"
            :style="`object-position: ${positions.left} ${positions.top}`"
            alt=""
          >
        </div>
        <span>
          {{ $t('popups.focal-point.ratios.portrait') }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FocalPointPreview',
  props: {
    src: {
      required: true,
    },
    positions: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style scoped lang="scss">
.focal-point-preview {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  span {
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #667C99;
  }
   div:first-child {
     width: 100%;
  }
}

.focal-point-preview__image {
  width: 100%;
  height: 70px;
  overflow: hidden;
  border-radius: 4px;

  &.focal-point-preview__image--wide {
    width: 100%;
  }

  &.focal-point-preview__image--square {
    width: 70px;
    min-width: 70px;
  }

  &.focal-point-preview__image--portrait {
    width: 49px;
    min-width: 49px;
  }

  img {
    width: 100%;
    max-width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

</style>
