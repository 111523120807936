import { apiAxios as axios } from '../libs/axios'
import { checkPermission, tc } from './config'
import { getCurrentOrganizationId } from '../services/siteService'

/**
 * Constants
 */

/**
 * Helpers
 */

export function siteDefaults(site = {}) {
  return {
    name: '',
    alias: '',
    description: '',
    logo: '',
    organizationId: getCurrentOrganizationId(),
    settings: {},
    id: '',
    defaultFolderId: null,
    ...site,
  }
}

export const SITES = Object.freeze({
  ALL: 'all',
})

/**
 * Transforms to received data
 */
export function transformSiteFromRequest(site) {
  return site
}

/**
 * CRUD Operations
 * */

/**
 *
 * @param {*} site
 *
 * @response
 * {
 *  id: GUID
 *  errors: null | Object
 *  success: true | false
 * }
 */
export function createSite(site, messages) {
  return checkPermission('canAddNewSite')
  && tc(axios.post('/{organization}/sites', site), {
    successTitle: 'sdk.success-title',
    successMessage: 'sdk.site.create-successful',
    failTitle: 'sdk.site.create-failed',
    catchError: false,
    ...messages,
  })
}

/**
 *
 * @response
 * [{
 *  id: GUID
 *  parentId: GUID | null
 *  title: String
 *  slug: String
 *  canContainArticle: Bool
 * }, {...}, ...]
 */
export function readSites() {
  return axios.get('v2/{organization}/sites', { params: { organization: getCurrentOrganizationId() } }).then(response => {
    response.data = response.data.map(site => transformSiteFromRequest(site))
    return response
  })
}

/**
 *
 * @response
 * [{
 *  id: GUID
 *  parentId: GUID | null
 *  title: String
 *  slug: String
 *  canContainArticle: Bool
 * }, {...}, ...]
 */
export function getUserSites() {
  return readSites().then(response => response.data.filter(site => site.currentUserIsPartOfSite))
}

/**
 *
 * @response
 * [{
 *  id: GUID
 *  parentId: GUID | null
 *  title: String
 *  slug: String
 *  canContainArticle: Bool
 * }, {...}, ...]
 */
export function searchSites() {
  return axios.get('/{organization}/sites/search')
}
/**
 *
 * @response
 * [{
 *  id: GUID
 *  parentId: GUID | null
 *  title: String
 *  slug: String
 *  canContainArticle: Bool
 * }, {...}, ...]
 */
export function searchRoles() {
  return axios.get('/{organization}/siterole/search')
}

/**
 *
 * @param {*} id
 *
 * @response
 * {
 *  id: GUID
 *  parentId: GUID | null
 *  title: String
 *  slug: String
 *  canContainArticle: Bool
 * }
 */
export function readSite(id) {
  return axios.get(`/{organization}/sites/${id}`).then(response => {
    response.data = transformSiteFromRequest(response.data)
    return response
  })
}

/**
 *
 * @param {*} site
 *
 * @response
 * {
 *  id: GUID
 *  errors: null | Object
 *  success: true | false
 * }
 */

export function updateOverview(id, settings, messages) {
  return checkPermission('canEditSite')

  && tc(axios.put(`/{organization}/sites/${id}`, settings), {
    successTitle: 'sdk.success-title',
    successMessage: 'sdk.site.update-successful',
    failTitle: 'sdk.site.update-failed',
    catchError: false,
    ...messages,
  })
}

export function updateSite(site, messages) {
  return updateOverview(site.id, site, messages)
}

export function siteSettings(siteId) {
  return axios.get(`/{organization}/sites/${siteId}/settings`).then(response => {
    response.data = transformSiteFromRequest(response.data)
    return response
  })
}

/**
 *
 * @param {*} siteId
 *
 * @response
 * {
 *  errors: null | Object
 *  success: true | false
 * }
 */
export function deleteSite(site, messages) {
  return checkPermission('canDeleteSite', { entity: site })
  && tc(axios.delete(`/{organization}/sites/${site.id}`), {
    successTitle: 'sdk.success-title',
    successMessage: 'sdk.site.delete-successful',
    failTitle: 'sdk.site.delete-failed',
    ...messages,
  })
}

/**
 *
 * @param {*} siteId
 *
 * @response
 * [{
 *  errors: null | Object
 *  success: true | false
 * },...]
 */
export function deleteSites(siteIds, messages) {
  return tc(Promise.all(siteIds.map(siteId => axios.delete(`/{organization}/sites/${siteId}`))), {
    successTitle: 'sdk.success-title',
    successMessage: 'sdk.site.delete-sites-successful',
    failTitle: 'sdk.site.delete-sites-failed',
    ...messages,
  }, true)
}
