<template>
  <div>
    <div
      v-for="(e,index) in entries"
      :key="`${e.id}-${index}`"
      class="entry-edit__reference"
    >
      <b-img-aspect
        v-if="e.system.featuredMedia"
        :key="e.id"
        aspect="1:1"
        style="width: 45px;"
        :src="e.system.featuredMedia.url"
      />
      <GjIcon
        v-else
        style="width: 45px;"
        size="24"
        :name="e.system.model.iconId"
      />
      <p v-b-tooltip.top="e.system.title">
        <span
          v-if="!e.system.title"
          class="entry-edit__reference-untitled"
        >
          {{ $t('entries.sidebar-tabs.references.untitled') }}
        </span>
        {{ e.system.title }}
      </p>
      <router-link
        :to="{name: 'entries-edit', params: { siteName: e.system.siteId, model: e.system.modelAlias, id: e.id }}"
        target="_blank"
      >
        <GjIcon
          class="entry-edit__reference-link"
          name="ArrowExportPopout"
        />
      </router-link>
      <StatusChip
        :entry="e"
        :no-text="true"
        :is-query="true"
        :scheduled-versions="e.system.scheduledVersions && e.system.scheduledVersions.items"
      />
    </div>
  </div>
</template>

<script>
import StatusChip from '@/views/entries/components/StatusChip.vue'

export default {
  name: 'ReferenceEntryList',
  components: {
    StatusChip,
  },
  props: {
    entries: {
      type: Array,
      default: () => ([]),
    },
  },
}
</script>
