import i18n from '@/libs/i18n'
import SectionListFilterComponent from './SectionList.vue'

export default class SectionListFilter {
  static COMPONENT = SectionListFilterComponent;

  static OPERATORS = {
    all: 'all',
    some: 'some',
    none: 'none',
    exists: 'exists',
    notExists: 'notExists',
  }

  static type = 'section-list'

  static graphqlType = 'SectionListFieldFilter'

  operator = null;

  value = null;

  includeChildren = false;

  constructor({ operator = SectionListFilter.OPERATORS.some, value, includeChildren = false } = {}) {
    this.operator = operator
    this.value = value
    this.includeChildren = includeChildren
  }

  set({ operator = SectionListFilter.OPERATORS.some, value, includeChildren = false }) {
    this.operator = operator
    this.value = value
    this.includeChildren = includeChildren
  }

  getCount() {
    if (this.operator === SectionListFilter.OPERATORS.exists || this.operator === SectionListFilter.OPERATORS.notExists) {
      return 1
    }
    return this.value?.length
  }

  getValueLabel({ cache }) {
    if (this.operator === SectionListFilter.OPERATORS.exists || this.operator === SectionListFilter.OPERATORS.notExists) {
      return this.operator === SectionListFilter.OPERATORS.exists ? i18n.t('filters.is-not-empty') : i18n.t('filters.is-empty')
    }
    const section = this.value.map(sectionId => cache[sectionId] || { id: sectionId })
    return section.map(m => m.title).join(', ')
  }

  toLoad() {
    return {
      sections: this.value,
      includeChildren: this.includeChildren,
    }
  }

  asQueryParam() {
    if (this.operator === SectionListFilter.OPERATORS.exists || this.operator === SectionListFilter.OPERATORS.notExists) {
      return {
        operator: this.operator,
      }
    } if (this.operator === SectionListFilter.OPERATORS.all || this.operator === SectionListFilter.OPERATORS.some) {
      return {
        operator: this.operator,
        value: this.value,
        includeChildren: this.includeChildren,
      }
    }
    return {
      operator: this.operator,
      value: this.value,
    }
  }

  asGraphQL(isReference = false) {
    switch (this.operator) {
      case SectionListFilter.OPERATORS.all:
        return {
          all: this.value,
          includeChildren: this.includeChildren != false,
        }
      case SectionListFilter.OPERATORS.some:
        if (isReference) {
          return {
            id: {
              some: this.value,
            },
            includeChildren: this.includeChildren != false,
          }
        }
        return {
          some: this.value,
          includeChildren: this.includeChildren != false,
        }
      case SectionListFilter.OPERATORS.none:
        return {
          none: this.value,
        }
      case SectionListFilter.OPERATORS.exists:
        return {
          exists: true,
        }
      case SectionListFilter.OPERATORS.notExists:
        return {
          exists: false,
        }
      default:
        return null
    }
  }

  asGraphQLDynamicList(isReference = false) {
    if (this.asGraphQL(isReference)) {
      return {
        ...this.asGraphQL(isReference),
        _t: SectionListFilter.graphqlType,
      }
    }
    return null
  }

  static validate({ operator, value }) {
    if (!operator) {
      return 'Operator is required'
    }

    if (operator === SectionListFilter.OPERATORS.exists || operator === SectionListFilter.OPERATORS.notExists) {
      return true
    }

    if (!value || value.length === 0) {
      return 'Value is required'
    }

    return true
  }

  static fromQueryParam({ operator, value, includeChildren }) {
    return new SectionListFilter({
      operator,
      value,
      includeChildren,
    })
  }

  static shouldApplyPredefinedValue(filter) {
    const forOperators = [SectionListFilter.OPERATORS.all, SectionListFilter.OPERATORS.some]
    return forOperators.includes(filter.operator)
  }
}
