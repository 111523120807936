<template>
  <div class="drag-and-browse">
    <img
      src="../../../assets/icons/upload.svg"
      class="no-assets-icon"
      @click="$emit('insert')"
    >
    <div class="drag-and-browse__text-container">
      <p
        class="drag-and-browse__text"
        @click="$emit('insert')"
      >
        <span class="drag-and-browse__browse hide-in-quick-view">{{ $t('assets.select.browse') }}</span> {{ $t('assets.select.dragAndDrop') }}
      </p>
      <span class="drag-and-browse__max-file">{{ $t(`fields.codex-field-media-content.preview.max-file`, { maxSize: maxFileSize }) }}</span>
    </div>
  </div>
</template>

<script>
import { VALUE_TYPES } from '@/views/models/constants'

export default {
  name: 'NoAssets',
  props: {
    valueType: {
      type: Number,
      default: VALUE_TYPES.SINGLE,
    },
    maxFileSize: {
      type: String,
      default: '32MB',
    },
  },
}
</script>

<style lang="scss" scoped>

.drag-and-browse {
  background: transparent;
  padding: 14px 20px;
  display: flex;
  gap: 10px;
  border-radius: 8px;
  border: 1px dashed var(--new-grey-grey-blue-300, #C8D0DA);
  .no-assets-icon {
    width: 32px;
    height: 32px;
  }
  .drag-and-browse__text-container{
    display: flex;
    flex-direction: column;
    gap: 4px;
    .drag-and-browse__text {
      cursor: pointer;
      color: #667C99;
      font-size: 14px;
      line-height: 18px;
      margin: 0;
      .drag-and-browse__browse {
        text-decoration-line: underline;

        &:hover {
          color: #4a5a6e;
        }
      }
    }
    .drag-and-browse__max-file{
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      color: #A3B0C2;
    }
  }

}
</style>
