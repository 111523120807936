<template>
  <b-modal
    id="gennea-disconnect__popup"
    v-model="showPopup"
    :title="$t('integrations.integrations.geneea.modal.title')"
    centered
    :ok-variant="showMoreDetails || (data.integration.alias === INTEGRATION_ALIASES.GENEEA_V2 ? showConnectionList : false) ? 'outline-secondary':'primary'"
    :ok-title="showPropperTitle"
    cancel-variant="outline-secondary"
    :cancel-title="$t('general.cancel')"
    size="md"
    :ok-only="showMoreDetails || data.integration.alias === INTEGRATION_ALIASES.GENEEA_V2"
    :ok-disabled="disableOk"
    :static="true"
    @ok="triggerPropperMethod"
    @cancel="handleCancel"
    @hide="handleCancel"
  >
    <div v-if="showMoreDetails">
      <div class="gennea-integration__info">
        <div class="integration-logo">
          <img
            src="../../../assets/icons/gennea.svg"
            alt=""
          >
        </div>
        <div class="gennea-integration__popup-header">
          <p>{{ getIntegration.name }}</p>
          <small>{{ $t('integrations.geneea-details') }}</small>
        </div>
      </div>
    </div>

    <div v-else>
      <div v-if="showConnectionList">
        <div class="gennea-integration__info">
          <div class="integration-logo">
            <img
              src="../../../assets/icons/gennea.svg"
              alt=""
            >
          </div>
          <div class="gennea-integration__popup-header">
            <p>{{ getIntegration.name }}</p>
            <small>{{ getIntegration.description }}</small>
            <p
              v-if="geneea.id"
              v-permission="['canDeleteIntegration']"
              class="disconnect-button"
              @click="disconnectGeneea"
            >
              {{ $t('integrations.card-buttons.disconnect') }}
            </p>
          </div>
        </div>

        <div
          v-if="data.integration.alias === INTEGRATION_ALIASES.GENEEA_V2"
          class="vp-integration__sidebar-content"
        >
          <div
            v-for="activation in activations"
            :key="activation.id"
            class="vp-integration__sidebar-connection"
          >
            <p @click="editActivation(activation)">
              {{ activation.attrs.connectionName }}
            </p>
            <div class="connection__more-actions">
              <div
                v-permission="['canEditIntegration']"
                class="edit-option"
                @click="editActivation(activation)"
              >
                <GjIcon
                  name="Edit"
                  size="20"
                  class="cursor-pointer"
                />
                <small>{{ $t('integrations.vp.edit') }}</small>
              </div>
              <div
                v-permission="['canDeleteIntegration']"
                class="disconnect-option"
                @click="disconnectActivation(activation)"
              >
                <GjIcon
                  name="DeleteTrash"
                  size="20"
                  class="cursor-pointer"
                />
                <small>{{ $t('integrations.vp.disconnect') }}</small>
              </div>
            </div>
          </div>
          <div>
            <p
              v-permission="['canCreateIntegration']"
              class="new-connection"
              @click="showConnectionList = !showConnectionList"
            >
              {{ $t('integrations.vp.add-connection') }}
            </p>
          </div>
        </div>
      </div>
      <b-form-group
        v-if="data.integration.alias !== INTEGRATION_ALIASES.GENEEA_V2"
        :label="$t('integrations.integrations.geneea.modal.user-key-label')"
        class="mt-2 user-key__input"
      >
        <b-input
          v-model="geneea.attrs.userKey"
          style="font-size:14px"
          :placeholder="$t('integrations.integrations.geneea.modal.user-key-placeholder')"
          autofocus
        />
      </b-form-group>
      <div v-else-if="data.integration.alias === INTEGRATION_ALIASES.GENEEA_V2 && !showConnectionList">
        <div class="new-connection__back-arrow">
          <GjIcon
            name="ArrowLeft"
            size="30"
            class="cursor-pointer"
            @click.native="goBack"
          />
          <p :class="{'connection-name': geneea.attrs.connectionName}">
            {{ geneea.attrs.connectionName ? geneea.attrs.connectionName : "New" }}
          </p>
        </div>
        <div class="vp-connection__new">
          <b-form-group label="Connection Name:">
            <b-input
              v-model="geneea.attrs.connectionName"
              style="font-size:14px"
              autofocus
              placeholder="Enter Connection Name"
            />
          </b-form-group>
          <b-form-group label="Api Key:">
            <b-input
              v-model="geneea.attrs.userKey"
              style="font-size:14px"
              placeholder="Enter Api Key"
            />
          </b-form-group>
          <b-form-group label="Sites:">
            <SitesDropdownSelect
              v-model="geneea.attrs.sites"
              placeholder="Select a site"
              class="sites-dropdown"
              :all="true"
              :clearable="false"
              :integration="!geneea.id ? getIntegration : null"
            />
          </b-form-group>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import PopupsMixin from '@/components/popups/PopupsMixin'
import { geneeaDefaults, INTEGRATION_ALIASES } from '@/codex-sdk/all-integrations'
import { cloneDeep } from 'lodash'
import { canEditIntegration } from '@/codex-permissions/integrations'
import SitesDropdownSelect from '@/components/SitesDropdownSelect.vue'
import { ENTITY_TYPES } from '@/utils/constants'

export default {
  components: { SitesDropdownSelect },
  mixins: [PopupsMixin],
  inject: ['showConfirmDeletePopup'],
  data() {
    return {
      INTEGRATION_ALIASES,
      geneea: geneeaDefaults(),
      showConnectionList: true,
      activations: [],
    }
  },
  computed: {
    getIntegration() {
      return this.data.integration
    },
    showMoreDetails() {
      return this.data.showMoreDetails
    },
    triggerPropperMethod() {
      if (this.data.integration.alias === INTEGRATION_ALIASES.GENEEA_V2 && (this.showMoreDetails || this.showConnectionList || !this.geneea)) return this.handleCancel
      if (this.showMoreDetails || !this.geneea) return this.handleCancel
      return this.handleOk
    },
    disableOk() {
      if (this.data.integration.alias === INTEGRATION_ALIASES.GENEEA_V2) {
        return (!this.showConnectionList && !canEditIntegration().can) || (!Object.values(this.geneea?.attrs).every(value => value) && !this.showConnectionList)
      }
      return !canEditIntegration().can || (!Object.values(this.geneea?.attrs).every(value => value) && !this.showMoreDetails)
    },
    geneeaConnections() {
      return this.$store.getters['general/getConnections'](this.getIntegration.alias) || {}
    },
    showPropperTitle() {
      if (this.data.integration.alias !== INTEGRATION_ALIASES.GENEEA_V2) {
        if ((!this.showMoreDetails && !this.geneea.id)) {
          return this.$t('general.connect')
        }
        if ((!this.showMoreDetails && this.geneea.id)) {
          return this.$t('general.save')
        }
      }
      if (!this.showConnectionList && !this.geneea.id) {
        return this.$t('general.connect')
      }

      if (!this.showConnectionList) {
        return this.$t('general.save')
      }

      return this.$t('general.cancel')
    },
  },
  mounted() {
    if (this.geneeaConnections.length && this.data.integration.alias !== INTEGRATION_ALIASES.GENEEA_V2) {
      this.geneea = cloneDeep(this.geneeaConnections[0])
    } else {
      this.activations = cloneDeep(this.geneeaConnections)
    }
  },
  methods: {
    canEditIntegration,
    async editActivation(activation) {
      try {
        this.showConnectionList = !this.showConnectionList
        this.geneea = cloneDeep(activation)
      } catch (error) {
        console.log(error)
      }
    },
    async disconnectActivation(activation) {
      const result = await this.showConfirmDeletePopup({
        items: [activation.attrs.connectionName],
        type: ENTITY_TYPES.CONNECTION,
      })
      try {
        if (result) {
          await this.$store.dispatch('general/removeConnections', activation)
          this.activations = this.activations.filter(getActivation => getActivation.id !== activation.id)
        }
      } catch (err) {
        console.log(err)
      }
    },
    goBack() {
      this.showConnectionList = true
      this.geneea = geneeaDefaults()
    },
    async handleOk() {
      try {
        if (!this.geneea.id) {
          this.geneea.integration = this.getIntegration.alias
          await this.$store.dispatch('general/addConnections', this.geneea)
        } else {
          await this.$store.dispatch('general/updateConnections', this.geneea)
        }
      } catch (err) {
        console.log(err)
      }
    },
    handleCancel() {
      this.closePopup(this.data?.integration?.alias === INTEGRATION_ALIASES.GENEEA_V2 ? this.activations : this.geneea)
    },
    async disconnectGeneea() {
      const result = await this.showConfirmDeletePopup({
        items: [this.geneea.attrs.userKey],
        type: ENTITY_TYPES.CONNECTION,
      })
      try {
        if (result) {
          this.$store.dispatch('general/removeConnections', this.geneea)

          this.geneea.attrs.userKey = null
          this.closePopup(this.geneea)
        }
      } catch (err) {
        console.log(err)
      }
    },
  },
}
</script>
<style lang='scss' scoped>

.gennea-integration__info{
  display: flex;
  width: 100%;
  align-items: flex-start;
  gap: 30px;
  margin: 3% auto;

  img{
    width: 36px;
    height: 36px;
  }

  .integration-logo{
    background: linear-gradient(
      180deg,
      #d9d9d9 0%,
      #f3f2f4 0.01%,
      #ebddfd 100%
    );
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
  }

  .gennea-integration__popup-header{
    p {
      font-weight: 500;
      font-size: 18px;
      color: #052D61;
      margin-bottom: 5px;
    }

    small{
      font-size: 14px;
      color: #667C99;
    }

    .disconnect-button{
      font-weight: 600;
      font-size: 14px;
      background: #E34850;
      padding: 8px 14px;
      color: white;
      width: fit-content;
      border-radius: 42px;
      margin-top: 3%;
      cursor: pointer;
    }
  }
}
</style>
