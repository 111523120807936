<template>
  <div>
    <FieldConditions
      v-model="validation.required"
      :widget="widget"
      :label="$t('fields.general.required.label')"
      :tooltip="$t('fields.general.required.info')"
      :error-message-label="$t('fields.general.required.error-message')"
    />

    <FieldConditions
      v-model="validation.readOnly"
      :widget="widget"
      :label="$t('fields.general.read-only.label')"
      :show-error-message="false"
    />

    <b-form-group>
      <b-form-checkbox v-model="validation.requiredMetadata.isEnabled">
        <span class="media-field__required-value">
          {{ $t('fields.codex-field-media-content.validation.metadata.required') }}
        </span>
        <GjIcon
          v-b-tooltip.hover="$t('fields.codex-field-media-content.validation.metadata.required-info')"
          name="Info"
          size="20"
        />
      </b-form-checkbox>
    </b-form-group>
    <b-form-group
      v-if="validation.requiredMetadata.isEnabled"
      class="ml-1"
    >
      <b-form-checkbox v-model="validation.requiredMetadata.title">
        <span>
          {{ $t('fields.codex-field-media-content.validation.metadata.title') }}
        </span>
      </b-form-checkbox>
      <b-form-checkbox v-model="validation.requiredMetadata.caption">
        <span>
          {{ $t('fields.codex-field-media-content.validation.metadata.caption') }}
        </span>
      </b-form-checkbox>
      <b-form-checkbox v-model="validation.requiredMetadata.alt">
        <span>
          {{ $t('fields.codex-field-media-content.validation.metadata.alt') }}
        </span>
      </b-form-checkbox>
      <b-form-checkbox v-model="validation.requiredMetadata.source">
        <span>
          {{ $t('fields.codex-field-media-content.validation.metadata.source') }}
        </span>
      </b-form-checkbox>
      <b-form-checkbox v-model="validation.requiredMetadata.author">
        <span>
          {{ $t('fields.codex-field-media-content.validation.metadata.author') }}
        </span>
      </b-form-checkbox>
      <b-form-checkbox v-model="validation.requiredMetadata.copyright">
        <span>
          {{ $t('fields.codex-field-media-content.validation.metadata.copyright') }}
        </span>
      </b-form-checkbox>
      <b-form-checkbox v-model="validation.requiredMetadata.tags">
        <span>
          {{ $t('fields.codex-field-media-content.validation.metadata.tags') }}
        </span>
      </b-form-checkbox>
    </b-form-group>
    <b-form-group
      v-if="validation.requiredMetadata.isEnabled"
      :label="$t('fields.codex-field-media-content.validation.metadata.required-error-message')"
      class="form-group__validation-last-field-margin"
    >
      <b-form-input v-model="validation.requiredMetadata.errorMessage" />
    </b-form-group>

    <b-form-group v-if="valueType === VALUE_TYPES.LIST">
      <b-form-checkbox v-model="validation.numberOfAssets.isEnabled">
        <span>
          {{ $t('fields.codex-field-media-content.validation.number-of-assets') }}
        </span>
      </b-form-checkbox>
    </b-form-group>

    <b-form-group v-if="valueType === VALUE_TYPES.LIST && validation.numberOfAssets.isEnabled">
      <b-form-group>
        <v-select
          v-model="validation.numberOfAssets.rangeOperator"
          :options="options"
          :reduce="e => e.value"
          :clearable="false"
          @input="changeRangeOperator"
        />
      </b-form-group>
      <b-row>
        <b-col v-if="validation.numberOfAssets.rangeOperator === RANGE_OPERATORS.BETWEEN || validation.numberOfAssets.rangeOperator === RANGE_OPERATORS.GTE">
          <b-form-group :label="$t('fields.codex-field-media-content.validation.min')">
            <b-form-input
              v-model.number="validation.numberOfAssets.min"
              min="0"
              :state="!validationError.isValid ? validationError.isValid : null"
              type="number"
              @keydown="validateNumber($event, validation.numberOfAssets.min)"
              @input="validationError = checkRangeValidation(validation.numberOfAssets)"
            />
          </b-form-group>
        </b-col>
        <b-col v-if="validation.numberOfAssets.rangeOperator === RANGE_OPERATORS.BETWEEN || validation.numberOfAssets.rangeOperator === RANGE_OPERATORS.LTE">
          <b-form-group :label="$t('fields.codex-field-media-content.validation.max')">
            <b-form-input
              v-model.number="validation.numberOfAssets.max"
              min="0"
              :state="!validationError.isValid ? validationError.isValid : null"
              type="number"
              @keydown="validateNumber($event, validation.numberOfAssets.max)"
              @input="validationError = checkRangeValidation(validation.numberOfAssets)"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-form-group v-if="!validationError.isValid">
        <b-form-invalid-feedback :state="validationError.isValid">
          {{ $t(validationError.emptyMessage) }}
        </b-form-invalid-feedback>
        <b-form-invalid-feedback :state="validationError.isValid">
          {{ $t(validationError.differenceMessage) }}
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group>
        <template #label>
          <label>{{ $t('fields.codex-field-media-content.validation.custom-error-template') }}</label>
          <span
            id="media__inputs-allowed-tooltip"
            class="codex-field-range-tooltip"
          >
            <GjIcon
              name="Info"
              size="20"
            />
          </span>
        </template>
        <PlaceholdersTooltip
          :validation="validation.numberOfAssets.rangeOperator"
          :target="'media__inputs-allowed-tooltip'"
        />
        <b-form-input
          v-model="validation.numberOfAssets.errorTemplate"
          :placeholder="$t('fields.codex-field-media-content.validation.custom-error-placeholder')"
          type="text"
        />
      </b-form-group>
      <b-form-group :label="$t('fields.codex-field-media-content.validation.custom-error')">
        <b-form-input
          v-model="validation.numberOfAssets.errorMessage"
          readonly
          type="text"
        />
      </b-form-group>
    </b-form-group>

    <b-form-group>
      <b-form-checkbox v-model="validation.dimensions.isEnabled">
        {{ $t('fields.codex-field-media-content.validation.dimensions.title') }}
        <GjIcon
          v-b-tooltip.hover="$t('fields.codex-field-media-content.validation.dimensions.tooltip')"
          name="Info"
          size="20"
        />
      </b-form-checkbox>
    </b-form-group>

    <b-form-group v-if="validation.dimensions.isEnabled">
      <b-form-group :label="$t('fields.codex-field-media-content.validation.dimensions.width-label')">
        <b-row>
          <b-col>
            <b-form-group :label="$t('fields.codex-field-media-content.validation.dimensions.min')">
              <b-form-input
                v-model.number="validation.dimensions.minWidth"
                min="0"
                type="number"
                :state="checkDimensionsValidation.width"
                @keydown="validateNumber($event, validation.dimensions.minWidth)"
              />
            </b-form-group>

          </b-col>
          <b-col>
            <b-form-group :label="$t('fields.codex-field-media-content.validation.dimensions.max')">
              <b-form-input
                v-model.number="validation.dimensions.maxWidth"
                min="0"
                type="number"
                :state="checkDimensionsValidation.width"
                @keydown="validateNumber($event, validation.dimensions.maxWidth)"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-invalid-feedback :state="checkDimensionsValidation.width">
              {{ $t('fields.codex-field-media-content.validation.dimensions.minMaxDifference') }}
            </b-form-invalid-feedback>
          </b-col>
        </b-row>

      </b-form-group>

      <b-form-group :label="$t('fields.codex-field-media-content.validation.dimensions.height-label')">
        <b-row>
          <b-col>
            <b-form-group :label="$t('fields.codex-field-media-content.validation.dimensions.min')">
              <b-form-input
                v-model.number="validation.dimensions.minHeight"
                min="0"
                type="number"
                :state="checkDimensionsValidation.height"
                @keydown="validateNumber($event, validation.dimensions.minHeight)"
              />
            </b-form-group>

          </b-col>
          <b-col>
            <b-form-group :label="$t('fields.codex-field-media-content.validation.dimensions.max')">
              <b-form-input
                v-model.number="validation.dimensions.maxHeight"
                min="0"
                type="number"
                :state="checkDimensionsValidation.height"
                @keydown="validateNumber($event, validation.dimensions.maxHeight)"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-form-invalid-feedback :state="checkDimensionsValidation.height">
          {{ $t('fields.codex-field-media-content.validation.dimensions.minMaxDifference') }}
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group>
        <template #label>
          <label>{{ $t('fields.codex-field-media-content.validation.custom-error-template') }}</label>
          <span
            id="media__inputs-dimensions-tooltip"
            class="codex-field-range-tooltip"
          >
            <GjIcon
              name="Info"
              size="20"
            />
          </span>
        </template>
        <!-- Don't remove "custom-class" prop -->
        <b-tooltip
          target="media__inputs-dimensions-tooltip"
          triggers="hover"
          custom-class=""
        >
          <p>{{ $t('fields.codex-field-media-content.validation.dimensions.placeholder') }}</p>
          <ul>
            <li>{{ $t('{minWidth}') }}</li>
            <li>{{ $t('{maxWith}') }}</li>
            <li>{{ $t('{minHeight}') }}</li>
            <li>{{ $t('{maxHeight}') }}</li>
          </ul>
        </b-tooltip>
        <b-form-textarea
          v-model="validation.dimensions.errorTemplate"
          :placeholder="$t('fields.codex-field-media-content.validation.custom-error-placeholder')"
          type="text"
          rows="3"
          no-resize
        />
      </b-form-group>
      <b-form-group :label="$t('fields.codex-field-media-content.validation.custom-error')">
        <b-form-textarea
          v-model="validation.dimensions.errorMessage"
          readonly
          type="text"
          rows="3"
          no-resize
        />
      </b-form-group>
    </b-form-group>
    <b-form-group>
      <b-form-checkbox v-model="validation.requiredLabels.isEnabled">
        {{ $t('fields.codex-field-media-content.validation.required-labels.title') }}
        <GjIcon
          v-b-tooltip.hover="$t('fields.codex-field-media-content.validation.required-labels.tooltip')"
          name="Info"
          size="20"
        />
      </b-form-checkbox>
    </b-form-group>
    <b-form-group
      v-if="validation.requiredLabels.isEnabled"
      :label="$t('fields.codex-field-media-content.validation.required-labels.label')"
    >
      <v-select
        v-model="validation.requiredLabels.allowedLabels"
        :options="labels"
        :close-on-select="false"
        deselect-from-dropdown
        label="name"
        :multiple="true"
        :reduce="(label) => label.id"
      >
        <template #option="{name, color}">
          <div class="d-flex align-items-center">
            <div
              class="mr-1"
              :style="{ backgroundColor: color, width: '10px', height: '10px', borderRadius: '1000px' }"
            />
            {{ name }}
          </div>
        </template>
      </v-select>
    </b-form-group>
    <b-form-group
      v-if="validation.requiredLabels.isEnabled"
      :label="$t('fields.codex-field-media-content.validation.required-labels.error-message')"
    >
      <b-form-textarea
        v-model="validation.requiredLabels.errorMessage"
        type="text"
        rows="3"
        no-resize
      />
    </b-form-group>
  </div>
</template>

<script>
import { generateComputedPropsFromAttrs } from '@/components/codex-layout-editor/BuilderUtils'
import {
  RANGE_OPERATORS, VALUE_TYPES, checkRangeValidation, validateNumber, errorTemplateParser,
} from '@/views/models/constants'
import { isNumber } from 'lodash'
import gql from 'graphql-tag'
import FieldConditions from '@/components/fields/FieldConditions.vue'
import BaseFieldMixin from '@/components/fields/BaseFieldMixin'
import PlaceholdersTooltip from '../PlaceholdersTooltip.vue'

export default {
  name: 'Validations',
  components: { FieldConditions, PlaceholdersTooltip },
  mixins: [BaseFieldMixin],
  apollo: {
    labelCollection: {
      query: gql`query {
          labelCollection(limit: 1000, where:  {
            entities: {some: [ALL,ASSET]}
          }) {
            items {
              id
              name
              description
              color
          }
        }
      }
      `,
      result({ data }) {
        this.labels = data.labelCollection.items
      },
      fetchPolicy: 'network-only',
    },
  },
  data() {
    return {
      VALUE_TYPES,
      RANGE_OPERATORS,
      validateNumber,
      checkRangeValidation,
      labels: [],
      validationError: {
        isValid: true,
        emptyMessage: '',
        differenceMessage: '',
      },
    }
  },
  computed: {
    ...generateComputedPropsFromAttrs([
      'validation',
      'valueType',
    ]),
    options() {
      return [
        { label: this.$t('fields.codex-field-media-content.validation.options.between'), value: RANGE_OPERATORS.BETWEEN },
        { label: this.$t('fields.codex-field-media-content.validation.options.at-least'), value: RANGE_OPERATORS.GTE },
        { label: this.$t('fields.codex-field-media-content.validation.options.no-more-than'), value: RANGE_OPERATORS.LTE },
      ]
    },
    predefinedErrorTemplates() {
      return {
        [RANGE_OPERATORS.BETWEEN]: this.$t('fields.codex-field-media-content.validation.number-of-assets-templates.between'),
        [RANGE_OPERATORS.GTE]: this.$t('fields.codex-field-media-content.validation.number-of-assets-templates.gte'),
        [RANGE_OPERATORS.LTE]: this.$t('fields.codex-field-media-content.validation.number-of-assets-templates.lte'),
      }
    },
    checkDimensionsValidation() {
      const validation = { width: null, height: null }

      if (isNumber(this.validation.dimensions.minWidth) && isNumber(this.validation.dimensions.maxWidth) && this.validation.dimensions.minWidth > this.validation.dimensions.maxWidth) {
        validation.width = false
      }
      if (isNumber(this.validation.dimensions.minHeight) && isNumber(this.validation.dimensions.maxHeight) && this.validation.dimensions.minHeight > this.validation.dimensions.maxHeight) {
        validation.height = false
      }
      return validation
    },
  },
  watch: {
    'validation.numberOfAssets.rangeOperator': function (v) {
      this.validation.numberOfAssets.errorTemplate = this.predefinedErrorTemplates[v]
    },
    'validation.numberOfAssets': {
      handler() {
        errorTemplateParser(this.validation.numberOfAssets)
      },
      deep: true,
    },
    'validation.dimensions': {
      handler() {
        let text = this.validation.dimensions.errorTemplate
        text = text.replaceAll('{minWidth}', this.validation.dimensions.minWidth)
        text = text.replaceAll('{maxWidth}', this.validation.dimensions.maxWidth)
        text = text.replaceAll('{minHeight}', this.validation.dimensions.minHeight)
        text = text.replaceAll('{maxHeight}', this.validation.dimensions.maxHeight)
        this.validation.dimensions.errorMessage = text
      },
      deep: true,
    },
  },
  mounted() {
    this.validationError = checkRangeValidation(this.validation.numberOfAssets)
  },
  methods: {
    changeRangeOperator() {
      this.validationError = checkRangeValidation(this.validation.numberOfAssets)
    },
  },
}
</script>
