import ability from '@/libs/acl/ability'
import { pr } from './config'

/**
 plugin_view
 plugin_create
 plugin_activate
 plugin_delete
 */
// For regEx search in VSCode: canViewPlugins|canActivatePlugins|canDeletePlugins

// * Create entity file, and can functions
// * Import and add them to allCanFunctions object down below
// * Add defaults into the permissions array down below
// * Implement v-permission directives on necessary buttons
// * Implement permissions on entities sdk functions
// * Implement permissions on navigation and route

export const Plugin = Object.freeze({
  VIEW: 'plugin_view',
  // CREATE: 'plugin_create', // Not used in admin
  ACTIVATE: 'plugin_activate',
  DELETE: 'plugin_delete',
})

export const PluginTooltips = {
  [Plugin.VIEW]: '',
  // [Plugin.CREATE]: '', // Not used in admin
  [Plugin.ACTIVATE]: '',
  [Plugin.DELETE]: '',
}

/**
 * _options
 *    entity = undefined
 *    showToast = false
 */

/**
 * Can VIEW Plugins
 * */
export function canViewPlugins(_options = {}) {
  const { entity, ...options } = _options

  return pr(
    ability.can(Plugin.VIEW),

    'You have no permission to view plugins',

    options,
  )
}

/**
 * Can ACTIVATE Plugin
 * */
export function canActivatePlugins(_options = {}) {
  const { entity, ...options } = _options

  return pr(
    ability.can(Plugin.ACTIVATE),

    'You have no permission to activate plugins',

    options,
  )
}

/**
 * Can DELETE Plugin
 * */
export function canDeletePlugins(_options = {}) {
  const { entity, ...options } = _options

  return pr(
    ability.can(Plugin.DELETE),

    'You have no permission to delete plugins',

    options,
  )
}
