<template>
  <div>
    <b-form-group
      :label="$t('fields.codex-field-json.general.name-label')"
      label-for="at-json-object-field__name-input"
    >
      <b-form-input
        id="at-json-object-field__name-input"
        v-model.trim="name"
        :placeholder="$t('fields.codex-field-json.general.name-placeholder')"
      />
    </b-form-group>
    <b-form-group
      :label="$t('models.new-model.alias')"
      label-for="at-json-object-field__alias-input"
      aria-required="true"
    >
      <b-form-input
        id="at-json-object-field__alias-input"
        v-model.trim="tempAlias"
        :placeholder="$t('fields.codex-field-json.general.alias-placeholder')"
        :state="tempAlias === null ? null : !aliasState"
        trim
      />
      <b-form-invalid-feedback v-if="!nameState.isValid">
        {{ $t('fields.general.validation.alias-invalid') }}
      </b-form-invalid-feedback>
      <b-form-invalid-feedback v-if="nameState.isReserved">
        {{ $t('fields.general.validation.alias-is-reserved') }}
      </b-form-invalid-feedback>
      <b-form-invalid-feedback v-if="!nameState.isUnique">
        {{ $t('fields.general.validation.alias-is-used') }}
      </b-form-invalid-feedback>
    </b-form-group>
    <b-form-group
      :label="$t('fields.codex-field-json.general.description-label')"
      label-for="at-json-object-field__description-input"
    >
      <b-form-textarea
        id="at-json-object-field__description-input"
        v-model="description"
        :placeholder="$t('fields.codex-field-json.general.description-placeholder')"
        rows="2"
        no-resize
      />
    </b-form-group>
    <b-button
      id="at-json-object-field__create-button"
      variant="primary"
      class="w-100"
      :disabled="aliasState"
      @click="create"
    >
      {{ $t('fields.codex-field-json.general.create') }}
    </b-button>
  </div>
</template>

<script>
import { generateComputedPropsFromAttrs } from '@/components/codex-layout-editor/BuilderUtils'
import FieldValidationMixin from '@/components/fields/FieldValidationMixin'
import BaseFieldMixin from '@/components/fields/BaseFieldMixin'

export default {
  inject: ['validateAlias'],
  mixins: [BaseFieldMixin, FieldValidationMixin],
  computed: {
    ...generateComputedPropsFromAttrs([
      'name',
      'alias',
      'description',
      'configured',
    ]),
    aliasState() {
      return this.nameState.isReserved || !this.nameState.isUnique || !this.nameState.isValid
    },
  },
}
</script>
