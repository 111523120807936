/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable comma-dangle */
/* eslint-disable no-use-before-define */
/* eslint-disable import/no-extraneous-dependencies */
import {
  vuexOidcCreateStoreModule,
  vuexOidcCreateRouterMiddleware,
} from 'vuex-oidc'
// eslint-disable-next-line import/no-cycle
import store from '@/store'
// eslint-disable-next-line import/no-cycle
import useJwt from '@/auth/jwt/useJwt'
import ability from '@/libs/acl/ability'
// eslint-disable-next-line import/no-cycle
import router from '@/router'
import decode from 'jwt-decode'
import { initialAbility } from '@/libs/acl/config'
import { authConfig } from './config'

export const OIDC_STORE_MODULE = 'oidcStore'

export const oidcSettings = JSON.parse(process.env.VUE_APP_OIDC_CONFIG)
oidcSettings.monitorSession = false
oidcSettings.clockSkew = 3156000000
oidcSettings.silentRedirectUri = null
oidcSettings.checkSessionInterval = Math.min()

export const authRoutes = [
  {
    path: '/callback', // Needs to match redirectUri in you oidcSettings
    name: 'oidcCallback',
    component: () => import('./OidcCallback.vue'),
    meta: {
      layout: 'full',
      isPublic: true,
      resource: 'Auth',
    },
  },
]

// eslint-disable-next-line no-shadow
export function authRoutesGuard(router) {
  router.beforeEach(async (to, from, next) => {
    if (to.meta.isPublic) {
      next()
      return
    }

    const token = localStorage.getItem(useJwt.jwtConfig.storageTokenKeyName)
    if (!token) {
      next({
        name: 'auth-login',
        query: {
          redirectTo: `${window.location.pathname}${window.location.search}`,
        },
      })
      return
    }

    const decodedAccessToken = decode(token)
    if (Date.now() >= decodedAccessToken.exp * 1000) {
      next({
        name: 'auth-login',
        query: {
          redirectTo: `${window.location.pathname}${window.location.search}`,
        },
      })
      return
    }

    next()
  })

  router.beforeEach(vuexOidcCreateRouterMiddleware(store, 'oidcStore'))
}

export const oidcStoreModule = vuexOidcCreateStoreModule(
  oidcSettings,
  // NOTE: If you do not want to use localStorage for tokens, in stead of just passing oidcSettings, you can
  // spread your oidcSettings and define a userStore of your choice
  // {
  //   ...oidcSettings,
  //   userStore: new WebStorageStateStore({ store: window.sessionStorage })
  // },
  // Optional OIDC store settings
  {
    namespaced: true,
    dispatchEventsOnWindow: true,
  },
  // Optional OIDC event listeners
  {
    userLoaded: async user => {
      useJwt.setToken(user.access_token)
      useJwt.setRefreshToken(user.refresh_token)

      const decodedAccessToken = decode(user.access_token)

      const userObject = {
        id: decodedAccessToken.sub,
        fullName: decodedAccessToken.name,
        username: decodedAccessToken.email,
        avatar: decodedAccessToken.picture || '/img/13-small.d796bffd.png',
        email: decodedAccessToken.email,
        role: 'admin',
        ability: [{ action: 'manage', subject: 'all' }],

        given_name: decodedAccessToken.given_name || '',
        family_name: decodedAccessToken.family_name || '',
        birthdate: decodedAccessToken.birthdate || '',
        gender: decodedAccessToken.gender || '',
        picture: decodedAccessToken.picture || '',
        preferred_username: decodedAccessToken.preferred_username || '',
      }

      const userData = await authConfig.userLoginHook(
        userObject,
        user,
        decodedAccessToken,
      )

      if (userData === null || userData === undefined) {
        logoutUser('auth-login')

        return
      }

      localStorage.setItem('userData', JSON.stringify(userData))
      ability.update(userData.ability)

      const isCallback = window.location.pathname === '/callback'

      if (isCallback) {
        router
          .push(
            authConfig.userRedirectHook(
              sessionStorage.getItem('vuex_oidc_active_route') || '/',
            ),
          )
          .catch(() => {})
      }

      console.log('OIDC user is loaded:', user)
    },
    userUnloaded: () => console.log('OIDC user is unloaded'),
    accessTokenExpiring: () => {
      console.log('Access token will expire')
      useJwt.refreshToken()
    },
    accessTokenExpired: () => {
      console.log('Access token did expire')
      console.log('calling renew Token...')
      useJwt.refreshToken()
    },
    silentRenewError: () => {
      console.log('OIDC user is unloaded')
      logoutUser('auth-login')
    },
    userSignedOut: () => {
      console.log('OIDC user is signed out')
      logoutUser('auth-login')
    },
    oidcError: payload => {
      console.log('OIDC error', payload)
      authConfig.oidcErrorHook(payload)

      if (payload && payload.error === 'invalid_grant') {
        logoutUser('auth-login')
      }
    },
    automaticSilentRenewError: payload => {
      console.log('OIDC automaticSilentRenewError', payload)
      logoutUser('auth-login')
    },
  },
)

export async function logoutUser(redirectPath) {
  // Remove userData from localStorage
  // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
  localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
  localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

  // Remove userData from localStorage
  localStorage.removeItem('userData')

  // Wait for logout to complete
  await store.dispatch(`${OIDC_STORE_MODULE}/signOutOidcSilent`)

  await authConfig.userLogoutHook()

  // Reset ability
  ability.update(initialAbility)

  // Redirect to login page
  router.push({ name: redirectPath })
}
if (store) {
  store.registerModule(OIDC_STORE_MODULE, oidcStoreModule)
}
