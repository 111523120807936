/**
 * Transaction Base
 */

import moment from 'moment'

export default class CodexLayoutTransaction {
  editor = null;

  datetime = null;

  constructor(editor) {
    this.editor = editor
    this.datetime = moment()
  }

  up() {

  }

  down() {

  }

  toJSON() {
    return this.name
  }
}
