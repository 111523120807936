<template>
  <div
    class="quick-add-list__item"
    :class="{
      'is-selected': isSelected,
      'quick-add-list__item--no-image': !image && !icon,
      'quick-add-list__item--with-icon': !image && icon
    }"
  >
    <div
      v-if="image || icon"
      class="quick-add-list__item-image"
    >
      <div class="quick-add-list__item-image-wrapper">
        <div class="quick-add-list__item-image-inner">
          <img
            v-if="image"
            :src="image"
          >
          <GjIcon
            v-else
            size="20"
            :name="icon"
          />
        </div>
      </div>
    </div>

    <div class="quick-add-list__item-content">
      <div
        :title="title"
        class="quick-add-list__item-name"
        :class="{'quick-add-list__item-name--untitled': !title}"
      >
        {{ title || 'Untitled' }}
      </div>
      <div class="quick-add-list__item-subtitle">
        {{ subtitle }}
      </div>
    </div>
  </div>
</template>

<script>
import { SEARCH_TYPES } from '@/utils/constants'

export default {
  name: 'QuickAddInlineItem',
  props: {
    item: {
      type: Object,
      required: true,
    },
    isSelected: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    title() {
      return this.item.title
    },
    subtitle() {
      if (this.item.type === SEARCH_TYPES.ASSET) {
        const split = this.item.entity.contentType.split('/')
        if (split.length > 1) {
          return split[1].toUpperCase()
        }
        return split[0].toUpperCase()
      }
      if (this.item.type === SEARCH_TYPES.ENTRY) {
        return this.item.entity.system.model.name
      }
      return null
    },
    icon() {
      if (this.item.type === SEARCH_TYPES.ENTRY) {
        return this.item.entity.system.model.iconId
      }

      return null
    },
    image() {
      if (this.item.type === SEARCH_TYPES.ASSET) {
        return this.item.entity.url
      }

      return null
    },
  },
}
</script>

<style>

</style>
