<template>
  <b-modal
    v-model="showModal"
    :title="$t('fields.codex-field-rich-text.link-modal.title')"
    centered
    :cancel-title="$t('general.cancel')"
    cancel-variant="outline-dark"
    :ok-title="$t('general.insert')"
    ok-variant="primary"
    no-close-on-backdrop
    @cancel="$emit('closeModal')"
    @close="$emit('closeModal')"
    @ok="insertLink"
  >
    <b-form-group
      :label="$t('fields.codex-field-rich-text.link-modal.text-label')"
      label-for="at-link-modal"
    >
      <b-form-input
        id="at-link-modal"
        v-model.trim="text"
        :state="error.text"
        trim
        autocomplete="off"
        @focus="error.text = null"
      />
      <b-form-invalid-feedback>
        {{ $t('fields.codex-field-rich-text.link-modal.error-message') }}
      </b-form-invalid-feedback>
    </b-form-group>
    <!--    <b-form-group :label="$t('fields.codex-field-rich-text.link-modal.type-label')">-->
    <!--      <b-form-select-->
    <!--        v-model="selectedType"-->
    <!--      >-->
    <!--        <b-form-select-option-->
    <!--          v-show="hyperlinks.url"-->
    <!--          value="1"-->
    <!--        >-->
    <!--          {{ $t('fields.codex-field-rich-text.link-modal.url-type') }}-->
    <!--        </b-form-select-option>-->
    <!--        <b-form-select-option-->
    <!--          v-show="hyperlinks.images"-->
    <!--          value="2"-->
    <!--        >-->
    <!--          {{ $t('fields.codex-field-rich-text.link-modal.image-type') }}-->
    <!--        </b-form-select-option>-->
    <!--        <b-form-select-option-->
    <!--          v-show="hyperlinks.files"-->
    <!--          value="3"-->
    <!--        >-->
    <!--          {{ $t('fields.codex-field-rich-text.link-modal.file-type') }}-->
    <!--        </b-form-select-option>-->
    <!--      </b-form-select>-->
    <!--    </b-form-group>-->

    <b-form-group :label="$t('fields.codex-field-rich-text.link-modal.target-label')">
      <div v-if="!inputValue">
        <b-form-input
          v-if="selectedType == 1"
          v-model.trim="link"
          :state="error.link"
          trim
          autocomplete="off"
          @focus="error.link = null"
        />
        <b-form-invalid-feedback>
          {{ $t('fields.codex-field-rich-text.link-modal.error-message') }}
        </b-form-invalid-feedback>
        <!--        <b-button-->
        <!--          v-if="selectedType == 2"-->
        <!--          variant="primary"-->
        <!--          @click="linkImageHandler"-->
        <!--        >-->
        <!--          {{ $t('fields.codex-field-rich-text.link-modal.image-button') }}-->
        <!--        </b-button>-->
        <!--        <b-button-->
        <!--          v-if="selectedType == 3"-->
        <!--          variant="primary"-->
        <!--          @click="linkFileHandler"-->
        <!--        >-->
        <!--          {{ $t('fields.codex-field-rich-text.link-modal.file-button') }}-->
        <!--        </b-button>-->
      </div>
      <div v-else>
        <div class="link-modal-target__card">
          <div class="link-modal-target__content">
            <div class="link-modal-target__image">
              <CodexImage
                v-if="getFeaturedMedia(inputValue)"
                :id="getFeaturedMedia(inputValue).id"
                v-slot="{url}"
                :size="$IMAGE_SIZES.THUMBNAIL_200"
              >
                <b-avatar
                  :src="url"
                  square
                  class="link-modal-target__avatar"
                  variant="light-secondary"
                />
              </CodexImage>
              <CodexImage
                v-else
                v-slot="{url}"
                :image="inputValue"
                :size="$IMAGE_SIZES.THUMBNAIL_200"
              >
                <b-avatar
                  text="F"
                  :src="url || null"
                  square
                  class="link-modal-target__avatar"
                  variant="light-secondary"
                />
              </CodexImage>

            </div>
            <div class="link-modal-target__text">
              <h6 class="link-modal-target__title">
                {{ inputValue.title || inputValue.fileName }}
              </h6>
              <p class="link-modal-target__subtitle">
                {{ inputValue.description }}
              </p>
            </div>
          </div>
          <div class="link-modal-target__actions">
            <b-button
              variant="flat"
              class="link-modal-target__delete-button"
              @click="inputValue = null"
            >
              <GjIcon
                name="Delete"
              />
            </b-button>
          </div>
        </div>
      </div>
      <small
        v-if="error.media && selectedType != 1 && inputValue == null"
        class="text-danger"
      >
        {{ $t('fields.codex-field-rich-text.link-modal.error-message') }}
      </small>
    </b-form-group>
  </b-modal>
</template>

<script>
import { ASSET_TYPES } from '@/codex-sdk/assets'
// import { fileUrlFromID, imageUrlFromID } from '@/components/fields/RichText/utils'

export default {
  name: 'LinkModal',
  inject: ['showAssetsPopup'],
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    linkText: {
      type: String,
      default: '',
    },
    hyperlinks: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      ASSET_TYPES,
      error: {
        text: null,
        link: null,
        media: null,
      },
      selectedType: this.type(),
      link: '',
      inputValue: null,
      text: this.linkText,
    }
  },
  computed: {
    showModal: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit('input', v)
      },
    },
  },
  watch: {
    selectedType() {
      this.link = ''
      if (this.inputValue != null) {
        this.inputValue = null
      }
    },
    linkText() {
      this.text = this.linkText
    },
  },
  methods: {
    type() {
      if (this.hyperlinks.url) return 1
      if (this.hyperlinks.images) return 2
      return 3
    },
    getFeaturedMedia(article) {
      if (article?.featuredMedia) {
        return article.featuredMedia
      }
      return null
    },
    // async linkImageHandler() {
    //   const medias = await this.showAssetsPopup({ limit: 1, types: [ASSET_TYPES.IMAGE] })
    //   if (medias?.length) {
    //     this.inputValue = medias[0]
    //     this.link = imageUrlFromID(this.inputValue.id)[0]
    //   }
    // },
    // async linkFileHandler() {
    //   const medias = await this.showAssetsPopup({ limit: 1, types: [ASSET_TYPES.FILE] })
    //   if (medias?.length) {
    //     this.inputValue = medias[0]
    //     this.link = fileUrlFromID(this.inputValue.id)[0]
    //   }
    // },
    insertLink(e) {
      if (this.text == '') {
        e.preventDefault()
        this.error.text = false
        return
      }
      if (this.selectedType != 1 && this.inputValue == null) {
        e.preventDefault()
        this.error.media = true
        return
      }
      if (this.selectedType == 1 && this.link == '') {
        e.preventDefault()
        this.error.link = false
        return
      }
      this.$emit('link', {
        text: this.text,
        link: this.link.indexOf('http://') == 0 || this.link.indexOf('https://') == 0 ? this.link : `https://${this.link}`,
      })
      this.error.text = null
      this.error.link = null
      this.error.media = null
      this.inputValue = null
      this.text = ''
      this.link = ''
    },
  },
}
</script>

<style lang="scss" scoped>
  .link-modal-target__card {
    padding: 0 8px;
    margin-bottom: 8px;
    display: flex;
    justify-content: space-between;
  }
  .link-modal-target__content {
    display: flex;
    max-width: 70%;
  }
  .link-modal-target__image {
    width: 60px;
    min-width: 60px;
    height: 60px;
    min-height: 60px;
    margin-right: 12px;
  }
  .link-modal-target__text {
    display: flex;
    flex-direction: column;
    margin-right: 16px;
    justify-content: center;
    max-width: 100%;
  }
  .link-modal-target__title {
    margin: 0;
    padding: 0;
    color: #031c3c;
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 6px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .link-modal-target__subtitle {
    margin: 0;
    padding: 0;
    color: #031c3c;
    font-size: 14px;
    line-height: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .link-modal-target__actions {
    display: flex;
    align-items: center;
  }

  .link-modal-target__avatar {
    width: 60px;
    height: 60px;
    border-radius: 4px;
    font-size: 20px;
  }
</style>
