<script>
import { readAsset } from '@/codex-sdk/assets'
import { $IMAGE_SIZES, suffixMapping } from '@/utils/constants'

export default {
  props: ['image', 'id', 'alt', 'size'],
  data() {
    const { id, alt, urls } = this.setData()

    // const url = this.urlFromID('ast_f9493638adaa4085b89386-p')
    // const url = this.urlFromID('ast_f9493638adaa4085b89386')
    // const url = this.urlFromID('asf9493638adaa4085b89386-p')
    // const url = this.urlFromID('gjirafa-asf9493638adaa4085b89386-p')

    return {
      urls,
      loading: true,
      onUrl: 0,
      idAttr: id,
      altAttr: alt,
      urlsTested: [],
    }
  },
  watch: {
    image() {
      const { id, alt, urls } = this.setData()
      if (id === this.idAttr) return

      this.idAttr = id
      this.altAttr = alt
      this.urls = urls
      this.onUrl = 0
      this.urlsTested = []
      if (this.$scopedSlots.default) {
        this.loadImage()
      }
    },
    id() {
      const { id, alt, urls } = this.setData()
      if (id === this.idAttr) return

      this.idAttr = id
      this.altAttr = alt
      this.urls = urls
      this.onUrl = 0
      this.urlsTested = []
      if (this.$scopedSlots.default) {
        this.loadImage()
      }
    },
  },
  mounted() {
    if (this.$scopedSlots.default) {
      this.loadImage()
    }
  },
  methods: {
    imageLoaded() {
      this.$emit('loaded', this.urls[this.onUrl])
    },
    loadImage() {
      const url = this.urls[this.onUrl]
      this.testImageUrl(url).then(this.imageLoaded).catch(this.imageNotFound)
    },
    setData() {
      let id = null
      let alt = null
      if (this.image) {
        if (this.image.id) {
          id = this.image.id
        } else if (this.image.path) {
          id = this.image.path
        } else if (this.image.asset) {
          id = this.image.asset
        } else if (this.image.src) {
          id = this.image.src
        } else if (this.image.imageUrl) {
          id = this.image.imageUrl
        } else if (this.image.image) {
          id = this.image.image
        }

        if (this.image.altAttribute) {
          alt = this.image.altAttribute
        } else if (this.image.alt) {
          alt = this.alt.image
        }
      } else {
        id = this.id
        alt = this.alt
      }

      return { id, alt, urls: this.urlFromID(id) || [false] }
    },

    urlFromID(id) {
      if (!id) return null
      let parts; let fileName; let suffix; let tenant; let type

      if (id.indexOf('/') !== -1 && id.indexOf('http') !== -1) {
        return [
          id,
          false,
        ]
      }

      if (id.indexOf('/') !== -1) {
        return [
          `https://noah.gjirafa.com${id.includes('/codex') ? id : `/codex${id}`}`,
          false,
        ]
      }

      if (id.startsWith('ast_')) {
        parts = id.replace('ast_', '').split('-')
        if (parts.length == 1) {
          // 1) ast_f9493638adaa4085b89386
          type = 1
          fileName = parts[0]
        } else {
          // 2) ast_f9493638adaa4085b89386-p
          type = 2
          fileName = parts[0]
          suffix = suffixMapping[parts[1]] || parts[1]
        }
      } else {
        parts = id.split('-')
        if (parts.length == 1) {
          // 1) asf9493638adaa4085b89386
          type = 1
          fileName = parts[0].slice(2)
        } else if (parts.length == 2) {
          // 3) asf9493638adaa4085b89386-p
          type = 3
          fileName = parts[0].slice(2)
          suffix = suffixMapping[parts[1]] || parts[1]
        } else {
          // 4) gjirafa-f9493638adaa4085b89386-p  /  gjirafa-stg-f9493638adaa4085b89386-p
          // No as prefix
          type = 4
          tenant = parts.slice(0, parts.length - 2).join('-')
          fileName = parts[parts.length - 2]
          suffix = suffixMapping[parts[parts.length - 1]] || parts[parts.length - 1]
        }
      }

      /**
       * Parts:
       *  tenant:   gjirafa
       *  fileName: f9493638adaa4085b89386
       *  suffix:   png
       */
      const useSize = this.size || $IMAGE_SIZES.REGULAR_1080
      const imageURLs = [
        // CDN
        useSize <= $IMAGE_SIZES.THUMBNAIL_200 ? `https://${tenant}.codexcdn.net/images/${fileName}-regular.${suffix}?w=200&q=60` : '',
        useSize <= $IMAGE_SIZES.SMALL_400 ? `https://${tenant}.codexcdn.net/images/${fileName}-regular.${suffix}?w=400&q=60` : '',
        useSize <= $IMAGE_SIZES.REGULAR_1080 ? `https://${tenant}.codexcdn.net/images/${fileName}-regular.${suffix}` : '',
        useSize <= $IMAGE_SIZES.ORIGINAL ? `https://${tenant}.codexcdn.net/images/${fileName}.${suffix}` : '',

        // AMAZON
        useSize <= $IMAGE_SIZES.THUMBNAIL_200 ? `https://aws.codexcdn.net/${tenant}/images/${fileName}-regular.${suffix}?w=200&q=60` : '',
        useSize <= $IMAGE_SIZES.SMALL_400 ? `https://aws.codexcdn.net/${tenant}/images/${fileName}-regular.${suffix}?w=400&q=60` : '',
        useSize <= $IMAGE_SIZES.REGULAR_1080 ? `https://aws.codexcdn.net/${tenant}/images/${fileName}-regular.${suffix}` : '',
        useSize <= $IMAGE_SIZES.ORIGINAL ? `https://aws.codexcdn.net/${tenant}/images/${fileName}.${suffix}` : '',

        // NOAH
        useSize <= $IMAGE_SIZES.THUMBNAIL_200 ? `https://noah.gjirafa.com/codex/images/${fileName}-regular.${suffix}?w=200&q=60` : '',
        useSize <= $IMAGE_SIZES.SMALL_400 ? `https://noah.gjirafa.com/codex/images/${fileName}-regular.${suffix}?w=400&q=60` : '',
        useSize <= $IMAGE_SIZES.REGULAR_1080 ? `https://noah.gjirafa.com/codex/images/${fileName}-regular.${suffix}` : '',
        useSize <= $IMAGE_SIZES.ORIGINAL ? `https://noah.gjirafa.com/codex/images/${fileName}.${suffix}` : '',
        false,
      ].filter(url => url !== '')

      switch (type) {
        // 1) ast_f9493638adaa4085b89386
        // 1) asf9493638adaa4085b89386
        case 1:
          readAsset(id).then(({ data }) => {
            this.urls = [
              `https://noah.gjirafa.com${data.path}`,
            ]
          })

          return null

        // 2) ast_f9493638adaa4085b89386-p
        case 2:
          return imageURLs

        // 3) asf9493638adaa4085b89386-p
        case 3:
          return imageURLs
        // 4) gjirafa-f9493638adaa4085b89386-p  /  gjirafa-stg-f9493638adaa4085b89386-p
        case 4:
          return imageURLs
        default: return [false]
      }
    },

    imageNotFound() {
      if (this.onUrl < this.urls.length - 1) {
        this.onUrl++
        if (this.$scopedSlots.default) {
          this.loadImage()
        }
      }
    },

    testImageUrl(url) {
      if (this.urlsTested.indexOf(url) !== -1) return ''
      this.urlsTested.push(url)

      return new Promise(((resolve, reject) => {
        const image = new Image()
        image.addEventListener('load', resolve)
        image.addEventListener('error', reject)
        image.src = url
      }))
    },
  },
  render(h) {
    const url = this.urls[this.onUrl]

    if (this.$scopedSlots.default) {
      return this.$scopedSlots.default({ url, loading: this.loading, alt: this.altAttr })
    }

    return h('img', {
      attrs: {
        src: url,
        alt: this.altAttr,
      },
      on: {
        error: this.imageNotFound,
        load: this.imageLoaded,
      },
    })
  },
}

</script>

<style>

</style>
