<template>
  <b-sidebar
    id="at-presets-popup"
    v-model="showPopup"
    class="c-sidebar c-sidebar--md"
    :title="preset.id ? $t('presets.presetPopup.edit-title') : $t('presets.presetPopup.new-title')"
    :no-close-on-route-change="true"
    right
    shadow
    backdrop
    @ok="proceed"
    @cancel="cancel"
  >
    <div class="entry-view-popup__info">
      <span class="entry-view-popup__title">{{ preset.id ? $t('presets.presetPopup.edit-subtitle'): $t('presets.presetPopup.new-subtitle') }} </span>
      <span class="entry-view-popup__subtitle"> {{ $t('presets.presetPopup.description') }} </span>
    </div>
    <b-form-group
      :label="$t('presets.presetPopup.name-label')"
      label-for="at-preset-name-input"
      class="mt-1"
    >
      <b-form-input
        id="at-preset-name-input"
        v-model.trim="preset.name"
        :placeholder="$t('presets.presetPopup.name-placeholder')"
        trim
        autocomplete="off"
      />
    </b-form-group>
    <b-form-group
      :label="$t('presets.presetPopup.alias-label')"
      label-for="at-preset-alias-input"
      class="mt-1"
    >
      <b-form-input
        v-if="preset.id"
        :value="preset.alias"
        disabled
      />
      <b-form-input
        v-else
        id="at-preset-alias-input"
        v-model.trim="preset.alias"
        :placeholder="$t('presets.presetPopup.alias-placeholder')"
        trim
        :state="aliasError"
        autocomplete="off"
        @focus="aliasError = null"
      />
      <b-form-invalid-feedback>
        {{ $t('presets.presetPopup.alias-error') }}
      </b-form-invalid-feedback>
    </b-form-group>
    <div class="toolbar-resize d-flex toolbar-form__margin-top">
      <b-form-group
        class="toolbar-input__group"
        :label="$t('presets.presetPopup.width-label')"
        label-for="toolbar-resize__input-width"
      >
        <div
          class="input-wrapper"
          :class="{ 'has-error': widthError != null }"
        >
          <b-form-input
            id="toolbar-resize__input-width"
            v-model.number="preset.width"
            type="number"
            class="toolbar-input__resize"
            :placeholder="$t('presets.presetPopup.width-placeholder')"
            :state="widthError"
            :min="0"
            @keydown="validateNumber($event, preset.width)"
            @focus="widthError = null"
          />
          <b-form-invalid-feedback>
            {{ $t('presets.presetPopup.width-error') }}
          </b-form-invalid-feedback>
          <span class="input-suffix">{{ $t('presets.presetPopup.px') }}</span>
        </div>
      </b-form-group>
      <b-form-group
        class="toolbar-input__group"
        :label="$t('presets.presetPopup.height-label')"
        label-for="toolbar-resize__input-height"
      >
        <div
          class="input-wrapper"
          :class="{ 'has-error': heightError != null }"
        >
          <b-form-input
            id="toolbar-resize__input-height"
            v-model.number="preset.height"
            type="number"
            class="toolbar-input__resize"
            :placeholder="$t('presets.presetPopup.height-placeholder')"
            :state="heightError"
            :min="0"
            @keydown="validateNumber($event, preset.height)"
            @focus="heightError = null"
          />
          <b-form-invalid-feedback>
            {{ $t('presets.presetPopup.height-error') }}
          </b-form-invalid-feedback>
          <span class="input-suffix">{{ $t('presets.presetPopup.px') }}</span>
        </div>
      </b-form-group>
    </div>
    <b-form-group
      :label="$t('presets.presetPopup.resize-label')"
      label-for="at-preset-resize-select"
      class="mt-1"
    >
      <v-select
        id="at-preset-resize-select"
        v-model="preset.resize"
        :options="resizeOptions"
        :reduce="o => o.value"
        :clearable="false"
        :placeholder="$t('presets.presetPopup.resize-placeholder')"
      />
    </b-form-group>
    <b-form-group
      :label="$t('presets.presetPopup.gravity-label')"
      label-for="at-preset-gravity-select"
      class="mt-1"
    >
      <v-select
        id="at-preset-gravity-select"
        v-model="preset.gravity"
        :options="gravityOptions"
        :reduce="o => o.value"
        :clearable="false"
        :placeholder="$t('presets.presetPopup.gravity-placeholder')"
      />
    </b-form-group>
    <b-form-group
      :label="$t('presets.presetPopup.sites-label')"
      label-for="at-preset-sites-select"
      class="mt-1"
    >
      <SitesDropdownSelect
        id="at-preset-sites-select"
        v-model="preset.sites"
        :clearable="true"
        :placeholder="$t('presets.presetPopup.sites-placeholder')"
      />
    </b-form-group>
    <template #footer>
      <b-button
        variant="outline-secondary"
        @click="cancel"
      >
        {{ $t('presets.presetPopup.cancel-button') }}
      </b-button>
      <b-button
        v-if="preset.id"
        :disabled="saving"
        variant="outline-primary"
        @click="proceed($event, true)"
      >
        {{ $t('presets.presetPopup.save-invalidate-button') }}
      </b-button>
      <b-button
        :disabled="saving"
        variant="primary"
        @click="proceed($event)"
      >
        {{ $t('presets.presetPopup.save-button') }}
      </b-button>
    </template>
  </b-sidebar>
</template>

<script>
import {
  createPresets, updatePreset, readPreset, presetsDefault, RESIZE, GRAVITY, mapPresetResize, mapPresetGravity,
} from '@/codex-sdk/presets'
import TeamsSelector from '@/components/TeamsSelector.vue'
import SitesDropdownSelect from '@/components/SitesDropdownSelect.vue'
import { toSlugV2 } from '@/utils/helpers'
import { validateNumber } from '@/views/models/constants'
import PopupsMixin from '../PopupsMixin'

export default {
  name: 'PresetsPopup',
  components: {
    SitesDropdownSelect,
  },
  mixins: [PopupsMixin],
  data() {
    return {
      validateNumber,
      saving: false,
      TeamsSelector,
      aliasError: null,
      heightError: null,
      widthError: null,
      preset: presetsDefault(),
    }
  },
  computed: {
    resizeOptions() {
      return [
        { label: this.$t(mapPresetResize(RESIZE.FIT)), value: RESIZE.FIT },
        { label: this.$t(mapPresetResize(RESIZE.FILL)), value: RESIZE.FILL },
        { label: this.$t(mapPresetResize(RESIZE.AUTO)), value: RESIZE.AUTO },
      ]
    },
    gravityOptions() {
      return [
        { label: this.$t(mapPresetGravity(GRAVITY.NORTH)), value: GRAVITY.NORTH },
        { label: this.$t(mapPresetGravity(GRAVITY.SOUTH)), value: GRAVITY.SOUTH },
        { label: this.$t(mapPresetGravity(GRAVITY.EAST)), value: GRAVITY.EAST },
        { label: this.$t(mapPresetGravity(GRAVITY.WEST)), value: GRAVITY.WEST },
        { label: this.$t(mapPresetGravity(GRAVITY.CENTER)), value: GRAVITY.CENTER },
        { label: this.$t(mapPresetGravity(GRAVITY.NORTHEAST)), value: GRAVITY.NORTHEAST },
        { label: this.$t(mapPresetGravity(GRAVITY.NORTHWEST)), value: GRAVITY.NORTHWEST },
        { label: this.$t(mapPresetGravity(GRAVITY.SOUTHEAST)), value: GRAVITY.SOUTHEAST },
        { label: this.$t(mapPresetGravity(GRAVITY.SOUTHWEST)), value: GRAVITY.SOUTHWEST },
      ]
    },
  },
  watch: {
    'preset.name': function (n, o) {
      if (!this.preset.id && (!this.preset.alias || this.preset.alias === toSlugV2(o, '-'))) {
        this.preset.alias = toSlugV2(n, '-')
      }
    },
  },
  mounted() {
    this.getPreset()
  },
  methods: {
    async getPreset() {
      if (!this.data?.id) return
      const { data } = await readPreset(this.data.id)
      this.preset = data
    },
    validatePreset() {
      const regex = /^[a-z0-9]+(-?[a-z0-9]+)*$/
      if (!this.preset.id && (this.preset.alias === '' || !regex.test(this.preset.alias))) {
        this.aliasError = false
      }
      if (this.preset.height <= 0) {
        this.heightError = false
      }
      if (this.preset.width <= 0) {
        this.widthError = false
      }
      return this.widthError === false || this.heightError === false || this.aliasError === false
    },
    async proceed(e, invalidateCache = false) {
      if (this.saving || this.validatePreset()) {
        e.preventDefault()
        return
      }
      try {
        this.saving = true
        if (this.preset.id) {
          const { data } = await updatePreset(this.preset, invalidateCache)
          this.closePopup(data)
        } else {
          const { data } = await createPresets(this.preset)
          this.closePopup(data)
        }
      } catch (error) {
        console.log(error)
      } finally {
        this.saving = false
      }
    },
    cancel() {
      this.closePopup(false)
    },
  },
}
</script>
<style lang="scss">
  #at-presets-popup {
    .input-wrapper {
      width: 100%;
      position: relative;
    }
    .input-suffix {
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      color: #A3B0C2;
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translateY(-50%);
    }
    .has-error .input-suffix {
      top: 32%;
    }
    .btn-outline-secondary {
      border: none !important;
      color: #052D61 !important;
    }
    .entry-view-popup__info {
      display: flex;
      flex-direction: column;
      gap: 10px;
      .entry-view-popup__title {
        font-weight: 500;
        font-size: 18px;
        line-height: 18px;
        color: #052D61;
      }
      .entry-view-popup__subtitle {
        font-weight: 400;
        font-size: 14px;
        line-height: 150%;
        color: #052D61;
      }
    }
  }
</style>
