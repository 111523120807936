<template>
  <div>
    <FieldConditions
      v-model="validation.readOnly"
      :widget="widget"
      :label="$t('fields.general.read-only.label')"
      :show-error-message="false"
    />
  </div>
</template>

<script>
import { generateComputedPropsFromAttrs } from '@/components/codex-layout-editor/BuilderUtils'
import FieldConditions from '@/components/fields/FieldConditions.vue'
import BaseFieldMixin from '@/components/fields/BaseFieldMixin'

export default {
  name: 'Validation',
  components: { FieldConditions },
  mixins: [BaseFieldMixin],
  computed: {
    ...generateComputedPropsFromAttrs([
      'validation',
    ]),
  },
}
</script>
