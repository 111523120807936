import i18n from '@/libs/i18n'
import { mapPresetGravity, GRAVITY_STRING } from '@/codex-sdk/presets'
import PresetGravity from './PresetGravity.vue'

export default class PresetGravityFilter {
  static COMPONENT = PresetGravity;

  static type = 'presetGravity'

  static OPERATORS = {
    include: 'include',
    exclude: 'exclude',
  }

  static OPTIONS = {
    [GRAVITY_STRING.NORTH]: i18n.t(mapPresetGravity(GRAVITY_STRING.NORTH)),
    [GRAVITY_STRING.SOUTH]: i18n.t(mapPresetGravity(GRAVITY_STRING.SOUTH)),
    [GRAVITY_STRING.EAST]: i18n.t(mapPresetGravity(GRAVITY_STRING.EAST)),
    [GRAVITY_STRING.WEST]: i18n.t(mapPresetGravity(GRAVITY_STRING.WEST)),
    [GRAVITY_STRING.CENTER]: i18n.t(mapPresetGravity(GRAVITY_STRING.CENTER)),
    [GRAVITY_STRING.NORTHEAST]: i18n.t(mapPresetGravity(GRAVITY_STRING.NORTHEAST)),
    [GRAVITY_STRING.NORTHWEST]: i18n.t(mapPresetGravity(GRAVITY_STRING.NORTHWEST)),
    [GRAVITY_STRING.SOUTHEAST]: i18n.t(mapPresetGravity(GRAVITY_STRING.SOUTHEAST)),
    [GRAVITY_STRING.SOUTHWEST]: i18n.t(mapPresetGravity(GRAVITY_STRING.SOUTHWEST)),
  }

  operator = null;

  value = null;

  constructor({ operator = PresetGravityFilter.OPERATORS.include, value = [] } = {}) {
    this.operator = operator
    this.value = value
  }

  set({ operator = PresetGravityFilter.OPERATORS.include, value = [] }) {
    this.operator = operator
    this.value = value
  }

  getCount() {
    return this.value.length
  }

  getValueLabel() {
    return this.value.map(id => PresetGravityFilter.OPTIONS[id]).join(', ')
  }

  asQueryParam() {
    return {
      operator: this.operator,
      value: this.value,
    }
  }

  asGraphQL() {
    switch (this.operator) {
      case PresetGravityFilter.OPERATORS.include:
        return {
          in: this.value,
        }
      case PresetGravityFilter.OPERATORS.exclude:
        return {
          notIn: this.value,
        }
      default:
        return null
    }
  }

  static validate({ operator, value }) {
    if (!operator) {
      return 'Operator is required'
    }

    if (!value || value.length === 0) {
      return 'Value is required'
    }

    return true
  }

  static fromQueryParam({ operator, value }) {
    return new PresetGravityFilter({
      operator,
      value,
    })
  }
}
