<template>
  <div class="seo-analyzer">
    <div class="seo-analyzed-content">
      <div class="content-score d-flex flex-column justify-content-end">
        <h2 style="margin-bottom: 0; display: flex; align-items: center; gap: 5px;">
          {{ $t('entries.ai-content-analyzer.seo.seo-score') }}
          <GjIcon
            v-b-tooltip.hover="$t('entries.ai-content-analyzer.seo.content-score-description')"
            name="Info"
            size="20"
          />
        </h2>
        <div class="score-circle d-flex flex-column justify-content-end align-items-center position-relative bg-white">
          <span class="h4 mb-0">{{ contentScore }}</span>
          <div
            class="score-circle-overlay"
            :style="circleStyle"
          >
            <svg
              width="163"
              height="162"
              viewBox="0 0 163 162"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                opacity="0.2"
                d="M162.204 80.7034C162.204 70.1053 160.116 59.6109 156.06 49.8195C152.005 40.0282 146.06 31.1315 138.566 23.6375C131.072 16.1435 122.175 10.1989 112.384 6.14318C102.593 2.08745 92.0984 -4.63259e-07 81.5003 0C70.9021 4.63259e-07 60.4078 2.08746 50.6164 6.14318C40.825 10.1989 31.9284 16.1435 24.4343 23.6375C16.9403 31.1315 10.9958 40.0282 6.94005 49.8195C2.88433 59.6109 0.796874 70.1053 0.796875 80.7034H16.9376C16.9376 72.2249 18.6075 63.8294 21.8521 55.9963C25.0967 48.1632 29.8523 41.0459 35.8475 35.0507C41.8427 29.0555 48.9601 24.2998 56.7932 21.0552C64.6263 17.8106 73.0218 16.1407 81.5003 16.1407C89.9788 16.1407 98.3742 17.8106 106.207 21.0552C114.04 24.2998 121.158 29.0555 127.153 35.0507C133.148 41.0459 137.904 48.1632 141.148 55.9963C144.393 63.8294 146.063 72.2249 146.063 80.7034H162.204Z"
                fill="#98BCEC"
              />
              <path
                d="M0.796875 80.7034C0.796873 102.107 9.29952 122.634 24.4343 137.769C39.5692 152.904 60.0964 161.407 81.5003 161.407C102.904 161.407 123.431 152.904 138.566 137.769C153.701 122.635 162.204 102.107 162.204 80.7034L146.063 80.7034C146.063 97.8265 139.261 114.248 127.153 126.356C115.045 138.464 98.6234 145.266 81.5003 145.266C64.3772 145.266 47.9554 138.464 35.8475 126.356C23.7397 114.248 16.9376 97.8265 16.9376 80.7034L0.796875 80.7034Z"
                fill="#98BCEC"
              />
              <path
                d="M0.796875 80.7034C0.796873 102.107 9.29952 122.634 24.4343 137.769C39.5692 152.904 60.0964 161.407 81.5003 161.407C102.904 161.407 123.431 152.904 138.566 137.769C153.701 122.635 162.204 102.107 162.204 80.7034L146.063 80.7034C146.063 97.8265 139.261 114.248 127.153 126.356C115.045 138.464 98.6234 145.266 81.5003 145.266C64.3772 145.266 47.9554 138.464 35.8475 126.356C23.7397 114.248 16.9376 97.8265 16.9376 80.7034L0.796875 80.7034Z"
                fill="url(#paint0_linear_242_6160)"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_242_6160"
                  x1="-110.17"
                  y1="-136.187"
                  x2="140.429"
                  y2="-126.604"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#206ED5" />
                  <stop
                    offset="1"
                    stop-color="#9747FF"
                  />
                </linearGradient>
              </defs>
            </svg>
          </div>
        </div>
      </div>
      <div class="scores">
        <div class="score">
          <span class="score-title">
            {{ $t('entries.ai-content-analyzer.seo.readability-score') }}
          </span>
          <div class="progress-bar-seo">
            <div class="progress">
              <div
                class="progress-bar-overlay"
                role="progressbar"
                :style="{ width: seo.readability ? `${seo.readability}%` : '0%' }"
                :aria-valuenow="seo.readability"
                :aria-valuemin="0"
                :aria-valuemax="100"
              />
            </div>
          </div>
          <span class="percentage">{{ readabilityScore }}</span>
        </div>
        <div class="score">
          <span class="score-title">
            {{ $t('entries.ai-content-analyzer.seo.keyword-density') }}

          </span>
          <div class="progress-bar-seo">
            <div class="progress">
              <div
                class="progress-bar-overlay"
                role="progressbar"
                :style="{ width: seo.keywordDensity ? `${seo.keywordDensity}%` : '0%' }"
                :aria-valuenow="seo.keywordDensity"
                :aria-valuemin="0"
                :aria-valuemax="100"
              />
            </div>
          </div>
          <span class="percentage">{{ keywordDensity }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { isNil } from '../image-editor/js/util'

export default {

  name: 'SeoScore',
  components: {
    // AiIcon,
  },
  props: {
    seo: {
      type: Object,
      default: () => ({
        contentScore: null,
        readability: null,
        keywordDensity: null,
        seoFeedback: [],
      }),
    },
  },
  data() {
    return {

    }
  },
  computed: {
    circleStyle() {
      const degrees = (this.seo.contentScore / 100) * 180
      return {
        transform: `rotate(${degrees}deg)`,
      }
    },
    readabilityScore() {
      return !isNil(this.seo.readability) ? `${this.seo.readability}%` : ''
    },
    keywordDensity() {
      return !isNil(this.seo.keywordDensity) ? `${this.seo.keywordDensity}%` : ''
    },
    contentScore() {
      return !isNil(this.seo.contentScore) ? `${this.seo.contentScore}%` : ''
    },
    seoFeedback() {
      return Array.isArray(this.seo.seoFeedback) ? this.seo.seoFeedback : []
    },

  },
}
</script>

<style lang="scss">

</style>
