<template>
  <div>
    <b-form-group>
      <b-form-checkbox v-model="configuration.searchable">
        {{ $t('fields.codex-field-rich-text.configuration.searchable') }}
        <GjIcon
          v-b-tooltip.hover="$t('fields.codex-field-rich-text.configuration.searchable-tooltip')"
          name="Info"
          size="20"
        />
      </b-form-checkbox>
    </b-form-group>
  </div>
</template>

<script>
import { generateComputedPropsFromAttrs } from '@/components/codex-layout-editor/BuilderUtils'
import BaseFieldMixin from '@/components/fields/BaseFieldMixin'

export default {
  mixins: [BaseFieldMixin],
  computed: {
    ...generateComputedPropsFromAttrs([
      'configuration',
    ]),
  },
}
</script>
