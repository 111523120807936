<template>
  <div class="new-filters-dropdown__filter-body">

    <b-row>
      <b-col cols="12">
        <vSelect
          v-model="value.operator"
          :options="options"
          :clearable="false"
          :reduce="option => option.value"
          :searchable="false"
          label="label"
        />
      </b-col>
    </b-row>

    <b-row
      v-if="!isExistsOperator"
      align-v="center"
      no-gutters
    >
      <b-col class="flex-grow">
        <b-input
          v-model="value.value"
          size="sm"
        />
      </b-col>
    </b-row>

  </div>
</template>

<script>
import StringListFilter from './StringListFilter'

export default {
  props: {
    filter: {
      type: Object,
      required: true,
    },
    filterObject: {
      type: [Object, Function],
      required: true,
    },
    value: {
      type: Object,
      required: true,
    },
  },
  computed: {
    options() {
      return [
        {
          label: this.$t('filters.all'),
          value: StringListFilter.OPERATORS.all,
        },
        {
          label: this.$t('filters.some'),
          value: StringListFilter.OPERATORS.some,
        },
        {
          label: this.$t('filters.is-not-empty'),
          value: StringListFilter.OPERATORS.exists,
        },
        {
          label: this.$t('filters.is-empty'),
          value: StringListFilter.OPERATORS.notExists,
        },
      ]
    },
    isExistsOperator() {
      return this.value.operator === StringListFilter.OPERATORS.exists || this.value.operator === StringListFilter.OPERATORS.notExists
    },
  },
}
</script>

<style>

</style>
