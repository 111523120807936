<template>
  <div
    class="twitter-embed-block-wrapper"
    :style="`text-align: ${alignClass}`"
  >
    <div
      class="twitter-embed-block"
    >
      <TwitterEmbedQuote
        :key="src"
        ref="twitterEmbed"
        :src="src"
      />
      <b-form-input
        v-model="caption"
        class="twitter-embed-block__caption"
        :placeholder="$t('codex-editor.nodes.twitter-embed.caption')"
        rows="1"
        max-rows="10"
        autocomplete="off"
      />
    </div>
    <div
      v-if="!selected"
      class="widget-select-overlay"
      @click="selectNode"
    />
  </div>
</template>

<script>
import TwitterEmbedQuote from './TwitterEmbedQuote.vue'

export default {
  components: {
    TwitterEmbedQuote,
  },
  props: ['attrs', 'updateAttrs', 'selectNode', 'selected'],
  inject: ['deleteBlock', 'showPromptPopup', 'toastNotification'],
  data() {
    return {
      initialSrc: '',
    }
  },
  computed: {
    alignClass() {
      return `${this.align}`
    },
    src() {
      return this.attrs.src
    },
    align: {
      get() {
        return this.attrs.align
      },
      set(e) {
        this.updateAttrs({ align: e })
      },
    },
    caption: {
      get() {
        return this.attrs.caption
      },
      set(e) {
        this.updateAttrs({ caption: e })
      },
    },
  },
  watch: {
    src() {
      this.$nextTick(() => {
        this.initialSrc = this.attrs.src
      })
    },
  },
  methods: {
    initialize() {
      if (!this.attrs.src) {
        this.openModal()
      }
    },
    async openModal() {
      const url = await this.showPromptPopup({ title: this.$t('codex-editor.nodes.twitter-embed.twitter-url') })
      if (!url) {
        this.deleteBlock(this.attrs.blockId)
      } else if (url.length && url.match((/^(https?:\/\/)?((w{3}\.)?)(twitter|x).com\/.*/i))) {
        this.updateAttrs({
          src: url,
        })
      } else {
        this.toastNotification({
          icon: 'XIcon',
          variant: 'danger',
          title: 'Error',
          text: this.$t('codex-editor.nodes.twitter-embed.twitter-invalid-url'),
        })
        this.deleteBlock(this.attrs.blockId)
      }
    },
    setSrc() {
      this.updateAttrs({
        src: this.initialSrc,
      })
    },
  },
}
</script>

<style scoped lang="scss">

.twitter-embed-block-wrapper {
  position: relative;
}

.widget-select-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0,0,0,0);
}

.twitter-embed-holder {
    position: relative;
  }
.twitter-embed-block {
  margin-top: 15px;
  width: 50%;
  position: relative;
  display: inline-block;
}

.twitter-embed-block__caption {
    width: 100%;
    padding: 5px 5px !important;
    font-size: 14px;
    color: gray;
    border: none;
    overflow: hidden !important;
  }

.sourceField {
  width: 50%;
  white-space: nowrap;
}
</style>
