<template>
  <b-form-group
    aria-required="true"
    class="boolean-field"
  >
    <template #label>
      <FieldName
        :name="name"
        :help-text="helpText"
        :help-text-display="helpTextDisplay"
        :required="required && appearance === BOOLEAN_FIELD_APPEARANCES.RADIO"
      />
    </template>
    <div class="boolean-field__inputs">
      <b-form-radio-group
        v-if="appearance === BOOLEAN_FIELD_APPEARANCES.RADIO"
        v-model="computedValue"
        class="appearance-type"
        :disabled="readOnly || !editable"
      >
        <b-form-radio
          :value="true"
          class="text-wrap__inputs"
        >
          {{ trueCustomLabel }}
        </b-form-radio>
        <b-form-radio
          :value="false"
          class="text-wrap__inputs"
        >
          {{ falseCustomLabel }}
        </b-form-radio>
      </b-form-radio-group>

      <b-form-checkbox
        v-if="appearance === BOOLEAN_FIELD_APPEARANCES.CHECKBOX"
        v-model="computedValue"
        class="appearance-type text-wrap__inputs"
        :disabled="readOnly || !editable"
      >
        {{ checkboxLabel }}
      </b-form-checkbox>

      <b-form-checkbox
        v-if="appearance === BOOLEAN_FIELD_APPEARANCES.SWITCH"
        v-model="computedValue"
        class="appearance-type text-wrap__inputs"
        switch
        :disabled="readOnly || !editable"
      >
        {{ checkboxLabel }}
      </b-form-checkbox>

      <b-button
        v-if="appearance === BOOLEAN_FIELD_APPEARANCES.RADIO && !required"
        class="hide-in-quick-view"
        variant="outline"
        :disabled="readOnly || !editable"
        @click="computedValue = null"
      >
        {{ $t('general.clear') }}
      </b-button>
    </div>
    <FieldError :error="error" />
  </b-form-group>
</template>

<script>

import { generateComputedPropsFromAttrs } from '@/components/codex-layout-editor/BuilderUtils'
import FieldName from '@/components/fields/FieldName.vue'
import BooleanFilter from '@/components/filters-dropdown/filters/boolean/BooleanFilter'
import FieldRenderMixin from '@/components/fields/RenderFieldMixin'
import BaseFieldMixin from '@/components/fields/BaseFieldMixin'
import { BOOLEAN_FIELD_APPEARANCES, FIELD_FILTER_OPERATORS } from '@/views/models/constants'
import FieldError from '@/components/fields/FieldError.vue'

export default {
  inject: ['filterValues', 'entryId'],
  components: {
    FieldError,
    FieldName,
  },
  mixins: [BaseFieldMixin, FieldRenderMixin],
  props: {
    value: {
      type: [Boolean],
      default: null,
    },
  },
  data() {
    return {
      BOOLEAN_FIELD_APPEARANCES,
    }
  },
  computed: {
    ...generateComputedPropsFromAttrs([
      'name',
      'alias',
      'validation',
      'appearance',
      'trueCustomLabel',
      'falseCustomLabel',
      'checkboxLabel',
      'defaultValue',
      'helpText',
      'helpTextDisplay',
    ]),
    computedValue: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit('input', v)
      },
    },
  },
  beforeMount() {
    if (!this.widget.attrs.hidden) {
      this.$set(this.widget.attrs, 'hidden', {
        value: false,
        conditionsEnabled: false,
        conditions: [
          {
            isSystem: false,
            field: '',
            operator: FIELD_FILTER_OPERATORS.EXISTS,
            value: '',
          },
        ],
      })
    }
  },
  mounted() {
    if (this.entryId() === 'create' && !this.readOnly && this.editable) {
      // Prefill from filter
      if (this.filterValues && this.filterValues?.[this.alias]) {
        this.computedValue = this.defaultValue
        if (BooleanFilter.shouldApplyPredefinedValue(this.filterValues[this.alias])) {
          const filterValue = this.filterValues[this.alias].value
          if (filterValue !== undefined) {
            this.computedValue = filterValue
          }
        }
      }
      // \Prefill from filter
    }
  },
  methods: {
    validate(value) {
      if (!!this.required && (typeof value) !== 'boolean') {
        return { isValid: false, message: this.validation.required.errorMessage }
      }
      return { isValid: true, message: '' }
    },
  },
}

</script>

<style lang="scss">
.boolean-field {
  .appearance-type .custom-control-label {
    margin: 0;
  }

  .appearance-type{
    display:flex;
    align-items: center;
    justify-content: space-between;
  }

  .boolean-field__inputs {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    > div {
      margin-right: auto;
    }
  }
  .text-wrap__inputs {
    word-break: break-all;
  }
}
</style>
