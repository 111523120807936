import CodexContentEditor from '../../CodexContentEditor'
import SpotifyPodcast from './SpotifyPodcast.vue'
import SpotifyPodcastPanel from './SpotifyPodcastPanel.vue'
import { BLOCK_GROUPS } from '../constants'

CodexContentEditor.registerWidget('codex', {
  displayName: 'codex-editor.block-names.spotify-podcast',
  name: 'spotify_podcast',
  group: BLOCK_GROUPS.EMBEDS,
  // eslint-disable-next-line global-require
  image: require('../../icons/spotify-podcast/icon.svg'),
  attrs: {
    blockId: {
      default: null,
    },
    src: {
      default: null,
    },
    align: {
      default: 'left',
    },
    width: {
      default: '50%',
    },
    caption: {
      default: null,
    },
  },
  renderEditor: SpotifyPodcast,
  renderEditorBar: SpotifyPodcastPanel,
})
