<template>
  <div class="new-filters-dropdown__filter-body new-filters-dropdown__filter-body--scroll">
    <b-row
      align-v="center"
      no-gutters
    >
      <b-col cols="12">
        <b-checkbox-group
          v-model="value.value"
          class="new-filters-dropdown__checkbox-group"
          :options="options"
          stacked
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { ASSET_TYPES, ASSET_TYPE_MAPPING, mapAssetTypeLabel } from '@/codex-sdk/assets'

export default {
  props: {
    filter: {
      type: Object,
      required: true,
    },
    filterObject: {
      type: [Object, Function],
      required: true,
    },
    value: {
      type: Object,
      required: true,
    },
    cache: {
      type: Object,
      required: true,
    },
    setCache: {
      type: Function,
      required: true,
    },
  },
  computed: {
    options() {
      return [
        { text: mapAssetTypeLabel(ASSET_TYPES.IMAGE), value: ASSET_TYPE_MAPPING.toNumber(ASSET_TYPES.IMAGE) },
        { text: mapAssetTypeLabel(ASSET_TYPES.FILE), value: ASSET_TYPE_MAPPING.toNumber(ASSET_TYPES.FILE) },
        { text: mapAssetTypeLabel(ASSET_TYPES.VIDEO), value: ASSET_TYPE_MAPPING.toNumber(ASSET_TYPES.VIDEO) },
        { text: mapAssetTypeLabel(ASSET_TYPES.VIDEO_PLAYLIST), value: ASSET_TYPE_MAPPING.toNumber(ASSET_TYPES.VIDEO_PLAYLIST) },
        { text: mapAssetTypeLabel(ASSET_TYPES.AUDIO), value: ASSET_TYPE_MAPPING.toNumber(ASSET_TYPES.AUDIO) },
        { text: mapAssetTypeLabel(ASSET_TYPES.PODCAST), value: ASSET_TYPE_MAPPING.toNumber(ASSET_TYPES.PODCAST) },
      ]
    },
  },
}
</script>
