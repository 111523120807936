import ability from '@/libs/acl/ability'
import { pr } from './config'

/**
 * preset_view
 * preset_create
 * preset_edit
 * preset_delete
 */

export const Presets = Object.freeze({
  VIEW: 'preset_view',
  CREATE: 'preset_create',
  EDIT: 'preset_edit',
  DELETE: 'preset_delete',
})

export const PresetsTooltips = {
  [Presets.VIEW]: 'Allow user to view Presets',
  [Presets.CREATE]: 'Allow user to create Presets',
  [Presets.EDIT]: 'Allow user to edit Presets',
  [Presets.DELETE]: 'Allow user to delete Presets',
}

/**
 * Can View Presets
 */
export function canViewPresets(_options = {}) {
  const { entity, ...options } = _options

  return pr(
    ability.can(Presets.VIEW),

    'You have no permission to view Presets',

    options,
  )
}

/**
 * Can Create Presets
 */
export function canCreatePresets(_options = {}) {
  const { entity, ...options } = _options

  return pr(
    ability.can(Presets.CREATE),

    'You have no permission to create Presets',

    options,
  )
}

/**
 * Can Edit Presets
 */
export function canEditPresets(_options = {}) {
  const { entity, ...options } = _options

  return pr(
    ability.can(Presets.EDIT),

    'You have no permission to edit Presets',

    options,
  )
}

/**
 * Can Delete Presets
 */

export function canDeletePresets(_options = {}) {
  const { entity, ...options } = _options

  return pr(
    ability.can(Presets.DELETE),

    'You have no permission to delete Presets',

    options,
  )
}
