import CodexContentEditor from '../../CodexContentEditor'
import TikTokEmbed from './TikTokEmbed.vue'
import TikTokEmbedPanel from './TikTokEmbedPanel.vue'
import { BLOCK_GROUPS } from '../constants'

CodexContentEditor.registerWidget('codex', {
  displayName: 'codex-editor.block-names.tiktok-embed',
  name: 'tiktok_embed',
  group: BLOCK_GROUPS.EMBEDS,
  // eslint-disable-next-line global-require
  image: require('../../icons/tiktok-embed/icon.svg'),
  attrs: {
    blockId: {
      default: null,
    },
    src: {
      default: null,
    },
    videoId: {
      default: null,
    },
    caption: {
      default: null,
    },
    align: {
      default: 'left',
    },
  },
  renderEditor: TikTokEmbed,
  renderEditorBar: TikTokEmbedPanel,
})
