<template>
  <div
    v-if="entry.system.updatedAt || (updatedByUser && updatedByUser.fullName)"
    class="entries-entity-info"
  >
    <h5 class="entity-info__label">
      {{ $t('entries.update-info-title') }}
    </h5>
    <b-row style="display: flex; align-items: center;">
      <b-col style="padding-right: 0;">
        <p>
          {{ entry.system.updatedAt | formatDateTime }}
        </p>
      </b-col>
      <b-col style="padding-left: 0;">
        <p
          v-if="updatedByUser && updatedByUser.fullName"
          class="entity-info__user-wrapper"
        >
          <b-avatar
            variant="default"
            :text="updatedByUser.fullName && updatedByUser.fullName[0]"
          />
          <span class="entity-info__user">
            {{ updatedByUser.fullName }}
          </span>
        </p>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import gql from 'graphql-tag'

export default {
  props: {
    entry: Object,
  },
  apollo: {
    userCollection: {
      query: gql`
        query ($ids: [String!]){
          userCollection (where: {id: {in: $ids}}) {
            items {
              id
              imageUrl
              email
              firstName
              lastName
            }
          }
        }
      `,
      result({ data }) { this.users = data.userCollection.items },
      variables() {
        const ids = []
        if (this.entry.system.createdBy) ids.push(this.entry.system.createdBy)
        if (this.entry.system.updatedBy) ids.push(this.entry.system.updatedBy)
        return { ids }
      },
    },
  },
  data() {
    return {
      users: [],
    }
  },
  computed: {
    updatedByUser() {
      const user = this.users.find(u => u.id == this.entry.system.updatedBy)
      if (user) user.fullName = `${user.firstName || ''} ${user.lastName || ''}`.trim()
      return user
    },
  },
}
</script>

<style lang="scss">
.entries-entity-info {
  overflow: hidden;
  .form-group {
    margin-bottom: 2px;
     .col-form-label {
       padding: 0 5px;
     }
  }
  p, legend {
    margin: 0;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #667C99 !important;
  }
  .b-avatar {
    width: 22px;
    height: 22px;
    display: flex !important;
  }
  .entity-info__labels {
    min-width: 88px;
  }
  .entity-info__label {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #052D61;
    margin: 0;
    padding: 0;
    margin-bottom: 8px;
  }

  .entity-info__user-wrapper {
    display: flex;
    align-items: center;
    margin-top: 4px;
    .entity-info__user {
      margin-left: 8px;
      display: -webkit-box !important;
      -webkit-box-orient: vertical;
      overflow: hidden;
      -webkit-line-clamp: 1;
      word-break: break-all;
    }
  }
}

</style>
