import { apiAxios as axios } from '../libs/axios'
import { tc } from './config'

export const ENTITY_TYPES = Object.freeze({
  // ENTRY: 1,
  // MODEL: 2,
  // SECTION: 3,
  // ASSET: 4,
  // AUTHOR: 5,
  // LIST: 6,
  COMMENT: 7,
  // INTEGRATION: 8,
  // LABEL: 9,
  // TEAM: 10,
  // USER: 11,
  // WEBHOOK: 12,
})
export function mapEntityTypes(type) {
  const types = {
    [ENTITY_TYPES.COMMENT]: 'workflows.entities.comment',
    // Add these in localization file
    // [ENTITY_TYPES.ENTRY]: 'workflows.entities.entry',
    // [ENTITY_TYPES.MODEL]: 'workflows.entities.model',
    // [ENTITY_TYPES.SECTION]: 'workflows.entities.section',
    // [ENTITY_TYPES.ASSET]: 'workflows.entities.asset',
    // [ENTITY_TYPES.AUTHOR]: 'workflows.entities.author',
    // [ENTITY_TYPES.LIST]: 'workflows.entities.list',
    // [ENTITY_TYPES.INTEGRATION]: 'workflows.entities.integration',
    // [ENTITY_TYPES.LABEL]: 'workflows.entities.label',
    // [ENTITY_TYPES.TEAM]: 'workflows.entities.team',
    // [ENTITY_TYPES.USER]: 'workflows.entities.user',
    // [ENTITY_TYPES.WEBHOOK]: 'workflows.entities.webhook',
  }

  return types[type] || 'workflows.entities.comment'
}

export const EVENT_TYPE = Object.freeze({
  // ENTRY_CREATED: 1,
  // ENTRY_UPDATED: 2,
  // ENTRY_DELETED: 3,
  // ASSET_UPLOADED: 4,
  COMMENT_ADDED: 5,
  COMMENT_REPLY_ADDED: 6,
  COMMENT_RESOLVED: 7,
  USER_MENTIONED: 8,
})
export function mapEventTypes(type) {
  const types = {
    [EVENT_TYPE.ENTRY_CREATED]: 'workflows.events.entry-created',
    [EVENT_TYPE.ENTRY_UPDATED]: 'workflows.events.entry-updated',
    [EVENT_TYPE.ENTRY_DELETED]: 'workflows.events.entry-deleted',
    [EVENT_TYPE.ASSET_UPLOADED]: 'workflows.events.asset-uploaded',
    [EVENT_TYPE.COMMENT_ADDED]: 'workflows.events.comment-added',
    [EVENT_TYPE.COMMENT_REPLY_ADDED]: 'workflows.events.comment-reply-added',
    [EVENT_TYPE.COMMENT_RESOLVED]: 'workflows.events.comment-resolved',
    [EVENT_TYPE.USER_MENTIONED]: 'workflows.events.user-mentioned',
  }

  return types[type] || 'workflows.workflow.actions.types.system-notification'
}

export function generateEventTypesDefaultMessages(type) {
  const types = {
    [EVENT_TYPE.COMMENT_ADDED]: 'workflows.events.default-messages.comment-added',
    [EVENT_TYPE.COMMENT_REPLY_ADDED]: 'workflows.events.default-messages.comment-reply-added',
    [EVENT_TYPE.COMMENT_RESOLVED]: 'workflows.events.default-messages.comment-resolved',
    [EVENT_TYPE.USER_MENTIONED]: 'workflows.events.default-messages.user-mentioned',
  }

  return types[type] || 'workflows.events.default-messages.default'
}

export const ACTIONS_TYPES = Object.freeze({
  SYSTEM_NOTIFICATION: 1,
  EMAIL_NOTIFICATION: 2,
  SLACK_MESSAGE: 3,
  GOOGLE_CHAT_MESSAGE: 4,
  // UPDATE_LABEL: 5,
  // UPDATE_STATUS: 6,
  // CREATE_TASK: 7,
  // SEND_WEBHOOK: 8,
})

export function mapActionsTypes(type) {
  const types = {
    [ACTIONS_TYPES.SYSTEM_NOTIFICATION]: 'workflows.workflow.actions.types.system-notification',
    [ACTIONS_TYPES.EMAIL_NOTIFICATION]: 'workflows.workflow.actions.types.email-notification',
    [ACTIONS_TYPES.SLACK_MESSAGE]: 'workflows.workflow.actions.types.external-notification',
    [ACTIONS_TYPES.GOOGLE_CHAT_MESSAGE]: 'workflows.workflow.actions.types.external-notification',
    // [ACTIONS_TYPES.UPDATE_LABEL]: 'workflows.workflow.actions.types.update-label',
    // [ACTIONS_TYPES.UPDATE_STATUS]: 'workflows.workflow.actions.types.update-status',
    // [ACTIONS_TYPES.CREATE_TASK]: 'workflows.workflow.actions.types.create-task',
    // [ACTIONS_TYPES.SEND_WEBHOOK]: 'workflows.workflow.actions.types.send-webhook',
  }

  return types[type] || 'workflows.workflow.actions.types.system-notification'
}
export function mapActionsIcons(value) {
  switch (value) {
    case 1:
      return 'Notifications'
    case 2:
      return 'MailSend'
    case 3:
    case 4:
      return 'Upload'
    // case 5:
    //   return 'ChatCircleAdd'
    // case 6:
    //   return 'CloudCheckDoneSave'
    // case 7:
    //   return 'IconparkComment'
    // case 8:
    //   return 'Webhook'
    default:
      return ''
  }
}

// System notifications types and mappers
export const SYS_NOTIFICATION_RECEIVER_TYPE = Object.freeze({
  USER: 1,
  TEAM: 2,
  MENTIONED_USER: 3,
  COMMENT_CREATOR: 4,
  ENTRY_CREATOR: 5,
})

export function mapSysNotificationReceiverTypes(type) {
  const types = {
    [SYS_NOTIFICATION_RECEIVER_TYPE.USER]: 'workflows.workflow.actions.system-notifications.form.receivers.options.user',
    [SYS_NOTIFICATION_RECEIVER_TYPE.TEAM]: 'workflows.workflow.actions.system-notifications.form.receivers.options.team',
    [SYS_NOTIFICATION_RECEIVER_TYPE.MENTIONED_USER]: 'workflows.workflow.actions.system-notifications.form.receivers.options.mentioned-user',
    [SYS_NOTIFICATION_RECEIVER_TYPE.COMMENT_CREATOR]: 'workflows.workflow.actions.system-notifications.form.receivers.options.comment-creator',
    [SYS_NOTIFICATION_RECEIVER_TYPE.ENTRY_CREATOR]: 'workflows.workflow.actions.system-notifications.form.receivers.options.entry-creator',
  }

  return types[type] || 'workflows.workflow.actions.system-notifications.form.receivers.options.placeholder'
}

// External notifications types and mappers
export const APPS_TYPES = Object.freeze({
  SLACK_MESSAGE: 3,
  GOOGLE_CHAT_MESSAGE: 4,
})

export function mapAppsTypes(type) {
  const types = {
    [ACTIONS_TYPES.SLACK_MESSAGE]: 'workflows.workflow.actions.external-notifications.apps.slack',
    [ACTIONS_TYPES.GOOGLE_CHAT_MESSAGE]: 'workflows.workflow.actions.external-notifications.apps.google-chat',
  }

  return types[type] || 'webhooks.webhook.types.content'
}

// Condition operators type and mappers
export const OPERATOR_TYPES = Object.freeze({
  AND: 0,
  OR: 1,
})
export function mapOperatorTypes(type) {
  const types = {
    [OPERATOR_TYPES.AND]: 'workflows.workflow.condition.operator.and',
    [OPERATOR_TYPES.OR]: 'workflows.workflow.condition.operator.or',
  }

  return types[type] || 'workflows.workflow.condition.operator.and'
}

// WORKFLOW
export default function createWorkflow(workflow, messages) {
  return tc(axios.post('/{organization}/automations', workflow), {
    successTitle: 'sdk.success-title',
    successMessage: 'sdk.workflows.create-successful',
    failTitle: 'sdk.workflows.create-failed',
    ...messages,
  })
}
export function readWorkflow(id) {
  return axios.get(`/{organization}/automations/${id}`)
    .then(response => response)
}
export function updateWorkflow(workflow, messages) {
  return tc(axios.put(`/{organization}/automations/${workflow.automation.id}`, workflow.getPayload()), {
    successTitle: 'sdk.success-title',
    successMessage: 'sdk.workflows.update-successful',
    failTitle: 'sdk.workflows.update-failed',
    ...messages,
  })
}
export function publishWorkflow(id, messages) {
  return tc(axios.patch(`/{organization}/automations/${id}/publish`), {
    successTitle: 'sdk.success-title',
    successMessage: 'sdk.workflows.publish-successful',
    failTitle: 'sdk.workflows.publish-failed',
    ...messages,
  })
}
export function deleteWorkflow(id, messages = {}) {
  return tc(axios.delete(`/{organization}/automations/${id}`), {
    successTitle: 'sdk.success-title',
    successMessage: 'sdk.workflows.delete-successful',
    failTitle: 'sdk.workflows.delete-failed',
    ...messages,
  })
}

// Actions
export function createAction(action, messages) {
  return tc(axios.post('/{organization}/actions', action), {
    successTitle: 'sdk.success-title',
    successMessage: 'sdk.workflows.create-successful',
    failTitle: 'sdk.workflows.create-failed',
    ...messages,
  })
}
export function updateAction(action, messages) {
  return tc(axios.put('/{organization}/actions', action), {
    successTitle: 'sdk.success-title',
    successMessage: 'sdk.workflows.update-successful',
    failTitle: 'sdk.workflows.update-failed',
    ...messages,
  })
}
export function deleteAction(id, messages = {}) {
  return tc(axios.delete(`/{organization}/actions/${id}`), {
    successTitle: 'sdk.success-title',
    successMessage: 'sdk.workflows.delete-successful',
    failTitle: 'sdk.workflows.delete-failed',
    ...messages,
  })
}
export function getReplacableVariables() {
  return axios.get('/{organization}/actions/replaceable-variables')
}

// Conditions
export function createCondition(condition, messages) {
  return tc(axios.post('/{organization}/conditions', condition), {
    successTitle: 'sdk.success-title',
    successMessage: 'sdk.workflows.create-successful',
    failTitle: 'sdk.workflows.create-failed',
    ...messages,
  })
}
export function updateCondition(condition, messages) {
  return tc(axios.put(`/{organization}/conditions/${condition.id}`, condition), {
    successTitle: 'sdk.success-title',
    successMessage: 'sdk.workflows.update-successful',
    failTitle: 'sdk.workflows.update-failed',
    ...messages,
  })
}
export function deleteCondition(id, messages = {}) {
  return tc(axios.delete(`/{organization}/conditions/${id}`), {
    successTitle: 'sdk.success-title',
    successMessage: 'sdk.workflows.delete-successful',
    failTitle: 'sdk.workflows.delete-failed',
    ...messages,
  })
}
