<template>
  <!-- Don't remove "custom-class" prop -->
  <b-tooltip
    :target="target"
    triggers="hover"
    custom-class=""
  >
    <p>
      {{ $t('fields.general.inputs-allowed-to-use.info') }}
    </p>

    <ul>
      <li v-if="validation === RANGE_OPERATORS.BETWEEN || validation === RANGE_OPERATORS.GTE">
        {{ $t('fields.general.inputs-allowed-to-use.min') }}
      </li>
      <li v-if="validation === RANGE_OPERATORS.BETWEEN || validation === RANGE_OPERATORS.LTE">
        {{ $t('fields.general.inputs-allowed-to-use.max') }}
      </li>
      <li v-if="validation === RANGE_OPERATORS.EXACTLY">
        {{ $t('fields.general.inputs-allowed-to-use.exactly') }}</li>
    </ul>
  </b-tooltip>
</template>

<script>
import { RANGE_OPERATORS } from '@/views/models/constants'

export default {
  name: 'PlaceholdersTooltip',
  props: ['validation', 'target'],
  data() {
    return { RANGE_OPERATORS }
  },
}
</script>
