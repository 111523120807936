<template>
  <div
    v-if="block.type === CODEX_EDITOR_BLOCKS.PAGE_BREAK"
    class="rich-content__page-break"
  >
    {{ block.attrs.name }}
  </div>
  <div
    v-else
    v-html="block.contentHTML"
  />
</template>

<script>

import { CODEX_EDITOR_BLOCKS } from '@/components/codex-editor/CodeEditorConstants'

export default {
  name: 'DefaultComponent',
  props: {
    block: {
      type: [Object],
    },
  },
  data() {
    return {
      CODEX_EDITOR_BLOCKS,
    }
  },
}
</script>

<style lang="scss">
.rich-content__page-break {
  text-align: center;
  border-bottom: 1px solid grey;
  margin-top: 8px;
}
</style>
