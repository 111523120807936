import { ASSET_TYPES, mapAssetTypeKey } from '@/codex-sdk/assets'
import CodexContentEditor from '../../CodexContentEditor'
import Media from './Media.vue'
import MediaPanel from './MediaPanel.vue'
import { BLOCK_GROUPS } from '../constants'

CodexContentEditor.registerWidget('codex', {
  displayName: 'codex-editor.block-names.media',
  name: 'media',
  group: [BLOCK_GROUPS.MEDIA, BLOCK_GROUPS.MOST_USED],
  keywords: ['media', 'image', 'video', 'gallery'],
  // eslint-disable-next-line global-require
  image: require('../../icons/media/icon.svg'),
  variants: [
    {
      name: mapAssetTypeKey(ASSET_TYPES.VIDEO),
      keywords: ['media', 'video'],
      attrs: {
        type: ASSET_TYPES.VIDEO,
      },
    },
    {
      name: mapAssetTypeKey(ASSET_TYPES.IMAGE),
      keywords: ['media', 'image', 'picture'],
      attrs: {
        type: ASSET_TYPES.IMAGE,
      },
    },
    {
      name: mapAssetTypeKey(ASSET_TYPES.FILE),
      keywords: ['media', 'file'],
      group: [BLOCK_GROUPS.MEDIA],
      attrs: {
        type: ASSET_TYPES.FILE,
      },
    },
    {
      name: mapAssetTypeKey(ASSET_TYPES.VIDEO_PLAYLIST),
      keywords: ['media', 'playlist'],
      group: [BLOCK_GROUPS.MEDIA],
      attrs: {
        type: ASSET_TYPES.VIDEO_PLAYLIST,
      },
    },
    {
      name: mapAssetTypeKey(ASSET_TYPES.AUDIO),
      keywords: ['media', 'audio'],
      group: [BLOCK_GROUPS.MEDIA],
      attrs: {
        type: ASSET_TYPES.AUDIO,
      },
    },
    {
      name: mapAssetTypeKey(ASSET_TYPES.PODCAST),
      keywords: ['media', 'podcast'],
      group: [BLOCK_GROUPS.MEDIA],
      attrs: {
        type: ASSET_TYPES.PODCAST,
      },
    },
  ],
  attrs: {
    media: {
      default: [],
    },
    blockId: {
      default: null,
    },
    src: {
      default: null,
    },
    align: {
      default: 'center',
    },
    caption: {
      default: '',
    },
    width: {
      default: '70%',
    },
    type: {
      default: null,
    },
    url: {
      default: null,
    },
  },
  renderEditor: Media,
  renderEditorBar: MediaPanel,
})
