export default {
  workflows(state) {
    return state.workflows
  },
  workflow(state) {
    return state.workflow
  },
  workflowsFrom(state) {
    return (state.filters.page - 1) * state.filters.pageSize + 1
  },
  workflowsTo(state) {
    return Math.min(state.filters.page * state.filters.pageSize, state.filters.totalItems)
  },
  selectedModel(state, getters, rootState) {
    const model = state.workflow.automation.models.length == 1 ? state.workflow.automation.models[0] : null
    return rootState.general.models.find(m => m.alias === model)
  },
  selectedModels(state, getters, rootState) {
    if (state.workflow.automation.models.length == 1) {
      return state.workflow.automation.models.map(model => rootState.general.models.find(m => m.alias === model))
    }
    return []
  },
}
