export default {
  props: {
    error: {
      type: Object,
      default: () => ({ isValid: true, message: '' }),
    },
    callValidate: {
      type: Function,
      default: () => { },
    },
    clearError: {
      type: Function,
      default: () => { },
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    editable: {
      type: Boolean,
      default: true,
    },
    hidden: {
      type: Boolean,
      default: true,
    },
  },
}
