<template>
  <div class="date-time__validation">
    <FieldConditions
      v-model="validation.required"
      :widget="widget"
      :label="$t('fields.general.required.label')"
      :tooltip="$t('fields.general.required.info')"
      :error-message-label="$t('fields.general.required.error-message')"
    />

    <FieldConditions
      v-model="validation.readOnly"
      :widget="widget"
      :label="$t('fields.general.read-only.label')"
      :show-error-message="false"
    />

    <b-form-group>
      <b-form-checkbox v-model="validation.range.isEnabled">
        {{ $t('fields.codex-field-date-and-time.validation.date-range') }}
        <GjIcon
          v-b-tooltip.hover="$t('fields.codex-field-date-and-time.validation.date-range-info')"
          name="Info"
          size="20"
        />
      </b-form-checkbox>
    </b-form-group>
    <b-form-group>
      <v-select
        v-if="validation.range.isEnabled"
        v-model="validation.range.rangeOperator"
        :options="rangeOptions"
        :clearable="false"
        :reduce="e => e.value"
      />
    </b-form-group>

    <template v-if="validation.range.isEnabled">
      <b-form-group
        v-if="validation.range.rangeOperator === RANGE_OPERATORS.BETWEEN || validation.range.rangeOperator === RANGE_OPERATORS.GTE"
        :label="$t('fields.codex-field-date-and-time.validation.later')"
      >
        <div class="validation-date-time__calendar-wrapper">
          <flat-pickr
            :key="`${format}${timeMode}`"
            v-model="min"
            :config="{ ...config, maxDate: validation.range.rangeOperator === RANGE_OPERATORS.BETWEEN && max ? max : null }"
          />
          <span class="validation-calendar">
            <GjIcon name="Calendar" />
          </span>
        </div>
      </b-form-group>

      <b-form-group
        v-if="validation.range.rangeOperator === RANGE_OPERATORS.BETWEEN || validation.range.rangeOperator === RANGE_OPERATORS.LTE"
        :label="$t('fields.codex-field-date-and-time.validation.earlier')"
      >
        <div class="validation-date-time__calendar-wrapper">
          <flat-pickr
            :key="`${format}${timeMode}`"
            v-model="max"
            :config="{ ...config, minDate: validation.range.rangeOperator === RANGE_OPERATORS.BETWEEN && min ? min : null }"
          />
          <span class="validation-calendar">
            <GjIcon name="Calendar" />
          </span>
        </div>
      </b-form-group>
      <b-form-group>
        <b-form-invalid-feedback :state="validationError.isValid">
          {{ $t(validationError.emptyMessage) }}
        </b-form-invalid-feedback>
      </b-form-group>
      <b-form-group
        v-if="validation.range.isEnabled"
        :label="$t('fields.codex-field-date-and-time.validation.custom-error')"
      >
        <b-form-input
          v-model="validation.range.errorMessage"
          :placeholder="$t('fields.codex-field-date-and-time.validation.custom-error-placeholder')"
          type="text"
        />
      </b-form-group>
    </template>
  </div>
</template>

<script>
import { generateComputedPropsFromAttrs } from '@/components/codex-layout-editor/BuilderUtils'
import { RANGE_OPERATORS } from '@/views/models/constants'
import moment from 'moment'
import FieldConditions from '@/components/fields/FieldConditions.vue'
import BaseFieldMixin from '@/components/fields/BaseFieldMixin'
import { DATE_FORMAT, TIME_FORMAT } from './index'

export default {
  components: { FieldConditions },
  mixins: [BaseFieldMixin],
  data() {
    return {
      RANGE_OPERATORS,
      validationError: {
        isValid: true,
        emptyMessage: this.$t('models.error-messages.emptyDate'),
      },
      min: this.widget.attrs.validation.range.min ? moment(this.widget.attrs.validation.range.min).format('YYYY-MM-DD HH:MM') : '',
      max: this.widget.attrs.validation.range.max ? moment(this.widget.attrs.validation.range.max).format('YYYY-MM-DD HH:MM') : '',
    }
  },
  computed: {
    ...generateComputedPropsFromAttrs([
      'validation',
      'format',
      'timeMode',
      'format',
      'timeMode',
    ]),
    rangeOptions() {
      return [
        { label: this.$t('fields.codex-field-date-and-time.validation.rangeOptions.between'), value: RANGE_OPERATORS.BETWEEN },
        { label: this.$t('fields.codex-field-date-and-time.validation.rangeOptions.at-least'), value: RANGE_OPERATORS.GTE },
        { label: this.$t('fields.codex-field-date-and-time.validation.rangeOptions.no-more-than'), value: RANGE_OPERATORS.LTE },
      ]
    },
    config() {
      let altFormat = 'd/m/Y'
      if (this.format === DATE_FORMAT.DATETIME) {
        altFormat = this.timeMode === TIME_FORMAT.H24 ? 'd/m/Y H:i' : 'd/m/Y h:i K'
      }

      return {
        enableTime: this.format === DATE_FORMAT.DATETIME,
        altFormat,
        time_24hr: this.timeMode === TIME_FORMAT.H24,
      }
    },
  },
  watch: {
    min(v) {
      this.validationError.isValid = !!v

      if (this.format === DATE_FORMAT.DATE) {
        this.validation.range.min = moment(v).startOf('day').toISOString()
      } else {
        this.validation.range.min = moment(v).toISOString()
      }
    },
    max(v) {
      this.validationError.isValid = !!v

      if (this.format === DATE_FORMAT.DATE) {
        this.validation.range.max = moment(v).endOf('day').toISOString()
      } else {
        this.validation.range.max = moment(v).toISOString()
      }
    },
  },
}
</script>

<style lang="scss">
.date-time__validation {
  .validation-date-time__calendar-wrapper {
    position: relative;
  }

  .validation-calendar {
    position: absolute;
    inset: 0;
    right: 10px;
    display: flex;
    justify-content: right;
    align-items: center;
    z-index: -1;
  }
}
</style>
