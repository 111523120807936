import Vue from 'vue'
// eslint-disable-next-line import/no-extraneous-dependencies
import flatPickr from 'flatpickr'
import FlatPickr from 'vue-flatpickr-component'

flatPickr.setDefaults(
  {
    altInput: true,
    altFormat: 'd/m/Y H:i',
    minuteIncrement: 1,
    time_24hr: true,
    locale: {
      firstDayOfWeek: 1,
    },
  },
)

Vue.component('flat-pickr', FlatPickr)
