<template>
  <div>
    <FieldConditions
      v-model="validation.required"
      :widget="widget"
      :label="$t('fields.general.required.label')"
      :tooltip="$t('fields.general.required.info')"
      :error-message-label="$t('fields.general.required.error-message')"
    />
    <!-- required -->

    <FieldConditions
      v-model="validation.readOnly"
      :widget="widget"
      :label="$t('fields.general.read-only.label')"
      :show-error-message="false"
    />

    <template v-if="valueType === VALUE_TYPES.SINGLE">
      <!-- unique field -->
      <b-form-group>
        <b-form-checkbox v-model="validation.uniqueInCollection.value">
          {{ $t('fields.codex-field-text.validation.unique-field') }}
          <GjIcon
            v-b-tooltip.hover="$t('fields.codex-field-text.validation.you-wont-be-able')"
            name="Info"
            size="20"
          />
        </b-form-checkbox>
      </b-form-group>
      <b-form-group
        v-if="validation.uniqueInCollection.value"
        :label="$t('fields.codex-field-text.validation.unique-error-message')"
        class="form-group__validation-last-field-margin"
      >
        <b-form-input
          v-model="validation.uniqueInCollection.errorMessage"
          :placeholder="$t('fields.codex-field-text.validation.enter-error-message')"
        />
      </b-form-group>
    </template>

    <template v-if="appearance !== TEXT_FIELD_APPEARANCES.CHECKBOX && appearance !== TEXT_FIELD_APPEARANCES.RATING && appearance !== TEXT_FIELD_APPEARANCES.URL && appearance !== TEXT_FIELD_APPEARANCES.DROPDOWN">
      <b-form-group>
        <b-form-checkbox v-model="validation.textLengthRange.isEnabled">
          {{ $t('fields.codex-field-text.validation.limit-character-count') }}
          <GjIcon
            v-b-tooltip.hover="$t('fields.codex-field-text.validation.specify-a-minimum')"
            name="Info"
            size="20"
          />
        </b-form-checkbox>
      </b-form-group>
      <template v-if="validation.textLengthRange.isEnabled">
        <b-form-group>
          <v-select
            v-model="validation.textLengthRange.rangeOperator"
            :options="charCountOptions"
            :clearable="false"
            :reduce="v => v.value"
            @input="changedTextLengthOperator"
          />
        </b-form-group>
        <b-row>
          <b-col v-if="validation.textLengthRange.rangeOperator === RANGE_OPERATORS.BETWEEN || validation.textLengthRange.rangeOperator === RANGE_OPERATORS.GTE">
            <b-form-group :label="$t('fields.codex-field-text.validation.min')">
              <b-form-input
                v-model.number="validation.textLengthRange.min"
                :state="!characterValidationError.isValid ? characterValidationError.isValid : null"
                min="0"
                type="number"
                @keydown="validateNumber($event, validation.textLengthRange.min)"
                @input="characterValidationError = checkRangeValidation(validation.textLengthRange)"
              />
            </b-form-group>
          </b-col>
          <b-col v-if="validation.textLengthRange.rangeOperator === RANGE_OPERATORS.BETWEEN || validation.textLengthRange.rangeOperator === RANGE_OPERATORS.LTE">
            <b-form-group :label="$t('fields.codex-field-text.validation.max')">
              <b-form-input
                v-model.number="validation.textLengthRange.max"
                :state="!characterValidationError.isValid ? characterValidationError.isValid : null"
                min="0"
                type="number"
                @keydown="validateNumber($event, validation.textLengthRange.max)"
                @input="characterValidationError = checkRangeValidation(validation.textLengthRange)"
              />
            </b-form-group>
          </b-col>
          <b-col v-if="validation.textLengthRange.rangeOperator === RANGE_OPERATORS.EXACTLY">
            <b-form-group :label="$t('fields.codex-field-text.validation.exactly')">
              <b-form-input
                v-model.number="validation.textLengthRange.exactly"
                :state="!characterValidationError.isValid ? characterValidationError.isValid : null"
                min="0"
                type="number"
                @keydown="validateNumber($event, validation.textLengthRange.exactly)"
                @input="characterValidationError = checkRangeValidation(validation.textLengthRange, true)"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-form-group>
          <b-form-invalid-feedback :state="characterValidationError.isValid">
            {{ $t(characterValidationError.emptyMessage) }}
          </b-form-invalid-feedback>
          <b-form-invalid-feedback :state="characterValidationError.isValid">
            {{ $t(characterValidationError.differenceMessage) }}
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group
          v-if="validation.textLengthRange.isEnabled"
        >
          <template #label>
            <label>{{ $t('fields.codex-field-text.validation.custom-error-template') }}</label>
            <span
              id="text__inputs-allowed-tooltip"
              class="codex-field-range-tooltip"
            >
              <GjIcon
                name="Info"
                size="20"
              />
            </span>
          </template>
          <PlaceholdersTooltip
            :validation="validation.textLengthRange.rangeOperator"
            :target="'text__inputs-allowed-tooltip'"
          />
          <b-form-input
            v-model="validation.textLengthRange.errorTemplate"
            class="error-template"
            :placeholder="$t('fields.codex-field-text.validation.custom-error-message')"
          />
        </b-form-group>
        <b-form-group
          v-if="validation.textLengthRange.isEnabled"
          :label="$t('fields.codex-field-text.validation.enter-error-message')"
          class="form-group__validation-last-field-margin"
        >
          <b-form-input
            v-model="validation.textLengthRange.errorMessage"
            readonly
          />
        </b-form-group>
      </template>
    </template>
    <!-- limit char -->

    <template v-if="valueType === VALUE_TYPES.LIST">
      <b-form-group>
        <b-form-checkbox v-model="validation.listLengthRange.isEnabled">
          {{ $t('fields.codex-field-text.validation.accept-only-specified-list-inputs') }}
          <GjIcon
            v-b-tooltip.hover="$t('fields.codex-field-text.validation.specify-min-max-number-of-list-inputs')"
            name="Info"
            size="20"
          />
        </b-form-checkbox>
      </b-form-group>

      <template v-if="validation.listLengthRange.isEnabled">
        <b-form-group>
          <v-select
            v-model="validation.listLengthRange.rangeOperator"
            :options="charCountOptions"
            :clearable="false"
            :reduce="v => v.value"
            @input="changedListLengthOperator"
          />
        </b-form-group>

        <b-row>
          <b-col v-if="validation.listLengthRange.rangeOperator === RANGE_OPERATORS.BETWEEN || validation.listLengthRange.rangeOperator === RANGE_OPERATORS.GTE">
            <b-form-group :label="$t('fields.codex-field-text.validation.min')">
              <b-form-input
                v-model.number="validation.listLengthRange.min"
                :state="!listValidationError.isValid ? listValidationError.isValid : null"
                min="0"
                type="number"
                @keydown="validateNumber($event, validation.listLengthRange.min)"
                @input="listValidationError = checkRangeValidation(validation.listLengthRange)"
              />
            </b-form-group>
          </b-col>
          <b-col v-if="validation.listLengthRange.rangeOperator === RANGE_OPERATORS.BETWEEN || validation.listLengthRange.rangeOperator === RANGE_OPERATORS.LTE">
            <b-form-group :label="$t('fields.codex-field-text.validation.max')">
              <b-form-input
                v-model.number="validation.listLengthRange.max"
                :state="!listValidationError.isValid ? listValidationError.isValid : null"
                min="0"
                type="number"
                @keydown="validateNumber($event, validation.listLengthRange.max)"
                @input="listValidationError = checkRangeValidation(validation.listLengthRange)"
              />
            </b-form-group>
          </b-col>
          <b-col v-if="validation.listLengthRange.rangeOperator === RANGE_OPERATORS.EXACTLY">
            <b-form-group :label="$t('fields.codex-field-text.validation.exactly')">
              <b-form-input
                v-model.number="validation.listLengthRange.exactly"
                :state="!listValidationError.isValid ? listValidationError.isValid : null"
                min="0"
                type="number"
                @keydown="validateNumber($event, validation.listLengthRange.exactly)"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-form-group>
          <b-form-invalid-feedback :state="listValidationError.isValid">
            {{ $t(listValidationError.emptyMessage) }}
          </b-form-invalid-feedback>
          <b-form-invalid-feedback :state="listValidationError.isValid">
            {{ $t(listValidationError.differenceMessage) }}
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group v-if="validation.listLengthRange.isEnabled">
          <template #label>
            <label>{{ $t('fields.codex-field-text.validation.custom-error-template') }}</label>
            <span
              id="text__listLengthRange-tooltip"
              class="codex-field-range-tooltip"
            >
              <GjIcon
                name="Info"
                size="20"
              />
            </span>
          </template>
          <PlaceholdersTooltip
            :validation="validation.listLengthRange.rangeOperator"
            :target="'text__listLengthRange-tooltip'"
          />
          <b-form-input
            v-model="validation.listLengthRange.errorTemplate"
            class="error-template"
            :placeholder="$t('fields.codex-field-text.validation.custom-error-message')"
          />
        </b-form-group>
        <b-form-group
          v-if="validation.listLengthRange.isEnabled"
          :label="$t('fields.codex-field-text.validation.custom-error-message')"
          class="form-group__validation-last-field-margin"
        >
          <b-form-input
            v-model="validation.listLengthRange.errorMessage"
            readonly
          />
        </b-form-group>
      </template>
    </template>
    <!-- accept a specified number of list inputs -->

    <b-form-group>
      <b-form-checkbox v-model="validation.matchingRegex.isEnabled">
        {{ $t('fields.codex-field-text.validation.match-specific-pattern') }}
        <GjIcon
          v-b-tooltip.hover="$t('fields.codex-field-text.validation.match-pattern-info')"
          name="Info"
          size="20"
        />
      </b-form-checkbox>
    </b-form-group>

    <template v-if="validation.matchingRegex.isEnabled">
      <b-form-group>
        <v-select
          v-model="validation.matchingRegex.type"
          :options="patternTypeOptions"
          :clearable="false"
          :reduce="v => v.value"
          :placeholder="$t('fields.codex-field-text.validation.pattern-type-placeholder')"
          @input="handleTypeChange"
        />
      </b-form-group>

      <div class="text-field__patterns-section">
        <b-form-input
          id="at-match-regex-word-input"
          v-model.trim="matchWord"
          :placeholder="$t('fields.codex-field-text.validation.pattern-placeholder')"
          :state="matchPatternState"
          @keypress.enter="handleInputChange(true)"
        />
        <span
          class="at-prohibit-regex-word__button"
          @click="handleAddText(true)"
        >
          <GjIcon
            size="18"
            style="margin-top: 2px; fill: #1D79F2"
            name="Plus"
          />
          {{ $t('fields.codex-field-text.validation.pattern-value-add') }}
        </span>
      </div>
      <b-form-invalid-feedback id="input-live-feedback">
        {{ $t('fields.codex-field-text.validation.pattern-error') }}
      </b-form-invalid-feedback>
      <div class="text-field__given-patterns">
        <div
          v-for="(cmt, idx) in validation.matchingRegex.patterns"
          :key="idx"
          class="text-field__given-pattern"
        >
          <p class="text-field__pattern-bubble">
            {{ cmt }}
          </p>
          <GjIcon
            class="at-match-regex-remove-word-icon"
            name="Close"
            style="margin-right: 8px; cursor: pointer;"
            @click.native="removeMatchWord(cmt)"
          />
        </div>
      </div>
      <b-form-group :label="$t('fields.codex-field-text.validation.flags')">
        <v-select
          v-model="validation.matchingRegex.flags"
          multiple
          :options="flagsOptions"
          :clearable="false"
          :reduce="v => v.value"
        />
      </b-form-group>
      <b-form-group
        :label="$t('fields.codex-field-text.validation.custom-error-message')"
        class="form-group__validation-last-field-margin"
      >
        <b-form-input
          v-model="validation.matchingRegex.errorMessage"
          :placeholder="$t('fields.codex-field-text.validation.enter-error-message')"
        />
      </b-form-group>
    </template>
    <!-- match specific pattern -->

    <b-form-group>
      <b-form-checkbox v-model="validation.prohibitedRegex.isEnabled">
        {{ $t('fields.codex-field-text.validation.prohibit-specific-pattern') }}
        <GjIcon
          v-b-tooltip.hover="$t('fields.codex-field-text.validation.prohibit-pattern-info')"
          name="Info"
          size="20"
        />
      </b-form-checkbox>
    </b-form-group>

    <template v-if="validation.prohibitedRegex.isEnabled">
      <div class="text-field__patterns-section">
        <b-form-input
          id="at-prohibit-regex-word-input"
          v-model.trim="prohibitWord"
          :placeholder="$t('fields.codex-field-text.validation.pattern-placeholder')"
          :state="prohibitPatternState"
          @keypress.enter="handleInputChange(false)"
        />
        <span
          class="at-prohibit-regex-word__button"
          @click="handleAddText(false)"
        >
          <GjIcon
            size="18"
            style="margin-top: 2px; fill: #1D79F2"
            name="Plus"
          />
          {{ $t('fields.codex-field-text.validation.pattern-value-add') }}
        </span>
      </div>
      <b-form-invalid-feedback id="input-live-feedback">
        {{ $t('fields.codex-field-text.validation.pattern-error') }}
      </b-form-invalid-feedback>
      <div class="text-field__given-patterns">
        <div
          v-for="(cmt, idx) in validation.prohibitedRegex.patterns"
          :key="idx"
          class="text-field__given-pattern"
        >
          <p class="text-field__pattern-bubble">
            {{ cmt }}
          </p>
          <GjIcon
            class="at-prohibit-word-remove-icon"
            name="Close"
            style="margin-right: 8px; cursor: pointer;"
            @click.native="validation.prohibitedRegex.patterns = validation.prohibitedRegex.patterns.filter(c => c !== cmt)"
          />
        </div>
      </div>
      <b-form-group :label="$t('fields.codex-field-text.validation.flags')">
        <v-select
          v-model="validation.prohibitedRegex.flags"
          multiple
          :options="flagsOptions"
          :clearable="false"
          :reduce="v => v.value"
        />
      </b-form-group>
      <b-form-group :label="$t('fields.codex-field-text.validation.custom-error-message')">
        <b-form-input
          v-model="validation.prohibitedRegex.errorMessage"
          :placeholder="$t('fields.codex-field-text.validation.enter-error-message')"
        />
      </b-form-group>
    </template>
    <!-- prohibit a specific pattern -->
  </div>
</template>

<script>
import { generateComputedPropsFromAttrs } from '@/components/codex-layout-editor/BuilderUtils'
import {
  VALUE_TYPES,
  RANGE_OPERATORS,
  PATTERN_TYPE,
  FLAGS,
  checkRangeValidation,
  validateNumber,
  errorTemplateParser,
  TEXT_FIELD_APPEARANCES,
} from '@/views/models/constants'
import FieldConditions from '@/components/fields/FieldConditions.vue'
import BaseFieldMixin from '@/components/fields/BaseFieldMixin'
import PlaceholdersTooltip from '../PlaceholdersTooltip.vue'

export default {
  name: 'Validations',
  components: {
    FieldConditions,
    PlaceholdersTooltip,
  },
  mixins: [BaseFieldMixin],
  data() {
    return {
      VALUE_TYPES,
      RANGE_OPERATORS,
      PATTERN_TYPE,
      FLAGS,
      TEXT_FIELD_APPEARANCES,

      checkRangeValidation,
      validateNumber,

      characterValidationError: {
        isValid: true,
        emptyMessage: '',
        differenceMessage: '',
      },
      listValidationError: {
        isValid: true,
        emptyMessage: '',
        differenceMessage: '',
      },

      matchWord: '',
      prohibitWord: '',
      matchPatternState: null,
      prohibitPatternState: null,
      emailRegex: '^\\w[\\w.-]*@([\\w-]+\\.)+[\\w-]+$',
      urlRegex: '^(http(s)?:\\/\\/)?([\\w-]+\\.)+[\\w-]+(\\/[\\w\\- ;,.\\/?%&=]*)?$',
    }
  },
  computed: {
    ...generateComputedPropsFromAttrs([
      'validation',
      'valueType',
      'appearance',
    ]),
    charCountOptions() {
      return [
        { label: this.$t('fields.codex-field-text.validation.between'), value: RANGE_OPERATORS.BETWEEN },
        { label: this.$t('fields.codex-field-text.validation.at-least'), value: RANGE_OPERATORS.GTE },
        { label: this.$t('fields.codex-field-text.validation.at-most'), value: RANGE_OPERATORS.LTE },
        { label: this.$t('fields.codex-field-text.validation.exactly'), value: RANGE_OPERATORS.EXACTLY },
      ]
    },
    predefinedErrorTemplates() {
      return {
        [RANGE_OPERATORS.BETWEEN]: this.$t('fields.codex-field-text.validation.length-of-text-templates.between'),
        [RANGE_OPERATORS.GTE]: this.$t('fields.codex-field-text.validation.length-of-text-templates.gte'),
        [RANGE_OPERATORS.LTE]: this.$t('fields.codex-field-text.validation.length-of-text-templates.lte'),
        [RANGE_OPERATORS.EXACTLY]: this.$t('fields.codex-field-text.validation.length-of-text-templates.exactly'),

      }
    },
    patternTypeOptions() {
      return [
        { label: this.$t('fields.codex-field-text.validation.custom'), value: PATTERN_TYPE.CUSTOM },
        { label: this.$t('fields.codex-field-text.validation.email'), value: PATTERN_TYPE.EMAIL },
        { label: this.$t('fields.codex-field-text.validation.url'), value: PATTERN_TYPE.URL },
      ]
    },
    flagsOptions() {
      return [{ label: this.$t('fields.general.flag-options.ignore-case'), value: FLAGS.IGNORE_CASE }]
    },
  },
  watch: {
    'validation.textLengthRange.rangeOperator': function (v) {
      this.validation.textLengthRange.errorTemplate = this.predefinedErrorTemplates[v]
    },
    'validation.textLengthRange': {
      handler() {
        errorTemplateParser(this.validation.textLengthRange)
      },
      deep: true,
    },
    'validation.listLengthRange': {
      handler() {
        errorTemplateParser(this.validation.listLengthRange)
      },
      deep: true,
    },
  },
  mounted() {
    this.characterValidationError = checkRangeValidation(this.validation.textLengthRange, this.validation.textLengthRange.rangeOperator == RANGE_OPERATORS.EXACTLY)
    this.listValidationError = checkRangeValidation(this.validation.listLengthRange, this.validation.listLengthRange.rangeOperator == RANGE_OPERATORS.EXACTLY)
  },
  methods: {
    changedTextLengthOperator(v) {
      this.validation.textLengthRange.errorMessage = ''
      this.characterValidationError = checkRangeValidation(this.validation.textLengthRange, v === RANGE_OPERATORS.EXACTLY)
    },
    changedListLengthOperator(v) {
      this.validation.listLengthRange.errorMessage = ''
      this.listValidationError = checkRangeValidation(this.validation.listLengthRange, v === RANGE_OPERATORS.EXACTLY)
    },
    handleTypeChange() {
      if (this.validation.matchingRegex.type === PATTERN_TYPE.EMAIL) {
        this.validation.matchingRegex.patterns = []
        this.validation.matchingRegex.patterns.push(this.emailRegex)
      } else if (this.validation.matchingRegex.type === PATTERN_TYPE.URL) {
        this.validation.matchingRegex.patterns = []
        this.validation.matchingRegex.patterns.push(this.urlRegex)
      } else {
        this.validation.matchingRegex.patterns = []
      }
    },

    handleInputChange(match) {
      this.matchPatternState = null
      this.prohibitPatternState = null
      this.handleAddText(match)
    },

    handleAddText(match) {
      if (match) {
        if (!this.matchWord.length) { return }
        if (this.validation.matchingRegex.patterns.includes(this.matchWord)) {
          this.matchPatternState = false
          return
        }

        this.validation.matchingRegex.patterns.push(this.matchWord)
        if (this.validation.matchingRegex.patterns.length > 1) { this.validation.matchingRegex.type = PATTERN_TYPE.CUSTOM }
        this.matchWord = ''
      } else {
        if (!this.prohibitWord.length) { return }
        if (this.validation.prohibitedRegex.patterns.includes(this.prohibitWord)) {
          this.prohibitPatternState = false
          return
        }
        this.validation.prohibitedRegex.patterns.push(this.prohibitWord)
        this.prohibitWord = ''
      }
    },

    removeMatchWord(cmt) {
      this.validation.matchingRegex.patterns = this.validation.matchingRegex.patterns.filter(c => c !== cmt)
      if (this.validation.matchingRegex.patterns.length === 1) {
        if (this.validation.matchingRegex.patterns.includes(this.emailRegex)) { this.validation.matchingRegex.type = PATTERN_TYPE.EMAIL }
        if (this.validation.matchingRegex.patterns.includes(this.urlRegex)) { this.validation.matchingRegex.type = PATTERN_TYPE.URL }
      } else { this.validation.matchingRegex.type = PATTERN_TYPE.CUSTOM }
    },
  },
}
</script>

<style lang="scss" scoped>
.text-field__pattern-bubble {
  margin-bottom: 0;
  padding: 4px 2px 4px 16px;
  font-size: 14px;
  color: #667C99;
  flex-wrap: wrap;
  word-break: break-all;
}
.text-field__given-patterns {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  margin-bottom: 10px;
}
.text-field__given-pattern {
  display: flex;
  align-items: center;

  background-color: #fff;
  border: 1px solid #e0e5eb;
  border-radius: 24px;

  svg {
    min-width: 24px;
  }
}

.text-field__patterns-section {
  position: relative;
  margin-top: 8px;
  margin-bottom: 10px;
  #at-prohibit-regex-word-input{
    width: 100%;
    padding-right: 64px;
  }

  .at-prohibit-regex-word__button{
    position: absolute;
    top: 50%;
    right: 12px;
    transform: translate(0%, -50%);

    display: flex;
    align-content: center;

    cursor: pointer;

    color: #1D79F2;
    font-weight: 600;
  }
}
</style>
