import { TYPES } from '@/views/models/constants'
import { readEntry } from '@/codex-sdk/entries'

export default {
  namespaced: true,
  state: {
    firstEntry: {},
    secondEntry: {},
    mergingModel: {},
    selectedFields: {},
  },
  getters: {
    mergingModel: state => state.mergingModel,
    firstEntry: state => state.firstEntry,
    secondEntry: state => state.secondEntry,
    selectedFields: state => state.selectedFields,
  },
  actions: {
    async getFirstEntry({ commit }, { model, id }) {
      const { data } = await readEntry({ model, id })
      commit('SET_FIRST_ENTRY', data)
    },
    setFirstEntry({ commit }, entry) {
      commit('SET_FIRST_ENTRY', entry)
    },
    getSecondEntry({ commit }, entry) {
      commit('SET_SECOND_ENTRY', entry)
    },
    getMergingModel({ commit }, model) {
      commit('SET_MERGING_MODEL', model)
      commit('SET_SELECTED_FIELDS')
    },
    selectAllChanges({ commit }, status) {
      commit('SELECT_ALL_CHANGES', status)
    },
    selectRichContentFields({ commit }, { field, list, status }) {
      commit('SELECT_RICH_CONTENT_FIELDS', { field, list, status })
    },
  },
  mutations: {
    SET_FIRST_ENTRY(state, entry) {
      state.firstEntry = entry
    },
    SET_SECOND_ENTRY(state, entry) {
      state.secondEntry = entry
    },
    SET_MERGING_MODEL(state, model) {
      state.mergingModel = model
    },
    SET_SELECTED_FIELDS(state) {
      const selObj = {}
      state.mergingModel.fields.forEach(field => {
        if (field.type === TYPES.RICH_CONTENT) {
          selObj[field.alias] = {}
        } else {
          selObj[field.alias] = 0
        }
      })
      state.selectedFields = selObj
    },
    SELECT_ALL_CHANGES(state, status) {
      Object.keys(state.selectedFields).forEach(field => {
        if (typeof state.selectedFields[field] === 'object') return
        if (status) state.selectedFields[field] = 2
        else if (state.selectedFields[field] !== 1) state.selectedFields[field] = 0
      })
    },
    SELECT_RICH_CONTENT_FIELDS(state, { field, list, status }) {
      const selectedBlock = {}
      list.forEach(block => {
        selectedBlock[block] = status ? 2 : 0
      })

      state.selectedFields[field] = selectedBlock
    },
  },
}
