import state from './modulesAuthorsState'
import mutations from './modulesAuthorsMutations'
import actions from './modulesAuthorsActions'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
