<template>
  <b-modal
    id="authors-popup"
    v-model="showPopup"
    ok-variant="primary"
    cancel-variant="outline-dark"
    size="xl"
    no-close-on-backdrop
    centered
    :static="true"
    @ok="closePopup(selectedAuthors)"
    @close="closePopup()"
  >
    <div class="page-header">
      <div class="page-header__actions">
        <b-button
          v-if="selectedAuthors.length"
          size="sm"
          class="button-selected-authors"
          @click="showSelected = !showSelected"
        >
          Selected ({{ selectedAuthors.length }})
        </b-button>
      </div>
      <div class="page-header__filters">
        <b-form-group>
          <div class="page-header__filters-form">
            <b-input-group
              v-if="!showSelected"
              size="sm"
              class="input-group-merge page-header__filters-form--md"
            >
              <b-input-group-prepend is-text>
                <GjIcon name="Search" />
              </b-input-group-prepend>
              <b-form-input
                id="at-authors-popup-search-input"
                v-model.trim="filters.query"
                :placeholder="$t('general.search')"
                :debounce="250"
              />
            </b-input-group>
          </div>
        </b-form-group>
      </div>
    </div>

    <div class="table-container">
      <vue-good-table
        :columns="columns"
        :rows="$apollo.loading ? [] : (showSelected ? selectedAuthors : authors.items)"
        class="gjirafa-tables authors-list-table"
        :class="{ 'authors-loading': $apollo.loading }"
        mode="remote"
        :pagination-options="{
          enabled: !showSelected,
          perPage: filters.pageSize
        }"
        :select-options="{
          enabled: true,
          disableSelectInfo: true,
        }"
        :row-style-class="rowClasses"
        @on-row-click="handleRowsSelect"
      >
        <div
          slot="emptystate"
          class="vgt-text-disabled vgt-center-align"
        >
          {{ $t('general.noData') }}
        </div>
        <template
          slot="table-row"
          slot-scope="props"
        >
          <div class="authors-row-inner">
            <span v-if="props.column.field === 'avatar'">
              <span class="text-nowrap">
                <b-avatar
                  :text="isCharLetter(props.row.byline)"
                  :src="props.row.image ? props.row.image.url : null"
                  variant="default"
                />
              </span>
            </span>
            <span v-if="props.column.field === 'author'">
              <span class="text-nowrap">
                <p class="primary-text">
                  {{ props.row.byline }}
                </p>
              </span>
            </span>
            <span v-if="props.column.field === 'emailAddress'">
              {{ props.row && props.row.email }}
            </span>
            <span v-if="props.column.field === 'dateAdded'">
              <span class="text-nowrap">
                {{ props.row.createdAt | formatDate }}
              </span>
            </span>
            <!-- Column: Actions -->
            <span v-if="props.column.field === 'actions'" />

          </div>

        </template>
        <!-- pagination -->
        <template
          slot="pagination-bottom"
          slot-scope="props"
        >
          <div class="gjirafa-tables__footer">
            <b-pagination
              id="at-authors-pagination"
              :value="filters.page"
              :total-rows="totalItems"
              :per-page="filters.pageSize"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value)=> {
                filters.page = value
                props.pageChanged({currentPage:value})
              }"
            >
              <template #prev-text>
                <GjIcon
                  name="ArrowLeft"
                  size="18"
                />
              </template>
              <template #next-text>
                <GjIcon
                  name="ArrowRight"
                  size="18"
                />
              </template>
            </b-pagination>
            <div class="gjirafa-tables__footer-leftside">
              <span class="text-nowrap">{{
                $t('general.pagination', {from: from, to: to, totalItems: totalItems})
              }}</span>
            </div>
          </div>
        </template>
      </vue-good-table>
    </div>
  </b-modal>
</template>

<script>
import PopupsMixin from '@/components/popups/PopupsMixin'
import gql from 'graphql-tag'
import { VueGoodTable } from 'vue-good-table'

export default {
  name: 'AuthorsPopup',
  components: {
    VueGoodTable,
  },
  mixins: [PopupsMixin],
  apollo: {
    authors: {
      query: gql`
        query Authors ($limit: Int!, $offset: Int!, $query: String) {
          authorCollection(limit: $limit, offset: $offset, where: {or: [ {byline: { contains: $query }}, {firstName: { contains: $query }}, {lastName: { contains: $query }} ]} ) {
            total
            items {
              id
              byline
              firstName
              lastName
              url
              imageId
              image {
                url
              }
              biography
              email
              createdAt
            }
          }
        }
      `,
      fetchPolicy: 'network-only',
      result({ data }) {
        return data.authorCollection
      },
      update: data => data.authorCollection,
      variables() {
        return {
          query: this.filters.query,
          limit: +this.filters.pageSize,
          offset: (this.filters.page - 1) * this.filters.pageSize,
        }
      },
    },
  },
  data() {
    return {
      filters: {
        page: 1,
        query: '',
        pageSize: 10,
      },
      ids: [],
      isSidebarOpen: false,
      selectedAuthors: [],
      showSelected: false,
    }
  },
  computed: {
    totalItems() {
      return (this.authors && this.authors.total) || 0
    },
    from() {
      return (this.filters.page - 1) * this.filters.pageSize + 1
    },
    to() {
      return (this.filters.page * this.filters.pageSize) > this.totalItems ? this.totalItems : (this.filters.page * this.filters.pageSize)
    },
    columns() {
      return [
        {
          label: '',
          field: 'avatar',
          width: '80px',
          sortable: false,
        },
        {
          label: this.$t('authors.name'),
          field: 'author',
          sortable: false,
        },
        {
          label: this.$t('authors.email'),
          field: 'emailAddress',
          sortable: false,
        },
        {
          label: this.$t('authors.date'),
          field: 'dateAdded',
          sortable: false,
        },
        {
          label: '',
          field: 'actions',
          sortable: false,
          width: '50px',
        },
      ]
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    'filters.searchTerm': function () {
      this.filters.page = 1
    },
    selectedAuthors(selected) {
      if (!selected?.length) {
        this.showSelected = false
      }
    },
  },
  methods: {
    rowClasses(row) {
      return this.selectedAuthors.some(a => a.id === row.id) ? 'authors-row-selected' : ''
    },
    handleRowsSelect({ row }) {
      if (this.selectedAuthors.some(a => a.id === row.id)) {
        this.selectedAuthors = this.selectedAuthors.filter(a => a.id !== row.id)
      } else {
        this.selectedAuthors.push(row)
      }
    },
    closeAssetsPopup() {
      this.closePopup(this.selectedAuthors)
    },
    isCharLetter(character) {
      const chars = []
      if (character) {
        character.split('').forEach(char => {
          if ((/[a-zA-Z]/).test(char) && char.toLowerCase() != char.toUpperCase()) {
            chars.push(char)
          }
        })
      }
      return chars[0]
    },
  },
}
</script>
