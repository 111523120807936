<template>
  <div class="toolbar-right__tab-container">
    <template v-if="!loaded">
      <div class="d-flex justify-content-center">
        <b-spinner
          :label="$t('general.loading')"
          variant="primary"
        />
      </div>
    </template>
    <template v-else>
      <h6 class="toolbar-title">
        {{ $t('assets.edit-image.pixelation-tool.title') }}
      </h6>

      <ShapeSelector v-model="shape" />

      <b-form-group :label="$t('assets.edit-image.pixelation-tool.pixelation-selection-strength')">
        <div
          class="toolbar-presets__range-text"
          :data-placeholder="$t('assets.edit-image.pixelation-tool.pixel')"
        >
          <b-form-input
            v-model.number="pixelate"
            class="toolbar-presets__range"
            type="range"
            :min="0"
            :max="100"
          />
          <b-form-input
            v-model.number="pixelate"
            type="number"
            :min="0"
            :max="100"
            class="toolbar-presets__text"
          />
        </div>

      </b-form-group>
      <b-form-group :label="$t('assets.edit-image.pixelation-tool.blur-selection-strength')">
        <div
          class="toolbar-presets__range-text"
          :data-placeholder="$t('assets.edit-image.pixelation-tool.percentage')"
        >
          <b-form-input
            v-model.number="blur"
            class="toolbar-presets__range"
            type="range"
            :min="0"
            :max="100"
          />
          <b-form-input
            v-model.number="blur"
            type="number"
            :min="0"
            :max="100"
            class="toolbar-presets__text"
          />
        </div>
      </b-form-group>
    </template>
    <p
      class="toolbar-reset__button"
      @click="handleCancel"
    >
      {{ $t('assets.edit-image.pixelation-tool.reset') }}
    </p>
  </div>
</template>

<script>
import { debounce } from 'lodash'
import ShapeSelector from '@/components/assets/image-editor/ShapeSelector.vue'
import { IMAGE_EDITOR_TABS } from '@/components/image-editor/constants'

export default {
  name: 'Pixelate',
  components: { ShapeSelector },
  props: ['imageEditor', 'reference', 'loaded', 'tabIndex'],
  data() {
    const filters = {
      pixelate: { pixelate: 5 },
      blur: { blur: 0.5 },
    }
    return {
      shape: 'rect',
      filters,
      selectedObj: null,
      dSetFilter: debounce(this.setFilter, 100),
    }
  },
  computed: {
    blur: {
      set(v) {
        this.filters.blur.blur = v / 100
      },
      get() {
        return (this.filters.blur.blur * 100).toFixed(0)
      },
    },
    pixelate: {
      set(v) {
        this.filters.pixelate.pixelate = v > 0 ? v : 1
      },
      get() {
        return this.filters.pixelate.pixelate
      },
    },
  },
  watch: {
    imageEditor(v) {
      if (v) {
        v.on('objectActivated', props => { this.selectedObj = props })
        v.on('objectAdded', props => { this.selectedObj = props })
        v.on('objectMoved', props => { this.selectedObj = props })
        v.on('objectScaled', props => { this.selectedObj = props })
      }
    },
    pixelate() {
      this.dSetFilter()
    },
    blur() {
      this.dSetFilter()
    },
    async tabIndex() {
      if (this.tabIndex === IMAGE_EDITOR_TABS.PIXELATE) {
        if (this.imageEditor.getDrawingMode() !== 'SHAPE') {
          this.imageEditor.stopDrawingMode()
          this.imageEditor.startDrawingMode('SHAPE')
        }
        this.selectedObj = null
        await this.setFilter()
      }
    },
    async shape() {
      if (this.imageEditor.getDrawingMode() !== 'SHAPE') {
        this.imageEditor.stopDrawingMode()
        this.imageEditor.startDrawingMode('SHAPE')
      }
      this.selectedObj = null
      await this.setFilter()
    },
  },
  methods: {
    async setFilter() {
      const filter = []
      if (this.filters.blur.blur) {
        filter.push(this.filters.blur)
      }
      if (this.filters.pixelate.pixelate) {
        filter.push(this.filters.pixelate)
      }

      if (this.selectedObj) {
        const obj = this.imageEditor.getObjectProperties(this.selectedObj.id)
        if (!obj) return
        this.selectedObj.fill = filter.length ? { type: 'filter', filter } : 'transparent'

        await this.imageEditor.removeObject(this.selectedObj.id)
        this.imageEditor.addShape(this.shape, this.selectedObj)
      } else {
        this.imageEditor.setDrawingShape(this.shape, {
          fill: filter.length ? { type: 'filter', filter } : 'transparent',
          stroke: 'transparent',
          isRegular: false,
        })
      }
    },
    async handleCancel() {
      await this.imageEditor.clearObjects()
      this.filters = {
        pixelate: { pixelate: 5 },
        blur: { blur: 0.5 },
      }
    },
  },
}
</script>
