<template>
  <NoPermission v-if="!isPopup && !canNavigate($route)" />
  <div v-else>
    <div class="author-page__wrapper">
      <SettingsAuthorProfile
        :data="author"
        :show-title="false"
      />
      <SaveButton
        v-if="!isPopup"
        :saving="saving"
        :cancel="true"
        :permission="['canEditAuthor', author]"
        @cancel="handleCancel"
        @save="createUser"
      />
    </div>
  </div>
</template>

<script>
import SaveButton from '@/components/settings/SaveButton.vue'
import { authorDefaults, authorStatuses } from '@/codex-sdk/authors'
import SettingsAuthorProfile from '@/components/authors-profile/SettingsAuthorProfile.vue'
import gql from 'graphql-tag'
import NoPermission from '@/components/NoPermission.vue'
import { canNavigate } from '@/libs/acl/routeProtection'

export default {
  name: 'Edit',
  components: {
    NoPermission,
    SettingsAuthorProfile,
    SaveButton,
  },
  props: {
    isPopup: {
      type: Boolean,
      default: false,
    },
    authorId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      author: authorDefaults(),

      loading: false,
      saving: false,
    }
  },
  computed: {
    id() {
      return !this.isPopup ? this.$route.params.id : this.authorId
    },
  },
  mounted() {
    this.fetchAuthor()
  },
  methods: {
    canNavigate,
    async fetchAuthor() {
      if (this.id === 'create') return

      this.loading = true

      try {
        const { data: { author } } = await this.$apollo.query({
          query: gql`
            query Author ($id: String!) {
              author(id: $id) {
                biography
                byline
                createdAt
                email
                facebook
                firstName
                id
                imageId
                image {
                  url
                }
                instagram
                lastName
                linkedin
                status
                tiktok
                twitter
                telegram
                updatedAt
                url
                userId
                website
                externalId
              }
            }
          `,
          variables: {
            id: this.id,
          },
          fetchPolicy: 'network-only',
        })
        this.author = { ...author, status: author.status === 'PUBLISHED' ? authorStatuses.PUBLISHED : authorStatuses.UNPUBLISHED }
      } catch (e) {
        if (e.indexOf('404') > -1) {
          this.$router.replace({ name: 'authors' })
        }
      }

      this.loading = false
    },
    handleCancel() {
      this.$router.replace({ name: 'authors' })
    },
    async createUser() {
      this.loading = true
      this.saving = true

      if (this.id !== 'create') {
        await this.$store.dispatch('authors/updateAuthor', this.author).catch(() => {
          this.saving = false
          this.loading = false
        })
      } else {
        const author = await this.$store.dispatch('authors/createAuthor', this.author).catch(() => {
          this.saving = false
          this.loading = false
        })
        this.$router.replace({ name: 'authors-edit', params: { id: author.data.id } })
      }

      this.saving = false
      this.loading = false
    },
  },
}
</script>

<style lang="scss" scoped>

.author-page__wrapper {
  max-width: 900px;
  margin: 0 auto;
  margin-top: 40px;
}

.save-section {
  padding-bottom: 32px;
  border-bottom: 1px solid #E0E5EB;
  margin: 36px 0;
}

.save-action {
  width: 80px !important;
  height: 34px !important;
}
</style>
