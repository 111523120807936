export default {
  props: ['data', 'isLast'],
  data() {
    return {
      show: false,
      canClose: true,
    }
  },
  watch: {
    isLast: {
      handler(value) {
        if (value) {
          if (typeof this.$el?.querySelector == 'function') {
            // eslint-disable-next-line no-unused-expressions
            this.$el?.querySelector('.modal-content, .b-sidebar').focus()
          }
        }
      },
    },
  },
  mounted() {
    setTimeout(() => {
      this.show = true
    }, 100)
  },
  computed: {
    showPopup: {
      get() {
        return this.show
      },
      set(value) {
        if (!value && !this.canClose) return
        this.show = value
        if (!value) this.closePopup(false)
      },
    },
  },
  methods: {
    closePopup(data) {
      this.show = false
      this.$emit('close', data)
    },
  },
}
