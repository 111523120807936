import { mapWebhookEventType, EVENT_TYPES } from '@/codex-sdk/webhooks'
import i18n from '@/libs/i18n'
import WebhookEventComponent from './WebhookEvent.vue'

export default class WebhookEventFilter {
  static COMPONENT = WebhookEventComponent;

  static OPERATORS = {
    equals: 'eq',
  }

  static OPTIONS = {
    [EVENT_TYPES.INSERT]: i18n.t(mapWebhookEventType(EVENT_TYPES.INSERT)),
    [EVENT_TYPES.DELETE]: i18n.t(mapWebhookEventType(EVENT_TYPES.DELETE)),
    [EVENT_TYPES.UPDATE]: i18n.t(mapWebhookEventType(EVENT_TYPES.UPDATE)),
    [EVENT_TYPES.AUTO_SAVE]: i18n.t(mapWebhookEventType(EVENT_TYPES.AUTO_SAVE)),
    [EVENT_TYPES.PUBLISH]: i18n.t(mapWebhookEventType(EVENT_TYPES.PUBLISH)),
    [EVENT_TYPES.UNPUBLISH]: i18n.t(mapWebhookEventType(EVENT_TYPES.UNPUBLISH)),
    [EVENT_TYPES.SCHEDULE]: i18n.t(mapWebhookEventType(EVENT_TYPES.SCHEDULE)),
    [EVENT_TYPES.UNSCHEDULE]: i18n.t(mapWebhookEventType(EVENT_TYPES.UNSCHEDULE)),
    [EVENT_TYPES.ARCHIVE]: i18n.t(mapWebhookEventType(EVENT_TYPES.ARCHIVE)),
    [EVENT_TYPES.UPLOAD]: i18n.t(mapWebhookEventType(EVENT_TYPES.UPLOAD)),
  }

  static type = 'webhookEvent'

  operator = null;

  value = null;

  constructor({ operator = WebhookEventFilter.OPERATORS.equals, value } = {}) {
    this.operator = operator
    this.value = value
  }

  set({ operator = WebhookEventFilter.OPERATORS.equals, value }) {
    this.operator = operator
    this.value = value
  }

  getCount() {
    return this.value.length
  }

  getValueLabel() {
    return this.value.map(event => WebhookEventFilter.OPTIONS[event]).join(', ')
  }

  toLoad() {
    return {
      assets: this.value,
    }
  }

  asQueryParam() {
    return {
      operator: this.operator,
      value: this.value,
    }
  }

  asGraphQL() {
    return this.value
  }

  static validate({ value }) {
    if (!value || value.length === 0) {
      return 'Value is required'
    }

    return true
  }

  static fromQueryParam({ operator, value }) {
    return new WebhookEventFilter({
      operator,
      value,
    })
  }
}
