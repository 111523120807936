<template>
  <div class="overlay">
    <div class="overlay__container">
      <div class="overlay__container-left">
        <FullHeightLayout
          v-if="compareAsset"
          class="entry-edit__main-inner asset-merging-tool"
        >
          <MergingTool
            @close="cancelCompare"
            @mergeData="mergeData"
          />
        </FullHeightLayout>
        <div
          v-else
          class="curtain"
        >
          <div class="curtain__header">
            <div class="curtain__header-left">
              <span class="icon-text">
                <GjIcon
                  id="at-media-back-button"
                  size="24"
                  name="ArrowLeft"
                  class="white-icon"
                  @click.native="checkChanges"
                />
                <span
                  v-if="oldAssetValue"
                  class="ml-2 curtain__edit-title"
                >
                  {{ oldAssetValue.title || oldAssetValue.fileName || '' }}
                </span>
              </span>
            </div>
            <div
              v-if="asset"
              class="curtain__header-right"
            >
              <span
                v-if="asset.type === ASSET_TYPES.IMAGE"
                id="at-media-edit-button"
                v-permission="['canUploadAsset', { folderId: asset.folderId }]"
                class="white-icon ml-2"
                @click="openImageEditor(asset.id)"
              >
                <GjIcon
                  name="Crop"
                  size="24"
                />
              </span>
              <span
                v-if="asset.type === ASSET_TYPES.IMAGE || asset.type === ASSET_TYPES.FILE"
                id="at-media-download-button"
                class="white-icon ml-2"
                @click="downloadAsset(asset)"
              >
                <GjIcon
                  size="24"
                  name="Download"
                />
              </span>

              <span
                id="at-media-delete-button"
                v-permission="['canDeleteAsset', { id: asset.id }]"
                class="white-icon ml-2"
                @click="handleAssetDelete(asset)"
              >
                <GjIcon
                  size="24"
                  name="Delete"
                />
              </span>

            </div>
          </div>
          <div
            v-if="asset && !$apollo.loading"
            class="curtain__image"
            @click.self="checkChanges"
          >
            <img
              v-if="asset.type === ASSET_TYPES.IMAGE"
              :src="asset.url"
              alt=""
              class="curtain-image"
            >
            <iframe
              v-else-if="asset.type === ASSET_TYPES.VIDEO || asset.type === ASSET_TYPES.VIDEO_PLAYLIST || asset.type === ASSET_TYPES.AUDIO || asset.type === ASSET_TYPES.PODCAST"
              :src="asset.url"
              class="curtain-image"
              :style="height"
              allow="autoplay *; encrypted-media *; fullscreen *"
              frameborder="0"
              sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-storage-access-by-user-activation allow-top-navigation-by-user-activation"
            />
            <div
              v-else
              class="no-preview-available"
            >
              <b-card body-class="no-preview-body">
                <p>
                  {{ $t('assets.no-preview') }}
                </p>

                <b-button
                  v-if="asset.type === ASSET_TYPES.IMAGE || asset.type === ASSET_TYPES.FILE"
                  id="at-media-second-download-button"
                  variant="primary"
                  tag="label"
                  size="sm"
                  @click="downloadAsset(asset)"
                >
                  <GjIcon
                    name="Download"
                    size="24"
                  />
                  <span class="action-title">
                    {{ $t('general.download') }}
                  </span>
                </b-button>
              </b-card>
            </div>
          </div>
          <div
            v-if="$apollo.loading"
            class="curtain__image-loading"
          >
            <LoadingSpinner color="#ffffff" />
          </div>
          <div
            v-if="!asset && !$apollo.loading"
            class="curtain__image-404"
          >
            <div class="curtain__image-404-icon">
              <GjIcon name="IconparkFileFailedOne" />
            </div>
            <div>
              <p class="curtain__image-404-title">
                {{ $t('assets.not-found') }}
              </p>
              <p class="curtain__image-404-subtitle">
                {{ $t('assets.not-found-description') }}
              </p>
            </div>
          </div>
          <div
            v-if="asset"
            class="prev__next-arrows"
          >
            <div
              id="at-media-previous-button"
              :class="{'previous-visibility': !previousAsset}"
              class="previous"
              @click="setPreviousImage"
            >
              <GjIcon
                name="ArrowLeft"
                class="white-icon"
                size="32"
              />
            </div>
            <span class="mb-0 image__count">
              <span v-show="currentIndex">
                {{ currentIndex }}/{{ total | numberWithThousandsCommas }}
              </span>
            </span>
            <div
              id="at-media-next-button"
              :class="{'previous-visibility': !nextAsset}"
              class="next"
              @click="setNextImage"
            >
              <GjIcon
                name="ArrowRight"
                class="white-icon"
                size="32"
              />
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="asset"
        class="overlay__container-right"
      >
        <b-card
          class="overlay__card"
          body-class="overlay__card-body"
        >
          <b-tabs class="asset-edit__sidebar-tabs vertical-tabs">
            <b-tab name="at-assets-metadata-general">
              <template v-slot:title>
                <span
                  v-b-tooltip.viewport.left
                  :title="$t('assets.sidebar-tabs.general.title')"
                >
                  <GjIcon
                    size="20"
                    name="Settings"
                  />
                </span>
              </template>
              <h3 class="vertical-tabs__content-title">
                {{ $t('assets.metadata.metadata-title') }}
              </h3>
              <AssetsMetadataFields
                v-model="currentAsset"
                :disabled="disabled"
              />
              <b-card-footer footer-class="assets-overlay__card-footer">
                <div v-b-tooltip="{ title: $t('assets.asset-processing-tooltip'), interactive: false, disabled: !disabled }">
                  <b-button
                    id="at-media-save-button"
                    v-permission="['canEditAsset', { id: asset.id, status: asset.status }]"
                    variant="primary"
                    :disabled="disabled"
                    @click="updateAssetInfo(currentAsset)"
                  >
                    {{ $t('general.save') }}
                  </b-button>
                </div>
                <b-button
                  id="at-media-cancel-button"
                  variant="outline-secondary"
                  @click="checkChanges"
                >
                  {{ $t('general.cancel') }}
                </b-button>
              </b-card-footer>
            </b-tab>
            <b-tab name="at-assets-metadata-versions">
              <template v-slot:title>
                <span
                  v-b-tooltip.viewport.left
                  :title="$t('assets.sidebar-tabs.versions.title')"
                >
                  <GjIcon
                    size="20"
                    name="VersionHistory"
                  />
                </span>
              </template>
              <h3 class="vertical-tabs__content-title">
                {{ $t('assets.sidebar-tabs.versions.title') }}
              </h3>
              <Listing
                ref="versionListing"
                :asset-version="version"
                :comparing-id="comparingId"
                :asset="asset"
              />
            </b-tab>
          </b-tabs>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import {
  ASSET_TYPES, deleteAsset, updateAsset, readAsset, readAssetVersion, transformCompare, ASSET_STATUSES,
} from '@/codex-sdk/assets'
import gql from 'graphql-tag'
import { downloadAsset } from '@/utils/helpers'
import { ENTITY_TYPES } from '@/utils/constants'
import AssetsMetadataFields from '@/components/assets/AssetsMetadataFields.vue'
import LoadingSpinner from '@/components/LoadingSpinner.vue'
import MergingTool from '@/views/merging-tool/MergingTool.vue'
import FullHeightLayout from '@/../gjirafa-front/script/full-height-layout.vue'
import { cloneDeep, isEqual, omit } from 'lodash'
import Listing from './versions/Listing.vue'
import { ASSET_COMPARE_MODEL } from './constants'

export default {
  components: {
    LoadingSpinner,
    AssetsMetadataFields,
    Listing,
    MergingTool,
    FullHeightLayout,
  },
  props: {
    assetId: {
      type: String,
      default: '',
    },
    assets: {
      type: Array,
      default: () => [],
    },
    total: {
      type: Number,
      default: 0,
    },
  },
  apollo: {
    asset: {
      query: gql`
        query ($id: String!) {
          asset(id: $id) {
            id
            externalId
            folderId
            title
            fileName
            width
            height
            labels {
              id
              name
              color
            }
            tags
            source
            size
            status
            url(transformation: {format: HTML, resize: FILL, width: 1280})
            originalUrl: url
            path
            type
            caption
            alt
            author
            copyright
            contentType
            duration
            updatedAt
            expiresAt
            versionId
            customParameters
            updatedBy {
              id
              email
              firstName
              lastName
              imageUrl
            }
            createdAt
            createdBy {
              id
              email
              firstName
              lastName
              imageUrl
            }
          }
        }
      `,
      variables() {
        return {
          id: this.currentAssetId,
        }
      },
      fetchPolicy: 'network-only',
      result({ data }) {
        this.currentAsset = cloneDeep(data.asset)
        this.oldAssetValue = cloneDeep(data.asset)
        // request in api for versionid until it is added to gql
        if (!data?.asset?.versionId) {
          this.getAssetApi()
        } else {
          this.version.currentVersionId = data.asset.versionId
        }
      },
    },
  },
  inject: ['showConfirmPopup', 'showConfirmDeletePopup', 'showImageEditorPopup'],
  data() {
    return {
      downloadAsset,
      ASSET_TYPES,
      ASSET_STATUSES,
      currentIndex: null,
      previousAsset: null,
      nextAsset: null,
      fetchCount: 0,
      currentAssetId: this.assetId,
      hasUnsavedChanges: false,
      compareAsset: null,
      currentAsset: null,
      oldAssetValue: null,
      version: {
        currentVersionId: '',
        version: null,
      },
    }
  },
  computed: {
    height() {
      if (this.asset?.type === ASSET_TYPES.AUDIO) {
        return 'height: 410px;'
      }
      if (this.asset?.type === ASSET_TYPES.PODCAST) {
        return 'height: 410px;'
      }
      return ''
    },
    comparingId() {
      return this.compareAsset?.id
    },
    currentQueryParams() {
      return this.$route.query
    },
    currentRouteName() {
      return this.$route.name
    },
    disabled() {
      return this.currentAsset.status === ASSET_STATUSES.PROCESSING
    },
  },
  watch: {
    assetId: {
      handler() {
        this.$router.replace({ name: this.currentRouteName, query: { ...this.currentQueryParams, assetId: this.assetId } }).catch(err => err)
      },
      deep: true,
      immediate: true,
    },
    asset() {
      this.$router.replace({ name: this.currentRouteName, query: { ...this.currentQueryParams, assetId: this.asset.id } }).catch(err => err)
      this.getImageList()
    },
    currentAsset: {
      handler(value) {
        const toOmit = ['createdBy', 'updatedBy']
        const first = omit(JSON.parse(JSON.stringify(value)), toOmit)
        const second = omit(JSON.parse(JSON.stringify(this.oldAssetValue)), toOmit)
        transformCompare(first)
        transformCompare(second)
        this.hasUnsavedChanges = !isEqual(first, second)
      },
      deep: true,
    },
    assets() {
      if (this.asset) {
        this.getImageList()

        this.fetchCount = 0
      }
    },
  },
  beforeMount() {
    window.addEventListener('keydown', this.handleKeydown, null)
    this.$root.$on('compareAsset', this.setCompareAsset)
    this.$root.$on('assetVersionRestored', this.refetchAsset)
  },
  beforeDestroy() {
    window.removeEventListener('keydown', this.handleKeydown, null)
    this.$root.$off('compareAsset', this.setCompareAsset)
    this.$root.$off('assetVersionRestored', this.refetchAsset)
  },
  methods: {
    refetchAsset() {
      setTimeout(() => {
        this.$apollo.queries.asset.refetch()
      }, 1000)
    },
    mergeData(content) {
      this.updateAssetInfo({ ...this.currentAsset, ...content })
      this.cancelCompare()
    },
    cancelCompare() {
      this.compareAsset = null
    },
    async setCompareAsset(compareAsset) {
      try {
        {
          const { data } = await readAssetVersion({
            assetId: this.asset.id,
            versionId: this.asset.versionId,
          })
          const asset = {
            id: data.id,
            assetId: this.asset.id,
            content: {
              title: data.entity.title,
              labels: data.entity.labels,
              caption: data.entity.caption,
              alt: data.entity.alt,
              source: data.entity.source,
              author: data.entity.author,
              copyright: data.entity.copyright,
              tags: data.entity.tags,
              expiresAt: data.entity.expiresAt,
            },
          }
          await this.$store.dispatch('mergingTool/setFirstEntry', asset)
        }
        {
          const { data } = await readAssetVersion({
            assetId: this.asset.id,
            versionId: compareAsset.id,
          })
          const asset = {
            id: data.id,
            assetId: this.asset.id,
            system: {
              versionId: data.id,
            },
            content: {
              title: data.entity.title,
              labels: data.entity.labels,
              caption: data.entity.caption,
              alt: data.entity.alt,
              source: data.entity.source,
              author: data.entity.author,
              copyright: data.entity.copyright,
              tags: data.entity.tags,
              expiresAt: data.entity.expiresAt,
            },
          }
          await this.$store.dispatch('mergingTool/getSecondEntry', asset)
        }
        await this.$store.dispatch('mergingTool/getMergingModel', ASSET_COMPARE_MODEL)
        this.compareAsset = compareAsset
      } catch (e) {
        console.log(e)
      }
    },
    async getAssetApi() {
      const { data } = await readAsset(this.currentAssetId)
      this.asset.versionId = data.versionId
      this.version.currentVersionId = data.versionId
    },
    async openImageEditor(assetId) {
      if (assetId) {
        const image = await this.showImageEditorPopup({ imageId: assetId })
        if (image) {
          this.$root.$emit('editedImage', image)
        }
      }
    },
    async handleAssetDelete(asset) {
      try {
        const result = await this.showConfirmDeletePopup({
          items: [asset.title || asset.fileName],
          type: ENTITY_TYPES.ASSET,
        })
        if (result) {
          await deleteAsset(asset)
          this.$emit('finishedEditing')
          this.$root.$emit('assetsDelete', [asset])
          this.$router.replace({ name: this.currentRouteName }).catch(err => err)
        }
      } catch (e) {
        console.log(e)
      }
    },
    async updateAssetInfo(asset) {
      try {
        if (this.disabled) return
        await updateAsset({ ...asset })
        this.$emit('assetUpdated', asset)
        this.hasUnsavedChanges = false
        this.checkChanges()
      } catch (e) {
        console.log(e)
      }
    },
    async checkChanges() {
      const { assetId, ...queryParams } = this.currentQueryParams

      if (this.hasUnsavedChanges) {
        const answer = await this.showConfirmPopup({})
        if (answer) {
          this.$router.replace({ name: this.currentRouteName, query: queryParams })
          this.$emit('finishedEditing')
        }
      } else {
        this.$router.replace({ name: this.currentRouteName, query: queryParams })
        this.$emit('finishedEditing')
      }
    },
    async getImageList() { // A function used per me tregu qka ka previous edhe qka ka next qikjo foto
      const currentImgIndex = this.assets.findIndex(x => x.id === this.asset.id)

      if (currentImgIndex !== -1) {
        const previousImage = this.assets[currentImgIndex - 1]
        const nextImage = this.assets[currentImgIndex + 1]

        this.currentIndex = currentImgIndex + 1
        this.previousAsset = previousImage || null
        this.nextAsset = nextImage || null

        const assetsLength = this.assets.length
        const length = assetsLength > 10 ? assetsLength - 10 : null

        if (length && currentImgIndex > assetsLength - 10 && currentImgIndex < assetsLength && !this.fetchCount) {
          this.$emit('fetchMoreAssets')

          this.fetchCount = 1
        }
      } else {
        this.currentIndex = null
        this.previousAsset = null
        this.nextAsset = null
      }
    },
    async setNextImage() {
      let shouldContinue = true
      if (this.hasUnsavedChanges) {
        shouldContinue = await this.showConfirmPopup({})
      }
      if (this.nextAsset && shouldContinue) {
        this.currentAssetId = this.nextAsset.id
      }
    },
    async setPreviousImage() {
      let shouldContinue = true
      if (this.hasUnsavedChanges) {
        shouldContinue = await this.showConfirmPopup({})
      }
      if (this.previousAsset && shouldContinue) {
        this.currentAssetId = this.previousAsset.id
      }
    },
    isFocusedElementInput() {
      const { activeElement } = document
      const inputs = ['input', 'select', 'button', 'textarea']
      return !!(activeElement && inputs.indexOf(activeElement.tagName.toLowerCase()) !== -1)
    },
    handleKeydown(e) {
      if (this.isFocusedElementInput()) return

      if (e.keyCode === 37) {
        this.setPreviousImage()
        // Left
      } else if (e.keyCode === 39) {
        this.setNextImage()
        // Right
      } else if (e.keyCode === 27) {
        this.checkChanges()
      }
    },
    redirectTo(link) {
      window.open(link)
    },
  },
  async beforeRouteLeave(to, from, next) {
    if (this.hasUnsavedChanges) {
      const answer = await this.showConfirmPopup({})
      if (answer) {
        next()
      } else {
        next(false)
      }
    } else {
      next()
    }
  },
}
</script>
