import { truncate } from 'lodash'
import i18n from '@/libs/i18n'
import ReferenceListFilterComponent from './ReferenceList.vue'

export default class ReferenceListFilter {
  static COMPONENT = ReferenceListFilterComponent;

  static OPERATORS = {
    all: 'all',
    some: 'some',
    none: 'none',
    exists: 'exists',
    notExists: 'notExists',
  }

  static type = 'reference-list'

  static graphqlType = 'ReferenceListFieldFilter'

  operator = null;

  value = null;

  constructor({ operator = ReferenceListFilter.OPERATORS.some, value = [] } = {}) {
    this.operator = operator
    this.value = value
  }

  set({ operator = ReferenceListFilter.OPERATORS.some, value = [] }) {
    this.operator = operator
    this.value = value
  }

  getCount() {
    if (this.operator === ReferenceListFilter.OPERATORS.exists || this.operator === ReferenceListFilter.OPERATORS.notExists) {
      return 1
    }
    return this.value?.length
  }

  getValueLabel({ cache }) {
    if (this.operator === ReferenceListFilter.OPERATORS.exists || this.operator === ReferenceListFilter.OPERATORS.notExists) {
      return this.operator === ReferenceListFilter.OPERATORS.exists ? i18n.t('filters.is-not-empty') : i18n.t('filters.is-empty')
    }
    const entry = this.value.map(entryId => cache[entryId] || { id: entryId })
    return entry.map(m => truncate(m?.system?.title, { length: 15, separator: ' ' })).join(', ')
  }

  toLoad() {
    return {
      entries: this.value,
    }
  }

  asQueryParam() {
    if (this.operator === ReferenceListFilter.OPERATORS.exists || this.operator === ReferenceListFilter.OPERATORS.notExists) {
      return {
        operator: this.operator,
      }
    }
    return {
      operator: this.operator,
      value: this.value,
    }
  }

  asGraphQL() {
    switch (this.operator) {
      case ReferenceListFilter.OPERATORS.all:
        return {
          all: this.value,
        }
      case ReferenceListFilter.OPERATORS.some:
        return {
          some: this.value,
        }
      case ReferenceListFilter.OPERATORS.none:
        return {
          none: this.value,
        }
      case ReferenceListFilter.OPERATORS.exists:
        return {
          exists: true,
        }
      case ReferenceListFilter.OPERATORS.notExists:
        return {
          exists: false,
        }
      default:
        return null
    }
  }

  asGraphQLDynamicList() {
    if (this.asGraphQL()) {
      return {
        ...this.asGraphQL(),
        _t: ReferenceListFilter.graphqlType,
      }
    }
    return null
  }

  static validate({ operator, value }) {
    if (!operator) {
      return 'Operator is required'
    }

    if (operator === ReferenceListFilter.OPERATORS.exists || operator === ReferenceListFilter.OPERATORS.notExists) {
      return true
    }
    if (!value || value.length === 0) {
      return 'Value is required'
    }

    return true
  }

  static fromQueryParam({ operator, value }) {
    return new ReferenceListFilter({
      operator,
      value,
    })
  }

  static shouldApplyPredefinedValue(filter) {
    const forOperators = [ReferenceListFilter.OPERATORS.all, ReferenceListFilter.OPERATORS.some]
    return forOperators.includes(filter.operator)
  }
}
