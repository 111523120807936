<template>
  <b-modal
    id="folder-popup"
    v-model="showPopup"
    class="folder-popup"
    :title="folder ? $t('assets.folder-popup.edit-title') : $t('assets.folder-popup.create-title')"
    :ok-title="folder ? $t('general.save'): $t('general.add')"
    ok-variant="primary"
    :cancel-title="$t('general.cancel')"
    cancel-variant="outline-dark"
    size="md"
    no-close-on-backdrop
    :static="true"
    @ok="proceed"
    @cancel="cancel"
  >
    <b-form-group
      :label="$t('assets.folders.edit-popup.name')"
      label-for="at-folder-name-modal"
    >
      <b-form-input
        id="at-folder-name-modal"
        v-model.trim="name"
        :state="error"
        trim
        autocomplete="off"
        @focus="error = null"
      />
      <b-form-invalid-feedback>
        {{ $t('assets.folders.edit-popup.error-message') }}
      </b-form-invalid-feedback>
    </b-form-group>
  </b-modal>
</template>

<script>
import { createFolder, updateFolder } from '@/codex-sdk/folders'
import PopupsMixin from '../PopupsMixin'

export default {
  name: 'FolderPopup',
  mixins: [PopupsMixin],
  data() {
    return {
      error: null,
      name: this.data?.folder?.name || '',
    }
  },
  computed: {
    okVariant() {
      return this.data?.okVariant || 'primary'
    },
    folder() {
      return this.data?.folder
    },
    parent() {
      return this.data?.parent
    },
  },
  methods: {
    async proceed() {
      this.canClose = false
      try {
        if (this.name === '') {
          this.error = false
          return
        }

        if (this.folder) {
          const { id, parentId } = this.folder

          const { data } = await updateFolder({
            name: this.name, id, parentId,
          })
          this.closePopup(data)
        } else {
          const newFolder = {
            parentId: this.parent?.id,
            name: this.name,
          }
          const { data } = await createFolder(newFolder, null)
          this.closePopup(data)
        }
      } catch (error) {
        console.log(error)
      }
    },
    cancel() {
      this.closePopup(null)
    },
  },
}
</script>

<style lang="scss" >
#at-confirm-popup {
  .modal-body {
    color: #052D61;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
  }
}
</style>
