<template>
  <div class="toolbar-right__tab-container">
    <template v-if="!loaded">
      <div class="d-flex justify-content-center">
        <b-spinner
          :label="$t('general.loading')"
          variant="primary"
        />
      </div>
    </template>
    <template v-else>
      <h6 class="toolbar-title">
        {{ $t('assets.edit-image.shapes.title') }}
      </h6>

      <ShapeSelector
        v-model="shape"
      />

      <b-form-group :label="$t('assets.edit-image.shapes.default-colors')">
        <div class="shapes-color">
          <div
            v-for="c in colors"
            :key="c"
            class="settings__label-color"
            :class="{'settings__label-color-selected': c === color}"
            :style="`background-color: ${c}`"
            @click="color = c"
          >
            <div
              v-if="c === color"
              class="settings__label-color-tick"
            >
              <GjIcon
                style="fill: white"
                name="Check"
              />
            </div>
          </div>
        </div>
      </b-form-group>

      <b-form-group :label="$t('assets.edit-image.shapes.custom-colors')">
        <b-form-input
          v-model="color"
          type="color"
        />
      </b-form-group>

    </template>
    <p
      class="toolbar-reset__button"
      @click="handleCancel"
    >
      {{ $t('assets.edit-image.shapes.reset-label') }}
    </p>
  </div>
</template>

<script>
import { debounce } from 'lodash'
import ShapeSelector from '@/components/assets/image-editor/ShapeSelector.vue'
import { IMAGE_EDITOR_TABS } from '@/components/image-editor/constants'

export default {
  name: 'Shapes',
  components: { ShapeSelector },
  props: ['imageEditor', 'reference', 'loaded', 'tabIndex'],
  data() {
    return {
      shape: 'rect',
      color: '#1A1A1A',
      colors: ['#1A1A1A', '#E34850', '#FFAB00', '#2291F2', '#B148E3', '#E3489C', '#2275F2', '#00E0FF', '#052D61', '#667C99', '6E6E6E'],
      selectedObj: null,
      dSetFilter: debounce(this.setFilter, 100),
    }
  },
  watch: {
    imageEditor(v) {
      if (v) {
        v.on('objectActivated', props => {
          this.selectedObj = props

          let color = props.fill?.color?.color || props.fill?.color
          color = color === 'transparent' ? '#1A1A1A' : color

          if (color) {
            this.color = color

            this.imageEditor.setDrawingShape(this.shape, {
              fill: this.color,
              stroke: this.color,
              isRegular: false,
            })
          }
        })
        v.on('objectAdded', props => { this.selectedObj = props })
        v.on('objectMoved', props => { this.selectedObj = props })
        v.on('objectScaled', props => { this.selectedObj = props })
      }
    },
    color() {
      this.dSetFilter()
    },
    async tabIndex() {
      if (this.tabIndex === IMAGE_EDITOR_TABS.SHAPES) {
        if (this.imageEditor.getDrawingMode() !== 'SHAPE') {
          this.imageEditor.stopDrawingMode()
          this.imageEditor.startDrawingMode('SHAPE')
        }
        this.imageEditor.deactivateAll()
        this.selectedObj = null
        await this.setFilter()
      }
    },
    async shape() {
      if (this.imageEditor.getDrawingMode() !== 'SHAPE') {
        this.imageEditor.stopDrawingMode()
        this.imageEditor.startDrawingMode('SHAPE')
      }
      this.imageEditor.deactivateAll()
      this.selectedObj = null
      await this.setFilter()
    },
  },
  methods: {
    async setFilter() {
      if (this.selectedObj) {
        this.imageEditor.setObjectProperties(this.selectedObj.id, {
          fill: this.color,
          stroke: this.color,
        })
      }

      this.imageEditor.setDrawingShape(this.shape, {
        fill: this.color,
        stroke: this.color,
        isRegular: false,
      })
    },
    async handleCancel() {
      await this.imageEditor.clearObjects()
    },
  },
}
</script>
<style lang="scss">
.shapes-color {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}
</style>
