<template>
  <b-button-group>
    <b-button
      v-if="shouldDisplayStyle('bold')"
      v-b-tooltip.hover.html.top="getTooltipData('bold', ['Ctrl', 'B'])"
      variant="flat-dark"
      :class="{ 'active': active.bold }"
      @click="commands.bold"
    >
      <GjIcon
        name="FormatBold"
        size="18"
      />
    </b-button>
    <b-button
      v-if="shouldDisplayStyle('italic')"
      v-b-tooltip.hover.html.top="getTooltipData('italic', ['Ctrl', 'I'])"
      variant="flat-dark"
      :class="{ 'active': active.italic }"
      @click="commands.italic"
    >
      <GjIcon
        name="FormatItalic"
        size="18"
      />
    </b-button>
    <b-button
      v-if="shouldDisplayStyle('underline')"
      v-b-tooltip.hover.html.top="getTooltipData('underline', ['Ctrl', 'U'])"
      variant="flat-dark"
      :class="{ 'active': active.underline }"
      @click="commands.underline"
    >
      <GjIcon
        name="FormatUnderline"
        size="18"
      />
    </b-button>
    <b-button
      v-if="shouldDisplayStyle('strikethrough')"
      v-b-tooltip.hover.html.top="getTooltipData('strike', ['Ctrl', 'D'])"
      variant="flat-dark"
      :class="{ 'active': active.strike }"
      @click="commands.strike"
    >
      <GjIcon
        name="FormatStrikethrough"
        size="18"
      />
    </b-button>
    <b-button
      v-if="toolbarOptions && toolbarOptions.scripts && toolbarOptions.scripts.isEnabled"
      v-b-tooltip.hover.html.top="getTooltipData('subscript', ['Ctrl', ','])"
      variant="flat-dark"
      :class="{ 'active': active.subscript }"
      @click="commands.subscript"
    >
      X<sub>2</sub>
    </b-button>
    <b-button
      v-if="toolbarOptions && toolbarOptions.scripts && toolbarOptions.scripts.isEnabled"
      v-b-tooltip.hover.html.top="getTooltipData('superscript', ['Ctrl', '.'])"
      variant="flat-dark"
      :class="{ 'active': active.superscript }"
      @click="commands.superscript"
    >
      X<sup>2</sup>
    </b-button>
    <b-button
      v-if="includeHyperLink && toolbarOptions && toolbarOptions.links && toolbarOptions.links.isEnabled"
      v-b-tooltip.hover.html.top="getTooltipData('toggle-link')"
      variant="flat-dark"
      id="hyper-link-toggle-button"
      :class="{ 'active': active.link }"
      @click="() => toggleLinkPopup()"
    >
      <GjIcon name="Hyperlink_fill" />
    </b-button>
    <b-button
      v-if="toolbarOptions && toolbarOptions.premium && toolbarOptions.premium.isEnabled"
      v-b-tooltip.hover.html.top="getTooltipData('premium')"
      variant="flat-dark"
      :class="{ 'active': active.premium }"
      @click="commands.premium"
    >
      <GjIcon name="IconparkDiamonds" />
    </b-button>
    <ColorPickerPanel
      v-if="shouldDisplayStyle('colorStyle')"
      :editor="editor"
      :commands="commands"
      :is-active="isActive"
    />
  </b-button-group>
</template>

<script>
import { throttle } from 'lodash'
import ColorPickerPanel from './ColorPickerPanel.vue'

export default {
  inject: ['getTooltipData', 'toolbarOptions'],
  components: {
    ColorPickerPanel,
  },
  props: {
    commands: Object,
    isActive: Object,
    toggleLinkPopup: Function,
    includeHyperLink: Boolean,
    editor: Object,
  },
  data() {
    return {
      active: {
        bold: false,
        italic: false,
        underline: false,
        strike: false,
        subscript: false,
        superscript: false,
        link: false,
        premium: false,
      },
      updateActiveStatusesThrottled: throttle(() => this.updateActiveStatuses(), 100),
    }
  },
  watch: {
    'editor.selection': 'updateActiveStatusesThrottled',
  },
  /**
   * ToDo: Maybe move this whole active logic to the editor itself
   */
  mounted() {
    this.updateActiveStatuses()
    if (this.editor) {
      this.editor.on('update', this.updateActiveStatusesThrottled)
    }
  },
  beforeDestroy() {
    if (this.editor) {
      this.editor.off('update', this.updateActiveStatusesThrottled)
    }
  },
  methods: {
    shouldDisplayStyle(style) {
      return this.toolbarOptions?.fontStyle?.isEnabled && this.toolbarOptions?.fontStyle?.[style]
    },
    updateActiveStatuses() {
      this.active.bold = this.isActive.bold()
      this.active.italic = this.isActive.italic()
      this.active.underline = this.isActive.underline()
      this.active.strike = this.isActive.strike()
      this.active.subscript = this.isActive.subscript()
      this.active.superscript = this.isActive.superscript()
      this.active.link = this.isActive.link()
      this.active.premium = this.isActive.premium()
    },
  },
}
</script>
