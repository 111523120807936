import { apiAxios as axios } from '../libs/axios'
import { tc } from './config'

export const GRAVITY = Object.freeze({
  NORTH: 1,
  SOUTH: 2,
  EAST: 3,
  WEST: 4,
  CENTER: 5,
  NORTHEAST: 6,
  NORTHWEST: 7,
  SOUTHEAST: 8,
  SOUTHWEST: 9,
})

export const GRAVITY_STRING = Object.freeze({
  NORTH: 'NORTH',
  SOUTH: 'SOUTH',
  EAST: 'EAST',
  WEST: 'WEST',
  CENTER: 'CENTER',
  NORTHEAST: 'NORTH_EAST',
  NORTHWEST: 'NORTH_WEST',
  SOUTHEAST: 'SOUTH_EAST',
  SOUTHWEST: 'SOUTH_WEST',
})

export const RESIZE = Object.freeze({
  FIT: 1,
  FILL: 2,
  AUTO: 3,
})

export const RESIZE_STRING = Object.freeze({
  FIT: 'FIT',
  FILL: 'FILL',
  AUTO: 'AUTO',
})

export function mapPresetGravity(status) {
  const statuses = {
    [GRAVITY.NORTH]: 'presets.gravity.north',
    [GRAVITY_STRING.NORTH]: 'presets.gravity.north',
    [GRAVITY.SOUTH]: 'presets.gravity.south',
    [GRAVITY_STRING.SOUTH]: 'presets.gravity.south',
    [GRAVITY.EAST]: 'presets.gravity.east',
    [GRAVITY_STRING.EAST]: 'presets.gravity.east',
    [GRAVITY.WEST]: 'presets.gravity.west',
    [GRAVITY_STRING.WEST]: 'presets.gravity.west',
    [GRAVITY.CENTER]: 'presets.gravity.center',
    [GRAVITY_STRING.CENTER]: 'presets.gravity.center',
    [GRAVITY.NORTHEAST]: 'presets.gravity.northeast',
    [GRAVITY_STRING.NORTHEAST]: 'presets.gravity.northeast',
    [GRAVITY.NORTHWEST]: 'presets.gravity.northwest',
    [GRAVITY_STRING.NORTHWEST]: 'presets.gravity.northwest',
    [GRAVITY.SOUTHEAST]: 'presets.gravity.southeast',
    [GRAVITY_STRING.SOUTHEAST]: 'presets.gravity.southeast',
    [GRAVITY.SOUTHWEST]: 'presets.gravity.southwest',
    [GRAVITY_STRING.SOUTHWEST]: 'presets.gravity.southwest',
  }
  return statuses[status] || 'presets.gravity.north'
}

export function mapPresetResize(status) {
  const statuses = {
    [RESIZE.FIT]: 'presets.resize.fit',
    [RESIZE_STRING.FIT]: 'presets.resize.fit',
    [RESIZE.FILL]: 'presets.resize.fill',
    [RESIZE_STRING.FILL]: 'presets.resize.fill',
    [RESIZE.AUTO]: 'presets.resize.auto',
    [RESIZE_STRING.AUTO]: 'presets.resize.auto',
  }
  return statuses[status] || 'presets.resize.auto'
}

export function presetsDefault(preset = {}) {
  return {
    alias: '',
    name: '',
    width: 0,
    height: 0,
    resize: RESIZE.FIT,
    gravity: GRAVITY.NORTH,
    sites: [],
    ...preset,
  }
}

export function readPreset(id) {
  return axios.get(`/{organization}/presets/${id}`)
}

export function createPresets(preset, messages) {
  return tc(axios.post('/{organization}/presets', preset), {
    successTitle: 'sdk.success-title',
    successMessage: 'sdk.presets.create-successful',
    failTitle: 'sdk.presets.create-failed',
    catchError: false,
    ...messages,
  })
}

export function updatePreset(preset, invalidateCache = false, messages) {
  return tc(axios.put(`/{organization}/presets/${preset.id}`, preset, {
    params: { invalidateCache },
  }), {
    successTitle: 'sdk.success-title',
    successMessage: 'sdk.presets.update-successful',
    failTitle: 'sdk.presets.update-failed',
    catchError: false,
    ...messages,
  })
}

export function deletePreset(id, messages = {}) {
  return tc(axios.delete(`/{organization}/presets/${id}`), {
    successTitle: 'sdk.success-title',
    successMessage: 'sdk.presets.delete-successful',
    failTitle: 'sdk.presets.delete-failed',
    catchError: false,
    ...messages,
  })
}
