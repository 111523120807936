<template>
  <div
    v-if="scheduledVersions.length"
    class="scheduled-versions"
  >
    <app-collapse>
      <app-collapse-item>
        <template #header>
          <span class="scheduled-versions-header">
            {{ $t('entries.scheduled-versions.title', { count: scheduledVersions.length}) }}
          </span>
        </template>
        <div class="scheduled-versions-container">
          <div
            v-for="v in scheduledVersions"
            :key="v.id"
            class="scheduled-version"
          >
            <div style="display: flex; justify-content: space-between">
              <p class="scheduled-version-name">
                {{ v.createdAt | formatDateTime }}
              </p>
              <b-dropdown
                right
                variant="flat-primary"
                class="dropdown-icon-wrapper scheduled-dropdown"
                size="sm"
              >
                <template #button-content>
                  <GjIcon name="Moreoptions" />
                </template>
                <b-dropdown-item @click="deleteScheduledEntryVersion(v)">
                  {{ $t('entries.scheduled-versions.delete') }}
                </b-dropdown-item>
              </b-dropdown>
            </div>

            <StatusChip
              :entry="v.publishScheduledDate ? publishedConfig : unpublishConfig"
              :no-text="false"
              :with-background="true"
              :scheduled-versions="[v]"
              is-schedule-status
            />
          </div>
        </div>
      </app-collapse-item>
    </app-collapse>
  </div>
</template>

<script>
import { deleteScheduleEntryVersion, ENTRY_STATUSES } from '@/codex-sdk/entries'
import StatusChip from '@/views/entries/components/StatusChip.vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import { ENTITY_TYPES } from '@/utils/constants'

export default {
  name: 'ScheduledVersions',
  components: { AppCollapseItem, AppCollapse, StatusChip },
  inject: ['showConfirmDeletePopup'],
  props: {
    entryId: {
      type: String,
      required: true,
    },
    modelAlias: {
      type: String,
      required: true,
    },
    siteId: {
      type: String,
      required: true,
    },
    versions: {
      type: Array,
      default: () => [],
    },
    scheduledVersions: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      publishedConfig: { id: this.entryId, system: { status: ENTRY_STATUSES.PUBLISHED } },
      unpublishConfig: { id: this.entryId, system: { status: ENTRY_STATUSES.UNPUBLISHED } },
    }
  },
  methods: {
    async deleteScheduledEntryVersion(scheduledEntryVersion) {
      try {
        const answer = await this.showConfirmDeletePopup({
          items: [this.$options.filters.formatDateTime(scheduledEntryVersion.createdAt)],
          type: ENTITY_TYPES.ENTRY_SCHEDULE_VERSION,
        })
        if (!answer) return
        await deleteScheduleEntryVersion({
          id: scheduledEntryVersion.id,
          entryId: this.entryId,
          versionId: scheduledEntryVersion.versionId,
          siteId: this.siteId,
          model: this.modelAlias,
        })
        this.refetch()
      } catch (e) {
        console.log(e)
      }
    },
    refetch() {
      this.$emit('refetchScheduledVersions')
    },
  },
}
</script>

<style lang="scss">
.scheduled-versions {
  .scheduled-versions-header {
    font-size: 12px;
    line-height: 14px;
    font-weight: 500;
    color: #052D61;
  }

  .scheduled-versions-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: 8px;
  }

  .scheduled-version {
    background-color: #F7FBFC;
    border-radius: 6px;
    padding: 8px;
  }

  .scheduled-version-name {
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: #052D61;
  }

  .scheduled-dropdown .btn {
    height: 20px;
    padding: 0;

    &:hover, &:focus, &:active {
      background: none !important;
    }
  }

  .collapse-default {
    .card {
      box-shadow: none;
      border: none;
    }

    .card-header {
      border: none !important;
      padding: 0 !important;

      &:after {
        right: 0;
      }
    }

    div.card-body {
      padding: 0 !important;
    }
  }
}

</style>
