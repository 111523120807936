<template>
  <v-select
    v-model="inputVal"
    :disabled="disabled"
    :multiple="multiple"
    :options="tags"
    :placeholder="placeholder"
    :taggable="taggable"
    :close-on-select="false"
    class="tags-selector"
    @search="dFetchOptions"
    @search:focus="firstSearch"
  >
    <template #no-options="">
      {{ noOptionsText }}
    </template>
  </v-select>
</template>

<script>
import { readTags, tagTypes } from '@/codex-sdk/tags'
import { readAssetsTags } from '@/codex-sdk/assets'
import { debounce } from 'lodash'

export const types = tagTypes

export default {
  props: ['value', 'placeholder', 'multiple', 'taggable', 'type', 'disabled'],
  data() {
    return {
      dFetchOptions: debounce(this.fetchOptions, 500),
      tags: [],
      search: null,
    }
  },
  computed: {
    inputVal: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
    noOptionsText() {
      return !this.search || this.search.trim().length === 0 ? this.$t('content.tags.selector.empty-search') : this.$t('content.tags.selector.no-results', { search: this.search })
    },
  },
  methods: {
    firstSearch() {
      if (this.search === null) {
        this.fetchOptions('')
      }
    },
    async fetchOptions(search, loading = () => {}) {
      loading(true)
      this.search = search
      if (this.type === tagTypes.ASSET) {
        const { data } = await readAssetsTags({ search })
        this.tags = data.map(i => i.name)
      } else {
        const { data } = await readTags({ search, type: this.type })
        this.tags = data.map(i => i.name)
      }
      loading(false)
    },
  },
}
</script>

<style lang="scss">
.tags-selector span.vs__selected{
  overflow: hidden;
  word-wrap: break-word;
  text-overflow: ellipsis;
  word-break: break-word;
}
</style>
