<template>
  <transition>
    <b-sidebar
      id="label__sidebar"
      v-model="showPopup"
      :title="!getLabel && !data.id ? $t('popups.label.createTitle') : $t('popups.label.editTitle')"
      class="c-sidebar c-sidebar--md"
      right
      shadow
    >
      <b-form-group :label="$t('labels.edit.name')">
        <b-form-input v-model="label.name" />
      </b-form-group>
      <b-form-group :label="$t('labels.edit.entities')">
        <v-select
          v-model="entities"
          :reduce="option => option.value"
          :options="options"
          deselect-from-dropdown
          multiple
          :close-on-select="false"
        />
      </b-form-group>
      <b-form-group
        v-if="includeSiteRestrictions"
        :label="$t('labels.edit.site')"
      >
        <SitesDropdownSelect
          v-model="label.restrictions.sites.allowed"
          :clearable="true"
        />
      </b-form-group>

      <b-form-group :label="$t('labels.edit.description')">
        <b-form-textarea v-model="label.description" />
      </b-form-group>

      <b-form-group :label="$t('labels.edit.color')">
        <div class="settings__label-colors">
          <div
            v-for="(color, idx) in colors"
            :key="idx"
            class="settings__label-color"
            :class="{'settings__label-color-selected': color === label.color}"
            :style="`background-color: ${color}`"
            @click="setSelectedColor(color)"
          >
            <div
              v-if="color === label.color"
              class="settings__label-color-tick"
            >
              <GjIcon
                style="fill: white"
                name="Check"
              />
            </div>
          </div>
        </div>
      </b-form-group>
      <template #footer>
        <b-button
          id="at-label-cancel-btn"
          variant="outline-secondary"
          @click="handleCancel"
        >
          {{ $t('general.cancel') }}
        </b-button>
        <b-button
          id="at-label-create-btn"
          variant="primary"
          @click="handleOk"
        >
          {{ label.id ? $t('general.save') : $t('general.create') }}
        </b-button>
      </template>
    </b-sidebar>
  </transition>
</template>

<script>
import PopupsMixin from '@/components/popups/PopupsMixin'
import {
  createLabel, labelDefaults, readLabel, types, updateLabel, LABEL_ENTITIES, mapLabelEntityLabel,
} from '@/codex-sdk/labels'
import { cloneDeep } from 'lodash'
import SitesDropdownSelect from '@/components/SitesDropdownSelect.vue'

export default {
  name: 'LabelsPopup',
  components: { SitesDropdownSelect },
  mixins: [PopupsMixin],
  data() {
    return {
      label: labelDefaults(),
      options: [
        { label: mapLabelEntityLabel(LABEL_ENTITIES.ALL), value: LABEL_ENTITIES.ALL },
        { label: mapLabelEntityLabel(LABEL_ENTITIES.ENTRY), value: LABEL_ENTITIES.ENTRY },
        { label: mapLabelEntityLabel(LABEL_ENTITIES.ASSET), value: LABEL_ENTITIES.ASSET },
      ],
      types,
      colors: ['#36B37E', '#E34850', '#FFAB00', '#2291F2', '#B148E3', '#E3489C', '#2275F2', '#00E0FF', '#052D61', '#667C99'],
    }
  },
  computed: {
    includeSiteRestrictions() {
      return this.label?.entities?.some(v => v === LABEL_ENTITIES.ENTRY || v === LABEL_ENTITIES.ALL) || false
    },
    getLabel() {
      return { ...this.label, ...(this.data?.label || {}) }
    },
    entities: {
      get() {
        return this.label.entities
      },
      set(v) {
        if (v[v.length - 1] === LABEL_ENTITIES.ALL || v.length === 0) {
          this.label.entities = [LABEL_ENTITIES.ALL]
        } else {
          this.label.entities = v.filter(e => e !== LABEL_ENTITIES.ALL)
        }
      },
    },
  },
  watch: {
    'label.restrictions.sites.allowed': {
      handler() {
        this.label.restrictions.sites.allowAll = this.label.restrictions.sites.allowed.length === 0
      },
      deep: true,
    },
  },
  mounted() {
    this.setLabel()
  },
  methods: {
    setLabel() {
      if (this.data.id) {
        readLabel(this.data.id).then(({ data }) => {
          this.label = data
        })
      } else if (this.getLabel) {
        this.label = cloneDeep(this.getLabel)
      } else {
        this.label = labelDefaults()
      }
    },
    handleCancel() {
      this.showPopup = false
      this.label = labelDefaults()
    },
    async handleOk() {
      try {
        if (!this.label.id) {
          this.label.type = this.types.PRIVATE
          const { data } = await createLabel(this.label)
          this.label.id = data.id
          if (this.label.id) {
            this.closePopup(this.label)
          }
        } else {
          this.label.type = this.types.PRIVATE
          const { data } = await updateLabel(this.label)
          this.closePopup(data)
        }
      } catch (e) {
        console.log(e)
      }
    },
    setSelectedColor(color) {
      this.label.color = color
    },
  },
}
</script>

<style lang="scss">
</style>
