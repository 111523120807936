<template>
  <div
    v-permission="['canEditList']"
    :class="classes"
  >
    <b-dropdown
      ref="dropdown"
      variant="outline"
      @show="refetchLists"
    >
      <template #button-content>
        <slot name="button" />
      </template>
      <div
        v-if="lists && lists.items"
        class="entries-list-listing"
      >
        <b-dropdown-item
          v-for="element in filteredLists"
          :key="element.id"
          :title="element.name"
          class="entries-lists--element"
          @click="addToEntriesList(element)"
        >
          <div class="entries-lists--element__icon">
            <GjIcon
              name="ListAlt"
              size="18"
            />
          </div>
          <span>{{ element.name }}</span>
        </b-dropdown-item>
      </div>
    </b-dropdown>
  </div>
</template>

<script>
import gql from 'graphql-tag'

export default {
  name: 'AddToEntriesLists',
  inject: ['showAddToEntriesListsPopup'],
  props: {
    entryId: {
      type: String,
      required: true,
    },
    classes: {
      type: String,
      default: '',
    },
    modelAlias: {
      type: String,
      default: '',
    },
  },
  apollo: {
    lists: {
      query: gql`
        query ($limit: Int, $offset: Int, $siteId: String, $query: String) {
          lists: listCollection (limit: $limit, offset: $offset, where: {
            siteId: {
              eq: $siteId
            }
            type: {
              in: [LEGACY, ENTRY]
            }
            query: $query
          }, order: {
            createdAt: DESC
          }) {
            items {
              id
              name
              configurations {
                models {
                  allowAll
                  allowed
                }
              }
            }
            limit
            offset
            total
          }
        }
      `,
      fetchPolicy: 'cache-and-network',
      variables() {
        return {
          offset: 0,
          limit: Number(10000),
          siteId: this.site?.id,
          query: '',
        }
      },
      result({ data }) {
        if (!data) return {}
        data.lists.items.forEach(item => {
          if (!item.configurations) {
            item.configurations = {
              models: {
                allowAll: true,
                allowed: [],
              },
            }
          }
        })
        return data
      },
    },
  },
  computed: {
    site() {
      return this.$store.state.general.currentSite
    },
    // remove lists that are not allowed for this model
    filteredLists() {
      if (!this.lists?.items) return []
      return this.lists.items.filter(list => {
        if (!list.configurations) return false
        if (list.configurations.models.allowAll) return true
        return list.configurations.models.allowed.includes(this.modelAlias)
      })
    },
  },
  methods: {
    showDropdown() {
      this.$refs.dropdown.show()
    },
    refetchLists() {
      if (this.$apollo.queries?.lists) {
        this.$apollo.queries.lists.refetch()
      }
    },
    addToEntriesList(entriesList) {
      this.showAddToEntriesListsPopup({
        id: entriesList.id,
        entryId: this.entryId,
      })
    },
  },
}
</script>
