<template>
  <b-modal
    id="entry-edit__save_modal"
    ref="saveModal"
    v-model="showModal"
    centered
    :title="$t('entries.save-button.save-modal.title')"
    @cancel="cancelSelect"
    @close="cancelSelect"
  >
    <template #modal-footer>
      <b-button
        variant="outline-dark"
        @click="cancelSelect"
      >
        {{ $t('entries.save-button.save-modal.cancel-title') }}
      </b-button>
      <b-button
        variant="primary"
        @click="proceedSelect(false)"
      >
        {{ $t('entries.save-button.save-modal.ok-title') }}
      </b-button>
    </template>
    <b-form-group>
      <b-form-radio
        v-model="publishDate"
        name="publish-date"
        value="current"
        class="entries-save-modal__radio"
      >
        {{ $t('entries.save-button.save-modal.current-date') }}
      </b-form-radio>
      <b-form-radio
        v-model="publishDate"
        name="publish-date"
        value="today"
        class="entries-save-modal__radio"
      >
        {{ $t('entries.save-button.save-modal.today') }}
      </b-form-radio>
      <b-form-radio
        v-if="showCustomDate"
        v-model="publishDate"
        name="publish-date"
        value="custom"
        class="entries-save-modal__radio"
      >
        {{ $t('entries.save-button.save-modal.custom-date') }}
      </b-form-radio>
      <flat-pickr
        v-if="publishDate === 'custom'"
        v-model="customDate"
        size="sm"
        class="custom-date"
        :config="datePickrConfig"
      />
    </b-form-group>
  </b-modal>
</template>

<script>
import moment from 'moment'
import { ENTRY_STATUSES } from '@/codex-sdk/entries'
import { CMI_ORGANIZATIONS } from '@/utils/constants'

export default {
  name: 'SaveModal',
  props: {
    value: Boolean,
  },
  data() {
    return {
      ENTRY_STATUSES,
      publishDate: 'current',
      customDate_: new Date(),
      datePickrConfig: {
        maxDate: new Date(),
        enableTime: true,
      },
    }
  },
  computed: {
    showModal: {
      get() { return this.value },
      set(v) { this.$emit('input', v) },
    },
    customDate: {
      get() {
        return new Date(this.customDate_)
      },
      set(v) {
        this.customDate_ = v?.length ? moment(v).utc().toISOString() : ''
      },
    },
    showCustomDate() {
      return !CMI_ORGANIZATIONS.includes(this.$store.state.general.currentOrganization.alias)
    },
  },
  methods: {
    proceedSelect(publishUnpublishedEntries = false) {
      if (this.publishDate === 'today') {
        this.$emit('republishDate', moment().toISOString(), publishUnpublishedEntries)
      } else if (this.publishDate === 'current') {
        this.$emit('republishDate', null, publishUnpublishedEntries)
      } else {
        this.$emit('republishDate', moment(this.customDate).toISOString(), publishUnpublishedEntries)
      }
    },
    cancelSelect() {
      this.publishDate = 'current'
      this.$emit('cancelSelect')
      if (this.$refs.saveModal) {
        this.$refs.saveModal.hide()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.entries-save-modal__radio {
  margin-bottom: 10px;
}
#entry-edit__save_modal___BV_modal_outer_ {
  z-index: 99990 !important;
}
</style>
