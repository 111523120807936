<template>
  <div>
    <FieldConditions
      v-model="validation.required"
      :widget="widget"
      :label="$t('fields.general.required.label')"
      :tooltip="$t('fields.general.required.info')"
      :error-message-label="$t('fields.general.required.error-message')"
    />

    <FieldConditions
      v-model="validation.readOnly"
      :widget="widget"
      :label="$t('fields.general.read-only.label')"
      :show-error-message="false"
    />

    <template v-if="validation.numberOfTags">
      <b-form-group>
        <b-form-checkbox v-model="validation.numberOfTags.isEnabled">
          {{ $t('fields.codex-field-tag.validation.range.label') }}
          <GjIcon
            v-b-tooltip.hover="$t('fields.codex-field-tag.validation.range.tooltip')"
            name="Info"
            size="20"
          />
        </b-form-checkbox>
      </b-form-group>
      <div v-if="validation.numberOfTags.isEnabled">
        <b-form-group>
          <v-select
            v-model="validation.numberOfTags.rangeOperator"
            :options="options"
            :clearable="false"
            :reduce="v => v.value"
          />
        </b-form-group>

        <b-row>
          <b-col v-if="validation.numberOfTags.rangeOperator === RANGE_OPERATORS.BETWEEN || validation.numberOfTags.rangeOperator === RANGE_OPERATORS.GTE">
            <b-form-group :label="$t('fields.codex-field-tag.validation.range-inputs.min')">
              <b-form-input
                v-model.number="validation.numberOfTags.min"
                :state="!validationError.isValid ? validationError.isValid : null"
                type="number"
                min="0"
              />
            </b-form-group>
          </b-col>
          <b-col v-if="validation.numberOfTags.rangeOperator === RANGE_OPERATORS.BETWEEN || validation.numberOfTags.rangeOperator === RANGE_OPERATORS.LTE">
            <b-form-group :label="$t('fields.codex-field-tag.validation.range-inputs.max')">
              <b-form-input
                v-model.number="validation.numberOfTags.max"
                :state="!validationError.isValid ? validationError.isValid : null"
                type="number"
                min="0"
              />
            </b-form-group>
          </b-col>
          <b-col v-if="validation.numberOfTags.rangeOperator === RANGE_OPERATORS.EXACTLY">
            <b-form-group :label="$t('fields.codex-field-tag.validation.range-inputs.exactly')">
              <b-form-input
                v-model.number="validation.numberOfTags.exactly"
                :state="!validationError.isValid ? validationError.isValid : null"
                type="number"
                min="0"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-form-group>
          <b-form-invalid-feedback :state="validationError.isValid">
            {{ $t(validationError.integerMessage) }}
          </b-form-invalid-feedback>
          <b-form-invalid-feedback :state="validationError.isValid">
            {{ $t(validationError.differenceMessage) }}
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group
          v-if="validation.numberOfTags.isEnabled"
        >
          <template #label>
            <label>
              {{ $t('fields.codex-field-tag.validation.range.custom-error-template') }}
            </label>
            <span
              id="reference__inputs-allowed-tooltip"
              class="codex-field-range-tooltip"
            >
              <GjIcon
                name="Info"
                size="20"
              />
            </span>
          </template>
          <PlaceholdersTooltip
            :validation="validation.numberOfTags.rangeOperator"
            :target="'reference__inputs-allowed-tooltip'"
          />
          <b-form-input
            v-model="validation.numberOfTags.errorTemplate"
            :placeholder="$t('fields.codex-field-tag.validation.range.enter-error-message')"
          />

        </b-form-group>
        <b-form-group
          v-if="validation.numberOfTags.isEnabled"
          :label="$t('fields.codex-field-tag.validation.range.custom-error-message')"
        >
          <b-form-input
            v-model="validation.numberOfTags.errorMessage"
            readonly
          />
        </b-form-group>
      </div>
    </template>
  </div>
</template>

<script>
import { generateComputedPropsFromAttrs } from '@/components/codex-layout-editor/BuilderUtils'
import { checkRangeValidation, errorTemplateParser, RANGE_OPERATORS } from '@/views/models/constants'
import PlaceholdersTooltip from '@/components/fields/PlaceholdersTooltip.vue'
import FieldConditions from '@/components/fields/FieldConditions.vue'
import BaseFieldMixin from '@/components/fields/BaseFieldMixin'

export default {
  name: 'Validations',
  components: { FieldConditions, PlaceholdersTooltip },
  mixins: [BaseFieldMixin],
  data() {
    return {
      RANGE_OPERATORS,
      validationError: {
        isValid: true,
        integerMessage: '',
        differenceMessage: '',
      },
    }
  },
  beforeMount() {
    if (!this.validation.numberOfTags) {
      this.$set(this.validation, 'numberOfTags', {
        isEnabled: false,
        rangeOperator: RANGE_OPERATORS.BETWEEN,
        min: 2,
        max: 5,
        exactly: 1,
        errorMessage: 'Number of tags: 2-5.',
        errorTemplate: 'Number of tags: {min}-{max}',
      })
    }
  },
  mounted() {
    this.validationError = checkRangeValidation(this.validation.numberOfTags, this.validation.numberOfTags.rangeOperator == RANGE_OPERATORS.EXACTLY)
  },
  destroyed() {
    // prevent decimal numbers
    this.validation.numberOfTags.min = parseInt(this.validation.numberOfTags.min, 10)
    this.validation.numberOfTags.max = parseInt(this.validation.numberOfTags.max, 10)
    this.validation.numberOfTags.exactly = parseInt(this.validation.numberOfTags.exactly, 10)
  },
  computed: {
    ...generateComputedPropsFromAttrs([
      'validation',
    ]),
    options() {
      return [
        { label: this.$t('fields.codex-field-tag.validation.range-options.between'), value: RANGE_OPERATORS.BETWEEN },
        { label: this.$t('fields.codex-field-tag.validation.range-options.gte'), value: RANGE_OPERATORS.GTE },
        { label: this.$t('fields.codex-field-tag.validation.range-options.lte'), value: RANGE_OPERATORS.LTE },
        { label: this.$t('fields.codex-field-tag.validation.range-options.exactly'), value: RANGE_OPERATORS.EXACTLY },
      ]
    },
    predefinedErrorTemplates() {
      return {
        [RANGE_OPERATORS.BETWEEN]: this.$t('fields.codex-field-tag.validation.range-templates.between'),
        [RANGE_OPERATORS.GTE]: this.$t('fields.codex-field-tag.validation.range-templates.gte'),
        [RANGE_OPERATORS.LTE]: this.$t('fields.codex-field-tag.validation.range-templates.lte'),
        [RANGE_OPERATORS.EXACTLY]: this.$t('fields.codex-field-tag.validation.range-templates.exactly'),
      }
    },
  },
  watch: {
    'validation.numberOfTags.min': function changedMin() {
      this.validationError = checkRangeValidation(this.validation.numberOfTags)
    },
    'validation.numberOfTags.max': function changedMax() {
      this.validationError = checkRangeValidation(this.validation.numberOfTags)
    },
    'validation.numberOfTags.exactly': function changedMax() {
      this.validationError = checkRangeValidation(this.validation.numberOfTags, true)
    },
    'validation.numberOfTags.rangeOperator': function changedRangeOperator(v) {
      this.validation.numberOfTags.errorMessage = ''
      this.validationError = checkRangeValidation(this.validation.numberOfTags, v === RANGE_OPERATORS.EXACTLY)
      this.validation.numberOfTags.errorTemplate = this.predefinedErrorTemplates[v]
    },
    'validation.numberOfTags': {
      handler() {
        errorTemplateParser(this.validation.numberOfTags)
      },
      deep: true,
    },
  },
}
</script>
