<template>
  <div
    class="tiktok-embed-block-wrapper"
    :style="`text-align: ${alignClass}`"
  >
    <div
      class="tiktok-embed-block"
    >
      <iframe
        :src="embedUrl"
        frameborder="0"
        class="embed"
      />
      <b-form-input
        v-model="caption"
        class="tiktok-embed-block__caption"
        :placeholder="$t('codex-editor.nodes.tiktok-embed.caption')"
        rows="1"
        max-rows="10"
        autocomplete="off"
      />
    </div>
    <div
      v-if="!selected"
      class="widget-select-overlay"
      @click="selectNode"
    />
  </div>
</template>

<script>
// import ResizableBlock from '@/components/ResizableBlock.vue'
import { TikTokGetID } from './TikTokHelpers'

export default {
  components: {
    // ResizableBlock,
  },
  props: ['attrs', 'updateAttrs', 'selectNode', 'selected'],
  inject: ['deleteBlock', 'showPromptPopup', 'toastNotification'],
  data() {
    return {
      initialSrc: '',
    }
  },
  computed: {
    alignClass() {
      return `${this.attrs.align}`
    },
    embedUrl() {
      if (this.videoId) {
        return `https://www.tiktok.com/embed/${this.videoId}?`
      }
      return ''
    },
    videoId: {
      get() {
        return this.attrs.videoId
      },
      set(e) {
        this.updateAttrs({ videoId: e })
      },
    },
    caption: {
      get() {
        return this.attrs.caption
      },
      set(e) {
        this.updateAttrs({ caption: e })
      },
    },
  },
  watch: {
    src() {
      this.$nextTick(() => {
        this.selectNode()
      })
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.initialSrc = this.attrs.src
      this.videoId = TikTokGetID(this.attrs.src)
    })
  },

  methods: {
    initialize() {
      if (!this.attrs.src) {
        this.openModal()
      }
    },
    async openModal() {
      const url = await this.showPromptPopup({ title: this.$t('codex-editor.nodes.tiktok-embed.tiktok-url') })
      if (!url) {
        this.deleteBlock(this.attrs.blockId)
      } else if (url.length && url.match((/\bhttps?:\/\/(?:m|www|vm)\.tiktok\.com\/\S*?\b(?:(?:(?:usr|v|embed|user|video)\/|\?shareId=|&item_id=)(\d+)|(?=\w{7})(\w*?[A-Z\d]\w*)(?=\s|\/$))\b/gm))) {
        this.updateAttrs({
          src: url,
          videoId: TikTokGetID(url),

        })
      } else {
        this.toastNotification({
          icon: 'XIcon',
          variant: 'danger',
          title: 'Error',
          text: this.$t('codex-editor.nodes.tiktok-embed.tiktok-invalid-url'),
        })
        this.deleteBlock(this.attrs.blockId)
      }
    },
    setSrc() {
      this.updateAttrs({
        src: this.initialSrc,
        videoId: TikTokGetID(this.initialSrc),
      })
    },

  },
}
</script>
<style scoped lang="scss">

.tiktok-embed-block-wrapper {
  position: relative;
}

.widget-select-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0,0,0,0);
}

.tiktok-embed-holder {
    position: relative;
  }
.tiktok-embed-block {
  margin-top: 15px;
  position: relative;
  display: inline-block;
  iframe{
    min-height:780px;
  }
}

.tiktok-embed-block__caption {
    width: 100%;
    font-size: 14px;
    color: gray;
    border: none;
    overflow: hidden !important;
    padding:0 !important;
  }

.tiktok-embed-block{
  .embed{
    width:325px;
  }
}

</style>
