import { enumMapping } from '@/utils/helpers'
import { cloneDeep } from 'lodash'
import i18n from '@/libs/i18n'
import { apiAxios as axios } from '../libs/axios'
import { checkPermission, tc } from './config'

/**
 * Constants
 */
export const NO_LABEL_ID = 'lbNoLabels'

export const types = Object.freeze({
  PUBLIC: 1,
  PRIVATE: 2,
})

export const LABEL_ENTITIES = Object.freeze({
  ALL: 'ALL',
  ENTRY: 'ENTRY',
  ASSET: 'ASSET',
})

export const LABEL_ENTITY_MAPPING = enumMapping({
  [LABEL_ENTITIES.ALL]: 1,
  [LABEL_ENTITIES.ENTRY]: 2,
  [LABEL_ENTITIES.ASSET]: 3,
})

export const LABEL_COMPONENT_ENTITY_TYPES = Object.freeze({
  ENTRY: 'ENTRY',
  ASSET: 'ASSET',
})

/**
 * Helpers
 */
export function mapLabelEntityLabel(entity) {
  const entities = {
    [LABEL_ENTITIES.ALL]: 'labels.entities.all',
    [LABEL_ENTITIES.ENTRY]: 'labels.entities.entry',
    [LABEL_ENTITIES.ASSET]: 'labels.entities.asset',
  }
  return i18n.t(entities[entity])
}

export function labelDefaults(label = {}) {
  return {
    id: null,
    name: '',
    normalizedName: '',
    description: '',
    color: '',
    type: types.PUBLIC,
    entities: [LABEL_ENTITIES.ALL],
    restrictions: {
      sites: {
        allowAll: true,
        allowed: [],
      },
    },
    ...label,
  }
}

/**
 * Transforms to received data
 */
export function transformLabelFromRequest(label) {
  if (label?.entities) {
    label.entities = label.entities.map(entity => LABEL_ENTITY_MAPPING.toString(entity))
  } else {
    label.entities = [LABEL_ENTITIES.ALL]
  }
  if (!label.restrictions) {
    label.restrictions = {
      sites: {
        allowAll: true,
        allowed: [],
      },
    }
  }
  return label
}

export function transformLabelToRequest(label) {
  if (label?.entities) {
    label.entities = label.entities.map(entity => LABEL_ENTITY_MAPPING.toNumber(entity))
  }
  return label
}

/**
 * CRUD Operations
 * */

/**
 *
 * @response
 * [{
 *  id: GUID
 *  name: String
 * }, {...}, ...]
 */
export function readLabels({ search: q, type = null } = {}) {
  return axios.get('/{organization}/labels', { params: { q, Type: type } }).then(response => {
    response.data = response.data.map(label => transformLabelFromRequest(label))
    return response
  })
}

/**
 *
 * @response
 * [{
 *  id: GUID
 *  name: String
 * }, {...}, ...]
 */
export function readLabel(id) {
  return axios.get(`/{organization}/labels/${id}`).then(response => {
    response.data = transformLabelFromRequest(response.data)
    return response
  })
}

/**
 *
 * @response
 * [{
 *  id: GUID
 *  name: String
 * }, {...}, ...]
 */
export function updateLabel(label, messages) {
  const current = cloneDeep(label)
  transformLabelToRequest(current)

  return checkPermission('canEditLabel')

  && tc(axios.put(`/{organization}/labels/${current.id}`, current).then(response => {
    response.data = transformLabelFromRequest(response.data)
    return response
  }), {
    successTitle: 'sdk.success-title',
    successMessage: 'sdk.label.update-successful',
    failTitle: 'sdk.label.update-failed',
    ...messages,
  })
}

/**
 *
 * @response
 * [{
 *  id: GUID
 *  name: String
 * }, {...}, ...]
 */
export function createLabel(label, messages) {
  const current = cloneDeep(label)
  transformLabelToRequest(current)
  return checkPermission('canCreateLabel')

  && tc(axios.post('/{organization}/labels', current).then(response => {
    response.data = transformLabelFromRequest(response.data)
    return response
  }), {
    successTitle: 'sdk.success-title',
    successMessage: 'sdk.label.create-successful',
    failTitle: 'sdk.label.create-failed',
    ...messages,
  })
}

/**
 *
 * @response
 * [{
 *  id: GUID
 *  name: String
 * }, {...}, ...]
 */
export function deleteLabel(id, messages = {}) {
  return checkPermission('canDeleteLabel')

  && tc(axios.delete(`/{organization}/labels/${id}`), {
    successTitle: 'sdk.success-title',
    successMessage: 'sdk.label.delete-successful',
    failTitle: 'sdk.label.delete-failed',
    ...messages,
  })
}
