<template>
  <div
    v-if="showDropdown && dropdownPosition"
    ref="quickAddList"
    class="quick-add-list"
    :class="{
      'quick-add-list--inverted': dropdownPosition.inverted,
      'quick-add-list--check-hover': checkHover
    }"
    :style="{top: dropdownPosition.top, left: dropdownPosition.left}"
  >
    <div
      ref="scrollEl"
      class="quick-add-list__scroll"
      @keydown.up="upHandler"
      @keydown.down="downHandler"
      @scroll="scrollHandler"
    >
      <div
        class="quick-add-list__scroll-inner"
      >
        <template v-if="$apollo.loading && (!quickAddItems.items || quickAddItems.length == 0)">
          <b-spinner
            variant="light"
            small
            class="m-50"
          />
        </template>
        <template v-else>
          <template v-if="quickAddItems.items && quickAddItems.items.length">

            <div
              class="quick-add-list__items"
              style="width: 100%"
            >
              <QuickAddItem
                v-for="(item, itemIndex) in quickAddItems.items"
                :key="item.entity.id"
                :ref="`item-${item.entity.id}`"
                :item="item"
                :is-selected="itemIndex == navigatedItemIndex"
                @click.native="enterHandler(item, itemIndex)"
                @mouseover.native="hoverHandler(item, itemIndex)"
              />
            </div>

          </template>
          <div
            v-else-if="!showSuggestions"
            class="quick-add-list__empty"
          >
            {{ $t('codex-editor.plugins.quick-add.search-for-quick-add') }}
          </div>
          <div
            v-else
            class="quick-add-list__empty"
          >
            {{ $t('codex-editor.plugins.quick-add.no-items-found') }}
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import gql from 'graphql-tag'
import { GenerateUUID } from '@/utils/helpers'
import { SEARCH_TYPES } from '@/utils/constants'
import { transformEntriesGraphQL } from '@/codex-sdk/entries'
import { ASSET_TYPE_MAPPING } from '@/codex-sdk/assets'
import { CODEX_EDITOR_BLOCKS } from '@/components/codex-editor/CodeEditorConstants'
import QuickAdd from './QuickAdd'
import QuickAddItem from './QuickAddItem.vue'
import { selectNode } from '../../CodexEditorUtils'

export default {
  name: 'QuickAddDropdown',
  inject: ['registerEditorExtension'],
  components: {
    QuickAddItem,
  },
  // eslint-disable-next-line vue/require-prop-types
  props: ['editor', 'includeModels'],

  apollo: {
    quickAddItems: {
      query: gql`
        query QuickAddItems($query: String!, $where: CodexSearchFilter, $limit: Int, $offset: Int) {
          search(query: $query, where: $where, limit: $limit, offset: $offset) {
            offset
            total
            items {
              type
              score
              title
              entity {
                id
                __typename
                ... on CodexEntry {
                  system {
                    title
                    modelId
                    modelAlias
                  }
                }
                ... on CodexAsset {
                  title
                  contentType
                  type
                  path
                  caption
                  alt
                  author
                  source
                  url(transformation: {height: 70, width: 70, format: THUMBNAIL})
                }
              }
            }
          }
        }
      `,
      fetchPolicy: 'network-only',
      update(data) {
        if (data?.search?.items) {
          transformEntriesGraphQL(data.search.items.filter(item => item.type === 'ENTRY').map(item => item.entity))
        }
        return data.search
      },
      variables() {
        const entities = ['ASSET']
        if (this.includeModels?.length) {
          entities.push('ENTRY')
        }
        return {
          query: this.filters.query,
          offset: 0,
          limit: this.filters.limit,
          where: {
            sites: [this.$store.state.general.currentSite?.id],
            entities,
            ...(this.includeModels?.length && {
              models: this.includeModels,
            }),
          },
        }
      },
    },
  },

  data() {
    return {
      showDropdown: false,
      checkHover: false,

      suggestionRange: null,
      navigatedItemIndex: 0,
      dropdownPosition: null,
      insertComponent: () => {},
      theNode: null,
      quickLinks: null,
      filters: {
        query: '',
        page: 0,
        limit: 20,
      },
    }
  },
  computed: {
    showSuggestions() {
      return this.filters.query.length > 0
    },
  },
  watch: {
    navigatedItemIndex() {
      if (this.$apollo.loading) return

      if (this.navigatedItemIndex > this.quickAddItems.items.length - 5 && this.quickAddItems.items.length < this.quickAddItems.total) {
        this.loadMore()
      }
    },
  },
  beforeDestroy() {
    this.destroyPopup()
    window.removeEventListener('keydown', this.keyDown)
    window.removeEventListener('mousemove', this.mouseMove)
    window.removeEventListener('resize', this.repositionDropdown)
    window.removeEventListener('scroll', this.repositionDropdown)
    window.removeEventListener('click', this.onClick)
  },
  mounted() {
    window.addEventListener('keydown', this.keyDown)
    window.addEventListener('mousemove', this.mouseMove)
    window.addEventListener('resize', this.repositionDropdown)
    window.addEventListener('scroll', this.repositionDropdown)
    window.addEventListener('click', this.onClick)

    this.quickLinks = new QuickAdd({
      // a list of all suggested items
      items: async () => [],
      // is called when a quickLinks starts
      onEnter: ({
        range, command, virtualNode,
      }) => {
        if (!this.showDropdown) return

        this.filters.query = ''
        this.filters.page = 0
        this.suggestionRange = range
        this.renderPopup(virtualNode)
        this.insertComponent = command
      },
      // is called when a quickLinks has changed
      onChange: ({
        query, range, virtualNode,
      }) => {
        if (!this.showDropdown) return

        this.filters.query = query
        this.filters.page = 0
        this.suggestionRange = range
        this.navigatedItemIndex = 0
        this.renderPopup(virtualNode)
      },
      // is called when a quickLinks is cancelled
      onExit: () => {
        // reset all saved values
        this.showDropdown = false
        this.filters.query = ''
        this.filters.page = 0
        this.dropdownItems = []
        this.suggestionRange = null
        this.navigatedItemIndex = 0
      },
      // is called on every keyDown event while a quickLinks is active
      onKeyDown: ({ event }) => {
        if (!this.showDropdown) return false

        if (event.key === 'ArrowUp') {
          this.upHandler()
          return true
        }
        if (event.key === 'ArrowDown') {
          this.downHandler()
          return true
        }
        if (event.key === 'Enter') {
          this.enterHandler()
          return true
        }
        if (event.key === 'Escape') {
          this.showDropdown = false
          this.destroyPopup()
          return false
        }
        return false
      },
      onFilter: async (items, query) => {
        this.filters.query = query || ''
        return []
      },
    })
    this.registerEditorExtension(this.quickLinks)
  },
  methods: {
    onClick(e) {
      if (this.$refs.quickAddList && !this.$refs.quickAddList.contains(e.target)) {
        this.quickLinks.options.onExit()
      }
    },

    loadMore() {
      this.filters.page++

      this.$apollo.queries.quickAddItems.fetchMore({
        variables: {
          offset: this.filters.page * this.filters.limit,
        },
        updateQuery: (previousResult, { fetchMoreResult }) => {
          if (!fetchMoreResult) return previousResult
          return {
            search: {
              ...previousResult.search,
              items: [
                ...previousResult.search.items,
                ...fetchMoreResult.search.items,
              ],
            },
          }
        },
      })
    },
    mouseMove() {
      this.checkHover = true
    },
    keyDown(e) {
      if (e.key === '@') {
        const { view, state } = this.editor
        const { from, to } = view.state.selection
        const text = state.doc.textBetween(from - 1, to, '')
        if (text.replace(' ', '') === '') this.$set(this, 'showDropdown', true)
      }
    },
    repositionDropdown() {
      if (this.theNode) {
        this.renderPopup(this.theNode)
      }
    },
    scrollHandler() {
      // Check if scrollEl div scroll is close to bottom
      if (this.$refs.scrollEl.scrollTop + this.$refs.scrollEl.clientHeight >= this.$refs.scrollEl.scrollHeight - 100) {
        this.loadMore()
      }
    },
    upHandler() {
      this.navigatedItemIndex = ((this.navigatedItemIndex + this.quickAddItems.items.length) - 1) % this.quickAddItems.items.length
      this.checkScroll()
      this.checkHover = false
    },
    downHandler() {
      this.navigatedItemIndex = ((this.navigatedItemIndex + this.quickAddItems.items.length) + 1) % this.quickAddItems.items.length
      this.checkScroll()
      this.checkHover = false
    },
    checkScroll() {
      const refName = `item-${this.quickAddItems.items[this.navigatedItemIndex].entity.id}`
      const itemRefEl = (Array.isArray(this.$refs[refName]) ? this.$refs[refName][0] : this.$refs[refName])?.$el
      const itemBox = itemRefEl.getBoundingClientRect()

      const scrollElBox = this.$refs.scrollEl.getBoundingClientRect()
      const scrollElY = this.$refs.scrollEl.scrollTop
      const itemHeight = itemBox.height
      const itemY = itemRefEl.offsetTop
      const offset = itemHeight * 2

      if (itemY - offset < scrollElY) {
        this.$refs.scrollEl.scrollTop = itemY - offset
      } else if (itemY + itemHeight + offset > scrollElBox.height + scrollElY) {
        this.$refs.scrollEl.scrollTop = itemY + itemHeight + offset - scrollElBox.height
      }
    },
    hoverHandler(item, itemIndex) {
      if (!this.checkHover) return

      this.navigatedItemIndex = itemIndex
    },
    async enterHandler(item) {
      let component = item

      if (item === undefined) {
        component = this.quickAddItems.items[this.navigatedItemIndex]
      }

      let attrs = null

      if (component.type === SEARCH_TYPES.ASSET) {
        attrs = {
          _nodeName: CODEX_EDITOR_BLOCKS.MEDIA,

          align: 'center',
          media: [{ id: component.entity.id, type: ASSET_TYPE_MAPPING.toNumber(component.entity.type) }],
          width: '70%',
        }
      } else if (component.type === SEARCH_TYPES.ENTRY) {
        attrs = {
          _nodeName: CODEX_EDITOR_BLOCKS.REFERENCE,

          references: [
            {
              entryId: component.entity.id,
              model: component.entity.system.modelAlias,
            },
          ],
        }
      }

      if (attrs) {
        const blockId = GenerateUUID.BLOCK()

        this.insertComponent({
          range: this.suggestionRange,
          attrs: {
            _nodeContent: '',
            blockId,
            ...attrs,
          },
        })

        this.$nextTick(() => {
          selectNode(blockId, this.editor)
        })

        this.showDropdown = false
        this.destroyPopup()
      }
    },
    renderPopup(node, heightReady = false) {
      if (!node) return
      this.theNode = node

      const editorDOM = this.editor.view.dom
      const editorRect = editorDOM.getBoundingClientRect()
      const rect = node.getBoundingClientRect()
      const wHeight = window.innerHeight
      const suggestions = this.$refs?.quickAddList?.getBoundingClientRect()
      const maxHeight = 350
      const suggestionsHeight = suggestions?.height || maxHeight
      let top = rect.top - editorRect.top + 10
      let inverted = false

      const offset = rect.top + suggestionsHeight + 50 - wHeight
      if (offset > 0) {
        top -= maxHeight + 40
        inverted = true
      }

      const position = {
        top: `${top}px`,
        left: `${rect.left - editorRect.left}px`,
        inverted,
      }

      this.dropdownPosition = position

      if (!heightReady) {
        setTimeout(() => { this.renderPopup(node, true) }, 10)
      }
    },
    destroyPopup() {
      this.dropdownPosition = null
      this.theNode = null
    },
  },
}
</script>
<style lang="scss">
@import "@core/scss/base/bootstrap-extended/include"; // Bootstrap includes
@import "@core/scss/base/components/include"; // Components includes

.quick-add-list {
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 10000;
  min-width: 275px;
  max-width: 275px;
  height: 350px;
  &.quick-add-list--inverted {
    justify-content: flex-end;
  }
}
.quick-add-list__empty {
  width: 100%;
  text-align: center;
  padding: 7px 5px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #667C99;
}
.quick-add-list__scroll {
  flex-shrink: 1;
  max-height: 350px;
  width: 100%;
  overflow: hidden !important;
  overflow-y: auto !important;
  background-color: #ffffff;
  transform: translateY(1.25rem) translateX(20px);
  box-shadow: 0px 2px 24px rgba(44, 44, 44, 0.16);
  border-radius: 4px;
  &::-webkit-scrollbar-track {
    border-radius: 4px;
    background-color: #fff;
  }
  &::-webkit-scrollbar {
    width: 8px;
    background-color: #F5F5F5;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: #e8e8e8;
  }
}
.quick-add-list__scroll-inner {
  min-height: 70px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.quick-add-list__loader {
  text-align: center;
  padding: 5px;
}
.quick-add-list__item {
  display: flex;
  align-items: center;
  margin-left: 16px;
  margin-right: 16px;
  padding: 4px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 14px;
  color: #2C2C2C;
  cursor: pointer;
  border-radius: 4px;

  margin-bottom: 2px;

  &:first-child {
    margin-top: 16px;
  }
  &:last-child {
    margin-bottom: 16px;
  }

  &.is-selected {
    background: #F4F6FA;
  }
}
.quick-add-list--check-hover {
  .quick-add-list__item {
    &:hover {
      background: #F4F6FA;
    }
  }
}
.quick-add-list__item--no-image {
  padding-left: 8px;
  padding-right: 8px;
}
.quick-add-list__item-image {
  width: 34px !important;
  min-width: 34px;
  margin-right: 6px;

  .quick-add-list__item-image-wrapper {
    padding-top: 100%;
    position: relative;
  }

  .quick-add-list__item-image-inner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 2px;
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  svg {
    // background: linear-gradient(180deg, #35D8FD 0%, #CD66FF 100%);
    color: #5EA3FF;
  }

  img {
    border-radius: 2px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.quick-add-list__item--with-icon {
  .quick-add-list__item-image-inner {
    background: linear-gradient(180deg, #D9D9D9 0%, #F3F2F4 0.01%, #EBDDFD 100%);
  }
}
.quick-add-list__item-content {
  padding-top: 4px;
  padding-bottom: 4px;
  min-width: 0;
}
.quick-add-list__item-name {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #052D61;
  margin-top: -2px;

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.quick-add-list__item-name--untitled {
  font-style: italic;
  opacity: 0.6;
}
.quick-add-list__item-subtitle {
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 10px;
  color: #667C99;
  margin-top: 4px;
}
.quick-add-list__group {
  padding: 0.25rem 0;
}
.quick-add-list__group-title {
  padding: 0 16px;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  text-transform: uppercase;
  color: #667C99;
}
.quick-add-list__item-link {
  font-size: 11px;
  color: #667C99;
  display: flex;
  align-items: center;
  .quick-add-list__item-link-name{
    max-width: 215px;
    white-space: nowrap;
    margin-right: 4px;
    overflow: hidden;
    text-overflow: ellipsis;
    direction: rtl;
    text-align: left;
  }
}
</style>
