<template>
  <div class="folders-grid">
    <div class="folders-grid__folders">
      <ContextMenu
        ref="menu"
        @openFolder="clickFolder"
      />
      <b-card
        v-for="folder in displayedFolders"
        :key="folder.id"
        class="folders-grid__folder"
        :class="{'folders-grid__folder-dark-background': isPopup}"
        :data-folder="folder.id"
        draggable="true"
        @dragstart="handleAssetDragstart($event, folder, null)"
        @contextmenu.prevent="$refs.menu.$refs.menu.open"
        @click.right.prevent="openContext(folder)"
      >
        <div
          class="folders-grid__folder-info"
          @click="clickFolder(folder)"
        >
          <div class="folders-grid__folder-icon">
            <img
              src="@/assets/icons/assets-folder.svg"
              alt=""
              width="27"
              height="24"
            >
          </div>
          <div
            class="folders-grid__folder-name text-dark"
            :title="folder.name"
          >
            <span class="folder-name__holder">
              {{ folder.name }}
            </span>
            <span class="folder-grid__path">
              <span
                :title="currentOrg"
                @click.stop="rootLevel"
              > {{ currentOrg }} /</span>
              <span
                v-for="(fold, i) in folders.path"
                :key="fold.id+i"
                :title="fold.name"
                @click.stop="clickFolder(fold)"
              >
                {{ fold.name }} /
              </span>
            </span>
          </div>
        </div>
      </b-card>
    </div>
    <div
      v-if="folders_.length > 5 && totalAssets > 0"
      class="folders-grid__button"
    >
      <span @click="showAll = !showAll">
        {{ !showAll ? $t('assets.folders.folders-show-all') : $t('assets.folders.folders-hide-all') }}
        <GjIcon
          :key="showAll"
          :name="!showAll ? 'ArrowDown' : 'ArrowUp'"
        />
      </span>
    </div>
  </div>
</template>

<script>
import { orderBy } from 'lodash'
import gql from 'graphql-tag'
import { mapMutations, mapState } from 'vuex'
import assetsMixin from '@/components/assets/assetsMixin'
import ContextMenu from './ContextMenu.vue'

export default {
  name: 'FoldersGrid',
  components: {
    ContextMenu,
  },
  mixins: [assetsMixin],
  props: {
    isPopup: {
      type: Boolean,
    },
    sortBy: {
      type: String,
      default: null,
    },
    parentId: {
      type: String,
      default: null,
    },
    totalAssets: {
      type: Number,
      default: 0,
    },
  },
  apollo: {
    folderCollection: {
      query: gql`
        query ($limit: Int, $parentId: CodexStringFilter) {
         folderCollection (limit: $limit, where: {
            parentId: $parentId,
          }) {
            total
            items {
              id
              name
              parentId
              parent {
                id
                name
                parent {
                  id
                  name
                  parent {
                    id
                    name
                  }
                }
              }
              children {
                total
                items {
                  id
                  name
                  parentId
                }
              }
            }
          }
        }
      `,
      variables() {
        return {
          limit: this.folders.total,
          parentId: {
            in: this.parentId ? [this.parentId] : [''],
            exists: !!this.parentId,
          },
        }
      },
      result(results) {
        if (results?.data?.folderCollection?.items) {
          this.folders.items = results.data.folderCollection.items
          this.$emit('total-folders', this.folders.items.length)
          this.folders.path = []
          this.setFolderParentName(this.folders.items)
        }
      },
      fetchPolicy: 'cache-and-network',
    },
  },
  data() {
    return {
      isFolderModalShown: {
        shown: false,
        mode: '',
        isEdit: true,
      },
      folders: {
        items: [],
        total: 9999,
        path: [],
      },
      showAll: false,
    }
  },
  computed: {
    ...mapState('assets', [
      'targetFolder',
      'filters',
    ]),
    folderId() {
      return this.$route.query.folder
    },
    folders_() {
      if (this.folders && this.folders.items) {
        if (this.sortBy === 'ASC') {
          return orderBy(this.folders.items, [f => f?.name?.toLowerCase()], ['asc'])
        }
        if (this.sortBy === 'DESC') {
          return orderBy(this.folders.items, [f => f?.name?.toLowerCase()], ['desc'])
        }
        return this.folders.items
      }
      return this.folders.items
    },
    displayedFolders() {
      if (this.showAll || this.totalAssets == 0) {
        return this.folders_
      }
      return this.folders_.slice(0, 5)
    },
    currentOrg() {
      return this.$store.state.general.currentOrganization.name
    },
  },
  watch: {
    targetFolder: {
      handler(v) {
        this.refetchFolders()
        if (!this.folders.items.some(folder => folder.id === v?.id)) {
          this.$refs.menu.$refs.menu.close()
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.$root.$on('refetch-folders', this.refetchFolders)
  },
  destroyed() {
    this.$root.$off('refetch-folders', this.refetchFolders)
  },
  methods: {
    setFolderParentName(folders) {
      folders.forEach(folder => {
        this.setParentNameRecursively(folder)
      })
    },
    setParentNameRecursively(folder) {
      const parent = folder.parent
      if (parent) {
        if (!this.folders.path.includes(parent)) {
          this.folders.path.unshift(parent)
        }
        this.setParentNameRecursively(parent)
      }
    },
    ...mapMutations('assets', [
      'SET_TARGET_FOLDER',
    ]),
    refetchFolders() {
      this.$apollo.queries.folderCollection.refetch()
    },
    rootLevel() {
      const { folder, ...remaining } = this.$route.query
      this.SET_TARGET_FOLDER(null)
      if (!this.isPopup) {
        this.$router.replace({ name: 'assets', query: { ...remaining } }).catch(() => {})
      }
      this.filters.folderId = null
    },
    clickFolder(folder) {
      if (!this.isPopup && this.folderId !== folder.id) {
        this.$router.replace({ name: this.$route.name, query: { ...this.$route.query, folder: folder.id } })
      }
      this.SET_TARGET_FOLDER(folder)
      this.filters.folderId = folder.id
    },
    openContext(folder) {
      folder.isEdit = false
      if (this.folderId !== folder.id) {
        if (!this.isPopup) {
          this.$router.replace({ name: this.$route.name, query: { ...this.$route.query, folder: folder?.id } })
        }
        this.SET_TARGET_FOLDER(folder)
      }
    },
    openFolder(folder) {
      folder.visible = true
    },
  },
}
</script>
<style lang="scss">
@import '../../../../../node_modules/vue-context/dist/css/vue-context.css';
@import '@/assets/scss/mixins/responsive.scss';
.folders-grid {
  display: flex;
  flex-direction: column;
  padding-right: 0.5rem;
  @include  max-md {
    padding-right: 0;
  }

  .btn-light,
  .btn-light:hover,
  .btn-light:focus,
  .btn-light:active {
    background-color: transparent !important;
    border: none !important;
  }

  .folders-grid__folders {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 14px;
    margin-bottom: 14px;
    @include max-lg {
      grid-template-columns: repeat(3, 1fr);
      .card.folders-grid__folder {
        padding: 0px 12px !important;
      }
    }
    @include max-md {
      grid-template-columns: repeat(2, 1fr);
      .card.folders-grid__folder {
        padding: 0px 12px !important;
      }
    }

    .card.folders-grid__folder-dark-background {
      background-color: rgb(246, 247, 249);
    }
    .card.folders-grid__folder {
      padding: 4px 14px;
      border-radius: 4px;
      cursor: pointer;
      word-break: break-all;
     }

    .folders-grid__folder-info {
      display: flex;
      flex-grow: 1;
      padding: 2px 5px;
      gap:14px;
      align-items: center;

      .folders-grid__folder-name {
        min-width: 0;
        flex: 1;
        .folder-name__holder {
          display: block;
          font-weight: 400;
          font-size: 14px;
          line-height: 18px;
          color: #052D61;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        .folder-grid__path {
          font-weight: 400;
          font-size: 12px;
          line-height: 18px;
          color: #A3B0C2;

          span:hover {
            color: #1d79f2;
            text-decoration: underline;
          }
        }
      }
    }
  }
  .folders-grid__button {
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      cursor: pointer;
    }
  }
}
</style>
