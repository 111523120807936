import { Extension } from 'tiptap'
import { Suggestions } from 'tiptap-extensions'
import { replaceParentNodeOfType } from 'prosemirror-utils'

export default class ComponentsDropdown extends Extension {
  get name() {
    return 'components-dropdown'
  }

  get defaultOptions() {
    return {
      matcher: {
        char: '/',
        allowSpaces: true,
        startOfLine: true,
      },
      suggestionClass: 'components-dropdown',
    }
  }

  keys() {
    return {
      Escape: () => {
        this.options.onExit()
      },
    }
  }

  get plugins() {
    return [
      Suggestions({
        command: ({ schema, attrs }) => function (state, dispatch) {
          const type = schema.nodes[attrs._nodeName]
          const node = type.create(attrs)
          dispatch(replaceParentNodeOfType([schema.nodes.paragraph], node)(state.tr))
        },
        appendText: '',
        matcher: this.options.matcher,
        items: this.options.items,
        onEnter: this.options.onEnter,
        onChange: this.options.onChange,
        onExit: this.options.onExit,
        onKeyDown: this.options.onKeyDown,
        onFilter: this.options.onFilter,
        suggestionClass: this.options.suggestionClass,
      }),
    ]
  }
}
