<template>
  <div class="comments">
    <Comment
      v-for="comment in comments"
      :key="comment.id"
      :comment="comment"
      class="bubble-comment"
    />
  </div>
</template>

<script>
import Comment from '@/components/codex-editor/panels/Comment.vue'

export default {
  name: 'Comments',
  components: { Comment },
  props: {
    comments: {
      type: Array,
      default: () => ([]),
    },
  },
}
</script>

<style lang="scss">
.bubble-comment:last-child {
  border: none;
}
</style>
