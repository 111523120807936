<template>
  <div>
    <b-form-group
      v-show="valueType === VALUE_TYPES.SINGLE"
      :label="$t('fields.codex-field-text.appearance.single')"
    >
      <b-form-radio-group v-model="appearance">
        <label
          for="text-field__single-line"
          class="text-field__radio-button"
        >
          <div class="text-field__radio-container">
            <div class="text-field__radio-button-left">
              <GjIcon
                name="SingleLine"
                class="text-field__icon"
                size="24"
                style="fill: #1D79F2"
              />
              <p class="mb-0 text-field__radio-button-text">
                {{ $t('fields.codex-field-text.appearance.single-line') }}
              </p>
            </div>

            <div class="text-field__radio-button-right">
              <b-form-radio
                id="text-field__single-line"
                :value="TEXT_FIELD_APPEARANCES.SINGLE_LINE"
                class="mr-0"
              />
            </div>
          </div>
        </label>
        <label
          for="text-field__multiple-lines"
          class="text-field__radio-button"
        >
          <div class="text-field__radio-container">
            <div class="text-field__radio-button-left">
              <GjIcon
                name="AlignLeft"
                class="text-field__icon"
                size="24"
                style="fill: #1D79F2"
                align-left-alt
              />
              <p class="mb-0 text-field__radio-button-text">
                {{ $t('fields.codex-field-text.appearance.multiple-lines') }}
              </p>
            </div>

            <div class="text-field__radio-button-right">
              <b-form-radio
                id="text-field__multiple-lines"
                :value="TEXT_FIELD_APPEARANCES.MULTIPLE_LINES"
                class="mr-0"
              />
            </div>
          </div>
        </label>

        <label
          for="text-field__url"
          class="text-field__radio-button"
        >
          <div class="text-field__radio-container">
            <div class="text-field__radio-button-left">
              <GjIcon
                name="Hyperlink"
                class="text-field__icon"
                size="24"
                style="fill: #1D79F2"
              />
              <p class="mb-0 text-field__radio-button-text">
                {{ $t('fields.codex-field-text.appearance.url') }}
              </p>
            </div>

            <div class="text-field__radio-button-right">
              <b-form-radio
                id="text-field__url"
                :value="TEXT_FIELD_APPEARANCES.URL"
                class="mr-0"
              />
            </div>
          </div>
        </label>

        <label
          for="text-field__dropdown"
          class="text-field__radio-button"
        >
          <div class="text-field__radio-container">
            <div class="text-field__radio-button-left">
              <GjIcon
                name="ArrowDown"
                class="text-field__icon"
                size="24"
                style="fill: #1D79F2"
              />
              <p class="mb-0 text-field__radio-button-text">
                {{ $t('fields.codex-field-text.appearance.dropdown') }}
              </p>
            </div>

            <div class="text-field__radio-button-right">
              <b-form-radio
                id="text-field__dropdown"
                :value="TEXT_FIELD_APPEARANCES.DROPDOWN"
                class="mr-0"
              />
            </div>
          </div>
        </label>

        <label
          for="text-field__radio"
          class="text-field__radio-button"
        >
          <div class="text-field__radio-container">
            <div class="text-field__radio-button-left">
              <GjIcon
                name="Video"
                class="text-field__icon"
                size="24"
                style="fill: #1D79F2"
              />
              <p class="mb-0 text-field__radio-button-text">
                {{ $t('fields.codex-field-text.appearance.radio') }}
              </p>
            </div>

            <div class="text-field__radio-button-right">
              <b-form-radio
                id="text-field__radio"
                :value="TEXT_FIELD_APPEARANCES.RADIO"
                class="mr-0"
              />
            </div>
          </div>
        </label>

        <label
          for="text-field__slug"
          class="text-field__radio-button"
        >
          <div class="text-field__radio-container">
            <div class="text-field__radio-button-left">
              <GjIcon
                name="Switch"
                class="text-field__icon"
                size="24"
                style="fill: #1D79F2"
              />
              <p class="mb-0 text-field__radio-button-text">
                {{ $t('fields.codex-field-text.appearance.slug') }}
              </p>
            </div>

            <div class="text-field__radio-button-right">
              <b-form-radio
                id="text-field__slug"
                :value="TEXT_FIELD_APPEARANCES.SLUG"
                class="mr-0"
              />
            </div>
          </div>
        </label>
      </b-form-radio-group>
    </b-form-group>

    <div v-show="valueType === VALUE_TYPES.LIST">
      <b-form-group :label="$t('fields.codex-field-text.appearance.list')">
        <b-form-radio-group v-model="appearance">
          <label
            for="text-field__single-line"
            class="text-field__radio-button"
          >
            <div class="text-field__radio-container">
              <div class="text-field__radio-button-left">
                <GjIcon
                  name="SingleLine"
                  class="text-field__icon"
                  size="24"
                  style="fill: #1D79F2"
                />
                <p class="mb-0 text-field__radio-button-text">
                  {{ $t('fields.codex-field-text.appearance.single-line') }}
                </p>
              </div>

              <div class="text-field__radio-button-right">
                <b-form-radio
                  id="text-field__single-line"
                  :value="TEXT_FIELD_APPEARANCES.SINGLE_LINE"
                  class="mr-0"
                />
              </div>

            </div>
          </label>
          <label
            for="text-mutiple_lines"
            class="text-field__radio-button"
          >
            <div class="text-field__radio-container">
              <div class="text-field__radio-button-left">
                <GjIcon
                  name="AlignLeft"
                  class="text-field__icon"
                  size="24"
                  style="fill: #1D79F2"
                />
                <p class="mb-0 text-field__radio-button-text">
                  {{ $t('fields.codex-field-text.appearance.multiple-lines') }}
                </p>
              </div>

              <div class="text-field__radio-button-right">
                <b-form-radio
                  id="text-mutiple_lines"
                  :value="TEXT_FIELD_APPEARANCES.MULTIPLE_LINES"
                  class="mr-0"
                />
              </div>
            </div>
          </label>
          <label
            for="text-tag"
            class="text-field__radio-button"
          >
            <div class="text-field__radio-container">
              <div class="text-field__radio-button-left">
                <GjIcon
                  name="PriceTag"
                  class="text-field__icon"
                  size="24"
                  style="fill: #1D79F2"
                />
                <p class="mb-0 text-field__radio-button-text">
                  {{ $t('fields.codex-field-text.appearance.tag') }}
                </p>
              </div>

              <div class="text-field__radio-button-right">
                <b-form-radio
                  id="text-tag"
                  :value="TEXT_FIELD_APPEARANCES.TAG"
                  class="mr-0"
                />
              </div>
            </div>
          </label>
          <label
            for="text-field_list"
            class="text-field__radio-button"
          >
            <div class="text-field__radio-container">
              <div class="text-field__radio-button-left">
                <GjIcon
                  name="ListAlt"
                  class="text-field__icon"
                  size="24"
                  style="fill: #1D79F2"
                />
                <p class="mb-0 text-field__radio-button-text">
                  {{ $t('fields.codex-field-text.appearance.list-type') }}
                </p>
              </div>

              <div class="text-field__radio-button-right">
                <b-form-radio
                  id="text-field_list"
                  :value="TEXT_FIELD_APPEARANCES.LIST"
                  class="mr-0"
                />
              </div>
            </div>
          </label>
          <label
            for="text-field__checkbox"
            class="text-field__radio-button"
          >
            <div class="text-field__radio-container">
              <div class="text-field__radio-button-left">
                <GjIcon
                  name="Check"
                  class="text-field__icon"
                  size="24"
                  style="fill: #1D79F2"
                />
                <p class="mb-0 text-field__radio-button-text">
                  {{ $t('fields.codex-field-text.appearance.checkbox') }}
                </p>
              </div>

              <div class="text-field__radio-button-right">
                <b-form-radio
                  id="text-field__checkbox"
                  :value="TEXT_FIELD_APPEARANCES.CHECKBOX"
                  class="mr-0"
                />
              </div>
            </div>
          </label>
        </b-form-radio-group>
      </b-form-group>
    </div>

    <TextInputs
      v-if="(valueType === VALUE_TYPES.LIST && (TEXT_FIELD_APPEARANCES.CHECKBOX === appearance)) || (TEXT_FIELD_APPEARANCES.DROPDOWN === appearance || TEXT_FIELD_APPEARANCES.RADIO === appearance)"
      v-model="validation.selectFrom.predefinedValues"
      field-label="fields.codex-field-text.appearance.predefined-values"
      placeholder-label="fields.codex-field-text.appearance.value"
      button-label="fields.codex-field-text.appearance.add"
      @removed="removeValue"
    /><!-- accept specified values -->

    <template v-if="valueType == VALUE_TYPES.LIST">
      <TextInputs
        v-if="appearance !== TEXT_FIELD_APPEARANCES.CHECKBOX"
        v-model="defaultValue"
        field-label="fields.codex-field-text.appearance.default-values-label"
        placeholder-label="fields.codex-field-text.appearance.value"
        button-label="fields.codex-field-text.appearance.add"
        @removed="removeValue"
      />
      <b-form-group
        v-show="validation.selectFrom.predefinedValues.length && appearance === TEXT_FIELD_APPEARANCES.CHECKBOX"
        :label="$t('fields.codex-field-text.appearance.default-values-label')"
      >
        <v-select
          v-model="defaultValue"
          multiple
          :options="validation.selectFrom.predefinedValues"
        />
      </b-form-group>
    </template>
    <div v-if="valueType === VALUE_TYPES.SINGLE">
      <b-form-group
        v-show="appearance !== TEXT_FIELD_APPEARANCES.DROPDOWN && appearance !== TEXT_FIELD_APPEARANCES.RADIO"
        :label="$t('fields.codex-field-text.appearance.default-value-label')"
        :description="appearance === TEXT_FIELD_APPEARANCES.SLUG ? $t('fields.codex-field-text.appearance.slug-default-value-info') : null"
      >
        <b-form-input
          v-model="defaultValue"
          :placeholder="$t('fields.codex-field-text.appearance.enter-default-placeholder')"
        />
      </b-form-group>
      <b-form-group
        v-show="validation.selectFrom.predefinedValues.length && (appearance === TEXT_FIELD_APPEARANCES.DROPDOWN || appearance === TEXT_FIELD_APPEARANCES.RADIO)"
        :label="$t('fields.codex-field-text.appearance.default-value-label')"
      >
        <v-select
          v-model="defaultValue"
          :options="validation.selectFrom.predefinedValues"
        />
      </b-form-group>
    </div>

    <b-form-group
      v-show="TEXT_FIELD_APPEARANCES.SLUG === appearance && (!defaultValue || !defaultValue.length)"
      :label="$t('fields.codex-field-text.appearance.generate-slug-from')"
    >
      <v-select
        v-model="generateSlug"
        :options="slugOptions"
        :clearable="true"
        label="name"
        :reduce="v => v.alias"
      />
    </b-form-group>

    <FieldHelpTextInput
      :widget="widget"
      :update-attrs="updateAttrs"
    />

    <b-form-group v-if="valueType === VALUE_TYPES.SINGLE && (appearance === TEXT_FIELD_APPEARANCES.SINGLE_LINE || appearance === TEXT_FIELD_APPEARANCES.MULTIPLE_LINES)">
      <b-form-checkbox v-model="showCharacterCount">
        {{ $t('fields.codex-field-text.appearance.show-character-count') }}
      </b-form-checkbox>
    </b-form-group>

    <FieldConditions
      v-model="hidden"
      :widget="widget"
      :label="$t('fields.general.hidden.label')"
      :show-error-message="false"
      :tooltip="$t('fields.general.hidden.info')"
    />
  </div>
</template>

<script>
import { generateComputedPropsFromAttrs } from '@/components/codex-layout-editor/BuilderUtils'
import { TEXT_FIELD_APPEARANCES, TYPES, VALUE_TYPES } from '@/views/models/constants'
import FieldHelpTextInput from '@/components/fields/FieldHelpTextInput.vue'
import BaseFieldMixin from '@/components/fields/BaseFieldMixin'
import FieldConditions from '@/components/fields/FieldConditions.vue'
import TextInputs from './TextInputs.vue'

export default {
  name: 'Appearance',
  components: { FieldConditions, FieldHelpTextInput, TextInputs },
  inject: ['getFieldsObjects'],
  mixins: [BaseFieldMixin],
  data() {
    return {
      VALUE_TYPES,
      TEXT_FIELD_APPEARANCES,
    }
  },
  computed: {
    ...generateComputedPropsFromAttrs([
      'valueType',
      'appearance',
      'validation',
      'defaultValue',
      'type',
      'helpText',
      'generateSlug',
      'showCharacterCount',
      'hidden',
    ]),
    slugOptions() {
      const fieldsQuery = widget => widget.attrs.type === TYPES.TEXT && widget.attrs.valueType === VALUE_TYPES.SINGLE && widget.attrs.appearance === TEXT_FIELD_APPEARANCES.SINGLE_LINE
      return this.getFieldsObjects(fieldsQuery)?.map(f => ({ name: f.attrs.name, alias: f.attrs.alias })) || []
    },
  },
  watch: {
    appearance(v) {
      this.validation.isSlug.value = v === TEXT_FIELD_APPEARANCES.SLUG
      this.validation.selectFrom.value = v === TEXT_FIELD_APPEARANCES.DROPDOWN
          || v === TEXT_FIELD_APPEARANCES.RADIO
          || v === TEXT_FIELD_APPEARANCES.CHECKBOX
    },
  },
  methods: {
    removeValue(value) {
      if (this.valueType === VALUE_TYPES.SINGLE && value == this.defaultValue) {
        this.defaultValue = null
      }
      if (this.valueType === VALUE_TYPES.LIST) {
        this.defaultValue = this.defaultValue.filter(n => n !== value)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.text-field__radio-button {
  width: 100%;

  margin-bottom: 8px;

  cursor: pointer;
  height: 40px;
}

.text-field__radio-button-left {
  display: flex;
}

.text-field__radio-button-text {
  margin-left: 8px;
  font-size: 12px;
  font-weight: bold;
}
.specified-values__text {
  border-radius: 4px;
  padding: 8px 12px;
  border: 1px solid #E0E5EB;
}
.specified-values__text-section {
  margin-top: 8px;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}
.text-field__radio-container {
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  height: 100%;

  padding: 4px 8px;
  padding-right: 0;

  border: 1px solid #E0E5EB;
  border-radius: 4px;
}
.text-field__error-text {
  font-size: 14px;
  color: red;
  margin-bottom: 0;
}
.text-field__icon {
  box-shadow: 0px 4px 8px rgba(163, 176, 194, 0.32);
  border-radius: 3px;
}
.text-field__radio {
  padding: 0;
  left: 9px;
  width: 16px;
  height: 16px;
}
</style>
