import CodexContentEditor from '../../CodexContentEditor'
import FacebookEmbedVue from './FacebookEmbed.vue'
import FacebookEmbedPanel from './FacebookEmbedPanel.vue'
import { BLOCK_GROUPS } from '../constants'

CodexContentEditor.registerWidget('codex', {
  displayName: 'codex-editor.block-names.facebook-embed',
  name: 'facebook_embed',
  group: BLOCK_GROUPS.EMBEDS,
  icon: 'InfoCard',
  // eslint-disable-next-line global-require
  image: require('../../icons/facebook-post/icon.svg'),
  attrs: {
    blockId: {
      default: null,
    },
    src: {
      default: null,
    },
    align: {
      default: 'left',
    },
    caption: {
      default: null,
    },
  },
  renderEditor: FacebookEmbedVue,
  renderEditorBar: FacebookEmbedPanel,
})
