<template>
  <b-form-group>
    <template #label>
      <FieldName
        :name="field.name"
        :help-text="''"
      />
    </template>
    <div>
      <b-form-checkbox
        v-model="fieldContent"
        switch
        disabled
      />
    </div>
  </b-form-group>
</template>

<script>
import FieldName from '@/components/fields/FieldName.vue'
import { BFormCheckbox } from 'bootstrap-vue'

export default {
  name: 'BooleanField',
  components: {
    BFormCheckbox,
    FieldName,
  },
  props: {
    fieldContent: {
      type: [Boolean],
    },
    field: {
      type: [Object],
    },
  },
}
</script>

<style lang="scss">
</style>
