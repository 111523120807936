import { Node } from 'tiptap'
import { wrappingInputRule, toggleList } from 'tiptap-commands'
import OrderedListPanel from '@/components/codex-editor/nodes/OrderedList/OrderedListPanel.vue'
import { getBlockIdFromDom } from '@/components/codex-editor/CodexEditorUtils'
import CodexContentEditor from '../../CodexContentEditor'
import { BLOCK_GROUPS } from '../constants'

// eslint-disable-next-line no-unused-vars
export function orderedListTipTapNode(componentName, componentAttrs, component) {
  class OrderedList extends Node {
    get name() {
      return componentName
    }

    get schema() {
      return {
        attrs: componentAttrs,
        content: 'list_item+',
        group: 'block',
        parseDOM: [
          {
            tag: 'ol',
            getAttrs: dom => ({
              ...JSON.parse(dom.dataset?.attrs || '{}'),
              order: dom.hasAttribute('start') ? +dom.getAttribute('start') : 1,
              blockId: getBlockIdFromDom(dom),
            }),
          },
        ],
        toDOM: node => (node.attrs.order === 1
          ? ['ol', {
            'data-id': node.attrs.blockId,
            'data-attrs': JSON.stringify(node.attrs) || '{}',
          }, 0]
          : ['ol', {
            start: node.attrs.order,
            'data-id': node.attrs.blockId,
            'data-attrs': JSON.stringify(node.attrs) || '{}',
          }, 0]),
      }
    }

    commands({ type, schema }) {
      return () => toggleList(type, schema.nodes.list_item)
    }

    keys({ type, schema }) {
      return {
        'Shift-Ctrl-9': toggleList(type, schema.nodes.list_item),
      }
    }

    inputRules({ type }) {
      return [
        wrappingInputRule(
          /^(\d+)\.\s$/,
          type,
          match => ({ order: +match[1] }),
          (match, node) => node.childCount + node.attrs.order === +match[1],
        ),
      ]
    }
  }

  return new OrderedList()
}

CodexContentEditor.registerWidget(null, {
  displayName: 'codex-editor.block-names.ordered-list',
  name: 'ordered_list',
  group: BLOCK_GROUPS.TEXTUAL,
  icon: 'NumberdList',
  // eslint-disable-next-line global-require
  image: require('../../icons/numbered-list/icon.svg'),
  attrs: {
    order: {
      default: 1,
    },
    blockId: {
      default: null,
    },
  },
  // renderEditor: ImageVue,
  createTiptapNode: orderedListTipTapNode,
  renderEditorBar: OrderedListPanel,
})
