<template>
  <div>
    <TextFormatPanel
      :commands="commands"
      :is-active="isActive"
      :include-hyper-link="true"
      :toggle-link-popup="toggleLinkPopup"
      :editor="editor"
    />
  </div>
</template>

<script>
import TextFormatPanel from '@/components/codex-editor/panels/TextFormatPanel.vue'

export default {
  components: { TextFormatPanel },
  props: ['attrs', 'updateAttrs', 'commands', 'isActive', 'toggleLinkPopup', 'editor'],
}
</script>
