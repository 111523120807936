import { readCurrentUserPermissions } from '@/codex-sdk/users'
import { permissionsToAbilities, setAbilities } from '@/libs/acl/ability'
import loadSitePlugins from '@/plugins'
import { GenerateUUID, toLowerFirstCharObjectKeys } from '@/utils/helpers'
import { apolloProvider, changeApolloClient } from '@/libs/vue-apollo'
import gql from 'graphql-tag'
import { apiAxios } from '@/libs/axios'
import {
  updateActivation, createActivation, deleteActivation, INTEGRATION_STATUSES,
} from '@/codex-sdk/all-integrations'
import { orderBy } from 'lodash'
import i18n, { setLanguage } from '@/libs/i18n'
import { LANGUAGES } from '@/utils/constants'
import { readLocalizationCDN } from '@/codex-sdk/localizations'
import { TYPES, VALUE_TYPES } from '@/views/models/constants'
import { generateSiteNavigationItems, generateViewNavigationItem } from '../sites/modulesSites'

export default {
  addNavigationItem({ commit }, item) {
    return new Promise(resolve => {
      const itemId = GenerateUUID.GENERAL()
      item._id = itemId

      commit('ADD_NAVIGATION_ITEM', item)

      resolve(itemId)
    })
  },
  addNavigationItems({ commit }, items) {
    return new Promise(resolve => {
      items.forEach(item => {
        const itemId = GenerateUUID.GENERAL()
        item._id = itemId

        commit('ADD_NAVIGATION_ITEM', item)
      })

      resolve()
    })
  },
  removeNavigationItem({ commit }, { item, removeBy = 'id' } = {}) {
    return new Promise(resolve => {
      commit('REMOVE_NAVIGATION_ITEM', { item, removeBy })

      resolve()
    })
  },

  removeNavigationItems({ commit }, { group = 'views' } = {}) {
    return new Promise(resolve => {
      commit('REMOVE_NAVIGATION_ITEMS', { group })

      resolve()
    })
  },

  removePluginsNavigationItems({ commit }) {
    return new Promise(resolve => {
      commit('REMOVE_PLUGINS_NAVIGATION_ITEMS')

      resolve()
    })
  },

  updateNavigationItem({ commit }, { item } = {}) {
    return new Promise(resolve => {
      commit('UPDATE_NAVIGATION_ITEM', item)

      resolve()
    })
  },

  addView({ commit }, { item }) {
    return new Promise(resolve => {
      commit('ADD_VIEW', item)

      resolve()
    })
  },

  updateView({ commit }, { item }) {
    return new Promise(resolve => {
      commit('UPDATE_VIEW', item)

      resolve()
    })
  },

  removeView({ commit }, item) {
    return new Promise(resolve => {
      commit('REMOVE_VIEW', item)

      resolve()
    })
  },

  updateViewsNavigationItems({ state, dispatch }) {
    return new Promise(resolve => {
      (async () => {
        dispatch('removeNavigationItems', { group: 'views' })
        await Promise.all(state.views.map(entity => dispatch('addNavigationItems', generateViewNavigationItem(entity))) || [])
      })()
      resolve()
    })
  },

  setBreadcrumbItems({ commit }, items) {
    return new Promise(resolve => {
      commit('SET_BREADCRUMB_ITEMS', items)
      resolve()
    })
  },
  upsertModel({ commit, state }, model) {
    return new Promise(resolve => {
      if (state.models && model) {
        const index = state.models.findIndex(m => m.id === model.id)
        if (index < 0) {
          commit('SET_MODELS', [model, ...state.models])
        } else {
          commit('SET_MODELS', [...state.models.slice(0, index), model, ...state.models.slice(index + 1)])
        }
        resolve()
      }
    })
  },
  removeModel({ commit, state }, models) {
    return new Promise(resolve => {
      if (state.models && models) {
        let array = [...state.models]
        models.forEach(model => {
          array = array.filter(m => m.id != model.id)
        })
        commit('SET_MODELS', array)
        resolve()
      }
    })
  },
  setCurrentAuthor({ commit }) {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const userData = JSON.parse(localStorage.getItem('userData') || '{}')

          const { data } = await apolloProvider.defaultClient.query({
            query: gql`
              query Authors ($userId: String!) {
                authors: authorCollection(limit: 1, where: { userId: { eq: $userId }}) {
                  total
                  items {
                    id
                    firstName
                    lastName
                    byline
                    email
                    image {
                      url
                    }
                  }
                }
              }
            `,
            variables: {
              userId: userData.id,
            },
            fetchPolicy: 'network-only',
          })

          commit('SET_CURRENT_AUTHOR', data?.authors?.items?.[0] || null)

          resolve()
        } catch (e) {
          reject(e)
        }
      })()
    })
  },
  setAllModels({ commit }) {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const { data } = await apolloProvider.defaultClient.query({
            query: gql`
              query allModels {
                allModels: modelCollection(limit: 1000, where: {
                  status: {in: [PUBLISHED]}
                } ) {
                  items {
                    id
                    name
                    alias
                    iconId
                    createdAt
                    configurations {
                      hideInCreateButton
                      hideInCloneButton
                    }
                    fields {
                      type
                      valueType
                      alias
                      name
                      configuration {
                        filterable
                        searchable
                      }
                      validation
                    }
                  }
                }
              }
            `,
          })
          const orderedModels = orderBy(data?.allModels?.items || [], ['createdAt'], 'desc')
          orderedModels.forEach(model => {
            model.fields.forEach(field => {
              field.type = TYPES[field.type]
              field.valueType = VALUE_TYPES[field.valueType]
              field.validation = toLowerFirstCharObjectKeys(field.validation)
            })
          })
          commit('SET_ALL_MODELS', orderedModels)
          resolve()
        } catch (e) {
          commit('SET_ALL_MODELS', [])
          reject(e)
        }
      })()
    })
  },
  setCurrentOrganization({ commit, dispatch, state }, { organizationId }) {
    if (!organizationId) {
      commit('SET_CURRENT_ORGANIZATION', null)
      loadSitePlugins(null)
      return null
    }

    return new Promise((resolve, reject) => {
      (async () => {
        try {
          await changeApolloClient(organizationId)

          const { data } = await apolloProvider.defaultClient.query({
            query: gql`
              query Organization {
                organization {
                  id
                  alias
                  name
                  logo
                  languageCode
                  baseId

                  organizationPlugins {
                    items {
                      id
                      plugin {
                        name
                        activeVersion {
                          version
                        }
                      }
                    }
                  }

                  storage {
                    id
                    cdnDomain
                  }
                  integrations {
                    items {
                      id
                      alias
                      attrs
                      status
                      createdAt
                      updatedAt
                    }
                  }
                  sites (limit: 1000) {
                    items {
                      id
                      name
                      alias
                      description
                      logo
                      domain
                      previewDomain
                      defaultFolder {
                        id
                        name
                      }
                    }
                  }
                  models (limit: 1000) {
                    items {
                      id
                      name
                      alias
                      iconId
                      createdAt
                      configurations {
                        hideInCreateButton
                        hideInCloneButton
                      }
                      fields {
                        type
                        valueType
                        alias
                        name
                        configuration {
                          filterable
                          searchable
                        }
                        validation
                      }
                    }
                  }
                  views(limit: 1000) {
                    items {
                      id
                      name
                      type
                      filters
                      path
                      searchTerm
                      siteId
                      createdAt
                      updatedAt
                      createdBy {
                        id
                        firstName
                        lastName
                        imageUrl
                      }
                      updatedBy {
                        id
                        firstName
                        lastName
                        imageUrl
                      }
                    }
                  }
                }
              }
            `,
          })

          const {
            organization: {
              models, sites, views, languageCode, ...organization
            },
          } = data

          // Set language from organization
          const language = localStorage.getItem('language')
          if (!language) {
            if (languageCode) {
              setLanguage(languageCode, false)
            } else {
              setLanguage(LANGUAGES.ENGLISH, false)
            }
          }

          commit('SET_CURRENT_ORGANIZATION', organization)
          const orderedModels = orderBy(models?.items || [], ['createdAt'], 'desc')
          orderedModels.forEach(model => {
            model.fields.forEach(field => {
              field.type = TYPES[field.type]
              field.valueType = VALUE_TYPES[field.valueType]
              field.validation = toLowerFirstCharObjectKeys(field.validation)
            })
          })
          commit('SET_MODELS', orderedModels)
          commit('sites/SET_SITES', sites?.items || [], { root: true })

          commit('SET_INTEGRATION_CONNECTIONS', organization.integrations.items.map(item => {
            item.status = INTEGRATION_STATUSES[item.status]
            item.organizationId = organizationId
            item.integration = item.alias
            return item
          }))

          await Promise.allSettled([
            readCurrentUserPermissions().then(async res => {
              const aclAbilities = await permissionsToAbilities(res.data)
              setAbilities(aclAbilities)
              commit('SET_CURRENT_USER_PERMISSIONS', aclAbilities)
            }),
            dispatch('setCurrentAuthor'),
            dispatch('setAllModels'),
          ])

          // await readCurrentUserPermissions().then(async res => {
          //   const aclAbilities = await permissionsToAbilities(res.data)
          //   setAbilities(aclAbilities)
          //   commit('SET_CURRENT_USER_PERMISSIONS', aclAbilities)
          // })

          // await dispatch('setCurrentAuthor')

          // await dispatch('setAllModels')

          // Add entries and lists to menu for each site
          await Promise.all(sites?.items.map(site => dispatch('addNavigationItem', generateSiteNavigationItems(site, views?.items?.filter(view => view.siteId == site.id)?.length > 0))) || [])
          // Add Views to menu
          commit('SET_VIEWS', views?.items || [])
          await Promise.all(state?.views?.map(entity => dispatch('addNavigationItems', generateViewNavigationItem(entity))) || [])

          try {
            organization.plugins = {
              items: organization?.organizationPlugins?.items.filter(p => p.plugin).map(p => ({
                id: p.id,
                pluginName: p.plugin.name,
                pluginVersion: p.plugin.activeVersion?.version,
              })),
            }
            await loadSitePlugins(organizationId, organization?.plugins?.items)
          } catch (error) {
            console.log('Error loading plugins', error)
          }

          // Updates Codex user's information fetched from "Porta"
          apiAxios.put('{organization}/users')

          readLocalizationCDN(organization.alias, i18n.locale)
            .then(response => {
              i18n.mergeLocaleMessage(i18n.locale, response.data)
            })

          resolve()
        } catch (e) {
          reject(e)
        }
      })()
    })
  },
  setCurrentSite({ commit }, { siteId }) {
    if (!siteId) {
      commit('SET_CURRENT_SITE', null)
      commit('SET_CURRENT_USER_PERMISSIONS', false)
      return null
    }

    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const { data } = await apolloProvider.defaultClient.query({
            query: gql`
              query Site($siteId: String!) {
                site(id: $siteId) {
                  id
                  name
                  alias
                  description
                  logo
                  domain
                  previewDomain
                  defaultFolder {
                    id
                    name
                  }
                  attrs
                }
              }
            `,
            variables: {
              siteId,
            },
          })

          commit('SET_CURRENT_SITE', data.site)

          await readCurrentUserPermissions(siteId).then(async res => {
            const aclAbilities = await permissionsToAbilities(res.data)
            setAbilities(aclAbilities)
            commit('SET_CURRENT_USER_PERMISSIONS', aclAbilities)
          })

          resolve()
        } catch (e) {
          reject(e)
        }
      })()
    })
  },

  addConnections({ commit }, connection) {
    return new Promise((resolve, reject) => {
      createActivation(connection)
        .then(response => {
          const activations = response.data
          commit('SET_INTEGRATION_CONNECTIONS', activations)

          // dispatch('addConnections', integrations)
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },

  updateConnections({ commit }, connection) {
    return new Promise((resolve, reject) => {
      updateActivation(connection)
        .then(response => {
          const activations = response.data
          commit('UPDATE_INTEGRATION_CONNECTIONS', activations)

          // dispatch('addConnections', integrations)
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },

  removeConnections({ commit }, connection) {
    return new Promise((resolve, reject) => {
      deleteActivation(connection.integration, connection.id)
        .then(response => {
          commit('REMOVE_INTEGRATION_CONNECTIONS', connection)

          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
}
