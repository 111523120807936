<template>
  <label>
    <span class="custom-url-radio">
      <b-form-radio
        v-model="_value"
        class="custom-url-radio__input"
        :name="name"
        :value="val"
      />
      <img
        :src="require(`@/assets/images/menu-icon/${icon}`)"
        alt=""
      >
      <span class="custom-url-radio__label">{{ label || 'no label' }}</span>
    </span>
  </label>
</template>

<script>

export default {
  name: 'UrlCustomRadio',
  props: ['label', 'value', 'name', 'val', 'icon'],
  computed: {
    _value: {
      get() { return this.value },
      set(v) { this.$emit('input', v) },
    },
  },
}
</script>

<style>
.custom-url-radio {
    position: relative;
    min-width: 180px;
    min-height: 150px;
    background: #F6F7F9;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 8px;
}
.custom-url-radio__input {
    align-self: flex-start;
}

.custom-url-radio__label {
    color: #667C99;
    font-size: 14px;
    line-height: 18px;
}

</style>
