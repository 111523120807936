import { truncate } from 'lodash'
import TagParent from './TagParent.vue'

export default class TagParentFilter {
    static COMPONENT = TagParent;

    static OPERATORS = {
      include: 'include',
      exclude: 'exclude',
    }

    static type = 'parentTag'

    operator = null;

    value = null;

    constructor({ operator = TagParentFilter.OPERATORS.include, value = [] } = {}) {
      this.operator = operator
      this.value = value
    }

    set({ operator = TagParentFilter.OPERATORS.include, value = [] }) {
      this.operator = operator
      this.value = value
    }

    getCount() {
      return this.value?.length
    }

    getValueLabel() {
      return this.value.map(m => truncate(m.tagValue, { length: 20, separator: ' ' })).join(', ')
    }

    toLoad() {
      return {
        authors: this.value,
      }
    }

    asQueryParam() {
      return {
        operator: this.operator,
        value: this.value,
      }
    }

    asGraphQL() {
      switch (this.operator) {
        case TagParentFilter.OPERATORS.include:
          return {
            in: this.value?.map(t => t.id),
          }
        case TagParentFilter.OPERATORS.exclude:
          return {
            notIn: this.value,
          }
        default:
          return null
      }
    }

    static validate({ operator, value }) {
      if (!operator) {
        return 'Operator is required'
      }

      if (!value || value.length === 0) {
        return 'Value is required'
      }

      return true
    }

    static fromQueryParam({ operator, value }) {
      return new TagParentFilter({
        operator,
        value,
      })
    }
}
