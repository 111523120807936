import { cloneDeep } from 'lodash'

export default {
  siteNavigationItems(state) {
    const items = cloneDeep(state.siteNavigationItems)

    function insertExtraItem(extraItem, addInItems) {
      if (!addInItems || addInItems.constructor !== Array) return false

      const insertBeforeAfter = extraItem.insertBefore || extraItem.insertAfter
      if (insertBeforeAfter) {
        const insertingAfter = !extraItem.insertBefore
        const index = addInItems.findIndex(findItem => findItem.staticName === insertBeforeAfter || findItem.name === insertBeforeAfter)
        if (index !== -1) {
          const extraItemCopy = cloneDeep(extraItem)
          extraItemCopy.name = extraItemCopy.name.replace('{siteId}', addInItems[index].siteId)

          if (extraItemCopy?.children?.length) {
            extraItemCopy.children.forEach(child => {
              child.name = child.name.replace('{siteId}', addInItems[index].siteId)

              if (child?.children?.length) {
                child.children.forEach(child2 => {
                  child2.name = child2.name.replace('{siteId}', addInItems[index].siteId)
                })
              }
            })
          }

          addInItems.splice(index + insertingAfter, 0, extraItemCopy)
          return true
        }
      }

      const insertAt = extraItem.insertAt
      if (insertAt && insertAt.constructor === Object && insertAt.parent && !Number.isNaN(insertAt.index)) {
        const itemFound = addInItems.find(findItem => findItem.staticName === insertAt.parent || findItem.name === insertAt.parent)
        if (itemFound) {
          if (!itemFound.children || itemFound.children.constructor !== Array) itemFound.children = []

          itemFound.children.splice(insertAt.index, 0, extraItem)

          return true
        }
      }

      addInItems.forEach(addInItem => {
        insertExtraItem(extraItem, addInItem.children)
      })

      return false
    } // function insertExtraItem

    if (state.extraNavigationItems && state.extraNavigationItems.length) {
      cloneDeep(state.extraNavigationItems).sort((a, b) => {
        if (a.order > b.order) return 1
        if (a.order < b.order) return -1
        return 0
      }).forEach(extraItem => {
        insertExtraItem(extraItem, items)
      })
    }

    return items
  },
  getModel: state => id => state.allModels.find(e => e.id === id || e.alias === id),
  // getVpConnections: state => alias => state.allIntegrationConnections.find(e => e.items.integration.toLowerCase().includes(alias)),
  getConnections: state => alias => state.allIntegrationConnections.filter(e => (e.integration === alias)),
}
