import CodexContentEditor from '../../CodexContentEditor'
import YouTubeEmbedVue from './YouTubeEmbed.vue'
import YouTubeEmbedPanel from './YouTubeEmbedPanel.vue'
import { BLOCK_GROUPS } from '../constants'

CodexContentEditor.registerWidget('codex', {
  displayName: 'codex-editor.block-names.youtube-embed',
  name: 'youtube_embed',
  group: BLOCK_GROUPS.EMBEDS,
  // eslint-disable-next-line global-require
  image: require('../../icons/youtube-video/icon.svg'),
  attrs: {
    blockId: {
      default: null,
    },
    src: {
      default: null,
    },
    videoId: {
      default: null,
    },
    caption: {
      default: null,
    },
    align: {
      default: 'left',
    },
    width: {
      default: '50%',
    },
  },
  renderEditor: YouTubeEmbedVue,
  renderEditorBar: YouTubeEmbedPanel,
})
