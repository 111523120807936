import { toSlugV2 } from '@/utils/helpers'
import { apolloProvider } from '@/libs/vue-apollo'
import gql from 'graphql-tag'
import { getBlocks, TYPES, VALUE_TYPES } from '@/views/models/constants'
import { cloneDeep } from 'lodash'

export const LOCK_TIME_OUT = 25 // seconds

export async function generateURL(pattern, entry, model) {
  if (!pattern || !entry) return ''

  const sections = []
  const authors = []

  function getPlaceholders(template) {
    const regexp = /{(.*?)}/g
    const array = [...template.matchAll(regexp)]
    return array.map(a => a[1])
  }

  // eslint-disable-next-line no-unused-vars
  function slugify(value) {
    return toSlugV2(value)
  }
  // eslint-disable-next-line no-unused-vars
  function getDate(value) {
    try {
      if (!value) return ''
      const date = new Date(value)
      return date.getDate()
    } catch {
      //
    }
    return value
  }
  // eslint-disable-next-line no-unused-vars
  function getMonth(value) {
    try {
      if (!value) return ''
      const date = new Date(value)
      return date.getMonth() + 1
    } catch {
      //
    }
    return value
  }
  // eslint-disable-next-line no-unused-vars
  function getYear(value) {
    try {
      if (!value) return ''
      const date = new Date(value)
      return date.getFullYear()
    } catch {
      //
    }
    return value
  }

  async function getSections(ids = []) {
    ids = ids.filter(e => e)
    if (!ids.length) return
    try {
      const { data } = await apolloProvider.defaultClient.query({
        query: gql`
          query ($ids: [String!]) {
            sectionCollection (where: {
              id: {
                in: $ids
              }
            }) {
              items {
                id
                url
              }
            }
          }
        `,
        variables: {
          ids,
        },
      })
      if (data?.sectionCollection?.items) {
        sections.push(...data?.sectionCollection?.items)
      }
    } catch (e) {
      console.log(e)
    }
  }

  async function getAuthors(ids = []) {
    ids = ids.filter(e => e)
    if (!ids.length) return
    try {
      const { data } = await apolloProvider.defaultClient.query({
        query: gql`
          query ($ids: [String!]) {
            authorCollection (where: {
              id: {
                in: $ids
              }
            }) {
              items {
                id
                url
              }
            }
          }
        `,
        variables: {
          ids,
        },
      })
      if (data?.authorCollection?.items) {
        authors.push(...data?.authorCollection?.items)
      }
    } catch (e) {
      console.log(e)
    }
  }

  // eslint-disable-next-line no-unused-vars
  function getMain(value) {
    try {
      return value.find(s => s.isMain)
    } catch {
      //
    }
    return {}
  }
  // eslint-disable-next-line no-unused-vars
  function getFirst(value) {
    try {
      return value?.[0]
    } catch {
      //
    }
    return {}
  }

  function isSection(alias) {
    const field = getBlocks(model.blocks, block => block.isField && block.attrs.alias === alias)?.[0]
    return field && field.attrs.type === TYPES.SECTION
  }

  function isAuthor(alias) {
    const field = getBlocks(model.blocks, block => block.isField && block.attrs.alias === alias)?.[0]
    return field && field.attrs.type === TYPES.AUTHOR
  }

  function getField(alias) {
    return getBlocks(model.blocks, block => block.isField && block.attrs.alias === alias)?.[0]
  }

  // eslint-disable-next-line no-unused-vars
  function mergeSections(value = []) {
    value.forEach(e => {
      const section = sections.find(s => s.id == e.id)
      if (section) {
        e.url = section.url
      }
    })
  }

  function mergeAuthors(value = []) {
    value.forEach(e => {
      const author = authors.find(s => s.id == e.id)
      if (author) {
        e.url = author.url
      }
    })
  }

  // eslint-disable-next-line no-unused-vars
  function getAlias(path) {
    const regExp = /\(([^)]+)\)/
    const matches = regExp.exec(path)
    if (matches) {
      return matches?.[1]?.split('.')?.[1] || matches?.[1]?.split('.')?.[0]
    }
    return path?.split('.')?.[1] || path?.split('.')?.[0]
  }

  let url = pattern
  // eslint-disable-next-line no-restricted-syntax
  for (const path of getPlaceholders(pattern)) {
    let value = ''
    try {
      const alias = getAlias(path)
      // eslint-disable-next-line no-unused-vars
      const { content, system, id } = cloneDeep(entry)
      if (isSection(alias)) {
        const field = getField(getAlias(path))
        if (field.attrs.valueType === VALUE_TYPES.LIST) {
          // eslint-disable-next-line no-await-in-loop
          await getSections(content?.[alias]?.map(s => s.id))
          mergeSections(content?.[alias])
        } else {
          // eslint-disable-next-line no-await-in-loop
          await getSections([content?.[alias]?.id])
          mergeSections([content?.[alias]])
        }
      }

      if (isAuthor(alias)) {
        const field = getField(getAlias(path))
        if (field.attrs.valueType === VALUE_TYPES.LIST) {
          // eslint-disable-next-line no-await-in-loop
          await getAuthors(content?.[alias]?.map(s => s.id))
          mergeAuthors(content?.[alias])
        } else {
          // eslint-disable-next-line no-await-in-loop
          await getAuthors([content?.[alias]?.id])
          mergeAuthors([content?.[alias]])
        }
      }

      // eslint-disable-next-line no-eval
      value = eval(`${path}`) || ''
    } catch {
      //
    }

    url = url.replaceAll(`{${path}}`, value).replaceAll('//', '/')
  }

  return url
}
