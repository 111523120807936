<template>
  <div class="author-field-initial__container">
    <b-form-group
      :label="$t('fields.codex-field-authors.general.field-label')"
      label-for="author-field__name"
    >
      <b-form-input
        id="author-field__name"
        v-model="name"
        :placeholder="$t('fields.codex-field-authors.general.field-name-placeholder')"
      />
    </b-form-group>
    <b-form-group
      :label="$t('fields.codex-field-authors.general.alias-label')"
      label-for="author-field__alias"
    >
      <b-form-input
        id="author-field__alias"
        :value="alias"
        disabled
        :placeholder="$t('fields.codex-field-authors.general.alias-name-placeholder')"
      />
    </b-form-group>
    <b-form-group
      :label="$t('fields.codex-field-authors.general.description-label')"
      label-for="author-field__description"
    >
      <b-form-textarea
        id="author-field__description"
        v-model="description"
        :placeholder="$t('fields.codex-field-authors.general.description-placeholder')"
        rows="2"
        no-resize
      />
    </b-form-group>
    <b-form-group
      :label="$t('fields.codex-field-authors.general.display')"
    >
      <b-form-radio-group
        v-model="previewType"
        disabled
      >
        <b-form-radio :value="VALUE_TYPES.SINGLE">
          {{ $t('fields.codex-field-authors.general.single') }}
        </b-form-radio>
        <b-form-radio :value="VALUE_TYPES.LIST">
          {{ $t('fields.codex-field-authors.general.list') }}
        </b-form-radio>
      </b-form-radio-group>
    </b-form-group>
  </div>
</template>

<script>
import { generateComputedPropsFromAttrs } from '@/components/codex-layout-editor/BuilderUtils'
import FieldValidationMixin from '@/components/fields/FieldValidationMixin'
import { VALUE_TYPES } from '@/views/models/constants'
import BaseFieldMixin from '@/components/fields/BaseFieldMixin'

export default {
  inject: ['validateAlias'],
  mixins: [BaseFieldMixin, FieldValidationMixin],
  data() {
    return {
      VALUE_TYPES,
    }
  },
  computed: {
    ...generateComputedPropsFromAttrs([
      'alias',
      'name',
      'description',
      'previewType',
      'configured',
    ]),
    previewType: {
      get() {
        return this.widget.attrs.valueType
      },
      set() {},
    },
  },
}
</script>
