import { TYPES, VALUE_TYPES, TEXT_FIELD_APPEARANCES } from '@/views/models/constants'

export const ASSET_COMPARE_TYPES = Object.freeze({
  LABELS: 'LABELS',
})

export const ASSET_COMPARE_MODEL = Object.freeze({
  fields: [
    {
      appearance: TEXT_FIELD_APPEARANCES.SINGLE_LINE,
      type: TYPES.TEXT,
      name: 'title',
      alias: 'title',
      valueType: VALUE_TYPES.SINGLE,
    },
    {
      appearance: TEXT_FIELD_APPEARANCES.SINGLE_LINE,
      type: ASSET_COMPARE_TYPES.LABELS,
      name: 'labels',
      alias: 'labels',
      valueType: VALUE_TYPES.LIST,
    },
    {
      appearance: TEXT_FIELD_APPEARANCES.MULTIPLE_LINES,
      type: TYPES.TEXT,
      name: 'caption',
      alias: 'caption',
      valueType: VALUE_TYPES.SINGLE,
    },
    {
      appearance: TEXT_FIELD_APPEARANCES.MULTIPLE_LINES,
      type: TYPES.TEXT,
      name: 'alt',
      alias: 'alt',
      valueType: VALUE_TYPES.SINGLE,
    },
    {
      appearance: TEXT_FIELD_APPEARANCES.SINGLE_LINE,
      type: TYPES.TEXT,
      name: 'source',
      alias: 'source',
      valueType: VALUE_TYPES.SINGLE,
    },
    {
      appearance: TEXT_FIELD_APPEARANCES.SINGLE_LINE,
      type: TYPES.TEXT,
      name: 'author name',
      alias: 'author',
      valueType: VALUE_TYPES.SINGLE,
    },
    {
      appearance: TEXT_FIELD_APPEARANCES.SINGLE_LINE,
      type: TYPES.TEXT,
      name: 'copyright',
      alias: 'copyright',
      valueType: VALUE_TYPES.SINGLE,
    },
    {
      appearance: TEXT_FIELD_APPEARANCES.SINGLE_LINE,
      type: TYPES.TAG,
      name: 'tags',
      alias: 'tags',
      valueType: VALUE_TYPES.LIST,
    },
    {
      appearance: TEXT_FIELD_APPEARANCES.SINGLE_LINE,
      type: TYPES.DATE_TIME,
      name: 'Expiration Date',
      alias: 'expiresAt',
      valueType: VALUE_TYPES.SINGLE,
    },
  ],
})
