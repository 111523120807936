<template>
  <div class="ai-popup">
    <b-input-group>
      <b-form-input
        v-model="prompt"
        class="prompt-input"
        :placeholder="$t('fields.general.ai-popup.prompt')"
        @keyup.enter="generateContent(prompt)"
      />
      <b-input-group-append>
        <b-input-group-text class="prompt-input-append">
          <b-spinner
            v-if="loading"
            small
            class="prompt-input-append-icon"
          />
          <GjIcon
            v-else
            name="SendMessageAltFilled"
            class="prompt-input-append-icon"
            size="22"
            @click.native.stop="generateContent(prompt)"
          />
        </b-input-group-text>
      </b-input-group-append>
    </b-input-group>

    <div class="results-header">
      <div class="results-header_left">
        <AiIcon
          class="results-header_left-icon"
          size="20"
          :class="{'results-header_left-icon__failed': error}"
        />
        <span class="results-header_left-title">
          {{ error ? $t('fields.general.ai-popup.results.error.title') : $t('fields.general.ai-popup.results.success.title') }}
        </span>
      </div>
      <div
        v-if="showRetry"
        class="results-header_right"
        @click="generateContent(previousPrompt)"
      >
        <GjIcon
          name="Refresh"
          size="18"
          class="results-header_right-icon"
        />
        <span class="results-header_right-label">
          {{ $t('fields.general.ai-popup.results.retry-label') }}
        </span>
      </div>
      <div
        v-else
        class="results-header_right"
        @click="generateContent('')"
      >
        <span class="results-header_right-label">
          {{ $t('fields.general.ai-popup.results.auto-generate') }}
        </span>
      </div>
    </div>
    <div class="results-container">
      <span
        v-if="error"
        class="results-error"
      >
        {{ $t('fields.general.ai-popup.results.error.description') }}
      </span>
      <div
        v-for="suggest in suggestions"
        :key="suggest"
        class="result-element"
        @click="setValue(suggest)"
      >
        <span class="result-element-text">
          {{ suggest }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import AiIcon from '@/components/ai/AiIcon.vue'
import { generateText } from '@/codex-sdk/ai'

export default {
  name: 'AiPopup',
  inject: ['createEntry', 'entry'],
  components: {
    AiIcon,
  },
  props: {
    alias: {
      type: String,
      default: '',
    },
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      prompt: '',
      previousPrompt: '',
      showRetry: false,
      suggestions: [],
      error: false,
    }
  },
  mounted() {
    // this.generateContent(false)
    setTimeout(() => {
      document.addEventListener('click', this.hideOnClick)
    }, 0)
  },
  beforeDestroy() {
    document.removeEventListener('click', this.hideOnClick)
  },
  methods: {
    hideOnClick(event) {
      if (!this.$el.contains(event.target)) {
        this.$emit('close')
      }
    },
    async generateContent(prompt = '') {
      try {
        if (this.loading) return
        if (!this.entry()?.id) {
          await this.createEntry()
        }
        this.previousPrompt = prompt

        this.error = false
        this.loading = true
        const { data } = await generateText({
          entryId: this.entry()?.id,
          fieldAlias: this.alias,
          prompt: this.previousPrompt,
        }, { successTitle: false })
        this.suggestions = data.suggestions
      } catch (e) {
        this.error = true
        this.suggestions = []
        console.log(e)
      }
      this.showRetry = true
      this.loading = false
    },
    setValue(suggest) {
      this.$emit('setValue', suggest)
      this.$emit('close')
    },
  },
}
</script>

<style lang="scss">
.ai-popup {
  position: absolute;
  top: 100%;
  left: 0;
  width: 550px;
  background: #FFFFFF;
  border: 1px solid #E0E5EB;
  box-shadow: 0 4px 20px rgba(5, 45, 97, 0.07);
  border-radius: 4px;
  margin-top: 4px;
  z-index: 10;
  padding: 4px 12px 12px;
  display: flex;
  flex-direction: column;
  gap: 8px;

  .prompt-input {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    border: none;
    padding-left: 0;
  }

  .prompt-input-append {
    border: none;
    padding-left: 0;
    padding-right: 0;
  }

  .prompt-input-append-icon {
    color: #206ED5;
  }

  svg.prompt-input-append-icon {
    cursor: pointer;
  }

  .results-header {
    display: flex;
    justify-content: space-between
  }

  .results-header_left {
    display: flex;
    gap: 8px;
    align-items: center;
  }

  .results-header_left-icon {
    color: #6A9EE3;
  }

  .results-header_left-icon__failed {
    color: #676777;
  }

  .results-header_left-title {
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    text-align: left;
    color: #1E1E36;
  }

  .results-header_right {
    display: flex;
    gap: 8px;
    align-items: center;
    cursor: pointer;
    color: #206ED5;
  }

  .results-header_right-label {
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    text-align: center;
  }

  .results-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .results-error {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    margin-left: 28px;
  }

  .result-element {
    border: 1px solid #DEDEE2;
    border-radius: 6px;
    padding: 8px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    cursor: pointer;
  }

  .result-element-text {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    color: #1E1E36;
  }

  .result-element-button {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    color: #206ED5;
  }
}

</style>
