<template>
  <b-modal
    v-model="showPopup"
    :title="_id ? $t('urls.urlModal.editUrlTitle') : $t('urls.urlModal.createUrlTitle')"
    size="lg"
    centered
    :static="true"
    no-close-on-backdrop
  >
    <b-form-group
      :label="_id ? $t('urls.urlModal.editUrlTitle') : $t('urls.urlModal.createUrlTitle')"
      :invalid-feedback="hasURL ? $t('urls.urlModal.invalid-url') : $t('urls.urlModal.requiredUrl')"
      :state="!(!validUrl && validated)"
    >
      <b-input-group
        :prepend="domain"
        class="disabled"
        :disabled="true"
      >
        <b-form-input
          v-model="entity.url"
          placeholder="/pagename/section/news-name/id3412"
        />
      </b-input-group>
    </b-form-group>
    <div
      v-if="!_id"
      class="create-url-modal__radios"
    >
      <UrlCustomRadio
        v-model="entity.entityType"
        :label="$t('urls.urlModal.entryType')"
        icon="Entry.svg"
        :val="URL_ENTITY_TYPES.ENTRY"
      />
      <UrlCustomRadio
        v-model="entity.entityType"
        :label="$t('urls.urlModal.sectionType')"
        icon="Section.svg"
        :val="URL_ENTITY_TYPES.SECTION"
      />
    </div>
    <b-form-group
      v-if="!_id"
      :label="$t('urls.urlModal.redirectType')"
    >
      <b-form-checkbox
        v-model="isRedirect"
        switch
      />
    </b-form-group>
    <b-form-group
      v-if="!_id"
      :label="$t('urls.urlModal.redirectTo')"
      class="create-url-modal__selects"
      :invalid-feedback="$t('urls.urlModal.requiredField')"
      :state="!(!validSelected && validated)"
    >
      <div
        v-if="isRedirect"
        class="create-url-modal__entry-select"
        @click="browseUrls"
      >
        <span>
          {{ selected && selected.url ? selected.url : $t('urls.urlModal.selectUrl') }}
        </span>
        <GjIcon name="ArrowDown" />
      </div>
      <div
        v-else-if="entity.entityType === URL_ENTITY_TYPES.ENTRY"
        class="create-url-modal__entry-select"
        :title="entryLabel"
        @click="browseEntries"
      >
        <span>
          {{ entryLabel }}
        </span>
        <GjIcon name="ArrowDown" />
      </div>
      <div v-else>
        <SectionsSelect
          v-model="selected"
          :multiple="false"
          :placeholder="$t('urls.urlModal.selectSection')"
        />
      </div>
    </b-form-group>
    <b-form-group
      v-if="isRedirect || entity.entityType === URL_ENTITY_TYPES.REDIRECT"
      :label="$t('urls.urlModal.statusCode')"
    >
      <v-select
        v-model="entity.statusCode"
        :options="statusCodesOptions"
        :reduce="e => e.value"
        :clearable="false"
      />
    </b-form-group>
    <template #modal-footer>
      <b-button
        variant="outline-dark"
        @click="handleCancel"
      >
        {{ $t('general.cancel') }}
      </b-button>
      <b-button
        variant="primary"
        @click="handleOk"
      >
        {{ $t('general.save') }}
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import PopupsMixin from '@/components/popups/PopupsMixin'
import SectionsSelect from '@/components/SectionsSelect.vue'
import {
  createUrl, readUrl, updateUrl, URL_ENTITY_TYPES, urlDefaults, URL_REDIRECT_STATUS_CODE, mapRedirectStatusCode,
} from '@/codex-sdk/urls'
import { canCreateUrl, canEditUrl } from '@/codex-permissions/urls'
import UrlCustomRadio from '@/views/urls/UrlCustomRadio.vue'
import { ENTRY_STATUSES, ENTRY_STATUSES_STRING } from '@/codex-sdk/entries'

export default {
  name: 'EditUrlPopup',
  components: {
    UrlCustomRadio,
    SectionsSelect,
  },
  inject: ['showEntriesPopup', 'showUrlsPopup'],
  mixins: [PopupsMixin],
  data() {
    return {
      URL_ENTITY_TYPES,
      domain: `https://${this.$store.state.general.currentSite.domain}`,
      selected: null,
      validated: false,
      entity: urlDefaults(),
      isRedirect: false,
    }
  },
  computed: {
    _id() {
      return this.data?.id || null
    },
    hasURL() {
      return this.entity.url.length > 0
    },
    validUrl() {
      return this.hasURL && this.entity.url.startsWith('/')
    },
    validSelected() {
      return !!this.selected || !!this._id
    },
    entryLabel() {
      if (this.selected !== null && (this.entity.entityType === URL_ENTITY_TYPES.ENTRY)) {
        if (this.selected.system && this.selected.system.title) {
          return this.selected.system.title
        }
        return this.$t('urls.urlModal.noTitle')
      }
      return this.$t('urls.urlModal.selectEntry')
    },
    statusCodesOptions() {
      return [
        { label: `${URL_REDIRECT_STATUS_CODE.PERMANENT}-${this.$t(mapRedirectStatusCode(URL_REDIRECT_STATUS_CODE.PERMANENT))}`, value: URL_REDIRECT_STATUS_CODE.PERMANENT },
        { label: `${URL_REDIRECT_STATUS_CODE.TEMPORARY}-${this.$t(mapRedirectStatusCode(URL_REDIRECT_STATUS_CODE.TEMPORARY))}`, value: URL_REDIRECT_STATUS_CODE.TEMPORARY },
        { label: `${URL_REDIRECT_STATUS_CODE.GONE}-${this.$t(mapRedirectStatusCode(URL_REDIRECT_STATUS_CODE.GONE))}`, value: URL_REDIRECT_STATUS_CODE.GONE },
      ]
    },
  },
  watch: {
    'entity.entityType': function () {
      this.selected = null
    },
    selected: {
      handler(value) {
        if ((this.entity.entityType === URL_ENTITY_TYPES.ENTRY) && value?.id) {
          this.entity.entityId = value.id
        } else if (this.entity.entityType === URL_ENTITY_TYPES.SECTION && value) {
          this.entity.entityId = value.id || value
        } else if (this.entity.entityType === URL_ENTITY_TYPES.REDIRECT && value) {
          this.entity.redirectUrl = value.url
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.fetchUrl()
  },
  methods: {
    async saveUrl() {
      try {
        if (this._id) {
          if (!(await canEditUrl({ entity: this.entity })).can) return
          const { data } = await updateUrl(this.entity)
          this.entity = data
        } else {
          if (!(await canCreateUrl()).can) return
          const { data } = await createUrl(this.entity)
          this.entity = data
        }
        this.closePopup(this.entity)
      } catch (e) {
        console.log(e)
      }
    },
    async fetchUrl() {
      if (!this._id) return
      try {
        const { data } = await readUrl(this._id)
        this.entity = data
      } catch (e) {
        console.log(e)
      }
    },
    async handleOk() {
      const isValid = this.validateData()
      if (isValid) {
        if (this.isRedirect) {
          this.entity.entityType = URL_ENTITY_TYPES.REDIRECT
        }
        await this.saveUrl()
        this.closePopup(this.selected)
      }
    },
    handleCancel() {
      this.closePopup(false)
    },
    validateData() {
      this.validated = true
      return !(!this.validUrl || !this.validSelected)
    },
    async browseEntries() {
      const res = await this.showEntriesPopup({ limit: 1, statuses: [ENTRY_STATUSES_STRING[ENTRY_STATUSES.PUBLISHED], ENTRY_STATUSES_STRING[ENTRY_STATUSES.EDITED]] })
      if (res?.[0]) {
        this.selected = res[0]
        this.entity.entityId = this.selected.id
        this.entity.model = this.selected.system.modelAlias
      }
    },
    async browseUrls() {
      const res = await this.showUrlsPopup({ type: this.entity.entityType })
      if (res?.[0]) {
        this.selected = res[0]
        this.entity.entityId = this.selected.id
      }
    },
  },
}

</script>

<style lang="scss">
.create-url-modal__radios {
  display: flex;
  align-self: center;
  gap: 16px;

  > label {
    width: 100%;
  }
}

.create-url-modal__entry-select {
  cursor: pointer;
  background: #FFFFFF;
  border: 1px solid #E0E5EB;
  border-radius: 4px;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  padding: 12px 36px 12px 12px;
  position: relative;
  overflow: hidden;
  span {
    display: inline-flex;
    overflow: hidden;
    max-width: 100%;
  }
  svg {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 12px;
  }
}

.create-url-modal__selects {
  margin-top: 16px;
}

</style>
