<template>
  <b-modal
    id="url-configuration-popup"
    v-model="showModal"
    size="lg"
    :title="$t('global-components.custom-url.title') "
    :cancel-title="$t('global-components.custom-url.cancel')"
    cancel-variant="outline-primary"
    :ok-title="$t('global-components.custom-url.save')"
    ok-variant="primary"
    centered
    @ok="save(pattern)"
    @close="close"
  >
    <div class="url__dropdown">
      <b-form-group>
        <div class="url_dropdown-info">
          <span>{{ $t('global-components.custom-url.url-info') }}</span>
        </div>
        <div class="url__sidebar-form-container">
          <b-form-input
            v-model="pattern"
            class="url__sidebar-value at-urls-path-input"
          />
          <b-dropdown
            variant="none"
            no-caret
          >
            <template #button-content>
              <div class="url__sidebar-value-dropdown at-urls-embed-path-dropdown-button">
                <GjIcon
                  name="PlusAlt"
                  style="color: #667C99"
                />
              </div>
            </template>
            <b-dropdown-item
              v-for="(field, idx) in possibleFields"
              :key="idx"
              @click="addPlaceholder(field)"
            >
              {{ field }}
            </b-dropdown-item>
          </b-dropdown>
        </div>
        <p
          v-if="false"
          class="url_dropdown-info-preview"
        >
          <b>
            {{ $t('global-components.custom-url.dropdown-preview') }}
          </b>
          https://yourdomain.com/id3412/pagename
        </p>
      </b-form-group>
    </div>
    <template>
      <div class="url-card">
        <GjIcon
          name="Info_fill"
          class="url-icon"
          style="color:#206ED5"
        />

        <div class="url-starter__info">
          <p class="url-starter__info-title">
            {{ $t('global-components.custom-url.how-to-configure-url') }}
          </p>
          <p class="url-starter__info-description">
            {{ $t('global-components.custom-url.description.1') }}
            <strong>{{ $t('global-components.custom-url.description.2') }}</strong>
            {{ $t('global-components.custom-url.description.3') }}
            {{ $t('global-components.custom-url.description.4') }}
          </p>
          <p class="url-starter__info-description">
            {{ $t('global-components.custom-url.description.5') }}
          </p>
          <ul class="url-starter__info-description">
            <li>{{ $t('global-components.custom-url.description.types.text-field') }} <code>{slugify(content.{{ $t('global-components.custom-url.description.field-alias') }})}</code></li>
            <li>{{ $t('global-components.custom-url.description.types.date-time') }} <code>{getDate(content.{{ $t('global-components.custom-url.description.field-alias') }})}</code>, <code>{getMonth(content.{{ $t('global-components.custom-url.description.field-alias') }})}</code>, <code>{getYear(content.{{ $t('global-components.custom-url.description.field-alias') }})}</code></li>
            <li>{{ $t('global-components.custom-url.description.types.section-single') }} <code>{content.{{ $t('global-components.custom-url.description.field-alias') }}.id}</code>, <code>{content.{{ $t('global-components.custom-url.description.field-alias') }}.url}</code></li>
            <li>{{ $t('global-components.custom-url.description.types.section-list') }} <code>{getMain(content.{{ $t('global-components.custom-url.description.field-alias') }}).id}</code>, <code>{getMain(content.{{ $t('global-components.custom-url.description.field-alias') }}).url}</code></li>
            <li>{{ $t('global-components.custom-url.description.types.author-single') }} <code>{content.{{ $t('global-components.custom-url.description.field-alias') }}.id}</code>,<code>{content.{{ $t('global-components.custom-url.description.field-alias') }}.url}</code></li>
            <li>{{ $t('global-components.custom-url.description.types.author-list') }} <code>{getFirst(content.{{ $t('global-components.custom-url.description.field-alias') }}).id}</code>,<code>{getFirst(content.{{ $t('global-components.custom-url.description.field-alias') }}).url}</code></li>
          </ul>
        </div>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { getPossibleParams } from '@/components/fields/Url/index'
import { TYPES, VALUE_TYPES } from '@/views/models/constants'

export default {
  name: 'CustomURL',
  inject: ['getFieldsObjects'],
  props: {
    defaultPattern: String,
    value: Boolean,
  },
  data() {
    return {
      VALUE_TYPES,
      pattern: this.defaultPattern,
      fields: this.getFieldsObjects(block => (block.attrs.type == TYPES.TEXT && block.attrs.valueType == VALUE_TYPES.SINGLE)
        || (block.attrs.type == TYPES.INTEGER && block.attrs.valueType == VALUE_TYPES.SINGLE)
        || (block.attrs.type == TYPES.DECIMAL && block.attrs.valueType == VALUE_TYPES.SINGLE)
        || (block.attrs.type == TYPES.DATE_TIME && block.attrs.valueType == VALUE_TYPES.SINGLE)
        || (block.attrs.type == TYPES.SECTION)
        || (block.attrs.type == TYPES.AUTHOR)),
    }
  },
  computed: {
    showModal: {
      get() {
        return this.value
      },
      set() {
        this.close()
      },
    },
    possibleFields() {
      const fields = ['{id}', '{system.modelAlias}', '{system.modelId}']
      this.fields.forEach(el => {
        const params = getPossibleParams(el.attrs.alias, el.attrs.type, el.attrs.valueType)
        if (params) {
          fields.push(...params.map(a => `{${a}}`))
        } else {
          fields.push(`{content.${el.attrs.alias}}`)
        }
      })
      return fields
    },
  },
  methods: {
    addPlaceholder(alias) {
      this.pattern = `${this.pattern}/${alias}`
    },
    save(pattern) {
      this.$emit('save', pattern)
    },
    close() {
      this.$emit('close')
    },
  },
}
</script>

<style lang="scss" scoped>
#url-configuration-popup {
  .url__sidebar-value-dropdown{
    display: flex;
    position: absolute;
    right: 30px;
    top: 50%;
    transform: translateY(-50%);
  }
  .url_dropdown-info{
    width: 573px;
    height: 18px;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #667C99;
    margin-top: -10px;
    margin-bottom: 30px;
  }
  .url_dropdown-info-preview{
    height: 18px;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #667C99;
    margin-top: 5px;
  }

  .url-card {
    background: #F4F6FA;
    box-shadow: 0px 1px 3px 0px #E0E5EBB2;
    border: 1px solid #E0E5EB;
    border-radius: 4px;
    padding: 14px;
    display: flex;
    gap: 20px;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .url-starter__info{
    width: 85%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  .url-starter__info-title {
    font-size: 1rem;
    font-weight: 500;
    color: #052D61;
    margin-bottom: 1%;
  }
  .url-starter__info-description {
    font-size: 0.85rem;
    color: #667C99;
    line-height: 1.4;

    code {
      color: black;
    }
  }
  .button-moreDetails{
    color: #206ED5;
    padding: 2px 10px;
    font-size: 12px;
    cursor: pointer;
  }

  .url__sidebar-form-container {
    display: flex;
    input {
      padding-right: 40px;
    }
    .b-dropdown {
      position: absolute !important;
      right: -5px;
    }
  }
  @media only screen and (max-width: 1400px) {
    .url-card{
      gap: 15px;
      align-items: flex-start;
    }
    .url-starter__info{
    p{
      font-size: 0.8;
    }
    small{
      font-size: 0.65rem;
    }
  }
}
}
</style>
