import qs from 'qs'
import { merge } from 'lodash'
import { ASSET_TYPES } from '@/codex-sdk/assets'
import { apiAxios as axios } from '../libs/axios'
import { tc } from './config'

export const WEBHOOK_TYPES = Object.freeze({
  CONTENT: 1,
  LIST: 2,
  MEDIA: 3,
  AUTHOR: 4,
  SECTION: 5,
  URL: 6,
  TAG: 7,
  LABEL: 8,
  JOB: 9,
})

export const FILTER_TYPES = Object.freeze({
  ID: 1,
  SITE: 2,
  // SECTION: 3,
  FOLDER: 4,
  MODEL: 5,
  LABEL_ENTITY: 6,
  JOB: 5,

})

export const WEBHOOK_STATUSES = Object.freeze({
  ACTIVE: 1,
  INACTIVE: 2,
  DELETED: 3,
})

export const HEADER_TYPES = Object.freeze({
  CUSTOM: 1,
  SECRET: 2,
  AUTHENTICATION: 3,
})

export const HTTP_METHODS = Object.freeze({
  POST: 'POST',
  GET: 'GET',
  PUT: 'PUT',
  PATCH: 'PATCH',
  DELETE: 'DELETE',
})

export const EVENT_TYPES = Object.freeze({
  INSERT: 1,
  DELETE: 2,
  UPDATE: 3,
  PUBLISH: 4,
  AUTO_SAVE: 5,
  UNPUBLISH: 6,
  SCHEDULE: 7,
  UNSCHEDULE: 8,
  ARCHIVE: 9,
  UPLOAD: 10,
  COMPLETED: 11,
  FAILED: 12,
})

export const ENDPOINT_STATUS = Object.freeze({
  ACTIVE: 1,
  INACTIVE: 2,
  UNSENT: 3,
})

export const CONTENT_TYPES_LIST = Object.freeze([
  { label: 'application/json', value: 'application/json' },
  { label: 'application/json; charset=utf-8', value: 'application/json; charset=utf-8' },
  { label: 'application/x-www-form-urlencoded', value: 'application/x-www-form-urlencoded' },
  { label: 'application/x-www-form-urlencoded charset=utf-8', value: 'application/x-www-form-urlencoded charset=utf-8' },
])

export const TABS = Object.freeze({
  EDIT: 'edit',
  LOGS: 'logs',
})

export const TABS_INDEXES = Object.freeze([
  TABS.EDIT,
  TABS.LOGS,
])

export function mapWebhookMediaTypes(type) {
  const types = {
    [ASSET_TYPES.IMAGE]: 'Image',
    [ASSET_TYPES.FILE]: 'File',
    [ASSET_TYPES.VIDEO]: 'Video',
    [ASSET_TYPES.VIDEO_PLAYLIST]: 'VideoPlaylist',
    [ASSET_TYPES.AUDIO]: 'Audio',
    [ASSET_TYPES.PODCAST]: 'Podcast',
  }

  return types[type] || 'Image'
}

export function mapWebhookTypes(type) {
  const types = {
    [WEBHOOK_TYPES.CONTENT]: 'webhooks.webhook.types.content',
    [WEBHOOK_TYPES.LIST]: 'webhooks.webhook.types.list',
    [WEBHOOK_TYPES.MEDIA]: 'webhooks.webhook.types.media',
    [WEBHOOK_TYPES.AUTHOR]: 'webhooks.webhook.types.author',
    [WEBHOOK_TYPES.SECTION]: 'webhooks.webhook.types.section',
    [WEBHOOK_TYPES.URL]: 'webhooks.webhook.types.url',
    [WEBHOOK_TYPES.TAG]: 'webhooks.webhook.types.tag',
    [WEBHOOK_TYPES.LABEL]: 'webhooks.webhook.types.label',
    [WEBHOOK_TYPES.JOB]: 'webhooks.webhook.types.job',
  }

  return types[type] || 'webhooks.webhook.types.content'
}

export function mapWebhookStatus(status) {
  const statuses = {
    [WEBHOOK_STATUSES.ACTIVE]: 'webhooks.listing.statuses.active',
    [WEBHOOK_STATUSES.INACTIVE]: 'webhooks.listing.statuses.inactive',
    [WEBHOOK_STATUSES.DELETED]: 'webhooks.listing.statuses.deleted',
  }

  return statuses[status] || 'inactive'
}

export function mapWebhookEventType(event, type) {
  const eventTypes = {
    [EVENT_TYPES.INSERT]: type === WEBHOOK_TYPES.LABEL ? 'webhooks.webhook.statuses.label-added' : 'webhooks.webhook.statuses.insert',
    [EVENT_TYPES.DELETE]: type === WEBHOOK_TYPES.LABEL ? 'webhooks.webhook.statuses.label-removed' : 'webhooks.webhook.statuses.delete',
    [EVENT_TYPES.AUTO_SAVE]: 'webhooks.webhook.statuses.auto-save',
    [EVENT_TYPES.PUBLISH]: 'webhooks.webhook.statuses.publish',
    [EVENT_TYPES.UPDATE]: 'webhooks.webhook.statuses.update',
    [EVENT_TYPES.UNPUBLISH]: 'webhooks.webhook.statuses.unpublish',
    [EVENT_TYPES.SCHEDULE]: 'webhooks.webhook.statuses.schedule',
    [EVENT_TYPES.UNSCHEDULE]: 'webhooks.webhook.statuses.unschedule',
    [EVENT_TYPES.ARCHIVE]: 'webhooks.webhook.statuses.archive',
    [EVENT_TYPES.UPLOAD]: 'webhooks.webhook.statuses.upload',
    [EVENT_TYPES.COMPLETED]: 'webhooks.webhook.statuses.completed',
    [EVENT_TYPES.FAILED]: 'webhooks.webhook.statuses.failed',
  }
  return eventTypes[event] || '-'
}

export function mapEndpointStatuses(status) {
  return {
    [ENDPOINT_STATUS.ACTIVE]: 'webhooks.webhook.logs.endpoint-statuses.active',
    [ENDPOINT_STATUS.INACTIVE]: 'webhooks.webhook.logs.endpoint-statuses.inactive',
    [ENDPOINT_STATUS.UNSENT]: 'webhooks.webhook.logs.endpoint-statuses.unsent',
  }[status]
}

export function mapWebhookEvents(type) {
  const eventTypes = {
    [WEBHOOK_TYPES.CONTENT]: [EVENT_TYPES.INSERT, EVENT_TYPES.DELETE, EVENT_TYPES.AUTO_SAVE, EVENT_TYPES.PUBLISH, EVENT_TYPES.UPDATE, EVENT_TYPES.UNPUBLISH, EVENT_TYPES.SCHEDULE, EVENT_TYPES.ARCHIVE, EVENT_TYPES.UNSCHEDULE],
    [WEBHOOK_TYPES.LIST]: [EVENT_TYPES.INSERT, EVENT_TYPES.UPDATE, EVENT_TYPES.DELETE],
    [WEBHOOK_TYPES.MEDIA]: [EVENT_TYPES.UPLOAD, EVENT_TYPES.UPDATE, EVENT_TYPES.DELETE],
    [WEBHOOK_TYPES.AUTHOR]: [EVENT_TYPES.INSERT, EVENT_TYPES.UPDATE, EVENT_TYPES.DELETE],
    [WEBHOOK_TYPES.SECTION]: [EVENT_TYPES.INSERT, EVENT_TYPES.UPDATE, EVENT_TYPES.DELETE],
    [WEBHOOK_TYPES.URL]: [EVENT_TYPES.INSERT, EVENT_TYPES.UPDATE, EVENT_TYPES.DELETE],
    [WEBHOOK_TYPES.TAG]: [EVENT_TYPES.INSERT, EVENT_TYPES.UPDATE, EVENT_TYPES.DELETE],
    [WEBHOOK_TYPES.LABEL]: [EVENT_TYPES.INSERT, EVENT_TYPES.DELETE],
    [WEBHOOK_TYPES.JOB]: [EVENT_TYPES.COMPLETED, EVENT_TYPES.FAILED],
  }
  return eventTypes[type] || [EVENT_TYPES.INSERT]
}

export const HTTP_STATUS_CODES = Object.freeze({
  100: 'Continue',
  101: 'Switching Protocols',
  102: 'Processing',
  103: 'Early Hints',

  200: 'OK',
  201: 'Created',
  202: 'Accepted',
  203: 'Non-Authoritative Information',
  204: 'No Content',
  205: 'Reset Content',
  206: 'Partial Content',
  207: 'Multi-Status',
  208: 'Already Reported',
  226: 'IM Used',

  300: 'Multiple Choices',
  301: 'Moved Permanently',
  302: 'Found',
  303: 'See Other',
  304: 'Not Modified',
  305: 'Use Proxy',
  306: 'Unused',
  307: 'Temporary Redirect',
  308: 'Permanent Redirect',

  400: 'Bad Request',
  401: 'Unauthorized',
  402: 'Payment Required',
  403: 'Forbidden',
  404: 'Not Found',
  405: 'Method Not Allowed',
  406: 'Not Acceptable',
  407: 'Proxy Authentication Required',
  408: 'Request Timeout',
  409: 'Conflict',
  410: 'Gone',
  411: 'Length Required',
  412: 'Precondition Failed',
  413: 'Payload Too Large',
  414: 'URI Too Long',
  415: 'Unsupported Media Type',
  416: 'Range Not Satisfiable',
  417: 'Expectation Failed',
  418: 'I\'m a teapot',
  421: 'Misdirected Request',
  422: 'Unprocessable Entity',
  423: 'Locked',
  424: 'Failed Dependency',
  425: 'Too Early',
  426: 'Upgrade Required',
  428: 'Precondition Required',
  429: 'Too Many Requests',
  431: 'Request Header Fields Too Large',
  451: 'Unavailable For Legal Reasons',

  500: 'Internal Server Error',
  501: 'Not Implemented',
  502: 'Bad Gateway',
  503: 'Service Unavailable',
  504: 'Gateway Timeout',
  505: 'Http Version Not Supported',
  506: 'Variant Also Negotiates',
  507: 'Insufficient Storage',
  508: 'Loop Detected',
  510: 'Not Extended',
  511: 'Network Authentication Required',
})

export function mapHttpStatusCode(code) {
  return `${code} - ${HTTP_STATUS_CODES[code]}` || ''
}

export function webhookDefaults(webhook = {}) {
  return merge({
    name: '',
    alias: '',
    url: '',
    webhookType: WEBHOOK_TYPES.CONTENT,
    httpMethod: {
      method: HTTP_METHODS.POST,
    },
    eventTypes: [],
    numberOfAttempts: 1,
    contentType: CONTENT_TYPES_LIST[0].value,
    headers: [],
    filters: [],
    customPayload: null,
    status: WEBHOOK_STATUSES.INACTIVE,
    triggerAllEvents: true,
    metrics: {
      totalCalls: 0,
      averageResponseTime: 0,
      commonStatusCodes: [],
    },
  }, webhook)
}

export function activityLogDefaults(log = {}) {
  return merge({
    id: '',
    webhookId: '',
    requestDateTime: new Date(),
    endpointStatus: ENDPOINT_STATUS.ACTIVE,
    responseStatusCode: '',
    requestDetails: '',
    delivered: false,
    eventType: EVENT_TYPES.INSERT,
    retryCount: 1,
    parentId: null,
    nextRetry: null,
    type: null,
    hierarchicalLogs: [],
  }, log)
}

export function transformWebhookFromRequest(webhook) {
  return webhookDefaults(webhook)
}

export function transformActivityLogFromRequest(webhook) {
  return activityLogDefaults(webhook)
}

export function createWebhook(webhook, messages) {
  return tc(axios.post('/{organization}/webhooks', webhook), {
    successTitle: 'sdk.success-title',
    successMessage: 'sdk.webhooks.create-successful',
    failTitle: 'sdk.webhooks.create-failed',
    ...messages,
  })
}

export function updateWebhook(webhook, messages) {
  return tc(axios.put(`/{organization}/webhooks/${webhook.id}`, webhook), {
    successTitle: 'sdk.success-title',
    successMessage: 'sdk.webhooks.update-successful',
    failTitle: 'sdk.webhooks.update-failed',
    ...messages,
  })
}

export function deleteWebhook(id, messages = {}) {
  return tc(axios.delete(`/{organization}/webhooks/${id}`), {
    successTitle: 'sdk.success-title',
    successMessage: 'sdk.webhooks.delete-successful',
    failTitle: 'sdk.webhooks.delete-failed',
    ...messages,
  })
}

export function readWebhooks({ page = null, pageSize = null, searchTerm } = {}) {
  return axios.get('/{organization}/webhooks', {
    params: {
      Page: page,
      Size: pageSize,
      Q: searchTerm,
    },
    paramsSerializer(params) {
      return qs.stringify(params, { indices: false })
    },
  })
}

export function readWebhook(id) {
  return axios.get(`/{organization}/webhooks/${id}`)
    .then(response => {
      response.data = transformWebhookFromRequest(response.data)
      return response
    })
}

export function readActivityLogs({
  webhookId = null,
  specificFilter = [],
  entities = [],
  statusCodes = [],
  eventTypes = [],
  delivered,
  siteFilter = [],
  mediaTypes = [],
  folderIds = [],
  urlTypes = [],
  labelTypes = [],
  page = 1,
  pageSize = 10,
}) {
  return axios.get(`/{organization}/webhooks/${webhookId}/logs`, {
    params: {
      entities,
      statusCodes,
      eventTypes,
      delivered,
      siteFilter,
      mediaTypes,
      folderIds,
      urlTypes,
      labelTypes,
      specificFilter,
      page,
      size: pageSize,
    },
    paramsSerializer(params) {
      return qs.stringify(params, { indices: false })
    },
  })
    .then(response => {
      if (response.data?.data) {
        response.data.data = response.data.data.map(e => transformActivityLogFromRequest(e))
      }
      return response
    })
}

export function readActivityLog(id) {
  return axios.get(`/{organization}/webhooks/log/${id}`)
}

export function retry(activityLog, messages) {
  const params = {
    parentId: activityLog.parentId || activityLog.id,
  }
  if (activityLog.parentId) {
    params.id = activityLog.id
  }
  return tc(axios.post('/{organization}/webhooks/retry', params), {
    successTitle: 'sdk.success-title',
    successMessage: 'sdk.webhooks.retry-successful',
    failTitle: 'sdk.webhooks.retry-failed',
    catchError: false,
    ...messages,
  })
}

export function testEvent(id = '', eventType = null, messages) {
  return tc(axios.post(`/{organization}/webhooks/test-event/${id}`, null, {
    params: { eventType },
  }), {
    successTitle: 'sdk.success-title',
    successMessage: 'sdk.webhooks.test-successful',
    failTitle: 'sdk.webhooks.test-failed',
    catchError: false,
    ...messages,
  })
}
