import Vue from 'vue'
import VueI18n from 'vue-i18n'

// eslint-disable-next-line import/no-extraneous-dependencies
import flatPickr from 'flatpickr'
import moment from 'moment'
import { LANGUAGES } from '@/utils/constants'

const flatpickrLocale = require.context('flatpickr/dist/l10n', false, /[A-Za-z0-9-_,\s]+\.js$/i)
const momentLocale = require.context('moment/locale', false, /[A-Za-z0-9-_,\s]+\.js$/i)

Vue.use(VueI18n)

function loadLocaleMessages() {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })
  return messages
}

const i18n = new VueI18n({
  locale: LANGUAGES.ENGLISH,
  fallbackLocale: LANGUAGES.ENGLISH,
  messages: loadLocaleMessages(),
  silentTranslationWarn: true,
})

export function setFlatpickrLocale(language) {
  try {
    const localeFile = flatpickrLocale(`./${language}.js`)
    flatPickr.localize(localeFile.default[language])
  } catch (e) {
    const localeFile = flatpickrLocale('./default.js')
    flatPickr.localize(localeFile.default)
  }
}

// setMomentLocale function
export function setMomentLocale(language) {
  try {
    const localeFile = momentLocale(`./${language}.js`)
    moment.locale(language, localeFile.default)
  } catch (e) {
    moment.locale(LANGUAGES.ENGLISH)
  }
}

export function setLanguage(language, save = false) {
  i18n.locale = language

  if (save) {
    localStorage.setItem('language', JSON.stringify({ language }))
  }

  try {
    setFlatpickrLocale(language)
    setMomentLocale(language)
  } catch (e) {
    console.log(e)
  }
}

export default i18n
