import '@/components/fields/Number/index'
import '@/components/fields/MediaContent/index'
import '@/components/fields/RichText/index'
import '@/components/fields/Json/index'
import '@/components/fields/Boolean/index'
import '@/components/fields/Text/index'
import '@/components/fields/Reference/index'
import '@/components/fields/DateAndTime/index'
import '@/components/fields/RichContent/index'
import '@/components/fields/Author/index'
import '@/components/fields/Tag/index'
import '@/components/fields/Section/index'
import '@/components/fields/Url/index'

export default {
  components: {
    codex_container: () => import('./CodexContainer.vue'),
    codex_tabs: () => import('./CodexTabs.vue'),
    codex_row: () => import('./Row.vue'),
  },
}
