import CodexLayoutError from '../CodexLayoutError'
import AddBlock from './AddBlock'

/**
 * Add Block Transaction
 */
export default class AddBlockBeforeAfter extends AddBlock {
  name = 'AddBlockBeforeAfter';

  blockId = null

  addIndex = null

  blockOptions = null

  constructor(editor, blockId, addPosition, blockOptions) {
    super(editor)

    const parentBlock = this.editor.findParentBlockById(blockId)

    if (!parentBlock) {
      throw new CodexLayoutError(`Block '${blockId}' not found`)
    }

    let addIndex = parentBlock.content.findIndex(block => block.id == blockId)

    if (addPosition == 'after') {
      addIndex++
    }

    this.blockId = parentBlock.id
    this.addIndex = addIndex
    this.blockOptions = blockOptions
  }
}
