<template>
  <div
    ref="filtersContainer"
    class="new-filters-dropdown__active-filters-outter"
  >

    <div
      ref="filtersSpacer"
      class="new-filters-dropdown__active-filters-spacer"
    />

    <!-- Not fitting filters -->
    <div
      class="new-filters-dropdown__actions"
    >
      <div
        ref="actionsContainer"
        class="new-filters-dropdown__actions-inner"
      >
        <div
          v-show="filtersNotFitInContainer.length"
          ref="notFittingFiltersButton"
          class="new-filters-dropdown__toggle-not-fitting"
          :class="{
            'new-filters-dropdown__toggle-not-fitting--active': showNotFittingFiltersPopper,
          }"
          @click.stop="toggleNotFittingFiltersPopper"
        >
          {{ filtersNotFitInContainer.length }}+
        </div>
      </div>
    </div>

    <div
      v-show="filtersNotFitInContainer.length && showNotFittingFiltersPopper"
      ref="notFittingFiltersDropdown"
      class="new-filters-dropdown"
    >
      <div class="new-filters-dropdown__body">
        <div class="new-filters-dropdown__items">

          <div
            v-for="filterName in filtersNotFitInContainer"
            :key="filterName"
            :ref="`${filterName}-not-fitting`"
            class="new-filters-dropdown__item"
            :class="{
              'new-filters-dropdown__item--active': activeFilter.filter && activeFilter.filter.name === filterName && activeFilter.location === location,
            }"
            @click="editFilterin(filterName, `${filterName}-not-fitting`, 'left-start')"
          >
            <!-- If the filter has more than one value, show the count -->
            <template v-if="getActiveFilterBadgeLabel(filterName).count > 1">

              <span class="new-filters-dropdown__active-filter-label">
                {{ getActiveFilterBadgeLabel(filterName).label }}:
              </span>

              <span class="new-filters-dropdown__active-filter-value">
                {{ getActiveFilterBadgeLabel(filterName).count }} values selected
              </span>

            </template>

            <!-- If the filter has only one value, show the value -->
            <template v-else>
              <span class="new-filters-dropdown__active-filter-label">
                {{ getActiveFilterBadgeLabel(filterName).label }}:
              </span>

              <span class="new-filters-dropdown__active-filter-value">
                {{ getActiveFilterBadgeLabel(filterName).value }}
              </span>
            </template>

            <!-- Caret -->
            <span class="new-filters-dropdown__active-filter-caret">
              <GjIcon
                name="ArrowDown"
                size="20"
              />
            </span>
          </div>

        </div>
      </div>
    </div>

    <div
      class="new-filters-dropdown__active-filters"
    >
      <!-- Filter badges -->
      <div
        v-for="filterName in activeFilters"
        :key="filterName"
        :ref="filterName"
        class="new-filters-dropdown__active-filter"
        :class="{
          'new-filters-dropdown__active-filter--open': activeFilter.filter && activeFilter.filter.name === filterName && activeFilter.location === location,
          'new-filters-dropdown__active-filter--active': isActiveFilter(filterName),
          'new-filters-dropdown__active-filter--inactive': !isActiveFilter(filterName),
          'new-filters-dropdown__active-filter--not-fitting': !filterFitsInContainer(filterName),
        }"
        @click="editFilterin(filterName)"
      >

        <!-- If filter is not active, only show the label -->
        <template v-if="!isActiveFilter(filterName)">
          <span class="new-filters-dropdown__active-filter-label">
            {{ getActiveFilterBadgeLabel(filterName).label }}
          </span>
        </template>

        <!-- If the filter has more than one value, show the count -->
        <template v-else-if="getActiveFilterBadgeLabel(filterName).count > 1">

          <span class="new-filters-dropdown__active-filter-value">
            {{ getActiveFilterBadgeLabel(filterName).label }} ({{ getActiveFilterBadgeLabel(filterName).count }})
          </span>

        </template>

        <!-- If the filter has only one value, show the value -->
        <template v-else>
          <span class="new-filters-dropdown__active-filter-label">
            {{ getActiveFilterBadgeLabel(filterName).label }}:
          </span>

          <span class="new-filters-dropdown__active-filter-value">
            {{ getActiveFilterBadgeLabel(filterName).value }}
          </span>
        </template>

        <!-- Caret -->
        <span class="new-filters-dropdown__active-filter-caret">
          <GjIcon
            name="ArrowDown"
            size="20"
          />
        </span>

      </div>
    </div>
  </div>
</template>

<script>
import { throttle } from 'lodash'
import { createPopper } from '@popperjs/core'
import { someParentHasClass } from '@/utils/helpers'

export default {
  name: 'ActiveFilters',
  inject: ['editFilter', 'activeFilter'],
  props: {
    filters: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      // Popper object for all filters
      notFittingFiltersPopper: null,

      showNotFittingFiltersPopper: false,

      filtersNotFitInContainer: [],
      resizeObserver: null,

      location: 'active-filters-dropdown',
    }
  },
  computed: {
    /**
     * Get active filters and put alwaysShow filters first in the array
     */
    activeFilters() {
      // Get alwaysShow filters and put them first
      const alwaysShowFilters = this.filters.getAlwaysShowFilters().map(filter => filter.name)
      let activeFilters = Object.keys(this.filters.activeFilters)

      // Remove alwaysShow filters from activeFilters using .filter
      activeFilters = activeFilters.filter(filter => !alwaysShowFilters.includes(filter))

      return activeFilters.concat(alwaysShowFilters)
    },
  },
  watch: {
    activeFilters() {
      this.checkForNotFittingFilters()
    },
  },
  mounted() {
    this.notFittingFiltersPopper = createPopper(this.$refs.notFittingFiltersButton, this.$refs.notFittingFiltersDropdown, {
      placement: 'bottom-start',
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, 4],
          },
        },
        {
          name: 'preventOverflow',
          options: {
            boundary: 'viewport',
            mainAxis: false, // true by default
          },
        },
      ],
    })

    this.checkForNotFittingFilters()

    const filtersContainer = this.$refs.filtersContainer
    if (!filtersContainer) return

    this.resizeObserver = new ResizeObserver(throttle(() => {
      this.checkForNotFittingFilters()
      this.notFittingFiltersPopper.update()
    }, 100))

    this.resizeObserver.observe(filtersContainer)
    this.resizeObserver.observe(this.$refs.actionsContainer)
    window.addEventListener('resize', this.checkForNotFittingFilters)

    document.addEventListener('mousedown', this.documentClick)
  },
  beforeDestroy() {
    const filtersContainer = this.$refs.filtersContainer
    if (!filtersContainer) return

    this.resizeObserver.unobserve(filtersContainer)
    window.removeEventListener('resize', this.checkForNotFittingFilters)
    document.removeEventListener('mousedown', this.documentClick)
  },
  methods: {

    /**
     * Check document click to hide poppers
     */
    documentClick(e) {
      const filtersModalElement = someParentHasClass(this.$refs.filtersContainer, 'modal')
      const filtersSidebarElement = someParentHasClass(this.$refs.filtersContainer, 'b-sidebar')
      const targetModalElement = someParentHasClass(e.target, 'modal')
      const targetSidebarElement = someParentHasClass(e.target, 'b-sidebar')

      if (
        !someParentHasClass(e.target, 'new-filters-dropdown')
        && !someParentHasClass(e.target, 'new-filters-dropdown__filter')
        && (!targetModalElement || targetModalElement === filtersModalElement)
        && (!targetSidebarElement || targetSidebarElement === filtersSidebarElement)
      ) {
        this.showNotFittingFiltersPopper = false
      }
    },

    toggleNotFittingFiltersPopper() {
      this.showNotFittingFiltersPopper = !this.showNotFittingFiltersPopper
      this.notFittingFiltersPopper.update()
    },

    /**
     * Check which filters don't fit in the container
     */
    checkForNotFittingFilters() {
      const filtersSpacer = this.$refs.filtersSpacer
      const filtersContainer = this.$refs.filtersContainer
      const actionsContainer = this.$refs.actionsContainer

      if (!filtersContainer) return

      filtersSpacer.style.display = 'block'

      const filtersContainerWidth = filtersContainer.offsetWidth
      const actionsContainerWidth = actionsContainer.offsetWidth + 30
      const notFitting = []
      let filtersWidth = actionsContainerWidth

      // Loop in reverse using for loop
      for (let i = this.activeFilters.length - 1; i >= 0; i--) {
        const filter = this.activeFilters[i]

        const filterEl = Array.isArray(this.$refs[filter]) ? this.$refs[filter][0] : this.$refs[filter]

        if (!filterEl) return

        filtersWidth += filterEl.offsetWidth + 8

        if (filtersWidth > filtersContainerWidth) {
          notFitting.push(filter)
        }
      }

      this.filtersNotFitInContainer = notFitting

      filtersSpacer.style.display = 'none'
    },

    /**
     * Check if filter fits in the container
     */
    filterFitsInContainer(filterName) {
      return !this.filtersNotFitInContainer.includes(filterName)
    },

    /**
     * Check if filter is active
     */
    isActiveFilter(filterName) {
      return this.filters.isFilterActive(filterName)
    },

    /**
     * Get filter
     */
    getFilter(filterName) {
      return this.filters.getFilter(filterName)
    },

    /**
     * Get active filter badge label
     */
    getActiveFilterBadgeLabel(filterName) {
      return this.filters.getActiveFilterBadgeLabel(filterName)
    },

    /**
     * Edit filter
     */
    editFilterin(filterName, refName, placement = 'bottom-start') {
      const filter = this.getFilter(filterName)

      if (!refName) {
        refName = filter.name
      }

      const filterEl = Array.isArray(this.$refs[refName]) ? this.$refs[refName][0] : this.$refs[refName]
      this.editFilter(filterEl, filter, { placement }, this.location)
    },
  },
}
</script>

<style lang="scss">
.new-filters-dropdown__actions {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}

.new-filters-dropdown__active-filters-spacer {
  width: 1300px;
  display: none;
}

.new-filters-dropdown__active-filters-outter {
  display: flex;
  gap: 8px;
  min-width: 0;
}

.new-filters-dropdown__toggle-not-fitting {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 28px;
  padding: 0 8px;
  border: 1px solid #1D79F2;
  background: white;
  border-radius: 4px;

  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  color: #206ED4;

  cursor: pointer;

  &:hover, &.new-filters-dropdown__toggle-not-fitting--active {
    background: #ECF3FF;
  }
}
.new-filters-dropdown__active-filters {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  min-width: 0;
}
.new-filters-dropdown__active-filter {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 4px 4px 8px;
  height: 28px;
  border: 1px solid #1D79F2;
  background: white;
  border-radius: 4px;

  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #667C99;

  cursor: pointer;

  &:hover, &.new-filters-dropdown__active-filter--open {
    background: #ECF3FF;
  }

}
.new-filters-dropdown__active-filter--inactive {
  background: #F4F6FA;
  color: #052D61;
  border: 1px solid #EDEFF3;

  &:hover, &.new-filters-dropdown__active-filter--open {
    background: #EDEFF3;
  }

  .new-filters-dropdown__active-filter-caret {
    color: #052D61;
  }
}
.new-filters-dropdown__active-filter--not-fitting {
  position: absolute;
  left: -9999px;
}
.new-filters-dropdown__active-filter-label {
  white-space: nowrap;
}
.new-filters-dropdown__active-filter-value {
  color: #206ED4;
  font-weight: bold;
  margin-left: 5px;
  text-transform: capitalize;
  flex-grow: 1;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.new-filters-dropdown .new-filters-dropdown__active-filter-value {
  max-width: unset;
  margin-right: 10px;
}

.new-filters-dropdown__active-filter-caret {
  color: #206ED4;

  svg {
    vertical-align: middle;
  }
}

.new-filters-dropdown .new-filters-dropdown__active-filter-caret {
  transform: rotate(-90deg);
}

</style>
