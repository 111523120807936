<template>
  <div
    v-if="showBreadcrumb"
    ref="folders"
    class="folders_breadcrumb"
  >
    <b-breadcrumb
      v-if="reversePath.length"
      ref="container"
      class="breadcrumb-container"
    >
      <template v-for="(folder,index) in reversePath">
        <b-dropdown
          v-if="invisibleFolders.length && index === 1"
          :key="'invisibleFolders'+index"
          no-caret
          variant="none"
        >
          <template #button-content>
            <GjIcon
              name="Moreoptions"
              size="20"
            />
          </template>
          <b-dropdown-item
            v-for="fo in invisibleFolders"
            :key="fo.id"
            @click="$emit('folder', fo)"
          >
            {{ fo.name }}
          </b-dropdown-item>
        </b-dropdown>

        <b-breadcrumb-item
          :key="folder.id"
          ref="elements"
          class="breadcrumb-element"
          :data-id="folder.id"
          @click="$emit('folder', folder)"
        >
          {{ folder.name }}
        </b-breadcrumb-item>
      </template>

      <b-dropdown
        v-if="reversePath.length == 1 && invisibleFolders.length"
        :key="'invisibleFolders'"
        no-caret
        variant="none"
      >
        <template #button-content>
          <GjIcon
            name="Moreoptions"
            size="20"
          />
        </template>
        <b-dropdown-item
          v-for="folder in invisibleFolders"
          :key="folder.id"
          @click="$emit('folder', folder)"
        >
          {{ folder.name }}
        </b-dropdown-item>
      </b-dropdown>
    </b-breadcrumb>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'Breadcrumbs',
  props: ['items'],
  data() {
    return {
      widths: [],
      visibleFolders: [],
      invisibleFolders: [],
      containerWidth: 0,
    }
  },
  computed: {
    ...mapState('assets', [
      'selectedData',
    ]),
    folders() {
      return this.items || []
      // return this.selectedData.items?.[0]?.folder?.path
    },
    showBreadcrumb() {
      return this.folders?.length > 0
    },
    reversePath() {
      if (!this.visibleFolders?.length) return []
      return [...this.visibleFolders].reverse()
    },
  },
  watch: {
    folders() {
      this.visibleFolders = this.folders || []
      this.$nextTick(() => {
        this.checkWidth()
      })
    },
  },
  mounted() {
    window.addEventListener('resize', this.checkForNotFittingFolders)
  },
  destroyed() {
    window.removeEventListener('resize', this.checkForNotFittingFolders)
  },
  methods: {
    checkWidth() {
      this.containerWidth = this.$refs.container?.offsetWidth - 32
      if (!this.$refs.elements?.length) return
      this.widths = this.$refs.elements.map((el, index) => ({
        width: el.offsetWidth,
        index,
        id: el.dataset.id,
      }))
      this.visibleFolders = []

      this.$nextTick(() => {
        this.checkForNotFittingFolders()
      })
    },
    checkForNotFittingFolders() {
      if (!this.folders?.length) return
      const totalWidth = this.$refs.folders.offsetWidth
      this.visibleFolders = []
      this.invisibleFolders = []
      let canPush = true
      let sum = 46
      const firstFolder = this.folders[0]

      {
        const width = this.widths.find(w => w.id === firstFolder.id).width + 15
        sum += width
      }

      for (let i = this.folders.length - 1; i >= 0; i--) {
        const folder = this.folders[i]
        const width = this.widths.find(w => w.id === folder.id).width + 15

        if ((sum + width <= totalWidth && canPush) || folder.id === firstFolder.id) {
          this.visibleFolders = [...this.visibleFolders, folder]
          sum += width
        } else {
          canPush = false
          this.invisibleFolders = [...this.invisibleFolders, folder]
        }
      }
    },
  },
}
</script>

<style  lang="scss">
.folders_breadcrumb {
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: 0;
  //height: 34px;
  .btn {
    height: unset;
  }
  .breadcrumb-item + .breadcrumb-item::before {
    content: '';
    background: var(--breadcrumb-icon);
    color:  #667C99;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    margin-right: 8px;
  }

  a.dropdown-item {
    color:  #667C99;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    padding: 8px;
  }

}

.breadcrumb-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  padding-left: 0 !important;
}

.breadcrumb-element {
  padding: 0 5px;
  a {
    color:  #667C99;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
  }
}
</style>
