var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"authors-popup","ok-variant":"primary","cancel-variant":"outline-dark","size":"xl","no-close-on-backdrop":"","centered":"","static":true},on:{"ok":function($event){return _vm.closePopup(_vm.selectedAuthors)},"close":function($event){return _vm.closePopup()}},model:{value:(_vm.showPopup),callback:function ($$v) {_vm.showPopup=$$v},expression:"showPopup"}},[_c('div',{staticClass:"page-header"},[_c('div',{staticClass:"page-header__actions"},[(_vm.selectedAuthors.length)?_c('b-button',{staticClass:"button-selected-authors",attrs:{"size":"sm"},on:{"click":function($event){_vm.showSelected = !_vm.showSelected}}},[_vm._v(" Selected ("+_vm._s(_vm.selectedAuthors.length)+") ")]):_vm._e()],1),_c('div',{staticClass:"page-header__filters"},[_c('b-form-group',[_c('div',{staticClass:"page-header__filters-form"},[(!_vm.showSelected)?_c('b-input-group',{staticClass:"input-group-merge page-header__filters-form--md",attrs:{"size":"sm"}},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('GjIcon',{attrs:{"name":"Search"}})],1),_c('b-form-input',{attrs:{"id":"at-authors-popup-search-input","placeholder":_vm.$t('general.search'),"debounce":250},model:{value:(_vm.filters.query),callback:function ($$v) {_vm.$set(_vm.filters, "query", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"filters.query"}})],1):_vm._e()],1)])],1)]),_c('div',{staticClass:"table-container"},[_c('vue-good-table',{staticClass:"gjirafa-tables authors-list-table",class:{ 'authors-loading': _vm.$apollo.loading },attrs:{"columns":_vm.columns,"rows":_vm.$apollo.loading ? [] : (_vm.showSelected ? _vm.selectedAuthors : _vm.authors.items),"mode":"remote","pagination-options":{
        enabled: !_vm.showSelected,
        perPage: _vm.filters.pageSize
      },"select-options":{
        enabled: true,
        disableSelectInfo: true,
      },"row-style-class":_vm.rowClasses},on:{"on-row-click":_vm.handleRowsSelect},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [_c('div',{staticClass:"authors-row-inner"},[(props.column.field === 'avatar')?_c('span',[_c('span',{staticClass:"text-nowrap"},[_c('b-avatar',{attrs:{"text":_vm.isCharLetter(props.row.byline),"src":props.row.image ? props.row.image.url : null,"variant":"default"}})],1)]):_vm._e(),(props.column.field === 'author')?_c('span',[_c('span',{staticClass:"text-nowrap"},[_c('p',{staticClass:"primary-text"},[_vm._v(" "+_vm._s(props.row.byline)+" ")])])]):_vm._e(),(props.column.field === 'emailAddress')?_c('span',[_vm._v(" "+_vm._s(props.row && props.row.email)+" ")]):_vm._e(),(props.column.field === 'dateAdded')?_c('span',[_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(_vm._f("formatDate")(props.row.createdAt))+" ")])]):_vm._e(),(props.column.field === 'actions')?_c('span'):_vm._e()])]}},{key:"pagination-bottom",fn:function(props){return [_c('div',{staticClass:"gjirafa-tables__footer"},[_c('b-pagination',{staticClass:"mt-1 mb-0",attrs:{"id":"at-authors-pagination","value":_vm.filters.page,"total-rows":_vm.totalItems,"per-page":_vm.filters.pageSize,"first-number":"","last-number":"","align":"right","prev-class":"prev-item","next-class":"next-item"},on:{"input":function (value){
              _vm.filters.page = value
              props.pageChanged({currentPage:value})
            }},scopedSlots:_vm._u([{key:"prev-text",fn:function(){return [_c('GjIcon',{attrs:{"name":"ArrowLeft","size":"18"}})]},proxy:true},{key:"next-text",fn:function(){return [_c('GjIcon',{attrs:{"name":"ArrowRight","size":"18"}})]},proxy:true}],null,true)}),_c('div',{staticClass:"gjirafa-tables__footer-leftside"},[_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(_vm.$t('general.pagination', {from: _vm.from, to: _vm.to, totalItems: _vm.totalItems})))])])],1)]}}])},[_c('div',{staticClass:"vgt-text-disabled vgt-center-align",attrs:{"slot":"emptystate"},slot:"emptystate"},[_vm._v(" "+_vm._s(_vm.$t('general.noData'))+" ")])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }