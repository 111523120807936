<template>
  <div>
    <slot />
  </div>
</template>

<script>
import { checkPath } from '@/utils/helpers'

export default {
  props: ['attrs', 'updateAttrs', 'selectNode'],
  data() {
    return {
      initialSrc: null,
      resizing: null,
      width: null,
    }
  },
  computed: {
    imgStyle() {
      if (this.width) return { width: this.width }
      return {}
    },
    alignClass() { return `img-${this.align}` },
    blockId() { return this.attrs.blockId },
    src: { get() { return this.attrs.src }, set(e) { this.updateAttrs({ src: e }) } },
    alt: { get() { return this.attrs.alt }, set(e) { this.updateAttrs({ alt: e }) } },
    caption: { get() { return this.attrs.caption }, set(e) { this.updateAttrs({ caption: e }) } },
    align: { get() { return this.attrs.align }, set(e) { this.updateAttrs({ align: e }) } },
  },
  watch: {
    src: {
      handler(newVal, oldVal) {
        if (!oldVal) {
          this.$nextTick(() => {
            this.selectNode()
          })
        }
        if (newVal !== oldVal) {
          this.initialSrc = `https://noah.gjirafa.com${checkPath(this.src)}`
        }
      },
      immediate: true,
    },
  },
  mounted() {
    document.addEventListener('mousemove', this.resizeImage)
    document.addEventListener('mouseup', this.endResize)

    this.$nextTick(() => {
      this.initialSrc = `https://noah.gjirafa.com${checkPath(this.src)}`
      this.width = this.attrs.width
      if (this.$refs.sourceField) {
        this.$refs.sourceField.$refs.vsinput.focus()
      }
    })
  },
  destroyed() {
    document.removeEventListener('mousemove', this.resizeImage)
    document.removeEventListener('mouseup', this.endResize)
  },
  methods: {
    alignButtonType(align) {
      return this.align === align ? 'filled' : 'border'
    },
    startResize(event, side) {
      this.resizing = {
        side,
        x: event.clientX,
        initialWidth: this.$refs.image.getBoundingClientRect().width,
        maxWidth: this.$refs.imageWrapper.getBoundingClientRect().width,
      }
      this.resizeImage(event)
    },
    resizeImage(event) {
      if (this.resizing) {
        const newX = event.clientX
        const oldX = this.resizing.x
        const resizeDelta = this.resizing.side === 'right' ? newX - oldX : oldX - newX
        const newWidth = this.resizing.initialWidth + (resizeDelta * (this.align === 'center' ? 2 : 1))
        this.width = newWidth + 5 >= this.resizing.maxWidth ? null : `${(newWidth / this.resizing.maxWidth) * 100}%`
        this.updateAttrs({ width: this.width })
      }
    },
    endResize() {
      this.resizing = null
    },
    setSrc() {
      this.updateAttrs({
        src: this.initialSrc,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
  .image-block {
    &::before {
      display: none;
    }
  }

  .image-block__img-wrapper {
    &.img-left {
      text-align: left;
    }
    &.img-center {
      text-align: center;
    }
    &.img-right {
      text-align: right;
    }
    &.img-stretch {
      .image-block__img {
        width: calc( 100% + 20px ) !important;
        margin-left: -10px;
        margin-right: -10px;

        img {
          border-radius: 0px;
        }
      }
      .image-block__caption {
        margin: 0 10px;
      }
    }
  }

  .image-block__img {
    margin-top: 15px;
    position: relative;
    display: inline-block;

    img {
      border-radius: 6px;
      width: 100%;
    }
  }

  .image-block__img-holder {
    position: relative;
  }

  .image-block__img-resizer-right,
  .image-block__img-resizer-left {
    position: absolute;
    display: none;
    top: 50%;
    transform: translateY(-50%);
    width: 2px;
    height: 35px;
    max-height: 80%;
    border-radius: 100px;
    background: #B3B3B3;
    cursor: w-resize;
    border: none;

    &:after {
      content: '';
      position: absolute;
      top: -20px;
      bottom: -20px;
      left: -5px;
      right: -5px;
      display: block;
    }
  }
  .image-block__img-resizer-right {
    right: -10px;
  }
  .image-block__img-resizer-left {
    left: -10px;
  }

  .image-block__caption {
    width: 100%;
    padding: 5px 5px !important;
    font-size: 12px;
    color: gray;
    border: none;
    overflow: hidden !important;
  }

  .image-block.selected {
    .image-block__img-resizer-right,
    .image-block__img-resizer-left {
      display: block;
    }
  }
</style>
