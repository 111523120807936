<template>
  <v-select
    id="at-tags-create-parent-tag-input"
    v-model="inputVal"
    :options="options.filter(t => t.show)"
    :placeholder="placeholder"
    label="tagValue"
    :reduce="s => s.id"
    :class="classes"
    :close-on-select="!multiple"
    :multiple="multiple"
    :clearable="clearable"
    :filterable="false"
    @open="onOpen"
    @close="onClose"
    @search="onSearch"
  >
    <template #selected-option="{ tagValue, tagAlias }">
      <span :title="tagAlias">
        {{ tagValue }}
      </span>
    </template>
    <template v-slot:option="option">
      <div :class="[option.collapsable ? 'table__collapse-arrow' : `table__collapse-no-arrow-${option.indent}`, `table__indent-${option.indent}`, 'd-flex']">
        <div
          :style="`${!option.collapsable ? 'width: 16px;':''}`"
          class="table__collapse-arrow-icon"
          @mousedown.stop.prevent="handleTableCollapse(option)"
        >
          <GjIcon
            v-show="option.collapsable"
            :key="option.collapsed ? 'ArrowDown' : 'ArrowRight'"
            size="16"
            style="fill: #052D61;"
            :name="option.collapsed ? 'ArrowDown' : 'ArrowRight'"
          />
        </div>
        <span
          class="table__collapse-name"
          :class="{'table__collapse-first': option.isFirstOfSet, 'tag-name__no-arrow': !option.collapsable && !option.parentId}"
          :title="option.tagValue"
        >
          <span class="table__collapse-value">
            {{ option.tagValue }}
          </span>
          <span
            v-if="option.tagAlias && isSearch"
            class="table__collapse-path"
          >
            {{ option.tagAlias }}
          </span>
        </span>
      </div>
    </template>
    <template #list-footer>
      <li
        ref="load"
        class="loader"
      >
        <b-spinner
          v-show="$apollo.loading"
          small
        />
      </li>
    </template>
  </v-select>
</template>

<script>
import gql from 'graphql-tag'
import { cloneDeep } from 'lodash'

export default {
  props: {
    value: {
      type: [String, Array],
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    classes: {
      type: String,
      default: '',
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    excludeIds: {
      type: Array,
      default: () => [],
    },
  },
  apollo: {
    tags: {
      query() {
        return this.isSearch ? gql`
        query tags ($where: CodexTagFilter, $limit: Int, $offset: Int) {
          tags: tagCollection (where: $where, limit: $limit, offset: $offset) {
            items {
              id
              tagAlias
              tagValue
              source
              parentId
              siteId
              createdBy {
                id
              }
              path {
                id
                tagAlias
                tagValue
              }
            }
            total
          }
        }
      ` : gql`
        fragment Tag on CodexTag {
          id
          tagAlias
          tagValue
          source
          parentId
          siteId
          createdBy {
            id
          }
        }
        query tags ($where: CodexTagFilter, $limit: Int, $offset: Int) {
          tags: tagCollection (where: $where, limit: $limit, offset: $offset) {
            items {
              ...Tag
              children {
                total
              }
            }
            total
          }
        }
      `
      },
      fetchPolicy: 'no-cache',
      variables() {
        const where = {
          query: this.searchTerm,
          siteId: { eq: this.$store.state.general.currentSite.id },
        }
        if (!this.isSearch) {
          where.parentId = {
            exists: false,
          }
        }
        if (this.excludeIds?.length) {
          where.id = {
            notIn: this.excludeIds,
          }
        }
        return {
          limit: this.limit,
          offset: 0,
          where,
        }
      },
      update(data) {
        this.loading = false
        this.tableData = this.flatten(cloneDeep(data.tags.items))
        this.total = data.tags.total
        return {
          ...data.tags,
          items: this.flatten(cloneDeep(data.tags.items)),
        }
      },
    },
    currentTags: {
      query: gql`
        query currentTags ($ids: [String!]){
          currentTags: tagCollection (where: {id: {in: $ids}}) {
            items {
              id
              tagAlias
              tagValue
              source
              parentId
              siteId
              createdBy {
                id
              }
            }
          }
        }
      `,
      fetchPolicy: 'no-cache',
      skip() {
        return this.inputIds.length < 1
      },
      variables() {
        return {
          ids: this.inputIds,
          limit: this.inputIds.length,
        }
      },
      update(results) {
        const items = results.currentTags.items.filter(t => this.inputIds.includes(t.id))
        return {
          ...results.currentTags,
          items,
        }
      },
    },
  },
  data() {
    return {
      loading: false,
      total: 0,
      offset: 0,
      limit: 20,
      items: [],
      searchTerm: '',
      observer: null,
      tableData: [],
      tagNames: {},
    }
  },
  computed: {
    isSearch() {
      return this.searchTerm?.length > 0
    },
    inputVal: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
    inputIds() {
      let ids = []
      if (this.multiple) {
        ids = this.inputVal.filter(e => e)
      } else if (this.inputVal) {
        ids = [this.inputVal]
      }
      return ids
    },
    hasNext() {
      return this.total >= this.offset + this.limit
    },
    options() {
      const currents = []
      // eslint-disable-next-line no-unused-expressions
      this.currentTags?.items?.forEach(tag => {
        if (!this.tableData.some(t => t.id === tag.id) || (this.tableData.some(t => t.id === tag.id && this.inputIds.includes(tag.id) && !t.show))) {
          currents.push({
            ...tag,
            show: true,
          })
        }
      })
      return [
        ...currents,
        ...this.tableData,
      ]
    },
  },
  mounted() {
    this.observer = new IntersectionObserver(this.infiniteScroll)
  },
  methods: {
    onSearch(searchTerm) {
      this.searchTerm = searchTerm
      this.offset = 0
    },
    onClose() {
      this.observer.disconnect()
    },
    async onOpen() {
      await this.$nextTick()
      this.observer.observe(this.$refs.load)
    },
    async infiniteScroll([{ isIntersecting, target }]) {
      if (isIntersecting && !this.$apollo.loading && this.limit + this.offset < this.total) {
        const ul = target.offsetParent
        const scrollTop = target.offsetParent.scrollTop
        await this.$apollo.queries.tags.fetchMore({
          variables: {
            offset: this.offset + this.limit,
          },
          updateQuery: (previousResult, { fetchMoreResult }) => {
            this.loading = false
            this.offset += this.limit

            this.tableData = [
              ...this.tableData,
              ...this.flatten(cloneDeep(fetchMoreResult.tags.items)),
            ]
            return {
              ...fetchMoreResult.tags,
              items: this.flatten(cloneDeep(fetchMoreResult.tags.items)),
            }
          },
        })
        this.$nextTick(() => {
          ul.scrollTop = scrollTop
        })
      }
    },
    async handleTableCollapse(item, state = undefined) {
      const dropdownMenu = document.getElementsByClassName('vs__dropdown-menu')[0]
      const scrollTop = dropdownMenu.scrollTop
      this.tableData = this.tableData.map(tag => {
        if (tag.id === item.id) {
          tag.collapsed = state === undefined ? !tag.collapsed : state
        }

        if (tag.parentId === item.id) {
          tag.show = state === undefined ? !tag.show : state

          if ((state === undefined && !tag.show) || state === false) {
            this.handleTableCollapse(tag, false)
          }
        }

        return tag
      })

      if (!this.tableData.some(tag => tag.parentId === item.id) && state === undefined && item.collapsed === false) {
        await this.fetchChildren(item)
      }
      this.$nextTick(() => {
        dropdownMenu.scrollTo({
          top: scrollTop,
          behavior: 'smooth',
        })
      })
    },
    async fetchChildren(parent = { id: null, indent: 0 }, refetch = false) {
      let tags = cloneDeep(this.tableData)
      if (refetch) {
        tags = this.tableData.filter(t => t.parentId !== parent.id)
      }
      try {
        const { data } = await this.$apollo.query({
          query: gql`
            fragment Tag on CodexTag {
              id
              tagAlias
              tagValue
              source
              parentId
              createdBy {
                id
              }
            }
            query tags ($where: CodexTagFilter, $limit: Int, $offset: Int) {
              tags: tagCollection (where: $where, limit: $limit, offset: $offset) {
                items {
                  ...Tag
                  children {
                    total
                  }
                }
                total
              }
            }
          `,
          fetchPolicy: 'no-cache',
          variables: {
            limit: 10000,
            offset: 0,
            where: {
              parentId: {
                eq: parent.id,
              },
              ...(this.excludeIds?.length && { id: { notIn: this.excludeIds } }),
            },
          },
        })
        const index = tags.findIndex(t => t.id == parent.id)
        tags.splice(index + 1, 0, ...this.flatten(data.tags.items, parent.id, parent.indent + 1, true))
        this.tableData = tags
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    flatten(items, parentId, indent = 0, show = false) {
      for (let i = 0; i < items.length; i++) {
        const childItems = []

        if (items[i].indent === undefined) {
          items[i].indent = indent
        }

        if (parentId && items[i].parentId === undefined) {
          items[i].parentId = parentId
        }

        if (items[i].show === undefined) {
          items[i].show = indent === 0 || show
        }

        if (items[i].children?.total) {
          items[i].collapsable = true
          items[i].collapsed = false
          if (items[i].children?.items) {
            childItems.push(...this.flatten(items[i].children?.items, items[i].id, indent + 1))
            childItems[0].isFirstOfSet = true
          }
        }

        delete items[i].children
        items = [...items.slice(0, i + 1), ...childItems, ...items.slice(i + 1)]
        this.tagNames[items[i].id] = items[i].title
      }

      return items
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/base/bootstrap-extended/include"; // Bootstrap includes

#at-tags-create-parent-tag-input {
  .vs__selected-options{
    width: 100%;
    .vs__selected {
      word-break: break-all;
    }
  }

  .table__collapse-arrow {
    position: relative;
  }

  .table__collapse-arrow-icon {
    display: flex;
    align-items: center;
    margin-right: 5px;
    flex-shrink: 0;
  }

  .table__collapse-arrow-button {
    position: absolute;
    left: -16px;
    top: 5px;
    cursor: pointer;
    transition: opacity 0.3s;

    &:hover {
      opacity: 0.6;
    }
  }
  .table__collapse-name {
    cursor: pointer;
    font-size: 14px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    margin-bottom: 0;
    display: flex;
    flex-direction: column;
  }

  .table__collapse-value {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
  }

  .table__collapse-path {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
  }

  // INDENT

  .table__collapse-first {
    position: relative;
    &::before {
      width: 12px;
      height: 12px;
      position: absolute;
      border-bottom: 1px solid #E0E5EB;
      border-left: 1px solid #E0E5EB;
      top: 0;
      left: -28px;
    }
    .table__collapse-name {
      cursor: pointer;
      font-size: 14px;

      &:hover {
        color: $primary;
      }
    }
  }

  @for $i from 1 through 20 {
    .table__indent-#{$i} {
        margin-left: $i * 24px;
        .table__collapse-first {
          &::before {
            content: '';
            left: -32px;
          }
        }
    }

    .table__collapse-no-arrow-#{$i} {
      margin-left: $i * 24px;
      .table__collapse-first {
        &::before {
          content: '';
          left: -14px;
        }
      }
    }
  }

  .footer-loader {
    display: flex;
    justify-content: center;
  }
}
</style>
