import { checkPermission } from '@/codex-permissions/config'
import ability from './ability'

export const canNavigate = to => to.matched.some(route => {
  if (route.meta.permission && route.meta.permission.constructor == String) {
    return checkPermission(route.meta.permission).can
  }
  return ability.can(route.meta.action)
})

export const _ = undefined
