<template>
  <div>
    <div
      class="upload-field"
      :class="{'upload-field--no-data': !_value}"
    >
      <div
        v-if="uploading"
        class="upload-field__loading"
      >
        <b-spinner
          class="media__logo-uploading"
          variant="primary"
          label=""
        />
      </div>
      <label
        v-show="!_value && !uploading"
        for="upload-field__input"
      >
        <GjIcon
          class="upload-field__icon"
          name="IconparkUploadOne"
        />
        <div
          class="upload-field__label"
          @click="browseMedias"
        >
          {{ $t('site.add-site.browse') }}
          <input
            v-show="type === 'upload'"
            id="upload-field__input"
            ref="inputImage"
            type="file"
            :accept="acceptedFileExtensions"
            @change="uploadImage"
          >
        </div>
      </label>
    </div>
    <div
      v-show="_value && !uploading"
      class="upload-field__items"
    >
      <div
        class="upload-field__item"
      >
        <img
          v-if="_value"
          :src="`${!_value.includes('https') ? 'https://admin.codexcdn.net/' : ''}${_value}`"
          alt=""
          class="upload-field__image"
        >
        <label
          for="upload-field__input"
          class="upload-field__replace"
          @click="browseMedias"
        >
          {{ $t('site.add-site.replace') }}
        </label>
        <span
          class="upload-field__remove"
          @click="removeImage"
        >
          <GjIcon name="CloseCancelCircleFilled" />
        </span>
      </div>
    </div>

  </div>
</template>

<script>
import { ASSET_TYPES, IMAGE_EXTENSIONS, uploadGeneralAsset } from '@/codex-sdk/assets'
import { validateAssets } from '@/utils/helpers'
import { MAX_FILE_SIZE } from '@/utils/constants'

export default {
  name: 'UploadField',
  props: ['value', 'type'],
  inject: ['showAssetsPopup', 'toastNotification'],
  data() {
    return {
      acceptedFileExtensions: IMAGE_EXTENSIONS.map(e => `.${e}`).join(', '),
      uploading: false,
    }
  },
  computed: {
    _value: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit('input', v)
      },
    },
  },
  methods: {
    removeImage() {
      this._value = null
      if (this.$refs?.inputImage) {
        this.$refs.inputImage.value = ''
      }
    },
    async browseMedias() {
      if (this.type === 'browse') {
        const data = await this.showAssetsPopup({ limit: 1 })
        if (!data) {
          return
        }
        this._value = data[0].url
      }
      if (this.type === 'upload') {
        await this.uploadImage()
      }
    },
    validateFileSize(file) {
      if (file.size > MAX_FILE_SIZE) {
        this.toastNotification({
          icon: 'XIcon',
          variant: 'danger',
          title: 'Error',
          text: this.$t('site.add-site.filesize-alert', { size: `${MAX_FILE_SIZE / 1024 ** 2}MB` }),
        })

        return false
      }
      return true
    },
    async uploadImage(event) {
      try {
        this.uploading = true
        const file = event.target.files[0]
        const incorrectFormatAssets = validateAssets([file], ASSET_TYPES.IMAGE)

        if (file && this.validateFileSize(file) && !incorrectFormatAssets.length) {
          await uploadGeneralAsset(file)
            .then(response => {
              this._value = response.data.path
            })
            .catch(error => {
              console.log(error)
            })
        } else if (incorrectFormatAssets.length) {
          // eslint-disable-next-line no-alert
          this.toastNotification({
            icon: 'XIcon',
            variant: 'danger',
            title: 'Error',
            text: this.$t('site.add-site.filetype-alert', { type: 'image' }),
          })
        }
      } catch (e) {
        console.log(e)
      } finally {
        this.uploading = false
      }
    },
  },
}
</script>

<style lang="scss">
.upload-field {
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.upload-field--no-data {
  padding: 16px;
  border: 1px dashed #C8D0DA;
}

.upload-field__label {
  font-weight: 400;
  font-size: 14px;
  text-decoration-line: underline;
  color: #1D417B;
  cursor: pointer;
}

.upload-field__items {
  display: flex;
  justify-content: flex-end;
}

.upload-field__item {
  position: relative;
  //width: fit-content;
  display: inline-block;
  border-radius: 4px;
  background-color: #F4F6FA;
}

.upload-field__remove {
  position: absolute;
  right: 0;
  top: 0;
  transform: translate(50%, -50%);
  color: var(--danger);
  background-color: white;
  border-radius: 100px;
  cursor: pointer;
}

.upload-field__replace {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;

  padding: 4px;
  background: rgba(29, 65, 123, 0.5);
  backdrop-filter: blur(2.5px);
  cursor: pointer;
  margin-bottom: 0;

  text-align: center;
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;
  color: #FFFFFF;

  opacity: 0;
  transition: opacity 0.2s linear;
  @at-root .upload-field__item:hover & {
    opacity: 1;
  }
}

.upload-field__image {
  aspect-ratio: 1.7/1;
  width: 120px;
  object-fit: cover;
  overflow: hidden;
  border-radius: 4px;
}

.upload-field__loading {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 16px 0;
}

</style>
