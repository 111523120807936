<template>
  <b-form-group>
    <template #label>
      <div class="codex-field-url__label">
        <FieldName
          :name="name"
          :help-text="helpText"
          :help-text-display="helpTextDisplay"
          :required="required"
        >
          <template #top-right>
            <GjIcon
              v-if="isURLEditable && !readOnly && editable"
              name="Refresh"
              size="18"
              class="refresh-icon"
              @click.native="setURL(entry)"
            />
          </template>
        </FieldName>
      </div>
    </template>
    <b-input-group>
      <template #prepend>
        <label
          class="codex-field-url__icon"
          :for="widget.id"
        >
          <GjIcon name="Hyperlink" />
        </label>
      </template>
      <b-form-input
        v-if="generateOnly"
        :id="widget.id"
        :value="computedValue"
        :readonly="!isURLEditable || generateOnly"
        :disabled="readOnly || !editable"
        class="url__sidebar-value at-urls-path-input"
      />
      <b-form-input
        v-else
        :id="widget.id"
        v-model="computedValue"
        :readonly="!isURLEditable || generateOnly"
        :disabled="readOnly || !editable"
        class="url__sidebar-value at-urls-path-input"
      />
    </b-input-group>
    <FieldError :error="error" />
  </b-form-group>
</template>

<script>
import { generateComputedPropsFromAttrs } from '@/components/codex-layout-editor/BuilderUtils'
import { ENTRY_STATUSES } from '@/codex-sdk/entries'
import { generateURL } from '@/views/entries/configs'
import FieldName from '@/components/fields/FieldName.vue'
import BaseFieldMixin from '@/components/fields/BaseFieldMixin'
import FieldRenderMixin from '@/components/fields/RenderFieldMixin'
import FieldError from '@/components/fields/FieldError.vue'
import { checkPermissionAsync } from '@/codex-permissions/config'
import { getFieldPermissions } from '@/utils/helpers'
import { FIELD_FILTER_OPERATORS } from '@/views/models/constants'

export default {
  name: 'UrlRender',
  components: {
    FieldError,
    FieldName,
  },
  mixins: [BaseFieldMixin, FieldRenderMixin],
  props: {
    value: {
      type: [String, Array],
      default: null,
    },
    entry: Object,
  },
  inject: ['model', 'isPreview', 'entryLabels', 'toastNotification'],
  data() {
    return {
      currentModel: this.model(),
    }
  },
  computed: {
    ...generateComputedPropsFromAttrs([
      'name',
      'alias',
      'configured',
      'appearance',
      'validation',
      'valueType',
      'helpText',
      'helpTextDisplay',
      'defaultValue',
      'pattern',
      'editableIn',
      'generateOnly',
    ]),
    isURLEditable() {
      return this.editableIn.includes(this.entry?.system?.status)
    },
    computedValue: {
      get() {
        return this.value
      },
      set(v) {
        if (this.isURLEditable) {
          this.$emit('input', v)
        }
      },
    },
  },
  watch: {
    entry: {
      // eslint-disable-next-line no-unused-vars
      handler(entry, prevEntry) {
        if (this.entry.system.status !== ENTRY_STATUSES.DRAFT || this.isPreview()) return
        this.setURL(entry, prevEntry)
      },
      deep: true,
    },
  },
  beforeMount() {
    if (!this.widget.attrs.validation) {
      this.$set(this.widget.attrs, 'validation', { })
    }

    if (!this.validation.required) {
      this.$set(this.validation, 'required', {
        value: false,
        errorMessage: 'URL is required',
      })
    }

    if (!this.validation.matchingRegex) {
      this.$set(this.validation, 'matchingRegex', {
        isEnabled: false,
        type: 1,
        patterns: [],
        flags: [],
        errorMessage: 'Pattern does not match',
      })
    }

    if (!this.widget.attrs.hidden) {
      this.$set(this.widget.attrs, 'hidden', {
        value: false,
        conditionsEnabled: false,
        conditions: [
          {
            isSystem: false,
            field: '',
            operator: FIELD_FILTER_OPERATORS.EXISTS,
            value: '',
          },
        ],
      })
    }
  },
  mounted() {
    this.$root.$on('ai-generated-content', this.setAiGeneratedContent)
  },
  beforeDestroy() {
    this.$root.$off('ai-generated-content', this.setAiGeneratedContent)
  },
  methods: {
    setAiGeneratedContent(alias, value, replace) {
      if (this.alias === alias && replace) {
        this.computedValue = value
        this.toastNotification({
          title: replace ? this.$t('site.add-site.replace') : this.$t('fields.general.ai-popup.results.retry-label'),
          text: this.$t('entries.ai-content-analyzer.seo.ai-suggestion-replaced'),
          variant: 'success',
          icon: 'Check',
        })
      }
    },
    async setURL(entry, prevEntry) {
      const canEditContent = await checkPermissionAsync(this.entry.id, 'entry', 'editcontent', { model: this.entry.system.modelAlias, siteId: this.entry.system.siteId, entryLabels: this.entryLabels?.() }, true)
      const permissionsPerField = getFieldPermissions(canEditContent, this.model())
      if (!entry?.id || this.readOnly || !this.editable || !permissionsPerField[this.alias]) return
      const oldURL = await generateURL(this.pattern, prevEntry, this.currentModel)
      const newURL = await generateURL(this.pattern, entry, this.currentModel)
      if (!prevEntry) {
        this.computedValue = newURL
      }
      if ((newURL !== this.computedValue && this.computedValue === oldURL && (this.entry.system.status === ENTRY_STATUSES.DRAFT || this.isURLEditable)) || this.computedValue === null) {
        this.computedValue = newURL
      }
    },
    validate(value) {
      const urlLength = value?.length || 0

      // Required
      if (this.required && urlLength < 1) {
        return { isValid: false, message: this.validation.required.errorMessage }
      }

      if (urlLength > 0 && !value.startsWith('/')) {
        return { isValid: false, message: this.$t('fields.codex-field-url.render.url-validation') }
      }

      if (this.validation.matchingRegex.isEnabled && this.validation.matchingRegex.patterns.length && urlLength) {
        let errors = false
        this.validation.matchingRegex.patterns.forEach(pattern => {
          const regex = new RegExp(pattern, this.validation.matchingRegex.flags.join(''))
          if (!regex.test(value)) {
            errors = true
          }
        })
        if (errors) return { isValid: false, message: this.validation.matchingRegex.errorMessage }
        return { isValid: true }
      }

      return { isValid: true }
    },
  },
}
</script>

<style lang="scss" scoped>
.codex-field-url__label {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.text-field__inputs {
  display: flex;
  flex-direction: column;
}

.text-field__add-input {
  width: fit-content;
  margin-left: auto;
}

.text-field__input-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 15px;
}

.text-field__input-align {
  display: flex;
  flex-grow: 1;
  align-items: center;
}
.text-wrap__inputs{
  word-break: break-all;
}

.refresh-icon {
  cursor: pointer;
}
.codex-field-url__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  border-left: 1px solid #d8d6de;
  border-top: 1px solid #d8d6de;
  border-bottom: 1px solid #d8d6de;
  padding: 8px;
  color: #206ED5;
}
</style>
