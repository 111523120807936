import { getCurrentOrganizationId } from '@/services/siteService'
import i18n from '@/libs/i18n'
import { apiAxios as axios } from '../libs/axios'
import { tc } from './config'

export const integrationGroups = Object.freeze({
  TECH: 1,
  CONTENT: 2,
  ASSETS: 3,
  OTHER: 4,
})

export const INTEGRATION_STATUSES = Object.freeze({
  ACTIVE: 1,
  DELETED: 2,
  DISCONNECTED: 3,
})

export const INTEGRATION_ALIASES = Object.freeze({
  VP: 'vp',
  VP_UPLOADER: 'vp-uploader',
  GENEEA_V1: 'geneea',
  GENEEA_V2: 'geneea-media',
  PROLEXIS: 'prolexis',
  MBASE: 'mbase',
  DANDELION: 'dandelion',
  CMI_FRANCE: 'cmi-france-tags',
})

export function mapIntegrationGroups(group) {
  const groups = {
    [integrationGroups.TECH]: 'integrations.groups.gjirafa-tech',
    [integrationGroups.CONTENT]: 'integrations.groups.content',
    [integrationGroups.ASSETS]: 'integrations.groups.assets',
    [integrationGroups.OTHER]: 'integrations.groups.other',
  }
  return i18n.t(groups[group] || 'integrations.groups.other')
}

export function vpDefaults(vpPlayer = {}) {
  return {
    integration: null,
    organizationId: getCurrentOrganizationId(),
    attrs: {
      connectionName: null,
      organization: null,
      projects: [],
      player: null,
      audioPlayer: null,
      folderId: [],
    },
    ...vpPlayer,
  }
}

export function prolexisDefaults(prolexis = {}) {
  return {
    integration: null,
    organizationId: getCurrentOrganizationId(),
    attrs: {
      connectionName: null,
      apiKey: null,
      sites: null,
      url: '',
    },
    ...prolexis,
  }
}

export function geneeaDefaults(geneea = {}) {
  return {
    integration: null,
    organizationId: getCurrentOrganizationId(),
    attrs: {
      userKey: null,
    },
    ...geneea,
  }
}

export function vpUploaderDefaults(vpUploader = {}) {
  return {
    integration: null,
    organizationId: getCurrentOrganizationId(),
    attrs: {
      organizationId: null,
      projectIds: [],
      apiKey: null,
    },
    ...vpUploader,
  }
}

export function mBaseDefaults(mBase = {}) {
  return {
    integration: null,
    organizationId: getCurrentOrganizationId(),
    attrs: {
      apiKey: null,
      url: null,
    },
    ...mBase,
  }
}

export function dandelionDefaults(dandelion = {}) {
  return {
    integration: null,
    organizationId: getCurrentOrganizationId(),
    attrs: {
      connectionName: null,
      apiKey: null,
      sites: null,
      params: [],
    },
    ...dandelion,
  }
}

export function cmiFranceTagsDefaults(cmiFrance = {}) {
  return {
    integration: null,
    organizationId: getCurrentOrganizationId(),
    attrs: {
      connectionName: null,
      host: null,
      apiKey: null,
      sites: null,
      params: [],
    },
    ...cmiFrance,
  }
}

export function readActivations(alias) {
  return axios.get('{organization}/integrations', {
    params: {
      alias,
    },
  })
}

export function createActivation(activation, messages) {
  return tc(axios.post(`{organization}/integrations/${activation.integration}`, activation), {
    successTitle: 'sdk.success-title',
    successMessage: 'sdk.all-integrations.create-successful',
    failTitle: 'sdk.all-integrations.create-failed',
    ...messages,
  })
}

export function readActivation(alias, activationId) {
  return axios.get(`{organization}/integrations/${alias}/${activationId}`)
}

export function updateActivation(activation, msg) {
  return tc(axios.put(`{organization}/integrations/${activation.integration}/${activation.id}`, activation), {
    successTitle: 'sdk.success-title',
    successMessage: 'sdk.all-integrations.update-successful',
    failTitle: 'sdk.all-integrations.update-failed',
    ...msg,
  })
}

export function deleteActivation(alias, activationId, msg) {
  return tc(axios.delete(`{organization}/integrations/${alias}/${activationId}`), {
    successTitle: 'sdk.success-title',
    successMessage: 'sdk.all-integrations.delete-successful',
    failTitle: 'sdk.all-integrations.delete-failed',
    ...msg,
  })
}

export function geneeaTags(text, userKey) {
  return tc(axios.post(`{organization}/integrations/external/geneea/tags?user_key=${userKey}`, {
    text,
  }), {
    successTitle: null,
    successMessage: null,
    failTitle: null,
  })
}

export function geneeaV2Tags(content, key) {
  return tc(axios.post('{organization}/integrations/external/geneea-media/nlp/analyze', content, {
    headers: {
      'X-API-KEY': key,
    },
  }), {
    successTitle: null,
    successMessage: null,
    failTitle: null,
  })
}

export function dandelionTags(input, attrs) {
  const params = {}
  attrs.params.forEach(param => {
    params[param.key] = param.value
  })
  return tc(axios.post(`{organization}/integrations/external/dandelion/v1?text=${encodeURIComponent(input)}`, {}, {
    params: {
      ...params,
      token: attrs.apiKey,
    },
  }), {
    successTitle: null,
    successMessage: null,
    failTitle: null,
  })
}

export function cmiFranceTags(text, connection) {
  return tc(axios.post(connection.host, {
    text,
  }, {
    headers: {
      'X-Api-Key': connection.apiKey,
    },
  }), {
    successTitle: null,
    successMessage: null,
    failTitle: null,
  })
}

export function aiGeneratedTags(entryId) {
  return tc(axios.post(`{organization}/ai/generate/tags/${entryId}`), {
    successTitle: null,
    successMessage: null,
    failTitle: null,
  })
}
