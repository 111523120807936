<template>
  <div class="listing-loading">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M21 12.0004C20.9999 13.901 20.3981 15.7528 19.2809 17.2904C18.1637 18.8279 16.5885 19.9723 14.7809 20.5596C12.9733 21.1469 11.0262 21.1468 9.21864 20.5594C7.41109 19.9721 5.83588 18.8276 4.71876 17.29C3.60165 15.7523 2.99999 13.9005 3 11.9999C3.00001 10.0993 3.60171 8.24755 4.71884 6.70994C5.83598 5.17233 7.4112 4.02785 9.21877 3.44052C11.0263 2.85319 12.9734 2.85316 14.781 3.44044"
        :stroke="color"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
    <p
      v-if="_label !== null && label !== ''"
      class="listing-loading__label"
    >
      {{ _label }}
    </p>
  </div>
</template>

<script>
export default {
  name: 'LoadingSpinner',
  props: {
    label: {
      required: false,
      type: String,
      default: null,
    },
    color: {
      required: false,
      type: String,
      default: '#206ED5',
    },
  },
  computed: {
    _label() {
      return this.label === null ? this.$t('general.loading') : this.label
    },
  },
}
</script>

<style scoped lang="scss">
.listing-loading {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;

  svg {
    animation: 0.9s linear infinite spin;
  }
  .listing-loading__label {
    margin-top: 4px;
    color:  #052D61;
    font-size: 14px;
    line-height: 21px;
  }
}
@keyframes spin {
  from {
    transform:rotate(0deg);
  }
  to {
    transform:rotate(360deg);
  }
}
</style>
