<template>
  <div class="new-filters-dropdown__filter-body">

    <b-row>
      <b-col cols="12">
        <vSelect
          v-model="value.operator"
          :options="operatorOptions"
          :clearable="false"
          :reduce="option => option.value"
          :searchable="false"
          :no-drop="operatorOptions.length < 2"
          label="label"
        />
      </b-col>
    </b-row>

    <b-row
      v-if="!isExistsOperator"
      no-gutters
      class="new-filters-dropdown__items"
    >
      <div
        v-for="option in options"
        :key="option.value"
        class="new-filters-dropdown__item"
        :class="{
          'new-filters-dropdown__item--active': value.value === option.value,
        }"
        @click="value.value = option.value"
      >
        {{ option.label }}
      </div>
    </b-row>

  </div>
</template>

<script>
import BooleanFilter from './BooleanFilter'

export default {
  props: {
    filter: {
      type: Object,
      required: true,
    },
    filterObject: {
      type: [Object, Function],
      required: true,
    },
    value: {
      type: Object,
      required: true,
    },
  },

  computed: {
    operatorOptions() {
      return [
        {
          label: this.$t('filters.is'),
          value: BooleanFilter.OPERATORS.equals,
        },
      ]
    },
    options() {
      return [
        {
          label: this.$t('filters.true'),
          value: true,
        },
        {
          label: this.$t('filters.false'),
          value: false,
        },
      ]
    },
    isExistsOperator() {
      return this.value.operator === BooleanFilter.OPERATORS.exists || this.value.operator === BooleanFilter.OPERATORS.notExists
    },
  },
}
</script>

<style>

</style>
