import Vue from 'vue'
import i18n from '@/libs/i18n'
import { generateEventTypesDefaultMessages } from '@/codex-sdk/workflows'
import ActionSysNotifications from './ActionSysNotifications'
import ActionExternalNotifications from './ActionExternalNotifications'

export default class ActionBase {
  constructor({
    Id = null, ActionType = null, Status = 0, ...rest
  } = {}) {
    this.id = Id
    this.actionType = ActionType
    this.status = Status
    const r = rest ?? {}
    this.setProperties(ActionType, r)
  }

  setProperties(type = null, rest = {}) {
    let newProperties = {}
    // Because the difference of api response is key with first letter uppercase 'Title',
    // and the constructor keys are with lowercase 'title', use this method to tranform
    // because the constructor is expecting an object with 'Title' and has default value
    rest = this.capitalizeKeys(rest)
    switch (type) {
      case 1:
      case 2:
        newProperties = new ActionSysNotifications(rest)
        break
      case 3:
      case 4:
        newProperties = new ActionExternalNotifications(rest)
        break
      // TODO: Add more cases for other types
      default:
        break
    }

    if (!newProperties.isMessageCustom) newProperties.body = this.setDefaultBodyMessage(rest.EventTypes)

    // This is used for Action class reactivity purposes
    Object.keys(newProperties).forEach(key => {
      Vue.set(this, key, newProperties[key])
    })
  }

  setDefaultBodyMessage(eventType) {
    return i18n.t(generateEventTypesDefaultMessages(eventType))
  }

  capitalizeKeys(obj) {
    return Object.keys(obj).reduce((acc, key) => {
      const capitalizedKey = key.charAt(0).toUpperCase() + key.slice(1)
      acc[capitalizedKey] = obj[key]
      return acc
    }, {})
  }

  // Use this function to reset Action to constructor data without losing reactivity
  resetAction() {
    Object.keys(this).forEach(key => {
      if (key === 'id' || key === 'actionType') return
      delete this[key]
    })
    Vue.set(this, 'actionType', null)
  }

  getPayload(automationId) {
    return this.actionType ? {
      actionType: this.actionType,
      automationId,
      data: JSON.stringify({
        automationId,
        ...this,
      }),
    } : null
  }
}
