import { VALUE_TYPES } from '@/views/models/constants'
import { CODEX_EDITOR_BLOCKS } from '@/components/codex-editor/CodeEditorConstants'

export const resolveRichContentComponent = type => {
  switch (type) {
    case CODEX_EDITOR_BLOCKS.PARAGRAPH:
    case CODEX_EDITOR_BLOCKS.HEADING:
    case 'codex_blockquote_content':
    case 'codex_blockquote_author':
    case CODEX_EDITOR_BLOCKS.CODE_BLOCK:
      return 'text-component'
    case CODEX_EDITOR_BLOCKS.ORDERED_LIST:
    case CODEX_EDITOR_BLOCKS.BULLET_LIST:
      return 'list-component'
    case CODEX_EDITOR_BLOCKS.FACTBOX:
    case CODEX_EDITOR_BLOCKS.BLOCKQUOTE:
      return 'blockquote-component'
    case CODEX_EDITOR_BLOCKS.REFERENCE:
      return 'reference-field'
    case CODEX_EDITOR_BLOCKS.MEDIA:
      return 'media-field'
    case CODEX_EDITOR_BLOCKS.GOOGLE_MAP_EMBED:
      return 'location-field'
    case CODEX_EDITOR_BLOCKS.TABLE:
      return 'table-component'
    case CODEX_EDITOR_BLOCKS.SPOTIFY_PODCAST:
    case CODEX_EDITOR_BLOCKS.FACEBOOK_EMBED:
    case CODEX_EDITOR_BLOCKS.YOUTUBE_EMBED:
    case CODEX_EDITOR_BLOCKS.INSTAGRAM_EMBED:
    case CODEX_EDITOR_BLOCKS.TWITTER_EMBED:
    case CODEX_EDITOR_BLOCKS.FAMEPLAY_EMBED:
    case CODEX_EDITOR_BLOCKS.VIMEO_EMBED:
    case CODEX_EDITOR_BLOCKS.TIKTOK_EMBED:
    case CODEX_EDITOR_BLOCKS.GOOGLE_PODCAST:
    case CODEX_EDITOR_BLOCKS.APPLE_PODCAST:
      return 'embed-component'
    default:
      return 'default-component'
  }
}

export const resolveHtmlTag = block => {
  switch (block.type) {
    case CODEX_EDITOR_BLOCKS.PARAGRAPH:
    case 'codex_blockquote_content':
      return 'p'
    case CODEX_EDITOR_BLOCKS.HEADING:
      return `h${block.attrs.level}`
    case CODEX_EDITOR_BLOCKS.ORDERED_LIST:
      return 'ol'
    case CODEX_EDITOR_BLOCKS.BULLET_LIST:
      return 'ul'
    case 'codex_blockquote_author':
      return 'small'
    case CODEX_EDITOR_BLOCKS.CODE_BLOCK:
      return 'pre'
    default: return 'p'
  }
}

export const getCustomProps = (block, isNestedItem = false) => {
  switch (block.type) {
    case CODEX_EDITOR_BLOCKS.PARAGRAPH:
    case CODEX_EDITOR_BLOCKS.HEADING:
      return isNestedItem ? { block } : {
        block,
        classes: ['rich-content__textfield'],
      }
    case CODEX_EDITOR_BLOCKS.REFERENCE:
      return {
        fieldContent: block.attrs.references,
        field: {
          valueType: VALUE_TYPES.LIST,
        },
        showFieldName: false,
        classes: isNestedItem ? ['mt-25'] : [],
      }
    case CODEX_EDITOR_BLOCKS.MEDIA:
      return {
        fieldContent: block.attrs.media,
        field: {
          valueType: VALUE_TYPES.LIST,
        },
        showFieldName: false,
        caption: block.attrs.caption,
        classes: isNestedItem ? ['mt-1'] : [],
      }
    case CODEX_EDITOR_BLOCKS.GOOGLE_MAP_EMBED:
      return {
        fieldContent: {
          latitude: block.attrs.lat,
          longitude: block.attrs.lng,
        },
        showFieldName: false,
        caption: block.attrs.caption,
        classes: isNestedItem ? ['d-inline-block mt-1'] : [],
      }
    default:
      return {
        block,
      }
  }
}
