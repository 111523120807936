<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 25 24"
    xmlns="http://www.w3.org/2000/svg"
    class="ai-icon"
    fill="currentColor"
    style="border-radius: 50%;"
  >
    <rect
      x="0.5"
      width="24"
      height="24"
      rx="12"
      style="fill: none !important;"
    />
    <path
      d="M16.3329 13.3189C12.5999 12.4755 12.0217 11.897 11.1787 8.16236C11.1401 7.99167 10.9882 7.87012 10.8127 7.87012C10.6372 7.87012 10.4853 7.99167 10.4467 8.16236C9.60336 11.897 9.0255 12.4755 5.29249 13.3189C5.1215 13.3579 5 13.5095 5 13.685C5 13.8606 5.1215 14.0122 5.29249 14.0512C9.0255 14.8949 9.60336 15.4734 10.4467 19.2077C10.4853 19.3784 10.6372 19.4999 10.8127 19.4999C10.9882 19.4999 11.1401 19.3784 11.1787 19.2077C12.022 15.4734 12.5999 14.8949 16.3329 14.0512C16.5039 14.0122 16.625 13.8606 16.625 13.685C16.625 13.5095 16.5035 13.3579 16.3329 13.3189Z"
      fill="#206ED5"
    />
    <path
      d="M19.7078 7.50425C17.7233 7.05594 17.4447 6.77719 16.9966 4.79225C16.9576 4.62118 16.8061 4.5 16.6306 4.5C16.4551 4.5 16.3036 4.62118 16.2646 4.79225C15.8165 6.77719 15.5379 7.05594 13.5538 7.50425C13.3828 7.54326 13.2617 7.69483 13.2617 7.8704C13.2617 8.04597 13.3828 8.19753 13.5538 8.23655C15.5379 8.68486 15.8165 8.9636 16.2646 10.9489C16.3036 11.1196 16.4551 11.2412 16.6306 11.2412C16.8061 11.2412 16.9576 11.1196 16.9966 10.9489C17.4447 8.9636 17.7233 8.68486 19.7078 8.23655C19.8784 8.19753 19.9999 8.04597 19.9999 7.8704C19.9999 7.69483 19.8784 7.54326 19.7078 7.50425Z"
      fill="#206ED5"
    />
  </svg>
</template>

<script>

export default {
  name: 'AiIcon',
  props: {
    size: {
      type: String,
      default: '24',
    },
  },
}
</script>

<style>
.ai-icon * {
  fill: currentColor;
}
</style>
