import ability from '@/libs/acl/ability'
import { getUserData } from '@/auth/utils'
import { pr } from './config'

// * Create entity file, and can functions
// * Import and add them to allCanFunctions object down below
// * Add defaults into the permissions array down below
// * Implement v-permission directives on neccessary buttons
// Implement permissions on entities sdk functions
// * Implement permissions on navigation and route - Everyone has access to view media

export const Authors = Object.freeze({
  VIEW: 'author_view',
  MANAGE: 'author_manage',
})

export const AuthorsToolTips = {
  [Authors.VIEW]: 'Allows users to view author list and author details in ‘view only’ mode',
  [Authors.MANAGE]: 'Allows users to perform all CRUD operations in Authors',
}

// eslint-disable-next-line no-unused-vars
function isCurrentUser(entity) {
  const userId = entity?.id
  const currentUserData = getUserData()
  if (!currentUserData) return false
  return userId == currentUserData.id
}

/**
 * Can VIEW Authors
 * */
export function canViewAuthors(_options = {}) {
  const { entity, ...options } = _options
  return pr(
    ability.can(Authors.VIEW) || ability.can(Authors.MANAGE),
    'You have no permission to view authors',
    options,
  )
}

/**
 * Can ADD new Author
 * */
export function canCreateAuthors(_options = {}) {
  const { entity, ...options } = _options
  return pr(
    ability.can(Authors.MANAGE),
    'You don’t have permission to create a new author',
    options,
  )
}

/**
 * Can EDIT Author
 * */

export function canEditAuthor(_options = {}) {
  const { entity, ...options } = _options
  return pr(
    ability.can(Authors.MANAGE),
    'You don’t have permission to edit an author',
    options,
  )
}

/**
 * Can DELETE Author
 * */

export function canDeleteAuthor(_options = {}) {
  const { entity, ...options } = _options
  return pr(
    ability.can(Authors.MANAGE),
    'You don’t have permission to delete an author',
    options,
  )
}

/**
 * Can VIEW Author Details
 * */

export function canViewAuthorDetails(_options = {}) {
  const { entity, ...options } = _options
  return pr(
    ability.can(Authors.VIEW),
    'You don’t have permission to view author details',
    options,
  )
}
