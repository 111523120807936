<template>
  <div class="version__history-listing">
    <div
      v-if="!assetId"
      class="notifications__loading-section mb-1"
    >
      <b-spinner variant="primary" />
    </div>

    <div v-else-if="!Object.keys(versions).length">
      <p class="d-flex justify-content-center">
        {{ $t('entries.versions.no-versions') }}
      </p>
    </div>

    <template v-else>
      <div
        v-for="([month, value], index) in Object.entries(versions)"
        :key="month.id"
      >
        <p
          class="version__history-listing-month"
          :class="`version__history-listing-month-${index}`"
        >
          {{ month }}
        </p>
        <Version
          v-for="version in value"
          :key="version.id"
          :version="version"
          :asset-version="assetVersion"
          :comparing-id="comparingId"
          @fetchVersions="fetchVersions"
        />
      </div>
    </template>
  </div>
</template>

<script>
import { groupBy, orderBy } from 'lodash'
import moment from 'moment'

import { readAssetVersions } from '@/codex-sdk/assets'
import gql from 'graphql-tag'
import Version from './Version.vue'

export default {
  name: 'Listing',
  components: {
    Version,
  },
  props: {
    assetVersion: Object,
    comparingId: String,
    asset: Object,
  },
  data() {
    return {
      versions: [],
    }
  },
  computed: {
    assetId() {
      return this.asset.id
    },
  },
  watch: {
    assetId: {
      handler() {
        this.init()
      },
      immediate: true,
    },
  },
  methods: {
    async init() {
      await this.fetchVersions()
    },
    async fetchVersions() {
      let versions = []
      try {
        const { data } = await readAssetVersions(this.assetId)
        versions = orderBy(data, ['createdAt'], ['desc'])
        const sorted = groupBy(versions, obj => moment(obj.createdAt).format('MMMM YYYY'))
        this.versions = sorted
      } catch (e) {
        console.log(e)
      }

      try {
        if (!versions?.length) return

        const { data: { userCollection } } = await this.$apollo.query({
          query: gql`
          query ($ids: [String!]){
            userCollection (where: {id: {in: $ids}}) {
              items {
                id
                imageUrl
                email
                firstName
                lastName
              }
            }
          }
        `,
          variables: {
            ids: versions.map(v => v.createdBy),
          },
        })

        versions.forEach(version => {
          const user = userCollection.items.find(u => u.id === version.createdBy)
          if (user) {
            user.fullName = user ? `${user.firstName || ''} ${user.lastName || ''}`.trim() : ''
          }
          version.createdBy = user
        })
      } catch (e) {
        console.log(e)
      }
    },
  },
}
</script>
