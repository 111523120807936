import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import modulesGeneral from '@/store/general/modulesGeneral'
import modulesModels from '@/store/models/modulesModels'
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'

import modulesPlugins from './plugins/modulesPlugins'
import modulesAuthors from './authors/modulesAuthors'
import modulesSections from './sections/modulesSections'
import modulesOrganizations from './organizations/modulesOrganizations'
import modulesSites from './sites/modulesSites'
import modulesUsers from './users/modulesUsers'
import modulesSiteUsers from './site-users/modulesSiteUsers'
import modulesAutoSave from './auto-save/modulesAutoSave'
import modulesAssets from './assets/modulesAssets'
import modulesWorkflows from './workflows/modulesWorkflows'
import mergingTool from './merging-tool'
import focus from './focus'
import filters from './filters'
import editor from './editor/modulesEditor'
import vpUploads from './vp-upload/index'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    general: modulesGeneral,
    plugins: modulesPlugins,
    authors: modulesAuthors,
    autoSave: modulesAutoSave,
    sections: modulesSections,
    organizations: modulesOrganizations,
    sites: modulesSites,
    users: modulesUsers,
    siteUsers: modulesSiteUsers,
    models: modulesModels,
    assets: modulesAssets,
    workflows: modulesWorkflows,
    mergingTool,
    focus,
    filters,
    editor,
    vpUploads,
  },
  strict: process.env.DEV,
})
