<template>
  <ResizableBlock
    :attrs="attrs"
    :selected="selected"
    :select-node="selectNode"
    :update-attrs="updateAttrs"
    :entity-ref="$refs.fameplayEmbed"
    min-width="30%"
  >
    <div
      v-show="attrs.src"
      ref="fameplayEmbed"
      style="padding-top:56.25%; position:relative; width:100%;"
    >
      <iframe
        v-if="embedSrc"
        width="100%"
        class="no-margin"
        style="position:absolute; left:0; top:0; width: 100%; height:100%;"
        :src="embedSrc"
        allow="autoplay *; encrypted-media *; fullscreen *"
        frameborder="0"
        sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-storage-access-by-user-activation allow-top-navigation-by-user-activation"
      />
      <div v-else>
        {{ $t('codex-editor.nodes.fameplay-embed.text') }}
      </div>
    </div>
  </ResizableBlock>
</template>

<script>
import ResizableBlock from '@/components/ResizableBlock.vue'

export default {
  components: {
    ResizableBlock,
  },
  props: ['attrs', 'updateAttrs', 'selectNode', 'selected'],
  inject: ['deleteBlock', 'showPromptPopup', 'toastNotification'],
  data() {
    return {
      initialSrc: '',
      showEmbed: false,
    }
  },
  computed: {
    embedSrc() {
      try {
        if (this.attrs.src.includes('/embed')) {
          return this.attrs.src
        }
        if (this.attrs.src) {
          const url = new URL(this.attrs.src)
          return `${url.origin}/embed${url.pathname}`
        }
      } catch (e) {
        return null
      }
      return null
    },
  },
  watch: {
    src() {
      this.$nextTick(() => {
        this.selectNode()
      })
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.initialSrc = this.src
      if (this.$refs.sourceField) {
        this.$refs.sourceField.$refs.vsinput.focus()
      }
    })
  },

  methods: {
    initialize() {
      if (!this.attrs.src) {
        this.openModal()
      }
    },
    setSrc() {
      this.attrs.src = this.initialSrc
      this.updateAttrs({
        src: this.initialSrc,
      })
    },
    async openModal() {
      const url = await this.showPromptPopup({ title: this.$t('codex-editor.nodes.fameplay-embed.title') })
      if (!url) {
        this.deleteBlock(this.attrs.blockId)
      } else if (url.length && url.match((/^(https?:\/\/)?((w{3}\.)?)(mall.tv|mall.fameplay.tv|fameplay.tv)\/.*/i))) {
        this.updateAttrs({
          src: url,
        })
      } else {
        this.toastNotification({
          icon: 'XIcon',
          variant: 'danger',
          title: 'Error',
          text: this.$t('codex-editor.nodes.fameplay-embed.invalid-url'),
        })
        this.deleteBlock(this.attrs.blockId)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.sourceField {
  width: 100%;
  white-space: nowrap;
}
</style>
