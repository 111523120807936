<template>
  <div>
    <b-form-group
      :label="$t('fields.codex-field-media-content.general.field-name')"
      label-for="media-field__name"
    >
      <b-form-input
        id="media-field__name"
        v-model="name"
        :placeholder="$t('fields.codex-field-media-content.general.field-name-placeholder')"
      />
    </b-form-group>

    <b-form-group
      :label="$t('fields.codex-field-media-content.general.alias')"
      label-for="media-field__alias"
    >
      <b-form-input
        id="media-field__alias"
        :value="alias"
        disabled
      />
    </b-form-group>

    <b-form-group
      :label="$t('fields.codex-field-media-content.general.description')"
      label-for="media-field__description"
    >
      <b-form-textarea
        id="media-field__description"
        v-model="description"
        :placeholder="$t('fields.codex-field-media-content.general.description-placeholder')"
        rows="2"
        no-resize
      />
    </b-form-group>

    <b-form-group>
      <template #label>
        <span>
          {{ $t('fields.codex-field-media-content.general.default-upload-folder-label') }}
        </span>
        <span class="codex-field-range-tooltip">
          <GjIcon
            v-b-tooltip="$t('fields.codex-field-media-content.general.default-upload-folder-info') "
            name="Info"
            size="20"
          />
        </span>
      </template>
      <FolderSelect
        v-model="defaultFolderId"
        :has-multiple="false"
        :close-on-select="true"
        :placeholder="$t('fields.codex-field-media-content.general.default-upload-folder-placeholder')"
      />
    </b-form-group>

    <b-form-group
      :label="$t('fields.general.metrics.calculate-metrics')"
      label-for="media-content__calculate-metrics"
      class="mb-3"
    >
      <template #label>
        <span>
          {{ $t('fields.general.metrics.calculate-metrics') }}
        </span>
        <span
          id="media-content__calculate-metrics-tooltip"
          class="codex-field-range-tooltip"
        >
          <GjIcon
            name="Info"
            size="20"
          />
        </span>
      </template>
      <b-form-checkbox
        id="media-content__calculate-metrics"
        v-model="calculateMetrics"
        switch
        @input="($event) => { updateAttrs({calculateMetrics: $event}) }"
      />
    </b-form-group>

    <b-tooltip
      target="media-content__calculate-metrics-tooltip"
      triggers="hover"
    >
      <p class="media-content__calculate-metrics-intro">
        {{ $t('fields.general.metrics.calculate-metrics-tooltip') }}
      </p>

      <ul>
        <li>{{ $t('fields.general.metrics.character-count') }}</li>
        <li>{{ $t('fields.general.metrics.image-count') }}</li>
        <li>{{ $t('fields.general.metrics.sentences') }}</li>
        <li>{{ $t('fields.general.metrics.words') }}</li>
      </ul>

      <p class="media-content__calculate-metrics-intro">
        {{ $t('fields.general.metrics.enable-reading-time') }}
      </p>

    </b-tooltip>

    <b-form-group :label="$t('fields.codex-field-media-content.general.number-of-media')">
      <b-form-radio
        v-model="valueTypeReadonly"
        disabled
        :value="VALUE_TYPES.SINGLE"
      >
        {{ $t('fields.codex-field-media-content.general.one-media') }}
      </b-form-radio>
      <b-form-radio
        v-model="valueTypeReadonly"
        disabled
        :value="VALUE_TYPES.LIST"
      >
        {{ $t('fields.codex-field-media-content.general.multiple-media') }}
      </b-form-radio>
    </b-form-group>
    <b-form-group :label="$t('fields.codex-field-media-content.general.media-type')">
      <b-form-checkbox
        v-model="imageTypeFile"
        class="media__file-type"
      >
        {{ mapAssetTypeLabel(ASSET_TYPES.IMAGE) }}
      </b-form-checkbox>
      <b-form-checkbox
        v-model="filesTypeFile"
        class="media__file-type"
      >
        {{ mapAssetTypeLabel(ASSET_TYPES.FILE) }}
      </b-form-checkbox>
      <b-form-checkbox
        v-model="videoTypeFile"
        class="media__file-type"
      >
        {{ mapAssetTypeLabel(ASSET_TYPES.VIDEO) }}
      </b-form-checkbox>
      <b-form-checkbox
        v-model="playlistsTypeFile"
        class="media__file-type"
      >
        {{ mapAssetTypeLabel(ASSET_TYPES.VIDEO_PLAYLIST) }}
      </b-form-checkbox>
      <b-form-checkbox
        v-model="audioTypeFile"
        class="media__file-type"
      >
        {{ mapAssetTypeLabel(ASSET_TYPES.AUDIO) }}
      </b-form-checkbox>
      <b-form-checkbox
        v-model="podcastTypeFile"
        class="media__file-type"
      >
        {{ mapAssetTypeLabel(ASSET_TYPES.PODCAST) }}
      </b-form-checkbox>
    </b-form-group>
  </div>
</template>

<script>
import FolderSelect from '@/components/assets/FolderSelect.vue'
import { generateComputedPropsFromAttrs } from '@/components/codex-layout-editor/BuilderUtils'
import { VALUE_TYPES } from '@/views/models/constants'
import { ASSET_TYPES, mapAssetTypeLabel } from '@/codex-sdk/assets'
import BaseFieldMixin from '@/components/fields/BaseFieldMixin'

export default {
  name: 'General',
  components: { FolderSelect },
  mixins: [BaseFieldMixin],
  data() {
    return {
      ASSET_TYPES,
      mapAssetTypeLabel,
      modelData: this.model(),
      VALUE_TYPES,
      imageTypeFile: this.widget.attrs.validation.fileType.image,
      filesTypeFile: this.widget.attrs.validation.fileType.files,
      videoTypeFile: this.widget.attrs.validation.fileType.video,
      playlistsTypeFile: this.widget.attrs.validation.fileType.playlists,
      audioTypeFile: this.widget.attrs.validation.fileType.audio,
      podcastTypeFile: this.widget.attrs.validation.fileType.podcast,
    }
  },
  inject: ['model', 'showConfirmPopup'],
  computed: {
    ...generateComputedPropsFromAttrs([
      'valueType',
      'validation',
      'name',
      'alias',
      'calculateMetrics',
      'description',
      'defaultFolderId',
    ]),
    valueTypeReadonly: {
      get() {
        return this.valueType
      },
      set() {},
    },
  },
  watch: {
    'validation.fileType': {
      handler(v) {
        this.validation.fileType.isEnabled = v.image || v.files || v.video || v.playlists || v.audio || v.podcast
      },
      deep: true,
    },
    async imageTypeFile(value) {
      await this.checkForRequiredFeaturedMediaTypes('image', value)
    },
    async videoTypeFile(value) {
      await this.checkForRequiredFeaturedMediaTypes('video', value)
    },
    async filesTypeFile(value) {
      await this.checkForDeniedFeaturedMediaTypes('files', value)
    },
    async playlistsTypeFile(value) {
      await this.checkForDeniedFeaturedMediaTypes('playlists', value)
    },
    async audioTypeFile(value) {
      await this.checkForDeniedFeaturedMediaTypes('audio', value)
    },
    async podcastTypeFile(value) {
      await this.checkForDeniedFeaturedMediaTypes('podcast', value)
    },
  },
  methods: {
    async checkForRequiredFeaturedMediaTypes(key, value) {
      const requiredValues = this.imageTypeFile || this.videoTypeFile
      if (this.model()?.featuredMediaAlias === this.widget.attrs.alias && !value && !requiredValues) {
        const answer = await this.showConfirmPopup({
          title: this.$t('fields.codex-field-media-content.validation.featured-media-allowed-type-validation.title'),
          description: this.$t('fields.codex-field-media-content.validation.featured-media-allowed-type-validation.description'),
          okTitle: this.$t('fields.codex-field-media-content.validation.featured-media-allowed-type-validation.okTitle'),
          cancelTitle: this.$t('fields.codex-field-media-content.validation.featured-media-allowed-type-validation.cancelTitle'),
          okVariant: 'danger',
        })

        if (answer) {
          this.model().featuredMediaAlias = null
          this.validation.fileType[key] = value
        } else if (key === 'image') {
          this.imageTypeFile = this.validation.fileType[key]
        } else if (key === 'video') {
          this.videoTypeFile = this.validation.fileType[key]
        }
      } else {
        this.validation.fileType[key] = value
      }
    },
    async checkForDeniedFeaturedMediaTypes(key, value) {
      if (this.model()?.featuredMediaAlias === this.widget.attrs.alias && value) {
        const answer = await this.showConfirmPopup({
          title: this.$t('fields.codex-field-media-content.validation.featured-media-denied-type-validation.title'),
          description: this.$t('fields.codex-field-media-content.validation.featured-media-denied-type-validation.description'),
          okTitle: this.$t('fields.codex-field-media-content.validation.featured-media-denied-type-validation.okTitle'),
          cancelTitle: this.$t('fields.codex-field-media-content.validation.featured-media-denied-type-validation.cancelTitle'),
          okVariant: 'danger',
        })

        if (answer) {
          this.model().featuredMediaAlias = null
          this.validation.fileType[key] = value
        } else if (key === 'playlists') {
          this.playlistsTypeFile = this.validation.fileType[key]
        } else if (key === 'files') {
          this.filesTypeFile = this.validation.fileType[key]
        } else if (key === 'audio') {
          this.audioTypeFile = this.validation.fileType[key]
        } else if (key === 'podcast') {
          this.podcastTypeFile = this.validation.fileType[key]
        }
      } else {
        this.validation.fileType[key] = value
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.media-content__calculate-metrics-intro {
  font-size: 14px;
}
</style>
