<template>
  <div>
    <TextFormatPanel
      :commands="commands"
      :include-hyper-link="true"
      :is-active="isActive"
      :toggle-link-popup="toggleLinkPopup"
      :editor="editor"
    />
    <TableFormatPanel
      :commands="commands"
      :is-active="isActive"
    />

  </div>
</template>

<script>
import TextFormatPanel from '@/components/codex-editor/panels/TextFormatPanel.vue'
import TableFormatPanel from '@/components/codex-editor/panels/TableFormatPanel.vue'

export default {
  components: {
    TextFormatPanel,
    TableFormatPanel,
  },
  props: ['attrs', 'updateAttrs', 'commands', 'isActive', 'toggleLinkPopup', 'editor'],
  computed: {
    dropCap: { get() { return this.attrs.dropCap }, set(e) { this.updateAttrs({ dropCap: e }) } },
  },
}
</script>
