<script>
export default {
  props: {
    min: {
      default: false,
      type: Boolean,
    },
    component: {
      default: 'div',
      type: String,
    },
    componentProps: {
      default: () => ({}),
      type: Object,
    },
  },
  data() {
    return {
      rect: null,
    }
  },
  mounted() {
    window.addEventListener('resize', this.updateSize)
    this.updateSize()
    setTimeout(this.updateSize, 200)
  },
  destroyed() {
    window.removeEventListener('resize', this.updateSize)
  },
  methods: {
    updateSize() {
      if (this.$refs.layout) {
        this.rect = this.$refs.layout.getBoundingClientRect()
        this.$refs.layout.style.setProperty('--layout-top', `${this.rect.top}px`)
      }
    },
  },
  render(h) {
    return h(this.component, {
      ref: 'layout',
      class: {
        'full-height-layout': true,
        'full-height-layout--min': this.min,
      },
      ...this.componentProps,
    }, this.$slots.default)
  },
}
</script>

<style lang="scss" scoped>
  .full-height-layout {
    &:not(.full-height-layout--min) {
      height: calc( 100vh - var(--layout-top) - 25px );
    }
    &.full-height-layout--min {
      min-height: calc( 100vh - var(--layout-top) - 25px );
    }
    margin-bottom: 0;

    &.card .card-body {
      display: flex;
      flex-direction: column;
    }
  }
</style>
