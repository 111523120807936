<template>
  <div class="page-break">
    <span class="page-break-order" />
    <input
      v-model="attrs.name"
      :placeholder="$t('codex-editor.nodes.pagebreak.title')"
      class="page-break__name"
    >
  </div>
</template>

<script>

export default {
  props: ['attrs', 'updateAttrs', 'selectNode'],
}
</script>

<style scoped lang="scss">
.page-break {
  display: flex;
  align-items: center;
  gap: 8px;
  counter-increment: pageBreak;
  margin-bottom: 0 !important;

  &::before {
    content: none;
  }
}

.page-break-order::before {
  content: counter(pageBreak);
  color: #767685;
}

.page-break__name {
  border: none;
  width: 100%;
  text-align: center;
  border-bottom: 1px solid gray;
  margin-bottom: 28px;

  &:focus {
    outline: none;
  }
}

</style>
