<template>
  <div class="new-filters-dropdown__filter-body new-filters-dropdown__filter-body--scroll">
    <b-row
      align-v="center"
      no-gutters
    >
      <b-col cols="12">
        <b-checkbox-group
          v-model="value.value"
          class="new-filters-dropdown__checkbox-group"
          :options="options"
          stacked
        />
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { mapWebhookEventType, EVENT_TYPES, WEBHOOK_TYPES } from '@/codex-sdk/webhooks'

export default {
  props: {
    filter: {
      type: Object,
      required: true,
    },
    filterObject: {
      type: [Object, Function],
      required: true,
    },
    value: {
      type: Object,
      required: true,
    },
    cache: {
      type: Object,
      required: true,
    },
    setCache: {
      type: Function,
      required: true,
    },
  },
  computed: {
    options() {
      switch (this.filter.webhookType) {
        case WEBHOOK_TYPES.LIST:
        case WEBHOOK_TYPES.AUTHOR:
        case WEBHOOK_TYPES.SECTION:
          return [
            {
              value: EVENT_TYPES.INSERT,
              text: this.$t(mapWebhookEventType(EVENT_TYPES.INSERT)),
            },
            {
              value: EVENT_TYPES.DELETE,
              text: this.$t(mapWebhookEventType(EVENT_TYPES.DELETE)),
            },
            {
              value: EVENT_TYPES.UPDATE,
              text: this.$t(mapWebhookEventType(EVENT_TYPES.UPDATE)),
            },
          ]
        case WEBHOOK_TYPES.MEDIA:
          return [
            {
              value: EVENT_TYPES.UPLOAD,
              text: this.$t(mapWebhookEventType(EVENT_TYPES.UPLOAD)),
            },
            {
              value: EVENT_TYPES.DELETE,
              text: this.$t(mapWebhookEventType(EVENT_TYPES.DELETE)),
            },
            {
              value: EVENT_TYPES.UPDATE,
              text: this.$t(mapWebhookEventType(EVENT_TYPES.UPDATE)),
            },
          ]
        case WEBHOOK_TYPES.URL:
          return [
            {
              value: EVENT_TYPES.INSERT,
              text: this.$t(mapWebhookEventType(EVENT_TYPES.INSERT)),
            },
            {
              value: EVENT_TYPES.UPDATE,
              text: this.$t(mapWebhookEventType(EVENT_TYPES.UPDATE)),
            },
          ]
        case WEBHOOK_TYPES.CONTENT:
          return [
            {
              value: EVENT_TYPES.INSERT,
              text: this.$t(mapWebhookEventType(EVENT_TYPES.INSERT)),
            },
            {
              value: EVENT_TYPES.DELETE,
              text: this.$t(mapWebhookEventType(EVENT_TYPES.DELETE)),
            },
            {
              value: EVENT_TYPES.AUTO_SAVE,
              text: this.$t(mapWebhookEventType(EVENT_TYPES.AUTO_SAVE)),
            },
            {
              value: EVENT_TYPES.PUBLISH,
              text: this.$t(mapWebhookEventType(EVENT_TYPES.PUBLISH)),
            },
            {
              value: EVENT_TYPES.UPDATE,
              text: this.$t(mapWebhookEventType(EVENT_TYPES.UPDATE)),
            },
            {
              value: EVENT_TYPES.UNPUBLISH,
              text: this.$t(mapWebhookEventType(EVENT_TYPES.UNPUBLISH)),
            },
            {
              value: EVENT_TYPES.SCHEDULE,
              text: this.$t(mapWebhookEventType(EVENT_TYPES.SCHEDULE)),
            },
            {
              value: EVENT_TYPES.UNSCHEDULE,
              text: this.$t(mapWebhookEventType(EVENT_TYPES.UNSCHEDULE)),
            },
            {
              value: EVENT_TYPES.ARCHIVE,
              text: this.$t(mapWebhookEventType(EVENT_TYPES.ARCHIVE)),
            },
          ]
        default:
          return [
            {
              value: EVENT_TYPES.INSERT,
              text: this.$t(mapWebhookEventType(EVENT_TYPES.INSERT)),
            },
            {
              value: EVENT_TYPES.DELETE,
              text: this.$t(mapWebhookEventType(EVENT_TYPES.DELETE)),
            },
            {
              value: EVENT_TYPES.AUTO_SAVE,
              text: this.$t(mapWebhookEventType(EVENT_TYPES.AUTO_SAVE)),
            },
            {
              value: EVENT_TYPES.PUBLISH,
              text: this.$t(mapWebhookEventType(EVENT_TYPES.PUBLISH)),
            },
            {
              value: EVENT_TYPES.UPDATE,
              text: this.$t(mapWebhookEventType(EVENT_TYPES.UPDATE)),
            },
            {
              value: EVENT_TYPES.UNPUBLISH,
              text: this.$t(mapWebhookEventType(EVENT_TYPES.UNPUBLISH)),
            },
            {
              value: EVENT_TYPES.SCHEDULE,
              text: this.$t(mapWebhookEventType(EVENT_TYPES.SCHEDULE)),
            },
            {
              value: EVENT_TYPES.UNSCHEDULE,
              text: this.$t(mapWebhookEventType(EVENT_TYPES.UNSCHEDULE)),
            },
            {
              value: EVENT_TYPES.ARCHIVE,
              text: this.$t(mapWebhookEventType(EVENT_TYPES.ARCHIVE)),
            },
            {
              value: EVENT_TYPES.UPLOAD,
              text: this.$t(mapWebhookEventType(EVENT_TYPES.UPLOAD)),
            },
          ]
      }
    },
  },
}
</script>
