export default {
  ADD_MODEL(state, model) {
    state.models.unshift(model)
  },
  SET_MODELS(state, models) {
    state.models = models
  },
  UPDATE_MODEL(state, model) {
    const modelIndex = state.models.findIndex(p => p.id === model.id)
    if (modelIndex === -1) return
    Object.assign(state.models[modelIndex], model)
  },
  REMOVE_MODEL(state, model) {
    const modelIndex = state.models.findIndex(p => p.id === model.id)
    state.models.splice(modelIndex, 1)
  },
  REMOVE_MODELS(state, models) {
    models.map(model => {
      const modelIndex = state.models.findIndex(p => p.id === model.id)
      state.models.splice(modelIndex, 1)
      return model.id
    })
  },
  SET_MODELS_PAGINATION(state, pagination) {
    state.modelsPagination = pagination
  },
  CLEAR_MODELS_STATE(state) {
    state.models = []
  },
}
