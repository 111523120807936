<template>
  <div>
    <b-form-group>
      <b-form-radio-group v-model="appearance">
        <label
          for="tag-tag"
          class="tag-field__radio-button"
        >
          <div class="tag-field__radio-container">
            <div class="tag-field__radio-button-left">
              <GjIcon
                name="PriceTag"
                class="tag-field__icon"
                size="24"
                style="fill: #1D79F2"
              />
              <p class="mb-0 tag-field__radio-button-tag">
                {{ $t('fields.codex-field-tag.appearance.tag') }}
              </p>
            </div>

            <div class="tag-field__radio-button-right">
              <b-form-radio
                id="tag-tag"
                :value="1"
                class="mr-0"
              />
            </div>
          </div>
        </label>
      </b-form-radio-group>
    </b-form-group>

    <FieldHelpTextInput
      :widget="widget"
      :update-attrs="updateAttrs"
    />

    <FieldConditions
      v-model="hidden"
      :widget="widget"
      :label="$t('fields.general.hidden.label')"
      :show-error-message="false"
      :tooltip="$t('fields.general.hidden.info')"
    />
  </div>
</template>

<script>
import { generateComputedPropsFromAttrs } from '@/components/codex-layout-editor/BuilderUtils'
import { VALUE_TYPES } from '@/views/models/constants'
import FieldHelpTextInput from '@/components/fields/FieldHelpTextInput.vue'
import BaseFieldMixin from '@/components/fields/BaseFieldMixin'
import FieldConditions from '@/components/fields/FieldConditions.vue'

export default {
  name: 'Appearance',
  components: { FieldConditions, FieldHelpTextInput },
  mixins: [BaseFieldMixin],
  data() {
    return {
      VALUE_TYPES,
    }
  },
  computed: {
    ...generateComputedPropsFromAttrs([
      'appearance',
      'helpText',
      'hidden',
    ]),
  },
}
</script>

<style lang="scss" scoped>
.tag-field__radio-button {
  width: 100%;

  margin-bottom: 8px;

  cursor: pointer;
  height: 40px;
}

.tag-field__radio-button-left {
  display: flex;
}

.tag-field__radio-button-tag {
  margin-left: 8px;
  font-size: 12px;
  font-weight: bold;
}
.specified-values__tag {
  border-radius: 4px;
  padding: 8px 12px;
  border: 1px solid #E0E5EB;
}
.specified-values__tag-section {
  margin-top: 8px;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}
.tag-field__radio-container {
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  height: 100%;

  padding: 4px 8px;
  padding-right: 0;

  border: 1px solid #E0E5EB;
  border-radius: 4px;
}
.tag-field__error-tag {
  font-size: 14px;
  color: red;
  margin-bottom: 0;
}
.text-field__icon {
  box-shadow: 0px 4px 8px rgba(163, 176, 194, 0.32);
  border-radius: 3px;
}
.text-field__radio {
  padding: 0;
  left: 9px;
  width: 16px;
  height: 16px;
}
</style>
