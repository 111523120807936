<template>
  <div class="new-filters-dropdown__items">
    <template v-for="(filter, idx) in filters">
      <!-- Divider -->
      <hr v-if="filter.type === 'divider'" :key="`${filter.name}-${idx}`" class="new-filters-dropdown__divider">

      <!-- Group -->
      <div v-else-if="filter.type === 'group'" :key="`${filter.name}-${idx}`" class="new-filters-dropdown__group">
        <div class="new-filters-dropdown__group-title">
          {{ filter.label }}
        </div>
        <FilterItems :filters="filter.filters" />
      </div>

      <!-- Filter -->
      <div v-else :key="`${filter.name}-${idx}`" :ref="filter.name" class="new-filters-dropdown__item" :class="{
      'new-filters-dropdown__item--active': filtersObject.isFilterActive(filter.name),
      'new-filters-dropdown__item--open': activeFilter.filter && activeFilter.filter.name === filter.name,
    }" @click="itemClick(filter)">
        {{ filter.label }}
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'FilterItems',
  inject: ['filtersObject', 'editFilter', 'activeFilter'],
  props: {
    filters: {
      type: Array,
      required: true,
    },
  },
  methods: {
    itemClick(filter) {
      const filterEl = Array.isArray(this.$refs[filter.name]) ? this.$refs[filter.name][0] : this.$refs[filter.name]
      this.editFilter(filterEl, filter, {}, 'filters-dropdown')
    },
  },
}
</script>

<style lang="scss">

.new-filters-dropdown__items {
  display: flex;
  flex-direction: column;
}

.new-filters-dropdown__items--margin {
  margin-top: 10px;
  margin-bottom: 10px;
}

.new-filters-dropdown__divider {
  margin: 8px 0;
}

.new-filters-dropdown__group {
  display: flex;
  flex-direction: column;
}

.new-filters-dropdown__group-title {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  text-transform: uppercase;
  color: #667C99;

  padding: 6px 16px;
}

.new-filters-dropdown__item {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #667C99;

  padding: 6px 16px;

  cursor: pointer;
  display: inline-flex;
  justify-content: space-between;

  &:hover {
    background: #F4F6FA;
  }
}

.new-filters-dropdown__item--active {
  color: #1A73E8;
  background: #F4F6FA;
}

.new-filters-dropdown__item--open {
  color: #1A73E8;
  background: #F4F6FA;
  font-weight: 500;
}

</style>
