/* eslint-disable no-multiple-empty-lines */

import Vue from 'vue'
import qs from 'qs'
import {
  mergeWith, isArray, set, get,
} from 'lodash'

import { apolloProvider } from '@/libs/vue-apollo'
import store from '@/store'
import gql from 'graphql-tag'
import { transformEntriesGraphQL } from '@/codex-sdk/entries'
import { capitalizeObjectKeys } from '@/utils/helpers'
import ScheduledVersionsFilter from '@/components/filters-dropdown/filters/scheduledVersions/ScheduledVersionsFilter'
import AuthorListFilter from './filters/authorList/AuthorListFilter'
import NumberFilter from './filters/number/NumberFilter'
import NumberListFilter from './filters/numberList/NumberListFilter'
import MediaFilter from './filters/media/MediaFilter'
import BooleanFilter from './filters/boolean/BooleanFilter'
import StringFilter from './filters/string/StringFilter'
import StringListFilter from './filters/stringList/StringListFilter'
import SimpleStringFilter from './filters/simpleString/SimpleStringFilter'
import ModelFilter from './filters/model/ModelFilter'
import EntryStatusFilter from './filters/entryStatus/EntryStatusFilter'
import DatetimeFilter from './filters/datetime/DatetimeFilter'
import UserFilter from './filters/user/UserFilter'
import WebhookEventFilter from './filters/webhook/WebhookEventFilter'
import WebhookResponseCodeFilter from './filters/webhook/WebhookResponseCodeFilter'
import SiteFilter from './filters/sites/SiteFilter'
import SiteListFilter from './filters/sitesList/SiteListFilter'
import SectionFilter from './filters/section/SectionFilter'
import AuthorFilter from './filters/author/AuthorFilter'
import MediaTypeFilter from './filters/mediaType/MediaTypeFilter'
import FolderFilter from './filters/folder/FolderFilter'
import SectionListFilter from './filters/sectionList/SectionListFilter'
import LabelsFilter from './filters/labels/LabelsFilter'
import ReferenceFilter from './filters/reference/ReferenceFilter'
import ReferenceListFilter from './filters/referenceList/ReferenceListFilter'
import MediaListFilter from './filters/mediaList/MediaListFilter'
import AssetTypeFilter from './filters/assetType/AssetTypeFilter'
import WebhookEntityFilter from './filters/webhook/WebhookEntityFilter'
import UrlEntityType from './filters/urls/UrlEntityTypeFilter'
import TagSourceFilter from './filters/tagSource/TagSourceFilter'
import TagParentFilter from './filters/tag-parent/TagParentFilter'
import PresetGravityFilter from './filters/presetsGravity/PresetGravityFilter'
import PresetResizeFilter from './filters/presetsResize/PresetResizeFilter'
import MinutesFilter from './filters/minutes/MinutesFilter'
import TagListFilter from './filters/tagList/TagListFilter'
import WebhookTags from './filters/webhook-tags/WebhookTagsFilter'
import CustomParameters from './filters/customParameters/CustomParametersFilter'

const filterObjects = (() => {
  const fo = {}

  fo[NumberFilter.type] = NumberFilter
  fo[NumberListFilter.type] = NumberListFilter
  fo[BooleanFilter.type] = BooleanFilter
  fo[MediaFilter.type] = MediaFilter
  fo[StringFilter.type] = StringFilter
  fo[StringListFilter.type] = StringListFilter
  fo[SimpleStringFilter.type] = SimpleStringFilter
  fo[SectionFilter.type] = SectionFilter
  fo[SectionListFilter.type] = SectionListFilter
  fo[ModelFilter.type] = ModelFilter
  fo[EntryStatusFilter.type] = EntryStatusFilter
  fo[DatetimeFilter.type] = DatetimeFilter
  fo[UserFilter.type] = UserFilter
  fo[WebhookEventFilter.type] = WebhookEventFilter
  fo[WebhookResponseCodeFilter.type] = WebhookResponseCodeFilter
  fo[SiteFilter.type] = SiteFilter
  fo[SiteListFilter.type] = SiteListFilter
  fo[AuthorFilter.type] = AuthorFilter
  fo[MediaTypeFilter.type] = MediaTypeFilter
  fo[FolderFilter.type] = FolderFilter
  fo[AuthorListFilter.type] = AuthorListFilter
  fo[LabelsFilter.type] = LabelsFilter
  fo[ReferenceFilter.type] = ReferenceFilter
  fo[ReferenceListFilter.type] = ReferenceListFilter
  fo[MediaListFilter.type] = MediaListFilter
  fo[AssetTypeFilter.type] = AssetTypeFilter
  fo[WebhookEntityFilter.type] = WebhookEntityFilter
  fo[UrlEntityType.type] = UrlEntityType
  fo[TagSourceFilter.type] = TagSourceFilter
  fo[TagParentFilter.type] = TagParentFilter
  fo[PresetGravityFilter.type] = PresetGravityFilter
  fo[PresetResizeFilter.type] = PresetResizeFilter
  fo[MinutesFilter.type] = MinutesFilter
  fo[TagListFilter.type] = TagListFilter
  fo[WebhookTags.type] = WebhookTags
  fo[CustomParameters.type] = CustomParameters
  fo[ScheduledVersionsFilter.type] = ScheduledVersionsFilter

  return fo
})()

export default class Filters {
  /**
   * The list of all filters including groups and dividers
   */
  filters = []


  /**
   * List of all active filters
   */
  activeFilters = {};

  /**
   * List of all active pending filters
   */
  activePendingFilters = {};

  /**
   * Cache filter values
   */
  cache = {};

  /**
   * Filters ready
   */
  ready = false;

  /**
   * Filters ready
   */
  skipFilters = [];


  /**
   * @param {Array<Object>} filters Filters to set
   */
  constructor(filters, skipFilters = []) {
    if (filters) {
      this.filters = filters
    }

    if (skipFilters) {
      this.skipFilters = skipFilters
    }
  }

  /**
   * Set cached item
   *
   * @param {Array|Object} key
   */
  setCache(value) {
    if (!value) return

    if (Array.isArray(value)) {
      value.forEach(v => {
        this.setCache(v)
      })
    } else if (value && value.id) {
      if (!store.state.filters.filtersCache[value.id]) {
        store.dispatch('filters/setFilterCache', { filterId: value.id, filter: value })
      }
      if (!this.cache[value.id]) {
        Vue.set(this.cache, value.id, value)
      }
    }
  }

  /**
   * Load data for all active filters
   */
  async loadData() {
    let load = {}

    Object.keys(this.activeFilters).forEach(filterName => {
      const activeFilter = this.activeFilters[filterName]

      if (activeFilter && typeof activeFilter.toLoad === 'function') {
        const filterLoad = activeFilter.toLoad(activeFilter)
        load = mergeWith(load, filterLoad, (objValue, srcValue) => {
          if (isArray(objValue)) {
            return objValue.concat(srcValue)
          }
          return undefined
        })
      }
    })

    if (Object.keys(load).length !== 0) {
      const { data } = await apolloProvider.defaultClient.query({
        query: gql`
          query ($assets: [String!], $users: [String!], $sections: [String!], $authors: [String!], $folders: [String!], $entries: [String!], $labels: [String!], $tags: [String!]) {
            assetCollection (where: { id: { in: $assets }}) {
              items {
                id
                title
                url(transformation: { height: 200, width: 200 })
                
                # Get extra fields for "prefill from filters" feature in entry
                caption
                type
                focalPoint {
                  x
                  y
                }
                
              }
            } 
            userCollection (where: {id: {in: $users }}) {
              items {
                id
                imageUrl
                email
                firstName
                lastName
              }
            }
            sectionCollection (where: {id: {in: $sections }}) {
              items {
                id
                title
                parentId
              }
            }
            authorCollection (where: {id: {in: $authors }}) {
              items {
                id
                firstName
                lastName
                byline
                email
                
                # Get extra fields for "prefill from filters" feature in entry
                image {
                  url
                }
              }
            }
            folderCollection (where: {id: {in: $folders }}) {
              items {
                id
                name
              }
            }
            entryCollection (where: {id: {in: $entries }}) {
              items {
                id
                system {
                  title
                  featuredMedia {
                    id
                    url
                  }
                  modelId
                  
                  # Get extra fields for "prefill from filters" feature in entry
                  modelAlias
                }
              }
            }
            labelCollection (where: {id: {in: $labels }}) {
              items {
                id
                name
                color
              }
            }
            tagCollection (where: {id: {in: $tags }}) {
              items {
                id
                tagValue
                     
                # Get extra fields for "prefill from filters" feature in entry
                tagAlias
                source
                externalId
              }
            }
          }
        `,
        variables: {
          assets: load.assets || ['n/a'],
          users: load.users || ['n/a'],
          sections: load.sections || ['n/a'],
          authors: load.authors || ['n/a'],
          folders: load.folders || ['n/a'],
          entries: load.entries || ['n/a'],
          labels: load.labels || ['n/a'],
          tags: load.tags || ['n/a'],
        },
      })

      // Set requested assets to cache
      data.assetCollection.items.forEach(asset => {
        this.setCache(asset)
        // if (this.cache[asset.id]) return
        // Vue.set(this.cache, asset.id, asset)
      })

      // Set requested users to cache
      data.userCollection.items.forEach(user => {
        this.setCache(user)
        // if (this.cache[user.id]) return
        // Vue.set(this.cache, user.id, user)
      })

      // Set requested sections to cache
      data.sectionCollection.items.forEach(section => {
        this.setCache(section)
        // if (this.cache[section.id]) return
        // Vue.set(this.cache, section.id, section)
      })

      // Set requested authors to cache
      data.authorCollection.items.forEach(author => {
        this.setCache(author)
        // if (this.cache[author.id]) return
        // Vue.set(this.cache, author.id, author)
      })

      // Set requested authors to cache
      data.folderCollection.items.forEach(folder => {
        this.setCache(folder)
        // if (this.cache[folder.id]) return
        // Vue.set(this.cache, folder.id, folder)
      })

      // Set requested labels to cache
      data.labelCollection.items.forEach(label => {
        this.setCache(label)
        // if (this.cache[label.id]) return
        // Vue.set(this.cache, label.id, label)
      })

      data.tagCollection.items.forEach(tag => {
        this.setCache(tag)
        // if (this.cache[tag.id]) return
        // Vue.set(this.cache, tag.id, tag)
      })

      // Set requested entries to cache
      transformEntriesGraphQL(data.entryCollection.items)
      data.entryCollection.items.forEach(entry => {
        this.setCache(entry)
        // if (this.cache[entry.id]) return
        // Vue.set(this.cache, entry.id, entry)
      })
    }

    // Set all models to cache
    store.state.general.models.forEach(model => {
      this.setCache(model)
      // if (this.cache[model.id]) return
      // Vue.set(this.cache, model.id, model)
    })
  }

  /**
   * Load active filters from a query string
   *
   * @param {Object} query Query object from $route object (e.g. this.$route.query)
   */
  async loadFromQuery(query) {
    return new Promise(resolve => {
      Vue.nextTick(() => {
        const queryFilters = qs.parse(query, { ignoreQueryPrefix: true })

        const { success } = this.setActiveFilters(queryFilters)

        if (Object.keys(queryFilters).length === 0) {
          this.ready = true
        }

        resolve(success)
      })
    })
  }

  /**
   * Returns a flat array of filters
   */
  get filtersFlat() {
    const filters = {}

    if (!this.filters) {
      return {}
    }

    this.filters.forEach(filter => {
      if (filter.type === 'group') {
        filter.filters.forEach(f => {
          filters[f.name] = f
        })
      } else if (filter.type !== 'divider') {
        filters[filter.name] = filter
      }
    })

    return filters
  }

  /**
   * @returns {String} Active filters as a query string
   */
  get asQueryParams() {
    const activeFilters = this.getActiveFilters()

    if (Object.keys(activeFilters).length === 0) {
      return ''
    }

    const queryParams = {}

    Object.keys(activeFilters).forEach(filterName => {
      const activeFilter = activeFilters[filterName]
      const filter = this.getFilter(filterName)

      if (typeof activeFilter.asQueryParam === 'function') {
        const value = activeFilter.asQueryParam()

        if (!filter || value === null) return

        queryParams[filterName] = value
      } else {
        console.warn('Filter does not support asQueryParam', activeFilter)
      }
    })

    return qs.stringify(queryParams)
  }

  /**
 * @returns {String} Active filters as a query string
 */
  getQueryParamsObject() {
    const queryParams = this.asQueryParams

    if (typeof queryParams !== 'string') {
      return {}
    }

    return qs.parse(queryParams, { ignoreQueryPrefix: true, depth: 0 })
  }


  /**
   * @returns {String} Active filters as a query string
   */
  get asGraphQL() {
    const activeFilters = this.getActiveFilters()

    if (Object.keys(activeFilters).length === 0) {
      return {}
    }

    const queryParams = {}

    Object.keys(activeFilters).forEach(filterName => {
      const activeFilter = activeFilters[filterName]
      const filter = this.getFilter(filterName)

      if (typeof activeFilter.asGraphQL === 'function') {
        const value = activeFilter.asGraphQL(filter?.isReference)

        if (!filter || value === null) return

        if (filter.graphQLPath) {
          set(queryParams, filter.graphQLPath, mergeWith(get(queryParams, filter.graphQLPath), value, (objValue, srcValue) => {
            if (isArray(objValue)) {
              return objValue.concat(srcValue)
            }
            return undefined
          }))
        } else {
          queryParams[filterName] = value
        }
      } else {
        console.warn('Filter does not support asGraphQL', activeFilter)
      }
    })

    return queryParams
  }

  get asGraphQLDynamicList() {
    const activeFilters = this.getActiveFilters()

    if (Object.keys(activeFilters).length === 0) {
      return {}
    }

    const queryParams = {}

    Object.keys(activeFilters).forEach(filterName => {
      const activeFilter = activeFilters[filterName]
      const filter = this.getFilter(filterName)

      if (typeof activeFilter.asGraphQLDynamicList === 'function') {
        const value = activeFilter.asGraphQLDynamicList(filter?.isReference)

        if (!filter || value === null) return

        if (filter.graphQLPath) {
          set(queryParams, filter.graphQLPath, mergeWith(get(queryParams, filter.graphQLPath), value, (objValue, srcValue) => {
            if (isArray(objValue)) {
              return objValue.concat(srcValue)
            }
            return undefined
          }))
        } else {
          queryParams[filterName] = value
        }
      } else if (typeof activeFilter.asGraphQL === 'function') {
        const value = activeFilter.asGraphQL(filter?.isReference)

        if (!filter || value === null) return

        if (filter.graphQLPath) {
          set(queryParams, filter.graphQLPath, mergeWith(get(queryParams, filter.graphQLPath), value, (objValue, srcValue) => {
            if (isArray(objValue)) {
              return objValue.concat(srcValue)
            }
            return undefined
          }))
        } else {
          queryParams[filterName] = value
        }
      } else {
        console.warn('Filter does not support asGraphQL', activeFilter)
      }
    })

    if (queryParams.system) {
      queryParams.system._t = 'EntrySystemFilter'

      if (queryParams.system.references) {
        queryParams.system.references._t = 'EntryReferencesFilter'

        if (queryParams.system.references.id) {
          queryParams.system.references.id._t = 'StringListFilter'
        }

        if (queryParams.system.references.model) {
          queryParams.system.references.model._t = 'StringListFilter'
        }

        if (queryParams.system.references.referencingFields) {
          queryParams.system.references.referencingFields._t = 'StringListFilter'
        }
        if (queryParams.system.references.entity) {
          queryParams.system.references.entity._t = 'EntryReferenceTypeFilter'
        }
      }
    }

    const temp = capitalizeObjectKeys(queryParams)
    temp.system = temp.System
    delete temp.System
    return temp
  }



  /**
   * @param {Array<Object>} filters Filters to set
   */
  setFilters(filters) {
    this.filters = filters

    // Remove any active filters that are not in the new filters
    Object.keys(this.activeFilters).forEach(filterName => {
      if (!this.filtersFlat[filterName]) {
        this.clearActiveFilter(filterName)
      }
    })

    this.retryActivePendingFilters()
  }


  /**
   * @returns {Array<Object>} Returns an array of filters
   */
  getFilters() {
    return this.filters
  }


  /**
   * Return single filter
   */
  getFilter(filterName) {
    return this.filtersFlat[filterName]
  }


  /**
   * Finds and returns the filter object for the given filter name.
   *
   * @param {String} filterName Name of the filter
   * @returns {Object} Filter object
   */
  getFilterObject(filterName) {
    const filter = this.getFilter(filterName)

    if (!filter) {
      throw new Error(`Filter ${filterName} not found`)
    }

    if (!filterObjects[filter.type]) {
      throw new Error(`Filter type ${filter.type} not found`)
    }

    return filterObjects[filter.type]
  }


  /**
   * Returns the default value for a filter. Validations are not applied.
   *
   * @param {String} filterName Name of the filter
   */
  getFilterDefaultValue(filterName) {
    try {
      const FilterObject = this.getFilterObject(filterName)
      return new FilterObject()
    } catch (e) {
      return {}
    }
  }


  /**
   * @returns {Array<Object>} Returns an array of active filters
   */
  getActiveFilters() {
    return this.activeFilters
  }


  /**
   * @returns {Array<Object>} Returns an array of filters that are set to always show
   */
  getAlwaysShowFilters() {
    return Object.values(this.filtersFlat).filter(filter => filter.alwaysShow)
  }


  /**
   * @param {String} filterName Name of the filter to return
   * @returns {Object} Returns the active filter value for the given filter name
   */
  getActiveFilter(filterName) {
    return this.activeFilters[filterName]
  }


  /**
   * Get active filter badge label
   *
   * @param {String} filterName Name of the filter to return
   */
  getActiveFilterBadgeLabel(filterName) {
    const filter = this.getFilter(filterName)
    const activeFilter = this.getActiveFilter(filterName)

    const dataObject = {
      cache: this.cache,
    }

    return {
      value: activeFilter && typeof activeFilter.getValueLabel === 'function' ? activeFilter.getValueLabel(dataObject) : '',
      count: activeFilter && typeof activeFilter.getCount === 'function' ? activeFilter.getCount(dataObject) : 1,
      label: filter ? filter.label : filterName,
    }
  }


  /**
   * Set multiple active filters at once
   *
   * @param {Array<Object>} filters Filters to set
   */
  setActiveFilters(filters, successCallback, errorCallback) {
    const result = { fail: 0, success: 0 }

    if (!filters || Object.keys(filters).length == 0) return result

    Object.keys(filters).forEach(filterName => {
      try {
        this.setActiveFilter(filterName, filters[filterName])
        result.success++

        if (successCallback) successCallback(filterName)
      } catch (e) {
        console.error(e)
        result.fail++

        if (errorCallback) errorCallback(filterName)
      }
    })

    if (!result.fail) {
      this.ready = true
    }

    this.loadData()
    return result
  }


  /**
   * Sets the active filter for the given filter name, while also validating the value.
   *
   * @param {String} filterName Name of the filter to set
   * @param {Object} filterValue The value of the filter to set
   */
  setActiveFilter(filterName, filterValue) {
    if (this.skipFilters && this.skipFilters.includes(filterName)) {
      return
    }

    if (!this.filtersFlat[filterName]) {
      this.activePendingFilters[filterName] = filterValue

      throw new Error(`Filter ${filterName} not found`)
    }

    const FilterObject = this.getFilterObject(filterName)

    if (FilterObject.validate) {
      const error = FilterObject.validate(filterValue)

      if (error !== true) {
        throw new Error(error)
      }
    }

    if (this.activeFilters[filterName]) {
      this.activeFilters[filterName].set(filterValue)
    } else {
      Vue.set(this.activeFilters, filterName, new FilterObject(filterValue, this.filtersFlat[filterName]))
    }
  }

  /**
   * Retry pending filters
   */
  retryActivePendingFilters() {
    this.setActiveFilters(this.activePendingFilters, filterName => {
      delete this.activePendingFilters[filterName]
    }, filterName => {
      console.warn('Failed to set pending filter', filterName)
    })
  }


  /**
   * Clear active filters
   */
  clearActiveFilters() {
    Vue.set(this, 'activeFilters', {})
  }


  /**
   * Clears the active filter for the given filter name
   *
   * @param {String} filterName Name of the filter to clear
   */
  clearActiveFilter(filterName) {
    Vue.delete(this.activeFilters, filterName)
  }


  /**
   * Is filer active
   *
   * @param {String} filterName Name of the filter to check if active
   */
  isFilterActive(filterName) {
    return !!this.activeFilters[filterName]
  }


  /**
   * @returns {Number} Return the number of active filters
   */
  getActiveFiltersCount() {
    return Object.keys(this.activeFilters).length
  }


  /**
   * @returns {Boolean} Returns true if there are active filters
   */
  hasActiveFilters() {
    return this.getActiveFiltersCount() > 0
  }
}
