import {
  readAuthors, updateAuthor, deleteAuthor, createAuthor,
} from '../../codex-sdk/authors'

export default {
  createAuthor({ commit }, author) {
    return new Promise((resolve, reject) => {
      createAuthor(author)
        .then(response => {
          commit('ADD_AUTHOR', Object.assign(author, { id: response.data.id }))
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  fetchAuthors({ commit }, { ...filtersAndPagination } = {}) {
    commit('CLEAR_AUTHORS_STATE')
    return new Promise((resolve, reject) => {
      readAuthors(filtersAndPagination)
        .then(response => {
          const { data: { data, ...pagination } } = response
          commit('SET_AUTHORS_PAGINATION', pagination)
          commit('SET_AUTHORS', data)
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  updateAuthor({ commit }, author) {
    return new Promise((resolve, reject) => {
      updateAuthor(author)
        .then(response => {
          commit('UPDATE_AUTHOR', author)
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  removeAuthor({ commit }, { authorId, message }) {
    return new Promise((resolve, reject) => {
      deleteAuthor(authorId, message)
        .then(response => {
          commit('REMOVE_AUTHOR', authorId)
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
}
