import { updateAssetsFolder, updateFolder } from '@/codex-sdk/folders'

export default {
  data() {
    return {
      cursorPosition: null,
      intervalId: null,
      draggingAssets: false,
      dragOverElement: null,
      ghostEl: null,
      assetBeingEdited: null,
      folderBeingDragged: '',
    }
  },
  methods: {
    removeDragAsset() {
      if (this.selectedData?.show) return
      clearInterval(this.intervalId)
      this.intervalId = null
      this.draggingAssets = false
    },
    checkScrollBoundaries() {
      if (this.selectedData?.show) return
      const threshold = 200
      const maxSpeed = 100
      if (this.cursorPosition <= threshold) {
        const mouseSpeed = (1 - (this.cursorPosition / threshold)) * maxSpeed
        window.scroll({ top: window.scrollY - mouseSpeed })
      } else if (this.cursorPosition >= window.innerHeight - threshold) {
        const mouseSpeed = (1 - ((window.innerHeight - this.cursorPosition) / threshold)) * maxSpeed
        window.scroll({ top: window.scrollY + mouseSpeed })
      }
    },
    handleAssetDragstart(event, file, checked) {
      if (this.selectedData?.show) return
      // if (!canMoveMedia().can) {
      //   event.preventDefault()
      //   return
      // }

      let dragging = []
      this.draggingAssets = true
      this.intervalId = setInterval(this.checkScrollBoundaries, 50)

      if (Object.keys(file).includes('parentId')) { // Nese eshte folder
        dragging.push({
          type: 'folder', id: file.id, name: file.name, parentId: file.parentId,
        })
        this.folderBeingDragged = file.id
      } else if (checked) { // Move selected assets
        dragging = this.selectedData.items.map(asset => ({ type: 'asset', id: asset.id, name: asset.title || asset.fileName }))
      } else {
        dragging.push({ type: 'asset', id: file.id, name: file.title || file.fileName })
      }

      event.dataTransfer.setData('text', JSON.stringify(dragging))
      this.dragAsset(event, dragging)
    },
    dragAsset(event, dragging) {
      if (this.selectedData?.show) return
      event.stopPropagation()
      const ghostEl = document.createElement('p')
      ghostEl.classList.add('dragging')
      const name = dragging[0]?.name
      if (dragging.length === 1 && name) {
        ghostEl.textContent = `Dragging ${name}`
      } else {
        ghostEl.textContent = `Dragging ${dragging.length} item${dragging.length > 1 ? 's' : ''}`
      }

      this.ghostEl = ghostEl
      document.body.appendChild(ghostEl)
      event.dataTransfer.setDragImage(ghostEl, 0, 0)
    },
    _preventDefault(event) {
      if (this.selectedData?.show) return
      event.preventDefault()
      event.stopPropagation()
    },
    getDragFolder(event) {
      if (this.selectedData?.show) return null
      let _dropFolder

      try {
        const dropFolder = event.target.closest('[data-folder]')
        if (dropFolder) {
          _dropFolder = dropFolder
        }
      } catch (e) { /* */ }

      return _dropFolder
    },
    handleDragEnter(event) {
      if (this.selectedData?.show) return
      const dropFolder = this.getDragFolder(event)
      if (!dropFolder) {
        if (this.dragOverElement) {
          this.dragOverElement.classList.remove('active-folder')
          this.dragOverElement = null
        }
        return
      }

      if (this.dragOverElement && this.dragOverElement !== dropFolder) {
        this.dragOverElement.classList.remove('active-folder')
      }

      this.dragOverElement = dropFolder
      dropFolder.classList.add('active-folder')
    },
    scrollWhenHover(e) {
      if (this.selectedData?.show) return
      this.cursorPosition = e.clientY
    },
    async handleFolderDrop(event) {
      if (this.selectedData?.show) return
      const dropFolder = this.getDragFolder(event)
      if (!dropFolder) return
      event.preventDefault()
      dropFolder.classList.remove('active-folder')

      const dropFolderId = dropFolder.dataset.folder.trim() !== '' ? dropFolder.dataset.folder : null

      const dragging = JSON.parse(event.dataTransfer.getData('text'))

      // Move assets
      const assets = dragging.filter(a => a.type === 'asset').map(a => a.id)
      if (assets.length) {
        try {
          await updateAssetsFolder(dropFolderId, assets)
          this.removeAssets(assets.map(id => ({ id })))
        } catch (e) {
          console.log(e)
        }
      }

      // Move folders
      const folders = dragging.filter(a => a.type === 'folder' && a.id !== dropFolderId)
      folders.forEach(async folder => {
        if (folder.parentId !== dropFolderId) {
          try {
            await updateFolder({ name: folder.name, id: folder.id, parentId: dropFolderId })
            this.$root.$emit('refetch-folders')
          } catch (e) {
            console.log(e)
          }
        }
      })
    },
    isAssetSelected(image) {
      if (this.selectedData?.show) return null
      return !!this.selectedAssets.find(x => x.id === image.id)
    },
  },
}
