<template>
  <div class="new-filters-dropdown__filter-body">
    <b-row
      no-gutters
      class="new-filters-dropdown__items"
    >
      <div
        v-for="option in operatorOptions"
        :key="option.value"
        class="new-filters-dropdown__item"
        :class="{ 'new-filters-dropdown__item--active': value.operator === option.value }"
        @click="value.operator = option.value"
      >
        {{ option.label }}
      </div>
    </b-row>
  </div>
</template>

<script>
import ScheduledVersionsFilter from './ScheduledVersionsFilter'

export default {
  props: {
    filter: {
      type: Object,
      required: true,
    },
    filterObject: {
      type: [Object, Function],
      required: true,
    },
    value: {
      type: Object,
      required: true,
    },
  },
  computed: {
    operatorOptions() {
      return [
        {
          label: this.$t('filters.any'),
          value: ScheduledVersionsFilter.OPERATORS.exists,
        },
        {
          label: this.$t('filters.none'),
          value: ScheduledVersionsFilter.OPERATORS.notExists,
        },
      ]
    },
  },
}
</script>
