import { Node } from 'tiptap'
import { splitListItem, liftListItem, sinkListItem } from 'tiptap-commands'
import { getBlockIdFromDom } from '@/components/codex-editor/CodexEditorUtils'

export default class ListItem extends Node {
  get name() {
    return 'list_item'
  }

  get schema() {
    return {
      attrs: {
        blockId: {
          default: null,
        },
      },
      content: 'block+',
      defining: true,
      draggable: false,
      parseDOM: [{
        tag: 'li',
        getAttrs: dom => ({
          ...JSON.parse(dom.dataset?.attrs || '{}'),
          blockId: getBlockIdFromDom(dom),
        }),
      }],
      toDOM: node => ['li', {
        'data-id': node.attrs.blockId,
        'data-attrs': JSON.stringify(node.attrs) || '{}',
      }, 0],
    }
  }

  keys({ type }) {
    return {
      Enter: splitListItem(type),
      Tab: sinkListItem(type),
      'Shift-Tab': liftListItem(type),
    }
  }
}
