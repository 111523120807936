<template>
  <v-select
    id="at-teams-select"
    v-model="teamsSelected"
    :options="teams"
    :multiple="multiple"
    :placeholder="multiple ? $t('teams.selector.multiple-placeholder') : $t('teams.selector.single-placeholder')"
    label="name"
    :clearable="typeof clearable === 'boolean' ? clearable : true"
    :class="classes ? classes : ''"
    :disabled="disable"
    :close-on-select="!multiple"
    :deselect-from-dropdown="true"
    @search="searchTeams"
    @close="onClose"
    @open="onOpen"
  >
    <template v-slot:option="option">
      <span>{{ option.name }}</span>
    </template>
    <template #no-options="">
      {{ noOptionsText }}
    </template>
    <template #list-footer>
      <li
        v-show="teams && teams.length < totalCount"
        ref="load"
        class="loader"
      >{{ $t('teams.selector.loading') }}</li>
    </template>
  </v-select>
</template>

<script>
import gql from 'graphql-tag'

export default {
  name: 'TeamSelect',
  props: ['value', 'multiple', 'clearable', 'excludeTeams', 'classes', 'disable'],
  apollo: {
    teams: {
      query: gql`
        query Teams($query: String, $excludeTeams: [String!], $offset: Int!, $limit: Int!) {
          teamCollection (limit: $limit, offset: $offset, where: {id: { notIn: $excludeTeams }, or: {name: { contains: $query}}} ) {
            items {
              id
              name
              description
            }
            total
          }
        }
      `,
      update: data => data.teamCollection.items,
      result({ data }) {
        this.totalCount = data.teamCollection.total
      },
      variables() {
        return {
          teams: this.inputVal.map(i => i.id),
          offset: 0,
          limit: 10,
          query: this.search,
          excludeTeams: this.excludeTeams || [],
        }
      },
    },
  },
  data() {
    return {
      observer: null,
      limit: 10,
      search: null,
      inputVal: [],
    }
  },
  computed: {
    teamsSelected: {
      get() { return this.inputVal },
      set(v) {
        this.inputVal = v
        this.$emit('input', v.map(i => i.id))
      },
    },
    noOptionsText() {
      return !this.search || this.search.trim().length === 0 ? this.$t('teams.selector.empty-search') : this.$t('teams.selector.no-results', { search: this.search })
    },
  },
  async mounted() {
    if (this.value && this.value.length) {
      await this.fetchTeam()
    }
    this.observer = new IntersectionObserver(this.infiniteScroll)
  },
  methods: {
    async fetchTeam() {
      try {
        const { data } = await this.$apollo.query({
          query: gql`
              query Team($teams: [String!]!){
                teamCollection(where:{ id: { in: $teams } }) {
                     items {
                          id
                          name
                          description
                     }
                 }
              }
            `,
          variables: {
            teams: this.value,
          },
        })
        if (data.teamCollection.items && data.teamCollection.items.length) {
          this.teamsSelected = data.teamCollection.items
        }
      } catch (error) {
        console.log(error)
      }
    },
    async onOpen() {
      if (this.teams && (this.teams.length < this.totalCount)) {
        await this.$nextTick()
        this.observer.observe(this.$refs.load)
      }
    },
    onClose() {
      this.observer.disconnect()
    },
    async infiniteScroll([{ isIntersecting, target }]) {
      if (isIntersecting) {
        this.$apollo.queries.teams.fetchMore({
          variables: {
            offset: this.teams?.length,
          },
          updateQuery: (prev, { fetchMoreResult }) => {
            if (!fetchMoreResult) return prev
            this.teams = [...this.teams, ...fetchMoreResult.teamCollection.items]
            this.totalCount = fetchMoreResult.teamCollection.total
            return {
              teamCollection: {
                items: [...prev.teamCollection.items, ...fetchMoreResult.teamCollection.items],
                total: fetchMoreResult.teamCollection.total,
                __typename: 'CodexTeamCollection',
              },
            }
          },
        })
        target.scrollTop = target.offsetParent.scrollTop
      }
    },
    searchTeams(search) {
      if (this.search !== search) {
        this.search = search
      }
    },
  },
}
</script>

<style lang="scss">
#at-teams-select {
  .vs__selected-options{
    width: 100%;
    .vs__selected {
      word-break: break-all;
    }
  }
  .loader {
    text-align: center;
    color: #bbbbbb;
  }
}
#at-teams-select.teams-select-entry-view {
  .vs__selected-options{
    width: 100%;
    .vs__selected {
      word-break: break-all;
      background: #EEEBFF;
      border-radius: 4px;
    }
    button {
      background: #DBD6F7;
      border-radius: 100px;
      svg {
        width: 16px;
        height: 16px;
      }
    }
  }
}
</style>
