import {
  FIELD_FILTER_OPERATORS, RANGE_OPERATORS, TYPES, VALUE_TYPES,
} from '@/views/models/constants'

/**
* Required
*/
const required = {
  // The name of the validation
  name: 'required',

  // Use to determine for which field types this validation is available
  type: TYPES.JSON,
  valueType: [VALUE_TYPES.SINGLE, VALUE_TYPES.LIST],

  // Used when showing the field validations in the editor
  component: () => import('./Required.vue'),

  // Used when the field is being created
  attr: {
    value: false,
    errorMessage: 'Json is required.',
    conditionsEnabled: false,
    conditions: [
      {
        isSystem: false,
        field: '',
        operator: FIELD_FILTER_OPERATORS.EXISTS,
        value: '',
      },
    ],
  },

  // Called when the field is being validated
  validation: (value, attr, field) => {
    if (field.valueType === VALUE_TYPES.SINGLE) {
      const length = value && typeof value == 'object' ? JSON.stringify(value)?.length : value?.length || 0
      if (attr.value && length < 1) {
        return { isValid: false, message: attr.errorMessage }
      }
    } else {
      const length = value?.length || 0
      if (attr.value && length < 1) {
        return { isValid: false, message: attr.errorMessage }
      }
    }

    return { isValid: true }
  },
}

/**
* Read Only
*/
const readOnly = {
  name: 'readOnly',

  type: TYPES.JSON,
  valueType: [VALUE_TYPES.SINGLE, VALUE_TYPES.LIST],

  component: () => import('./ReadOnly.vue'),

  attr: {
    value: false,
    conditionsEnabled: false,
    conditions: [
      {
        isSystem: false,
        field: '',
        operator: FIELD_FILTER_OPERATORS.EXISTS,
        value: '',
      },
    ],
  },

  validation: (value, attr) => {
    if (attr.value && value) {
      return attr.errorMessage
    }
    return null
  },
}
/**
* Number of Properties
*/
const numberOfProperties = {
  name: 'numberOfProperties',

  type: TYPES.JSON,
  valueType: [VALUE_TYPES.SINGLE, VALUE_TYPES.LIST],

  component: () => import('./NumberOfProperties.vue'),

  attr: {
    isEnabled: false,
    rangeOperator: RANGE_OPERATORS.BETWEEN,
    min: 1,
    max: 2,
    exactly: 0,
    errorMessage: 'Number of JSON should be 1-2',
    errorTemplate: 'Number of JSON should be {min}-{max}',
  },

  validation: (value, attr, field) => {
    if (field.valueType === VALUE_TYPES.SINGLE) {
      const length = value && typeof value == 'object' ? JSON.stringify(value)?.length : value?.length || 0
      if (attr.isEnabled && length > 0) {
        let jsonKeys = []
        if (typeof value === 'string') {
          jsonKeys = Object.keys(JSON.parse(value))
        } else {
          jsonKeys = Object.keys(value)
        }
        if (attr.rangeOperator === RANGE_OPERATORS.BETWEEN) {
          if (jsonKeys.length < attr.min || jsonKeys.length > attr.max) {
            return { isValid: false, message: attr.errorMessage }
          }
        }
        if (attr.rangeOperator === RANGE_OPERATORS.LTE) {
          if (jsonKeys.length > attr.max) {
            return { isValid: false, message: attr.errorMessage }
          }
        }
        if (attr.rangeOperator === RANGE_OPERATORS.GTE) {
          if (jsonKeys.length < attr.min) {
            return { isValid: false, message: attr.errorMessage }
          }
        }
      }
    } else if (attr.isEnabled) {
      const indexes = []
      for (let i = 0; i < value.length; i++) {
        const obj = value[i]
        let jsonKeys = []
        if (typeof obj === 'string') {
          jsonKeys = Object.keys(JSON.parse(obj))
        } else {
          jsonKeys = Object.keys(obj)
        }
        if (attr.rangeOperator === RANGE_OPERATORS.BETWEEN && (jsonKeys.length < attr.min || jsonKeys.length > attr.max)) {
          indexes.push(i)
        }
        if (attr.rangeOperator === RANGE_OPERATORS.GTE && attr.min > jsonKeys.length) {
          indexes.push(i)
        }
        if (attr.rangeOperator === RANGE_OPERATORS.LTE && attr.max < jsonKeys.length) {
          indexes.push(i)
        }
      }
      if (indexes.length > 0) {
        return { isValid: false, message: attr.errorMessage, indexes }
      }
    }
    return { isValid: true, message: '' }
  },
}

export default [
  required,
  readOnly,
  numberOfProperties,
]
