<template>
  <div>
    <b-button-group>
      <b-input
        v-model="initialUrl"
        :placeholder="$t('codex-editor.nodes.media.url')"
        autocomplete="off"
        @keyup.enter="setUrl"
      />
      <b-button
        v-b-tooltip.hover.html.top="getTooltipData('save')"
        variant="flat-dark"
        @click="setUrl"
      >
        <GjIcon
          name="Save"
          size="18"
        />
      </b-button>
    </b-button-group>
    <b-button-group>
      <b-button
        v-b-tooltip.hover.html.top="getTooltipData('edit-assets')"
        variant="flat-dark"
        @click="browseMedias"
      >
        <GjIcon name="Media" />
      </b-button>
    </b-button-group>
    <AlignBlockPanel
      v-if="medias.length === 1"
      :attrs="attrs"
      :update-attrs="updateAttrs"
    />
  </div>
</template>

<script>
import AlignBlockPanel from '@/components/codex-editor/panels/AlignBlockPanel.vue'
import { ASSET_TYPE_MAPPING } from '@/codex-sdk/assets'

export default {
  components: { AlignBlockPanel },
  inject: ['showAssetsPopup', 'getTooltipData'],
  props: ['attrs', 'updateAttrs'],
  data() {
    return {
      initialSrc: '',
      initialUrl: '',
    }
  },
  computed: {
    medias() {
      return this.attrs.media
    },
  },
  watch: {
    attrs: {
      handler() {
        this.initialSrc = this.attrs.src
        this.initialUrl = this.attrs.url
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    setUrl() {
      this.updateAttrs({
        url: this.initialUrl,
      })
    },
    async browseMedias() {
      const data = await this.showAssetsPopup({ selectedAssets: this.medias })
      if (!data || !data.length) { return }
      if (data.length === 1) {
        this.updateAttrs({ caption: data[0].caption })
      }
      const filteredData = data.map(i => ({ id: i.id, type: ASSET_TYPE_MAPPING.toNumber(i.type) }))
      if (filteredData) {
        if (filteredData.length > 1) {
          this.updateAttrs({ align: 'left' })
        }
        this.updateAttrs({ media: [...filteredData] })
      }
    },
  },
}
</script>
