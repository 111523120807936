import { setBlockType } from 'tiptap-commands'
import { Node } from 'tiptap'
import { getBlockIdFromDom } from '@/components/codex-editor/CodexEditorUtils'

export default class BlockquoteAuthor extends Node {
  get name() {
    return 'codex_blockquote_author'
  }

  get schema() {
    return {
      attrs: {
        blockId: {
          default: null,
        },
      },
      content: 'inline*',
      group: '',
      draggable: false,
      isolating: true,
      parseDOM: [
        {
          tag: 'small',
          getAttrs: dom => ({
            ...JSON.parse(dom.dataset?.attrs || '{}'),
            blockId: getBlockIdFromDom(dom),
          }),
        },
      ],
      toDOM: node => ['small', {
        'data-id': node.attrs.blockId,
        'data-attrs': JSON.stringify(node.attrs) || '{}',
      }, 0],
    }
  }

  commands({ type }) {
    return () => setBlockType(type)
  }
}
