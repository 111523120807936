<template>
  <b-form-group>
    <template #label>
      <FieldName
        :name="field.name"
        :help-text="''"
      />
    </template>
    <b-input-group v-if="fieldContent">
      <template #prepend>
        <label
          class="codex-field-url__icon"
          :for="field.id"
        >
          <GjIcon name="Hyperlink" />
        </label>
      </template>
      <b-form-input
        v-model="fieldContent"
        :readonly="true"
        class="url__sidebar-value at-urls-path-input"
      />
    </b-input-group>
    <div
      v-else
      class="content-list__empty"
    >
      <GjIcon name="Hyperlink" />
      <span>{{ $tc('entries.merge-tool.info.no-url-value') }}</span>
    </div>
  </b-form-group>
</template>

<script>
import FieldName from '@/components/fields/FieldName.vue'
import { VALUE_TYPES } from '@/views/models/constants'

export default {
  name: 'UrlField',
  components: {
    FieldName,
  },
  props: {
    fieldContent: {
      type: [String, Number, Array],
    },
    field: {
      type: [Object],
    },
  },
  setup() {
    return {
      VALUE_TYPES,
    }
  },
}
</script>

<style lang="scss" scoped>
.codex-field-url__label {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.text-field__inputs {
  display: flex;
  flex-direction: column;
}

.text-field__add-input {
  width: fit-content;
  margin-left: auto;
}

.text-field__input-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 15px;
}

.text-field__input-align {
  display: flex;
  flex-grow: 1;
  align-items: center;
}
.text-wrap__inputs{
  word-break: break-all;
}

.refresh-icon {
  cursor: pointer;
}
.codex-field-url__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  border-left: 1px solid #d8d6de;
  border-top: 1px solid #d8d6de;
  border-bottom: 1px solid #d8d6de;
  padding: 8px;
  color: #206ED5;
}
</style>
