<template>
  <div
    class="rich-content__text"
    :class="classes"
  >
    <component
      :is="resolveHtmlTag(block)"
      v-bind="{...block.attrs}"
      :class="{'dropCap': block.attrs.dropCap}"
      v-html="block.contentHTML"
    />
  </div>
</template>

<script>
import { resolveHtmlTag } from '../richContentUtils'
import 'highlight.js/styles/default.css'

export default {
  name: 'TextComponent',
  props: {
    block: {
      type: [Object],
    },
    classes: {
      type: [Array],
    },
  },
  setup() {
    return {
      resolveHtmlTag,
    }
  },
}
</script>

<style lang="scss">
.rich-content__textfield {
  padding: 20px 16px;
  border: 1px solid #E0E5EB;
  border-radius: 4px;

  > * {
    margin: 0;
  }

  p.dropCap {
    position: relative;
  }

  p.dropCap::before,
  p.dropCap::after {
    background-color: #1d79f2;
    position: absolute;
    content: "";
    top: 0;
    left: -0.4em;
    width: 2px;
    height: 1em;
  }

  p.dropCap::after {
    transform: rotate(-90deg);
    transform-origin: top left;
    clear: both;
    display: block;
  }

}

.rich-content__text {
  pre {
    padding: 15px;
  }

  pre code {
    padding: 0;
  }
}
</style>
