<template>
  <div class="codex-field-url-general">
    <b-form-group
      :label="$t('fields.codex-field-url.general.name-label')"
      label-for="text-field__name"
    >
      <b-form-input
        id="text-field__name"
        v-model="name"
        :placeholder="$t('fields.codex-field-url.general.name-placeholder')"
      />
    </b-form-group>

    <b-form-group
      :label="$t('fields.codex-field-url.general.alias-label')"
      label-for="text-field__alias"
    >
      <b-form-input
        id="text-field__alias"
        :value="alias"
        disabled
        :placeholder="$t('fields.codex-field-url.general.alias-placeholder')"
      />
    </b-form-group>

    <b-form-group
      :label="$t('fields.codex-field-url.general.description-label')"
      label-for="text-field__description"
    >
      <b-form-textarea
        id="text-field__description"
        v-model="description"
        :placeholder="$t('fields.codex-field-url.general.description-placeholder')"
        rows="2"
        no-resize
      />
    </b-form-group>
    <b-button
      class="url_open-modal"
      @click="showModal = true"
    >
      {{ $t('fields.codex-field-url.appearance.configure-url-field') }}
      <GjIcon
        name="Hyperlink"
        style="color:#052D61; margin-left:5px"
      />
    </b-button>
    <CustomURL
      v-if="showModal"
      v-model="showModal"
      :default-pattern="pattern"
      @save="setCustomUrl"
      @close="showModal = false"
    />
  </div>
</template>

<script>
import { generateComputedPropsFromAttrs } from '@/components/codex-layout-editor/BuilderUtils'
import CustomURL from '@/components/custom-url/CustomURL.vue'
import BaseFieldMixin from '@/components/fields/BaseFieldMixin'

export default {
  name: 'General',
  components: { CustomURL },
  mixins: [BaseFieldMixin],
  data() {
    return {
      showModal: false,
    }
  },
  computed: {
    ...generateComputedPropsFromAttrs([
      'name',
      'alias',
      'description',
      'valueType',
      'pattern',
    ]),
  },
  methods: {
    setCustomUrl(value) {
      this.pattern = value
    },
  },
}
</script>

<style lang="scss">
.codex-field-url-general {
  .url_open-modal{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 12px;
    color: #052D61 !important;
    width: 282px;
    height: 40px;
    border-style:none;
    background: #ECF3FF !important;
    border-radius: 4px;

    &:active, &:focus {
      background: #ECF3FF !important;
    }
  }
}
</style>
