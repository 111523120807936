// directives/click-outside.js
function isElementOrChild(el, target) {
  return el == target || el.contains(target)
}

function isProvidedElements(selectors, target) {
  if (!selectors?.length) return false
  return selectors.map(s => {
    const el = document.querySelector(s)
    return isElementOrChild(el, target)
  }).every(x => x)
}

export default {
  bind(el, binding) {
    el.clickOutsideEvent = function (event) {
      if (!(isElementOrChild(el, event.target)) && !(isProvidedElements(binding.value?.excluded, event.target))) {
        if (binding.value?.method) binding.value.method(event, binding.value.props)
      }
    }
    document.body.addEventListener('click', el.clickOutsideEvent)
  },
  unbind(el) {
    document.body.removeEventListener('click', el.clickOutsideEvent)
  },
}
