import { FIELD_FILTER_OPERATORS, TYPES, VALUE_TYPES } from '@/views/models/constants'
import { registerField } from '@/components/codex-layout-editor/BuilderUtils'
import { FIELD_GROUPS } from '@/codex-sdk/models'
import { CODEX_EDITOR_BLOCKS } from '@/components/codex-editor/CodeEditorConstants'
import Validation from './Validation.vue'
import Initial from './Initial.vue'
import Render from './Render.vue'
import General from './General.vue'
import Appearance from './Appearance.vue'
import Configuration from './Configuration.vue'

registerField('codex_field', {
  displayName: 'Rich Content',
  name: 'rich_content',
  render: Render,
  renderEditor: Render,
  renderEditorPanel: [
    {
      icon: 'Settings', label: 'fields.codex-field-rich-content.tabs.general', component: General, isGeneral: true,
    },
    { icon: 'Brush', label: 'fields.codex-field-rich-content.tabs.appearance', component: Appearance },
    { icon: 'Tasks', label: 'fields.codex-field-rich-content.tabs.validations', component: Validation },
    { icon: 'SettingsPreferencesTools', label: 'fields.codex-field-rich-content.tabs.configuration', component: Configuration },
  ],
  renderEditorInitialPanel: [
    { icon: 'Settings', label: 'fields.codex-field-rich-content.tabs.initial', component: Initial },
  ],
  group: FIELD_GROUPS.CODEX,
  icon: 'twitter-timeline.svg',

  type: TYPES.RICH_CONTENT,
  valueType: VALUE_TYPES.LIST,

  attrs: {
    version: {
      default: null,
    },
    calculateMetrics: {
      default: false,
    },
    appearance: {
      default: 1,
    },
    helpText: {
      default: '',
    },
    helpTextDisplay: {
      default: 'tooltip',
    },
    warningStatus: {
      default: true,
    },
    showCharacterCount: {
      default: false,
    },
    validation: {
      default: {
        readOnly: {
          value: false,
          conditionsEnabled: false,
          conditions: [
            {
              isSystem: false,
              field: '',
              operator: FIELD_FILTER_OPERATORS.EXISTS,
              value: '',
            },
          ],
        },
        blocks: {
          isEnabled: true,
          values: [CODEX_EDITOR_BLOCKS.PARAGRAPH],
          errorMessage: '',
        },
        models: {
          isEnabled: true,
          values: [],
          errorMessage: '',
        },
        toolbarOptions: {
          fontStyle: {
            isEnabled: true,
            bold: true,
            italic: true,
            underline: true,
            strikethrough: true,
            colorStyle: true,
          },
          dropcap: {
            isEnabled: true,
          },
          textAlignment: {
            isEnabled: true,
            alignLeft: true,
            alignCenter: true,
            alignRight: true,
          },
          links: {
            isEnabled: true,
            external: false,
            codex: false,
            phone: false,
            email: false,
          },
          scripts: {
            isEnabled: true,
          },
          comments: {
            isEnabled: true,
          },
        },
      },
    },
    configuration: {
      default: {
        searchable: true,
        filterable: true,
        aiFeatures: false,
      },
    },
    hidden: {
      value: false,
      conditionsEnabled: false,
      conditions: [
        {
          isSystem: false,
          field: '',
          operator: FIELD_FILTER_OPERATORS.EXISTS,
          value: '',
        },
      ],
    },
  },
})
