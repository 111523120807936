import { ASSET_TYPES } from '@/codex-sdk/assets'

export default {
  targetFolder: null,
  filters: {
    folderId: null,
    searchTerm: null,
    types: [ASSET_TYPES.IMAGE, ASSET_TYPES.FILE, ASSET_TYPES.VIDEO, ASSET_TYPES.VIDEO_PLAYLIST, ASSET_TYPES.AUDIO, ASSET_TYPES.PODCAST],
  },
  openedFolders: [],
  assetId: null,
  selectedData: {
    items: [],
    show: false,
    total: 0,
    limit: Infinity,
    totalSize: 0,
  },
}
