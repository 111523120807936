export const CODEX_EDITOR_BLOCKS = Object.freeze({
  MISSING_BLOCK: 'codex_missing_block',
  PARAGRAPH: 'paragraph',
  HEADING: 'heading',
  HEADING_1: 'heading:h1',
  HEADING_2: 'heading:h2',
  HEADING_3: 'heading:h3',
  HEADING_4: 'heading:h4',
  HEADING_5: 'heading:h5',
  HEADING_6: 'heading:h6',
  BULLET_LIST: 'bullet_list',
  ORDERED_LIST: 'ordered_list',
  FACTBOX: 'codex_factbox',
  BLOCKQUOTE: 'codex_blockquote',
  FACEBOOK_EMBED: 'codex_facebook_embed',
  YOUTUBE_EMBED: 'codex_youtube_embed',
  INSTAGRAM_EMBED: 'codex_instagram_embed',
  TWITTER_EMBED: 'codex_twitter_embed',
  SPOTIFY_PODCAST: 'codex_spotify_podcast',
  FAMEPLAY_EMBED: 'codex_malltv_embed',
  VIMEO_EMBED: 'codex_vimeo_embed',
  APPLE_PODCAST: 'codex_apple_podcast',
  GOOGLE_PODCAST: 'codex_google_podcast',
  GOOGLE_MAP_EMBED: 'codex_google_map_embed',
  IFRAME: 'codex_iframe',
  PAGE_BREAK: 'codex_page_break',
  CODE_BLOCK: 'codex_code_block',
  MEDIA: 'codex_media',
  MEDIA_IMAGE: 'codex_media:IMAGE',
  MEDIA_FILE: 'codex_media:FILE',
  MEDIA_VIDEO: 'codex_media:VIDEO',
  MEDIA_VIDEO_PLAYLIST: 'codex_media:VIDEO_PLAYLIST',
  MEDIA_AUDIO: 'codex_media:AUDIO',
  MEDIA_PODCAST: 'codex_media:PODCAST',
  TIKTOK_EMBED: 'codex_tiktok_embed',
  TABLE: 'table',
  REFERENCE: 'codex_reference',
  REFERENCE_INLINE: 'codex_reference_inline',
})
