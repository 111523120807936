<template>
  <div class="pt-0 new-filters-dropdown__filter-body new-filters-dropdown__filter-body--scroll">
    <b-row class="filters-models__search-row">
      <b-form-input
        v-model="search"
        type="text"
        :placeholder="$t('filters.models.search-placeholder')"
        class="filters-models__search"
      />
    </b-row>
    <b-row
      align-v="center"
      no-gutters
      class="mt-3"
    >
      <b-col
        v-if="models.length"
        cols="12"
      >
        <b-checkbox-group
          v-model="value.value"
          class="new-filters-dropdown__checkbox-group"
          :options="models"
          stacked
        />
      </b-col>
      <b-col v-else>
        <NoElements
          :title="$t('filters.models.no-model-found')"
          icon="Modeling"
          :icon-size="24"
        />
      </b-col>
    </b-row>

  </div>
</template>

<script>
import NoElements from '@/components/NoElements.vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    NoElements,
  },
  props: {
    filter: {
      type: Object,
      required: true,
    },
    filterObject: {
      type: [Object, Function],
      required: true,
    },
    value: {
      type: Object,
      required: true,
    },
    cache: {
      type: Object,
      required: true,
    },
    setCache: {
      type: Function,
      required: true,
    },
  },
  inject: ['showAssetsPopup'],
  data() {
    return {
      search: '',
    }
  },
  computed: {
    ...mapGetters('general', [
      'getModel',
    ]),
    objectValues() {
      if (!this.value.value) return []

      return this.value.value.map(mediaId => this.cache[mediaId] || { id: mediaId })
    },

    models() {
      if (this.filter?.models?.length) {
        const models = []
        this.filter.models.forEach(m => {
          const model = this.getModel(m)
          if (model) models.push(model)
        })
        const mappedModels = models.map(model => ({
          value: model.id,
          text: model.name,
        }))
        return this.search?.trim().length ? mappedModels.filter(m => m.text.toLowerCase().includes(this.search.toLowerCase())) : mappedModels
      }
      const models = this.$store.state.general.models.map(model => ({
        value: model.id,
        text: model.name,
      }))
      return this.search?.trim().length ? models.filter(m => m.text.toLowerCase().includes(this.search.toLowerCase())) : models
    },
  },
  watch: {
    'value.value': function (value) {
      value.forEach(modelId => {
        if (!this.cache[modelId]) {
          this.setCache(this.$store.state.general.models.find(model => model.id === modelId))
        }
      })
    },
  },
  methods: {
  },
}
</script>

<style lang="scss">
.new-filters-dropdown__media-item {
  display: flex;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 0.5rem;
  }
}
.new-filters-dropdown__media-item-avatar {
  margin-right: 10px;
}
.new-filters-dropdown__media-item-title {
  flex-grow: 1;
  margin: 0;

  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #1D417B;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.new-filters-dropdown__media-item-remove {
  cursor: pointer;
  opacity: 1;
  transition: color 0.3s ease;

  &:hover {
    opacity: 0.8;
  }
}
.filters-models__search.form-control {
  border: none;
  &::placeholder {
    color: #A3B0C2;
  }
}
.filters-models__search-row {
  position: fixed;
  z-index: 10;
  background-color: #fff;
  //100% - scrollbar width
  width: calc(100% - 8px);
}
</style>
