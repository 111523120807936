<template>
  <ResizableBlock
    :attrs="attrs"
    :selected="selected"
    :select-node="selectNode"
    :update-attrs="updateAttrs"
    :entity-ref="$refs.genericIframe"
  >
    <iframe
      ref="genericIframe"
      :src="embedSrc"
      width="100%"
      :height="height"
      :scrolling="scrolling"
      allowtransparency="true"
      allow="autoplay *; encrypted-media *; fullscreen *; picture-in-picture *"
      frameborder="0"
      sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-storage-access-by-user-activation allow-top-navigation-by-user-activation"
    />
  </ResizableBlock>
</template>

<script>
import ResizableBlock from '@/components/ResizableBlock.vue'

export default {
  components: {
    ResizableBlock,
  },
  props: ['attrs', 'updateAttrs', 'selectNode', 'selected'],
  inject: ['deleteBlock', 'showPromptPopup'],
  data() {
    return {
      initialSrc: '',
    }
  },
  computed: {
    embedSrc() {
      const link = this.attrs.src
      if (link) {
        if (link.indexOf('http://') == 0 || link.indexOf('https://') == 0) {
          return link
        }
        return `https://${link}`
      }
      return ''
    },
    src: { get() { return this.attrs.src }, set(e) { this.updateAttrs({ src: e }) } },
    height: { get() { return this.attrs.height }, set(e) { this.updateAttrs({ height: e }) } },
    scrolling: { get() { return this.attrs.scrolling }, set(e) { this.updateAttrs({ scrolling: e }) } },
    caption: { get() { return this.attrs.caption }, set(e) { this.updateAttrs({ caption: e }) } },
  },
  watch: {
    src() {
      this.$nextTick(() => {
        this.selectNode()
      })
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.initialSrc = this.src
      if (this.$refs.sourceField) {
        this.$refs.sourceField.$refs.vsinput.focus()
      }
    })
  },
  methods: {
    initialize() {
      if (!this.attrs.src) {
        this.openModal()
      }
    },
    handleCancel() {
      this.deleteBlock(this.attrs.blockId)
    },
    async openModal() {
      const url = await this.showPromptPopup({ title: this.$t('codex-editor.nodes.generic-iframe.generic-iframe-url') })
      if (!url) {
        this.deleteBlock(this.attrs.blockId)
      } else {
        this.updateAttrs({
          src: url,
        })
      }
    },
    setSrc() {
      this.updateAttrs({
        src: this.initialSrc,
      })
    },
  },
}
</script>
