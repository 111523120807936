import CodexLayoutError from '../CodexLayoutError'
import CodexLayoutTransaction from './Base'

/**
 * Remove Block Transaction
 */
export default class RemoveBlock extends CodexLayoutTransaction {
  name = 'RemoveBlock';

  parentBlockId = null

  blockId = null

  removeIndex = null

  removedBlock = null

  constructor(editor, blockId) {
    super(editor)

    const parentBlock = this.editor.findParentBlockById(blockId)

    if (!parentBlock) {
      throw new CodexLayoutError(`Block '${blockId}' not found`)
    }

    const removeIndex = parentBlock.content.findIndex(block => block.id === blockId)

    this.blockId = blockId
    this.removeIndex = removeIndex
    this.parentBlockId = parentBlock.id
  }

  up() {
    if (this.blockId === this.editor.selectedBlockId) {
      this.editor.selectBlock(null)
    }

    // Get parent block, where we'll remove the block from
    const parentBlock = this.editor.findBlockById(this.parentBlockId)

    // Find the index of block we want to remove
    const removeIndex = parentBlock.content.findIndex(block => block.id === this.blockId)

    // Add block to parents content
    const removedBlock = parentBlock.content.splice(removeIndex, 1)

    // Store the removed block so we can restore as needed
    this.removedBlock = removedBlock[0]
  }

  down() {
    // Get parent block, where we'll restore the block to
    const parentBlock = this.editor.findBlockById(this.parentBlockId)

    // Add back the block to parents content
    parentBlock.content.splice(this.removeIndex, 0, this.removedBlock)
  }
}
