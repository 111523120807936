import {
  TYPES,
  VALUE_TYPES,
  RANGE_OPERATORS,
  FIELD_FILTER_OPERATORS,
} from '@/views/models/constants'
import { registerField } from '@/components/codex-layout-editor/BuilderUtils'
import { FIELD_GROUPS } from '@/codex-sdk/models'
import Initial from './Initial.vue'
import Render from './Render.vue'
import General from './General.vue'
import Appearance from './Appearance.vue'
import Validation from './Validation.vue'
import Configuration from './Configuration.vue'

registerField('codex_field', {
  displayName: 'Rich Text',
  name: 'rich_text',
  render: Render,
  renderEditor: Render,
  renderEditorPanel: [
    {
      icon: 'Settings', label: 'fields.codex-field-rich-text.tabs.general', component: General, isGeneral: true,
    },
    { icon: 'Brush', label: 'fields.codex-field-rich-text.tabs.appearance', component: Appearance },
    { icon: 'Tasks', label: 'fields.codex-field-rich-text.tabs.validations', component: Validation },
    { icon: 'SettingsPreferencesTools', label: 'fields.codex-field-rich-text.tabs.configuration', component: Configuration },
  ],
  renderEditorInitialPanel: [
    { icon: 'Settings', label: 'fields.codex-field-rich-text.tabs.initial', component: Initial },
  ],
  group: FIELD_GROUPS.BASIC,
  icon: 'field-rich-text.png',

  type: TYPES.RICH_TEXT,
  valueType: VALUE_TYPES.SINGLE,

  attrs: {
    calculateMetrics: {
      default: false,
    },
    showCharacterCount: {
      default: false,
    },
    appearance: {
      default: 1,
    },
    helpText: {
      default: '',
    },
    helpTextDisplay: {
      default: 'tooltip',
    },
    hyperlinks: {
      default: {
        url: true,
        articles: true,
        images: true,
        files: true,
      },
    },
    embedded: {
      default: {
        images: true,
      },
    },
    formattingOption: {
      default: {
        headers: [1, 2, 3, 4, 5, 6, ''],
        bold: true,
        italicize: true,
        underline: true,
        bulletPoint: true,
        numberLine: true,
        quote: true,
        codeBlock: true,
        hyphen: false,
        superscript: true,
        subscript: true,
      },
    },
    validation: {
      default: {
        required: {
          value: false,
          errorMessage: 'RichText is required.',
          conditionsEnabled: false,
          conditions: [
            {
              isSystem: false,
              field: '',
              operator: FIELD_FILTER_OPERATORS.EXISTS,
              value: '',
            },
          ],
        },
        readOnly: {
          value: false,
          conditionsEnabled: false,
          conditions: [
            {
              isSystem: false,
              field: '',
              operator: FIELD_FILTER_OPERATORS.EXISTS,
              value: '',
            },
          ],
        },
        limitCharacterCount: {
          value: false,
          rangeOperator: RANGE_OPERATORS.BETWEEN,
          min: 20,
          max: 50,
          errorMessage: 'Number of characters: 20-50.',
          errorTemplate: 'Number of characters: {min}-{max}',
        },
        codexModelIds: [],
        fileType: {
          value: false,
          image: false,
          video: false,
          files: false,
          errorMessage: '',
        },
        richTextEmbedding: {
          model: false,
          article: false,
          media: false,
        },
      },
    },
    configuration: {
      default: {
        searchable: true,
        filterable: true,
      },
    },
    hidden: {
      value: false,
      conditionsEnabled: false,
      conditions: [
        {
          isSystem: false,
          field: '',
          operator: FIELD_FILTER_OPERATORS.EXISTS,
          value: '',
        },
      ],
    },
  },
})
