<template>
  <div class="new-filters-dropdown__filter-body new-filters-dropdown__filter-body--scroll">
    <b-row
      align-v="center"
      no-gutters
    >
      <b-col cols="12">
        <b-checkbox-group
          v-model="value.value"
          class="new-filters-dropdown__checkbox-group"
          :options="sites"
          stacked
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  props: {
    filter: {
      type: Object,
      required: true,
    },
    filterObject: {
      type: [Object, Function],
      required: true,
    },
    value: {
      type: Object,
      required: true,
    },
    cache: {
      type: Object,
      required: true,
    },
    setCache: {
      type: Function,
      required: true,
    },
  },
  computed: {
    sites() {
      return this.$store.state.sites.sites.map(site => ({
        value: site.id,
        text: site.name,
      }))
    },
  },
}
</script>
