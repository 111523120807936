<template>
  <b-modal
    id="codex-jobs-create"
    v-model="showPopup"
    size="lg"
    hide-header
    hide-footer
    no-close-on-backdrop
    :static="true"
    :no-close-on-route-change="true"
    header-class="create-job-header"
    footer-class="create-job-footer"
    body-class="create-job-body-class"
    centered
    :content-class="step !== 1 ? 'codex-jobs-create-modal' : ''"
  >
    <div class="create-job-container">
      <div
        v-if="step === 1"
        class="create-job-sidebar"
      >
        <img
          :src="require('@/assets/images/jobs/jobs-doc-icon.svg')"
          alt=""
          class="create-job-sidebar-image"
        >
        <span class="create-job-sidebar-title">
          {{ $t('jobs.create-popup.sidebar.title') }}
        </span>
        <span class="create-job-sidebar-description">
          {{ $t('jobs.create-popup.sidebar.description') }}
        </span>
        <a
          href="https://docs.codex.gjirafa.tech/docs/jobs/intro"
          target="_blank"
          class="create-job-sidebar-link"
        >
          {{ $t('jobs.create-popup.sidebar.link') }}
          <GjIcon
            name="Redirect"
            size="20"
          />
        </a>
      </div>
      <div class="create-job-body">
        <div class="create-job-body-header">
          <GjIcon
            name="Close"
            size="20"
            class="create-job-body-header-button"
            @click.native="closePopup(false)"
          />
        </div>
        <div v-show="step === 1">
          <p class="codex-jobs-create-type-label">
            {{ $t('jobs.create-popup.input-labels.type-label') }}
          </p>
          <div class="codex-jobs-create-job-type">
            <b-card
              v-for="type in types"
              :key="type.value"
              class="codex-jobs-type"
              :class="{ 'codex-jobs-type__selected': type.value === job.jobType }"
              body-class="codex-jobs-type-body"
              @click="setJobType(type.value)"
            >
              <div class="codex-jobs-type-body-left">
                <b-avatar
                  class="codex-jobs-type-body__icon"
                  size="40"
                >
                  <GjIcon
                    :key="type.icon"
                    :name="type.icon"
                    size="24"
                  />
                </b-avatar>
                <div>
                  <span class="codex-jobs-type-body__label">
                    {{ type.label }}
                  </span>
                  <span class="codex-jobs-type-body__description">
                    {{ type.description }}
                  </span>
                </div>
              </div>
              <GjIcon
                name="ArrowRight"
                size="24"
              />
            </b-card>
          </div>
        </div>
        <div
          v-show="step === 2"
          class="codex-jobs-create-form"
        >
          <p class="codex-jobs-create-form-job-type">
            {{ mapJobTypeLabel(job.jobType) }}
          </p>
          <b-form-group :label="$t('jobs.create-popup.input-labels.name-label')">
            <b-form-input v-model="job.jobName" />
          </b-form-group>

          <template v-if="job.jobType === JOB_TYPES.IMPORT_ENTRIES || job.jobType === JOB_TYPES.IMPORT_ASSETS">
            <b-form-group :label="$t('jobs.create-popup.upload-label')">
              <Upload v-model="job.fileKey" />
            </b-form-group>

          </template>

          <template v-if="job.jobType === JOB_TYPES.DELETE_CONTENT">
            <b-form-group :label="$t('jobs.create-popup.input-labels.site-label')">
              <v-select
                v-model="job.siteId"
                label="name"
                :reduce="t => t.id"
                :options="sites"
                :clearable="false"
              />
            </b-form-group>
            <b-form-group :label="$t('jobs.create-popup.input-labels.delete-content-task-types-label')">
              <v-select
                v-model="deleteContentTaskTypes"
                label="label"
                :reduce="t => t.value"
                :options="deleteContentTaskTypesOptions"
                deselect-from-dropdown
                multiple
                :clearable="false"
              />
            </b-form-group>
          </template>

          <template v-if="job.jobType === JOB_TYPES.DELETE_FIELD">
            <b-form-group :label="$t('jobs.create-popup.input-labels.model-label')">
              <v-select
                v-model="job.model"
                :options="$store.state.general.allModels"
                label="name"
                :reduce="m => m.id"
                :clearable="false"
              >
                <template #selected-option="option">
                  <span>
                    <GjIcon
                      :name="option.iconId"
                      size="20"
                    />
                    {{ option.name }}
                  </span>
                </template>
                <template v-slot:option="option">
                  <span>
                    <GjIcon
                      :name="option.iconId"
                      size="20"
                    />
                    {{ option.name }}
                  </span>
                </template>
              </v-select>
            </b-form-group>

            <b-form-group :disabled="!job.model || !model || loadingModel">
              <template #label>
                <div class="codex-jobs-create-field">
                  <span>{{ $t('jobs.create-popup.input-labels.field-alias-label') }}</span>
                  <b-spinner
                    v-if="loadingModel"
                    small
                  />
                  <GjIcon
                    v-else
                    v-b-tooltip="$t('jobs.create-popup.input-labels.field-alias-tooltip')"
                    name="Info"
                    size="18"
                  />
                </div>
              </template>
              <v-select
                v-model="field"
                :options="allFields"
                :clearable="false"
                :selectable="option => !option.disabled"
              >
                <template #option="option">
                  {{ option.label }}
                  <template v-if="option.value">
                    ({{ option.value }})
                  </template>
                </template>
              </v-select>
            </b-form-group>
          </template>

          <template v-if="job.jobType === JOB_TYPES.CREATE_ENVIRONMENT || job.jobType === JOB_TYPES.DELETE_ENVIRONMENT">
            <b-form-group :label="$t('jobs.create-popup.input-labels.select-base-environment')">
              <v-select
                v-model="job.baseEnvironmentId"
                :options="environments"
                :clearable="false"
                label="alias"
                :reduce="e => e.id"
              />
            </b-form-group>
            <b-row v-if="job.jobType === JOB_TYPES.CREATE_ENVIRONMENT">
              <b-col>
                <b-form-group :label="$t('jobs.create-popup.input-labels.new-environment-alias')">
                  <b-input-group :prepend="`${job.baseEnvironmentId || ''}-`">
                    <b-form-input v-model="job.newEnvironmentAlias" />
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group :label="$t('jobs.create-popup.input-labels.new-environment-display-name')">
                  <b-form-input v-model="job.newEnvironmentDisplayName" />
                </b-form-group>
              </b-col>
            </b-row>
          </template>

          <template v-if="job.jobType === JOB_TYPES.SYNC_ENVIRONMENTS">
            <b-row>
              <b-col>
                <b-form-group :label="$t('jobs.create-popup.input-labels.select-source-environment')">
                  <v-select
                    v-model="job.baseEnvironmentId"
                    :options="environments"
                    :clearable="false"
                    label="alias"
                    :reduce="e => e.id"
                  />
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group :label="$t('jobs.create-popup.input-labels.select-target-environment')">
                  <v-select
                    v-model="job.targetEnvironmentId"
                    :options="environments"
                    :clearable="false"
                    label="alias"
                    :reduce="e => e.id"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <CronScheduler v-model="job.cronExpression" />
              </b-col>
            </b-row>
          </template>

          <template v-if="job.jobType === JOB_TYPES.CLONE_SITE">
            <b-form-group :label="$t('jobs.create-popup.input-labels.site-label')">
              <v-select
                v-model="job.baseSiteId"
                :options="$store.state.sites.sites"
                label="name"
                :reduce="s => s.id"
                :clearable="false"
              />
            </b-form-group>
            <b-form-group :label="$t('jobs.create-popup.input-labels.site-name')">
              <b-form-input v-model="job.newSiteName" />
            </b-form-group>

            <b-form-group :label="$t('jobs.create-popup.input-labels.site-alias')">
              <b-form-input v-model="job.newSiteAlias" />
            </b-form-group>
            <b-row class="edit-site__upload-field">
              <b-col>
                <span class="edit-site__upload-title">
                  {{ $t('jobs.create-popup.input-labels.site-logo') }}
                </span>
                <span class="edit-site__logo-description">
                  {{ $t('jobs.create-popup.input-labels.site-logo-description') }}
                </span>
              </b-col>
              <b-col>
                <UploadField
                  v-model="job.newSiteLogo"
                  type="upload"
                />
              </b-col>
            </b-row>
          </template>
        </div>

        <div class="create-job-steps-container">
          <span class="create-job-steps-label">
            {{ $t('jobs.create-popup.steps', {step, steps: 2}) }}
          </span>
          <b-progress
            :max="2"
            height="2px"
            class="create-job-steps-progress"
          >
            <b-progress-bar
              variant="primary"
              :value="step >= 1 ? 1: 0"
              class="create-job-steps-progress-bar"
            />
            <b-progress-bar
              variant="primary"
              :value="step >= 2 ? 1: 0"
              class="create-job-steps-progress-bar"
            />
          </b-progress>
        </div>

        <div class="create-job-actions">
          <div class="create-job-action-columns">
            <span
              v-if="step !== 1"
              class="create-job-actions-back-label"
              @click="previous"
            >
              <GjIcon
                name="ArrowLeft"
                size="20"
                class="create-job-actions-back-icon"
              />
              {{ $t('jobs.create-popup.back') }}
            </span>
          </div>

          <div class="create-job-action-columns">
            <span
              class="create-job-actions-cancel"
              @click="closePopup(false)"
            >
              {{ $t('jobs.create-popup.cancel') }}
            </span>
            <b-button
              v-if="step !== 1"
              size="sm"
              variant="primary"
              class="create-job-actions-save"
              :disabled="(isLast && !isValid) || saving"
              @click="next"
            >
              {{ isLastStep ? $t('jobs.create-popup.create') : $t('jobs.create-popup.continue') }}
            </b-button>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import PopupsMixin from '@/components/popups/PopupsMixin'
import {
  jobDefaults,
  mapJobTypeLabel,
  JOB_TYPES,
  mapDeleteContentTaskType,
  DELETE_CONTENT_TASK_TYPES,
  transformImportJobInput,
  transformDeleteJobInput,
  transformCreateEnvironmentJobInput,
  transformSyncEnvironmentsJobInput,
  getErrors,
  JOB_ENTITY_TYPES, RECURRING_JOB_STATUSES,
  transformDeleteEnvironmentJobInput, transformCreateCloneSiteJobInput,
} from '@/codex-sdk/jobs'
import Upload from '@/views/jobs/components/Upload.vue'
import gql from 'graphql-tag'
import CronScheduler from '@/views/jobs/components/CronScheduler.vue'
import { ENVIRONMENTS_STATUSES } from '@/codex-sdk/organizations'
import { readModel } from '@/codex-sdk/models'
import UploadField from '@/components/assets/UploadField.vue'

export default {
  name: 'CreateJobPopup',
  components: { UploadField, CronScheduler, Upload },
  mixins: [PopupsMixin],
  inject: ['toastNotification', 'showConfirmPopup'],
  apollo: {
    $client: 'jobsClient',
    organizationEnvironments: {
      query: gql`
        query {
          organizationEnvironments (limit: 1000){
            items {
              id
              alias
              baseId
              status
            }
          }
        }
      `,
      fetchPolicy: 'network-only',
      skip() {
        return this.job.jobType !== JOB_TYPES.CREATE_ENVIRONMENT
            && this.job.jobType !== JOB_TYPES.SYNC_ENVIRONMENTS
            && this.job.jobType !== JOB_TYPES.DELETE_ENVIRONMENT
      },
    },
  },
  data() {
    const isRecurringTab = this.data.jobEntityType === JOB_ENTITY_TYPES.RECURRING
    const job = jobDefaults()

    if (isRecurringTab) {
      job.jobType = JOB_TYPES.SYNC_ENVIRONMENTS
    }
    return {
      model: null,
      field: null,
      JOB_TYPES,
      mapJobTypeLabel,
      LAST_STEP: {
        [JOB_TYPES.IMPORT_ASSETS]: 2,
        [JOB_TYPES.IMPORT_ENTRIES]: 2,
        [JOB_TYPES.DELETE_CONTENT]: 2,
        [JOB_TYPES.CREATE_ENVIRONMENT]: 2,
        [JOB_TYPES.SYNC_ENVIRONMENTS]: 2,
        [JOB_TYPES.DELETE_ENVIRONMENT]: 2,
        [JOB_TYPES.DELETE_FIELD]: 2,
        [JOB_TYPES.CLONE_SITE]: 2,
      },
      items: [],
      step: isRecurringTab ? 2 : 1,
      job,
      isRecurringTab,
      loadingModel: false,
      saving: false,
    }
  },
  computed: {
    allFields() {
      if (this.model?.deletedBlocks?.length) {
        return this.model.deletedBlocks.map(f => ({
          label: f.attrs.name,
          value: f.attrs.alias,
        }))
      }
      return []
    },
    environments() {
      const environments = this.organizationEnvironments?.items?.filter(o => o.status === ENVIRONMENTS_STATUSES.ACTIVE || o.status === ENVIRONMENTS_STATUSES.UNDEFINED) || []
      if (this.job.jobType === JOB_TYPES.DELETE_ENVIRONMENT) return environments.filter(e => e.baseId)
      return environments
    },
    sites() {
      return this.$store.state.sites.sites || []
    },
    deleteContentTaskTypes: {
      get() {
        return this.job.deleteContentTaskTypes
      },
      set(v) {
        if (v[v.length - 1] === DELETE_CONTENT_TASK_TYPES.DELETE_ALL || v.length === 0) {
          this.job.deleteContentTaskTypes = [DELETE_CONTENT_TASK_TYPES.DELETE_ALL]
        } else {
          this.job.deleteContentTaskTypes = v.filter(item => item !== DELETE_CONTENT_TASK_TYPES.DELETE_ALL)
        }
      },
    },
    isValid() {
      const hasValidName = !!this.job.jobName?.length
      const hasValidFile = this.job.jobType === JOB_TYPES.IMPORT_ASSETS || this.job.jobType === JOB_TYPES.IMPORT_ENTRIES ? !!this.job.fileKey?.length : true
      const hasValidSite = this.job.jobType === JOB_TYPES.DELETE_CONTENT ? !!this.job.siteId?.length : true
      const hasValidDeleteContentTaskTypes = this.job.jobType === JOB_TYPES.DELETE_CONTENT ? !!this.job.deleteContentTaskTypes?.length : true
      const hasValidBaseEnvironmentId = this.job.jobType === JOB_TYPES.CREATE_ENVIRONMENT || this.job.jobType === JOB_TYPES.SYNC_ENVIRONMENTS || this.job.jobType === JOB_TYPES.DELETE_ENVIRONMENT ? !!this.job.baseEnvironmentId?.length : true
      const hasValidNewEnvironmentAlias = this.job.jobType === JOB_TYPES.CREATE_ENVIRONMENT ? !!this.job.newEnvironmentAlias?.length : true
      const hasValidNewEnvironmentDisplayName = this.job.jobType === JOB_TYPES.CREATE_ENVIRONMENT ? !!this.job.newEnvironmentDisplayName?.length : true
      const hasValidTargetEnvironmentId = this.job.jobType === JOB_TYPES.SYNC_ENVIRONMENTS ? !!this.job.targetEnvironmentId?.length : true
      const hasValidModel = this.job.jobType === JOB_TYPES.DELETE_FIELD ? !!this.job.model?.length : true
      const hasValidFieldAlias = this.job.jobType === JOB_TYPES.DELETE_FIELD ? !!this.job.fieldAlias?.length : true

      const hasValidBaseSiteId = this.job.jobType === JOB_TYPES.CLONE_SITE ? !!this.job.baseSiteId?.length : true
      const hasValidNewSiteName = this.job.jobType === JOB_TYPES.CLONE_SITE ? !!this.job.newSiteName?.length : true
      const hasValidNewSiteAlias = this.job.jobType === JOB_TYPES.CLONE_SITE ? !!this.job.newSiteAlias?.length : true
      const hasValidNewSiteLogo = this.job.jobType === JOB_TYPES.CLONE_SITE ? !!this.job.newSiteLogo?.length : true

      return hasValidName
          && hasValidFile
          && hasValidSite
          && hasValidDeleteContentTaskTypes
          && hasValidBaseEnvironmentId
          && hasValidNewEnvironmentAlias
          && hasValidNewEnvironmentDisplayName
          && hasValidTargetEnvironmentId
          && hasValidModel
          && hasValidFieldAlias
          && hasValidBaseSiteId
          && hasValidNewSiteName
          && hasValidNewSiteAlias
          && hasValidNewSiteLogo
    },
    types() {
      return this.isRecurringTab ? [{
        label: mapJobTypeLabel(JOB_TYPES.SYNC_ENVIRONMENTS),
        value: JOB_TYPES.SYNC_ENVIRONMENTS,
        description: this.$t('jobs.types-description.import_assets'),
        icon: 'IconparkShuffleOne',
      }] : [
        {
          label: mapJobTypeLabel(JOB_TYPES.IMPORT_ASSETS),
          value: JOB_TYPES.IMPORT_ASSETS,
          description: this.$t('jobs.types-description.import_assets'),
          icon: 'Image',
        },
        {
          label: mapJobTypeLabel(JOB_TYPES.IMPORT_ENTRIES),
          value: JOB_TYPES.IMPORT_ENTRIES,
          description: this.$t('jobs.types-description.import_entries'),
          icon: 'File',
        },
        {
          label: mapJobTypeLabel(JOB_TYPES.DELETE_CONTENT),
          value: JOB_TYPES.DELETE_CONTENT,
          description: this.$t('jobs.types-description.delete_content'),
          icon: 'Delete',
        },
        {
          label: mapJobTypeLabel(JOB_TYPES.CREATE_ENVIRONMENT),
          value: JOB_TYPES.CREATE_ENVIRONMENT,
          description: this.$t('jobs.types-description.create_environment'),
          icon: 'IconparkBranchTwo',
        },
        {
          label: mapJobTypeLabel(JOB_TYPES.SYNC_ENVIRONMENTS),
          value: JOB_TYPES.SYNC_ENVIRONMENTS,
          description: this.$t('jobs.types-description.sync_environments'),
          icon: 'IconparkShuffleOne',
        },
        {
          label: mapJobTypeLabel(JOB_TYPES.DELETE_ENVIRONMENT),
          value: JOB_TYPES.DELETE_ENVIRONMENT,
          description: this.$t('jobs.types-description.delete_environment'),
          icon: 'Delete',
        },
        {
          label: mapJobTypeLabel(JOB_TYPES.DELETE_FIELD),
          value: JOB_TYPES.DELETE_FIELD,
          description: this.$t('jobs.types-description.delete_field'),
          icon: 'Delete',
        },
        {
          label: mapJobTypeLabel(JOB_TYPES.CLONE_SITE),
          value: JOB_TYPES.CLONE_SITE,
          description: this.$t('jobs.types-description.clone_site'),
          icon: 'Copy',
        },
      ]
    },
    deleteContentTaskTypesOptions() {
      return [
        {
          label: mapDeleteContentTaskType(DELETE_CONTENT_TASK_TYPES.DELETE_ALL),
          value: DELETE_CONTENT_TASK_TYPES.DELETE_ALL,
        },
        {
          label: mapDeleteContentTaskType(DELETE_CONTENT_TASK_TYPES.DELETE_ENTRIES),
          value: DELETE_CONTENT_TASK_TYPES.DELETE_ENTRIES,
        },
        {
          label: mapDeleteContentTaskType(DELETE_CONTENT_TASK_TYPES.DELETE_URLS),
          value: DELETE_CONTENT_TASK_TYPES.DELETE_URLS,
        },
        {
          label: mapDeleteContentTaskType(DELETE_CONTENT_TASK_TYPES.DELETE_TAGS),
          value: DELETE_CONTENT_TASK_TYPES.DELETE_TAGS,
        },
      ]
    },
    isLastStep() {
      return this.step >= this.LAST_STEP[this.job.jobType]
    },
  },
  watch: {
    $route() {
      this.closePopup(false)
    },
    'job.jobType': function (n, o) {
      if (o === JOB_TYPES.DELETE_CONTENT) {
        this.job.siteId = null
        this.job.deleteContentTaskTypes = [DELETE_CONTENT_TASK_TYPES.DELETE_ALL]
      }
    },
    field: {
      handler(v) {
        this.job.fieldAlias = v?.value || null
      },
      deep: true,
    },
    'job.model': function (v) {
      if (v) {
        this.field = null
        this.model = null
        this.readModel()
      }
    },
  },
  methods: {
    async readModel() {
      try {
        this.loadingModel = true
        const { data } = await readModel(this.job.model)
        this.model = data
      } catch (e) {
        console.log(e)
      }
      this.loadingModel = false
    },
    setJobType(type) {
      this.job.jobType = type
      this.next()
    },
    next() {
      if (this.isLastStep) {
        this.create()
        return
      }
      this.step++
    },
    previous() {
      if (this.step > (this.isRecurringTab ? 2 : 1)) {
        this.step--
      }
    },
    async create() {
      if (!this.isValid || this.saving) return
      this.saving = true
      if (this.job.jobType === JOB_TYPES.IMPORT_ASSETS || this.job.jobType === JOB_TYPES.IMPORT_ENTRIES) {
        await this.createImportJob()
      } else if (this.job.jobType === JOB_TYPES.DELETE_CONTENT || this.job.jobType === JOB_TYPES.DELETE_FIELD) {
        await this.createDeleteJob()
      } else if (this.job.jobType === JOB_TYPES.CREATE_ENVIRONMENT) {
        await this.createEnvironmentJob()
      } else if (this.job.jobType === JOB_TYPES.SYNC_ENVIRONMENTS) {
        await this.createSyncEnvironmentJob()
      } else if (this.job.jobType === JOB_TYPES.DELETE_ENVIRONMENT) {
        await this.createDeleteEnvironmentJob()
      } else if (this.job.jobType === JOB_TYPES.CLONE_SITE) {
        await this.createCloneSiteJob()
      }
      this.saving = false
    },
    async createImportJob() {
      try {
        const { data, errors } = await this.$apollo.mutate({
          client: 'jobsClient',
          mutation: gql`
            mutation CreateImportJob($input: CreateImportJobInput!) {
              createImportJob(createJobInput: $input) {
                id
              }
            }
          `,
          variables: {
            input: transformImportJobInput(this.job),
          },
          errorPolicy: 'all',
        })
        if (errors) {
          this.displayErrors(errors)
        } else {
          this.displaySuccess()
          this.closePopup(data.createImportJob)
        }
      } catch (e) {
        console.log(e)
      }
    },
    async createDeleteJob() {
      const result = await this.showDeleteConfirm()

      if (!result) return

      try {
        const { data, errors } = await this.$apollo.mutate({
          client: 'jobsClient',
          mutation: gql`
            mutation CreateDeleteJob($input: CreateDeleteJobInput!) {
              createDeleteJob(createJobInput: $input) {
                id
              }
            }
          `,
          variables: {
            input: transformDeleteJobInput(this.job),
          },
          errorPolicy: 'all',
        })
        if (errors) {
          this.displayErrors(errors)
        } else {
          this.displaySuccess()
          this.closePopup(data.createDeleteJob)
        }
      } catch (e) {
        console.log(e)
      }
    },
    async createEnvironmentJob() {
      try {
        const { data, errors } = await this.$apollo.mutate({
          client: 'jobsClient',
          mutation: gql`
            mutation CreateEnvironmentJob($input: CreateEnvironmentJobInput!) {
              createEnvironmentJob(createJobInput: $input) {
                id
                name
                status
                tasks {
                  type
                  consoleAppName
                  displayName
                  runAt
                  status
                  id
                }
                type
                createdAt
                updatedAt
              }
            }
          `,
          variables: {
            input: transformCreateEnvironmentJobInput(this.job),
          },
          errorPolicy: 'all',
        })

        if (errors) {
          this.displayErrors(errors)
        } else {
          this.displaySuccess()
          this.closePopup(data.createEnvironmentJob)
        }
      } catch (e) {
        console.log(e)
      }
    },
    async createSyncEnvironmentJob() {
      try {
        const { data, errors } = await this.$apollo.mutate({
          client: 'jobsClient',
          mutation: gql`
            mutation SyncEnvironmentJob($input: SyncEnvironmentsJobInput!) {
              syncEnvironmentJob(createJobInput: $input) {
                job {
                  id
                }
                recurringJob {
                  id
                  status
                }
              }
            }
          `,
          variables: {
            input: transformSyncEnvironmentsJobInput(this.job),
          },
          errorPolicy: 'all',
        })
        if (errors) {
          this.displayErrors(errors)
        } else {
          this.displaySuccess()
          this.closePopup({
            ...(data.syncEnvironmentJob.job || data.syncEnvironmentJob.recurringJob),
            isRecurringScheduled: data?.syncEnvironmentJob?.recurringJob?.status === RECURRING_JOB_STATUSES.SCHEDULED,
          })
        }
      } catch (e) {
        console.log(e)
      }
    },
    async createDeleteEnvironmentJob() {
      try {
        const env = this.environments.find(e => e.id === this.job.baseEnvironmentId)

        const answer = await this.showConfirmPopup({
          title: this.$t('jobs.create-popup.confirm-delete-environment.title'),
          description: this.$t('jobs.create-popup.confirm-delete-environment.description', { alias: env.alias }),
          okTitle: this.$t('jobs.create-popup.confirm-delete-environment.ok-title'),
          cancelTitle: this.$t('jobs.create-popup.confirm-delete-environment.cancel-title'),
          okVariant: 'danger',
        })

        if (!answer) return

        const { data, errors } = await this.$apollo.mutate({
          client: 'jobsClient',
          mutation: gql`
            mutation DeleteEnvironmentJob($input: DeleteEnvironmentJobInput!) {
              deleteEnvironmentJob(createJobInput: $input) {
                id
              }
            }
          `,
          variables: {
            input: transformDeleteEnvironmentJobInput(this.job),
          },
          errorPolicy: 'all',
        })

        if (errors) {
          this.displayErrors(errors)
        } else {
          this.displaySuccess()
          this.closePopup(data.deleteEnvironmentJob)
        }
      } catch (e) {
        console.log(e)
      }
    },
    async createCloneSiteJob() {
      try {
        const { data, errors } = await this.$apollo.mutate({
          client: 'jobsClient',
          mutation: gql`
            mutation CreateCloneSiteJob($input: CreateCloneSiteJobInput!) {
              createCloneSiteJob(createCloneSiteJobInput: $input) {
                id
              }
            }
          `,
          variables: {
            input: transformCreateCloneSiteJobInput(this.job),
          },
          errorPolicy: 'all',
        })
        if (errors) {
          this.displayErrors(errors)
        } else {
          this.displaySuccess()
          this.closePopup(data.createCloneSiteJob)
        }
      } catch (e) {
        console.log(e)
      }
    },
    async showDeleteConfirm() {
      const siteName = this.sites.find(site => site.id === this.job.siteId)?.name

      let description = ''

      if (this.job.jobType === JOB_TYPES.DELETE_FIELD) {
        description = this.$t('jobs.create-popup.confirm-delete-job.delete-field', {
          field: this.field.label,
          model: this.model.name,
        })
      } else {
        const isDeleteAll = this.job.deleteContentTaskTypes.includes(DELETE_CONTENT_TASK_TYPES.DELETE_ALL)
        let deleteTypes = this.job.deleteContentTaskTypes

        if (isDeleteAll) {
          deleteTypes = [DELETE_CONTENT_TASK_TYPES.DELETE_ENTRIES, DELETE_CONTENT_TASK_TYPES.DELETE_URLS, DELETE_CONTENT_TASK_TYPES.DELETE_TAGS]
        }

        deleteTypes = deleteTypes.map(type => {
          switch (type) {
            case DELETE_CONTENT_TASK_TYPES.DELETE_ENTRIES:
              return this.$t('jobs.create-popup.confirm-delete-job.entries')
            case DELETE_CONTENT_TASK_TYPES.DELETE_URLS:
              return this.$t('jobs.create-popup.confirm-delete-job.urls')
            case DELETE_CONTENT_TASK_TYPES.DELETE_TAGS:
              return this.$t('jobs.create-popup.confirm-delete-job.tags')
            default:
              return ''
          }
        })

        if (deleteTypes.length === 1) {
          description = this.$t('jobs.create-popup.confirm-delete-job.oneDescription', {
            site: siteName, entity1: deleteTypes[0],
          })
        } else if (deleteTypes.length === 2) {
          description = this.$t('jobs.create-popup.confirm-delete-job.twoDescription', {
            site: siteName, entity1: deleteTypes[0], entity2: deleteTypes[1],
          })
        } else if (deleteTypes.length === 3) {
          description = this.$t('jobs.create-popup.confirm-delete-job.threeDescription', {
            site: siteName, entity1: deleteTypes[0], entity2: deleteTypes[1], entity3: deleteTypes[2],
          })
        }
      }

      // eslint-disable-next-line no-return-await
      return await this.showConfirmPopup({
        title: this.$t('jobs.create-popup.confirm-delete-job.title'),
        description,
        okTitle: this.$t('jobs.create-popup.confirm-delete-job.okTitle'),
        cancelTitle: this.$t('jobs.create-popup.confirm-delete-job.cancelTitle'),
        okVariant: 'danger',
      })
    },
    displayErrors(errors) {
      this.toastNotification({
        title: this.$t('sdk.jobs.create-failed'),
        icon: 'Close',
        text: getErrors(errors),
        variant: 'danger',
      })
    },
    displaySuccess() {
      this.toastNotification({
        title: this.$t('sdk.success-title'),
        icon: 'Check',
        text: this.$t('sdk.jobs.create-successful'),
        variant: 'success',
      })
    },
  },
}
</script>
