<template>
  <b-sidebar
    v-model="showPopup"
    :title="site.id ? $t('site.edit') : $t('site.add')"
    class="c-sidebar c-sidebar--lg"
    :no-close-on-route-change="true"
    right
    backdrop
    shadow
  >
    <div class="edit-site">
      <b-form-group
        v-if="site.id"
        :label="$t('site.add-site.id')"
        label-for="siteId"
        class="site-popup__field"
      >
        <b-form-input
          id="siteId"
          v-model="site.id"
          readonly
        />
        <span
          class="site-popup__field-copy"
          @click="copy(site.id)"
        >
          <GjIcon
            name="Copy"
            size="20"
          />
        </span>
      </b-form-group>
      <b-form-group
        :label="$t('site.add-site.name')"
        label-for="displayName"
      >
        <b-form-input
          id="displayName"
          v-model="site.name"
          autocomplete="off"
          @keyup.enter.prevent="saveSite"
        />
      </b-form-group>
      <b-form-group
        :label="$t('site.add-site.alias')"
        label-for="alias"
      >
        <b-form-input
          id="alias"
          v-model="site.alias"
          :readonly="site.id ? true : false"
          autocomplete="off"
          @keyup.enter.prevent="saveSite"
        />
      </b-form-group>

      <b-form-group
        :label="$t('site.add-site.domain')"
        label-for="name"
      >
        <b-form-input
          id="domain"
          v-model="site.domain"
          autocomplete="off"
          @keyup.enter.prevent="saveSite"
        />
      </b-form-group>

      <b-form-group
        :label="$t('site.add-site.preview-domain')"
        label-for="previewDomain"
      >
        <b-form-input
          id="domain"
          v-model="site.previewDomain"
          autocomplete="off"
          @keyup.enter.prevent="saveSite"
        />
      </b-form-group>

      <b-form-group
        :label="$t('site.add-site.description')"
        label-for="description"
      >
        <b-form-textarea
          v-model="site.description"
          @keyup.enter.prevent="saveSite"
        />
      </b-form-group>
      <b-form-group>
        <template #label>
          <div class="default-folder-label">
            <span>
              {{ $t('site.add-site.default-folder') }}
            </span>
            <GjIcon
              v-b-tooltip="$t('site.add-site.default-folder-tooltip')"
              name="Info"
              size="18"
            />
          </div>
        </template>
        <FolderSelect
          v-model="site.defaultFolderId"
          :has-multiple="false"
          :close-on-select="true"
        />
      </b-form-group>
      <div class="site-popup__repeater">
        <b-form-group
          v-if="pairs.length > 0"
          :label="$t('site.add-site.key-value')"
          label-for="keyValue"
          class="site-popup__repeater-title"
        />
        <div
          v-if="pairs.length > 0"
          class="site-popup__repeater-form"
        >
          <div
            v-for="(item, idx) in pairs"
            :key="idx"
            class="site-popup__repeater-item"
          >
            <b-form-group>
              <b-form-input
                v-model="item.key"
                placeholder="Key"
                :state="validateKey(item.key)"
                @change="validateKey(item.key)"
              />
            </b-form-group>
            <b-form-group>
              <b-form-input
                v-model="item.value"
                placeholder="Value"
              />
            </b-form-group>
            <GjIcon
              name="Close"
              @click.native="removeItem(idx)"
            />
          </div>
        </div>
        <div
          class="site-popup__repeater-add"
          @click="pairs.push({ key: '', value: '' })"
        >
          <span>
            <GjIcon
              size="20"
              name="Plus"
            />
            {{ $t('site.add-site.add-key-value') }}
          </span>
        </div>
      </div>
      <b-row class="edit-site__upload-field">
        <b-col>
          <span class="edit-site__upload-title">
            {{ $t('site.add-site.logo') }}
          </span>
          <span class="edit-site__logo-description">
            {{ $t('site.add-site.logoDescription') }}
          </span>
        </b-col>
        <b-col>
          <UploadField
            v-model="site.logo"
            type="upload"
          />
        </b-col>
      </b-row>
    </div>
    <template #footer>
      <b-button
        id="at-label-cancel-btn"
        variant="outline-secondary"
        @click="handleCancel"
      >
        {{ $t('general.cancel') }}
      </b-button>
      <b-button
        id="at-label-create-btn"
        variant="primary"
        @click="saveSite"
      >
        {{ site.id ? $t('general.save') : $t('general.create') }}
      </b-button>
    </template>
  </b-sidebar>
</template>

<script>
import { useClipboard } from '@vueuse/core'
import PopupsMixin from '@/components/popups/PopupsMixin'
import { siteDefaults } from '@/codex-sdk/sites'
import { uploadGeneralAsset } from '@/codex-sdk/assets'
import { cloneDeep } from 'lodash'
import UploadField from '@/components/assets/UploadField.vue'
import { MAX_FILE_SIZE } from '@/utils/constants'
import FolderSelect from '@/components/assets/FolderSelect.vue'

export default {
  name: 'EditSitePopup',
  components: { FolderSelect, UploadField },
  mixins: [PopupsMixin],
  inject: ['toastNotification'],
  data() {
    return {
      uploadField: null,
      uploading: false,
      site: siteDefaults(),
      layouts: {},
      saving: false,
      pairs: [],
    }
  },
  computed: {
    siteId() {
      return this.data.id
    },
    getSite() {
      return this.data?.site
    },
  },
  watch: {
    uploadField() {
      this.site.logo = this.uploadField.url
    },
  },
  mounted() {
    this.setSite()
  },
  methods: {
    duplicatedKey(key) {
      return this.pairs.filter(item => item.key === key).length > 1
    },
    validateKey(key) {
      if (key === '') return null
      const regex = /^[a-zA-Z][a-zA-Z0-9_-]*$/

      return (key && key.match(regex) && !this.duplicatedKey(key)) ? null : false
    },
    removeItem(idx) {
      this.pairs.splice(idx, 1)
    },
    copy(text) {
      const { copy } = useClipboard()
      copy(text)

      this.toastNotification({
        icon: 'Copy',
        variant: 'success',
        title: 'Copied',
        text: 'Copied to clipboard',
      })
    },

    setSite() {
      if (this.getSite) {
        this.site = cloneDeep(this.getSite)
        this.$set(this.site, 'defaultFolderId', this.site?.defaultFolder?.id)

        try {
          // prettier-ignore
          this.pairs = this.site.attrs ? Object.entries(this.site.attrs).map(([key, value]) => ({
            key,
            value,
          })) : []
        } catch (e) {
          this.pairs = []
        }
      } else {
        this.label = siteDefaults()
      }
    },

    /**
     * Here we can add any validation rule for the site
     *
     * ToDo: Maybe move this to the Codex SDK
     */
    validateSite() {
      const errorMessages = []

      const regex = new RegExp('^[a-zA-Z0-9]+$')
      const result = regex.test(this.site.alias)

      if (!this.site.name) {
        errorMessages.push(this.$t('site.error-messages.name-required'))
      }

      if (!this.site.logo) {
        errorMessages.push(this.$t('site.error-messages.logo-required'))
      }

      if (!this.site.alias) {
        errorMessages.push(this.$t('site.error-messages.alias-required'))
      } else if (!result) {
        errorMessages.push(this.$t('site.error-messages.alias-validation'))
      }

      if (errorMessages.length) {
        this.toastNotification({
          icon: 'Close',
          variant: 'danger',
          title: 'Error',
          text: errorMessages.join('.'),
        })
        // this.$vs.notify({
        //   title: 'Error',
        //   text: errorMessages.join('<br>'),
        //   color: 'danger',
        // })

        return false
      }
      return true
    },

    /**
     * Save the site
     *
     * Checks wether we are creating a new site or
     * updating an existing one, and call the appropriate Action.
     *
     * If creating, updated the URL with recieved Site ID
     */
    async saveSite() {
      if (this.saving) return

      if (!this.validateSite()) return

      this.saving = true

      try {
        const formattedPairs = {}
        this.pairs.forEach(item => {
          formattedPairs[item.key] = item.value
        })
        this.site.attrs = formattedPairs
        if (!this.site.id) {
          const data = await this.$store.dispatch('sites/addSite', { site: this.site })
          this.closePopup(data)
        } else {
          await this.$store.dispatch('sites/updateSite', {
            site: { ...this.site, logo: this.site.logo ? this.site.logo.replace('https://admin.codexcdn.net/', '') : this.site.logo },
          })
          this.closePopup(this.site)
        }
      } catch (error) {
        console.log(error)
      } finally {
        this.$root.$emit('refreshUserSites')
        this.saving = false
      }
    },
    async uploadImage(event) {
      try {
        this.uploading = true
        const file = event.target.files[0]
        if (file && this.validateFileSize(file)) {
          await uploadGeneralAsset(file)
            .then(response => {
              this.$set(this.site, 'logo', response.data.path)
            })
            .catch(error => {
              console.log(error)
            })
        }
      } catch (e) {
        console.log(e)
      } finally {
        this.uploading = false
      }
    },
    validateFileSize(file) {
      if (file.size > MAX_FILE_SIZE) {
        // eslint-disable-next-line no-alert
        alert(this.$t('site.add-site.filesize-alert', { size: `${MAX_FILE_SIZE / 1024 ** 2}MB` }))
        return false
      }
      return true
    },
    handleCancel() {
      this.showPopup = false
      this.site = siteDefaults()
    },
  },
}
</script>

<style lang="scss">

.site-popup__field {
  > div {
    position: relative;

    .site-popup__field-copy {
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
    }
  }
}

.edit-site__upload-field {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #1D417B;
  display: block;
  margin-top: 32px;
}

.edit-site__logo-description {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #667C99;
  display: block;
}

.site-popup__repeater-add {
  cursor: pointer;
  color: #1D79F2;
}

.site-popup__repeater-item {
  display: flex;
  gap: 8px;

  .gj-icon {
    height: 42px;
    cursor: pointer;
  }

  .form-group {
    flex: 1;
  }
}

.site-popup__repeater-title {
  margin-bottom: 0 !important;
}

.default-folder-label {
  display: flex;
  align-items: center;
  gap: 5px;
}
</style>
