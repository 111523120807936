import ConditionParameters from './ConditionParameters'

export default class ConditionBase {
  constructor({
    id = null,
    index = 0,
    operator = 0,
    parameters = [],
  } = {}) {
    this.id = id
    this.operator = operator
    this.index = index
    this.parameters = this.generateParameters(parameters)
    this.status = 1
  }

  generateParameters(parameters) {
    if (parameters.length === 0) {
      return []
    }
    return parameters.map(parameter => new ConditionParameters(parameter))
  }
}
