/* eslint-disable no-unused-vars */
/* eslint-disable global-require */

import CodexContentEditor from '@/components/codex-editor/CodexContentEditor'
import CodexLayoutEditor from '@/components/codex-layout-editor/CodexLayoutEditor'
import router from '@/router'
import store from '@/store'
import { cloneDeep } from 'lodash'
import { apiAxios as axios } from '../libs/axios'
import Vue from 'vue'
// import ability from '@/libs/acl/ability'

/* eslint-disable import/no-dynamic-require */
const plugins = {
  // 'cmi-france': {
  //   'cmi-france': '1.0.0',
  //   'cmi-france-codex-image-plugin': '1.0.0',
  // },
  // 'cmi-france-stg': {
  //   'cmi-france': '1.0.0',
  //   'cmi-france-codex-image-plugin': '1.0.0',
  // },
  // 'cmifrance-stg': {
  //   'cmi-france': '1.0.0',
  //   'cmi-france-codex-image-plugin': '1.0.0',
  // },
  'gjirafa-dev': { // Gjirafa organization
    'sport': '1.0.0',
    // 'cmi-france': '1.0.0',
    // 'cmi-france-codex-image-plugin': '1.0.0',
  },
  // 'codex-dev': { // Gjirafa organization
  //   'sport': '1.0.0',
  //   'cmi-france': '1.0.0',
  //   'cmi-france-codex-image-plugin': '1.0.0',
  // },
  // 'codex-test': { // Gjirafa organization
  //   'sport': '1.0.0',
  //   'cmi-france': '1.0.0',
  //   'cmi-france-codex-image-plugin': '1.0.0',
  // },
  'codex-stg': { // Gjirafa organization
    'sport': '1.0.0',
    // 'cmi-france': '1.0.0',
    // 'cmi-france-codex-image-plugin': '1.0.0',
  },
  si601336da5867442d9235: { // Zeny
    // 'weather-plugin': '2.0.0',
    // 'video-games-plugin': '1.0.0',
  },
}

const context = require.context('./', true, /\.json|\.js|\.vue|\.svg|\.png$/, 'lazy')
const loadedPlugins = []
// let loadedSiteId = null
export const routePluginsState = {
  addedRoutesCount: 0,
};

class CodexPlugin {
  manifest = null;

  name = '';

  version = '';

  isExternal = false;
  constructor(name, version, isExternal = false) {
    this.name = name
    this.version = version,
    this.isExternal = isExternal
  }

  getPath(path = '') {
    if(this.isExternal) {
      var completePath = `./external/${this.name}/${this.version}${path ? `/${path}` : ''}`
      console.log(`Loading: ${completePath}`)
      return completePath
    }
    else {
      var completePath = `./${this.name}/${this.version}${path ? `/${path}` : ''}`
      console.log(`Loading: ${completePath}`)
      return completePath
    }
  }



  async loadFile(file, failReturn = {default: null}) {
    try {
      let result = await context(this.getPath(file))
      return result;
    } catch(e) {
      console.log('Error loading file', this.getPath(file), e);
      return failReturn;
    }
  }

  /**
   * Functions for loading a plugin
   */
  async load() {
    try {
      this.manifest = await this.loadFile('manifest.json')
      await this.loadWidgets()
      await this.loadFields()
      await this.loadRoutes()
      await this.loadNavigationItems();
      await this.loadEntrySidebar();

      return this.manifest;
    } catch(e) {
      console.log('Error loading plugin', this.name, this.version, e);
    }
  }

  /**
   * Functions for unloading a plugin
   */
  async unload() {
    this.unloadWidgets()
    this.unloadFields()
    this.unloadNavigationItems()
  }

  /**
   * Entry sidebar
   */
  async loadEntrySidebar() {
    if (!this.manifest.entrySidebar || !this.manifest.entrySidebar.length) return
    await Promise.all(this.manifest.entrySidebar.map(async (sidebar) => await this.loadEntrySidebarItem(sidebar)))
  }

  async loadEntrySidebarItem(sidebar) {
    try {
      let sidebarData = {
        ...sidebar,
        ...(sidebar.component ? { component: this.pluginHOC((await this.loadFile(sidebar.component)).default) } : {}),
        ...(sidebar.name ? { name: `${this.manifest.plugin_name}-${sidebar.name}` } : {}),
        plugin: this.manifest.plugin_name,
      };

      store.dispatch('plugins/addEntrySidebarItem', sidebarData)
    } catch(e) {
      console.log('Error loading entry sidebar', this.name, this.version, sidebar, e);
    }
  }

  /**
   * Navigation
   */
  async loadNavigationItems() {
    if (!this.manifest.navigation || !this.manifest.navigation.length) return
    this.manifest.navigation.forEach(navItem => this.loadNavigationItem(cloneDeep(navItem)))
  }

  async loadNavigationItem(navItem) {
    this.transformNavigagtionItem(navItem)

    navItem.plugin = this.manifest.plugin_name
    navItem.order = 10
    store.dispatch('general/addNavigationItem', navItem)
  }

  async transformNavigagtionItem(navItem) {
    navItem.name = `${this.manifest.plugin_name}-${navItem.name || navItem.route}`

    const route = this.findRouteByNavigationItem(navItem)
    if( route && route?.meta?.siteRequired ) {
      navItem.name += `-{siteId}`
    }

    if( navItem?.children?.length ) {
      await Promise.all( navItem.children.map(child => this.transformNavigagtionItem(child)) );
    }
  }


  findRouteByNavigationItem(navItem) {
    return this.manifest.routes.find(route => {
      let routeName = `${this.manifest.plugin_name}-${route.name}`
      return routeName === navItem.route
    })
  }

  async unloadNavigationItems() {
    if (!this.manifest.navigation || !this.manifest.navigation.length) return
    this.manifest.navigation.forEach(navItem => this.unloadNavigationItem(navItem))
  }

  async unloadNavigationItem(navItem) {
    const navItemName = `${this.manifest.plugin_name}-${navItem.name || navItem.route}`
    store.dispatch('general/removeNavigationItem', {item: navItemName, removeBy: 'name'})
  }

  /**
   * Routes
   */
  async loadRoutes() {
    if (!this.manifest.routes || !this.manifest.routes.length) return
    await Promise.all( this.manifest.routes.map(route => this.loadRoute(route)) );
    this.maybeRedirectToLoadedRoute();
  }

  async loadRoute(route) {
    try {
      let routePath = `/:orgName/plugins/${this.manifest.plugin_name}/${route.path}`
      let routeNavActiveLink = route?.meta?.navActiveLink ? `name:${this.manifest.plugin_name}-${route?.meta?.navActiveLink}` : `name:${this.manifest.plugin_name}-${route.name}`

      if( route?.meta?.siteRequired ) {
        routePath = `/:orgName/:siteName/plugins/${this.manifest.plugin_name}/${route.path}`
        routeNavActiveLink = `${routeNavActiveLink}-{siteName}`
      }

      router.addRoute({
        ...route,
        path: routePath,
        component: route.component ? this.pluginHOC((await this.loadFile(route.component)).default) : null,
        name: `${this.manifest.plugin_name}-${route.name}`,
        meta: {
          rule: 'editor',
          siteRequired: route?.meta?.siteRequired,
          organizationRequired: true,

          ...(route.meta || {}),

          navActiveLink: routeNavActiveLink,
        }
      })

      routePluginsState.addedRoutesCount++;
    } catch(e) {
      console.log('Error loading route', this.name, this.version, route, e);
    }
  }

  maybeRedirectToLoadedRoute() {
    const pluginRoute = router.getRoutes().filter(item => item.regex.test(router.currentRoute.path) && item.path !== '*' && item.name !== 'plugins' && item.name !== 'plugins-site')
    if (pluginRoute.length && router.currentRoute.name !== pluginRoute[0].name && pluginRoute[0].path.includes('plugins') ) {
      router.replace({ name: pluginRoute[0].name })
    }
  }

  /**
   * Fields
   */
  async loadFields() {
    if (!this.manifest.fields || !this.manifest.fields.length) return

    await Promise.all(this.manifest.fields.map(async (field) => await this.loadField(field)))
  }

  async loadField(field) {
    try {
      let fieldData = {
        ...field,
        ...(field.icon ? { icon: (await this.loadFile(field.icon, null)) } : {}),
        ...(field.render ? { render: this.pluginHOC((await this.loadFile(field.render)).default) } : {}),
        ...(field.renderEditor ? { renderEditor: this.pluginHOC((await this.loadFile(field.renderEditor)).default) } : {}),
        ...(await this.loadRenderEditorPanels(field)),
        // ...(await this.loadRenderEditorPanels(field, 'renderEditorInitialPanel')),
      };

      CodexLayoutEditor.registerField(this.manifest.plugin_name, fieldData, true)
    } catch(e) {
      console.log('Error loading field', this.name, this.version, field, e);
    }
  }

  /**
   * Wrap plugin files
   */
  pluginHOC(Component) {
    if( !Component ) return null

    const originalProps = Component.props || {};
    const pluginName = this.manifest.plugin_name;

    return Vue.component('plugin-hoc', {
      name: `${pluginName}__plugin-hoc`,
      render(h) {
        return h('div', { class: 'plugin-hoc', attrs: { 'data-plugin': pluginName } }, [
          h(Component, {
            props: {
              ...this.$props,
              // permissions: this.permissions,
              // userCan: (action) => this.permissions[action],
            },
            attrs: {
              ...this.$attrs,
            },
            on: { ...this.$listeners }
          })
        ])
      },
      computed: {
        // If we want to provide only plugin specific permissions
        permissions() {
          let permissions = {}
          ability.rules.filter(f => f.pluginName == pluginName).forEach(rule => {
            permissions[rule.action] = !rule.inverted
          });
          return permissions
        }
      },
      props: Array.isArray(originalProps) ? originalProps : [...Object.keys(originalProps)],
    });
  }

  /**
   * Widgets
   */
  async loadWidgets() {
    if (!this.manifest.widgets || !this.manifest.widgets.length) return
    await Promise.all(this.manifest.widgets.map(async (widget) => await this.loadWidget(widget)))
  }

  async loadWidget(widget) {
    try {
      let widgetData = {
        ...widget,
        ...(widget.image ? { image: (await this.loadFile(widget.image, null)) } : {}),
        ...(widget.createTiptapNode ? { createTiptapNode: (await this.loadFile(widget.createTiptapNode)).default } : {}),
        ...(widget.renderEditor ? { renderEditor: this.pluginHOC((await this.loadFile(widget.renderEditor)).default) } : {}),
        ...(widget.renderEditorBar ? { renderEditorBar: this.pluginHOC((await this.loadFile(widget.renderEditorBar)).default) } : {}),
        ...(await this.loadRenderEditorPanels(widget)),
      };

      // if(this.showWidgetOn(widget, 'layout')) {
      //   CodexLayoutEditor.registerWidget(this.manifest.plugin_name, widgetData)
      // }
      if(this.showWidgetOn(widget, 'content')) {
        CodexContentEditor.registerWidget(this.manifest.plugin_name, widgetData)
      }
    } catch(e) {
      console.log('Error loading widget', this.name, this.version, widget, e);
    }
  }

  showWidgetOn(widget, type) {
    return !widget.showOn || (widget.showOn.constructor === Array && widget.showOn.includes(type));
  }

  async loadRenderEditorPanels(widget, key = 'renderEditorPanel') {
    if (!widget[key]) return {}

    let r = {};

    if( widget[key].constructor == String ) {

      r[key] = this.pluginHOC((await this.loadFile(widget[key])).default)

    } else if( widget[key].constructor == Array ) {

      r[key] = await Promise.all(widget[key].map(async item => {
        let itemDup = {
          ...item,
          component: this.pluginHOC((await this.loadFile(item.component)).default)
        }
        return itemDup
      }))

    }
    return r
  }

  async unloadWidgets() {
    if (!this.manifest.widgets || !this.manifest.widgets.length) return
    this.manifest.widgets.forEach(widget => {
      if(this.showWidgetOn(widget, 'layout')) {
        CodexLayoutEditor.unregisterWidget(this.manifest.plugin_name, widget)
      }
      if(this.showWidgetOn(widget, 'content')) {
        CodexContentEditor.unregisterWidget(this.manifest.plugin_name, widget)
      }
    })
  }

  async unloadFields() {
    if (!this.manifest.fields || !this.manifest.fields.length) return
    this.manifest.fields.forEach(field => {
      CodexLayoutEditor.unregisterWidget(this.manifest.plugin_name, field)
    })
  }
}

export default async function loadSitePlugins(organization, installedPlugins = []) {
  await store.dispatch('general/removePluginsNavigationItems')
  // if( loadedSiteId == organization ) return;
  // loadedSiteId = organization;
  return new Promise(async (resolve, reject) => {

    routePluginsState.addedRoutesCount = 0;

    try {
      let loadPromises = [];

      // Unload plugins
      if (loadedPlugins.length > 0) {
        loadedPlugins.forEach(plugin => plugin.unload())
      }

      // Load must use plugins
      const mustUse = {}
      const loadedPluginsList = []

      if(process.env.VUE_APP_SESSION_ID){
        await Promise.all(context.keys().map(async key => {
          console.log(`playground plugin key: ${key}`)
          if (key.startsWith(`./playground/${process.env.VUE_APP_SESSION_ID}/`)) {
            const splitName = key.split('/')
            console.log(`./playground/${process.env.VUE_APP_SESSION_ID}/2`)
            if (splitName.length <= 2) return
            const mustUseKey = `${splitName[1]}-${splitName[2]}`
            if (mustUse[mustUseKey]) return

            const plugin = new CodexPlugin(splitName[1], splitName[2], false)
            mustUse[mustUseKey] = plugin
            console.log(`Loading plugins: ${splitName[1]} ${splitName[2]}`);

            var manifest = await plugin.load();
            if(manifest.plugin_name){
              loadedPluginsList.push(manifest.plugin_name);
            }
          }
        }))
      }

      // var installedPlugins = await loadInstalledPluginsForOrganization(organization)
      installedPlugins.forEach(async installedPlugin => {
        context.keys().forEach(async key => {
          if (key.includes(`./external/${installedPlugin.pluginName}/${installedPlugin.pluginVersion}`)) {
            const splitName = key.split('/')
            if (splitName.length <= 2) return

            if (loadedPluginsList.includes(splitName[2])) {
              console.log(`Skipping ${splitName[2]}, since it has been loaded for playground preview`)
              return
            }

            const mustUseKey = `${splitName[2]}-${splitName[3]}`
            if (mustUse[mustUseKey]) return
            mustUse[mustUseKey] = true
            const plugin = new CodexPlugin(splitName[2], splitName[3], true)
            loadPromises.push(plugin.load())
          }
        })
      });

      // Load site plugins
      const sitePlugins = plugins[organization]
      if (sitePlugins) {
        Object.keys(sitePlugins).forEach(name => {
          const version = sitePlugins[name]

          const plugin = new CodexPlugin(name, version)

          try {
            loadPromises.push( plugin.load() )
            loadedPlugins.push(plugin)
          } catch (e) {
            console.error('Error loading plugin', name, version, e)
          }

        })
      }

      await Promise.all(loadPromises);
      resolve();

    } catch(e) {
      console.log("Plugins load failed", e)
      reject();
    }

  })
}

async function loadInstalledPluginsForOrganization(organization) {
  if (organization) {
    return axios.get(`${process.env.VUE_APP_PLAYGROUND_API_BASE_URL}/api/plugin/installed/${organization}`, {
      headers: {
        "accept":"*/*",
        "Codex-Api-Key":`${process.env.VUE_APP_PLAYGROUND_API_KEY}`,
        "Content-Type":"application/json"
      },
    }).then(response => {
      return response.data
    })
  }
}

export function getPluginManifestByName(pluginName) {
  let plugin = loadedPlugins.find(plugin => plugin?.manifest?.plugin_name === pluginName)
  if (plugin) return plugin.manifest
}

