<template>
  <div v-if="appearance === BOOLEAN_FIELD_APPEARANCES.RADIO">
    <FieldConditions
      v-model="validation.required"
      :widget="widget"
      :label="$t('fields.general.required.label')"
      :tooltip="$t('fields.general.required.info')"
      :error-message-label="$t('fields.general.required.error-message')"
    />

    <FieldConditions
      v-model="validation.readOnly"
      :widget="widget"
      :label="$t('fields.general.read-only.label')"
      :show-error-message="false"
    />
  </div>
</template>

<script>
import { generateComputedPropsFromAttrs } from '@/components/codex-layout-editor/BuilderUtils'
import FieldConditions from '@/components/fields/FieldConditions.vue'
import BaseFieldMixin from '@/components/fields/BaseFieldMixin'
import { BOOLEAN_FIELD_APPEARANCES } from '@/views/models/constants'

export default {
  components: { FieldConditions },
  mixins: [BaseFieldMixin],
  data() {
    return {
      BOOLEAN_FIELD_APPEARANCES,
    }
  },
  computed: {
    ...generateComputedPropsFromAttrs([
      'validation',
      'appearance',
    ]),
  },
}
</script>
