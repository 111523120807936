<template>
  <div>
    <div
      class="sections-select__item"
      :class="{
        'sections-select__item--disabled': disabledItems.find(item => item === items.id),
        'sections-select__item--selected': isSelected(items),
        'sections-select__item--no-child': items.children && !search ? items.children.items.length === 0 : false,
        'sections-select__item--has-child': items.children && !search ? items.children.items.length > 0 : true ,
        'sections-select__item--checkbox': checkbox,
        [`sections-select__item-depth-${depth}`]: true,
      }"
      @click="!isDisabled && addOrRemoveSection(items)"
    >
      <b-checkbox
        v-if="checkbox"
        :disabled="isDisabled"
        :checked="isSelected(items)"
      />
      <GjIcon
        v-if="items.children && items.children.items.length && !search"
        :key="isOpen ? 'ArrowUp' : 'ArrowDown'"
        :name="isOpen ? 'ArrowUp' : 'ArrowDown'"
        size="24px"
        @click.native.stop="isOpen = !isOpen"
      />
      {{ items.title }}
      <GjIcon
        v-if="isSelected(items) && !checkbox"
        class="sections-select__checkmark"
        name="Check"
        @click.native.stop=""
      />
    </div>
    <template v-if="items.children && !search">
      <div
        v-for="item in items.children.items"
        :key="item.id"
        class="recursive-section"
      >
        <div
          class="recursive-section__children"
          :class="{'ml-1': !checkbox}"
        >
          <Recursive-section
            v-if="isOpen"
            :depth="depth + 1"
            :checkbox="checkbox"
            :items="item"
            :include-children="includeChildren"
            :disabled-items="disabledItems"
            :parent-checked="isSelected(items)"
          />
        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'RecursiveSection',
  inject: ['addOrRemoveSection', 'sections'],
  props: {
    depth: {
      type: Number,
      required: false,
      default: 0,
    },
    checkbox: {
      type: Boolean,
      required: false,
      default: false,
    },
    items: {
      type: [Object, Array],
      required: true,
    },
    disabledItems: {
      type: Array,
      required: false,
      default: () => [],
    },
    search: {
      type: [String, Boolean],
      required: false,
      default: '',
    },
    includeChildren: {
      type: Boolean,
      default: false,
    },
    parentChecked: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isOpen: false,
    }
  },
  computed: {
    isDisabled() {
      return this.includeChildren && this.parentChecked
    },
  },
  methods: {
    isSelected(item) {
      return this.sections().some(section => section.id === item.id || section === item.id) || this.isDisabled
    },
  },
}
</script>

<style lang="scss">
.recursive-section {
}

.sections-select__item--disabled {
  color: lightgray;
  cursor: default;
}
.sections-select__item--checkbox {
 position: relative;
  padding-left: 24px;

   &.sections-select__item--selected {
    background-color: transparent !important;
   }
  .custom-checkbox {
    pointer-events: none;
    position: absolute;
    left: 4px;
    top: 50%;
    transform: translateY(-50%);
    padding: 0 !important;
    height: 16px;
    min-height: 0;

  }

  &.sections-select__item-depth-0 {
    padding-left: 28px;
  }
  &.sections-select__item-depth-1 {
    padding-left: #{28 + (20 * 1)}px !important;
  }
  &.sections-select__item-depth-2 {
    padding-left: #{28 + (20 * 2)}px !important;
  }
  &.sections-select__item-depth-3 {
    padding-left: #{28 + (20 * 3)}px !important;
  }
  &.sections-select__item-depth-4 {
    padding-left: #{28 + (20 * 4)}px !important;
  }
  &.sections-select__item-depth-5 {
    padding-left: #{28 + (20 * 5)}px !important;
  }
  &.sections-select__item-depth-6 {
    padding-left: #{28 + (20 * 6)}px !important;
  }
  &.sections-select__item-depth-7 {
    padding-left: #{28 + (20 * 7)}px !important;
  }
  &.sections-select__item-depth-8 {
    padding-left: #{28 + (20 * 8)}px !important;
  }
  &.sections-select__item-depth-9 {
    padding-left: #{28 + (20 * 9)}px !important;
  }
  &.sections-select__item-depth-10 {
    padding-left: #{28 + (20 * 10)}px !important;
  }
}
</style>
