<script>
export default {
  name: 'NoPermission',
  computed: {
    title() {
      /* eslint-disable quote-props */
      const pages = {
        'jobs': this.$t('no-permissions.page-titles.jobs'),
        'jobs-details': this.$t('no-permissions.page-titles.job'),
        'authors': this.$t('no-permissions.page-titles.authors'),
        'authors-edit': this.$t('no-permissions.page-titles.author'),
        'lists-static': this.$t('no-permissions.page-titles.lists'),
        'lists-static-edit': this.$t('no-permissions.page-titles.list'),
        'lists-dynamic': this.$t('no-permissions.page-titles.lists'),
        'lists-dynamic-edit': this.$t('no-permissions.page-titles.list'),
        'models': this.$t('no-permissions.page-titles.models'),
        'models-edit': this.$t('no-permissions.page-titles.model'),
        'teams': this.$t('no-permissions.page-titles.teams'),
        'teams-edit': this.$t('no-permissions.page-titles.team'),
        'webhooks': this.$t('no-permissions.page-titles.webhooks'),
        'webhooks-edit': this.$t('no-permissions.page-titles.webhook'),
        'all-integrations': this.$t('no-permissions.page-titles.integrations'),
        'api-keys': this.$t('no-permissions.page-titles.api-keys'),
        'dashboard-users': this.$t('no-permissions.page-titles.users'),
        'sections': this.$t('no-permissions.page-titles.sections'),
        'labels': this.$t('no-permissions.page-titles.labels'),
        'tags': this.$t('no-permissions.page-titles.tags'),
        'sites': this.$t('no-permissions.page-titles.sites'),
        'home': this.$t('no-permissions.page-titles.home'),
        'urls': this.$t('no-permissions.page-titles.urls'),
        'entries': this.$t('no-permissions.page-titles.entries'),
        'presets': this.$t('no-permissions.page-titles.presets'),
        'localizations': this.$t('no-permissions.page-titles.localizations'),
        'edit-localizations': this.$t('no-permissions.page-titles.localizations'),
      }

      return pages[this.$route.name] || this.$t('no-permissions.page-titles.default')
    },
  },
}
</script>

<template>
  <div class="no-permission">
    <div>
      <img
        src="@/assets/icons/lock.svg"
        alt=""
      >
    </div>
    <div>
      <h2 class="no-permission__title">
        {{ title }}
      </h2>
      <p class="no-permission__subtitle">
        {{ $t('no-permissions.description') }}
      </p>
      <p class="no-permission__subsubtile">
        {{ $t('no-permissions.contact-admin') }}
      </p>
    </div>
  </div>
</template>

<style lang="scss">
.no-permission {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  //justify-content: center;
  position: fixed;
  inset: 0;
  top: 200px;
  * {
    margin: 0;
    padding: 0;
  }
}
.no-permission__title {
  color:  #052D61;
  font-size: 18px;
  font-weight: 500;
  line-height: 21px;
  margin-top: 24px;
}
.no-permission__subtitle {
  color:  #052D61;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  margin-top: 6px;
  margin-bottom: 32px;
}
.no-permission__subsubtile {
  color:  #052D61;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
}
</style>
