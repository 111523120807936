import { readUsers } from '../../codex-sdk/site-users'

export default {
  // eslint-disable-next-line no-unused-vars
  fetchUsers({ commit }, { ...filtersAndPagination } = {}) {
    return new Promise((resolve, reject) => {
      readUsers(filtersAndPagination)
        .then(response => {
          const { data: { data, ...pagination } } = response
          commit('SET_USERS_PAGINATION', pagination)
          commit('SET_USERS', data)
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },

}
