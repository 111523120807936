import {
  createModel, readModels, updateModel, deleteModel, deleteModels,
} from '../../codex-sdk/models'

export default {
  addModel({ commit }, { model, message }) {
    return new Promise((resolve, reject) => {
      createModel(model, message)
        .then(response => {
          commit('ADD_MODEL', Object.assign(model, { id: response.data.id }))
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  fetchModels({ commit }, { ...filtersAndPagination }) {
    commit('CLEAR_MODELS_STATE')
    return new Promise((resolve, reject) => {
      readModels(filtersAndPagination)
        .then(response => {
          const { data: { data, ...pagination } } = response
          commit('SET_MODELS_PAGINATION', pagination)
          commit('SET_MODELS', data)
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  updateModel({ commit }, { model, message }) {
    return new Promise((resolve, reject) => {
      updateModel(model, message)
        .then(response => {
          commit('UPDATE_MODEL', response.data)
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  removeModel({ commit }, { model, message }) {
    return new Promise((resolve, reject) => {
      deleteModel(model, message)
        .then(response => {
          commit('REMOVE_MODEL', model)
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  removeModels({ commit }, { models, message }) {
    return new Promise((resolve, reject) => {
      deleteModels(models, message)
        .then(response => {
          commit('REMOVE_MODELS', models)
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
}
