import { apiAxios as axios } from '../libs/axios'
import { tc } from './config'

export const VIEW_TYPE = Object.freeze({
  PRIVATE: 'PRIVATE',
  SHARED: 'SHARED',
})

export const VIEW_TYPE_MAPPING = Object.freeze({
  1: VIEW_TYPE.PRIVATE,
  2: VIEW_TYPE.SHARED,
})

export function entriesViewDefaults(entriesView = {}) {
  return {
    externalId: null,
    name: '',
    searchTerm: '',
    path: '',
    filters: null,
    type: VIEW_TYPE.PRIVATE,
    teams: [],
    customizations: {
      columns: [],
      order: '',
    },
    ...entriesView,
  }
}

export function transformEntriesViewFromRequest(entriesView) {
  entriesView.type = VIEW_TYPE_MAPPING[entriesView.type]
  return entriesView
}

/**
 * CRUD Operations
 * */

/**
 *
 * @param {*} entriesView
 *
 * @response
 * {
 *  id: GUID
 *  slug: String
 *  errors: null | Object
 *  success: true | false
 * }
 */
export function createEntriesView(entriesView, messages) {
  return tc(axios.post('/{organization}/views', entriesView), {
    successTitle: 'sdk.success-title',
    successMessage: 'sdk.entries-view.create-successful',
    failTitle: 'sdk.entries-view.create-failed',
    ...messages,
  }).then(response => {
    response.data = transformEntriesViewFromRequest(response.data)
    return response
  })
}

/**
 *
 * @param {*} id
 *
 * @response
 * {
 *  id: GUID
 *  blocks: []
 *  siteId: null
 *  slug: String
 *  status: 1 | 2
 *  title: String
 * }
 */
export function readEntriesView(id) {
  return axios.get(`/{organization}/views/${id}`).then(response => {
    response.data = transformEntriesViewFromRequest(response.data)
    return response
  })
}

/**
 *
 * @param {*} entriesView
 *
 * @response
 * {
 *  id: GUID
 *  errors: null | Object
 *  success: true | false
 * }
 */
export function updateEntriesView(entriesView, messages = {}) {
  return tc(axios.put(`/{organization}/views/${entriesView.id}`, entriesView), {
    successTitle: 'sdk.success-title',
    successMessage: 'sdk.entries-view.update-successful',
    failTitle: 'sdk.entries-view.update-failed',
    ...messages,
  }).then(response => {
    response.data = transformEntriesViewFromRequest(response.data)
    return response
  })
}

/**
 *
 * @param {*} id
 *
 * @response
 * {
 *  errors: null | Object
 *  success: true | false
 * }
 */
export function deleteEntriesView(id, messages = {}) {
  return tc(axios.delete(`/{organization}/views/${id}`), {
    successTitle: 'sdk.success-title',
    successMessage: 'sdk.entries-view.delete-successful',
    failTitle: 'sdk.entries-view.delete-failed',
    ...messages,
  })
}
