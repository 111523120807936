import { getUserData } from '@/auth/utils'
import ability from '@/libs/acl/ability'
import { pr } from './config'

// * Create entity file, and can functions
// * Import and add them to allCanFunctions object down below
// * Add defaults into the permissions array down below
// * Implement v-permission directives on necessary buttons
// * Implement permissions on entities sdk functions
// * Implement permissions on navigation and route - Everyone has access to view media

export const Urls = Object.freeze({
  VIEW: 'url_view',
  CREATE: 'url_create',
  MANAGE: 'url_manage',
})

export const UrlTooltips = {
  [Urls.VIEW]: 'Allows a user to view all URLs in ‘view only’ mode, without performing any operations',
  [Urls.CREATE]: 'Allows a user to create URLs and edit URLs created by that user',
  [Urls.MANAGE]: 'Allows users to perform all CRUD operations',
}

function isCurrentUser(entity) {
  const userId = entity?.createdBy?.id || entity?.createdBy

  const currentUserData = getUserData()
  if (!currentUserData) return false
  return userId == currentUserData.id
}

/**
 * Can VIEW Urls
 * */
export function canViewUrl(_options = {}) {
  const { entity, ...options } = _options
  return pr(
    ability.can(Urls.VIEW) || ability.can(Urls.CREATE) || ability.can(Urls.MANAGE),
    'You have no permission to view urls',
    options,
  )
}

/**
 * Can CREATE Urls
 * */
export function canCreateUrl(_options = {}) {
  const { entity, ...options } = _options
  return pr(
    ability.can(Urls.MANAGE) || ability.can(Urls.CREATE),
    'You have no permission to create url',
    options,
  )
}

/**
 * Can EDIT Url
 * */
export function canEditUrl(_options = {}) {
  const { entity, ...options } = _options
  return pr(
    ability.can(Urls.MANAGE) || (ability.can(Urls.CREATE) && isCurrentUser(entity)),
    'You have no permission to edit url',
    options,
  )
}
