<template>
  <div class="layout-editor-panel">
    <!-- <label class="layout-editor-panel__label">
      {{ widget.type }}
    </label> -->

    <div class="layout-editor-panel__left">
      <a
        class="layout-editor-panel__button always-show layout-editor-panel__button-name"
        @click.stop="selectBlock"
      >
        {{ widgetObject.displayName }}
      </a>
    </div>

    <div class="layout-editor-panel__right">
      <a
        class="layout-editor-panel__button"
        @click.stop="selectBlock"
      >
        <GjIcon
          name="Layout"
          size="16"
        />
      </a>

      <!-- Widget Setting: Hide delete setting -->
      <a
        v-if="allowDelete"
        class="layout-editor-panel__button if-selected"
        @click.stop="removeBlock"
      >
        <GjIcon
          name="Delete"
          size="16"
        />
      </a>

      <!-- Widget Setting: Hide duplicate setting -->
      <a
        v-if="allowDuplicate"
        class="layout-editor-panel__button if-selected"
        @click.stop="duplicateBlock"
      >
        <GjIcon
          name="Copy"
          size="16"
        />
      </a>

      <a
        v-if="hasPanel"
        class="layout-editor-panel__button if-selected"
        @click.stop="showAttributesPanel"
      >
        <GjIcon
          name="Settings"
          size="16"
        />
      </a>

      <!-- Widget Setting: Hide drag setting -->
      <a
        v-if="allowDrag"
        class="handle layout-editor-panel__button if-selected"
        @click.stop
      >
        <GjIcon
          name="MenuAlt"
          size="16"
        />
      </a>
    </div>
  </div>
</template>

<script>
import CodexLayoutEditor from '@/components/codex-layout-editor/CodexLayoutEditor'

export default {
  props: {
    widget: {
      required: true,
      type: Object,
    },
  },
  inject: ['editor', 'checkForFieldUsage'],
  computed: {
    allowDelete() {
      return !(this.widgetObject?.settings?.delete === false)
    },
    allowDuplicate() {
      return this.editor.allowDuplicate && !(this.widgetObject?.settings?.duplicate === false)
    },
    allowDrag() {
      return !(this.widgetObject?.settings?.drag === false)
    },
    widgetObject() {
      return CodexLayoutEditor.getWidgetObjectByType(this.widget.type)
    },
    widgetName() {
      return this.widgetObject.displayName
    },
    hasPanel() {
      return this.widgetObject.widgetSidebar
    },
  },
  methods: {
    selectBlock() {
      this.editor.selectBlock(this.widget.id)
    },
    duplicateBlock() {
      this.editor.duplicateBlock(this.widget.id)
    },
    async removeBlock() {
      const value = await this.checkForFieldUsage(this.widget.id)
      if (!value) return
      this.editor.removeBlock(this.widget.id)
      this.editor.setMetadata('sidebarBlockId', null)
    },
    showAttributesPanel() {
      this.editor.setMetadata('sidebarBlockId', this.widget.id)
    },
  },
}
</script>

<style>

</style>
