<template>
  <div
    v-if="showDropdown && showSuggestions && componentsDropdownPosition && show"
    ref="suggestions"
    class="suggestion-list"
    :class="{'suggestion-list--inverted': componentsDropdownPosition.inverted}"
    :style="{top: componentsDropdownPosition.top, left: componentsDropdownPosition.left}"
  >
    <div
      ref="scrollEl"
      class="suggestion-list__scroll "
    >
      <template v-if="hasResults">
        <template v-for="(component, index) in filteredComponents">
          <div
            v-if="index === 0 || filteredComponents[index - 1].group !== component.group"
            :key="component.id"
            class="suggestion-list__group"
          >
            {{ $t(mapBlockGroups(component.group)) }}
          </div>
          <div
            :key="index"
            class="suggestion-list__item"
            :class="{ 'is-selected': navigatedComponentsIndex === index }"
            @click="selectComponent(component)"
          >
            <div class="suggestion-list__item-image">
              <img
                v-if="component.image"
                :src="component.image"
              >
            </div>
            <div class="suggestion-list__item-content">
              <div
                class="suggestion-list__item-name"
                :title="component.group !== 'content' ? $t(component.name) : component.name"
              >
                {{ component.group !== 'content' ? $t(component.name) : component.name }}
              </div>
            </div>
          </div>
        </template>
      </template>
      <div
        v-else
        class="suggestion-list__empty"
      >
        {{ $t('codex-editor.plugins.components-dropdown.no-components-found') }}
      </div>
    </div>
  </div>
</template>

<script>
import { mergeWith } from 'lodash'
import { BLOCK_GROUPS, mapBlockGroups } from '@/components/codex-editor/nodes/constants'
import { ASSET_TYPE_MAPPING } from '@/codex-sdk/assets'
import { CODEX_EDITOR_BLOCKS } from '@/components/codex-editor/CodeEditorConstants'
import CodexContentEditor from '../CodexContentEditor'
import ComponentsDropdown from './ComponentsDropdown'

export default {
  name: 'EditorComponentsDropdown',
  // eslint-disable-next-line vue/require-prop-types
  props: ['editor', 'show', 'includeBlocks', 'includeModels', 'selectedNodes'],
  inject: ['registerEditorExtension'],
  data() {
    return {
      componentsDropdownExtension: null,
      mapBlockGroups,
      showDropdown: false,
      query: null,
      suggestionRange: null,
      filteredComponents: [],
      navigatedComponentsIndex: 0,
      componentsDropdownPosition: null,
      insertComponent: () => {
      },
      componentsGroupOrder: [
        BLOCK_GROUPS.MOST_USED,
        BLOCK_GROUPS.TEXTUAL,
        BLOCK_GROUPS.MEDIA,
        BLOCK_GROUPS.EMBEDS,
        BLOCK_GROUPS.NEWSROOM,
        BLOCK_GROUPS.OTHERS,
        BLOCK_GROUPS.CONTENT,
      ],
      theNode: null,
      loading: false,

    }
  },
  computed: {
    hasResults() {
      return this.filteredComponents.length
    },
    showSuggestions() {
      return this.query || this.hasResults
    },
  },
  beforeDestroy() {
    this.destroyPopup()
    window.removeEventListener('keydown', this.keyDown)
    window.removeEventListener('resize', this.repositionDropdown)
    window.removeEventListener('scroll', this.repositionDropdown)
    window.removeEventListener('click', this.onClick)
  },
  mounted() {
    window.addEventListener('keydown', this.keyDown)
    window.addEventListener('resize', this.repositionDropdown)
    window.addEventListener('scroll', this.repositionDropdown)
    window.addEventListener('click', this.onClick)

    window.CodexContentEditor = CodexContentEditor

    this.componentsDropdownExtension = new ComponentsDropdown({
      // a list of all suggested items
      items: async () => {
        const blocks = [
          ...CodexContentEditor.getRegisteredWidgets().filter(i => !i._internal).flatMap(({
            name,
            displayName,
            image,
            type,
            group,
            keywords,
            allowedContent,
            variants,
          }) => {
            if (type === CODEX_EDITOR_BLOCKS.HEADING) {
              const headings = Array.from({ length: 6 }, (_, i) => ({
                name: `${displayName}-${i + 1}`,
                type: CODEX_EDITOR_BLOCKS.HEADING,
                keywords: keywords || [],
                allowedContent: allowedContent || [],
                image,
                // i === 1 so it will be the Heading 2(H2) in most used
                group: i == 1 ? group : group[0] || group,
                attrs: {
                  level: i + 1,
                },
              }))
              return headings
            }

            const items = [{
              name: displayName,
              type: type || name,
              keywords: type === CODEX_EDITOR_BLOCKS.REFERENCE ? [...keywords, ...this.$store.state.general.models.map(m => m.name)] : keywords || [],
              allowedContent: allowedContent || [],
              image,
              group,
              attrs: {},
            }]

            if (variants) {
              variants.forEach(variant => {
                items.push(mergeWith(
                  {},
                  items[0],
                  variant,
                  (objValue, srcValue) => {
                    if (Array.isArray(objValue) && Array.isArray(srcValue)) {
                      return srcValue
                    }
                    return undefined
                  },
                ))
              })
            }

            return items
          }),
          ...this.getModels(),
        ]

        let filteredBlocks = []
        const mediaTypes = []
        if (this.includeBlocks?.length || this.includeModels?.length) {
          filteredBlocks = blocks.filter(b => {
            if (b.type === CODEX_EDITOR_BLOCKS.MEDIA) {
              if (this.includeBlocks.includes(CODEX_EDITOR_BLOCKS[`MEDIA_${ASSET_TYPE_MAPPING.toString(b.attrs.type)}`]) && b?.attrs?.type) {
                mediaTypes.push(b)
              }
              return this.includeBlocks.includes(b.type) && (this.includeBlocks.includes(CODEX_EDITOR_BLOCKS[`MEDIA_${ASSET_TYPE_MAPPING.toString(b.attrs.type)}`]) || !b?.attrs?.type)
            }
            if (b.type === CODEX_EDITOR_BLOCKS.HEADING) {
              return this.includeBlocks.includes(b.type) && this.includeBlocks.includes(CODEX_EDITOR_BLOCKS[`HEADING_${b.attrs.level}`])
            }
            if (b.type === CODEX_EDITOR_BLOCKS.REFERENCE || b.type === CODEX_EDITOR_BLOCKS.REFERENCE_INLINE) {
              return this.includeBlocks.includes(b.type) && this.includeModels?.length > 0
            }
            return this.includeBlocks.includes(b.type) || this.includeModels.includes(b.attrs.modelAlias)
          })
        } else {
          filteredBlocks = blocks
        }

        const codexMediaBlock = filteredBlocks.find(b => b.type === CODEX_EDITOR_BLOCKS.MEDIA)
        if (codexMediaBlock) {
          codexMediaBlock.attrs.type = mediaTypes.map(m => m.attrs.type)
        }

        filteredBlocks = filteredBlocks.flatMap(b => {
          if (b.group.constructor === Array) {
            const { group, ...rest } = b
            return group.map(g => ({ ...rest, group: g }))
          }
          return b
        })

        // check allowed content for current node
        const node = this.selectedNodes.length > 0 && blocks.find(b => b.type === this.selectedNodes[0].node.type.name)
        if (node && node.allowedContent.length > 0) {
          filteredBlocks = blocks.filter(b => node.allowedContent.includes(b.type))
        }

        return filteredBlocks.sort((a, b) => this.componentsGroupOrder.indexOf(a.group) - this.componentsGroupOrder.indexOf(b.group))
      },
      // is called when a suggestion starts
      onEnter: ({
        items, query, range, command, virtualNode,
      }) => {
        if (!this.showDropdown || !this.show) return

        this.query = query
        this.filteredComponents = this.query.length ? items.filter(i => i.group !== BLOCK_GROUPS.MOST_USED) : items
        this.suggestionRange = range
        this.renderPopup(virtualNode)
        this.insertComponent = command
      },
      // is called when a suggestion has changed
      onChange: ({
        items, query, range, virtualNode,
      }) => {
        if (!this.showDropdown || !this.show) return

        this.query = query
        this.filteredComponents = this.query.length ? items.filter(i => i.group !== BLOCK_GROUPS.MOST_USED) : items
        this.suggestionRange = range
        this.navigatedComponentsIndex = 0
        this.renderPopup(virtualNode)
      },
      // is called when a suggestion is cancelled
      onExit: () => {
        // reset all saved values
        this.showDropdown = false

        this.query = null
        this.filteredComponents = []
        this.suggestionRange = null
        this.navigatedComponentsIndex = 0
        this.destroyPopup()
      },
      // is called on every keyDown event while a suggestion is active
      onKeyDown: ({ event }) => {
        if (!this.showDropdown || !this.show) return false

        if (event.key === 'ArrowUp') {
          this.upHandler()
          return true
        }
        if (event.key === 'ArrowDown') {
          this.downHandler()
          return true
        }
        if (event.key === 'Enter') {
          this.enterHandler()
          return true
        }
        return false
      },
      onFilter: async (items, query) => {
        if (!this.showDropdown || !this.show) return []

        if (!query) return items

        const queryNormalized = query.trim().toLowerCase()
        return items.filter(i => {
          let name = this.$t(i.name)
          if (name && name.constructor !== String) {
            name = i.name
          }
          return name.toLowerCase().includes(queryNormalized) || this.containsKeyword(i.keywords, queryNormalized)
        })
      },
    })
    this.registerEditorExtension(this.componentsDropdownExtension)
  },
  methods: {
    onClick(e) {
      if (this.$refs.suggestions && !this.$refs.suggestions.contains(e.target)) {
        this.componentsDropdownExtension.options.onExit()
      }
    },

    keyDown(e) {
      if (e.key === '/') {
        this.showDropdown = true
      }
    },

    repositionDropdown() {
      if (this.theNode) {
        this.renderPopup(this.theNode)
      }
    },
    upHandler() {
      this.navigatedComponentsIndex = ((this.navigatedComponentsIndex + this.filteredComponents.length) - 1) % this.filteredComponents.length
      this.checkScroll()
    },
    downHandler() {
      this.navigatedComponentsIndex = (this.navigatedComponentsIndex + 1) % this.filteredComponents.length
      this.checkScroll()
    },
    checkScroll() {
      const scrollElBox = this.$refs.scrollEl.getBoundingClientRect()
      const scrollElY = this.$refs.scrollEl.scrollTop
      const itemHeight = 60
      const itemY = itemHeight * this.navigatedComponentsIndex
      const offset = itemHeight * 2

      if (itemY - offset < scrollElY) {
        this.$refs.scrollEl.scrollTop = itemY - offset
      } else if (itemY + itemHeight + offset > scrollElBox.height + scrollElY) {
        this.$refs.scrollEl.scrollTop = itemY + itemHeight + offset - scrollElBox.height
      }
    },
    enterHandler() {
      const component = this.filteredComponents[this.navigatedComponentsIndex]
      if (component) this.selectComponent(component)
    },
    selectComponent(block) {
      if ([CODEX_EDITOR_BLOCKS.REFERENCE_INLINE, CODEX_EDITOR_BLOCKS.FACTBOX, 'codex_lock', 'cmi-france_exergue', 'cnc_lock', 'cnc_text_wrapper', CODEX_EDITOR_BLOCKS.BLOCKQUOTE, CODEX_EDITOR_BLOCKS.BULLET_LIST, CODEX_EDITOR_BLOCKS.ORDERED_LIST, CODEX_EDITOR_BLOCKS.TABLE, CODEX_EDITOR_BLOCKS.HEADING].indexOf(block.type) !== -1) {
        // remove query text and slash from editor
        const { from } = this.editor.selection
        this.editor.view.dispatch(this.editor.state.tr.delete(from - this.query.length - 1, from))

        if (block.type == CODEX_EDITOR_BLOCKS.TABLE) {
          this.editor.commands.createTable({ rowsCount: 3, colsCount: 3, withHeaderRow: false })
        } else if (block.type == CODEX_EDITOR_BLOCKS.HEADING) {
          this.editor.commands[block.type]({ level: block.attrs.level || 1 })
        } else if (this.editor.commands[block.type]) {
          this.editor.commands[block.type]()
        }
        this.editor.focus()
        return
      }

      this.insertComponent({
        range: this.suggestionRange,
        attrs: {
          _nodeContent: block.content,
          _nodeName: block.type,
          ...block.attrs,
        },
      })
      this.editor.focus()
    },
    renderPopup(node, heightReady = false) {
      if (!node) return
      this.theNode = node

      const editorDOM = this.editor.view.dom
      const editorRect = editorDOM.getBoundingClientRect()
      const rect = node.getBoundingClientRect()
      const wHeight = window.innerHeight
      const suggestions = this.$refs?.suggestions?.getBoundingClientRect()
      const maxHeight = 350
      const suggestionsHeight = suggestions?.height || maxHeight
      let top = rect.top - editorRect.top + 10
      let inverted = false

      const offset = rect.top + suggestionsHeight + 50 - wHeight
      if (offset > 0) {
        top -= maxHeight + 40
        inverted = true
      }

      const position = {
        top: `${top}px`,
        left: `${rect.left - editorRect.left}px`,
        inverted,
      }

      this.componentsDropdownPosition = position

      if (!heightReady) {
        setTimeout(() => {
          this.renderPopup(node, true)
        }, 10)
      }
    },
    destroyPopup() {
      this.componentsDropdownPosition = null
      this.theNode = null
    },
    containsKeyword(keywords, query) {
      return keywords.length > 0 && query.length > 0 && keywords.some(keyword => keyword.toLowerCase().includes(query))
    },
    getModels() {
      let models = []
      if (Array.isArray(this.includeModels) && this.includeModels.length > 0) {
        models = this.$store.state.general.models.filter(model => this.includeModels.includes(model.alias))
      }
      return models.map(c => ({
        name: c.name,
        group: BLOCK_GROUPS.CONTENT,
        type: CODEX_EDITOR_BLOCKS.REFERENCE,
        keywords: ['reference', c.name],
        description: c.description,
        // eslint-disable-next-line global-require
        image: require('../icons/models/icon.svg'),
        attrs: {
          modelAlias: c.alias,
        },
      }))
    },
  },
}
</script>

<style lang="scss">

@import "@core/scss/base/bootstrap-extended/include";
// Bootstrap includes
@import "@core/scss/base/components/include"; // Components includes

.suggestion-list {
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 10000;
  min-width: 250px;
  height: 350px;

  &.suggestion-list--inverted {
    justify-content: flex-end;
  }
}

.suggestion-list__empty {
  width: 100%;
  text-align: center;
  padding: 20px 5px;

  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #667C99;
}

.suggestion-list__group {
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  color: #667C99;
  padding: 12px 12px 2px 12px;
  line-height: 18px;
}

.suggestion-list__scroll {
  flex-shrink: 1;
  max-height: 350px;
  width: 100%;

  overflow: hidden !important;
  overflow-y: scroll !important;
  background-color: #ffffff;
  transform: translateY(1.25rem) translateX(20px);
  box-shadow: 0px 2px 24px rgba(44, 44, 44, 0.16);
  border-radius: 4px;

  &::-webkit-scrollbar-track {
    border-radius: 4px;
    background-color: #fff;
  }

  &::-webkit-scrollbar {
    width: 8px;
    background-color: #F5F5F5;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: #e8e8e8;
  }
}

.suggestion-list__item {
  display: flex;
  align-items: center;

  padding-left: 0.75rem;
  padding-right: 0.75rem;
  padding-top: 8px;
  padding-bottom: 8px;

  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #2C2C2C;
  cursor: pointer;

  &.is-selected, &:hover {
    background-color: #f8f8f8;

    * {
      color: $primary
    }
  }
}

.suggestion-list__item-image {
  padding-right: 12px;
}

.suggestion-list__item-name {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #667C99;
  width: 165px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.suggestion-list__item-description {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #667C99;
  margin-top: 4px;
  width: 165px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

</style>
