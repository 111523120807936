import ability from '@/libs/acl/ability'
import { pr } from './config'

// * Create entity file, and can functions
// * Import and add them to allCanFunctions object down below
// * Add defaults into the permissions array down below
// * Implement v-permission directives on neccessary buttons
// Implement permissions on entities sdk functions
// * Implement permissions on navigation and route - Everyone has access to view media

export const OrganizationSites = Object.freeze({
  VIEW: 'site_view',
  MANAGE: 'site_manage',
})

export const OrganizationSitesTooltips = {
  [OrganizationSites.VIEW]: 'Allows user to view the list of all sites of the organization in ‘view only’ mode',
  [OrganizationSites.MANAGE]: 'Allows user to create or remove a Site from the organization',
}

/**
 * Can VIEW Site
 * */

export function canViewSites(_options = {}) {
  const { entity, ...options } = _options
  return pr(
    ability.can(OrganizationSites.VIEW) || ability.can(OrganizationSites.MANAGE),
    'You have no permission to view sites',
    options,
  )
}

/**
 * Can VIEW Site Details
 * */

export function canViewSiteDetails(_options = {}) {
  const { entity, ...options } = _options
  return pr(
    ability.can(OrganizationSites.MANAGE),
    'You have no permission to view site details',
    options,
  )
}

/**
 * Can ADD new Site
 * */
export function canAddNewSite(_options = {}) {
  const { entity, ...options } = _options
  return pr(
    ability.can(OrganizationSites.MANAGE),
    'You don’t have permission to add a new Site',
    options,
  )
}

/**
 * Can EDIT new Site
 * */
export function canEditSite(_options = {}) {
  const { entity, ...options } = _options
  return pr(
    ability.can(OrganizationSites.MANAGE),
    'You don’t have permission to edit sites',
    options,
  )
}

/**
 * Can DELETE new Site
 * */
export function canDeleteSite(_options = {}) {
  const { entity, ...options } = _options
  return pr(
    ability.can(OrganizationSites.MANAGE),
    'You don’t have permission to delete sites',
    options,
  )
}
