<template>
  <div>
    <NoPermission v-if="!canNavigate($route)" />
    <div
      v-else
      class="urls__wrapper"
    >
      <full-height-layout class="urls__full-height">
        <div class="urls">
          <div class="article-listing__header">
            <div class="page-header">
              <div
                v-if="!isPopup"
                class="page-header__actions"
                style="display: flex; gap: 16px;"
              >
                <b-button
                  v-permission="['canCreateUrl']"
                  variant="primary"
                  size="sm"
                  @click="createUrl"
                >
                  <GjIcon name="Plus" />
                  <span class="ml-50">{{ $t('urls.create') }}</span>
                </b-button>

              </div>
              <div class="page-header__filters page-header__filters-filters--full-width">
                <div class="page-header__filters-group">
                  <!-- search -->
                  <div class="page-header__filters-search">
                    <b-form-input
                      v-model="searchTerm"
                      :debounce="250"
                      :placeholder="$t('urls.search-placeholder')"
                      type="text"
                      size="sm"
                    />
                  </div>

                  <div class="page-header__filters-filters">
                    <FiltersVue
                      v-if="!isPopup"
                      :filters="filters"
                    />
                  </div>
                </div>

              </div>
            </div> <!-- page-header -->
          </div> <!-- article-listing__header -->
          <div>
            <vue-good-table
              mode="remote"
              class="urls__table entry-field__table"
              :class="{
                'listing-popup-table': isPopup,
                'listing-popup-table-no-hover': !isPopup,
                'listing-popup-table--has-checkbox': isPopup,
                'vgt--loading': $apollo.loading,
              }"
              :select-options="isPopup ? {
                enabled: true,
                disableSelectInfo: true
              } : {}"
              :columns="columns"
              :rows="items"
              :row-style-class="rowClasses"
              @on-row-click="selectionChanged"
            >
              <div slot="emptystate">
                {{ $t('urls.no-data') }}
              </div>
              <template
                slot="table-row"
                slot-scope="props"
              >
                <div
                  class="entry-field__row"
                  :class="{
                    'popup-row-clicked': selectedUrls.find(e => e.id == props.row.id),
                    'pointer-events-none': isPopup
                  }"
                >
                  <!-- type column -->
                  <div
                    v-if="props.column.field === 'entity' || props.column.field === 'redirectUrl'"
                    class="urls-table__entity"
                  >
                    <b-img-aspect
                      v-if="props.row.entityType == URL_ENTITY_TYPES_STRING.ENTRY && props.row.entity && props.row.entity.system && props.row.entity.system.featuredMedia"
                      :key="props.row.id + props.row.entity.system.featuredMedia.url"
                      aspect="1:1"
                      class="urls-table__featured-media"
                      :src="props.row.entity.system.featuredMedia.url"
                    />
                    <GjIcon
                      v-else-if="props.row.entityType == URL_ENTITY_TYPES_STRING.ENTRY && props.row.entity"
                      :key="`${getEntryModel(props.row.entity).iconId}${props.row.id}`"
                      size="24"
                      class="urls-table__icon"
                      :name="getEntryModel(props.row.entity).iconId && doesIconExist(getEntryModel(props.row.entity).iconId) ? getEntryModel(props.row.entity).iconId : 'Modeling'"
                    />
                    <GjIcon
                      v-else
                      :key="props.row.id"
                      size="24"
                      name="Modeling"
                      class="urls-table__icon"
                    />
                    <div class="urls-table__entity-text">
                      <span
                        v-if="props.row.entity"
                        class="urls-table__title"
                      >
                        <span :title="props.row.entityType == URL_ENTITY_TYPES_STRING.ENTRY ? props.row.entity.system.title : props.row.entity.title">
                          {{ props.row.entityType == URL_ENTITY_TYPES_STRING.ENTRY ? props.row.entity.system.title : props.row.entity.title }}
                        </span>
                        <GjIcon
                          v-if="props.row.entityType == URL_ENTITY_TYPES_STRING.ENTRY && props.row.entity"
                          name="Show"
                          size="20"
                          title="Quick view"
                          class="urls-table__url-icon"
                          @click.native="openQuickView(props.row)"
                        />
                      </span>
                      <div
                        v-if="props.row.redirectUrl"
                        class="urls-table__title"
                      >
                        <span :title="props.row.redirectUrl">
                          {{ props.row.redirectUrl }}
                        </span>
                      </div>
                      <span
                        v-if="props.row.isCustom"
                        class="urls-table__subtitle"
                      >
                        {{ $t('urls.urlModal.custom') }}
                      </span>
                    </div>
                  </div>

                  <!-- entity column -->

                  <span
                    v-if="props.column.field === 'entityType'"
                    class="urls-table__type"
                  >
                    {{ props.row.entityType == URL_ENTITY_TYPES_STRING.REDIRECT ? `${mapEntityType(props.row.entityType)} - ${mapRedirectStatusCode(props.row.statusCode)}` : mapEntityType(props.row.entityType) }}
                  </span>
                  <!-- url column -->
                  <div
                    v-if="props.column.field === 'url'"
                    class="urls-table__url"
                  >
                    <span :title="props.row.url">
                      {{ props.row.url }}
                    </span>
                    <a
                      target="_blank"
                      :href="getUrl(props.row)"
                      class="urls-table__url-icon"
                    >
                      <GjIcon
                        name="Redirect"
                        size="20"
                      />
                    </a>
                    <GjIcon
                      name="Copy"
                      size="20"
                      class="urls-table__url-icon"
                      @click.native="copyUrl(props.row)"
                    />
                  </div>
                  <!-- actions column -->
                  <div v-if="props.column.field === 'actions'">
                    <span>
                      <b-dropdown
                        no-caret
                        right
                        variant="flat-primary"
                        size="sm"
                      >
                        <template #button-content>
                          <GjIcon name="Moreoptions" />
                        </template>
                        <b-dropdown-item
                          v-permission="['canEditUrl', props.row]"
                          @click="editUrl(props.row)"
                        >
                          <GjIcon
                            key="EditAltBox"
                            size="16px"
                            name="EditAltBox"
                          />
                          {{ $t('urls.edit') }}
                        </b-dropdown-item>
                        <b-dropdown-item
                          v-if="props.row.isCustom"
                          v-permission="['canEditUrl', props.row]"
                          @click="deleteUrl(props.row)"
                        >
                          <GjIcon
                            key="Delete"
                            size="16px"
                            name="Delete"
                          />
                          {{ $t('urls.delete') }}
                        </b-dropdown-item>
                      </b-dropdown>
                    </span>
                  </div>
                </div>
              </template>
            </vue-good-table>
            <LoadingSpinner v-if="$apollo.loading" />
            <div slot="table-actions-bottom">
              <div class="entries-listing__footer">
                <div class="codex-pagination">
                  <b-pagination
                    v-model="page"
                    :total-rows="total"
                    :per-page="limit"
                    hide-goto-end-buttons
                    hide-ellipsis
                    limit="3"
                    prev-class="prev-item"
                    next-class="next-item"
                  >
                    <template #prev-text>
                      <GjIcon
                        name="ArrowLeft"
                        size="18"
                      />
                    </template>
                    <template #next-text>
                      <GjIcon
                        name="ArrowRight"
                        size="18"
                      />
                    </template>
                  </b-pagination>
                  <div class="codex-pagination__text">
                    <span>
                      {{ $t('general.pagination', { from: from, to: to, totalItems: total}) }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> <!-- urls -->
      </full-height-layout>
    </div>
  </div>
</template>

<script>
import FullHeightLayout from '@gjirafatech/gjirafa-front/script/full-height-layout.vue'
import FiltersVue from '@/components/filters-dropdown/Filters.vue'
import Filters from '@/components/filters-dropdown/Filters'
import { VueGoodTable } from 'vue-good-table'
import gql from 'graphql-tag'
import { useClipboard } from '@vueuse/core'
import { debounce, merge } from 'lodash'
import {
  deleteUrl, mapUrlEntityType, URL_ENTITY_TYPES_STRING, mapRedirectStatusCode,
} from '@/codex-sdk/urls'
import { getEntryModel } from '@/utils/helpers'
import { ENTITY_TYPES } from '@/utils/constants'
import { transformEntryGraphQL } from '@/codex-sdk/entries'
import NoPermission from '@/components/NoPermission.vue'
import { canNavigate } from '@/libs/acl/routeProtection'
import LoadingSpinner from '@/components/LoadingSpinner.vue'

export default {
  name: 'UrlsListing',
  components: {
    LoadingSpinner,
    NoPermission,
    FullHeightLayout,
    FiltersVue,
    VueGoodTable,
  },
  apollo: {
    urlCollection: {
      query: gql`
        query ($offset: Int, $limit: Int, $where: CodexUrlFilter) {
          urlCollection (limit: $limit, offset: $offset, where: $where) {
            total
            items {
              id
              url
              entity {
                ... on CodexEntry {
                  id
                  system {
                    title
                    siteId
                    modelId
                    modelAlias
                    featuredMedia {
                      id
                      url(transformation: {format: THUMBNAIL, width: 50})
                    }
                  }
                }
                ... on CodexSection {
                  id
                  title
                }
              }
              entityType
              fieldAlias
              level
              isCustom
              statusCode
              redirectUrl
              createdBy {
                id
              }
            }
          }
        }
      `,
      fetchPolicy: 'network-only',
      variables() {
        const filters = this.filters.asGraphQL
        return {
          limit: this.limit,
          offset: this.offset,
          where: merge({
            siteId: {
              eq: this.$store.state.general.currentSite.id,
            },
            query: this.searchTerm,
          }, filters),
        }
      },
      result(results) {
        results.data.urlCollection.items.forEach(e => {
          if (e.entityType === URL_ENTITY_TYPES_STRING.ENTRY) {
            transformEntryGraphQL(e.entity)
          }
        })
        this.total = results.data.urlCollection.total
        return results
      },
    },
  },
  inject: ['showEditUrlPopup', 'toastNotification', 'doesIconExist', 'showQuickViewEntryPopup', 'showConfirmDeletePopup'],
  props: {
    isPopup: {
      type: Boolean,
      default: false,
    },
    type: {
      type: Number,
      default: null,
    },
  },
  data() {
    const filters = new Filters([])
    window.filters = filters
    if (!this.isPopup) {
      filters.loadFromQuery(this.$route.query)
    }
    return {
      getEntryModel,
      mapRedirectStatusCode,
      URL_ENTITY_TYPES_STRING,
      searchTerm: '',
      limit: 10,
      total: 0,
      page: 1,
      filters,
      selectedUrls: [],
    }
  },
  computed: {
    columns() {
      return [
        {
          label: this.$t('urls.table.entity'),
          field: 'entity',
          sortable: false,
          tdClass: 'entries-table__mobile-cell',
        },
        {
          label: this.$t('urls.table.type'),
          field: 'entityType',
          sortable: false,
        },
        {
          label: this.$t('urls.table.url'),
          field: 'url',
          sortable: false,
          tdClass: 'entries-table__mobile-cell',
        },
        {
          label: '',
          field: 'actions',
          sortable: false,
          width: '50px',
          tdClass: 'entries-table__mobile-cell',
        },
      ]
    },
    urlFilters() {
      return [{
        type: 'group',
        label: this.$t('urls.system-filters'),
        filters: [
          {
            type: 'urlEntityType',
            name: 'urlType',
            label: this.$t('urls.filters.entityType'),
            graphQLPath: 'entityType',
          },
        ],
      }]
    },
    items() {
      return this.urlCollection?.items || []
    },
    offset() {
      return (this.page - 1) * this.limit
    },
    from() {
      return (this.page - 1) * this.limit + 1
    },
    to() {
      return (this.page * this.limit) > this.total ? this.total : (this.page * this.limit)
    },
  },
  mounted() {
    this.filters.setFilters(this.urlFilters)
    if (this.isPopup && this.type) {
      this.filters.setActiveFilters({
        urlType: {
          operator: 'includeOneOf',
          value: [
            this.$t(mapUrlEntityType(this.type)).toUpperCase(),
          ],
        },
      })
    }
  },
  watch: {
    'filters.asQueryParams': {
      handler: debounce(function () {
        if (!this.isPopup) {
          this.$router.push({ query: { search: this.$route.query.search, ...this.filters.getQueryParamsObject() } }).catch(() => {})
        }
      }, 100), // 100ms delay
      deep: true,
    },
  },
  methods: {
    rowClasses(row) {
      return this.selectedUrls.find(e => e.id == row.id) ? 'popup-row-selected' : ''
    },
    canNavigate,
    mapEntityType(entityType) {
      return this.$t(mapUrlEntityType(entityType))
    },
    openQuickView(entry) {
      this.showQuickViewEntryPopup({
        entryId: entry.entity.id,
        modelAlias: entry.entity.system.modelAlias,
        siteId: entry.entity.system.siteId,
      })
    },
    copyUrl(entity) {
      const { copy } = useClipboard()
      copy(this.getUrl(entity))

      this.toastNotification({
        icon: 'Copy',
        variant: 'success',
        title: 'Copied',
        text: 'Copied to clipboard',
      })
    },
    getUrl(entity) {
      return `https://${this.$store.state.general.currentSite.domain}${entity.url}`
    },
    async createUrl() {
      const res = await this.showEditUrlPopup()
      if (res) {
        this.refetch()
      }
    },
    async editUrl(url) {
      const res = await this.showEditUrlPopup({ id: url.id })
      if (res) {
        this.refetch()
      }
    },
    async deleteUrl(url) {
      if (!url || !url.isCustom) return
      try {
        const result = await this.showConfirmDeletePopup({
          items: [url.url],
          type: ENTITY_TYPES.URL,
        })
        if (result) {
          await deleteUrl(url)
          this.refetch()
        }
      } catch (e) {
        console.log(e)
      }
    },
    refetch() {
      setTimeout(() => {
        this.$apollo.queries.urlCollection.refetch()
      }, 500)
    },
    selectionChanged({ row }) {
      if (!this.selectedUrls.some(e => e.id === row.id)) {
        this.selectedUrls = [row]
      } else {
        this.selectedUrls.filter(url => url.id !== row.id)
      }
      this.$emit('selectedUrls', this.selectedUrls)
    },
  },
}
</script>

<style>
.urls__full-height {
  overflow: auto;
}
</style>
