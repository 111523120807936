<template>
  <div class="date-time__appearance">
    <b-form-group>
      <b-form-radio-group v-model="appearance">
        <div class="date-time-field__appearance">
          <div class="date-time-field__icon-text">
            <GjIcon
              name="Calendar"
              class="date-time-field__icon"
              size="24"
              style="fill: #1D79F2"
            />
            <span class="date-time-field__text">
              {{ $t('fields.codex-field-date-and-time.appearance.date-time') }}
            </span>
          </div>
          <b-form-radio
            name="radio"
            :value="1"
            class="date-time-field__radio"
          />
        </div>
      </b-form-radio-group>
    </b-form-group>

    <FieldHelpTextInput
      :widget="widget"
      :update-attrs="updateAttrs"
    />

    <b-form-group>
      <template #label>
        <span>{{ $t('fields.codex-field-date-and-time.appearance.format') }}</span>
        <GjIcon
          v-b-tooltip.hover="$t('fields.codex-field-date-and-time.appearance.format-tooltip')"
          name="Info"
          size="20"
        />
      </template>
      <v-select
        id="appearanceFormat"
        v-model="format"
        :options="formatDates"
        :placeholder="$t('fields.codex-field-date-and-time.appearance.select-option')"
        :reduce="option => option.value"
        :clearable="false"
      />
    </b-form-group>
    <b-form-group
      v-if="format !== DATE_FORMAT.DATE"
      :label="$t('fields.codex-field-date-and-time.appearance.time-mode')"
    >
      <v-select
        v-model="timeMode"
        :options="timeModes"
        :reduce="option => option.value"
        :placeholder="$t('fields.codex-field-date-and-time.appearance.select-option')"
        :clearable="false"
      />
    </b-form-group>
    <b-form-group :label="$t('fields.codex-field-date-and-time.appearance.default-value')">
      <b-form-radio
        v-model="defaultOption"
        :value="DEFAULT_OPTION.CUSTOM"
      >
        {{ $t('fields.codex-field-date-and-time.appearance.custom') }}
      </b-form-radio>
      <div
        v-if="defaultOption === DEFAULT_OPTION.CUSTOM"
        class="appereance-date-time__calendar-wrapper"
      >
        <flat-pickr
          :key="`${format}${timeMode}`"
          v-model="nowTime"
          :config="config"
          :placeholder="$t('fields.codex-field-date-and-time.appearance.select-date')"
        />
        <span class="appereance-calendar">
          <GjIcon name="Calendar" />
        </span>
      </div>
      <b-form-radio
        v-model="defaultOption"
        :value="DEFAULT_OPTION.NOW"
        class="default-value__now"
      >
        {{ $t('fields.codex-field-date-and-time.appearance.now') }}
      </b-form-radio>
    </b-form-group>
    <FieldConditions
      v-model="hidden"
      :widget="widget"
      :label="$t('fields.general.hidden.label')"
      :show-error-message="false"
      :tooltip="$t('fields.general.hidden.info')"
    />
  </div>
</template>

<script>
import { generateComputedPropsFromAttrs } from '@/components/codex-layout-editor/BuilderUtils'
import { DATE_FORMAT, TIME_FORMAT, DEFAULT_OPTION } from '@/components/fields/DateAndTime/index'
import moment from 'moment'
import FieldHelpTextInput from '@/components/fields/FieldHelpTextInput.vue'
import BaseFieldMixin from '@/components/fields/BaseFieldMixin'
import FieldConditions from '@/components/fields/FieldConditions.vue'

export default {
  components: { FieldConditions, FieldHelpTextInput },
  mixins: [BaseFieldMixin],
  data() {
    return {
      DATE_FORMAT,
      DEFAULT_OPTION,
      now: this.widget.attrs.defaultValue || '',
    }
  },
  computed: {
    ...generateComputedPropsFromAttrs([
      'appearance',
      'format',
      'timeMode',
      'helpText',
      'defaultOption',
      'defaultValue',
      'validation',
      'hidden',
    ]),
    nowTime: {
      get() {
        return new Date(this.now)
      },
      set(v) {
        this.now = v?.length ? moment(v).utc().toISOString() : ''
      },
    },
    formatDates() {
      return [
        { label: this.$t('fields.codex-field-date-and-time.appearance.date-only'), value: DATE_FORMAT.DATE },
        { label: this.$t('fields.codex-field-date-and-time.appearance.date-time'), value: DATE_FORMAT.DATETIME },
      ]
    },
    timeModes() {
      return [
        { label: this.$t('fields.codex-field-date-and-time.appearance.am-pm'), value: TIME_FORMAT.AmPm },
        { label: this.$t('fields.codex-field-date-and-time.appearance.24-hour'), value: TIME_FORMAT.H24 },
      ]
    },
    config() {
      let altFormat = 'd/m/Y'
      if (this.format === DATE_FORMAT.DATETIME) {
        altFormat = this.timeMode === TIME_FORMAT.H24 ? 'd/m/Y H:i' : 'd/m/Y h:i K'
      }

      return {
        enableTime: this.format === DATE_FORMAT.DATETIME,
        altFormat,
        time_24hr: this.timeMode === TIME_FORMAT.H24,
      }
    },
  },
  watch: {
    now(v) {
      this.defaultValue = v?.length ? moment(v).utc().toISOString() : null
    },
    defaultOption(v) {
      if (v === DEFAULT_OPTION.NOW) {
        this.defaultValue = null
      }
    },
    format(value) {
      if (value === DATE_FORMAT.DATE) {
        this.validation.range.min = moment(this.validation.range.min).startOf('day').toISOString()
        this.validation.range.max = moment(this.validation.range.max).endOf('day').toISOString()
      }
    },
  },
}
</script>

<style scoped lang="scss">
.date-time__appearance {
  .default-value__now{
    margin-top: 10px;
  }
  .appereance-date-time__calendar-wrapper {
    margin-top: 15px;
    position: relative;
  }
  .appereance-calendar {
    position: absolute;
    inset: 0;
    right: 10px;
    display: flex;
    justify-content: right;
    align-items: center;
    z-index: -1;
  }
  .help-message {
    font-weight: 600;
  }
  .info {
    font-size: 12px;
    font-weight: 400;
    margin-top: 5px;
  }
  .format-date {
    margin-top: 10px;
  }
  .time-mode{
    margin-top: 30px;
  }
  .datepicer__icon {
    display: flex;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
    box-shadow: #e4e4e4;
  }
  .datepicer {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    padding: 4px 8px;

    position: static;
    width: 290px;
    height: 45px;
    left: 16px;
    top: 0px;

    background: #FFFFFF;
    border: 1px solid #E0E5EB;
    box-sizing: border-box;
    border-radius: 4px;

    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 24px 0px;
  }
  #appearanceFormat .vs__selected-options span.vs__selected {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: -webkit-fill-available;
  }
   .vs__selected-options {
    width: 90%;
  }
  .vs__dropdown-option--selected::after {
    display: none;
  }
  input.vs__search {
    height: 0;
    margin: 0;
  }
.date-time-field__appearance {
  width: 100%;
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 4px 8px;
  border: 1px solid #E0E5EB;
  border-radius: 4px;
}
.date-time-field__icon-text {
  display: flex;
  flex-direction: row;
}
.date-time-field__icon {
  box-shadow: 0px 4px 8px rgba(163, 176, 194, 0.32);
  border-radius: 3px;
}
.date-time-field__text {
  margin-top: 0.2rem;
  margin-bottom: 0;
  margin-left: 12px;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #052D61;
}
.date-time-field__radio {
  padding: 0;
  left: 9px;
  width: 16px;
  height: 16px;
}
}

</style>
