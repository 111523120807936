<template>
  <span
    class="reference-inline"
    :class="{'reference-inline--selected': selected}"
    contenteditable="false"
    :title="references.length > 0 ? references[0].system.title : null"
  >
    <template v-if="references.length > 0">
      {{ references[0].system.title | truncate(30) }}

      <div class="reference-inline-status">
        <StatusChip
          v-if="!isNotFound(references[0])"
          :entry="references[0]"
          :is-query="true"
          :no-text="true"
          :scheduled-versions="references[0].system.scheduledVersions && references[0].system.scheduledVersions.items"
        />
      </div>
      <span
        class="reference-inline__change"
        @click="showEntriesPop"
      >
        <GjIcon
          name="IconparkListAdd"
          size="20"
        />
      </span>
    </template>
  </span>
</template>

<script>
import gql from 'graphql-tag'
import { ENTRY_STATUSES, transformEntriesGraphQL } from '@/codex-sdk/entries'
import StatusChip from '@/views/entries/components/StatusChip.vue'

export default {
  inject: ['showEntriesPopup', 'deleteBlock', 'includeModels'],
  components: {
    StatusChip,
  },
  props: ['attrs', 'updateAttrs', 'selectNode', 'selected'],
  data() {
    return {
      references_: [],
      loading: false,
    }
  },
  computed: {
    references: {
      get() { return this.references_ },
      set(v) {
        this.references_ = v
        this.updateValue()
      },
    },
    computedValue: {
      get() { return this.attrs.references },
      set(value) { this.updateAttrs({ references: [...value] }) },
    },
    modelAlias: {
      get() { return this.attrs.modelAlias },
      set(value) { this.updateAttrs({ modelAlias: value }) },
    },
    entryIds() {
      return this.references.map(entry => entry.id)
    },
  },
  created() {
    this.fetchEntries()
  },
  mounted() {
    this.$root.$on('entry-updated', this.refetchEntries)
  },
  destroyed() {
    this.$root.$off('entry-updated', this.refetchEntries)
  },
  methods: {
    updateValue() {
      this.computedValue = this.references.map(e => ({
        entryId: e.id,
        model: e.system.model.alias,
      }))
    },
    initialize() {
      if (this.computedValue && this.computedValue.length !== 0) return
      this.showEntriesPop()
    },
    refetchEntries() {
      setTimeout(() => {
        this.references_ = []
        this.fetchEntries()
      }, 1000)
    },
    async showEntriesPop() {
      const result = await this.showEntriesPopup({
        models: this.modelAlias ? [this.modelAlias] : this.includeModels, excludeIds: this.entryIds, limit: 1, includeCustomSiteFilter: true, addOnPublish: true,
      })
      if (result) {
        this.references = result
        this.selectNode()
      } else if (!this.references.length) {
        this.deleteBlock(this.attrs.blockId)
      }
    },
    async fetchEntries() {
      if (this.computedValue && this.computedValue.length) {
        this.loading = true
        try {
          const { data: apolloData } = await this.$apollo.query({
            query: gql`
              query getEntries($ids: [String!]) {
                entryCollection (where: { id: {in: $ids }}) {
                  items {
                    id
                    system {
                      title
                      status
                      modelId
                      scheduledVersions (where: {
                        status: { in: [SCHEDULED] }
                      }) {
                        items {
                          id
                          versionId
                          publishScheduledDate
                          unpublishScheduledDate
                          versionId
                          createdAt
                        }
                      }
                    }
                  }
                }
              }
            `,
            fetchPolicy: 'no-cache',
            variables: {
              ids: this.computedValue.map(e => e.entryId),
            },
          })
          if (apolloData?.entryCollection?.items?.length) {
            transformEntriesGraphQL(apolloData.entryCollection.items)
          }

          this.computedValue.forEach(entry => {
            let item = apolloData.entryCollection?.items?.find(e => e.id === entry.entryId)
            if (!item) {
              item = {
                id: entry.entryId,
                system: {
                  status: ENTRY_STATUSES.DELETED,
                  model: {
                    name: entry.model,
                    iconId: 'Modeling',
                    alias: entry.model,
                  },
                },
              }
            }
            this.references_.push(item)
          })
        } catch (e) {
          console.log(e)
        }
        this.loading = false
      }
    },
    removeEntry(id) {
      this.references = this.references.filter(e => e.id !== id)
    },
    isNotFound(entry) {
      return entry.system.status === ENTRY_STATUSES.DELETED
    },
  },
}
</script>

<style lang="scss">
.reference-inline {
  background-color: #f6f7f9;
  border: 1px dashed #c8d0da;
  border-radius: 4px;
  display: inline;
  align-items: center;
  white-space: normal;
  padding: 0 5px;
  min-height: 1.2em;

  .reference-inline-status {
    display: inline-block;
    height: 20px;
  }

  .entry-edit--status-container {
    height: 16px;

    .entry-badge-container {
      margin-left: 5px;
    }

    .entries-field-status-schedules {
      margin-top: -5px;
      height: 20px;
      width: 20px;
    }
  }

  .entry-badge-container {
    margin-left: 5px;

    .entry-badge {
      &:before {
        margin-right: 0;
      }
    }
  }
}
.reference-inline--selected {
  border-color: #3b8ee3;
}
.reference-inline__change {
  margin-left: 4px;
  cursor: pointer;
}
</style>
