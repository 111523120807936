import ability from '@/libs/acl/ability'
import { pr } from './config'

// For regEx search in VSCode: canViewUser|canCreateUser|canEditUser|canDeleteUser

/**
user_view
user_manage
 */
// * Create entity file, and can functions
// * Import and add them to allCanFunctions object down below
// * Add defaults into the permissions array down below
// Implement v-permission directives on neccessary buttons
// Implement permissions on entities sdk functions
// Implement permissions on navigation and route

export const User = Object.freeze({
  VIEW: 'user_view',
  MANAGE: 'user_manage',
})

export const UserTooltips = {
  [User.VIEW]: 'Allows a user to view all users in ‘view only’ mode',
  [User.MANAGE]: 'Allows user to perform all CRUD operations in user management level',
}

/**
 * _options
 *    entity = undefined
 *    showToast = false
 */

/**
 * Can VIEW Users
 * */
export function canViewUsers(_options = {}) {
  const { entity, ...options } = _options

  return pr(
    ability.can(User.VIEW) || ability.can(User.MANAGE),

    'You have no permission to view users',

    options,
  )
}

/**
 * Can INVITE User
 * */
export function canInviteUser(_options = {}) {
  const { entity, ...options } = _options

  return pr(
    ability.can(User.MANAGE),

    'You have no permission to invite user',

    options,
  )
}

/**
 * Can EDIT User
 * */
export function canEditUser(_options = {}) {
  const { entity, ...options } = _options

  return pr(
    ability.can(User.MANAGE),

    'You have no permission to edit user',

    options,
  )
}

/**
 * Can DELETE User
 * */
export function canDeleteUser(_options = {}) {
  const { entity, ...options } = _options

  return pr(
    ability.can(User.MANAGE),

    'You have no permission to delete this user',

    options,
  )
}
