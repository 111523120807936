<template>
  <div
    class="new-filters-dropdown__filter-body"
    :class="{'new-filters-dropdown__filter-body--scroll': sites.length > 3 }"
  >
    <b-row>
      <b-col cols="12">
        <vSelect
          v-model="value.operator"
          :options="options"
          :clearable="false"
          :reduce="option => option.value"
          :searchable="false"
          label="label"
        />
      </b-col>
    </b-row>

    <b-row
      align-v="center"
      no-gutters
    >
      <b-col cols="12">
        <b-checkbox-group
          v-model="value.value"
          class="new-filters-dropdown__checkbox-group"
          :options="sites"
          stacked
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import SiteListFilter from './SiteListFilter'

export default {
  props: {
    filter: {
      type: Object,
      required: true,
    },
    filterObject: {
      type: [Object, Function],
      required: true,
    },
    value: {
      type: Object,
      required: true,
    },
    cache: {
      type: Object,
      required: true,
    },
    setCache: {
      type: Function,
      required: true,
    },
  },
  computed: {
    options() {
      return [
        {
          label: this.$t('filters.all'),
          value: SiteListFilter.OPERATORS.all,
        },
        {
          label: this.$t('filters.some'),
          value: SiteListFilter.OPERATORS.some,
        },
        {
          label: this.$t('filters.none'),
          value: SiteListFilter.OPERATORS.none,
        },
      ]
    },
    sites() {
      return this.$store.state.sites.sites.map(site => ({
        value: site.id,
        text: site.name,
      }))
    },
  },
}
</script>
