import { merge } from 'lodash'
import { apiAxios as axios } from '../libs/axios'
import { tc } from './config'

/**
 * Constants
 */
export const MODEL_STATUSES = Object.freeze({
  PUBLISHED: 1,
  DELETED: 2,
  DRAFT: 3,
})

export const ACCESS_STRATEGIES = Object.freeze({
  UNRESTRICTED: 1,
  RESTRICTED: 2,
})

export const MODELS_STATUSES_STRING = Object.freeze({
  1: 'PUBLISHED',
  2: 'DELETED',
  3: 'DRAFT',
})

// Used inside field files
export const FIELD_GROUPS = Object.freeze({
  PLUGIN: 'plugin',
  BASIC: 'basic',
  GENERAL: 'general',
  CODEX: 'codex',
})

export const FIELD_GROUP_NAMES = Object.freeze({
  [FIELD_GROUPS.PLUGIN]: 'models.field-group-names.plugin',
  [FIELD_GROUPS.BASIC]: 'models.field-group-names.basic',
  [FIELD_GROUPS.GENERAL]: 'models.field-group-names.general',
  [FIELD_GROUPS.CODEX]: 'models.field-group-names.codex',
})

/**
 * Helpers
 */
export function mapModelStatus(status) {
  const statuses = {
    [MODEL_STATUSES.PUBLISHED]: 'models.models-statuses.published',
    [MODEL_STATUSES.DELETED]: 'models.models-statuses.deleted',
    [MODEL_STATUSES.DRAFT]: 'models.models-statuses.draft',
  }
  return statuses[status] || 'models.models-statuses.unsaved'
}

/**
 * Helpers
 */
export function modelDefaults(model = {}) {
  return {
    status: MODEL_STATUSES.DRAFT,
    name: '',
    alias: '',
    iconId: '',
    description: '',
    blocks: [],
    titleAlias: '',
    slugAlias: '',
    featuredMediaAlias: '',
    deletedBlocks: [],
    restrictions: {
      sites: {
        allowAll: true,
        allowed: [],
      },
    },
    configurations: {
      sidebar: {
        general: {
          isEnabled: true,
          items: {
            preview: { isEnabled: true, order: 0, pattern: '' },
            status: { isEnabled: true, order: 1 },
            labels: { isEnabled: true, order: 2 },
            publishedAt: { isEnabled: true, order: 3 },
            metrics: { isEnabled: true, order: 4 },
            created: { isEnabled: true, order: 5 },
            updated: { isEnabled: true, order: 6 },
            prolexis: { isEnabled: true, order: 7 },
            id: { isEnabled: true, order: 8 },
            externalId: { isEnabled: true, order: 9 },
            addToList: { isEnabled: true, order: 10 },
            firstPublishedAt: { isEnabled: true, order: 11 },
            view: { isEnabled: true, order: 12 },
            aiContentAnalyzer: { isEnabled: false, order: 13 },
            scheduledVersions: { isEnabled: true, order: 14 },
          },
        },
        history: { isEnabled: true },
        referencedBy: { isEnabled: true },
        clonedReferences: { isEnabled: true },
      },
      accessStrategy: ACCESS_STRATEGIES.UNRESTRICTED,
      lockDurationInSeconds: 900,
      hideInCreateButton: false,
      hideInCloneButton: false,
      labels: {
        allowAll: true,
        allowed: [],
      },
      defaultFolderId: null,
    },
    ...model,
  }
}

/**
 * Transforms to received data
 */
export function transformModelFromRequest(model) {
  const mod = modelDefaults(model)
  if (!mod.deletedBlocks) {
    mod.deletedBlocks = []
  }

  if (!mod.configurations.lockDurationInSeconds) {
    mod.configurations.lockDurationInSeconds = 900
  }
  const sidebar = {
    general: {
      isEnabled: true,
      items: {
        preview: { isEnabled: true, order: 0, pattern: '/preview/{system.modelAlias}/{id}' },
        status: { isEnabled: true, order: 1 },
        labels: { isEnabled: true, order: 2 },
        publishedAt: { isEnabled: true, order: 3 },
        metrics: { isEnabled: true, order: 4 },
        created: { isEnabled: true, order: 5 },
        updated: { isEnabled: true, order: 6 },
        prolexis: { isEnabled: true, order: 7 },
        id: { isEnabled: true, order: 8 },
        externalId: { isEnabled: true, order: 9 },
        addToList: { isEnabled: true, order: 10 },
        firstPublishedAt: { isEnabled: true, order: 11 },
        view: { isEnabled: true, order: 12 },
        aiContentAnalyzer: { isEnabled: false, order: 13 },
        scheduledVersions: { isEnabled: true, order: 14 },
      },
    },
    history: { isEnabled: true },
    referencedBy: { isEnabled: true },
    clonedReferences: { isEnabled: true },
  }

  merge(sidebar, mod.configurations.sidebar)
  Object.assign(mod.configurations.sidebar, sidebar)

  if (!mod.configurations.labels) {
    mod.configurations.labels = {
      allowAll: true,
      allowed: [],
    }
  }
  return mod
}

export function transformModelVersionFromRequest(modelVersion) {
  return modelVersion
}

/**
 * CRUD Operations
 * */

/**
 *
 * @param {*} model
 *
 * @response
 * {
 *  id: GUID
 *  errors: null | Object
 *  success: true | false
 * }
 */
export function createModel(model, messages = {}) {
  return tc(axios.post('/{organization}/models', model), {
    successTitle: 'sdk.success-title',
    successMessage: 'sdk.model.create-successful',
    failTitle: 'sdk.model.create-failed',
    catchError: false,
    ...messages,
  })
}

/**
 *
 * @param {Object} [options] Filter attrs
 * @param {Number} [options.page] Page
 * @param {Number} [options.pageSize] Page size
 * @param {String} [options.searchTerm] Search term
 * @response
 * [{
 *  id: GUID
 *  parentId: GUID | null
 *  title: String
 *  slug: String
 *  canContainArticle: Bool
 * }, {...}, ...]
 */
export function readModels({
  page = 1, pageSize = 10, searchTerm, status,
} = {}) {
  return axios.get('/{organization}/models', {
    params: {
      Page: page,
      Size: pageSize,
      Q: searchTerm,
      Status: status,
    },
  }).then(response => {
    response.data.data = response.data.data.map(model => transformModelFromRequest(model))
    return response
  })
}

export function readPublishedModels(siteId) {
  return axios.get('/{organization}/models/published', {
    params: {
      siteId: siteId || null,
    },
  })
}

/**
 *
 * @response
 * [{
 *  id: GUID
 *  parentId: GUID | null
 *  title: String
 *  slug: String
 *  canContainArticle: Bool
 * }, {...}, ...]
 */
// export function searchModels({ search: q, type, siteId }) {
//   return axios.get('/{organization}/layout/search', { params: { q, type, siteId } }).then(response => {
//     response.data = response.data.map(layout => transformModelFromRequest(layout))
//     return response
//   })
// }

/**
 *
 * @param {String} id
 *
 * @response
 * {
 *  id: GUID
 *  parentId: GUID | null
 *  title: String
 *  slug: String
 *  canContainArticle: Bool
 * }
 */
export function readModel(id) {
  return axios.get(`/{organization}/models/${id}`).then(response => {
    response.data = transformModelFromRequest(response.data)
    return response
  })
}

/**
 *
 * @param {Object} model
 *
 * @response
 * {
 *  id: GUID
 *  errors: null | Object
 *  success: true | false
 * }
 */
export function updateModel(model, messages) {
  return tc(axios.put(`/{organization}/models/${model.id}`, model), {
    successTitle: 'sdk.success-title',
    successMessage: 'sdk.model.update-successful',
    failTitle: 'sdk.model.update-failed',
    catchError: false,
    ...messages,
  })
}

/**
 *
 * @param {Object} model
 *
 * @response
 * {
 *  errors: null | Object
 *  success: true | false
 * }
 */
export function deleteModel(model, messages) {
  return tc(axios.delete(`/{organization}/models/${model.id}`), {
    successTitle: 'sdk.success-title',
    successMessage: 'sdk.model.delete-successful',
    failTitle: 'sdk.model.delete-failed',
    catchError: false,
    ...messages,
  })
}

/**
 * @param {Object} models
 * @param messages
 * @returns {*}
 */
export function deleteModels(models, messages) {
  // return models.every(model => checkPermission('canDeleteLayout', { entity: model }))
  return tc(Promise.all(models.map(model => axios.delete(`/{organization}/models/${model.id}`))), {
    successTitle: 'sdk.success-title',
    successMessage: 'sdk.model.delete-models-successful',
    failTitle: 'sdk.model.delete-models-failed',
    ...messages,
  }, true)
}

/// /////////////////////////////////////////////////////////////
/// //////////////////////// VERSIONS ///////////////////////////
/// /////////////////////////////////////////////////////////////
//
export function readModelVersions(modelId) {
  return axios.get(`/{organization}/models/${modelId}/versions`)
}

export function readModelVersion(modelId, versionId) {
  return axios.get(`/{organization}/models/${modelId}/versions/${versionId}`)
}

export function updateModelVersion(version, messages) {
  return tc(axios.put(`/{organization}/models/${version.modelId}/versions/${version.id}`, version), {
    successTitle: 'sdk.success-title',
    successMessage: 'sdk.model.version.update-successful',
    failTitle: 'sdk.model.version.update-failed',
    ...messages,
  })
}

export function restoreModelVersion(version, messages) {
  return tc(axios.put(`/{organization}/models/${version.entity.id}/versions/${version.id}/restore`, { }, {
    params: { revisionId: version.revisionId },
  }), {
    successTitle: 'sdk.success-title',
    successMessage: 'sdk.model.version.restore-successful',
    failTitle: 'sdk.model.version.restore-failed',
    catchError: false,
    ...messages,
  })
}

export function mappingDefaults(mapping = {}) {
  return {
    fromModel: null,
    toModel: null,
    fields: { },
    configurations: {
      asset: {
        isEnabled: false,
        folderId: null,
      },
    },
    ...mapping,
  }
}

export function transformModelMappingFromRequest(mapping = {}) {
  if (!mapping.configurations) {
    mapping.configurations = mappingDefaults().configurations
  }
  return mapping
}

export function readModelMapping(modelId, mappingId) {
  return axios.get(`/{organization}/models/${modelId}/mappings/${mappingId}`).then(response => {
    response.data = transformModelMappingFromRequest(response.data)
    return response
  })
}

export function createModelMapping(modelId, mapping, messages = {}) {
  return tc(axios.post(`/{organization}/models/${modelId}/mappings`, mapping), {
    successTitle: 'sdk.success-title',
    successMessage: 'sdk.mapping.create-successful',
    failTitle: 'sdk.mapping.create-failed',
    catchError: false,
    ...messages,
  })
}

export function updateModelMapping(modelId, mappingId, mapping, messages = {}) {
  return tc(axios.put(`/{organization}/models/${modelId}/mappings/${mappingId}`, mapping), {
    successTitle: 'sdk.success-title',
    successMessage: 'sdk.mapping.update-successful',
    failTitle: 'sdk.mapping.update-failed',
    catchError: false,
    ...messages,
  })
}

export function deleteModelMapping(modelId, mappingId, messages = {}) {
  return tc(axios.delete(`/{organization}/models/${modelId}/mappings/${mappingId}`), {
    successTitle: 'sdk.success-title',
    successMessage: 'sdk.mapping.delete-successful',
    failTitle: 'sdk.mapping.delete-failed',
    catchError: false,
    ...messages,
  })
}
