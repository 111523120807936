import { Node } from 'tiptap'
import { wrappingInputRule, toggleWrap } from 'tiptap-commands'
// import { TextSelection } from 'prosemirror-state'
import { replaceParentNodeOfType } from 'prosemirror-utils'
import { getBlockIdFromDom } from '@/components/codex-editor/CodexEditorUtils'
import BlockquotePanel from './BlockquotePanel.vue'
import CodexContentEditor from '../../CodexContentEditor'
import { BLOCK_GROUPS } from '../constants'

// eslint-disable-next-line no-unused-vars
export function blockquoteTipTapNode(componentName, componentAttrs, component) {
  class Blockquote extends Node {
    get name() {
      return componentName
    }

    get schema() {
      return {
        attrs: componentAttrs,
        defining: true,
        draggable: false,
        content: 'codex_blockquote_content codex_blockquote_author',
        group: 'block',
        parseDOM: [{
          tag: 'blockquote',
          getAttrs: dom => ({
            ...JSON.parse(dom.dataset?.attrs || '{}'),
            blockId: getBlockIdFromDom(dom),
            class: 'blockquote',
          }),
        }],
        toDOM: node => ['blockquote', {
          'data-id': node.attrs.blockId,
          'data-attrs': JSON.stringify(node.attrs) || '{}',
          class: 'blockquote',
        }, 0],
      }
    }

    commands({ type, schema }) {
      return () => (state, dispatch) => {
        // const offset = state.tr.selection.anchor

        const nodes = schema.nodes[type.name].createChecked(null, [schema.nodes.codex_blockquote_content.createChecked(), schema.nodes.codex_blockquote_author.createChecked()])
        const tr = replaceParentNodeOfType([schema.nodes.paragraph], nodes)(state.tr).scrollIntoView()
        // const resolvedPos = tr.doc.resolve(offset)

        // tr.setSelection(TextSelection.near(resolvedPos))

        dispatch(tr)
      }
    }

    keys({ type }) {
      return {
        'Ctrl->': toggleWrap(type),
      }
    }

    inputRules({ type }) {
      return [wrappingInputRule(/^\s*>\s$/, type)]
    }
  }

  return new Blockquote()
}

CodexContentEditor.registerWidget('codex', {
  displayName: 'codex-editor.block-names.blockquote',
  name: 'blockquote',
  group: BLOCK_GROUPS.TEXTUAL,
  icon: 'Quote',
  // eslint-disable-next-line global-require
  image: require('../../icons/quote/icon.svg'),
  attrs: {
    blockId: {
      default: null,
    },
  },
  // renderEditor: ImageVue,
  createTiptapNode: blockquoteTipTapNode,
  renderEditorBar: BlockquotePanel,
})
