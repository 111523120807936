<template>
  <b-modal
    v-model="showPopup"
    :title="$t('assets.delete-folder.title')"
    centered
    :static="true"
    :cancel-title="$t('general.cancel')"
    cancel-variant="outline-primary"
    no-close-on-backdrop
    :ok-title="$t('general.delete')"
    ok-variant="danger"
    @ok.prevent="handleFolderDeletion(folder)"
    @cancel="handleCancel"
    @close="handleCancel"
  >
    <b-form-group :label="$t('assets.delete-folder.select-options')">
      <b-form-radio
        id="at-delete-folder-and-assets-button"
        v-model="selected"
        value="1"
      >
        {{ $t('assets.delete-folder.all') }}
      </b-form-radio>
      <b-form-radio
        id="at-delete-folder-move-assets-button"
        v-model="selected"
        value="2"
      >
        {{ $t('assets.delete-folder.move') }}
      </b-form-radio>
    </b-form-group>
    <p class="mb-0 text-danger error">
      {{ error }}
    </p>
  </b-modal>
</template>

<script>
import PopupsMixin from '@/components/popups/PopupsMixin'
import { deleteFolder } from '@/codex-sdk/folders'

export default {
  name: 'DeleteFolderModal',
  mixins: [PopupsMixin],
  data() {
    return {
      selected: 0,
      error: '',
    }
  },
  computed: {
    folder() {
      return this.data?.folder || {}
    },
  },
  watch: {
    selected() {
      this.error = ''
    },
  },
  methods: {
    async handleFolderDeletion(folder) {
      const selectedOption = Number(this.selected)

      if (selectedOption !== 1 && selectedOption !== 2) {
        this.error = 'Please provide one of the mentioned options.'
      } else {
        try {
          await deleteFolder(folder, Number(this.selected), 1)
          this.closePopup(folder)
        } catch (e) {
          console.log(e)
        }
      }
    },
    handleCancel() {
      this.closePopup(false)
    },
  },
}
</script>

<style lang="scss" scoped>
.error {
  font-size: 14px;
}
</style>
