<template>
  <div class="codex-field-reference-validation">
    <FieldConditions
      v-model="validation.required"
      :widget="widget"
      :label="$t('fields.general.required.label')"
      :error-message-label="$t('fields.general.required.error-message')"
      :tooltip="$t('fields.general.required.info')"
      :system-fields-only="true"
    />

    <FieldConditions
      v-model="validation.readOnly"
      :widget="widget"
      :label="$t('fields.general.read-only.label')"
      :show-error-message="false"
    />

    <b-form-group>
      <b-form-checkbox v-model="validation.modelTypes.isEnabled">
        {{ $t('fields.codex-field-reference.validation.accept-only-specified-entry-type') }}
        <GjIcon
          v-b-tooltip.hover="$t('fields.codex-field-reference.validation.accept-only-specified-entry-type-info')"
          name="Info"
          size="20"
        />
      </b-form-checkbox>
    </b-form-group>

    <b-form-group
      v-if="validation.modelTypes.isEnabled"
      class="accepted-models"
    >
      <b-form-checkbox-group
        v-model="validation.modelTypes.acceptedModels"
        buttons
        button-variant="outline"
        style="display: block"
      >
        <b-form-checkbox
          v-for="model in $store.state.general.allModels"
          :key="model.id"
          :value="model.alias"
          class="codex-field-reference-validation__button-group"
        >
          <GjIcon
            :name="model.iconId"
            width="24"
            height="24"
          />
          <span class="codex-field-reference-validation__button-text">
            {{ model.name }}
          </span>
        </b-form-checkbox>
      </b-form-checkbox-group>
    </b-form-group>
    <b-form-group v-if="validation.modelTypes.isEnabled">
      <b-form-invalid-feedback :state="modelTypesValid">
        {{ $t('fields.codex-field-reference.validation.accept-only-specified-entry-type-error-message') }}
      </b-form-invalid-feedback>
    </b-form-group>

    <b-form-group
      v-if="validation.modelTypes.isEnabled"
      :label="$t('fields.codex-field-reference.validation.custom-error-message')"
      class="form-group__validation-last-field-margin"
    >
      <b-form-input
        v-model="validation.modelTypes.errorMessage"
        :placeholder="$t('fields.codex-field-reference.validation.enter-error-message')"
      />
    </b-form-group>

    <template v-if="valueType === VALUE_TYPES.LIST">
      <b-form-group>
        <b-form-checkbox v-model="validation.allowDuplicates">
          {{ $t('fields.codex-field-reference.validation.allow-duplicates') }}
          <GjIcon
            v-b-tooltip.hover="$t('fields.codex-field-reference.validation.allow-duplicates-info')"
            name="Info"
            size="20"
          />
        </b-form-checkbox>
      </b-form-group>
      <b-form-group>
        <b-form-checkbox v-model="validation.numberOfReferences.isEnabled">
          {{ $t('fields.codex-field-reference.validation.accept-only-a-specified-number-of-entries') }}
          <GjIcon
            v-b-tooltip.hover="$t('fields.codex-field-reference.validation.specify-a-minimum')"
            name="Info"
            size="20"
          />
        </b-form-checkbox>
      </b-form-group>

      <div v-if="validation.numberOfReferences.isEnabled">
        <b-form-group>
          <v-select
            v-model="validation.numberOfReferences.rangeOperator"
            :options="options"
            :clearable="false"
            :reduce="v => v.value"
          />
        </b-form-group>

        <b-row>
          <b-col v-if="validation.numberOfReferences.rangeOperator === RANGE_OPERATORS.BETWEEN || validation.numberOfReferences.rangeOperator === RANGE_OPERATORS.GTE">
            <b-form-group :label="$t('fields.codex-field-reference.validation.min')">
              <b-form-input
                v-model.number="validation.numberOfReferences.min"
                :state="!validationError.isValid ? validationError.isValid : null"
                type="number"
                min="0"
              />
            </b-form-group>
          </b-col>
          <b-col v-if="validation.numberOfReferences.rangeOperator === RANGE_OPERATORS.BETWEEN || validation.numberOfReferences.rangeOperator === RANGE_OPERATORS.LTE">
            <b-form-group :label="$t('fields.codex-field-reference.validation.max')">
              <b-form-input
                v-model.number="validation.numberOfReferences.max"
                :state="!validationError.isValid ? validationError.isValid : null"
                type="number"
                min="0"
              />
            </b-form-group>
          </b-col>
          <b-col v-if="validation.numberOfReferences.rangeOperator === RANGE_OPERATORS.EXACTLY">
            <b-form-group :label="$t('fields.codex-field-reference.validation.exactly')">
              <b-form-input
                v-model.number="validation.numberOfReferences.exactly"
                :state="!validationError.isValid ? validationError.isValid : null"
                type="number"
                min="0"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-form-group>
          <b-form-invalid-feedback :state="validationError.isValid">
            {{ $t(validationError.integerMessage) }}
          </b-form-invalid-feedback>
          <b-form-invalid-feedback :state="validationError.isValid">
            {{ $t(validationError.differenceMessage) }}
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group
          v-if="validation.numberOfReferences.isEnabled"
        >
          <template #label>
            <label>
              {{ $t('fields.codex-field-reference.validation.custom-error-template') }}
            </label>
            <span
              id="reference__inputs-allowed-tooltip"
              class="codex-field-range-tooltip"
            >
              <GjIcon
                name="Info"
                size="20"
              />
            </span>
          </template>
          <PlaceholdersTooltip
            :validation="validation.numberOfReferences.rangeOperator"
            :target="'reference__inputs-allowed-tooltip'"
          />
          <b-form-input
            v-model="validation.numberOfReferences.errorTemplate"
            :placeholder="$t('fields.codex-field-reference.validation.enter-error-message')"
          />

        </b-form-group>
        <b-form-group
          v-if="validation.numberOfReferences.isEnabled"
          :label="$t('fields.codex-field-reference.validation.custom-error-message')"
        >
          <b-form-input
            v-model="validation.numberOfReferences.errorMessage"
            readonly
          />
        </b-form-group>
      </div>
    </template>

  </div>
</template>

<script>
import { generateComputedPropsFromAttrs } from '@/components/codex-layout-editor/BuilderUtils'
import {
  VALUE_TYPES, RANGE_OPERATORS, checkRangeValidation, errorTemplateParser,
} from '@/views/models/constants'
import FieldConditions from '@/components/fields/FieldConditions.vue'
import BaseFieldMixin from '@/components/fields/BaseFieldMixin'
import PlaceholdersTooltip from '../PlaceholdersTooltip.vue'

export default {
  name: 'Validations',
  components: {
    FieldConditions,
    PlaceholdersTooltip,
  },
  mixins: [BaseFieldMixin],
  data() {
    return {
      VALUE_TYPES,
      RANGE_OPERATORS,
      validationError: {
        isValid: true,
        integerMessage: '',
        differenceMessage: '',
      },
      modelTypesValid: true,
    }
  },
  computed: {
    ...generateComputedPropsFromAttrs([
      'validation',
      'valueType',
      'appearance',
    ]),
    predefinedErrorTemplates() {
      return {
        [RANGE_OPERATORS.BETWEEN]: this.$t('fields.codex-field-reference.validation.number-of-references-templates.between'),
        [RANGE_OPERATORS.GTE]: this.$t('fields.codex-field-reference.validation.number-of-references-templates.gte'),
        [RANGE_OPERATORS.LTE]: this.$t('fields.codex-field-reference.validation.number-of-references-templates.lte'),
        [RANGE_OPERATORS.EXACTLY]: this.$t('fields.codex-field-reference.validation.number-of-references-templates.exactly'),
      }
    },
    options() {
      return [
        { label: this.$t('fields.codex-field-reference.validation.between'), value: RANGE_OPERATORS.BETWEEN },
        { label: this.$t('fields.codex-field-reference.validation.at-least'), value: RANGE_OPERATORS.GTE },
        { label: this.$t('fields.codex-field-reference.validation.at-most'), value: RANGE_OPERATORS.LTE },
        { label: this.$t('fields.codex-field-reference.validation.exactly'), value: RANGE_OPERATORS.EXACTLY },
      ]
    },
  },
  watch: {
    'validation.numberOfReferences.min': function changedMin() {
      this.validationError = checkRangeValidation(this.validation.numberOfReferences)
    },
    'validation.numberOfReferences.max': function changedMax() {
      this.validationError = checkRangeValidation(this.validation.numberOfReferences)
    },
    'validation.numberOfReferences.exactly': function changedMax() {
      this.validationError = checkRangeValidation(this.validation.numberOfReferences, true)
    },
    'validation.numberOfReferences.rangeOperator': function changedRangeOperator(v) {
      this.validation.numberOfReferences.errorMessage = ''
      this.validationError = checkRangeValidation(this.validation.numberOfReferences, v === RANGE_OPERATORS.EXACTLY)
      this.validation.numberOfReferences.errorTemplate = this.predefinedErrorTemplates[v]
    },
    'validation.numberOfReferences': {
      handler() {
        errorTemplateParser(this.validation.numberOfReferences)
      },
      deep: true,
    },
    'validation.modelTypes': {
      handler(v) {
        this.modelTypesValid = v.isEnabled ? v.acceptedModels.length > 0 : true
      },
      deep: true,
    },
  },
  mounted() {
    this.validationError = checkRangeValidation(this.validation.numberOfReferences, this.validation.numberOfReferences.rangeOperator == RANGE_OPERATORS.EXACTLY)
    this.modelTypesValid = this.validation.modelTypes.isEnabled ? this.validation.modelTypes.acceptedModels.length > 0 : true
  },
  destroyed() {
    // prevent decimal numbers
    this.validation.numberOfReferences.min = parseInt(this.validation.numberOfReferences.min, 10)
    this.validation.numberOfReferences.max = parseInt(this.validation.numberOfReferences.max, 10)
    this.validation.numberOfReferences.exactly = parseInt(this.validation.numberOfReferences.exactly, 10)
  },
}
</script>

<style lang="scss" scoped>
.codex-field-reference-validation {
  .accepted-models {
    max-height: 250px;
    overflow-x: hidden;
    overflow-y: scroll;
    padding-left: 1rem;
    margin-bottom: 1rem;
  }

  .btn-group [class*=btn-]:not([class*=btn-outline-]):not([class*=btn-flat-]):not([class*=btn-gradient-]):not([class*=btn-relief-]).codex-field-reference-validation__button-group {
    color: #7483A1;
    border: 1px solid #E0E5EB !important;
    padding-left: 1rem;
    word-break: break-all;
    &.active {
      background-color: #fff;
      color: #052D61;
      border: 1px solid #1D79F2 !important;
    }
    &:hover {
      background: #f5f5f5;
    }
  }
  .codex-field-reference-validation__button-group {
    width: 100%;
    margin: 5px 0 !important;
    border-radius: 4px !important;
    justify-content: flex-start;
  }

  .codex-field-reference-validation__button-text {
    margin-left: 1rem;
  }
}
</style>
