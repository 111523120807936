/* eslint-disable max-classes-per-file */
import {
  Table,
  TableCell,
  TableHeader,
  TableRow,
} from 'tiptap-extensions'
import { getBlockIdFromDom } from '@/components/codex-editor/CodexEditorUtils'
import TablePanel from './TablePanel.vue'
import CodexContentEditor from '../../CodexContentEditor'
import { BLOCK_GROUPS } from '../constants'

// eslint-disable-next-line no-unused-vars
export function tableTipTapNode(componentName, componentAttrs, component) {
  class NewTable extends Table {
    get schema() {
      const s = super.schema
      s.attrs = componentAttrs
      s.parseDOM = [{
        tag: 'table',
        getAttrs: dom => ({
          ...JSON.parse(dom.dataset?.attrs || '{}'),
          blockId: getBlockIdFromDom(dom),
        }),
      }]
      s.toDOM = node => ['table', {
        'data-id': node.attrs.blockId,
        'data-attrs': JSON.stringify(node.attrs) || '{}',
      }, 0]
      return s
    }
  }

  const table = new NewTable({
    resizable: true,
    lastColumnResizable: false,
  })

  return table
}

CodexContentEditor.registerWidget(null, {
  displayName: 'codex-editor.block-names.table',
  name: 'table',
  group: BLOCK_GROUPS.OTHERS,
  icon: 'Table',
  // eslint-disable-next-line global-require
  image: require('../../icons/paragraph/icon.svg'),
  attrs: {
    blockId: {
      default: null,
    },
  },
  createTiptapNode: tableTipTapNode,
  renderEditorBar: TablePanel,
})

export class CodexTableHeader extends TableHeader {
  get schema() {
    const s = super.schema
    s.attrs = { blockId: { default: '' }, ...s.attrs }
    s.parseDOM = [{
      tag: 'th',
      getAttrs: dom => ({
        ...JSON.parse(dom.dataset?.attrs || '{}'),
        blockId: getBlockIdFromDom(dom),
      }),
    }]
    s.toDOM = node => ['th', {
      'data-id': node.attrs.blockId,
      'data-attrs': JSON.stringify(node.attrs) || '{}',
    }, 0]
    return s
  }
}
export class CodexTableCell extends TableCell {
  get schema() {
    const s = super.schema
    s.attrs = { blockId: { default: '' }, ...s.attrs }
    s.parseDOM = [{
      tag: 'td',
      getAttrs: dom => ({
        ...JSON.parse(dom.dataset?.attrs || '{}'),
        blockId: getBlockIdFromDom(dom),
      }),
    }]
    s.toDOM = node => ['td', {
      'data-id': node.attrs.blockId,
      'data-attrs': JSON.stringify(node.attrs) || '{}',
    }, 0]
    return s
  }
}

export class CodexTableRow extends TableRow {
  get schema() {
    const s = super.schema
    s.attrs = { blockId: { default: '' }, ...s.attrs }
    s.parseDOM = [{
      tag: 'tr',
      getAttrs: dom => ({
        ...JSON.parse(dom.dataset?.attrs || '{}'),
        blockId: getBlockIdFromDom(dom),
      }),
    }]
    s.toDOM = node => ['tr', {
      'data-id': node.attrs.blockId,
      'data-attrs': JSON.stringify(node.attrs) || '{}',
    }, 0]
    return s
  }
}
