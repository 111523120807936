<template>
  <div>
    <b-form-group :label="$t('fields.codex-field-reference.appearance.one-reference')">
      <b-form-radio-group v-model="appearance">
        <label
          for="reference-field__single-line"
          class="reference-field__radio-button"
        >
          <div class="reference-field__radio-container">
            <div class="reference-field__radio-button-left">
              <GjIcon
                name="ListAlt"
                class="referece-field__icon"
                size="24"
                style="fill: #1D79F2"
              />
              <p class="mb-0 reference-field__radio-button-text">
                {{ $t('fields.codex-field-reference.appearance.entry-links') }}
              </p>
            </div>

            <div class="reference-field__radio-button-right">
              <b-form-radio
                id="reference-field__single-line"
                :value="1"
                class="mr-0"
              />
            </div>
          </div>
        </label>
      </b-form-radio-group>
    </b-form-group>

    <FieldHelpTextInput
      :widget="widget"
      :update-attrs="updateAttrs"
    />

    <div class="reference-field__disable-button">
      <label class="bold-text">
        {{ $t('fields.codex-field-reference.appearance.disable-create-button.message') }}
      </label>
      <p class="info">
        {{ $t('fields.codex-field-reference.appearance.disable-create-button.tooltip-message') }}
      </p>
      <b-form-radio-group v-model="disableCreateButton">
        <b-form-radio :value="true">
          {{ $t('fields.codex-field-reference.appearance.disable-create-button.yes') }}
        </b-form-radio>
        <b-form-radio :value="false">
          {{ $t('fields.codex-field-reference.appearance.disable-create-button.no') }}
        </b-form-radio>
      </b-form-radio-group>
    </div>
    <div class="reference-field__disable-button">
      <label class="bold-text">
        {{ $t('fields.codex-field-reference.appearance.disable-browse-button.message') }}
      </label>
      <p class="info">
        {{ $t('fields.codex-field-reference.appearance.disable-browse-button.tooltip-message') }}
      </p>
      <b-form-radio-group v-model="disableBrowseButton">
        <b-form-radio :value="true">
          {{ $t('fields.codex-field-reference.appearance.disable-browse-button.yes') }}
        </b-form-radio>
        <b-form-radio :value="false">
          {{ $t('fields.codex-field-reference.appearance.disable-browse-button.no') }}
        </b-form-radio>
      </b-form-radio-group>
    </div>

    <FieldConditions
      v-model="hidden"
      :widget="widget"
      :label="$t('fields.general.hidden.label')"
      :show-error-message="false"
      :tooltip="$t('fields.general.hidden.info')"
    />
  </div>
</template>

<script>
import { generateComputedPropsFromAttrs } from '@/components/codex-layout-editor/BuilderUtils'
import { VALUE_TYPES } from '@/views/models/constants'
import FieldHelpTextInput from '@/components/fields/FieldHelpTextInput.vue'
import BaseFieldMixin from '@/components/fields/BaseFieldMixin'
import FieldConditions from '@/components/fields/FieldConditions.vue'

export default {
  name: 'Appearance',
  components: { FieldConditions, FieldHelpTextInput },
  mixins: [BaseFieldMixin],
  data() {
    return {
      VALUE_TYPES,
    }
  },
  computed: {
    ...generateComputedPropsFromAttrs([
      'valueType',
      'appearance',
      'validation',
      'disableCreateButton',
      'disableBrowseButton',
      'type',
      'helpText',
      'hidden',
    ]),
  },
  mounted() {
    if (this.disableCreateButton === undefined) {
      this.disableCreateButton = false
    }
  },
}
</script>

<style lang="scss" scoped>
.reference-field__show-link-title {
  font-size: 14px;
  font-weight: 400;
  color: #031c3c;
}

.reference-field__unique-description {
  font-size: 14px;
  color: #667C99;

  opacity: .6;
}

.reference-field__radio-button {
  width: 100%;

  margin-bottom: 8px;

  cursor: pointer;
  height: 40px;
}

.reference-field__radio-button-left {
  display: flex;
}

.reference-field__radio-button-text {
  margin-left: 8px;
  font-size: 12px;
  font-weight: bold;
}

.reference-field__radio-container {
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  height: 100%;

  padding: 4px 8px;
  padding-right: 0;

  border: 1px solid #E0E5EB;
  border-radius: 4px;
}

.reference-field__error-text {
  font-size: 14px;
  color: red;
  margin-bottom: 0;
}

.referece-field__icon {
  box-shadow: 0px 4px 8px rgba(163, 176, 194, 0.32);
  border-radius: 3px;
}

.reference-field__disable-button {
  font-size: 14px;
  margin-top: 20px;
  margin-bottom: 1rem;

  .bold-text {
    font-weight: 600;
  }

  .info {
    margin-top: 5px;
    font-size: 14px;
    color: #667C99;
    opacity: .6;
  }
}
</style>
