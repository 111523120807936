<template>
  <div class="new-filters-dropdown__filter-body new-filters-dropdown__filter-body--scroll">
    <b-row
      align-v="center"
      no-gutters
    >
      <b-col cols="12">
        <b-checkbox-group
          v-model="value.value"
          class="new-filters-dropdown__checkbox-group"
          :options="options"
          stacked
        />
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { mapUrlEntityType, URL_ENTITY_TYPES } from '@/codex-sdk/urls'

export default {
  props: {
    filter: {
      type: Object,
      required: true,
    },
    filterObject: {
      type: [Object, Function],
      required: true,
    },
    value: {
      type: Object,
      required: true,
    },
    cache: {
      type: Object,
      required: true,
    },
    setCache: {
      type: Function,
      required: true,
    },
  },
  computed: {
    options() {
      return this.filter?.entities?.length
        ? this.filter.entities
        : [
          {
            value: URL_ENTITY_TYPES.SECTION,
            text: this.$t(mapUrlEntityType(URL_ENTITY_TYPES.SECTION)),
          },
          {
            value: URL_ENTITY_TYPES.ENTRY,
            text: this.$t(mapUrlEntityType(URL_ENTITY_TYPES.ENTRY)),
          },
        ]
    },
  },
}
</script>
