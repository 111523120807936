<template>
  <div class="link-popup">
    <div class="link-popup__content">
      <div class="link-popup__content-input">
        <b-input-group size="sm">
          <b-form-input
            :value="value"
            :placeholder="$t('codex-editor.panels.wrapper-panel.url')"
            @input="(v) => {onInputValue(v)}"
            @keyup.enter="() => insertLink(value)"
          />
          <b-input-group-append is-text>
            <GjIcon
              v-if="value && isUrl(value)"
              size="16"
              class="link-popup__content-input-icon mr-50"
              name="ArrowExportPopout"
              @click.native="() => openLink(value)"
            />
            <GjIcon
              v-if="(!isEditing || changedUrl) && isValid(value)"
              :key="`check-${changedUrl}`"
              name="Check"
              class="link-popup__content-input-icon"
              size="18"
              @click.native="() => insertLink(value)"
            />
            <GjIcon
              v-if="isEditing && !changedUrl"
              :key="`delete-${changedUrl}`"
              name="DeleteTrash"
              class="link-popup__content-input-icon"
              size="18"
              @click.native="() => insertLink('')"
            />
          </b-input-group-append>
        </b-input-group>

        <div class="link-popup__content-input-link-wrapper">
          <a
            v-if="showLinkToEntry && (toolbarOptions.links.codex || !hasValidations)"
            href="javascript:void(0);"
            class="link-popup__content-input-link"
            @click="linkToEntry"
          >
            {{ $t('codex-editor.panels.wrapper-panel.link') }}
          </a>
        </div>
      </div>
    </div>
    <hr class="link-popup__break">
    <div class="link-popup__config">
      <b-form-checkbox
        v-model="targetBlank"
        class="link-popup__config-checkbox"
        switch
        @change="() => insertLink(value)"
      >
        {{ $t('codex-editor.panels.wrapper-panel.target-blank') }}
      </b-form-checkbox>

      <b-form-checkbox
        v-model="noopener"
        class="link-popup__config-checkbox"
        switch
        @change="() => insertLink(value)"
      >
        {{ $t('codex-editor.panels.wrapper-panel.rel-noopener') }}
      </b-form-checkbox>
      <b-form-checkbox
        v-model="nofollow"
        class="link-popup__config-checkbox"
        switch
        @change="() => insertLink(value)"
      >
        {{ $t('codex-editor.panels.wrapper-panel.rel-nofollow') }}
      </b-form-checkbox>
      <b-form-checkbox
        v-model="noreferrer"
        class="link-popup__config-checkbox"
        switch
        @change="() => insertLink(value)"
      >
        {{ $t('codex-editor.panels.wrapper-panel.rel-noreferrer') }}
      </b-form-checkbox>
    </div>
  </div>
</template>

<script>
import { readModel } from '@/codex-sdk/models'
import { readEntry } from '@/codex-sdk/entries'
import { TYPES, VALUE_TYPES } from '@/views/models/constants'

export default {
  // eslint-disable-next-line vue/require-prop-types
  props: ['articles', 'value', 'linkAttrs', 'isEditing', 'changedUrl', 'activeLink', 'insert'],
  inject: ['showEntriesPopup', 'includeModels', 'toolbarOptions'],
  data() {
    return {
      targetBlank: false,
      noopener: false,
      nofollow: false,
      noreferrer: false,
      // eslint-disable-next-line global-require
      customLinkImage: require('../../icons/marks/link.svg'),
      showCustomLink: false,
      showLinkToEntry: this.includeModels?.length > 0,
    }
  },
  computed: {
    hasValidations() {
      return this.toolbarOptions.links.external || this.toolbarOptions.links.phone || this.toolbarOptions.links.email || this.toolbarOptions.links.codex
    },
  },
  mounted() {
    if (this.linkAttrs) {
      this.targetBlank = this.linkAttrs?.target === '_blank'
      this.noopener = !!this.linkAttrs?.noopener
      this.nofollow = !!this.linkAttrs?.nofollow
      this.noreferrer = !!this.linkAttrs?.noreferrer
    }
  },
  methods: {
    async getEntryUrl(entry) {
      try {
        const { data: model } = await readModel(entry?.system?.model?.id);
        ({ data: entry } = await readEntry({ model: entry?.system?.model?.alias, id: entry?.id }))
        const urlField = model?.fields?.find(f => f.type === TYPES.URL && f.valueType === VALUE_TYPES.SINGLE)
        const url = entry?.content?.[urlField?.alias]

        if (url) {
          return url
        }
        return `#${entry?.system?.slug}`
      } catch (e) {
        return `#${entry?.system?.slug}`
      }
    },

    async linkToEntry() {
      const entry = await this.showEntriesPopup({ limit: 1, includeCustomSiteFilter: true, models: this.includeModels })
      if (entry) {
        const url = await this.getEntryUrl(entry[0])
        this.insert({
          ...this.linkAttrs, href: url, target: this.targetBlank && '_blank', noopener: this.noopener, nofollow: this.nofollow, noreferrer: this.noreferrer, ...{ entryId: entry[0].id },
        })
      }
    },

    // searchArticles(search) { this.$emit('searchArticles', search) },
    inputValue(value) { this.$emit('input', value) },
    insertLink(link, attrs = {}) {
      // trim
      link = link?.trim()

      if (this.isValid(link) || link === '') {
        if (this.isUrl(link)) {
          // add http if not exist
          link = this.addHttpsProtocol(link)
        }

        this.inputValue(link)

        this.insert({
          ...this.linkAttrs, href: link, target: this.targetBlank && '_blank', noopener: this.noopener, nofollow: this.nofollow, noreferrer: this.noreferrer, ...attrs,
        })
      }
    },
    isUrl(value) {
      return new RegExp('^(?:(?:https?):\\/\\/)?(?:www\\.)?((?!@)[a-zA-Z0-9_-]+(\\.[a-zA-Z0-9_-]+)+)(\\/[-a-zA-Z0-9@:%_\\+.~#?&//=]*)?$').test(value)
    },
    isPhone(value) {
      return value.startsWith('tel:') && /([+(\d]{1})(([\d+() -.]){5,16})([+(\d]{1})/gm.test(value.slice(4).trim())
    },
    isEmail(value) {
      return value.startsWith('mailto:') && /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g.test(value.slice(7).trim())
    },
    isValid(value) {
      if (!this.hasValidations) return true

      const validUrl = this.toolbarOptions.links.external && this.isUrl(value)
      const validPhone = this.toolbarOptions.links.phone && this.isPhone(value)
      const validEmail = this.toolbarOptions.links.email && this.isEmail(value)

      return validUrl || validPhone || validEmail
    },
    addHttpsProtocol(url) {
      // Check if the URL already has a protocol
      if (!/^(http:|https:|ftp:|file:|mailto:|tel:)/.test(url)) {
        return `https://${url}`
      }
      return url
    },
    openLink(link) {
      const url = this.addHttpsProtocol(link)
      window.open(url, '_blank').focus()
    },
    onInputValue(value) {
      this.inputValue(value)
      this.showCustomLink = true
    },
  },
}
</script>

<style lang="scss">
.link-popup {
  width: 400px;
  border: 1px solid #E0E5EB;
  border-radius: 4px;
  .link-popup__content-input-link-wrapper {
    margin-top: 3px;
    text-align: left;
  }
  .link-popup__content-input-link {
    font-size: 13px;
    display: inline-block;
  }
  .link-popup__content {
    padding: 14px;
    .link-popup__content-input {
      input {
        border: 1px solid #d8d6de !important;
        border-right: none !important;
        border-top-left-radius: 0.25rem !important;
        border-bottom-left-radius: 0.25rem !important;
        &:focus {
          border-color: #1D79F2 !important;
        }
        &::placeholder{
          color: #A3B0C2;
          font-size: 14px;
        }
      }
      .link-popup__content-input-icon{
        color: #E0E5EB;
        cursor: pointer;
        &:hover {
          color: #1D79F2;
        }
      }
    }
    .link-popup__content-items{
      margin-top: 14px;
      max-height: 110px;
      overflow-y: auto;
      .link-popup__content-item{
        color: #667c99;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin: 2px 0px;
        padding: 4px 0px;
        &:hover{
          background-color: #f8f8f8;
          cursor: pointer;
        }
        .link-popup__content-item-icon{
          width: 36px;
          height: 36px;
          border-radius: 4px;
          overflow: hidden;
          margin-right: 10px;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .link-popup__content-item-info{
          flex-basis: 70%;
          .link-popup__content-item-name{
            font-size: 14px;
            line-height: 18px;
            color: #052D61;
            max-width: 310px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .link-popup__content-item-link {
            font-size: 11px;
            color: #667C99;
            display: flex;
            align-items: center;
            &:hover {
              color: #1D79F2;
            }
            .link-popup__content-item-link-name{
              max-width: 295px;
              white-space: nowrap;
              margin-right: 4px;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
      }
    }
  }
  .link-popup__config {
    padding: 14px;
    .link-popup__config-checkbox{
      height: 27px;
      input {
        height: 27px;
      }
      input:checked ~ .custom-control-label {
        &::after {
          transform: translateX(12px) !important;
          background-color: #fff;
          top: 6px !important;
        }
        &::before {
          background-color: #1D79F2;
        }
      }
      label.custom-control-label {
        font-size: 12px !important;
        color: #667C99 !important;
        padding-left: 3rem;
        margin: 0;
        &:before {
          height: 22px !important;
          width: 36px !important;
          border: 1px solid #DADFE7;
          background-color: transparent;
          top: 3px !important;
        }
        &::after {
          background-color: #DADFE7;
          top: 6px !important;
          left: 5px;
          width: 14px;
          height: 14px;
        }
      }
    }
  }
  .link-popup__break {
    margin: 0;
  }
  .link-popup__custom-link{
    color: #667c99;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 14px;
    padding: 2px 0px;
    &:hover{
      background-color: #f8f8f8;
      cursor: pointer;
    }
    .link-popup__custom-link-icon{
      width: 36px;
      height: 36px;
      border-radius: 4px;
      overflow: hidden;
      margin-right: 10px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .link-popup__custom-link-info{
      flex-basis: 70%;
      .link-popup__custom-link-name{
        font-size: 14px;
        line-height: 18px;
        color: #052D61;
      }
      .link-popup__custom-link-link {
        font-size: 11px;
        color: #667C99;
        display: flex;
        align-items: center;
        .link-popup__custom-link-link-name{
          max-width: 305px;
          white-space: nowrap;
          margin-right: 4px;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
}
</style>
