<template>
  <ReferenceComponent
    v-model="references"
    :loading="loading"
    :models="modelAlias ? [modelAlias] : null"
    :include-site-logo="true"
    @remove="removeEntry"
    @add="showEntriesPop"
  />
</template>

<script>
import ReferenceComponent from '@/components/fields/Reference/ReferenceComponent.vue'
import gql from 'graphql-tag'
import { ENTRY_STATUSES, transformEntriesGraphQL } from '@/codex-sdk/entries'
import { mapGetters } from 'vuex'

export default {
  inject: ['showEntriesPopup', 'deleteBlock', 'includeModels'],
  components: {
    ReferenceComponent,
  },
  props: ['attrs', 'updateAttrs', 'selectNode', 'selected'],
  data() {
    return {
      references_: [],
      loading: false,
    }
  },
  computed: {
    ...mapGetters('sites', [
      'getSite',
    ]),
    references: {
      get() { return this.references_ },
      set(v) {
        this.references_ = v
        this.updateValue()
      },
    },
    computedValue: {
      get() { return this.attrs.references },
      set(value) { this.updateAttrs({ references: [...value] }) },
    },
    modelAlias: {
      get() { return this.attrs.modelAlias },
      set(value) { this.updateAttrs({ modelAlias: value }) },
    },
    entryIds() {
      return this.references.map(entry => entry.id)
    },
  },
  created() {
    this.fetchEntries()
  },
  mounted() {
    this.$root.$on('entry-updated', this.refetchEntries)
  },
  destroyed() {
    this.$root.$off('entry-updated', this.refetchEntries)
  },
  methods: {
    updateValue() {
      this.computedValue = this.references.map(e => ({
        entryId: e.id,
        model: e.system.modelAlias,
      }))
    },
    refetchEntries() {
      setTimeout(() => {
        this.references_ = []
        this.fetchEntries()
      }, 1000)
    },
    initialize() {
      if (this.computedValue && this.computedValue.length !== 0) return
      this.showEntriesPop()
    },
    async showEntriesPop() {
      const result = await this.showEntriesPopup({
        models: this.modelAlias ? [this.modelAlias] : this.includeModels, excludeIds: this.entryIds, includeCustomSiteFilter: true, addOnPublish: true,
      })
      if (result) {
        if (this.references.length > 0) {
          this.references = [...this.references, ...result]
        } else {
          this.references = result
        }
        this.selectNode()
      } else if (!this.references.length) {
        this.deleteBlock(this.attrs.blockId)
      }
    },
    removeEntry(index) {
      this.references.splice(index, 1)
      this.updateValue()
    },
    async fetchEntries() {
      if (this.computedValue && this.computedValue.length) {
        this.loading = true
        try {
          const { data: apolloData } = await this.$apollo.query({
            query: gql`
              query getEntries2($ids: [String!]) {
                entryCollection (where: { id: {in: $ids }}) {
                  items {
                    id
                    system {
                      title
                      status
                      siteId
                      modelId
                      modelAlias
                      featuredMedia {
                        id
                        url(transformation: { width: 70, height: 70, format: THUMBNAIL })
                      }
                      scheduledVersions (where: {
                        status: { in: [SCHEDULED] }
                      }) {
                        items {
                          id
                          versionId
                          publishScheduledDate
                          unpublishScheduledDate
                          versionId
                          createdAt
                        }
                      }
                    }
                  }
                }
              }
            `,
            variables: {
              ids: this.computedValue.map(e => e.entryId),
            },
          })
          if (apolloData?.entryCollection?.items) {
            transformEntriesGraphQL(apolloData.entryCollection.items)
          }

          this.computedValue.forEach(entry => {
            let item = apolloData.entryCollection?.items?.find(e => e.id === entry.entryId)
            if (!item) {
              item = {
                id: entry.entryId,
                system: {
                  status: ENTRY_STATUSES.DELETED,
                  site: {},
                  modelAlias: entry.model,
                  model: {
                    name: entry.model,
                    iconId: 'Modeling',
                    alias: entry.model,
                  },
                },
              }
            }
            item.system.site = this.getSite(item.system.siteId)
            this.references_.push(item)
          })
        } catch (e) {
          console.log(e)
        }
        this.loading = false
      }
    },
  },
}
</script>
