import FolderFilterComponent from './Folder.vue'

export default class FolderFilter {
  static COMPONENT = FolderFilterComponent;

  static type = 'folder'

  static OPERATORS = {
    all: 'all',
    some: 'some',
  }

  operator = null;

  value = null;

  constructor({ operator = FolderFilter.OPERATORS.some, value = [] } = {}) {
    this.operator = operator
    this.value = value
  }

  set({ operator = FolderFilter.OPERATORS.some, value = [] }) {
    this.operator = operator
    this.value = value
  }

  getCount() {
    return this.value.length
  }

  getValueLabel({ cache }) {
    const folder = this.value.map(folderId => cache[folderId] || { id: folderId })
    return folder.map(m => m.name).join(', ')
  }

  toLoad() {
    return {
      folders: this.value,
    }
  }

  asQueryParam() {
    return {
      operator: this.operator,
      value: this.value,
    }
  }

  asGraphQL() {
    switch (this.operator) {
      case FolderFilter.OPERATORS.all:
        return {
          all: this.value,
        }
      case FolderFilter.OPERATORS.some:
        return {
          some: this.value,
        }
      default:
        return null
    }
  }

  static validate({ operator, value }) {
    if (!operator) {
      return 'Operator is required'
    }
    if (!value || value.length === 0) {
      return 'Value is required'
    }

    return true
  }

  static fromQueryParam({ operator, value }) {
    return new FolderFilter({
      operator,
      value,
    })
  }
}
