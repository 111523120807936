<template>
  <section>
    <div
      v-if="medias && medias.length"
      class="media-block__label-edit"
    >
      <div
        v-if="!readOnly"
        class="media-block__edit-button hide-element"
        @click="showUp"
      >
        <img
          class="media-block__edit-icon"
          src="../../../../assets/icons/pencil.svg"
          alt="media block button where you can open the media adder"
        >
        <span class="media-block__edit-text">{{ $t('codex-editor.nodes.media.edit-assets') }}</span>
      </div>
      <span>
        <GjIcon
          :color="showImageDetails ? '#1D79F2' : '#455A82'"
          name="CaptionBelow"
          class="caption__button"
          @click.native="showImageDetails = !showImageDetails"
        />
      </span>
      <span
        v-if="medias && medias.length > 5"
        class="media-block__expandable-parent"
      >
        <GjIcon
          v-b-tooltip.hover.html.top="!expand ? $t('fields.codex-field-media-content.preview.expand-media') : $t('fields.codex-field-media-content.preview.collapse-media') "
          name="ArrowExpand"
          size="16"
          class="expandable__button"
          @click.native="expand = !expand"
        />
      </span>
    </div>
    <div
      class="media-block"
      :class="getMediaClasses()"
      @click="selectNode"
    >
      <AssetViewPopup
        v-if="assetId"
        :assets="medias"
        :total="medias.length"
        :asset-id="assetId"
        @close="assetId = null"
      />
      <div
        v-show="medias.length > 0"
        class="media-block__draggable"
      >
        <DraggableAssets
          v-show="medias.length > 1"
          v-model="medias"
          :name="generateName"
          type="multiple"
          :expand="medias && medias.length > 5 ? expand : false"
          :show-image-details="showImageDetails"
          :disabled="readOnly"
          @edit="editMedia"
          @metadata="editMediaMetadata"
          @delete="deleteMedia"
          @showAsset="setAssetId"
          @focalPoint="focalPoint"
        />
      </div>

      <ResizableBlock
        v-show="medias.length === 1"
        :attrs="attrs"
        :selected="selected"
        :select-node="selectNode"
        :update-attrs="updateAttrs"
        :entity-ref="$refs['media-block']"
        :include-caption="false"
        :class="{'selected': selected}"
        class="media-block__medias"
      >
        <div ref="media-block">
          <div
            v-for="media in medias"
            :key="media.id"
            class="media-block__media"
            @click="setPlayer(media)"
          >
            <div
              v-if="isDeleted(media)"
              class="draggable-media__file deleted-media media__image-container"
            >
              <span class="deleted-asset"> <GjIcon
                name="HideAlt"
                size="28"
                style="fill: #9595A0;"
              /></span>
            </div>
            <b-img-aspect
              v-else-if="isLive(media) && media.id !== playingId"
              :key="media.url"
              aspect="16/9"
              :src="require('@/assets/images/icons/livestream.png')"
            />
            <b-aspect
              v-else-if="showMediaType(media.type) && media.id !== playingId && !isFailedLoading(media.url)"
              :key="`${media.type}-${media.id}`"
              aspect="16/9"
              class="position-relative align-items-center justify-content-center media-block__image"
            >
              <div class="media__image-container">
                <b-img
                  :src="media.url"
                  :fluid="true"
                  @error="() => failedImages.push(media.url)"
                />
              </div>

              <div
                v-if="media.type !== ASSET_TYPES.IMAGE"
                class="video__play-icon-container"
              >
                <div
                  class="video__play-icon"
                >
                  <GjIcon
                    :name="typeIcon(media.type)"
                    size="24"
                  />
                </div>

                <div
                  v-if="isLive(media)"
                  class="video__live-stream-icon"
                >
                  <div class="video__live-stream-icon__dot" />
                  <span class="video__live-stream-icon__text">
                    {{ $t('assets.videos.live') }}
                  </span>
                </div>
                <div class="video__icons">
                  <div class="video__icon">
                    <img
                      src="@/assets/icons/vp-icon.svg"
                      class="video__vp-icon"
                      alt=""
                    >
                  </div>
                  <div
                    v-if="isLive(media)"
                    class="video__icon"
                  >
                    <GjIcon
                      name="Livestream - main"
                      size="16"
                    />
                  </div>
                </div>
              </div>
            </b-aspect>
            <b-aspect
              v-else-if="media.type === ASSET_TYPES.VIDEO && media.id === playingId"
              :key="media.id"
              aspect="16/9"
            >
              <iframe
                :src="media.videoUrl"
                style="width: 100%;height: 100%;"
                allow="autoplay *; encrypted-media *; fullscreen *"
                frameborder="0"
                sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-storage-access-by-user-activation allow-top-navigation-by-user-activation"
              />
            </b-aspect>
            <div
              v-else
              class="media-block__media-background"
            >
              <img
                v-if="media.type === ASSET_TYPES.FILE || media.type === ASSET_TYPES.IMAGE"
                :src="require('@/assets/images/icons/file.svg')"
              >
              <img
                v-else-if="media.type === ASSET_TYPES.VIDEO_PLAYLIST || media.type === ASSET_TYPES.VIDEO"
                :src="require('@/assets/images/icons/video-playlist.svg')"
              >
              <img
                v-else-if="media.type === ASSET_TYPES.AUDIO"
                :src="require('@/assets/images/icons/audio.svg')"
              >
              <img
                v-else-if="media.type === ASSET_TYPES.PODCAST"
                :src="require('@/assets/images/icons/podcast.svg')"
              >
            </div>
            <div
              class="media-overlay--resizeable"
            >
              <div class="media-overlay__left">
                <div>
                  <div
                    v-show="media.width && media.height"
                    class="media-overlay__resolution"
                  >
                    {{ media.width && media.height ? `${media.width} x ${media.height}` : '' }}
                  </div>
                </div>
              </div>
              <div class="media-overlay__right">
                <div
                  v-if="isDeleted(media)"
                  class="media-overlay__settings"
                >

                  <GjIcon
                    size="20px"
                    name="DeleteTrash"
                    @click.native.stop="$emit('delete', media.id)"
                  />

                </div>
                <div
                  v-else
                  class="media-overlay__settings"
                >
                  <span
                    v-if="!readOnly"
                    v-permission="['canEditAsset', { id: media.id }]"
                    class="hide-element"
                    @click.stop="editMediaMetadata(media)"
                  >
                    <GjIcon
                      size="20px"
                      name="Edit"
                    />
                  </span>
                  <span
                    v-if="media.type === ASSET_TYPES.IMAGE && !readOnly"
                    v-permission="['canUploadAsset', { folderId: media.folderId }]"
                    class="hide-element"
                    @click.stop="editMedia(media)"
                  >
                    <GjIcon
                      size="20px"
                      name="Crop"
                    />
                  </span>
                  <GjIcon
                    v-if="media.type === ASSET_TYPES.IMAGE && !readOnly"
                    class="hide-element"
                    name="IconparkFocus"
                    size="20px"
                    @click.native.stop="focalPoint(media)"
                  />
                  <GjIcon
                    size="20px"
                    name="Show"
                    @click.native.stop="setAssetId(media.id)"
                  />
                  <GjIcon
                    v-if="!readOnly"
                    class="hide-element"
                    size="20px"
                    name="DeleteTrash"
                    @click.native.stop="deleteMedia(media.id)"
                  />
                </div>
                <div
                  v-if="media.expiresAt && playingId !== media.id"
                  class="media-overlay__expire"
                >
                  <GjIcon
                    v-if="checkExpired(media.expiresAt)"
                    class="media-overlay__expire-expired"
                    size="16"
                    name="Denied"
                  />
                  <GjIcon
                    v-if="!checkExpired(media.expiresAt)"
                    v-b-tooltip
                    :title="media.expiresAt | formatDateTime"
                    class="media-overlay__expire-info"
                    name="Info_fill"
                    size="16"
                  />
                </div>
              </div>
            </div>

            <div v-if="showImageDetails">
              <CaptionInput
                v-model="caption"
                :asset-caption="media.caption"
                @input="medias = [...medias]"
              />
              <AssetDetails :media="media" />
            </div>
          </div>
        </div>
        <!-- <div
          v-show="medias.length > 0"
          class="media-block__caption"
        >
          <b-form-textarea
            v-model="caption"
            placeholder="Add Caption"
            type="text"
            rows="1"
            max-rows="3"
            no-resize
          />
        </div> -->
      </ResizableBlock>
      <div
        v-show="medias.length < 1"
        class="media-block__no-data"
      >
        {{ $t('codex-editor.nodes.media.no-media') }}
        <span
          class="media-block__add-media"
          @click="showUp"
        >
          <GjIcon name="Plus" />
          {{ $t('codex-editor.nodes.media.add-media') }}
        </span>
      </div>

    </div>
  </section>
</template>

<script>
import { $IMAGE_SIZES } from '@/utils/constants'
import ResizableBlock from '@/components/ResizableBlock.vue'
import gql from 'graphql-tag'
import { ASSET_TYPE_MAPPING, ASSET_TYPES, ASSET_STATUSES } from '@/codex-sdk/assets'
import { debounce } from 'lodash'
import DraggableAssets from '@/components/assets/DraggableAssets.vue'
import AssetViewPopup from '@/components/assets/AssetViewPopup.vue'
import AssetDetails from '@/components/assets/AssetDetails.vue'
import CaptionInput from '@/components/assets/CaptionInput.vue'
import moment from 'moment'

export default {
  components: {
    AssetViewPopup,
    ResizableBlock,
    DraggableAssets,
    AssetDetails,
    CaptionInput,
  },
  props: ['attrs', 'updateAttrs', 'selectNode', 'selected'],
  inject: ['deleteBlock', 'showPromptPopup', 'toastNotification', 'showEditAssetPopup', 'showImageEditorPopup', 'showAssetsPopup', 'showFocalPointPopup', 'isReadOnly'],
  data() {
    return {
      assetId: null,
      ASSET_TYPES,
      showImageDetails: false,
      $IMAGE_SIZES,
      expand: false,
      playingId: null,
      failedImages: [],

      refetch: debounce(function () {
        this.$apollo.queries.mediaItems.refetch()
      }, 1000),
    }
  },
  apollo: {
    mediaItems: {
      query: gql`
        query mediaItems ($limit: Int, $ids: [String!]) {
          assetCollection (limit: $limit, where: {id: {in: $ids } }) {
            items {
              id
                title
                expiresAt
                fileName
                folderId
                type
                author
                source
                alt
                caption
                copyright
                tags
                width
                height
                url(transformation: {width: 400, quality: 80, format: THUMBNAIL})
                videoUrl: url(transformation: {format: HTML})
                createdBy {
                  id
                  firstName
                  lastName
                  email
                  imageUrl
                }
                updatedBy {
                  id
                  firstName
                  lastName
                  email
                  imageUrl
                }
                labels {
                  id
                  name
                  color
                }
                createdAt
                updatedAt
                status
                attrs
            }
          }
        }
      `,
      update(data) {
        const mediaItems = {}
        data.assetCollection.items.forEach(item => {
          mediaItems[item.id] = item
        })
        this.medias.forEach(m => {
          if (!mediaItems[m.id]) {
            mediaItems[m.id] = {
              id: m.id,
              status: ASSET_STATUSES.DELETED,
              labels: [],
            }
          }
        })
        return mediaItems
      },
      variables() {
        return {
          ids: this.medias.map(media => media.id),
          limit: this.medias?.length,
        }
      },
    },
  },
  computed: {
    isFailedLoading() {
      return mediaUrl => {
        // check if failedImages contains mediaUrl
        const found = this.failedImages.find(img => img === mediaUrl)
        return !!found
      }
    },
    readOnly() {
      return false
    },
    alignClass() {
      return `${this.align}`
    },
    generateName() {
      const randomId = Math.random().toString(36).substr(2, 6)
      return `rich-content-${randomId}`
    },
    medias: {
      get() {
        return this.attrs.media.map(item => {
          if (this.mediaItems && this.mediaItems[item.id]) {
            return {
              ...item,
              ...this.mediaItems[item.id],
              customCaption: item.caption,
            }
          }
          return item
        })
      },
      set(v) {
        this.$emit('input', this.updateAttrs({
          media: v.map((item => ({
            id: item.id, type: ASSET_TYPE_MAPPING.toNumber(item.type) || item.type, caption: item.customCaption, focalPoints: item.focalPoints,
          }))),
        }))
      },
    },
    align: {
      get() {
        return this.attrs.align
      },
      set(e) {
        this.updateAttrs({ align: e })
      },
    },
    caption: {
      get() {
        return this.attrs.caption
      },
      set(e) {
        this.updateAttrs({ caption: e })
      },
    },
    typeIcon() {
      return type => {
        if (type === ASSET_TYPES.VIDEO) return 'Play'
        if (type === ASSET_TYPES.AUDIO) return 'IconparkAudioFile'
        if (type === ASSET_TYPES.PODCAST) return 'IconparkMicrophone'
        return 'IconparkFileQuestion'
      }
    },
  },
  methods: {
    getHeight(type) {
      if (type === ASSET_TYPES.AUDIO) {
        return '180'
      }
      if (type === ASSET_TYPES.PODCAST) {
        return '410'
      }
      return ''
    },
    async focalPoint(media) {
      const res = await this.showFocalPointPopup({
        imageId: media.id, focals: media.focalPoints, from: 'assets',
      })
      if (res) {
        this.medias = this.medias.map(m => {
          if (m.id === media.id) {
            return { ...m, focalPoints: res }
          }
          return m
        })
      }
    },
    checkExpired(time) {
      return moment().isAfter(time)
    },
    initialize() {
      if (this.medias.length > 0) return
      this.showUp()
    },
    setPlayer(media) {
      if (media.type === ASSET_TYPES.VIDEO) {
        this.playingId = media.id
      }
    },
    async showUp() {
      if (this.attrs.type) {
        await this.browseMedias({ types: Array.isArray(this.attrs.type) ? this.attrs.type : [this.attrs.type] })
      } else {
        await this.browseMedias()
      }
      if (this.medias.length < 1) {
        this.deleteBlock(this.attrs.blockId)
      }
    },
    async browseMedias(popupProps = {}) {
      const data = await this.showAssetsPopup({ selectedAssets: this.medias, ...popupProps })
      if (!data || data?.trigger == 'headerclose') { return }
      const filteredData = data.map(i => {
        const mediaItem = this.medias.find(m => m.id === i.id)
        if (mediaItem) {
          return {
            ...mediaItem,
            type: ASSET_TYPE_MAPPING.toNumber(i.type),
            caption: '',
          }
        }
        return {
          id: i.id,
          type: ASSET_TYPE_MAPPING.toNumber(i.type),
          caption: '',
          focalPoints: { default: i.focalPoint || { x: 0.5, y: 0.5 } },
        }
      })
      if (filteredData) {
        if (filteredData.length > 1) {
          this.updateAttrs({ align: 'left' })
        }
        this.updateAttrs({ media: [...filteredData] })
      }
    },
    async editMedia(media) {
      const data = await this.showImageEditorPopup({ imageId: media.id })
      if (data?.id) {
        const asset = this.attrs.media.find(a => a.id === media.id)
        if (asset) {
          Object.assign(asset, { id: data.id, type: data.type })
          this.refetch()
        }
      }
    },
    async editMediaMetadata(media) {
      const updatedMedia = await this.showEditAssetPopup(media)
      Object.assign(this.medias.find(m => m.id === media.id), updatedMedia)
      await this.$apollo.queries.mediaItems.refetch()
    },
    deleteMedia(idOrIds) {
      if (Array.isArray(idOrIds)) {
        this.medias = this.medias.filter(i => !idOrIds.includes(i.id))
        return
      }

      this.medias = this.medias.filter(i => i.id !== idOrIds)
    },
    setAssetId(assetId) {
      this.assetId = assetId
    },
    getMediaClasses() {
      return {
        'media-block--single': this.medias.length === 1,
        'media-block--multiple': this.medias.length > 1,
        'media-block--blank': this.medias.length < 1,
        'media-block--left': this.alignClass === 'left' && this.medias.length === 1,
        'media-block--right': this.alignClass === 'right' && this.medias.length === 1,
        'media-block--center': this.alignClass === 'center' && this.medias.length === 1,
        'media-block--selected': this.selected,
      }
    },
    showMediaType(type) {
      if (Number.isInteger(type)) {
        const keys = Object.keys(ASSET_TYPES)
        return keys.some(val => (ASSET_TYPES[val] === type && (val === ASSET_TYPES.IMAGE || val === ASSET_TYPES.VIDEO || val === ASSET_TYPES.AUDIO || val === ASSET_TYPES.PODCAST)))
      }
      return ASSET_TYPES[type] === ASSET_TYPES.IMAGE || ASSET_TYPES[type] === ASSET_TYPES.VIDEO || ASSET_TYPES[type] === ASSET_TYPES.AUDIO || ASSET_TYPES[type] === ASSET_TYPES.PODCAST
    },
    isLive(media) {
      let type
      if (Number.isInteger(media.type)) {
        const keys = Object.keys(ASSET_TYPES)
        keys.forEach(val => {
          if (ASSET_TYPES[val] === media.type) type = val
        })
        return type
      }
      return (media.type === ASSET_TYPES.VIDEO || type === ASSET_TYPES.VIDEO) && media.attrs && (media.attrs.isLive === 'true' || media.attrs.isLive === true)
    },
    isDeleted(media) {
      return media.status === ASSET_STATUSES.DELETED
    },
  },
}
</script>

<style lang="scss">
.media-block__edit-button {
  margin-right: 2px;
}

.media-block__expandable-parent {
  border-left: 1px solid #E0E5EB;

  .expandable__button {
    width: 16px;
    height: 16px;
    margin-left: 4px;
    margin-bottom: 1px;
    pointer-events: all;
  }
}

.caption__button {
  cursor: pointer;
  pointer-events: all;
}

.media-block__label-edit {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-size: 14px;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 8px;

  span {
    margin-right: 2px
  }

  .media-block__edit-icon {
    width: 15px;
    height: 15px;
    margin-bottom: 2.4px;
    margin-right: 2px;
    padding: .8px;
  }
}

.media-block__wrapper {
  .resizable-block__element, .resizable-block__element-wrapper {
    width: 100%;
  }
}

.media-block {
  border: 1px solid #E0E5EB;
  border-radius: 4px;
  padding: 12px;
  display: flex;
  flex-direction: column;

  &.media-block--selected.media-block--multiple {
    .media-block__medias {
      background-color: #F4F6FA;
    }
  }

  &.media-block--center {
    align-items: center;
  }

  &.media-block--left {
    align-items: flex-start;
  }

  &.media-block--right {
    align-items: flex-end;
  }

}

.media-block .image-aspect {
  background-repeat: no-repeat;
  background-size: contain;
}

.media-block__caption {
  margin-top: 6px;
  width: 100%;

  textarea.form-control {
    width: 100%;
    border: 0px solid transparent;
    text-align: left;
    outline: none;
    font-weight: 400;
    font-size: 12px;
    color: #455A82;
    padding: 0 !important;
    overflow: hidden scroll;

    &::placeholder {
      color: #667C99;
    }
  }

  textarea.form-control:focus {
    width: 100%;
    border: 0px solid transparent;
    outline: none;
    font-weight: 400;
    font-size: 12px;
    color: #455A82;
    padding: 0 !important;

    &::placeholder {
      color: #667C99;
    }
  }
}

.media-block__draggable {
  width: 100%;

  @at-root .media-block--single & {
    width: auto;
  }
}

.media-block__medias {
  width: 100%;
  @at-root .media-block--single & {
    .b-aspect {
      //width: 461px;
      width: 100%;
    }
  }
  @at-root .media-block--multiple & {
    border: 1px dashed #B5C0CE;
    border-radius: 4px;
    padding: 16px;
    display: grid;
    grid-gap: 16px;
    grid-template-columns: repeat(auto-fill, 223px);
  }
}

.media-block__no-data {
  padding: 16px;
  background-color: #f6f7f9;
  border: 1px dashed #c8d0da;
  border-radius: 4px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.media-block__add-media {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  line-height: 18px;
  color: #1d79f2;
  cursor: pointer;
}

.media-block__media {
  position: relative;
  cursor: pointer;

  .center {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.media-block__media-bar {
  @at-root .media-block__media:hover & {
    opacity: 1;
  }
  opacity: 0;
  transition: all 0.3s ease-out;
  position: absolute;
  top: 4px;
  right: 4px;
  display: flex;
  flex-direction: column;
  color: white;
  z-index: 10;
  background: rgba(18, 37, 62, 0.5);
  backdrop-filter: blur(5px);
  padding: 8px 4px;
  border-radius: 4px;

  svg {
    &:not(:last-child) {
      margin-bottom: 12px;
    }

    cursor: pointer;
  }
}

.media-overlay--resizeable {
  position: absolute !important;
  top: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 6px;
  pointer-events: none;
  z-index: 1;

  .media-overlay__left, .media-overlay__right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: fit-content;
    gap: 4px;
  }
}

.video__play-icon-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  z-index: 1;
}

.video__play-icon {
  color: white;
  position: absolute;
  z-index: 1;
  pointer-events: all;
}

.media-block__media-background {
  background: #F4F6FA;
  padding: 40px;

  img {
    width: 100px;
  }
}

.media-block__image {
  width: 13rem;
  border-radius: 4px;
  object-fit: cover;
  background-color: rgba(0, 0, 0, 0.1);

  .media__image-container {
    width: 100%;
    padding-bottom: 56.25%;
    overflow: hidden;

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}
.deleted-asset{
  background: white!important;
  background-image: linear-gradient(to top, #EBDDFD, #F3F2F4  ) !important;
  padding:9px;
  border-radius:12px;

}
</style>
