<template>
  <div class="rich-content__list">
    <component
      :is="resolveHtmlTag(block)"
    >
      <li
        v-for="(item, index) in block.content"
        :key="`list-item-#${index}`"
      >
        <component
          :is="resolveRichContentComponent(itemContent.type)"
          v-for="(itemContent, contentIndex) in item.content"
          :key="`item-component-#${index}-${contentIndex}`"
          v-bind="getCustomProps(itemContent, isNestedItem = true)"
        />
      </li>
    </component>
  </div>
</template>

<script>
import { resolveHtmlTag, resolveRichContentComponent, getCustomProps } from '../richContentUtils'
import BlockquoteComponent from './BlockquoteComponent.vue'
import TextComponent from './TextComponent.vue'
import DefaultComponent from './DefaultComponent.vue'
import ReferenceField from '../ReferenceField.vue'
import MediaField from '../MediaField.vue'
import TableComponent from './TableComponent.vue'

export default {
  name: 'ListComponent',
  components: {
    BlockquoteComponent,
    TextComponent,
    DefaultComponent,
    ReferenceField,
    MediaField,
    TableComponent,
  },
  props: {
    block: {
      type: [Object],
    },
  },
  setup() {
    return {
      resolveHtmlTag,
      resolveRichContentComponent,
      getCustomProps,
    }
  },
}
</script>
