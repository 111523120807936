import i18n from '@/libs/i18n'
import { mapPresetResize, RESIZE_STRING } from '@/codex-sdk/presets'
import PresetResize from './PresetResize.vue'

export default class PresetResizeFilter {
  static COMPONENT = PresetResize;

  static type = 'presetResize'

  static OPERATORS = {
    include: 'include',
    exclude: 'exclude',
  }

  static OPTIONS = {
    [RESIZE_STRING.FIT]: i18n.t(mapPresetResize(RESIZE_STRING.FIT)),
    [RESIZE_STRING.FILL]: i18n.t(mapPresetResize(RESIZE_STRING.FILL)),
    [RESIZE_STRING.AUTO]: i18n.t(mapPresetResize(RESIZE_STRING.AUTO)),
  }

  operator = null;

  value = null;

  constructor({ operator = PresetResizeFilter.OPERATORS.include, value = [] } = {}) {
    this.operator = operator
    this.value = value
  }

  set({ operator = PresetResizeFilter.OPERATORS.include, value = [] }) {
    this.operator = operator
    this.value = value
  }

  getCount() {
    return this.value.length
  }

  getValueLabel() {
    return this.value.map(id => PresetResizeFilter.OPTIONS[id]).join(', ')
  }

  asQueryParam() {
    return {
      operator: this.operator,
      value: this.value,
    }
  }

  asGraphQL() {
    switch (this.operator) {
      case PresetResizeFilter.OPERATORS.include:
        return {
          in: this.value,
        }
      case PresetResizeFilter.OPERATORS.exclude:
        return {
          notIn: this.value,
        }
      default:
        return null
    }
  }

  static validate({ operator, value }) {
    if (!operator) {
      return 'Operator is required'
    }

    if (!value || value.length === 0) {
      return 'Value is required'
    }

    return true
  }

  static fromQueryParam({ operator, value }) {
    return new PresetResizeFilter({
      operator,
      value,
    })
  }
}
