// eslint-disable-next-line no-unused-vars
import ability from '@/libs/acl/ability'
import { checkPermissionAsync, pr } from './config'

// For regEx search in VSCode: canViewAsset|canCreateAsset|canEditAsset|canDeleteAsset

// * Create entity file, and can functions
// Import and add them to allCanFunctions object down below
// Add defaults into the permissions array down below
// Implement v-permission directives on neccessary buttons
// Implement permissions on entities sdk functions
// Implement permissions on navigation and route

export const Asset = Object.freeze({})

export const AssetTooltips = {}

/**
 * _options
 *    entity = undefined
 *    showToast = false
 */

/**
 * Can UPLOAD Asset
 * */
export async function canUploadAsset(_options = {}) {
  const { entity, ...options } = _options

  return pr(
    await checkPermissionAsync(entity.id, 'asset', 'upload', { folderId: entity.folderId }),

    'You have no permission to upload assets',

    options,
  )
}

/**
 * Can EDIT Asset
 * */
export async function canEditAsset(_options = {}) {
  const { entity, ...options } = _options

  return pr(
    await checkPermissionAsync(entity.id, 'asset', 'edit', { folderId: entity.folderId, status: entity.status }),

    'You have no permission to edit this asset',

    options,
  )
}

/**
 * Can EDIT Asset Labels
 * */
export async function canEditAssetLabels(_options = {}) {
  const { entity, ...options } = _options

  return pr(
    await checkPermissionAsync(entity.id, 'asset', 'editlabel', { folderId: entity.folderId, status: entity?.status }),

    'You have no permission to edit labels of this asset',

    options,
  )
}

/**
 * Can DELETE Asset
 * */
export async function canDeleteAsset(_options = {}) {
  const { entity, ...options } = _options

  return pr(
    await checkPermissionAsync(entity.id, 'asset', 'delete', { folderId: entity.folderId }),

    'You have no permission to delete this asset',

    options,
  )
}

/**
 * Can EDIT Asset
 * */
export async function canEditAssetBulk(_options = {}) {
  const { entity, ...options } = _options

  return pr(
    await checkPermissionAsync(entity.id, 'asset', 'bulk-edit', { folderId: entity.folderId }),

    'You have no permission to edit this asset',

    options,
  )
}

/**
 * Can DELETE Asset
 * */
export async function canDeleteAssetBulk(_options = {}) {
  const { entity, ...options } = _options

  return pr(
    await checkPermissionAsync(entity.id, 'asset', 'bulk-delete', { folderId: entity.folderId }),

    'You have no permission to delete this asset',

    options,
  )
}

/**
 * Can MANAGE Asset Versions
 */
export async function canManageAssetVersions(_options = {}) {
  const { entity, ...options } = _options

  return pr(
    await checkPermissionAsync(entity.id, 'asset', 'manageassetversion', { }),

    'You have no permission to manage versions of assets',

    options,
  )
}
