import Vue from 'vue'

// axios
import axios from 'axios'
import { getCurrentOrganizationId, getCurrentSiteId } from '../services/siteService'

export const subdomains = {
  api: process.env.VUE_APP_API_BASE_URL,

  // playground: 'http://141.94.74.199:3000',
  playground: `${process.env.VUE_APP_API_PLAYGROUND_BASE_URL || 'https://playground.codex.gjirafa.tech'}`,
}

export const axiosIns = {}

// eslint-disable-next-line no-restricted-syntax
Object.keys(subdomains).forEach(subdomain => {
  const axiosInstance = axios.create({
    baseURL: subdomains[subdomain],
  })

  axiosInstance.interceptors.request.use(config => {
    // If current site id is set.. intercept all requests
    // and inject site !!
    const siteId = getCurrentSiteId()
    const orgId = getCurrentOrganizationId()

    if (orgId) {
      config.url = config.url.replace('{organization}', orgId)
    }

    if (siteId) {
      config.url = config.url.replace('{site}', siteId)
    }

    if (siteId) {
      if (config.method === 'get') {
        if (!config.params?.siteId && config.params?.siteId !== null) {
          config.params = { ...config.params, siteId }
        }
      } else if (config.data && config.data.constructor === FormData) {
        if (!config.params?.siteId && config.params?.siteId !== null) {
          config.params = { ...config.params, siteId }
        }
        config.data.append('siteId', siteId)
      } else {
        if (!config.params?.siteId && config.params?.siteId !== null) {
          config.params = { ...config.params, siteId }
        }
        if (!config?.data?.siteId && config?.data?.siteId !== null) {
          config.data = { ...config.data, siteId }
        }
      }
    }

    return config
  }, error => Promise.reject(error))

  axiosIns[subdomain] = axiosInstance
})

Vue.prototype.$http = axiosIns.api

export const apiAxios = axiosIns.api

export const playgroundAxios = axiosIns.playground

export default axiosIns.api
