import { getCurrentSiteId } from '@/services/siteService'
import {
  createUser, readUsers, updateUser, deleteUser, deleteUsers,
} from '../../codex-sdk/users'

export default {
  addUser({ commit }, { user, message }) {
    return new Promise((resolve, reject) => {
      createUser(user, message)
        .then(response => {
          commit('ADD_USER', Object.assign(user, { id: response.data.id }))
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  // eslint-disable-next-line no-unused-vars
  fetchUsers({ commit }, { ...filtersAndPagination } = {}) {
    const siteId = getCurrentSiteId()
    return new Promise((resolve, reject) => {
      readUsers(filtersAndPagination, siteId)
        .then(response => {
          const { data: { items, ...pagination } } = response
          commit('SET_USERS_PAGINATION', pagination)
          commit('SET_USERS', items)
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  updateUser({ commit }, { user, message }) {
    return new Promise((resolve, reject) => {
      updateUser(user, message)
        .then(response => {
          commit('UPDATE_USER', user)
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  removeUser({ commit }, { userId, message }) {
    return new Promise((resolve, reject) => {
      deleteUser(userId, message)
        .then(response => {
          commit('REMOVE_USER', userId)
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  removeUsers({ commit }, { userIds, message }) {
    return new Promise((resolve, reject) => {
      deleteUsers(userIds, message)
        .then(response => {
          commit('REMOVE_USERS', userIds)
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
}
