import axios from 'axios'
import store from '@/store'
// import { tc } from './config'

const VP_API_BASE_URL = 'https://vp-api.gjirafa.tech/'
const VP_AUDIO_API_BASE_URL = 'https://audio.vp.gjirafa.tech/'

function generateUrl(url = '', isAudio = false) {
  const projectId = store?.state?.vpUploads?.projectId
  return !isAudio ? `${VP_API_BASE_URL}api/projects/${projectId}/${url}` : `${VP_AUDIO_API_BASE_URL}api/v1/${projectId}/audios`
}

function generateHeaders(restHeaders = {}) {
  const apiKey = store?.state?.vpUploads?.apiKey
  return {
    'api-key': apiKey,
    ...restHeaders,
  }
}

export function readVPProjects(organizationId) {
  return axios
    .get(`${VP_API_BASE_URL}api/accounts/organizations/${organizationId}/projects/select-list`, {
      headers: generateHeaders(),
    })
}

export function getPreSigned(data) {
  return axios
    .post(generateUrl('uploads/url'), data.params, {
      headers: generateHeaders(data.headers),
    })
}

export function getMultipartPreSigned(data) {
  return axios
    .post(generateUrl('uploads/multipart'), data, {
      headers: generateHeaders(),
    })
}

export function getMultipartUploadedParts(requestKey) {
  return axios
    .get(`${generateUrl('uploads/url/multipart')}/${requestKey}/parts`, {
      headers: generateHeaders(),
    })
}

export function multipartCompleteAction(data) {
  return axios
    .put(generateUrl('uploads/multipart-complete'), data, {
      headers: generateHeaders(),
    })
}

export function multipartAbort(requestKey) {
  return axios
    .put(generateUrl('uploads/multipart-abort'), { requestKey }, {
      headers: generateHeaders(),
    })
}

export function encodesVideo(data) {
  return axios
    .post(generateUrl('encodes'), { ...data }, {
      headers: generateHeaders(),
    })
}
export function encodesAudio(data) {
  return axios
    .post(generateUrl('audios', true), { ...data }, {
      headers: generateHeaders(),
    })
}
