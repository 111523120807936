<template>
  <b-modal
    id="focal-point-modal"
    v-model="showPopup"
    :static="true"
    :title="$t('popups.focal-point.title-update')"
    modal-class="focal-point"
    hide-footer
    size="xl"
  >
    <div class="focal-point__row">
      <div
        id="focalPointWrapper"
        ref="focalPointWrapper"
        class="focal-point__image-wrapper"
      >
        <div
          id="focalImage"
          ref="focalImage"
          class="focal-point__image"
          draggable="false"
          :style="{'width': asset ? asset.width + 'px' : 'auto', 'height': asset ? asset.height + 'px' : 'auto', 'opacity': fitWatcher ? 1 : 0}"
        >
          <img
            v-show="asset && asset.url && isImageLoaded"
            :draggable="false"
            :src="asset && asset.url"
            alt=""
            @load="onImageLoad"
            @drag="() => false"
          >
          <span
            v-if="asset"
            ref="focalDot"
            class="focal-point__dot"
            :src="asset.url"
            :style="circleStyle"
            @mousedown.stop.prevent="startDrag"
          />
        </div>
      </div>
      <div
        class="focal-point__sidebar"
        :class="{'focal-point__sidebar--narrow': from === 'assets'}"
      >
        <FocalPointPreview
          v-if="asset && from === 'assets'"
          :positions="circleStyle"
          :src="asset"
        />
        <FocalPointPresets
          v-if="asset && from === 'entry'"
          v-model="activeFocalPoint"
          :presets="data.ratios"
          :focals="focalPoint"
          :src="asset"
          @removePreset="deletePreset"
        />
        <div class="focal-point__save">
          <b-button
            block
            variant="primary"
            @click="handleOk"
          >
            {{ $t('popups.focal-point.update-button') }}
          </b-button>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import PopupsMixin from '@/components/popups/PopupsMixin'
import gql from 'graphql-tag'
import FocalPointPreview from '@/components/popups/focal-point/FocalPointPreview.vue'
import fit from 'fit.js'
import FocalPointPresets from '@/components/popups/focal-point/FocalPointPresets.vue'

export default {
  name: 'FocalPointPopup',
  components: {
    FocalPointPresets,
    FocalPointPreview,
  },
  mixins: [PopupsMixin],
  apollo: {
    asset: {
      query: gql`
        query ($id: String!) {
          asset(id: $id) {
            id
            folderId
            title
            width
            height
            tags
            contentType
            source
            copyright
            size
            url(transformation: {width: 1000})
            path
            type
            fileName
        }
       }
      `,
      variables() {
        return {
          id: this.imageId,
        }
      },
      fetchPolicy: 'no-cache',
    },
  },
  data() {
    return {
      fitWatcher: null,

      isImageLoaded: false,
      selectedPreset: 'default',
      isDragging: false,
      containerWidth: 0,
      containerHeight: 0,
      config: {
        hAlign: fit.CENTER, // or fit.LEFT, fit.RIGHT
        vAlign: fit.CENTER, // or fit.TOP, fit.BOTTOM
        apply: true,
        watch: true,
      },
      activeFocalPoint: 'default',
      focalPoint: {
        default: {
          x: 0.5,
          y: 0.5,
        },
        presets: {
          // gallery: {
          //   x: 0.5,
          //   y: 0.5,
          // },
        },
      },
      initialMousePosition: {
        x: 0, // Initialize with the initial x position of the mouse
        y: 0, // Initialize with the initial y position of the mouse
      },
    }
  },
  computed: {
    circlePosition: {
      get() {
        if (this.activeFocalPoint === 'default') {
          return this.focalPoint.default || { x: 0.5, y: 0.5 }
        }
        return this.focalPoint.presets[this.activeFocalPoint] || this.focalPoint.default || { x: 0.5, y: 0.5 }
      },
      set(newValue) {
        if (this.activeFocalPoint === 'default') {
          this.focalPoint.default = newValue
        } else {
          this.$set(this.focalPoint.presets, this.activeFocalPoint, newValue)
        }
      },
    },
    imageId() {
      return this.data?.imageId || null
    },
    from() {
      return this.data?.from || null
    },
    focals() {
      return this.data?.focals || this.focalPoint
    },
    circleStyle() {
      const left = this.circlePosition.x * 100
      const top = this.circlePosition.y * 100

      return {
        left: `${left}%`,
        top: `${top}%`,
      }
    },
  },
  mounted() {
    if (Object.keys(this.focals).length !== 0) {
      if (!this.focals.presets) {
        this.$set(this.focals, 'presets', {})
      }
      this.focalPoint = this.focals
    }

    document.addEventListener('mousemove', this.drag)
    document.addEventListener('mouseup', this.stopDrag)
  },
  beforeDestroy() {
    if (this.fitWatcher) {
      this.fitWatcher.off()
    }

    document.removeEventListener('mousemove', this.drag)
    document.removeEventListener('mouseup', this.stopDrag)
  },
  methods: {
    getKey(ratio) {
      return `${ratio.width}:${ratio.height}`
    },
    deletePreset(preset) {
      this.focalPoint.presets[this.getKey(preset)] = undefined
    },
    handleOk() {
      this.closePopup(this.focalPoint)
    },
    fitImage() {
      const focalImage = document.getElementById('focalImage')
      const focalPointWrapper = document.getElementById('focalPointWrapper')

      if (this.fitWatcher) this.fitWatcher.off()

      this.fitWatcher = fit(focalImage, focalPointWrapper, this.config, transform => {
        if (transform.scale > 1) {
          focalImage.classList.add('focal-point__image--small')
          return
        }
        focalImage.classList.remove('focal-point__image--small')

        fit.cssTransform(transform, focalImage)

        if (this.$refs.focalDot) {
          this.$refs.focalDot.style.transform = `translate(-50%, -50%) scale(${1 / transform.scale}) `
        }
      })
    },
    onImageLoad() {
      const containerRect = this.$refs.focalImage?.getBoundingClientRect()
      this.containerWidth = containerRect.width
      this.containerHeight = containerRect.height
      this.isImageLoaded = true
      setTimeout(() => {
        this.fitImage()
      }, 200)
    },
    startDrag(event) {
      this.isDragging = true
      this.initialMousePosition = {
        x: event.clientX,
        y: event.clientY,
      }
    },
    drag(event) {
      if (this.isDragging) {
        const containerRect = this.$refs.focalImage.getBoundingClientRect()

        // Calculate the new circle position
        const newCircleX = (event.clientX - containerRect.x) / containerRect.width
        const newCircleY = (event.clientY - containerRect.y) / containerRect.height

        this.circlePosition = {
          x: Math.max(0, Math.min(newCircleX, 1)), // Update with the appropriate x-coordinate from the event
          y: Math.max(0, Math.min(newCircleY, 1)), // Update with the appropriate y-coordinate from the event
        }
        // Update the circle position for 'default'

        this.initialMousePosition = {
          x: event.clientX,
          y: event.clientY,
        }
      }
    },
    stopDrag() {
      this.isDragging = false
    },
  },
}
</script>

<style lang="scss">
.focal-point {
  .modal-dialog {
    height: calc(100% - 3.5rem);

    .modal-content {
      height: 100%;
      overflow: hidden;

      .modal-body {
        height: calc(100% - 50px);
      }

      .row, div[class^="col-"] {
        height: 100%;
      }
    }
  }
}

.focal-point__row {
  display: flex;
  gap: 32px;
  height: 100%;
}

.focal-point__sidebar {
  border-left: 1px solid var(--border-color);
  margin-top: -1rem;
  margin-bottom: -1rem;
  padding: 1.5rem 0 16px 16px;
  width: 380px;
  min-width: 380px;
  height: calc(100% + 50px);
  //overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  &.focal-point__sidebar--narrow {
    width: 300px;
    min-width: 300px;
  }
}
.focal-point__sidebar-header {
    margin-bottom: 24px;
}
.focal-point__sidebar-title {
  color: #052D61;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  display: block;
  margin-bottom: 2px;
}
.focal-point__sidebar-description {
  color: #667C99;
  font-size: 14px;
  line-height: 21px;
  display: block;
}

.focal-point__presets {
  display: flex;
  flex-direction: column;

}

.focal-point__preset {
  position: relative;
  border: 1px solid var(--border-color);
  padding: 0.5rem;
  margin-bottom: 0.5rem;
  cursor: pointer;
  border-radius: 4px;

  input {
    position: absolute;
    top: 8px;
    right: 8px;
  }
}

.focal-point__preset-image {
  width: 88px;
  height: 88px;
  background-color: var(--border-color);
  border-radius: 4px;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.focal-point__image-wrapper {
  min-width: 0;
  flex-grow: 1;
  position: relative;
}

.focal-point__image {
  position: relative;

  img {
    max-width: 100%;
    max-height: 100%;
    width: 100%;
    height: 100%;
    user-select: none;
    pointer-events: none;
    object-fit: contain;
  }

  &.focal-point__image--small {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) !important;
  }
}

.focal-point__dot {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: #FFFFFF;
  border: 6px solid #d7d7d7;
  //box-sizing: content-box;
  background-clip: content-box;
}
.focal-point__save {
  //position: absolute;
  //bottom: 0;
  //left: 0;
  //right: 0;
  padding: 16px;
  margin: 0 -16px;
  //padding-bottom: 32px;
  background-color: #FFFFFF;
  border-top: 1px solid var(--border-color);
}
</style>
