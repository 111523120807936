<template>
  <b-modal
    id="mbase-disconnect__popup"
    v-model="showPopup"
    :title="$t('integrations.integrations.mBase.modal.title')"
    centered
    :ok-variant="showMoreDetails ? 'outline-secondary':'primary'"
    :ok-title="showPropperTitle"
    cancel-variant="outline-secondary"
    :cancel-title="$t('general.cancel')"
    size="md"
    :ok-only="showMoreDetails"
    no-close-on-backdrop
    :ok-disabled="disableOk"
    :static="true"
    @ok="triggerPropperMethod"
    @cancel="handleCancel"
    @close="handleCancel"
  >
    <div v-if="showMoreDetails">
      <div class="mbase-integration__info">
        <div class="integration-logo">
          <img
            src="../../../assets/icons/mbase.svg"
            alt=""
          >
        </div>
        <div class="mbase-integration__popup-header">
          <p>{{ getIntegration.name }}</p>
          <small>{{ $t('integrations.mBase-details') }}</small>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="mbase-integration__info">
        <div class="integration-logo">
          <img
            src="../../../assets/icons/mbase.svg"
            alt=""
          >
        </div>
        <div class="mbase-integration__popup-header">
          <p>{{ getIntegration.name }}</p>
          <small>{{ getIntegration.description }}</small>
          <p
            v-if="mBase.id"
            v-permission="['canDeleteIntegration']"
            class="disconnect-button"
            @click="disconnectActivation"
          >
            {{ $t('integrations.card-buttons.remove') }}
          </p>

        </div>
      </div>

      <div>
        <b-form-group :label="$t('integrations.integrations.mBase.modal.url-label')">
          <b-form-input
            v-model="mBase.attrs.url"
            :placeholder="$t('integrations.integrations.mBase.modal.url-placeholder')"
            type="url"
            style="font-size:14px"
            autofocus
          />
        </b-form-group>
        <b-form-group :label="$t('integrations.integrations.mBase.modal.api-key-label')">
          <b-input
            v-model="mBase.attrs.apiKey"
            style="font-size:14px"
            :placeholder="$t('integrations.integrations.mBase.modal.api-key-placeholder')"
          />
        </b-form-group>
        <small
          v-if="errorMessage"
          class="error-message"
        >{{ errorMessage }}</small>
      </div>

      <div
        class="integration-warning"
      >
        <GjIcon
          name="Warning"
          size="30"
        />
        <div class="integration-warning__info">
          <p>{{ $t('integrations.note.note') }}</p>
          <small>{{ $t('integrations.note.mBase-warning') }}</small>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import PopupsMixin from '@/components/popups/PopupsMixin'
import { mBaseDefaults } from '@/codex-sdk/all-integrations'
import { cloneDeep } from 'lodash'
import { canCreateIntegration } from '@/codex-permissions/integrations'
import { ENTITY_TYPES } from '@/utils/constants'

export default {
  mixins: [PopupsMixin],
  inject: ['showConfirmDeletePopup'],
  data() {
    return {
      // eslint-disable-next-line no-useless-escape
      urlRegex: new RegExp(/^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/),
      mBase: mBaseDefaults(),
      errorMessage: '',
      canCreateIntegration,
    }
  },
  computed: {
    getIntegration() {
      return this.data.integration
    },
    showMoreDetails() {
      return this.data.showMoreDetails
    },
    triggerPropperMethod() {
      if (this.showMoreDetails || !this.mBase) return this.handleCancel
      return this.validateUrl
    },
    disableOk() {
      return !canCreateIntegration().can || (!Object.values(this.mBase?.attrs).every(value => value) && !this.showMoreDetails)
    },
    mBaseConnections() {
      return this.$store.getters['general/getConnections'](this.getIntegration.alias) || {}
    },
    showPropperTitle() {
      if (!this.showMoreDetails && !this.mBase.id) {
        return this.$t('general.connect')
      }

      if (!this.showMoreDetails && this.mBase.id) {
        return this.$t('general.save')
      }

      return this.$t('general.cancel')
    },
  },
  mounted() {
    if (this.mBaseConnections.length) {
      this.mBase = cloneDeep(this.mBaseConnections[0])
    }
  },
  methods: {
    handleCancel() {
      this.closePopup(this.mBase)
    },
    async handleOk() {
      try {
        if (!this.mBase.id) {
          this.mBase.integration = this.getIntegration.alias
          this.$store.dispatch('general/addConnections', this.mBase)
          this.closePopup(this.mBase)
        } else {
          this.$store.dispatch('general/updateConnections', this.mBase)
        }
      } catch (err) {
        console.log(err)
      }
    },
    validateUrl(e) {
      if (!this.urlRegex.test(this.mBase.attrs.url)) {
        e.preventDefault()
        setTimeout(() => {
          this.errorMessage = null
        }, 3000)
        this.errorMessage = this.$t('integrations.error-message')
      } else {
        this.handleOk()
      }
    },
    async disconnectActivation() {
      const result = await this.showConfirmDeletePopup({
        items: [this.mBase.attrs.url],
        type: ENTITY_TYPES.CONNECTION,
      })
      try {
        if (result) {
          this.$store.dispatch('general/removeConnections', this.mBase)

          this.mBase = mBaseDefaults()
          this.closePopup(this.mBase)
        }
      } catch (err) {
        console.log(err)
      }
    },
  },
}
</script>
<style lang='scss' scoped>

.mbase-integration__info{
  display: flex;
  width: 100%;
  align-items: flex-start;
  gap: 30px;
  margin: 3% auto;

  img{
    width: 36px;
    height: 36px;
  }

  .integration-logo{
    background: linear-gradient(
      180deg,
      #d9d9d9 0%,
      #f3f2f4 0.01%,
      #ebddfd 100%
    );
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
  }

  .mbase-integration__popup-header{
    p {
      font-weight: 500;
      font-size: 18px;
      color: #052D61;
      margin-bottom: 5px;
    }

    small{
      font-size: 14px;
      color: #667C99;
    }

    .disconnect-button{
      font-weight: 600;
      font-size: 14px;
      background: #E34850;
      padding: 8px 14px;
      color: white;
      width: fit-content;
      border-radius: 42px;
      margin-top: 3%;
      cursor: pointer;
    }
  }
}

.integration-warning{
  padding: 10px;
  display: flex;
  width: 100%;
  align-items: flex-start;
  gap: 10px;
  margin: 3% auto;
  background: #FDEDEE;
  border-radius: 4px;

  svg{
    color: #E34850;
  }

  .integration-warning__info{
    p{
      font-weight: 600;
      font-size: 14px;
      color: #052D61;
      margin: 0;
    }

    small{
      color: #052D61;
      font-size: 14px;
    }
  }
}

.error-message{
  color: #E34850;
  font-size: 12px;
}

</style>
