import { readAccessControlPermission } from '@/codex-sdk/teams'
import ability from '@/libs/acl/ability'
import * as Sections from './sections'
import * as Lists from './lists'
import * as Users from './users'
import * as OrganizationSites from './organization-sites'
import * as OrganizationUsers from './organization-users'
import * as Authors from './authors'
import * as ApiKeys from './api-keys'
import * as QuickLinks from './quick-links'
import * as Urls from './urls'
import * as EntriesViews from './entries-views'
import * as Tags from './tags'

import * as Labels from './labels'

import * as Entries from './entries'
import * as Assets from './assets'
import * as Folder from './folders'
import * as Integrations from './integrations'
import * as Teams from './teams'
import * as Models from './models'
import * as Webhooks from './webhooks'
import * as Workflows from './workflows'
import * as Jobs from './jobs'
import * as Presets from './presets'
import * as Localizations from './localizations'
import * as Plugins from './plugins'

import { SectionTooltips } from './sections'
import { ListTooltips } from './lists'
import { UserTooltips } from './users'
import { OrganizationSitesTooltips } from './organization-sites'
import { OrganizationUsersToolTips } from './organization-users'
import { AuthorsToolTips } from './authors'
import { ApiKeysToolTips } from './api-keys'
import { QuickLinksTooltips } from './quick-links'
import { UrlTooltips } from './urls'
import { EntriesViewTooltips } from './entries-views'
import { TagTooltips } from './tags'
import { JobTooltips } from './jobs'
import { PresetsTooltips } from './presets'
import { LocalizationTooltips } from './localizations'
import { PluginTooltips } from './plugins'

// Create entity file, and can functions
// Import and add them to allCanFunctions object down below
// Add defaults into the permissions array down below
// Implement v-permission directives on neccessary buttons
// Implement permissions on entities sdk functions
// Implement permissions on navigation and route
//

/**
 * ToDo:
 *  - Add createdBy/updatedBy on all entities on create
 *  - Ask API to add createdBy/creator on all listing pages
 */

const allCanFunctions = {
  ...Sections,
  ...Lists,
  ...Users,
  ...OrganizationSites,
  ...OrganizationUsers,
  ...Authors,
  ...ApiKeys,
  ...QuickLinks,
  ...Urls,
  ...Labels,

  ...Entries,
  ...Assets,
  ...Folder,
  ...Integrations,
  ...Teams,
  ...Models,
  ...Webhooks,
  ...Workflows,
  ...EntriesViews,
  ...Tags,
  ...Jobs,
  ...Presets,
  ...Localizations,
  ...Plugins,

  // Add all raw can functions here, for each ability
  _ability: {},
}

export const allPermissionTooltips = {
  ...SectionTooltips,
  ...ListTooltips,
  ...UserTooltips,
  ...OrganizationSitesTooltips,
  ...OrganizationUsersToolTips,
  ...AuthorsToolTips,
  ...ApiKeysToolTips,
  ...QuickLinksTooltips,
  ...UrlTooltips,
  ...EntriesViewTooltips,
  ...TagTooltips,
  ...JobTooltips,
  ...PresetsTooltips,
  ...LocalizationTooltips,
  ...PluginTooltips,
}

/**
 * Here we can set the default error handler
 */
export const permissionsConfig = {
  displayError(title, message) {
    console.log('error')
    console.log(title)
    console.log(message)
  },
}

/**
 * Let the user of CodexPermissions to set Error handler
 */
export function setErrorsHandler(fn) {
  permissionsConfig.displayError = fn
}

/**
 * pr - Short for `permission return`
 */
export function pr(hasPermission, message, options = {
  showToast: false,
}) {
  if (hasPermission) return { can: true }

  if (options.showToast) {
    permissionsConfig.displayError('No permission', message)
  }

  return { can: false, message }
}

/**
 * Async function to check if user has permission
 * uses 'readAccessControlPermission' function
 */
const permissionCache = new Map()

export async function checkPermissionAsync(id, type, permission, extraProps = {}, returnFullResponse = false) {
  try {
    // Remove undefined values from extraProps
    Object.keys(extraProps).forEach(key => {
      if (extraProps[key] === undefined) {
        delete extraProps[key]
      }
    })

    if (Object.values(extraProps).length === 0 && !id) {
      return true
    }

    const cacheKey = id + type + permission + Object.values(extraProps).join('') + (returnFullResponse ? 'full' : '')

    if (permissionCache.has(cacheKey)) {
      // If a request with the same cache key is pending,
      // wait for it to resolve and return the result
      return await permissionCache.get(cacheKey)
    }

    // Create a new pending request for this cache key
    const request = readAccessControlPermission({
      id, type, permission, extraProps,
    })
      .then(({ data }) => (returnFullResponse ? data : !!data.hasPermission))

    permissionCache.set(cacheKey, request)

    // Wait for the request to resolve and return the result
    return await request
  } catch (error) {
    console.log('error', error)
    return false
  }
}

/**
 * Returns the function for checking a certain permission by function name,
 * from the list of all permissions
 *
 * @param {String} functionName The name of `can` function to return
 * @returns null or the function it found
 */
export function getCanFunction(functionName) {
  if (functionName && allCanFunctions[functionName] && allCanFunctions[functionName].constructor == Function) {
    return allCanFunctions[functionName]
  }
  if (functionName && allCanFunctions._ability[functionName] && allCanFunctions._ability[functionName].constructor == Function) {
    return allCanFunctions._ability[functionName]
  }
  return null
}

/**
 * Add a new can function for plugin
 */
export function addCanFunction(permissionName) {
  allCanFunctions._ability[permissionName] = function (_options = {}) {
    const { entity, ...options } = _options

    return pr(
      ability.can(permissionName),

      `You have no ${permissionName} permission`,

      options,
    )
  }
}

/**
 * Check if user has permission
 *
 * @param {String} functionName The name of `can` function to return
 * @returns null or the function it found
 */
export function checkPermission(functionName, options) {
  if (functionName) {
    const canFunction = getCanFunction(functionName)
    if (!canFunction) return true

    return canFunction(options)
  }
  return { can: true }
}

export const permissions = {
  // Done
  [Sections.Section.VIEW]: { default: false },
  [Sections.Section.MANAGE]: { default: false },
  [Sections.Section.CREATE]: { default: false },

  // Done
  [Lists.List.VIEW]: { default: false },
  [Lists.List.CREATE]: { default: false },
  [Lists.List.MANAGE]: { default: false },

  // Done
  [Users.User.VIEW]: { default: false },
  [Users.User.MANAGE]: { default: false },

  // Done
  [OrganizationSites.OrganizationSites.VIEW]: { default: false },
  [OrganizationSites.OrganizationSites.MANAGE]: { default: false },

  [OrganizationUsers.OrganizationUsers.VIEW]: { default: true },
  [OrganizationUsers.OrganizationUsers.MANAGE]: { default: true },

  // Done
  [Authors.Authors.VIEW]: { default: false },
  [Authors.Authors.MANAGE]: { default: false },

  [QuickLinks.QuickLinks.VIEW]: { default: true },
  [QuickLinks.QuickLinks.MANAGE]: { default: true },

  // Done
  [ApiKeys.ApiKeys.VIEW]: { default: false },
  [ApiKeys.ApiKeys.MANAGE]: { default: false },

  [Urls.Urls.VIEW]: { default: true },
  [Urls.Urls.CREATE]: { default: true },
  [Urls.Urls.MANAGE]: { default: true },

  // Done
  [Labels.Label.VIEW]: { default: false },
  [Labels.Label.MANAGE]: { default: false },

  // Done
  [Integrations.Integration.VIEW]: { default: false },
  [Integrations.Integration.MANAGE]: { default: false },

  // Done
  [Teams.Team.VIEW]: { default: false },
  [Teams.Team.MANAGE]: { default: false },

  // Done
  [Models.Model.VIEW]: { default: false },
  [Models.Model.CREATE]: { default: false },
  [Models.Model.EDIT]: { default: false },
  [Models.Model.DELETE]: { default: false },
  [Models.Model.PUBLISH]: { default: false },

  // Done
  [Webhooks.Webhook.VIEW]: { default: false },
  [Webhooks.Webhook.CREATE]: { default: false },
  [Webhooks.Webhook.EDIT]: { default: false },
  [Webhooks.Webhook.DELETE]: { default: false },

  // Done

  [Workflows.Workflows.VIEW]: { default: false },
  [Workflows.Workflows.CREATE]: { default: false },
  [Workflows.Workflows.EDIT]: { default: false },
  [Workflows.Workflows.DELETE]: { default: false },

  // Done
  [Presets.Presets.VIEW]: { default: true },
  [Presets.Presets.CREATE]: { default: true },
  [Presets.Presets.EDIT]: { default: true },
  [Presets.Presets.DELETE]: { default: true },

  // Done
  [EntriesViews.EntriesView.MANAGE]: { default: false },

  // Done
  [Jobs.Job.VIEW]: { default: false },
  [Jobs.Job.CREATE]: { default: false },

  // Done
  [Plugins.Plugin.VIEW]: { default: false },
  [Plugins.Plugin.ACTIVATE]: { default: false },
  [Plugins.Plugin.DELETE]: { default: false },
}

export default allCanFunctions
