<template>
  <div class="resizable-block">
    <div
      ref="blockWrapper"
      class="resizable-block__element-wrapper"
      :class="{[alignClass]: true}"
      @click="selectNode"
    >
      <div
        class="resizable-block__element"
        :style="elementStyle"
      >
        <div class="resizable-block__element-holder">

          <slot />
          <b-form-input
            v-if="includeCaption"
            v-model="caption"
            class="resizable-block__caption"
            placeholder="Caption"
            rows="1"
            max-rows="10"
          />
          <b-form-input
            v-if="attrs.includeAltText"
            v-model="altText"
            class="resizable-block__caption"
            placeholder="Alt Text"
            rows="1"
            max-rows="10"
          />
          <div
            v-if="attrs.includeAuthor"
          ><p class="resizable-block__author">
            {{ attrs.author }}
          </p></div>

          <div
            v-if="attrs.includeSource"
          ><p class="resizable-block__author">
            {{ attrs.source }}
          </p></div>

          <div
            v-if="!!resizing || !selected"
            class="cover"
          />

          <div
            v-if="align !== 'stretch'"
            class="resizable-block__element-resizer-right"
            @mousedown.prevent="startResize($event, 'right')"
          />
          <div
            v-if="align !== 'stretch'"
            class="resizable-block__element-resizer-left"
            @mousedown.prevent="startResize($event, 'left')"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    attrs: {
      type: Object,
      default: () => {},
    },
    selectNode: {
      type: Function,
      default: () => {},
    },
    selected: {
      type: Boolean,
      default: false,
    },
    entityRef: {
      type: [HTMLDivElement, HTMLIFrameElement, Object],
      default: () => {},
    },
    updateAttrs: {
      type: Function,
      default: () => {},
    },
    minWidth: {
      type: String,
      default: '25%',
    },
    includeCaption: {
      type: Boolean,
      default: true,
    },

  },
  data() {
    return {
      resizing: null,
      width: null,
    }
  },
  computed: {
    altText: {
      get() {
        return this.attrs.altText
      },
      set(v) {
        this.updateAttrs({ altText: v })
      },
    },
    caption: {
      get() {
        return this.attrs.caption
      },
      set(v) {
        this.updateAttrs({ caption: v })
      },
    },
    elementStyle() {
      if (this.width) return { width: this.width }
      return {}
    },
    alignClass() {
      return `element-${this.align}`
    },
    align: {
      get() {
        return this.attrs.align
      },
      set(e) {
        this.updateAttrs({ align: e })
      },
    },
  },
  watch: {
    width(v) {
      this.updateAttrs({ width: v })
    },
  },
  mounted() {
    document.addEventListener('mousemove', this.resizeBlock)
    document.addEventListener('mouseup', this.endResize)
    this.$nextTick(() => {
      this.width = this.attrs.width
    })
  },
  destroyed() {
    document.removeEventListener('mousemove', this.resizeBlock)
    document.removeEventListener('mouseup', this.endResize)
  },
  methods: {
    startResize(event, side) {
      const entityRef = this.entityRef.$el || this.entityRef
      this.resizing = {
        side,
        x: event.clientX,
        initialWidth: entityRef.getBoundingClientRect().width,
        maxWidth: this.$refs.blockWrapper.getBoundingClientRect().width,
      }
      this.resizeBlock(event)
    },
    resizeBlock(event) {
      if (this.resizing) {
        const newX = event.clientX
        const oldX = this.resizing.x
        const resizeDelta = this.resizing.side === 'right' ? newX - oldX : oldX - newX
        const newWidth = this.resizing.initialWidth + (resizeDelta * (this.align === 'center' ? 2 : 1))
        this.width = newWidth + 5 >= this.resizing.maxWidth ? '100%' : `${(newWidth / this.resizing.maxWidth) * 100}%`

        this.width = parseInt(this.width, 10) > parseInt(this.minWidth, 10) ? this.width : this.minWidth

        this.updateAttrs({ width: this.width })
      }
    },
    endResize() {
      this.resizing = null
    },
  },
}
</script>

<style scoped lang="scss">

.resizable-block {
  &::before {
    display: none;
  }
}

.resizable-block__element-wrapper {
  &.element-left {
    text-align: left;
  }

  &.element-center {
    text-align: center;
  }

  &.element-right {
    text-align: right;
  }

  &.element-stretch {
    .resizable-block__element {
      width: calc(100% + 20px) !important;
      margin-left: -10px;
      margin-right: -10px;
    }
  }
}

.resizable-block__element {
  position: relative;
  display: inline-block;
}

.resizable-block__element-holder {
  position: relative;
}

.resizable-block__element-resizer-right,
.resizable-block__element-resizer-left {
  position: absolute;
  display: none;
  top: 50%;
  transform: translateY(-50%);
  width: 2px;
  height: 35px;
  max-height: 80%;
  border-radius: 100px;
  background: #B3B3B3;
  cursor: w-resize;
  border: none;

  &:after {
    content: '';
    position: absolute;
    top: -20px;
    bottom: -20px;
    left: -5px;
    right: -5px;
    display: block;
  }
}

.resizable-block__element-resizer-right {
  right: -10px;
}

.resizable-block__element-resizer-left {
  left: -10px;
}

.resizable-block.selected {
  .resizable-block__element-resizer-right,
  .resizable-block__element-resizer-left {
    display: block;
  }
}

.resizable-block__caption {
    width: 100%;
    padding: 5px 5px !important;
    font-size: 12px;
    color: gray;
    border: none;
    overflow: hidden !important;
  }

.cover {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.resizable-block__author{
  font-size:13px;
}
</style>
