import { truncate } from 'lodash'
import i18n from '@/libs/i18n'
import AuthorListFilterComponent from './AuthorList.vue'

export default class AuthorListFilter {
  static COMPONENT = AuthorListFilterComponent;

  static OPERATORS = {
    all: 'all',
    some: 'some',
    exists: 'exists',
    notExists: 'notExists',
  }

  static type = 'author-list'

  static graphqlType = 'AuthorListFieldFilter'

  operator = null;

  value = null;

  constructor({ operator = AuthorListFilter.OPERATORS.some, value } = {}) {
    this.operator = operator
    this.value = value
  }

  set({ operator = AuthorListFilter.OPERATORS.some, value }) {
    this.operator = operator
    this.value = value
  }

  getCount() {
    if (this.operator === AuthorListFilter.OPERATORS.exists || this.operator === AuthorListFilter.OPERATORS.notExists) {
      return 1
    }
    return this.value?.length
  }

  getValueLabel({ cache }) {
    if (this.operator === AuthorListFilter.OPERATORS.exists) {
      return i18n.t('filters.is-not-empty')
    }
    if (this.operator === AuthorListFilter.OPERATORS.notExists) {
      return i18n.t('filters.is-empty')
    }
    const author = this.value.map(authorId => cache[authorId] || { id: authorId })
    return author.map(m => {
      let name = [m.firstName, m.lastName].filter(Boolean).join(' ')
      if (name === '') name = m.byline || m.email
      return truncate(name, { length: 20, separator: ' ' })
    }).join(', ')
  }

  toLoad() {
    return {
      authors: this.value,
    }
  }

  asQueryParam() {
    if (this.operator === AuthorListFilter.OPERATORS.exists || this.operator === AuthorListFilter.OPERATORS.notExists) {
      return {
        operator: this.operator,
      }
    }
    return {
      operator: this.operator,
      value: this.value,
    }
  }

  asGraphQL() {
    switch (this.operator) {
      case AuthorListFilter.OPERATORS.all:
        return {
          all: this.value,
        }
      case AuthorListFilter.OPERATORS.some:
        return {
          some: this.value,
        }
      case AuthorListFilter.OPERATORS.exists:
        return {
          exists: true,
        }
      case AuthorListFilter.OPERATORS.notExists:
        return {
          exists: false,
        }
      default:
        return null
    }
  }

  asGraphQLDynamicList() {
    if (this.asGraphQL()) {
      return {
        ...this.asGraphQL(),
        _t: AuthorListFilter.graphqlType,
      }
    }
    return null
  }

  static validate({ operator, value }) {
    if (!operator) {
      return 'Operator is required'
    }

    if (operator === AuthorListFilter.OPERATORS.exists || operator === AuthorListFilter.OPERATORS.notExists) {
      return true
    }

    if (!value || value.length === 0) {
      return 'Value is required'
    }

    return true
  }

  static fromQueryParam({ operator, value }) {
    return new AuthorListFilter({
      operator,
      value,
    })
  }

  static shouldApplyPredefinedValue(filter) {
    const forOperators = [AuthorListFilter.OPERATORS.all, AuthorListFilter.OPERATORS.some]
    return forOperators.includes(filter.operator)
  }
}
