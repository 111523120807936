export default {
  ADD_PLUGIN(state, plugin) {
    state.plugins.push(plugin)
  },
  SET_PLUGINS(state, plugins) {
    state.plugins = plugins
  },
  UPDATE_PLUGIN(state, plugin) {
    const pluginIndex = state.plugins.findIndex(p => p.id === plugin.id)
    if (pluginIndex === -1) return
    Object.assign(state.plugins[pluginIndex], plugin)
  },
  REMOVE_PLUGIN(state, pluginId) {
    const pluginIndex = state.plugins.findIndex(p => p.id === pluginId)
    state.plugins.splice(pluginIndex, 1)
  },
  SET_PLUGINS_PAGINATION(state, pagination) {
    state.pluginsPagination = pagination
  },
  CLEAR_PLUGINS_STATE(state) {
    state.plugins = []
  },

  ADD_ENTRY_SIDEBAR_ITEM(state, item) {
    const itemIndex = state.entrySidebarItems.findIndex(i => i.name === item.name)
    if (itemIndex !== -1) return
    state.entrySidebarItems.push(item)
  },
  REMOVE_ENTRY_SIDEBAR_ITEM(state, item) {
    const itemIndex = state.entrySidebarItems.findIndex(i => i.name === item.name)
    state.entrySidebarItems.splice(itemIndex, 1)
  },
}
