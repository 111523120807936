<template>
  <div>
    <b-form-group>
      <b-form-checkbox v-model="configuration.filterable">
        {{ $t('fields.codex-field-media-content.configuration.filterable') }}
      </b-form-checkbox>
    </b-form-group>
  </div>
</template>

<script>
import { generateComputedPropsFromAttrs } from '@/components/codex-layout-editor/BuilderUtils'
import BaseFieldMixin from '@/components/fields/BaseFieldMixin'

export default {
  mixins: [BaseFieldMixin],
  computed: {
    ...generateComputedPropsFromAttrs([
      'configuration',
    ]),
  },
}
</script>
