<template>
  <div class="new-filters-dropdown__filter-body">
    <b-row>
      <b-col cols="12">
        <vSelect
          v-model="value.operator"
          :options="options"
          :clearable="false"
          :reduce="option => option.value"
          :searchable="false"
          label="label"
        />
      </b-col>
    </b-row>
    <b-row
      v-if="!isExistsOperator"
      align-v="center"
      no-gutters
    >
      <b-col class="flex-grow">
        <flat-pickr
          v-model="inputValue"
          class="new-filters-dropdown__date-picker"
          :config="config"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import moment from 'moment'
import DatetimeFilter from './DatetimeFilter'

export default {
  props: {
    filter: {
      type: Object,
      required: true,
    },
    filterObject: {
      type: [Object, Function],
      required: true,
    },
    value: {
      type: Object,
      required: true,
    },
  },
  computed: {
    inputValue: {
      get() {
        if (this.isBetween && this.value.value2 && this.value.value1 !== this.value.value2) {
          return `${this.value.value1} to ${this.value.value2}`
        }
        return this.value.value1
      },
      set(value) {
        const splitValue = value.split(' to ')
        this.value.value1 = splitValue[0]

        if (this.isBetween && !splitValue[1]) {
          this.value.value2 = splitValue[0]
        } else {
          this.value.value2 = splitValue[1]
        }
      },
    },

    config() {
      return {
        altInput: false,
        inline: true,
        mode: this.isBetween ? 'range' : 'single',
      }
    },

    isBetween() {
      return this.value.operator === DatetimeFilter.OPERATORS.between
    },

    options() {
      return [
        {
          label: this.$t('filters.is'),
          value: DatetimeFilter.OPERATORS.equals,
        },
        {
          label: this.$t('filters.greater-than'),
          value: DatetimeFilter.OPERATORS.greaterThan,
        },
        {
          label: this.$t('filters.lower-than'),
          value: DatetimeFilter.OPERATORS.lowerThan,
        },
        {
          label: this.$t('filters.between'),
          value: DatetimeFilter.OPERATORS.between,
        },
        {
          label: this.$t('filters.is-not-empty'),
          value: DatetimeFilter.OPERATORS.exists,
        },
        {
          label: this.$t('filters.is-empty'),
          value: DatetimeFilter.OPERATORS.notExists,
        },
      ]
    },

    isExistsOperator() {
      return this.value.operator === DatetimeFilter.OPERATORS.exists || this.value.operator === DatetimeFilter.OPERATORS.notExists
    },
  },
  watch: {
    'value.operator': {
      handler(newValue, oldValue) {
        if (newValue == DatetimeFilter.OPERATORS.between && oldValue != DatetimeFilter.OPERATORS.between) {
          this.value.value2 = moment(this.value.value1).add(1, 'days').format('YYYY-MM-DD')
        }
      },
    },
  },
}
</script>

<style>

</style>
