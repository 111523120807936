export default {
  // createAuthor({ commit }, author) {
  //   return new Promise((resolve, reject) => {
  //     createAuthor(author)
  //       .then(response => {
  //         commit('ADD_AUTHOR', Object.assign(author, { id: response.data.id }))
  //         resolve(response)
  //       })
  //       .catch(error => { reject(error) })
  //   })
  // },

  addEntrySidebarItem({ commit }, item) {
    commit('ADD_ENTRY_SIDEBAR_ITEM', item)
  },
}
