import i18n from '@/libs/i18n'
import { mapTagSourceLabel, TAG_SOURCES } from '@/codex-sdk/tags'
import TagSource from './TagSource.vue'

export default class TagSourceFilter {
  static COMPONENT = TagSource;

  static type = 'tagSource'

  static OPERATORS = {
    includeOneOf: 'includeOneOf',
    includeAllOf: 'includeAllOf',
    exclude: 'exclude',
  }

  static OPTIONS = {
    [TAG_SOURCES.CODEX]: i18n.t(mapTagSourceLabel(TAG_SOURCES.CODEX)),
    [TAG_SOURCES.DANDELION]: i18n.t(mapTagSourceLabel(TAG_SOURCES.DANDELION)),
    [TAG_SOURCES.GENEA]: i18n.t(mapTagSourceLabel(TAG_SOURCES.GENEA)),
  }

  operator = null;

  value = null;

  constructor({ operator = TagSourceFilter.OPERATORS.includeOneOf, value = [] } = {}) {
    this.operator = operator
    this.value = value
  }

  set({ operator = TagSourceFilter.OPERATORS.includeOneOf, value = [] }) {
    this.operator = operator
    this.value = value
  }

  getCount() {
    return this.value.length
  }

  getValueLabel() {
    return this.value.map(id => TagSourceFilter.OPTIONS[id]).join(', ')
  }

  // toLoad() {
  //   return {
  //     assets: this.value,
  //   }
  // }

  asQueryParam() {
    return {
      operator: this.operator,
      value: this.value,
    }
  }

  asGraphQL() {
    return {
      in: this.value,
    }
  }

  static validate({ operator, value }) {
    if (!operator) {
      return 'Operator is required'
    }

    if (!value || value.length === 0) {
      return 'Value is required'
    }

    return true
  }

  static fromQueryParam({ operator, value }) {
    return new TagSourceFilter({
      operator,
      value,
    })
  }
}
