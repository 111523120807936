<template>
  <b-sidebar
    id="at-edit-tags-popup"
    ref="sidebar"
    v-model="showPopup"
    :title="tagId ? $t('content.tags.edit-popup.edit-title') : $t('content.tags.edit-popup.create-title')"
    class="c-sidebar c-sidebar--xl"
    :no-close-on-route-change="true"
    right
    shadow
    backdrop
    @change="visibilityChange"
  >
    <div
      v-if="entity"
      class="tags-edit-header"
    >
      <div class="tags-edit-header__container">
        <span class="tags-edit-header__value">
          {{ entity.tagValue }}
        </span>
        <div
          v-if="parents.length"
          class="tags-edit-header__parents"
        >
          <template v-for="(p,index) in parents">
            <span
              v-if="index > 0"
              :key="p.id"
              class="tags-edit-header__parents__separator"
            >
              /
            </span>
            <span
              :key="p.id"
              :class="{'tags-edit-header__parents__element': p.id !== entity.id}"
              @click="changeTag(p.id)"
            >
              {{ p.tagValue }}
            </span>
          </template>
        </div>
      </div>
      <div v-b-tooltip="hasReferences ? $t('content.tags.merge-tooltip') : ''">
        <b-button
          v-if="tagId"
          id="at-content-tags-create-tag-button"
          v-permission="['canEditTag', entity]"
          variant="primary"
          size="sm"
          class="tags-header__merge-tag-button"
          :disabled="hasReferences"
          @click="mergeTag"
        >
          <span class="align-middle">
            {{ $t('content.tags.merge-button-label') }}
          </span>
          <GjIcon
            key="Cross"
            name="Cross"
            size="20"
          />
        </b-button>
      </div>
    </div>
    <b-tabs v-if="entity">
      <b-tab :title="$t('content.tags.edit-popup.tabs.edit')">
        <b-form-group :label="$t('content.tags.edit-popup.name-label')">
          <b-form-input v-model="entity.tagValue" />
          <b-form-invalid-feedback :state="tagState">
            {{ $t('content.tags.edit-popup.invalid-state') }}
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group :label="$t('content.tags.edit-popup.description-label')">
          <b-form-textarea v-model="entity.description" />
        </b-form-group>
        <b-form-group :label="$t('content.tags.edit-popup.parent-label')">
          <TagSelect
            v-model="entity.parentId"
            :clearable="true"
            :exclude-ids="entity.id ? [entity.id] : []"
          />
        </b-form-group>
        <b-form-group :label="$t('content.tags.edit-popup.references-label')">
          <ReferenceComponent
            v-model="references"
            @add="browseEntries"
            @remove="removeEntries"
          />
        </b-form-group>
      </b-tab>
      <b-tab
        v-if="tagId"
        :title="$t('content.tags.edit-popup.tabs.usage')"
      >
        <Listing
          :is-embed="true"
          :disable-entry-creation="true"
          :always-show-model-filter="false"
          :reference-ids="[tagId]"
          :columns="['status']"
        />
      </b-tab>
      <b-tab
        v-if="tagId && tableData.length > 1"
        :title="$t('content.tags.edit-popup.tabs.child-tags')"
      >
        <div style="display: flex;padding: 20px;gap: 10px;isolation: isolate;border: 1px solid #EBEBED;border-radius: 6px; justify-content: center;">
          <div
            v-if="tableData.length"
            style="width: 100%"
          >
            <div
              v-for="option in tableData.filter(a => a.show)"
              :key="option.id"
              style="padding-left:16px;padding-top:12px;"
            >
              <span>
                <p
                  class="text-nowrap mb-0 tags-sections"
                  :class="[option.collapsable ? 'table__collapse-arrow' : `table__collapse-no-arrow-${option.indent}`, `table__indent-${option.indent}`, 'd-flex']"
                >
                  <GjIcon
                    v-if="option.collapsable"
                    :key="option.collapsed ? 'ArrowDown' : 'ArrowRight'"
                    class="table__collapse-arrow-button cursor-pointer"
                    size="16"
                    style="fill: #052D61;"
                    :name="option.collapsed ? 'ArrowDown' : 'ArrowRight'"
                    @mousedown.native="handleTableCollapse(option)"
                  />
                  <span
                    class="mb-0 table__collapse-name"
                    :class="{'table__collapse-first': option.isFirstOfSet}"
                  >
                    <span :title="option.tagValue">
                      {{ option.tagValue }}
                    </span>
                  </span>
                </p>
              </span>
            </div>
          </div>
          <div
            v-else
            class="tag-details__empty-state"
            style="display: flex; flex-direction: column; align-items: center; justify-content: center; height: 100%;"
          >
            <b-img
              height="80px"
              width="80px"
              :src="require(`@/assets/images/tags/EmptyChildren.svg`)"
            />
            <h6 class="tag-details__empty-state-text">
              {{ $t('content.tags.details.children.empty') }}
            </h6>
          </div>
        </div>
      </b-tab>
    </b-tabs>
    <div v-else>
      {{ $t('content.tags.edit-popup.not-found') }}
    </div>
    <template #footer>
      <b-button @click="handleCancel">
        {{ $t('content.tags.edit-popup.cancel') }}
      </b-button>
      <b-button
        v-permission="entity.id ? ['canEditTag', entity] : ['canCreateCustomTag']"
        variant="primary"
        :disabled="!entity"
        @click="handleSave"
      >
        {{ tagId ? $t('content.tags.edit-popup.save') : $t('content.tags.edit-popup.create') }}
      </b-button>
    </template>
  </b-sidebar>
</template>

<script>
import PopupsMixin from '@/components/popups/PopupsMixin'
import ReferenceComponent from '@/components/fields/Reference/ReferenceComponent.vue'
import TagSelect from '@/views/tags/TagSelect.vue'
import {
  createTag, mapSourceToString, readTag, tagDefaults, updateTag,
} from '@/codex-sdk/tags'
import { ERROR_CODES } from '@/utils/constants'
import gql from 'graphql-tag'
import { ENTRY_STATUSES, transformEntriesGraphQL } from '@/codex-sdk/entries'
import { cloneDeep, isEqual } from 'lodash'
import Listing from '@/views/entries/Listing.vue'

export default {
  name: 'EditTag',
  components: { Listing, TagSelect, ReferenceComponent },
  inject: ['showEntriesPopup', 'showMergeTagPopup', 'showConfirmPopup'],
  mixins: [PopupsMixin],
  apollo: {
    entryCollection: {
      query: gql`
        query ($ids: [String!], $limit: Int) {
          entryCollection (where: {
              id: {
                in: $ids
              }
            }, limit: $limit) {
              items {
                id
                system {
                  title
                  slug
                  status
                  siteId
                  publishedAt
                  modelId
                  modelAlias
                  featuredMedia {
                    id
                    url(transformation: { width: 70, height: 70, format: THUMBNAIL })
                  }
                  scheduledVersions (where: {
                    status: { in: [SCHEDULED] }
                  }) {
                    items {
                      id
                      versionId
                      publishScheduledDate
                      unpublishScheduledDate
                      versionId
                      createdAt
                    }
                  }
                  createdBy {
                    id
                    email
                    firstName
                    lastName
                    imageUrl
                  }
                }
              }
            }
          }
      `,
      fetchPolicy: 'no-cache',
      skip() {
        return !this.entity?.references?.length
      },
      variables() {
        const ids = this.entity.references.map(e => e.entryId)
        return {
          ids,
          limit: ids.length,
        }
      },
      update(results) {
        if (results?.entryCollection?.items) {
          transformEntriesGraphQL(results.entryCollection.items)
        }
      },
      result(results) {
        this.references_ = []
        const ids = this.entity.references
        ids.forEach(e => {
          let item = results.data.entryCollection.items.find(c => c.id === e.entryId)
          if (!item) {
            item = {
              id: e.entryId,
              system: {
                status: ENTRY_STATUSES.DELETED,
                model: {
                  name: e.model,
                  iconId: 'Modeling',
                  alias: e.model,
                },
              },
            }
          }
          this.references_.push(item)
        })
        this.$apollo.queries.entryCollection.skip = true
      },
    },
    tagCollection: {
      query: gql`
        query ($ids: [String!], $limit: Int) {
          tagCollection (where: { id: { in: $ids } }, limit: $limit) {
            items {
              id
              tagValue
              tagAlias
              siteId
            }
          }
        }
      `,
      fetchPolicy: 'no-cache',
      skip() {
        return !this.entity
      },
      variables() {
        const ids = this.entity.path
        return {
          ids,
          limit: ids.length,
        }
      },
      result(results) {
        const items = results.data?.tagCollection?.items || []
        this.parents = []
        this.entity.path.forEach(id => {
          if (id !== this.tagId) {
            const tag = items.find(t => t.id === id)
            this.parents.push(tag)
          }
        })
      },
    },
  },
  data() {
    return {
      oldEntity: null,
      entity: null,
      tagState: true,
      references_: [],
      tableData: [],
      tagNames: {},
      total: 0,
      tagId: this.data?.tag?.id,
      parents: [],
    }
  },
  computed: {
    hasReferences() {
      return this.entity?.references.length > 0
    },
    parentId() {
      return this.data?.parentId
    },
    references: {
      get() { return this.references_ },
      set(v) {
        this.references_ = v
        this.updateReferences()
      },
    },
    hasChanges() {
      if (!this.oldEntity) return false
      return !isEqual(this.oldEntity, this.entity)
    },
  },
  watch: {
    'entity.tagValue': function () {
      this.tagState = true
    },
    tagId() {
      this.references_ = []
      this.fetchTag()
    },
    hasChanges(newVal) {
      this.canClose = !newVal
    },
  },
  mounted() {
    this.fetchTag()
  },
  methods: {
    async visibilityChange(newVal) {
      if (!newVal && this.hasChanges) {
        // Keep sidebar open
        this.showPopup = true
        this.$refs.sidebar.localShow = true

        const answer = await this.showConfirmPopup({})
        if (answer) {
          this.canClose = true
          this.closePopup()
        }
      }
    },
    changeTag(id) {
      if (this.tagId === id) return
      this.tagId = id
      this.fetchTag()
    },
    async mergeTag() {
      if (this.hasReferences) return
      try {
        const response = await this.showMergeTagPopup({ tagsToMerge: [this.tagId] })
        if (response) {
          this.closePopup(true)
        }
      } catch (e) {
        console.log(e)
      }
    },
    updateReferences() {
      if (this.references) {
        this.entity.references = this.references.map(e => ({
          entryId: e.id,
          model: e.system.modelAlias,
        }))
      }
    },
    async handleSave() {
      if (this.entity.id) {
        // update
        try {
          const { data } = await updateTag(this.entity)
          this.oldEntity = cloneDeep(data)
          this.closePopup(data)
        } catch (e) {
          if (e.response.data.code == ERROR_CODES.CONTENT.TagWithAliasAlreadyExists) {
            this.tagState = false
          }
          console.log(e)
        }
      } else {
        // insert
        try {
          this.entity.tagValue = this.entity.tagValue?.split('/')?.filter(Boolean)?.join('/')
          const { data } = await createTag(this.entity)
          this.closePopup(data)
        } catch (e) {
          if (e.response.data.code == ERROR_CODES.CONTENT.TagWithAliasAlreadyExists) {
            this.tagState = false
          }
          console.log(e)
        }
      }
    },
    async handleCancel() {
      if (this.hasChanges) {
        const answer = await this.showConfirmPopup({})
        if (answer) {
          this.closePopup()
        }
      } else {
        this.closePopup()
      }
    },
    async fetchTag() {
      if (!this.tagId) {
        this.entity = tagDefaults({ parentId: this.parentId })
        return
      }
      try {
        const { data } = await readTag(this.tagId)
        this.entity = data
        this.oldEntity = cloneDeep(data)
        this.tableData = this.flatten(cloneDeep([{
          id: data.id,
          tagAlias: data.tagAlias,
          tagValue: data.tagValue,
          source: mapSourceToString(data.source),
          parentId: data.parentId,
          siteId: data.siteId,
          createdBy: { id: data.createdBy },
          children: { total: 1 },
          path: data.path,
        }]))
        this.tableData[0].collapsed = true
        this.fetchChildren(this.tableData[0], false)
      } catch (e) {
        console.log(e)
      }
    },
    async browseEntries() {
      const result = await this.showEntriesPopup({ models: this.acceptedModels })
      if (result?.length) {
        result.forEach(e => {
          if (!this.references.some(el => el.id == e.id)) {
            this.references.push(e)
          }
        })
        this.references = [...this.references]
      }
    },
    removeEntries(index) {
      this.references.splice(index, 1)
      this.updateReferences()
    },
    handleTableCollapse(item, state = undefined) {
      this.tableData = this.tableData.map(tag => {
        if (tag.id === item.id) {
          tag.collapsed = state === undefined ? !tag.collapsed : state
        }

        if (tag.parentId === item.id) {
          tag.show = state === undefined ? !tag.show : state

          if ((state === undefined && !tag.show) || state === false) {
            this.handleTableCollapse(tag, false)
          }
        }

        return tag
      })
      if (!this.tableData.some(tag => tag.parentId === item.id) && state === undefined) {
        this.fetchChildren(item)
      }
    },
    async fetchChildren(parent = { id: null, indent: 0 }, refetch = false) {
      let tags = cloneDeep(this.tableData)
      if (refetch) {
        tags = this.tableData.filter(t => t.parentId !== parent.id)
      }
      try {
        const { data } = await this.$apollo.query({
          query: gql`
            fragment Tag on CodexTag {
              id
              tagAlias
              tagValue
              source
              parentId
              createdBy {
                id
              }
            }
            query tags ($where: CodexTagFilter, $limit: Int, $offset: Int) {
              tags: tagCollection (where: $where, limit: $limit, offset: $offset) {
                items {
                  ...Tag
                  children {
                    total
                  }
                }
                total
              }
            }
          `,
          fetchPolicy: 'no-cache',
          variables: {
            limit: 10000,
            offset: 0,
            where: {
              parentId: {
                eq: parent.id,
              },
            },
          },
        })
        const index = tags.findIndex(t => t.id == parent.id)
        if (data.tags.items[0]) {
          data.tags.items[0].isFirstOfSet = true
        }

        tags.splice(index + 1, 0, ...this.flatten(data.tags.items, parent.id, parent.indent + 1, true))
        this.tableData = tags
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    flatten(items, parentId, indent = 0, show = false) {
      for (let i = 0; i < items.length; i++) {
        const childItems = []

        if (items[i].indent === undefined) {
          items[i].indent = indent
        }

        if (parentId && items[i].parentId === undefined) {
          items[i].parentId = parentId
        }

        if (items[i].show === undefined) {
          items[i].show = indent === 0 || show
        }

        if (items[i].children?.total) {
          items[i].collapsable = true
          items[i].collapsed = false
          if (items[i].children?.items) {
            childItems.push(...this.flatten(items[i].children?.items, items[i].id, indent + 1))
            childItems[0].isFirstOfSet = true
          }
        }

        delete items[i].children
        items = [...items.slice(0, i + 1), ...childItems, ...items.slice(i + 1)]
        this.tagNames[items[i].id] = items[i].title
      }

      return items
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/base/bootstrap-extended/include"; // Bootstrap includes
#at-edit-tags-popup {
  .full-height-layout {
    min-height: 300px;
  }

  .tags-edit-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 28px;
    align-items: center;
    gap: 30px;
  }

  .tags-edit-header__container {
    display: flex;
    flex-direction: column;
    min-width: 0;
  }

  .tags-edit-header__value {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #1E1E36;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .tags-edit-header__parents {
    display: flex;
    gap: 5px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #4B4B5F;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .tags-edit-header__parents__element {
    cursor: pointer;
  }

  .tags-edit-header__parents__element:hover {
    color: #1d79f2;
    text-decoration: underline;
  }

  .entries {
    .page-header__filters-group, .form-control {
      background: #F7F7F8;
      border-radius: 6px;
    }

    .entry-field__table th, .entry-field__table th:hover {
      background: #F7F7F8;
    }

    .entry-field__table thead tr {
      border-bottom: 10px solid #fff;
    }

    .entry-field__table td {
      background-color: white;
      padding: 10px;
    }
  }

  .tags-sections {
    font-weight: 500;
    font-size: 14px;
    color: #1E1E36;
    svg {
      width: 16px;
      height: 16px;
      background: #EBEBED;
      border-radius: 50px;
    }
  }
  .table__collapse-arrow {
    position: relative;
  }

  .table__collapse-arrow-button {
    position: absolute;
    left: -16px;
    top: 5px;
    align-self: center;
    cursor: pointer;
    transition: opacity 0.3s;

    &:hover {
      opacity: 0.6;
    }
  }
  .table__collapse-name {
    font-size: 14px;
    margin-left: 6px;
    display: flex;
    min-width: 0;
  }
  .table__collapse-name span {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  .table__collapse-link {
    color: #052D61;
  }
  .table__collapse-first {
    position: relative;
    &::before {
      content: '';
      width: 12px;
      height: 12px;
      position: absolute;
      border-bottom: 1px solid #E0E5EB;
      border-left: 1px solid #E0E5EB;
      top: 0;
      left: -10px;
    }
    .table__collapse-name {
      cursor: pointer;
      font-size: 14px;

      &:hover {
        color: $primary;
      }
    }
  }
  @for $i from 1 through 20 {
    .table__indent-#{$i} {
      margin-left: $i * 24px;
      .table__collapse-first {
        &::before {
          content: '';
          left: -39px;
        }
      }
    }

    .table__collapse-no-arrow-#{$i} {
      margin-left: $i * 24px;
      .table__collapse-first {
        &::before {
          content: '';
          left: -35px;
        }
      }
    }
  }
  .tags-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 26px;
    gap: 30px;
  }
  .tags-header__content {
    display: flex;
    align-items: center;
    gap: 12px;
    min-width: 0;
    svg {
      width: 24px;
      height: 24px;
      background: #FFFFFF;
      border-radius: 50px;
    }
  }

  .tags-header__title {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #1A1E39;
    margin: 0 0 4px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .tags-header__actions {
    display: flex;
    align-items: center;
    gap: 12px;
    flex-shrink: 0;
  }

  .tags-header__more-options-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 18px;
    gap: 8px;
    background: #fff !important;
    box-shadow: 0px 1px 2px rgba(224, 229, 235, 0.4);
    border-radius: 6px;

    span, svg {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: #676777;
    }
  }
  .tags-header__merge-tag-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 12px;
    gap: 6px;
    background: #206ED5 !important;
    border-radius: 6px;
    flex-shrink: 0;

    span, svg {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      color: #FFFFFF;
    }
  }

  .tag-details-card > .card-body {
    padding: 0;
  }
  .tag-details-card {
    background: #F6F7F9;
    box-shadow: none;
    .tag-details-statistics {
      display: flex;
      flex-direction: column;
      height: 100%;
    }

    .tag-details__statistics {
      .tag-details-statistics__elements {
        border-bottom: 1px solid #EBEBED;
        margin-bottom: 1.5rem;
        .tag-details-statistics__element {
          display: flex;
          .tag-details-statistics__element-icon {
            height: 47px;
            margin-right: 16px;
            padding: 12px;
            border-radius: 6px;
          }
          .tag-details-statistics__element-icon-orange {
            background: #FDE9D3;
            color: #D57D11;
          }
          .tag-details-statistics__element-icon-blue {
            background: #DDE9F9;
            color: #1D64C2;
          }
          .tag-details-statistics__element-stats {
            h6 {
              font-weight: 500;
              font-size: 20px;
              line-height: 21px;
              color: #1A1E39;
            }
            p {
              font-weight: 400;
              font-size: 14px;
              line-height: 21px;
              color: #4B4B5F;
            }
          }
        }
      }
      .tag-details-statistics__description {
        overflow: scroll;
        h6 {
          font-weight: 500;
          font-size: 16px;
          line-height: 21px;
          color: #1A1E39;
          margin-bottom: 12px;
        }
        p {
          font-weight: 400;
          font-size: 14px;
          line-height: 21px;
          color: #4B4B5F;
        }
      }
    }

    .tag-details-entries__header{
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
      h4 {
        margin: 0;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        color: #1A1E39;
      }
      .tag-details-entries__header-button {
        flex-direction: row;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: #206ED5;
      }
    }
  }
  .tag-details__empty-state {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .tag-details__empty-state-text {
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      color: #4B4B5F;
    }
  }
}
</style>
