<template>
  <div>
    <b-form-radio-group v-model="appearance">
      <label
        class="media-field__appearance"
        for="media-field__appearance--gallery"
      >
        <div class="media-field__icon-text">
          <GjIcon
            name="Grid"
            class="media-field__icon"
            size="24"
            style="fill: #1D79F2"
          />
          <span class="media-field__text">
            {{ $t('fields.codex-field-media-content.appearance.media-gallery') }}
          </span>
        </div>
        <b-form-radio
          id="media-field__appearance--gallery"
          name="radio"
          :value="APPEARANCE_TYPES.GRID"
          class="media-field__radio"
        />
      </label>
    </b-form-radio-group>
    <br>

    <FieldHelpTextInput
      :widget="widget"
      :update-attrs="updateAttrs"
    />

    <div class="media-field__default-value">
      <label class="bold-text">
        {{ $t('fields.codex-field-media-content.appearance.upload-new-media') }}
      </label>
      <p class="info">
        {{ $t('fields.codex-field-media-content.appearance.upload-new-media-info') }}
      </p>
      <b-form-radio-group v-model="showUploadNewAsset">
        <b-form-radio :value="true">
          {{ $t('fields.codex-field-media-content.appearance.yes') }}
        </b-form-radio>
        <b-form-radio :value="false">
          {{ $t('fields.codex-field-media-content.appearance.no') }}
        </b-form-radio>
      </b-form-radio-group>
    </div>
    <FieldConditions
      v-model="hidden"
      :widget="widget"
      :label="$t('fields.general.hidden.label')"
      :show-error-message="false"
      :tooltip="$t('fields.general.hidden.info')"
    />
  </div>
</template>

<script>

import { generateComputedPropsFromAttrs } from '@/components/codex-layout-editor/BuilderUtils'
import { VALUE_TYPES } from '@/views/models/constants'
import FieldHelpTextInput from '@/components/fields/FieldHelpTextInput.vue'
import BaseFieldMixin from '@/components/fields/BaseFieldMixin'
import FieldConditions from '@/components/fields/FieldConditions.vue'
import { APPEARANCE_TYPES } from './index'

export default {
  components: { FieldConditions, FieldHelpTextInput },
  mixins: [BaseFieldMixin],
  data() {
    return {
      APPEARANCE_TYPES,
      VALUE_TYPES,
    }
  },
  computed: {
    ...generateComputedPropsFromAttrs([
      'appearance',
      'valueType',
      'showUploadNewAsset',
      'helpText',
      'hidden',
    ]),
  },
}

</script>

<style lang="scss" scoped>
.help-message{
  font-weight: 600;
}
.bold-text{
  font-weight: 600;
}
.info{
  font-size: 14px;
  color: #667C99;

  opacity: .6;
}
.media-field__default-value{
  font-size: 14px;
  margin-top: 20px;
  margin-bottom: 1rem;
}
.button_clear{
  margin-left: 15px;
}
.button_clear:hover{
  cursor: pointer;
  color: rgb(63, 63, 199);
}

.media-field__appearance {
  width: 100%;
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 4px 8px;
  border: 1px solid #E0E5EB;
  border-radius: 4px;
  margin-bottom: 8px;
}
.media-field__icon-text {
  display: flex;
  flex-direction: row;
}
.media-field__icon {
  box-shadow: 0px 4px 8px rgba(163, 176, 194, 0.32);
  border-radius: 3px;
}
.media-field__text {
  margin-top: 0.2rem;
  margin-bottom: 0;
  margin-left: 12px;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #052D61;
}
.media-field__radio {
  width: 16px;
  height: 16px;
  margin-right: -10px;

}
.media-field__help-text {
  margin-top: 24px;
}
.media-field__textarea {
  width: 100%;
  padding: 12px;
  border-radius: 4px;
  border: 1px solid #E0E5EB;
  resize: none;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  background-color: #fff;
  outline: none;
}
</style>
