/**
 * Constants
 */

export const layoutTypes = Object.freeze({
  SECTION: 1,
  ARTICLE: 2,
  TAG: 3,
  AUTHOR: 4,
  GALLERY: 5,
  HOMEPAGE: 8,
  SEARCH: 9,
  DISCUSSION: 10,
  ROOT: 11,
  ERROR: 12,
})

// Used inside widget files
export const widgetGroups = Object.freeze({
  GENERAL: 'general',
  ADS: 'ads',
  AUTHOR: 'author',
  ARTICLE: 'article',
  GALLERIES: 'galleries',
  LISTS: 'lists',
  MENUS: 'menus',
  SOCIAL: 'social',
  MEDIA: 'media',
  EXTERNAL: 'external',
})
