<template>
  <div class="codex-field__label-container">
    <div class="codex-field__label-container__top">
      <div class="codex-field__label-container__top-left">
        <div
          :ref="widget.attrs.alias"
          class="codex-field__label"
          :class="{'codex-field__label__required': required}"
        >
          <span class="codex-field__label-text">
            {{ name }}
          </span>
          <span
            v-if="required"
            class="codex-field__label-required"
          >
            *
          </span>
        </div>
        <GjIcon
          v-if="helpTextDisplay === 'tooltip' && helpText"
          v-b-tooltip.hover="helpText"
          class="codex-fields-help-text"
          name="Info"
          size="18"
        />
        <slot name="top-left" />
        <div
          v-if="entryId"
          class="codex-field__comments hide-in-quick-view"
          :style="'visibility: ' + (fieldComments.length || showComments ? 'visible' : 'hidden') + ';'"
          @click.stop="openComments"
        >
          <GjIcon
            name="CommentAlt"
            size="18"
          />
          <span>{{ fieldComments.length }}</span>
          <div
            v-if="showComments"
            ref="commentsBubble"
            style="position: absolute;z-index: 11; top: 24px;left: 0; max-height: 400px;  overflow-y: auto; background: #fff;box-shadow: 0px 4px 25px 0px #11153814;border-radius: 8px;"
            :class="[baseClass]"
            @click.stop
          >
            <div ref="commentsContainer">
              <Comment
                v-for="(comment, i) in fieldComments"
                :key="comment.id"
                :comment="comment"
                :saving="saving && i == fieldComments.length - 1"
                class="bubble-comment"
              />
            </div>

            <div
              class="menububble__link-popup"
              style="display: flex; gap: 15px; position:sticky; bottom: 0; box-shadow: none;"
            >
              <CommentEdit
                v-model="showComments"
                :field-alias="widget.attrs.alias"
                @saving="(e) => saving = e"
                @addComment="addComment"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="codex-field__label-container__top-right">
        <slot name="top-right" />
      </div>
    </div>

    <div class="codex-field__label-container__above-field">
      <div
        v-if="helpTextDisplay === 'inline-top' && helpText"
        class="codex-fields-help-text-inline"
      >
        {{ helpText }}
      </div>
    </div>
  </div>
</template>

<script>
import { TYPES } from '@/views/models/constants'
import Comments from '@/components/fields/Comments.vue'
import CommentEdit from '@/components/fields/CommentEdit.vue'
import { someParentHasClass } from '@/utils/helpers'
import Comment from '@/components/codex-editor/panels/Comment.vue'

export default {
  name: 'FieldName',
  // eslint-disable-next-line vue/no-unused-components
  components: { Comment, CommentEdit, Comments },
  inject: ['entry', 'widget', 'allComments', 'setSelectedEditor', 'toastNotification'],
  props: {
    name: {
      type: String,
      required: true,
    },
    helpText: {
      type: String,
      required: true,
    },
    helpTextDisplay: {
      type: String,
      default: 'tooltip',
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      TYPES,
      showComments: false,
      toScroll: false,
      saving: false,
    }
  },
  computed: {
    baseClass() {
      return `comments-bubble__${this.widget.attrs.alias}`
    },
    fieldComments() {
      return this.allComments()?.filter(c => c.attrs.fieldAlias === this.widget.attrs.alias && !c.attrs.blockId) || []
    },
    entryId() {
      return this.entry()?.id
    },
  },
  watch: {
    fieldComments: {
      handler(newValue) {
        let show = false
        newValue.forEach(c => {
          if (c.openBubble) show = true
        })
        if (show) {
          setTimeout(() => {
            this.showComments = true
          }, 100)
        }

        if (this.showComments && this.toScroll) {
          setTimeout(() => {
            this.scrollToBottom()
          }, 100)
        }
      },
      deep: true,
    },
    showComments(v) {
      if (v) {
        setTimeout(() => {
          this.scrollToBottom()
          document.addEventListener('click', this.documentClick)
        }, 10)
      } else {
        document.removeEventListener('click', this.documentClick)
      }
    },
  },
  mounted() {
    this.$root.$on('openComments', this.onOpenComments)
    this.$root.$on('refreshComments', this.refreshComments)
    this.$root.$on('ai-generated-content', this.setAiGeneratedContent)
  },
  beforeDestroy() {
    document.removeEventListener('click', this.documentClick)
    this.$root.$off('openComments', this.onOpenComments)
    this.$root.$off('refreshComments', this.refreshComments)
    this.$root.$off('ai-generated-content', this.setAiGeneratedContent)
  },
  methods: {
    setAiGeneratedContent(alias) {
      if (this.widget.attrs.alias === alias) {
        this.setSelectedEditor(alias)
        this.$nextTick(() => {
          const rect = this.$refs[this.widget.attrs.alias].getBoundingClientRect()
          const scrollTop = window.pageYOffset || document.documentElement.scrollTop
          const targetScroll = scrollTop + rect.top - 100

          window.scrollTo({
            top: targetScroll,
            behavior: 'smooth',
          })
        })
      }
    },
    refreshComments(scroll) {
      this.toScroll = scroll
    },
    onOpenComments(data) {
      if (this.entryId === data.entryId) {
        this.showComments = data.alias === this.widget.attrs.alias
      }
    },
    openComments() {
      this.showComments = true
      this.$root.$emit('openComments', {
        entryId: this.entryId,
        alias: this.widget.attrs.alias,
      })
    },
    addComment(e) {
      this.fieldComments.push(e)
    },
    scrollToBottom() {
      this.$refs.commentsBubble.scroll({ behavior: 'smooth', top: this.$refs.commentsContainer.offsetHeight })
    },
    documentClick(e) {
      if (!someParentHasClass(e.target, this.baseClass) && !someParentHasClass(e.target, 'tooltip-inner')) {
        this.showComments = false
        this.fieldComments.forEach(c => {
          c.openBubble = false
        })
      }
    },
  },
}
</script>

<style lang="scss">
.bubble-comment:last-child {
  border: none;
}

.codex-field__label-container {
  width: 100%;
}

.codex-field__label-container__top {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-width: 0;
  justify-content: space-between;
}

.codex-field__label-container__top-left,
.codex-field__label-container__top-right {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-width: 0;
  gap: 10px;
}

.codex-field__label-container__above-field > *:first-child {
  margin-top: 8px;
}

.codex-field__label {
  width: fit-content;
  min-height: 25px;
  background: #D8E6FD;
  border-radius: 4px;
  min-width: 0;
  padding: 2px 4px;
  position: relative;

  .codex-field__label-text {
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 18px;
    text-transform: uppercase;
    color: #052D61;
    overflow-wrap: break-word;
  }

  .codex-field__label-required {
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 18px;
    text-transform: uppercase;
    color: #E34850;
    position: absolute;
    right: 7px;
    top: 4px;
  }
}

.codex-field__label__required {
  padding-right: 16px;
}

.codex-field__comments {
  display: flex;
  align-items: center;
  gap: 6px;
  visibility: hidden;
  cursor: pointer;
  position: relative;
}

.codex-field:hover .codex-field__comments {
  visibility: visible !important;
}

.codex-field__ai-icon {
  color: #206ED5;
  background: #E9F1FB;
  cursor: pointer;
}
</style>
