<template>
  <b-col
    :id="column.attrs._id ? column.attrs._id : null"
    :class="generateColClass(column)"
  >
    <div class="codex-entry__col">
      <component
        :is="item.isField ? `${item.type}-render` : item.type"
        v-for="(item, index) in column.content"
        :id="item.attrs._id ? item.attrs._id : null"
        :key="item.id"
        :row="item.type === 'codex_row' ? item : null"
        :class="item.type === 'codex_row' ? null : generateWidgetClass(item)"
        :row-index="item.type === 'codex_row' ? index : null"
        :block="item"
        :widget="item"
        :container="item.type === 'codex_container' ? item : null"
        :items="item.blocks || item.content"
      />
    </div>
  </b-col>
</template>

<script>
import FieldMixin from './FieldMixin'

export default {
  name: 'CodexColumn',
  mixins: [FieldMixin],
  props: {
    column: Object,
    rowIndex: Number,
    columnIndex: Number,
  },
  inject: ['generateDisplayClasses'],
  methods: {
    generateColClass(col) {
      const customClass = col.attrs._class
      const displayClasses = this.generateDisplayClasses(col, 'col')
      const widthClasses = this.generateColumnWidthClasses(col)
      const orientationClasses = this.generateColumnOrientationClasses(col)
      const alignClasses = this.generateColumnAlignClasses(col)
      const orderClasses = this.generateColumnOrderClasses(col)

      return [
        customClass,
        ...displayClasses,
        ...widthClasses,
        ...orientationClasses,
        ...alignClasses,
        ...orderClasses,
      ].join(' ')
    },
    generateColumnOrientationClasses(col) {
      const desktopOrientation = col.attrs._responsive.desktop.orientation
      const tabletOrientation = col.attrs._responsive.tablet.orientation
      const mobileOrientation = col.attrs._responsive.mobile.orientation

      return [
        desktopOrientation === 'vertical' ? 'flex-lg-column flex-xl-column' : 'flex-lg-row flex-xl-row',
        tabletOrientation === 'vertical' ? 'flex-md-column' : 'flex-md-row',
        mobileOrientation === 'vertical' ? 'flex-column flex-sm-column' : 'flex-row flex-sm-row',
      ]
    },
    generateColumnAlignClasses(col) {
      const classes = []

      const desktopAlign = col.attrs._responsive.desktop.align
      const tabletAlign = col.attrs._responsive.tablet.align
      const mobileAlign = col.attrs._responsive.mobile.align

      // DESKTOP ALIGN
      if (desktopAlign === 'center') {
        classes.push('justify-content-lg-center justify-content-xl-center')
      } else if (desktopAlign === 'right') {
        classes.push('justify-content-lg-end justify-content-xl-end')
      } else {
        classes.push('justify-content-lg-start justify-content-xl-start')
      }
      // TABLET ALIGN
      if (tabletAlign === 'center') {
        classes.push('justify-content-md-center')
      } else if (tabletAlign === 'right') {
        classes.push('justify-content-md-end')
      } else {
        classes.push('justify-content-md-start')
      }
      // MOBILE ALIGN
      if (mobileAlign === 'center') {
        classes.push('justify-content-center justify-content-sm-center')
      } else if (mobileAlign === 'right') {
        classes.push('justify-content-end justify-content-sm-end')
      } else {
        classes.push('justify-content-start justify-content-sm-start')
      }

      return classes
    },
    generateColumnWidthClasses(col) {
      const desktopCols = col.attrs._responsive.desktop.columns
      const tabletCols = col.attrs._responsive.tablet.columns
      const mobileCols = col.attrs._responsive.mobile.columns

      return [
        `col-${mobileCols}`,
        `col-sm-${mobileCols}`,
        `col-md-${tabletCols}`,
        `col-lg-${desktopCols}`,
        `col-xl-${desktopCols}`,
      ]
    },
    generateColumnOrderClasses(col) {
      const desktopOrder = col.attrs._responsive.desktop.order
      const tabletOrder = col.attrs._responsive.tablet.order
      const mobileOrder = col.attrs._responsive.mobile.order

      return [
        `order-${mobileOrder}`,
        `order-sm-${mobileOrder}`,
        `order-md-${tabletOrder}`,
        `order-lg-${desktopOrder}`,
        `order-xl-${desktopOrder}`,
      ]
    },
    generateWidgetClass(widget) {
      const customClass = widget.attrs._class
      const displayClasses = this.generateDisplayClasses(widget, 'widget')

      return [
        customClass,
        ...displayClasses,
        'codex-entry___col',
      ].join(' ')
    },
  },
}
</script>
