<template>
  <transition>
    <b-sidebar
      id="sections__sidebar"
      v-model="showPopup"
      :title="!getSection ? $t('content.sections.overview.create-section') : $t('content.sections.overview.update-section')"
      class="new-section__sidebar c-sidebar c-sidebar--md "
      backdrop-variant="dark"
      :no-close-on-route-change="true"
      backdrop
      shadow
      right
    >
      <div>
        <div class="d-flex flex-column">
          <h3 class="section-popup__title">
            {{ !section.id ? $t('content.sections.overview.create-new-section') : ($t('general.edit') + ' ' + section.title) }}
          </h3>
        </div>
        <b-form-group
          v-if="section.id"
          :label="$t('content.sections.overview.id')"
          class="section-popup__field"
        >
          <b-form-input
            id="at-sections-create-id-input"
            v-model="section.id"
            readonly
          />
          <span
            class="section-popup__field-copy"
            @click="copy(section.id)"
          >
            <GjIcon
              name="Copy"
              size="20"
            />
          </span>
        </b-form-group>
        <b-form-group
          class="new-section__title"
          :label="$t('content.sections.overview.title')"
        >
          <b-input
            id="at-sections-create-title-input"
            v-model="section.title"
            :placeholder="$t('content.sections.overview.title-placeholder')"
          />
        </b-form-group>

        <b-form-group :label="$t('content.sections.overview.parent-section')">
          <SectionsSelect
            v-model="section.parentId"
            :placeholder="$t('content.sections.overview.parent-section-placeholder')"
            :exclude-ids="[section.id]"
            class="sections-parent__section"
          />
        </b-form-group>

        <b-form-group :label="$t('content.sections.overview.description')">
          <quillEditor
            ref="myQuillEditor"
            v-model="section.description"
            :options="editorOption"
            class="section-quill-editor"
          >
            <div
              :id="idEditor"
              slot="toolbar"
            >
              <select class="ql-header" />

              <button
                :title="$t('fields.codex-field-rich-text.tooltips.bold')"
                class="ql-bold"
              />
              <button
                :title="$t('fields.codex-field-rich-text.tooltips.italic')"
                class="ql-italic"
              />
              <button
                :title="$t('fields.codex-field-rich-text.tooltips.underline')"
                class="ql-underline"
              />

              <button
                :title="$t('fields.codex-field-rich-text.tooltips.code')"
                class="ql-code-block"
              />

              <button
                class="ql-list"
                :title="$t('fields.codex-field-rich-text.tooltips.bullet-list')"
                value="bullet"
              />

              <button
                class="ql-list"
                :title="$t('fields.codex-field-rich-text.tooltips.ordered-list')"
                value="ordered"
              />

              <button
                :title="$t('fields.codex-field-rich-text.tooltips.blockquote')"
                class="ql-blockquote"
              />

              <button
                :title="$t('fields.codex-field-rich-text.tooltips.hyperlink')"
                class="ql-link"
              />
            </div>
          </quillEditor>
        </b-form-group>

        <b-form-group>
          <div class="d-flex justify-content-between new-section__url">
            <p>
              {{ $t('content.sections.overview.url') }}
            </p>
            <GjIcon
              name="RefreshAlt"
              @click.native="setUrl"
            />
          </div>
          <b-input-group>
            <b-input-group-prepend is-text>
              <GjIcon
                name="Hyperlink"
                style="color:#206ED5;"
              />
            </b-input-group-prepend>
            <b-form-input
              v-model="section.url"
              type="text"
            />
          </b-input-group>
        </b-form-group>

        <b-form-group
          class="mt-2"
          :label="$t('content.sections.overview.related-entries')"
        >
          <ReferenceComponent
            v-model="references"
            :loading="$apollo.loading"
            @add="browseEntries"
            @remove="remove"
          />
        </b-form-group>

        <b-form-group>
          <div class="d-flex justify-content-between new-section__media mt-1">
            <p>{{ $t('content.sections.overview.upload-media') }}</p>
            <span
              v-if="media.length"
              class="media-field__edit-text"
              @click="browseMedias"
            >
              {{ $t('fields.codex-field-media-content.preview.edit') }}
              <GjIcon
                name="EditAlt"
                style="color:#455A82;"
              />
            </span>
          </div>
          <div class="media-content__wrapper">
            <NoAssets
              v-if="!media.length"
              @insert="browseMedias"
            />
            <DraggableAssets
              v-model="media"
              :type="'multiple'"
              @delete="removeMedia"
              @edit="editMedia"
              @metadata="editMediaMetadata"
            />
          </div>
        </b-form-group>

      </div>
      <template #footer>
        <b-button
          id="at-sections-sidebar-cancel-btn"
          variant="outline-secondary"
          class="footer-button"
          @click="handleCancel"
        >
          {{ $t('general.cancel') }}
        </b-button>
        <b-button
          id="at-sections-sidebar-create-btn"
          v-permission="section.id ? ['canEditSection', section] : ['canCreateSection']"
          variant="primary"
          class="footer-button"
          @click="handleOk"
        >
          {{ section.id ? $t('general.save') : $t('general.create') }}
        </b-button>
      </template>
    </b-sidebar>
  </transition>
</template>

<script>
import SectionsSelect from '@/components/SectionsSelect.vue'
import { quillEditor } from 'vue-quill-editor'
import ReferenceComponent from '@/components/fields/Reference/ReferenceComponent.vue'
import DraggableAssets from '@/components/assets/DraggableAssets.vue'
import NoAssets from '@/components/fields/MediaContent/NoAssets.vue'
import PopupsMixin from '@/components/popups/PopupsMixin'
import {
  readSection, updateSection, createSection, sectionDefaults,
} from '@/codex-sdk/sections'
import gql from 'graphql-tag'
import { ENTRY_STATUSES, transformEntriesGraphQL } from '@/codex-sdk/entries'
import { ASSET_TYPE_MAPPING, ASSET_TYPES } from '@/codex-sdk/assets'
import { useClipboard } from '@vueuse/core'
import { toSlugV2 } from '@/utils/helpers'

export default {
  name: 'SectionsPopup',
  inject: ['showEntriesPopup', 'showAssetsPopup', 'showEditAssetPopup', 'showImageEditorPopup', 'toastNotification'],
  components: {
    SectionsSelect, quillEditor, ReferenceComponent, DraggableAssets, NoAssets,
  },
  apollo: {
    assetCollection: {
      query: gql`
        query ($ids: [String!], $limit: Int) {
          assetCollection (where: {
              id: {
                in: $ids
              }
            }, limit: $limit) {
              items {
                id
                folderId
                title
                expiresAt
                fileName
                type
                author
                source
                alt
                copyright
                caption
                url(transformation: {format: THUMBNAIL})
                attrs
                createdBy {
                  id
                  firstName
                  lastName
                  email
                  imageUrl
                }
                updatedBy {
                  id
                  firstName
                  lastName
                  email
                  imageUrl
                }
                createdAt
                updatedAt
              }
            }
          }
      `,
      fetchPolicy: 'no-cache',
      variables() {
        return {
          ids: this.section?.media?.map(a => a.id),
          limit: this.section?.media?.length,
        }
      },
      skip() {
        return !this.section?.media?.length
      },
      result(results) {
        this.media_ = []
        const ids = this.section?.media?.map(a => a.id)
        ids.forEach(e => {
          const item = results.data.assetCollection.items.find(c => c.id === e)
          if (item) {
            this.media_.push(item)
          }
        })
        this.$apollo.queries.assetCollection.skip = true
      },
    },
    entryCollection: {
      query: gql`
        query ($ids: [String!], $limit: Int) {
          entryCollection (where: {
              id: {
                in: $ids
              }
            }, limit: $limit) {
              items {
                id
                system {
                  title
                  slug
                  status
                  publishedAt
                  featuredMedia {
                    id
                    url(transformation: { width: 70, height: 70, format: THUMBNAIL })
                  }
                  modelId
                  modelAlias
                  createdBy {
                    id
                    email
                    firstName
                    lastName
                    imageUrl
                  }
                  scheduledVersions (where: {
                    status: { in: [SCHEDULED] }
                  }) {
                    items {
                      id
                      versionId
                      publishScheduledDate
                      unpublishScheduledDate
                      versionId
                      createdAt
                    }
                  }

                }
              }
            }
          }
      `,
      fetchPolicy: 'no-cache',
      skip() {
        return !this.section.references?.length
      },
      variables() {
        const ids = this.section.references?.map(e => e.entryId)
        return {
          ids,
          limit: ids.length,
        }
      },
      update(results) {
        if (results?.entryCollection?.items) {
          transformEntriesGraphQL(results.entryCollection.items)
        }
      },
      result(results) {
        this.references_ = []
        const ids = this.section.references
        ids.forEach(e => {
          let item = results.data.entryCollection.items.find(c => c.id === e.entryId)
          if (!item) {
            item = {
              id: e.entryId,
              system: {
                status: ENTRY_STATUSES.DELETED,
                model: {
                  name: e.model,
                  iconId: 'Modeling',
                  alias: e.model,
                },
              },
            }
          }
          this.references_.push(item)
        })
        this.$apollo.queries.entryCollection.skip = true
      },
    },
  },
  mixins: [PopupsMixin],
  data() {
    return {
      section: sectionDefaults(),
      idEditor: `rich-text-editor-${Math.random()}`.replaceAll('.', ''),
      loading: true,
      references_: [],
      ASSET_TYPES,
      media_: [],
    }
  },
  computed: {
    media: {
      get() {
        return this.media_
      },
      set(v) {
        this.media_ = v
      },
    },
    references: {
      get() { return this.references_ },
      set(v) {
        this.references_ = v
        this.updateValue()
      },
    },
    editorOption() {
      return {
        modules: {
          clipboard: true,
          toolbar: {
            container: `#${this.idEditor}`,
          },
        },
        bounds: '.quill-editor',
        placeholder: this.$t('content.sections.overview.description-placeholder'),

      }
    },
    getSection() {
      return this.data.section
    },
  },
  watch: {
    media: {
      async handler() {
        this.section.media = this.media.map(i => ({ id: i.id, type: ASSET_TYPE_MAPPING.toNumber(i.type) }))
      },
    },
  },
  mounted() {
    this.setSection()
  },
  methods: {
    copy(text) {
      const { copy } = useClipboard()
      copy(text)

      this.toastNotification({
        icon: 'Copy',
        variant: 'success',
        title: this.$t('content.sections.overview.copy-id.title'),
        text: this.$t('content.sections.overview.copy-id.text'),
      })
    },
    async editMediaMetadata(media) {
      await this.showEditAssetPopup(media)
    },
    async editMedia(media) {
      const data = await this.showImageEditorPopup({ imageId: media.id })
      if (data?.id) {
        const asset = this.section.media.find(a => a.id === media.id)
        if (asset) {
          Object.assign(asset, { id: data.id })
        }
      }
    },
    setUrl() {
      if (this.section.title) {
        this.section.url = `/section/${toSlugV2(this.section.title)}`
      }
    },
    removeMedia(idOrIds) {
      if (Array.isArray(idOrIds)) {
        this.medias = this.medias.filter(i => !idOrIds.includes(i.id))
        return
      }

      this.media = this.media.filter(i => i.id !== idOrIds)
    },
    async browseMedias() {
      const data = await this.showAssetsPopup({ selectedAssets: this.media, types: [ASSET_TYPES.IMAGE] })
      if (!data) { return }
      this.media = [...data]
    },
    updateValue() {
      if (this.references) {
        this.section.references = this.references.map(e => ({
          entryId: e.id,
          model: e.system.modelAlias,
        }))
      }
    },
    async browseEntries() {
      let selectedReferences = []
      if (this.references) {
        selectedReferences = this.references.map(e => e.id)
      }

      const excludeIds = [...selectedReferences]

      const result = await this.showEntriesPopup({ excludeIds })
      if (result?.length) {
        const references = this.references
        result.forEach(e => {
          if (!references.find(el => el.id == e.id)) {
            references.push(e)
          }
        })
        this.references = references
      }
    },
    remove(index) {
      this.references.splice(index, 1)
      this.updateValue()
    },
    async setSection() {
      if (!this.getSection) return

      try {
        this.loading = true
        const { data: sectionFromAPI } = await readSection(this.getSection.id)
        this.section = { ...sectionFromAPI }
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    handleCancel() {
      this.showPopup = false
      this.section = sectionDefaults()
    },
    async handleOk() {
      try {
        if (!this.section.id) {
          const { data } = await createSection(this.section)
          this.section.id = data.id
          if (this.section.id) {
            this.closePopup(this.section)
          }
        } else {
          await updateSection(this.section)
          this.closePopup(this.section)
        }
      } catch (e) {
        console.log(e)
      }
    },
  },
}
</script>

<style lang="scss" scoped>

.section-popup__title{
font-size:16px;
color: #1D417B;
 margin:0;
}

.seo-tab{
  overflow-x: none;
}

::v-deep .section-quill-editor{
  .ql-blank, .ql-editor{
    min-height:88px;
    &::before{
      font-style:normal;
      color: #A3B0C2;
      font-size: 14px
    }
  }
}

.new-section__url, .new-section__media{
  padding-bottom:4px;
  p{
     margin:0;
     font-size: 14px;
     color: #667c99;
  }
  svg{
    width:14px;
    cursor:pointer;
  }
  span{
    cursor:pointer;
     color: #667c99;
     font-size: 14px;
  }
}

.sections-parent__section{
::v-deep .vs__selected-options{
  .vs__search::placeholder{
    color: #A3B0C2;
  }
}
}
.new-section__title{
  margin-top:10px;
  div{
    input::placeholder{
    color: #A3B0C2;
    font-size: 14px;
    }
  }
}

.new-section__sidebar{
  ::v-deep .b-sidebar{
   width: 850px !important;
  }
}
</style>

<style lang="scss">
.section-popup__field {
  > div {
    position: relative;

    .section-popup__field-copy {
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
    }
  }
}
</style>
