<template>
  <b-form-group>
    <div class="color-picker-dropdown">
      <b-button
        ref="button"
        v-b-tooltip.hover.html.top="getTooltipData('color-style')"
        variant="flat-dark"
        :style="`color: ${markData.attrs.color || '#000'};`"
        @click="toggleDropdown"
      >
        <GjIcon
          name="BrushPaintColorAlt"
        />
      </b-button>

      <div
        v-show="showDropdown"
        ref="dropdown"
        class="color-picker-dropdown__dropdown"
      >
        <div class="color-picker-dropdown__dropdown-heading">
          <span>
            {{ $t('codex-editor.panels.color-style-panel.text-color-heading') }}
          </span>
        </div>

        <div
          v-for="color in predefinedColors"
          :key="color.color"
          :style="`--color: ${color.color || '#000'} `"
          class="color-picker-dropdown__dropdown-item"
          :class="{
            'color-picker-dropdown__dropdown-item--active': markData.attrs.color === color.color,
          }"
          @click="setColor(color.color)"
        >
          <span class="color-picker-dropdown__dropdown-item-preview">A</span>
          <div>
            {{ color.label }}
          </div>
          <span class="color-picker-dropdown__dropdown-item-check">
            <GjIcon
              name="Check"
              size="16"
            />
          </span>
        </div>

        <div class="color-picker-dropdown__dropdown-divider" />

        <div
          class="color-picker-dropdown__dropdown-item"
          :class="{
            'color-picker-dropdown__dropdown-item--active': markData.customColor,
          }"
        >
          <label>
            <input
              type="color"
              class="color-picker-dropdown__dropdown-color-picker"
              @input="(e) => setColorThrottled(e.target.value)"
            >
            <GjIcon
              name="ColorEyedropperEyepicker"
              size="16"
              :style="`color: ${markData.customColor ? markData.attrs.color : '#000'};`"
            />
            <div>
              {{ $t('codex-editor.panels.color-style-panel.custom-color') }}
            </div>
            <span class="color-picker-dropdown__dropdown-item-check">
              <GjIcon
                name="Check"
                size="16"
              />
            </span>
          </label>
        </div>
      </div>
    </div>
  </b-form-group>
</template>

<script>
import { createPopper } from '@popperjs/core'
import { throttle } from 'lodash'
import { someParentHasClass } from '@/utils/helpers'

export default {
  inject: ['getTooltipData'],
  name: 'ColorPickerPanel',
  props: {
    commands: Object,
    isActive: Object,
    editor: Object,
  },
  data() {
    return {
      predefinedColors: [
        { color: undefined, label: this.$t('codex-editor.panels.color-style-panel.default') },
        { color: '#FFAB00', label: this.$t('codex-editor.panels.color-style-panel.orange') },
        { color: '#36B37E', label: this.$t('codex-editor.panels.color-style-panel.green') },
        { color: '#E34850', label: this.$t('codex-editor.panels.color-style-panel.red') },
        { color: '#206ED5', label: this.$t('codex-editor.panels.color-style-panel.blue') },
        { color: '#8C7BE4', label: this.$t('codex-editor.panels.color-style-panel.purple') },
        { color: '#A1A1A1', label: this.$t('codex-editor.panels.color-style-panel.dark-gray') },
      ],
      popper: null,
      setColorThrottled: throttle(this.setColor, 200),
      showDropdown: false,
      markData: {
        active: false,
        customColor: false,
        attrs: { color: null },
      },
    }
  },
  computed: {
    isColorStyleActive() {
      return this.editor.activeMarks.color_style()
    },
    isCustomColor() {
      return this.isColorStyleActive && this.predefinedColors.every(color => color.color !== this.editor.activeMarkAttrs.color_style.color)
    },
  },
  watch: {
    'editor.selection': function () {
      this.markData = {
        active: this.isColorStyleActive,
        customColor: this.isCustomColor,
        attrs: this.editor.activeMarkAttrs.color_style,
      }
    },
  },
  mounted() {
    this.popper = createPopper(this.$refs.button, this.$refs.dropdown, {
      placement: 'bottom-start',
      strategy: 'fixed',
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, 4],
          },
        },
      ],
    })
    document.addEventListener('mousedown', this.documentClick)
  },

  beforeDestroy() {
    document.removeEventListener('mousedown', this.documentClick)
  },

  methods: {
    setColor(color) {
      this.commands.color_style({ color })
    },
    toggleDropdown() {
      this.showDropdown = !this.showDropdown

      this.$nextTick(() => {
        this.popper.update()
      })
    },
    documentClick(e) {
      if (
        !someParentHasClass(e.target, 'color-picker-dropdown')
      ) {
        this.showDropdown = false
      }
    },
  },
}
</script>

<style lang="scss">
.color-picker-dropdown {

  .color-picker-dropdown__dropdown {
    display: flex;
    flex-direction: column;
    padding: 12px 8px 8px;
    min-width: 178px;
    background: #FFFFFF;
    box-shadow: 0 4px 25px rgba(17, 21, 56, 0.08);
    border-radius: 4px;
  }
  .color-picker-dropdown__dropdown-heading {
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 18px;
    text-transform: uppercase;
    color: #667C99;
    padding: 0 8px 4px;
  }
  .color-picker-dropdown__dropdown-divider {
    height: 1px;
    background: #DADFE7;
    margin: 8px -8px;
    width: calc(100% + 16px);
  }
  .color-picker-dropdown__dropdown-item {
    display: flex;
    align-items: center;
    gap: 10px;

    padding: 4px 8px;

    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #052D61;
    cursor: pointer;
    position: relative;

    &:hover {
      background: #ECF3FF;
      border-radius: 3px;
    }

    div {
      flex-grow: 1;
    }

    label {
      display: contents;
      font-size: 14px;
      line-height: 18px;
      color: #052D61;
      cursor: pointer;
    }

    .color-picker-dropdown__dropdown-item-check {
      display: none;
      color: #206ED5;
    }

    &.color-picker-dropdown__dropdown-item--active {
      background: #ECF3FF;
      border-radius: 3px;

      .color-picker-dropdown__dropdown-item-check {
        display: block;
      }
    }

    .color-picker-dropdown__dropdown-item-preview {
      display: flex;
      justify-content: center;
      align-items: center;

      width: 18px;
      height: 18px;

      border: 1px solid var(--color);
      border-radius: 3px;

      color: var(--color);
      font-style: normal;
      font-weight: 700;
      font-size: 10px;
      line-height: 12px;
    }

    .color-picker-dropdown__dropdown-color-picker {
      position: absolute;
      opacity: 0;
      bottom: -2px;
      width: 0;
      height: 0;
      left: 0;
    }

  }
}
</style>
