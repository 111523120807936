<template>
  <div class="ai-content-suggestions">
    <div
      v-if="seoFeedback_.length && !loading"
      class="tab-content"
    >
      <div
        v-for="(field, index) in seoFeedback_"
        :key="index"
        class="suggestion-field"
        :style="{ boxShadow: showAiContent === index ? '0 6px 10px rgba(0, 0, 0, 0.2)' : '' }"
      >
        <div
          class="initial-field"
          @click="showSuggestedContent(index, field)"
        >
          <div class="field-header">
            <h6 class="field-title">{{ field.fieldName.toUpperCase() }}
            </h6>
            <button
              v-if="!field.isVisible"
              class="suggestion-button"
              @click="retrySeoSuggestions(field, index)"
            >
              <b-spinner
                v-if="loadingSuggestions[field.fieldAlias]"
                class="field-spinner"
                variant="primary"
                small
              />
              {{ $t('fields.general.ai-popup.results.retry-label') }}
            </button>
          </div>
          <transition name="fade-out-right">
            <p
              v-show="field.isVisible"
              class="field-feedback"
            >
              {{ field.feedback }}
            </p>
          </transition>
        </div>
        <div v-if="showAiContent === index">
          <transition name="fade-out-right">
            <div
              v-show="field.isVisible"
              class="ai-field"
            >
              <h6 class="ai-field-title">{{ $t('entries.ai-content-analyzer.seo.ai-suggestion') }}
              </h6>
              <p
                class="ai-field-suggestion"
                v-html="field.suggestion"
              />
              <div class="ai-field-suggestion-buttons">
                <button
                  class="replace-suggestion-button"
                  @click="setAiContent(field, true)"
                >
                  {{ $t('site.add-site.replace') }}
                </button>
                <button
                  class="suggestion-button"
                  @click="retrySeoSuggestions(field)"
                >
                  <b-spinner
                    v-if="loadingSuggestions[field.fieldAlias]"
                    class="field-spinner"
                    variant="primary"
                    small
                  />
                  {{ $t('fields.general.ai-popup.results.retry-label') }}
                </button>
                <button
                  class="suggestion-button"
                  @click="copyValue('suggestion', field.suggestion)"
                >
                  <GjIcon
                    name="Copy"
                    size="14"
                    class="mr-sm"
                  />
                  {{ $t('assets.metadata.file-metadata.copy-value.title') }}
                </button>
              </div>
            </div>
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { retrySeoSuggestion } from '@/codex-sdk/ai'
import { useClipboard } from '@vueuse/core'
import { TYPES } from '@/views/models/constants'

export default {
  name: 'AiContentSuggestions',
  props: {
    seoFeedback: {
      type: Array,
      default: () => [],
    },
    fields: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: () => false,
    },
    entryId: {
      type: String,
      default: () => '',
    },
    getPath: {
      type: Function,
      default: () => {},
    },
  },
  inject: ['toastNotification'],
  data() {
    return {
      showAiContent: null,
      loadingSuggestions: {},
    }
  },
  computed: {
    seoFeedback_() {
      const available = this.fields.filter(f => f.attrs.type !== TYPES.RICH_TEXT).map(f => f.attrs.alias)
      return this.seoFeedback.filter(f => available.includes(f.fieldAlias))
    },
  },
  methods: {
    setAiContent(field, replace) {
      const pathArray = this.getPath(field.fieldAlias)
      if (replace) {
        const item = this.seoFeedback.find(i => i.fieldAlias === field.fieldAlias)
        if (item) {
          item.isVisible = false
        }
      }
      this.$root.$emit('ai-generated-content', field.fieldAlias, field.suggestion, replace, pathArray)
    },
    async retrySeoSuggestions(field, index) {
      this.$set(this.loadingSuggestions, field.fieldAlias, true)

      try {
        const { data } = await retrySeoSuggestion({
          entryId: this.entryId,
          feedback: field.feedback,
          fieldAlias: field.fieldAlias,
        }, { successTitle: false })
        this.updateSuggestion(field.fieldAlias, data.response)
        const item = this.seoFeedback.find(i => i.fieldAlias === field.fieldAlias)

        if (item && !item.isVisible) {
          item.isVisible = true
          if (this.showAiContent === index) {
            this.showAiContent = null
          } else {
            this.showAiContent = index
          }
        }
      } catch (e) {
        console.error('Error retrying SEO suggestions:', e)
      }
      this.$set(this.loadingSuggestions, field.fieldAlias, false)
    },
    updateSuggestion(fieldAlias, newSuggestion) {
      const feedbackItem = this.seoFeedback.find(item => item.fieldAlias === fieldAlias)

      if (feedbackItem) {
        this.$set(feedbackItem, 'suggestion', newSuggestion)
      }
    },
    showSuggestedContent(index, field) {
      if (this.showAiContent === index) {
        this.showAiContent = null
      } else {
        this.showAiContent = index
      }
      this.setAiContent(field, false)
    },
    async copyValue(key = '', value = '') {
      const isHtml = /<\/?[a-z][\s\S]*>/i.test(value)

      try {
        if (isHtml) {
          const tempElement = document.createElement('div')
          tempElement.innerHTML = value
          document.body.appendChild(tempElement)

          const range = document.createRange()
          range.selectNodeContents(tempElement)
          const selection = window.getSelection()
          selection.removeAllRanges()
          selection.addRange(range)

          document.execCommand('copy')

          document.body.removeChild(tempElement)
        } else {
          const { copy } = useClipboard()
          copy(value)
        }

        this.toastNotification({
          title: this.$t('assets.metadata.file-metadata.copy-value.title'),
          icon: 'Copy',
          text: this.$t('entries.ai-content-analyzer.seo.ai-suggestion-copied', { key }),
          variant: 'success',
        })
      } catch (error) {
        console.error('Failed to copy:', error)
        this.toastNotification({
          title: this.$t('assets.metadata.file-metadata.copy-value.title'),
          icon: 'Error',
          text: this.$t('entries.ai-content-analyzer.seo.copy-failed', { key }),
          variant: 'danger',
        })
      }
    },

  },
}
</script>

<style lang="scss">
.ai-content-suggestions{
  color: #4B4B5F;
  padding-bottom: 18px;

  .tab-content{
display: flex;
flex-direction: column;
gap:8px;
  }
  .fade-out-right-leave-active {
    animation: fadeOutRight 0.5s forwards;
  }

  @keyframes fadeOutRight {
    0% {
      opacity: 1;
      transform: translateX(0);
    }
    100% {
      opacity: 0;
      transform: translateX(100%);
    }
  }

  .tab-title a {
    padding-left: 0;
    font-size: 14px !important;
    font-weight: 500 !important;
    color: #767685 !important;
  }

  .field-feedback{
    font-size:14px;
    margin-top:0.5rem;
  }

  .suggestion-field {
    border: solid 1px #ebebed;
    border-radius: 6px;

    &:hover {
      box-shadow: 0 6px 10px rgba(0, 0, 0, 0.2);

    }
  }

  .initial-field {
    padding: 10px 0.5rem;
    cursor: pointer;
  }

  .field-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h6 {
      margin-bottom: 0 !important;;
    }

  }

  .field-spinner {
    width: 14px;
    height: 14px;
    margin-right: 4px;

  }

  .field-title {
    width: fit-content;
    background-color: #e7eefa !important;
    padding-left: 4px;
    padding-right: 4px;
    border-radius: 4px;
    color: #052D61;
    font-size: 11px;
  }

  p {
    font-size: 12px;
    line-height: 21px;
    margin-bottom: 0px;
  }

  .suggestion-button {
    background-color: unset;
    padding: 4px 8px;
    gap: 4px;
    border-radius: 6px;
    border: 1px solid #DEDEE2;
    font-size: 12px;
    font-weight: 500;
    height: fit-content;
    outline: none !important;

  }

  .ai-field {
    border-top: 1px solid #DEDEE2;
    padding: 10px 0.5rem;
    display: flex;
    flex-direction: column;
    gap: 12px;

    .ai-field-title {
      font-size: 12px;
      font-weight: 600;
      margin: 0;
      line-height: 18px !important;

    }

    .ai-field-suggestion {
      font-size: 14px;
      max-height: 190px;
      overflow-y: scroll;
    }

    .ai-field-suggestion-buttons {
      display: flex;
      gap: 12px;
      outline: none !important;

    }

    .replace-suggestion-button {
      background-image: linear-gradient(to right, #206ED5, #9747FF);
      padding: 4px 8px;
      gap: 4px;
      border-radius: 6px;
      font-size: 12px;
      font-weight: 500;
      height: fit-content;
      color: white;
      border: none;
      outline: none !important;
    }
  }

  .ai-content-loader {
    .col {
      width: 150px;
      height: 150px;
      text-align: center;
      margin: auto;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .sparkles, .pointer {
      position: relative;
      margin: auto;
      height: 80px;
      width: 80px;
    }

    .sparkle-1 {
      animation: twinkle 2.4s ease infinite;
      animation-timing-function: cubic-bezier(.17, .67, .83, .67);
      position: absolute;
      top: 32px;
      left: 14px;
    }

    .sparkle-2 {
      position: absolute;
      top: 20px;
      right: 14px;
      animation: twinkle 2.6s ease infinite;
      animation-timing-function: cubic-bezier(.17, .67, .83, .67);
    }

    .dot-1 {
      position: absolute;
      bottom: 18px;
      right: 24px;
      animation: twinkle2 2.2s ease infinite;
      animation-timing-function: cubic-bezier(.17, .67, .83, .67);
    }
  }

  .ai-field-suggestion {
    h1, h2, h3, h4, h5, h6 {
      font-weight: bold;
      font-size: 1rem;
      line-height: 1.2
    }
  }
}

@keyframes twinkle {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  50% {
    opacity: 0.1;
    transform: scale(0.5);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes twinkle2 {
  0% {
    opacity: 1;
  }
  50% {
    opacity: .1;
    transform: scale(.8)
  }
  100% {
    opacity: 1;
  }
}
</style>
