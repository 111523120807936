<template>
  <div class="new-filters-dropdown__filter-body">
    <b-row>
      <b-col cols="12">
        <vSelect
          v-model="value.operator"
          :options="options"
          :clearable="false"
          :reduce="option => option.value"
          :searchable="false"
          label="label"
        />
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-input
          v-model.number="values.value1Hours"
          type="number"
          size="sm"
          :placeholder="$t('filters.duration-placeholders.hours')"
          @keydown="validateNumber($event, values.value1Hours)"
        />
      </b-col>
      <b-col>
        <b-input
          v-model.number="values.value1Minutes"
          type="number"
          size="sm"
          :min="0"
          :max="59"
          :placeholder="$t('filters.duration-placeholders.minutes')"
          @keydown="validateNumber($event, values.value1Minutes)"
          @keyup="validateNumberInput($event, 'value1Minutes')"
        />
      </b-col>
      <b-col>
        <b-input
          v-model.number="values.value1Seconds"
          type="number"
          size="sm"
          :min="0"
          :max="59"
          :placeholder="$t('filters.duration-placeholders.seconds')"
          @keydown="validateNumber($event, values.value1Seconds)"
          @keyup="validateNumberInput($event, 'value1Seconds')"
        />
      </b-col>
    </b-row>
    <b-row
      v-if="isBetween"
      align="center"
    >
      <b-col>
        {{ $t('filters.and') }}
      </b-col>
    </b-row>
    <b-row
      v-if="isBetween"
      align-v="center"
    >
      <b-col>
        <b-input
          v-model.number="values.value2Hours"
          type="number"
          size="sm"
          :placeholder="$t('filters.duration-placeholders.hours')"
          @keydown="validateNumber($event, values.value2Hours)"
          @keyup="validateNumberInput($event, 'value2Hours')"
        />
      </b-col>

      <b-col>
        <b-input
          v-model.number=" values.value2Minutes"
          type="number"
          size="sm"
          :min="0"
          :max="59"
          :placeholder="$t('filters.duration-placeholders.minutes')"
          @keydown="validateNumber($event, values.value2Minutes)"
          @keyup="validateNumberInput($event, 'value2Minutes')"
        />
      </b-col>
      <b-col>
        <b-input
          v-model.number="values.value2Seconds"
          type="number"
          size="sm"
          :min="0"
          :max="59"
          :placeholder="$t('filters.duration-placeholders.seconds')"
          @keydown="validateNumber($event, values.value2Seconds)"
          @keyup="validateNumberInput($event, 'value2Seconds')"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { secondsToHMS } from '@/utils/helpers'
import { validateNumber } from '@/views/models/constants'
import MinutesFilter from './MinutesFilter'

export default {
  props: {
    filter: {
      type: Object,
      required: true,
    },
    filterObject: {
      type: [Object, Function],
      required: true,
    },
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    const value1 = secondsToHMS(this.value.value1)
    const value2 = secondsToHMS(this.value.value2)
    return {
      validateNumber,
      values: {
        value1Hours: value1.hours || '',
        value1Minutes: value1.minutes || '',
        value1Seconds: value1.seconds || '',
        value2Hours: value2.hours || '',
        value2Minutes: value2.minutes || '',
        value2Seconds: value2.seconds || '',
      },
    }
  },
  computed: {
    isBetween() {
      return this.value.operator === MinutesFilter.OPERATORS.between
    },
    options() {
      return [
        {
          label: this.$t('filters.is'),
          value: MinutesFilter.OPERATORS.equals,
        },
        {
          label: this.$t('filters.greater-than'),
          value: MinutesFilter.OPERATORS.greaterThan,
        },
        {
          label: this.$t('filters.lower-than'),
          value: MinutesFilter.OPERATORS.lowerThan,
        },
        {
          label: this.$t('filters.between'),
          value: MinutesFilter.OPERATORS.between,
        },
      ]
    },
  },
  watch: {
    values: {
      handler(v) {
        {
          // block scope
          const hours = v.value1Hours || 0
          const minutes = v.value1Minutes || 0
          const seconds = v.value1Seconds || 0
          this.value.value1 = (hours * 60 * 60) + (minutes * 60) + seconds
        }

        {
          // block scope
          const hours = v.value2Hours || 0
          const minutes = v.value2Minutes || 0
          const seconds = v.value2Seconds || 0
          this.value.value2 = (hours * 60 * 60) + (minutes * 60) + +seconds
        }
      },
      deep: true,
    },
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    validateNumberInput(event, name) {
      if (this.values[name] < 0) {
        this.values[name] = 0
        event.target.value = 0
      }
      if (this.values[name] > 59) {
        this.values[name] = 59
        event.target.value = 59
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.form-control {
  width: 100% !important;
}
</style>
