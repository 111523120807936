<template>
  <div>
    <b-form-group
      :label="$t('fields.codex-field-json.general.name-label')"
      label-for="at-json-object-field__name-input"
    >
      <b-form-input
        id="at-json-object-field__name-input"
        v-model.trim="name"
        :placeholder="$t('fields.codex-field-json.general.name-placeholder')"
      />
    </b-form-group>
    <b-form-group
      :label="$t('fields.codex-field-json.general.alias-label')"
      label-for="at-json-object-field__alias-input"
      aria-required="true"
    >
      <b-form-input
        id="at-json-object-field__alias-input"
        :value="alias"
        disabled
      />
      <b-form-invalid-feedback>
        {{ $t('models.new-model.alias-error') }}
      </b-form-invalid-feedback>
    </b-form-group>
    <b-form-group
      :label="$t('fields.codex-field-json.general.description-label')"
      label-for="at-json-object-field__description-input"
    >
      <b-form-textarea
        id="at-json-object-field__description-input"
        v-model="description"
        :placeholder="$t('fields.codex-field-json.general.description-placeholder')"
        rows="2"
        no-resize
      />
    </b-form-group>
  </div>
</template>

<script>
import { generateComputedPropsFromAttrs } from '@/components/codex-layout-editor/BuilderUtils'
import BaseFieldMixin from '@/components/fields/BaseFieldMixin'

export default {
  name: 'General',
  mixins: [BaseFieldMixin],
  computed: {
    ...generateComputedPropsFromAttrs([
      'name',
      'alias',
      'description',
    ]),
  },
}
</script>

<style scoped>

</style>
