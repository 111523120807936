<template>
  <div class="image-block">
    <template v-if="attrs.src">
      <div :class="`google-podcast ${align}`">
        <div :class="size">
          <a
            :href="src"
            target="_blank"
          >
            <img
              :src="require('@/assets/images/svg/EN_Google_Podcasts_Badge.svg')"
              alt="EN_Google_Podcasts_Badge"
            >
          </a>
        </div>
      </div>
    </template>
  </div>
</template>

<script>

export default {
  props: ['attrs', 'updateAttrs', 'selectNode', 'selected'],
  inject: ['deleteBlock', 'showPromptPopup'],
  data() {
    return {
      initialSrc: '',
    }
  },
  computed: {
    alignClass() {
      return `${this.align}`
    },
    size() {
      return this.attrs.size
    },
    blockId() {
      return this.attrs.blockId
    },
    src() {
      return this.attrs.src
    },
    align: {
      get() {
        return this.attrs.align
      },
      set(e) {
        this.updateAttrs({ align: e })
      },
    },
  },
  watch: {
    src() {
      this.$nextTick(() => {
        this.selectNode()
      })
    },
  },
  methods: {
    initialize() {
      if (!this.attrs.src) {
        this.openModal()
      }
    },
    async openModal() {
      const src = await this.showPromptPopup({ title: this.$t('codex-editor.nodes.google-podcast.google-url') })
      if (!src) {
        this.deleteBlock(this.attrs.blockId)
      } else {
        this.updateAttrs({
          src,
        })
      }
    },
    setSrc() {
      this.updateAttrs({
        src: this.initialSrc,
      })
    },
  },
}
</script>

<style scoped lang="scss">
.google-podcast {
  display: flex;

  &.left {
    justify-content: flex-start;
  }

  &.right {
    justify-content: flex-end;
  }

  &.center {
    justify-content: center;
  }

  .small {
    margin: 9.6px 37.5px;
    img {
      width: 150px;
    }
  }

  .medium {
    margin: 16px 62.5px;
    img {
      width: 250px;
    }
  }

  .large {
    margin: 22.5px 87.5px;
    img {
      width: 350px;
    }
  }
}
</style>
