import ability from '@/libs/acl/ability'
import { pr } from './config'

/**
team_manage
team_view
 */
// For regEx search in VSCode: canViewTeam|canCreateTeam|canEditTeam|canDeleteTeam

// * Create entity file, and can functions
// * Import and add them to allCanFunctions object down below
// * Add defaults into the permissions array down below
// * Implement v-permission directives on neccessary buttons
// * Implement permissions on entities sdk functions
// * Implement permissions on navigation and route

export const Team = Object.freeze({
  VIEW: 'team_view',
  MANAGE: 'team_manage',
})

export const TeamTooltips = {
  [Team.VIEW]: '',
  [Team.MANAGE]: '',
}

/**
 * _options
 *    entity = undefined
 *    showToast = false
 */

/**
 * Can VIEW Teams
 * */
export function canViewTeams(_options = {}) {
  const { entity, ...options } = _options

  return pr(
    ability.can(Team.VIEW) || ability.can(Team.MANAGE),

    'You have no permission to view teams',

    options,
  )
}

/**
 * Can CREATE Team
 * */
export function canCreateTeam(_options = {}) {
  const { entity, ...options } = _options

  return pr(
    ability.can(Team.MANAGE),

    'You have no permission to create team',

    options,
  )
}

/**
 * Can EDIT Team
 * */
export function canEditTeam(_options = {}) {
  const { entity, ...options } = _options

  return pr(
    ability.can(Team.MANAGE),

    'You have no permission to edit team',

    options,
  )
}

/**
 * Can DELETE Team
 * */
export function canDeleteTeam(_options = {}) {
  const { entity, ...options } = _options

  return pr(
    ability.can(Team.MANAGE),

    'You have no permission to delete this team',

    options,
  )
}
