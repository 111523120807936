<template>
  <div class="codex-field-rich-content-general">
    <b-form-group
      :label="$t('fields.codex-field-rich-content.general.name-label')"
      label-for="at-location-field__name-input"
    >
      <b-form-input
        id="at-location-field__name-input"
        v-model.trim="name"
        :placeholder="$t('fields.codex-field-rich-content.general.name-placeholder')"
      />
    </b-form-group>
    <b-form-group
      :label="$t('fields.codex-field-rich-content.general.alias-label')"
      label-for="at-location-field__alias-input"
      aria-required="true"
    >
      <b-form-input
        id="at-location-field__alias-input"
        :value="alias"
        disabled
      />
      <b-form-invalid-feedback>
        {{ $t('models.new-model.alias-error') }}
      </b-form-invalid-feedback>
    </b-form-group>
    <b-form-group
      :label="$t('fields.codex-field-rich-content.general.description-label')"
      label-for="at-location-field__description-input"
    >
      <b-form-textarea
        id="at-location-field__description-input"
        v-model="description"
        :placeholder="$t('fields.codex-field-rich-content.general.description-placeholder')"
        rows="2"
        no-resize
      />
    </b-form-group>

    <b-form-group
      :label="$t('fields.general.metrics.calculate-metrics')"
      label-for="content-editor__calculate-metrics"
      class="mb-3"
    >
      <template #label>
        <span>
          {{ $t('fields.general.metrics.calculate-metrics') }}
        </span>
        <span
          id="content-editor__calculate-metrics-tooltip"
          class="codex-field-range-tooltip"
        >
          <GjIcon
            name="Info"
            size="20"
          />
        </span>
      </template>
      <b-form-checkbox
        id="content-editor__calculate-metrics"
        v-model="calculateMetrics"
        switch
        @input="($event) => { updateAttrs({calculateMetrics: $event}) }"
      />
    </b-form-group>

    <b-tooltip
      target="content-editor__calculate-metrics-tooltip"
      triggers="hover"
    >
      <p class="content-editor__calculate-metrics-intro">
        {{ $t('fields.general.metrics.calculate-metrics-tooltip') }}
      </p>

      <ul>
        <li>{{ $t('fields.general.metrics.character-count') }}</li>
        <li>{{ $t('fields.general.metrics.image-count') }}</li>
        <li>{{ $t('fields.general.metrics.sentences') }}</li>
        <li>{{ $t('fields.general.metrics.words') }}</li>
      </ul>

      <p class="content-editor__calculate-metrics-intro">
        {{ $t('fields.general.metrics.enable-reading-time') }}
      </p>

    </b-tooltip>

    <app-collapse class="narrow-collapse">
      <app-collapse-item :title="$t('fields.codex-field-rich-content.general.toolbar-label')">
        <template v-for="(option, index) in Object.keys(defaultToolbar)">
          <b-form-checkbox
            :key="index"
            v-model="validation.toolbarOptions[option].isEnabled"
            button
            button-variant="outline"
            class="rich-content__button"
          >
            <span>
              <GjIcon :name="getOptionIcon(option)" />
              {{ $t(`fields.codex-field-rich-content.general.toolbar-options.${option}`) }}
            </span>
            <b-dropdown
              v-if="Object.keys(validation.toolbarOptions[option]).length > 1"
              no-caret
              right
              variant="flat-primary"
              class="rich-content__dropdown"
              :disabled="!validation.toolbarOptions[option].isEnabled"
              @show="handleToolbarChange(option, $event)"
              @hide="handleToolbarChange(option)"
            >
              <template #button-content>
                <GjIcon name="Settings" />
              </template>
              <b-dropdown-item
                v-for="key in Object.keys(defaultToolbar[option]).filter(k => k !== 'isEnabled')"
                :key="key"
              >
                <span :class="{'gray-color': !validation.toolbarOptions[option][key]}">
                  <GjIcon :name="getOptionIcon(key)" />
                  {{ $t(`fields.codex-field-rich-content.general.toolbar-options.${key}`) }}
                </span>
                <b-form-checkbox
                  v-model="validation.toolbarOptions[option][key]"
                  switch
                  class="rich-content__switch-checkbox"
                  @click.native.capture.stop
                />
              </b-dropdown-item>
            </b-dropdown>
          </b-form-checkbox>
        </template>
      </app-collapse-item>
      <app-collapse-item
        v-for="(group, index) in blockGroups"
        :key="index"
        :title="$t(mapBlockGroups(group))"
      >
        <b-form-checkbox
          v-for="block in blocks.filter(b => b.group === group || b.group[0] === group)"
          :key="block.type"
          v-model="includeBlocks"
          v-b-tooltip.hover="block.type === CODEX_EDITOR_BLOCKS.PARAGRAPH ? $t('fields.codex-field-rich-content.general.paragraph-deselect-error') : null"
          :value="block.type"
          button
          button-variant="outline"
          class="rich-content__button"
        >
          <div>
            <img
              :src="block.image"
              width="40"
              height="40"
            >
            <span class="rich-content__button-text">
              {{ $t(block.name) }}
            </span>
          </div>
          <b-dropdown
            v-if="block.type === CODEX_EDITOR_BLOCKS.HEADING"
            no-caret
            right
            variant="flat-primary"
            class="rich-content__dropdown"
            :disabled="!includeBlocks.includes(CODEX_EDITOR_BLOCKS.HEADING)"
            @show="handleHeadingChange"
            @hide="handleHeadingChange"
          >
            <template #button-content>
              <GjIcon name="Settings" />
            </template>
            <b-dropdown-item
              v-for="i in 6"
              :key="i"
            >
              <span :class="{'gray-color': !includeBlocks.includes(CODEX_EDITOR_BLOCKS[`HEADING_${i}`])}">
                {{ $t(`${block.name}-${i}`) }}
              </span>
              <b-form-checkbox
                v-model="includeBlocks"
                :value="CODEX_EDITOR_BLOCKS[`HEADING_${i}`]"
                switch
                class="rich-content__switch-checkbox"
                @click.native.capture.stop
              />
            </b-dropdown-item>
          </b-dropdown>
          <b-dropdown
            v-if="block.type === CODEX_EDITOR_BLOCKS.MEDIA"
            no-caret
            right
            variant="flat-primary"
            class="rich-content__dropdown"
            :disabled="!includeBlocks.includes(CODEX_EDITOR_BLOCKS.MEDIA)"
            @show="handleMediaChange"
            @hide="handleMediaChange"
          >
            <template #button-content>
              <GjIcon name="Settings" />
            </template>
            <b-dropdown-item
              v-for="mediaType in mediaTypes"
              :key="mediaType"
            >
              <span :class="{'gray-color': !includeBlocks.includes(CODEX_EDITOR_BLOCKS[`MEDIA_${ASSET_TYPE_MAPPING.toString(mediaType)}`])}">
                {{ mapAssetTypeLabel(mediaType) }}
              </span>
              <b-form-checkbox
                v-model="includeBlocks"
                :value="CODEX_EDITOR_BLOCKS[`MEDIA_${ASSET_TYPE_MAPPING.toString(mediaType)}`]"
                switch
                class="rich-content__switch-checkbox"
                @click.native.capture.stop
              />
            </b-dropdown-item>
          </b-dropdown>
        </b-form-checkbox>

      </app-collapse-item>
      <app-collapse-item :title="$t(mapBlockGroups(BLOCK_GROUPS.CONTENT))">
        <b-form-checkbox-group
          v-model="includeModels"
          buttons
          button-variant="outline"
          style="display: block"
        >
          <b-form-checkbox
            v-for="model in models"
            :key="model.id"
            :value="model.alias"
            class="rich-content__button-group rich-content__button"
          >
            <GjIcon
              :name="model.iconId"
              size="30"
            />
            <span class="rich-content__button-text">
              {{ model.name }}
            </span>
          </b-form-checkbox>
        </b-form-checkbox-group>
      </app-collapse-item>
    </app-collapse>
  </div>
</template>

<script>
import { defaultToolbar } from '@/components/fields/RichContent/defaultValues'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'

import { generateComputedPropsFromAttrs } from '@/components/codex-layout-editor/BuilderUtils'
import CodexContentEditor from '@/components/codex-editor/CodexContentEditor'
import { BLOCK_GROUPS, mapBlockGroups } from '@/components/codex-editor/nodes/constants'
import { ASSET_TYPES, mapAssetTypeLabel, ASSET_TYPE_MAPPING } from '@/codex-sdk/assets'
import BaseFieldMixin from '@/components/fields/BaseFieldMixin'
import { CODEX_EDITOR_BLOCKS } from '@/components/codex-editor/CodeEditorConstants'

export default {
  name: 'General',
  components: {
    AppCollapse,
    AppCollapseItem,
  },
  mixins: [BaseFieldMixin],
  data() {
    return {
      CODEX_EDITOR_BLOCKS,
      mapAssetTypeLabel,
      ASSET_TYPE_MAPPING,
      mapBlockGroups,
      BLOCK_GROUPS,
      defaultToolbar,
      models: this.$store.state.general.allModels || [],
      mediaTypes: [ASSET_TYPES.IMAGE, ASSET_TYPES.FILE, ASSET_TYPES.VIDEO, ASSET_TYPES.VIDEO_PLAYLIST, ASSET_TYPES.AUDIO, ASSET_TYPES.PODCAST],
    }
  },
  computed: {
    ...generateComputedPropsFromAttrs([
      'name',
      'alias',
      'description',
      'calculateMetrics',
      'validation',
    ]),
    includeBlocks: {
      get() {
        return this.validation?.blocks?.values || []
      },
      set(v) {
        if (v?.length > 0 && v?.includes(CODEX_EDITOR_BLOCKS.PARAGRAPH)) {
          this.validation.blocks.values = v
        } else {
          this.validation.blocks.values = [...this.validation.blocks.values]
        }
      },
    },
    includeModels: {
      get() {
        return this.validation?.models?.values || []
      },
      set(v) {
        this.validation.models.values = v
      },
    },
    blocks() {
      return CodexContentEditor.getRegisteredWidgets().filter(i => !i._internal).map(({
        name, displayName, image, type, group,
      }) => ({
        name: displayName,
        type: type || name,
        image,
        group,
        attrs: {},
      }))
    },
    blockGroups() {
      return [...Object.values(BLOCK_GROUPS)].filter(block => block !== BLOCK_GROUPS.CONTENT && block !== BLOCK_GROUPS.MOST_USED)
    },
  },
  beforeDestroy() {
    this.handleHeadingChange()
    this.handleMediaChange()
  },
  methods: {
    handleHeadingChange(event) {
      const headingRegex = /^heading:h[1-6]$/
      const headingSelected = this.validation.blocks.values.some(block => headingRegex.test(block))
      const includesHeading = this.validation.blocks.values.includes(CODEX_EDITOR_BLOCKS.HEADING)

      if (!includesHeading) {
        this.validation.blocks.values = this.validation.blocks.values.filter(block => !headingRegex.test(block))
      } else if (includesHeading && !headingSelected) {
        this.validation.blocks.values = [...this.validation.blocks.values, CODEX_EDITOR_BLOCKS.HEADING_1, CODEX_EDITOR_BLOCKS.HEADING_2, CODEX_EDITOR_BLOCKS.HEADING_3, CODEX_EDITOR_BLOCKS.HEADING_4, CODEX_EDITOR_BLOCKS.HEADING_5, CODEX_EDITOR_BLOCKS.HEADING_6]
      }
      if (event && event.type !== 'show' && !headingSelected) {
        this.validation.blocks.values = this.validation.blocks.values.filter(block => block !== CODEX_EDITOR_BLOCKS.HEADING)
      }
    },
    handleMediaChange(event) {
      const mediaRegex = /^codex_media:(.*)$/
      const mediaTypesSelected = this.validation.blocks.values.some(block => mediaRegex.test(block))
      const includesMedia = this.validation.blocks.values.includes(CODEX_EDITOR_BLOCKS.MEDIA)

      if (!includesMedia) {
        this.validation.blocks.values = this.validation.blocks.values.filter(block => !mediaRegex.test(block))
      } else if (includesMedia && !mediaTypesSelected) {
        this.validation.blocks.values = [...this.validation.blocks.values, CODEX_EDITOR_BLOCKS.MEDIA_IMAGE, CODEX_EDITOR_BLOCKS.MEDIA_FILE, CODEX_EDITOR_BLOCKS.MEDIA_VIDEO, CODEX_EDITOR_BLOCKS.MEDIA_VIDEO_PLAYLIST, CODEX_EDITOR_BLOCKS.MEDIA_AUDIO, CODEX_EDITOR_BLOCKS.MEDIA_PODCAST]
      }
      if (event && event.type !== 'show' && !mediaTypesSelected) {
        this.validation.blocks.values = this.validation.blocks.values.filter(block => block !== CODEX_EDITOR_BLOCKS.MEDIA)
      }
    },
    handleToolbarChange(option, event) {
      if (option === 'links') return
      const children = Object.keys(this.validation.toolbarOptions[option]).filter(k => k !== 'isEnabled')
      if (children.length < 2) return
      const includesChildren = children.every(key => !this.validation.toolbarOptions[option][key])
      const includesOption = this.validation.toolbarOptions[option].isEnabled
      if (!includesOption) {
        children.forEach(key => {
          this.validation.toolbarOptions[option][key] = false
        })
      } else if (includesOption && includesChildren) {
        console.log(children)
        children.forEach(key => {
          this.validation.toolbarOptions[option][key] = true
        })
      }
      if (event?.type !== 'show' && includesChildren) {
        this.validation.toolbarOptions[option].isEnabled = false
      }
    },
    getOptionIcon(key) {
      switch (key) {
        case 'fontStyle':
        case 'bold':
          return 'FormatBold'
        case 'dropcap':
          return 'IconparkTextStyleOne'
        case 'premium':
          return 'IconparkDiamonds'
        case 'textAlignment':
        case 'alignLeft':
          return 'AlignLeftAlt'
        case 'links':
        case 'codex':
          return 'Hyperlink'
        case 'scripts':
          return 'Script'
        case 'comments':
          return 'ChatLeftBubbleText'
        case 'italic':
          return 'FormatItalic'
        case 'underline':
          return 'FormatUnderline'
        case 'strikethrough':
          return 'FormatStrikethrough'
        case 'colorStyle':
          return 'BrushPaintColorAlt'
        case 'alignCenter':
          return 'AlignCenterAlt'
        case 'alignRight':
          return 'AlignRightAlt'
        case 'external':
          return 'ArrowExportPopout'
        case 'phone':
          return 'IconparkIphone'
        case 'email':
          return 'IconparkMail'
        default:
          return ''
      }
    },
  },
}
</script>

<style lang="scss">
.codex-field-rich-content-general {
  .btn-group [class*=btn-]:not([class*=btn-outline-]):not([class*=btn-flat-]):not([class*=btn-gradient-]):not([class*=btn-relief-]).rich-content__button-group {
    color: #7483A1;
    border: 1px solid #E0E5EB !important;
    padding-left: 8px;
    word-break: break-all;
    &.active {
      background-color: #fff;
      color: #052D61;
      border: 1px solid #1D79F2 !important;
    }
    &:hover {
      background: #f5f5f5;
    }
  }
  .rich-content__button-group {
    width: 100%;
    margin: 5px 0 !important;
    border-radius: 4px !important;
    justify-content: flex-start;
  }

  .rich-content__button {
    width: 100%;
    margin: 5px 0 !important;

    label {
      width: 100%;
      justify-content: space-between;
      background-color: #FFFFFF;
      border: 1px solid #E0E5EB;
      color: #667C99;

      &.active {
        background-color: #fff;
        color: #052D61;
        border: 1px solid #1D79F2 !important;
      }

      &:hover {
        background: #f5f5f5;
      }
    }
  }
  .rich-content__dropdown {

    .btn-flat-primary.dropdown-toggle {
      color: inherit;

      &:hover:not(.disabled):not(:disabled), &:active, &:focus {
        background-color: transparent;
      }
    }

    ul {
      width: 89%;
      left:17px !important;
      a {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        color: #052D61;

        .gray-color {
          color: #667C99;
        }

        &:hover {
          background-color: transparent;
        }
      }
    }
  }
  .rich-content__switch-checkbox {
    align-items: flex-start;

    label {
      border: none;
      &::before &::after{
        width: 40px;
        height: 24px;
      }
    }
  }
  .rich-content__button-text {
    padding-left: 10px;
  }
}
</style>
