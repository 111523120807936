import CodexContentEditor from '../../CodexContentEditor'
import Models from './Models.vue'
import { BLOCK_GROUPS } from '../constants'

CodexContentEditor.registerWidget('codex', {
  displayName: 'codex-editor.block-names.reference',
  name: 'reference',
  group: [BLOCK_GROUPS.OTHERS, BLOCK_GROUPS.MOST_USED],
  keywords: ['reference', 'entry', 'article'],
  // eslint-disable-next-line global-require
  image: require('../../icons/models/icon.svg'),
  attrs: {
    blockId: {
      default: null,
    },
    modelAlias: {
      default: null,
    },
    references: {
      default: [],
    },
  },
  renderEditor: Models,
})
