<template>
  <vue-context ref="menu">
    <li v-permission="['canCreateFolder', targetFolder]">
      <b-link
        id="at-context-menu-new-folder-button"
        class="d-flex align-items-center"
        @click="createFolder"
      >
        <GjIcon
          name="Plus"
          size="16"
        />
        <span class="ml-75">
          {{ $t('assets.context-menu.new-folder') }}
        </span>
      </b-link>
    </li>
    <li
      v-if="targetFolder"
      v-permission="['canEditFolder', targetFolder]"
    >
      <b-link
        id="at-context-menu-edit-folder-button"
        class="d-flex align-items-center"
        @click="editFolder"
      >
        <GjIcon
          name="Edit"
          size="16"
        />
        <span class="ml-75">
          {{ $t('assets.context-menu.edit') }}
        </span>
      </b-link>
    </li>
    <li
      v-if="targetFolder"
      v-permission="['canDeleteFolder', targetFolder]"
    >
      <b-link
        id="at-context-menu-delete-folder-button"
        class="d-flex align-items-center"
        @click="showDeletionModal"
      >
        <GjIcon
          name="Delete"
          size="16"
        />
        <span class="ml-75">
          {{ $t('assets.context-menu.delete') }}
        </span>
      </b-link>
    </li>
  </vue-context>
</template>

<script>
import VueContext from 'vue-context'
import { mapState } from 'vuex'

export default {
  name: 'ContextMenu',
  inject: ['showFolderPopup', 'showDeleteFolderPopup'],
  components: {
    VueContext,
  },
  computed: {
    ...mapState('assets', [
      'targetFolder',
    ]),
  },
  methods: {
    async showDeletionModal() {
      const deletedFolder = await this.showDeleteFolderPopup({ folder: this.targetFolder })
      if (deletedFolder) {
        this.$root.$emit('refetch-folders')
        this.$emit('openFolder', { id: deletedFolder.parentId })
      }
    },
    async createFolder() {
      const folder = await this.showFolderPopup({ parent: this.targetFolder })
      if (folder) {
        this.$root.$emit('refetch-folders')
      }
    },
    async editFolder() {
      const folder = await this.showFolderPopup({ folder: this.targetFolder })
      Object.assign(this.targetFolder, folder)
    },
  },
}
</script>
