<template>
  <b-form-group
    aria-required="true"
    class="json-object-field"
    :disabled="readOnly || !editable"
  >
    <template #label>
      <FieldName
        :name="name"
        :help-text="helpText"
        :help-text-display="helpTextDisplay"
        :required="required"
      >
        <template #top-right>
          <b-button
            size="sm"
            variant="light"
            class="copy-json"
            @click="copyJSON()"
          >
            <GjIcon
              name="Copy"
              size="16"
            />
            {{ $t('entries.json-editor.copy.title') }}
          </b-button>
        </template>
      </FieldName>
    </template>
    <div class="json-object-field__header">
      {{ $t('fields.codex-field-json.preview.json-editor') }}
    </div>
    <textarea ref="codeTextArea" />
    <FieldError :error="error" />
  </b-form-group>
</template>

<script>
import FieldName from '@/components/fields/FieldName.vue'
import { FIELD_FILTER_OPERATORS, RANGE_OPERATORS } from '@/views/models/constants'
import CodeMirror from 'codemirror'
import 'codemirror/mode/javascript/javascript'
import 'codemirror/lib/codemirror.css'
import 'codemirror/theme/material.css'

import { generateComputedPropsFromAttrs } from '@/components/codex-layout-editor/BuilderUtils'
import { debounce } from 'lodash'
import BaseFieldMixin from '@/components/fields/BaseFieldMixin'
import FieldRenderMixin from '@/components/fields/RenderFieldMixin'
import FieldError from '@/components/fields/FieldError.vue'
import { useClipboard } from '@vueuse/core'

export default {
  name: 'JsonObject',
  components: {
    FieldError,
    FieldName,
  },
  mixins: [BaseFieldMixin, FieldRenderMixin],
  inject: ['toastNotification'],
  props: {
    value: {
      type: [Object, String, Array],
      default: null,
    },
  },
  data() {
    return {
      editor: null,
      setValue_: debounce(this.setValue, 500),
    }
  },
  computed: {
    ...generateComputedPropsFromAttrs([
      'name',
      'alias',
      'description',
      'validation',
      'helpText',
      'helpTextDisplay',
    ]),
    code: {
      get() {
        return this.value || ''
      },
      set(v) {
        try {
          if (!v?.length) {
            this.setValue_(null)
          } else {
            this.setValue_(JSON.parse(v))
          }
        } catch {
          //
        }
      },
    },
    editableCode: {
      get() {
        return this.value ? JSON.stringify(this.value, null, '  ') : ''
      },
      set(v) {
        this.code = v
      },
    },
  },
  beforeMount() {
    if (!this.widget.attrs.hidden) {
      this.$set(this.widget.attrs, 'hidden', {
        value: false,
        conditionsEnabled: false,
        conditions: [
          {
            isSystem: false,
            field: '',
            operator: FIELD_FILTER_OPERATORS.EXISTS,
            value: '',
          },
        ],
      })
    }
  },
  mounted() {
    this.editor = CodeMirror.fromTextArea(this.$refs.codeTextArea, {
      lineNumbers: true,
      mode: 'javascript',
      theme: 'material',
    })
    this.editor.setValue(this.value ? JSON.stringify(this.value, null, '  ') : '')
    this.editor.on('change', instance => {
      this.editableCode = instance.getValue()
    })

    this.editor.refresh()
  },
  methods: {
    setValue(value) {
      this.$emit('input', value)
    },
    validate(value) {
      const length = this.value && typeof value == 'object' ? JSON.stringify(value)?.length : value?.length || 0
      if (this.required && length < 1) {
        return { isValid: false, message: this.validation.required.errorMessage }
      }
      try {
        if (this.validation.numberOfProperties.isEnabled && length > 0) {
          let jsonKeys = []
          if (typeof value === 'string') {
            jsonKeys = Object.keys(JSON.parse(value))
          } else {
            jsonKeys = Object.keys(value)
          }
          if (this.validation.numberOfProperties.rangeOperator === RANGE_OPERATORS.BETWEEN) {
            if (jsonKeys.length < this.validation.numberOfProperties.min || jsonKeys.length > this.validation.numberOfProperties.max) {
              return { isValid: false, message: this.validation.numberOfProperties.errorMessage }
            }
          }
          if (this.validation.numberOfProperties.rangeOperator === RANGE_OPERATORS.LTE) {
            if (jsonKeys.length > this.validation.numberOfProperties.max) {
              return { isValid: false, message: this.validation.numberOfProperties.errorMessage }
            }
          }
          if (this.validation.numberOfProperties.rangeOperator === RANGE_OPERATORS.GTE) {
            if (jsonKeys.length < this.validation.numberOfProperties.min) {
              return { isValid: false, message: this.validation.numberOfProperties.errorMessage }
            }
          }
        }
      } catch (e) {
        return { isValid: false, message: this.validation.invalidJSON }
      }
      return { isValid: true, message: '' }
    },
    copyJSON() {
      const { copy } = useClipboard()
      copy(JSON.stringify(this.value))

      this.toastNotification({
        icon: 'Copy',
        variant: 'success',
        title: this.$t('entries.json-editor.copy.title'),
        text: this.$t('entries.json-editor.copy.text'),
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.json-object-field {
  .json-object-field__header {
    padding: 12px;
    width: 100%;
    height: 48px;
    background: #F6F7F9;
    border: 1px solid #E0E5EB;
    border-radius: 4px 4px 0px 0px;
    color: #667C99;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
  }
  .json-object-field__editor {
    width: 100%;
    background-color: #fff;
    border-top: 1px solid #e0e5eb;
    border-radius: 4px;
    color: #ccc;
    font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
    font-size: 14px;
    line-height: 1.5rem;
    padding: 5px;
    height: 200px;
    white-space: pre;
  }
}

</style>
