import i18n from '@/libs/i18n'
import { checkPermission, tc } from '@/codex-sdk/config'
import { apiAxios as axios } from '@/libs/axios'
import * as defaultAxios from 'axios'
import { setWith } from 'lodash'

export function mapLanguage(key) {
  return i18n.t(`localizations.languages.${key}`)
}

export function transformLanguageResponse(language) {
  if (!language.isCustom) {
    language.defaultValue = language.value
  }
  return language
}

export function transformLanguagesResponses(languages) {
  languages.forEach(transformLanguageResponse)
}

export function readLocalizations(language) {
  return axios.get('/{organization}/localizations', {
    params: {
      language,
    },
  }).then(response => {
    transformLanguagesResponses(response.data)
    return response
  })
}

export function readLocalization(language, key) {
  return axios.get(`/{organization}/localizations/${key}`, {
    params: {
      language,
    },
  })
}

export function createLocalization(localization, messages = {}) {
  return checkPermission('canManageLocalizations')
   && tc(axios.post('/{organization}/localizations', localization), {
     successTitle: 'sdk.success-title',
     successMessage: 'sdk.localizations.create-successful',
     failTitle: 'sdk.localizations.create-failed',
     ...messages,
   })
}

export function updateLocalization(localization, messages = {}) {
  return checkPermission('canManageLocalizations')
    && tc(axios.put(`/{organization}/localizations/${localization.key}`, localization), {
      successTitle: 'sdk.success-title',
      successMessage: 'sdk.localizations.update-successful',
      failTitle: 'sdk.localizations.update-failed',
      ...messages,
    })
}

export function deleteLocalization(localization, messages) {
  return checkPermission('canManageLocalizations')
   && tc(axios.delete(`/{organization}/localizations/${localization.key}`, {
     params: {
       language: localization.language,
     },
   }), {
     successTitle: 'sdk.success-title',
     successMessage: 'sdk.localizations.delete-successful',
     failTitle: 'sdk.localizations.delete-failed',
     ...messages,
   })
}

/*
 * Deprecated method: return same response as readLocalizationCDN
 * Use readLocalizationCDN instead
 */
export function readLocalizationAsJSON(language) {
  return axios.get('/{organization}/localizations', {
    params: {
      language,
    },
  }).then(response => {
    const obj = {}
    response.data.forEach(localization => {
      setWith(obj, localization.key, localization.value, Object)
    })
    response.data = obj
    return response
  })
}

export function readLocalizationCDN(organizationAlias, language) {
  return defaultAxios.get(`https://admin.codexcdn.net/v2/${organizationAlias}/i18n/${language}`)
    .then(response => {
      const obj = {}
      response.data.forEach(localization => {
        setWith(obj, localization.key, localization.value, Object)
      })
      response.data = obj
      return response
    })
    .catch(() => {})
}
