<template>
  <b-modal
    id="at-confirm-popup"
    v-model="showPopup"
    :title="getTitle"
    :ok-title="getOkTitle"
    :static="true"
    :ok-variant="data.okVariant ? data.okVariant : 'primary'"
    :cancel-title="getCancelTitle"
    :cancel-variant="data.cancelVariant ? data.cancelVariant : 'outline-dark'"
    centered
    :content-class="contentClasses"
    class="text-center confirm-popup"
    :modal-class="data.class"
    :ok-only="data.okOnly"
    :size="size"
    @ok="proceedConfirm"
    @cancel="cancelConfirm"
  >
    <span class="confirm-popup__description">
      {{ data.description || $t('general.confirmLeaving') }}
    </span>
    <ul
      v-if="count > 1"
      class="popup-list"
    >
      <li
        v-for="(section, index) in data.items"
        :key="index"
      >
        {{ section }}
      </li>
    </ul>
    <div v-if="data.helperText">
      {{ data.helperText }}
    </div>
    <b-alert
      variant="warning"
      :show="!!data.alertVariant"
    >
      <span class="alert-icon">
        <GjIcon :name="data.alertIcon" />
      </span>
      <div class="alert-content">
        <span class="alert-title">
          {{ data.alertTitle }}
        </span>
        <span class="alert-description">
          {{ data.alertDescription }}
        </span>
      </div>
    </b-alert>
  </b-modal>
</template>

<script>
import PopupsMixin from '../PopupsMixin'

export default {
  name: 'ConfirmPopup',
  mixins: [PopupsMixin],
  computed: {
    count() {
      return this.data?.items?.length || 0
    },
    size() {
      if (this.data.size) {
        return this.data.size
      }
      return 'md'
    },
    getTitle() {
      if (this.data.title) {
        return this.data.title
      }
      return this.$t('general.unsaved-changes')
    },
    getOkTitle() {
      if (this.data.okTitle) {
        return this.data.okTitle
      }
      return this.$t('general.confirm')
    },
    getCancelTitle() {
      if (this.data.cancelTitle) {
        return this.data.cancelTitle
      }
      return this.$t('general.cancel')
    },
    contentClasses() {
      return {
        'confirmPopup-content': true,
        'modal-body--left': this.data.leftAlign,
      }
    },
  },
  methods: {
    async proceedConfirm() {
      this.canClose = false

      try {
        this.closePopup(true)
      } catch (error) {
        // Empty
      }
    },
    cancelConfirm() {
      this.closePopup(false)
    },
  },
}
</script>

<style lang="scss" >
#at-confirm-popup {
  .modal-body {
    color: #052D61;
    font-size: 14px;
    line-height: 18px;
    text-align: center;

    .alert {
      margin-top: 16px;
    }
  }
  .modal-body--left {
    .modal-body {
      text-align: left;
    }
  }
}
.confirm-popup__description {
  color: #052D61;
  word-wrap: break-word;
}
</style>
