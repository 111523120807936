<template>
  <div class="new-filters-dropdown__filter-body">

    <slot name="operator">
      <b-row>
        <b-col cols="12">
          <vSelect v-model="value.operator" :options="options" :clearable="false" :reduce="option => option.value"
            :searchable="false" label="label" />
        </b-col>
      </b-row>
    </slot>

    <b-form-group
      v-if="appearance === TEXT_FIELD_APPEARANCES.DROPDOWN || appearance === TEXT_FIELD_APPEARANCES.RADIO || appearance === TEXT_FIELD_APPEARANCES.CHECKBOX"
      class="string-filter__dropdown">
      <v-select v-model="value.value" :clearable="false"
        :options="filter.field.attrs.validation.selectFrom.predefinedValues" class="text-wrap__inputs" />
    </b-form-group> <!-- DROPDOWN / RADIO / CHECKBOX -->

    <b-row v-else-if="!isExistsOperator" align-v="center" no-gutters>
      <b-col class="flex-grow">
        <b-input v-model="value.value" size="sm" />
      </b-col>
    </b-row>

  </div>
</template>

<script>
import { TEXT_FIELD_APPEARANCES } from '@/views/models/constants'
import StringFilter from './StringFilter'

export default {
  props: {
    filter: {
      type: Object,
      required: true,
    },
    filterObject: {
      type: [Object, Function],
      required: true,
    },
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isPredefined: false,
      // options: [],
      TEXT_FIELD_APPEARANCES,
    }
  },
  computed: {
    options() {
      if (this.isPredefined) {
        return [
          {
            label: this.$t('filters.is'),
            value: StringFilter.OPERATORS.equals,
          },
          {
            label: this.$t('filters.is-not'),
            value: StringFilter.OPERATORS.notEquals,
          },
          {
            label: this.$t('filters.is-not-empty'),
            value: StringFilter.OPERATORS.exists,
          },
          {
            label: this.$t('filters.is-empty'),
            value: StringFilter.OPERATORS.notExists,
          },
        ]
      }
      return [
        {
          label: this.$t('filters.contains'),
          value: StringFilter.OPERATORS.contains,
        },
        {
          label: this.$t('filters.not-contains'),
          value: StringFilter.OPERATORS.notContains,
        },
        {
          label: this.$t('filters.is'),
          value: StringFilter.OPERATORS.equals,
        },
        {
          label: this.$t('filters.is-not'),
          value: StringFilter.OPERATORS.notEquals,
        },
        {
          label: this.$t('filters.is-not-empty'),
          value: StringFilter.OPERATORS.exists,
        },
        {
          label: this.$t('filters.is-empty'),
          value: StringFilter.OPERATORS.notExists,
        }]
    },
    isExistsOperator() {
      return this.value.operator === StringFilter.OPERATORS.exists || this.value.operator === StringFilter.OPERATORS.notExists
    },
    appearance() {
      return this.filter?.field?.attrs?.appearance
    },
  },
  beforeUpdate() {
    this.setPredefined()
  },
  mounted() {
    this.setPredefined()
  },
  methods: {
    setPredefined() {
      this.isPredefined = this.appearance == TEXT_FIELD_APPEARANCES.DROPDOWN
        || this.appearance == TEXT_FIELD_APPEARANCES.RADIO
        || this.appearance == TEXT_FIELD_APPEARANCES.CHECKBOX
      const isOption = this.options.some(o => o.value === this.value.operator)
      if (!isOption) {
        this.$set(this.value, 'operator', this.options[0].value)
      }
    },
  },
}
</script>

<style lang="scss">
.new-filters-dropdown__filter .string-filter__dropdown {

  .v-select ul li,
  .vs__selected {
    text-transform: none;
  }
}
</style>
