import Vue from 'vue'
import moment from 'moment'
import { numberWithThousandsCommas } from '@/utils/helpers'
import { isNumber } from 'lodash'
import i18n from '@/libs/i18n'
import store from './store'

Vue.filter('truncate', (value, length) => {
  const reqdString = value.split('').slice(0, length).join('')
  return reqdString !== value ? `${reqdString}...` : value
})

Vue.filter('formatDate', (value, shortDate = false) => {
  if (value) {
    if (shortDate == 'settings') {
      const dateFormat = store.state.general.site_settings.dateFormat
      return moment(String(value)).format(dateFormat)
    }
    if (shortDate) {
      return moment(String(value)).format('DD/MM/YYYY')
    }
    return moment(String(value)).format('DD MMMM YYYY')
  }
  return value
})

Vue.filter('formatDateTime', (value, monthWithWords = false) => {
  if (value) {
    if (monthWithWords == 'settings') {
      const dateFormat = store.state.general.site_settings.dateFormat
      const timeFormat = store.state.general.site_settings.timeFormat
      return moment(String(value)).format(`${dateFormat} ${timeFormat}`)
    }
    if (monthWithWords) {
      return moment(String(value)).format('DD MMMM YYYY HH:mm')
    }
    return moment(String(value)).format('DD/MM/YYYY HH:mm')
  }
  return value
})

Vue.filter('capitalize', value => {
  if (!value) return ''
  value = value.toString()
  return value.charAt(0).toUpperCase() + value.slice(1)
})

Vue.filter('articleStatus', value => {
  if (value) {
    switch (value) {
      case 1:
        return 'Published'
      case 2:
        return 'Draft'
      case 3:
        return 'Unpublished'
      case 4:
        return 'Archived'
      case 5:
        return 'Deleted'
      default:
        return 'Article'
    }
  }
  return value
})

Vue.filter('formatNumber', value => {
  if (!isNumber(value) || value < 0) return ''
  if (value >= 1000000000000) {
    return i18n.t('global-components.format-number.trillion', { value: (value / 1000000000000).toFixed(1).replace(/\.0$/, '') })
  }
  if (value >= 1000000000) {
    return i18n.t('global-components.format-number.billion', { value: (value / 1000000000).toFixed(1).replace(/\.0$/, '') })
  }
  if (value >= 1000000) {
    return i18n.t('global-components.format-number.million', { value: (value / 1000000).toFixed(1).replace(/\.0$/, '') })
  }
  if (value >= 1000) {
    return i18n.t('global-components.format-number.thousand', { value: (value / 1000).toFixed(1).replace(/\.0$/, '') })
  }
  return value
})

Vue.filter('numberWithThousandsCommas', numberWithThousandsCommas)
