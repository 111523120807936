/**
 * Defaults
 */
export const authConfig = {
  userLoginHook(userData) {
    return userData
  },
  userLogoutHook() {
  },
  oidcErrorHook() {
  },
  userRedirectHook(redirectPath) {
    return redirectPath
  },
}

/**
 * Functions to set the defaults
 */
export function setUserLoginHook(fn) {
  authConfig.userLoginHook = fn
}
export function setUserLogoutHook(fn) {
  authConfig.userLogoutHook = fn
}
export function setUserRedirectHook(fn) {
  authConfig.userRedirectHook = fn
}
export function setOidcErrorHook(fn) {
  authConfig.oidcErrorHook = fn
}
