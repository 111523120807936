<template>
  <div class="image-editor__action-buttons">
    <div
      v-if="additionalButton"
      class="image-editor__action-buttons-button"
    >
      <b-button
        class="w-100"
        variant="primary"
        @click="$emit('additionalButtonEvent')"
      >
        {{ additionalButton }}
      </b-button>
    </div>
    <div class="image-editor__action-buttons-button">
      <b-button
        variant="primary"
        class="w-100"
        :disabled="saving"
        @click="handleSave"
      >
        <b-spinner
          v-if="saving"
          class="image-editor__action-buttons-spinner"
        />
        {{ $t('assets.edit-image.action-buttons.save-as-new') }}
      </b-button>
    </div>
    <div class="image-editor__action-buttons-button">
      <b-button
        variant="outline-secondary"
        class="w-100"
        @click="cancel"
      >
        {{ $t('assets.edit-image.action-buttons.cancel') }}
      </b-button>
    </div>
  </div>
</template>

<script>
import { uploadAsset } from '@/codex-sdk/assets'
import { MAX_FILE_SIZE } from '@/utils/constants'

export default {
  name: 'ActionButtons',
  inject: ['showConfirmPopup'],
  props: {
    imageEditor: Object,
    additionalButton: String,
    imageId: String,
    asset: Object,
  },
  data() {
    return {
      saving: false,
    }
  },
  methods: {
    async handleSave() {
      this.saving = true
      if (this.asset) {
        const type = this.asset.contentType.replace('image/png', '')
        const file = await fetch(this.imageEditor.toDataURL(type))
          .then(response => response.blob())
          .then(blob => new File([blob], this.asset.fileName, { type: 'image/png' }))
        if (file.size > MAX_FILE_SIZE) {
          await this.showConfirmPopup({
            title: this.$t('assets.edit-image.action-buttons.filesize-alert.title'),
            description: this.$t('assets.edit-image.action-buttons.filesize-alert.description', { maxSize: `${MAX_FILE_SIZE / 1024 ** 2}MB` }),
            okTitle: this.$t('assets.edit-image.action-buttons.filesize-alert.okTitle'),
            okOnly: true,
          })
        } else {
          try {
            const { data } = await uploadAsset({
              file,
              ...this.asset,
            })
            const org = this.$store.state.general.currentOrganization
            if (org?.storage?.id === data.storageId) {
              data.url = `https://${org.storage.cdnDomain}/${data.path}`
            }
            this.$emit('save', data)
          } catch (e) {
            console.log(e)
          }
        }
      }
      this.saving = false
    },
    cancel() {
      this.$emit('cancel')
    },
  },
}
</script>
