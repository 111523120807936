<template>
  <b-modal
    id="newApiKey"
    v-model="showPopup"
    :static="true"
    :title="$t('apiKeys.newKeyModal.title')"
    :cancel-title="$t('apiKeys.newKeyModal.cancel')"
    :ok-title="$t('apiKeys.newKeyModal.add')"
    ok-variant="primary"
    cancel-variant="outline-dark"
    centered
    size="md"
    :no-close-on-backdrop="true"
    @ok.prevent="save"
    @cancel="closePopup(false)"
    @hidden="closePopup(false)"
  >
    <b-form-group :label="$t('apiKeys.newKeyModal.nameLabel')">
      <b-form-input
        v-model="apiKeyData.name"
        type="text"
        :placeholder="$t('apiKeys.newKeyModal.namePlaceholder')"
        @input="errors.name = false"
      />
      <small
        v-if="errors.name"
        class="text-danger"
      >
        {{ $t('apiKeys.newKeyModal.nameError') }}
      </small>
    </b-form-group>

    <b-form-group :label="$t('users.new-user.team')">
      <TeamsSelector
        v-model="apiKeyData.teams"
        :multiple="true"
        :class="errors.teams ? 'danger-select' : ''"
        @input="errors.teams = false"
      />
      <small
        v-if="errors.teams"
        class="text-danger"
      >
        {{ $t('apiKeys.newKeyModal.teamsError') }}
      </small>
    </b-form-group>

    <b-form-group class="api-key__has-expire-date">
      <b-checkbox v-model="hasExpireDate">
        {{ $t('apiKeys.newKeyModal.hasExpired') }}
      </b-checkbox>
    </b-form-group>

    <b-form-group
      v-if="hasExpireDate"
      :label="$t('apiKeys.newKeyModal.expireLabel')"
      class="api-key__expire-date"
    >
      <flat-pickr
        id="at-site-date-input"
        v-model="apiKeyData.expiresAt"
        class="form-control dashboard-overview__calendar apikey-calendar"
        :placeholder="$t('apiKeys.newKeyModal.expirePlaceholder')"
        :config="dateConfig"
      />
      <span class="api-key__calendar-icon">
        <GjIcon name="Calendar" />
      </span>
    </b-form-group>
  </b-modal>
</template>

<script>
import PopupsMixin from '@/components/popups/PopupsMixin'
import { apikeyDefaults, createApiKey } from '@/codex-sdk/api-keys'
import moment from 'moment'
import TeamsSelector from '@/components/TeamsSelector.vue'

export default {
  name: 'CreateApiKeyPopup',
  components: { TeamsSelector },
  mixins: [PopupsMixin],
  data() {
    return {
      apiKeyData: apikeyDefaults(),
      dateConfig: {
        altFormat: 'd/m/Y',
        dateFormat: 'Y/m/d',
        minDate: 'today',
      },
      hasExpireDate: false,
      errors: {
        name: false,
        teams: false,
      },
    }
  },
  methods: {
    clearErrors() {
      this.errors = { name: false }
    },
    async save() {
      try {
        this.errors.name = !(this.apiKeyData.name?.length > 0)
        this.errors.teams = !(this.apiKeyData.teams?.length > 0)

        if (this.errors.name || this.errors.teams) return

        const saveObj = {
          name: this.apiKeyData.name,
          expiresAt: '',
          teams: this.apiKeyData.teams,
        }
        if (this.hasExpireDate) {
          saveObj.expiresAt = moment(this.apiKeyData.expiresAt).toISOString()
        }

        const { data } = await createApiKey(saveObj)
        this.closePopup(data)
      } catch (e) {
        console.log(e)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.api-key__has-expire-date {
  margin-top: 30px;
}
.api-key__expire-date {
  position: relative;
}
.api-key__calendar-icon {
  position: absolute;
  top: 8px;
  right: 8px;
}
</style>
