export default {
  namespaced: true,
  state: {
    filtersCache: {},
  },
  getters: {
    getFilterCache: state => filterId => state.filtersCache[filterId],
    getFiltersCache: state => filterIds => filterIds.map(id => state.filtersCache[id]),
  },
  mutations: {
    SET_FILTER_CACHE(state, { filterId, filter }) {
      state.filtersCache[filterId] = filter
    },
  },
  actions: {
    setFilterCache({ commit }, { filterId, filter }) {
      commit('SET_FILTER_CACHE', { filterId, filter })
    },
  },
}
