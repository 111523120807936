//
import { truncate } from 'lodash'
import i18n from '@/libs/i18n'
import MediaListFilterComponent from './MediaList.vue'

export default class MediaListFilter {
  static COMPONENT = MediaListFilterComponent;

  static OPERATORS = {
    all: 'all',
    some: 'some',
    none: 'none',
    exists: 'exists',
    notExists: 'notExists',
  }

  static type = 'media-list'

  static graphqlType = 'MediaListFilter'

  operator = null;

  value = null;

  constructor({ operator = MediaListFilter.OPERATORS.some, value = [] } = {}) {
    this.operator = operator
    this.value = value
  }

  set({ operator = MediaListFilter.OPERATORS.some, value = [] }) {
    this.operator = operator
    this.value = value
  }

  getCount() {
    if (this.operator === MediaListFilter.OPERATORS.exists || this.operator === MediaListFilter.OPERATORS.notExists) {
      return 1
    }
    return this.value.length
  }

  getValueLabel({ cache }) {
    if (this.operator === MediaListFilter.OPERATORS.exists) {
      return i18n.t('filters.is-not-empty')
    }
    if (this.operator === MediaListFilter.OPERATORS.notExists) {
      return i18n.t('filters.is-empty')
    }
    const media = this.value.map(mediaId => cache[mediaId] || { id: mediaId })
    return media.map(m => truncate(m.title, { length: 15, separator: ' ' })).join(', ')
  }

  toLoad() {
    return {
      assets: this.value,
    }
  }

  asQueryParam() {
    if (this.operator === MediaListFilter.OPERATORS.exists || this.operator === MediaListFilter.OPERATORS.notExists) {
      return {
        operator: this.operator,
      }
    }
    return {
      operator: this.operator,
      value: this.value,
    }
  }

  asGraphQL() {
    switch (this.operator) {
      case MediaListFilter.OPERATORS.all:
        return {
          id: {
            all: this.value,
          },
        }
      case MediaListFilter.OPERATORS.some:
        return {
          id: {
            some: this.value,
          },
        }
      case MediaListFilter.OPERATORS.none:
        return {
          id: {
            none: this.value,
          },
        }
      case MediaListFilter.OPERATORS.exists:
        return {
          exists: true,
        }
      case MediaListFilter.OPERATORS.notExists:
        return {
          exists: false,
        }
      default:
        return null
    }
  }

  asGraphQLDynamicList() {
    if (this.asGraphQL()) {
      return {
        ...this.asGraphQL(),
        _t: MediaListFilter.graphqlType,
      }
    }
    return null
  }

  static validate({ operator, value }) {
    if (!operator) {
      return 'Operator is required'
    }

    if (operator === MediaListFilter.OPERATORS.exists || operator === MediaListFilter.OPERATORS.notExists) {
      return true
    }

    if (!value || value.length === 0) {
      return 'Value is required'
    }

    return true
  }

  static fromQueryParam({ operator, value }) {
    return new MediaListFilter({
      operator,
      value,
    })
  }

  static shouldApplyPredefinedValue(filter) {
    const forOperators = [MediaListFilter.OPERATORS.all, MediaListFilter.OPERATORS.some]
    return forOperators.includes(filter.operator)
  }
}
