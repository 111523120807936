<template>
  <div>
    <b-form-group
      :label="$t('fields.codex-field-date-and-time.general.field-name')"
      label-for="date-time-field__name"
    >
      <b-form-input
        id="date-time-field__name"
        v-model="name"
        :placeholder="$t('fields.codex-field-date-and-time.general.field-name-placeholder')"
      />
    </b-form-group>
    <b-form-group
      :label="$t('fields.codex-field-date-and-time.general.field-alias')"
      label-for="date-time-field__alias"
    >
      <b-form-input
        id="date-time-field__alias"
        :value="alias"
        disabled
      />
    </b-form-group>
    <b-form-group
      :label="$t('fields.codex-field-date-and-time.general.description')"
      label-for="date-time-field__description"
    >
      <b-form-textarea
        id="date-time-field__description"
        v-model="description"
        :placeholder="$t('fields.codex-field-date-and-time.general.description-placeholder')"
        rows="2"
        no-resize
      />
    </b-form-group>
  </div>
</template>

<script>
import { generateComputedPropsFromAttrs } from '@/components/codex-layout-editor/BuilderUtils'
import BaseFieldMixin from '@/components/fields/BaseFieldMixin'

export default {
  mixins: [BaseFieldMixin],
  computed: {
    ...generateComputedPropsFromAttrs([
      'name',
      'alias',
      'description',
    ]),
  },
}
</script>
