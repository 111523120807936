import state from './modulesSiteUsersState'
import mutations from './modulesSiteUsersMutations'
import actions from './modulesSiteUsersActions'
import getters from './modulesSiteUsersGetters'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
