<template>
  <b-modal
    id="at-prompt-popup"
    v-model="showPopup"
    :title="getTitle"
    :ok-title="getOkTitle"
    :ok-variant="data.okVariant ? data.okVariant : 'primary'"
    :cancel-title="getCancelTitle"
    :cancel-variant="data.cancelVariant ? data.cancelVariant : 'outline-dark'"
    centered
    :content-class="contentClasses"
    :modal-class="data.class"
    :ok-only="data.okOnly"
    :static="true"
    @ok="proceedConfirm"
    @cancel="cancelConfirm"
  >
    {{ data.description }}

    <b-form-group
      class="prompt__input"
    >
      <b-input
        v-model="promptText"
        :autofocus="true"
        @keydown.enter="proceedConfirm"
      />
    </b-form-group>

    <div v-if="data.helperText">
      {{ data.helperText }}
    </div>
  </b-modal>
</template>

<script>
import PopupsMixin from '../PopupsMixin'

export default {
  name: 'PromptPopup',
  mixins: [PopupsMixin],
  data() {
    return {
      promptText: '',
    }
  },
  computed: {
    getTitle() {
      if (this.data.title) {
        return this.data.title
      }
      return this.$t('general.unsaved-changes')
    },
    getOkTitle() {
      if (this.data.okTitle) {
        return this.data.okTitle
      }
      return this.$t('general.confirm')
    },
    getCancelTitle() {
      if (this.data.cancelTitle) {
        return this.data.cancelTitle
      }
      return this.$t('general.cancel')
    },
    contentClasses() {
      return {
        'confirmPopup-content': true,
      }
    },
  },
  mounted() {

  },
  methods: {
    async proceedConfirm() {
      this.canClose = false

      try {
        this.closePopup(this.promptText)
      } catch (error) {
        // Empty
      }
    },
    cancelConfirm() {
      this.closePopup('')
    },
  },
}
</script>

<style lang="scss" >
#at-prompt-popup {
  .modal-body {
    font-size: 14px;
    line-height: 18px;
  }
  .prompt__input {
    margin-top: 10px;
  }
}
</style>
