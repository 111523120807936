<template>
  <div class="job-upload">
    <div
      class="job-upload-field"
      :class="{
        'drop-upload': true,
        'drop-upload--over': isOver,
        'drop-upload--uploading': uploading,
      }"
      @dragenter.stop.prevent="dragenter"
      @dragover.stop.prevent="dragover"
      @dragleave="dragleave"
      @drop.stop.prevent="drop"
    >
      <label
        v-show="!file"
        for="job-upload-field__input"
      >
        <GjIcon
          class="job-upload-field__icon"
          name="IconparkUploadOne"
          size="28"
        />
        <p class="job-upload-field__label">
          {{ $t('jobs.create-popup.upload.title') }}
        </p>
        <p class="job-upload-field__description">
          {{ $t('jobs.create-popup.upload.info') }}
        </p>
        <p class="job-upload-field__browse-label">
          {{ $t('jobs.create-popup.upload.browse-button-label') }}
        </p>
        <input
          id="job-upload-field__input"
          ref="inputFile"
          type="file"
          accept="application/json"
          @change="setFileEvent"
        >
      </label>

      <div
        v-if="file && !_value"
        class="job-upload-field__item"
      >
        <GjIcon
          class="job-upload-field__icon"
          name="File"
          size="32"
        />
        <span class="job-upload-field__name">
          {{ file.name }}
        </span>
        <span class="job-upload-field__size">
          {{ bytesToSize(file.size, 2) }}
        </span>

        <div class="job__uploading-container">
          <div class="job__uploading-container__progress-container">
            <b-progress
              v-if="!error"
              variant="secondary"
              class="job__uploading-container__progress"
            >
              <b-progress-bar
                :value="percentage"
                :max="100"
                class="job__uploading-container__progress-bar"
              />
            </b-progress>
            <span class="job__uploading-container__progress_label">
              {{ error ? $t('jobs.create-popup.upload.uploading-fail') : isUploadComplete ?
                $t('assets.upload-progress.processing') :
                $t('assets.upload-progress.uploading', { percentage }) }}
            </span>
          </div>
          <GjIcon
            v-if="error"
            v-b-tooltip.hover="$t('jobs.create-popup.upload.retry-tooltip')"
            name="IconparkLinkCloudFaild"
            class="job-upload-field__icon job-upload-field__icon__failed"
            size="24"
            @click.native="setFile"
          />
          <span
            class="job-upload-field__remove"
            @click="removeImage"
          >
            <GjIcon
              name="Close"
              size="16"
            />
          </span>
        </div>
      </div>

      <div
        v-if="file && _value"
        class="job-upload-field__item-uploaded-container"
      >
        <GjIcon
          name="CloudCheckDoneSave"
          size="40"
          class="job-upload-field__item-uploaded-icon-success"
        />

        <span class="job-upload-field__item-uploaded-label-success">
          {{ $t('jobs.create-popup.upload.uploading-success') }}
        </span>

        <div class="job-upload-field__item-uploaded-content">
          <div class="job-upload-field__item-uploaded-column">
            <span class="job-upload-field__item-uploaded-content-label">
              {{ $t('jobs.create-popup.upload.size') }}
            </span>
            <span class="job-upload-field__item-uploaded-content-value">
              {{ bytesToSize(file.size, 2) }}
            </span>
          </div>
          <div class="job-upload-field__item-uploaded-column">
            <span class="job-upload-field__item-uploaded-content-label">
              {{ $t('jobs.create-popup.upload.file-name') }}
            </span>
            <span class="job-upload-field__item-uploaded-content-value">
              {{ file.name }}
            </span>
          </div>
          <div class="job-upload-field__item-uploaded-column">
            <span
              class="job-upload-field__remove"
              @click="removeImage"
            >
              <GjIcon
                name="Close"
                size="16"
              />
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import gql from 'graphql-tag'
import { bytesToSize, fileToText } from '@/utils/helpers'
import axios from 'axios'

export default {
  name: 'Upload',
  props: ['value'],
  inject: ['showAssetsPopup', 'toastNotification'],
  data() {
    return {
      bytesToSize,
      file: null,
      uploading: false,
      error: false,
      initFileUpload: null,
      isOver: false,
      dragEnterTarget: null,
    }
  },
  computed: {
    _value: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit('input', v)
      },
    },
    isUploadComplete() {
      return this.percentage === 100
    },
    percentage() {
      let loaded = 0
      let total = 0
      if (this.initFileUpload?.preSignedUrls) {
        this.initFileUpload.preSignedUrls.forEach(chunk => {
          const uploading = chunk.uploading
          if (uploading.started) {
            loaded += uploading.loaded
            total += uploading.total
          }
        })
      }
      if (!total) return 0
      return ((loaded / total) * 100).toFixed(0)
    },
  },
  methods: {
    dragenter(e) {
      if (!e.dataTransfer?.types?.includes('Files') || this._value) return

      this.dragEnterTarget = e.target
      this.isOver = true
    },
    dragover() {
      // Needed for drop event to fire
    },
    dragleave(e) {
      if (e.target !== this.dragEnterTarget) return

      e.stopPropagation()
      e.preventDefault()
      this.dragEnterTarget = null
      this.isOver = false
    },
    drop(e) {
      if (!e.dataTransfer?.types?.includes('Files') || this._value) return

      this.dragEnterTarget = null
      this.isOver = false

      const files = [...e.dataTransfer.files]
      const file = files[0]

      if (!file?.name?.toLowerCase().endsWith('.json')) {
        this.toastNotification({
          icon: 'Close',
          variant: 'danger',
          title: this.$t('Invalid file type'),
          text: this.$t('Only JSON files are accepted'),
        })

        return
      }

      this.file = file
      this.setFile()
    },
    removeImage() {
      this.file = null
      this.error = false
      this._value = null
    },
    async setFile() {
      try {
        if (this.uploading) return
        this.uploading = true
        this.error = false

        const fs = await fileToText(this.file)
        const { data: uploadData } = await this.$apollo.mutate({
          client: 'jobsClient',
          mutation: gql`
            mutation initFileUpload($input: InitFileUploadInput!) {
            initFileUpload(initFileUploadInput: $input) {
              uploadId
              key
              preSignedUrls {
                url
                partNumber
              }
            }
          }
        `,
          variables: {
            input: {
              name: this.file.name,
              size: fs.length,
            },
          },
        })

        uploadData.initFileUpload.preSignedUrls.forEach(e => {
          e.uploading = { loaded: 0, total: 0, started: false }
        })

        this.initFileUpload = uploadData.initFileUpload
        this.uploadFile()
      } catch (e) {
        console.log(e)
        this.error = true
      } finally {
        this.uploading = false
      }
    },
    async setFileEvent(event) {
      if (!event.target.files.length) return
      this.file = event.target.files[0]
      this.setFile()
    },
    async uploadFile() {
      try {
        const fs = await fileToText(this.file)
        const promises = []

        const { preSignedUrls } = this.initFileUpload
        for (let i = 0; i < preSignedUrls.length; i++) {
          const preSignedUrl = preSignedUrls[i]
          const CHUNK_SIZE = 5 * 1024 * 1024
          const { url } = preSignedUrl
          const chunk = fs.slice(i * CHUNK_SIZE, i * CHUNK_SIZE + CHUNK_SIZE)

          promises.push(
            axios.put(url, chunk, {
              headers: {
                'Content-Type': 'application/json',
                'x-amz-acl': 'public-read',
              },
              onUploadProgress: progressEvent => {
                preSignedUrls[i].uploading.loaded = progressEvent.loaded
                preSignedUrls[i].uploading.total = progressEvent.total
                preSignedUrls[i].uploading.started = true
              },
            }),
          )
        }
        const responses = await Promise.all(promises)
        const multiPartUploads = []

        for (let i = 0; i < preSignedUrls.length; i++) {
          const partNumber = preSignedUrls[i].partNumber
          const eTag = responses[i].headers.etag.replaceAll('"', '')
          multiPartUploads.push({ partNumber, eTag })
        }

        const { data: completeData } = await this.$apollo.mutate({
          client: 'jobsClient',
          mutation: gql`
            mutation initFileUpload($input: CompleteMultipartUploadInput!) {
              completeMultiPartUpload(completeMultipartUploadInput: $input) {
                success
              }
            }
          `,
          variables: {
            input: {
              key: this.initFileUpload.key,
              uploadId: this.initFileUpload.uploadId,
              multiPartUploads,
            },
          },
        })
        if (completeData?.completeMultiPartUpload?.success) {
          this._value = this.initFileUpload.key
          this.$refs.inputFile.value = null
        }
      } catch (e) {
        this.error = true
        console.log(e)
      }
    },
  },
}
</script>
