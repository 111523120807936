<template>
  <b-form-group class="compare-media-content-field">
    <template
      v-if="showFieldName"
      #label
    >
      <FieldName
        :name="field.name"
        :help-text="''"
      />
    </template>

    <div
      v-if="medias !== null && !Array.isArray(medias)"
      class="media-content__wrapper"
    >
      <div class="media-content__image">
        <span>{{ fieldContent.id }}</span>
        <GjIcon
          name="Image"
          size="200"
        />
      </div>
    </div>
    <div
      v-else-if="medias !== null && medias.length"
      class="multiple-media__wrapper"
      :class="classes"
    >
      <div
        v-for="media in medias"
        :key="media.id"
        class="multiple-media__item"
      >

        <b-aspect
          v-if="isDeleted(media)"
          aspect="1:1"
          style="width: 100px"
        >
          <div class="deleted-media">
            <span class="deleted-asset">
              <GjIcon
                name="HideAlt"
                size="28"
                style="fill: #9595A0;"
              />
            </span>
          </div>
        </b-aspect>
        <b-img-aspect
          v-else-if="media.type === ASSET_TYPES.IMAGE || media.type === ASSET_TYPES.VIDEO"
          aspect="1:1"
          :src="media.url"
          style="width: 100px"
        />
        <GjIcon
          v-else
          name="File"
          size="74"
        />
      </div>
    </div>
    <div
      v-else
      class="content-list__empty"
    >
      <GjIcon name="Images" />
      <span>{{ $t('entries.merge-tool.info.no-media') }}</span>
    </div>
    <div
      v-if="caption"
      class="media-content__caption"
    >
      <small>{{ caption }}</small>
    </div>
  </b-form-group>
</template>

<script>
import FieldName from '@/components/fields/FieldName.vue'
import gql from 'graphql-tag'
import { ASSET_STATUSES, ASSET_TYPES } from '@/codex-sdk/assets'

export default {
  name: 'MediaField',
  components: {
    FieldName,
  },
  props: {
    fieldContent: {
      type: [Object, Array],
    },
    field: {
      type: [Object],
    },
    showFieldName: {
      type: [Boolean],
      default: true,
    },
    caption: {
      type: [String],
      default: '',
    },
    classes: {
      type: [Array],
      default: () => [],
    },
  },
  data() {
    return {
      ASSET_TYPES,
      medias: [],
    }
  },
  apollo: {
    assetCollection: {
      query: gql`
        query ($ids: [String!], $limit: Int) {
          assetCollection (where: {
              id: {
                in: $ids
              }
            }, limit: $limit) {
              items {
                id
                title
                expiresAt
                fileName
                folderId
                type
                author
                source
                alt
                caption
                copyright
                url(transformation: {format: THUMBNAIL})
                createdBy {
                  id
                  firstName
                  lastName
                  email
                  imageUrl
                }
                updatedBy {
                  id
                  firstName
                  lastName
                  email
                  imageUrl
                }
                createdAt
                updatedAt

              }
            }
          }
      `,
      fetchPolicy: 'no-cache',
      variables() {
        return {
          ids: this.ids,
          limit: this.ids.length,
        }
      },
      skip() {
        return !this.ids?.length
      },
      result(results) {
        const medias = []
        // eslint-disable-next-line no-nested-ternary
        this.ids.forEach(e => {
          const item = results.data.assetCollection.items.find(c => c.id === e)
          if (item) {
            medias.push(item)
          } else {
            medias.push({ id: e, status: ASSET_STATUSES.DELETED })
          }
        })
        this.medias = medias
        return results
      },
    },
  },
  computed: {
    ids() {
      if (this.fieldContent && !Array.isArray(this.fieldContent)) return [this.fieldContent.id]
      return this.fieldContent?.map(m => m.id)
    },
  },
  methods: {
    isDeleted(media) {
      return media.status === ASSET_STATUSES.DELETED
    },
  },
}
</script>

<style lang="scss">
.compare-media-content-field {
  .deleted-media {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: #F7F7F8;
  }

  .deleted-asset{
    background: white !important;
    background-image: linear-gradient(to top, #EBDDFD, #F3F2F4  ) !important;
    padding:9px;
    border-radius:12px;
  }
}
.media-content__wrapper {
  border: 1px solid #E0E5EB;
  border-radius: 4px;
  padding: 12px;
}

.media-content__image {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.multiple-media__wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  border: 1px solid #E0E5EB;
  border-radius: 4px;
  padding: 12px;
}

.media-content__caption {
  background: #fff;
  border-radius: 4px;

  small {
    font-size: 12px !important;
    margin-left: 4px !important;
  }

  small::before {
    display: none;
  }
}
</style>
